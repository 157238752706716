angular.module('bitnudgeApp')
    .controller('quoteCtrl', function ($scope,Auth, Upload, toaster) {
        $scope.onFileSelect = function () {
            if ($scope.bulkQuote == undefined) {
                toaster.pop('error', "Submission.", "Select File to Upload.");
            } else {
                var fileName = $scope.bulkQuote[0].name.split(".");
                var fileType = fileName[fileName.length - 1];
                if (fileType == "xls" || fileType == "xlsx" || fileType == "csv") {
                    Upload.upload({
                        url: '/api/upload',
                        data: {userId: Auth.getCurrentUser()._id, 
                        cronTag:'uploadQuotes',
                        invocationMethod:"manual"},
                        file: $scope.bulkQuote[0],
                        progress: function (e) {
                        }
                    }).then(function (data, status, headers, config) {
                        toaster.pop('success', "Excel uploaded", "Division creation successfully finished.");
                    });
                } else {
                    toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' or '.csv' Types Only.");
                }
            }
        }
    });