const autoImportNgTemplateLoaderTemplate1 = require('../html/managePeersModal.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('campaignLeagueTableController', function ($scope, $timeout, $http, Auth, fetchLeagues, p0) {

        $scope.showCardView = true;
        $scope.me = Auth.getCurrentUser();

        $http.post('api/campaigns').then(function(campaigns) {
            campaigns=campaigns.data;
            $scope.campaigns = campaigns;
            $scope.selectedCampaignForOverview = campaigns[0];
            $scope.getContests();
        });
        if($scope.me.systemRole=='seniorManager')
            $scope.hideTabForNow=true;

        $scope.getContests = function() {
            var campaign = $scope.selectedCampaignForOverview;
            $scope.selectedCampaignForOverview = campaign;
            $scope.selectedCampaignForLeaderboard = campaign;

            $scope.selectedCampaignCycleIdForOverview = campaign.cycleId;
            $scope.selectedCampaignContests = campaign.contests;


            var allRoles = p0.allJobRoles;
            var me=Auth.getCurrentUser();

            $scope.roles = ($scope.playerType == "managers" ? getJobRolesForManagers(allRoles) : getJobRolesForFrontline(allRoles));

            $scope.selectedRole = $scope.roles[0];
            $scope.isOwn = (me.organizationalTag.jobRole._id == $scope.selectedRole._id);
            $scope.allLeagues =fetchLeagues.getAllLeagues(me, $scope.isOwn);
            $scope.selectedLeague = $scope.allLeagues[0];
            var campaignKpiIds = _.map($scope.selectedCampaignContests, "kpiId");
            $scope.kpis = _.filter(p0.allKpis, function(kpi){
                if(_.includes(campaignKpiIds, kpi._id)) {
                    kpi.contestName = _.find(campaign.contests, {kpiId:kpi._id}).name;
                    return true;
                }
            });

            $scope.lbs = getLbs();
            // Fetch data for default tab
            $scope.getAllLeaderboards();
        };


        $scope.getAllLeaderboards=function(){
            $scope.showPeerButton = ($scope.selectedLeague.value=="peer");

            var cycleId = $scope.selectedCampaignForLeaderboard.cycleId,
                role = $scope.selectedRole,
                league = $scope.selectedLeague;

            var lbArray = $scope.lbs;
            lbArray.forEach(function(lb, index){
                //if(index < 1)
                getLb(lb.type, lb.kpiId, lb.activityId, cycleId, role, league, lbArray, "#overallLB-loading");
            });

        };

        $scope.openManagePeersModal = function(){
            $mdDialog.open({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'managePeersModalController',
                size: 'md',
                resolve: {
                    refreshDataCB:  function(){return   $scope.getAllLeaderboards;}
                }
            });
        };

        var getLb = function(type, kpiId, activityId, cycle, jobRole, league, lbArray, congratsElement) {
            //$(congratsElement).text("Loading...");
            var cache = league.value != "peer";
            const urlDetails = getLbRoute(type, kpiId, activityId, cycle, jobRole, league);
            $http.post(urlDetails.url, urlDetails.postData).then(function(leaderboard){
                leaderboard=leaderboard.data;
                var numOfReporteesInTopFive, numOfReportees;
                var userLeaders = leaderboard.leaderboard
                if($scope.me.children!= undefined) {
                    numOfReportees= $scope.me.children.length
                    userLeaders = _.filter(userLeaders, function(user){
                        var userIds = _.map($scope.me.children, function(user){return user.childId.toString()});
                        return _.includes(userIds, user.userId._id.toString());
                    });
                    if(userLeaders)
                        numOfReporteesInTopFive = userLeaders.length
                }
                var index = _.findIndex(lbArray, {type:type, kpiId:kpiId, activityId:activityId});
                lbArray[index].leaderboard = leaderboard.leaderboard;
                lbArray[index].userRank = leaderboard.userRank;
                lbArray[index].leagueSize = leaderboard.leagueSize;
                lbArray[index].managerRank = leaderboard.managerRank;
                lbArray[index].numOfReportees = numOfReportees;
                lbArray[index].numOfReporteesInTopFive = numOfReporteesInTopFive;
                lbArray[index].loadingText = getCongratsText(lbArray[index]);
                //$(congratsElement).parent().parent().hide();
            });
        };

        var getCongratsText = function(lbObject) {

            var rank = lbObject.userRank;
            var leagueSize = lbObject.leagueSize;
            if(rank == 1)         return "Congratulations! You are ranked #1";

            if($scope.isOwn)
                return "You are ranked #" + rank + " out of " + leagueSize + " players";
            else{
                if($scope.me.systemRole == 'frontline'){
                    if(lbObject.managerRank == 1)
                        return "Congratulations! Your team ranks #1";
                    return "Congrats! Your team is among top 5 teams";
                }
                else{
                    if(lbObject.numOfReporteesInTopFive)
                        return "You have "+ lbObject.numOfReporteesInTopFive+ " out of " + lbObject.numOfReportees+ " reportees in top 5 ";
                    else
                        return "You have no reportees in top 5 ";
                }
            }
        };

        var getLbRoute = function(type, kpiId, activityId, cycleId, jobRole, league) {
            
            const url = '/api/analytics/leaderboards/cycles';
            const postData = {
                cycleId,
                kpiId,
                activityId,
                count:5,
                type,
                playerType: jobRole._id,
                teamType: league.value,
                teamId: league._id
            }
            return {url,postData};
        };


        $scope.onLbClick=function(type, kpiId, activityId,options){
            $scope.teamType = type.toLowerCase();

            $scope.showComplete();
            $scope.getCompleteList(type.toLowerCase(),kpiId, activityId,options.lbHeader);
        };

        $scope.showComplete = function() {
            $scope.showCardView=false;
        };

        $scope.showLbCards = function() {
            $scope.showCardView=true;
        }

        $scope.getCompleteList=function(type, kpiId, activityId, lbHeader){
            var league = $scope.selectedLeague;
            var jobRole = $scope.selectedRole;
            var cycleId = $scope.selectedCampaignForLeaderboard.cycleId;

            if(!league || !jobRole || !cycleId)  return;

            $scope.completeType=type;

            //must be done because we are piggybacking activity leaderboards in the same type as kpis. However, their routes
            //are different. So some hacking must be done to check if type refers to kpi or activity
            var url='/api/analytics/leaderboards/cycles';

            const postData = {
                cycleId,
                kpiId,
                activityId,
                type,
                playerType: jobRole._id,
                teamType: league.value,
                teamId: league._id
            }
            $http.post(url, postData).then(function(leaderboard){
                leaderboard=leaderboard.data;
                $scope.completeLB=leaderboard.leaderboard;
                $scope.selectedLeagueCardType=type;
                $scope.selectedCategory=lbHeader;
            });
        };



        var getJobRolesForFrontline = function (allRoles) {
            var myRole = _.find(allRoles, {_id:Auth.getCurrentUser().organizationalTag.jobRole._id});
            var flRoles;

            switch(myRole.family) {
                case "SO":              flRoles = _.filter(allRoles, function(r){ return r.name == "SO"}); break;
                case "DSA":             flRoles = _.filter(allRoles, function(r){ return r.name == "DSA"}); break;
                case "BM":              flRoles = _.filter(allRoles, function(r){ return r.name == "SO"}); break;
                case "TL":              flRoles = _.filter(allRoles, function(r){ return r.name == "DSA"}); break;
                case "SM":              flRoles = _.filter(allRoles, function(r){ return r.name == "SO" || r.name == "DSA"}); break;
            }
            return flRoles;
        };

        var getJobRolesForManagers = function (allRoles) {
            var myRole = _.find(allRoles, {_id:Auth.getCurrentUser().organizationalTag.jobRole._id});
            var mgrRoles;

            switch(myRole.family) {
                case "SO":              mgrRoles = _.filter(allRoles, function(r){ return r.name == "Branch Manager"}); break;
                case "DSA":             mgrRoles = _.filter(allRoles, function(r){ return r.name == "Team Leader"}); break;
                case "BM":              mgrRoles = _.filter(allRoles, function(r){ return r.name == "Branch Manager"}); break;
                case "TL":              mgrRoles = _.filter(allRoles, function(r){ return r.name == "Team Leader"}); break;
                case "SM":              mgrRoles = _.filter(allRoles, function(r){
                    return r.name == "Branch Manager" || r.name == "Team Leader"}); break;
            }
            return mgrRoles;
        };

        var getLbs = function () {
            var lbs = [];
            var loadingText = "Loading...";
            lbs.push({name:"Overall Campaign",              type:"business", kpiId:'overall',       activityId:"overall",           leaderboard:null, loadingText:loadingText});

            $scope.kpis.forEach(function(kpi){
                lbs.push({name:kpi.contestName,      type:"kpi",      kpiId:kpi._id,  activityId:"overall",           leaderboard:null, loadingText:loadingText});
            });
            return lbs;
        };

        $scope.getDaysLeft=function(){
            var currCampaign=$scope.selectedCampaignForLeaderboard;
            if(currCampaign)
                return Math.floor((new Date(currCampaign.endDate)-new Date())/(1000*60*60*24));
            else return 0;
        };

    });
