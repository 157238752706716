'use strict';
angular.module('bitnudgeApp')
	.controller('productConfigurationCtrl', function ($scope, p0, $http, $timeout, toaster, ProductService) {
		console.log('inside the product configuration')

		var getProductConfigsPromise = ProductService.getProductConfigs();
		getProductConfigsPromise.then(function (products) {
			$scope.products = products
		}, function (err) {
			toaster.pop('error', 'Products', err.message)
			console.log(err)
		})

		$scope.portfolioArray = []

		$scope.valueTypes = [{
			type: 'Number'
		}, {
			type: 'String'
		}, {
			type: 'phonenumber'
		},{
			type: 'Date'
		}]

		function getSubProductConfig(id) {
			var getSubProductConfigsByParentPromise = ProductService.getSubProductConfigsByParent(id);
			getSubProductConfigsByParentPromise.then(function (subProducts) {
                $scope.subProducts = subProducts;
				$scope.subProduct = false
			}, function (err) {
				console.log(err)
			})
		}

		$scope.selectProduct = function (product) {
			getSubProductConfig(product._id)
		}

		$scope.selectSubProduct = function (subproduct) {
			updatePortfolioArray()
		}

		$scope.addConfigRow = function () {
			$scope.addingConfig = true;
			var obj = {
				displayName: "",
				isPortfolio: false,
				showOnUI: false,
				portfolioLabel: $scope.subProduct.metaData[0] ? $scope.subProduct.metaData[0].portfolioLabel : "",
				type: "",
				newConfigRow: true,
				value: "String",
				productId: $scope.subProduct.productCode
			}
			$scope.subProduct.metaData.unshift(obj)
			$scope.addingConfig = false;
		}
		$scope.deleteConfigRow = function (type) {
			$scope.addingConfig = true;
			var index = _.findIndex($scope.subProduct.metaData, {
				type: type
			});
            $scope.subProduct.metaData.splice(index, 1)
            updatePortfolioArray()
			$scope.addingConfig = false;
		}

		$scope.changeDatatype = function (valueType, type) {
			var index = _.findIndex($scope.subProduct.metaData, {
				type: type
			});
			$scope.subProduct.metaData[index].value = valueType.type;
			updatePortfolioArray()
		}

		$scope.changePortfolioConfig = function (portfolioSelected) {
			_.forEach($scope.subProduct.metaData, function (metadata) {
				if (metadata.type == portfolioSelected.type) {
					metadata.isPortfolio = true;
				} else {
					metadata.isPortfolio = false;
				}
			})
		}

		function finalPortfolioFormat(){
			let flag = true;
			let errmsg = "";

			if(!$scope.portfolioLabel.length){
				flag = false
				errmsg = "Portfolio Label can not be empty";

			}
			else
			{
				_.forEach($scope.subProduct.metaData, function (metadata) {
					if(metadata.newConfigRow){
						metadata.type = metadata.displayName
					}
					if(!metadata.type.length){
						flag = false
						errmsg = "Display Name can not be empty"
					}
					metadata.portfolioUnit = $scope.portfolioUnit
					if($scope.portfolioLabel.length)
						metadata.portfolioLabel = $scope.portfolioLabel;
				})
			}
			$scope.subProduct.portfolioUnit = $scope.portfolioUnit
			if(!flag){
				showToaster('error','Updtation',errmsg)
				return false;
			}else
				return true;
		}

		function updatePortfolioArray() {
			$scope.portfolioArray = _.filter($scope.subProduct.metaData, function (metadata) {
				return metadata.value === 'Number'
			})
			$scope.portfolioUnit = $scope.subProduct.portfolioUnit;
        	$scope.portfolioLabel = $scope.portfolioArray[0]? $scope.portfolioArray[0].portfolioLabel : $scope.subProduct.portfolioLabel;
        }
        
        $scope.saveConfig = function () {
            if(finalPortfolioFormat()){
				ProductService.updateSubProduct($scope.subProduct).then( function(success){
					toaster.pop('success', "Updation", "Product Configuration updated");
				}, function(err){
					toaster.pop('error', "Updation", "Error in updating the product configuration");
				})
			}
		}

        $scope.downloadConfig = function(){
            ProductService.downloadSubProduct($scope.subProduct);
		}
		
		function showToaster(type, tag, errmsg){
			toaster.pop(type, tag, errmsg);
		}

	});
