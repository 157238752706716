const autoImportNgTemplateLoaderTemplate1 = require('../html/scoreCard/basicScoreCard.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('basicScoreCard', function(){
        return {
            name: 'basicScoreCard',
            scope: {
                cardId:                 '=',
                benchmarkValue:         '=',
                benchmark:              '=',
                kpi:                    '=',
                forecastMultiplier:     '=',
                isActive:               '=',
                isSeniorManager:        '=',
                isActiveCycle:          '=',
                enlarge:                '=',
                compareValue:           '=',
                applyForecast:          '=',
                selectedUser:           '=',
                selectedCycle:          '=',
                selectedKpi:            '='
            },
            controller: 'basicScoreCardCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
