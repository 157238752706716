const autoImportNgTemplateLoaderTemplate1 = require('../html/myForm.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('myForm', function () {
        return {
            name: 'myForm',
            scope: {},
            controller: 'myFormCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1

        };
    });
