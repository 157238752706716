const autoImportNgTemplateLoaderTemplate1 = require('../html/pointsInfo.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('earnStatementCtrl', function ($scope, earnStatement,p0, userAnalyticsService, Auth, $rootScope, $mdDialog) {
        $scope.isPercentage=false;
        $scope.strings = $rootScope.strings;
        $scope.prevDailyCycle = p0.trackDailyCycle;
        $scope.isPoints=true;
        $scope.me = Auth.getCurrentUser();
        $scope.activityNames = {'call' :{name:'Calls'},
            'submission':   {name:'Submissions'},
            'meeting':      {name:'Meetings'},
            'newLead':      {name:'Leads'},
            'email':        {name:'Emails'}};

        $scope.columnindex = 0;

        $scope.kaiLeft = function(){
            if($scope.columnindex>0)
                $scope.columnindex=$scope.columnindex-1; //#1 - Jump 1 fields everytime
        };

        $scope.kaiRight = function(){
            if($scope.columnindex< $scope.activities.length-4) // #4 - Number of fields displaying
                $scope.columnindex=$scope.columnindex+1;
        };


        $scope.config = {
            autoHideScrollbar: false,
            theme: 'dark',
            advanced:{
                updateOnContentResize: true
            },
            setHeight: "100%",
            axis:'y',
            scrollInertia: 0
        };

        var fetchData = function(){
            earnStatement.getEarnStatement($scope.cycle).then(function(data){
                $scope.activities = earnStatement.getActivities();
                $scope.data = data;
            });
        };

        var fetchpreviousDaydata = function(){
            earnStatement.getEarnStatement($scope.prevDailyCycle).then(function(data){
                $scope.activities = earnStatement.getActivities();
                $scope.previousDaydata = data;
            });
        };

        var fetchMonthlyCycleData = function () {
            $scope.monthlyCycle = $scope.cycle;
            userAnalyticsService.fetchUserAnalytics($scope.me._id,$scope.cycle._id).then(function(userAnalytic){
                $scope.userAnalytic = userAnalytic;
            });
        };
        $scope.showWeeklyBar = false;
        $scope.$watch('cycle',function (newValue, oldValue) {
            if($scope.cycle) {
                $scope.showWeeklyBar = $scope.cycle.cycleType != 'monthly';
                if($scope.cycle.cycleType == 'monthly'){
                    fetchMonthlyCycleData();
                }
                fetchData();
            }
        });
        $scope.showPointsInfo = function(){
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'pointsInfoCtrl'
            });
        };
        fetchpreviousDaydata();
    });
