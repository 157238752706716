'use strict';
angular.module('bitnudgeApp')
    .controller('badgeRecommendationModalCtrl', function ($scope,recommendation, p0,userAnalyticsService,leagueAnalytic, Auth,$mdDialog, selectedKpi) {
        var init = (function(){
            $scope.data = {};
            this.me = Auth.getCurrentUser();
            this.cycleIds = [];
            this.cycleIds.push(p0.currentMonthlyCycle._id);
            if(p0.config.weeklyCycle){
                this.cycleIds.push(p0.currentWeeklyCycle._id);
            }
        }).bind(this);

        init();
        
        userAnalyticsService.fetchUserAnalyticsOfCycles(this.me._id, this.cycleIds).then((userAnalytics) => {
            var monthlyUserAnalytics = userAnalytics[0];
            var weeklyUserAnalytics = userAnalytics[1];
            var kpiId = selectedKpi == 'overall' ? null : selectedKpi._id;
            var monthlyKpiData = _.find(monthlyUserAnalytics.kpiData,{kpiId:kpiId});
            leagueAnalytic.fetchLeagueAnalytic(this.me.leagues[this.me.leagues.length - 1].leagueId, p0.currentMonthlyCycle._id).then((leagueAnalytic) => {
                var multiKpi = p0.myKpis.length >1;
                recommendation.addBadgeRecommendations($scope.data, monthlyKpiData, monthlyUserAnalytics, weeklyUserAnalytics, this.me.leagues[this.me.leagues.length - 1].leagueId, leagueAnalytic, selectedKpi, multiKpi).then(() => {

                });
            })
        });

        $scope.onClose = function() {
            $mdDialog.cancel();
        };
    });
