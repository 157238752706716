const autoImportNgTemplateLoaderTemplate1 = require('../html/barGraph.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('barGraph', function(){
        return {
            name: 'barGraph',
            scope: {
                value: '@',
                value2: '@',
                max: '@',
                max2:'@',
                color:'@',
                color2:'@',
                right:'@',
                type:'@',
                label: '@',
                label2: '@',
                fontSize: '@',
                unit: '@'
            },
            controller: 'barGraphCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
