angular.module('bitnudgeApp')
    .controller('addModuleCtrl', function ($http, $scope, Auth, Upload, $location, toaster,$state) {
        $scope.addIP = function (form) {
            var isValid = true;
            if(!$scope.data){
                $scope.message = "Please provide all the fields";
                isValid = false;
            }
            else if(!$scope.data.name){
                $scope.message = "Please provide name field";
                isValid = false;
            }
            else if(!$scope.data.alaisName){
                $scope.message = "Please provide name field";
                isValid = false;
            }
            if(isValid){
                $http.post('/api/adminActions/addModule',$scope.data).then(function(result){
                    if(result.data.status){
                        toaster.pop("success","IP added","Successfully module added");
                        $scope.data.name = "";
                        $scope.data.alaisName = "";
                    }else{
                        toaster.pop("error","Error",result.data.errors[0].message);
                    }
                }); 
            }
        
        }

        
});