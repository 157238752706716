const autoImportNgTemplateLoaderTemplate1 = require('../html/admin/lbJobroleConfigModal.html');

angular.module('bitnudgeApp')
    .controller('lbJobroleConfigCtrl', function ($scope, $http, $mdDialog) {
        function setJobRolesConfig() {
            //get all the jobroles for showing all the existing config in the ui
            $http.post('/api/jobroles/index').then(function (result) {
                let allJobRoles = result.data;
                allJobRoles = _.orderBy(allJobRoles, ['jobCode'], ['asc'])
                //for each of the job roles get the string representation in jobcode for showing in the ui
                allJobRoles.forEach(function (jobRoleObject) {
                    jobRoleObject.comparisonRoleFrontlineString = getJobCodeStringFromComparisonRoles(jobRoleObject, 'frontline')
                    jobRoleObject.comparisonRoleManagerString = getJobCodeStringFromComparisonRoles(jobRoleObject, 'manager')
                    jobRoleObject.comparisonRoleSeniorManagerString = getJobCodeStringFromComparisonRoles(jobRoleObject, 'seniorManager')
                })
                //it contains all the jobrole objects with their config
                $scope.allJobRoles = allJobRoles;
            });
        }

        //open the edit jobrole modal for changing the selected jobRole's config
        $scope.editLbRoles = function (jobRoleObject, roleType) {
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'lbJobroleConfigModalCtrl',
                fullscreen: true, // Only for -xs, -sm breakpoints.
                resolve: {
                    jobRole: function () {
                        return jobRoleObject;
                    },
                    allJobRoles: function () {
                        return angular.copy($scope.allJobRoles);
                    },
                    preSelectedJobRoles: function () {
                        return getRolesForRoleType(jobRoleObject, roleType);
                    },
                    roleType: function () {
                        return roleType;
                    },
                    refreshFunction: function () {
                        return setJobRolesConfig;
                    }
                }
            })
        }

        //remove the , at the last place if it exists
        function removeCommaAtLastPlace(string) {
            if (string.length > 0 && string[string.length - 1] == ",") {
                return string.substring(0, string.length - 1);
            }
            return string;
        }
        //given the jobrole and roletype returns the string representation in terms of jobcode
        function getJobCodeStringFromComparisonRoles(jobRoleObject, roleType) {
            var comparisonjobRoles = getRolesForRoleType(jobRoleObject, roleType)
            var comparisonRoleString = "";
            var trimmed = false;
            if (comparisonjobRoles) {
                var trimmedComparisonRoles = comparisonjobRoles;
                if (comparisonjobRoles.length > 5) {
                    trimmedComparisonRoles = comparisonjobRoles.slice(0, 5)
                    trimmed = true;
                }
                trimmedComparisonRoles.forEach(function (comparisonRole) {
                    comparisonRoleString += comparisonRole.jobCode + ",";
                })
            }
            comparisonRoleString = removeCommaAtLastPlace(comparisonRoleString)
            if (trimmed) {
                comparisonRoleString += "........"
            }
            return comparisonRoleString;
        }

        //returns the jobrole config based on role type
        function getRolesForRoleType(jobRoleObject, roleType) {
            if (roleType == 'frontline') {
                return jobRoleObject.comparisonRolesFrontline;
            }
            else if (roleType == 'manager') {
                return jobRoleObject.comparisonRolesManager;
            }
            else {
                return jobRoleObject.comparisonRolesSeniorManager;
            }
        }

        //init function
        setJobRolesConfig()
    });