angular.module('bitnudgeApp')
    .controller('addBulkActivityCtrl', function ($http, $scope, Auth, Upload, $location, toaster) {
        $scope.fileType = "activities"
        $scope.onFileSelectAction = function () {
            if ($scope.bulkAction == undefined) {
                toaster.pop('error', "Submission.", "Select File to Upload.");
            } else {
                var fileName = $scope.bulkAction[0].name.split(".");
                var fileType = fileName[fileName.length - 1];
                if (fileType == "xls" || fileType == "xlsx" || fileType == "csv") {
                    Upload.upload({
                        url: '/api/upload',
                        data: {userId: Auth.getCurrentUser()._id,cronTag:'uploadActivity',invocationMethod:"manual"},
                        file: $scope.bulkAction[0],
                        progress: function (e) {
                        }
                    }).error(function (data, status, headers, config) {
                        console.error('Error uploading file')
                        toaster.pop('error', "Submission.", `Unable to trigger upload: ${data}`);
                      })
                    .then(function (data, status, headers, config) {
                        // file is uploaded successfully
                        toaster.pop('success', "Excel uploaded", "Activity creation successfully finished.");
                    });
                } else {
                    toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' or '.csv' Types Only.");
                }
            }
        }
    });
