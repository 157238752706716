'use strict';

const gpAnalyze = require('../html/gpAnalyze.html');
const gpAnalyzeDaily = require('../html/gpAnalyzeDaily.html');

angular.module('bitnudgeApp')
    .directive('gpAnalyze', function () {
        return {
            name: 'gpAnalyze',
            scope: {
            },
            controller: 'gpAnalyzeCtrl',
            restrict: 'AE',
            templateUrl: function (elem,attr) {
                if(attr.targetPeriod === 'month') return gpAnalyze;
                else return gpAnalyzeDaily;
            }
        };
    });
