'use strict';
angular.module('bitnudgeApp')
    .controller('playBookCtrl', function ($scope, $timeout, $rootScope,$location,$state, $http, Auth, $redirectRouter, moment, $filter,$q, $stateParams, toaster, DTOptionsBuilder, DTColumnDefBuilder, SwotService, GetDeltaImageService, p0, CurrentCycle, Kpi) {
        $scope.me=Auth.getCurrentUser();
        $scope.selectedRole = $scope.me.organizationalTag.jobRole;
        $scope.impactPtTransactions = [];
        $scope.strings = $rootScope.strings;
        $scope.config = $rootScope.config;

        function setKpiDropdown(){
            $scope.kpis = [];
            $scope.kpis = _.cloneDeep(p0.myKpis);
            $scope.kpis.unshift({_id:'overall',name:'Overall'});
            if((!$scope.selectedKpi || !_.find($scope.kpis,{_id:$scope.selectedKpi._id})) && $scope.kpis.length){
                $scope.selectedKpi = $scope.kpis[0];
            }else if(!$scope.kpis.length){
                $scope.selectedKpi = null;
            }
        }

        $scope.changeKpi = function (kpi) {
           $scope.selectedKpi = kpi;
        };
        setKpiDropdown();

        $scope.changeImpactStm = function(tabType) {
            $scope.impactStmTab=tabType;
            // $scope.impactStmTab++;
            // $scope.impactStmTab %= 2;
        };

        $scope.cycleChange = function (cycle) {
            $scope.selectedCycle = cycle;
            CurrentCycle.setCycle(cycle);
        };

        $scope.showRedemption = p0.config.showRedemptionPoints;
        $scope.dtOptionsForImpactPtTable = DTOptionsBuilder.newOptions().withPaginationType('simple_numbers').withOption('order', [0, 'desc']);
        $scope.dtOptionsForImpactPtTableHistory = DTOptionsBuilder.newOptions().withPaginationType('simple_numbers').withOption('order', [0, 'asc']);
        /**
         * Deprecated
         */
        /*$scope.getActivityPtTransactions = function(selectedCycle){
            $scope.activityStmTab = 'summary';


        };*/

        $scope.changeActivityStm = function(tabType) {
            $scope.activityStmTab = tabType;
            // $scope.activityStmTab++;
            // $scope.activityStmTab %= 2;
        };

        $scope.dtOptionsForActivityPtTable = DTOptionsBuilder.newOptions().withPaginationType('simple_numbers').withOption('order', [0, 'de sc']);
        $scope.dtOptionsForActivityPtTableHistory = DTOptionsBuilder.newOptions().withPaginationType('simple_numbers').withOption('order', [0, 'asc']);
        $scope.dtColumnDefsForActivityPtTable = [
            DTColumnDefBuilder.newColumnDef(0).notSortable(),
            DTColumnDefBuilder.newColumnDef(1).notSortable(),
            DTColumnDefBuilder.newColumnDef(2).notSortable(),
            DTColumnDefBuilder.newColumnDef(3).notSortable(),
        ];
        //transaction stats ends

        //campaign leo starts

        $scope.cycles = _.filter(p0.allCycles, function (cycle) {
            return cycle.cycleType != 'campaign'
        });
        $scope.selectedCycle = p0.currentMonthlyCycle;
        $scope.selectedCycleForImpact = p0.currentMonthlyCycle;
        $scope.selectedCycleForActivity = p0.currentMonthlyCycle;
        $scope.activityTM = p0.allActivities;
        $scope.impactTM = p0.allKpis;

        $scope.points_redeemable = "2500";

        $scope.burndata = [
            {"date":"25th Aug, 2016","points_redeemed":"25","points_redeemed_sub":"Impact Pts.","overall":"3185","impact":"3185","activities":"3185","redemption_status":"Applied","redemption_details":"lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur"},
            {"date":"25th Aug, 2016","points_redeemed":"25","points_redeemed_sub":"Impact Pts.","overall":"3185","impact":"3185","activities":"3185","redemption_status":"Applied","redemption_details":"lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur"},
            {"date":"25th Aug, 2016","points_redeemed":"25","points_redeemed_sub":"Impact Pts.","overall":"3185","impact":"3185","activities":"3185","redemption_status":"Applied","redemption_details":"lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur"},
            {"date":"25th Aug, 2016","points_redeemed":"25","points_redeemed_sub":"Impact Pts.","overall":"3185","impact":"3185","activities":"3185","redemption_status":"Applied","redemption_details":"lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur"},
            {"date":"25th Aug, 2016","points_redeemed":"25","points_redeemed_sub":"Impact Pts.","overall":"3185","impact":"3185","activities":"3185","redemption_status":"Applied","redemption_details":"lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur"},
            {"date":"25th Aug, 2016","points_redeemed":"25","points_redeemed_sub":"Impact Pts.","overall":"3185","impact":"3185","activities":"3185","redemption_status":"Applied","redemption_details":"lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur"},
            {"date":"25th Aug, 2016","points_redeemed":"25","points_redeemed_sub":"Impact Pts.","overall":"3185","impact":"3185","activities":"3185","redemption_status":"Applied","redemption_details":"lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur"},
            {"date":"25th Aug, 2016","points_redeemed":"25","points_redeemed_sub":"Impact Pts.","overall":"3185","impact":"3185","activities":"3185","redemption_status":"Applied","redemption_details":"lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur"},
            {"date":"25th Aug, 2016","points_redeemed":"25","points_redeemed_sub":"Impact Pts.","overall":"3185","impact":"3185","activities":"3185","redemption_status":"Applied","redemption_details":"lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur"},
            {"date":"25th Aug, 2016","points_redeemed":"25","points_redeemed_sub":"Impact Pts.","overall":"3185","impact":"3185","activities":"3185","redemption_status":"Applied","redemption_details":"lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur"},
            {"date":"25th Aug, 2016","points_redeemed":"25","points_redeemed_sub":"Impact Pts.","overall":"3185","impact":"3185","activities":"3185","redemption_status":"Applied","redemption_details":"lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur"},
            {"date":"25th Aug, 2016","points_redeemed":"25","points_redeemed_sub":"Impact Pts.","overall":"3185","impact":"3185","activities":"3185","redemption_status":"Applied","redemption_details":"lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur"},
            {"date":"25th Aug, 2016","points_redeemed":"25","points_redeemed_sub":"Impact Pts.","overall":"3185","impact":"3185","activities":"3185","redemption_status":"Applied","redemption_details":"lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur"},
            {"date":"25th Aug, 2016","points_redeemed":"25","points_redeemed_sub":"Impact Pts.","overall":"3185","impact":"3185","activities":"3185","redemption_status":"Applied","redemption_details":"lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur"},
            {"date":"25th Aug, 2016","points_redeemed":"25","points_redeemed_sub":"Impact Pts.","overall":"3185","impact":"3185","activities":"3185","redemption_status":"Applied","redemption_details":"lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur"},
            {"date":"25th Aug, 2016","points_redeemed":"25","points_redeemed_sub":"Impact Pts.","overall":"3185","impact":"3185","activities":"3185","redemption_status":"Applied","redemption_details":"lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur"},

        ];

        $scope.title1 = "Redemption options for you";
        $scope.title2 = "All Redemption Options";

         $scope.configObj = {
            autoHideScrollbar: false,
            theme: 'dark',
            advanced:{
                updateOnContentResize: true
            },
                setHeight: 200,
                axis:'y',
                scrollInertia: 0
            };

        $scope.nonkpipointsdata = [
            {"nonKpi":"Learning","Points":"10"},
            {"nonKpi":"Learning","Points":"10"},
            {"nonKpi":"Learning","Points":"10"},
            {"nonKpi":"Learning","Points":"10"},
            {"nonKpi":"Learning","Points":"10"},
            {"nonKpi":"Learning","Points":"10"},
            {"nonKpi":"Learning","Points":"10"},
            {"nonKpi":"Learning","Points":"10"},
            {"nonKpi":"Learning","Points":"10"},
            {"nonKpi":"Learning","Points":"10"},
            {"nonKpi":"Learning","Points":"10"},
            {"nonKpi":"Learning","Points":"10"}
        ];

        /*
        $scope.pointsdata = [
            {"kpi":"Personal Loan","kpisubname":"AED.m","achievement":0.4,"pts_earned_sub_total_1":"3185","pts_earned_sub_total_1_percentage":"70","col1":"12","col2":"08","col3":"02","col4":"02","col5":"12","col6":"08","col7":"02","colpts1":"24","colpts2":"18","colpts3":"12","colpts4":"12","colpts5":"24","colpts6":"18","colpts7":"12","otherkpiactions":"12","pts_earned_sub_total_2":"200","total_points":"3385"},
            {"kpi":"Personal Loan","kpisubname":"AED.m","achievement":0.4,"pts_earned_sub_total_1":"3185","pts_earned_sub_total_1_percentage":"70","col1":"12","col2":"08","col3":"02","col4":"02","col5":"12","col6":"08","col7":"02","colpts1":"24","colpts2":"18","colpts3":"12","colpts4":"12","colpts5":"24","colpts6":"18","colpts7":"12","otherkpiactions":"12","pts_earned_sub_total_2":"200","total_points":"3385"},
            {"kpi":"Personal Loan","kpisubname":"AED.m","achievement":0.4,"pts_earned_sub_total_1":"3185","pts_earned_sub_total_1_percentage":"70","col1":"12","col2":"08","col3":"02","col4":"02","col5":"12","col6":"08","col7":"02","colpts1":"24","colpts2":"18","colpts3":"12","colpts4":"12","colpts5":"24","colpts6":"18","colpts7":"12","otherkpiactions":"12","pts_earned_sub_total_2":"200","total_points":"3385"},
            {"kpi":"Personal Loan","kpisubname":"AED.m","achievement":0.4,"pts_earned_sub_total_1":"3185","pts_earned_sub_total_1_percentage":"70","col1":"12","col2":"08","col3":"02","col4":"02","col5":"12","col6":"08","col7":"02","colpts1":"24","colpts2":"18","colpts3":"12","colpts4":"12","colpts5":"24","colpts6":"18","colpts7":"12","otherkpiactions":"12","pts_earned_sub_total_2":"200","total_points":"3385"},
            {"kpi":"Personal Loan","kpisubname":"AED.m","achievement":0.4,"pts_earned_sub_total_1":"3185","pts_earned_sub_total_1_percentage":"70","col1":"12","col2":"08","col3":"02","col4":"02","col5":"12","col6":"08","col7":"02","colpts1":"24","colpts2":"18","colpts3":"12","colpts4":"12","colpts5":"24","colpts6":"18","colpts7":"12","otherkpiactions":"12","pts_earned_sub_total_2":"200","total_points":"3385"},
            {"kpi":"Personal Loan","kpisubname":"AED.m","achievement":0.4,"pts_earned_sub_total_1":"3185","pts_earned_sub_total_1_percentage":"70","col1":"12","col2":"08","col3":"02","col4":"02","col5":"12","col6":"08","col7":"02","colpts1":"24","colpts2":"18","colpts3":"12","colpts4":"12","colpts5":"24","colpts6":"18","colpts7":"12","otherkpiactions":"12","pts_earned_sub_total_2":"200","total_points":"3385"}
        ];
        */

        $scope.kaicols = ["INTERACT","CALLS","METTING","SUB","LEAD", "EMAIL","OTHER"];

        $scope.pointsdata = [
            {"kpi":"Personal Loan","kpisubname":"AED.m","achievement":0.4,"pts_earned_sub_total_1":3185,"pts_earned_sub_total_1_percentage":"70","pointscol":[12,22,32,42,52,62,72],"otherkpiactions":"12","pts_earned_sub_total_2":"200","total_points":"3385"},
            {"kpi":"Personal Loan","kpisubname":"AED.m","achievement":0.4,"pts_earned_sub_total_1":3185,"pts_earned_sub_total_1_percentage":"70","pointscol":[12,22,32,42,52,62,72],"otherkpiactions":"12","pts_earned_sub_total_2":"200","total_points":"3385"},
            {"kpi":"Personal Loan","kpisubname":"AED.m","achievement":0.4,"pts_earned_sub_total_1":3185,"pts_earned_sub_total_1_percentage":"70","pointscol":[12,22,32,42,52,62,72],"otherkpiactions":"12","pts_earned_sub_total_2":"200","total_points":"3385"},
            {"kpi":"Personal Loan","kpisubname":"AED.m","achievement":0.4,"pts_earned_sub_total_1":3185,"pts_earned_sub_total_1_percentage":"70","pointscol":[12,22,32,42,52,62,72],"otherkpiactions":"12","pts_earned_sub_total_2":"200","total_points":"3385"},
            {"kpi":"Personal Loan","kpisubname":"AED.m","achievement":0.4,"pts_earned_sub_total_1":3185,"pts_earned_sub_total_1_percentage":"70","pointscol":[12,22,32,42,52,62,72],"otherkpiactions":"12","pts_earned_sub_total_2":"200","total_points":"3385"},
            {"kpi":"Personal Loan","kpisubname":"AED.m","achievement":0.4,"pts_earned_sub_total_1":3185,"pts_earned_sub_total_1_percentage":"70","pointscol":[12,22,32,42,52,62,72],"otherkpiactions":"12","pts_earned_sub_total_2":"200","total_points":"3385"},
            {"kpi":"Personal Loan","kpisubname":"AED.m","achievement":0.4,"pts_earned_sub_total_1":3185,"pts_earned_sub_total_1_percentage":"70","pointscol":[12,22,32,42,52,62,72],"otherkpiactions":"12","pts_earned_sub_total_2":"200","total_points":"3385"}
        ];

        $rootScope.currentState = {tab: $scope.strings.POINTS.value+" Statement", now: new Date()};

        $scope.loadedflag=1;

        //campaign leo ends
    });
