'use strict';

angular.module('bitnudgeApp')
    .controller('moversAndShakersCardsCtrl', function($scope, $http, toaster, $rootScope, $location, Auth, $state) {
        $scope.showCardView = true;
        $scope.isSlickDataLoaded = false;
        $scope.textForEmptyTable = "No relevant data";

        $scope.getTeamName = function(player) {
            if(!player.userId)    return "";
            return player.userId.teams[0].name;
        };

        $scope.compareSinglePlayer=function(playerObj){
            if(playerObj._id==Auth.getCurrentUser()._id)
                return toaster.pop('error', "Select", "You cannot Select Yourself");
            $rootScope.myComparison = $scope.isOwnCards;
            $rootScope.playersFromLB = [playerObj._id];
            $rootScope.comparePlayersFromLB = true;
            $rootScope.comparePlayersFromLBMS = true;
            $rootScope.jobRoleFromLB = $scope.jobRole;
            $state.go('userFlow.analytics', { tabName: 'playerComparison', myComparison: $rootScope.myComparison});
        };

        $scope.getCompleteMoversAndShakers=function(type, selectedKPI, selectedCycleForMSBMTL, selectedPlayerTypeForMSBMTL, selectedLeagueForMSBMTL,gainType,selectedCategory,arr){
            var onCompleteList = $scope.onCompleteClick;
            if(onCompleteList && typeof onCompleteList == 'function'){
                onCompleteList(type, selectedKPI, selectedCycleForMSBMTL, selectedPlayerTypeForMSBMTL, selectedLeagueForMSBMTL,gainType,selectedCategory,arr);
            }
        };

        $scope.showTopMS=function(){
            $scope.showCardView = true;
        };

        $scope.$watch('mss', function(newValue, old){
            if(newValue){
                $scope.isSlickDataLoaded = false;
                setTimeout(function(){
                    $scope.isSlickDataLoaded = true;
                },5);
            }
        });

        $scope.slickConfig = {
            method: {},
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows:true,
            draggable:true
        }


    });
