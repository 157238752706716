'use strict';
angular.module('bitnudgeApp')
    .service('rhsAnalyticsL2Achievement', function (userAnalyticsService,$rootScope,leagueAnalytic,p0,$filter, ForecastMultiplier, $q) {
        let self = this;
        self.config = p0.config;
        this.getData = function(user,kpi,cycle){
            return userAnalyticsService.fetchUserAnalytics(user._id,cycle._id).then(function(userAnalytic){
                var userLeagues = _.map(user.leagues,'leagueId');
                var leagueCycle = cycle.cycleType == 'daily'? p0.currentMonthlyCycle:cycle;
                return leagueAnalytic.fetchLeagueAnalyticsOfCycle(userLeagues,leagueCycle._id).then(function(leagueAnalytics){
                    return self.setReturnData({userAnalytic, leagueAnalytics, cycle, kpi, user, userLeagues});
                });
            }).catch(function(err){
                console.log(err);
            });
        };

        this.setReturnData = ({userAnalytic, leagueAnalytics, cycle, kpi, user, userLeagues}) => {
            var returnData = {};
            if(kpi._id == 'overall'){
                //    card1
                let card1Info = self.getCard1Information(cycle, userAnalytic);
                returnData.card1 = self.setCard1OverallData(userAnalytic, cycle, card1Info);

                //    card2
                let card2Info = self.getCard2Information(cycle, leagueAnalytics, user, kpi);
                let card2Headings = self.getCard2Heading(cycle, kpi);
                Object.assign(card2Info, card2Headings);
                Object.assign(card2Info, card1Info);
                returnData.card2 = self.setCard2Data(card2Info);

                //    card3
                let card3Info = self.getCard3Information(cycle, userAnalytic);
                Object.assign(card2Info, card3Info);
                returnData.card3 = self.setCard3Data(card2Info);
                return $q.resolve(returnData);
            }else {
                var kpiData = _.find(userAnalytic.kpiData, {kpiId: kpi._id});
                var forecastMultiplier = cycle.cycleType == 'monthly' ? ForecastMultiplier.ofCycle(cycle._id) : $rootScope.weeklyForecastMultiplier;
                //card1
                let cycles = self.getPreviousCycles(cycle);
                let cycleIds = _.map(cycles, '_id');
                return userAnalyticsService.fetchUserAnalyticsOfCycles(user._id, cycleIds).then( function (card1Analytics) {
                    returnData.card1 = self.setCard1KpiData(cycle, cycles, kpi, kpiData, card1Analytics, forecastMultiplier);
                    
                    //    card2
                    let card2Info = self.getCard2KpiInformation(leagueAnalytics, cycle, kpi, kpiData, user);
                    let card2Headings = self.getCard2Heading(cycle, kpi);
                    Object.assign(card2Info, card2Headings);
                    returnData.card2 = self.setCard2Data(card2Info);

                    //    card3
                    let card3Info = self.getCard3Information(cycle, kpiData);
                    Object.assign(card2Info, card3Info);
                    returnData.card3 = self.setCard3Data(card2Info);

                    //    card4
                    if ($rootScope.lms) {
                        returnData.card4 = self.setCard4KpiData(kpiData);
                    }
                    return $q.resolve(returnData);
                });
            }

        }
        
        this.setCard4KpiData = (kpiData) => {
            var pipelineValue = kpiData.pipelineValue.value / kpiData.denom;
            var pipelinePPText;
            var pipelineDelta = kpiData.pipelineValue.delta;
            if (pipelineDelta < 0) {
                pipelinePPText = 'Down ' + $filter('number')(-pipelineDelta, 1) + ' vs pp';
            } else {
                pipelinePPText = 'Up ' + $filter('number')(pipelineDelta, 1) + ' vs pp';
            }

            let card4 = {
                heading1: 'Pipeline',
                heading2: '(' + kpiData.unit + ')',
                max: 10,
                data: [
                    {
                        label: 'Me',
                        number: $filter('number')(pipelineValue, 1),
                        pp: pipelinePPText,
                        icon: pipelineDelta < 0 ? 'fa-arrow-down' : 'fa-arrow-up',
                        labelColor: 'blue-1',
                        graphColor: pipelineDelta < 0 ? 'red' : 'green'
                    }
                ]
            };
            if(kpiData.stagesData){
                kpiData.stagesData.forEach(function (stagesData) {
                    var value = stagesData.pipelineValue ? stagesData.pipelineValue.value || 0 : 0
                    card4.data.push({
                        label: 'S' + stagesData.stageNum,
                        value: $filter('number')( value / kpiData.denom, 1)
                    });
                });
            }
            return card4;
        }

        this.getCard2KpiInformation = (leagueAnalytics, cycle, kpi, kpiData, user) => {
            let mtd, forecast, bankTopDecile, lowestLeagueBest, dropDown = []; 
            var bankKpiData = _.find(leagueAnalytics[leagueAnalytics.length - 1].kpiData, {kpiId: kpi._id});
            var lowestLeagueKpiData = _.find(leagueAnalytics[0].kpiData, {kpiId: kpi._id});
            if (cycle.cycleType == 'weekly' && self.config.showGameplan) {
                mtd = kpiData.gameplanPercent.value;
                forecast = mtd * $rootScope.weeklyForecastMultiplier;
                bankTopDecile = bankKpiData.gameplanPercent.topDecile;
                lowestLeagueBest = lowestLeagueKpiData.gameplanPercent.max.value;
            } else {
                mtd = kpiData.percentageCompleted.value;
                forecast = mtd * ForecastMultiplier.ofCycle(cycle._id);
                bankTopDecile = bankKpiData.percentageCompleted.topDecile;
                lowestLeagueBest = lowestLeagueKpiData.percentageCompleted.max.value;
            }
            let lowestLeagueText = 'Best in ' + user.leagues[0].teamTypeName;
            leagueAnalytics.forEach(function (leagueAnalytic) {
                var kpiData = _.find(leagueAnalytic.kpiData, {kpiId: kpi._id});
                if (cycle.cycleType == 'weekly' && self.config.showGameplan) {
                    dropDown.push({
                        label: leagueAnalytic.teamTypeName + ' Avg.',
                        value: $filter('number')(kpiData.gameplanPercent.avg, 1) + '%'
                    });
                } else {
                    dropDown.push({
                        label: leagueAnalytic.teamTypeName + ' Avg.',
                        value: $filter('number')(kpiData.percentageCompleted.avg, 1) + '%'
                    });
                }
            });
            return {
                bankKpiData, mtd, forecast, bankTopDecile, lowestLeagueBest, dropDown, lowestLeagueText
            }
        }
     

        this.getPreviousCycles = (cycle) => {
            var cycles = [];
            if (cycle.previousCycleId) {
                var cycle1 = _.find(p0.allCycles, {_id: cycle.previousCycleId._id});
                if (cycle1 && cycle1.previousCycleId) {
                    cycles.push(cycle1);
                    var cycle2 = _.find(p0.allCycles, {_id: cycle1.previousCycleId._id});
                    if (cycle2 && cycle2.previousCycleId) {
                        cycles.push(cycle2);
                        var cycle3 = _.find(p0.allCycles, {_id: cycle2.previousCycleId._id});
                        if (cycle3) cycles.push(cycle3);
                    }
                }
            }
            return cycles;            
        }

        this.setCard1KpiData = (cycle, cycles, kpi, kpiData, card1Analytics, forecastMultiplier) => {
            let card1 = {
                heading1: 'Achievement',
                data: []
            };
            var label = cycle.name + ' (F)';
            var value = $filter('number')(kpiData.percentageCompleted.value * forecastMultiplier, 1) + '%';
            if (!self.config.showForecast) {
                label = cycle.name;
                value = $filter('number')(kpiData.percentageCompleted.value, 1) + '%';
            }
            card1.data.push({
                label: label,
                value: value,
                graphColor: 'blue-1'
            });
            cycles.forEach(function (currentCycle) {
                var currentAnalytics = _.find(card1Analytics, {cycleId: currentCycle._id});
                var currentKpiData = _.find(currentAnalytics.kpiData, {kpiId: kpi._id});

                card1.data.push({
                    label: currentCycle.name,
                    value: $filter('number')(currentKpiData.percentageCompleted.value, 1) + '%'
                });
            })
            return card1;
        }

        this.getCard2Heading = (cycle, kpi) => {
            let card2Heading1 = cycle.cycleType == 'weekly' ? 'WTD' : 'MTD';
            let plan = '(' + kpi.unit + ', % of Plan)';
            let target = '(' + (kpi.unit ? kpi.unit + ',' : '') + ' % of target)';
            let card2Heading2 = cycle.cycleType == 'weekly' && self.config.showGameplan ? plan : target;
            return {
                card2Heading1,
                card2Heading2
            }
        }

        this.getCard2Information = (cycle, leagueAnalytics, user, kpi) => {
            let bankTopDecile,lowestLeagueText,lowestLeagueBest, dropDown = [];
            if(cycle.cycleType == 'weekly' && self.config.showGameplan){
                bankTopDecile = leagueAnalytics[leagueAnalytics.length - 1].gameplanPercent.topDecile;
                lowestLeagueBest = leagueAnalytics[0].gameplanPercent.max.value;
            }else {
                bankTopDecile = leagueAnalytics[leagueAnalytics.length - 1].percentageCompleted.topDecile;
                lowestLeagueBest = leagueAnalytics[0].percentageCompleted.max.value;
            }
            lowestLeagueText = 'Best in '+ user.leagues[0].teamTypeName;
            leagueAnalytics.forEach(function(leagueAnalytic){
                if(cycle.cycleType == 'weekly'&& self.config.showGameplan){
                    dropDown.push({
                        label: leagueAnalytic.teamTypeName + ' Avg.',
                        value: $filter('number')(leagueAnalytic.gameplanPercent.avg, 1) + '%'
                    });
                }else{
                    dropDown.push({
                        label: leagueAnalytic.teamTypeName + ' Avg.',
                        value: $filter('number')(leagueAnalytic.percentageCompleted.avg, 1) + '%'
                    });
                }
            });
            return {
                bankTopDecile, lowestLeagueText, lowestLeagueBest, dropDown
            }
        }

        this.getCard3Information = (cycle, data) => {
            var ppText,delta;
            if(cycle.cycleType == 'weekly' && self.config.showGameplan){
                delta = data.gameplanPercent.delta;
            }else {
                delta = data.percentageCompleted.delta;
            }
            if(delta < 0){
                ppText = 'Down ' + $filter('number')(-delta,1) + '% vs pp';
            }else{
                ppText = 'Up ' + $filter('number')(delta,1) + '% vs pp';
            }
            return {
                ppText, 
                delta
            }
        }

        this.getCard1Information = (cycle, userAnalytic) => {
            let mtd,forecast;
            
            if(cycle.cycleType == 'weekly' && self.config.showGameplan){
                mtd = userAnalytic.gameplanPercent.value;
                forecast = mtd * $rootScope.weeklyForecastMultiplier;
            }else{
                mtd = userAnalytic.percentageCompleted.value;
                forecast = mtd * ForecastMultiplier.ofCycle(cycle._id);
            }
            return {
                mtd,
                forecast
            }            
        }

        this.setCard3Data = ({ppText, delta, bankTopDecile, lowestLeagueText, lowestLeagueBest, dropDown, card2Heading1, card2Heading2, forecast}) => {
            if (self.config.showForecast) {
                var card3 = {
                    heading1: 'Forecast',
                    heading2: card2Heading2,
                    data: [
                        {
                            label: 'Me',
                            value: $filter('number')(forecast, 1) + '%',
                            pp: ppText,
                            icon: delta > 0 ? 'fa-arrow-up' : 'fa-arrow-down',
                            labelColor: 'blue-1',
                            graphColor: delta > 0 ? 'green' : 'red'
                        },
                        {
                            label: 'Bank Top 10%',
                            value: $filter('number')(bankTopDecile, 1) + '%'
                        },
                        {
                            label: lowestLeagueText,
                            value: $filter('number')(lowestLeagueBest, 1) + '%'
                        },
                        {
                            selection: dropDown
                        }
                    ]
                };
            }
            return card3;
        }

        this.setCard2Data = ({bankTopDecile, lowestLeagueText, lowestLeagueBest, dropDown, card2Heading1, card2Heading2, mtd}) => {
            let card2 = {
                heading1: card2Heading1,
                heading2: card2Heading2,
                data: [
                    {
                        label: 'Me',
                        value: $filter('number')(mtd, 1) + '%',
                        labelColor: 'blue-1',
                        graphColor: 'blue-1'
                    },
                    {
                        label: 'Bank Top 10%',
                        value: $filter('number')(bankTopDecile, 1) + '%'
                    },
                    {
                        label: lowestLeagueText,
                        value: $filter('number')(lowestLeagueBest, 1) + '%'
                    },
                    {
                        selection: dropDown
                    }
                ]
            };
            return card2;            
        }

        this.setCard1OverallData = (userAnalytic, cycle, card1Info) => {
            let mtdText = cycle.cycleType == 'weekly' ? 'WTD Overall' : 'MTD Overall';
            let card1 = {
                heading1:mtdText,
                heading2:'(Average)',
                percentage:$filter('number')(card1Info.mtd,1) + '%',
                data:[]
            };
            userAnalytic.kpiData.forEach(function(kpiData){
                if(kpiData.target.value > 0){
                    var percentage;
                    if(cycle.cycleType == 'weekly' && self.config.showGameplan){
                        percentage = kpiData.gameplanPercent.value;
                    }else{
                        percentage = kpiData.percentageCompleted.value;
                    }
                    card1.data.push({
                        label:kpiData.name,
                        value:$filter('number')(percentage,1) + '%'
                    });
                }
            });
            return card1;
        }
    });

