angular.module('bitnudgeApp')
    .controller('dataDictionaryModalCtrl', function ($scope, $mdDialog) {
        $scope.dictionary = [
                {key:'inst1', name:'Steps to setup BitNudge application (Configuration files upload flow)', steps:[
                    {header:'AdminMenuItems', desc:'Upload list of all admin users actions.'},
                    {header:'AdminRolePermissions', desc:'Upload list of admin user roles and role-wise permissions.'},
                    {header:'Admin Users', desc:'Upload list of admin users.'},
                    {header:'Configurations', desc:'Upload list of application configuration parameters.'},
                    {header:'Strings', desc:'Upload list of client specific terminologies.'},
                    {header:'Quotes', desc:'Upload list of quotes.'},
                    {header:'Security questions', desc:'Upload list of security questions user for password recovery.'},
                    {header:'Monthly cycle types', desc:'Upload list of different monthly cycle periods.'},
                    {header:'Product', desc:'Upload list of products selling.'},
                    {header:'JobRoles', desc:'Upload list of organization job roles hierarchy.'},
                    {header:"KPI's", desc:"Upload list of KPI's."},
                    {header:'Play types', desc:'Upload list of play types.'},
                    {header:'Activities', desc:'Upload list of activities.'},
                    {header:'Divisions', desc:'Upload list of divisions.'},
                    {header:'First two month cycles', desc:'Upload list of all first two monthly cycles.'},
                    {header:'KAI and KPI points multipliers', desc:'Points engine - Upload list of KAI and KPI points multiplies jobrole-wise.'},
                    {header:'KAI and KPI points weightages', desc:'Points engine - Upload list of KAI and KPI weightages jobrole-wise.'},
                    {header:'Employees', desc:'Upload list of employees.'},
                    {header:'Badges', desc:'Gamification engine - Upload list of badges.'},
                    {header:'Levels', desc:'Gamification engine - Upload list of levels.'},
                    {header:'Formulas', desc:'Upload list of scorecard formulae which are defined job role wise.'}
                ]},
                {key:'inst2', name:'Metrics files uploads', steps:[
                    {header:'Targets', desc:'Frequency - Once a month - Manual'},
                    {header:'Scorecard metrics',desc:'Frequency - Daily - Auto'},
                    {header:'Leads',desc:'Frequency - Daily - Auto'},
                    {header:'Tickets',desc:'Frequency - Daily - Auto'},
                    {header:'Portfolio',desc:'Frequency - Daily - Auto'},
                    {header:'Employees',desc:'Frequency - Anytime - Manual'}
                ]}];
        $scope.closeModal = function() {
            $mdDialog.hide();
        };
    });