'use strict';
angular.module('bitnudgeApp')
    .controller('badgeAchieversModalController', function ($scope,$rootScope, DTOptionsBuilder, achieversList, badgeName, $uibModalInstance, social,Auth) {
        
        var init = (function(){
            this.currentUser = Auth.getCurrentUser();
            $scope.achieversList = achieversList;
            $scope.socialAction = social.socialAction;
            $scope.heading = badgeName;
        }).bind(this);

        $scope.onClose = function() {
            $uibModalInstance.close();
        };

        $scope.isPlayerLiked = (function(player){
            var liked = _.find(player.user.likes, (like) => {
                return like.likerId.toString() == this.currentUser._id.toString();
            });
            if(liked){
                return "light-blue";
            }
            else {
                return "black";
            }
        }).bind(this);
        
        $scope.badgeNameMap = {
            "#1": "Top",
            "Top 10%": "Top Decile",
            "Top 25%": "Top Quatile",
            "150% inc.":"150% increase",
            "100% inc.":"100% increase",
            "50% inc.":"50% increase",
            "level 5": "Level Five",
            "level 4": "Level Four",
            "level 3": "Level Three",
            "platinum":"Platinum",
            "gold":"Gold",
            "silver":"Silver",
            "Red Flag":"Red Flag"
        }

        $scope.dtOptionsForAchieversList = DTOptionsBuilder.newOptions()
            .withPaginationType('simple')
            .withOption('displayLength', 5)
            .withOption('lengthChange', false)
            .withOption('paging', true)
            .withOption('searching', true)
            .withOption('ordering', false)
        //    .withOption('info', true);

        init();
    });
