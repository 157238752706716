const autoImportNgTemplateLoaderTemplate1 = require('./../html/component/historyCardComponent.html');

"use strict";
angular.module("bitnudgeApp").component("historyCardComponent", {
	templateUrl: autoImportNgTemplateLoaderTemplate1,
	bindings: {
		history: "<"
	},
	controller: "historyCardComponentCtrl"
});
