'use strict';
angular.module('bitnudgeApp')
    .service('performanceIndicatorService', function ($http ,Auth, userAnalyticsService, p0,$rootScope,$filter, ForecastMultiplier, Accuracy, $q, UtilService, Sidebar) {
        let self = this;
        self.filterKaiBenchmarksForUser = function (userAnalytic, multiplier) {
            var benchmarkValues = [];
            if(userAnalytic){
                _.forEach(userAnalytic.kpiData, function (kpi) {
                    if(kpi.target.value == 0) return;
                    benchmarkValues[kpi.kpiId] = {};
                    var userActivities = _.filter(userAnalytic.activityData, {kpiId:kpi.kpiId, important:true});
                    userActivities.forEach(function(activity){
                        benchmarkValues[kpi.kpiId][activity.fieldName] = $filter('number')(activity.value.value * multiplier,0);
                    });
                });
            }
            return $q.resolve(benchmarkValues);
        };

        this.fetchKpiBenchmark = function (userId, cycle, benchmark ) {
            if(benchmark._id == 'prevCycle'){
                return userAnalyticsService.fetchUserAnalytics(userId, cycle.previousCycleId._id).then( function (userAnalytic) {
                    var benchmarkValues = [];
                    if(userAnalytic){
                        _.forEach(userAnalytic.kpiData, function (kpi) {
                            if(kpi.target.value == 0) return;
                            var userKpi = _.find(userAnalytic.kpiData, {kpiId:kpi.kpiId});
                            benchmarkValues[kpi.kpiId] = {value:userKpi.percentageCompleted.value};
                        });
                    }
                    return benchmarkValues;
                });
            }else if(benchmark._id == 'forecast'){
                return userAnalyticsService.fetchUserAnalytics(userId, cycle._id).then( function (userAnalytic) {
                    var benchmarkValues = [];
                    _.forEach(userAnalytic.kpiData, function (kpi) {
                        if(kpi.target.value == 0) return;
                        var userKpi = _.find(userAnalytic.kpiData, {kpiId:kpi.kpiId});
                        benchmarkValues[kpi.kpiId] = {value:userKpi.percentageCompleted.value * ForecastMultiplier.ofCycle(userAnalytic.cycleId)};
                    });
                    return benchmarkValues;
                });
            }else if(benchmark._id == 'ytd'){
                return $http.post('/api/userAnalytics/deepDiveData', {userId, cycleId: cycle._id}).then(function (userAnalytic) {
                    userAnalytic=userAnalytic.data;
                    var benchmarkValues = {};
                    _.forEach(userAnalytic.kpiData, function (kpi) {
                        if(kpi.target.value == 0) return;
                        var userKpi = _.find(userAnalytic.kpiData, {kpiId:kpi.kpiId});
                        benchmarkValues[kpi.kpiId] = {value:userKpi.ytdBestPercentage};
                    });
                    return benchmarkValues;
                });
            }else if(benchmark._id == 'custom'){
                //TODO: Now its cached in user analytics service, caching is required?
                return userAnalyticsService.fetchUserAnalytics(benchmark.userId, cycle._id).then( function (userAnalytic) {
                    var benchmarkValues = [];
                    _.forEach(userAnalytic.kpiData, function (kpi) {
                        if(kpi.target.value == 0) return;
                        var userKpi = _.find(userAnalytic.kpiData, {kpiId:kpi.kpiId});
                        benchmarkValues[kpi.kpiId] = {value:userKpi.percentageCompleted.value};
                    });
                    return benchmarkValues;
                });
            }else{
                //TODO: Get the use kpiData from league analytics
                return userAnalyticsService.fetchUserAnalytics(userId, cycle._id).then( function (userAnalytic) {
                    return $http.post('/api/leagueAnalytics/kpiBenchmark', {leagueId: benchmark._id, cycleId: cycle._id}).then(function (leagueBenchmark) {
                        leagueBenchmark=leagueBenchmark.data;
                        var benchmarkValues = {};
                        _.forEach(userAnalytic.kpiData, function (kpi) {
                            if(kpi.target.value == 0) return;
                            var leagueKpi = _.find(leagueBenchmark.kpiData, {kpiId:kpi.kpiId});
                            if(leagueKpi && leagueKpi.percentageCompleted) benchmarkValues[kpi.kpiId] = {value:leagueKpi.percentageCompleted.avg};
                        });
                        return benchmarkValues;
                    });
                });
            }
        };

        this.fetchKaiBenchmark = function (userId, cycle, benchmark) {
            if(benchmark._id == 'prevCycle'){
                return userAnalyticsService.fetchUserAnalytics(userId, cycle.previousCycleId._id).then( function (userAnalytic) {
                    return self.filterKaiBenchmarksForUser(userAnalytic, 1);
                });
            }else if(benchmark._id == 'forecast'){
                return userAnalyticsService.fetchUserAnalytics(userId, cycle._id).then( function (userAnalytic) {
                    return self.filterKaiBenchmarksForUser(userAnalytic, ForecastMultiplier.ofCycle(userAnalytic.cycleId));
                });
            }else if(benchmark._id == 'ytd'){
                return $http.post('/api/userAnalytics/deepDiveData', {userId, cycleId: cycle._id}).then(function (userAnalytic) {
                    userAnalytic=userAnalytic.data;
                    var benchmarkValues = {};
                    _.forEach(userAnalytic.kpiData, function (kpi) {
                        if(kpi.target.value == 0) return;
                        benchmarkValues[kpi.kpiId] = {};
                        var activityData = _.filter(userAnalytic.activityData,{kpiId:kpi.kpiId,important:true});
                        activityData.forEach(function(activityData){
                            benchmarkValues[kpi.kpiId][activityData.fieldName] = $filter('number')(activityData.ytdBest,0);
                        });
                    });
                    return benchmarkValues;
                });
            }else if(benchmark._id == 'custom'){
                //TODO: Now its cached in user analytics service, caching is required?
                return userAnalyticsService.fetchUserAnalytics(benchmark.userId, cycle._id).then( function (userAnalytic) {
                    return self.filterKaiBenchmarksForUser(userAnalytic, 1);
                });
            }else{
                return $http.post('/api/leagueAnalytics/kaiBenchmark',{leagueId: benchmark._id, cycleId: cycle._id}).then(function (leagueBenchmarks) {
                    leagueBenchmarks=leagueBenchmarks.data;
                    return userAnalyticsService.fetchUserAnalytics(userId, cycle._id).then( function (userAnalytic) {
                        var benchmarkValues = {};
                        _.forEach(userAnalytic.kpiData, function (kpi) {
                            if(kpi.target.value == 0) return;
                            benchmarkValues[kpi.kpiId] = {};
                            p0.allActivities.forEach(function(activity){
                                if(activity.important){
                                    var bm = _.find(leagueBenchmarks, {kpiId:kpi.kpiId, fieldName:activity.fieldName});
                                    if(bm) {
                                        var val = 0;
                                        if(activity.activityType == 'value'){
                                            val = $filter('number')(bm.perFTE.avg * userAnalytic.leafCount,0);
                                        }else{
                                            val = $filter('number')(bm.value.avg,0);
                                        }
                                        benchmarkValues[kpi.kpiId][activity.fieldName] = val;
                                    }
                                }
                            });
                        });
                        return benchmarkValues;
                    });
                });
            }
        };

        this.fetchAchieved = function (userId, cycleId,myKpis,level,myPlayTypes,allJobRoles) {
            return userAnalyticsService.fetchUserAnalytics(userId, cycleId).then( function (userAnalytic) {
                    var achieved = [];
                    _.forEach(userAnalytic.kpiData, function (kpi) {
                        let currKpi = _.find(myKpis,{_id:kpi.kpiId});
                        if(!currKpi) return;
                        let currProduct = _.find(p0.myProducts, {mainKpi: currKpi._id});
                        let userKpi = _.find(userAnalytic.kpiData, {kpiId:kpi.kpiId});
                        // if (kpi.kpiCode == 'K00' && userKpi.multAchievedValue) {
                            // if (myPlayTypes.length > 0 && myPlayTypes[0].campaignActivities && self.checkLowestSeniorManager(allJobRoles,level))  {
                            //     var campaignActiviesString = myPlayTypes[0].campaignActivities[0];
                            //     campaignActiviesString = campaignActiviesString.split(",")
                            //     var campaignActivities = [];
                            //     userAnalytic.activityData.forEach(function (activityData) {
                            //         if (campaignActiviesString.indexOf(activityData.fieldName) != -1 && !activityData.kpiCode) {
                            //             campaignActivities.push(activityData)
                            //         }
                            //     })

                            //     var overallScore = userKpi.percentageCompleted.value / 100;
                            //     userAnalytic.kpiData.forEach(function (useranalyticKpiData) {
                            //         if (useranalyticKpiData.kpiCode == "K00") {
                            //             campaignActivities.forEach(function (campaignActivity) {
                            //                 var campaignActivityValue = campaignActivity.value.value;
                            //                 overallScore += (overallScore * campaignActivityValue);
                            //             })
                            //         }
                            //     })
                            // userKpi.percentageCompleted.value = userKpi.multAchievedValue.value;
                            // userKpi.percentageCompletedForecast ={value: userKpi.multAchievedValueForecast.value};
                            // }
                        // }

                        var achievedObj = {
                            name:userKpi.name, swot:userKpi.swot,
                            kpiCode:kpi.kpiCode,
                            kpiId:kpi.kpiId,
                            kpiSequence:currKpi.sequence,
                            achievedValue:userKpi.achievedValue,
                            percentageCompleted:userKpi.percentageCompleted,
                            achievedValueForecast:userKpi.achievedValueForecast,
                            avgAchievedValueForecast:userKpi.avgAchievedValueForecast,
                            denom:userKpi.denom,
                            unit:userKpi.unit,
                            points:userKpi.points.value,
                            multAchievedValue: userKpi.multAchievedValue,
                            multAchievedValueForecast: userKpi.multAchievedValueForecast,
                            avgMultAchievedValue: userKpi.avgMultAchievedValue,
                            target:userKpi.target
                        };
                        if(currKpi.uiLevel){
                            var achievedObj = {
                                name:userKpi.name, swot:userKpi.swot,
                                kpiCode:kpi.kpiCode,
                                kpiId:kpi.kpiId,
                                kpiSequence:currKpi.sequence,
                                achievedValue:userKpi.achievedValue,
                                percentageCompleted:userKpi.percentageCompleted,
                                achievedValueForecast:userKpi.achievedValueForecast,
                                avgAchievedValueForecast:userKpi.avgAchievedValueForecast,
                                denom:userKpi.denom,
								                unit:userKpi.unit,
                                points:userKpi.points.value,
                                multAchievedValue: userKpi.multAchievedValue,
                                multAchievedValueForecast: userKpi.multAchievedValueForecast,
                                avgMultAchievedValue: userKpi.avgMultAchievedValue,
                                productId: currProduct ? currProduct._id : "",
                                target:userKpi.target
                            };
                            if(p0.config.uploadPeriod == 'day' && kpi.kpiCode !== 'K00'){
                                achievedObj.perDay = $filter('number')(userKpi.achievedValue.value / ($rootScope.daysPassed * userKpi.denom), 0);
                            }
                            achieved.push(achievedObj);
                        }
                        if(currProduct && currProduct.activities){
                            currProduct.activities.forEach( activityId => {
                                let activity = _.find(userAnalytic.activityData, {activityId });
                                const achievedObj = {
                                    name:activity.name,
                                    activityId:activityId,
                                    swot:activity.swot,
                                    kpiCode:kpi.kpiCode,
                                    activityCode: activity.activityCode,
                                    kpiId:kpi.kpiId,
                                    kpiSequence:currKpi.sequence,
                                    achievedValue:activity.achievedValue,
                                    percentageCompleted: {
                                        value:activity.target.value ? (activity.achievedValue.value / activity.target.value)*100 : 0
                                    },
                                    achievedValueForecast:kpi.achievedValueForecast,
                                    avgAchievedValueForecast:kpi.avgAchievedValueForecast,
                                    denom:activity.denom,
                                    unit:activity.unit,
                                    points:activity.points.value,
                                    multAchievedValue: activity.multAchievedValue,
                                    multAchievedValueForecast: activity.multAchievedValueForecast,
                                    avgMultAchievedValue: activity.avgMultAchievedValue,
                                    parentProductId: currProduct._id,
                                    target:activity.target
                                };
                                if(p0.config.uploadPeriod == 'day' && kpi.kpiCode !== 'K00'){
                                    achievedObj.perDay = $filter('number')(activity.achievedValue.value / ($rootScope.daysPassed * activity.denom), 0);
                                }
                                achieved.push(achievedObj);
                            })
                        }
                    });
                    return achieved;
                });

        };

        self.checkLowestSeniorManager=function(allJobRoles, userLevel) {
            var seniorManagerJobRoles = allJobRoles.filter(function (jobRole) {
                if (jobRole.roleType == "seniorManager") {
                    return true;
                }
                else {
                    return false;
                }
            })
            seniorManagerJobRoles = _.orderBy(seniorManagerJobRoles, ['level'], ['asc']);
            var lowestLevel = seniorManagerJobRoles[0].level;
            if (userLevel <= lowestLevel) {
                return true;
            }
            else {
                return false;
            }
        }
        self.fetchUserAnalytic=function(userId, cycleId) {
            const postBody = {
                cycleId,
                userId
            }
            return $http.post('/api/userAnalytics/getUserAnalytic', postBody).then(function(result) {
                result=result.data;
                //analytics[cycleId][userId] = result;
                return result;
            });
        };

        this.fetchBasicActivity = function (userId, cycle,activities) {
            var returnObj=[]
            return fetchUserAnalytic(userId, cycle._id).then(function (userAnalytic) {
                userAnalytic.kpiData.forEach(function(kpiData){
                    if (kpiData.kpiCode == "K01") {
                        userAnalytic.activityData.forEach(function(activityData){
                            if(activities.includes(activityData.fieldName)&&!activityData.kpiId)
                            {
                                if(!kpiData.activities)
                                {
                                    kpiData.activities=[]
                                }
                                kpiData.activities.push(activityData)
                            }
                        })
                        returnObj.push(kpiData)
                    }
                    else {
                        returnObj.push(kpiData)
                    }
                })
                return returnObj;
            });
        };
        // TODO: This is a legacy code and might need refactoring for ES6 conventions
        this.populateActivityData = function(leads, userAnalytic, productKpiMap, myKpis) {
            var activity = [];
            _.forEach(userAnalytic.kpiData, function (kpi) {
                if (!_.find(myKpis,{_id:kpi.kpiId})) return;
                if (!productKpiMap[kpi.kpiId]) return;
                var userKpi = _.find(userAnalytic.kpiData, {kpiId:kpi.kpiId});
                var userActivities = _.filter(userAnalytic.activityData, {kpiId:kpi.kpiId});
                let activityData = {
                    name:userKpi.name,
                    kpiCode:userKpi.kpiCode,
                    swot:userKpi.swot,
                    kpiId:kpi.kpiId,
                    points:userKpi.points.value, percentageCompleted:userKpi.percentageCompleted.value
                };
                var call = _.find(userActivities, {fieldName:'call'});
                var submission = _.find(userActivities, {fieldName:'submission'});
                var meeting = _.find(userActivities, {fieldName:'meeting'});
                call ? activityData['call'] = {value: call.value.value, last: call.lastAction} : '';
                submission ? activityData['submission'] = {value: submission.value.value, last: submission.lastAction} : '';
                meeting ? activityData['meeting'] = {value: meeting.value.value, last: meeting.lastAction} : '';
                var activeLeads = _.find(userActivities, {fieldName:'activeLeads'});
                var lostLeads = _.find(userActivities, {fieldName:'lostLeads'});
                var newLeads =_.find(userActivities, {fieldName:'newLead'});
                activeLeads ? activityData['activeLeadCount'] = {value:activeLeads.value.value, last:self.getMinutesAgo()} : '';
                lostLeads ? activityData['lostLeadCount'] = {value: lostLeads.value.value, last:self.getMinutesAgo()} : '';
                newLeads ? activityData['newLeadsCount'] = {value:newLeads.value.value, last:self.getMinutesAgo()} : '';
                let productKey = productKpiMap[kpi.kpiId]
                if (leads && leads[productKey]) {
                    activityData.followUpToday = leads[productKey].followUpToday;
                    activityData.followUpLost = leads[productKey].followUpMissed;
                    activityData['activeLeadCount'] = {value: leads[productKey].active, last:self.getMinutesAgo()}
                    activityData['lostLeadCount'] = {value: leads[productKey].leadsLost, last:self.getMinutesAgo()}
                    activityData.leadsWon = leads[productKey].leadsWon;
                } else {
                    activityData.followUpToday = 0;
                    activityData.followUpLost = 0;
                    activityData['activeLeadCount'] = {value: 0, last:self.getMinutesAgo()}
                    activityData['lostLeadCount'] = {value: 0, last:self.getMinutesAgo()}
                    activityData.leadsWon = 0;
                }
                
                activityData['documentShared'] = _.find(userActivities, {fieldName:'documentShared'});
                activity.push(activityData);
            });
            try {
                activity.sort(function (a, b) {
                    if (a.kpiCode < b.kpiCode) return -1;
                    if (a.kpiCode > b.kpiCode) return 1;
                    return 0;
                })
            }
            catch (err) {
                console.log("activity cards sorting failed with error" + err)
            }
            return activity;
        }

        this.fetchActivity = function (userId, cycle, myKpis) {
            myKpis = myKpis.filter(k => k.kpiCode !== p0.config.agentOnBoardingProductKpi)
            return self.fetchUserAnalytic(userId, cycle._id).then(function (userAnalytic) {
                var myProducts = p0.myProducts;
                var kpiIds = _.map(myProducts, 'mainKpi');
                var productKpiMap = {}
                for (let kpi of kpiIds) {
                    productKpiMap[kpi] = _.find(myProducts, {mainKpi:kpi})._id
                }
                return $http.post('/api/leads/activityIndicatorsNumbers', {userId, cycleId:cycle._id, kpiIds}).then(function(leads){
                    leads=leads.data.data;
                    return self.populateActivityData(leads, userAnalytic, productKpiMap, myKpis);
                }, function(err) {
                    const leads={};
                    return self.populateActivityData(leads, userAnalytic, productKpiMap, myKpis);
                });
            });
        };

        this.fetchNonLmsActivity = function(userId,cycle, myKpis){
            var daysPassed;
            if(cycle.cycleType == 'monthly'){
                daysPassed = $rootScope.daysPassed;
            }else if(cycle.cycleType == 'weekly'){
                daysPassed = $rootScope.weeklyDaysPassed;
            }else{
                daysPassed = 1;
            }
            return userAnalyticsService.fetchUserAnalytics(userId,cycle._id).then(function(userAnalytic){
                var returnData = [];
                p0.myKpis.forEach(function(kpi){
                    if(!_.find(myKpis,{_id:kpi._id})) return;
                    var activityData = {};
                    var kpiData = _.find(userAnalytic.kpiData,{kpiId:kpi._id});
                    if(kpiData.target.value == 0) return;
                    var fieldNames = p0.config.activityIndicator;
                    var displayNames = {call:'Calls made:', newLead:'Leads generated:', submission:'Submissions:'};

                    var activities = [], ratios = [];
                    fieldNames.forEach(function (fieldName) {
                        var activity = _.find(p0.allActivities, {fieldName: fieldName});
                        var activity = _.find(userAnalytic.activityData,{fieldName:fieldName,kpiId:kpi._id});
                        var rateText = '/ day';
                        if(fieldName == 'submission') rateText = '/ week';
                        if(activity && activity.activityType == 'value'){
                            var activityObj = {};
                            activityObj.title = displayNames.fieldName?displayNames.fieldName:activity.name;
                            activityObj.unit = activity.unit;
                            activityObj.value = activity.value.value;
                            if(p0.config.uploadPeriod == 'month'){
                                activityObj.rate = activityObj.value/daysPassed;
                                activityObj.rateText=  rateText;
                            }
                            activityObj.fieldName = fieldName;
                            if(activityObj.fieldName == 'volumes' || activityObj.fieldName == 'referrals'){
                                var rate = activityObj.value/daysPassed;
                                activityObj.toolTip = $filter('number')(rate, 2) + " per day";
                            }
                            activities.push(activityObj);
                        }else if(activity && activity.activityType == 'percentage'){
                            var ratioObj = {};
                            ratioObj.name = activity.name;
                            ratioObj.unit = activity.unit;
                            ratioObj.value = activity.value.value * 100;
                            ratioObj.fieldName = fieldName;
                            if(ratioObj.fieldName == 'accuracyp'){
                                var errCount = Accuracy.getErrorCount(fieldName, userAnalytic, kpi._id);
                                if(errCount){
                                    ratioObj.toolTip = "Error count "+ $filter('number')(errCount, 0);
                                }
                            }
                            ratios.push(ratioObj);
                        }
                    });

                    if(activities.length) activityData.activities = activities;
                    if(ratios.length) activityData.ratios = ratios;
                    activityData.name = kpi.name;
                    activityData.kpiId = kpi._id;
                    activityData.swot = kpiData.swot;
                    returnData.push(activityData);
                });
                return returnData;
            });
        };

        this.fetchLeagues = function (userId, cycleId) {
            return userAnalyticsService.fetchUserAnalytics(userId, cycleId).then(function (userAnalytic) {
                return userAnalytic ? userAnalytic.leagues : [];
            });
        };


        this.fetchKPIDataForCycle=function(userId,cycleId)
        {
            const postBody = {
                cycleId,
                userId
            }
             return $http.post('/api/userAnalytics/getUserAnalytic', postBody).then(function(useranalytic) {
                useranalytic=useranalytic.data;
                //analytics[cycleId][userId] = result;
                return useranalytic.kpiData;
            });
        }

        this.fetchOverallScoreCard=function(userId,cycleId,jobRoleId,metric,subMetric,playType, activityId, isDownloadExcel, isMeOwn, modalHeading)
        {
            let postBody = {
                userId,
                cycleId,
                jobRoleId,
                metric,
                subMetric,
                playType,
                download: isDownloadExcel
            }
            if(isDownloadExcel){
                let url = Sidebar.getAppUrl();
                url += '/api/userAnalytics/fetchScoreCard';
                const params = {
                    cycleId,
                    userId,
                    jobRoleId,
                    metric,
                    subMetric,
                    playType,
                    download:true
                }
                if(activityId){
                    params.activityId = activityId;
                    params.subProductLevel = true;
                }
                if (isMeOwn) {
                    params.isMeOwn = isMeOwn;
                }
                if (modalHeading) {
                    params.modalHeading = modalHeading;
                }
                const deferred = $q.defer();
                UtilService.downloadFile(url, params)
                deferred.resolve()
                return deferred.promise;
            }else{
                let url = '/api/userAnalytics/fetchScoreCard';
                if(activityId){
                    postBody['activityId'] = activityId;
                    postBody['subProductLevel'] = true;
                }
                return $http.post(url, postBody).then(function(scorecard) {
                    scorecard=scorecard.data;
                    //analytics[cycleId][userId] = result;
                    return scorecard;
                })
            }

        }

        this.fetchOverallScoreCardForUsers=function(userId,cycleId,jobRoleId,metric,subMetric,playType, activityId)
        {
            const url = '/api/userAnalytics/fetchOverallScoreCardForUsers';
            const params = {
                cycleId,
                jobRoleId,
                metric,
                subMetric,
                playType
            };
            if(activityId){
                params.activityId = activityId;
                params.subProductLevel = true;
            }

            return $http.post(url, params).then(function(scorecard) {
                scorecard=scorecard.data;
                //analytics[cycleId][userId] = result;
                return scorecard;
            })
            .catch(function (error, status){
                return error;
          });
        }

        this.fetchOverallScoreCardForUsersMonths=function(userId,cycleId,jobRoleId,metric,subMetric,numMonths,playType)
        {   
            const postBody = {
                cycleId,jobRoleId,metric,subMetric,playType,numMonths
            }
             return $http.post('/api/userAnalytics/fetchOverallScoreCardForUsersMonths', postBody).then(function(scorecard) {
                scorecard=scorecard.data;
                //analytics[cycleId][userId] = result;
                return scorecard;
            });
        }

        this.fetchOverallKPIForFormula=function(userId,cycleId,jobRoleId,metric,subMetric)
        {   
             const postBody =  {
                cycleId,
                userId,
                jobRoleId,
                metric,
                subMetric
             }
             return $http.post('/api/userAnalytics/fetchOverallKPIFormula', postBody).then(function(kpis) {
                kpis=kpis.data;
                //analytics[cycleId][userId] = result;
                return kpis;
            });
        }

        this.fetchJobroleId = function (userId, cycleId) {
            return userAnalyticsService.fetchUserAnalytics(userId, cycleId).then( function (userAnalytic) {
                return userAnalytic.role;
            });
        };

        self.getMinutesAgo=function() {
            return Math.floor((Math.random() * 50) + 1) + ' m ago';
        }

        this.destroy = function(){

        };
    });
