'use strict';

angular.module('bitnudgeApp').controller('ticketUploadCtrl', function($scope, Upload, Auth, parameters, $mdDialog, toaster, Tickets, Sidebar, UtilService) {
    var user = Tickets.getCurrentUser();
    $scope.headname = parameters.modalName;
    $scope.me = Auth.getCurrentUser()
    $scope.url = parameters.url;
    var successMessage = '';
    if ($scope.url == '/api/tickets/import/excel') {
        successMessage = "We will notify you when tickets are processed.";
    } else if ($scope.url == '/api/tickets/importCallSheet/ticket/') {
        successMessage = "Ticket creation successfully finished.";
    }

    $scope.getFormat = function() {
        const appUrl = Sidebar.getAppUrl();
        const baseUrl = appUrl + '/api/tickets/getUploadExcelFormat';

        UtilService.downloadFile(baseUrl);
        $scope.cancel();
    }

    $scope.getGlossary = function() {
        const appUrl = Sidebar.getAppUrl();
        const baseUrl = appUrl + '/api/glossary';
        UtilService.downloadFile(baseUrl, {type:'ticket'});
        return $scope.cancel();
    };

    $scope.getInstructions = function() {
        const appUrl = Sidebar.getAppUrl();
        const baseUrl = appUrl + '/api/tickets/getInstructions';

        UtilService.downloadFile(baseUrl);
        return $scope.cancel();
    }

    $scope.onFileUpload = function(file, url) {
        var $file = file;
        var fileName = $file.name.split(".");
        var fileType = fileName[fileName.length - 1].toLowerCase();
        if (fileType == "xlsx" || fileType == "xls" || fileType == "xlsm") {
            Upload.upload({
                url: $scope.url,
                data: {
                    cronTag: 'tckt',
                    invocationMethod: 'manual',
                    dataType: 'tickets',
                    userId: $scope.me._id,
                    asOfDate: new Date().getTime()
                },
                responsetype: 'application/json',
                file: $file,
                progress: function(e) {}
            }).then(function(result) {
                result = result.data;
                toaster.pop('success', "Excel uploaded!!", result.message);
                var timestamp = new Date();
                timestamp = timestamp.toString().substring(4, 14)
                    //toaster.pop('success', "Excel uploaded", successMessage);
                    // if(result.exceptions != undefined) {
                    //     if(result.operations.length == 0)
                    //     toaster.pop('warning', "Excel uploaded!!", 'There were '+result.exceptionsCount+' \"Exceptions\" and '+result.successCount+' \"Successful\" operations.!!');
                    //     else
                    //     toaster.pop('failure', "Excel uploaded!!", 'There were '+result.exceptionsCount+' \"Exceptions\" and '+result.successCount+' \"Successful\" operations.!!');
                    //     if($scope.me.roleType != 'lmsAdmin') {
                    //         var file = ngXlsx.writeXlsx([result.exceptions]);
                    //         var date = new Date()
                    //         saveAs(new Blob([s2ab(file)],{type:"application/octet-stream"}), timestamp+"-Tickets-Errors.xlsx");
                    //     }
                    // } else {
                    //     toaster.pop('success', "Excel uploaded!!", 'No exceptions in the operation!!');
                    // }
                $scope.hide();
            }).catch(function(res) {
                if (res.status == 310) {
                    toaster.pop('warning', "Excel could not be uploaded!!", 'Sorry!! Headers in the excel file are not appropriate!!');
                }
                if (!res.data.canBeTriggered) {
                    toaster.pop('error', "Excel could not be uploaded!!", res.data.message);
                }
                $scope.hide();
            })
        } else {
            toaster.pop('error', "Submission.", "Upload '.xlsx' or '.xls' file only.");
            $scope.hide();
        }
    };

    $scope.hide = function() {
        $mdDialog.hide();
    };

    $scope.cancel = function() {
        $mdDialog.cancel();
    };

    $scope.answer = function(answer) {
        $mdDialog.hide(answer);
    };
});