const autoImportNgTemplateLoaderTemplate1 = require('../html/gpModal.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/legendInfoModal.html');

'use strict';

angular.module('bitnudgeApp')
    .service('getGPService',function ($http, $q, $rootScope, SwotService, $mdDialog, ForecastMultiplier, p0, benchmark, numberFilter) {
        var _this = this;
        //TODO: get icons from activities file
        var gpActivities = [{fieldName:'call', icon:'fa fa-phone'},
            {fieldName:'submission', icon: 'fa fa-paper-plane'},
            {fieldName:'newLead', icon: 'fa fa-user-plus'},
            {fieldName:'meeting', icon: 'fa fa-handshake-o'},
            {fieldName:'throughput'}];
        var importantActivities = [];
        gpActivities.forEach(function(activity){
            var activity = _.find(p0.myActivities,{fieldName:activity.fieldName});
            if(activity) { importantActivities.push(activity.fieldName);}
        });
        this.getImportantActivities = function () {
            var importantActivities = [];
            gpActivities.forEach(function(activity){
                var activity = _.find(p0.myActivities,{fieldName:activity.fieldName});
                if(activity) {
                    var isRate = false;
                    var rateText ='(per day)';
                    if(activity.fieldName == 'submission'){rateText = '(per week)';}
                    if(activity.fieldName == 'throughput'){isRate = true;}
                    importantActivities.push({_id:activity._id, fieldName:activity.fieldName, isRate:isRate,
                        name:activity.names[p0.myKpis[0]._id]+' ('+activity.unit+')',
                        rateText: rateText, icon: activity.icon
                    });
                }
            });
           importantActivities.forEach(function(activity){
                var activity = _.find(p0.myActivities,{fieldName:activity.fieldName});
                if(!activity){return;}
                activity.name = activity.names[p0.myKpis[0]._id]+' ('+activity.unit+')';
            });
            return importantActivities;
        };
        var obj={};
        var kpiData,selectedKPI;
        var initKpis = function(cycleId) {
            for (var i = 0; i < kpiData.length; i++) {
                kpiData[i].seq = i;

                kpiData[i].percentageCompleted.value = parseFloat(kpiData[i].percentageCompleted.value.toFixed(0));

                if(kpiData[i].bankAveragePercentage) {
                    kpiData[i].bankAveragePercentage = kpiData[i].bankAveragePercentage.toFixed(2);
                }
                kpiData[i].managerPercentage = ((kpiData[i].managerValue / kpiData[i].target.value) * 100).toFixed(2);

                if (kpiData[i].previousPeriodPercentage)
                    kpiData[i].previousPeriodPercentage = (kpiData[i].previousPeriodPercentage).toFixed(2);

                if (kpiData[i].regionAveragePercentage)
                    kpiData[i].regionAveragePercentage = kpiData[i].regionAveragePercentage.toFixed(2);

                if (kpiData[i].branchAveragePercentage)
                    kpiData[i].branchAveragePercentage = kpiData[i].branchAveragePercentage.toFixed(2);

                if (kpiData[i].teamAveragePercentage)
                    kpiData[i].teamAveragePercentage = kpiData[i].teamAveragePercentage.toFixed(2);
                setCurrentRunRateOfKPI(kpiData[i]);
                setTargetRunRateOfKPI(kpiData[i]);
                setProjectedTotalLeftOfKPI(kpiData[i], cycleId);
                setGamePlanRunRateOfKPI(kpiData[i]);

                if (!kpiData[i].gameplanTarget || kpiData[i].gameplanTarget.value == undefined) {
                    kpiData[i].gameplanTarget= {value : 0};
                }
                kpiData[i].gameplanPercentage = (kpiData[i].gameplanTarget.value / kpiData[i].target.value) * 100;
                if(!kpiData[i].gameplanPercentage || isNaN(kpiData[i].gameplanPercentage)){
                    kpiData[i].gameplanPercentage = 0;
                }
                importantActivities.forEach(function(activity){
                    if(!kpiData[i][activity]){
                        return;
                    }
                    if(p0.config.targetPeriod == 'month'){
                        kpiData[i][activity].rate = parseFloat(kpiData[i][activity].value / $rootScope.daysPassed).toFixed(2);
                        kpiData[i][activity].topDecileRate =  parseFloat(kpiData[i][activity].topDecile / $rootScope.daysPassed).toFixed(2);
                        kpiData[i][activity].req = _this.getTargetActivitiesForMonthType(kpiData[i].kpiId, kpiData[i].target.value - kpiData[i].achievedValue.value ,kpiData[i].activityConversionRatios[activity].value,kpiData[i].averageTicketSize.value);
                        kpiData[i][activity].reqPlan = _this.getTargetActivitiesForMonthType(kpiData[i].kpiId, kpiData[i].gameplanTarget.value,kpiData[i].activityConversionRatios[activity].value,kpiData[i].averageTicketSize.value);
                        kpiData[i][activity].reqRate  = parseFloat(kpiData[i][activity].req  / $rootScope.daysTotal).toFixed(2);
                        kpiData[i][activity].reqRatePlan = kpiData[i][activity].reqPlan?parseFloat(kpiData[i][activity].reqPlan / $rootScope.daysTotal).toFixed(2) : 0;
                    }else{
                        setReqPlanTargetHelper('monthly',kpiData[i], activity);
                    }
                });
                var submissionIndex = importantActivities.indexOf('submission');
                if(submissionIndex != -1 && kpiData[i][importantActivities[submissionIndex]]){
                    kpiData[i][importantActivities[submissionIndex]].rate = kpiData[i][importantActivities[submissionIndex]].rate * 5;
                    kpiData[i][importantActivities[submissionIndex]].topDecileRate = kpiData[i][importantActivities[submissionIndex]].topDecileRate * 5;
                    kpiData[i][importantActivities[submissionIndex]].reqRate = kpiData[i][importantActivities[submissionIndex]].reqRate * 5;
                    kpiData[i][importantActivities[submissionIndex]].reqRatePlan = kpiData[i][importantActivities[submissionIndex]].reqRatePlan * 5;
                }
                initConversions(kpiData[i]);
            }
        };

        this.getTargetThroughputRate = function (cycleType, kpiData, isThroughputForTarget) {
            var standardTransactions = _.find(p0.allKpis, {_id:kpiData.kpiId}).transactionsPerHour;
            var targetPercentage = kpiData.gameplanPercentage;
            if(isThroughputForTarget){
                targetPercentage = 100;
            }
            var daysTotal, daysPassed, daysLeft;
            if(cycleType == 'monthly'){
                daysTotal = $rootScope.daysTotal; daysPassed = $rootScope.daysPassed; daysLeft = $rootScope.daysLeft;
            }else{
                daysTotal = $rootScope.weeklyDays; daysPassed = $rootScope.weeklyDaysPassed; daysLeft = $rootScope.weeklyDaysLeft;
            }
            if(isNaN(targetPercentage) || targetPercentage == 0 || daysLeft == 0){
                return 0;
            }
            var percentageReqToAchieveGoal = (targetPercentage * daysTotal - daysPassed * kpiData.percentageCompleted.value)/daysLeft;
            if(percentageReqToAchieveGoal < 0){
                return 0;
            }else{
                return standardTransactions * percentageReqToAchieveGoal/100;
            }
        };

        this.getRateIndicator = function (activity, selectedKpi, cycleId) {
            var selectedActivity = activity.fieldName;
            var isRate = activity.isRate;
            var divisior = 1, activityRate = selectedKpi[selectedActivity].value, callSubRateColor, callSubText;
            if(!isRate){
                divisior = $rootScope.daysPassed;
                activityRate = selectedKpi[selectedActivity].rate;
            }
            if(selectedKpi[selectedActivity]) {
                return benchmark.activity('topDecile','bank',selectedActivity,selectedKpi.kpiId, cycleId).then(function(topDecileActivity){
                    var topDecileActivityRate = topDecileActivity/divisior;
                    if (activityRate >= topDecileActivityRate) {
                        callSubRateColor = 'green';
                    } else if (activityRate >= topDecileActivityRate * 0.8) {
                        callSubRateColor = 'amber';
                    } else {
                        callSubRateColor = 'red';
                    }
                    callSubText = '<p>Your current rate is: ' + numberFilter(activityRate, 0) + '<br />Top decile rate is: ' + numberFilter(topDecileActivityRate, 0) + '</p>';
                    return $q.resolve([callSubRateColor, callSubText]);
                });
            }
        };



        var setCurrentRunRateOfKPI = function(kpi){
            if(kpi.kpiType != 'percentage')
                kpi.currentRunRate = (kpi.achievedValue.value/$rootScope.daysPassed*5).toFixed(2);
            else
                kpi.currentRunRate = kpi.achievedValue.value;
        };
        var setTargetRunRateOfKPI = function(kpi){
            if($rootScope.daysLeft && kpi.kpiType != 'percentage') {
                kpi.targetRunRate = (((kpi.target.value - kpi.achievedValue.value) / $rootScope.daysLeft) * 5);
                kpi.targetRunRate = kpi.targetRunRate < 0 ? 0 : (kpi.targetRunRate).toFixed(2);
            }
            else if(kpi.kpiType == 'percentage'){
                kpi.targetRunRate = kpi.target.value;
            }
            else{
                kpi.targetRunRate = '-'
            }
        };
        var setProjectedTotalLeftOfKPI = function(kpi, cycleId){
            var forecastMultiplier = ForecastMultiplier.ofCycle(cycleId);
            var projected = parseFloat((kpi.percentageCompleted.value * forecastMultiplier).toFixed(2));
            kpi.projectedTotalLeft = parseFloat((projected - kpi.percentageCompleted.value).toFixed(2));
            if(kpi.benchmarks) {
                kpi.benchmarks.forEach(function (bm) {
                    bm.projectedPercentage = parseFloat((bm.averagePercentage * forecastMultiplier).toFixed(2));
                    bm.projectedCallValue = parseFloat((bm.callValue * forecastMultiplier).toFixed(2));
                    bm.projectedSubmissionValue = parseFloat((bm.submissionValue * forecastMultiplier).toFixed(2));
                });
            }
        };
        var setDefaultGamePlan = function(kpi){
            kpi.gameplanTarget.value = parseFloat(((kpi.percentageCompleted.value+kpi.projectedTotalLeft)*kpi.target.value)/(kpi.denom)/100).toFixed(2);
            kpi.gameplanPercentage = kpi.percentageCompleted.value+kpi.projectedTotalLeft;
        };
        var initConversions = function(kpi) {
            kpi.target.value = parseFloat((kpi.target.value/kpi.denom));
            if(kpi.gameplanTarget.value !=undefined){
                kpi.gameplanTarget.value = (kpi.gameplanTarget.value/kpi.denom).toFixed(2);
                kpi.gameplanPercentage = (100 * kpi.gameplanTarget.value / kpi.target.value).toFixed(0);
                if(!kpi.gameplanPercentage || isNaN(kpi.gameplanPercentage)){
                    kpi.gameplanPercentage = 0;
                }
            }
            kpi.achievedValue.value = parseFloat((kpi.achievedValue.value/kpi.denom).toFixed(2));
            if(kpi.managerValue)
                kpi.managerValue = parseFloat((kpi.managerValue/kpi.denom).toFixed(2));
            else{
                kpi.managerValue =  0;
                kpi.managerPercentage = 0;
            }
        };
        var setGamePlanRunRateOfKPI = function(kpi){
            if(kpi.kpiType == 'percentage'){
                var accurateApplicationsCompleted = kpi.n;
                var volumeProcessed = kpi.d;
                var volumeProcessRate = volumeProcessed / $rootScope.daysPassed;
                var volumeToBeProcessed = volumeProcessRate * $rootScope.daysLeft;
                var volumesTotal = volumeProcessed + volumeToBeProcessed;
                var requiredCorrectApplications = (kpi.newGpValue * volumesTotal/ 100) - accurateApplicationsCompleted;
                var requiredCorrectApplicationsRate = volumeToBeProcessed != 0 ? (requiredCorrectApplications / volumeToBeProcessed) * 100 : 0;
                kpi.gameplanRunRate = requiredCorrectApplicationsRate;
            }
            else {
                kpi.gameplanRunRate = '-';
            }
        };


        this.getTargetActivitiesForMonthType = function(kpiId, target,conversionRatio, ticketSize){
            target = Math.max(target,0);
            var targetActivities = 0;
            //Set default conversion ratio if conversion ratio is zero
            if(conversionRatio == 0){
                conversionRatio = 0.01;
            }
            if(!ticketSize){
                var kpi = _.find(p0.allKpis, {_id:kpiId});
                if(kpi){
                    ticketSize = kpi.averageTicketSize;
                }
            }
            if($rootScope.config.lms) {
                targetActivities = target / (conversionRatio * ticketSize);
            }else{
                targetActivities = (target / conversionRatio);

            }
            if(isNaN(targetActivities) || targetActivities == Infinity || targetActivities == -Infinity){
                targetActivities = 0;
            }
            return Math.floor(targetActivities);
        };

        this.reCalcPlan = function(goal, isReqEntirePeriod){
            const importantActivities = this.getImportantActivities()
            importantActivities.forEach(function(activity){
                if(goal[activity.fieldName]) {
                    if(p0.config.targetPeriod == 'month'){
                        if (goal.dateRange) { // check if weekly
                            let target, days;
                            if (isReqEntirePeriod) {
                                target = goal.newGpValue * goal.denom;
                                days = 5;
                            } else {
                                target = (goal.newGpValue - goal.achievedValue.value) * goal.denom;
                                days = $rootScope.weeklyDaysLeft;
                            }
                            goal[activity.fieldName].req = _this.getTargetActivitiesForMonthType(goal.kpiId, target, goal.activityConversionRatios[activity.fieldName].value, goal.averageTicketSize.value);
                            goal[activity.fieldName].reqRate = days ? parseFloat(goal[activity.fieldName].req / days).toFixed(2) : 0;

                        } else {
                            let target,days;
                            if(isReqEntirePeriod){
                                target = goal.newGpValue * goal.denom;
                                days = $rootScope.daysTotal;
                            }else{
                                target = (goal.newGpValue  - goal.achievedValue.value) * goal.denom;
                                days = $rootScope.daysLeft;
                            }
                            goal[activity.fieldName].reqPlan = _this.getTargetActivitiesForMonthType(goal.kpiId, target, goal.activityConversionRatios[activity.fieldName].value, goal.averageTicketSize.value);
                            goal[activity.fieldName].reqRatePlan = parseFloat(goal[activity.fieldName].reqPlan /days).toFixed(2);
                        }
                    } else{
                        goal[activity.fieldName].reqPlan = _this.getTargetThroughputRate('monthly', goal, false);
                        if(goal['accuracyp'].value ==0 || goal['occupancy'].value == 0){
                            goal[activity.fieldName].reqPlanActual = 0;
                        }else{
                            goal[activity.fieldName].reqPlanActual = goal[activity.fieldName].reqPlan / goal['accuracyp'].value / goal['occupancy'].value;
                        }
                    }
                }
            });
            const submissionIndex = importantActivities.indexOf('submission');
            if(submissionIndex != -1 && goal[importantActivities[submissionIndex].fieldName]) {
                if (goal.dateRange) { // check if weekly
                    goal[importantActivities[submissionIndex].fieldName].reqRate = goal[importantActivities[submissionIndex].fieldName].reqRate * 5;
                }else{
                    goal[importantActivities[submissionIndex].fieldName].reqRatePlan = goal[importantActivities[submissionIndex].fieldName].reqRatePlan * 5;
                }
            }
            return goal;
        }

        this.getGoal=  function (userId, selectedCycle, hasTargetLimit) {
            var deferred = $q.defer();
            var gpUrl = '/api/gamePlans/getGamePlan/cycles/user';
             $http.post(gpUrl, {cycleId:selectedCycle._id, userId}).then(function (gameplan) {
                gameplan=gameplan.data;
                if (p0.config.agentOnBoardingProductKpi) gameplan = gameplan.filter(row => row.kpiCode !== p0.config.agentOnBoardingProductKpi)
                kpiData = gameplan;
                initKpis(selectedCycle._id);
                if(hasTargetLimit){
                    kpiData = _.filter(kpiData, function (kpi) {
                        return kpi.target.value > 0;
                    });
                }
                deferred.resolve(kpiData);
            });
            return deferred.promise;
        };

        this.getDailyGoal = function (kpiId) {
            var deferred = $q.defer();
            var gpUrl = '/api/gamePlans/daily';
             $http.post(gpUrl, {kpiId}).then(function (dailyPlan) {
                dailyPlan=dailyPlan.data;
                deferred.resolve(dailyPlan);
            });
            return deferred.promise;
        };

        this.initGoalsForReportee = function(userId,reporteeKpiData, cycleId){
            kpiData = reporteeKpiData;
            initKpis(cycleId);
            return kpiData;
        };
        this.showModals = function(selectedPlayer,selectedKPI, type){
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'gamePlanModalCtrl',
                size: 'lg',
                resolve: {
                    selectedPlayer: function () {
                        return selectedPlayer;
                    },
                    selectedKPI: function () {
                        return selectedKPI;
                    },
                    type : function () {
                        return type;
                    }
                }
            });
        };
        this.showLegendModal = function(){
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate2,
                controller: 'legendInfoModalCtrl',
                size: 'md'});
        };
        this.getGamePlanRunRateOfKPI=  function(kpi){
            setGamePlanRunRateOfKPI(kpi);
            return kpi.gameplanRunRate;
        };
        this.getImpliedRunRateKpi=  function (kpi) {
            if($rootScope.daysLeft && kpi.kpiType != 'percentage') {
                if(kpi.kpiCode == 'K01'){
                    var correctToVolProcessRate = kpi.volumesProcessed / kpi.achievedValue.value;
                    var requiredCorrectApplications = kpi.newGpValue - kpi.achievedValue.value;
                    var requiredVolToProcess = requiredCorrectApplications / correctToVolProcessRate;
                    return  $rootScope.daysLeft < 5 ? requiredVolToProcess : requiredVolToProcess / $rootScope.daysLeft * 5;
                }
                return (((kpi.newGpValue- kpi.achievedValue.value) / $rootScope.daysLeft) * 5).toFixed(2);
            }
            else if(kpi.kpiType == 'percentage'){
                var accurateApplicationsCompleted = kpi.n;
                var volumeProcessed = kpi.d;
                var volumeProcessRate = volumeProcessed / $rootScope.daysPassed;
                var volumeToBeProcessed = volumeProcessRate * $rootScope.daysLeft;
                var volumesTotal = volumeProcessed + volumeToBeProcessed;
                var requiredCorrectApplications = (kpi.newGpValue * volumesTotal / 100 ) - accurateApplicationsCompleted;
                requiredCorrectApplications = $rootScope.daysLeft < 5 ? requiredCorrectApplications : requiredCorrectApplications * 5 /  $rootScope.daysLeft;
                return requiredCorrectApplications;
            }
        };

        this.getWeeklyGoals = function(userId, kpiId,cycleId,activityConversionRatios,averageTicketSize){
            return $http.post('/api/gamePlans/weeks', {cycleId, kpiId, userId}).then(function(results){
                results=results.data;
                var weeks =  results;
                var editable = false;
                weeks= _.sortBy(weeks, function(week) { return week.index; });
                var kpi = _.find(p0.allKpis, {_id:kpiId});
                var activities = _this.getImportantActivities();
                var kpiActivities = _.filter(activities, function (activity) {
                    return kpi.activities.indexOf(activity._id) != -1;
                });
                kpiActivities = _.map(kpiActivities, 'fieldName');
                var submissionIndex = kpiActivities.indexOf('submission');
                for(var i = 0; i< weeks.length;i++){
                    var dateRange= formatDate(weeks[i].startDate)+" " +"," +" " +formatDate(weeks[i].endDate);
                    weeks[i].dateRange=dateRange;
                    weeks[i].isEditable=editable;
                    editable = false;
                    kpiActivities.forEach(function(activity){
                        if(weeks[i][activity]) {
                            weeks[i][activity].rate = weeks[i][activity].value;
                        }
                        if(!weeks[i].activeWeek) {
                            weeks[i][activity].value = weeks[i][activity].value * 5;
                        }else{
                            weeks[i][activity].value = weeks[i][activity].value * $rootScope.lastUpdatedAtDate.getDay();
                        }
                    });
                    if(weeks[i].activeWeek){
                        editable = true;
                        if(submissionIndex != -1 && weeks[i][kpiActivities[submissionIndex]]) {
                            weeks[i][kpiActivities[submissionIndex]].value = weeks[i][kpiActivities[submissionIndex]].value * 5; // weekly rate
                        }
                        weeks[i].projectedTotalLeft = weeks[i].percentageCompleted.value * $rootScope.weeklyForecastMultiplier;
                    }else{
                        weeks[i].projectedTotalLeft = 0;

                    }
                    weeks[i].gameplanPercentage = weeks[i].target.value == 0 ? 0 :(weeks[i].gameplanTarget.value / weeks[i].target.value * 100);
                    if(!weeks[i].gameplanPercentage || isNaN(weeks[i].gameplanPercentage)){
                        weeks[i].gameplanPercentage = 0;
                    }
                    if(weeks[i].gameplanTarget.value != undefined) {
                        weeks[i].gameplanTarget.value = parseFloat((weeks[i].gameplanTarget.value) / (weeks[i].denom)).toFixed(2);
                        kpiActivities.forEach(function(activity){
                            if(weeks[i][activity]) {
                                if(p0.config.targetPeriod == 'month'){
                                    weeks[i][activity].req = _this.getTargetActivitiesForMonthType(weeks[i].kpiId, weeks[i].gameplanTarget.value * weeks[i].denom - weeks[i].achievedValue.value, activityConversionRatios[activity].value, averageTicketSize);
                                    weeks[i][activity].reqRate = $rootScope.weeklyDaysPassed == 0 ? weeks[i][activity].req : weeks[i][activity].req / $rootScope.weeklyDaysPassed;
                                }else{
                                    setReqPlanTargetHelper('weekly', weeks[i], activity);
                                }
                            }
                        });
                        if(submissionIndex != -1 && weeks[i][kpiActivities[submissionIndex]]) {
                            weeks[i][kpiActivities[submissionIndex]].reqRate = weeks[i][kpiActivities[submissionIndex]].reqRate * 5;    // weekly rate
                        }
                    }
                    weeks[i].target = {value: parseFloat((weeks[i].target.value/weeks[i].denom)).toFixed(2)};
                    weeks[i].achievedValue = {value:parseFloat((weeks[i].achievedValue.value/weeks[i].denom).toFixed(2))};
                    weeks[i].rate = parseFloat((weeks[i].rate/weeks[i].denom).toFixed(2));
                    weeks[i].reqRate = parseFloat((weeks[i].reqRate /weeks[i].denom).toFixed(2));
                    weeks[i].req = parseFloat((weeks[i].req /weeks[i].denom).toFixed(2));
                    weeks[i].compliance =0;
                    weeks[i].name = weeks[i].cycleName;
                    if(weeks[i].gameplanTarget !=0 && weeks[i].gameplanTarget !=undefined)
                        weeks[i].compliance = parseFloat(weeks[i].achievedValue.value/weeks[i].gameplanTarget.value).toFixed(2);
                }
                return $q.resolve(weeks);
            })
        };

        var setReqPlanTargetHelper = function (cycleType, kpi, activity) {
            kpi[activity].req = _this.getTargetThroughputRate(cycleType, kpi, true);
            kpi[activity].reqPlan = _this.getTargetThroughputRate(cycleType, kpi, false);
            return; // TODO : handle hardcoded activities
            if(kpi['accuracyp'].value==0 || kpi['occupancy'].value == 0){
                kpi[activity].reqActual = 0;
                kpi[activity].reqPlanActual = 0;
            }else{
                kpi[activity].reqActual = kpi[activity].req / kpi['accuracyp'].value / kpi['occupancy'].value;
                kpi[activity].reqPlanActual = kpi[activity].reqPlan / kpi['accuracyp'].value / kpi['occupancy'].value;
            }
            kpi[activity].accuracy = kpi['accuracyp'].value * 100;
            kpi[activity].occupancy = kpi['occupancy'].value * 100;
        };

        var formatDate = function(date,type){
            var Months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
            var newDate = new Date(date);
            if(type=="comment")
                var reqDate = newDate.getDate()+"-"+Months[newDate.getMonth()]+"-"+newDate.getFullYear();
            else
                var reqDate = newDate.getDate()+"-"+Months[newDate.getMonth()];
            return reqDate;
        };
    });
