'use strict';

angular.module('bitnudgeApp').controller('leadExportCtrl', function ($scope, $mdDialog, parameters, p0, UtilService) {
    var appUrl = p0.config.domain;
    var subfolder = p0.config.subfolder;
    $scope.format = p0.config.defaultLeadExportFormat || 'rhbFormat';
    if (subfolder) {
        if (appUrl[appUrl.length - 1] !== '/') {
            appUrl += '/';
        }
        if (subfolder[0] == '/') {
            subfolder = subfolder.slice(1);
        }
        appUrl += subfolder;
    }
    $scope.isPipelineContext = parameters.pipelineContext
    $scope.exportLeads = function (isAllLeads, format) {
        format = 'rhbFormat';
        let baseUrl = appUrl + '/api/leads/exportUserLeads';
        const params = { category: parameters.category }
        if (isAllLeads) {
            params.tab = parameters.view;
            params.userId = parameters.userId.toString();
            params.format = format;
            UtilService.downloadFile(baseUrl, params);
            $scope.cancel();
        } else {
            let filterObj = parameters.filterObj;
            let extraParams = parameters.extraParams;
            let sortingObj = parameters.sortingObj;
            params.tab = parameters.view;
            if (sortingObj && !_.isUndefined(sortingObj)) {
                params.sortKey = sortingObj.sortKey;
                params.sortOrder = sortingObj.sortOrder;
            }
            if (extraParams.leadId) {
                $scope.cancel();
                params.format = format;
                return UtilService.downloadFile(baseUrl, { leadId: extraParams.leadId.toString() })
            }
            if (extraParams.customerId) {
                params.customerId = extraParams.customerId.toString();
            }
            if (parameters.userId) {
                params.userId = parameters.userId.toString();
            }
            if (extraParams.currentMonthlyCycle) {
                params.startDate = new Date(extraParams.currentMonthlyCycle.startDate).getTime();
                params.endDate = new Date(extraParams.currentMonthlyCycle.endDate).getTime();
            }
            if (extraParams.selectedCycle) {
                params.dateFilterStartDate = new Date(extraParams.selectedCycle.startDate).getTime();
                params.dateFilterEndDate = new Date(extraParams.selectedCycle.endDate).getTime();
            }
            if (extraParams.productId) {
                params.productId = extraParams.productId.toString();
            } else if (filterObj.productId) {
                params.productId = filterObj.productId.toString();
            }
            if (filterObj.searchParam) {
                params.searchParam = filterObj.searchParam;
            }
            if (filterObj.leadStatusKey) {
                params.leadStatusKey = filterObj.leadStatusKey;
                if (filterObj.leadStatusKey === 'status') {
                    params.status = filterObj.status;
                }
            }
            if (filterObj.colorCode) {
                params.colorCode = filterObj.colorCode.stageUid
            }
            if (filterObj.actNowFilterKey) {
                params.actNowKey = filterObj.actNowFilterKey;
            }
            if (filterObj.leadDateKey) {
                params.leadDateKey = filterObj.leadDateKey;
            }
            if (filterObj.leadTypeKey) {
                params.leadTypeKey = filterObj.leadTypeKey;
            }
            if (filterObj.stageCode) {
                params.stageCode = filterObj.stageCode.stageUid
            }
            if (filterObj.campaignCode) {
                params.campaignCode = filterObj.campaignCode
            }
            if (filterObj.customerObj && Object.keys(filterObj.customerObj).length) {
                const customer = {};
                if (filterObj.customerObj.age) {
                    customer.ageFrom = filterObj.customerObj.age.from;
                    customer.ageTo = filterObj.customerObj.age.to;
                }
                if (filterObj.customerObj.dnc) {
                    customer.dnc = filterObj.customerObj.dnc._id
                }
                if (filterObj.customerObj.marital) {
                    customer.maritalStatus = filterObj.customerObj.marital._id
                }

                params.customerObj = JSON.stringify(customer);
            }
            params.format = format;
            UtilService.downloadFile(baseUrl, params);
            $scope.cancel();
        }

    };
    $scope.cancel = function () {
        $mdDialog.cancel();
    };

});

