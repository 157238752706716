'use strict';

angular.module('bitnudgeApp')
    .service('userAnalyticsService', function ($http, $q) {
        var analytics = {};
        this._fetchUserAnalytics = function (userId, cycleId) {
            if (!analytics[cycleId][userId]) {
                const postBody = {
                    cycleId,
                    userId
                }
                return $http.post('/api/userAnalytics/getUserAnalytic', postBody).then(function(result) {
                        result=result.data;
                        analytics[cycleId][userId] = result;
                        return analytics[cycleId][userId];
                    });
            }else{
                return $q.resolve(analytics[cycleId][userId]);
            }
        };
        this.getSingleActivityInUserAnalytic = function(cycleId){
            var deferred = $q.defer();
            $http.post('/api/userAnalytics/getSingleActivityInUserAnalytic',{cycleId}).then(function(result) {
                if(result.data.status)
                    deferred.resolve(result.data.data);
                else
                    deferred.reject(result.data.errors[0])
            },function(err){
                deferred.reject(err)
            });
            return deferred.promise;
        };
        this.fetchUserAnalytics = function (userId, cycleId) {
            if(!analytics[cycleId]) analytics[cycleId] = {};
            return this._fetchUserAnalytics(userId, cycleId).then(function (analytic) {
                return analytic;
            });
        };
        //
        this.fetchUserAnalyticsOfCycle = function(userIds,cycleId){
            if(!analytics[cycleId]) analytics[cycleId] = {};
            var toFetch = _.filter(userIds,function(userId){
                return !analytics[cycleId][userId];
            });
            if(toFetch.length) {
                return $http.post('/api/userAnalytics/getArrayUserAnalytics/userIds',{cycleId, userIds: toFetch}).then(function (userAnalytics) {
                    userAnalytics=userAnalytics.data;
                    userAnalytics.forEach(function (userAnalytic) {
                        analytics[cycleId][userAnalytic.userId] = userAnalytic;
                    });
                    var sendArray = [];
                    userIds.forEach(function (userId) {
                        sendArray.push(analytics[cycleId][userId]);
                    });
                    return sendArray;
                });
            }else{
                var sendArray = [];
                userIds.forEach(function (userId) {
                    sendArray.push(analytics[cycleId][userId]);
                });
                return $q.resolve(sendArray);
            }
        };

        this.fetchUserAnalyticsOfCycleForHuddleboard = function(parent, parentId, cycleId, userIds, jobRoles, product){
            return $http.post('/api/userAnalytics/getArrayOfUserAnalyticsForHuddleboard', {
                parent: parent,
                cycleId: cycleId,
                parentId: parentId,
                userIds: userIds,
                jobRoles: jobRoles,
                productId: product ? product._id : null
            }).then(function (response) {
                var userAnalytics = response.data.userAnalytics;
                var analytics = {};
                if(parent == 'seniorManager'){
                    var childrenIds = response.data.userIds;
                }
                userAnalytics.forEach(function (userAnalytic) {
                    if(analytics[cycleId] == undefined){
                        analytics[cycleId] = {};
                    }
                    analytics[cycleId][userAnalytic.userId] = userAnalytic;
                });
                var sendArray = [];
                if(parent == 'manager'){
                    userIds.forEach(function (userId) {
                        sendArray.push(analytics[cycleId][userId]);
                    });
                    sendArray.unshift(analytics[cycleId][parentId])
                } else {
                    childrenIds.forEach(function (userId) {
                        sendArray.push(analytics[cycleId][userId]);
                    });
                }
                return sendArray;
            });
        };

        this.fetchUserAnalyticsOfCycles = function(userId,cycleIds){
            var toFetch = [];
            cycleIds.forEach(function(cycleId){
                if(!analytics[cycleId]) analytics[cycleId] = {};
                if(!analytics[cycleId][userId]){
                    toFetch.push(cycleId);
                }
            });

            if(toFetch.length) {
                return $http.post('/api/userAnalytics/getArrayUserAnalytics/userId', {userId, cycleIds: toFetch}).then(function (userAnalytics) {
                    userAnalytics=userAnalytics.data;
                    userAnalytics.forEach(function (userAnalytic) {
                        if(!analytics[userAnalytic.cycleId]){
                            analytics[userAnalytic.cycleId] = {}
                        }
                        analytics[userAnalytic.cycleId][userAnalytic.userId] = userAnalytic;
                    });
                    var sendArray = [];
                    cycleIds.forEach(function (cycleId) {
                        sendArray.push(analytics[cycleId][userId]);
                    });
                    return sendArray;
                });
            }else{
                var sendArray = [];
                cycleIds.forEach(function (cycleId) {
                    sendArray.push(analytics[cycleId][userId]);
                });
                return  $q.resolve(sendArray);
            }
        };

        this.destroy = function(){
            analytics = {};
        }
    });
