'use strict';
angular.module('bitnudgeApp')
    .controller('TrackController', function ($scope,social,p1) {
    	//alert(1);
    	$scope.$watch('trackData', function(newVal, oldVal){
            $scope.showTrackDetails = false;
    		if(newVal && newVal.leaderboard){
    			$scope.drawImages();
    		}
    			
		    	//console.log('changed',newVal);
		}, true);

        $scope.isPlayerLiked=function(player){
                if(player && player.userId && p1.userData[player.userId._id].liked)
                return "light-blue";
            else
                return "white";
        };
        $scope.isPlayerFollowed=function(player){
            if(player&& player.userId && p1.userData[player.userId._id].followed)
                return "light-blue";
            else
                return "white";
        };

        $scope.hideTrackDetails = function(){
            $scope.showTrackDetails = false;
        }

        $scope.socialAction = social.socialAction;

		$scope.drawImages = function(){
			$(".circle-img").remove();
			var svg = d3.select("svg#trackSvg");
            var trackG = svg.select("g#trackG")
			var radius = [450,400,300,250,200];
            var path = trackG.select("path");
            // var path = svg.select("path");
            var startPoints = pathStartPoint(path);
            var marker = [];
            var defs = [];
            startPoints.forEach(function(el,ind){

                el[0] = el[0] - parseInt(radius[ind]/2);
                el[1] = el[1] - parseInt(radius[ind]/2);

                var textPoint = [el[0]+parseInt(radius[ind]/2),el[1]-5];

            if($scope.trackData.leaderboard[ind]){
    //         	defs[ind] = svg.append('svg:defs').attr("class","circle-img");

				// defs[ind].append(+"svg:pattern")
				//     .attr("id", "image"+ind)
				//     .attr("width", radius[ind])
				//     .attr("height", radius[ind])
				//     .attr("patternUnits", "userSpaceOnUse")
				//     .append("svg:image")
				//     .attr("xlink:href", 'http://placekitten.com/g/48/48')
				//     .attr("width", radius[ind])
				//     .attr("height", radius[ind])
				//     .attr("x", 0)
				//     .attr("y", 0);	
				    
				//  marker[ind] = svg.append("circle")
    //         					.attr("class","circle-img")
    //                             .attr("r",radius[ind])
    //                             .style("fill","url(#image"+ind+")")
    //                             .attr("transform", "translate(" + el + ")")

                // svg.append("g")
                // .attr("class","circle-img")
                // .attr("height",radius[ind])
                // .attr("width",radius[ind])                    
                // .attr("transform", "translate(" + textPoint + ")")
                // .append("text")
                // .text($scope.trackData.leaderboard[ind].rank)    
                // .style("text-anchor","middle"); 

                 
                var g = trackG.append("g");
                g.attr("class","circle-img")
                .attr("index",ind)
                .attr("height",radius[ind])
                .attr("width",radius[ind])                    
                .attr("transform", "translate(" + el + ")")
                // .on("click",function(ev){
                    
                //     console.log("g clicked");
                //     $scope.trackDetails = $scope.trackData.leaderboard[parseInt($(this).attr("index"),10)];
                //     $scope.showTrackDetails = true;
                //     $scope.$apply();
                    
                // })
                // .append("svg:image")
                // .attr('width', radius[ind])
                // .attr('height', radius[ind])
                // .attr("xlink:href",window.location.protocol + "//" + window.location.host + "/" + $scope.trackData.leaderboard[ind].userId.image)

                .append('foreignObject')
                .attr('width', radius[ind])
                .attr('height', radius[ind])
                .attr('x', -10)
                .attr('y', -10) 
                .append('xhtml:div').style('height',radius[ind]).style('width',radius[ind])
                .append('xhtml:object')
                .attr('height',radius[ind]).attr('width',radius[ind])
                .attr('type','image/svg+xml')
                .attr('class','div-image')
                .attr('data',window.location.protocol + "//" + window.location.host + "/" + $scope.trackData.leaderboard[ind].userId.image)
                .append('img').attr('alt','notloaded').attr("style","height:475px")
                .on("click",function(ev){
                    
                    console.log("g clicked");
                    $scope.trackDetails = $scope.trackData.leaderboard[parseInt($(this).attr("index"),10)];
                    $scope.showTrackDetails = true;
                    $scope.$apply();
                    
                });                
            	   
                // transitionAll(g,ind);   
            }	

            });
               
            


            function pathStartPoint(path) {
                var l = path.node().getTotalLength();

                var arrPoints = [];
                var d = path.attr("d");
                var dsplitted = d.split(" ");
                var slug = Math.floor(l/5);
                for(var intIndex=1;intIndex<6;intIndex++){
                    var p = path.node().getPointAtLength(intIndex*slug);
                    arrPoints.push([p.x,p.y]);
                }
                return arrPoints;
              }

            function transitionAll(marker,ind){
  marker.transition()
    .duration(10000).ease("linear")
    .attrTween("transform", translateAlong(path.node(),ind))
    .each("end", partial(transitionAll,marker,ind));// infinite loop*/
}
              
             function translateAlong(path,ind) {
  var l = path.getTotalLength();
  return function(i) {
    return function(t) {
      var p = path.getPointAtLength(((t+ind/5)%1)* l);
      return "translate(" + p.x + "," + p.y + ")";//Move marker
    }
  }
}

function partial(func /*, 0..n args */) {
  var args = Array.prototype.slice.call(arguments, 1);
  return function() {
    var allArguments = args.concat(Array.prototype.slice.call(arguments));
    return func.apply(this, allArguments);
  };
		}
    }

    });