'use strict';

angular.module('bitnudgeApp')
    .controller('deepDiveCtrl', function ($scope, $http, Auth, $rootScope, $stateParams, profile, p0, deepDiveService, ForecastMultiplier, Kpi,$state) {
        var dataArr, kpiActivities=[];
        $scope.config = p0.config;
        $scope.strings = $rootScope.strings;

        var init = function (kpis) {
            $scope.deepDiveTypes = _.cloneDeep(kpis);
            $scope.activities = _.filter(_.cloneDeep(p0.allActivities), function(act){
            var pointsMulti = _.find(act.pointsMultipliers,{role:$scope.selectedJobRole});
                return act.kpiSpecific == true && pointsMulti && pointsMulti.value > 0;
            });
            $scope.myKpiIds = _.map($scope.deepDiveTypes, '_id');
            _.forEach(kpis, function (kpi) {
                kpiActivities = _.filter(p0.allActivities, function (activity) {
                    return kpi.activities.indexOf(activity._id) > -1 && _.find(activity.pointsMultipliers,{role:$scope.selectedJobRole}).value > 0 && activity.isBNActivity && activity.important;
                });
            });
			
            $scope.deepDiveTypes.unshift({_id: 'impact', name: 'Aggregate '+$scope.strings.IMPACT.value});
            $scope.deepDiveTypes.unshift({_id: 'activity', name: 'Aggregate '+$scope.strings.ACTIVITY.value});

            $scope.selectedKpi = _.find($scope.deepDiveTypes, function(kpi) {
                return kpi._id == $stateParams.typeId;
            });

            //Default
            if(!$scope.selectedKpi){
                $scope.selectedKpi = _.find($scope.deepDiveTypes, {_id:'impact'});
            }

            $scope.type = $scope.selectedKpi._id;
            $scope.toggles = {
                absolute: false,
                forecast: false
            };

            $scope.kpiDataCodes = _.map($scope.myKpiIds, function (kpiId) {
                return 'kpiData:' + kpiId;
            });
            
            $scope.activityDataCodes = _.map($scope.myKpiIds, function (kpiId) {
                return 'activityData:' + kpiId;
            });
        };

        function prepareMetricsObject() {
            var selectedMetricData;
            selectedMetricData = _.find($scope.metricConfigData,((data) => {
                    return ((data.kpiId.toString() === $scope.type.toString())
					&& (data.cycleId.toString() === $scope.cycleId.toString()))
            }));
            if(!selectedMetricData){
                selectedMetricData = _.find($scope.metricConfigData,((data) => {
                    return ((data.kpiId.toString() === $scope.type.toString()) 
                    && (data.cycleId.toString() === "default"))
                }))
            }
            if(!selectedMetricData){
                return;
            }
            // var impactMetricKeys;
            // if($scope.kpiDataCodes.length > 1){
            //     impactMetricKeys = ['impact'].concat($scope.kpiDataCodes);
            // }else{
            //     impactMetricKeys = $scope.kpiDataCodes;
            // }
            if ($scope.type == 'overall') {
                // var comparison2MetricKeys = ['activity'];
                // _.forEach(kpiActivities, function (activity) {
                //     comparison2MetricKeys.push('activity:'+activity.fieldName);
                // });
                // dataArr = {
                //     metricGroups: [
                //         {header: 'Primary Comparison', absolute:true, metricKeys: ['overall']},
                //         {header: 'Related Comparison 1', absolute:true, metricKeys: impactMetricKeys},
                //         {header: 'Related Comparison 2', absolute:false, metricKeys: comparison2MetricKeys}
                //     ]
                // }
                dataArr = {metricGroups:selectedMetricData.metricGroups};
            } else if ($scope.type == 'impact') {
                // var kpiMetricKeys = [];
                // _.forEach($scope.myKpiIds, function (kpiId) {
                //     _.forEach(kpiActivities, function (activity) {
                //         kpiMetricKeys.push('activity:'+activity.fieldName+':'+kpiId);
                //     });
                // });

                // dataArr = {
                //     metricGroups: [
                //         {header: 'Primary Comparison', absolute:true, metricKeys: impactMetricKeys},
                //         //{header: 'Related Comparison 1', absolute:true, metricKeys: ['overall']},
                //         {header: 'Related Comparison 1', absolute:false, metricKeys: ['activity'].concat(kpiMetricKeys)}
                //     ]
                // }
                dataArr = {metricGroups:selectedMetricData.metricGroups};
            } else if ($scope.type == 'activity') {
                // var comparison1MetricKeys = ['activity'];
                // _.forEach(kpiActivities, function (activity) {
                //     comparison1MetricKeys.push('activity:'+activity.fieldName);
                // });
                // dataArr = {
                //     metricGroups: [
                //         {header: 'Primary Comparison', absolute:false, metricKeys: comparison1MetricKeys},
                //         //{header: 'Related Comparison 1', absolute:true, metricKeys: ['overall']},
                //         {header: 'Related Comparison 1', absolute:true, metricKeys: ['impact']}
                //     ]
                // }
                dataArr = {metricGroups:selectedMetricData.metricGroups};
            } else {
                // var kpiId = $scope.type;
                // dataArr = {
                //     metricGroups: [
                //         {header: 'Primary Comparison', absolute:true, kpiId: kpiId, metricKeys: ['impact']},
                //     ]
                // };
                // var activities = _.filter($scope.activities,function (activity) {
                //     return _.find(activity.kpiData,{kpiId:kpiId}) && activity.isBNActivity  && activity.important;
                // });
                // _.forEach(activities, function (activity) {
                //     dataArr.metricGroups.push({
                //         header: activity.name,
                //         absolute:false,
                //         kpiId: kpiId,
                //         metricKeys: getMetricKeys(activity.fieldName, activity.activityType, activity.cron)
                //     });
                // });
                // if(p0.config.lms){
                //     dataArr.metricGroups.push({
                //         header: 'Conversion Ratio',
                //         absolute:false,
                //         kpiId: kpiId,
                //         metricKeys: ['conversionRatio', 'conversionRatio:call', 'conversionRatio:meeting']
                //     });
                // }
                selectedMetricData.metricGroups.forEach((data) => {
                    data["kpiId"] = $scope.type;
                })
                dataArr = {metricGroups:selectedMetricData.metricGroups};
            }
        }

        function getMetricKeys(fieldName, type, cron) {
            if (fieldName != 'newLead') {
                if(type == 'percentage'){
                    return [fieldName + ':' + fieldName];
                }else if(!p0.config.lms || !cron){
                    return [fieldName + ':' + fieldName, fieldName + ":" + fieldName + "Rate"];
                }else{
                    return [fieldName + ':' + fieldName, fieldName + ":" + fieldName + "Rate", fieldName + ":" + fieldName + "WinRate"];
                }
            } else {
                return [fieldName + ':' + 'active', fieldName + ":timeToCall", fieldName + ":timeToSubmit"];
            }
        }

        function getPlayerDataSummary(playerData, userAnalytic, callback) {
            var rank = _.find(userAnalytic.points.ranks, {teamType: 'bank'});
            if(rank) rank = rank.value;
            var leagueName = _.find(userAnalytic.leagues,{teamType: 'bank'});
            leagueName = leagueName ? leagueName.teamTypeName : '';
            var summary = {
                userId: playerData._id,
                image: playerData.image,
                name: playerData.name,
                rank: rank,
                statusBadge: "",
                badgeCount: userAnalytic.badges.length,
                swot: {s: [], w: [], o: [], t: []},
                leagueName: leagueName
            };

            if($scope.type != "overall" && $scope.type != 'impact' && $scope.type != 'activity'){
                _.forEach(userAnalytic.activityData, function (activity) {
                    if(activity.kpiId && activity.kpiId == $scope.type){
                        if(activity.swot) summary.swot[activity.swot].push({_id: activity.kpiId, name: activity.name});
                    }
                });
            }else{
                _.forEach(userAnalytic.kpiData, function (kpi) {
                    if (kpi.swot)
                        summary.swot[kpi.swot].push({_id: kpi.kpiId, name: kpi.name});
                });
            }
            return deepDiveService.getStatusBadge(playerData._id).then(function (badge) {
                summary.statusBadge = badge;
                return callback(summary);
            });
        }

        function getBadgeObj(badges, badgeType, badgeCategory) {
            var filteredBadges = _.filter(badges, {badgeType: badgeType, badgeCategory: badgeCategory});
            if (filteredBadges) return filteredBadges;
            else return false;
        }

        function getMetric(userAnalytic, metricKey, kpiId) {
            var metricKeys = metricKey.split(':');
            var key = metricKeys[0], subFilter, subFilter1;
            if (metricKeys[1]) {
                subFilter = metricKeys[1];
            }
            if(metricKeys[2])
                subFilter1 = metricKeys[2]; // kpiId

            var name, points, value, swot, rankObj, rank, badges, kpiData, activity,rankObject;
            if ($scope.type == "overall" || $scope.type == 'impact' || $scope.type == 'activity') {
                switch (key) {
                    case 'overall':
                        name = 'Overall';
                        points = userAnalytics.points ? userAnalytic.points.value : null;
                        value = userAnalytic.percentageCompleted ? userAnalytic.percentageCompleted.value : null;
                        swot = userAnalytic ? userAnalytic.swot : null;
                        badges = getBadgeObj(userAnalytic.badges, 'total', 'overall');
                        rankObject = _.find(userAnalytic.points.ranks, {teamType: 'bank'});
                        rank = rankObject ? rankObject.value : null;
                        break;
                    case 'impact':
                        name = 'Aggregate '+$scope.strings.IMPACT.value;
                        points = userAnalytic.businessPoints ?  userAnalytic.businessPoints.value : null;
                        value = userAnalytic.percentageCompleted ? userAnalytic.percentageCompleted.value : null;
                        swot = userAnalytic ? userAnalytic.swot : null;
                        badges = getBadgeObj(userAnalytic.badges, 'overallBusiness', 'overall');
                        rankObject = _.find(userAnalytic.businessPoints.ranks, {teamType: 'bank'});
                        rank =  rankObject ? rankObject.value : null;
                        break;
                    case 'activity':
                        if(subFilter){
                            if(subFilter1){
                                activity = _.find(userAnalytic.activityData,{kpiId: subFilter1, fieldName: subFilter});
                                if(!activity){
                                    return;
                                }
                                name = activity.kpiName+' '+ activity.name;
                                points = activity.points ? activity.points.value : null;
                                value = activity.value ? activity.value.value : null;
                                swot = activity ? activity.swot : null;
                                badges = activityBadges;
                                rankObj = _.find(activity.points.ranks, {teamType: 'bank'});
                                rank = rankObj ? rankObj.value : null;
                            }else{
                                activity = _.find(userAnalytic.activityData, function (activity) {
                                    return !activity.kpiId && activity.fieldName == subFilter;
                                });
                                if(!activity){
                                    return;
                                }
                                name = $scope.strings.ACTIVITY.value+' '+activity.name;
                                points = activity.points ? activity.points.value : null;
                                value = activity.value ? activity.value.value : null;
                                swot = activity ? activity.swot : null;
                                badges = activityBadges;
                                rankObj = _.find(activity.points.ranks, {teamType: 'bank'});
                                rank = rankObj ? rankObj.value : null;
                            }

                        }else{
                            name = 'Aggregate '+$scope.strings.ACTIVITY.value;
                            points = userAnalytic.activityPoints ? userAnalytic.activityPoints.value : null;
                            value = userAnalytic.activityPoints ? userAnalytic.activityPoints.value : null;
                            swot = 'u'; //Unknown, Not calculated in cron
                            badges = getBadgeObj(userAnalytic.badges, 'overallActivity', 'overall');
                            rankObject = _.find(userAnalytic.activityPoints.ranks, {teamType: 'bank'})
                            rank = rankObject ? rankObject.value : null;
                        }
                        break;
                    case 'kpiData':
                        kpiData = _.find(userAnalytic.kpiData, {kpiId: subFilter});
                        if(!kpiData){
                            return;
                        }
                        name = $scope.strings.IMPACT.value+' ' + kpiData.name;
                        points = kpiData.points ? kpiData.points.value : null;
                        value = kpiData.percentageCompleted ? kpiData.percentageCompleted.value : null;
                        swot = kpiData ? kpiData.swot : null;
                        badges = _.filter(userAnalytic.badges, {from: kpiData.kpiId});
                        rankObj = _.find(kpiData.points.ranks, {teamType: 'bank'});
                        rank = rankObj ? rankObj.value : null;
                        break;
                    case 'activityData':
                        kpiData = _.find(userAnalytic.kpiData, {kpiId: subFilter});
                        if(!kpiData){
                            return;
                        }
                        var activities = _.filter(userAnalytic.activityData, {kpiId: subFilter});
                        if(!activities){
                            return;
                        }
                        name = $scope.strings.ACTIVITY.value+' ' + kpiData.name;
                        points = _.reduce(activities, function (total, activity) {
                            return total + activity.points.value;
                        }, 0);
                        value = _.reduce(activities, function (total, activity) {
                            return total + activity.value.value;
                        }, 0);
                        swot = 'u'; //Unknown, Swot is not calculated for this case
                        badges = getBadgeObj(userAnalytic.badges, 'kpi', 'activity');
                        rank = null;  // rank is not calculated for this case
                        break;
                }
            } else {
                kpiData = _.find(userAnalytic.kpiData, {kpiId: kpiId});
                if(!kpiData){
                    return;
                }
                var kpiBadges = _.filter(userAnalytic.badges, {from: kpiId});
                if(!kpiBadges){
                    return;
                }
                if (key == 'impact') {
                    name = $scope.strings.IMPACT.value+' ' + kpiData.name;
                    points = kpiData.points ? kpiData.points.value : null;
                    value = kpiData.percentageCompleted ? kpiData.percentageCompleted.value : null; 
                    swot = kpiData ? kpiData.swot : null;
                    badges = kpiBadges;
                    rankObj = _.find(kpiData.points.ranks, {teamType: 'bank'});
                    rank = rankObj ? rankObj.value : null;
                }else if(key == 'conversionRatio'){
                    if(subFilter == 'call'){
                        name = 'Call to Meeting';
                        points = kpiData.activityConversionRatios.call.value / kpiData.activityConversionRatios.meeting.value;
                        value = kpiData.activityConversionRatios.call.value / kpiData.activityConversionRatios.meeting.value;
                        swot = 'u'; //Not calculated
                        badges = []; //Not calculated
                        rank = null; //Not calculated
                    }else if(subFilter == 'meeting'){
                        name = 'Meeting to Submission';
                        points = kpiData.activityConversionRatios.meeting.value / kpiData.activityConversionRatios.submission.value;
                        value = kpiData.activityConversionRatios.meeting.value / kpiData.activityConversionRatios.submission.value;
                        swot = 'u'; //Not calculated
                        badges = []; //Not calculated
                        rank = null; //Not calculated
                    }else{
                        name = 'End to End';
                        if(kpiData.stagesData && kpiData.stagesData.length){
                            points = kpiData.stagesData[0].conversionRatioFinal ? kpiData.stagesData[0].conversionRatioFinal.value : null;
                            value = kpiData.stagesData[0].conversionRatioFinal ? kpiData.stagesData[0].conversionRatioFinal.value : null;
                            rankObj = _.find(kpiData.stagesData[0].conversionRatioFinal.ranks, {teamType: 'bank'});
                        }
                        swot = 'u'; //Not calculated
                        badges = []; //Not calculated
                        rank = rankObj ? rankObj.value : null;
                    }
                }
                else if (key == 'call' || key == 'meeting' || key == 'submission') {
                    activity = _.find(userAnalytic.activityData, {kpiId: kpiId, fieldName: key});
                    if(!activity){
                        return;
                    }
                    //Activity badges are only calculated for Calls and Submissions
                    var activityBadges = [];
                    if (key == 'call') {
                        activityBadges = _.filter(kpiBadges, {badgeLabel: 'Calls'});
                    } else if (key == 'submission') {
                        activityBadges = _.filter(kpiBadges, {badgeLabel: 'Submissions'});
                    }

                    if (subFilter == key) {
                        name = 'Volume';
                        points = activity.points ? activity.points.value : null;
                        value = activity.value ? activity.value.value : null;
                        swot = activity ? activity.swot : null;
                        badges = activityBadges;
                        rankObj = _.find(activity.points.ranks, {teamType: 'bank'});
                        rank = rankObj ? rankObj.value : null;
                    } else if (subFilter == key + 'Rate') {
                        name = 'Rate per day';
                        points = activity.points.value / $rootScope.daysPassed;
                        value = activity.value.value / $rootScope.daysPassed;
                        swot = 'u'; //Not calculated
                        badges = []; //Not calculated
                        rank = null; //Not calculated
                    } else {
                        name = 'Win Rate';
                        points = kpiData.activityConversionRatios[key].value;
                        value = kpiData.activityConversionRatios[key].value;
                        swot = 'u'; //Not calculated
                        badges = []; //Not calculated
                        rank = null; //Not calculated
                    }
                } else if (key == 'newLead') {
                    activity = _.find(userAnalytic.activityData, {kpiId: kpiId, fieldName: key});
                    if(!activity){
                        return;
                    }
                    if (subFilter == 'active') {
                        name = 'Active';
                        var activeLeads = _.find(userAnalytic.activityData,{kpiId:kpiId,fieldName:'newLead'});
                        if(!activeLeads){
                            return;
                        }
                        points = activeLeads.points ? activeLeads.points.value : null;
                        value = activeLeads.value ? activeLeads.value.value : null;
                        swot = activity ? activity.swot : null;
                        badges = _.filter(kpiBadges, {badgeLabel: 'Leads'});
                        rankObj = _.find(activeLeads.value.ranks, {teamType: 'bank'});
                        rank = rankObj ? rankObj.value : null;
                    } else if (subFilter == 'timeToCall') {
                        name = 'Avg. time to 1 st Call';
                        points = (kpiData && kpiData.timeToAct && kpiData.timeToAct.call) ? kpiData.timeToAct.call.value : null;
                        swot = 'u'; //Not calculated
                        badges = []; //Not calculated
                        rank = null; //Not calculated
                        // rankObj = _.find(kpiData.timeToAct.call.ranks, {teamType: 'bank'});
                        // rank = rankObj ? rankObj.value : null;
                    } else {
                        name = 'Avg. time to 1 st Submit';
                        points = (kpiData && kpiData.timeToAct && kpiData.timeToAct.submission) ? kpiData.timeToAct.submission.value : null; 
                        swot = 'u'; //Not calculated
                        badges = []; //Not calculated
                        rank = null; //Not calculated
                        // rankObj = _.find(kpiData.timeToAct.submission.ranks, {teamType: 'bank'});
                        // rank = rankObj ? rankObj.value : null;
                    }
                }else{
                    activity = _.find(userAnalytic.activityData, {kpiId: kpiId, fieldName: key});
                    if(!activity){
                        return;
                    }
                    //Activity badges are only calculated for Calls and Submissions
                    var activityBadges = [];
                    if (key == 'call') {
                        activityBadges = _.filter(kpiBadges, {badgeLabel: 'Calls'});
                    } else if (key == 'submission') {
                        activityBadges = _.filter(kpiBadges, {badgeLabel: 'Submissions'});
                    }

                    if (subFilter == key) {
                        name = activity.shortName;
                        points = activity.points ? activity.points.value : undefined;
                        value = activity.value.value * (activity.activityType == 'percentage' ? 100 : 1);
                        swot = activity ? activity.swot : undefined;
                        badges = activityBadges;
                        rankObj = _.find(activity.points.ranks, {teamType: 'bank'});
                        rank = rankObj ? rankObj.value : null;
                    } else if (subFilter == key + 'Rate') {
                        name = 'Rate per day';
                        points = activity.points.value / $rootScope.daysPassed;
                        value = activity.value.value / $rootScope.daysPassed;
                        swot = 'u'; //Not calculated
                        badges = []; //Not calculated
                        rank = null; //Not calculated
                    } else {
                        name = 'Win Rate';
                        points = kpiData.activityConversionRatios[key]? kpiData.activityConversionRatios[key].value:0;
                        value = kpiData.activityConversionRatios[key]? kpiData.activityConversionRatios[key].value:0;
                        swot = 'u'; //Not calculated
                        badges = []; //Not calculated
                        rank = null; //Not calculated
                    }
                }
            }
            return {
                key: metricKey,
                name: name,
                points: points,
                value: value,
                //TODO: Naveen - forecastMultiplier changes based on selected cycle
                pointsForecast: points * ($scope.config.showForecast ? ForecastMultiplier.ofCycle(userAnalytic.cycleId) : 1),
                valueForecast: value *  ($scope.config.showForecast ? ForecastMultiplier.ofCycle(userAnalytic.cycleId) : 1),
                swot: swot,
                badges: badges,
                rank: rank
            };
        }

        function getMetricValueObject(metricLeft, metricRight, bgColor) {
            var maxPoints = metricLeft.points > metricRight.points ? metricLeft.points : metricRight.points;
            var maxPointsForecast = metricLeft.pointsForecast > metricRight.pointsForecast ? metricLeft.
                pointsForecast : metricRight.pointsForecast;
            var maxValue = metricLeft.value > metricRight.value ? metricLeft.value : metricRight.value;
            var maxValueForecast = metricLeft.valueForecast > metricRight.valueForecast ? metricLeft.
                valueForecast : metricRight.valueForecast;
            return {
                left: metricLeft,
                right: metricRight,
                maxPoints: maxPoints,
                maxPointsForecast: maxPointsForecast,
                maxValue: maxValue,
                maxValueForecast: maxValueForecast,
                bgColor: bgColor
            };
        }

        function getPlayerComparisonData(userAnalyticLeft, userAnalyticRight) {
            _.forEach(dataArr.metricGroups, function (metrics) {
                metrics.metricValues = [];
                var metricKeys = metrics.metricKeys;
                for (var i = 0; i < metricKeys.length; i++) {
                    var key = metricKeys[i];
                    var bgColor;
                    if (metrics.header.indexOf('Primary') > -1) {
                        bgColor = i == 0 ? 'primary' : 'tertiary';
                    } else if (metrics.header.indexOf('Related') > -1) {
                        bgColor = i == 0 ? 'secondary' : 'tertiary';
                    } else {
                        bgColor = 'secondary';
                    }
                    
                    
                    var metricLeft = getMetric(userAnalyticLeft, key, metrics.kpiId);
                    var metricRight = getMetric(userAnalyticRight, key, metrics.kpiId);
                    if(metricLeft && metricRight){
                        metrics.metricValues.push(getMetricValueObject(metricLeft, metricRight, bgColor));
                    }
                }

                var maxPoints = _.chain(metrics.metricValues).map('maxPoints').max().value();
                var maxPointsForecast = _.chain(metrics.metricValues).map('maxPointsForecast').max().value();
                var maxValue = _.chain(metrics.metricValues).map('maxValue').max().value();
                var maxValueForecast = _.chain(metrics.metricValues).map('maxValueForecast').max().value();
                _.forEach(metrics.metricValues, function(metric) {
                    metric.maxPoints = maxPoints;
                    metric.maxPointsForecast = maxPointsForecast;
                    metric.maxValue = maxValue;
                    metric.maxValueForecast = maxValueForecast;
                });
            });
			return dataArr.metricGroups;
        }

        $scope.getPlayersComparisonData = function () {
            return deepDiveService.getPlayersData([$scope.player1._id, $scope.player2._id], $scope.cycleId).then(function (userAnalytics) {
                var playerOneData = userAnalytics[0];

                var playerTwoData = userAnalytics[1];
                getPlayerDataSummary($scope.player1, playerOneData, function (summary) {
                    $scope.playerOneSummary = summary;
                });
                getPlayerDataSummary($scope.player2, playerTwoData, function (summary) {
                    $scope.playerTwoSummary = summary;
                });
                if(dataArr && dataArr.metriGroups){
                    dataArr.metricGroups = [];
                }
                prepareMetricsObject();
                $scope.comparisonData = getPlayerComparisonData(playerOneData, playerTwoData);
            });
        };

        $scope.onDateChange = function(selectedCycle) {
            $scope.cycleId = selectedCycle._id;
            $scope.playerOneSummary = {};
            $scope.playerTwoSummary = {};
            $scope.comparisonData = {};
            if (!$scope.player1 && !$scope.player2) {
                fetchUserData();
            }else{
                $scope.getPlayersComparisonData();
            }
        };

        $scope.changeKpi = function(kpi) {
            $scope.type = kpi._id;
            $scope.playerOneSummary = {};
            $scope.playerTwoSummary = {};
            $scope.comparisonData = {};
            $scope.getPlayersComparisonData();
        };
        function setKpis(){
            var playerPlayTypes = _.filter(p0.allPlayTypes, function(playType){
                return $scope.player1.playTypes.indexOf(playType._id) > -1;
            });
            var kpis = [];
            _.forEach(playerPlayTypes, function(playType){
                kpis = kpis.concat(playType.kpis);
            });
            init(kpis);
            if($scope.cycleId){
                $scope.getPlayersComparisonData();
            }
        }

        function getMetricData(){
            $http.post('/api/userAnalytics/getDeepDiveMetric').then((result) => {
                if(result.data.status){
                    $scope.metricConfigData = result.data.data;
                    setKpis();
                }
            })
        }

        //TODO: Test method, remove once you have all necessary values
        function fetchUserData() {
            $http.post('/api/users/getUserById', {userId: $stateParams.userId1}).then(function (player1) {
                player1=player1.data.data;
                $scope.player1 = player1;
                $scope.selectedJobRole = $scope.player1.organizationalTag.jobRole._id;
                // $scope.selectedRole = _.find(p0.allJobRoles, {_id:$scope.player1.organizationalTag.jobRole.});
                $scope.selectedRole = $scope.player1.organizationalTag.jobRole;
                $http.post('/api/users/getUserById', {userId: $stateParams.userId2}).then(function (player2) {
                    player2=player2.data.data;
                    $scope.player2 = player2;
                    if($stateParams.cycleId){
                        $scope.selectedCycle = _.find(p0.allMonthlyCycles,{_id:$stateParams.cycleId});
                    }
                    $scope.cycleId = $scope.selectedCycle ? $scope.selectedCycle._id : 'default';
                    getMetricData();
                });
            });
        }
        fetchUserData();
    });
