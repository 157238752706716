'use strict';
angular.module('bitnudgeApp')
    .controller('addBulkMetrics', function($http, $scope, Auth, Upload, $location, toaster, $mdDialog, $rootScope, moment) {
        $scope.fileTypeTargets = "targets";
        $scope.fileTypeScore= "dailyScorecard";
        $scope.runCron = true;
    $scope.toggleMin = function() {
        $scope.minDate = $scope.minDate ? null : new Date();
    };
    $scope.toggleMin();

    //opening a datepicker dialog box
    $scope.open = function($event) {
        $event.preventDefault();
        $event.stopPropagation();

        $scope.opened = true;
    };
    $scope.maxDate = new Date();
    //Configration for Date picker.
    $scope.dateOptions = {
        formatYear: 'yy',
        modelDateFormat: "yyyy-MM-ddTHH:mm:ss",
        startingDay: 1
    };

    $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    $scope.format = $scope.formats[0];

    function resetInputs(){
        $scope.asOfDate = null;
        $scope.targetFiles = null;
        $scope.scoreCardFiles = null;
        $scope.runCron = true;
    }

    var isValidFileType = function (file) {
        var isValid = true;
        var fileName = file.name.split(".");
        var fileType = fileName[fileName.length - 1];
        if(!(fileType == "xls" || fileType == "xlsx" || fileType == "xlsb" || fileType == "xlsm")){
            isValid = false;
        }
        return isValid;
    };

    function getFileNameFormats(date, fileNameFormats, extension){
        var fileNames = [];
        if(!fileNameFormats) return fileNames;
        var dateFormats = $rootScope.config.fileformat_date;
        var connectors =  $rootScope.config.fileformat_connector;
        var nameFormats = fileNameFormats;
        dateFormats.forEach(function(dateFormat){
            var dateFormat = moment(date).format(dateFormat);
            connectors.forEach(function(connector){
                nameFormats.forEach(function(nameFormat){
                    var fileName = dateFormat + connector + nameFormat +'.'+extension;
                    fileNames.push(fileName);
                })
            })
        });
        return fileNames;

    }
    // On submit function submit the excel and date to server's controller
    $scope.onFileSelectIndi = function(fileType) {
        if(!$scope.asOfDate){
            toaster.pop('error', "Target / Scorecard metrics", "Excel upload successful.");
            return;
        }
        if(fileType == 'target' && (!$scope.targetFiles)){
            toaster.pop('error', "Form error", "Kindly fill all required fields.");
            return;
        }else if(fileType == 'scorecard' && !$scope.scoreCardFiles){
            toaster.pop('error', "Form error", "Kindly fill all required fields.");
            return;
        }
        if($scope.asOfDate < new Date($rootScope.lastUpdatedAtDate)){
            var text = "You have selected As Of Date is less than Last sync date, Are you sure to continue?";
            var confirm = $mdDialog.confirm()
                .title('As Of Date').htmlContent(text).targetEvent(event)
                .ok('Yes').cancel('No');
            $mdDialog.show(confirm).then(function() {
                continueUpload();
            },function(){resetInputs();});
        }else continueUpload();

        function continueUpload(){
            var cronType;
            var $targetFile,$scorecardFiles;
            var fileNameMatched = false;
            var targetFileNameFormats = getFileNameFormats($scope.asOfDate, $rootScope.config.fileformat_TARGET, 'xlsx');
            var scorecardFileNameFormats = getFileNameFormats($scope.asOfDate, $rootScope.config.fileformat_SCORECARD, 'xlsx');

            //TODO: Validate file names
            if ($scope.targetFiles && $scope.scoreCardFiles) {
                $targetFile = $scope.targetFiles[0];
                $scorecardFiles = $scope.scoreCardFiles;
                fileNameMatched = _.includes(targetFileNameFormats, $targetFile.name);
                //fileNameMatched = fileNameMatched &&  _.includes(scorecardFileNameFormats, $scorecardFile.name);
            }else if($scope.targetFiles && !$scope.scoreCardFiles){
                $targetFile = $scope.targetFiles[0];
                $scope.dataType = $scope.fileTypeTargets
                fileNameMatched = _.includes(targetFileNameFormats, $targetFile.name);
            }else if(!$scope.targetFiles && $scope.scoreCardFiles){
                $scorecardFiles = $scope.scoreCardFiles;
                $scope.dataType = $scope.fileTypeScore
                //fileNameMatched = _.includes(scorecardFileNameFormats, $scorecardFile.name);
            }else {
                resetInputs();
                return;
            }
            if(!fileNameMatched && false){
                var alert = $mdDialog.alert({
                    title: 'Attention',
                    textContent: 'You have selected wrong file or the file name is wrong, please check!',
                    ok: 'Close'
                });
                $mdDialog.show( alert ).then(function() {
                    alert = undefined;
                    resetInputs();
                });
                return;
            }
            var text = '<br> You have selected: <br>';
            if($targetFile)text = text +'<br>'+ 'Target metrics file: '+ $targetFile.name;
            if($scorecardFiles){
                $scorecardFiles.forEach(function (file, index) {
                    text = text +'<br>'+ 'Scorecard metrics file: '+ file.name;
                });

            }
            /*if($scope.isFinalMTD)text = text +'<br>' + 'is Month Final Data: YES';
            if(!$scope.isFinalMTD)text = text +'<br>'+ 'is Month Final Data: No';*/
            text = text+'<br>';
            var confirm = $mdDialog.confirm()
                .title('Metrics Upload').htmlContent(text).targetEvent(event)
                .ok('Yes').cancel('No');
            $mdDialog.show(confirm).then(function() {
                let asOfDate = new Date($scope.asOfDate);
                var uploadData = {
                    asOfDate: asOfDate.getTime(),
                    cronType: 'both',   //for RAK cronType is both always - we are getting both KAI & KPI meetrics in single file
                    targetFile: $targetFile,
                    runCron: $scope.runCron,
                    userId: Auth.getCurrentUser()._id,
                    cronTag:'uploadScorecard',
                    invocationMethod:"manual",
                    dataType: $scope.dataType
                };

                if($targetFile && !isValidFileType($targetFile)){
                    toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx', '.xlsb' or '.xlsm' Types Only.");
                    return;
                }

                let isValid = true;
                if($scorecardFiles){
                    $scorecardFiles.forEach(function (file, index) {
                        if(!isValidFileType(file)) isValid = false;
                        uploadData['scoreCard'+index] = file;
                    });
                }
                if($scorecardFiles && !isValid){
                    toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx', '.xlsb' or '.xlsm' Types Only.");
                    return;
                }

                Upload.upload({
                    url: '/api/upload',
                    data: uploadData,
                    progress: function(e) {}
                }).then(function(data, status, headers, config) {
                    toaster.pop('success', "Target / Scorecard metrics", "Excel upload successful.");
                },function(error){
                    var options = {
                        body : error.data,
                        noscreen: true
                    };
                    var errorNotification = new Notification(type, options);
                    errorNotification.close.bind(errorNotification);
                    resetInputs();
                });
            });
        }
    }
})
