const autoImportNgTemplateLoaderTemplate1 = require('../html/fullCard.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('fullCard', function($compile){
        return {
            name: 'lbCarousel',
            scope: {
                cardContent: "=",
                idName: "=",
                type:"@",
                jobRole: "=",
				selectedCycle:"=",
                isOwnLeaderBoard: "=",
                showCompare: "=",
                managerEnabled: "=",
                league: "=",
                compareList: "=",
                multiplier: "="
            },
            controller: 'FullCardCtrl',
            restrict: 'AE',
            transclude:true,
            templateUrl: autoImportNgTemplateLoaderTemplate1,
            /*link:function ($scope, $element, $attrs){
                $scope.$watch('cardContent', function(){
                    $element.html('<full-card card-content="lbs[currMetric]"></full-card>');
                    $compile($element.contents())($scope);
                });
            }*/
        };
    });
