//this directive is a workaround for the compatibility issue between angular-ui
//and ng-animate. Check out https://github.com/angular-ui/bootstrap/issues/1350

angular.module('bitnudgeApp').directive('disableAnimation', function($animate){
    return {
        restrict: 'A',
        link: function($scope, $element, $attrs){
            $attrs.$observe('disableAnimation', function(value){
                $animate.enabled(!value, $element);
            });
        }
    }
});
