'use strict';
angular.module('bitnudgeApp')
    .controller('compareMetricCtrl', function ($scope,$rootScope) {
        $scope.strings = $rootScope.strings;
        $scope.hide = $scope.values.left.key == 'activity';
        var values = $scope.values;
        var toggles = $scope.toggles;

        function getLeftBarWidth() {
            var width = 0;
            if (toggles.absolute === true) {
                if (toggles.forecast === true) {
                    width = values.maxValueForecast != 0 ? (values.left.valueForecast * 100) / values.maxValueForecast : 0;
                } else {
                    width = values.maxValue != 0 ? (values.left.value * 100) / values.maxValue : 0;
                }
            } else {
                if (toggles.forecast === true) {
                    width = values.maxPointsForecast != 0 ? (values.left.pointsForecast * 100) / values.maxPointsForecast : 0;
                } else {
                    width = values.maxPoints != 0 ? (values.left.points * 100) / values.maxPoints : 0;
                }
            }

            return width;
        }

        function getRightBarWidth() {
            var width = 0;
            if (toggles.absolute === true) {
                if (toggles.forecast === true) {
                    width = values.maxValueForecast != 0 ? (values.right.valueForecast * 100) / values.maxValueForecast : 0;
                } else {
                    width = values.maxValue != 0 ? (values.right.value * 100) / values.maxValue : 0;
                }
            } else {
                if (toggles.forecast === true) {
                    width = values.maxPointsForecast != 0 ? (values.right.pointsForecast * 100) / values.maxPointsForecast : 0;
                } else {
                    width = values.maxPoints != 0 ? (values.right.points * 100) / values.maxPoints : 0;
                }
            }

            return width;
        }

        $scope.$watchGroup(['toggles.absolute', 'toggles.forecast'], function() {
            var leftWidth = getLeftBarWidth() * 0.4;
            var rightWidth = getRightBarWidth() * 0.4;

            $scope.barLeft = {
                'left': (40 - leftWidth) + '%',
                'width': leftWidth + '%'
            };

            $scope.barRight = {
                'left': '60%',
                'width': rightWidth + '%'
            };

            $scope.leftValue = toggles.absolute === true ? (toggles.forecast === true ? values.left.valueForecast :
                values.left.value) : (toggles.forecast === true ? values.left.pointsForecast :
                values.left.points);
            $scope.rightValue = toggles.absolute === true ? (toggles.forecast === true ? values.right.valueForecast :
                values.right.value) : (toggles.forecast === true ? values.right.pointsForecast :
                values.right.points);
        })

        if($scope.header=="Related Comparison 1")
        {
            $scope.badgeLeft=_.find($scope.values.left.badges,{badgeCategory:"business",badgeType:"kpi"});
            $scope.badgeRight=_.find($scope.values.right.badges,{badgeCategory:"business",badgeType:"kpi"});
        }
        else
        {
            if($scope.values.left.badges || $scope.values.right.badges)
            {
                $scope.badgeLeft=$scope.values.left.badges[0];
                $scope.badgeRight=$scope.values.right.badges[0];
            }
        }


    });
