const autoImportNgTemplateLoaderTemplate1 = require('../html/userProfilePopover.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('userProfilePopover', function(){
        return {
            name: 'userProfilePopover',
            scope: {
                popoverUserName: '=',
                popoverLinkName: '=',
                onClickNavigate: '=',
                popoverPlacement: '@',
                popoverUserId: '=',
                popoverShowImage: '=',
                popoverImageUrl: '=',
                popoverLinkWhite: '=',
                hideName: '='
            },
            controller: 'userProfilePopoverCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1,
        };
    });
