const autoImportNgTemplateLoaderTemplate1 = require('../html/playerListModal.html');

'use strict';
angular.module('bitnudgeApp')
    .filter('returnFilteredNotifications', function() {

        return function(notifications, isChat) {
            return notifications.filter(function(e, i, l) {
                return isChat ? e.extraParams && e.extraParams.isChatNotif && e.extraParams.isChatNotif === true :
                    !e.extraParams || !e.extraParams.isChatNotif || e.extraParams.isChatNotif === false;
            });
        }
    })
    .controller('rhsSidebarCtrl', function($rootScope, $scope, $state, $http, Auth, $redirectRouter,
        returnFilteredNotificationsFilter, toaster, $location, 
        p0, $mdDialog, SocketService, sidebarVariables, UtilService, AlertsService, Sidebar, $timeout, $filter) {

        let self = this;
        const { config } = p0;
        let alertsDeepCopy= []
        $scope.isFrontline = $rootScope.isFrontline;
        $scope.productAggregatedView = config.productAggregatedView
        this.init = function() {
            $scope.isMessagesView = true;
            $scope.unReadAlerts = 0
            $scope.showFromMain = false;
            $scope.strings = $rootScope.strings;
            $scope.cloned = $rootScope.cloned;
            $scope.totalUnreadMessages = 0;
            $scope.showRhsPerformanceSummary = p0.config.showRhsPerformanceSummary;
            $scope.isRead = false;
            $http.post('/api/notifications/user/show',{id: Auth.getCurrentUser()._id})
                .then(function(notif) {
                    notif = notif.data.notifications.docs;
                    notif = _.orderBy(notif, 'createdBy', 'desc');
                    $scope.notifications = notif || [];
                    // $scope.chatNotifications = returnFilteredNotificationsFilter (notif || [], true);
                    $scope.appNotifications = returnFilteredNotificationsFilter(notif || [], false);
                    // // $scope.unReadChatNotificationCount = _.filter($scope.chatNotifications, {read: false}).length;
                    $scope.unReadAppNotificationCount = _.filter($scope.appNotifications, { read: false }).length;
                    //socket.syncUpdates('notif', $scope.notifications);
                }).
            then(() => {
                SocketService.getSocket().then(function(socket) {
                    socket.on('notificationMessage', function(data, userId) {
                        if (Auth.getCurrentUser()._id == userId) {
                            $scope.appNotifications.unshift(data);
                            $scope.unReadAppNotificationCount++;
                        }
                    });
                });
            })

            $scope.pagination = {}
            $scope.pagination.offset = 0;
            $scope.pagination.page = 1;
			$scope.pagination.limit = 10;
			//TODO: Pagination to be implemented
			//https://bitnudge.atlassian.net/browse/BN-1287
			const sortingObj = {
				sortKey: 'createdAt',
				sortOrder: 'desc'
			};
            AlertsService.getUserAlerts({}, $scope.pagination, true, sortingObj).then(function(result) {
                $scope.alertsData = result.alerts.docs;
                alertsDeepCopy = _.cloneDeep($scope.alertsData)
                filterAlerts('UNREAD')
                $scope.unReadAlerts = result.unreadCount;

            }, function(error) {
                console.log(error)
            })
        }

        $scope.gotoAlerts = function(){
            $state.go('userFlow.alerts')
            $rootScope.isRightSideBarExpand = !$rootScope.isRightSideBarExpand
            if(!$rootScope.isRightSideBarExpand){
              $rootScope.$emit('hideSideProductView',{})
            }
        }

        $rootScope.$on('showFromMain', function(event, obj) {
            $scope.showFromMain = obj.show;
            if (obj.show == 'nudges') {
                $scope.shown = true;
            }
            $scope.dataFromMain = obj.data;
        });

        $rootScope.$on('hideFromMain', function(event, obj) {
            if (obj.hide == 'nudges') {
                $scope.showFromMain = false;
            }
        });

        $rootScope.$on('showSideProductView', function(event, obj){
            let {viewStatus, product } = obj;

            $scope.loadingProductDetail = true;
            // $scope.showSecondLevel= true;
            $scope.showSecondLevelHandler(product)
            $scope.showProductDetails = viewStatus;
            $scope.product = product;
            if($scope.selectedTabIndex){
                $scope.selectedTabIndex = 0
            }


            // filter out customers based on the subproduct that was selected


            //implemented timeout to give loading message, so that user know that data has refreshed
            $timeout(function(){
                $scope.loadingProductDetail = false;
            }, 500);

        })
        

        $scope.showSecondLevelHandler = (product) => {
            $scope.showSecondLevel= true;
            product.subproducts.forEach(subproduct => {
                subproduct.metaData.forEach ( metadata => {
                    const value = new Date(metadata.value)
                    /**
                     * condition to check for valid date, if valid date then format it as per the requirement
                     */

                    if(metadata.dataType && metadata.dataType.toLocaleLowerCase().trim() === 'date') {
                        metadata.value = $filter('date')(value, "mediumDate")
                    }
                })

            }) 
        }

        $scope.showThirdLevelHandler = (product, subproduct) => {
            $scope.showSecondLevel= false;
            $scope.product = product;
            $scope.product.selectedProducts=[subproduct]
            if($scope.selectedTabIndex){
                $scope.selectedTabIndex = 0
            }
        }

        $scope.onClickNotification = function(from, notificationObj) {
            return Promise.resolve().then(() => {
                    if (!notificationObj.read) {
                        notificationObj.read = true;
                        return $http.post('/api/notifications/setNotificationRead',{id: notificationObj._id}, { read: notificationObj.read }).then(function() {
                            if (from == 'app') $scope.unReadAppNotificationCount -= 1;
                            else $scope.unReadChatNotificationCount -= 1;
                            return;
                        });
                    }
                })
                .then(() => {
                    if (notificationObj.extraParams.docId) {
                        let docId = notificationObj.extraParams.docId;
                        let appUrl = Sidebar.getAppUrl();
                        return UtilService.downloadFile(appUrl + '/api/adminActions/fileData', {actionId: docId});
                    } else if (notificationObj.extraParams.url) {
                        return UtilService.downloadFile(notificationObj.extraParams.url);
                    } else if (notificationObj.extraParams && notificationObj.extraParams.openModal) {
                        self.openUserListModal(notificationObj.extraParams.userIds, notificationObj.extraParams.modalTitle);
                    } else {
                        $redirectRouter.redirectTo(notificationObj.linkTo, notificationObj.extraParams);
                    }
                })
        };
        this.downloadFile = function(link, isPost) {
            UtilService.downloadFile(link, isPost);
        };
        $scope.clearSelects = function($event) {
            if ($event.keyCode == 27) {
                $scope.searchWinner = '';
            }
        };
        $scope.toggleShown = function() {
            $scope.shown = !$scope.shown;
        };
        $scope.readAllNotifications = function() {
            return $http.post('/api/notifications/readAllNotifications').then(function(result) {
                result = result.data;
                if (result.status) {
                    _.forEach($scope.appNotifications, function(notification) {
                        notification.read = true;
                    });
                    $scope.unReadAppNotificationCount = 0;
                } else {
                    toaster.pop('error', "Notification.", "Failed to mark notification status to read.");
                }
            });
        };
        $scope.onTabChange = function() {
            $scope.shown = false;
            sidebarVariables.setTabIndex($scope.selectedTabIndex);
        };
        this.openUserListModal = function(userIds, modalTitle) {
            return $http.post('/api/users/getUsersByIds',{userIds: JSON.stringify(userIds)}).then(function(users) {
                users = users.data;
                $mdDialog.show({
                    templateUrl: autoImportNgTemplateLoaderTemplate1,
                    controller: 'playerListModalCtrl',
                    resolve: {
                        data: function() { return users; },
                        type: function() { return ''; },
                        title: function() { return modalTitle; },
                        roleToggle: function() { return false; }
                    }
                });
            })
        }


        $scope.routeToMappedCustomer = function (cus){
            var url = `userFlow/customer/${cus.cui}/`;
            $rootScope.isRightSideBarExpand = false;
            $scope.product = null;
            $scope.selectedTabIndex=1
            $location.path(url);
        }

        $scope.showMessages = function() {
            $scope.isMessagesView = true
        }
        $scope.showAlerts = function() {
            $scope.isMessagesView = false
        }
        $scope.readAlert = function(alert) {
            if (alert.status === "UNREAD") {
                AlertsService.setAlertActionRead(alert).then(function(result) {
                    toaster.pop('success', "Alert.", "Alert set read");
                }, function(err) {
                    toaster.pop('error', "Alert.", "Alert unable to set read");
                })
            }
        };
        $scope.redirectAlert = function(alert) {
            $scope.readAlert(alert);
            if (alert.entityType === 'lead' && alert.entityId && $scope.isFrontline) {
                if(p0.config.leadUI_v1){
                    $state.go('userFlow.editLead_v1', {
                        leadId: alert.entityId
                    });
                }else{
                    $state.go('userFlow.editLead_v2', {
                        leadId: alert.entityId
                    });
                }
            } else if (alert.entityType === 'ticket' && alert.entityId && $scope.isFrontline) {
                $state.go('userFlow.editTicket', {
                    ticketId: alert.entityId,
                    statusUpdate: alert.extraParams.internalTab === 'updateStatus' ? true : false
                })
            } else if (alert.entityType === 'customer' && alert.entityId) {
                $state.go('userFlow.customer', {
                    customerId: alert.entityId,
                    activeTab: alert.extraParams ? alert.extraParams.internalTab : 'documents'
                })
            }
            if(alert.extraParams.isRedirectable){
                // alert('called');
                if(alert.tag === 'lead'){
                    const {startDate, endDate, productId} = alert.extraParams
                    let params = {
                        productId,
                        startDate: new Date(startDate).getTime(),
                        endDate: new Date(endDate).getTime(),
                        dateField: 'followUpIn'
                    }
                    $state.go('lms.listLead', params)
                }else if(alert.tag==='ticket'){
                    const {startDate, endDate, productId} = alert.extraParams
                    let params = {
                        tab: productId,
                        state: 'followUpIn',
                        startDate: new Date(startDate).getTime(),
                        endDate: new Date(endDate).getTime(),
                    }
                    $state.go('lms.tickets', params)
                }

                if((alert.extraParams.workflowId ||alert.extraParams.workflowIds) && alert.extraParams.productId){
                    if(alert.tag === 'lead'){
                        let params = {
                            productId:alert.extraParams.productId,
                            workflowIds: alert.extraParams.workflowIds && alert.extraParams.workflowIds.length ? alert.extraParams.workflowIds : [alert.extraParams.workflowId]
                        }
                        if(alert.alertCondition && ["workflowReassignFromViaAlerts","workflowReassignFrom"].includes(alert.alertCondition.options.name)){
                            params.tabName="lostLeads"
                        }
                        $state.go('lms.listLead', params)
                    }else if(alert.tag === 'ticket'){
                        let params = {
                            tab: alert.extraParams.productId,
                            workflowIds: alert.extraParams.workflowIds && alert.extraParams.workflowIds.length ? alert.extraParams.workflowIds : [alert.extraParams.workflowId]
                        }
                        $state.go('lms.tickets', params)
                    }
                }
            }
            hideRHSView()
        };
        $scope.alertStatus = 'READ';
        $scope.$watch('isRead', function(oldValue, newValue) {
            if (oldValue) {
                $scope.alertStatus = 'READ';
            } else {
                $scope.alertStatus = 'UNREAD';
            }
            filterAlerts($scope.alertStatus)
        })
        
        const filterAlerts = (status) => {
            if(status === "UNREAD")
                $scope.alertsData = alertsDeepCopy.filter(alert => alert.status === status)
            else
                $scope.alertsData = alertsDeepCopy.filter(alert => alert.status !== "UNREAD")
        }

        const hideRHSView = () => {
            $rootScope.$emit('hideSideRHSView', {});
        }
        $rootScope.$on('hideSideProductView', function(event, obj){
            if($scope.product){
                $scope.loadingProductDetail = false;
                $scope.showProductDetails = false;
                $scope.product = null;
                self.init();
            }
        })
        this.init();

    });
