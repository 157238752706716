'use strict';
angular.module('bitnudgeApp')
    .controller('targetRatesModalCtrl', function ($scope,  $mdDialog, goal, p0) {
        $scope.importantActivities = [
            {name:'Calls',fieldName:'call', rateText:'(per day)', icon:'fa fa-phone'},
            {name:'Leads',fieldName:'newLead', rateText:'(per day)', icon: 'fa fa-user-plus'},
            {name:'Submissions',fieldName:'submission', rateText:'(per week)', icon: 'fa fa-paper-plane'},
            {name:'Meetings',fieldName:'meeting', rateText:'(per day)', icon: 'fa fa-handshake-o'}
        ];
        _.remove($scope.importantActivities,function(impActivity){
            var activity = _.find(p0.myActivities,{fieldName:impActivity.fieldName});
            if(activity) impActivity.name = activity.name
            return !activity;
        });
        $scope.goal = goal;
        $scope.cancel = function() {
            $mdDialog.hide();
        };
    });
