const autoImportNgTemplateLoaderTemplate1 = require('../html/admin/organization.html');

'use strict';

angular.module('bitnudgeApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('organization', {
        url: '/organization',
        templateUrl: autoImportNgTemplateLoaderTemplate1,
        controller: 'OrganizationCtrl'
      });
  });
