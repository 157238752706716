'use strict';

angular.module('bitnudgeApp')
    .service('ActivityService', function ($http, $q) {
		
        this.getAllActivities = function () {
            var deferred = $q.defer();
            let api = '/api/activities/index';
			$http.post(api).then(function (result) {
				deferred.resolve(result.data);
			}, function (error) {
				deferred.reject(error);
			});
			return deferred.promise;
        }
    });
