'use strict';
angular.module('bitnudgeApp')
    .controller('badgeCarouselCtrl', function ($scope, p0, userAnalyticsService, $http, Auth, $timeout) {
        var init = (function(){
            $scope.badgeDataLoaded = false;
            $scope.badges = [];
            $scope.me = Auth.getCurrentUser();
            $scope.title = 'Badges';
            if($scope.me.systemRole == 'frontline' || $scope.me.systemRole == 'manager'){
                $scope.title = 'Badges';
            }else{
                $scope.title = 'Reportee Badges';
            }
        }).bind(this);

        this.setOverallBadges = function (overallBadgesEarned, overallImpactBadgesEarned, overallActivityBadgesEarned) {
            $scope.badges = overallBadgesEarned;
            $scope.badges = $scope.badges.concat(overallImpactBadgesEarned);
            $scope.badges = $scope.badges.concat(overallActivityBadgesEarned);
        };

        this.setBadges = (function (badges, kpiId) {
            var overallBadgesEarned = _.filter(badges, function(badge){return (badge.badgeCategory == 'overall' || badge.badgeCategory == 'level' || badge.badgeCategory == 'decile' || badge.badgeCategory == 'ytdBest') && (badge.badgeType == 'total' || badge.badgeType == 'level' || badge.badgeType == 'overall' || badge.badgeType == 'productivity')});
            var overallImpactBadgesEarned = _.filter(badges, function(badge){return badge.badgeType == 'overallBusiness'});
            var overallActivityBadgesEarned = _.filter(badges, function(badge){return badge.badgeType == 'overallActivity'});
            //Not showing redflags in quick view
            var kpiBadgesEarned = _.filter(badges, function(badge){return badge.from == kpiId &&  badge.badgeType == 'kpi' && badge.badgeCategory != 'redFlag'});

            if(kpiId == 'overall'){
                this.setOverallBadges(overallBadgesEarned, overallImpactBadgesEarned, overallActivityBadgesEarned);
            }else{ //KPI Badges
                if(!$scope.isSingleKpi){
                    $scope.badges = kpiBadgesEarned;
                }else{
                    //In single kpi Case we are showing overall badges along with kpi badges
                    this.setOverallBadges(overallBadgesEarned, overallImpactBadgesEarned, overallActivityBadgesEarned);
                    $scope.badges = $scope.badges.concat(kpiBadgesEarned);
                }
            }
            $scope.badges =  _.sortBy($scope.badges,'badgeOrder');
            $timeout(function () {
                $scope.badgeDataLoaded = true;
            }, 100);
        }).bind(this);

        $scope.fetchBadges = function (userId, cycleId, kpiId) {
            if(!(userId && cycleId && kpiId)){return;}
            if($scope.isManager){if(!$scope.selectedRole){return;}}
            $scope.badgeDataLoaded = false;
            $scope.badges = [];
            if(!$scope.showReporteeBadges){
                $scope.fetchUserBadges(userId, cycleId, kpiId);
            }else{
                $scope.fetchReporteeBadges(userId, cycleId, kpiId);
            }
        };

        $scope.fetchUserBadges = (function (userId, cycleId, kpiId) {
            userAnalyticsService.fetchUserAnalytics(userId, cycleId).then((userAnalytic) => {
                this.setBadges(userAnalytic.badges, kpiId);
            });
        }).bind(this);

        $scope.fetchReporteeBadges = (function (userId, cycleId, kpiId) {
            let postBody = {
                role: $scope.selectedRoleId,
                cycleId: $scope.cycleId
            }
            $http.post("/api/analytics/reportingTreeBadges", postBody).then((badgesEarned) => {
                badgesEarned=badgesEarned.data;
                var badgesEarned = _.filter(badgesEarned, function (earnedBadge) {
                    return earnedBadge.users.length > 0;
                });
                this.setBadges(badgesEarned, kpiId);
            });
        }).bind(this);

        $scope.getJobRolesFromPlayType = function (roleType) {
            if($scope.selectedPlayType._id !='all'){
                return $scope.selectedPlayType[roleType][0];
            }else{
                //All divisions case
                return roleType;
            }
        };

        $scope.$watchGroup(['selectedUser', 'selectedKpi', 'cycleId'],function (newValue, oldValue) {
            if(newValue[0] && newValue[1] && newValue[2]){
                $scope.userId = $scope.selectedUser._id;
                $scope.isFrontline = false;
                if($scope.selectedUser.systemRole=='frontline'){
                    $scope.showReporteeBadges = false;
                    $scope.isFrontline = true;
                }else if($scope.selectedUser.systemRole=='manager'){
                    $scope.showReporteeBadges = false;
                }else{
                    $scope.showReporteeBadges = true;
                    $scope.selectedRoleId = $scope.getJobRolesFromPlayType('frontline');
                }
                $scope.fetchBadges($scope.userId, $scope.cycleId, $scope.selectedKpi._id);
                if($scope.me.systemRole == 'manager' && $scope.selectedUser.systemRole=='frontline'){
                    $scope.title = `${p0.strings.FRONTLINE.value} Badges`;
                }else if($scope.me.systemRole == 'manager'){
                    $scope.title = 'Badges';
                }
            }
        });

        $scope.$watch('switchChange',function (newValue, oldValue) {
            if(newValue != undefined && newValue != null && $scope.selectedUser){
                if($scope.selectedUser.systemRole=='manager'){
                    if(newValue){
                        $scope.showReporteeBadges = true;
                        $scope.selectedRoleId = $scope.getJobRolesFromPlayType('frontline');
                    }else{
                        $scope.showReporteeBadges = false;
                    }
                }else if($scope.selectedUser.systemRole=='seniorManager'){
                    $scope.showReporteeBadges = true;
                    if(newValue){
                        $scope.selectedRoleId = $scope.getJobRolesFromPlayType('managers');
                    }else{
                        $scope.selectedRoleId = $scope.getJobRolesFromPlayType('frontline');
                    }
                }
                if($scope.me.systemRole === 'manager' && newValue){
					$scope.title = `${p0.strings.FRONTLINE.value} Badges`;
                }else if($scope.me.systemRole === 'manager'){
                    $scope.title = 'Badges';
                }else if($scope.me.systemRole === 'seniorManager' && newValue){
					$scope.title = `${p0.strings.MANAGER.value} Badges`;
				}else if($scope.me.systemRole === 'seniorManager'){
					$scope.title = `${p0.strings.FRONTLINE.value} Badges`;
				}
                $scope.fetchBadges($scope.userId, $scope.cycleId, $scope.selectedKpi._id);
            }
        });

        $scope.slickConfig1= {
            enabled: true,
            autoplay: false,
            draggable: false,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        $scope.slickConfig2= {
            enabled: true,
            infinite: true,
            autoplay: false,
            draggable: false,
            slidesToShow: 3,
            slidesToScroll: 3
        };

        init();
    });
