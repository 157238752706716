'use strict';
angular.module('bitnudgeApp')
    .controller('graphSectionCtrl', function ($scope, p0) {
        //Dummy data

        //changeLineData(false);
        $scope.lineData = {
            labels: [1, 2, 3, 4, 5, 6, 7, 8, 9 , 10, 11],
            series: [[
                {x: 5, y: 10},
                {x: 10, y: 8},
                {x: 3, y: 14}
            ]]
        };

        var getLabel = function (value) {
            return value;
        };

        $scope.lineOptions = {
            axisX: {
                type: Chartist.AutoScaleAxis,
                showGrid: false,
                onlyInteger: true,
                labelInterpolationFnc: function (value) {
                    return getLabel(value);
                }
            },
            axisY: {
                onlyInteger: true
            },
            chartPadding: {
                top: 30,
                bottom: 0,
                left: 0
            },
            plugins: [
                Chartist.plugins.tooltip({
                    appendToBody: true,
                    transformTooltipTextFnc: function(xyPair) {
                        var xy = xyPair.split(',');
                        var info = _.filter($scope.chartData.series[0], function(point){
                            return point.x == xy[0] && point.y == xy[1];
                        });
                        var res = '';
                        if(info.length){
                            info.forEach(function(user){
                                res += user.name+', '+user.cycle +'<br>';
                            })
                        }
                        res = res + p0.strings.ACHIEVEMENT.value +' '+xy[1]+' , '+$scope.chartData.seriesLegends[0].activity+' '+Math.round(xy[0]);
                        return res;
                    }
                })
            ],
            width: '400px',
            height: '400px',
            showLine: false
        };

        $scope.$watch('expanded',function(newVal,oldVal){
            $scope.lineOptions.width = newVal ? '600px' : '400px';
        });
    });
