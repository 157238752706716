'use strict';
angular.module('bitnudgeApp')
    .controller('CampaignsLeaderboardController', function($scope, $rootScope, $http, $location, $timeout, Auth, p0, fetchLeagues, $q, $stateParams, $state, $mdDialog, CurrentCycle, toaster, social, firework){
        $scope.strings = $rootScope.strings;
        $scope.me=Auth.getCurrentUser();
        $scope.playerType = $stateParams.player;
        $scope.selectedCampaignId = $stateParams.id;
        $scope.dataRequested = true;
        $scope.trackData = [];
        $scope.dataLoaded = false;
        $scope.playerTypeSwitch = $rootScope.isFrontline;
        $scope.playerType = $scope.playerTypeSwitch ? 'frontline':'managers';

        //Will Hold the Current Metric Index
        $scope.currMetric = 0;
        $scope.slickConfigCard = {
            method: {},
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows:true,
            draggable:true
        };
        $scope.slickConfigBoard = {
            method: {},
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows:true,
            draggable:true
        };
        var allContests;
        $http.post('/api/campaigns').then(function (campaigns) {
            campaigns=campaigns.data;
            $scope.isSlickDataLoaded = false;
            $scope.campaigns = campaigns;
            if($scope.selectedCampaignId)
                $scope.selectedCampaign = _.find($scope.campaigns,{_id:$scope.selectedCampaignId});
            else{
                $scope.selectedCampaign = $scope.campaigns[0];
                $scope.selectedCampaignId = $scope.selectedCampaign._id;
            }
            
            $http.post('api/contests/getContestByCampaignId', {campaignId:$scope.selectedCampaignId}).then(function (contests) {
                contests=contests.data;
                contests.forEach(function (contest){
                    contest.leaderboard = contest.leaderboard.leaders;
                });
                allContests = _.cloneDeep(contests);
                getContestsOfRole(allContests)
            });
        });

        function getContestsOfRole(contests){
            var roleType = 'managers';
            if($scope.playerType == 'frontline') roleType = 'frontline';
            var temp_contests;
            if(!$rootScope.isSeniorManager){
                var myDivId = p0.myDivisions[0]._id;
                temp_contests = _.filter(contests, function(contest){
                    return _.includes(contest.divisions,myDivId);
                })
            }else temp_contests = contests;
            $scope.lbs = _.filter(temp_contests, function(contest){
                return contest.roleType == roleType;
            });
            $scope.trackData = $scope.lbs[0];
            $scope.selectedContest = $scope.lbs[0];
            $scope.dataLoaded = true;
            $scope.isSlickDataLoaded = true;
        };
        $scope.showLeagues = true;
        $scope.allCycles = p0.allMonthlyCycles;
        $scope.selectedCycle = p0.currentMonthlyCycle;
        $scope.isOwnLeaderBoard = true;
        var loadFlags = function(){
            if($scope.playerType == 'frontline'){
                if($rootScope.isFrontline){
                    $scope.isOwnLeaderBoard = true;
                }else if($rootScope.isMiddleManager){
                    $scope.isOwnLeaderBoard = false;
                }else{
                    $scope.isOwnLeaderBoard = false;
                }
                $scope.managerEnabled = false;
            }else{
                if($rootScope.isMiddleManager){
                    $scope.isOwnLeaderBoard = true;
                }else if($rootScope.isFrontline){
                    $scope.isOwnLeaderBoard = false;
                }else{
                    $scope.isOwnLeaderBoard = false;
                    $scope.showCompare = true;
                }
                $scope.managerEnabled = true;
            }
            $scope.showTrackDetails = false;

        };
        loadFlags();
        $scope.fnShowTrackDetails = function(trackDetails){
            $scope.trackDetails = trackDetails;
            $scope.showTrackDetails = true;
        }

        $scope.hideTrackDetails = function(){
            $scope.showTrackDetails = false;
        }

        $scope.compareList = [];
        $scope.incrementCounter = function(){
            var index = $scope.currMetric;
            if($scope.currMetric == $scope.lbs.length-1)
                $scope.currMetric = 0;
            else
                $scope.currMetric += 1;
            $scope.slickConfigCard.method.slickGoTo($scope.currMetric);
            $scope.trackData = $scope.lbs[$scope.currMetric];
            if($scope.isRelatedLeaderBoards){
                $scope.getRelatedLeaderBoards('inline');
            }
            _.forEach($scope.lbs[index].leaderboard, function (selectedUser) {
                if(selectedUser){
                    var foundUser = _.find($scope.lbs[$scope.currMetric].leaderboard, function(user){
                        return user.userId._id == selectedUser.userId._id;
                    });
                    if(foundUser) foundUser.compareSelection = selectedUser.compareSelection;
                }
            });
        };

        $scope.decrementCounter = function(){
            var index = $scope.currMetric;
            if($scope.currMetric == 0)
                $scope.currMetric = $scope.lbs.length-1;
            else
                $scope.currMetric -= 1;
            $scope.slickConfigCard.method.slickGoTo($scope.currMetric);
            $scope.trackData = $scope.lbs[$scope.currMetric];
            if($scope.isRelatedLeaderBoards){
                $scope.getRelatedLeaderBoards('inline');
            }
            _.forEach($scope.lbs[index].leaderboard, function (selectedUser) {
                if(selectedUser){
                    var foundUser = _.find($scope.lbs[$scope.currMetric].leaderboard, function(user){
                        return user.userId._id == selectedUser.userId._id;
                    });
                    if(foundUser) foundUser.compareSelection = selectedUser.compareSelection;
                }
            });
        };
        $scope.setCurrIndex = function(ind){
            $scope.currMetric = ind;
            $scope.trackData = $scope.lbs[$scope.currMetric];
        }
        $scope.getStyle = function(rank){
            var width = "";
            var marginTop = "";
            switch(rank){
                case 1:
                    width = "90%";
                    marginTop = "10px";
                    break;
                case 2:
                    width = "80%";
                    marginTop = "15px";
                    break;
                case 3:
                    width = "70%";
                    marginTop = "20px";
                    break;
                default:
                    width = "50%"
                    marginTop = "25px";
                    break;
            }

            return {width:width,margin:'auto','border-radius':'100%','bottom':'0px',"margin-top":marginTop};
        }

        $scope.divisionHeight = 300;
        $scope.setMiddleSectionHeight = function(){
            var windowHeight = $(document).height();
            var middleSectionOffset = $("#middleSection").offset();
            var footerHeight = $(".dashboard-footer").height();

            if($scope.divisionHeight !== (windowHeight - middleSectionOffset.top - footerHeight)) {
				$scope.divisionHeight = windowHeight - middleSectionOffset.top - footerHeight;
			}

            return {height:$scope.divisionHeight,"position":"relativie","display":"flex"};
        };
        $scope.setTrackSectionStyles = function(){
            var trackSectionHeight = $('.track-section').height();
            var trackSectionWidth = $('.track-section').width();
            return {width:trackSectionWidth,height:trackSectionHeight};
        };
        $scope.comparePlayer = function(){
            $rootScope.jobRoleFromLB = $scope.me.organizationalTag.jobRole;
            $rootScope.myComparison = true;
            $rootScope.playersFromLB = [$scope.trackDetails.userId.id];
            $rootScope.comparePlayersFromLB = true;
            $state.go('userFlow.analytics', {tabName: 'playerComparison', myComparison: true, roleType: $scope.playerType});
        };
        $scope.gotoSimulate = function(){
            $state.go('userFlow.gameplanSimulation',{userId:$scope.trackDetails.userId.id});
        };
        $scope.gotoProfile = function(){
            $state.go('userFlow.profile',{selectedUserId: $scope.trackDetails.userId.id});
        };

        $scope.gotoCampaignMain = function () {
            $state.go('userFlow.campaignsnew');
        };

        $scope.socialAction = social.socialAction;
        $scope.onPlayerTypeChange = function () {
            $scope.dataLoaded = false;
            $scope.isSlickDataLoaded = false;
            if($scope.playerTypeSwitch){
                $scope.playerType = 'frontline';
            }
            else{
                $scope.playerType = 'managers';
            }
            getContestsOfRole(allContests);
        };
        firework.triggerFireWorks(true);
    });
