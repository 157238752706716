const autoImportNgTemplateLoaderTemplate1 = require('../html/customCorrelationsCard.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('customCorrelationsCard', function () {
        return {
            name: 'customCorrelationsCard',
            scope: {
                isStepGraph: '@'
            },
            controller: 'customCorrelationsCardCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
