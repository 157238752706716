'use strict';
angular.module('bitnudgeApp')
    .service('huddleBoard', function (Auth, userAnalyticsService,$http, p0, $rootScope, ForecastMultiplier, getGPService, Accuracy, $filter, $q) {
        var importantActivities;
        var config = p0.config;
        var me = Auth.getCurrentUser();
        
        function getHealthMetrics() {
            var metricIds = [];
            var playTypes = _.filter(p0.allPlayTypes, function(pt){
                return _.includes(me.playTypes, pt._id);
            });
            playTypes.forEach(function(pt){
                if(pt.healthMetrics && pt.healthMetrics.length){
                    metricIds = metricIds.concat(pt.healthMetrics);
                }
            });
            var healthMetrics = _.filter(p0.allActivities, function(act){
                return metricIds.indexOf(act._id) > -1;
            });
            healthMetrics = _.cloneDeep(_.map(healthMetrics,'_id'));
            return healthMetrics;
        }

        const setHuddleBoardDateHelpers = function() {
            let lastDate = new Date();
            if(lastDate < new Date(p0.currentMonthlyCycle.startDate)){
                lastDate = new Date(p0.currentMonthlyCycle.startDate);
            }
            let daysLeft = $rootScope.calcBusinessDays(lastDate, new Date(p0.currentMonthlyCycle.endDate)) - 1;
            if (daysLeft < 0 || !daysLeft) {
                daysLeft = 1;
            }
            const daysTotal = $rootScope.calcBusinessDays((new Date(p0.currentMonthlyCycle.startDate)), (new Date(p0.currentMonthlyCycle.endDate)))
            const totalBD = daysTotal
            const daysPassed = daysTotal - daysLeft;
            const daysLeftPercentage = 100 - parseInt((daysLeft / daysTotal) * 100);
            const perDayLeft = daysLeftPercentage;
            const weeklyDays = 7 - (config.weekEnds ? config.weekEnds.length : 0);
            const weeklyDaysPassed = lastDate.getDay() + 1;
            const weeklyForecastMultiplier = weeklyDays / weeklyDaysPassed;
            const weeklyDaysLeft = weeklyDays - weeklyDaysPassed;
            const weeksPassed =  daysPassed / 7;
            return{
                daysLeft,
                daysTotal,
                perDayLeft,
                totalBD,
                daysPassed,
                daysLeftPercentage,
                weeklyDays,
                weeklyDaysPassed,
                weeklyForecastMultiplier,
                weeklyDaysLeft,
                weeksPassed
            }
        };

        const huddleBoardDates = setHuddleBoardDateHelpers()
        var getActivities = function (kpi, showHealthMetrics) {
            var activities = kpi.activities || [];
            if(showHealthMetrics){
                var metricIds = getHealthMetrics();
                var huddleboardActivities = _.filter(p0.myActivities, function (activity) {
                    return metricIds.indexOf(activity._id) != -1 && activity.kpiSpecific;
                });

            }else{
                var huddleboardActivities = _.filter(p0.myActivities, function (activity) {
                    return (activities.indexOf(activity._id) != -1 || !activity.kpiSpecific)&& activity.huddleboard;
                });
                if(kpi.kpiCode == 'K01'){
                    var actFieldNames = ["CSATCount","Quality"];
                    var otherActs = _.filter(p0.myActivities, function (activity) {
                        return _.includes(actFieldNames, activity.fieldName);
                    });
                    if(otherActs.length){
                        huddleboardActivities = huddleboardActivities.concat(otherActs);
                    }
                }
                
            }
            huddleboardActivities = _.sortBy(huddleboardActivities, ['order']);
            importantActivities = _.map(huddleboardActivities,'fieldName');
        };
        this.getHuddleboardActivities = function () {
            return importantActivities;
        };


        this.getData = function(cycle, kpi, healthMetrics, playType, role, product, selectedUser){
            getActivities(kpi, healthMetrics);
            var deferred = $q.defer();
            var promise = deferred.promise;
            let children = [];
            if(selectedUser.roleType == 'manager') {
                children = _.map(selectedUser.children,'childId');
            }
            var jobRoles;
            if(selectedUser.roleType == 'seniorManager'){
                if(role._id == 'frontline'){
                    jobRoles = playType.frontline;
                } else {
                    jobRoles = playType.managers;
                }
            }
            return userAnalyticsService.fetchUserAnalyticsOfCycleForHuddleboard(selectedUser.roleType, selectedUser._id, cycle._id, children, jobRoles, product)
                .then(function(userAnalytics){
                    if(p0.config.showBadges){
                        return [userAnalytics, $http.post('/api/rollingBadges/statusOfChildren')]
                    } else {
                        return [userAnalytics, $q.when()]
                    }
                })
                .then(function(results){
                    var userAnalytics = results[0];
                    var badges = results[1];
                    if(p0.config.showBadges){
                        badges=badges.data;
                    }
                    var finalData = [];
                    userAnalytics.forEach(function(userAnalytic){
                        if(!userAnalytic || !userAnalytic.isActive) return;
                        var user = {};
                        user.image = userAnalytic.image;
                        user.name = userAnalytic.name;
                        user.userId = userAnalytic.userId;
                        var bankWideRank = _.find(userAnalytic.points.ranks, {teamType:'bank'})
                        if(bankWideRank) {
                            user.opBankRank = bankWideRank.value;
                        }

                        var kpiData = _.find(userAnalytic.kpiData,{kpiId:kpi._id});
                        user.swot = kpiData.swot;
                        var userId = null;
                        if(userAnalytic.userId._id)
                            userId = userAnalytic.userId._id;
                        else
                            userId = userAnalytic.userId;
                        if(p0.config.showBadges){
                            user.status = _.find(badges,{userId:userId});
                        }
                        user.systemRole = userAnalytic.systemRole;
                        user.MTD = kpiData.achievedValue.value / kpiData.denom;
                        if(cycle.cycleType == 'monthly'){
                            user.target = kpiData.target.value/kpiData.denom;
                            user.percentageCompleted = kpiData.percentageCompleted.value;
                            user.forecast = user.MTD * ForecastMultiplier.ofCycle(userAnalytic.cycleId);
                            user.forecastPercent = user.percentageCompleted * ForecastMultiplier.ofCycle(userAnalytic.cycleId);
                        }else if(cycle.cycleType == 'weekly'){
                            user.target = (config.showGameplan ? kpiData.gameplanTarget.value :kpiData.target.value)/kpiData.denom;
                            user.percentageCompleted = config.showGameplan ?  kpiData.gameplanPercent.value: kpiData.percentageCompleted.value;
                            user.forecast = user.MTD * huddleBoardDates.weeklyForecastMultiplier;
                            user.forecastPercent = user.percentageCompleted * huddleBoardDates.weeklyForecastMultiplier;
                        }else{
                            user.target = kpiData.target.value/kpiData.denom;
                            user.percentageCompleted = kpiData.percentageCompleted.value;
                            user.forecast = '-';
                        }
                        importantActivities.forEach(function(activity){
                            var selectedActivity = _.find(userAnalytic.activityData,function(ad){
                            return ad.fieldName == activity && ad.kpiId && ad.kpiId == kpi._id;
                            });
                            if(!selectedActivity){
                                selectedActivity = _.find(userAnalytic.activityData,function(ad){
                                    return ad.fieldName == activity;
                                });
                            }
                            user[activity] = {};
                            user[activity].name = selectedActivity.name;
                            user[activity].activityType = selectedActivity.activityType;
                            if(selectedActivity.activityType == 'percentage'){
                                var value = selectedActivity.value.value * 100;
                                user[activity].value = $filter('number')(value, 0);
                            }else{
                                var value = selectedActivity.value.value;
                                user[activity].value =  $filter('number')(value, 0);
                                if(cycle.cycleType == 'monthly'){
                                    user[activity].forecast = user[activity].value * ForecastMultiplier.ofCycle(userAnalytic.cycleId);
                                    if(user[activity].activityType == 'value'){
                                        user[activity].rate = user[activity].value/huddleBoardDates.daysPassed;
                                    }
                                }else if(cycle.cycleType == 'weekly'){
                                    user[activity].forecast = user[activity].value * huddleBoardDates.weeklyForecastMultiplier;
                                    if(user[activity].activityType == 'value'){
                                        user[activity].rate = user[activity].value/huddleBoardDates.weeklyDaysPassed;
                                    }
                                }else{
                                    user[activity].forecast = user[activity].value;
                                    if(user[activity].activityType == 'value'){
                                        user[activity].rate = user[activity].value;
                                    }
                                }
                            }

                            if(activity == 'submission' && user[activity].activityType == 'value') {
                                user[activity].currentSubmissionCounts = userAnalytic['currentSubmissionCounts']
                            }

                            var conversionRatioActivities = Object.keys(kpiData.activityConversionRatios);
                            if(conversionRatioActivities.indexOf(activity) != -1 && p0.config.uploadPeriod == 'month'){
                                var target = kpiData.target.value;
                                if(cycle.cycleType == 'weekly' && config.showGameplan){
                                    target = kpiData.gameplanTarget.value;
                                }
                                var conversionRatio = kpiData.activityConversionRatios[activity];
                                if(conversionRatio){
                                    user[activity].target = getGPService.getTargetActivitiesForMonthType(kpiData.kpiId, target,conversionRatio.value,kpiData.averageTicketSize.value);
                                    if(cycle.cycleType == 'monthly' && user[activity].activityType == 'value'){
                                        user[activity].targetRate = user[activity].target /huddleBoardDates.daysPassed;
                                    }else if(cycle.cycleType == 'weekly' && user[activity].activityType == 'value'){
                                        user[activity].targetRate = user[activity].target /huddleBoardDates.weeklyDaysPassed;
                                    }else if(user[activity].activityType == 'value'){
                                        user[activity].targetRate = user[activity].target;
                                    }
                                    user[activity].displayTarget = true;
                                }else{
                                    user[activity].displayTarget = false;
                                }
                            }

                        });

                        finalData.push(user);
                    });
                    return $q.resolve(finalData);
                });
        };
    });
