const autoImportNgTemplateLoaderTemplate1 = require('../html/track.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('trackLane', function(){
        return {
            scope: {
                trackData : "=",
                drawPath : "="
            },
            replce:true,
            controller: 'TrackController',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
