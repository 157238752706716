'use strict';
angular.module('bitnudgeApp')
    .controller('newsAndUpdatesController', function($scope, $http, Auth, p0, dailyFeed, newsAndUpdatesService){
        var divisions = p0.allDivisions;
        $scope.me = Auth.getCurrentUser();
        $scope.selectedCycle = p0.currentMonthlyCycle;
        $scope.filters ={
            selectedCycle: p0.currentMonthlyCycle,
            selectedRoleType: $scope.me.systemRole == 'frontline'? 'frontline' : 'manager',
            selectedDivision: {name: 'Overall'}
        };
        var getBadges = function () {
            newsAndUpdatesService.fetchBadgeAchievers ($scope.filters.selectedRoleType, $scope.filters.selectedCycle._id,null).then(function (badgeAchievers) {
                $scope.badgeAchievers = badgeAchievers;
            });
        };
        var getRolling = function () {
            var jobRole = $scope.filters.selectedDivision.name=='Overall'? _.map(divisions, $scope.filters.selectedRoleType): $scope.filters.selectedDivision[$scope.filters.selectedRoleType];
            newsAndUpdatesService.fetchRollingBadges(jobRole, $scope.filters.selectedCycle._id).then(function (rollingBadges) {
                $scope.rollingBadgeAchievers = rollingBadges;
            });
        };
        getBadges();
        getRolling();
        $scope.onFiltersChanged = function (selectedCycle , selectedRoleType, selectedDivision) {
            var newObj = angular.copy($scope.filters);
            if (selectedCycle && (selectedCycle._id != newObj.selectedCycle._id)) newObj.selectedCycle = selectedCycle;
            if (selectedRoleType && (selectedRoleType != newObj.selectedRoleType)) newObj.selectedRoleType = selectedRoleType;
            if (selectedDivision && (selectedDivision.name != newObj.selectedDivision.name)) newObj.selectedDivision = selectedDivision;
            if (selectedRoleType || selectedCycle || selectedDivision) {
                getRolling();
                if (selectedCycle || selectedRoleType) {
                    getBadges();
                }
            }
            $scope.filters = newObj;
        };
    });
