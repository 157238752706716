'use strict';

angular.module('bitnudgeApp')
    .service('Accuracy', function () {
        var _this = this;
        this.getErrorCount = function (fieldName, userAnalytic, kpiId) {
            var activity = _.find(userAnalytic.activityData, function (analytic) {
                return analytic.fieldName == fieldName && (!kpiId?!analytic.kpiId:(analytic.kpiId && analytic.kpiId.toString() == kpiId.toString()));
            });
            var operand1Value = _.find(userAnalytic.activityData, function (analytic) {
                    return analytic.activityId.toString() == activity.operand1.toString() && (!kpiId?!analytic.kpiId:(analytic.kpiId && analytic.kpiId.toString() == kpiId.toString()));
                }).value.value;
            var operand2Value = _.find(userAnalytic.activityData, function (analytic) {
                    return analytic.activityId.toString() == activity.operand2.toString() && (!kpiId?!analytic.kpiId:(analytic.kpiId && analytic.kpiId.toString() == kpiId.toString()));
                }).value.value;
            return operand2Value - operand1Value;
        };
    });

