'use strict';
angular.module('bitnudgeApp')
    .service('rhsAnalyticsL2Social', function (userAnalyticsService,$rootScope,leagueAnalytic,p0,$filter,$http, $q) {
        this.strings = p0.strings;
        let self = this;
        this.getData = function(user,kpi,cycle){
            var returnData = {};
            return userAnalyticsService.fetchUserAnalytics(user._id,cycle._id).then(function(userAnalytic){
                var userLeagues = _.map(user.leagues,'leagueId');
                return leagueAnalytic.fetchLeagueAnalyticsOfCycle(userLeagues,cycle._id).then(function(leagueAnalytics) {
                    return $http.post('/api/rollingBadges/users', {userId: user._id, cycleId: cycle._id}).then(function(rollingBadge){
                        rollingBadge=rollingBadge.data;
                        var rollingBadge = rollingBadge[0];
                        returnData.card1 = {
                            heading1:'Rank',
                            heading2:'(#)',
                            tabs:[]
                        };
                        returnData.card2 = {
                            heading1:'Badges',
                            badge:rollingBadge,
                            data:[
                                [
                                    {},
                                    {
                                        bold:'Excellence'
                                    },
                                    {
                                        bold:'Momentum'
                                    }
                                ],
                            ]
                        };
                        let params = {
                            user, 
                            kpi, 
                            cycle,
                            returnData,
                            leagueAnalytics,
                            userAnalytic
                        }
                        if(kpi._id == 'overall'){
                            self.setReturnDataForOverall(params);
                        }else{
                            self.setReturnDataForKpi(params);
                        }

                        return $q.resolve(returnData);
                    });
                });
            });
        };

        this.setReturnDataForOverall = ({ user, returnData, leagueAnalytics, userAnalytic}) => {
            self.setCard1Data({ user, returnData, leagueAnalytics, userAnalytic});
            self.setCard2Data({userAnalytic, returnData});
        }

        this.setCard2Data = ({userAnalytic, returnData}) => {
            //    card2
            var overallExcellence = _.filter(userAnalytic.badges,function(badge){
                return badge.badgeCategory == 'overall' && (badge.badgeType == 'total' || badge.badgeType == 'level');
            });
            var overallMomentum = _.filter(userAnalytic.badges,function(badge){
                return (badge.badgeCategory == 'overall' && badge.badgeType == 'productivity') || ((badge.badgeCategory == 'decile' || badge.badgeCategory == 'ytdBest') && badge.badgeType == 'overall');
            });
            var impactExcellence = _.filter(userAnalytic.badges,function(badge){
                return badge.badgeCategory == 'overall' && badge.badgeType == 'overallBusiness';
            });
            var impactMomentum = _.filter(userAnalytic.badges,function(badge){
                return (badge.badgeCategory == 'decile' || badge.badgeCategory == 'ytdBest') && badge.badgeType == 'overallBusiness';
            });
            var activityExcellence = _.filter(userAnalytic.badges,function(badge){
                return badge.badgeCategory == 'overall' && badge.badgeType == 'overallActivity';
            });
            var activityMomentum = _.filter(userAnalytic.badges,function(badge){
                return (badge.badgeCategory == 'decile' || badge.badgeCategory == 'ytdBest') && badge.badgeType == 'overallActivity';
            });
            returnData.card2.data.push([
                {
                    text:'Overall'
                },
                {
                    bold:overallExcellence.length
                },
                {
                    bold:overallMomentum.length
                }
            ]);
            returnData.card2.data.push([
                {
                    text: self.strings.IMPACT.value
                },
                {
                    bold:impactExcellence.length
                },
                {
                    bold:impactMomentum.length
                }
            ]);
            returnData.card2.data.push([
                {
                    text: self.strings.ACTIVITY.value
                },
                {
                    bold:activityExcellence.length
                },
                {
                    bold:activityMomentum.length
                }
            ]);
        }

        this.setCard1Data = ({user,  returnData, leagueAnalytics}) => {
            let item =  [
                { bold:'League' },
                { bold:'Rank' },
                { bold:'Player Ahead' }
            ];
            var overallPtsData = [item];
            var impactPtsData = [item];
            var activityPtsData = [item];
            
            leagueAnalytics.forEach(function(leagueAnalytic){
                var arr = [];
                var impactArr =[];
                var activityArr = [];
                var userIndexOverall = _.findIndex(leagueAnalytic.points.leaders,{userId:user._id});
                var userIndexImpact = _.findIndex(leagueAnalytic.businessPoints.leaders,{userId:user._id});
                var userIndexActivity = _.findIndex(leagueAnalytic.activityPoints.leaders,{userId:user._id});

                arr.push({text:leagueAnalytic.teamTypeName});
                impactArr.push({text:leagueAnalytic.teamTypeName});
                activityArr.push({text:leagueAnalytic.teamTypeName});

                arr.push({
                    text:leagueAnalytic.points.leaders[userIndexOverall].rank,
                    delta:leagueAnalytic.points.leaders[userIndexOverall].delta
                });
                impactArr.push({
                    text:leagueAnalytic.points.leaders[userIndexImpact].rank,
                    delta:leagueAnalytic.points.leaders[userIndexImpact].delta
                });
                activityArr.push({
                    text:leagueAnalytic.points.leaders[userIndexActivity].rank,
                    delta:leagueAnalytic.points.leaders[userIndexActivity].delta
                });

                arr.push({
                    text:userIndexOverall!=0?leagueAnalytic.points.leaders[userIndexOverall-1].userName:''
                });
                impactArr.push({
                    text:userIndexImpact!=0?leagueAnalytic.points.leaders[userIndexImpact-1].userName:''
                });
                activityArr.push({
                    text:userIndexActivity!=0?leagueAnalytic.points.leaders[userIndexActivity-1].userName:''
                });

                overallPtsData.push(arr);
                impactPtsData.push(impactArr);
                activityPtsData.push(activityArr);
            });

            returnData.card1.tabs.push({
                name:'Overall '+ self.strings.POINTS.short+'.',
                data:overallPtsData
            });
            returnData.card1.tabs.push({
                name:'Agr.'+ self.strings.IMPACT.value,
                data:impactPtsData
            });
            returnData.card1.tabs.push({
                name:'Agr. '+ self.strings.ACTIVITY.value,
                data:activityPtsData
            });   
        }

        this.setReturnDataForKpi = ({user, kpi, returnData, leagueAnalytics, userAnalytic}) => {
            self.setCard1DataForKpi(user, kpi, returnData, leagueAnalytics);
            // card2
            self.setCard2DataForKpi(userAnalytic, returnData, kpi);
        }

        this.setCard1DataForKpi = (user, kpi, returnData, leagueAnalytics) => {
            var impactPointsData = [
                [
                    { bold:'League' },
                    { bold:'Rank' },
                    { bold:'Player Ahead' }
                ]
            ];
            leagueAnalytics.forEach(function(leagueAnalytic){
                var arr = [];
                var kpiData = _.find(leagueAnalytic.kpiData,{kpiId:kpi._id});
                var userIndex = _.findIndex(kpiData.points.leaders,{userId:user._id});
                arr.push({text:leagueAnalytic.teamTypeName});
                arr.push({
                    text:kpiData.points.leaders[userIndex].rank,
                    delta:kpiData.points.leaders[userIndex].delta
                });
                arr.push({
                    text:userIndex!=0?kpiData.points.leaders[userIndex-1].userName:''
                });
                impactPointsData.push(arr);
            });
            returnData.card1.tabs.push({
                name:self.strings.IMPACT.value +' '+$rootScope.self.strings.POINTS.value,
                data:impactPointsData
            });
        }

        this.setCard2DataForKpi = (userAnalytic, returnData, kpi) => {
            var badges = _.filter(userAnalytic.badges,{from:kpi._id});
            var impactExcellence = _.filter(badges,{badgeCategory:'business'});
            var impactMomentum = _.filter(badges,{badgeCategory:'productivity'});
            var activityExcellence = _.filter(badges,{badgeCategory:'activity'});
            var activityMomentum = _.filter(badges,{badgeCategory:'conversion'});
            returnData.card2.data.push([
                {
                    text:self.strings.IMPACT.value
                },
                {
                    bold:impactExcellence.length
                },
                {
                    bold:impactMomentum.length
                }
            ]);
            returnData.card2.data.push([
                {
                    text: self.strings.ACTIVITY.value
                },
                {
                    bold:activityExcellence.length
                },
                {
                    bold:activityMomentum.length
                }
            ]);
        }
    });

