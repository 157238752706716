'use strict';
angular.module('bitnudgeApp')
    .controller('addBulkPortfolioController', function ($http, $scope, Auth, Upload, $location, toaster, p0) {

        //Function to submit portfolio excel
        $scope.onFileSelectPortfolio = function () {
            if ($scope.bulkPortfolio == undefined) {
                toaster.pop('error', "Submission.", "Select File to Upload.");

            } else {
                var fileName = $scope.bulkPortfolio[0].name.split(".");
                var fileType = fileName[fileName.length - 1].toLowerCase();
                if (fileType == "xlsx" || fileType == "xlsm") {
                    Upload.upload({
                        url: '/api/upload',
                        data: {
                            userId: Auth.getCurrentUser()._id,
                            cronTag:'uploadPortfolio',
                            invocationMethod:"manual"
                        },
                        file: $scope.bulkPortfolio[0],
                        progress: function (e) { }
                    }).then(function (data, status, headers, config) {
                        // file is uploaded successfully
                        //$location.path('/lms/listLead');
                        toaster.pop('success', "Excel uploaded", "Portfolio creation successfully finished.");
                    });
                } else {
                    toaster.pop('error', "Submission.", "Upload '.xlsx' file only.");
                }

            }
        };
    })
