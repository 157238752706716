const autoImportNgTemplateLoaderTemplate1 = require('../html/graphSection.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('graphSection', function () {
        return {
            name: 'graphSection',
            scope: {
                isStepGraph: '@',
                expanded: '=',
                chartData: '=',
                
            },
            controller: 'graphSectionCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
