'use strict';
angular.module('bitnudgeApp')
    .service('uploadFlowCrons', function (Auth, $q, $http) {
        var _this = this;
        this.user= Auth.getCurrentUser();
        this.getUploadFlowCrons = function(page,limit){

            var deferred = $q.defer();
            $http.post('/api/upload/cron/all',{page,limit})
                .then(function(response) {
                    deferred.resolve(response.data);
                }, function(error) {
                    deferred.reject(error);
                });
            return deferred.promise;

        };
        this.killUploadFlowCron = function(uploadTrackerId){
            var deferred = $q.defer();
            $http.post('/api/upload/cron/stop/', {uploadTrackerId: uploadTrackerId})
                .then(function(response) {
                    if (response.data.message === 'success') {
                        deferred.resolve(response.data.data)
                    } else {
                        deferred.reject(response)
                    }
                }, function (err) {
                    deferred.reject(err)
                })
            return deferred.promise;
        };
        this.getCronLog = function(uploadTrackerId){
            var deferred = $q.defer();
            $http.post('/api/upload/cron/logs',{uploadTrackerId})
                .then(function(response) {
                    if (response.data.message === 'success') {
                        deferred.resolve(response.data.data)
                    } else {
                        deferred.reject(response)
                    }
                }, function (err) {
                    deferred.reject(err)
                })
            return deferred.promise;
        };
        this.availableCrons = () => {
            var deferred = $q.defer();
            $http.post('/api/upload/cron/getAllCrons')
                .then(function(response) {
                    if (response.data.message === 'success') {
                        deferred.resolve(response.data.data)
                    } else {
                        deferred.reject(response)
                    }
                }, function (err) {
                    deferred.reject(err)
                })
            return deferred.promise;
        };
        this.getCronStatus = () => {
            var deferred = $q.defer();
            $http.post('/api/upload/cron/getCronStatus')
                .then(function(response) {
                    if (response.data.message === 'success') {
                        deferred.resolve(response.data.data)
                    } else {
                        deferred.reject(response)
                    }
                }, function (err) {
                    deferred.reject(err)
                })
            return deferred.promise;
        };
        this.runCron = inActiveId => {
            return $http.post(`/api/upload/cronStart`,{uploadTrackerId: inActiveId})
                .then(function(response) {
                    if (response.data.message === 'success') {
                        return response.data.data;
                    } else {
                        return response;
                    }
                }, function (err) {
                    $q.reject(err)
                })
        };
        this.createScheduler = scheduleObj => {
            return $http.post('/api/createSchedule', scheduleObj)
                .then(function(response) {
                    if (response.data.message === 'success') {
                        return response.data.data;
                    } else {
                        return response;
                    }
                }, function (err) {
                    $q.reject(err)
                })
        };
        this.getAllSchedulers = () => {
            return $http.post('/api/schedule')
                .then(function(response) {
                    if (response.data.message === 'success') {
                        return response.data.data;
                    } else {

                    }
                }, function (err) {
                    $q.reject(err)
                })
        };
        this.disableScheduler = scheduler => {
            scheduler.scheduleId = scheduler._id;
            return $http.post(`/api/disableSchedule`, scheduler)
                .then(function(response) {
                    if (response.data.message === 'success') {
                        return response.data.data;
                    } else {
                        return response;
                    }
                }, function (err) {
                    $q.reject(err);
                })
        };
    });
