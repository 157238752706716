'use strict';
angular.module('bitnudgeApp')
    .controller('gradesCtrl', function ($rootScope, $scope, $http, Auth, p0, GradeService, $mdDialog){
        $scope.allKpis = p0.allKpis;
        $scope.me = Auth.getCurrentUser();
        //In case of quick view swot - history is saved inside swot directive
        //In Analytics swot case - history is saved in parent of swot directive
        $scope.history = [];
        $scope.switch = {isReportee:false,isKpiOverall:false};
        $scope.config = p0.config;
        $scope.frontlineGrades = [];
        $scope.managerGrades = [];

        if($scope.me.roleType === 'frontline'){
            $scope.hideActivityDropdown = true;
        }
        var allActivityModel = {
            '_id' : 'All',
            'name' : 'All'
        };
        
        $scope.selectedValue = {};

        $scope.fetchGrade = function () {
            if(!($scope.cycleId && $scope.userId && $scope.gradeType)) return;
            var playTypeIds = $scope.selectedPlayType._id != 'all'?$scope.selectedPlayType._id:_.reduce(p0.allPlayTypes, function(ids, playType){
                ids = (ids == '')?playType._id:ids+','+playType._id;
            }, '');
            var playTypesIds;
            if($scope.selectedPlayType._id == 'all'){
                p0.allPlayTypes.forEach(function(playType){
                    if(playTypeIds == undefined){
                        playTypeIds = playType._id;
                    } else {
                        playTypeIds = playTypeIds + ',' + playType._id;
                    }
                });
            } else {
                playTypeIds = $scope.selectedPlayType._id;
            }
            GradeService._getGrades($scope.cycleId, $scope.userId, playTypeIds)
                .then(function(grades){
                    var userRoleType = $scope.gradeUser?$scope.gradeUser.roleType:$scope.me.roleType;
                    switch(userRoleType){
                        case 'seniorManager':
                            $scope.frontlineGrades = grades.frontlineGrades;
                            $scope.managerGrades = grades.managerGrades;
                            if($scope.meReporteeSwitch){
                                $scope.grades = $scope.managerGrades;
                            } else {
                                $scope.grades = $scope.frontlineGrades;
                            }
                            break;
                        case 'manager': 
                            $scope.grades = grades.frontlineGrades;                        
                            break;
                        case 'frontline':
                            $scope.grades = grades.personalGrades; 
                            break;
                    }
                })
        };

        var getJobRolesFromPlayType = function (roleType) {
            if($scope.selectedPlayType._id !='all'){
                var jobRoleId = $scope.selectedPlayType[roleType][0];
                setLeagueId(jobRoleId);
                return jobRoleId;
            }else{
                //All divisions case
                $scope.leagueId = null;
                return roleType;
            }
        };

        var setLeagueId = function (jobRoleId) {
            var leagues = _.find(p0.allJobRoles, {_id:jobRoleId}).leagues;
            if(leagues) {
                $scope.leagueId = _.find(leagues, {teamType:'bank'}).leagueId;
            }
        };

        $scope.$watch('meReporteeSwitch', function(newValue, oldValue){
            if(newValue != undefined && newValue != null && $scope.swotUser && $scope.isQuickView){
                setVarsOnSwitch(newValue);
            }
            if($scope.meReporteeSwitch){
                $scope.grades = $scope.managerGrades;
            } else {
                $scope.grades = $scope.frontlineGrades;
            }
        });

        $scope.$watchGroup(['gradeUser','cycleId','type','kpi', 'selectedPlayType'],function (newValue, oldValue) {
            if(newValue[0] && newValue[1] && newValue[2]){
                $scope.userId = $scope.gradeUser._id;
                $scope.isFrontline = false;
                if($scope.kpi){
                    $scope.kpiId = $scope.kpi._id;
                }
                if($scope.me._id != $scope.userId){
                    $scope.userName = $scope.gradeUser.name;
                }else{
                    $scope.userName ='My';
                }
                if($scope.gradeUser.systemRole=='frontline'){
                    $scope.isFrontline = true;
                    $scope.leagueId = _.find($scope.gradeUser.leagues, {teamType:'bank'}).leagueId;
                }else if($scope.gradeUser.systemRole=='manager'){
                    $scope.label1 = 'Me';
                    if($scope.me._id != $scope.userId) $scope.label1 = $scope.gradeUser.name;
                    $scope.label2 = $rootScope.strings.FRONTLINE.value;
                    if($scope.me._id != $scope.userId) $scope.label2 = $scope.gradeUser.name+'\'s '+'Frontline';
                    $scope.leagueId = _.find($scope.gradeUser.leagues, {teamType:'bank'}).leagueId;
                }else{
                    $scope.label1 =$rootScope.strings.FRONTLINE.value;
                    $scope.label2 = $rootScope.strings.MANAGER.value;
                    $scope.jobRoleId =  $scope.switch.isReportee ?  getJobRolesFromPlayType('managers') : getJobRolesFromPlayType('frontline') ;
                }
                //In case of quick view swot - single swot component changes to different swot types based of the selected swot user
                //In Analytics swot case - swot type is determined by its parent container.
                var roleType = $scope.gradeUser?$scope.gradeUser.roleType:$scope.me.roleType;
                if(roleType != 'frontline'){
                    $scope.gradeType = 'reportee';
                } else {
                    $scope.gradeType = $scope.type;
                }
                
                $scope.history = [];
                $scope.fetchGrade();
            }
        });
    
        var setVarsOnSwitch = function (isReportee) {
            $scope.switch.isReportee = isReportee;
            if($scope.swotUser.systemRole=='manager'){
                if(isReportee || ( $scope.activity && $scope.activity._id !== 'All')){
                    $scope.swotType = 'reportee';
                    $scope.hideActivityDropdown = false;
                    $scope.jobRoleId = getJobRolesFromPlayType('frontline')
                }else{
                    $scope.hideActivityDropdown = true;
                    $scope.swotType = 'kpi';
                }
            }else if($scope.swotUser.systemRole=='seniorManager'){
                $scope.swotType = 'reportee';
                if(isReportee){
                    $scope.jobRoleId = getJobRolesFromPlayType('managers');
                }else{
                    $scope.jobRoleId = getJobRolesFromPlayType('frontline')
                }
            }
            else{
                $scope.hideActivityDropdown = true;
            }
            $scope.fetchGrade();
            if($scope.onParentSwitchChange) $scope.onParentSwitchChange(isReportee);
        };
    });
