'use strict';
angular.module('bitnudgeApp')
    .directive('speedometer', function () {
        return {
            name: 'speedometer',
            scope: {
                    settings                    : '=',
                    size                        : '@',
                    clipWidth                   : '@',
                    clipHeight                  : '@',
                    ringInset                   : '@',
                    ringWidth                   : '@',
                    pointerWidth                : '@',
                    pointerTailLength           : '@',
                    pointerHeadLengthPercent    : '@',
                    minValue                    : '@',
                    maxValue                    : '@',
                    lowThreshhold               : '@',
                    highThreshhold              : '@',
                    minAngle                    : '@',
                    maxAngle                    : '@',
                    lowThreshholdColor          : '@',
                    defaultColor                : '@',
                    highThreshholdColor         : '@',
                    transitionMs                : '@',
                    majorTicks                  : '@',
                    labelFormat                 : '@',
                    labelInset                  : '@',
                    rotateLabels                : '@',
                    infoData                    : '@',
                    multiplier                  : '@',
                    value                       : '@'
            },
            restrict: 'AE',
            link : function(scope, element, attr){
                var arcColorFn;
                var range = undefined;
                var r = undefined;
                var pointerHeadLength = undefined;
                var value = [];
                var container;
                var numberDiv;
                var numberInfo;
                var numberSpan;

                var svg = undefined;
                var arc = undefined;
                var scale = undefined;
                var ticks = undefined;
                var tickData = undefined;
                var pointer = undefined;

                var donut = d3.layout.pie();
                var numberFormat = d3.format("f.1");
                var that, options, initOptions, init;

                initOptions = function(){
                    options = angular.extend({
                        size                        : scope.size ? parseInt(scope.size,10) : 100,
                        clipWidth                   : scope.clipWidth ? parseInt(scope.clipWidth,10) : 200,
                        clipHeight                  : scope.clipHeight ? parseInt(scope.clipHeight,10) : 110,
                        ringInset                   : scope.ringInset ? parseInt(scope.ringInset,10) : 10,
                        ringWidth                   : scope.ringWidth ? parseInt(scope.ringWidth,10) : 40,
                        pointerWidth                : scope.pointerWidth ? parseInt(scope.pointerWidth,10) : 15,
                        pointerTailLength           : scope.pointerWidth ? parseInt(scope.pointerWidth,10) : 5,
                        pointerHeadLengthPercent    : scope.pointerHeadLengthPercent ? pointerHeadLengthPercent : 0.6,
                        minValue                    : scope.minValue ? parseInt(scope.minValue,10) : 0,
                        maxValue                    : scope.maxValue ? parseInt(scope.maxValue,10) : 100,
                        lowThreshhold               : scope.lowThreshhold ? parseInt(scope.lowThreshhold) : 0,
                        highThreshhold              : scope.highThreshhold ? parseInt(scope.highThreshhold) :100,
                        minAngle                    : scope.minAngle ? parseInt(scope.minAngle) : -90,
                        maxAngle                    : scope.maxAngle ? parseInt(scope.maxAngle) : 90,
                        lowThreshholdColor          : scope.lowThreshholdColor ? scope.lowThreshholdColor : '#B22222',
                        defaultColor                : scope.defaultColor ? scope.defaultColor : 'steelblue',
                        highThreshholdColor         : scope.highThreshholdColor ? scope.highThreshholdColor : '#008000',
                        transitionMs                : scope.transitionMs? parseInt(scope.transitionMs) : 750,
                        majorTicks                  : scope.majorTicks ? parseInt(scope.majorTicks) : 5,
                        labelFormat                 : d3.format(',g'),
                        labelInset                  : scope.labelInset ? parseInt(scope.labelInset,10) : 10,
                        rotateLabels                : scope.rotateLabels ? scope.rotateLabels : false,
                        infoData                    : scope.infoData,
                        multiplier                  : scope.multiplier ? scope.multiplier : 100,
                        value                       : scope.value ? scope.value : 0,
                    },scope.settings);
                }



                init = function(){
                    initOptions();
                    // if(options.value == 0){
                    //     options.value = parseInt(Math.random() * options.multiplier);
                    // }
                    configure();
                    render(options.value);


                    function deg2rad(deg) {
                        return deg * Math.PI / 180;
                    }

                    function newAngle(d) {
                        var ratio = scale(d);
                        var newAngle = options.minAngle + (ratio * range);
                        return newAngle;
                    }

                    function configure() {
                        // var prop = undefined;
                        // for ( prop in configuration ) {
                        //     config[prop] = configuration[prop];
                        // }

                        range = options.maxAngle - options.minAngle;
                        r = options.size / 2;
                        pointerHeadLength = Math.round(r * options.pointerHeadLengthPercent);

                        // a linear scale that maps domain values to a percent from 0..1
                        scale = d3.scale.linear()
                            .range([0,1])
                            .domain([options.minValue, options.maxValue]);

                        var colorDomain = [options.lowThreshhold, options.highThreshhold].map(scale),
                            colorRange  = [options.lowThreshholdColor, options.defaultColor, options.highThreshholdColor];

                        arcColorFn = d3.scale.threshold().domain(colorDomain).range(colorRange)

                        ticks = scale.ticks(options.majorTicks);
                        //tickData = [0.2, 0.8, 1];//d3.range(options.majorTicks).map(function() {return 1/options.majorTicks;});
                        tickData = [options.lowThreshhold, options.highThreshhold, options.maxValue]
                                .map(function(d) {return scale(d); });

                        arc = d3.svg.arc()
                            .innerRadius(r - options.ringWidth - options.ringInset)
                            .outerRadius(r - options.ringInset)
                            .startAngle(function(d, i) {
                                var ratio = i > 0 ? tickData[i-1] : options.minValue;//d * i;
                                return deg2rad(options.minAngle + (ratio * range));
                            })
                            .endAngle(function(d, i) {
                                var ratio = tickData[i];//d * (i+1);
                                return deg2rad(options.minAngle + (ratio * range));
                            });
                    }
                    // that.configure = configure;

                    function centerTranslation() {
                        return 'translate('+r +','+ r +')';
                    }

                    function isRendered() {
                        return (svg !== undefined);
                    }
                    // that.isRendered = isRendered;

                    function render(newValue) {
                        container = d3.select(element[0]);
                        svg = container.append('svg:svg')
                                .attr('class', 'gauge')
                                .attr('width', options.clipWidth)
                                .attr('height', options.clipHeight);

                        var centerTx = centerTranslation();
                        var arcs = svg.append('g')
                                .attr('class', 'arc')
                                .attr('transform', centerTx);
                        arcs.selectAll('path')
                            .data(tickData)
                            .enter().append('path')
                            .attr('fill', function(d, i) {
                                return arcColorFn(d - 0.001);
                            })
                            .attr('d', arc);

                        var lg = svg.append('g')
                            .attr('class', 'label')
                            .attr('transform', centerTx);

                        lg.selectAll('text')
                            .data(ticks)
                            .enter().append('text')
                            .attr('transform', function(d) {
                                var ratio = scale(d);
                                if (options.rotateLabels) {
                                    var newAngle = options.minAngle + (ratio * range);
                                    return 'rotate(' +newAngle +') translate(0,' +(options.labelInset - r) +')';
                                }
                                else {
                                    var newAngle = deg2rad(options.minAngle + (ratio * range)),
                                    y = (options.labelInset - r) * Math.cos(newAngle),
                                    x =  -1 * (options.labelInset - r) * Math.sin(newAngle);
                                    return 'translate(' + x + ',' + y +')';
                                }
                            })
                            .text(options.labelFormat);

                        lg.selectAll('line')
                            .data(ticks)
                            .enter().append('line')
                            .attr('class', 'tickline')
                            .attr('x1', 0)
                            .attr('y1', 0)
                            .attr('x2', 0)
                            .attr('y2', options.ringWidth * 2)
                            .attr('transform', function(d) {
                                var ratio = scale(d),
                                newAngle = options.minAngle + (ratio * range);

                                return 'rotate(' +newAngle +') translate(0,' + (options.labelInset + options.ringWidth - r) +')';
                            })
                            .style('stroke', '#666')
                            .style('stroke-width', '1px');

                        var lineData = [ [options.pointerWidth / 2, 0],
                                        [0, -pointerHeadLength],
                                        [-(options.pointerWidth / 2), 0],
                                        [0, options.pointerTailLength],
                                        [options.pointerWidth / 2, 0] ];

                        var pointerLine = d3.svg.line().interpolate('monotone');

                        var pg = svg.append('g').data([lineData])
                            .attr('class', 'pointer')
                            .attr('transform', centerTx);

                        pointer = pg.append('path')
                            .attr('d', pointerLine )
                            .attr('transform', 'rotate(' +options.minAngle +')');

                        numberDiv = container.append('div')
                            .attr('class', 'number-div')
                            .style('width', options.clipWidth + 'px');
                        numberInfo = numberDiv.append('div')
                            .attr('class','number-info')
                            .style('width','100%')
                            .html(options.infoData);

                        numberSpan = numberDiv.append('span')
                            .data([newValue])
                            .attr('class', 'number-span');

                        update(newValue === undefined ? 0 : newValue);
                    }
                    // that.render = render;




                }
                init();

                function update(newValue, newConfiguration) {
                    if ( newConfiguration  !== undefined) {
                        configure(newConfiguration);
                    }

                    value = [newValue];

                    var ratio = scale(newValue);
                    var newAngle = options.minAngle + (ratio * range);
                    pointer.transition()
                        .duration(options.transitionMs)
                        //.ease('elastic')
                        .ease('cubic-in-out')
                        .attr('transform', 'rotate(' +newAngle +')');

                    numberSpan
                        .data(value)
                        .transition()
                        .duration(options.transitionMs)
                        .ease('quad')
                        // .style('color', arcColorFn( scale(newValue) ))
                        //.text( d3.round(newValue, 2));
                        .tween("text", function(d) {
                            var i = d3.interpolate(this.textContent, d);
                            return function(t) {
                                this.textContent = i(t).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            };
                        });
                    }
                    // that.update = update;


                    // setInterval(function() {
                    //     update(parseInt(Math.random() * options.multiplier));
                    // }, 5 * 1000);
            }
        };
    });
