'use strict';
angular.module('bitnudgeApp')
    .controller('huddleBoardSummaryCtrl', function ($scope, $http, $rootScope, $state, Auth, getGPService, toaster, p0) {
    $scope.strings = $rootScope.strings;
    $scope.config = $rootScope.config;
    $scope.me = Auth.getCurrentUser();
    if($state.params.selectedPlayerId)
        $scope.selectedPlayerId = $state.params.selectedPlayerId;
    else
        $scope.selectedPlayerId = $scope.me._id;

    $scope.progressBarConfig = function(goal){
      return {
        mtdColor: "#79c3f9",
        forecastColor: "#c95955",
        percentageCompleted: goal.percentageCompleted.value,
        percents: [0,50,150],
        activePercent: goal.percentageCompleted.value
      };
    };
    $scope.getGP = function(playerId){
        if(playerId == $scope.me._id)
            $scope.isReportee = false;
        else
            $scope.isReportee = true;

        if($scope.isWeekly){
            var currentCycle = $scope.weeklyCycle;
        }
        else{
            var currentCycle = $scope.selectedCycle;
        }
        getGPService.getGoal(playerId,currentCycle, true).then(function(result){
            $scope.goals=result;
            for (var i = 0; i < $scope.goals.length; i++) {
                $scope.goals[i].enableSlide = false;
            }
            $scope.overall = {};
            $scope.overall.callRate = _.sumBy($scope.goals, function(goal){return parseFloat(goal.callRate)});
            $scope.overall.submissionRate = _.sumBy($scope.goals, function(goal){return parseFloat(goal.submissionRate)});
            $scope.overall.weeklyCallRate = _.sumBy($scope.goals, function(goal){return parseFloat(goal.weeklyCallRate)});
            $scope.overall.reqWeeklyCallRatePlan = _.sumBy($scope.goals, function(goal){return parseFloat(goal.reqWeeklyCallRatePlan)});
            $scope.overall.reqWeeklySubmissionRatePlan = _.sumBy($scope.goals, function(goal){return parseFloat(goal.reqWeeklySubmissionRatePlan)});
            $scope.overall.reqSubmissionRatePlan = _.sumBy($scope.goals, function(goal){return parseFloat(goal.reqSubmissionRatePlan)});
            $scope.overall.reqCallRatePlan = _.sumBy($scope.goals, function(goal){return parseFloat(goal.reqCallRatePlan)});
            $scope.overall.reqSubmissionRateTgt = _.sumBy($scope.goals, function(goal){return parseFloat(goal.reqSubmissionRateTgt)});
            $scope.overall.reqCallRateTgt = _.sumBy($scope.goals, function(goal){return parseFloat(goal.reqCallRateTgt)});
            $scope.overall.activeLeadCount = _.sumBy($scope.goals, function(goal){return parseFloat(goal.activeLeadCount)});
        });
    };

    $scope.isPlayer = $rootScope.isPlayer;
    $scope.isManager = $rootScope.isManager;
    $scope.daysTotal = $rootScope.daysTotal;
    $scope.cycles = [p0.currentMonthlyCycle];
    $scope.selectedCycle=p0.currentMonthlyCycle;
    $scope.getGP($scope.selectedPlayerId);

    $scope.showInfoModal = function(selectedGoal, type){
        $scope.selectedGoal=selectedGoal;
        getGPService.showModals($scope.selectedPlayer,$scope.selectedGoal, type);
    };

    $scope.showLegendInfoModal = function(){
        getGPService.showLegendModal();
    };

    $scope.navigateToActiveLeads = function (leadcount, cycle, kpiId) {
        var params = {status:'active', kpiId:kpiId, startDate: moment($scope.selectedCycle.startDate).valueOf(), endDate:moment($scope.selectedCycle.endDate).valueOf()};
        $state.go('lms.listLead', params);
    };
});
