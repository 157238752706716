const autoImportNgTemplateLoaderTemplate1 = require('../html/playerListModal.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('playerListModal', function(){
        return {
            name: 'playerListModal',
            scope: {
                selectedPlayer: '='
            },
            controller: 'playerListModalCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
