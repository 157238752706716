'use strict';
angular.module('bitnudgeApp')
    .controller('swotChartCtrl', function ($scope, $rootScope, $http, DTOptionsBuilder, SwotService, Auth) {
        // $scope.strings = $rootScope.strings;
        // $scope.benchmark = [
        //     {name: "Average", value:"avg"},
        //     {name: "#1", value:"top1"},
        //     {name: "Top Decile", value:"topDecile"},
        //     {name: "Top Quartile", value:"topQuartile"}
        // ];
        // $scope.selectedBenchmark = $scope.benchmark[0];
        // function getFrontlineSwotOfType(swot,type,kpiId){
        //     var swotObj={
        //         Strength: [],
        //         Opportunity: [],
        //         Weakness: [],
        //         Threat: [],
        //         Unknown: []
        //     };
        //     if(type=='overall'){
        //         swot.users.forEach(function(swotUser, index) {
        //             var threshold = swot.league.percentageCompleted.avg*$rootScope.forecastMultiplier;
        //             var topDecile = swot.league.percentageCompleted.topDecile*$rootScope.forecastMultiplier;
        //             var bottomDecile = swot.league.percentageCompleted.bottomDecile*$rootScope.forecastMultiplier;
        //             var swotValue = SwotService.getSWOTString(swotUser.swot);
        //             swotObj[swotValue].push({
        //                 user: {_id: swotUser.userId, name: swotUser.name},
        //                 current: {value: 'NA', percent: swotUser.percentageCompleted.value},
        //                 forecast: {value: 'NA', percent: $rootScope.forecastMultiplier*swotUser.percentageCompleted.value, threshold: threshold, topDecile:topDecile, bottomDecile:bottomDecile}
        //             });
        //         });
        //         return swotObj;
        //     }
        //     else if(type=='kpi'){
        //         swot.users.forEach(function(swotUser, index) {
        //             var kpi = _.find(swotUser.kpiData, {kpiId: kpiId});
        //             var forecastMultiplier = kpi.kpiId.kpiType == 'percentage' ? 1 : $rootScope.forecastMultiplier;
        //             var peer = _.find(swot.league.kpiData, {kpiId: kpiId}).percentageCompleted;
        //             var threshold = peer.avg*forecastMultiplier;
        //             var topDecile = peer.topDecile*forecastMultiplier;
        //             var bottomDecile = peer.bottomDecile*forecastMultiplier;
        //             var swotValue = SwotService.getSWOTString(kpi.swot);
        //             if(kpi !=undefined && swotValue){
        //                 swotObj[swotValue].push({
        //                     user: {_id: swotUser.userId, name: swotUser.name},
        //                     current: {value: kpi.achievedValue.value, percent: kpi.percentageCompleted.value},
        //                     forecast: {value: forecastMultiplier*kpi.achievedValue.value, percent: forecastMultiplier*kpi.percentageCompleted.value, threshold: threshold, topDecile:topDecile, bottomDecile:bottomDecile}
        //                 });
        //             }
        //         });
        //         return swotObj;
        //     }
        //     else return {};
        // }
        //
        // $scope.getFrontlineSWOTAnalysis = function(playerType, cycle, kpi) {
        //     $scope.kpiName = kpi.name;
        //     $scope.kpiId = kpi._id;
        //     var kpiId = kpi._id;
        //     $scope.getSwot.swotData = {};
        //     $http.get('api/analytics/frontlineSWOT/cycle/'+cycle._id +'/jobRole/'+playerType._id).then(function(swot) {
        //
        //         var userList = [];
        //         _.forEach(swot.users, function(obj){
        //             userList.push({name: obj.name, userId: obj.userId})
        //         });
        //         if($scope.getChildSwot!= undefined){
        //             $scope.getChildSwot.userList = userList;
        //         }
        //
        //         var frontlineData={};
        //         if(kpiId == 'overall'){
        //             frontlineData.overall=getFrontlineSwotOfType(swot,'overall');
        //             $scope.getSwot.swotData =frontlineData.overall;
        //         }
        //         else{
        //             frontlineData[kpiId]=getFrontlineSwotOfType(swot,'kpi',kpiId);
        //             $scope.getSwot.swotData = frontlineData[kpiId];
        //         }
        //         $scope.getSwot.loadingText = 'You have no frontline in this '+$scope.strings.SWOT_SWOT_PL.value+'.';
        //
        //     })
        // };
        //
        // $scope.getPlayerKPISwot = function (cycleId, userId) {
        //     $scope.kpis=[];
        //
        //     if(!userId)
        //         var userId = Auth.getCurrentUser()._id;
        //
        //     $http.get('/api/analytics/user/mySWOT' + '/cycle/' + cycleId + '/league/' + 'Bank' + '/metric/' + $scope.selectedBenchmark.value+'?userId='+ userId).then(function (kpiData) {
        //         var kpiSwotData = {
        //             Strength: [],
        //             Weakness: [],
        //             Opportunity: [],
        //             Threat: [],
        //             Unknown: []
        //         };
        //         _.forEach(kpiData.users.kpiData, function (kpi, index) {
        //             var leagueKPIDataPercentageCompleted = _.find(kpiData.league.kpiData, {kpiId: kpi.kpiId._id}).percentageCompleted;
        //             var forecastMultiplier = kpi.kpiId.kpiType == 'percentage' ? 1 : $rootScope.forecastMultiplier;
        //             kpi.forecastedAchievement = forecastMultiplier*kpi.percentageCompleted.value;
        //             kpi.topDecilePercentageCompleted = forecastMultiplier*leagueKPIDataPercentageCompleted.topDecile;
        //             kpi.bottomDecilePercentageCompleted = forecastMultiplier*leagueKPIDataPercentageCompleted.bottomDecile;
        //             kpi.swotThreshold = forecastMultiplier*leagueKPIDataPercentageCompleted.avg;
        //             var redFlag = _.find(kpiData.users.badges,function(badge){
        //                 return badge.badgeCategory == 'redFlag' && badge.from == kpi.kpiId._id;
        //             });
        //             if(redFlag){
        //                 switch (redFlag.metric){
        //                     case 'top1':
        //                         kpi.redFlags = 3;
        //                         break;
        //                     case 'topDecile':
        //                         kpi.redFlags = 2;
        //                         break;
        //                     case 'topQuartile':
        //                         kpi.redFlags = 1;
        //                         break;
        //                 }
        //             }else{
        //                 kpi.redFlags = 0;
        //             }
        //             $scope.kpis.push(kpi);
        //             kpiSwotData[SwotService.getSWOTString(kpi.swot)].push(kpi);
        //         });
        //
        //         if(userId !=Auth.getCurrentUser()._id){
        //             $scope.getChildSwot.swotData = kpiSwotData;
        //             $scope.getChildSwot.loadingText= 'You have no KPI in this'+$scope.strings.SWOT_SWOT_PL.value+'.';
        //             $scope.getPlayerActivitySwot(cycleId, userId)
        //         }
        //         else{
        //             $scope.getSwot.swotData = kpiSwotData;
        //             $scope.getSwot.loadingText= 'You have no KPI in this '+$scope.strings.SWOT_SWOT_PL.value+'.';
        //         }
        //     });
        // };
        //
        // $scope.getPlayerActivitySwot= function(cycleId,userId){
        //     if(!userId)
        //         var userId = Auth.getCurrentUser()._id;
        //
        //     $http.get('/api/analytics/user/myActivitySWOT' + '/cycle/' + cycleId + '/league/' + 'Bank'+'?userId='+ userId).then(function (myActivity) {
        //         $scope.myActivity = myActivity;
        //         var KPIIndependentActivity={
        //             Strength: [],
        //             Weakness: [],
        //             Opportunity: [],
        //             Threat: [],
        //             Unknown: []
        //         };
        //
        //         var activities= [];
        //         _.forEach($scope.myActivity.users.activityData, function(activity){
        //             activity.swot = SwotService.getSWOTString(activity.swot);
        //         });
        //
        //         activities= _.filter($scope.myActivity.users.activityData, function(obj){return (obj.kpiId== undefined)});
        //         _.forEach(activities, function (activity, index) {
        //             var leagueActivityData = _.filter($scope.myActivity.league.activityData, function(obj){return (obj.kpiId== undefined && obj.activityId == activity.activityId._id)});
        //             activity.topDecilePercentageCompleted = leagueActivityData[0].value.topDecile;
        //             activity.bottomDecilePercentageCompleted = leagueActivityData[0].value.bottomDecile;
        //             activity.threshold = leagueActivityData[0].value.avg;
        //             activity.unit= "#";
        //             if(activity.swot)
        //                 KPIIndependentActivity[activity.swot].push(activity);
        //             if(userId !=Auth.getCurrentUser()._id){
        //                 $scope.getChildSwot.userActivity = KPIIndependentActivity;
        //                 $scope.getChildSwot.myActivity= $scope.myActivity;
        //                 $scope.getSwot.myActivity= $scope.myActivity;
        //             }
        //             else{
        //                 $scope.getSwot.userActivity = KPIIndependentActivity;
        //                 $scope.getSwot.myActivity= $scope.myActivity;
        //             }
        //         });
        //     });
        // };
        //
        // $scope.getActivity = function(kpiId){
        //     if($scope.getSwot.myActivity) {
        //         if (kpiId.kpiId) {
        //             getActivitiesOfKPI($scope.getSwot.myActivity, kpiId.kpiId._id);
        //         }
        //         else {
        //             getActivitiesOfKPI($scope.getSwot.myActivity, kpiId);
        //         }
        //     }
        // };
        //
        // if($scope.getChildSwot!= undefined){
        //     $scope.getChildSwot.getActivity = $scope.getActivity;
        //     $scope.getChildSwot.getPlayerKPISwot = $scope.getPlayerKPISwot;
        // }
        //
        // $scope.showSwotModal = function() {
        //     if(!$scope.isMySwot) {
        //         $mdDialog.open({
        //             templateUrl: '../html/swotAnalysis/frontlineSwotModal.html',
        //             controller: 'swotModalCtrl',
        //             size: 'lg',
        //             resolve: {
        //                 selectedPlayer: function () {
        //                     $scope.frontlineSWOTs = [];
        //                     var selectedPlayers = $scope.getSwot.swotData;
        //                     for(var swot in selectedPlayers) {
        //                         for(var i = 0; i < selectedPlayers[swot].length; i++) {
        //                             selectedPlayers[swot][i].swot = swot;
        //                             $scope.frontlineSWOTs.push(selectedPlayers[swot][i])
        //                         }
        //                     }
        //                     return $scope.frontlineSWOTs;
        //                 },
        //                 kpiName: function(){return $scope.kpiName},
        //                 activityData: function(){
        //                     return "";
        //                 },
        //                 isActivity: function(){
        //                     return "";
        //                 },
        //                 showActivityModal: function(){
        //                     return '';
        //                 }
        //             }
        //         });
        //     }
        //     if($scope.isMySwot){
        //         $mdDialog.open({
        //             templateUrl: '../html/swotAnalysis/mySwotModal.html',
        //             controller: 'swotModalCtrl',
        //             size: 'lg',
        //             resolve: {
        //                 selectedPlayer: function () {
        //                     var selectedKPIsData = $scope.getSwot.swotData;
        //                     var kpiSWOTs = [];
        //                     for(var swot in selectedKPIsData) {
        //                         for(var i = 0; i < selectedKPIsData[swot].length; i++) {
        //                             selectedKPIsData[swot][i].swot = swot;
        //                             kpiSWOTs.push(selectedKPIsData[swot][i])
        //                         }
        //                     }
        //                     kpiSWOTs = _.sortBy(kpiSWOTs,'kpiCode');
        //                     return kpiSWOTs;
        //                 },
        //                 activityData: function(){
        //                    var activitySWOTs = [];
        //                     var selectedActivitiesData = $scope.getSwot.swotData;
        //                     if(!$scope.isActivity){
        //                         selectedActivitiesData= $scope.getSwot.userActivity;
        //                     }
        //                     for(var swot in selectedActivitiesData) {
        //                         for(var i = 0; i < selectedActivitiesData[swot].length; i++) {
        //                             selectedActivitiesData[swot][i].swot = swot;
        //                            activitySWOTs.push(selectedActivitiesData[swot][i])
        //                         }
        //                     }
        //                     return activitySWOTs;
        //                 },
        //                 kpiName: function(){
        //                     if($scope.getSwot.selectedKPI && $scope.getSwot.selectedKPI.name)
        //                         return $scope.getSwot.selectedKPI.name
        //                     else if($scope.getSwot.selectedPlayer && $scope.getSwot.selectedPlayer.name)
        //                         return $scope.getSwot.selectedPlayer.name
        //                     else
        //                         return "";
        //                 },
        //                 isActivity: function(){
        //                     return $scope.isActivity;
        //                 },
        //                 showActivityModal: function(){
        //                     return '';
        //                 }
        //             }
        //         });
        //     }
        // };
        //
        // $scope.dtOptionsForMySWOT = DTOptionsBuilder.newOptions()
        //     .withOption('paging', false)
        //     .withOption('searching', false)
        //     .withOption('ordering', false)
        //     .withOption('info', false);
        //
        // var getActivitiesOfKPI = function (myActivity, kpiId) {
        //     $scope.kpiSpecificActivities={
        //         Strength: [],
        //         Weakness: [],
        //         Opportunity: [],
        //         Threat: [],
        //         Unknown: []
        //     };
        //     var relevantActivities=[];
        //     _.forEach(myActivity.users.activityData, function(activity){
        //         if(activity.kpiId == kpiId)
        //             relevantActivities.push(activity)
        //     });
        //     _.forEach(relevantActivities, function(activity){
        //         var leagueActivityData = _.filter(myActivity.league.activityData, function(obj){return (obj.kpiId == kpiId && obj.activityId == activity.activityId._id)});
        //         activity.topDecilePercentageCompleted = leagueActivityData[0].topDecile;
        //         activity.bottomDecilePercentageCompleted = leagueActivityData[0].bottomDecile;
        //         activity.forecastValue = $rootScope.forecastMultiplier*activity.value;
        //         activity.weaknessThreshold = leagueActivityData[0].avg;
        //         activity.unit= "#";
        //         $scope.kpiSpecificActivities[activity.swot].push(activity);
        //     });
        //     if($scope.getChildSwot!= undefined) {
        //         $scope.getChildSwot.swotData = $scope.kpiSpecificActivities;
        //         $scope.getChildSwot.kpiList = $scope.kpis;
        //         $scope.getChildSwot.loadingText = 'You have no activity in this '+$scope.strings.SWOT_SWOT_PL.value+'.';
        //     }
        // };
        //
        // if($scope.isMySwot){
        //     if($scope.getSwot!= undefined) {
        //         $scope.getSwot.mySwot = $scope.getPlayerKPISwot;
        //         $scope.getSwot.activitySwot = $scope.getPlayerActivitySwot;
        //     }
        // }
        // else{
        //     if($scope.getSwot!= undefined)
        //         $scope.getSwot.frontlineSwot= $scope.getFrontlineSWOTAnalysis;
        // }
        // $scope.showOverallOrActivity = function (cycleId, swotItem) {
        //     if ($scope.kpiName == 'Overall') {
        //         $scope.getPlayerKPISwot(cycleId,swotItem.user._id);
        //     }
        //     else {
        //         $scope.getPlayerActivitySwot (cycleId,swotItem.user._id);
        //         $scope.getActivity($scope.kpiId);
        //     }
        // };
        // $scope.getNumber = function(num) {
        //     return new Array(num);
        // }
        //
        // $scope.addScoll = function (swot){
        //     if($scope.getSwot.swotData && $scope.getSwot.swotData[swot] && $scope.getSwot.swotData[swot].length>5) return 'swotScrollViewer';
        //     else return '';
        // }
    });
