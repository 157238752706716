'use strict';
angular.module('bitnudgeApp')
    .service('NotificationService', function ($http,Auth, $q, p0) {
        var _this= this;
        _this.getUserNotifications = function(filterObj,pagination){

            filterObj.offset=pagination.offset;
            filterObj.limit=pagination.limit
            var deferred = $q.defer();
            filterObj.id = Auth.getCurrentUser()._id;
            //console.log(query,'---query')
            $http.post('/api/notifications/user/show', filterObj)
            .then(function(result){
                deferred.resolve(result.data)
                //socket.syncUpdates('notif', $scope.pnotifications);
            },function(error){
                deferred.reject(error)
            });
            return deferred.promise;
        }
        var buildURLQuery = function(obj){
            return Object.entries(obj)
                    .map(pair => pair.map(encodeURIComponent).join('='))
                    .join('&');
        }
        _this.deleteNotification = function(notification){
            var deferred = $q.defer();
            $http.post('/api/notifications/deleteNotification',{notificationId:notification})
            .then(function(result){
                deferred.resolve(result.data)
                //socket.syncUpdates('notif', $scope.pnotifications);
            },function(error){
                deferred.reject(error)
            });
            return deferred.promise;
        }
        _this.setNotificationRead = function(notificationObj){
            var deferred = $q.defer();
            if(!notificationObj.read){
                notificationObj.read = true;
                $http.post('/api/notifications/setNotificationRead',{id:notificationObj._id}, {read: notificationObj.read}).then(function(){
                    deferred.resolve(true)
                },function(err){
                    deferred.resolve(false)
                });
            }else{
                deferred.resolve(true)
            }
            return deferred.promise;
        }
        _this.setNotificationActionRead = function(notificationObj){
            var deferred = $q.defer();
            if(!notificationObj.read){
                notificationObj.read = true;
                $http.post('/api/notifications/setNotificationActionRead',{id: notificationObj._id}, {read: notificationObj.read}).then(function(){
                    deferred.resolve(true)
                },function(err){
                    deferred.resolve(false)
                });
            }else{
                deferred.resolve(true)
            }
            return deferred.promise;
        }
        _this.recentGeneratedAlertNotifications = function(filterObj,pagination){
            filterObj.offset=pagination.offset;
            filterObj.limit=pagination.limit;
            var deferred = $q.defer();
            filterObj.id = Auth.getCurrentUser()._id;
            $http.post('/api/notifications/recentGeneratedAlertNotifications', filterObj)
            .then(function(result){
                deferred.resolve(result.data,'----- received notifications')
            },function(error){
                deferred.reject(error)
            });
            return deferred.promise;
        }


        

    });
