'use strict';
angular.module('bitnudgeApp')
    .controller('pointsInfoCtrl', function ($scope,$rootScope, $mdDialog, p0, Auth) {
        $scope.strings = $rootScope.strings;
        $scope.config = p0.config;
        $scope.isFrontline = $rootScope.isFrontline;
        $scope.me = Auth.getCurrentUser();
        $scope.myDivisions = _.cloneDeep(p0.myDivisions);
        $scope.division = $scope.myDivisions[0];
        $scope.myRole = $scope.me.organizationalTag.jobRole._id;
        $scope.jobRoles = [];
        $scope.activityPoints = {};
        $scope.impactPoints = {};
        var jobRoles = [];
        jobRoles = jobRoles.concat([$scope.division.frontline,$scope.division.manager]);
        $scope.cycleTypes = [{_id:'daily',name:'Daily'}];
        if($scope.config.weeklyCycle){
            $scope.cycleTypes.push({_id:'weekly',name:'Weekly'});
        }
        $scope.cycleTypes.push({_id:'monthly',name:'Monthly'});
        $scope.cycleType = $scope.cycleTypes[0];
        p0.allJobRoles.forEach(function(role){
            if(role.roleType =='seniorManager' )return;
            if(_.includes(jobRoles,role._id)){
                $scope.jobRoles.push({name:role.name,_id:role._id});
            }
            $scope.activityPoints[role._id] = [];
            p0.allActivities.forEach(function(activity){
                if(_.find(activity.pointsMultipliers,{role:role._id}).value>0){
                    $scope.activityPoints[role._id].push({name:activity.name, points: _.find(activity.pointsMultipliers,{role:role._id}).value});
                }
            });
            $scope.impactPoints[role._id]= [];
            p0.allKpis.forEach(function(goal){
                if(_.find(goal.pointsMultipliers,{role:role._id}).value>0){
                    $scope.impactPoints[role._id].push({name:goal.name, points: _.find(goal.pointsMultipliers,{role:role._id}).value});
                }
            })
        });
        $scope.onDivisionChange = function(division){
            $scope.jobRoles = [], jobRoles = [];
            jobRoles = jobRoles.concat([division.frontline, division.manager]);
            p0.allJobRoles.forEach(function(role){
                if(_.includes(jobRoles,role._id)){
                    $scope.jobRoles.push({name:role.name,_id:role._id});
                }
            });
        };

        $scope.onClose = function() {
            $mdDialog.hide();
        };
    });
