const autoImportNgTemplateLoaderTemplate1 = require('./../html/component/editableComponent.html');

"use strict";
angular.module("bitnudgeApp").component("editableComponent", {
    templateUrl: autoImportNgTemplateLoaderTemplate1,
    bindings: {
        docMetadata: "<",
        isNew: "<",
        context: "<",
        documentTypeId: "<",
        cui: "<",
        workflowId: "<",
        docId: "<",
        fileIds: "<",
        fileNames: "<"
    },
    controller: "editableComponentCtrl"
});