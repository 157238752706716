'use strict';

angular.module('bitnudgeApp')
    .controller('broadcastMessageModalCtrl', function ($scope,$mdDialog,$http,initWelcomeBoard) {
        $scope.onClose = function() {
            $mdDialog.cancel();
        };
        $http.post('/api/welcomeMessages/mine').then(function(welcomeMessage){
            welcomeMessage=welcomeMessage.data;
            $scope.message = '';
            if(welcomeMessage){
                $scope.message = welcomeMessage.message;
                $scope.oldMsg = welcomeMessage.message;
            }
        });

        $scope.clear = function(){
            $scope.message= '';
        };
        $scope.submit = function(){
            $http.post('/api/welcomeMessages/',{message:$scope.message}).then(function(){
                $mdDialog.hide();
                initWelcomeBoard();
            });
        };
    });
