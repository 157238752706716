angular.module('bitnudgeApp').controller('carImageUploadModalCtrl', function ($scope, $http, Upload, $mdDialog, carImage, me, toaster) {

    $scope.onClose = function () {
        $mdDialog.hide()
    };

    $scope.$watch('files', function () {
        $scope.onFileSelect("");
    });
    $scope.onFileSelect = function ($files) {
        $scope.file = $files[0];
        var reader = new FileReader()
        if($files[0]){
            reader.readAsDataURL($files[0]);
            reader.onload = function (e) {
                $scope.fileUrl = reader.result;
                const output = document.getElementById('car-image');
                output.src = $scope.fileUrl;
            }
        }
    };

    $scope.save = function (file) {
        var fileName = file.name.split(".");
        var fileType = fileName[fileName.length - 1].toLowerCase();
        if (fileType == "jpg" ||fileType == "jpeg" || fileType == "png") {
            Upload.upload({
                url: '/api/users/picture/addCarImage',
                data: {userId: me._id},
                file: file,
            }).then(function (response) {
                // console.log('file ' + config.file.name + ' uploaded. response: ' + data);
                var data = response.data;
                var status = response.status;
                var statusText = response.statusText;
                var headers = response.headers;
                var config = response.config;
                carImage(data.data.path);
                $mdDialog.hide();
            }).catch(function (error) {
                toaster.pop('error', "Submission.", "Upload '.png', '.jpg' Types Only.");
            });
        }else{
            toaster.pop('error', "Submission.", "Upload '.png', '.jpg' Types Only.");
        }

    };

    $scope.cancel = function () {
        delete $scope.file;
    };

});
