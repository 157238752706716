const autoImportNgTemplateLoaderTemplate1 = require('../html/interactionsTable.html');

'use strict';
angular.module('bitnudgeApp')
    .component('interactionsTable',{
        bindings: {
            interactions: '<',
            interactionIcons: '<',
            pagination: '<',
            apiLoaded: '<',
            previousPage: '=',
            nextPage: '=',
            customerView: '<',
        },
        templateUrl: autoImportNgTemplateLoaderTemplate1,
        controller: function($scope,$state, $rootScope, p0){
            $scope.isSeniorManager = $rootScope.isSeniorManager
            this.$onChanges = (changes) => {
                if (changes.interactions) {
                    $scope.interactions = changes.interactions.currentValue;
                }
                if (changes.interactionIcons) {
                    $scope.interactionIcons = changes.interactionIcons.currentValue;
                }
                if (changes.apiLoaded) {
                    $scope.apiLoaded = changes.apiLoaded.currentValue;
                }
                if (changes.pagination) {
                    $scope.pagination = changes.pagination.currentValue;
                }
                if (changes.previousPage) {
                    $scope.previousPage = changes.previousPage.currentValue;
                }
                if (changes.nextPage) {
                    $scope.nextPage = changes.nextPage.currentValue;
                }
                if (changes.customerView) {
                    $scope.customerView = changes.customerView.currentValue;
                }
            };
            $scope.redirectFromActivity = (activity) =>{
                if (activity.category === 'lead' && activity.entityId ) {
                    if(p0.config.leadUI_v1){
                        $state.go('userFlow.editLead_v1', {
                            leadId: activity.entityId
                        });
                    }else{
                        $state.go('userFlow.editLead_v2', {
                            leadId: activity.entityId
                        });
                    }
                } else if (activity.category === 'ticket' && activity.entityId) {
                    $state.go('userFlow.editTicket', {
                        ticketId: activity.entityId
                    })
                }
            }
        }
    });
