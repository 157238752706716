'use strict';
angular.module('bitnudgeApp')
    .controller('notificationsCtrl', function ($scope, $location, $compile, $http, Auth, toaster, moment, $timeout, $state, $redirectRouter) {
        let self = this;
        this.init = function(){
            $scope.user = Auth.getCurrentUser();
            $scope.currentPage = 1;
            $scope.totalItems = 0;
            $scope.entryLimit = 13; // items per page
            $scope.noOfPages = Math.ceil($scope.totalItems / $scope.entryLimit);
            return $http.post('/api/notifications/user/show',{id: $scope.user._id})
            .then(function (inbox) {
                inbox=inbox.data;
                var sortedInbox  = _.sortBy(inbox, 'createdBy');
                $scope.inbox = sortedInbox.reverse();
                $scope.totalItems = $scope.inbox.length;
                $scope.noOfPages = Math.ceil($scope.totalItems / $scope.entryLimit);
                $scope.getUnreadCount();
            });
        }

        $scope.getPageData = function(currentPage){
            if($scope.inbox){
                return $scope.inbox.slice((currentPage-1)* $scope.entryLimit);
            }
            return [];
        };

        $scope.formatDate = function(date){
            return(moment(date).fromNow());
        };

        $scope.getUnreadCount = function() {
            $scope.unread = _.filter($scope.inbox, function(mail) { return mail.read == false });
            $scope.unreadCount = $scope.unread.length;
        };

        $scope.getNotificationReadStatus = function(notification) {
            if(notification.read == false)
                return "message-unread";
            else
                return "";
        };

        $scope.onClickNotification=function(notification) {
            if(!notification.read){
                notification.read = 'read';
                return $http.post('/api/notifications/setNotificationRead',{id: notification._id}, {read: notification.read})
                .then(function(){
                    $scope.unreadCount -=1;
                    $redirectRouter.redirectTo(notification.linkTo, notification.extraParams);
                });
            }
            else{
                return Promise.resolve().then(() => {
                    return $redirectRouter.redirectTo(notification.linkTo, notification.extraParams);
                })
            }
        };

        this.init();
    });
