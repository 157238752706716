'use strict';
angular.module('bitnudgeApp')
    .controller('cardHeaderCtrl', function ($scope, p0) {

        if($scope.onIndicatorChange){
            $scope.onIndicatorChange($scope.indicator);
        }
        var selectedIndicatorIndex = 0;
        $scope.selectedUnit = $scope.units[0];
        $scope.selectedCycleType = $scope.cycleTypes[0];
        $scope.decrementMetric = function() {
            if($scope.metric === 'activity') return ;

            var newIndex = selectedIndicatorIndex === 0 ? $scope.myIndicators.length - 1 :  selectedIndicatorIndex-1;

            if(newIndex !== selectedIndicatorIndex){
                selectedIndicatorIndex = newIndex;
                $scope.indicator = $scope.myIndicators[selectedIndicatorIndex];

                if($scope.onIndicatorChange){
                    $scope.onIndicatorChange($scope.indicator);
                }
            }
        };

        $scope.incrementMetric = function() {
            if($scope.metric === 'activity') return ;

            var newIndex = selectedIndicatorIndex === $scope.myIndicators.length - 1 ? 0 :  selectedIndicatorIndex+1;

            if(newIndex !== selectedIndicatorIndex) {
                selectedIndicatorIndex = newIndex;
                $scope.indicator = $scope.myIndicators[selectedIndicatorIndex];

                if($scope.onIndicatorChange){
                    $scope.onIndicatorChange($scope.indicator);
                }
            }
        };

    });
