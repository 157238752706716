'use strict';

angular.module('bitnudgeApp').controller('addLeadCtrl_v1', function (_, $scope, $rootScope, $state, $mdDialog, Auth,
    leadsService, toaster, p0, countryList, $window, $stateParams) {
    const me = Auth.getCurrentUser();
    $scope.isMiddleManager = $rootScope.isMiddleManager;
    $scope.strings = p0.strings;
    $scope.isNotDropDown = false;
    $scope.data = {};
    $scope.currentUser = me;
    $scope.number;
    $scope.initPageLoad = true;
    $scope.isEditable = false;
    $scope.leadData = {}
    $scope.taggedUser = null;
    $scope.isReferralLead = !!$stateParams.isReferral;
    $scope.disableL3Product = true;

    if($stateParams.unassignedView == 'true'){
        $scope.unassignedView = true    
    }
    $scope.referredTo = $stateParams.referredTo;
    if($stateParams.number && $stateParams.number.length>0) {
        const pno = $stateParams.number.split(":")
        $scope.phoneNumber = `${pno[0]}${pno[1]}`
    }
    $scope.countryCodeList = leadsService.leadCountryCodeList;
    var workflowDefinitionParams = {};
    $scope.options = {};


    var createLead = function (leadData) {

        let postPayload = {
            contact: leadData.customer,
            product: { internalId: leadData.product._id },
            subProduct: { internalId: leadData.subProduct ? leadData.subProduct.internalId : "Random"},
            l3Product: leadData.l3Product,
            leadType: { internalId: leadData.type.internalId },
            leadSubType: { internalId: leadData.subType.internalId },
            amount: leadData.amount,
            leadSource: leadData.leadSource,
            preText: leadData.preText
        };
        postPayload.contact.contactNumbers = [postPayload.contact.contactNumber];
        if (!postPayload.contact.cui) {
            postPayload.contact.cui = '';
        }
        if (!postPayload.contact.email) {
            postPayload.contact.email = '';
        }
        if($scope.isReferralLead){
            postPayload.userId = $scope.leadData.selectedUser ? $scope.leadData.selectedUser._id: null;
        }else{
            if ($rootScope.isFrontline) {
                postPayload.userEmail = $scope.leadData.selectedUser ? $scope.leadData.selectedUser.email : null;
                if($scope.leadData.selectedUser && me.email ===  $scope.leadData.selectedUser.email){
                    postPayload.userId = me._id
                }
            } else {
                postPayload.userId = $scope.leadData.selectedUser ? $scope.leadData.selectedUser._id: null;
            }
        }
        if (leadData.campaignCode && !_.isUndefined(leadData.campaignCode)) {
            const index = _.findIndex($scope.campaigns, { 'campaignCode': leadData.campaignCode});
            postPayload.campaignCode = leadData.campaignCode;
            postPayload.campaignName = $scope.campaigns[index].name;
        }
        postPayload.isReferralLead = $scope.isReferralLead;
        leadsService.createLead(postPayload).then(function (response) {
            let responseData = response.data;
            if (responseData.status) {
                $scope.errors = [];
                toaster.pop('success', 'Lead creation successful.');
                if(!postPayload.userId){
                    const params = {
                        productId : postPayload.product.internalId,
                        tabName: 'unassigned'

                    }
                    $state.go('lms.listLead', params)
                }else if($scope.isReferralLead){
                    let goToCustomer360 = false;
                    if($stateParams.number && $stateParams.number.length>0) {
                        const pno = $stateParams.number.split(":")
                        if(pno.length == 2){
                            goToCustomer360 = true;
                        }
                    }
                    if(goToCustomer360){
                        $state.go('userFlow.customer', { customerId: postPayload.contact.cui });
                         // /customer/:customerId/:activeTab
                    }else{
                        const params = {
                            productId : postPayload.product.internalId,
                            tabName: 'referrals'
                        }
                        $state.go('lms.listLead', params)
                    }
                   
                }else{
                    const params = { leadId: responseData.data.lead._id}
                    if ($state.params.tab) {
                        params.productId = $state.params.tab;
                    }
                    $state.go(p0.config.leadUI_v1 ? 'userFlow.editLead_v1': 'userFlow.editLead_v2', params)
                }
            } else {
                $scope.errors = responseData.errors;
                toaster.pop('error', 'Lead creation failed.');
            }
        }).catch(function (err) {
            console.error('CreateLead API failed ', err);
            toaster.pop('error', 'Lead creation failed.');
        });
    }

    var init = function () {
        var leadProducts = [];
        $scope.lead = {};
        $scope.nationList = countryList.countries;
        fetchLeadSourcesList();
        if($scope.isReferralLead){
            p0.allProducts.forEach(function (product) {
                leadProducts.push({ name: product.shortName, _id: product._id, subProducts: product.subProducts, productCode:  product.productCode})
            });
        }else{
            p0.myProducts.forEach(function (product) {
                leadProducts.push({ name: product.shortName, _id: product._id, subProducts: product.subProducts, productCode:  product.productCode })
            });
        }
        $scope.leadProducts = leadProducts;


        leadsService.getCampaigns().then((result)=>{
            result = result.data;

            let now = new Date();
            let campaigns = _.filter(result, (campaign) =>{
                return new Date(campaign.startDate) <= now && new Date(campaign.endDate) >= now;
            });
            $scope.campaigns = campaigns;

            let leadTypes = [];
            p0.leadTypes.forEach(function (t) {
                let type = null;
                if($scope.isReferralLead && t.isReferralType){
                    type = t;
                }else if(!$scope.isReferralLead && t.isMyLeadType){
                    type = t;
                }
                if(type && ((type.internalId == 'campaign' && campaigns.length) || type.internalId !== 'campaign')){
                    leadTypes.push(type)
                }
            })
            $scope.leadTypes = leadTypes;
            if(leadTypes.length){
                $scope.lead.type = $scope.leadTypes[0];
                if($scope.lead.type && $scope.lead.type.subTypes && $scope.lead.type.subTypes.length){
                    $scope.lead.subType = $scope.lead.type.subTypes[0]
                }
            }

            if($scope.isReferralLead){
                let allJobRoleIds = [];
                // TODO: update this logic from User Access Configuration
                _.each(p0.allPlayTypes, function (playType) {
                    allJobRoleIds = allJobRoleIds.concat(playType.frontline);
                    allJobRoleIds = allJobRoleIds.concat(playType.managers);
                    allJobRoleIds = allJobRoleIds.concat(playType.seniorManagers);
                });
                allJobRoleIds = [...new Set(allJobRoleIds)]
                leadsService.getUsersOfJobRoles(me._id, allJobRoleIds).then((users)=>{
                    if($scope.referredTo) {
                        users = users.filter(user=>user._id === $scope.referredTo)
                        // force rendering ..... doesnot fire ng-change event on itself the first time
                        setTimeout(()=>{
                            $scope.onRoleTypeChange()
                        }, 200)
                    }else{
                        users = users.filter(u => u._id.toString() !== me._id.toString());
                    }

                    const usersByRoleType = _.groupBy(users, 'systemRole')
                    $scope.options.usersByRoleType = usersByRoleType;

                    $scope.options.roleTypes = [];
                    const strings = p0.strings;

                    Object.keys(usersByRoleType).forEach(r => {
                        if(r === 'seniorManager'){
                            $scope.options.roleTypes.push({
                                _id: r,
                                name: strings.SENIORMANAGER ? strings.SENIORMANAGER.value : 'Senior Manager',
                                order:3,
                            })
                        }else if(r === 'manager'){
                            $scope.options.roleTypes.push({
                                _id: r,
                                name: strings.MANAGER ? strings.MANAGER.value : 'Team Leader',
                                order:2,
                            })
                        }else if(r === 'frontline'){
                            $scope.options.roleTypes.push({
                                _id: r,
                                name: strings.FRONTLINE ? strings.FRONTLINE.value : 'RM',
                                order:1,
                            })
                        }
                    })
                    $scope.options.roleTypes = _.sortBy($scope.options.roleTypes, 'order')
                    $scope.options.usersLoaded = true;
                    if($scope.referredTo) {
                        $scope.options.roleType = $scope.options.roleTypes[0];
                    }
                }, (err)=> {
                    console.error(`Error in fetching users: ${err}`);
                })
            }else{
                if (me.roleType == "frontline") {
                    $scope.myTeam = [me];
                } else {
                    leadsService.getMyTeam().then(function (response) {
                        $scope.myTeam = response.data;
                    }, function (err) {
                        console.log(err)
                    })
                }
                if($scope.unassignedView){
                    $scope.leadData.selectedUser = null;
                }
            }

        }).catch((error) =>{
            console.log(error)
            toaster.pop('Error', 'Error in fetching campaigns details')
        })
    };

    $scope.onRoleTypeChange = ()=>{
        if(!$scope.options.roleType){
            return;
        }
        $scope.options.usersLoaded = false;
        const r = $scope.options.roleType;
        $scope.leadData.selectedUser = null;
        $scope.options.users = $scope.options.usersByRoleType[r._id];
        $scope.options.usersLoaded = true;
    }


    $scope.onSubmit = function (form) {
        if (form.$valid) {
            let formData = $scope.lead
            if(!formData.customer){
                formData.customer = {};
            }
            // if($scope.leadData.customer){
            //     if($scope.leadData.customer.middleName){
            //         $scope.leadData.customer.name += ' '+$scope.leadData.customer.middleName
            //     }
            //     if($scope.leadData.customer.lastName){
            //         $scope.leadData.customer.name += ' '+$scope.leadData.customer.lastName
            //     }
            // }
            formData.customer.name = $scope.leadData.customer.name
            formData.customer.contactNumber = $scope.phoneNumber
            formData.customer.cui = $scope.leadData.customer.cui
            formData.customer.email = $scope.leadData.customer.email;
            formData.customer.nationality = $scope.leadData.customer.nationality || '';
            formData.customer.age = $scope.leadData.customer.age;          
            formData.amount = $scope.leadData.amount;
            formData.leadSource = $scope.leadData.source;
            if ($scope.isReferralLead) {
                formData.preText = $scope.leadData.preText;
            }
            createLead(formData)
        }
    }
    $scope.onCancel = function () {
        // if ($state.params.tab)
        //     $state.go('lms.listLead', { productId: $state.params.tab });
        // else {
        //     $state.go('lms.listLead')
        // }
        $window.history.back()
    }
    $scope.onClear = function (leadForm) {
        $scope.lead = {};
        $scope.leadData = {};
        if ($scope.options && !_.isUndefined($scope.options)) {
            $scope.options.roleType = null;
            $scope.options.duplicateCUI = null;
        }
        $scope.phoneNumber = "";
        $scope.isExistingCustomer = false;
        $scope.errors = null;
        $scope.leadForm.customercui.$error = {};
        $scope.taggedUser = null;
        $scope.leadForm.$setPristine();
        $scope.leadForm.$setUntouched();
    }
    $scope.numberChange = (contact, fromState, form) => {
        if(contact.$valid || fromState) {
            $scope.initPageLoad = false;
            // const number = contact.$modelValue ? contact.$modelValue : fromState;
            let number = (contact.$modelValue ? contact.$modelValue : $scope.phoneNumber);
            // $scope.phoneNumber = number;
            leadsService.getCustomerByPhoneNumber(number).then((response) => {
                if (response.data.allow) {
                    $scope.errors = null;
                    $scope.isEditable = false;
                    $scope.isNotDropDown = true;
                    if (response.data.details) {
                        $scope.isExistingCustomer = true;
                        $scope.leadData.customer = response.data.details;
                        if (response.data.details && response.data.details.activeHandlerName) {
                            $scope.taggedUser = `${response.data.details.activeHandlerName} (${response.data.details.activeHandlerEmail})`;
                        } else {
                            $scope.taggedUser = null;
                        }
                        $scope.options.duplicateCUI = false;
                        $scope.options.duplicateCUImsg = '';
                        if(form){
                            form.customercui.$setValidity('duplicate', true);
                        }
                    }else{
                       // $scope.leadData = {}
                        $scope.isExistingCustomer = false;
                        $scope.isEditable = true;
                        oldCUIValue = null;
                        if($scope.leadData.customer && $scope.leadData.customer.cui && form){
                            $scope.isDuplicateCui($scope.leadData.customer.cui, form)
                        }
                    }

                } else {
                    toaster.pop('success', 'success', 'You can\'t create a lead with this number This number is being used as a contact number for multiple people. Continue and update number to a unique number or choose an existing customer' );
                    // $mdDialog.show(confirm).then(function() {
                    //     $scope.isExistingCustomer = false;
                    //     $scope.isEditable = true;
                    // }, function(err) {
                    //     $scope.isExistingCustomer = false;
                    // });
                    $scope.lead = {}
                    $scope.isExistingCustomer = false;
                    $scope.isEditable = true;
                }
            }).catch(err => {
                console.log(err);
                $scope.errors = [err.data];
                $scope.initPageLoad = true;
                $scope.leadData = {}
            })
        }
    }
    function searchComponent(query,key, key2) {
        var ismatched = false;
        var re = new RegExp(query, 'gi');
        return function filterFn(list) {
            if(key2)
                ismatched = list[key].match(re) || list[key2].match(re);
            else
                ismatched = list[key].match(re);

            if(ismatched)
                return true;
            else
                return false;
        };
      }

    //---angular material -----
    $scope.searchList = function(query,list,key, key2) {
        var results = query ? list.filter( searchComponent(query, key, key2) ) : list;
        return results;
    }

    $scope.updateNumber = function(customer) {
        if(customer)
        {
            const {countryCode, number } = customer.primaryContactNumber;
            $scope.phoneNumber = `${countryCode}${number}`;
            if(!$scope.lead.customer){
                $scope.lead.customer = {}
            }
            $scope.lead.customer.nationality = customer.nationality
                
        } else
            $scope.phoneNumber = "";
    }

    // leadsService.getCustomerNames(me._id, true).then(function (response) {
    //     $scope.customersList = response.data.data;
    //     $scope.data.customer = $scope.customersList[0]
    // }, function (error) {
    //     console.log(error)
    // });
    $scope.existingCustomer = function(){
        if(!$scope.isExistingCustomer){
            $scope.initPageLoad = false
            $scope.isNotDropDown = false;
            if($scope.leadData.customer && !$scope.leadData.customer.name.length){
                delete $scope.leadData.customer
            }
        }else{
            $scope.initPageLoad = true;

        }
    }

    function fetchLeadSourcesList() {
        leadsService.fetchLeadSourceList().then(function (response) {
            const allLeadSources = response.data && response.data.data;
            const defaultLeadSource = allLeadSources.find(source => source.defaultConfig.toLowerCase() === $scope.currentUser.roleType.toLowerCase());
            const leadSources = allLeadSources.filter(s => (!s.defaultConfig && s.isMyLeadSource));
            const referralLeadSources = allLeadSources.filter(source => source.isReferralSource);
            if($scope.isReferralLead){
                $scope.leadSourceList = [...new Set(referralLeadSources)];
            }else{
                $scope.leadSourceList = [defaultLeadSource, ...new Set(leadSources)];
            }
            $scope.leadData.source = $scope.leadSourceList.length ? $scope.leadSourceList[0]._id :  undefined;
        }, function (err) {
            console.log(err)
        })
    }

    if($stateParams.number){
        //no validation check on redirection as no prefilling requirement
        $scope.numberChange('',$stateParams.number)
    }

    $scope.onLeadTypeChange = () => {
        if($scope.lead.type && $scope.lead.type.subTypes){
            $scope.lead.subType = $scope.lead.type.subTypes[0];
        }else{
            $scope.lead.subType = null;
        }
    }
    $scope.onLeadProductChange = () => {
        if($scope.lead.product && $scope.lead.product.subProducts){
            $scope.lead.subProduct = $scope.lead.product.subProducts[0];
        }else{
            $scope.lead.subProduct = null;
        } 
        if (!$rootScope.config.L3MainProducts || ($scope.lead.product && $rootScope.config.L3MainProducts.indexOf($scope.lead.product.productCode) === -1)) {
            $scope.disableL3Product = true;
        } else {
            $scope.disableL3Product = false;
        }
    }

    $scope.updateUser = (userEmail) => {
        $scope.selectedUser = userEmail;
    }

    let oldCUIValue = null;
    $scope.isDuplicateCui = (cui, form) => {
        if(!oldCUIValue){
            oldCUIValue = cui
        }else if(oldCUIValue ===  cui){
            //do not hit api for same previous value
            return;
        }
        oldCUIValue = cui;
        leadsService.isDuplicateCui(cui).then(function (result) {
            result = result.data;
            if(!result.unique){
                $scope.options.duplicateCUI = true;
                const cust = result.duplicateCustomers[0];
                const {countryCode, number} = cust.primaryContactNumber;
                const contact = `${countryCode}${number}`;
                $scope.options.duplicateCUImsg = `Customer exists with NRIC: ${cust.name} (${contact})`
                form.customercui.$setValidity('duplicate', false);
            }else{
                form.customercui.$setValidity('duplicate', true);
                $scope.options.duplicateCUI = false;
                $scope.options.duplicateCUImsg = '';
            }
        }, function (err) {
            console.log(err)
        }) 
    }

    init();
});
