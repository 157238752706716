const autoImportNgTemplateLoaderTemplate1 = require('../html/cardHeader.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('cardHeader', function () {
        return {
            name: 'cardHeader',
            scope: {
                labelText: '@',
                headerText: '@',
                cycleTypes : '=',
                units : '=',
                indicator : '=',
                myIndicators : '=',
                isMoreDataCard: '@',
                canExpand: '@',
                canChangeUnit: '@',
                expandLayout: '=',
                dismissLayout: '&',
                onIndicatorChange: '=',
                onCycleTypeChange : '=',
                onUnitChange  : '=',
                metric : '=',
                canSwipeHeader : '='
            },
            controller: 'cardHeaderCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
