const autoImportNgTemplateLoaderTemplate1 = require('../html/timeoutWarningModal.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/timedOutModal.html');

'use strict';

angular.module('bitnudgeApp')
    .service('IdleUtil', function (serviceManager, Idle, $rootScope, Auth, $state,$mdDialog, $window, toaster) {
        function closeModals() {
            if ($rootScope.warning) {
                $mdDialog.hide();
                $rootScope.warning = null;
            }

            if ($rootScope.timedout) {
                $mdDialog.hide();
                $rootScope.timedout = null;
            }
        };
        var idleEnd = false;
        this.init = function () {
            if ($rootScope.config.webTimeOut) {
                if(Idle.running())
                    Idle.unwatch();
                Idle.setIdle($rootScope.config.webTimeOut);
                Idle.setTimeout(6);
                Idle.watch();
                idleEnd = false;
            }else{
                Idle.unwatch();
            }
        };
        $rootScope.$on('IdleStart', function() {
            idleEnd = false;
            closeModals();
            $rootScope.warning = $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: function timeoutController($scope, $rootScope) {
                    $scope.webTimeOut = $rootScope.config.webTimeOut;
                }
            });
        });

        $rootScope.$on('IdleEnd', function() {
            idleEnd = true;
            closeModals();
        });

        $rootScope.$on('IdleTimeout', function() {
            closeModals();
            $(".popover").each(function(){
                $(this).hide();
            });
            if(!idleEnd){
                $rootScope.timedout = $mdDialog.show({
                    templateUrl: autoImportNgTemplateLoaderTemplate2,
                    controller: function timeoutController($scope, $mdDialog) {
                        $scope.onClose = function () {
                            $mdDialog.hide();
                        }
                    }
                });
                serviceManager.logout();
                return Auth.logout().then(function(){
                    Idle.unwatch();
                    $window.localStorage.clear();
                    $state.go('login');
                })
                .catch(function(error){
                    return toaster.pop('error', 'Logout', 'Can\'t connect to server. Please try again in a moment.');
                })
            }
        });

    });

