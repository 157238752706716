'use strict';

angular.module('bitnudgeApp')
    .controller('addEmployeeCtrl', function ($rootScope, $scope, $http, Auth, p0, toaster) {
        $scope.titles = ['Mr.', 'Mrs.'];
        $scope.genders = [{ _id: 'm', name: 'Male' }, { _id: 'f', name: 'Female' }];
        $scope.countryCodes = [
            '+971',
            '+973',
            '+968',
            '+974',
            '+966'
        ];
        $scope.parents = [];
        $scope.roleTypes = [{ _id: 'FRONTLINE',"key":"frontline" }, { _id: 'MANAGER',"key":"manager" },{_id:"SENIORMANAGER","key":"seniorManager"}];
        $scope.roleTypes.forEach(function (roletType) {
            roletType.name = p0.strings[roletType._id].value;
        });
        $scope.maxDate = new Date();

        //$scope.roleTypes.push({_id:'SENIORMANAGER', name:'Senior Manager', value:'seniorManager'});
        $scope.employee = {
            joinedAt: new Date(),
            countryCode: $scope.countryCodes[0],
            gender: $scope.genders[0]._id,
            organizationalTag: {}
        };

        $scope.onRoleTypeChange = function (roleType) {
            //clear the already selected values
            $scope.employee.jobRole = {};
            $scope.employee.parentObj = {};

            //get all the job roles
            $scope.jobRoles = _.filter(p0.allJobRoles, { roleType: roleType.key });

        };

        $scope.onJobroleChange = function (jobRole) {
            var parentIds = jobRole.parents;
            $scope.employee.parentObj = {};
            $http.post('/api/users/getUsersOfJobRole',{jobRoleIds: parentIds} ).then(function (users) {
                users=users.data;
                $scope.parents = users;
                if (users.length == 1) {
                    $scope.employee.parentObj = users[0];
                }
            });
        };

        $scope.processEmployee = function (employee, isValid) {
            if (isValid) {
                var middleName = employee.middleName ? employee.middleName : '';
                employee.fullName = employee.title + ' ' + employee.firstName + ' ' + middleName + ' ' + employee.lastName;
                employee.name = employee.firstName + ' ' + employee.lastName;
                employee.gender = employee.genderObj._id;
                employee.contactNumber = employee.countryCode + employee.mobileNumber;
                employee.roleType = employee.roleTypeObj.key;
                employee.organizationalTag.jobRole = employee.jobRole._id;
                employee.parent = employee.parentObj._id;
                employee.createdAt = new Date();
                employee.updatedAt = new Date();

                if (employee.gender.toLowerCase() === 'm') {
                    employee.image = require('../images/avatarMale.jpg');
                }
                else if (employee.gender.toLowerCase() === 'f') {
                    employee.image = require('../images/avatarFemale.jpg');
                }
                else {
                    employee.image = require('../images/avatarMale.jpg');
                }

                $http.post('/api/users/create', employee).then(function (responce) {
                    responce=responce.data;
                    toaster.pop('success', "User creation", "User successfully created");
                }).catch(function (err) {
                    if (err.errorCode == '409') {
                        $scope.employee.duplicate = true;
                    } else {
                        console.log(err);
                    }
                });
            }
        };

    });
