'use strict';
angular.module('bitnudgeApp')
    .controller('rhsAnalyticsL2SocialCtrl', function ($scope, Auth, sidebarVariables, p0,rhsAnalyticsL2Social,$rootScope) {
        let self = this;

        this.init = function(){
            $scope.strings = $rootScope.strings;
            $scope.card2Data = {
                heading1:'Badges',
                badge:'Gold',
                data:[
                    [
                        {},
                        {
                            bold:'Excellence'
                        },
                        {
                            bold:'Momentum'
                        }
                    ],
                    [
                        {
                            text:'Overall'
                        },
                        {
                            bold:'1'
                        },
                        {
                            bold:'1'
                        }
                    ],
                    [
                        {
                            text:$scope.strings.IMPACT.value
                        },
                        {
                            bold:'1'
                        },
                        {
                            bold:'1'
                        }
                    ],
                    [
                        {
                            text:$scope.strings.ACTIVITY.value
                        },
                        {
                            bold:'1'
                        },
                        {
                            bold:'1'
                        }
                    ]
                ]
            };
            $scope.card3Data = {
                heading1:'Social',
                data:[
                    [
                        {
                            bold:'me'
                        },
                        {
                            bold:'Max. in Branch'
                        },
                        {
                            bold:'Max. in Bank'
                        }
                    ],
                    [
                        {
                            icon:'fa-thumbs-up',
                            iconText:'25'
                        },
                        {
                            text:'150',
                            link:'Rishi Sharma'
                        },
                        {
                            text:'30',
                            link:'Rishi Sharma'
                        }
                    ],
                    [
                        {
                            icon:'fa-users',
                            iconText:'25'
                        },
                        {
                            text:'150',
                            link:'Rishi Sharma'
                        },
                        {
                            text:'30',
                            link:'Rishi Sharma'
                        }
                    ]
                ]
            };

            $scope.me = Auth.getCurrentUser();

            //sidebars variables
            $scope.sidebarVariables = sidebarVariables;
            $scope.kpi = sidebarVariables.kpi;
            $scope.user = sidebarVariables.user;
            $scope.monthName = p0.currentMonthlyCycle.name.substring(0,3);
            $scope.weekName = p0.currentWeeklyCycle ? p0.currentWeeklyCycle.name : '';
            $scope.lastSync = $rootScope.lastUpdatedAtDate;
            $scope.config = p0.config;

            $scope.period = 'month';
            $scope.cycle = p0.currentMonthlyCycle;
        }
        $scope.$watch('sidebarVariables.kpi',function(){
            $scope.kpi = sidebarVariables.kpi;
            $scope.setData();
        });
        $scope.$watch('sidebarVariables.user',function(){
            $scope.user = sidebarVariables.user;
            $scope.setData();
        });

        //cycle
        $scope.selectCycle = function (period) {
            $scope.period = period;
            switch (period){
                case 'month':
                    $scope.cycle = p0.currentMonthlyCycle;
                    break;
                case 'week':
                    $scope.cycle = p0.currentWeeklyCycle;
                    break;
                case 'day':
                    $scope.goBack();
                    break;
            }
            $scope.setData();
        };

        $scope.setData = function() {
            return rhsAnalyticsL2Social.getData($scope.user, $scope.kpi, $scope.cycle).then( function (data) {
                $scope.data = data;
            });
        };

        $scope.goBack = function(){
            $scope.changeView('lvl1');
        };
        this.init();
    });
