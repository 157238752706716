const autoImportNgTemplateLoaderTemplate1 = require('../html/reporteesHierarchy.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('mgrDashSidePanelCtrl', function ($scope, p0, $state, $mdDialog, $rootScope, Auth) {
        var currentUser = Auth.getCurrentUser();
        $scope.filterTexts = [];
        $scope.selectedKpiId = null;
        console.log('I am mgr sidebar controller')
        // ****TODO:- Avoid using watches on $rootScope
        $rootScope.$watch('secondaryMetrices', function(newVal, oldVal){
            $scope.secondaryMetrices = newVal;
        });
        $rootScope.$watch('widgetSidePanelData', function(newVal, oldVal){
            $scope.filterTexts = [];
            $scope.widgetSidePanelData = newVal;
            for (var key in $scope.widgetSidePanelData) {
                if (key !== 'widgetName' && key !== 'kpiId' && (!_.isUndefined($scope.widgetSidePanelData[key]) && !_.isNull($scope.widgetSidePanelData[key]))) {
                    $scope.filterTexts.push({key: key, value: $scope.widgetSidePanelData[key]});
                }
                if (key === 'kpiId') {
                    $scope.selectedKpiId = $scope.widgetSidePanelData[key]
                }
            }   
        });

        $scope.secondaryMetrices = $rootScope.secondaryMetrices;
        $scope.widgetSidePanelData = $rootScope.widgetSidePanelData;


        $scope.reporteesHierarchyDialog = function (metric) {
            $rootScope.externalParams.selectedMetric = metric.metric;
            $rootScope.externalParams.kpiId = $scope.selectedKpiId;
            if (currentUser.systemRole.toLowerCase() === 'frontline') {
                if (metric.value === 0) {
                    return;
                }
                $rootScope.externalParams.loadFullData = true;
                var params = {externalParams: $rootScope.externalParams};
                if ($scope.widgetSidePanelData.widgetName.toLowerCase().includes('ticket') || metric.source === 'ticket') {
                    $state.go('lms.tickets', params);
                } else if ($scope.widgetSidePanelData.widgetName.toLowerCase().includes('lead') ||
                    $scope.widgetSidePanelData.widgetName.toLowerCase().includes('cash') || metric.source === 'lead') {
                    $state.go('lms.listLead', params);
                } else if ($scope.widgetSidePanelData.widgetName.toLowerCase().includes('customer')) {
                    $state.go('lms.customers', params);
                }
                else if ($scope.widgetSidePanelData.widgetName.toLowerCase().includes('portfolio')) {
                    var sourceParts = metric.source.split('#');
                    portfolioDialog(metric.result[0], sourceParts[1], 'me');
                }
            } else {
                $mdDialog.show({
                    templateUrl: autoImportNgTemplateLoaderTemplate1,
                    controller: 'reporteesHierarchyController',
                    resolve: {
                        leadsData: function () {
                            return metric.result;
                        },
                        widget: function() {
                            return $scope.widgetSidePanelData.widgetName;
                        },
                        label: function () {
                            return metric.label;
                        },
                        kpiId: function () {
                            return $scope.selectedKpiId || $scope.widgetSidePanelData.kpiId;
                        },
                        unit: function () {
                            return metric.unit;
                        },
                        source: function () {
                            return metric.source;
                        },
                        metricName: function() {
                            return metric.metric;;
                        }
                    }
                });
            }
        }
    });
