'use strict';

angular.module('bitnudgeApp')
  .controller('LevelCtrl', function ($stateParams, $scope,$location, Auth, $http, toaster, User) {
    $scope.getCurrentUser = Auth.getCurrentUser;
    var url = '/api/levels/index';
    let postBody = {};
    if($stateParams.id){
        url = '/api/levels/show';
        postBody.id = $stateParams.id
    }
    $http.post(url, postBody).then(function(levels){
        levels=levels.data;
        $scope.levels = levels;
        //socket.syncUpdates('level', $scope.levels);
        $scope.users = Auth.getCurrentUser();
        if($stateParams.id){
            $scope.newLevel = levels;
        }
        $scope.addLevel = function(){

            var newLevel = {};
            newLevel.levelNumber=$scope.newLevelName;
            newLevel.lowerLimit=$scope.newLowerLevel;
            newLevel.upperLimit=$scope.newUpperLevel;
            newLevel.createdAt = new Date();

            $http.post("/api/levels" ,newLevel)
                .then(function(response){
                    var data = response.data;
                    var status = response.status;
                    var statusText = response.statusText;
                    var headers = response.headers;
                    var config = response.config;
                    $location.path('/level/listLevel');
                    toaster.pop('success' , "Level Creation", "New Level Added")

                })
                .catch(function(){})
        }
    });
  })
  .controller('bulkLevelCtrl',function($scope,$location, Auth, $http, toaster, Upload, User){
      $scope.fileType = "levels";
      $scope.onFileSelectLevel = function () {
          if ($scope.bulkLevel == undefined) {
              toaster.pop('error', "Submission.", "Select File to Upload.");

          } else {
              var fileName = $scope.bulkLevel[0].name.split(".");
              var fileType = fileName[fileName.length - 1];
              if (fileType == "xls" || fileType == "xlsx" || fileType == "csv") {
                  Upload.upload({
                      url: '/api/upload',
                      data: {userId: Auth.getCurrentUser()._id,cronTag:'uploadLevels',invocationMethod:"manual"},
                      file: $scope.bulkLevel[0],
                      progress: function (e) {
                      }
                  }).then(function (data, status, headers, config) {
                      toaster.pop('success', "Excel uploaded", "Level creation successfully finished.");
                  });
              } else {
                  toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' or '.csv' Types Only.");
              }

          }
      }
});
