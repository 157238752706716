'use strict';
angular.module('bitnudgeApp')
    .service('Usage', function ($http, $interval, Auth, $rootScope, SocketService) {
        var _this = this;
        var startTime;
        var endTime;
        var duration;
        var usageInterval;
        var user;
        var socket;

        this.setStartTime = function () {
            startTime = new Date();
            checkUsage();
        };
        this.pushUsage = function () {
            if (startTime) {
                user = Auth.getCurrentUser();
                if (user && user._id) {
                    startTime = new Date(startTime);
                    endTime = new Date();
                    duration = (endTime.getTime() - startTime.getTime()) / 1000;
                    var sessions = [{userId: user._id, time: new Date(), duration: duration}];
                    var body = {actionType: 'Usage', sessions: sessions};
                    if (user.roleType != 'lmsAdmin'){
                        updateUsage(body)
                    }else{
                        _this.stopInterval();
                    }
                } else {
                    _this.stopInterval();
                }
            } else {
                this.setStartTime();
            }
        };

        this.stopInterval = function () {
            if (angular.isDefined(usageInterval)) {
                $interval.cancel(usageInterval);
                usageInterval = undefined;
            }
        };

        var updateUsage = function (body) {
            if(!socket || (socket && !socket.connected)){
                return SocketService.getSocket().then(function(_socket){
                    if(_socket.connected){
                        socket = _socket;
                        pushData(socket, body);
                    }
                })
            }else{
                pushData(socket, body);
            }
        };
        function pushData(socket, body){
            socket.emit('Usage',body);
        }

        var checkUsage = function () {
            if (angular.isDefined(usageInterval)) return;
            var interval;
            if($rootScope.config && $rootScope.config.USAGE_PUSH_SEC){
                interval = $rootScope.config.USAGE_PUSH_SEC;
            }else{
                interval = 30;
            }
            usageInterval = $interval(function () {
                if (Auth.isLoggedIn()) {
                    _this.pushUsage();
                }
                startTime = new Date();
            }, interval*1000);
        };
    });
