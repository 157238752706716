const autoImportNgTemplateLoaderTemplate1 = require('../html/admin/ability/ability.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/admin/ability/listAbility.html');
const autoImportNgTemplateLoaderTemplate3 = require('../html/admin/ability/addAbility.html');
const autoImportNgTemplateLoaderTemplate4 = require('../html/admin/ability/abilityTest.html');

'use strict';

angular.module('bitnudgeApp')
  .config(function ($stateProvider) {
    $stateProvider
        .state('ability', {
            url: '/ability',
            templateUrl: autoImportNgTemplateLoaderTemplate1,
            controller: 'AbilityCtrl'
        })
        .state('ability.listAbility', {
            url: '/listAbility',
            templateUrl: autoImportNgTemplateLoaderTemplate2,
            controller: function($scope,$http){
                $http.post('/api/abilities').then(function(abilities){
                    abilities=abilities.data;
                    $scope.abilities = abilities;
                    //socket.syncUpdates('ability', $scope.abilities);
                });
            }
        })
        .state('ability.addAbility', { //TODO: Deprecated
            url: '/addAbility',
            templateUrl: autoImportNgTemplateLoaderTemplate3,
            controller: function($http,$scope,Auth,$location, $q, toaster){
                $scope.addAbility = function(){
                    var name= $("#ability_name").val();
                    $http.post('/api/abilities', {
                        name: name
                    }).then(function(responce) {
//                            $location.path('/ability/listAbility');
//                            toaster.pop('success', "ability Creation", "New ability Added.");
                        }).catch(function(data, status, headers, config) {

                        });
//
                }
                $http.post('/api/abilities').then(function(abilities){
                    abilities=abilities.data;
                    $scope.abilities = abilities;
                    //socket.syncUpdates('ability', $scope.abilities);
                });
                $scope.users = User.query(); //TODO: Deprecated
                $scope.addAbility = function(){
                    var name= $("#ability_name").val();
                    $http.post('/api/abilities', {
                        name: name
                    }).success(function(data) {
//                            $location.path('/ability/listAbility');
//                            toaster.pop('success', "ability Creation", "New ability Added.");
                        }).catch(function(data, status, headers, config) {

                        });
//
                }
            }

        })
        .state('ability.abilityTest', {
            url: '/addAbility',
            templateUrl: autoImportNgTemplateLoaderTemplate4,
            controller: function($http,$scope,Auth,$location, $q, toaster){
                $scope.addAbility = function(){
                    var name= $("#ability_name").val();
                    $http.post('/api/abilities', {
                        name: name
                    }).then(function(responce) {
//                            $location.path('/ability/listAbility');
//                            toaster.pop('success', "ability Creation", "New ability Added.");
                        }).catch(function(data, status, headers, config) {

                        });
//
                }
                $http.post('/api/abilities').then(function(abilities){
                    abilities=abilities.data;
                    $scope.abilities = abilities;
                    //socket.syncUpdates('ability', $scope.abilities);
                });
                $scope.users = User.query();

            }
        })
  });
