const autoImportNgTemplateLoaderTemplate1 = require('../html/gameAnalytics.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('gameAnalytics', function () {
        return {
            name: 'gameAnalytics',
            scope: {
                values: '=',
                toggles: '='
            },
            controller: 'gameAnalyticsCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
