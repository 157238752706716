'use strict';

angular.module('bitnudgeApp').controller('addPosLeadCtrl', function ($scope,$mdDialog,leadsService,Auth,p0,$http, $stateParams, toaster, parameters, $rootScope, exitStatus, fetchLeads, countryList) {
    var duplicateLeadAllowedStatus = ['Freepool', 'Interested', 'Not yet contacted', 'Undecided'];
    $scope.me = Auth.getCurrentUser();
    $scope.statusList = p0.posLeadGenerationAllowedStatusList;
    $scope.productList = p0.posLeadProductList
    $scope.niRemarksList = p0.posNiRemarksList
    $scope.countryList = p0.countryList;
    $scope.leadInfo = {};
    $scope.leadInfo.leadStatus = $scope.statusList[0];

    $scope.hide = function(answer) {
        $mdDialog.hide(answer);
    };

    $scope.cancel = function() {
        $mdDialog.cancel();
    };

    $scope.answer = function(answer) {
        $mdDialog.hide(answer);
    };

    $scope.verifyUniqueId = function(idName, idNumber){
        if($scope.leadInfo[idName] != undefined && $scope.leadInfo[idName].length >= 6 && $scope.leadInfo[idName].length <= 8) {
            $http.post('/api/leads/verifyUniqueId/', {idName: idName, idValue: $scope.leadInfo[idName]}).then(function(result){
                result=result.data;
                if(result.err)
                    console.log('Error in validating unique id: '+idName)
                else
                    $scope.employerIdDuplicate = !result.valid
            })
        }
    }

    $scope.checkDuplicate = function(){
        if ($scope.leadInfo.countryCode != undefined && $scope.leadInfo.countryCode != '' && $scope.leadInfo.mobileNo != undefined && $scope.leadInfo.mobileNo != '' && $scope.leadInfo.productRequired != undefined) {
            const postBody = {
                mobileNumber: $scope.leadInfo.countryCode+$scope.leadInfo.mobileNo,
                productRequired: $scope.leadInfo.productRequired
            }
            $http.post('/api/leads/checkDuplicatePosLead', postBody)
                .then(function(response){
                    $scope.duplicatePosLead = response.data.duplicate;
                });
        }
    }

    $scope.removeSpaces = function(mobile){
        if(mobile){
            mobile = mobile.replace(/[^\d]/g,'');
            return mobile.substring(0,9);
        }
    };
    $scope.verifyAndSubmitLead = function(form ,isValid){
        if(isValid){
            var lead = {
                leadStatus: $scope.leadInfo.leadStatus,
                contactNumber: $scope.leadInfo.countryCode+$scope.leadInfo.mobileNo,
                companyName: $scope.leadInfo.companyName,
                networkId: $scope.me.email,                       //creator id
                userName: $scope.me.name,                         //creator name
                userBusiness: p0.myPlayTypes[0].name,            //creator playtype
                userId: $scope.me._id,
                businessNature: $scope.leadInfo.businessNature,
                productRequired: $scope.leadInfo.productRequired,
                merchantAddress: $scope.leadInfo.merchantAddress,
                emailId: $scope.leadInfo.emailId,
                niRemarks: $scope.leadInfo.niRemarks,
                machineCount: $scope.leadInfo.machineCount,
                contactPerson: $scope.leadInfo.contactPerson,
                leadType: 'POS Leads',
                leadSubType: 'POS Leads'
            };

            var req = {
                user: $scope.me,
                leads: [lead]
            };

            $http.post('/api/leads/createPosLeads', req).then(function(response) {
                response=response.data;
                if(response.status == 500){
                    // toaster.pop('error', response.message, response.details);
                    var alert = $mdDialog.alert({
                        title: response.message,
                        textContent: response.details,
                        ok: 'Close'
                    });
                    $mdDialog.show(alert).then(function() { alert = undefined;});
                }else{
                    var alert = $mdDialog.alert({
                        title: response.message,
                        textContent: response.results,
                        ok: 'Close'
                    })
                    toaster.pop('success', "Lead Created", 'POS Lead created successfully!!');
                    fetchLeads();
                    $scope.hide(true);
                }
            }).catch(function(err){
                //$scope.hide();
            });
            $scope.hide();
        }else{
            console.log('form is not valid')
            //$scope.disableSubmit = false;
        }
    };
    $scope.formatPhoneNumber = function(tel){
        if(tel){
            return tel.slice(0,4)+' '+tel.slice(4,6)+' '+tel.slice(6,9)+' '+tel.slice(9);
        }
    }

});
    // $scope.addNewProduct = function(){
    //     if($scope.customerInfo.interactionType && $scope.customerInfo.interactionType!=='Not Reachable'){
    //         if($scope.myAvailableProducts.length > 0){
    //             $scope.AllProductList.push({'kpiName':'','amount':'','status':'','followUpDate':new Date(), disableKpiSelect:false});
    //         }
    //     }else{
    //         toaster.pop('Lead update', "", 'Kindly select Interaction Type');
    //     }
    // };
    // $scope.removeProduct = function(index){
    //     $scope.AllProductList.splice(index, 1);
    // };