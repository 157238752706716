const autoImportNgTemplateLoaderTemplate1 = require('../html/performanceIndicators.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('performanceIndicators', function () {
        return {
            name: 'performanceIndicators',
            scope: {
                selectedUser:         '=',
                selectedCycle:  '=',
                selectedKpi:    '=',
                selectedDivision: '=',
                selectedPlayType: '='            },
            controller: 'performanceIndicatorsCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
