const autoImportNgTemplateLoaderTemplate1 = require('../html/lab.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('lab', function () {
        return {
            name: 'lab',
            scope: {
                selectedCycle:  '=',
                selectedKpi:    '=',
                resize:         '=',
                selectedUser:   '=',
                selectedPlayType:   '='
            },
            controller: 'labCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1

        };
    });
