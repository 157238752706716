'use strict';
angular.module('bitnudgeApp')
    .service('newsAndUpdatesService', function ($http, $q, p0) {
        var _this= this;
        this.starPlayers = {};
        this.badgeAchievers = {};
        this.rollingBadges = {};
        this.badgeFeed = {};
        this.topPerformers = {};
        this.monthStarPlayers = {};
        this.getStarsOfTheDay = function(roleType,monthlyCycleId){
            if(!_this.starPlayers[roleType]){
                _this.starPlayers[roleType] = {};
            }
            if(_this.starPlayers[roleType][monthlyCycleId]){
                return $q.resolve(_this.starPlayers[roleType][monthlyCycleId]);
            }else{
                return $http.post('/api/userAnalytics/getStars',{cycleId:monthlyCycleId, roleType}).then(function(stars){
                    stars=stars.data;
                    stars.forEach(function (starPlayer) {
                        var role = _.find(p0.allJobRoles,{_id:starPlayer.organizationalTag.jobRole});
                        starPlayer.jobRole = role.name;
                    });
                    _this.starPlayers[roleType][monthlyCycleId] = stars;
                    return $q.resolve(stars);
                });
            }
        };

        this.getStarsOfTheMonth = function(roleType,monthlyCycleId){
            if(!_this.monthStarPlayers[roleType]){
                _this.monthStarPlayers[roleType] = {};
            }
            if(_this.monthStarPlayers[roleType][monthlyCycleId]){
                return $q.resolve(_this.monthStarPlayers[roleType][monthlyCycleId]);
            }else{
                return $http.post('/api/userAnalytics/getStarsForMonth',{cycleId:monthlyCycleId, roleType}).then(function(stars){
                    stars=stars.data;
                    var finalStars = [];
                    _.forOwn(stars,function(value,cycleId){
                        var tempObj = {stars:value};
                        var currentCycle = _.find(p0.allCycles,{_id:cycleId});
                        if(currentCycle){
                            tempObj.cycle = currentCycle;
                            finalStars.push(tempObj);
                        }
                    });
                    finalStars = _.sortBy(finalStars,'cycle.startDate');
                    _this.monthStarPlayers[roleType][monthlyCycleId] = finalStars;
                    return $q.resolve(finalStars);
                });
            }
        };

        this.fetchBadgeAchievers = function (roleType, monthlyCycleId, weeklyCycleId) {
            var cycleId;
            if(weeklyCycleId){
                cycleId = weeklyCycleId
            }else{
                cycleId = monthlyCycleId;
            }
            if (_this.badgeAchievers[cycleId] && _this.badgeAchievers[cycleId][roleType]) {
                return $q.resolve(_this.badgeAchievers[cycleId][roleType]);
            }
            else {
                return $http.post('api/analytics/getBadgeAchievers', {cycleId: monthlyCycleId, weeklyCycleId}).then(function(badgeAchiever){
                    badgeAchiever=badgeAchiever.data;
                    if (!_this.badgeAchievers[cycleId]) _this.badgeAchievers[cycleId] = {};
                    _this.badgeAchievers[cycleId][roleType] = badgeAchiever;
                    return $q.resolve(_this.badgeAchievers[cycleId][roleType]);
                });
            }
        };

        this.fetchBadgeFeeds = function(roleType,cycleId){
            if(!_this.badgeFeed[roleType]){
                _this.badgeFeed[roleType] = {};
            }
            if(_this.badgeFeed[roleType][cycleId]){
                return $q.resolve(_this.badgeFeed[roleType][cycleId]);
            }else{
                return $http.post('/api/dailyFeeds/getBadgeFeed',{cycleId, playerType: roleType}).then(function(badgeFeed){
                    badgeFeed=badgeFeed.data;
                    _this.badgeFeed[roleType][cycleId] = badgeFeed;
                    return $q.resolve(badgeFeed);
                });
            }
        };

        this.fetchTopPerformers = function(roleType,cycleId){
            if(!_this.topPerformers[roleType]){
                _this.topPerformers[roleType] = {};
            }
            if(_this.topPerformers[roleType][cycleId]){
                return $q.resolve(_this.topPerformers[roleType][cycleId]);
            }else{
                return $http.post('/api/leagueAnalytics/topPerformers', {playerType:roleType, cycleId}).then(function(badgeFeed){
                    badgeFeed=badgeFeed.data;
                    _this.topPerformers[roleType][cycleId] = badgeFeed;
                    return $q.resolve(badgeFeed);
                });
            }
        };

        this.fetchRollingBadges = function (jobRole, cycleId) {
            if (_.isArray (jobRole)) jobRole = JSON.stringify(jobRole);
            return $http.post("/api/rollingBadges/jobrole", {jobRole, cycleId}).then(function (result) {
                return $q.resolve( result.data);
            }, function (error) {
                console.log (error);
            });
        }
    });
