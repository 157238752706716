'use strict';
angular.module('bitnudgeApp')
    .service('earnStatement', function ($http,p0, $q) {
        var kpiActivities = ['call','meeting','submission','newLead','email'];
        var playBookActivities = [];
        var getActivities = function () {
            _.forEach(p0.myKpis, function (kpi) {
                var activities = kpi.activities;

                var pbActivities = _.filter(p0.myActivities, function (activity) {
                    return activities.indexOf(activity._id) != -1 && kpiActivities.indexOf(activity.fieldName) != -1;
                });
                playBookActivities = playBookActivities.concat(pbActivities);
            });
            playBookActivities  = _.uniqBy(playBookActivities,function(activity){
                            return activity.fieldName;
                        });
            playBookActivities = _.sortBy(playBookActivities, ['order']);
            playBookActivities = _.map(playBookActivities,'fieldName');
        };
        if(p0.myKpis){
            getActivities();
        }

        this.getActivities = function () {
            if(p0.myKpis && !playBookActivities.length){
                getActivities();
            }
            return playBookActivities;
        };

        this.getEarnStatement = function(cycle){
            return $http.post('/api/userAnalytics/getEarnStatement',{cycleId:cycle._id}).then(function(earnStatement){
                earnStatement=earnStatement.data;
                var relevantKpiIds = _.map(p0.myKpis,'_id');
                const myKpisById = _.groupBy(p0.myKpis, '_id');
                earnStatement.kpiData = _.filter(earnStatement.kpiData,function(kpiData){
                    return _.includes(relevantKpiIds,kpiData.kpiId);
                });
                earnStatement.kpiData.forEach(function(kpiData){
                    kpiData.activityPoints = kpiData.other.points;
                    kpiData.sequence = myKpisById[kpiData.kpiId][0].sequence;
                    if(playBookActivities.length){
                        _.forEach(playBookActivities, function (activity) {
                            if(kpiData[activity].points){
                                kpiData.activityPoints = kpiData.activityPoints + kpiData[activity].points;
                            }
                        })
                    }
                    kpiData.totalPoints = kpiData.points + kpiData.activityPoints;
                });
                earnStatement.kpiData = _.orderBy(earnStatement.kpiData, 'sequence', 'asc')
                return $q.resolve(earnStatement);
            });
        };

        this.dayWiseStatement = function(cycle) {
            return $http.post('/api/userAnalytics/getDayWiseUserAnalytics',{cycleId: cycle._id}).then(function (userData) {
                userData=userData.data;
                return $q.resolve(userData);
            });
        }
    });

