'use strict';
angular.module('bitnudgeApp')
    .controller('hallOfFameCtrl', function($rootScope, $scope,Auth, p0, $timeout, newsAndUpdatesService, CurrentCycle){
        var that = this;
        $scope.me = Auth.getCurrentUser();
        $scope.strings = $rootScope.strings;
        $scope.lastSyncDate = $rootScope.lastUpdatedAtDate;
        $scope.badgeAchieversLoaded = false;
        $scope.allPlayTypes = _.cloneDeep(p0.allPlayTypes);
        $scope.showLastSync = true;

        $scope.isManager = !$rootScope.isFrontline;
        $scope.showPlayTypes = p0.allPlayTypes.length>1;
        $scope.monthlyCyclesAsOfDate = _.filter(p0.allCycles, function(cycle){
            return  cycle.cycleType == 'monthly' && new Date(cycle.startDate).getTime() <= new Date($rootScope.lastUpdatedAtDate).getTime();
        });

        function setAsOfDate(cycle) {
            if($rootScope.lastUpdatedAtDate.getTime() >= new Date(cycle.startDate).getTime()  && $rootScope.lastUpdatedAtDate.getTime()  < new Date(cycle.endDate).getTime() ){
                $scope.asOfDate = $rootScope.lastUpdatedAtDate;
            }else if($rootScope.lastUpdatedAtDate.getTime()  > new Date(cycle.startDate).getTime() ) {
                $scope.asOfDate = new Date(cycle.endDate);
            }
        };
        $scope.selectedCycle = $scope.monthlyCyclesAsOfDate[0];

        $scope.init = function(){
            setAsOfDate($scope.selectedCycle);
            getStars($scope.selectedCycle);
        };
        $scope.playTypeChanged = function(playType){
            $scope.selectedPlayType = playType;
            $scope.init();
        };
        $scope.setPlayTypes = function(selectedCycle){
            $scope.frontLineRoles = [];
            $scope.managerRoles = [];
            $scope.playTypes = $scope.allPlayTypes;
            $scope.playTypes.forEach(function(playType){
                $scope.frontLineRoles.push(playType.frontline);
                $scope.managerRoles.push(playType.manager);
            });
            $scope.playTypes.unshift({name:'All '+ $rootScope.strings.PLAYTYPE.value + 's',_id:'overall'});
            if(p0.allPlayTypes.length == 1){
                $scope.selectedPlayType = $scope.playTypes[1]; // if there is only one playType select that playType directly
            }else{
                $scope.selectedPlayType = $scope.playTypes[0];
            }
            // $scope.playTypeChanged($scope.selectedPlayType);
        };

        $scope.cycleChanged = function(cycle){
            $scope.selectedCycle = cycle;
            $scope.showLastSync = cycle.active;
            CurrentCycle.setCycle(cycle);
            setAsOfDate($scope.selectedCycle);
            $scope.setPlayTypes(cycle);
            $scope.init();
        };
        $scope.cycleChanged($scope.selectedCycle);

        function getStars(selectedCycle){
            newsAndUpdatesService.getStarsOfTheMonth($scope.isManager?'manager':'frontline',selectedCycle._id).then(function(stars){
                $scope.playTypes[0].stars = stars;
                $scope.playTypes.forEach(function (playType,playTypeIndex) {
                    $scope.playTypes[playTypeIndex].stars = [];
                    stars.forEach(function(star){
                        var selectedStar = _.find(star.stars,function(userAnalytic){
                            return userAnalytic.playTypes[0].toString() == playType._id.toString();
                        });
                        var clonedStar = _.cloneDeep(star);
                        clonedStar.star = selectedStar;
                        $scope.playTypes[playTypeIndex].stars.push(clonedStar);
                    });
                });
                $scope.starsLoaded = false;
                $timeout(function(){
                    $scope.starsLoaded = true;
                },1);
            });
        }

        $scope.showFullList = function (badgeRow, format) {
            var data = {};
            if (format=='starPlayers') {
                data.users = badgeRow;
            }
            else {
                data = badgeRow
            }
            $rootScope.$emit ('showFromMain', {show: format, data: data});
        };
    });
