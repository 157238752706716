'use strict';
angular.module('bitnudgeApp')
    .controller('rhsCardBarCtrl', function ($scope) {
        $scope.selectedValue = [];
        $scope.benchMarkChange = function(i){
            var data = _.find($scope.data.data[i].selection,{label:$scope.selectedValue[i]});
            $scope.data.data[i].value = data.value;
            $scope.data.data[i].number = data.number;
        };

        $scope.$watch('data',function(){
            $scope.data.data.forEach(function (row, i) {
                if (row.selection) {
                    $scope.selectedValue[i] = row.selection[0].label;
                    $scope.benchMarkChange(i);
                }
            });
        });

    });
