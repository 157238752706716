const autoImportNgTemplateLoaderTemplate1 = require('../html/frontlineSwot.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('frontlineSwot', function () {
        return {
            name: 'frontlineSwot',
            scope: {},
            controller: 'frontlineSwotCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
