'use strict';
angular.module('bitnudgeApp')
    .controller('chatPlayersListModalCtrl', function ($scope,$rootScope, DTOptionsBuilder, playerList, $uibModalInstance, Auth, $http, Gender) {
        let self = this;
        this.init = function(){
            $scope.playerList = playerList;
            if(!playerList.image){
                $scope.playerList.image  = Gender.getAvatarImage();
            }
            return $http.post('/api/users/count').then(function(users){
                users=users.data;
                $scope.usersCount = users;
            });
        }
        $scope.onClose = function() {
            $uibModalInstance.close();
        };
        
        this.init();
    });
