const autoImportNgTemplateLoaderTemplate1 = require('../html/rewardsModal.html');


'use strict';
angular.module('bitnudgeApp')
.controller('CampaignsNewController', function ($scope, $timeout, $rootScope, $http, Auth,  moment, $filter,$q, p0, $mdDialog) {
    $scope.me = Auth.getCurrentUser();
    $scope.strings=$rootScope.strings;
    $scope.config = $rootScope.config;
    $scope.managerEnabled = $rootScope.isMiddleManager;
    $scope.myDivisions = p0.myDivisions;
    $scope.contestLoaded = false;

    $http.post('/api/campaigns').then(function (campaigns) {
        campaigns=campaigns.data;
        $scope.campaigns =[];
        var activeCampaigns = _.filter(campaigns,{active:true});
        if(activeCampaigns.length)$scope.campaigns.push({name:'Live',campaigns:activeCampaigns});
        var pastCampaigns = _.filter(campaigns,{active:false});
        if(pastCampaigns.length)$scope.campaigns.push({name:'Past',campaigns:pastCampaigns});
        $scope.selectedCampaign = $scope.campaigns[0];
        $scope.selectedCampaignIndex = 0;
    });

	$scope.setCampaignData = function(campaign){
		$scope.campaignsLoaded = false;
		$scope.campaignData = campaign.campaigns;
		setTimeout(function(){
			$scope.campaignsLoaded = true;
		},10);
        $scope.getContests($scope.campaignData[0],0);
	};
	$scope.viewAllRewards = function(rewards, leaders, isRewards){
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate1,
            controller: function ($scope, rewards,leaders, isRewards, $mdDialog) {
                $scope.rewards = rewards;
                $scope.leaders = leaders;
                $scope.isRewards = isRewards;
                $scope.onClose = function(){
                    $mdDialog.hide();
                }
            },
            resolve: {
                rewards : function(){return rewards},
                leaders: function(){return leaders},
                isRewards : function () { return isRewards}
            }
        });
    };
    $scope.slickConfig = {
        method: {},
        dots: false,
        infinite: false,
        // slidesToShow: 1,
        slidesToScroll: 1,
        arrows:true,
        draggable:true,
        autoplay: false
    };
    var allContests;
    $scope.selectedCampaignId = null;
	$scope.getContests = function(campaign, index){
	    $scope.contestLoaded = false;
        $scope.selectedCampaignId = campaign._id;
        $http.post('/api/contests/getContestByCampaignId', {campaignId: campaign._id}).then(function (contests) {
            contests=contests.data;
            allContests = contests;
            $scope.contestLoaded = true;
            getContestsOfRole(contests,index);
        });
	};
	function getContestsOfRole(contests, index){
        var roleType = 'managers';
        if(!$scope.managerEnabled) roleType = 'frontline';
        var temp_contests;
        if(!$rootScope.isSeniorManager){
            var myDivId = p0.myDivisions[0]._id;
            temp_contests = _.filter(contests, function(contest){
                return _.includes(contest.divisions,myDivId);
            })
        }else temp_contests = contests;
        $scope.contests = _.filter(temp_contests, function(contest){
            return contest.roleType == roleType;
        });
        if($scope.contests != undefined && $scope.contests.length > 0)
            $scope.selectedContest = $scope.contests[0];
        $scope.selectedCampaignIndex = index;
    };

	$scope.switchChanged = function (value) {
        getContestsOfRole(allContests, $scope.selectedCampaignIndex);
    };

	$scope.$watch('selectedCampaign', function(newValue, oldValue){
		if(newValue){
			$scope.setCampaignData(newValue);
		}
	})
});
