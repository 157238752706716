const autoImportNgTemplateLoaderTemplate1 = require('../html/compareMetric.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('compareMetric', function () {
        return {
            name: 'compareMetric',
            scope: {
                values: '=',
                toggles: '=',
                absolute: '=',
                hideUnit: '=',
                header: '='
            },
            controller: 'compareMetricCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1,
        };
    });
