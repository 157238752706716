const autoImportNgTemplateLoaderTemplate1 = require('../html/liveBadgeUpdate.html');

'use strict';

angular.module('bitnudgeApp')
    .directive('liveBadgeUpdate', function () {
        return {
            name: 'liveBadgeUpdate',
            scope: {
                badgeData:     '=',
                selectedCycleType: '='
            },
            controller: 'liveBadgeUpdateController',
            restrict: 'EA',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
