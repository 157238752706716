'use strict';

angular.module('bitnudgeApp').controller('leadUploadCtrl',function($scope,Upload, Auth, parameters,$mdDialog, toaster,leadsService, Sidebar, UtilService){
    var user = leadsService.getCurrentUser();
    $scope.headname = parameters.modalName;
    $scope.me = Auth.getCurrentUser()
    $scope.url = parameters.url;
    var successMessage = '';
    if($scope.url == '/api/upload'){
        successMessage = "We will notify you when leads are processed. Check the notifications section for updates";
    }else if($scope.url == '/api/leads/importCallSheet/lead/'){
        successMessage = "Lead creation successfully finished.";
    }
    
    $scope.getFormat = function() {
        const appUrl = Sidebar.getAppUrl();
        const baseUrl = appUrl+'/api/leads/getUploadExcelFormat';

        UtilService.downloadFile(baseUrl);
        $scope.cancel();
    }

    $scope.getGlossary = function () {
    	const appUrl = Sidebar.getAppUrl();
        const baseUrl = appUrl + '/api/glossary';
    	UtilService.downloadFile(baseUrl, {type:'lead'});
    	return $scope.cancel();
    };

    $scope.getInstructions = function () {
    	const appUrl = Sidebar.getAppUrl();
    	const baseUrl = appUrl + '/api/leads/getInstructions';

    	UtilService.downloadFile(baseUrl);
    	return $scope.cancel();
    }

    $scope.onFileUpload = function(file,url) {
        var $file = $scope.files[0];
        var fileName = $file.name.split(".");
        var fileType = fileName[fileName.length - 1].toLowerCase();
        if (fileType == "xlsx" || fileType == "xls" || fileType == "xlsm") {
            var uploader = Upload.upload({
                url: $scope.url,
                data: {
                    userId: user,
                    fileName: fileName,
                    asOfDate: new Date().getTime(),
                    cronTag: 'uploadLead',
                    lead0: $file,
                },
                responsetype: 'application/json',
                progress: function(e) {}
            }).then(function(result){
                result=result.data;
                toaster.pop('info', "Excel uploaded", successMessage);
                // if(result.exceptions != undefined) {
                //     if(result.operations.length == 0)
                //     toaster.pop('warning', "Excel uploaded!!", 'There were '+result.exceptionsCount+' \"Exceptions\" and '+result.successCount+' \"Successful\" operations.!!');                    
                //     else                        
                //     toaster.pop('failure', "Excel uploaded!!", 'There were '+result.exceptionsCount+' \"Exceptions\" and '+result.successCount+' \"Successful\" operations.!!');                    
                //     if($scope.me.roleType != 'lmsAdmin') {
                //         var file = ngXlsx.writeXlsx([result.exceptions]);
                //         var date = new Date()
                //         var timestamp = new Date(); 
                //         timestamp = timestamp.toString().substring(0, 24)
                //         saveAs(new Blob([s2ab(file)],{type:"application/octet-stream"}), getTimestamp()+'-Leads-Errors.xlsx');
                //     }
                // } else {
                //     toaster.pop('success', "Excel uploaded!!", 'No exceptions in the operation!!');
                // }
                $scope.hide();
            }).catch(function(res){
                if(res.status == 310) {
                    toaster.pop('warning', "Excel could not be uploaded!!", 'Sorry!! Headers in the excel file are not appropriate!!');                    
                }
                $scope.hide();
            })
        }else{
            toaster.pop('error', "Submission.", "Upload '.xlsx' or '.xls' file only.");
            $scope.hide();
        }
    };

    $scope.hide = function() {
        $mdDialog.hide();
    };

    $scope.cancel = function() {
        $mdDialog.cancel();
    };

    $scope.answer = function(answer) {
        $mdDialog.hide(answer);
    };
});

