'use strict';
angular.module('bitnudgeApp')
	.controller('logTriggerController', function ($scope, $mdDialog, p0, ProductService, DocumentService, BadgeService, ActivityService, CustomerService, userAnalyticsService, toaster, TriggerService, WorkflowService, $filter, DTColumnDefBuilder, DTOptionsBuilder) {
		/**
		 * this trigger is going to have different set of options
		 * based on trigger field selected
		 */
		const exceptionDateTrigger = ['ticketMetadataDate'];
		// const
		const interactionCategories = [
			{ name: 'Leads', _id: 'lead' },
			{ name: 'Tickets', _id: 'ticket' },
			{ name: 'Customers', _id: 'customer' }
		];
		$scope.dtOptions = DTOptionsBuilder.newOptions().withDOM('C<"clear">lfrtip');
		$scope.dtColumnDefs = [
			DTColumnDefBuilder.newColumnDef(5).notSortable()
		];

		//#endregion
		const camelCase = $filter('camelCaseToHuman');
		//hash map created for the conditions to make it human readable
		var conditionsMap = new Map();
		conditionsMap.set('$eq', 'equal to');
		conditionsMap.set('$lt', 'less than');
		conditionsMap.set('$gt', 'greater than');
		conditionsMap.set('$gte', 'greater than or equals to');
		conditionsMap.set('$lte', 'less than or equals to');

		const ticketDateFields = {
			CREATED_AT: 'createdAt',
			UPDATED_AT: 'updatedAt',
			FOLLOWUP_DATE: 'followUpDate',
			REQUIRE_CLOSURE_DATE: 'requiredClosureDate'

		}

		function refresh(){
			$scope.editOpen = false;
			const params = {
				allAlarms : true
			}
			TriggerService.getAllAlarms(params).then(function (result) {
				$scope.alarms = result;
			}, function (error) {
				console.log(error)
			})
		}

		$scope.cancelTrigger = () => {
			$scope.editOpen = false;
			$scope.selectedAlarmIndex = -1;
		}

		$scope.editAlarm = (ev, alarmId, index) => {
			$scope.selectedAlarmIndex = index;
			$scope.editOpen = false;
			TriggerService.getAlarmDetail(alarmId).then(function (result) {
				const { data } = result;

				if (!data.data) {
					return;
				}

				$scope.selectedAlarm = data.data[0];

				TriggerService.getAllTriggers().then(function (responseTriggers) {

					$scope.filtereredAlert = responseTriggers.filter((alarm) => {
						if (alarm.name === $scope.selectedAlarm.trigger.name) {
							return true;
						}
					})

					$scope.triggerNames = _.map($scope.filtereredAlert);
					$scope.triggerNameObj = $scope.filtereredAlert[0];
					$scope.editOpen = true;

					if ($scope.triggerNameObj.name == "state_trigger") {
						getAllWorkflowDefinitions();
						setTrigger($scope.triggerNameObj)
					} else {
						getExcelHeaders();
					}

				}, function (error) {
					console.log(error)
				})
				//get all the Intervals possible for the triggers
				TriggerService.getTriggerIntervals().then(function (response) {
					$scope.intervals = response.data;

					$scope.interval = $scope.selectedAlarm.interval ? $scope.selectedAlarm.interval : $scope.intervals[0];

				}, function (err) {
					console.log(err)
				})
				DocumentService.getAllDocumentTypes().then((documentTypes) => {
					$scope.documentTypes = documentTypes;
					const selectedDocumentTypeId = $scope.selectedAlarm.trigger.options.documentTypeId;
					if (selectedDocumentTypeId) {
						$scope.documentType = $scope.documentTypes.find(type => {
							if (type._id === selectedDocumentTypeId) {
								return type;
							}
						})
						$scope.changeDocumentType($scope.documentType)
					} else {
						$scope.changeDocumentType($scope.documentTypes[0])
					}
				}).catch((err) => {
					toaster.pop('error', 'Document Types', err.message)
					console.error(err)
				})

				getSubProductsMetadata();

				ProductService.getProductConfigs().then(function (products) {
					$scope.products = products;

					//set if the product is present
					const selectedAlarmProductId = $scope.selectedAlarm.trigger.options.parentProductId
					if(selectedAlarmProductId){
						$scope.product = $scope.products.find( product => {
							if(product._id === selectedAlarmProductId){
								return true;
							}
						})
					}else{
						$scope.product = $scope.products[0]
					}
					BadgeService.getAllBadges().then(function (badges) {
						if (badges.length) {
							$scope.badges = badges;
							filterBadgesOnProduct(false);
						} else {
							toaster.pop('error', 'Badges', 'No Badges found')
						}
					}, function (error) {
						const message = error && error.message ? error.message : 'Error occured while fetching Badges.'
						toaster.pop('error', 'Badges', message)
						console.log(error)
					})
				}, function (error) {
					toaster.pop('error', 'Products', error.message)
					console.log(error)
				})

				ActivityService.getAllActivities().then(function (result) {
					$scope.activityObjs = result;
					if ($scope.selectedAlarm && $scope.selectedAlarm.trigger &&
						$scope.selectedAlarm.trigger.options && $scope.selectedAlarm.trigger.options.activity) {
							$scope.activityObj = _.find($scope.activityObjs, {'name': $scope.selectedAlarm.trigger.options.activity.name});
					} else {
						$scope.activityObj = $scope.activityObjs[0];
					}
					userAnalyticsService.getSingleActivityInUserAnalytic(p0.currentMonthlyCycle._id).then(function (result) {
						if (result) {
							$scope.userAnalytic = result;
							$scope.userAnalyticAcitivity = result.activityData[0];
							$scope.checkProductExist($scope.activityObj, false)
							prepareOverallMetrics(result);
							prepareKPIMetrics(result);
						} else
							toaster.pop('error', 'UserAnalytic', "No Useranalytic found")
					}, function (error) {
						toaster.pop('error', 'UserAnalytic', error.message)
					})
				}, function (err) {
					const message = err && err.message ? err.message : 'Error occured while fetching Activities.'
					toaster.pop('error', 'Activity', message)
					console.log(err)
				})

			}, function (error) {
				console.log(error, '---error')
				// toaster
			})
		}

		$scope.interactionCategories = interactionCategories;
		$scope.selected = {
			kpis: p0.allKpis
		};


		const prepareOverallMetrics = function (userAnalytic) {
			$scope.selected.overallMetrics = [];
			Object.keys(userAnalytic).forEach(key => {
				if (userAnalytic[key] instanceof Object && userAnalytic[key].value != undefined) {
					let obj = {
						key: key,
						name: camelCase(key),
						value: userAnalytic[key].value,
						delta: userAnalytic[key].delta,
					};
					$scope.selected.overallMetrics.push(obj)
				}
			});
		};

		const prepareKPIMetrics = function (userAnalytic) {
			let kpiData = userAnalytic.kpiData[0];
			$scope.selected.kpiMetrics = [];
			Object.keys(kpiData).forEach(key => {
				if (kpiData[key] instanceof Object && kpiData[key].value != undefined) {
					let obj = {
						key: key,
						name: camelCase(key),
						value: kpiData[key].value,
						delta: kpiData[key].delta,
					};
					$scope.selected.kpiMetrics.push(obj)
				}
			});
		}

		$scope.checkProductExist = function (selectedActivity, isDropDown) {
			if (selectedActivity.kpiSpecific) {
				$scope.showProductOption = true;

				$scope.kpisProducts = []
				_.forEach(selectedActivity.kpiData, function (kpi) {
					var index = _.findIndex($scope.products, {
						mainKpi: kpi.kpiId
					});
					if (index >= 0)
						$scope.kpisProducts.push($scope.products[index])
				})
			} else {
				$scope.showProductOption = false;
			}

			$scope.metrics = []
			Object.keys($scope.userAnalyticAcitivity).forEach(function (key) {
				if ($scope.userAnalyticAcitivity[key] instanceof Object) {
					$scope.metrics.push({
						name: key,
						value: $scope.userAnalyticAcitivity[key].value,
						delta: $scope.userAnalyticAcitivity[key].delta,
					})
				}
			});

		if(!isDropDown){
			//set pre selected metric
			const selectedAlarmActivity = $scope.selectedAlarm.trigger.options.activity;
			let filteredMetric = null
			if(selectedAlarmActivity){
				const selectedAlarmMetric = selectedAlarmActivity.metric;
				filteredMetric = $scope.metrics.find( metric => {
					if(metric.name === selectedAlarmMetric.name){
						return metric;
					}
				})
				$scope.metricThresholdValue = selectedAlarmMetric.metricThresholdValue;
				$scope.isRecurringSpecific = $scope.selectedAlarm.trigger.options.recurringSpecific;
			}
			if(filteredMetric){
				$scope.metric = filteredMetric;
			}
		}else{
				$scope.metric = $scope.metrics[0]
			}
		}

		function filterBadgesOnProduct(isDropDown) {
			$scope.filteredBadges = _.filter($scope.badges, function (badge) {
				return badge.from == $scope.product.mainKpi
			})
			if(!isDropDown){
				const selectedAlarmBadgeId = $scope.selectedAlarm.trigger.options.badgeId;
				const filteredBadge = $scope.filteredBadges.find( badge => {
					if(badge._id === selectedAlarmBadgeId){
						return badge;
					}
				})
				$scope.badge = filteredBadge ? filteredBadge : {};
			}else{
				$scope.badge = $scope.filteredBadges[0]
			}
		}

		$scope.productMetadataValue = 0;
		$scope.triggerNames = []
		var excelHeaders = []

		function getExcelHeaders() {
			CustomerService.getExcelHeader('customer').then(function (result) {
				if (result.status) {
					{
						excelHeaders = result.data.fields
						setTrigger($scope.triggerNameObj)
					}
				} else
					toaster.pop("error", "ExcelHeader Failed", "No Header present for the customer")
			}, function (err) {
				console.log(err)
			})
		}

		function getSubProductsMetadata() {

			CustomerService.getAllProductMetadata().then(function (result) {

				if (result.data) {
					{
						$scope.subProducts = _.filter(result.data, function (data) {
							if (data.metaData)
								return true
						});
						if ($scope.selectedAlarm && $scope.selectedAlarm.trigger &&
							$scope.selectedAlarm.trigger.options && $scope.selectedAlarm.trigger.options.productCode) {
							$scope.subProduct = _.find($scope.subProducts, {'productCode': $scope.selectedAlarm.trigger.options.productCode});
						} else {
							$scope.subProduct = $scope.subProducts[0]
						}
						$scope.changeSubProduct($scope.subProduct)
					}
				} else
					toaster.pop("error", "Products Metadata Failed", "No metadata present for the products")
			}, function (err) {
				toaster.pop("error", "Products Metadata Failed", err)
				console.log(err)
			})
		}

		$scope.changeSubProduct = function (product, name) {
			$scope.subProductMetadatas = product.metaData ? product.metaData : [];

			$scope.subProductMetadatas = _.filter($scope.subProductMetadatas, function (data) {
				if (name) {
					if (name === 'customerProductMetadataValue' && data.value === 'Number')
						return true
					else if (name !== 'customerProductMetadataValue' && data.value === 'Date')
						return true;
				} else {
					if (data.value === 'Date')
						return true;
				}
			})
			if ($scope.selectedAlarm && $scope.selectedAlarm.trigger &&
				$scope.selectedAlarm.trigger.options && $scope.selectedAlarm.trigger.options.keyName) {
				$scope.subProductMetadata = _.find($scope.subProductMetadatas, {'type': $scope.selectedAlarm.trigger.options.keyName});
			} else {
				$scope.subProductMetadata = $scope.subProductMetadatas[0];
			}
		}

		$scope.changeProduct = function (product, name) {
			if (name === 'badgeAlert'){
				filterBadgesOnProduct(true);
			}
		}

		$scope.changeDocumentType = (type, triggerName, isDropDown) => {
			$scope.documentPrerequisite = type.metaData.map(metadata => {
				if (metadata.prerequisite.type === 'date' && $scope.trigger && $scope.trigger.name === 'documentMetadataDate') {
					return {
						ipid: metadata.ipid,
						name: metadata.prerequisite.name,
						label: metadata.prerequisite.label,
						systemField: metadata.prerequisite.systemField,
						type: metadata.prerequisite.type
					}
				} else if (metadata.prerequisite.type === 'number' && $scope.trigger && $scope.trigger.name === 'documentMetadataNumber') {
					return {
						ipid: metadata.ipid,
						name: metadata.prerequisite.name,
						label: metadata.prerequisite.label,
						systemField: metadata.prerequisite.systemField,
						type: metadata.prerequisite.type
					}
				}
			})
			$scope.documentPrerequisite = $scope.documentPrerequisite.filter(e => e);
			let filteredPrequisite = null;
			if (!isDropDown) {
				const selectedAlarmDocPrequisite = $scope.selectedAlarm.trigger.options.docPrerequisiteMetadata;
				if (selectedAlarmDocPrequisite) {
					filteredPrequisite = $scope.documentPrerequisite.find(pre => {
						if (pre.ipid === selectedAlarmDocPrequisite.ipid) {
							return pre
						}
					})
				}
				if (filteredPrequisite) {
					$scope.docPrerequisiteMetadata = $scope.documentPrerequisite[0]
				}
			}
			if (filteredPrequisite && !isDropDown) {
				$scope.docPrerequisiteMetadata = filteredPrequisite;
			} else {
				$scope.docPrerequisiteMetadata = $scope.documentPrerequisite[0]
			}
		}

		function setExcelTriggerFields() {

			if ($scope.trigger.name === 'customerMetadataDate') {
				if (excelHeaders.length) {
					$scope.trigger.triggerFieldLabels = []
					excelHeaders.forEach(function (header) {
						if (header.isPrimary && header.typeOf === 'date') {
							$scope.trigger.triggerFieldLabels.push({
								'dbField': header.dbField,
								'displayName': header.displayName
							})
						}
					})
					$scope.triggerField = $scope.trigger.triggerFieldLabels[0]
				} else {
					toaster.pop('error', 'Alert Rule', 'Error in fetch the headers for the customer')
				}
			} else if ($scope.trigger.name === 'ticketMetadataDate') {
				$scope.trigger.triggerFieldLabels = [{
					displayName: "Creation Date",
					dbField: ticketDateFields.CREATED_AT,
					isFutureAvailable: false
				}, {
					displayName: "Updation Date",
					dbField: ticketDateFields.UPDATED_AT,
					isFutureAvailable: false
				}, {
					displayName: "Due Date",
					dbField: ticketDateFields.REQUIRE_CLOSURE_DATE,
					isFutureAvailable: true
				},{
					displayName: "Promise to pay date",
					dbField: ticketDateFields.PROMISE_TO_PAY,
					isFutureAvailable: true,
					showPastAlso:true
				},{
					displayName: "Payment due on",
					dbField: ticketDateFields.PAYMENT_DUE_ON,
					isFutureAvailable: true
				}]
				$scope.triggerField = $scope.trigger.triggerFieldLabels[0]
			}

			const selectedAlarmField = $scope.selectedAlarm.trigger.options.triggerField
			if (selectedAlarmField && $scope.trigger.triggerFieldLabels) {
				const filteredTriggerField = $scope.trigger.triggerFieldLabels.find(field => {
					if (field.dbField === selectedAlarmField) {
						return field;
					}
				})
				if (filteredTriggerField) {
					$scope.triggerField = filteredTriggerField;
				}
			}

		}

		function setTrigger(triggerConfigs) {

			$scope.triggers = triggerConfigs.config.filter((config) => {
				if (config.name === $scope.selectedAlarm.trigger.options.name) {
					return true
				}
			})

			$scope.triggerName = triggerConfigs.name;

			if ($scope.triggers.length) {
				$scope.trigger = $scope.triggers[0];

				$scope.CONST_TRIGGER = Object.assign({}, $scope.trigger);
				//mapping the conditions to readable conditions

				$scope.triggers.forEach(trigger => {
					if (trigger.conditions) {
						trigger.readableConditions = []
						trigger.conditions.forEach(condition => {
							trigger.readableConditions.push(conditionsMap.get(condition))
						})
					}
				});
				setExcelTriggerFields();

			}
			setTriggerConf($scope.trigger)
		}

		//get the action based on the choosed trigger
		function getActionByTrigger(triggerName) {
			TriggerService.getActionByTrigger(triggerName).then(function (result) {
				$scope.actions = []
				$scope.actionsBackup = result;
				result.forEach(function (con) {
					con.config.forEach(function (c) {
						var conf = {}
						conf.actionType = c.actionType
						conf.label = c.label
						$scope.actions.push(conf)
					})
				});
				//set selected alarm action
				const selectedAlarmOption = $scope.selectedAlarm.actions[0]
				let filteredOption = null;
				if (selectedAlarmOption) {
					filteredOption = $scope.actions.find(action => {
						if (action.actionType === selectedAlarmOption.options.actionType) {
							return action
						}
					})
				}
				if (filteredOption) {
					$scope.action = filteredOption;
				} else {
					$scope.action = $scope.actions[0];
				}

				setSelectedAction($scope.action, $scope.actionsBackup)
			}, function (err) {
				console.log(err)
			})
		}

		function getOutputsByTrigger(triggerName) {
			TriggerService.getOutputsByTrigger(triggerName).then(function (result) {
				$scope.outputs = []
				$scope.outputsBackup = result;
				result.forEach(function (con) {
					con.config.forEach(function (c) {
						var conf = {}
						conf.outputType = c.outputType
						conf.label = c.label
						$scope.outputs.push(conf)
					})
				});
				$scope.output = $scope.outputs[0];
				setSelectedOutput($scope.output, $scope.outputsBackup)
			}, function (err) {
				console.log(err)
			})
		}

		function setSelectedAction(action, actions) {
			actions.forEach(function (actn) {
				var data = _.find(actn.config, {
					actionType: action.actionType
				})
				if (data)
					$scope.actionSelected = data
			})
			if ($scope.trigger.dummyTemplates) {
				$scope.trigger.template = $scope.trigger.templates[$scope.actionSelected.actionName]
				$scope.trigger.dummyTemplate = $scope.trigger.dummyTemplates[$scope.actionSelected.actionName]
			}
		}

		function setSelectedOutput(output, outputs) {
			outputs.forEach(function (otp) {
				var data = _.find(otp.config, {
					outputType: output.outputType
				})
				if (data)
					$scope.outputSelected = data
			})
		}

		//change the trigger
		$scope.changeTrigger = function (trigger) {
			$scope.triggerNameObj = trigger;
			getAllWorkflowDefinitions();
			setTrigger(trigger);
		}

		//change the trigger config and set the other required values for this trigger
		$scope.changeTriggerConf = function (triggerConf) {
			if (!triggerConf) {
				triggerConf = $scope.triggers[0]
			}
			$scope.trigger = triggerConf
			$scope.changeSubProduct($scope.subProduct, triggerConf.name)
			setTriggerConf(triggerConf)
		}

		$scope.changeTriggerOptions = (triggerField, isInternal) => {
			if (isInternal) {
				triggerField = triggerField.triggerFieldLabels.find(field => {
					return field.dbField === $scope.triggerField.dbField;
				});
			}
			const { isFutureAvailable, showPastAlso } = triggerField;
			$scope.readableConditions = []
			if (!isFutureAvailable) {
				$scope.trigger.readableConditions = [conditionsMap.get('$lt')]
				$scope.trigger.options = $scope.CONST_TRIGGER.options.filter(option => {
					if (option !== 'tomorrow' && option !== 'today') {
						return true;
					}
				});
				$scope.readableConditions = $scope.trigger.readableConditions
			} else {
				$scope.trigger.conditions = $scope.CONST_TRIGGER.conditions;
				$scope.trigger.conditions.forEach(condition => {
					$scope.readableConditions.push(conditionsMap.get(condition))
				})
					$scope.trigger.options = $scope.CONST_TRIGGER.options.filter(option => {
						if (option !== 'yesterday') {
							return true;
						}else if(option === 'yesterday' && showPastAlso){
							return true
						}
					});
			}

			$scope.options = $scope.trigger.options
			if ($scope.selectedAlarm && $scope.selectedAlarm.trigger &&
				$scope.selectedAlarm.trigger.options && $scope.selectedAlarm.trigger.options.value) {
				var index = _.indexOf($scope.options, $scope.selectedAlarm.trigger.options.value);
				if (index != -1) {
					$scope.option = $scope.options[index];
				}
			} else {
				$scope.option = $scope.options[0];
			}
		}

		$scope.changeAction = function (action) {
			setSelectedAction(action, $scope.actionsBackup)
		}
		$scope.changeOutput = function (output) {
			setSelectedOutput(output, $scope.outputsBackupBackup)
		}

		function getAllWorkflowDefinitions() {
			WorkflowService.getAllWorkflowDefinition('all').then(function (result) {
				$scope.workflowDefinitions = result ? result : []
				//set the workflow definition
				const selectedAlarmWorkflowOptions = $scope.selectedAlarm.trigger.options
				let selectedAlarmWorkflow = null;
				if (selectedAlarmWorkflowOptions && selectedAlarmWorkflowOptions.workflowDefinition) {
					selectedAlarmWorkflow = $scope.workflowDefinitions.find(workflow => {
						if (workflow.uid === selectedAlarmWorkflowOptions.workflowDefinition.uid) {
							return true
						}
					})
				}
				if (selectedAlarmWorkflow) {
					$scope.workflowDefinition = selectedAlarmWorkflow;
				} else {
					$scope.workflowDefinition = $scope.workflowDefinitions[0];
				}

				$scope.filterStatesOnWorkflowDefinition($scope.workflowDefinition);
			}, function (err) {
				console.log(err.message)
			})
		}

		$scope.filterStatesOnWorkflowDefinition = function (param) {
			var filteredStates = _.filter($scope.workflowDefinitions, function (definition) {
				return definition.name == param.name
			});
			if (filteredStates.length) {
				$scope.workflowStates = filteredStates[0].states;

				//set value for fromState
				const selectedAlarmFromState = $scope.selectedAlarm.trigger.options.fromState;
				const filteredStateFromState = $scope.workflowStates.find(state => {
					if (state.uid === selectedAlarmFromState) {
						return state;
					}
				})
				if (filteredStateFromState) {
					$scope.fromState = filteredStateFromState;
				} else {
					$scope.fromState = $scope.workflowStates[0];
				}

				//set value for toState
				const selectedAlarmToState = $scope.selectedAlarm.trigger.options.toState;
				const filteredStateToState = $scope.workflowStates.find(state => {
					if (state.uid === selectedAlarmToState) {
						return state;
					}
				})
				if (filteredStateFromState) {
					$scope.toState = filteredStateToState;
				} else {
					$scope.toState = $scope.workflowStates[0];
				}
			}
		}

		function setTriggerConf(triggerConf) {


			triggerConf = triggerConf ? triggerConf : $scope.trigger;

			$scope.trigger = triggerConf;
			setExcelTriggerFields()
			const filteredTrigger = _.find($scope.triggers, {
				label: triggerConf.label
			});

			$scope.readableConditions = filteredTrigger.readableConditions ? filteredTrigger.readableConditions : [];

			if ($scope.selectedAlarm) {
				const selectedAlarmCondition = $scope.selectedAlarm.trigger.options.condition
				$scope.readableCondition = conditionsMap.get(selectedAlarmCondition);
			} else {
				$scope.readableCondition = $scope.readableConditions[0];
			}


			$scope.durationFors = triggerConf.durationFor ? triggerConf.durationFor : []
			let selectedAlarmDurationFor = null
			if ($scope.selectedAlarm) {
				selectedAlarmDurationFor = $scope.selectedAlarm.trigger.options.durationFor;
			}

			if (selectedAlarmDurationFor) {
				$scope.durationFor = selectedAlarmDurationFor
			} else {
				$scope.durationFor = $scope.durationFors[0]
			}

			$scope.durationNumbers = triggerConf.durationNumber ? triggerConf.durationNumber : []
			let selectedAlarmDurationNumber = null;
			if ($scope.selectedAlarm) {
				selectedAlarmDurationNumber = $scope.selectedAlarm.trigger.options.durationNumber
			}
			if (parseInt(selectedAlarmDurationNumber)) {
				$scope.durationNumber = selectedAlarmDurationNumber;
			} else {
				$scope.durationNumber = $scope.durationNumbers[0]
			}

			$scope.recurringCycles = triggerConf.recurringCycles ? triggerConf.recurringCycles : []

			if ($scope.selectedAlarm) {
				const selectedAlarmCycle = $scope.selectedAlarm.trigger.options.recurringCycle
				if (selectedAlarmCycle || parseInt(selectedAlarmCycle) === 0) {
					$scope.recurringCycle = selectedAlarmCycle
				} else {
					$scope.recurringCycle = $scope.recurringCycles[0]
				}
			}


			$scope.numberOfFlags = triggerConf.numberOfFlags ? triggerConf.numberOfFlags : []
			if ($scope.selectedAlarm) {
				const selectedAlarmFalgs = $scope.selectedAlarm.trigger.options.numberOfFlags
				if (selectedAlarmFalgs || parseInt(selectedAlarmFalgs) === 0) {
					$scope.numberOfFlag = selectedAlarmFalgs
				} else {
					$scope.numberOfFlag = $scope.numberOfFlags[0]
				}
			}


			$scope.options = filteredTrigger.options ? filteredTrigger.options : [];

			const selectedAlertOption = $scope.selectedAlarm.trigger.options.value;
			let filteredOption = null;
			if (selectedAlertOption) {
				filteredOption = $scope.options.find(option => {
					if (option === selectedAlertOption) {
						return true;
					}
				})

			}
			if (filteredOption) {
				$scope.option = filteredOption
			} else {
				$scope.option = $scope.options[0];
			}

			if (exceptionDateTrigger.includes(filteredTrigger.name)) {
				$scope.changeTriggerOptions(triggerConf, true)
			}

			const selectedAlarmInteractionCategory = $scope.selectedAlarm.trigger.options.interactionCategory
			if (selectedAlarmInteractionCategory) {
				const filteredCategory = interactionCategories.find(category => {
					if (category._id === selectedAlarmInteractionCategory) {
						return category;
					}
				})
				if (filteredCategory) {
					$scope.selected.interactionCategory = filteredCategory;
				}
			}

			getActionByTrigger($scope.triggerName)
			getOutputsByTrigger($scope.triggerName)
		}


		function getCondition(searchValue) {
			for (var [key, value] of conditionsMap.entries()) {
				if (value === searchValue)
					return key;
			}
		}

		$scope.sendHandlerNotificationFunc = (param) => {
			if (param) {
				$scope.actionsBackup.forEach(function (actn) {
					var data = _.find(actn.config, {
						actionType: "ACTION_TYPES.NOTIFICATION_FOR_FRONTLINE"
					})
					if (data)
						$scope.secondaryActionSelected = data
				})
			} else {
				$scope.secondaryActionSelected = {}
			}
		}
		//#endregion

		const deleteUnwantedKeys = (trigger, baseTrigger) => {
			const triggerKeys = Object.keys(baseTrigger);
			if (!triggerKeys.includes('triggerField')) {
				delete trigger.triggerField;
				delete trigger.subProductMetadata
			}
		}

		$scope.updateTrigger = function () {
			const filteredTrigger = _.find($scope.triggers, {
				label: $scope.trigger.label
			});
			filteredTrigger.template = $scope.trigger.template;
			filteredTrigger.dummyTemplate = $scope.trigger.dummyTemplate;
			let trigger = {};
			trigger.label = filteredTrigger.label;
			trigger.name = filteredTrigger.name;


			if (trigger.name === 'customerProductMetadataDate') {
				$scope.triggerField = null
				trigger.workflowDefinition = null
			} else if (trigger.name === 'customerMetadataDate') {
				$scope.subProductMetadata = null
				trigger.workflowDefinition = null
			} else if (trigger.name === 'ticketMetadataDate') {
				$scope.subProductMetadata = null
				trigger.workflowDefinition = null
			} else if (trigger.name === 'durationInAState' || trigger.name === 'durationInAStateBulk' || trigger.name === 'timeElapsedInSeconds') {
				$scope.triggerField = null
				$scope.subProductMetadata = null
				trigger.workflowDefinition = {}
				trigger.workflowDefinition.name = $scope.workflowDefinition.data.name
				trigger.workflowDefinition.uid = $scope.workflowDefinition.data.uid
				trigger.workflowDefinition.type = $scope.workflowDefinition.data.type
				trigger.workflowDefinition.active = $scope.workflowDefinition.data.active
				trigger.workflowDefinition._id = $scope.workflowDefinition.data._id
			} else if (trigger.name === 'badgeAlert') {
				$scope.subProductMetadata = null
				trigger.badgeCode = $scope.badge.badgeCode;
				trigger.badgeName = $scope.badge.name;
				trigger.badgeId = $scope.badge._id;
				trigger.numberOfFlag = $scope.numberOfFlag;
				trigger.product = $scope.product;
			} else if (trigger.name === 'metricAlert') {
				$scope.subProductMetadata = null
				trigger.product = $scope.isKpiSpecific ? $scope.product : null;
				trigger.activity = {
					name: $scope.activityObj.name,
					activityCode: $scope.activityObj.activityCode,
					alertSpecificName: $scope.activityObj.alertSpecificName,
					metric: $scope.metric,
					isKpiSpecific: $scope.isKpiSpecific
				}
				trigger.activity.metric.metricThresholdValue = $scope.metricThresholdValue;
				trigger.activity.metric.metricType = $scope.activityObj.activityType;
				// trigger.metricThresholdValue = $scope.metricThresholdValue;

				trigger.isRecurringSpecific = $scope.isRecurringSpecific;
				trigger.recurringCycle = $scope.isRecurringSpecific ? $scope.recurringCycle : null;

			} else if (trigger.name === 'overallMetricAlert') {
				$scope.subProductMetadata = null
				trigger.metric = $scope.metric;
				trigger.metric.metricThresholdValue = $scope.metricThresholdValue;
				delete trigger.metric.value;
				delete trigger.metric.delta;
				trigger.isRecurringSpecific = $scope.isRecurringSpecific;
				trigger.recurringCycle = $scope.isRecurringSpecific ? $scope.recurringCycle : null;

			} else if (trigger.name === 'kpiMetricAlert') {
				$scope.subProductMetadata = null;
				const selectedKpi = $scope.selected.kpi;
				trigger.kpi = {
					name: selectedKpi.name,
					_id: selectedKpi._id,
					kpiCode: selectedKpi.kpiCode,
					metric: $scope.metric,
				};
				delete trigger.kpi.metric.value;
				delete trigger.kpi.metric.delta;
				trigger.kpi.metric.metricThresholdValue = $scope.metricThresholdValue;

				trigger.isRecurringSpecific = $scope.isRecurringSpecific;
				trigger.recurringCycle = $scope.isRecurringSpecific ? $scope.recurringCycle : null;
			} else if (trigger.name === 'documentMetadataDate' || trigger.name === 'documentMetadataNumber') {
				trigger.documentTypeId = $scope.documentType._id
				trigger.docPrerequisiteMetadata = $scope.docPrerequisiteMetadata
			}


			trigger.triggerType = filteredTrigger.triggerType ? filteredTrigger.triggerType : null,
				trigger.interval = $scope.interval;
			trigger.readableCondition = $scope.readableCondition;
			trigger.subProductMetadata = $scope.subProductMetadata;

			trigger.triggerField = $scope.triggerField;
			if ($scope.trigger.name === 'customerProductMetadataValue') {
				trigger.option = $scope.productMetadataValue
			} else {
				trigger.option = $scope.option;
			}
			trigger.actions = [$scope.actionSelected];
			if ($scope.trigger.name === 'leadArchiveEvent') {
				trigger.sendHandlerNotification = $scope.sendHandlerNotification;
				if ($scope.sendHandlerNotification) {
					trigger.actions.push($scope.secondaryActionSelected);
				}
			}
			trigger.output = $scope.outputSelected;
			trigger.output.value = filteredTrigger.template;
			trigger.condition = getCondition(trigger.readableCondition);
			trigger.triggerName = $scope.triggerName;
			trigger.durationNumber = $scope.durationNumber;
			trigger.durationFor = $scope.durationFor;
			trigger.fromState = $scope.fromState ? $scope.fromState.uid : null;
			trigger.fromStateLabel = $scope.fromState ? $scope.fromState.name : null;
			trigger.toState = $scope.toState ? $scope.toState.uid : null;
			trigger.interactionCategory = $scope.selected.interactionCategory ? $scope.selected.interactionCategory._id : null;

			//delete unwanted keys - triggerField and product info from object
			//its mutating the object so no return type required
			deleteUnwantedKeys(trigger, filteredTrigger);

			let tag = null;
			if ($scope.selected.interactionCategory) {
				const list = ['bulkUpcomingInteractions', 'noContactInteractions', 'noInteractionsComplete', 'followUpMissed'];
				if ($scope.trigger && list.includes($scope.trigger.name)) {
					tag = trigger.interactionCategory;
				}
			} else if (filteredTrigger.tag) {
				tag = filteredTrigger.tag;
			}
			if (tag) {
				trigger.tag = tag;
			}
			trigger._id = $scope.selectedAlarm._id;
			trigger.active = $scope.selectedAlarm.active;
			TriggerService.updateTrigger(trigger).then(function () {
				toaster.pop('success', 'Alarm Rule', 'Alarm updated')
				refresh();
			}, function (error) {
				toaster.pop('error', 'Alert Rule', error.message)
				console.log(error)
			})
		};
		refresh();
	});
