'use strict';

angular.module('bitnudgeApp')
    .service('burnStatement', function ($http, $q) {
        this.getData = function(cycle){
            return $http.post('/api/redemptionPoints/burnStatement', {cycleId:cycle._id}).then(function(data){
                data=data.data;
                return $q.resolve(data);
            });
        }
    });

