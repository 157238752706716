'use strict';
 
angular.module('bitnudgeApp')
    .controller('StepTwoController', ($scope, Auth, $location, $state, $injector, $timeout, p0, step2, userAnalyticsService, Gender, $rootScope) => {
    $scope.me=Auth.getCurrentUser();
    $scope.jerseyImage = Gender.getJerseyImage();
    $scope.strings = $rootScope.strings;
     $scope.config = p0.config;
    $scope.showLevel = true;
    $scope.isCycleActive = p0.currentMonthlyCycle.active;
    if(!$scope.config.liveLevel && !p0.currentMonthlyCycle.previousCycleId){
        $scope.showLevel = false;
    }
    var getUserAnalytics = function (cycleId) {
        return userAnalyticsService.fetchUserAnalytics($scope.me._id,cycleId).then(function(userAnalytics){
            if(!userAnalytics){
                return;
            }
            var max = userAnalytics.points.value / userAnalytics.percentageCompleted.value * 150;
            var threshold = userAnalytics.points.value / userAnalytics.percentageCompleted.value * 100;
            $scope.speedometerSettings = {
                size: 130,
                clipWidth: 200,
                clipHeight: 180,
                ringWidth: 10,
                maxValue: 150,
                transitionMs: 2000,
                highThreshhold : 100,
                defaultColor : '#0188ff',
                infoData : 'Overall Score',
                value : userAnalytics.percentageCompleted.value
            };
            $scope.speedometerSettings2 = {
                size: 130,
                clipWidth: 200,
                clipHeight: 180,
                ringWidth: 10,
                maxValue: max,
                lowThreshhold : 0,
                highThreshhold : threshold,
                transitionMs: 2000,
                defaultColor : '#0188ff',
                infoData : $rootScope.strings.IMPACT.value+' '+$rootScope.strings.POINTS.value,
                value : userAnalytics.businessPoints.value
            };
            $scope.userDataFetched = true;
        });
    };
    var task = getUserAnalytics(p0.currentMonthlyCycle._id);
    
    $scope.tabDataLoaded = false;
    $scope.userDataFetched = false;

	step2.getSummary().then(function(summary){
        $scope.summary = summary;
    });


    $scope.allMonthlyCycles = _.cloneDeep(p0.allMonthlyCycles);

    $scope.selectedCycle = p0.currentMonthlyCycle;

    $scope.toggleLayer = function(){
        $(".overlay").toggle();
        $(".sidebar-links ul li span").fadeToggle();
    };

    $scope.getSelectedTabData = function(index, cycle){
        $scope.tabDataLoaded = false;
        $scope.userDataFetched = false;
        return step2.getData(cycle).then(function(data){
            $scope.data = data;
            $scope.rankData = $scope.data[0];
            $scope.tabDataLoaded = true;

            $scope.isCycleActive = cycle.active;
            if(!$scope.config.liveLevel && !cycle.previousCycleId){
                $scope.showLevel = false;
            }else{
                $scope.showLevel = true;
            }
            getUserAnalytics(cycle._id);
        });
      
    };

    $scope.setRankData = function(index){
        $scope.rankData = $scope.data[index];
    };
    $scope.gotoDashoard = function(){
        $state.go('userFlow.dashboard');
    }

    function staticPattern() {
        $('#dot').css('border-color', 'transparent');
        $('#dot.active').css('background-color', '#fff').noisy({
            'intensity' : 1,
            'size' : 1000,
            'opacity' : 1,
            'fallback' : '',
            'monochrome' : true
        });
        $('#dot').toggleClass('active');
        $timeout(function() {
            $('#dot').toggleClass('active');
            $timeout(function() {
                $('#dot').toggleClass('transparent');
                $('#screen2').toggleClass('triggerAnimation');
                $timeout(function() {
                    $('#screen').toggleClass('triggerAnimation');
                },3000);
            },5000);
        }, 2000);
    }

    staticPattern();
    return task
});