const autoImportNgTemplateLoaderTemplate1 = require('../html/playerListModal.html');

'use strict';

angular.module('bitnudgeApp')
    .controller('badgeCtrl', function ($scope, $mdDialog, $element, $timeout, $interval, p0, badgeCorner) {
        if($scope.data != null && $scope.data != undefined) {
            $scope.isIcon =  $scope.data.users == null || $scope.data.users == undefined;
        } else {
            $scope.isIcon = true;
        }

        $scope.data.imageUrl = badgeCorner.getImageUrl($scope.data.imageEnum);
        $scope.isContention = !$scope.data.earned && $scope.data.contention;

        var setBadgeForUsers = function () {
            var badge = _.cloneDeep($scope.data);
            delete badge.users;
            _.forEach($scope.data.users, function (user) {
                if(!user.badge) user.badge = badge;
            });
        };

        $scope.showModal = function() {
            if(!$scope.hideModel){
                $mdDialog.show({
                    templateUrl: autoImportNgTemplateLoaderTemplate1,
                    controller: 'playerListModalCtrl',
                    resolve: {
                        data: function () {
                            setBadgeForUsers();
                            return $scope.data.users;
                        },
                        type: function () {
                            return 'badge';
                        },
                        title: function () {
                            return 'Badges Earned';
                        },
                        roleToggle: function () {
                            return false;
                        }
                    }
                });
            }
        };

        $scope.animateBadge = p0.config.anim_badge; // make this flag false if want to disable animation
        var width, height, canvas, ctx, triangles, target, periodic;
        var colors = ['72,35,68', '43,81,102', '66,152,103', '250,178,67', '224,33,48'];
        if ($scope.data.badgeCategory && $scope.animateBadge && $scope.isEarned && !$scope.data.users && $scope.data.badgeCategory != 'redFlag') {
            $timeout(function() {
                initHeader();
                initAnimation();
            }, 50);

            $element.on('$destroy', function () {
                if (angular.isDefined(periodic)) {
                    $interval.cancel(periodic);
                }
            });
        }

        function initHeader() {
            var badgeContainer = $element.find('.badge-container')[0];
            width = badgeContainer.offsetWidth;
            height = badgeContainer.offsetHeight;
            target = {x: 0, y: height};

            canvas = $element.find('canvas')[0];
            canvas.width = width;
            canvas.height = height;
            ctx = canvas.getContext('2d');

            // create particles
            triangles = [];
            for(var x = 0; x < 48; x++) {
                addTriangle(x*10);
            }

            periodic = $interval(function() {
                $scope.animateBadge = !$scope.animateBadge;
            }, 5000);
        }

        function addTriangle(delay) {
            $timeout(function() {
                var t = new Triangle();
                triangles.push(t);
                tweenTriangle(t);
            }, delay);
        }

        function initAnimation() {
            animate();
        }

        function tweenTriangle(tri) {
            var t = Math.random()*(2*Math.PI);
            var x = (200+Math.random()*100)*Math.cos(t) + width*0.5;
            var y = (200+Math.random()*100)*Math.sin(t) + height*0.5/*-20*/;
            var time = 4+3*Math.random();

            TweenLite.to(tri.pos, time, {x: x,
                y: y, ease:Circ.easeOut,
                onComplete: function() {
                    tri.resetValues();
                    tweenTriangle(tri);
                }});
        }

        function animate() {
            if($scope.animateBadge) {
                ctx.clearRect(0,0,width,height);
                ctx.beginPath();
                for(var i in triangles) {
                    triangles[i].draw();
                }
                ctx.closePath();
                ctx.fill();
            }
            requestAnimationFrame(animate);
        }

        // Canvas manipulation
        function Triangle() {
            var _this = this;

            // constructor
            (function() {
                _this.coords = [{},{},{}];
                _this.pos = {};
                _this.resetPos = {};
                init();
            })();

            function init() {
                _this.pos.x = width*0.5;
                _this.pos.y = height*0.5;

                _this.resetPos.x = _this.pos.x;
                _this.resetPos.y = _this.pos.y;

                _this.coords[0].x = -10+Math.random()*30;
                _this.coords[0].y = -10+Math.random()*30;
                _this.coords[1].x = -10+Math.random()*30;
                _this.coords[1].y = -10+Math.random()*30;
                _this.coords[2].x = -10+Math.random()*30;
                _this.coords[2].y = -10+Math.random()*30;
                _this.color = colors[Math.floor(Math.random()*colors.length)];
                _this.alpha = 1;
            }

            function resetValues() {
                _this.alpha = 1;
                _this.color = colors[Math.floor(Math.random()*colors.length)];

                _this.pos.x = _this.resetPos.x;
                _this.pos.y = _this.resetPos.y;
            }

            this.draw = function() {
                if(_this.alpha >= 0.0025) _this.alpha -= 0.0025;
                else _this.alpha = 0;
                //ctx.beginPath();
                ctx.moveTo(_this.coords[0].x+_this.pos.x, _this.coords[0].y+_this.pos.y);
                ctx.lineTo(_this.coords[1].x+_this.pos.x, _this.coords[1].y+_this.pos.y);
                ctx.lineTo(_this.coords[2].x+_this.pos.x, _this.coords[2].y+_this.pos.y);
                //ctx.closePath();
                ctx.fillStyle = 'rgba('+_this.color+','+ _this.alpha+')';
                //ctx.fill();
            };

            this.init = init;
            this.resetValues = resetValues;
        }
    });
