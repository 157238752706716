'use strict';
angular.module('bitnudgeApp')
    .controller('labModalController', function ($scope, $http, Auth, myReporteeFeed, cycleType, selectedKpi, $uibModalInstance, p0, isLive) {
        $scope.Math = window.Math;
        var setReporteeLabFeed = function(){
            var currentUser = Auth.getCurrentUser();
            $scope.managerTable = [];
            $scope.table = [];
            if(!selectedKpi) selectedKpi = 'overall';
            var managerFeed = myReporteeFeed[currentUser._id];
            $scope.managerTable.push(getRow(managerFeed,cycleType, selectedKpi));
            currentUser.children.forEach(function(child){
                var reporteeFeed = myReporteeFeed[child.childId];
                $scope.table.push(getRow(reporteeFeed, cycleType, selectedKpi));
            });
        };
        setReporteeLabFeed();
        function getRow(user, cycleType, selectedKpi){
            var cycleId;
            if(cycleType == 'daily'){
                if(isLive) {
                    cycleId = p0.currentDailyCycle._id;
                }else{
                    cycleId = p0.previousDailyCycle._id;
                }
            }else if(cycleType == 'weekly'){
                cycleId = p0.currentWeeklyCycle._id;
            }else{
                cycleId = p0.currentMonthlyCycle._id;
            }
            var userData = user[cycleId][selectedKpi];
            return {
                name:user.name,
                image:user.image,
                calls:{value:userData.calls,last:getMinutesAgo()},
                meetings:{value:userData.meetings,last:getMinutesAgo()},
                submissions:{value:userData.submissions,last:getMinutesAgo()},
                distance:{value:userData.distance,last:getMinutesAgo()},
                leadsAssigned:{value:userData.leadsAssigned,last:getMinutesAgo()},
                newLead:{value:userData.newLead,last:getMinutesAgo()},
                leadsLost:{value:userData.leadsLost,last:getMinutesAgo()}
            };
        }

        function getRandomValue(){
            return Math.floor((Math.random() * 100) + 1)/ 10;
        }

        function getMinutesAgo() {
            return Math.floor((Math.random() * 50) + 1);
        }
        $scope.onClose=  function(){
            $uibModalInstance.close();
        }

    });
