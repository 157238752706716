'use strict';
angular.module('bitnudgeApp').controller('customerNotesTimelineController', function ($scope, $mdDialog, customer, $http, $filter, $state) {
    this.init = (function(){
        $scope.customer = customer;
        $scope.histories = customer.notes;
    }).bind(this);

    $scope.cancel = function () {
        $mdDialog.hide();
    }

    this.init();
});