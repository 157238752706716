'use strict';
angular.module('bitnudgeApp')
    .controller('heatmapRadialChartCtrl', function ($scope) {
        $scope.item={};
        $scope.titles = ['in current month','in previous month'];
        $scope.subtitles = {
                            'Volume':'(in AED M)',
                            'Yield':'(in %)',
                            'RoRWA':'(in %)',
                            'Cross sell':'',
                            'No. of Customers':'',
                            'Quality':'(in %)'
                          };
        $scope.subtitle = $scope.subtitles[$scope.title];
        $scope.radialChartValues = [[getRandomValue(),getRandomValue()],
                                    [getRandomValue(),getRandomValue()]];

        $scope.item.label = 'Change';
        $scope.item.delta1 = Math.floor(Math.random() * 30) - 15;
        $scope.item.delta2 = Math.floor(Math.random() * 30) - 15;
        setTimeout(init, 100);
        function init() {
            var ringchart1 = new RadialProgressChart('#graph1', {
                diameter: 90,
                quarters: 4,
                stroke: {
                    width: 10,
                    gap: 5
                },
                max: 100,
                round: true,
                series: [
                    {
                        value: 0,
                        color: {
                            solid: '#939393',
                            background: '#939393'
                        }
                    },
                    {
                        value: $scope.radialChartValues[0][0],
                        color: {
                            solid: '#009393',
                            background: '#939393'
                        }
                    },
                    // {
                    //     value:getRandomValue(),
                    //     color: {
                    //         solid: '#FF3800',
                    //         background: '#939393'
                    //     }
                    // },
                    {
                        value: 0,
                        color: {
                            solid: '#939393',
                            background: '#939393'
                        }
                    },

                    {
                        value: $scope.radialChartValues[0][1],
                        color: {
                            solid: '#00585E',
                            background: '#939393'
                        }
                    }
                ],center: {

                }
            });
            var ringchart2 = new RadialProgressChart('#graph2', {
                diameter: 90,
                quarters: 4,
                stroke: {
                    width: 10,
                    gap: 5
                },
                max: 100,
                round: true,
                series: [
                    {
                        value: 0,
                        color: {
                            solid: '#939393',
                            background: '#939393'
                        }
                    },
                    {
                        value: $scope.radialChartValues[1][0],
                        color: {
                            solid: '#009393',
                            background: '#939393'
                        }
                    },
                    // {
                    //     value:getRandomValue(),
                    //     color: {
                    //         solid: '#FF3800',
                    //         background: '#939393'
                    //     }
                    // },
                    {
                        value: 0,
                        color: {
                            solid: '#939393',
                            background: '#939393'
                        }
                    },
                    {
                        value: $scope.radialChartValues[1][1],
                        color: {
                            solid: '#00585E',
                            background: '#939393'
                        }
                    }
                ],center: {

                }
            });
            var ringchart3 = new RadialProgressChart('#graph3', {
                diameter: 90,
                quarters: 4,
                stroke: {
                    width: 10,
                    gap: 5
                },
                max: 100,
                round: true,
                series: [
                    {
                        value: 0,
                        color: {
                            solid: '#939393',
                            background: '#939393'
                        }
                    },
                    {
                        value: getRandomValue(),
                        color: {
                            solid: '#009393',
                            background: '#939393'
                        }
                    },
                    // {
                    //     value:getRandomValue(),
                    //     color: {
                    //         solid: '#FF3800',
                    //         background: '#939393'
                    //     }
                    // },
                    {
                        value: 0,
                        color: {
                            solid: '#939393',
                            background: '#939393'
                        }
                    },
                    {
                        value: getRandomValue(),
                        color: {
                            solid: '#00585E',
                            background: '#939393'
                        }
                    }
                ],center: {

                }
            });
            var ringchart4 = new RadialProgressChart('#graph4', {
                diameter: 90,
                quarters: 4,
                stroke: {
                    width: 10,
                    gap: 5
                },
                max: 100,
                round: true,
                series: [
                    {
                        value: 0,
                        color: {
                            solid: '#939393',
                            background: '#939393'
                        }
                    },
                    {
                        value: getRandomValue(),
                        color: {
                            solid: '#009393',
                            background: '#939393'
                        }
                    },
                    // {
                    //     value:getRandomValue(),
                    //     color: {
                    //         solid: '#FF3800',
                    //         background: '#939393'
                    //     }
                    // },
                    {
                        value: 0,
                        color: {
                            solid: '#939393',
                            background: '#939393'
                        }
                    },
                    {
                        value: getRandomValue(),
                        color: {
                            solid: '#00585E',
                            background: '#939393'
                        }
                    }
                ],center: {

                }
            });
        }

        function getRandomValue() {
            return Math.floor((Math.random() * 100) + 1);
        }
    });
