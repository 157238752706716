'use strict';
angular.module('bitnudgeApp')
    .controller('activityLBCtrl', function ($scope, $rootScope, data,activityName, $mdDialog) {
        $scope.data = data;
        $scope.strings = $rootScope.strings;
        $scope.header = 'LeaderBoard: '+activityName;
        $scope.onClose = function(){
            $mdDialog.hide();
        };
        $scope.roleSwitch = function(manager){
            $scope.showManager = manager;
            if(manager){$scope.lbData = $scope.data['manager']}
            else $scope.lbData = $scope.data['frontline'];
            $scope.lbData = _.orderBy($scope.lbData, ['value'],['desc']);
        };
        $scope.fractionSize =function (value) {
            if(value == 0 || !$scope.showManager) return 0;
            else return 1;
        };
        $scope.showManager = $rootScope.isMiddleManager;
        $scope.roleSwitch($scope.showManager);
    });
