'use strict';
angular.module('bitnudgeApp')
    .service('sidebarVariables', function (Auth, p0) {
        var _this = this;
        this.user= Auth.getCurrentUser();
        this.kpi = {name:'Overall',_id:'overall'};
        this.cycle = p0.currentMonthlyCycle;
        this.tabIndex = 0;
        this.setKpi = function(kpi){
            _this.kpi = kpi;
        };
        this.setUser = function(user){
            _this.user = user;
        };
        this.setCycle = function (cycle) {
            _this.cycle = cycle;
        };
        this.setTabIndex = function (index) {
            _this.tabIndex = index;
        }
    });

