'use strict';
angular.module('bitnudgeApp')
    .controller('adminLogsModalCtrl', function ($scope,$rootScope, logs, header,isCronCompleted, $mdDialog) {
        $scope.logs = logs;
        $scope.header = header;
        $scope.isCronCompleted = isCronCompleted;
        $scope.closeModal = function() {
            $mdDialog.hide();
        };

    });
