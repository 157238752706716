'use strict';
angular.module('bitnudgeApp')
.controller('correlationsCardCtrl', function ($scope, p0, Correlation, Auth, $state, $rootScope) {

        $scope.me =  Auth.getCurrentUser();
        $scope.config = p0.config;
        $scope.strings = p0.strings;
        $scope.params = {};
        $scope.isMiddleManager = $rootScope.isMiddleManager;
        if(!$rootScope.isFrontline){
            $scope.label1 = $rootScope.isMiddleManager ? 'Me' : p0.strings.MANAGER.value;
            $scope.label2 = $rootScope.isMiddleManager ? p0.strings.FRONTLINE.value : p0.strings.FRONTLINE.value;
        }

        //Dummy data
        $scope.chartData = {
            seriesLegends:[{label: 'Series A', series: 'a'}],
            labels: [1, 2, 3, 4, 5, 6, 7, 8, 9 , 10, 11],
            series: [[
                {x: 0, y: 0},
                {x: 0, y: 0},
                {x: 0, y: 0}
            ]]
        };
        var chartData = [];
        $scope.cardExpanded = false;

        var correlationActivities = _.filter(p0.allActivities, {correlationChart:true});

        $scope.yMetrics = [{_id:$scope.kpi._id, name:p0.strings.ACHIEVEMENT.value +' ('+ $scope.kpi.unit +') ', metric:'kpi'}];
        $scope.yMetric = $scope.yMetrics[0];


        //Helpers
        var setLegend = function () {
            var prefix = '';
            if($rootScope.isMiddleManager){
                prefix = ($scope.params.isReportee ? $scope.label2 : $scope.label1) + ' - ' ;
            }
            $scope.chartData.seriesLegends[0].label = prefix + $scope.yMetric.name+' vs '+$scope.xMetric.name;
            $scope.chartData.seriesLegends[0].activity = $scope.xMetric.name;
        };

        var setXMetrics = function () {
            $scope.xMetrics = [];
            _.forEach(correlationActivities, function (activity) {
                if($scope.kpi.activities.indexOf(activity._id) != -1){
                    $scope.xMetrics.push({_id:activity._id, name:activity.name+' ('+activity.unit+')', metric:'kai', fieldName:activity.fieldName})
                }
            });
            if($scope.xMetrics.length){
                $scope.xMetric = $scope.xMetrics[0];
            }
        };

        var setChartData = function () {
            var xyMap = [], labels = [], max = 0;
            chartData.forEach(function (userData) {
                var xData = userData[$scope.xMetric.fieldName];
                var yData = userData.sales;
                var name = userData.name;
                var cycles = userData.cycles;

                xData.forEach(function (value, index) {
                    if(value > max) max= value;
                    if(!value && !yData[index]) {
                        return;
                    }
                    xyMap.push({x:value, y:yData[index], name:name, cycle: cycles[index]});
                });
            });
            for(var i =0;i<=max;i++){labels.push(i);}
            $scope.chartData.labels = labels;
            $scope.chartData.series[0] = xyMap;
        };

        setXMetrics();
        setLegend();

        var fetchData = function () {
            $scope.yMetric._id = $scope.kpi._id;
            var userIds;
            if($scope.params.isReportee || !$rootScope.isMiddleManager){
                userIds = _.map($scope.reporteeList, '_id');
            }else{
                userIds = [$scope.me._id];
            }
            $scope.dataLoading = true;
            return Correlation.fetchSalesAndActivityData(userIds, $scope.timeRange, $scope.timePeriod, $scope.yMetric).then(function (result) {
                chartData = result;
                setChartData();
                $scope.dataLoading = false;
            });
        };

        var setDefaultTimeRanges = function () {
            var daily = {_id:'daily', name:'Daily'}, monthly = {_id:'monthly', name:'Monthly'}, weekly = {_id:'weekly', name:'Weekly'};
            var singleUser = $rootScope.isMiddleManager && !$scope.params.isReportee;
            var weeklyDaily = [], weeklyMonthly = [];
            if($scope.config.weeklyCycle){
                weeklyDaily.push(weekly);
                weeklyMonthly.push(weekly);
            }
            weeklyDaily.push(daily);
            weeklyMonthly.push(monthly);
            $scope.timeRanges[0].periods = weeklyDaily;
            if($scope.timeRanges[0].previous){
                $scope.timeRanges[1].periods = singleUser? weeklyDaily :weeklyMonthly;
                $scope.timeRanges[2].periods = singleUser? weeklyMonthly :[monthly];
                $scope.timeRanges[3].periods = singleUser? weeklyMonthly :[monthly];
            }
            $scope.timeRange = $scope.timeRanges[0];
            $scope.timePeriod = $scope.timeRange.periods[0];
        };

        var initTimePeriods = function (monthType) {
            $scope.timeRanges = [];
            var startDate, endDate;
            var currentCycle = p0.currentMonthlyCycle;
            $scope.timeRanges.push({name:'Current month', id:'currentMonth',
                startDate: currentCycle.startDate, endDate: new Date($rootScope.lastUpdatedAtDate).setHours(23, 59, 59, 999),
                periods:[],
                previous: currentCycle.previousCycleId ? true:false});
            if(currentCycle.previousCycleId){
                const previousCycle = currentCycle.previousCycleId;
                $scope.timeRanges.push({name:'Last month', id:'lastMonth',
                    startDate: previousCycle.startDate, endDate: previousCycle.endDate,
                    periods:[]});
                var today = new Date(), y = today.getFullYear(), m = today.getMonth();
                if(monthType == 'regular'){
                    endDate = new Date(y, m , 1);
                    startDate = new Date(y, m - 3, 1);
                }else{
                    endDate = new Date(y, m-1 , 15);
                    startDate = new Date(y, m - 4, 16);
                }
                endDate.setHours(23, 59, 59, 999);
                $scope.timeRanges.push({name:'Last 3 months', id:'last3Months',
                    startDate:startDate, endDate:endDate,
                    periods:[]});
                if(monthType == 'regular'){
                    startDate = new Date(y, m - 6, 1);
                }else{
                    startDate = new Date(y, m - 7, 16);
                }
                $scope.timeRanges.push({name:'Last 6 months', id:'last6Months',
                    startDate:startDate, endDate:endDate,
                    periods:[]});
            }

            setDefaultTimeRanges();
        };

        var init = function (kpi) {
            setXMetrics();
            var division = _.find(p0.allDivisions, function (division) {return _.find(division.kpis, {_id: kpi._id});});
            initTimePeriods(division.monthType);
            fetchData();
        };

        //On Change
        $scope.onChangeY = function () {

        };

        $scope.onChangeX = function () {
            setLegend();
            setChartData();
        };

        $scope.onReporteeSelectionChange = function () {

        };

        $scope.onSwitchChange = function () {
            setLegend();
            setDefaultTimeRanges();
            fetchData();
        };

        $scope.onChangeTimePeriod = function () {
            fetchData();
        };

        $scope.onChangeTimeRange = function () {
            $scope.timePeriod = $scope.timeRange.periods[0];
            fetchData();
        };

        //On Click
        $scope.expandLayout = function () {
            $scope.cardExpanded = !$scope.cardExpanded;
        };

        $scope.showBenchmarkLayout = function () {
            $scope.cardExpanded = !$scope.cardExpanded;
        };

        $scope.gotoGP = function () {
            $state.go('userFlow.gameplan',{selectedPlayerId: $scope.selectedReportee._id});
        };

        $scope.gotoOCR = function () {
            $state.go('userFlow.oneClickReport',{selectedPlayerIdForOCR:$scope.selectedReportee._id});
        };

        $scope.gotoProfile = function () {
            var cycleId;
            if($scope.cycle){
                cycleId = $scope.cycle._id;
            }else{
                cycleId = p0.currentMonthlyCycle._id;
            }
            $state.go('userFlow.profile',{selectedUserId: $scope.selectedReportee._id, cycleId:cycleId});
        };

        //Watch
        $scope.$watchGroup(['reporteeList', 'kpi'],function (newValue, oldValue){
            if(newValue[0].length){
                $scope.reporteeList = newValue[0]
            }
            if(!$rootScope.isMiddleManager && newValue[0].length && newValue[1]){
                init(newValue[1]);
            }else if($rootScope.isMiddleManager && !$scope.params.isReportee && newValue[1]){
                init(newValue[1]);
            }else if($rootScope.isMiddleManager && $scope.params.isReportee && newValue[0].length && newValue[1]){
                init(newValue[1]);
            }
        });
    });
