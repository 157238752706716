'use strict';
angular.module('bitnudgeApp')
	.controller('portfolioModalController', function ($scope, $state, Auth, $http, $mdDialog, user, PortfolioService, p0) {
		$scope.isWindowMaximised = false;

        $scope.redirectionMessage = "NOTE: "+p0.strings.FRONTLINE.value + "s are redirected to customers page.";
		var loggedInUser = Auth.getCurrentUser();
		$scope.selected = {
			user: loggedInUser
		};
		if (user && user._id) {
			$scope.trailList = [user];
			$scope.selected.user = user;
		} else {
			$scope.trailList = [loggedInUser];
		}


		$scope.myProducts = _.cloneDeep(p0.myProducts);
		$scope.myProducts = _.sortBy($scope.myProducts, 'productCode');

		function init() {
			const userId = user && user._id ? user._id : loggedInUser._id;
			getPortfolio(userId);
		}

		function getPortfolio(userId) {
			if (!userId) {
				userId = null
			}
			let cycleId = p0.currentMonthlyCycle._id;
			$scope.dataLoaded = false;
			PortfolioService.getPortfolio(cycleId, userId).then(result => {
				result = result.data;

				if(!_.find(result.data,{roleType:'seniorManager'})){
					let managers = _.remove(result.data, {roleType:'manager'});
					managers.forEach(manager => {
						result.data.unshift(manager);
					})
				} else {
					let seniorManagers = _.remove(result.data, {roleType:'seniorManager'});
					seniorManagers.forEach(sm => {
						result.data.unshift(sm);
					})
				}

				$scope.userData = result.data;
				$scope.dataLoaded = true;
			})
        }

		$scope.onBreadCrumbClick = function (user) {
			var index = _.findIndex($scope.trailList, {
				_id: user._id
			});
			$scope.trailList.length = index + 1;
			$scope.selected.user = user;
			getPortfolio(user._id)
		}

		$scope.openReporteesForUser = function (userPortfolio) {
			let index = _.findIndex($scope.trailList, {
				_id: userPortfolio._id
			});
			if (index == -1) {
				$scope.trailList.push(userPortfolio);
			}
			if (userPortfolio.roleType !== 'frontline') {
                $scope.selected.user = userPortfolio;
                getPortfolio(userPortfolio._id)
			} else {
				$scope.onClose();
				$state.go('lms.customers', {
					userId: userPortfolio._id
				});

			}
		}

		$scope.onClose = function () {
			$mdDialog.cancel()
		}

		$scope.maximiseWindow = function () {
			$scope.isWindowMaximised = !$scope.isWindowMaximised;
		}
        $scope.styleFunction = function(index){
	        return {"background-color":index};
        };


        init();
	})
