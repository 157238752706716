const autoImportNgTemplateLoaderTemplate1 = require('./../html/component/thumbnailComponent.html');

"use strict";
angular.module("bitnudgeApp").component("thumbnailComponent", {
	templateUrl: autoImportNgTemplateLoaderTemplate1,
	bindings: {
		thumbnail: "<",
		fileType: "<"
	},
	controller: "thumbnailComponentCtrl"
});
