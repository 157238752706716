const autoImportNgTemplateLoaderTemplate1 = require('../html/admin/confirmWithCheckModal.html');

'use strict';

angular.module('bitnudgeApp')
    .controller('listCycleCtrl', function ($http, $scope, toaster, DTOptionsBuilder, $mdDialog) {
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withPaginationType('simple')
            .withDisplayLength(20)
            .withOption('lengthChange', false)
            .withOption('paging', true)
            .withOption('searching', true)
            .withOption('ordering', true)
            .withOption("bSort", true)
            .withOption('order', [1, 'desc']);
        $scope.dateOptions = {
            formatYear: 'yy',
            modelDateFormat: "yyyy-MM-ddTHH:mm:ss",
            startingDay: 1
        };
        $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];
        $scope.open = function($event) {
            $event.preventDefault();
            $event.stopPropagation();
            $scope.opened = true;
        };
        var fetchCycles = function () {
            $http.post('api/cycles/allCycleList').then(function (cycles) {
                cycles=cycles.data;
                $scope.cycles = cycles;
            });
        };
        fetchCycles();
        var cycle, asOfDate;
        $scope.deleteCycle = function ($event, toDelete) {
            cycle = toDelete;
            showDoubleConfirmModal($event, onDoubleConfirmDelete, 'Would you like to delete '+ cycle.name + ' cycle?');
        };
        $scope.setActive = function ($event, asOfDate) {
            showDoubleConfirmModal($event, onDoubleConfirmSetActive, 'Would you like to set '+ asOfDate.toDateString() + ' as active cycle?');
        };
        var showDoubleConfirmModal = function ($event, onConfirm, subHeader) {
            $mdDialog.show({
                controller: 'confirmWithCheckModalCtrl',
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                parent: angular.element(document.body),
                targetEvent: $event,
                clickOutsideToClose: true,
                escapeToClose: true,
                resolve:{
                    onConfirm: function () {
                        return onConfirm;
                    },
                    subHeader: function () {
                        return subHeader;
                    }
                }
            });
        };
        var onDoubleConfirmDelete = function (status) {
            if(!status) {
                toaster.pop('error', "Delete", 'Select checkbox and click yes to update!');
                return;
            }
            $http.post('api/cycles/archive',{id:cycle._id}).then(function(result) {
                toaster.pop('success', "Delete", 'Successful');
                _.remove($scope.cycles, {_id: cycle._id});
            }).catch(function(err) {
                const message = err && err.message ? err.message : 'Error occured while deleting cycle.'
                toaster.pop('error', "Delete", message);
            });
        };
        var onDoubleConfirmSetActive = function (status) {
            if(!status) {
                toaster.pop('error', "Set Active", 'Select checkbox and click yes to update!');
                return;
            }
            var asOfDate = $scope.asOfDate.getTime();
            $http.post('api/cycles/setActiveStatus', {asOfDate}).then(function(result) {
                toaster.pop('success', "Set Active", 'Successful');
                fetchCycles();
            }).catch(function(err) {
                const message = err && err.message ? err.message : 'Error occured while setting active status.'
                toaster.pop('error', "Set Active", message);
            });
        }
    });
