'use strict';
angular.module('bitnudgeApp').controller('leadsTimelineController', function ($scope, $mdDialog, lead, history) {
    $scope.lead = lead;
    $scope.histories = history;
    $scope.historiesLoaded = true;
    // $scope.histories = _.filter(lead.histories, function(history){
    //     if(history.historyType == 'statusChanged' || history.historyType == 'created') {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // })

    $scope.cancel = function () {
        $mdDialog.hide();
    }
});