'use strict';

'use strict';
angular.module('bitnudgeApp')
    .service('mgrDashboardService', ['mgrDashboardConfigService', 'mgrDashboardMetricService', '$q', 'toaster', function (configService, metricService, $q, toaster) {
        const self = this;
        this.widgets = {};
        let isConfigLoaded = false;
        let isMetricLoaded = false;
        this.context = null;
        this.config = null;
        this.metrics = {};
        this.isInitialized = false;
        this.metricPromises = [];

        this.register = function (w) {
            this.widgets[w.name] = w;
        };

        this.hasWidgets = function () {
            return this.getWidgetNames().length > 0;
        };

        this.getWidgetNames = function () {
            return this.widgets ? Object.keys(this.widgets) : [];
        };

        this.getMetricNames = function (widgetName, dynamicParams) {
            if (!_.isEmpty(this.config.data)) {
                var selectedWidgetConfig = null;
                if (widgetName === 'W_CUSTOMER_PARAMS') {
                    selectedWidgetConfig = this.config.data.filter(function(config) {
                        if (dynamicParams && !_.isUndefined(dynamicParams.dataType)) {
                            if (dynamicParams.dataType === 'Count') {
                                return config.widget === widgetName && !config.metric.toString().toLowerCase().includes('value');
                            } else {
                                return config.widget === widgetName && config.metric.toString().toLowerCase().includes('value');
                            }
                        } else {
                            return config.widget === widgetName;
                        }
                    })
                } else {
                    selectedWidgetConfig = _.filter(this.config.data, { 'widget': widgetName});
                }
                return _.map(selectedWidgetConfig, 'metric');
            }
            return [];
        };


        this.init = function (context) {
            this.isInitialized = false;
            this.context = context;
            this.config = null;
            this.metrics = {};
            this.metricPromise = null;
            this.userId = context.userId;
            this.configPromise = configService.getConfig(context.cycleId, context.roleType, context.userId).then(config => {
                this.config = config;
                this.isInitialized = true
            }).catch((err) => {
                console.log('dashboard config initialization failed with error: ', err.message);
                toaster.pop('error', 'Dashboard', 'Dashboard config error');
            });
            return this.configPromise;
        };

        this.fetchMetrics = function (widgetName, dynamicParams) {
            if (!this.hasWidgets()) return;
            if (!this.metricPromise) {
                let toBeFetched = this.getMetricNames(widgetName, dynamicParams);
                if (toBeFetched.length === 0) {
                    return;
                }
                return this.metricPromise = metricService.getMetrics(this.context.cycleId, this.context.roleType, toBeFetched, dynamicParams, false, this.userId).then(metrics => {                    
                    this.metrics[widgetName] = { ...this.metrics[widgetName], ...metrics };
                    this.metricPromise = null;
                }).catch((err) => {
                    console.log('dashboard metrics initialization failed with error: ', err);
                    toaster.pop('error', 'Dashboard', 'Dashboard metrics error');
                });
            } else {
                this.metricPromise.then(() => this.fetchMetrics());
            }
        };

        this.renderWidgets = async function (widgetNames, dynamicParams) {
            for (var index = 0; index < widgetNames.length; index++) {
                await this.fetchMetrics(widgetNames[index], dynamicParams);
                this.renderWidget(widgetNames[index])
              }
        };

        this.renderWidget = function (name) {
            const widget = this.widgets[name];
            const obj = this.widgets[name].metrics.reduce((obj, s) => {
                obj[s] = this.metrics[name];
                return obj;
            }, {});
            const data = JSON.parse(JSON.stringify(obj));
            if (widget) {
                widget.render(this.config, data);
            }
        };
    }]);
