const autoImportNgTemplateLoaderTemplate1 = require('../html/managePeersModal.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('leagueTableController', function ($scope, $timeout, $http, Auth, fetchLeagues, p0) {

        $scope.showCardView = true;
        $scope.isSlickDataLoaded = false;
        $scope.allCycles = p0.allMonthlyCycles;
        $scope.selectedCycle = p0.currentMonthlyCycle;
        $scope.isSingleKPI = false;

        function getJobRoles(){
            if($scope.playerType == 'managers'){
                return p0.myManagerRoles;
            }else{
                return p0.myFrontlineRoles;
            }
        }

        $scope.me=Auth.getCurrentUser();
        $scope.roles = getJobRoles();
        $scope.selectedRole = $scope.roles[0];
        $scope.isOwn = ($scope.me.organizationalTag.jobRole._id == $scope.selectedRole._id);
        $scope.hideCompare = false;
        if($scope.me.systemRole == 'frontline')
            $scope.hideCompare = !$scope.isOwn;

        $scope.allLeagues =fetchLeagues.getAllLeagues($scope.me, $scope.isOwn);
        $scope.selectedLeague = $scope.allLeagues[0];

        $scope.jobRoleChanged = function(){
            $scope.dataRequested = true;
            $scope.getRelevantKpis();
        };
        $scope.getRelevantKpis = function(){
            var relevantDivisions = $scope.selectedRole.division;
            $scope.kpisForDropdown = [];
            relevantDivisions.forEach(function(division){
                var selectedDivision = _.find(p0.allDivisions,{_id:division});
                $scope.kpisForDropdown = $scope.kpisForDropdown.concat(selectedDivision.kpis);
            });
            $scope.kpis = _.cloneDeep($scope.kpisForDropdown);
            $scope.kpisForDropdown.unshift({name:'Overall', _id:'overall'});
            //Don't show KPI Filter if there is one KPI
            if($scope.kpisForDropdown.length == 2){
                $scope.isSingleKPI = true;
            }
            $scope.selectedKpi = $scope.kpisForDropdown[0];
            $scope.activities = _.cloneDeep(p0.allActivities);
            $scope.activities = $scope.activities.filter(function (element, index, list) {
                return element.leaderboard==true;
            });
            $scope.activities = _.sortBy($scope.activities, 'activityCode');
            //Remove LMS compliance LB
            _.remove($scope.activities,{activityCode:'A05'})
            if($scope.playerType == "frontline") {_.remove($scope.activities,{activityCode:'A09'})}
            $scope.lbs = getLbs();

            // Fetch data for default tab
            if($scope.me.systemRole == 'frontline') {
                if($scope.playerType == "frontline") {
                    $scope.dataRequested = true;
                }
            }else {
                if($scope.playerType == "managers") {
                    $scope.dataRequested = true;
                }
            }
            // Handling data requested for params cycle, role, league.
            if($scope.dataRequested) {
                $scope.dataRequested = false;
                $scope.getAllLeaderboards();
            }
        };

        $scope.dataRequested = false;
        $scope.getAllLeaderboards=function(){

            if(!$scope.showCardView){
                $scope.onLbClick($scope.teamType ,$scope.kpiId, $scope.activityId, $scope.headerOptions);
            }
            else{
                var cycle = $scope.selectedCycle,
                    role = $scope.selectedRole,
                    league = $scope.selectedLeague;
                if(!cycle || !role || !league) {
                    $scope.dataRequested = true;
                    return;
                }
                if($scope.lbs){
                    var lbArray;
                    lbArray = $scope.lbs;
                    lbArray.forEach(function(lb, index){
                        getLb(lb.type, lb.kpiId, lb.activityId, cycle, role, league, lbArray, "#overallLB-loading");
                    });
                }
            }
            if($scope.selectedLeague)
                $scope.showPeerButton = ($scope.selectedLeague.value=="peer");
        };

        $scope.getRelevantKpis();

        $scope.onChangeKpi = function() {
            $scope.lbs =[];
            if($scope.selectedKpi._id == 'overall') {
                $scope.lbs = getLbs();
            }else {
                $scope.lbs = getLbsForKpi($scope.selectedKpi._id);
            }
            $scope.dataRequested = false;
            $scope.teamType='activity';

            $scope.getAllLeaderboards();
        };

        $scope.parentCtrlObj.getData = $scope.getAllLeaderboards;

        $scope.openManagePeersModal = function(){
            $mdDialog.open({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'managePeersModalController',
                size: 'md',
                resolve: {
                    refreshDataCB:  function(){return   $scope.getAllLeaderboards;}
                }
            });
        };

        function getLb(type, kpiId, activityId, cycle, jobRole, league, lbArray, congratsElement) {
            //$(congratsElement).text("Loading...");
            var cache = league.value != "peer";
            const urlDetails = getLbRoute(type, kpiId, activityId, cycle, jobRole, league);
            $http.post(urlDetails.url, urlDetails.postData).then(function(leaderboard){
                leaderboard=leaderboard.data;
                var numOfReporteesInTopFive, numOfReportees;
                var userLeaders = leaderboard.leaderboard
                if($scope.me.children!= undefined) {
                    numOfReportees= $scope.me.children.length
                    userLeaders = _.filter(userLeaders, function(user){
                        var userIds = _.map($scope.me.children, function(user){return user.childId.toString()});
                        return _.includes(userIds, user.userId._id.toString());
                    });
                    if(userLeaders)
                        numOfReporteesInTopFive = userLeaders.length
                }
                var index = _.findIndex(lbArray, {type:type, kpiId:kpiId, activityId:activityId,});
                lbArray[index].leaderboard = leaderboard.leaderboard;
                lbArray[index].userRank = leaderboard.userRank;
                lbArray[index].leagueSize = leaderboard.leagueSize;
                lbArray[index].managerRank = leaderboard.managerRank;
                lbArray[index].numOfReportees = numOfReportees;
                lbArray[index].numOfReporteesInTopFive = numOfReporteesInTopFive;
                lbArray[index].loadingText = getCongratsText(lbArray[index]);
                //$(congratsElement).parent().parent().hide();
            });
        }

        var getCongratsText = function(lbObject) {
            var rank = lbObject.userRank;
            var leagueSize = lbObject.leagueSize;
            if(rank == 1)         return "Congratulations! You are ranked #1";

            if($scope.isOwn)
                return "You are ranked #" + rank + " out of " + leagueSize + " players";
            else{
                if($scope.me.systemRole == 'frontline'){
                    if(lbObject.managerRank == 1)
                        return "Congratulations! Your team ranks #1";
                    return "Congrats! Your team is among top 5 teams";
                }
                else{
                    if(lbObject.numOfReporteesInTopFive)
                        return "You have "+ lbObject.numOfReporteesInTopFive+ " out of " + lbObject.numOfReportees+ " reportees in top 5 ";
                    else
                        return "You have no reportees in top 5 ";
                }
            }
        };

         function getLbRoute(type, kpiId, activityId, cycle, jobRole, league) {
                const url = '/api/analytics/leaderboards/cycles';
                const postData = {
                    cycleId: cycle._id,
                    kpiId,
                    activityId,
                    count:5,
                    type,
                    playerType: jobRole._id,
                    teamType: league.value,
                    teamId: league._id,
                    lbOn
                }
                return {url,postData}
        }

        $scope.onLbClick=function(type, kpiId, activityId,options){
            $scope.teamType = type.toLowerCase();
            $scope.kpiId = kpiId;
            $scope.activityId = activityId;
            $scope.headerOptions = options;
            $scope.showComplete();
            $scope.getCompleteList(type.toLowerCase(),kpiId, activityId,options.lbHeader);
        };

        $scope.showComplete = function() {
            $scope.showCardView=false;
        };

        $scope.showLbCards = function() {
            $scope.showCardView=true;
        }

        $scope.getCompleteList=function(type, kpiId, activityId, lbHeader){
            var league = $scope.selectedLeague;
            var jobRole = $scope.selectedRole;
            var cycle = $scope.selectedCycle;

            if(!league || !jobRole || !cycle)  return;

            $scope.completeType=type;

            //must be done because we are piggybacking activity leaderboards in the same type as kpis. However, their routes
            //are different. So some hacking must be done to check if type refers to kpi or activity
            const postData = {
                cycleId: cycle._id,
                kpiId,
                activityId,
                count:5,
                type,
                playerType: jobRole._id,
                teamType: league.value,
                teamId: league._id,
            }
            $http.post('/api/analytics/leaderboards/cycles', postData).then(function(leaderboard){
                leaderboard=leaderboard.data;
                $scope.completeLB=leaderboard.leaderboard;
                $scope.selectedLeagueCardType=type;
                $scope.selectedCategory=lbHeader;
            });
        };

        function getLbs () {
            $scope.isSlickDataLoaded = false;
            var lbs = [];
            var loadingText = "Loading...";
            lbs.push({name:"Overall "+$scope.strings.POINTS.value,                type:"total",           kpiId:"overall",        activityId:"overall",               leaderboard:null,           loadingText:loadingText});
            lbs.push({name:$scope.strings.IMPACT.value+' '+$scope.strings.POINTS.value,                 type:"business",        kpiId:"overall",        activityId:"overall",               leaderboard:null,           loadingText:loadingText});
            lbs.push({name:$scope.strings.ACTIVITY.value+' '+$scope.strings.POINTS.value,               type:"activity",        kpiId:"overall",        activityId:"overall",               leaderboard:null,           loadingText:loadingText});
            //lbs.push({name:"Badges",                        type:"badge",           kpiId:"overall",        activityId:"overall",               leaderboard:null,           loadingText:loadingText});
            //Hide KPI Leader board in overall LB, if there is only one kpi
            if($scope.kpis.length = 1){
                lbs.push({name:'Conversion ratio',          type:"kpi",             kpiId:$scope.kpis[0]._id,   activityId:"conversionRatio",               leaderboard:null,           loadingText:loadingText});
            } else{
                $scope.kpis.forEach(function(kpi){
                    lbs.push({name:kpi.name,             type:"kpi",             kpiId:kpi._id,   activityId:"overall",               leaderboard:null,           loadingText:loadingText});
                });
            }
            $scope.activities.forEach(function(activity){
                lbs.push({name:activity.name,      type:"activity",        kpiId:"overall",        activityId:activity._id, leaderboard:null, loadingText:loadingText});
            });
            setTimeout(function(){
                $scope.isSlickDataLoaded = true;
            },5);
            return lbs;
        }

        var getLbsForKpi = function (kpiId) {
            var lbs = [];
            $scope.isSlickDataLoaded = false;
            var loadingText = "Loading...";
            var kpi = _.find($scope.kpis, {_id: kpiId});
            lbs.push({name:kpi.name,                 type:"kpi",             kpiId:kpi._id,   activityId:"overall",               leaderboard:null,           loadingText:loadingText});
            var kpiSpecificActivities = _.filter($scope.activities, function(activity){ return activity.kpiSpecific; });
            kpiSpecificActivities.forEach(function(activity){
                lbs.push({name:activity.name,          type:"activity",        kpiId:kpiId,            activityId:activity._id,   leaderboard:null,           loadingText:loadingText});
            });
            lbs.push({name:'Conversion ratio',              type:"kpi",             kpiId:kpi._id,   activityId:"conversionRatio",               leaderboard:null,           loadingText:loadingText});
            setTimeout(function(){
                $scope.isSlickDataLoaded = true;
            },5);
            return lbs;
        };
    });
