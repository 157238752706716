const autoImportNgTemplateLoaderTemplate1 = require('../html/scoreCardModal.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('graphCardCtrl', function ($scope, p0,$rootScope, $mdDialog) {
        $scope.config = $rootScope.config;
        $scope.unit = _.find(p0.allUIKpis, {_id:$scope.kpi.kpiId}).unit;
        $scope.denom = _.find(p0.allUIKpis, {_id:$scope.kpi.kpiId}).denom;
        $scope.mtd = 'MTD';
        if(!$scope.isActiveCycle) $scope.mtd = 'ACTUAL';
        var gauge;
        function setZone() {
            if($scope.kpi.swot == 's'){
                $scope.zone = 'green';
            }else if($scope.kpi.swot == 'o'){
                $scope.zone = 'blue';
            }else if($scope.kpi.swot == 'w'){
                $scope.zone = 'amber';
            }else $scope.zone = 'red';
        }
        var setMeterColors = function () {
            var colors = {};
            var firstArc, secondArc, firstArcColor, secondArcColor;
            var mtdPercentage = $scope.kpi.percentageCompleted.value * $scope.mtdMultiplier>100?100:$scope.kpi.percentageCompleted.value * $scope.mtdMultiplier;
            var blue = Math.round(mtdPercentage);
            var benchmarkValue = $scope.benchmarkValue ? $scope.benchmarkValue.value *($scope.applyForecast?$scope.forecastMultiplier:1) : 0;
            var black = Math.round(benchmarkValue >100?100:benchmarkValue);
            if(blue > black){
                firstArcColor = '#464c4d';
                secondArcColor = '#2f91ce';
                firstArc = black; secondArc = blue;
            }else if(blue < black){
                firstArcColor = '#2f91ce';
                secondArcColor = '#464c4d';
                firstArc = blue; secondArc = black;
            }else{
                firstArcColor = '#2f91ce';
                secondArcColor = '#464c4d';
                firstArc = blue; secondArc = blue;
            }
            colors['0'] = {
                color: firstArcColor,
                lineWidth: 10
            };
            colors[firstArc] = {
                color: secondArcColor,
                lineWidth: 10
            };
            colors[secondArc] = {
                color: '#c1beb9',
                lineWidth: 4
            };
            return colors;
        };
        var getArrowValue = function () {
          return $scope.kpi.percentageCompleted.value * $scope.mtdMultiplier>100?100:$scope.kpi.percentageCompleted.value * $scope.mtdMultiplier;
        };
        function initGauge() {
            var gaugeInput = {
                angles: [170, 370],
                lineWidth: 10,
                arrowWidth: 20,
                arrowColor: '#87c96f',
                arrowCircleWidth: 12,
                arrowLineWidth: 4,
                inset: true,
                mtd: 30,
                amount: 300000,
                previous: 30,
            };
            gaugeInput.colors = setMeterColors();
            //gaugeInput.value = getArrowValue();
            var gaugeElement = $('#gauge'+$scope.cardId).gauge(gaugeInput);
            gauge=gaugeElement.data('plugin-gauge');
            function shakeRight(){
                if(gauge){
                    gauge.setValue(getArrowValue());
                }
                setTimeout(function () {
                    shakeRight();
                },1000);
            }
            function shakeLeft(){
                if(gauge){
                    gauge.setValue(getArrowValue()-2);
                }
                setTimeout(function () {
                    shakeLeft();
                },1500);
            }
            setTimeout(function () {
                shakeRight();
                shakeLeft();
            }, 100);
        }
        setTimeout(function () {
            initGauge();
        },1000);
        setZone();
        var setActualValue = function () {
            if($scope.benchmark._id == 'forecast'){
                $scope.percentageCompleted = $scope.kpi.percentageCompleted.value * $scope.forecastMultiplier;
                $scope.achievedValue = $scope.kpi.achievedValue.value * $scope.forecastMultiplier;
                $scope.actual = 'F';
            }else{
                $scope.percentageCompleted = $scope.kpi.percentageCompleted.value;
                $scope.achievedValue = $scope.kpi.achievedValue.value;
                $scope.actual = 'ACTUAL';
            }
        };
        $scope.mtdMultiplier = 1;
        $scope.$watchGroup(['benchmarkValue', 'compareValue','applyForecast'], function (newValue) {
            if(newValue){
                if($scope.compareValue == 'actual')$scope.mtdMultiplier = 1;
                else $scope.mtdMultiplier =  $scope.forecastMultiplier;
                if(gauge){
                    gauge.createPaths(setMeterColors());
                    gauge.createArrow();
                    gauge.setValue(getArrowValue());
                }
                setActualValue();
            }
        });

        if($rootScope.config.showScoreCard){
            //only for tanfeeth;
            var viewKpis = ['K01','K02','K03','K04','K05', 'K06'];
            var kpiCode = _.find(p0.allKpis,{_id:$scope.kpi.kpiId}).kpiCode;
            $scope.scoreCard = _.includes(viewKpis, kpiCode);
            $scope.openScoreCard = function(){
                var kpi = $scope.kpi;
                $mdDialog.show({
                    controller: 'scoreCardModalCtrl',
                    templateUrl: autoImportNgTemplateLoaderTemplate1,
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    escapeToClose: true,
                    resolve:{
                        user:function(){return $scope.selectedUser},
                        kpi: function() { return kpi;}
                    }
                });
            };
        }

    });
