const autoImportNgTemplateLoaderTemplate1 = require('../html/gpCommentBox.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('gpCommentBox', function(){
        return {
            name: 'gpCommentBox',
            scope: {
                comment:'=',
                addReply:'=',
                selectedPlayerId:'@'
            },
            controller: 'gpCommentBoxCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1,
        };
    });
