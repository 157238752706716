const autoImportNgTemplateLoaderTemplate1 = require('../html/targetRatesModal.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/dailyGameplanDetail.html');

angular.module('bitnudgeApp')
    .controller('gpAnalyzeCtrl', function($scope, $timeout, $http, $rootScope, Auth, toaster, getGPService, $state, p0, numberFilter, CurrentCycle, benchmark, userAnalyticsService, $mdDialog) {
        $scope.config = p0.config;
        $scope.isReqEntirePeriod = false;
        $scope.me = Auth.getCurrentUser();
        $scope.selectedPlayerId = $scope.me._id;
        $scope.isSlickDataLoaded = false;
        $scope.userId = Auth.getCurrentUser()._id;
        $scope.commentFilter = 'All';
        var activityRingChart = {};
        $scope.kpis = p0.allKpis; // for tagging
        $scope.isRate = false;
        $scope.dailyActivityRate = {};
        $scope.jobRole = $scope.me.organizationalTag.jobRole;
        $scope.params = { denom: 1 };
        $scope.isSeniorManager = $rootScope.isSeniorManager;

        $scope.activityLeft = function() {
            if ($scope.activityIndex > 0) {
                $scope.activityIndex--;
            }
        };
        $scope.activityRight = function() {
            if ($scope.activityIndex < $scope.importantActivities.length - 3) {
                $scope.activityIndex++;
            }
        };
        var salesRingChart = [];

        var setKPI = function(kpiId) {
            var index = _.findIndex($scope.goals, function(goal) { return (goal.kpiId.toString() == kpiId) });
            $scope.selectedKPI = $scope.goals[index];
            if ($scope.config.mtdUploadType == 'points') {
                $scope.params.denom = 1;
            } else {
                $scope.params.denom = $scope.selectedKPI.denom;
            }
        };

        $scope.onKPIChange = function(kpi) {
            setKPI(kpi.kpiId);
            setImportantActivities(kpi.kpiId);
            fetchWeeklyAndDailyGP(kpi.kpiId);
            fetchComments(kpi.kpiId);
        };

        $scope.onWeekChange = function(week) {
            $scope.selectedWeek = week;
        }

        var setImportantActivities = function(kpiId) {
            var importantActivities = getGPService.getImportantActivities();
            var kpi = _.find(p0.allKpis, { _id: kpiId });
            importantActivities = _.filter(importantActivities, function(activity) {
                return kpi.activities.indexOf(activity._id) != -1;
            });
            $scope.importantActivities = importantActivities;

            $scope.importantActivities.forEach(function(activity) {
                activityRingChart[activity.fieldName] = [];
            });
            $scope.maxArray = [];
            for (var i = 0; i < Math.min($scope.importantActivities.length, 3); i++) {
                $scope.maxArray[i] = i;
            }
            $scope.activityIndex = 0;
        };

        var getGP = function(userId, cycle) {
            if (!userId) userId = $scope.me._id;
            getGPService.getGoal(userId, cycle)
                .then(function(result) {
                    $scope.goals = result;
                    setKPI($state.params.selectedKPIId);
                    fetchWeeklyAndDailyGP($state.params.selectedKPIId);
                });

        };

        var fetchWeeklyAndDailyGP = function(kpiId) {
            var index = _.findIndex($scope.goals, function(goal) { return (goal.kpiId.toString() == kpiId) });
            $scope.selectedKPI = $scope.goals[index];

            var reqPerDay = $scope.selectedKPI.target.value / $rootScope.daysTotal;
            $scope.onTrack = 'on-track';
            if ($scope.config.mtdUploadType == 'points') {
                $scope.planGap = Math.round((($scope.selectedKPI.denom * $scope.selectedKPI.achievedValue.value) - ($rootScope.daysPassed * reqPerDay * $scope.selectedKPI.denom)));
                if ($scope.planGap < 0) { $scope.onTrack = 'off-track'; }
                $scope.planGap = $scope.planGap + ' points';
            } else {
                $scope.planGap = Math.round((($scope.selectedKPI.achievedValue.value) - ($rootScope.daysPassed * reqPerDay)));
                if ($scope.planGap < 0) { $scope.onTrack = 'off-track'; }
                $scope.planGap = $scope.planGap + ' ' + $scope.selectedKPI.unit;
            }
            if ($scope.config.weeklyCycle) {
                upDateWeeklyGoals(index);
            }
            if ($scope.config.targetPeriod == 'month') {
                updateDailyGoals($scope.selectedKPI.kpiId);
            }
            if ($scope.selectedKPI) {
                $scope.setSelectedKPI($scope.selectedKPI);
                $scope.setRateIndicators();
            }
        };

        $scope.onCycleChange = function(cycle) {
            CurrentCycle.setCycle(cycle);
        };

        var upDateWeeklyGoals = function(index) {
            getGPService.getWeeklyGoals($scope.userId, $scope.goals[index].kpiId, $scope.selectedCycle._id, $scope.goals[index].activityConversionRatios, $scope.goals[index].averageTicketSize.value).then(function(weeks) {
                $scope.goals[index].weeks = weeks;
                $scope.activeWeekIndex = _.findIndex(weeks, { activeWeek: true });
                $scope.selectedWeek = _.find(weeks, { isEditable: true });
                var editableWeek = _.find(weeks, { isEditable: true });
                if (editableWeek) {
                    $scope.editableWeekCycleId = editableWeek.cycleId;
                }
                if ($scope.config.targetPeriod == 'month') {
                    $scope.isSlickDataLoaded = true;
                } else {
                    $scope.weeks = weeks.slice($scope.activeWeekIndex, $scope.activeWeekIndex + 1);
                    $scope.selectedWeek = $scope.weeks[0];
                    getGPService.getRateIndicator($scope.importantActivities[$scope.activityIndex], $scope.selectedWeek, p0.currentWeeklyCycle._id).then(function(args) {
                        var rateColor = args[0],
                            rateText = args[1];
                        $scope.selectedWeek[$scope.importantActivities[$scope.activityIndex].fieldName].trafficColor = rateColor;
                        $scope.selectedWeek[$scope.importantActivities[$scope.activityIndex].fieldName].trafficText = rateText;
                    });
                }
            });
        };

        var updateDailyGoals = function(kpiId) {
            getGPService.getDailyGoal(kpiId).then(function(result) {
                $scope.dailyGp = result;
                $scope.dailyGp.forEach(function(daily) {
                    var cycleName = daily.cycleId.name.split(' ');
                    if (cycleName.length > 2) {
                        daily.cycleId.name = cycleName[0] + ', ' + cycleName[1] + ' ' + cycleName[2];
                    }
                });
                if ($scope.dailyGp.length) {
                    $scope.dailyGp[0].cycleId.name = 'Today';
                    if ($scope.config.mtdUploadType == 'points') {
                        $scope.dailyGp[0].kpiData.newGameplanTarget = $scope.dailyGp[0].kpiData.gameplanTarget.value;
                    } else {
                        $scope.dailyGp[0].kpiData.newGameplanTarget = $scope.dailyGp[0].kpiData.gameplanTarget.value / $scope.params.denom;
                    }
                }
                if ($scope.config.mtdUploadType == 'points') {
                    $scope.dailyActivityRate.suggestion = Math.max(($scope.selectedKPI.target.value - $scope.selectedKPI.achievedValue.value) * $scope.selectedKPI.denom, 0) / $rootScope.daysLeft;
                } else {
                    $scope.dailyActivityRate.suggestion = Math.max(($scope.selectedKPI.target.value - $scope.selectedKPI.achievedValue.value), 0) / $rootScope.daysLeft;
                }

                $scope.importantActivities.forEach(function(activity) {
                    $scope.dailyActivityRate[activity.fieldName] = {};
                    $scope.dailyActivityRate[activity.fieldName].reqRate = getGPService.getTargetActivitiesForMonthType($scope.selectedKPI.kpiId, $scope.dailyGp[0].kpiData.gameplanTarget.value, $scope.selectedKPI.activityConversionRatios[activity.fieldName].value, $scope.selectedKPI.averageTicketSize.value);
                    $scope.dailyActivityRate[activity.fieldName].suggestionRate = getGPService.getTargetActivitiesForMonthType($scope.selectedKPI.kpiId, $scope.dailyActivityRate.suggestion, $scope.selectedKPI.activityConversionRatios[activity.fieldName].value, $scope.selectedKPI.averageTicketSize.value);
                });
            });
        };

        $scope.cycles = [p0.currentMonthlyCycle];
        $scope.selectedCycle = p0.currentMonthlyCycle;
        $scope.showInfoModal = function(selectedGoal, type) {
            if (type == 'activity') {
                type = $scope.importantActivities[$scope.activityIndex].fieldName;
            }
            $scope.selectedGoal = selectedGoal;
            getGPService.showModals($scope.me, $scope.selectedGoal, type);
        };

        $scope.showLegendInfoModal = function() {
            getGPService.showLegendModal();
        };

        $scope.setRateIndicators = function() {
            $scope.importantActivities.forEach(function(activity) {
                if ($scope.selectedKPI[activity.fieldName] && $scope.me.roleType != 'seniorManager') {
                    getGPService.getRateIndicator(activity, $scope.selectedKPI, p0.currentMonthlyCycle._id).then(function(args) {
                        var rateColor = args[0],
                            rateText = args[1];
                        $scope.selectedKPI[activity.fieldName].trafficColor = rateColor;
                        $scope.selectedKPI[activity.fieldName].trafficText = rateText;
                    });
                }
            });

        };

        $scope.cancelGamePlanForGoal = function(goal) {
            if (parseFloat(goal.gameplanTarget.value) == 0) {
                goal.newGpValue = 0
            }
            goal.gameplanPercentage = parseFloat(goal.gameplanTarget.value) / goal.target.value * 100;
        };

        $scope.saveGamePlanForGoal = function(goal) {
            goal.gameplanPercentage = goal.newGpValue / goal.target.value * 100;
            $scope.selectedKPI.gameplanTarget.value = parseFloat(goal.newGpValue);
            $http.post('/api/gameplans/setGP', { cycleId: $scope.selectedCycle._id, kpiId: $scope.selectedKPI.kpiId, gameplanTarget: { value: goal.newGpValue * goal.denom } }).then(function(goal) {
                toaster.pop('success', "Save", "Your monthly GamePlan is successfully saved");
            });
            $scope.grrValidity = true;
            goal.gameplanTarget.value = goal.newGpValue;
        };
        $scope.saveGamePlanForGoalWeek = function(goal) {
            goal.gameplanPercentage = goal.newGpValue / goal.target.value * 100;
            goal.gameplanTarget.value = parseFloat(goal.newGpValue);
            $http.post('/api/gamePlans/week', {cycleId:goal.cycleId, kpiId:goal.kpiId, gameplanTarget: { value: goal.newGpValue * goal.denom } }).then(function() {
                toaster.pop('success', "Save", "Your weekly GamePlan is successfully saved");
            });
        };
        $scope.saveDailyGP = function(cycleId, kpiId, newGpValue) {
            var newTarget = newGpValue;
            if ($scope.params.mtdUploadType != 'points') {
                newTarget = newTarget * $scope.params.denom;
            }
            $http.post('/api/gameplans/setGP', { cycleId, kpiId, gameplanTarget: { value: newTarget } }).then(function(goal) {
                goal = goal.data;
                $scope.dailyGp[0].kpiData.gameplanTarget.value = newGpValue;
                toaster.pop('success', "Save", "Your daily GamePlan is successfully saved");
            });
        };
        $scope.cancel = function() {
            $scope.dailyGp[0].kpiData.newGameplanTarget = $scope.dailyGp[0].kpiData.gameplanTarget.value;
        };

        $scope.calculateDailyActivityRate = function(newGp) {
            $scope.importantActivities.forEach(function(activity) {
                $scope.dailyActivityRate[activity.fieldName].reqRate = getGPService.getTargetActivitiesForMonthType($scope.selectedKPI.kpiId, newGp, $scope.selectedKPI.activityConversionRatios[activity.fieldName].value, $scope.selectedKPI.averageTicketSize.value);
            });
        };

        $scope.goToGoalSummary = function() {
            $state.go('userFlow.gameplan', { selectedPlayerId: $scope.me._id });
        };

        $scope.grrValidity = true;
        $scope.setSelectedKPI = function(goal) {
            if (goal) {
                var selectedKPI = _.find(p0.allKpis, { _id: goal.kpiId });
                var selectedActivities = _.filter(p0.allActivities, function(activity) {
                    return _.includes(selectedKPI.activities, activity._id);
                });
                $scope.graphActivities = [];
                $scope.importantActivities.forEach(function(impActivity) {
                    var arrActivity = _.find(selectedActivities, { fieldName: impActivity.fieldName });
                    if (arrActivity) {
                        $scope.graphActivities.push(impActivity);
                    }
                });
                $scope.graphActivities = $scope.graphActivities.slice(0, 2);
                $scope.previousGameplanValue = goal.gameplanTarget.value;
                $scope.gameplanRunRate = getGPService.getGamePlanRunRateOfKPI($scope.selectedKPI);
                $scope.grrWeeklyPlan = $scope.gameplanRunRate;
                $scope.targetRunRate = goal.targetRunRate;
                $scope.currentRunRate = goal.currentRunRate;
                if ($scope.gameplanRunRate < 0) {
                    $scope.grrValidity = false;

                    $scope.showSaveBtnInReportee = false;
                    $scope.grrValidityInReportee = false;
                } else {
                    $scope.grrValidity = true;

                    $scope.showSaveBtnInReportee = true;
                    $scope.grrValidityInReportee = true;
                }

                userAnalyticsService.fetchUserAnalytics($scope.me._id, $scope.selectedCycle._id).then(function(userAnalytic) {
                    if ($scope.config.targetPeriod == 'month') {
                        $scope.conversionRatios = _.filter(userAnalytic.activityData, function(activityData) {
                            return activityData.operand1 && activityData.kpiId && activityData.kpiId.toString() == goal.kpiId.toString();
                        });
                        _.forEach($scope.conversionRatios, function(ratio) {
                            ratio.ratioName = ratio.name;
                            ratio.ratioValue = ratio.value.value * 100;
                        });
                        $scope.averageTicketSize = goal.averageTicketSize.value;
                    } else {
                        var fieldNames = ['volumes', 'accuracyp'];
                        $scope.conversionRatios = _.filter(userAnalytic.activityData, function(activityData) {
                            return fieldNames.indexOf(activityData.fieldName) > -1 && activityData.kpiId && activityData.kpiId.toString() == goal.kpiId.toString();
                        });
                        _.forEach($scope.conversionRatios, function(ratio) {
                            ratio.ratioName = ratio.name + ' (' + ratio.unit + ')';
                            ratio.ratioValue = ratio.value.value;
                            if (ratio.activityType == 'percentage') {
                                ratio.ratioValue *= 100;
                            }
                        });
                    };
                });
            }
        };

        $scope.onSliderChange = function(goal) {
            $scope.grrWeeklyPlan = $scope.selectedKPI.gpRunRate;
            if ($rootScope.operations)
                $scope.updateWeeklyPlanTable();
            else
                $scope.updateReqForPlan(goal);
            $scope.grrValidity = $scope.grrWeeklyPlan > 0;
        };

        $scope.onSliderChangeWeek = function() {
            $scope.importantActivities.forEach(function(activity) {
                updatePlanHelper('weekly', $scope.selectedWeek, activity);
            });
        };

        $scope.updateWeeklyPlanTable = function() {
            if ($scope.grrWeeklyPlan >= 0) {
                if ($scope.selectedKPI.kpiType == 'value') {
                    $scope.impliedDocument = getGPService.getImpliedRunRateKpi($scope.selectedKPI);
                } else if ($scope.selectedKPI.kpiType == 'percentage') {
                    $scope.impliedAccuracy = getGPService.getImpliedRunRateKpi($scope.selectedKPI);
                }
            } else {
                $scope.impliedDocument = '-';
                $scope.impliedAccuracy = '-';
            }

            if ($scope.impliedAccuracy <= 0) $scope.impliedAccuracy = 0;
        };

        $scope.updateReqForPlan = function(goal) {
            getGPService.reCalcPlan(goal, $scope.isReqEntirePeriod);
        };

        $scope.showTargetRatesModal = function() {
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'targetRatesModalCtrl',
                resolve: {
                    goal: function() {
                        return $scope.selectedKPI;
                    }
                }
            });
        };


        $scope.formatDate = function(date, type) {
            var Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            var newDate = new Date(date);
            if (type == "comment")
                var reqDate = newDate.getDate() + "-" + Months[newDate.getMonth()] + "-" + newDate.getFullYear();
            else
                var reqDate = newDate.getDate() + "-" + Months[newDate.getMonth()];
            return reqDate;
        };

        $scope.saveWeeklyGP = function(week) {
            $http.post('/api/gamePlans/week', {cycleId:$scope.editableWeekCycleId,kpiId:$scope.selectedKPI.kpiId, gameplanTarget: { value: week.newGpValue * $scope.selectedKPI.denom } }).then(function() {
                toaster.pop('success', "Save", "Your weekly GamePlan is successfully saved");
            });
        };

        var fetchComments = function(kpiId) {
            $http.post('/api/users/gpCommentsForUser', {userId: $scope.me._id}).then(function(result) {
                result = result.data;
                $scope.allComments = _.filter(result, function(comment) {
                    comment.kpis = _.map(comment.kpis, function(kpi) {
                        return kpi.toString();
                    });
                    return _.includes(comment.kpis, kpiId.toString());
                });
                filterComments();
            });
        };

        var updatePlanHelper = function(cycleType, kpi, activity) {
            kpi[activity.fieldName].reqPlan = getGPService.getTargetThroughputRate(cycleType, kpi, false);
            if (kpi['accuracyp'].value == 0 || kpi['occupancy'].value == 0) {
                kpi[activity.fieldName].reqPlanActual = 0;
            } else {
                kpi[activity.fieldName].reqPlanActual = kpi[activity.fieldName].reqPlan / kpi['accuracyp'].value / kpi['occupancy'].value;
            }
        };
        var initGPAnalyze = function(kpiId) {
            setImportantActivities(kpiId);
            getGP($scope.me._id, $scope.selectedCycle, kpiId);
            fetchComments(kpiId);
        };
        initGPAnalyze($state.params.selectedKPIId);

        $scope.changeCommentFilter = function(filter) {
            $scope.commentFilter = filter;
            filterComments();
        };

        function filterComments() {
            switch ($scope.commentFilter) {
                case 'All':
                    $scope.comments = $scope.allComments;
                    break;
                case 'Manager':
                    $scope.comments = _.filter($scope.allComments, function(comment) {
                        return comment.commentor == $scope.selectedPlayer.parent;
                    });
                    break;
                case 'Others':
                    $scope.comments = _.filter($scope.allComments, function(comment) {
                        return comment.commentor != $scope.selectedPlayer.parent;
                    });
                    break;

            }
        }
        $scope.addReply = function(commentText, commentId) {
            if (commentText == undefined || commentText.trim() == "")
                toaster.pop('Failure', "Please enter a reply.");
            else {
                var reply = {};
                reply.date = new Date();
                reply.content = commentText;
                reply.commentorName = $scope.me.name;
                reply.commentor = $scope.me._id;
                reply['userId'] = $scope.me._id;
                reply.commentId = commentId;
                $http.post('/api/users/addGpReplyForComment', reply).then(function(result) {
                    result = result.data;
                    var index = _.findIndex($scope.comments, function(comment) {
                        return comment._id.toString() == commentId.toString();
                    });
                    reply.commentor = $scope.me;
                    $scope.comments[index].replies.push(reply);

                    toaster.pop('success', "Save", "Reply Successfully Added.");
                });

                $scope.gpComment = "";
            }
        };

        $scope.toggleActivities = function(val) {
            if (val) {
                if ($scope.activityIndex < $scope.importantActivities.length - 1) {
                    $scope.activityIndex++;
                    while ($scope.activityIndex < $scope.importantActivities.length - 1 && !$scope.selectedKPI[$scope.importantActivities[$scope.activityIndex].fieldName]) {
                        $scope.activityIndex++;
                    }

                    if (!$scope.selectedKPI[$scope.importantActivities[$scope.activityIndex].fieldName]) {
                        while ($scope.activityIndex > 0 && !$scope.selectedKPI[$scope.importantActivities[$scope.activityIndex].fieldName]) {
                            $scope.activityIndex--;
                        }
                    }
                }
            } else {
                if ($scope.activityIndex > 0) {
                    $scope.activityIndex--;
                    while ($scope.activityIndex > 0 && !$scope.selectedKPI[$scope.importantActivities[$scope.activityIndex].fieldName]) {
                        $scope.activityIndex--;
                    }
                    if (!$scope.selectedKPI[$scope.importantActivities[$scope.activityIndex].fieldName]) {
                        while ($scope.activityIndex < $scope.importantActivities.length - 1 && !$scope.selectedKPI[$scope.importantActivities[$scope.activityIndex].fieldName]) {
                            $scope.activityIndex++;
                        }
                    }
                }
            }
        };

        $scope.toggleTooltip = function() {
            $('#hover-element').popover('show');
        };

        $scope.reCalcReq = function(isReqEntirePeriod) {
            $scope.isReqEntirePeriod = isReqEntirePeriod;
            $scope.onSliderChange($scope.selectedKPI);
            if ($scope.config.weeklyCycle) {
                $scope.onSliderChange($scope.selectedWeek);
            }
        };


        //Daily detail modal
        $scope.showDailyGPDetails = function(event) {
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate2,
                controller: 'dailyGameplanDetailCtrl',
                parent: angular.element(document.body),
                targetEvent: event,
                clickOutsideToClose: true,
                fullscreen: true, // Only for -xs, -sm breakpoints.
                resolve: {
                    parameters: function() {
                        return {
                            dailyGp: $scope.dailyGp,
                            dailyActivityRate: $scope.dailyActivityRate,
                            importantActivities: $scope.importantActivities,
                            calculateDailyActivityRate: $scope.calculateDailyActivityRate,
                            saveDailyGP: $scope.saveDailyGP,
                            undo: $scope.cancel,
                            unit: $scope.config.mtdUploadType == 'points' ? 'points' : $scope.selectedKPI.unit,
                            denom: $scope.params.denom,
                            deficit: { value: $scope.planGap, style: $scope.onTrack }
                        };
                    }
                }
            })
        };


    });