'use strict';
angular.module('bitnudgeApp')
    .service('ThresholdReport', function (Auth, $http) {
        var _this = this;
        this.getSalesReport = function (kpiId, cycles, isActualSales) {
            var cycleIds = _.map(cycles, '_id');
            var options = {
                cycleIds: cycleIds,
                kpiId
            };
            options.metric = 'percentageCompleted';
            if(isActualSales) options.metric = 'achievedValue';
            return $http.post('/api/customReports/kpi', options).then(function (outputData) {
                outputData=outputData.data;
                return outputData;
            });
        };

        this.destroy = function () {

        }
    });
