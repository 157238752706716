angular.module('bitnudgeApp')
    .controller('listConfigCtrl', function ($http, $scope) {
        $scope.configs;
        function init(){
            $http.post('/api/config/index').then(function(configs){
                configs=configs.data;
                $scope.configs = _.sortBy(configs,'key');
            });
        };
        init();
    });
