const autoImportNgTemplateLoaderTemplate1 = require('../html/srManagerForm.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('srManagerForm', function () {
        return {
            name: 'srManagerForm',
            scope: {},
            controller: 'srManagerFormCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1

        };
    });
