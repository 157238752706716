const autoImportNgTemplateLoaderTemplate1 = require('./../html/component/previewComponent.html');

"use strict";
angular.module("bitnudgeApp").component("previewComponent", {
	templateUrl: autoImportNgTemplateLoaderTemplate1,
	bindings: {
		document: "<",
		selectMode: "<",
		approveMode: "<",
		viewMode: "<",
		workflowId:"<"
	},
	controller: "previewComponentCtrl"
});
