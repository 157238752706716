const autoImportNgTemplateLoaderTemplate1 = require('../html/admin/filterInfoModal.html');

'use strict';

angular.module('bitnudgeApp').controller('filterController',['$scope','$mdDialog','leadsService','Auth','toaster','p0', '$timeout', 'CurrentCycle', function ($scope,$mdDialog,leadsService,Auth,toaster,p0, $timeout, CurrentCycle) {
    $scope.filterLeadsOnDate = function(key){
        if($scope.filterObj.leadDateKey && $scope.filterObj.leadDateKey==key){
            $scope.filterObj.leadDateKey = "";
            $scope.leadDateKey = null;
        }else
            $scope.filterObj.leadDateKey =key;
        if(!$scope.startDate || !$scope.endDate){
            setDates($scope.selected.cycle);
        }
        $scope.filterObj.actNowFilterKey = null;
        $scope.filterLeads();
    };
	$scope.filterLeadsOnStatus = function(key){
        if($scope.filterObj.leadStatusKey && $scope.filterObj.leadStatusKey==key){
            $scope.filterObj.leadStatusKey = "";
            $scope.leadStatusKey = null;
        }else
            $scope.filterObj.leadStatusKey =key;

        if($scope.filterObj.leadStatusKey == 'new'){
            $scope.filterObj.leadDateKey = 'created';
            $scope.selected.cycle = CurrentCycle.cycle;
            setDates($scope.selected.cycle);
        }
        $scope.filterObj.actNowFilterKey = null;
	    $scope.filterLeads();
    };
	$scope.filterLeadsOnSource = function(key){
        if($scope.filterObj.source && $scope.filterObj.source==key){
            $scope.filterObj.source = "";
            $scope.source = null;
        }else
            $scope.filterObj.source =key;
        $scope.filterLeads();
    };
    $scope.$watch('selected.cycle', function(newVal, oldVal) {
        if(newVal){
           setDates(newVal);
        }
    });
    function setDates(cycle){
        $scope.startDate = new Date(cycle.startDate);
        $scope.endDate = new Date(cycle.endDate);
    }
	$scope.removeFilter = function(filter){
        $scope.filterObj.actNowFilterKey = null;
        switch(filter){
			case 'leadStatus':
                $scope.leadStatusKey = "";
				$scope.filterObj.leadStatusKey = "";
				break;
			case 'leadDate':
                $scope.leadDateKey = null;
				$scope.filterObj.leadDateKey = "";
                $scope.selected.cycle = CurrentCycle.cycle;
                $timeout(function(){
                    $scope.startDate = null;
                    $scope.endDate = null;
                });

                break;
			case 'source':
                $scope.source = "";
				$scope.filterObj.source = "";
				break;
			default:
				break;
		}
		$scope.filterLeads();
	};
    $scope.cycleChange = {
        cycle : function(cycle){
            if($scope.selected.cycle && cycle._id != $scope.selected.cycle._id){
                $scope.selected.cycle = cycle;
                $scope.filterLeads();
            };
            CurrentCycle.setCycle(cycle);
        },
        startDate : function(startDate){
            if(!$scope.endDate)
                $scope.endDate = $scope.selected.cycle.endDate;
            $scope.selected.cycle = {startDate:startDate, endDate:$scope.endDate};
            $scope.filterLeads();
        },
        endDate : function(endDate){
            if(!$scope.startDate)
                $scope.startDate = $scope.selected.cycle.startDate;
            $scope.selected.cycle = {startDate:$scope.startDate, endDate:endDate};
            $scope.filterLeads();
        }
    };
    $scope.showInfoModal = function () {
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate1,
            controller: 'filterInfoModalCtrl',
        });
    };
    var init = function () {
		leadsService.fetchLastSyncDate().then(function (lastSync) {
			if(lastSync.status && lastSync.data.uploaded && lastSync.data.uploaded.lastUpdatedAt){
				$scope.lastSyncDate = new Date(lastSync.data.uploaded.lastUpdatedAt);
			}
		});
    };
    init();
}]);
