const autoImportNgTemplateLoaderTemplate1 = require('../html/analyticsSwot.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('analyticsSwot', function () {
        return {
            name: 'analyticsSwot',
            scope: {
            },
            controller: 'analyticsSwotCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
