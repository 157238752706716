const autoImportNgTemplateLoaderTemplate1 = require('../html/adminLogs.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('adminLogs', function () {
        return {
            name: 'adminLogs',
            scope: {
                logMessage:   '&',
                newlog: '&'
            },
            controller: 'adminLogsCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1

        };
    });
