const autoImportNgTemplateLoaderTemplate1 = require('../html/swotIcon.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('swotIcon', function () {
        return {
            name: 'swotIcon',
            scope: {
                zone: '@',
                isBigger: '@'
            },
            controller: 'swotIconCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
