'use strict';

const goalSummary = require('../html/goalSummary.html');
const goalSummaryDaily = require('../html/goalSummaryDaily.html');

angular.module('bitnudgeApp')
    .directive('goalSummary', function () {
        return {
            name: 'goalSummary',
            scope: {
                mini: '@'
            },
            controller: 'goalSummaryCtrl',
            restrict: 'AE',
            templateUrl: function (elem,attr) {
                if(attr.targetPeriod === 'month') return goalSummary;
                else return goalSummaryDaily;
            }
        };
    });
