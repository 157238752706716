'use strict';
angular.module('bitnudgeApp')
    .controller('swotIconCtrl', function ($scope) {
        if($scope.zone == 'green' || $scope.zone == 's'){
            $scope.class="fa-heart";
        }else if($scope.zone == 'blue' || $scope.zone == 'o'){
            $scope.class="fa-lightbulb-o";
        }else if($scope.zone == 'amber' || $scope.zone == 'w'){
            $scope.class="fa-thumbs-down";
        }else{
            $scope.class="fa-flag";
        }
    });
