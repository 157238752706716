const autoImportNgTemplateLoaderTemplate1 = require('../html/grades.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('grades', function () {
        return {
            name: 'grades',
            scope: {
                cycleId:                '=',
                gradeUser:               '=',
                kpi:                    '=', //overall or kpi._id
                type:                   '=', //kpi, reportee, kai
                selectedDivision:       "=",
                //Gets used in case of Analytics grade
                isQuickView:            '=',       //true -> dashboard quick view grade, false -> analytics grade
                onClick:                '=',
                onParentSwitchChange:     '=', //Analytics grade - notify child when switch changes
                meReporteeSwitch:           '=', //Quickview grade- Reportee frontline switch change from outside
                showTwoByTwo:       '=',
                selectedPlayType: '='
            },
            controller: 'gradesCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
