const autoImportNgTemplateLoaderTemplate1 = require('../html/mySwot.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('mySwot', function () {
        return {
            name: 'mySwot',
            scope: {},
            controller: 'mySwotCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1

        };
    });
