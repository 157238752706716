'use strict';
angular.module('bitnudgeApp')
    .controller('progressBarCtrl', function ($scope, $rootScope, ForecastMultiplier, p0, $filter) {

        $scope.configData = angular.fromJson($scope.config);
        var percentageActivity = $scope.configData.activityType == 'percentage';
        var isActivitySwot = $scope.configData.swotType == 'kai';

        $scope.showValueWise = ($scope.configData.swotType === 'kai' || $scope.configData.swotType === 'activity');

        $scope.config = $rootScope.config;
        $scope.strings = $rootScope.strings;
        if(isActivitySwot){
            $scope.maxScale = percentageActivity ? 100 : $scope.configData.threshold.max.value;
            $scope.configData.percents[1] =  $filter('number')($scope.maxScale, 1);
        }else{
            $scope.maxScale = $scope.configData.percents[1];
        }

        $scope.forecastMultiplier = $rootScope.config.showForecast? ForecastMultiplier.ofCycle(p0.currentMonthlyCycle._id) : 1;
        var activityMultiplier = percentageActivity ? 100 : 1; // this multiplier only for activitys having percentage type, for kpi and reportee swot it should be 1.
        $scope.percentageCompleted = {
            posValue: isActivitySwot ? (100/$scope.maxScale * $scope.configData.percentageCompleted) : (2/3 *($scope.configData.percentageCompleted)),
            value: $filter('number')($scope.configData.percentageCompleted, isActivitySwot ? (percentageActivity ? 0 : 1) : 0)
        };
        $scope.forecast = {
            value: ($scope.forecastMultiplier * $scope.percentageCompleted.value).toFixed(2),
            posValue: $scope.forecastMultiplier * $scope.percentageCompleted.posValue
        };
        if($scope.configData.swotType && ($scope.configData.swotType == 'kai' || $scope.configData.swotType === 'activity')){
            $scope.forecast.value = Math.round($scope.forecast.value);
        }

        var setThresholdsLeftStyle = function () {
            $scope.bottomDecileForecast = $scope.configData.threshold.bottomDecile * $scope.forecastMultiplier * activityMultiplier;
            $scope.bottomDecileForecast = $scope.bottomDecileForecast > $scope.maxScale ? $scope.maxScale : $scope.bottomDecileForecast;
            $scope.bottomDecileLeft = $scope.bottomDecileForecast * 100/ $scope.maxScale;
            $scope.bottomDecileLeft = $scope.bottomDecileLeft > 100 ? 100:$scope.bottomDecileLeft;
            $scope.avgForecast = $scope.configData.threshold.avg * $scope.forecastMultiplier * activityMultiplier;
            $scope.avgForecast = $scope.avgForecast > $scope.maxScale ? $scope.maxScale : $scope.avgForecast;
            $scope.avgLeft = $scope.avgForecast * 100/ $scope.maxScale;
            $scope.avgLeft = $scope.avgLeft > 100 ? 100:$scope.avgLeft;
            $scope.topDecileForecast = $scope.configData.threshold.topDecile * $scope.forecastMultiplier * activityMultiplier;
            $scope.topDecileForecast = $scope.topDecileForecast > $scope.maxScale? $scope.maxScale : $scope.topDecileForecast;
            $scope.topDecileLeft = $scope.topDecileForecast * 100/ $scope.maxScale;
            $scope.topDecileLeft = $scope.topDecileLeft > 100 ? 100:$scope.topDecileLeft;
        };
        setThresholdsLeftStyle();
        if($scope.forecast.value > $scope.maxScale){
            $scope.forecast = {
                value: $scope.maxScale,
                posValue: 100
            };
        }
        $scope.zone = $scope.configData.swot;
        $scope.getMtdStyle = function(){
            if($scope.percentageCompleted.posValue > 90){
                $scope.mtdLabelPos = {'margin-left':'-20px'};
            }
            return {'background-color':$scope.configData.mtdColor,'width': $scope.percentageCompleted.posValue+'%'};
        };

        $scope.getForecastStyle = function(){
            if($scope.forecast.posValue > 90){
                $scope.forecastLabelPos = {'margin-left':'-76px'};
            }
            return {'background-color':$scope.configData.forecastColor,'width': $scope.forecast.posValue+'%'};
        };
        $scope.getPercentStyle = function(value){
            var percent = value;
            if(isActivitySwot){
                percent = 100/$scope.maxScale * percent;
            }else{
                percent = 2/3 * percent;
            }
            return {'left': percent+'%'}
        }
    });
