'use strict';
angular.module('bitnudgeApp')
    .controller('FullCardCtrl', function($timeout, Auth, $scope, $http, social, p1, $rootScope, $location, toaster, $anchorScroll, $state) {
        $scope.cloned = $rootScope.cloned;
        $scope.config = $rootScope.config;
        $scope.me = Auth.getCurrentUser();
        $scope.firstValue = 0;
        $scope.vm = {};
        //this is done to handle the latency in the refresh of table
        $scope.$watch('cardContent.leaderboard',function(newValue){
            $scope.vm.loading = true;
            $scope.vm.leaderboardData = [];
            $timeout(function () {
                $scope.vm.leaderboardData = newValue;
                $scope.vm.loading = false;
            }, 600);
         });
        if($scope.cardContent.leaderboard && $scope.cardContent.leaderboard.length>0){
            $scope.firstValue = $scope.cardContent.leaderboard[0].value;
        }
        $scope.compareIndex = [];
    	$scope.isPlayerLiked=function(player){
                if(player && player.userId && p1.userData[player.userId._id].liked)
                return "light-blue";
            else
                return "white";
        };
        $scope.isPlayerFollowed=function(player){
            if(player&& player.userId && p1.userData[player.userId._id].followed)
                return "light-blue";
            else
                return "white";
        };

        $scope.compareSelectionChanged = function(ind, comparable){
            if(!$scope.showCompare || !comparable) return;
            var selectedUser = $scope.cardContent.leaderboard[ind];
            var found = $scope.compareList.filter(function(user){return user.userId._id == selectedUser.userId._id;} );
            if(found[0]){
                $scope.cardContent.leaderboard[ind].compareSelection = false;
                _.remove($scope.compareList, function(user){
                    return user.userId._id == selectedUser.userId._id;
                });
            }else{
                if($scope.me._id == selectedUser.userId._id){
                    $scope.cardContent.leaderboard[ind].compareSelection = false;
                    return toaster.pop('error', "Select", "You cannot Select Yourself for Comparison");
                }else{
                    if($scope.isOwnLeaderBoard){
                        if($scope.compareList.length <3)
                            $scope.compareList.push(selectedUser);
                        else{
                            $scope.cardContent.leaderboard[ind].compareSelection = false;
                            return toaster.pop('error', 'Comparison Limit', "You cannot select more than 3 players for Comparison");
                        }
                    }
                    else{
                        if($scope.compareList.length < 4)
                            $scope.compareList.push(selectedUser);
                        else{
                            $scope.cardContent.leaderboard[ind].compareSelection = false;
                            return toaster.pop('error', 'Comparison Limit', "You cannot select more than 4 players for Comparison");
                        }
                    }
                }
            }
        };

        $scope.setProgressWidth = function(progress){
            $scope.firstValue = $scope.cardContent.leaderboard[0].value;
            var per = 0;
            if($scope.firstValue) per = parseInt((progress * 79) / $scope.firstValue);
            return {width : per+'%'};
        };

        $scope.comparePlayers=function(name, type, kpiId, activityId, league){
            if($scope.isOwnLeaderBoard && $scope.compareList.length < 1)
                    return toaster.pop('error', 'Comparison Selection', "Please select at least 1 player for Comparison");
            else if(!$scope.isOwnLeaderBoard && $scope.compareList.length < 2)
                    return toaster.pop('error', 'Comparison Selection', "Please select at least 2 players for Comparison");


            var selectedPlayerIds=[];
            $scope.compareList.forEach(function(user){
                selectedPlayerIds.push(user.userId._id);
            });
            $rootScope.playersFromLB = selectedPlayerIds;
            $rootScope.comparePlayersFromLB = true;
            $rootScope.jobRoleFromLB = $scope.jobRole;
            $rootScope.nameOfLb = name;
            $rootScope.typeOfLb = type;
            $rootScope.kpiIdOfLb= kpiId;
            $rootScope.activityIdOfLb= activityId;
            if(league == 'peer')
                $rootScope.leagueOfLb = 'bank';
            else
                $rootScope.leagueOfLb = league;
            var roleType ='';   // manager, frontline
            if($scope.managerEnabled)
                roleType = 'manager';
            else
                roleType = 'frontline';
                $state.go('userFlow.analytics', {tabName:'playerComparison', myComparison: $scope.isOwnLeaderBoard, roleType: roleType,cycleId:($scope.selectedCycle ? $scope.selectedCycle._id : "")});
        };

        $scope.jumpToMeAll = function(id,rank){
            $location.hash(id + rank);
            $anchorScroll();
            /*if ($location.hash() != newHash) {
            // set the $location.hash to `newHash` and
            // $anchorScroll will automatically scroll to it
                $location.hash(id + rank);
            } else {
            // call $anchorScroll() explicitly,
            // since $location.hash hasn't changed
            $anchorScroll();
            }*/
        };

        $scope.setLBHeight = function(){
            var eleHeight = $(".leaderboard-header").height() -80;
            return {height : eleHeight,"overflow-y":"scroll"};
        };

        $scope.setmoversShakersHeight = function(){
            var eleHeight = $(".bg-white").height() -75;
            return {height : eleHeight,"overflow-y":"scroll","overflow-x":"hidden"};
        };
		$scope.socialAction = function(action, player){
            social.socialAction(action, player, $scope.selectedCycle?$scope.selectedCycle._id:'');
		};
    });
