"use strict";
angular.module("bitnudgeApp").controller("documentModalCtrl", function($scope, $http, Auth, $mdDialog, parameters) {
	$scope.isSelectMode = parameters.isSelectMode ? true : false;
	$scope.isApproveMode = parameters.isApproveMode ? true : false;
	$scope.isCustomerMode = parameters.isCustomerMode ? true : false;
	$scope.workflowId = parameters.workflowId;
	$scope.cui = parameters.customer.cui;

	if ($scope.isApproveMode || $scope.isCustomerMode) {
		if (parameters.documents && !Array.isArray(parameters.documents)) {
			$scope.documentIds = [parameters.documents];
		} else {
			$scope.documentIds = parameters.documents;
		}
	}
	if ($scope.isSelectMode) {
		if (parameters.documentTypes && !Array.isArray(parameters.documentTypes)) {
			$scope.documentTypes = [parameters.documentTypes];
		} else {
			$scope.documentTypes = parameters.documentTypes;
		}
	}
});
