'use strict'

angular.module('bitnudgeApp').service('BNCache', function($rootScope, $q) {

    var lastSyncTimestamp       = localStorage.getItem('LAST_SYNC_TIME') || null;
    var lastSyncCallTimestamp   = null;
    // 15 minutes in seconds duration
    var duration                = 900;

    var _updateLastSyncTime = function() {
        var defer = $q.defer()
        var now = new Date().getTime() / 1000
        if (lastSyncTimestamp != null && lastSyncCallTimestamp != null) {

            if(now - lastSyncCallTimestamp < duration) {
                defer.resolve(lastSyncTimestamp)
                return defer.promise;
            }

            if (now - lastSyncCallTimestamp >= duration) {
                _getLastSyncTime().then(function(timestamp) {
                    if(timestamp && timestamp !== null) {
                        lastSyncTimestamp = new Date(JSON.parse(timestamp))
                        lastSyncCallTimestamp = now
                        defer.resolve(lastSyncTimestamp)
                        return defer.promise;
                    }
                })
            }
        }

        if (lastSyncTimestamp == null) {
            _getLastSyncTime().then(function(timestamp) {
                if(timestamp && timestamp !== null) {
                    lastSyncTimestamp = new Date(JSON.parse(timestamp))
                    lastSyncCallTimestamp = now
                    defer.resolve(lastSyncTimestamp)
                    return defer.promise;
                }
            })
        }

        return defer.promise;
    }

    var _getLastSyncTime = function() {
        return new Promise(function(resolve, reject){
            var xhr = new XMLHttpRequest()
            var url = $rootScope.remoteUrl + '/api/lmsTrackers/lastSyncDate'
            xhr.open('POST', url, true)
            xhr.onload = function() {
                var result = xhr.response
                resolve(result)  
            }
            xhr.onerror = function() {
                resolve()
            }
            xhr.send()
        })
    }

    this.updateLastSyncTime = _updateLastSyncTime
    this.lastSyncCallTimestamp = lastSyncCallTimestamp
    this.lastSyncTimestamp = lastSyncTimestamp

    return this;
})