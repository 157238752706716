const autoImportNgTemplateLoaderTemplate1 = require('../html/rhsAnalyticsL2Incentive.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('rhsAnalyticsL2Incentive', function(){
        return {
            name: 'rhsAnalyticsL2Incentive',
            scope: {
            },
            controller: 'rhsAnalyticsL2IncentiveCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
