'use strict';

angular.module('bitnudgeApp')
    .controller('badgeCornerCtrl', function ($scope, $timeout, $http, Auth, $rootScope,  moment, $filter, $q, $stateParams, DTOptionsBuilder, DTColumnDefBuilder, p0, badgeCorner) {
    	const self = this;

        var init = (function(){
            this.months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

            $scope.strings = $rootScope.strings;
            $scope.fetched = {};
            $scope.me = Auth.getCurrentUser();
        }).bind(this);

        $scope.$watchGroup(['selectedPlayer', 'selectedKpi', 'selectedJobrole','selectedCycle','isWeekly'],(function (newValue, oldValue) {
            if(newValue[0] &&  newValue[1] && newValue[2] ){
                if($scope.selectedPlayer.name == "All Players" && !$scope.selectedJobrole) return;
                if(!$scope.isWeekly){
                    self.initMonth();
                }else{
                    self.initWeek();
                }
            }
        }).bind(this));

        this.initMonth = (function(){
            if($scope.selectedPlayer._id == $scope.me._id) {
                this.initCycle();
            }
            this.setKpis();
            $scope.getBadges();
        }).bind(this);

        this.initWeek = (function(){
            $scope.weeklyBadgesFetched = false;
            badgeCorner.getWeekBadges($scope.selectedCycle,$scope.selectedKpi,$scope.selectedPlayer,$scope.selectedJobrole).then(function(weekBadges){
                $scope.weekBadges = weekBadges;
                $scope.weeklyBadgesFetched = true;
            });
        }).bind(this);

        this.initCycle = (function(){
        	if(!$scope.selectedCycle) return;

        	console.log('End date', $scope.selectedCycle.endDate);
            let month = (new Date($scope.selectedCycle.endDate)).getMonth();
            $scope.monthName = this.months[month];
			let daysInMonth = (new Date($scope.selectedCycle.endDate)).getDate();
            $scope.days = new Array(daysInMonth);

            badgeCorner.getStarBadges($scope.selectedCycle).then(function(userAnalytics){
                userAnalytics.forEach(function (userAnalytic) {
                    let date = new Date(userAnalytic.cycle.endDate);
                    if(userAnalytic.userId == $scope.me._id) {
                        $scope.days[date.getDate() - 1] = true;
                    }else{
                        $scope.days[date.getDate() - 1] = userAnalytic;
                    }
                })
            });
        }).bind(this);

        this.setKpis = function () {
            $scope.fetched.badges = false;
            if ($scope.selectedKpi._id === 'overall') {
                $scope.kpiDropdown = [];
            }
            else {
                $scope.kpiDropdown = _.filter($scope.userKpis, function (e, i,l) {
                    return e._id == $scope.selectedKpi._id;
                });
            }
        };
        // $scope.allCycles = p0.allMonthlyCycles;
        // $scope.selectedCycle = p0.currentMonthlyCycle;

        this.getBadgesUrl = function(isRolling) {
            if(!isRolling){
                var isAllReporteeBadges = ($scope.selectedPlayer.name == "All Players");
                if(isAllReporteeBadges && $scope.selectedPlayer!=null) {
                    let postBody = {
                        role: $scope.selectedJobrole,
                        cycleId: $scope.selectedCycle._id
                    }
                    return {url: "/api/analytics/reportingTreeBadges", data: postBody};
                }else {
                    let postBody = {
                        userId: $scope.selectedPlayer._id,
                        cycleId: $scope.selectedCycle._id
                    }
                    return {url: "/api/analytics/getUserBadges", data: postBody };
                }
            }
            else{
                var isAllReporteeBadges = ($scope.selectedPlayer.name == "All Players");
                if(isAllReporteeBadges && $scope.selectedPlayer) {
                    let postBody = {
                        role: $scope.selectedJobrole,
                        cycleId: $scope.selectedCycle._id
                    }
                    return {url: "/api/rollingBadges/jobrole", data: postBody};
                }
                else {
                    let postBody = {
                        userId: $scope.selectedPlayer._id,
                        cycleId: $scope.selectedCycle._id
                    }
                    return {url: "/api/rollingBadges/users", data: postBody};
                }
            }
        };

        this.checkEarnedBadge = function(totalBadges, badgeEarned){
            _.forEach(totalBadges, function(badge,index){
                _.forEach(badgeEarned,function(earnedBadge){
                    if(earnedBadge.badgeType == badge.badgeType && earnedBadge.badgeCategory == badge.badgeCategory)
                        if(earnedBadge.from){
                            if(earnedBadge.from == badge.from){
                                badge.earnedBadge = earnedBadge;
                            }
                        }else {
                            badge.earnedBadge = earnedBadge;
                        }
                });
            });
            if($scope.numberOfShelf == 1){
                _.remove(totalBadges,function(badge){
                    if(badge.earnedBadge){
                        return false;
                    }else{
                        return true;
                    }
                });
            }
            return totalBadges;
        };

        $scope.getBadges = function(){
            if(!$scope.selectedPlayer || !$scope.selectedCycle || !$scope.selectedKpi)  return;
            $http.post("/api/badges/topQuartileBadgeImages").then((badges) => {
                badges=badges.data;
                var allBadges = badges;
                const urlDetails = self.getBadgesUrl(false);
                $http.post(urlDetails.url, urlDetails.data).then((badgesEarned) => {
                    badgesEarned=badgesEarned.data;
                    const urlDetails2 = self.getBadgesUrl(true);
                    $http.post(urlDetails2.url, urlDetails2.data).then((rollingBadgesEarned) => {
                        rollingBadgesEarned=rollingBadgesEarned.data;
                        if(rollingBadgesEarned.length > 0) badgesEarned = _.concat(badgesEarned,rollingBadgesEarned);
                        $scope.fetched.badges = false;
                        if($scope.selectedPlayer.name === "All Players"){
                            _.forEach(badgesEarned, function(badge){
                                _.forEach(badge.users, function(user){
                                    user.badgeName = badge.name;
                                    if(!user.badge){
                                        user.badge = _.cloneDeep(badge);
                                        delete user.badge.users;
                                    }
                                })
                            });
                        }
                        var excellenceBadgesEarned = _.filter (badgesEarned, function (badge){
                            return (badge.badgeCategory == 'overall') && (badge.badgeType == 'total' || badge.badgeType == 'overall' || badge.badgeType == 'overallBusiness' || badge.badgeType == 'overallActivity')
                        });
                        var ytdBadgesEarned = _.filter (badgesEarned, function (badge) {
                            return badge.badgeCategory == 'ytdBest';
                        });
                        var kpiBadgesEarned = _.filter(badgesEarned, function(badge) {
                            return (badge.badgeCategory == 'business' || badge.badgeCategory == 'productivity' || badge.badgeCategory == 'conversion' || (badge.badgeCategory == 'redFlag' && badge.from) || (badge.badgeCategory == 'decile' && badge.badgeType == 'kpi') || (badge.badgeCategory == 'activity' && badge.badgeType == 'kpi'))
                        });
                        var statusBadgeEarned = _.filter(badgesEarned,{badgeCategory:'level'});
                        var levelBadgeEarned = _.filter(badgesEarned,{badgeType:'level'});
                        var overallActivityBadgesEarned = _.filter(badgesEarned,{badgeType:'activity', badgeCategory:'activity'});
                        var overallWarningBadgesEarned = _.filter(badgesEarned,function(badge){ return badge.badgeType === 'activity' && badge.badgeCategory === 'redFlag' && !badge.from});
                        $scope.setBadges(allBadges, excellenceBadgesEarned,statusBadgeEarned,levelBadgeEarned, ytdBadgesEarned, kpiBadgesEarned,overallActivityBadgesEarned, overallWarningBadgesEarned);
                        _.forEach($scope.kpis, function(goal){
                            goal.dataLoaded  = true;
                        });
                        $timeout(function () {
                            $scope.fetched.badges = true;
                        }, 0);
                    });
                });
            });
        };

        $scope.setBadges = function(allBadges, excellenceBadgesEarned,statusBadgeEarned,levelBadgeEarned, ytdBadgesEarned, kpiBadgesEarned, overallActivityBadgesEarned, overallWarningBadgesEarned){
            var excellenceBadges = _.filter (allBadges, function (badge){
                return (badge.badgeCategory == 'overall') && (badge.badgeType == 'total' || badge.badgeType == 'overall' || badge.badgeType == 'overallBusiness' || badge.badgeType == 'overallActivity')
            });
            var ytdBadges = _.filter (allBadges, function (badge) {
                return badge.badgeCategory == 'ytdBest';
            });
            var kpiBadges = _.filter(allBadges, function(badge){return badge.badgeCategory == 'business' || badge.badgeCategory == 'productivity' || badge.badgeCategory == 'conversion' || (badge.badgeCategory == 'redFlag' && badge.from)|| (badge.badgeCategory == 'decile' && badge.badgeType == 'kpi') || (badge.badgeCategory == 'activity' && badge.badgeType == 'kpi')});

            var statusBadges = _.filter(allBadges,{badgeCategory:'level'});
            var levelBadges = _.filter(allBadges,{badgeType:'level'});
            var overallActivityBadges = _.filter(allBadges,{badgeType:'activity',badgeCategory:'activity'});
            var overallWarningBadges = _.filter(allBadges,function(badge){ return badge.badgeType === 'activity' && badge.badgeCategory === 'redFlag' && !badge.from});
            _.forEach(kpiBadges, function(totalKPIBadges){
                _.forEach(kpiBadgesEarned, function(kpiBadgeEarned){
                    if(totalKPIBadges.from.toString() == kpiBadgeEarned.from.toString() && totalKPIBadges.badgeType == kpiBadgeEarned.badgeType && totalKPIBadges.badgeCategory == kpiBadgeEarned.badgeCategory) {
                        if (totalKPIBadges.activityId || kpiBadgeEarned.activityId) {
                            if (totalKPIBadges.activityId && kpiBadgeEarned.activityId && totalKPIBadges.activityId.toString() == kpiBadgeEarned.activityId.toString()) {
                                totalKPIBadges.earnedBadge = kpiBadgeEarned;
                            }
                        } else {
                            totalKPIBadges.earnedBadge = kpiBadgeEarned;
                        }
                    }
                });
            });
            if($scope.numberOfShelf === 1){
                _.remove(kpiBadges,function(badge){
                    if(badge.earnedBadge){
                        return false;
                    }else{
                        return true;
                    }
                });
            }
            $scope.excellenceBadges = self.checkEarnedBadge(excellenceBadges, excellenceBadgesEarned);
            $scope.excellenceBadges = _.sortBy($scope.excellenceBadges, 'badgeOrder');
            $scope.ytdBest = self.checkEarnedBadge(ytdBadges, ytdBadgesEarned);
            $scope.ytdBest = _.sortBy($scope.ytdBest, 'badgeOrder');
            $scope.statusBadge=  self.checkEarnedBadge(statusBadges, statusBadgeEarned)[0];
            $scope.levelBadge =  self.checkEarnedBadge(levelBadges, levelBadgeEarned)[0];
            $scope.overallActivityBadges = self.checkEarnedBadge(overallActivityBadges,overallActivityBadgesEarned);
            $scope.overallActivityBadges =  _.sortBy($scope.overallActivityBadges, 'badgeOrder');
            $scope.overallWarningBadges = self.checkEarnedBadge(overallWarningBadges,overallWarningBadgesEarned);
            $scope.overallWarningBadges =  _.sortBy($scope.overallWarningBadges, 'badgeOrder');
            $scope.kpis = [];

            _.forEach($scope.kpiDropdown, function(goal){
                var badges = _.filter(kpiBadges, function(kpiBadge){
                    return kpiBadge.from.toString() === goal._id.toString()
                });
                badges = _.sortBy(badges,'badgeOrder');
                $scope.kpis.push({name:goal.name, _id: goal._id, badges: badges, dataLoaded:false});
            });

            if($scope.displayLockedBadges === false){
                $scope.excellenceBadges = $scope.excellenceBadges.filter(function(badge){
                    return badge.earnedBadge !== undefined;
                });

                $scope.ytdBest = $scope.ytdBest.filter(function(badge){
                   return badge.earnedBadge !== undefined;
                });

                for(var i=0; i< $scope.kpis.length; i++){
                    $scope.kpis[i].badges = $scope.kpis[i].badges.filter(function(badge){
                        return badge.earnedBadge !== undefined;
                    });
                }
            }
            $scope.kpis.forEach(function(kpi){
                var redFlags = _.remove(kpi.badges,{badgeCategory:'redFlag'});
                kpi.warningBadges = redFlags;
            });
        };

        init();
    });
