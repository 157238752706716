'use strict';
angular.module('bitnudgeApp')
    .directive('cneCard', function(){
        return {
            name: 'cneCard',
            scope: {
                heading: '@',
                section1: '=',
                section2: '=',
                section3: '=',
                data: '@',
                cycle: '@',
                badges: '=',
                hoverData: '=',
                itemType: '@',
                type:'@',
                kpi:'@',
                metric:'='
            },
            controller: 'cneCardCtrl',
            restrict: 'AE',
            templateUrl: function(elem,attr) {
                if(attr.itemType == 'leads'){
                    return '../html/cneCard/cneCard-leads.html';
                }
                else if(attr.itemType == 'activityIndicators'){
                    return '../html/cneCard/cneCard-activityIndicators.html';
                }
                else{
                    return '../html/cneCard/cneCard.html';
                }
            }
        };
    });
