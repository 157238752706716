'use strict';
angular.module('bitnudgeApp')
    .service('heatmapMirrorChart', function ($http,p0, $rootScope, $q) {
        var _this = this;
        var heatmapActivities = ['call', 'meeting', 'volumes', 'availability', 'accuracyp'];
        var importantActivities = [];
        var config = $rootScope.config;
        p0.allActivities.forEach(function (activity) {
            if(heatmapActivities.indexOf(activity.fieldName) != -1){
                importantActivities.push(activity.fieldName);
            }
        });
        var isPlayer;
        var allBankData, allRegionData,reporteeBankData;
        var selectedUser;
        var cycleType;
        this.getMirrorChartData = async function (userId,kpiId,activityId,type,target,isWeekly, cycleId) {
            try {
                let user = await $http.post('/api/users/getUserById',{id:userId});
                user= user && user.data && user.data.data;
                selectedUser = user;
                if(user.systemRole == 'frontline' && !$rootScope.isFrontline && config.showFLAchievedInHMap && kpiId != 'overall')
                    _this.showAchieved = true;
                else
                    _this.showAchieved = false;
                const regionLeague = _.find(user.leagues, {teamType: 'region'});
                const bankLeague = _.find(user.leagues,{teamType:'bank'});
                const teamLeague = _.find(user.leagues, {teamType: 'team'});
                if(!bankLeague){
                    return $q.resolve([false]);
                }
                isPlayer = user.isPlayer;
                const parentId = user.parent;
                const leagueIds = [bankLeague.leagueId];
                let leagues=[];
                leagues.push(bankLeague);
                if(regionLeague){
                    leagueIds.push(regionLeague.leagueId);
                    leagues.push(regionLeague);
                }
                if(teamLeague){
                    leagues.push(teamLeague);
                }
                let parent = await $http.post('/api/users/getUserById', {userId: parentId});
                parent=parent.data.data;
                const children = _.map(parent.children,'childId');
                /*if(type == 'scorecard' && !isWeekly){
                    cycleType = 'monthly';
                }else if(type == 'scorecard' && isWeekly){
                    cycleType = 'weekly'
                }else{
                    cycleType = 'monthly'
                }*/
                const postData={
                    leagues: leagueIds,
                    cycleId
                }
                let result = await $http.post('/api/leagueAnalytics/getLeagues', postData);
                result=result.data;
                let leagueAnalytic = result.leagueAnalytics;
                let finalBankData = getConsolidatedData(leagueAnalytic[0], kpiId,activityId, type,target);
                let finalRegionData = null
                if(leagueAnalytic[1]) {
                    finalRegionData = getConsolidatedData(leagueAnalytic[1], kpiId,activityId, type,target);
                }
                let users = await $http.post('/api/users/getUserImagesOfLeague',{leagueId: bankLeague.leagueId});
                users=users.data;
                finalBankData = _.map(finalBankData, function (user) {
                    const currentUser = _.find(users, {_id: user.userId});
                    user.image = currentUser.image;
                    user.isPlayer = currentUser.isPlayer;
                    user.linkName = '#'+ user.rank+' '+currentUser.profile.name;
                    return user;
                });
                if(finalRegionData){
                    finalRegionData = _.map(finalRegionData, function (user) {
                        const image = _.find(users, {_id: user.userId}).image;
                        const isPlayer =_.find(users, {_id: user.userId}).isPlayer;
                        user.image = image;
                        user.isPlayer = isPlayer;
                        user.linkName = '#'+ user.rank+' '+user.userName;
                        return user;
                    });
                }
    
                return sendData();
                function sendData() {
                    allBankData = finalBankData;
                    if(finalRegionData)
                        allRegionData = finalRegionData;
                    reporteeBankData = _.filter(finalBankData,function(userData){
                        return _.includes(children,userData.userId);
                    });
                    return $q.resolve([finalBankData, leagues]);
                }
            } catch(err) {
                console.error('error: ', err.message);
                return $q.resolve([false]);
            }
        };
        function  getConsolidatedData(leagueAnalytic, kpiId,activityId, type,target) {
            var data ;
            var tempData;
            var activities = {};
            var activityCR = {};
            var percentageCompleted, pipeline,conversionRatio;
            if(type != 'activities') {
                if (kpiId == 'overall') {
                    if(target == 'Target') {
                        data = leagueAnalytic.percentageCompleted.leaders;
                    }else{
                        data = leagueAnalytic.gameplanPercent.leaders;
                    }
                    importantActivities.forEach(function(activity){
                        activities[activity] = _.find(leagueAnalytic.activityData, function (actData) {
                            return actData.fieldName == activity && !actData.kpiId
                        });
                        activities[activity] = activities[activity].value.leaders;
                    });
                    pipeline = leagueAnalytic.pipelinePercentage && leagueAnalytic.pipelinePercentage.leaders;
                }
                else{
                    var selectedKpiData = _.find(leagueAnalytic.kpiData, function (kpi) {
                        return kpi.kpiId.toString() == kpiId.toString()
                    });
                    if(target == 'Target') {
                        // Show achievedValue leads if isReportee is true;
                        if(_this.showAchieved) {
                            data = selectedKpiData.achievedValue.leaders;
                            tempData = leagueAnalytic.percentageCompleted.leaders;
                        }
                        else
                            data = selectedKpiData.percentageCompleted.leaders;
                    }else{
                        data = selectedKpiData.gameplanPercent.leaders;
                    }
                    pipeline = selectedKpiData.pipelinePercentage && selectedKpiData.pipelinePercentage.leaders;
                    var conversionRatioActivities = Object.keys(selectedKpiData.activityConversionRatios);
                    importantActivities.forEach(function(activity){
                        activities[activity] = _.find(leagueAnalytic.activityData, function (actData) {
                            return actData.fieldName == activity && actData.kpiId && actData.kpiId.toString() == kpiId.toString()
                        });
                        if(activities[activity]){
                            activities[activity] = activities[activity].value.leaders;
                            if(conversionRatioActivities.indexOf(activity) != -1){
                                activityCR[activity] = selectedKpiData.activityConversionRatios[activity].leaders;
                            }
                        }
                    });
                }
            }
            else{
                if (kpiId == 'overall' || !kpiId) {
                    data = _.find(leagueAnalytic.activityData, function (activity) {
                        return activity.activityId.toString() == activityId.toString() && activity.kpiId == undefined
                    }).value.leaders;
                    percentageCompleted = leagueAnalytic.percentageCompleted.leaders;
                    pipeline = leagueAnalytic.pipelinePercentage && leagueAnalytic.pipelinePercentage.leaders;
                }else{
                    data = _.find(leagueAnalytic.activityData, function (activity) {
                        return activity.activityId.toString() == activityId.toString() && activity.kpiId && activity.kpiId.toString() == kpiId.toString()
                    }).value.leaders;
                    var selectedKpiData = _.find(leagueAnalytic.kpiData, function (kpi) {
                        return kpi.kpiId.toString() == kpiId.toString()
                    });
                    percentageCompleted = selectedKpiData.percentageCompleted.leaders;
                    pipeline = selectedKpiData.pipelinePercentage && selectedKpiData.pipelinePercentage.leaders;
                    var conversionRatioActivities = Object.keys(selectedKpiData.activityConversionRatios);
                    var selectedActivity = _.find(p0.allActivities,{_id:activityId});
                    if(selectedActivity.fieldName!='newLead' && conversionRatioActivities.indexOf(selectedActivity.fieldName) > -1)
                        conversionRatio = selectedKpiData.activityConversionRatios[selectedActivity.fieldName].leaders;
                }
            }
            if(activityId){
                _.remove(data,function(user){
                    var userPercentageCompleted = _.find(percentageCompleted,{userId:user.userId});
                    return !userPercentageCompleted;
                });
            }
            data.forEach(function(user){
                if(!activityId) {
                    if(_this.showAchieved && kpiId != 'overall'){
                        user.achievedValue = user.value;
                        var percentage = _.find(tempData, {userId:user.userId}).value;
                        user.target = user.achievedValue/percentage * 100;
                    }else
                        user.percentageCompleted = user.value;
                    var userPipeline = _.find(pipeline,{userId: user.userId});
                    user.pipeline = userPipeline ? userPipeline.value : 0;
                    importantActivities.forEach(function(activity){
                        user[activity] = _.find(activities[activity],{userId:user.userId});
                        if(kpiId != 'overall' && activityCR) {
                            user[activity].conversionRatio = _.find(activityCR[activity],{userId:user.userId});
                            if(user[activity].conversionRatio)
                                user[activity].conversionRatio = user[activity].conversionRatio.value;
                        }
                    });

                } else {
                    var activity = _.find(p0.allActivities, {_id: activityId});
                    var multiplier = 1;
                    if(activity.activityType == 'percentage'){multiplier = 100;}
                    user.activity = user.value;
                    user.activity = user.activity * multiplier;
                    var userPercentageCompleted = _.find(percentageCompleted,{userId:user.userId});
                    var userPipeline = _.find(pipeline,{userId: user.userId});
                    user.percentageCompleted = userPercentageCompleted.value;
                    if(userPipeline){
                        user.pipeline = userPipeline.value;
                        if(kpiId != 'overall') {
                            var userConversionRatio = _.find(conversionRatio, {userId: user.userId});
                            if(userConversionRatio)
                                user.conversionRatio = userConversionRatio.value;
                        }
                    }
                }
            });
            return data;
        }
        this.getIsPlayer = function(){
            return isPlayer;
        };
        this.getReporteeData = function(){
            return reporteeBankData;
        };
        this.getAllBankData = function(){
            return allBankData;
        };
        this.getAllRegionData = function () {
            return allRegionData;
        }
        this.getJobRole = function(){
            return selectedUser.organizationalTag.jobRole;
        };
    });
