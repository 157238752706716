'use strict';
angular.module('bitnudgeApp')
    .controller('LbCarouselCtrl', function($timeout, $scope, $http,  toaster, Auth, $rootScope, $location, social,p1, $state) {
        $scope.currentUser=Auth.getCurrentUser();
        $scope.strings = $rootScope.strings;

        $scope.playerRowColor = function(player) {
            if(!player||!player.userId)
                return "";
            if($scope.currentUser.children != undefined && $scope.currentUser){
                for(var i=0; i<$scope.currentUser.children.length; i++){
                    if(player.userId._id==$scope.currentUser.children[i].childId)
                        return "row lb-row lb-custom-orange"
                }
            }
            if($scope.currentUser.parent!= undefined){
                if(player.userId._id==$scope.currentUser.parent){
                    return "row lb-row lb-custom-orange"
                }
            }
            if(player.userId._id==$scope.currentUser._id)
                return "row lb-row lb-custom-orange";
            return "row lb-row lb-custom-black";
        };
        $scope.isUserInTop5=function(lb){
            if(lb&&!lb[5])
                return true;
            else if(lb&&lb[5].userId._id==$scope.currentUser._id ||lb&&lb[5].userId._id==$scope.currentUser.parent)
                return false;
            else
                return true;
        };

        $scope.getHeaderClass = function(index) {
            switch(index % 4){
                case 0: return "lb-custom-blue-2";
                case 1: return "custom-purple";
                case 2: return "lb-custom-blue-6";
                case 3: return "custom-pink";
            }
        };

        $scope.isPlayerLiked=function(player){
                if(player && player.userId && p1.userData[player.userId._id].liked)
                return "light-blue";
            else
                return "white";
        };
        $scope.isPlayerFollowed=function(player){
            if(player&& player.userId && p1.userData[player.userId._id].followed)
                return "light-blue";
            else
                return "white";
        };

        $scope.socialAction = social.socialAction;

        $scope.selectedPlayers={};

        $scope.comparePlayers=function(name, type, kpiId, activityId){
            var numOpponents = Object.keys($scope.selectedPlayers).length;

            if($scope.isOwnLeaderBoard=="true") {
                if(numOpponents < 1)
                    return toaster.pop('error', 'Comparison Selection', "Please select at least 1 player for Comparison");
                else if(numOpponents > 3)
                    return toaster.pop('error', 'Comparison Limit', "You cannot select more than 3 players for Comparison");
            }else {
                if(numOpponents < 2)
                    return toaster.pop('error', 'Comparison Selection', "Please select at least 2 players for Comparison");
                else if(numOpponents > 4)
                    return toaster.pop('error', 'Comparison Limit', "You cannot select more than 4 players for Comparison");
            }


            var selectedPlayerIds=[];
            _.forEach(Object.keys($scope.selectedPlayers), function(selectedPlayer) {
                selectedPlayerIds.push(selectedPlayer);
            });
            $rootScope.myComparison = $scope.isOwnLeaderBoard;
            $rootScope.playersFromLB = selectedPlayerIds;
            $rootScope.comparePlayersFromLB = true;
            $rootScope.jobRoleFromLB = $scope.jobRole;
            $rootScope.nameOfLb = name;
            $rootScope.typeOfLb = type;
            $rootScope.kpiIdOfLb= kpiId;
            $rootScope.activityIdOfLb= activityId;
            $state.go('userFlow.analytics', {tabName: 'playerComparison', myComparison: $rootScope.myComparison});
        };

        $scope.LBCheckAction=function(player){
            var userId = player.userId._id;
            if(userId == $scope.currentUser._id) {
                toaster.pop('error', "Select", "You cannot Select Yourself");
                delete $scope.selectedPlayers[userId];
                return;
            }
            if($scope.selectedPlayers[userId]){
                $scope.selectedPlayers[userId] = true;
            }else {
                delete $scope.selectedPlayers[userId];
            }
        };

        //options could be used to pass additional options to this function
        //this function will call the stuff that is passed when the directive is called
        //through the on-complete-click option in html
        $scope.completeListClick=function(type, kpiId, activityId, options){
            var onCompleteList = $scope.onCompleteClick;

            if(onCompleteList && typeof onCompleteList == 'function'){
                onCompleteList(type, kpiId, activityId, options);
            }

        };

        $scope.$watch('hideCompare', function(newValue, old){
            if(newValue){
                $scope.hideCompare = newValue;
            }
        });

        $scope.slickConfig = {
            method: {},
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            arrows:true,
            draggable:true
        };
    });
