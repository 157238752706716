const autoImportNgTemplateLoaderTemplate1 = require('../html/welcomeBoard/broadcastMessageModal.html');

'use strict';

angular.module('bitnudgeApp')
    .controller('welcomeBoardCtrl', function ($scope,p0,$mdDialog,$http, Auth, $rootScope) {
        $scope.me = Auth.getCurrentUser();
        $scope.cloned = $rootScope.cloned;
        $scope.slickBannerConfig= {
            enabled: true,
            autoplay: true,
            draggable: false,
            autoplaySpeed: 3000,
            infinite:true
        };
        $scope.loaded = false;
        $scope.messages = [p0.quote];

		$scope.init = (function() {
            $scope.loaded = false;
            return $http.post('/api/welcomeMessages/index').then(function(welcomeMessages){
                welcomeMessages = welcomeMessages.data;
                $scope.messages = welcomeMessages;
                $scope.loaded = true;
            });
        });

        $scope.broadcastMessage = function(){
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'broadcastMessageModalCtrl',
                size: 'lg',
                resolve:{
                    initWelcomeBoard: function() { return $scope.init }
                }
            });
        };

		$scope.init();
    });
