'use strict';
angular.module('bitnudgeApp')
    .service('benchmark', function (Auth, leagueAnalytic, $q) {
        this.activity = function(benchmark,teamType,activityFieldName,kpiId,cycleId){
            var me = Auth.getCurrentUser();
            var league = _.find(me.leagues,{teamType:teamType});
            if(!league){
                return $q.resolve(0)
            }
            return leagueAnalytic.fetchLeagueAnalytic(league.leagueId,cycleId).then(function(leagueAnalytic){
                var activityData = _.find(leagueAnalytic.activityData,{fieldName:activityFieldName,kpiId:kpiId});
                return $q.resolve(activityData.value[benchmark]);
            });
        };

        this.kpi = function (benchmark, teamType, kpiId, cycleId, callback) {
            var me = Auth.getCurrentUser();
            var league = _.find(me.leagues,{teamType:teamType});
            if(!league){
                return $q.resolve(0)
            }
            return leagueAnalytic.fetchLeagueAnalytic(league.leagueId,cycleId).then(function(leagueAnalytic){
                var kpiData = _.find(leagueAnalytic.kpiData,{kpiId:kpiId});
                return $q.resolve(kpiData.percentageCompleted[benchmark]);
            });
        }
    });
