'use strict';
angular.module('bitnudgeApp')
    .controller('otherAnalyticsCtrl', function ($scope, p0, Auth, $rootScope, Reportees) {
        $scope.slickConfig= {
            enabled: true,
            infinite: true,
            draggable: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            variableWidth: true,
        };
        $scope.isSlickDataLoaded = true;
        $scope.badges = [{}, {}, {}, {}, {}];

        //Variables
        $scope.config = p0.config;
        $scope.strings = $rootScope.strings;
        $scope.isFrontline = false;
        $scope.selected = {reporteeList: []};
        $scope.isMiddleManager = $rootScope.isMiddleManager;
        $scope.isFrontline = $rootScope.isFrontline;
        $scope.isSeniorManager = $rootScope.isSeniorManager;
        $scope.me = Auth.getCurrentUser();
        Reportees.fetchReportingTree($scope.me._id,p0.myPlayTypes).then(function(reportees){
            $scope.selected.reporteeList = _.filter(reportees,function (user) {
                return user.roleType == 'frontline' || user.roleType == 'manager';
            })
        });
        //On change
        $scope.onReporteeChange = function (reportee) {

        };
        $scope.onCycleChange= function (cycle) {

        };
        $scope.onDivisionChange = function (division) {
        };
        $scope.onPlayTypeChange = function(playType){
            let myKpis = _.cloneDeep(playType.kpis)
            $scope.myKpis = myKpis.filter(k => k.kpiCode !== p0.config.agentOnBoardingProductKpi)
            $scope.isSlickDataLoaded = false;
            setTimeout(function(){
                $scope.isSlickDataLoaded = true;
            },5);
        }
        if($rootScope.isMiddleManager){
            let myKpis = _.cloneDeep(p0.myKpis)
            $scope.myKpis = myKpis.filter(k => k.kpiCode !== p0.config.agentOnBoardingProductKpi)
        }

        //On click
        $scope.customCorrelations = function() {

        };
    });
