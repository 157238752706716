const autoImportNgTemplateLoaderTemplate1 = require('../html/admin/campaign/campaign.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/admin/campaign/listCampaign.html');
const autoImportNgTemplateLoaderTemplate3 = require('../html/admin/campaign/editCampaign.html');
const autoImportNgTemplateLoaderTemplate4 = require('../html/admin/campaign/addCampaign.html');
const autoImportNgTemplateLoaderTemplate5 = require('../html/admin/campaign/addBulkCampaign.html');
const autoImportNgTemplateLoaderTemplate6 = require('../html/admin/campaign/campaignWeightage.html');

'use strict';

angular.module('bitnudgeApp')
    .config(function ($stateProvider) {
        $stateProvider

        .state('campaign', {
            url: '/campaign',
            templateUrl: autoImportNgTemplateLoaderTemplate1,
            controller: function ($http, $scope,  Auth, $location, $q, toaster) {
                $http.post('/api/campaign').then(function (campaign) {
                    campaign=campaign.data;
                    $scope.campaigns = campaign;
                    //socket.syncUpdates('campaign', $scope.campaigns);
                });
                $scope.getCurrentUser = Auth.getCurrentUser;
            }
        })
        .state('lms.listCampaign', {
            url: '/listCampaign',
            templateUrl: autoImportNgTemplateLoaderTemplate2,
            controller: 'adminCampaignsCtrl'
        })
        .state('campaign.editCampaign', {
            url: '/editCampaign',
            templateUrl: autoImportNgTemplateLoaderTemplate3,
            controller: 'adminCampaignsCtrl'
        })
        .state('campaign.addCampaign', {
            url: '/addCampaign',
            templateUrl: autoImportNgTemplateLoaderTemplate4,
            controller: function ($http, $scope,  Auth, $location, $q, toaster, p0) {
                $scope.activities = p0.allActivities;
                $scope.getData = function () {
                    var selected_transaction = $("#action").val();

                    var matches = $scope.activities.filter(function(val, index, array) {
                        return val._id === selected_transaction;
                    });
                    $("#target_param").val(matches[0].keyParam);
                };
                $scope.addActionCount = 0;
                $scope.addAction = function () {
                    //console.log("$scope.activities");
                    //console.log($scope.activities);
                    var dropdown = '<select id="action_select_'+$scope.addActionCount+'" class="with-search col-md-12" ng-model="action" ng-change="getData()">'+'</select>';
                    var action_block = '<div id="action_block_'+$scope.addActionCount+'" class="individual-action-block">'+'</h1>'+dropdown+'</h1>'+'</div>';
                    $("#actions-block").append(action_block);
                };


                $scope.addCampaign = function () {
                    var name = $("#campaign_name").val();
                    var desc = $("#campaign_desc").val();

                    var startDate = $("#campaign-start-date-js").val();
                    var endDate = $("#campaign-end-date-js").val();
                    var rewards = $("#campaign_reward").val();

                    var startDateYear = startDate.split("-")[2];
                    var startDateMonth = startDate.split("-")[1];
                    var startDateDate = startDate.split("-")[0];
                    var startdate = (parseInt(startDateDate)+1).toString();

                    var endDateYear = endDate.split("-")[2];
                    var endDateMonth = endDate.split("-")[1];
                    var endDateDate = endDate.split("-")[0];
                    var objective=$("#campaign_objective").val();
                    var action=$("#action").val();
                    var targetParams=$("#target_param").val();
                    var targetValue=$("#target_val").val();
                    var enddate=(parseInt(endDateDate)+1).toString();
                    var formattedStartDate = new Date(startDateYear, (startDateMonth-1), startdate);
                    var formattedEndDate = new Date(endDateYear, (endDateMonth-1), enddate);
                    $http.post('/api/campaigns/create', {
                        name: name,
                        scope:'Organization',
                        desc:desc,
                        reward: rewards,
                        startDate: formattedStartDate,
                        endDate: formattedEndDate
                    }).then(function (responce) {
                            $location.path('/campaign/listCampaign');
                            toaster.pop('success', "Campaign Creation", "New Campaign Added.");
                        }).catch(function (data, status, headers, config) {
                        
                        });
//
                }
            }

        })
        .state('lms.addBulkCampaign',{
            url: '/addBulkCampaign',
            templateUrl: autoImportNgTemplateLoaderTemplate5,
            controller:function ($http, $scope, Auth, Upload, $location, toaster) {
                $scope.fileType = "campaigns";
                $scope.onFileSelectCampaign = function () {
                    if ($scope.bulkCampaign == undefined) {
                        toaster.pop('error', "Submission.", "Select File to Upload.");
                    } else {
                        var fileName = $scope.bulkCampaign[0].name.split(".");
                        var fileType = fileName[fileName.length - 1];
                        if (fileType == "xls" || fileType == "xlsx" || fileType == "csv") {
                            Upload.upload({
                                url: '/api/upload',
                                data: {userId: Auth.getCurrentUser()._id,cronTag:'campgnCont',invocationMethod:"manual",dataType: $scope.fileType},
                                file: $scope.bulkCampaign[0],
                                progress: function (e) {
                                }
                            }).then(function (data, status, headers, config) {
                                // file is uploaded successfully
                                toaster.pop('success', "Excel uploaded", "Campaign creation successfully finished.");
                            });
                        } else {
                            toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' or '.csv' Types Only.");
                        }
                    }
                }
            }
        })
        .state('lms.addBulkCampaignWeightages', {
            url: '/addBulkCampaignWeightages',
            templateUrl: autoImportNgTemplateLoaderTemplate6,
            controller: function ($http, $scope, Auth, Upload, $location, toaster) {
                $scope.fileType = "campaignActivityWeightages";
                $scope.onFileSelectWeightages = function () {
                    if ($scope.bulkWeightages == undefined) {
                        toaster.pop('error', "Submission.", "Select File to Upload.");
                    } else {
                        var fileName = $scope.bulkWeightages[0].name.split(".");
                        var fileType = fileName[fileName.length - 1];
                        if (fileType == "xls" || fileType == "xlsx" || fileType == "csv") {
                            Upload.upload({
                                url: '/api/upload',
                                data: {userId: Auth.getCurrentUser()._id,cronTag:'campgnContWeights',invocationMethod:"manual", dataType: $scope.fileType},
                                file: $scope.bulkWeightages[0],
                            }).then(function (data, status, headers, config) {
                                // file is uploaded successfully
                                toaster.pop('success', "Excel uploaded", "Weightage creation successfully finished.");
                            });
                        } else {
                            toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' or '.csv' Types Only.");
                        }
                    }
                }
            }
        })
    });
