const autoImportNgTemplateLoaderTemplate1 = require('../html/admin/addBulkDivisions.html');

angular.module('bitnudgeApp')
    .config(function($stateProvider) {
        $stateProvider
            .state('addBulkDivisions', {
                url: '/addBulkDivisions',
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'addBulkDivisionsCtrl'
            })
    });
