'use strict';
angular.module('bitnudgeApp')
    .controller('heatMapPipelineModalCtrl', function (kpiId,userId, userName, $scope, p0,$mdDialog, $http) {
        var kpiIds;
        $scope.kpiId = kpiId;
        $scope.userName = userName;
        $scope.PIPELINE_LABEL = p0.strings.PIPELINE_LABEL ? p0.strings.PIPELINE_LABEL.value : "Pipeline"
        if(kpiId == 'overall'){
            $scope.kpiName = 'Overall';
        }else{
            $scope.kpi = p0.getKpi(kpiId);
            $scope.kpiName = $scope.kpi.name;
        }

        $http.post('/api/userAnalytics/user/analytics', {userId, cycleId: p0.currentMonthlyCycle._id}).then(function(userAnalytic){
            userAnalytic=userAnalytic.data;
            if(kpiId != 'overall') {
                $scope.lmsKpi = _.find(userAnalytic.kpiData, {kpiId:kpiId});
            }else{
                userAnalytic.kpiData.forEach(function(kpiData){
                    kpiData.velocity = _.sumBy(kpiData.stagesData,function(stagesData){
                        return stagesData.timeToAct.value;
                    })
                });
                $scope.lmsKpi = userAnalytic.kpiData;
            }
        });

        $scope.onClose = function(){
            $mdDialog.cancel();
        }
    });
