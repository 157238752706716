'use strict';
angular.module('bitnudgeApp')
    .service('Gender', function ($http ,Auth) {
        this.getJerseyImage = function (user) {
            if(!user) user = Auth.getCurrentUser();
            if(user.gender && user.gender === 'f') return require("../images/JerseyImageFemale.png");
            else return require("../images/JerseyImageMale.png");
        };
        this.getAvatarImage = function () {
            var me = Auth.getCurrentUser();
            if(me.gender && me.gender === 'f') return require("../images/avatarFemale.jpg");
            else return require("../images/avatarMale.jpg");
        };
    });
