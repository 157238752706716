const autoImportNgTemplateLoaderTemplate1 = require('../html/createPlayerGroupModal.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/chatPage/deleteOrLeaveGroupModal.html');
const autoImportNgTemplateLoaderTemplate3 = require('../html/chatPage/showGroupUsers.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('detailChatController', function ($scope, $http, Auth, SocketService, $anchorScroll, $location, $timeout, $filter, $mdDialog) {

        let self = this;

        this.init = function(){
            $scope.currentUser = Auth.getCurrentUser();
            return $http.post('/api/users')
            .then(function(response){
               var users=response.data.data;
                users = _.filter(users, function(user){return user._id.toString() != $scope.currentUser._id.toString()});
                $scope.filteredUsers = users;
            })
            .then(() => {
                this.createSocketConnection();
            })
        }

        this.createSocketConnection = () => {
            SocketService.getSocket().then(function(socket) {
                self.socket = socket;
            });
        }

        $scope.sendMessage = function(group) {
            var users=[{userId: group._id}];
            var date = new Date();
            var message = [{senderId: $scope.currentUser._id, msg: $scope.msg, senderName: $scope.currentUser.name, time: date}];
            var groupObj = {name: group.name, owner: $scope.currentUser._id, users: users, messages:message, time: date, isGroup:false};

            if(!group.owner && $scope.msg){
                return $http.post('/api/chatGroups?opponentName=' + group.name, groupObj).then(function (obj) {
                    obj = obj.data;
                    _.forEach(obj.users, function(user) {
                        user.userId = {_id : user.userId};
                    });

                    $scope.conversation = obj;
                    if($scope.conversation){
                        self.socket.emit('send:message', {_id:$scope.conversation._id, message:message[0]});
                    }
                    return $http.post('/api/chatGroups/updateReadMessages/conversation/'+obj._id+'/'+1)
                }).then(function(chat){
                    $scope.msg = '';
                }).catch(function(e) { console.log('Error', e); })
            }
            else if($scope.msg){
                message = { senderId: $scope.currentUser._id, senderName: $scope.currentUser.name, msg: $scope.msg, time : new Date()};
                $scope.conversation.messages.push(message);
                //console.log(group._id);
                message.groupId = group._id;
                return $http.post('/api/chatGroups/updateConversation', message)
                .then((response) => {
                    response=response.data;
                    $scope.conversation.messages = response.messages;
                    // $scope.conversation.messages[$scope.conversation.messages.length-1]._id = response.messages[response.messages.length-1]._id;
                    return $http.post('/api/chatGroups/updateReadMessages/conversation/'+group._id+'/'+$scope.conversation.messages.length)
                }).then((chat) => {
                    $scope.msg='';
                    self.scrollTo($scope.conversation.messages.length-1);
                    if($scope.conversation){
                        self.socket.emit('send:message', { _id: $scope.conversation._id, message: message });
                    }
                });
            }
            //$scope.refreshChat();

        };

        this.scrollTo = function (index){
            $timeout(function() {
                var old = $location.hash();
                $location.hash(index);
                $anchorScroll();
                $location.hash(old);
            });
        };

        $scope.getClass= function(name, inv){
            if (inv == 'true') {
                if(name && (name.toString() == $scope.currentUser.name.toString()))
                    return 'right';
                else
                    return 'left';
            }
            else {
                if(name && (name.toString() == $scope.currentUser.name.toString()))
                    return 'left';
                else
                    return 'right';
            }
        };
        $scope.getOtherClass= function(name){
            if(name && (name.toString() == $scope.currentUser.name.toString()))
                return 'other-message';
            else
                return 'my-message';
        };
        $scope.archiveConversation = function(conversation){
            conversation.isArchived = true;
            $scope.selectedConversation = {};
            return $http.post('/api/chatGroups/archiveConversation/'+conversation._id +'/archive').then(function(){
                $scope.refreshChat();
                $scope.onGoBack();
            });
        };
        $scope.deleteMessage= function (message) {
            if($scope.currentUser._id == message.senderId){
                var confirm = $mdDialog.confirm()
                    .title('Are you sure you want to delete this message?')
                    .targetEvent(event)
                    .ok('Yes')
                    .cancel('No');
                $mdDialog.show(confirm).then(function() {

                    $http.post('api/chatGroups/deleteMessage', message).then(function (response) {
                        response=response.data;
                        _.remove($scope.conversation.messages,function (currentMessage) {
                            return currentMessage._id == message._id;
                        });
                    });
                });
            }

        };


        $scope.createOrEditGroupModal = function(selectedGroup){
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'createPlayerGroupModalController',
                hasBackdrop: false,
                parent: document.getElementById('groupModal'),
                resolve: {
                    refreshChat: function(){
                        return $scope.refreshChat
                    },
                    selectedGroup: function(){
                        return selectedGroup;
                    },
                    users: function(){
                        return $scope.filteredUsers;
                    },
                    bottomPadding: function(){
                        return $scope.bottomPadding;
                    },
                    onModalClose: function () {
                        return self.modalCloseCallback;
                    }
                }
            });
        };

        this.modalCloseCallback = function (groupImage) {
            $scope.conversation._image = groupImage;
        };

        $scope.deleteGroup = function(group) {
            $scope.refreshChat(true);
            return $http.post('/api/chatGroups/archive', {id: group._id})
            .then(() => {
            })
            .catch((err) => {
                console.log("Error while deleting group", err);
            });
        };

        $scope.leaveGroup = function(group) {
            $scope.refreshChat(true);
            $http.post('/api/chatGroups/leaveGroup', {userId: $scope.currentUser._id, groupId: group._id})
            .then(function () {
            })
            .catch((err) => {
                console.log("Error while leaving group", err);
            });
        };

        $scope.leaveOrDeleteGroup = function(conversation, text){
            if(text === 'Delete Group') $scope.deleteGroup(conversation);
            else $scope.leaveGroup(conversation);
            $mdDialog.cancel();
        };


        $scope.showModal = function(conversation){
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate2,
                controller: 'deleteOrLeaveGroupModalCtrl',
                resolve: {
                    leaveOrDeleteGroup: function () {
                        return $scope.leaveOrDeleteGroup;
                    },
                    conversation: function () {
                        return conversation;
                    },
                    textGroup: function () {
                        return $scope.textGroup;
                    }
                }
            });
        };

        $scope.showGroupDetails = function(conversation){
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate3,
                controller: 'showGroupUsersCtrl',
                resolve: {
                    conversation: function () {
                        return conversation;
                    }
                }
            });

        };

        this.init();
    });
