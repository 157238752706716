'use strict';

angular.module('bitnudgeApp').directive('onlyAlphabetsWithSpace', [
    function(){
        return {
            require: 'ngModel',
            link: function(scope, element, attrs, modelCtrl) {
                modelCtrl.$parsers.push(function (inputValue) {
                    // this next if is necessary for when using ng-required on your input.
                    // In such cases, when a letter is typed first, this parser will be called
                    // again, and the 2nd time, the value will be undefined

                    if (inputValue === undefined) return '' ;
                    var transformedInput = inputValue.replace(/[^a-zA-Z\s]/g, '');
                    if (transformedInput!=inputValue) {
                        modelCtrl.$setViewValue(transformedInput);
                        modelCtrl.$render();
                    }

                    element.bind('blur', function (event) {
                        var fieldValue = element.val();
                        fieldValue = fieldValue.replace(/^\s+|\s+$/g,'');
                        element.val(fieldValue);
                    });

                    return transformedInput;
                });

            }
        };
    }
]);

angular.module('bitnudgeApp').directive('myMaxlength', function() {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ngModelCtrl) {
            var maxlength = Number(attrs.myMaxlength);
            function fromUser(text) {
                if (text.length > maxlength) {
                    var transformedInput = text.substring(0, maxlength);
                    ngModelCtrl.$setViewValue(transformedInput);
                    ngModelCtrl.$render();
                    return transformedInput;
                }
                return text;
            }
            ngModelCtrl.$parsers.push(fromUser);
        }
    };
});

angular.module('bitnudgeApp').directive('setHeight', function($window){
    return{
        link: function(scope, element, attrs){
            var space = screen.height - element[0].offsetTop-20;

            element.css('height', 100 + '%');
            //element.height($window.innerHeight/3);
        }
    }
})

