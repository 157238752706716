const autoImportNgTemplateLoaderTemplate1 = require('../html/admin/downloadLatestFile.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('downloadLatestFile', function () {
        return {
            name: 'downloadLatestFile',
            scope: {
                fileType:'='
            },
            controller: 'downloadLatestFileCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
