angular.module('bitnudgeApp')
    .controller('playerListCtrl', function ($scope, parameters, $http, Auth) {

        $scope.query = {
            order: 'name',
            limit: 5,
            page: 1
        };
        $scope.limitOptions = [5, 10, 15];

        $scope.options = {
            rowSelection: false,
            multiSelect: false,
            autoSelect: false,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true
        };
        $scope.userList = []
        $scope.fetchUserList = function () {
            const postBody ={
                userId: parameters.userId,
                cycleId: parameters.cycleId,
                metric: parameters.type
            }
            $http.post('/api/userAnalytics/seniorManager/performanceList', postBody).then(function (result) {
                result=result.data;
                if(parameters.type == 'redFlag'){
                    $scope.userList = [];
                    _.each(result.redFlag, function (users) {
                        $scope.userList = $scope.userList.concat(users);
                    });
                }
            });
        };
        $scope.fetchUserList();
    });