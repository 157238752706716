const autoImportNgTemplateLoaderTemplate1 = require('../html/dayWiseEarnStatement.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('dayWiseEarnStatement', function(){
        return {
            name: 'dayWiseEarnStatement',
            scope: {
                cycle:  '=',
                selectedKpi: '=',
                allKpis: '='
            },
            controller: 'dayWiseEarnStatementCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
