const autoImportNgTemplateLoaderTemplate1 = require('../html/gradesCard.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('gradesCard', function () {
        return {
            name: 'gradesCard',
            scope: {
                zone: '@',
                label: '@',
                list: '=',
                type: '@',
                onClick: '=',
                showBgColor:    '='
            },
            controller: 'gradesCardCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
