'use strict';

angular.module('bitnudgeApp')
  .controller('addCustomerCtrl', function ($scope, Auth, p0, $mdDialog, countryList) {
     
        $scope.countryList = p0.countryList;

        $scope.customer = {
            country: {
               0:$scope.countryList[0],
            },
            mobileNo: {},
            mobReadOnly: {}
        };

        $scope.nationList = countryList.countries;
        var uaeIndex = $scope.nationList.indexOf("United Arab Emirates ");
        $scope.customer.nationality = (uaeIndex != -1) ? $scope.nationList[uaeIndex] : $scope.nationList[0];
        $scope.mobileNumbersArray = [0];

        $scope.saveCustomer = function(isValid){
            if(isValid){
                $scope.customer.contactNumber = [];
                $scope.mobileNumbersArray.forEach(function (i) {
                    if ($scope.customer.mobileNo[i]) {
                        $scope.customer.contactNumber.push($scope.customer.country[i] + $scope.customer.mobileNo[i]);
                    }
                });
                var duplicates = findDuplicateNumbers(_.cloneDeep($scope.customer.contactNumber));
                if(duplicates.length){
                    return;
                }

                console.log($scope.customer)
                $mdDialog.hide();
            }
        }
        $scope.removeSpaces = function(mobile){
            if(mobile){
                mobile = mobile.replace(/[^\d]/g,'');
                return mobile.substring(0,9);
            }
        };

        function findDuplicateNumbers(contactNumbers){
            var recipientsArray = contactNumbers.sort();
            var duplicates = [];
            for (var i = 0; i < recipientsArray.length - 1; i++) {
                if (recipientsArray[i + 1] == recipientsArray[i]) {
                    duplicates.push(recipientsArray[i]);
                }
            }
            return duplicates;
        };

        $scope.findDuplicateNumbers = function(){
            var contactNumers = [];
            $scope.mobileNumbersArray.forEach(function (i) {
                if($scope.customer.mobileNo[i]) {
                    contactNumers.push($scope.customer.country[i] + $scope.customer.mobileNo[i]);
                }
            });
            var duplicates = findDuplicateNumbers(contactNumers);

            $scope.duplicateNumbers = duplicates.length ? true : false;
        }

        $scope.addNewNumber = function(){
            $scope.focusLastMobile = false;
            var length = $scope.mobileNumbersArray.length;
            var last = $scope.mobileNumbersArray[length -1];
            var contactNumbers = []
            $scope.mobileNumbersArray.forEach(function (i) {
                if ($scope.customer.mobileNo[i]) {
                    contactNumbers.push($scope.customer.country[i] + $scope.customer.mobileNo[i]);
                }
            });
            var duplicates = findDuplicateNumbers(_.cloneDeep(contactNumbers));
            if(duplicates.length){
                $scope.duplicateNumbers = true;
            }
            else if($scope.customer.mobileNo[last] && $scope.customer.country[last]){
                $scope.mobileNumbersArray.push(last+1);
                $scope.customer.country[last+1] = $scope.countryList[0];
            }else{
                $scope.focusLastMobile = true;
            }
        };

        $scope.removeNewMobileNumber = function(index){
            /**Delete the index from array by using the acutal index in the array**/
            var actualIndex = $scope.mobileNumbersArray.indexOf(index);
            $scope.mobileNumbersArray.splice(actualIndex,1);
            delete $scope.customer.country[index];
            delete $scope.customer.mobileNo[index];
            delete $scope.customer.mobReadOnly[index];
            $scope.findDuplicateNumbers();
        }

        $scope.cancel = function(){
            $mdDialog.hide();
        }
  });
