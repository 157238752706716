const autoImportNgTemplateLoaderTemplate1 = require('../html/campaignLeagueTable.html');

'use strict';

angular.module('bitnudgeApp')
    .directive('campaignLeagueTable', function () {
        return {
            name: 'campaignLeagueTable',
            scope: {
                playerType:     '='       // 'managers' or 'frontline'
            },
            controller: 'campaignLeagueTableController',
            restrict: 'EA',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
