"use strict";

angular.module("bitnudgeApp").controller("historyComponentCtrl", function($scope, Auth, DocumentService, toaster) {
    $scope.getCurrentUser = Auth.getCurrentUser;
    $scope.historyLoaded = false;
    this.$onInit = () => {
        $scope.histories = {};
    };
    $scope.isGrouped = false;
    this.$onChanges = changes => {
        if (changes.document) {
            if (!changes.document.isFirstChange()) {
                $scope.document = changes.document.currentValue;
                if ($scope.document) {
                    $scope.isGrouped = false;
                    prepareHistoryView($scope.document);
                }
            } else {
                $scope.historyLoaded = false;
            }
        }
        if (changes.workflowId) {
            $scope.workflowId = changes.workflowId.currentValue;
        }
    };
    const prepareHistoryView = document => {
        if(!document){
            return;
        }
        $scope.historyLoaded = false;
        if (!document._id && $scope.isGrouped || document._id) {
            const param = {
                docId: document._id,
                documentTypeId: document.documentTypeId,
                cui: document.cui,
                isGrouped: $scope.isGrouped,
                workflowId: $scope.workflowId
            };
            DocumentService.history(param)
                .then(histories => {
                    histories.forEach(history => {
                        if (history.thumbnail) {
                            history.thumbnail.dmsUrl = history.thumbnail.dmsUrl
                        }
                    });
                    $scope.histories = histories;
                    $scope.historyLoaded = true;
                })
                .catch(err => {
                    $scope.historyLoaded = true;
                    console.error(err.message);
                    toaster.pop("error", "Error in document history", err.message);
                });
        } else {
            $scope.histories = [];
            $scope.historyLoaded = true;
        }
    };
    $scope.showCurrent = grouptype => {
        $scope.isGrouped = grouptype;
        prepareHistoryView($scope.document);
    };
    $scope.showGrouped = grouptype => {
        $scope.isGrouped = grouptype;
        prepareHistoryView($scope.document);
    };
    $scope.$on("dms:history:fade", (evt, data) => {
        if (data) {
            $scope.isDocumentEdit = true;
        } else {
            $scope.isDocumentEdit = false;
            prepareHistoryView($scope.document);
        }
    });
});