'use strict';
angular.module('bitnudgeApp').controller('addLeadNotesCtrl', function ($scope, $mdDialog, leadsService, lead,reloadLeadInfoPage, toaster) {
    $scope.lead = lead;
    $scope.currentNote = ""
    $scope.cancel = function () {
        $mdDialog.hide();
    }

    $scope.addNote = () => {
        leadsService.addNote($scope.lead._id, $scope.currentNote).then((data)=>{
            if(data.status){
                $scope.currentNote = "";
                toaster.pop('success', 'Lead Notes', `Note is successfully added.`);
                reloadLeadInfoPage();
                $mdDialog.hide();
            }else{
                toaster.pop('error','Lead Notes', 'Error occured while adding notes')
            }
        }).catch(err => {
            const message = err && err.message ? err.message : 'Error occured while adding notes.'
            toaster.pop('error','Lead Notes', message)
        })
    }
    

});
