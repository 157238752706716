'use strict';

angular.module('bitnudgeApp').controller('advancedFiltersController', function ($scope, $mdDialog, parameters, callback, CustomerService) {
    $scope.pagination = CustomerService.getPaginationObj();
    $scope.pagination.limit =  parameters.totalCustomers

    
    $scope.applyAdvancedFilters = function (value){
        // change parameters.ctrl according to the value received
        // console.log("apply all filters ", parameters.ctrl.items);
        
        if(value) {
            callback(value)
        }else{
            // callback(null);
        }
    }

    $scope.cancel = function(){
        $mdDialog.cancel();
    }

});