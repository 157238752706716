'use strict';

const activityIndicatorCard = require('../html/activityIndicatorCard.html');
const nonLmsActivityIndicatorCard = require('../html/nonLmsActivityIndicatorCard.html');

angular.module('bitnudgeApp')
    .directive('activityIndicatorCard', function(){
        return {
            name: 'activityIndicatorCard',
            scope: {
                showSwotIcon:           '=',
                benchmarkValue:         '=',
                benchmark:              '=',
                achievedData:           '=',
                forecastMultiplier:     '=',
                isActive:               '=',
                selectedCycle:          '=',
                user:                   '=',
                lms:                    '=',
                enlarge:                '=',
                toggleChild:            '='
            },
            controller: 'activityIndicatorCardCtrl',
            restrict: 'AE',
            templateUrl: function (elem,attr) {
                if(attr.lms === 'true') return activityIndicatorCard;
                else return nonLmsActivityIndicatorCard;
            }
        };
    });
