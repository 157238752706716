'use strict';
angular.module('bitnudgeApp')
    .controller('rhsAnalyticsL2AchievementCtrl', function ($scope,rhsAnalyticsL2Achievement,sidebarVariables,p0,$rootScope) {
        //sidebars variables
        let self = this;

        this.init = function(){
            $scope.sidebarVariables = sidebarVariables;
            $scope.kpi = sidebarVariables.kpi;
            $scope.user = sidebarVariables.user;
            $scope.monthName = p0.currentMonthlyCycle.name.substring(0,3);
            $scope.weekName = p0.currentWeeklyCycle ? p0.currentWeeklyCycle.name : '';
            $scope.lastSync = $rootScope.lastUpdatedAtDate;
            $scope.config = p0.config;
            $scope.period = 'month';
            $scope.cycle = p0.currentMonthlyCycle;
        }

        $scope.$watch('sidebarVariables.kpi',function(){
            $scope.kpi = sidebarVariables.kpi;
            $scope.setData();
        });
        $scope.$watch('sidebarVariables.user',function(){
            $scope.user = sidebarVariables.user;
            $scope.setData();
        });

        //cycle
        $scope.selectCycle = function (period) {
            $scope.period = period;
            switch (period){
                case 'month':
                    $scope.cycle = p0.currentMonthlyCycle;
                    break;
                case 'week':
                    $scope.cycle = p0.currentWeeklyCycle;
                    break;
                case 'day':
                    $scope.goBack();
                    break;
            }
            $scope.setData();
        };

        $scope.setData = function() {
            return rhsAnalyticsL2Achievement.getData($scope.user, $scope.kpi, $scope.cycle).then( function (data) {
                $scope.data = data;
            });
        };

        $scope.goBack = function(){
            $scope.changeView('lvl1');
        };

        this.init();
    });
