const autoImportNgTemplateLoaderTemplate1 = require('../html/cappedInfo.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/pointsInfo.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('dayWiseEarnStatementCtrl', function ($scope, earnStatement,p0, userAnalyticsService, Auth, $rootScope, $mdDialog, $filter) {
        $scope.strings = $rootScope.strings;
        $scope.me = Auth.getCurrentUser();
        $scope.isFrontline = $rootScope.isFrontline;
        $scope.me.perIFE = false;
        $scope.columnindex = 0;

        $scope.kaiLeft = function(){
            if($scope.columnindex>0)
                $scope.columnindex=$scope.columnindex-1; //#1 - Jump 1 fields everytime
        };

        $scope.kaiRight = function(){
            if($scope.columnindex< $scope.activities[$scope.kpiId].length-4) // #4 - Number of fields displaying
                $scope.columnindex=$scope.columnindex+1;
        };
        $scope.ngScrollbarConfig = {
            autoHideScrollbar: false,
            theme: 'dark',
            advanced:{
                updateOnContentResize: true
            },
            setHeight: "100%",
            axis:'y',
            scrollInertia: 0
        };
        $scope.getActivityName = function(kpiId, index){
            var name;
            name = $scope.activityNames[kpiId][index];
            return name;
        };
        $scope.getUnits = function(overall, kpiId, columnindex){
          var units;
          if(overall && overall.impActivities[kpiId]){
              var act = overall.impActivities[kpiId][$scope.activities[kpiId][columnindex]];
              if(act){
                  units = act.isPercentage ? '(%)' : '(#)';
              }
              return units;
          }
        };
        $scope.getActivityData = function(data, index, type, perIFE){
            if(data.impActivities[$scope.kpiId][$scope.activities[$scope.kpiId][index]]){
                var val, divider=1;
                var act = data.impActivities[$scope.kpiId][$scope.activities[$scope.kpiId][index]];
                if(act.fieldName == 'attendance' && type == 'value'){
                    if(act[type].value == 0){
                        return '0 / 1';
                    }else {
                        return '1 / 1'
                    }
                }
                val = act[type].value;
                if(type == 'value'){
                    if(perIFE){
                        val = (act.isPercentage ? val : act.perFTE);
                    }
                    divider = 10;
                }
                return  Math.round(val * divider)/divider;
            }
        };
        $scope.getKPIData = function(data, type){
            var val;
            if($scope.kpiId == 'overall'){
                if(data && data[type])
                    val = data[type].value;
            }else{
                if(data && data[type])
                    val = data[$scope.kpiId][type].value;
            }
            return $filter('number')(val,0);
        };

        function setImportantActivities(){
            $scope.activities = {};
            $scope.activityNames = {};
            var jobRole = $scope.me.organizationalTag.jobRole._id;
            $scope.allKpis.forEach(function (kpi){
                var myActivities = _.filter(p0.myActivities, function(act){
                    if(kpi._id == 'overall'){
                        return act.important && _.find(act.pointsMultipliers,{role:jobRole}).value > 0;
                    }else{
                        return act.important && act.kpiSpecific && _.find(act.kpiData,{kpiId:kpi._id}) && _.find(act.pointsMultipliers,{role:jobRole}).value > 0;
                    }
                });
                myActivities = _.sortBy(myActivities, ['order']);
                $scope.activities[kpi._id] = _.map(myActivities,'fieldName');
                $scope.activityNames[kpi._id] = _.map(myActivities,'shortName');
            });
        }

        var fetchData = function(){
            earnStatement.dayWiseStatement($scope.cycle).then(function(data){
                data = setActivities(data);
                data = setKPISummary(data);
                $scope.overAll = _.remove(data,function(userAna){
                    return userAna.cycleId._id == $scope.cycle._id;
                })[0];
                $scope.userData = data;
                if(!$scope.userData.length)$scope.userData = [_.cloneDeep($scope.overAll)];
                $scope.userData.forEach(function(data){
                    data.cycleName = moment(new Date(data.cycleId.startDate)).format('MMM DD');
                });
            });
        };
        function getSumOfKpiActivities(activities) {
            var sum = 0;
            _.forOwn(activities, function(act, key){
                sum += act.points.value;
            });
            return {value:sum};
        }
        function setKPISummary(data){
            _.forEach(data, function (userAna) {
                p0.myKpis.forEach(function(kpi){
                    userAna[kpi._id] = {};
                    var kpiData = _.find(userAna.kpiData,{kpiId:kpi._id});
                    if(kpiData){
                        userAna[kpi._id]['percentageCompleted'] = kpiData.percentageCompleted;
                        userAna[kpi._id]['businessPoints'] = kpiData.points;
                        userAna[kpi._id]['activityPoints'] = getSumOfKpiActivities(userAna.impActivities[kpi._id]);
                    }
                })
            });
            return data;
        }
        function setActivities(data) {
            data.forEach(function(userAna){
                userAna.impActivities = {};
                var activityData = userAna.activityData;
                $scope.allKpis.forEach(function(kpi){
                    userAna.impActivities[kpi._id] ={};
                    $scope.activities[kpi._id].forEach(function(impAct){
                        var activity;
                        if(kpi._id == 'overall'){
                            activity = _.find(activityData, function(act){
                                return act.fieldName == impAct && !act.kpiId;
                            });
                        }else{
                            activity = _.find(activityData, function(act){
                                return act.fieldName == impAct && act.kpiId && act.kpiId == kpi._id;
                            });
                        }
                        if(activity){
                            if(activity.activityType == 'percentage'){
                                activity.value.value = activity.value.value * 100;
                                activity.isPercentage = true;
                            }
                            activity.perFTE = (activity.perFTE ? activity.perFTE.value : 0);
                            userAna.impActivities[kpi._id][impAct] = activity;
                        }
                    });
                });
            });
            return data;
        }
        function init(){
            setImportantActivities();
            $scope.$watch('cycle',function (newValue, oldValue) {
                if($scope.cycle) {
                    fetchData();
                }
            });
        }
        $scope.$watch('selectedKpi',function (newVal, oldVal) {
           $scope.kpiId = $scope.selectedKpi._id;
        });
        init();

        $scope.showCappingInfo = function(){
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: function($scope,$rootScope, $mdDialog){
                    $scope.config = $rootScope.config;
                    $scope.onClose = function() {
                        $mdDialog.hide();
                    };
                }
            });
        };
        $scope.showPointsInfo = function(){
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate2,
                controller: 'pointsInfoCtrl'
            });
        };
    });
