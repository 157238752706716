const autoImportNgTemplateLoaderTemplate1 = require('../html/cneKpiTab/dailyFeedModals/bulkCommentModal.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('dailyFeedController', function ($scope,data, Auth,DTOptionsBuilder,onClose, dailyFeed, type, $mdDialog, $rootScope) {
        $scope.me = Auth.getCurrentUser();
        $scope.data = data;
        $scope.isFrontline = $rootScope.isFrontline;
        $scope.feeds = [];

        function sortData(){
            $scope.data.forEach(function (feed) {
                feed.value.badges.forEach(function (badge) {
                    var pushed=false;
                    $scope.feeds.forEach(function (e) {
                        if (e.badgeId == badge.badgeId) {
                            var userExists = e.users.find (function (j) {
                                return j._id == feed.userId._id;
                            });
                            if (!userExists) {
                                e.feedIds.push(feed._id);
                                var user = _.cloneDeep(feed.userId);
                                user.feedId = feed._id;
                                user.likedBy = badge.likes;
                                user.commentedBy = badge.comments;
                                e.users.push(user);
                            }
                            pushed=true;
                        }
                    });
                    if (!pushed) {
                        var finalObj = _.cloneDeep(badge);
                        delete finalObj.liked;
                        delete finalObj.likes;
                        delete finalObj.commented;
                        delete finalObj.comments;
                        finalObj.users = [];
                        var user = _.cloneDeep(feed.userId);
                        user.feedId = feed._id;
                        user.likedBy = badge.likes;
                        user.commentedBy = badge.comments;
                        finalObj.feedIds  = [feed._id];
                        finalObj.users.push(user);
                        $scope.feeds.push(finalObj);
                    }
                });
            });
            $scope.feeds.forEach(function (feed) {
                feed.liked = feed.users.every(function (user) {
                    return user.likedBy && user.likedBy.indexOf($scope.me._id) > -1;
                });
                feed.commented = feed.users.every(function (user) {
                    return user.commentedBy && user.commentedBy.indexOf($scope.me._id) > -1;
                });
            });
            $scope.feeds = _.sortBy($scope.feeds, 'badgeOrder');
        }
        sortData();
        $scope.type = type;
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withPaginationType('simple')
            .withDisplayLength(5)
            .withOption('lengthChange', false)
            .withOption('paging', true)
            .withOption('searching', true)
            .withOption('ordering', false);
        $scope.onClose = function() {
            onClose();
            $mdDialog.cancel();
        };
        $scope.social = function(type,feed){
            dailyFeed.social(type,feed);
        };
        $scope.bulkLike = function(feed){
            var feedIds = feed.feedIds.map (function (e) {
                return $scope.data.find (function (d) {
                    return d._id == e;
                });
            });
            dailyFeed.socialByBadge('bulkLike', feedIds,feed.badgeId, 'badge',null).then(function(updatedFeed){
                updatedFeed.forEach(function (e, i, l) {
                    $scope.data.forEach(function (d) {
                        if (d._id == e._id) {
                            d = e;
                        }
                    });
                });
                sortData();
                onClose();
            });
        };
        $scope.openBulkCommentModal = function(feed){
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'bulkCommentModalCtrl',
                resolve:{
                    dailyFeeds:function(){
                        return feed.feedIds.map (function (e) {
                            return $scope.data.find (function (d) {
                                return d._id == e;
                            });
                        });
                    },
                    onClose: function(){
                        return function() {
                            $scope.feeds = [];
                            sortData();
                            onClose();
                        }
                    },
                    type:function(){return 'badge';}
                },
                locals: {
                    badgeId : feed.badgeId,
                    dataFromDailyFeed: $scope.data
                }
            });
        };
    });
