'use strict';
angular.module('bitnudgeApp')
    .controller('rhsAnalyticsL2IncentiveCtrl', function ($scope) {
        $scope.card1Data = {
            heading1:'MTD Overall',
            heading2:'(Weighted Avg.)',
            percentage:'40%',
            max:1000,
            data:[
                {
                    label:'Personal Loan',
                    number:400
                },
                {
                    label:'Credit Cards',
                    number:100
                },
                {
                    label:'Home Finance',
                    number:700
                },
                {
                    label:'Insurance',
                    value:0
                },
                {
                    label:'Sadara Investments',
                    number:700
                },
                {
                    label:'Sadara Corporate',
                    number:700
                }
            ]
        };
        $scope.card2Data = {
            heading1:'Earned Till Date',
            heading2:'(AED)',
            max:1000,
            data:[
                {
                    label:'Me',
                    number:400,
                    labelColor:'blue-1',
                    graphColor:'blue-1'
                },
                {
                    label:'Bank Top 10%',
                    number:100
                },
                {
                    label:'Best in Branch',
                    number:700
                },
                {
                    selection:[
                        {
                            label:'Bank Avg.',
                            number:700
                        },
                        {
                            label:'Region Avg.',
                            number:100
                        },
                        {
                            label:'Team Avg.',
                            number:100
                        }
                    ]

                }
            ]
        };
        $scope.card3Data = {
            heading1:'Forecast',
            heading2:'(AED)',
            max:1000,
            data:[
                {
                    label:'Me',
                    number:400,
                    pp:'Up 200 vs Jul',
                    icon:'fa-arrow-up',
                    labelColor:'blue-1',
                    graphColor:'green'
                },
                {
                    label:'Bank Top 10%',
                    number:550
                },
                {
                    label:'Best in Branch',
                    number:100
                },
                {
                    selection:[
                        {
                            label:'Bank Avg.',
                            number:700
                        },
                        {
                            label:'Region Avg.',
                            number:100
                        },
                        {
                            label:'Team Avg.',
                            number:100
                        }
                    ]

                }
            ]
        };
        $scope.card4Data = {
            heading1:'Qualification',
            heading2:'(% of target)',
            max:10,
            data:[
                [
                    {
                        text:'Me',
                        color:'blue-1'
                    },
                    {
                        icon:'fa-ban red'
                    }
                ],
                [
                    {
                        text:'Required for Qualification'
                    },
                    {
                        text:'60%'
                    }
                ],
                [
                    {
                        text:'Difference from MTD'
                    },
                    {
                        text:'-40%'
                    }
                ]
            ]
        };
    });
