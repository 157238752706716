const autoImportNgTemplateLoaderTemplate1 = require('../html/managePeersModal.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('moversAndShakersController', function ($scope, $timeout, $http, Auth, fetchLeagues, p0) {

        $scope.showCardView=true;
        $scope.allCycles = p0.allMonthlyCycles;
        $scope.selectedCycle =  p0.currentMonthlyCycle;
        function getJobRoles(){
            if($scope.playerType == 'managers'){
                return p0.myManagerRoles;
            }else{
                return p0.myFrontlineRoles;
            }
        }

        var me=Auth.getCurrentUser();
        $scope.roles = getJobRoles();
        $scope.selectedRole = $scope.roles[0];
        $scope.isOwn = (me.organizationalTag.jobRole._id == $scope.selectedRole._id);
        $scope.allLeagues =fetchLeagues.getAllLeagues(me, $scope.isOwn);
        $scope.selectedLeague = $scope.allLeagues[0];


        $scope.jobRoleChanged = function(){
            $scope.dataRequested = true;
            $scope.getRelevantKpis();
        };
        $scope.getRelevantKpis = function(){
            var relevantDivisions = $scope.selectedRole.division;
            $scope.kpis = [];
            relevantDivisions.forEach(function(division){
                var selectedDivision = _.find(p0.allDivisions,{_id:division});
                $scope.kpis = $scope.kpis.concat(selectedDivision.kpis);
            });
            $scope.mss = getMSs();
            // Fetch data for default tab
            //$scope.getTopMoversAndShakers();

            // Handling data requested for params cycle, role, league.
            if($scope.dataRequested) {
                $scope.dataRequested = false;
                $scope.getTopMoversAndShakers();
            }
        };

        $scope.getRelevantKpis();
        $scope.fixMoversAndShakersOfType=function(msArray, index, type, data){
            data.forEach(function(dataObj){
                dataObj.value=dataObj.deltaRank;
            });
            msArray[index].gainers = _.filter(data,function(num){return (num.deltaRank > 0);});
            msArray[index].loosers = _.filter(data, function(num)   {return (num.deltaRank < 0);}).reverse();
        };

        var getMS = function(type, subtype, cycle, jobRole, league, msArray) {
            var cache = league.value != "peer";
            const {url,postBody} =  getMSRoute(type, subtype, cycle, jobRole, league);
            $http.post(url, postBody).then(function(leaderboard){
                leaderboard=leaderboard.data;
                var index = _.findIndex(msArray, {type:type, subtype:subtype});
                $scope.fixMoversAndShakersOfType(msArray, index, type, leaderboard, subtype);
            });
        };

        var getMSRoute = function(type, subtype, cycle, jobRole, league) {
            const postBody = {
                cycleId: cycle._id,
                type,
                playerType: jobRole._id,
                teamType: league.value,
                teamId: league._id,
                kpiId: subtype
            }
            return {url: '/api/analytics/leaderboards/movers', postBody};
        };

        var fixMoversAndShakersOfCompleteList = function(msArray, leaderboard){
            leaderboard.forEach(function(dataObj){
                dataObj.value=dataObj.deltaRank;
            });
            msArray.gainers = _.filter(leaderboard,function(num){return (num.deltaRank > 0);});
            msArray.loosers = _.filter(leaderboard, function(num)   {return (num.deltaRank < 0);}).reverse();
        }

        var getMSComplete = function(type, subtype, cycle, jobRole, league, msArray,callback) {
            var cache = league.value != "peer";
            const routeData = getMSRoute(type, subtype, cycle, jobRole, league);
            $http.post(routeData.url, routeData.postBody).then(function(leaderboard){
                leaderboard=leaderboard.data;
                fixMoversAndShakersOfCompleteList(msArray,leaderboard);
                return callback();
            });
        }

        $scope.dataRequested = false;
        $scope.getTopMoversAndShakers=function(){
            var cycle = $scope.selectedCycle,
                role = $scope.selectedRole,
                league = $scope.selectedLeague;
            if (!$scope.showCardView) {
                var msComplete = [];
                var msList = [];
                var subtype = $scope.selectedKpi ? $scope.selectedKpi : 0;
                getMSComplete($scope.completeType, subtype, cycle, role, league, msList, function () {
                    if ($scope.gainType == 'G') {
                        msComplete = msList.gainers;
                        $scope.onMsClick($scope.completeType, $scope.selectedKpi, $scope.selectedCycle, $scope.selectedRole, $scope.selectedLeague, $scope.gainType, $scope.selectedCategory, msComplete);
                    }
                    else {
                        msComplete = msList.loosers;
                        $scope.onMsClick($scope.completeType, $scope.selectedKpi, $scope.selectedCycle, $scope.selectedRole, $scope.selectedLeague, $scope.gainType, $scope.selectedCategory, msComplete);
                    }
                });
            }
            else{
                if (!cycle || !role || !league) {
                    $scope.dataRequested = true;
                    return;
                }
                if($scope.mss){
                    var msArray;
                    msArray = $scope.mss;
                    msArray.forEach(function(ms, index){
                        getMS(ms.type, ms.subtype, cycle, role, league, msArray);
                    });
                }
            }
            $scope.showPeerButton = ($scope.selectedLeague.value=="peer");
        };

        $scope.parentCtrlObj.getData = $scope.getTopMoversAndShakers;

        $scope.onMsClick=function(type, selectedKPI, selectedCycleForMSBMTL, selectedPlayerTypeForMSBMTL, selectedLeagueForMSBMTL,gainType,selectedCategory,arr){
            $scope.showComplete();
            $scope.completeLB = arr;
            $scope.selectedCategory = selectedCategory;
            $scope.completeType=type;
            $scope.selectedKpi = selectedKPI;
            $scope.gainType = gainType;
        };

        $scope.showComplete = function() {
            $scope.showCardView=false;
        };

        $scope.showLbCards = function() {
            $scope.showCardView=true;
        };

        $scope.openManagePeersModal = function(){
            $mdDialog.open({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'managePeersModalController',
                size: 'md',
                resolve: {
                    refreshDataCB:  function(){return   $scope.getTopMoversAndShakers;}
                }
            });
        };


        function getMSs() {
            var dummyUsers = [];
            for(var i = 0; i< 5; i++){
                dummyUsers[i] = [];
                dummyUsers[i]["user"] = [];
                dummyUsers[i]["user"]["organizationalTag"] = [];
                dummyUsers[i]["user"]["organizationalTag"]["branch"] = [];
                dummyUsers[i]["user"]["organizationalTag"]["branch"]["name"] = "Loading . . .";
            }
            var ms = [];
            ms.push({name:"Overall "+$scope.strings.POINTS.value,                type:"overall",      subtype:"0",            gainers:dummyUsers, loosers:dummyUsers});
            ms.push({name: $scope.strings.IMPACT.value+' '+$scope.strings.POINTS.value,                 type:"business",     subtype:"0",            gainers:dummyUsers, loosers:dummyUsers});
            ms.push({name: $scope.strings.ACTIVITY.value +' '+$scope.strings.POINTS.value,               type:"activity",     subtype:"0",            gainers:dummyUsers, loosers:dummyUsers});
            //ms.push({name:"Badges",                        type:"badge",        subtype:"0",            gainers:dummyUsers, loosers:dummyUsers});

            $scope.kpis.forEach(function(kpi){
                ms.push({name:kpi.name,             type:"kpi",          subtype:kpi._id, gainers:dummyUsers, loosers:dummyUsers});
            });
            return ms;
        }

    });
