const autoImportNgTemplateLoaderTemplate1 = require('../html/performanceBarometer.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('performanceBarometer', function () {
        return {
            name: 'performanceBarometer',
            scope: {
                reportee:           '=',
                selectedCycle:  '=',
                selectedKpi:    '='
            },
            controller: 'performanceBarometerCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1,
            link: function(scope, element) {
                (function df(){
                    var arr = element.find(".fa-stack");
                    arr.push(angular.element(".fa-quote-left")[0]);
                    var rn = Math.round(Math.random()*arr.length);
                    arr.removeClass("shake");
                    angular.element(arr[rn-1]).addClass("shake");
                    setTimeout(function(){
                        df();
                    }, 3000);
                })();
            }
        };
    });
