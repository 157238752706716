angular.module('bitnudgeApp')
	.controller('addManagerDashboardConfigCtrl', function ($rootScope, $scope, Auth, toaster, $http, p0, Upload) {

		$scope.allMonthlyCycles = p0.allCycles
			.filter(cycle => cycle.cycleType === 'monthly')
			.sort((a, b) => new Date(a.endDate) < new Date(b.endDate));

		$scope.configFilesPrefix = "managerDashboardConfig";

		$scope.uploadFiles = {};

		$scope.latestFilenames = {};

		$scope.allMonthlyCycles.forEach((cycle) => {
			var fileType = getFileType(cycle);
			$http.get('api/adminActions/getLatestFileName/' + fileType).then((fileName) => {
				$scope.latestFilenames[cycle._id] = fileName;
			});
		});

		function getFileType(cycle) {
			return `${$scope.configFilesPrefix}_${cycle._id}`;
		}

		$scope.downloadLatestFile = function (cycle) {
			var fileType = getFileType(cycle);
			var fileName = $scope.latestFilenames[cycle._id];
			$http.get('api/adminActions/getLatestFile/' + fileType,
				{ responseType: "arraybuffer" }).then(function (filedata) {
					saveAs(new Blob([filedata], { type: "application/octet-stream" }), fileName);
				});
		}

		$scope.upload = function (cycle) {
			if (!$scope.uploadFiles[cycle._id]) {
				toaster.pop('error', "Submission error", "Select File to Upload.");
			} else {
				var file = $scope.uploadFiles[cycle._id];
				var fileName = file.name.split(".");
				var fileType = fileName[fileName.length - 1];
				if (fileType == "xls" || fileType == "xlsx" || fileType == "csv") {
					Upload.upload({
                        url: `/api/managerDashboard/config/import/`,
						file: file,
						body: {
							cycleId: cycle._id
						},
                        progress: function (e) {
                        }
                    }).then(function (data, status, headers, config) {
                        // file is uploaded successfully
                        toaster.pop('success', "Excel uploaded", "Activity creation successfully finished.");
                    });
					// $upload.upload({
					// 	url: `/api/managerDashboard/config/import/`,
					// 	file,
					// 	progress: function (e) { }
					// }).then(function (data, status, headers, config) {
					// 	// file is uploaded successfully
					// 	toaster.pop('success', "Excel uploaded", "Manager dashboard configuration uploaded successfully.");
					// 	$scope.latestFilenames[cycle._id] = file.name;
					// 	delete $scope.uploadFiles[cycle._id];
					// }).catch(function (err) {
					// 	var msg = err.data.err;
					// 	(Array.isArray(msg) ? msg : [msg]).forEach(s => toaster.pop('error', "Submission error", s));
					// });
				} else {
					toaster.pop('error', "Submission error", "Upload '.xls', '.xlsx' or '.csv' Types Only.");
				}
			}
		};
	});
