const autoImportNgTemplateLoaderTemplate1 = require('../html/playerComparisonCard.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('playerComparisonCard', function () {
        return {
            name: 'playerComparisonCard',
            scope: {
                cycle:              '=',
                player:             '=',
                playerType:         '=',
                onDeepDive:         '=',

                referenceData:      '=',
                isMyComparison:     '='
            },
            controller: 'playerComparisonCardCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
 });
