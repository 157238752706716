'use strict';
angular.module('bitnudgeApp')
    .controller('reassignCustomerModalCtrl', function ($scope, $mdDialog, customer, Auth, getCustomersData, $http, $state, p0, toaster, secondaryHandler) {
        $scope.customer = customer;
        $scope.me = Auth.getCurrentUser();
        //for list of frontlines or managers
        $scope.isManagerSelected = false;
        //for showing hide/show on switch
        $scope.userToggleSwitch = true;
        $scope.dataLoaded = false;

        const filterConfiguredPlaytypeUsers = (users, roleType) => {

            let filteredHandlerPlaytypes = p0.allPlayTypes.filter(playtype => playtype.canHandleCustomers);
            filteredHandlerPlaytypes = filteredHandlerPlaytypes.map(pt => pt._id.toString())
            
            users = users.filter( user => {
                const userPlaytypes = user.playTypes
                let validPlaytype = false
                if(user.roleType === roleType){
                    userPlaytypes.forEach( pt => {
                        if(filteredHandlerPlaytypes.includes(pt)){
                            validPlaytype = true
                        }
                    })
                }
                return validPlaytype
            })
            return users

        }


        // //on user toggle change update the userlist
        // $scope.changeUsers = function (isManagerSelected) {
        //     $scope.isManagerSelected = isManagerSelected;
        //     if($scope.isManagerSelected){
        //         $scope.usersList = filterConfiguredPlaytypeUsers($scope.allUsersList, 'manager')
        //     }else{
        //         $scope.usersList = filterConfiguredPlaytypeUsers($scope.allUsersList, 'frontline')
        //     }
            
        // }

        //reassign lead to the clicked user
        $scope.onClickPlayer = function (player, event) {
            showConfirm($scope.customer, player, event);
        };

        //modal for confirmation popup
        const showConfirm = function (customer, player, event) {
                //In the case of Assign lead show this message
            const confirm = $mdDialog.confirm()
                .title('Are you sure you want to assign/reassign the handler to ' + player.name + '?')
                .targetEvent(event)
                .ok('Yes')
                .cancel('No');
            $mdDialog.show(confirm).then(function () {
                $http.post(`/api/customer/assignHandlerToCustomer/customerCui/${customer.cui}`,{
                    secondaryHandler,userId: player._id
                }).then(function (result) {
                    if(result.data.status){
                        getCustomersData($scope.me._id);
                        toaster.success("Customer handler successfully assigned")
                    }else{
                        if(result.data.errors){
                            result.data.errors.forEach(error => {
                                if(error.message){
                                    toaster.error(error.message)
                                }else{
                                    toaster.error(error)
                                }
                            })
                        }else{
                            toaster.error("Error in assigning the user to the customer")
                        }
                    }
                    $mdDialog.hide();
                },function(error){
                    toaster.error(error)
                });
            }, function (error) {
            });
        };

        $scope.cancel = function () {
            $mdDialog.hide();
        }

        fetchUsersList()
    });
