'use strict';
angular.module('bitnudgeApp')
    .controller('reassignReferralLeadModalCtrl', function ($scope, $mdDialog, lead, Auth, seniorManagersList, fetchLeads, $http, $state, p0) {
        $scope.lead = lead;
        $scope.me = Auth.getCurrentUser();
        //for list of frontlines or managers
        $scope.isManagerSelected = true;
        //for showing hide/show on switch
        $scope.userToggleSwitch = true;
        $scope.dataLoaded = true;
        $scope.usersList = seniorManagersList;

        $scope.usersList = _.filter($scope.usersList, function(user){
            var worksInKpi = false;
            var leadKpis = p0.getLeadKpisOfPlayTypes(user.playTypes);
            leadKpis.forEach(function(kpi){
                if(kpi._id.toString() == lead.kpiId._id.toString()){
                    worksInKpi = true;
                }
            });
            return worksInKpi;
        })
        
        //reassign lead to the clicked user
        $scope.onClickPlayer = function (player, event) {
            showConfirm($scope.lead, player, event);
        };

        //modal for confirmation popup
        var showConfirm = function (lead, player, event) {
            if (lead.userId.toString() == $scope.me._id.toString()) {
                //In the case of Assign lead show this message
                var confirm = $mdDialog.confirm()
                    .title('Are you sure you want reassign this lead to ' + player.name + '?')
                    .targetEvent(event)
                    .ok('Yes')
                    .cancel('No');
            } else {
                //In the case of Reassign lead show this message
                var confirm = $mdDialog.confirm()
                    .title('Are you sure you want reassign this lead from ' + lead.userId.name + ' to ' + player.name + '?')
                    .targetEvent(event)
                    .ok('Yes')
                    .cancel('No');
            }
            $mdDialog.show(confirm).then(function () {
                const postBody = {
                    leadId: lead._id,
                    userId:  player._id,
                    userEmail: player.email
                }
                $http.post('/api/leads/changeUser', postBody).then(function (lead) {
                    fetchLeads();
                    $mdDialog.hide();
                });
            }, function () {
            });
        };

        $scope.cancel = function () {
            $mdDialog.hide();
        }
    });
