'use strict';
angular.module('bitnudgeApp')
    .controller('oneClickReportController', function($scope, $rootScope, $http, $state, $timeout, Auth,  DTOptionsBuilder, DTColumnDefBuilder,toaster, SwotService, p0,$location, $window, OCR){
        var ratioFieldNames = ['call','meeting'];
        //ToDo differentiate the ratio activities separately, conversionRatio metric is getting used for multi-purpose
        var ratios = _.filter(p0.allActivities, function (activity) {
            return ratioFieldNames.includes(activity.fieldName);
        });
        $scope.strings = $rootScope.strings;
        $http.post('/api/users/getUserById',{id: $state.params.selectedPlayerIdForOCR}).then(function(result){
            let user = result.data.data;
            $scope.selectedUser = user;
            $scope.selectedPlayerName = user.name;

            $scope.selectedPlayerIdForOCR = $state.params.selectedPlayerIdForOCR;
            let kpis = p0.getKpisOfDivisions(user.divisions);
            if(p0.config.agentOnBoardingProductKpi){
                kpis = kpis.filter(k => k.kpiCode !== p0.config.agentOnBoardingProductKpi)
            }
            $scope.kpis = kpis;
            $scope.selectedKPIForPerfTrend = $scope.kpis[0];
            $scope.selectedKPIForMonthlySwotTrend = $scope.kpis[0];
            var activityIds = [];
            $scope.kpis.forEach(function(kpi){
                if(kpi.activities){
                    activityIds = activityIds.concat(kpi.activities);
                }
            });
            $scope.activities = _.filter(p0.allActivities,function(activity){
                return _.find(activityIds,function(id){
                    return id && id.toString() == activity._id.toString() && activity.activityType == 'value';
                });
            });
            $scope.ratios = _.filter(ratios, function (ratio) {
                return activityIds.indexOf(ratio._id) > -1;
            });
            $scope.activities = _.filter($scope.activities,{swot:true});
            $scope.selectedActivityForTrend = $scope.activities[0];
            var fieldNames = _.map($scope.activities,'fieldName');
            $scope.ratios = _.filter($scope.ratios, function(ratio){
                return _.includes(fieldNames,ratio.fieldName);
            });
            $scope.selectedRatio =  $scope.ratios[0];

            $scope.cycles = p0.allMonthlyCycles;
            $scope.selectedCycleForPerfTrend = p0.currentMonthlyCycle;
            $scope.selectedCycleForRedFlags = p0.currentMonthlyCycle;
            $scope.generatePerfTrend();
            // $scope.generateBadgesChart();
            $scope.generateSWOTChart();
            $scope.generateActivityTrend();
            if($scope.selectedRatio){
                $scope.generateConversionRatioChart($scope.selectedRatio);
            }

        });
        $scope.generatePerfTrend = function() {
            const postData = {
                cycleId: $scope.selectedCycleForPerfTrend._id,
                kpiId: $scope.selectedKPIForPerfTrend._id,
                userId: $scope.selectedPlayerIdForOCR,
                metric: 'avg'
            }
            $http.post('/api/analytics/user/OCRGraphData', postData)
                .then(function(perfTrendChart){
                    perfTrendChart=perfTrendChart.data;
                    $scope.perfTrendChart = perfTrendChart;
                    $scope.generateSWOTLineGraph();
                })
        };

        $scope.generateConversionRatioChart= function(ratio){
            var ratioData = [];
            var kpiRatios = ['call', 'meeting', 'email', 'submission', 'newLead'];
            var div = _.find(p0.allDivisions, function(div){
                return _.includes($scope.selectedUser.divisions, div._id);
            });
            var metricType = 'kai', activityId = ratio._id;
            if(kpiRatios.indexOf(ratio.fieldName) > -1){
                metricType = 'ratio';
                activityId = ratio.fieldName;
            }
            OCR.getRatioTrendGraph($scope.selectedUser,div.kpis[0]._id, activityId, metricType).then(function(userData){
                userData.forEach(function(data){
                    data.me = {value:data.value};
                    ratioData.push(data);
                });
                var finalData = $scope.formatSWOTLineData(ratioData);
                var perfTrendChartHeader = ratio.name + ' conversion ratio';
                $scope.HCLineChart("ratio-graph", perfTrendChartHeader, finalData, 'ratio',1);
            });
        };

        $scope.generateActivityTrend = function(){
            var activityData = [];
            var act = $scope.selectedActivityForTrend;
            OCR.getActivityTrendGraph($scope.selectedUser,act._id, act.activityType).then(function(userData,max){
                 userData.forEach(function(data){
                     if(act.activityType == 'percentage'){
                         data.me = {value:data.value};
                     }else{
                         data.me = {value:data.perIFE};
                     }
                     activityData.push(data);
                 });
                var finalData = $scope.formatSWOTLineData(activityData);
                var perfTrendChartHeader = $scope.selectedActivityForTrend.name + ($scope.user.roleType == 'manager'?' (per IFE)' : '');
                $scope.HCLineChart("activity-graph", perfTrendChartHeader, finalData,$scope.selectedActivityForTrend.name,max);
            });
        };

        $scope.generateSWOTLineGraph = function() {
            var perfTrendChartHeader = $scope.selectedKPIForPerfTrend.name +", (% of target)";
            var perfTrendChartRawData = $scope.perfTrendChart.data;
            var perfTrendChartData = $scope.formatSWOTLineData(perfTrendChartRawData);
            $scope.HCLineChart("swot-graph", perfTrendChartHeader, perfTrendChartData,'% of target')
        };

        $scope.formatSWOTLineData = function(data) {
            var TorB = {name: "Team's Average", key: "team"};
            var chartData = [
                {
                    name: $scope.selectedPlayerName,
                    key: "me",
                    marker: {symbol: 'circle'},
                    data: []
                },
                {
                    name: TorB.name,
                    key: TorB.key,
                    marker: {symbol: 'diamond'},
                    data: []
                },
                {
                    name: "Region's Average",
                    key: "region",
                    marker: {symbol: 'triangle'},
                    data: []
                },
                {
                    name: "Bank's Average",
                    key: "bank",
                    marker: {symbol: 'square'},
                    data: []
                }
            ];

            var xLabels = [];

            var meMax = {value: -1, index: -1};
            for(var i = 0; i < data.length; i++) {
                xLabels.push(data[i].name);
                for(var j = 0; j < chartData.length; j++) {
                    if(data[i][chartData[j].key]) {
                        var yAxisValue;
                        if(data[i][chartData[j].key].value == undefined)
                            yAxisValue =data[i][chartData[j].key];
                        else
                            yAxisValue=  data[i][chartData[j].key].value;

                        chartData[j].data.push({
                            y: parseFloat(yAxisValue.toFixed(2)),
                            x: i
                        });
                    }
                }
                if(data[i].me.value >= meMax.value) {
                    meMax.value = data[i].me;
                    meMax.index = i;
                }
            }

            xLabels[xLabels.length-1] += " (Current Month)"

            for(var i = 0; i < chartData.length; i++) {
                if(chartData[i] && chartData[i].key == "me") {
                    if(chartData[i].data && chartData[i].data[meMax.index]){
                        chartData[i].data[meMax.index].marker = {};
                        chartData[i].data[meMax.index].marker.symbol = 'url(images/star.png)';
                    }
                    break;
                }
            }
            return {xLabels: xLabels, data: chartData};
        }

        $scope.HCLineChart = function(id, chartHeader, chartData,yTitle,max) {
            new Highcharts.Chart({
                chart: {
                    renderTo: id,
                    type: 'spline'
                },
                title: {
                    text: chartHeader
                    // text: "KPI: "+$scope.selectedSWOTKPI.name
                },
                // subtitle: {
                //     text: '(% of target)'
                // },
                xAxis: {
                    title: {
                        text: 'Months'
                    },
                    categories: chartData.xLabels
                },
                yAxis: {
                    title: {
                        text: yTitle
                    },
                    labels: {
                        formatter: function () {
                            return this.value + (id=='#swot-graph'?'%':'');
                        }
                    },
                    gridLineDashStyle: "LongDash",
                    max: max?max:100,
                    min: 0
                },
                tooltip: {
                    crosshairs: true,
                    shared: true
                },
                plotOptions: {
                    spline: {
                        marker: {
                            radius: 4,
                            lineColor: '#666666',
                            lineWidth: 1
                        }
                    }
                },
                series: chartData.data
            });
        }

        $scope.generateBadgesChart = function(){
            $http.post('/api/analytics/badgesTrend', {userId: $scope.selectedPlayerIdForOCR})
                .then(function(data){
                    data=data.data;
                    $scope.userBadgeData = data;
                    $scope.generateBarGraphData($scope.userBadgeData,'', 'badgeData');
                })
        }

        $scope.generateBarGraphData = function(graphData,cycleNames,holder){
            $scope[holder] = {};
            if(holder=="redFlagData"){
                $scope[holder].categories =cycleNames;
                $scope[holder].data = graphData;

                $scope.drawBarChart("red-flags-graph", $scope[holder].data, $scope[holder].categories, "Months", "No. of Red Flags");
            }
            else if(holder=="badgeData") {
                $scope[holder].categories = [];
                $scope[holder].data = [];
                if (graphData != undefined){

                    for (var i in graphData) {
                        $scope[holder].categories.push(i)
                        $scope[holder].data.push(_.filter(graphData[i], {from: $scope.selectedKPIBadgesEarned._id}).length)
                    }
                var monthBadges = Object.keys($scope[holder].data);
                    for (var i = monthBadges.length - 1; i >= 0; i--) {
                       $scope.drawBarChart("badges-graph", $scope[holder].data, $scope[holder].categories, "Months", "No. of Badges");
                    }
                }
            }
        }

        $scope.drawBarChart = function(graphId, graphData, xAxisCategory, xAxisTitle, yAxisTitle){
            new Highcharts.Chart({
                chart: {
                    renderTo: graphId,
                    type: 'column',
                },
                title: {
                    text: ''
                },
                subtitle: {
                    text: ''
                },
                xAxis: {
                    title: {
                        text: xAxisTitle
                    },
                    categories: xAxisCategory
                },
                yAxis: {
                    title: {
                        text: yAxisTitle,
                        useHTML: true,
                        style: {
                            "-webkit-transform": "rotate(90deg)",
                            "-moz-transform": "rotate(90deg)",
                            "-o-transform": "rotate(90deg)"
                        }
                    },
                    lineWidth: 1,
                    tickInterval: 1,
                },
                tooltip: {
                    headerFormat: '',
                    pointFormat: '<span style="color:black">'+yAxisTitle+ ': {point.y}</span> <br/>'
                },
                series: [{
                    data: graphData,
                    pointWidth: 15
                }],
                legend: {
                    enabled: false
                }

            });
        }

        $scope.generateRedFlagChart = function(){
            $http.post('/api/analytics/recommendations/redFlagsYearly', {userId: $scope.selectedPlayerIdForOCR}).then(function(redFlags){
                redFlags=redFlags.data;
                $scope.redFlagsData=[];
                var cycleName=[];
                for (var index in redFlags) {
                        cycleName.push(redFlags[index].cycle);
                        $scope.redFlagsData.push(redFlags[index].redFlagData);
                }
                $scope.generateBarGraphData($scope.redFlagsData, cycleName, 'redFlagData');
            });
        };

        function convertToSwotFormat (dataObj) {
            var numLookup={Strength:4,Opportunity:3,Weakness:2,Threat:1,Unknown:0};
            dataObj.data.forEach(function(cycleObj,index) {
                var swot = 'Unknown';
                $scope.swotMonths[index] = cycleObj.name;
                swot = SwotService.getSWOTString(cycleObj.swot);

                //if swot data is not available for a cycle
                if(swot == undefined)
                    swot = 'Unknown';
                $scope.monthlySwot[index] = numLookup[swot];
            });
        };

        $scope.showMyFrontLineMain = function(){
            $state.go('userFlow.analytics', {tabName: 'otherAnalytics'});
            // $rootScope.gotoAnalytics=true;
            // if($rootScope.oneClickSM){
            //     $state.go('userFlow.dashboard');
            // }else{
            //     $state.go('userFlow.analytics');
            // }
        };

        $scope.generateSWOTChart = function(){
            const postData = {
                cycleId: p0.currentMonthlyCycle._id,
                kpiId: $scope.selectedKPIForMonthlySwotTrend._id,
                userId: $scope.selectedPlayerIdForOCR,
                metric: 'avg'
            }
            $http.post('/api/analytics/user/OCRGraphData', postData).then(function(swotData){
                swotData=swotData.data;
                convertToSwotFormat(swotData);
                showMonthlySwotGraph();
            });
        };
        $scope.monthlySwot=[];
        $scope.swotMonths=[];
        showMonthlySwotGraph();
        function showMonthlySwotGraph () {

            new Highcharts.Chart({
                chart: {
                    renderTo: 'monthly-swot-graph',
                    type: 'column',
                },
                title: {
                    text: '',
                    x: -20 //center
                },
                subtitle: {
                    text: '',
                    x: -20
                },
                xAxis: {
                    categories: $scope.swotMonths
                },
                yAxis: {
                    title: {
                        text: 'Monthly ' + $rootScope.strings.SWOT_FULL.value
                    },
                    min:0,
                    max:4,
                    categories:['No Data','Threat','Weakness','Opportunity','Strength']
                },
                tooltip: {
                    valueSuffix: '',
                    formatter: function() {
                        return false;
                    }
                },
                legend: {
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle',
                    borderWidth: 0
                },
                series: [{
                    name: 'SWOT Trend',
                    data: $scope.monthlySwot
                }]
            });
        }

        $scope.changeGraphType = function(){
            if($scope.isRedFlag){
                $scope.generateRedFlagChart();
            }else{
                $scope.generateSWOTChart();
            }
        }



    });
