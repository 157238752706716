const autoImportNgTemplateLoaderTemplate1 = require('../html/redemptionOptions/redeemModal.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('redemptionOptionsCtrl', function ( $scope, redemptionOptions, $mdDialog, $rootScope) {
        $scope.strings = $rootScope.strings;

        redemptionOptions.getData().then(function(data){
            $scope.data = data;
        });
        $scope.title1 = "Redemption options for you";
        $scope.title2 = "Other Redemption Options";

        $scope.redeem = function(reward){
            $mdDialog.show({
                templateUrl:autoImportNgTemplateLoaderTemplate1,
                controller:'redeemModalCtrl',
                size:'lg',
                resolve:{
                    reward:function(){
                        return reward;
                    },
                    balance:function(){
                        return $scope.data.balance
                    }
                }
            });
        };
    });
