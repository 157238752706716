angular.module('bitnudgeApp')
    .controller('PushNotificationsCtrl', function (_, $scope, Auth, $http, toaster, p0) {
        $scope.select = {};
        let allJobRoles = _.cloneDeep(p0.allJobRoles);
        allJobRoles = allJobRoles.sort((a,b)=> a.level - b.level)
        allJobRoles.unshift({_id:'allSeniorManagers', name:`All ${p0.strings.SENIORMANAGER.value}s`, allSeniorManagers:true})
        allJobRoles.unshift({_id:'allManagers', name:`All ${p0.strings.MANAGER.value}s`, allManagers:true})
        allJobRoles.unshift({_id:'allFrontline', name:`All ${p0.strings.FRONTLINE.value}s`, allFrontline:true})

        $scope.rolesList = allJobRoles;

        const validations = function(validateTime){
            const body = {}

            if($scope.select.role && $scope.select.role._id){
                let role = $scope.select.role;
                if(role.allSeniorManagers){
                    body.allSeniorManagers = true;
                }else if(role.allManagers){
                    body.allManagers = true;
                }else if(role.allFrontline){
                    body.allFrontline = true
                }else{
                    body.jobRoleId = role._id
                }
            }else{
                toaster.pop('error','Please select users type')
                return null;
            }
            if($scope.select.title && $scope.select.title.length > 5 && $scope.select.title.length < 41){
                body.title = $scope.select.title;
            }else{
                toaster.pop('error','Please enter a title of minimum 5 characters and Maximum of 40 characters')
                return;
            }

            if($scope.select.content && $scope.select.content.length > 5 && $scope.select.content.length < 256){
                body.content = $scope.select.content;
            }else{
                toaster.pop('error','Please enter the content of minimum 5 characters and max 255 characters')
                return;
            }

            if(validateTime){
                const time = new Date($scope.select.time);
                if($scope.select.time && time){
                    body.hours = time.getHours();
                    body.minutes = time.getMinutes();
                    body.time = time.getHours() +':'+ time.getMinutes();
                }else{
                    toaster.pop('error','Please select a time to run the scheduler daily')
                    return;
                }
            }
            return body
        }

        $scope.sendPushNotification = function(){
            const body = validations();
            if(body){
                $http.post('/api/hotKeys/pushNotifications', body).then(result=>{
                    result = result.data;
                    if(result.status){
                        toaster.pop('success','Notification sent')
                    }else{
                        toaster.pop('err', result.message)
                    }
                })
            }
        }

        const getSchedules = function(){
            $http.post('/api/hotKeys/pushNotifications/get/schedule').then(result=>{
                result = result.data;
                if(result.status){
                    result.data.forEach(d=>{
                        if(d.extraParams.allFrontline){
                            d.extraParams.users = `All ${p0.strings.FRONTLINE.value}s`
                        }else if(d.extraParams.allManagers){
                            d.extraParams.users = `All ${p0.strings.MANAGER.value}s`
                        }else if(d.extraParams.allSeniorManagers){
                            d.extraParams.users = `All ${p0.strings.SENIORMANAGER.value}s`
                        }else if(d.extraParams.jobRoleId){
                            const jobRole = p0.allJobRoles.find(j =>j._id.toString() === d.extraParams.jobRoleId.toString())
                            if(jobRole){
                                d.extraParams.users = `${jobRole.name} (${jobRole.jobCode})`;
                            }
                        }
                    })
                    $scope.select.schedules = result.data;
                    $
                }else{
                    toaster.pop('err', result.message)
                }
            })
        }


        $scope.schedule = function(){
            const body = validations(true);
            if(body){
                $http.post('/api/hotKeys/pushNotifications/schedule', body).then(result=>{
                    result = result.data;
                    if(result.status){
                        toaster.pop('success','Notification scheduled')
                        getSchedules();
                    }else{
                        toaster.pop('err', result.message)
                    }
                })
            }
        }

        $scope.disableScheduler = function(id){
            $http.post('/api/hotKeys/pushNotifications/disableSchedule', {schedulerId:id}).then(result=>{
                result = result.data;
                if(result.status){
                    getSchedules();
                    toaster.pop('success','Notification schedule removed')
                }else{
                    toaster.pop('err', result.message)
                }
            })
        }

        getSchedules();
    })