const autoImportNgTemplateLoaderTemplate1 = require('../html/userCompareCard.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('userCompareCard', function () {
        return {
            name: 'userCompareCard',
            scope: {
                user: '=',
                imagePos: '@'
            },
            controller: 'userCompareCardCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1,
            bindToController: true,
            controllerAs: 'card'
        };
    });
