'use strict';

angular.module('bitnudgeApp')
    .service('PipelineService', function ($http, $q, p0) {
        let self = this;
        self.pipelineData =  {};
        self.pipelineScorecardData = {};
        this.getPipelineData = function ( userId, cycleId, kpiId ) {

            if(! self.pipelineData[userId] ) self.pipelineData[userId] = {};
            if(! self.pipelineData[userId][cycleId] ) self.pipelineData[userId][cycleId] = {};

           return getPipelineViewData(userId, cycleId, kpiId)
        }

        this.getPipelineScorecardData = function( userId, cycleId) {
            if(! self.pipelineScorecardData[userId] ) self.pipelineScorecardData[userId] = {};

            return self.getScorecardData(userId, cycleId);
        }

        this.getScorecardData = function (userId, cycleId, isPipelineViewTrue) {
            if(!userId || !cycleId){
                return $q.resolve();
            }

            var pipelineViewTrue = false;
            if(isPipelineViewTrue){
                pipelineViewTrue = true;
            }

            var userIds = []
            if(typeof userId === 'string')
                    userIds.push(userId)
                else
                    userIds = userId
            var deferred = $q.defer();

            if(! self.pipelineScorecardData[userId][cycleId]){
                $http.post('/api/userAnalytics/getPipelineData', { userIds : userIds, cycleId : cycleId, pipelineViewTrue : pipelineViewTrue })
                    .then( function(result){
                        self.pipelineScorecardData[userId][cycleId] = result.data;
                        deferred.resolve(self.pipelineScorecardData[userId][cycleId]);
                    }).catch( function(error){
                        self.pipelineScorecardData[userId][cycleId] = null;
                        deferred.reject(error);
                    })
                self.pipelineScorecardData[userId][cycleId]= deferred.promise;
                // $http.get( '/api/userAnalytics/getPipelineData/userId/' + userId + '/cycleId/' + cycleId)
                //     .then(function(result) {
                //         self.pipelineScorecardData[userId][cycleId] = result.data;
                //         deferred.resolve(self.pipelineScorecardData[userId][cycleId]);

                //     }).catch(function(error) {
                //         self.pipelineScorecardData[userId][cycleId] = null;
                //         deferred.reject(error);
                //     });
                //     self.pipelineScorecardData[userId][cycleId]= deferred.promise;
            }
            return $q.when(self.pipelineScorecardData[userId][cycleId]);
        };

        var getPipelineViewData = function (userId, cycleId, kpiId) {
                var pipelineViewTrue = true;
                var userIds = []
                if(typeof userId === 'string')
                    userIds.push(userId)
                else
                    userIds = userId
                return $http.post('/api/userAnalytics/getPipelineData', { userIds : userIds, cycleId : cycleId, pipelineViewTrue : pipelineViewTrue });
                // then( function(result){
                //     console.log(result);
                // }, function(err){

                // })
                // return $http.get('/api/userAnalytics/getPipelineData/userId/' + userId + '/cycleId/' + cycleId);
        };


        this.exportToExcel = function(reporteePipelineData, fileName){

            return $http.post('/api/userAnalytics/exportPipelineToExcel', { pipelineData : reporteePipelineData } , { responseType: "arraybuffer" })
            .then(function (filedata) {
                filedata=filedata.data;
                // TODO: move excel download to server side
                saveAs(new Blob([filedata], { type: "application/octet-stream" }), fileName);
            });
        };

        this.getLastPipelineCronStatus = () => {
			return $http.post('/api/lmsTrackers/status', {name:'PIPELINE_CRON'}).then(function (lastSync) {
				lastSync = lastSync.data;
				return $q.resolve(lastSync);
			});
		};

        this.destroy = function(){
            self.pipelineData = {};
        }
    });
