const autoImportNgTemplateLoaderTemplate1 = require('../html/chartCard.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('chartCard', function () {
        return {
            name: 'chartCard',
            scope: {
                graphCreationData : '=',
                benchmarkData  :'<',
                addRemoveOptions : '<',
                units : '<',
                myIndicators : "<",
                indicator : '=',
                benchmarkComparisonOptions :'<',
                metric : '=',
                onKpiChange : '=',
                showBenchmark : '<',
                showComparison : '<',
                onCombinedActivityChange : '=',
                activityConversionRatiosData : '=',
                activityConversionRatiosBenchmarkData: '=',
                combinedActivity : '=',
                getPeerAnalyticsData: '=',
                kpiPeerAnalyticsData : '=',
                activityPeerAnalyticsData : '='
            },
            controller: 'chartCardCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
