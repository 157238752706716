'use strict';

angular.module('bitnudgeApp')
    .controller('securityQuestionsController', function ($scope, $http,$state,$stateParams, toaster, Auth) {
        var allQuestions;
        $scope.set = $stateParams.set == 'true';
        $scope.loaded = true;
        if($scope.set) {
            $scope.title = 'Please enter your security questions.';
            $scope.userQuestions = [];
            for (var i = 0; i < 3; i++) {
                $scope.userQuestions.push({});
            }
            $http.post('/api/securityQuestions/').then(function (result) {
            	if(result.data && result.data.status){
					var questions = result.data.data;
					allQuestions = questions;
					$scope.userQuestions.forEach(function (question) {
						question.questions = questions;
					});
				}else{
            		console.error(result);
					toaster.pop('error', "Security Questions", "Failed to get the security questions");
				}
            }).catch(angular.noop);
        }else{
            $scope.title = 'Please answer your security questions.';
            $http.post('/api/users/getSecurityQuestions', {email: $stateParams.email}).then(function(result){
				if(result.data && result.data.status){
					var questions = result.data.data;
					if(!questions || !Object.keys(questions).length){
						toaster.pop('error', "Security Questions", "Security questions are not set. Please contact your Admin to reset password.");
						$state.go('login');
						return
					}
					var num = Math.floor(Math.random() * 3);
					$scope.userQuestions = questions;
					$scope.userQuestions.splice(num, 1);
					return;
				}else{
                    $scope.loaded = false;
                    $scope.error = true;
                    var message = result.data.message;
                    if(message){
                        $scope.title = '';
                        $scope.message = message;
                    }else{
                        message = 'Failed to get the security questions';
                    }
					toaster.pop('error', "Security Questions", message);
				}
            }).catch(angular.noop);

        }


        $scope.questionSelected = function () {
            $scope.userQuestions.forEach(function (uq, i) {
                uq.questions = _.clone(allQuestions);
                $scope.userQuestions.forEach(function (innerUq, innerI) {
                    if (innerI == i) {
                        return;
                    }
                    if (innerUq.question) {
                        _.remove(uq.questions, function (q) {
                            return q._id.toString() == innerUq.question._id.toString();
                        });
                    }
                });
            });
        };

        $scope.save = function () {
            $scope.myForm1.$setSubmitted();
            if ($scope.myForm1.$valid) {
                return $http.post('/api/users/setSecurityQuestions',{questions:$scope.userQuestions}).then(function(result){
					if(result.data && result.data.status){
						var currentUser = Auth.getCurrentUser();
						if(currentUser.cloneUser){
							currentUser.cloneUser.questionsSet = true;
						}else{
							currentUser.questionsSet = true;
						}
						$state.go('userFlow.dashboard');
					}else{
						console.error(result);
						toaster.pop('error', "Security Questions", "Failed to set the security answers");
					}
				}).catch(angular.noop);
            }
        }

        $scope.checkAnswers = function () {
            $scope.myForm2.$setSubmitted();
            if ($scope.myForm2.$valid) {
                return $http.post('/api/users/checkSecurityAnswers',{questions:$scope.userQuestions,email:$stateParams.email}).then(function(result){
                    if(result.data && result.data.status){
                        let token = result.data.data.token;
                        if(token) {
                            $state.go('reset',{token:token});
                        }else{
                            toaster.pop('error', "Security Questions", "Incorrect Answers. Try Again.");
                        }
                    }else{
                        result.data.errors.forEach(error => {
                            toaster.pop('error', "Security Questions", error.message);
                        })
                    }
                });
            }
        }

        $scope.gotoLogin = () =>{
            $state.go('login');
        }
    });
