'use strict';
angular.module('bitnudgeApp')
    .service('Correlation', function ($http, $q){
        var _this = this;

        this.fetchSalesAndActivityData = function (userIds, timeRange, timePeriod,  yMetric) {
            const startDate = new Date(timeRange.startDate).getTime();
            const endDate = new Date(timeRange.endDate).getTime();
            const cycleType = timePeriod._id;
            return $http.post('/api/customReports/correlation',{startDate, endDate, cycleType, kpiId: yMetric._id, userIds: userIds}).then(function (result) {
                result=result.data;
                return $q.resolve(result);
            });
        };

        var getUrl = function (timeRange, timePeriod, kpiId) {
            const startDate = new Date(timeRange.startDate).getTime();
            const endDate = new Date(timeRange.endDate).getTime();
            const cycleType = timePeriod._id;
            return '/api/customReports/correlation'+
                '/startDate/'+startDate +
                '/endDate/'+ endDate +
                '/kpiId/'+ kpiId +
                '/cycleType/'+cycleType;
        };
    });
