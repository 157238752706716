angular.module('bitnudgeApp')
    .controller('listRedemptionsCtrl', function ($scope,Auth, Upload, toaster, $http, ngXlsx) {
        $http.post('/api/redemptions').then(function(redemptions){
            redemptions=redemptions.data;
            $scope.data = redemptions;
        });

        $scope.downloadExcel = function(){
            var data = [];
            $scope.data.forEach(function(row){
                data.push({
                    _id:row._id,
                    date:row.redemptionDate,
                    rewardId:row.rewardId.code,
                    reward:row.rewardId.name,
                    userId:row.userId.email,
                    user:row.userId.name,
                    status:row.status
                });
            });
            var result = ngXlsx.writeXlsx([
                {
                    sheetName: "testSheet",
                    columnDefs: [
                        {field: "_id", displayName: "_id"},
                        {field: "date", displayName: "date"},
                        {field: "rewardId", displayName: "rewardId"},
                        {field: "reward", displayName: "reward"},
                        {field: "userId", displayName: "userId"},
                        {field: "user", displayName: "user"},
                        {field: "status", displayName: "status"}
                    ],
                    data: data
                }
            ]);
            // TODO: move excel download to server side
            saveAs(new Blob([s2ab(result)],{type:"application/octet-stream"}), "redemptions.xlsx");
        };

        function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }

        $scope.uploadExcel = function(){
            if ($scope.importFile == undefined) {
                toaster.pop('error', "Submission.", "Select File to Upload.");
            } else {
                var fileName = $scope.importFile[0].name.split(".");
                var fileType = fileName[fileName.length - 1];
                if (fileType == "xls" || fileType == "xlsx" || fileType == "csv") {
                    Upload.upload({
                        url: '/api/redemptions/import/excel',
                        data: {userId: Auth.getCurrentUser()._id},
                        file: $scope.importFile[0],
                        progress: function (e) {
                        }
                    }).then(function (data, status, headers, config) {
                        toaster.pop('success', "Excel uploaded", "Successfully uploaded the file.");
                    });
                } else {
                    toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' or '.csv' Types Only.");
                }
            }
        };
    });