const autoImportNgTemplateLoaderTemplate1 = require('../html/lbCompleteList.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('lbCompleteList', function(){
      // Runs during compile
      return {
        name: 'lbCompleteList',
        scope: {
          completeLB:'=completeModel',
          selectedLeague:'=selectedLeagueModel',
          selectedCard:'=selectedCardModel',
          selectedCategory:'=selectedCategory',
          selectedCycle:'=selectedCycleModel',
          completeType:'@type',
          onRefreshData:'=onRefreshData',
          isOwnLeaderBoard:   '@',
        },
        controller: 'LbCompleteListCtrl',
        restrict: 'AE',
        templateUrl: autoImportNgTemplateLoaderTemplate1
      };
    });
