'use strict';

angular.module('bitnudgeApp')
  .controller('configureLeadsSubProduct', function ($http, $scope, Auth, Upload, $location, toaster,$rootScope) {
        $scope.currentUser = Auth.getCurrentUser()
      $scope.strings = $rootScope.strings;
      $scope.fileType = "leads-configure-sub-product";
        $scope.onFileSelectLead = function () {
            if ($scope.bulkLead == undefined) {
                toaster.pop('error', "Submission.", "Select File to Upload.");

            } else {
                var fileName = $scope.bulkLead[0].name.split(".");
                var fileType = fileName[fileName.length - 1];
                if (fileType == "xls" || fileType == "xlsx" || fileType == "xlsb" || fileType == "xlsm") {
                    Upload.upload({
                        url: '/api/upload',
                        data: {userId: Auth.getCurrentUser()._id,cronTag: 'ldSubProd',
                        invocationMethod: 'manual'},
                        file: $scope.bulkLead[0],
                        progress: function (e) {
                        }
                    }).then(function (data, status, headers, config) {
                        // file is uploaded successfully
                        toaster.pop('success', "Excel uploaded", "Sub-Product Configuration successfully finished.");
                    });
                } else {
                    toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' or '.csv' Types Only.");
                }

            }
        }

    });
