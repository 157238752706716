'use strict';
angular.module('bitnudgeApp')
    .controller('keyRadialGraphCtrl', function ($scope,$rootScope,Auth) {
        $scope.me = Auth.getCurrentUser();
        var mainChart;
        if($scope.goal.incentive.value == 1 && $scope.goal.gameplanCompliant){
            $scope.iconColor = 'green';
            $scope.iconText = '<p>Incentive achieved.</p><p>Gameplan compliant</p>';
        }else if($scope.goal.incentive.value == 0 && !$scope.goal.gameplanCompliant){
            $scope.iconColor = 'red';
            $scope.iconText = '<p>Incentive not achieved.</p><p>Not gameplan compliant</p>';
        }else{
            $scope.iconColor = 'amber';
            if($scope.goal.incentive.value == 0){
                $scope.iconText = '<p>Incentive not achieved.</p><p>Gameplan compliant</p>';
            }else{
                $scope.iconText = '<p>Incentive achieved.</p><p>Not gameplan compliant</p>';
            }
        }
        function getColor(percent) {
            if(percent >= 100)  return "#c1fcc1";
            if(percent >= 90)  return "#FFCC00";
            return "#ff7f7f";
        }
        function getForecastValue(type){
            var multiplier;
            if($scope.selectedBenchmark != 'previousPeriod' && $scope.selectedBenchmark != 'ytdBest' && $scope.cycleIsActive && $scope.goal.kpiType != 'percentage'){
                multiplier = $rootScope.forecastMultiplier;
                if(type == 'value'){
                    return $scope.goal.benchmarks[$scope.selectedBenchmark].averageValue*multiplier;
                }else{
                    return $scope.goal.benchmarks[$scope.selectedBenchmark].averagePercentage*multiplier;
                }
            } else{
                if(type == 'value'){
                    return $scope.goal[$scope.selectedBenchmark+"Value"];
                }else{
                    return $scope.goal[$scope.selectedBenchmark+"Percentage"];
                }
            }
        }
        function getActualValue(){
            if($scope.selectedBenchmark != 'previousPeriod' && $scope.selectedBenchmark != 'ytdBest' && $scope.cycleIsActive)
                return $scope.goal.benchmarks[$scope.selectedBenchmark].averagePercentage;
            else
                return 0;
        }
        $scope.$watch('selectedBenchmark',function(newVal,oldVal){
            if(newVal && mainChart) {
                if(mainChart) {
                    mainChart.destroy();
                    $('.radialContainer svg').remove();
                    setTimeout(populateChart, 100);
                }
            }
        });
        $scope.$watch('isForecast',function(newVal,oldVal){
            if(mainChart) {
                mainChart.destroy();
                $('.radialContainer svg').remove();
                setTimeout(populateChart, 100);
            }
        });
        setTimeout(populateChart, 100);
        function roundValues(value){
            return parseFloat(value.toFixed(2)).toLocaleString('en-IN');
        }
        function capPercentage(value){
            value *= .75;
            if(value > 75)
                return 75;
            else
                return value;
        }
        function getStartLabel(){
            if($scope.cycle.active && !$scope.isForecast)
                return 'F:'+Math.round($scope.goal.forecast)+'%';
            else if ($scope.cycle.active && $scope.isForecast)
                return 'MTD: '+Math.round($scope.goal.percentageCompleted.value) + '%';
            else
                return $scope.cycle.name.split(' ')[0]+': '+ Math.round($scope.goal.percentageCompleted.value)+'%';
        }
        function getSecondLabel(){
            if($scope.selectedBenchmark != 'previousPeriod' && $scope.selectedBenchmark != 'ytdBest' && $scope.cycleIsActive)
                return Math.round($scope.goal.benchmarks[$scope.selectedBenchmark].averagePercentage);
            else
                return Math.round($scope.goal[$scope.selectedBenchmark+"Percentage"]);
        }
        function populateChart() {
            var index = 0;
            var id = "#goal" + $scope.i;
            var forecastValue = $scope.goal.achievedValue.value * $rootScope.forecastMultiplier;
            forecastValue = forecastValue / $scope.goal.denom;
            var forecastPercentage = getForecastValue('percentage');
            var actualValue = getActualValue('value');
            var actualPercentage = getActualValue('percentage');
            mainChart = new RadialProgressChart(id, {
                diameter: 90,
                quarters:3,
                stroke: {
                    width: 10,
                    gap: 5
                },
                max: 100,
                round: true,
                series: [
                    {
                        labelStart: getStartLabel(),
                        value: capPercentage($scope.goal.forecast),
                        color: {
                            solid: getColor($scope.goal.forecast),
                            background: '#939393'
                        }
                    },
                    {
                        labelStart: 'bm: '+getSecondLabel()+ "%",
                        value:  $scope.cycleIsActive ? capPercentage($scope.goal.percentageCompleted.value) : 0,
                        color: {
                            solid: '2a91d8',
                            background: '#939393'
                        }
                    }, {
                        value:  capPercentage(forecastPercentage),
                        color: {
                            solid: getColor(forecastPercentage),
                            background: '#939393'
                        }
                    },
                    {
                        value: capPercentage(actualPercentage),
                        color: {
                            solid: '2a91d8',
                            background: '#939393'
                        }
                    }],
                center: {
                    content: [
                        Math.round($scope.goal.percentageCompleted.value)+'%',
                        $scope.cycle.active && $scope.isForecast? 'Forecast: '+Math.round(forecastValue): "MTD: "+roundValues($scope.goal.achievedValue.value)
                    ],
                    y: 0
                }
            });
        }
    });
