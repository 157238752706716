'use strict';

angular.module('bitnudgeApp')
    .service('step2', function ($http, p0,Auth, userAnalyticsService, moment,$rootScope,leagueAnalytic, $q) {
        this.getData = function(cycle){
            return $http.post('/api/analytics/step2',{cycleId:cycle._id}).then(function(data){
                data=data.data;
                return data;
            });
        };

        this.getSummary = function(){
            var me = Auth.getCurrentUser();
            var currentCycle = p0.trackMonthlyCycle;
            var monthStart = new Date(currentCycle.startDate);
            var monthEnd = new Date(currentCycle.endDate);
            if(p0.config.weeklyCycle){
                var today = new Date(p0.trackWeeklyCycle.startDate);
                var racePeriod;
                var startWeek = moment(monthStart).format('w');
                var endWeek = moment(monthEnd).format('w');
                var currentWeek = moment(today).format('w');
                if(today.getDate() == 1){
                    racePeriod = 'firstDay';
                }else if(currentWeek == startWeek){
                    racePeriod = 'week1';
                }else if(currentWeek == endWeek){
                    racePeriod = 'lastWeek';
                }else if(currentWeek - startWeek == 1){
                    racePeriod = 'week2';
                } else if(currentWeek - startWeek == 2){
                    racePeriod = 'week3';
                } else if(currentWeek - startWeek == 3){
                    racePeriod = 'week4';
                }
            }else{
                racePeriod = 'monthly';
            }


            var teamAnalytic, teamRank;
            if(me.roleType == 'frontline'){
                return userAnalyticsService.fetchUserAnalytics(me.parent,p0.trackWeeklyCycle ? p0.trackWeeklyCycle._id : p0.trackMonthlyCycle._id).then(function (parentAnalytic) {
                    teamAnalytic = parentAnalytic;
                    if(teamAnalytic){
                        teamRank = teamAnalytic.points.ranks[teamAnalytic.points.ranks.length - 1];
                    }
                    return calcSummary();
                });
            }else{
                return calcSummary();
            }
            function calcSummary() {
                var cycleIds = [currentCycle._id];
                if(p0.trackWeeklyCycle){
                    cycleIds.push(p0.trackWeeklyCycle._id);
                }
                if(currentCycle.previousCycleId)
                    cycleIds.push(currentCycle.previousCycleId._id);
                    return userAnalyticsService.fetchUserAnalyticsOfCycles(me._id, cycleIds).then(function (userAnalytics) {
                        return leagueAnalytic.fetchLeagueAnalytic(me.leagues[me.leagues.length - 1].leagueId,currentCycle._id).then(function(monthlyLeagueAnalytic){
                            return leagueAnalytic.fetchLeagueAnalytic(me.leagues[me.leagues.length - 1].leagueId,p0.trackWeeklyCycle ? p0.trackWeeklyCycle._id : null).then(function(weeklyLeagueAnalytics) {
                            var name = me.name;
                            var currentAnalytics = _.find(userAnalytics, {cycleId:currentCycle._id});
                            var weeklyAnalytics = _.find(userAnalytics, function(userAna){
                                return p0.trackWeeklyCycle && p0.trackWeeklyCycle._id == userAna.cycleId;
                            });
                            if(currentCycle.previousCycleId) {
                                var previousAnalytics = _.find(userAnalytics, {cycleId: currentCycle.previousCycleId._id});
                                if (previousAnalytics) {
                                    var previousRank = previousAnalytics.points.ranks[me.leagues.length - 1].value;
                                }
                            }
                            if(currentAnalytics){
                                var currentRank = currentAnalytics.points.ranks[me.leagues.length - 1];
                                var swot = currentAnalytics.swot;
                            }

                            var summary = [];
                            summary.push('<span class="left"><i class="fa fa-flag-checkered"></i> Laps concluded: <span class="number">' + $rootScope.daysPassed + '</span></span> <span class="right"><i class="fa fa-flag-checkered"></i> Laps remaining in the month: <span class="number">' + $rootScope.daysLeft + '</span></span>');
                            var weekRank, weekGainers, weekLoosers;
                            if(weeklyLeagueAnalytics){
                                weekRank = weeklyAnalytics.points.ranks[me.leagues.length - 1];
                                weekGainers = _.filter(weeklyLeagueAnalytics.points.leaders, function (leader) {
                                    return leader.deltaRank > 0;
                                });
                                weekLoosers = _.filter(weeklyLeagueAnalytics.points.leaders, function (leader) {
                                    return leader.deltaRank < 0;
                                });
                            }
                            switch (racePeriod) {
                                case 'monthly':
                                    //TODO change the monthly summary text .... now it is just copied from firstDay...
                                    if(swot && currentRank){
                                        var start = getStartText(swot);
                                        summary.push('Welcome to the live race. You are off to a ' + start + ' and have ' + getMovedText(currentRank.delta) + ' <span class="number">' + Math.abs(currentRank.delta) + '</span> ranks versus last month.');
                                    }
                                    if (me.roleType == 'frontline') {
                                        if(teamAnalytic && teamRank){
                                            var teamStart = getTractionText(teamAnalytic.swot);
                                            summary.push('Your team is showing ' + teamStart + ' traction and has ' + getMovedText(teamRank.delta) + ' <span class="number">' + Math.abs(teamRank.delta) + '</span> positions in ranking versus last week.');
                                        }
                                    }
                                    summary.push('A lot is still to come. Push the octane!');
                                    break;
                                case 'firstDay':
                                    summary.push('Welcome to the track. The ' + currentCycle.previousCycleId.name + ' race just got concluded. You notched Rank <span class="number">' + previousRank + '</span>.');
                                    summary.push('As the ' + currentCycle.name + ' GrandPrix kicks-off, we wish you and your team all the very best and hope that this race sees the best in you!');
                                    break;
                                case 'week1':
                                    if(swot && currentRank){
                                        var start = getStartText(swot);
                                        summary.push('Welcome to the live race. You are off to a ' + start + ' and have ' + getMovedText(currentRank.delta) + ' <span class="number">' + Math.abs(currentRank.delta) + '</span> ranks versus last month.');
                                    }
                                    if (me.roleType == 'frontline') {
                                        if(teamAnalytic && teamRank){
                                            var teamStart = getTractionText(teamAnalytic.swot);
                                            summary.push('Your team is showing ' + teamStart + ' traction and has ' + getMovedText(teamRank.delta) + ' <span class="number">' + Math.abs(teamRank.delta) + '</span> positions in ranking versus last week.');
                                        }
                                    }
                                    summary.push('A lot is still to come. Push the octane!');
                                    break;
                                case 'lastWeek':
                                    if(monthlyLeagueAnalytic){
                                        var meIndex = _.findIndex(monthlyLeagueAnalytic.points.leaders, {userId: me._id});
                                        var playerBehind = monthlyLeagueAnalytic.points.leaders[meIndex - 1];
                                        var improved = weekRank.delta > 0? '[including you]':'';
                                        var slipped = weekRank.delta < 0? '[including you]':'';
                                        summary.push('Welcome back. Just, <span class="number">' + $rootScope.daysLeft + ' more days to go for the final whistle. Player behind you ' + playerBehind.name + ' is fast approaching.')
                                        summary.push('Remember, a lot can still happen in the race.');
                                        summary.push('Racing pressure is mounting. <span class="number">'+ weekGainers.length +'</span> players improved their standing'+ improved +' while <span class="number">'+ weekLoosers.length +'</span> ' +slipped +' slipped down.');
                                        summary.push('Time to push the accelerator hard towards the finish line. Keep all engines firing!');
                                    }
                                    break;
                                case 'week2':
                                    if(weekRank){
                                        var improved = weekRank.delta > 0? '[including you]':'';
                                        var slipped = weekRank.delta < 0? '[including you]':'';
                                        summary.push('You are ' + getMovingText(weekRank.delta) + ' your position and have ' + getMovedText(weekRank.delta)+' <span class="number">' +weekRank.delta +'</span> ranks versus last week.');
                                    }
                                    if (me.roleType == 'frontline' && teamAnalytic && teamRank) {
                                        var teamStart = getTractionText(teamAnalytic.swot);
                                        summary.push('Your team is showing ' + teamStart + ' traction and has ' + getMovedText(teamRank.delta) + ' <span class="number">' + Math.abs(teamRank.delta) + '</span> positions in ranking versus last week.');
                                    }
                                    if(weekGainers && weekLoosers && (weekGainers.length || weekLoosers.length)){
                                        summary.push('Last week saw a lot of action. <span class="number">'+ weekGainers.length +'</span> players improved their standing'+ improved +' while <span class="number">'+ weekLoosers.length +'</span> ' +slipped +' slipped down.');
                                    }
                                    summary.push('A lot is still to come. Push the octane!');
                                    break;
                                case 'week3':
                                    if (weekRank){
                                        var improved = weekRank.delta > 0? '[including you]':'';
                                        var slipped = weekRank.delta < 0? '[including you]':'';
                                        summary.push('You are ' + getMovingText(weekRank.delta) + ' your position and have ' + getMovedText(weekRank.delta)+' <span class="number">' +weekRank.delta +'</span> ranks versus last week.');
                                    }
                                    if (me.roleType == 'frontline' && teamAnalytic && teamRank) {
                                        var teamStart = getTractionText(teamAnalytic.swot);
                                        summary.push('Your team is showing ' + teamStart + ' traction and has ' + getMovedText(teamRank.delta) + ' <span class="number">' + Math.abs(teamRank.delta) + '</span> positions in ranking versus last week.');
                                    }
                                    if(weekGainers && weekLoosers && (weekGainers.length || weekLoosers.length)){
                                        summary.push('Last week saw a lot of action. <span class="number">'+ weekGainers.length +'</span> players improved their standing'+ improved +' while <span class="number">'+ weekLoosers.length +'</span> ' +slipped +' slipped down.');
                                        summary.push('A lot is still to come. Rev on!');
                                    }
                                    break;
                                case 'week4':
                                    if(weekRank){
                                        var improved = weekRank.delta > 0? '[including you]':'';
                                        var slipped = weekRank.delta < 0? '[including you]':'';
                                        summary.push('Welcome back. '+$rootScope.daysLeft + ' more days of racing left in the month. You are ' + getMovingText(weekRank.delta) + ' your position and have ' + getMovedText(weekRank.delta)+' <span class="number">' +weekRank.delta +'</span> ranks versus last week.');
                                    }
                                    if (me.roleType == 'frontline' && teamAnalytic && teamRank) {
                                        var teamStart = getTractionText(teamAnalytic.swot);
                                        summary.push('Your team is showing ' + teamStart + ' traction and has ' + getMovedText(teamRank.delta) + ' <span class="number">' + Math.abs(teamRank.delta) + '</span> positions in ranking versus last week.');
                                    }
                                    if(weekGainers && weekLoosers && (weekGainers.length || weekLoosers.length)){
                                        summary.push('Remember, week 4 usually is the hottest week of the race. Temperatures are soaring. <span class="number">'+ weekGainers.length +'</span> players improved their standing'+ improved +' while <span class="number">'+ weekLoosers.length +'</span> ' +slipped +' slipped down.');
                                    }
                                    summary.push('A lot of action still to unfold. Rev on!');
                                    break;
                            }
                            return $q.resolve(summary);
                        });
                    });
                });
            }
        };

        function getStartText(swot){
            var start;
            switch (swot) {
                case 's':
                case 'o':
                    start = 'good';
                    break;
                case 'w':
                    start = 'soft';
                    break;
                case 't':
                    start = 'sluggish';
                    break;
            }
            return start;
        }

        function getTractionText(swot){
            var start;
            switch (swot) {
                case 's':
                case 'o':
                    start = 'strong';
                    break;
                case 'w':
                    start = 'moderate';
                    break;
                case 't':
                    start = 'soft';
                    break;
            }
            return start;
        }

        function getMovedText(delta){
            var movedText;
            if (delta > 0) {
                movedText = 'notched up';
            } else {
                movedText = 'gone down by';
            }
            return movedText;
        }
        function getMovingText(delta) {
            var movedText;
            if (delta > 0) {
                movedText = 'consolidating';
            } else {
                movedText = 'slipping from';
            }
            return movedText;

        }
    });

