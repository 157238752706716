'use strict';
angular.module('bitnudgeApp')
    .controller('activityExtraDataCtrl', function ($scope, $rootScope, user, data, kpi, $mdDialog,  Auth) {
        var me = Auth.getCurrentUser();
        $scope.onClose = function(){
            $mdDialog.hide();
        };
        data.tickets = [];
        data.value.forEach(function(ticket, index){
            data.tickets.push({id:index, ticket:ticket});
        })
        var name = user.name;
        $scope.data = data;
        $scope.header = name + ': '+ kpi.name;
    });
