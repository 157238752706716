'use strict';
angular.module('bitnudgeApp')
    .service('UserActivities', function ($http, $q, toaster) {

        let interactionTypes = null;
        let interactionIcons = {};
 
        this.getInteractionTypes = function () {
            if (interactionTypes) {
                return $q.resolve(interactionTypes);;
            } else {
                return $http.post('/api/interactions/types').then(function (result) {
                    if(result.data.status){
                        interactionTypes = result.data.data;
                        interactionTypes.forEach(function(type){
                            interactionIcons[type.internalId] = type.icon;
                        })
                        return interactionTypes;
                    }else{
                        toaster.pop('Interaction types', result.data.errors[0].message);
                        return null;
                    }
                   
                });
            }
        }

        this.getInteractionIcons = function(){
            return interactionIcons;
        }

        this.getInteractionHistory = function (user, cui, pagination, type) {
            let url = '/api/customers/getAllInteractionHistory';
            let postBody = {};
            if (pagination) {
                let offset = pagination.offset;
                let limit = pagination.limit;
                postBody.offset = offset;
                postBody.limit = limit;
            }
            if(type){
                postBody.type = type;
            }
            if(cui){
                postBody.cui = cui;
            }
            return $http.post(url, postBody).then(function (result) {
                if(result.data.status){
                    return result.data.interactions;
                }else{
                    toaster.pop('error','Interaction History', result.data.err);
                    return [];
                }
            },function(err){
                return err
            });
        }

        this.getInteractionHistoryCounts = function (user) {
            return $http.post('/api/interactions/counts', {userId:user._id }).then(function (result) {
                if(result.data.status){
                    return result.data;
                }else{
                    toaster.pop('Interaction Counts', result.data.errors[0].message);
                    return [];
                }
            });
        }
        
        this.getAllInteractionHistoryCounts = function(customer){
            return $http.post('/api/customers/getAllInteractionHistoryTypeCounts',{cui:customer.cui}).then(function(result){
                return result.data;
            }).catch(function(err){
                console.log(err);
            })
        }

    });
