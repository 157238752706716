'use strict';

angular.module('bitnudgeApp')
	.controller('userPortfolioTrendCtrl', function ($scope, $rootScope, p0, Auth, PortfolioService, UtilService, toaster, $window) {

		$scope.pieData = []
		let me = Auth.getCurrentUser();

		$scope.selected = {isReportees: false};
		$scope.products = _.cloneDeep(p0.myProducts)
		if ($rootScope.isFrontline) {
			$scope.selected.user = me;
		}
    $scope.selected.product = $scope.products[0];
    $scope.selected.kpiId = $scope.selected.product.mainKpi;


		$scope.onReporteeChange = function (user) {
      $scope.selected.user = user;
      $scope.selected.isReportees = false;
			init();
		}
		$scope.onPlayTypeChange = function (playType) {
			$scope.selected.playType = playType;
		}
		$scope.onProductChange = function (product) {
			$scope.selected.product = product;
      $scope.selected.kpiId = $scope.selected.product.mainKpi;
      prepareGraphData($scope.data);
		}

		$scope.onTimeRangeChange = function (range) {
      $scope.selected.range = range;
			$scope.selected.trend = $scope.selected.range.cycleTypes[0];
			fetchData()
		}
		$scope.onTrendChange = function (trend) {
			$scope.selected.trend = trend;
			fetchData()
		}

		function getTimeRange(monthType) {
			$scope.timeRange = UtilService.getTimeRange(monthType);
			$scope.selected.range = $scope.timeRange[0];
			$scope.selected.trend = $scope.selected.range.cycleTypes[0];
		}

		let cycleTypeCode;

		function init() {
			let userRole = $scope.selected.user.organizationalTag.jobRole;
			let monthTypeId = userRole.monthlyCycleTypeId;
			let cycleType = _.find(p0.monthlyCycleTypes, {
				_id: monthTypeId
			});

      //to avoid multiple  & duplicate function calls check this condition
			if (!cycleTypeCode || cycleTypeCode != cycleType.cycleTypeCode) {
				cycleTypeCode = cycleType.cycleTypeCode;
				getTimeRange(cycleType.cycleTypeCode)
			}
			fetchData();
    }
    
    $scope.onReporteeToggle = function(isReportees){
      fetchData();
    }

		function fetchData() {
      $scope.shoGraph = false;

			let userId = $scope.selected.user._id;
			let range = $scope.selected.range;
      let cycleType = $scope.selected.trend._id || 'daily';
      let isReportees = $scope.selected.isReportees;
			return PortfolioService.getTrend(userId, range, cycleType, isReportees).then(result => {
        result = result.data;
				if (result.status) {
          $scope.data = result.data;
					prepareGraphData($scope.data);
				} else {
					result.errors.forEach(error => {
						toaster.pop('error', error)
					})
				}
			}).catch(error => {
				console.log(error);
				toaster.pop('error', error.message)
			})
    }
    
    var getLabel = function (value) {
      var splits = value.split(' ');
      if ($scope.selected.trend._id === 'daily') {
          return splits[1] + ' ' + splits[2];
      } else if ($scope.selected.trend._id === 'weekly') {
          return value;
      }

      return value;
  };

  function getOptions(){
    $scope.lineOptions = {
        axisX: {
            labelOffset: {
                x: 0,
                y: 0
            },
            showGrid: true,
            labelInterpolationFnc: function (value) {
                return getLabel(value);
            }
        },
        axisY: {
            labelOffset: {
                x: 0,
                y: 0
            },
            showGrid: true,
            labelInterpolationFnc: function (value) {
                return parseFloat(value).toFixed(2);
            }
        },
        chartPadding: {
            top: 20,
            bottom: 10,
            left: (window.innerWidth * 10/100),
            right: 40
        },
        plugins: [
            Chartist.plugins.tooltip({
                appendToBody: true,
                tooltipFnc: function(meta, value) {
                    return meta + '<br>'+parseFloat(value).toFixed(2);
                },
            })
        ],
        width: (window.innerWidth * 80/100)+'px',
        height: (window.innerHeight * 70/100)+'px',
        showLine: true
    };
  }
		function prepareGraphData(usersData) {
      getOptions();
      $scope.lineData = {
        series:[],
        labels:[],
        seriesLegends:[]
      }
      $scope.table = {
        header:['cycle'],
        data:[],
      }
      let kpiId = $scope.selected.kpiId;
      _.forEach(usersData, (userData, $index) => {
        let series = []
        $scope.table.header.push(userData.name)
        $scope.lineData.seriesLegends.push({
          label : userData.name,
          series : String.fromCharCode(97 + $index)
        });
        if(!$index){
          $scope.lineData.labels = _.map(userData.trend, 'cycleName');
        }
        userData.trend.forEach(point => {
          series.push({meta:userData.name, value:point.portfolio[kpiId]})
        });
        $scope.lineData.series.push(series);       
      });
      $scope.showGraph = !!(usersData && usersData.length);
      for(let i=0; i< $scope.lineData.labels.length;i++){
        let data = [$scope.lineData.labels[i]];
        $scope.lineData.series.forEach(series => {
          let val = series[i];
          val = val ? val.value : ''
          data.push(val)
        });
        $scope.table.data.push(data);
      }
    }
    
    angular.element($window).bind('resize', function(){
      prepareGraphData($scope.data);
    });
    if($rootScope.isFrontline){
      init();
    }
	});
