'use strict';
angular.module('bitnudgeApp')
    .controller('burnStatementCtrl', function ($scope, p0, burnStatement,$rootScope) {
        $scope.selectedCycle = p0.currentMonthlyCycle;
        $scope.strings = $rootScope.strings;

        $scope.init = function(cycle){
            burnStatement.getData(cycle).then(function(data){
                $scope.data = data;
                $scope.balance = data.balance;
                if($scope.data.history[0]) {
                    $scope.openingBalance = {
                        points:$scope.data.history[0].balance.points - $scope.data.history[0].delta.points,
                        gems:$scope.data.history[0].balance.gems - $scope.data.history[0].delta.gems
                    };
                }else{
                    $scope.openingBalance = data.balance;
                }
            })
        };

        $scope.onCycleChange = function (cycle) {
            $scope.init(cycle);
        };

        $scope.init($scope.selectedCycle);

        $scope.config = {
            autoHideScrollbar: false,
            theme: 'dark',
            advanced:{
                updateOnContentResize: true
            },
                axis:'y',
                scrollInertia: 0
        };


        $scope.slickConfig= {
            enabled: true,
            autoplay: false,
            draggable: false,
            slidesToShow: 1,
            slidesToScroll: 1
        };
    });
