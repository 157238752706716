const autoImportNgTemplateLoaderTemplate1 = require('../html/cneNetworkCard.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('cneNetworkCard', function(){
        return {
            name: 'cneNetworkCard',
            scope: {
                heading: '@',
                users: '=',
                showLink: '@'
            },
            controller: 'cneNetworkCardCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
