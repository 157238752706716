'use strict';

const activityIndicatorCard = require('../html/basicActivityIndicatorCard.html');

angular.module('bitnudgeApp')
    .directive('basicActivityIndicatorCard', function(){
        return {
            name: 'basicActivityIndicatorCard',
            scope: {
                showSwotIcon:           '=',
                benchmarkValue:         '=',
                benchmark:              '=',
                achievedData:           '=',
                forecastMultiplier:     '=',
                isActive:               '=',
                selectedCycle:          '=',
                user:                   '=',
                lms:                    '=',
                enlarge:                '='
            },
            controller: 'basicActivityIndicatorCardCtrl',
            restrict: 'AE',
            templateUrl: function (elem,attr) {
                return activityIndicatorCard;
            }
        };
    });
