const autoImportNgTemplateLoaderTemplate1 = require('../html/admin/filterInfoModal.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('cneTabCtrl', function ($scope, $rootScope, $http, Auth, p0, dailyFeed) {
        //HACK: Unable to access $rootScope vars directly in the html, so copying to $scope. TODO: Look into this!
        $scope.config = $rootScope.config;
        $scope.me = Auth.getCurrentUser();
        $scope.isWeekly = false;
        $scope.isWeeklyDaily = false;
        $scope.currentMonthlyCycle = p0.currentMonthlyCycle;

        if($rootScope.isPlayer) {
            $scope.hideReportees = {reportee: true};
        }else{
            $scope.hideReportees = {reportee: false};
        }
        //Active attribute: Used only to set active tab depending on user type
        //active attribute value should be assignable
        $scope.isUserFrontline = $rootScope.isPlayer;


        if($scope.me.systemRole == 'frontline'){
            $scope.hideReportees = {reportee:false};
        }
        $scope.metric = {metric : 'Top Decile'};
        $scope.dailyFeedTimePeriods = [];
        if ($scope.me.systemRole == 'frontline') {
            $scope.leagues = ['bank', 'region', 'branch'];
        } else{
            $scope.leagues = ['bank', 'region'];
        }
        $scope.selectedLeague = {league:$scope.leagues[0]};
        //get relevant KPIs

        $scope.selectedCycle = p0.currentMonthlyCycle;
        $scope.dailyFeedTimePeriods.push({name:'Day', tabType:'daily', cycleName:p0.currentDailyCycle.name});
        $scope.dailyFeedTimePeriods.push({name:'Week', tabType:'weekly', cycleName:p0.currentWeeklyCycle.name});
        $scope.dailyFeedTimePeriods.push({name:'Month', tabType:'monthly', cycleName:p0.currentMonthlyCycle.name});
        $scope.selectedPeriod = {period:$scope.dailyFeedTimePeriods[0]};

        $http.post("api/kpis/getCurrent", {userId: $scope.me._id, cycleId:$scope.selectedCycle._id}).then(function(kpis) {
            kpis=kpis.data;
            $scope.kpis = kpis;
        });
        $scope.fetchedYet = {mine: false, reportees: false, role: {}};
        $scope.jobRoles = p0.myFrontlineRoles.concat(p0.myManagerRoles);
        $scope.jobRoles.forEach(function (e,i,l) {
           $scope.fetchedYet.role[e._id] = false;
        });
        $scope.selectedjobRole = {jobRole:$scope.jobRoles[0]};
        $rootScope.currentState = {tab: "Daily Feed", now: new Date()};

        $scope.getAllLeaf = function(){
            if($scope.me.roleType == 'frontline'){
                $scope.selectedReportee= {player:$scope.me};
                return;
            }
            const postBody = {
                userId: $scope.me._id,
                jobRole: $scope.selectedjobRole.jobRole._id
            }
            $http.post('/api/users/playerOfJobRole', postBody).then(function(reportees) {
                reportees=reportees.data;
                $scope.filteredReportees = reportees;
                $scope.filteredReportees.unshift({name: "All", _id:'all'});
                $scope.selectedReportee= {player:$scope.filteredReportees[0]};
            });
        };

        $scope.getAllLeaf();
        $scope.infoModal = {
            activeLeadsTotal: true,
            activeLeadsYetTo: true,
            hotLeadsTotal : true,
            hotLeadsYetTo: true,
            lossPotentialLost : true,
            lossPotentialRisk : true,
            followUpMissed2 : true,
            followUpPending : true,
        };
        $scope.openFilterInfoModal = function(){
            $scope.modalInstance = $mdDialog.open({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                scope:$scope
            });
        };
        $scope.onClose = function() {
            $scope.modalInstance.close();
        };
    });

