'use strict';
angular.module('bitnudgeApp')
    .controller('reassignLeadModalController', function ($scope,$uibModalInstance,users,selectedUser,leadId,$http,$state, $rootScope, p0) {
        $scope.strings = $rootScope.strings;
        $scope.closeModal = function() {
            $uibModalInstance.close();
        };
        $scope.users = users;
        $scope.selectedUser = selectedUser;

        $scope.changeFrontline = function(){
            const postBody = {
                leadId: lead._id,
                userId: $scope.selectedUser._id
            }
            $http.post('/api/leads/changeUser', postBody).then(function (lead) {
                lead = lead.data;
                if(p0.config.leadUI_v1){
                    $state.go('userFlow.editLead_v1',{customerId:lead.customerId,userId:$scope.selectedUser._id});
                }else{
                    $state.go('userFlow.editLead_v2',{customerId:lead.customerId,userId:$scope.selectedUser._id});
                }
                $uibModalInstance.close();
            });
        };
    });
