
'use strict';

angular.module('bitnudgeApp')
  .controller('filterCustomerCtrl', function ($scope, $rootScope, Auth, p0, $state, $stateParams, $mdDialog, filters, callback) {

  $scope.filterObj = filters;
  $scope.ageRange = $scope.filterObj.ageRange ? $scope.filterObj.ageRange._id : null
  $scope.lessThanCount = false;
  $scope.invalidProductCount = false;
  const nonCustomerProductCodes = ['P05', 'P06'];
  if($scope.filterObj.dnc){
    if($scope.filterObj.dnc.name == 'TRUE'){
      $scope.dncTag = true;
    }else{
      $scope.dncTag = false
    }
  }
  if ($scope.filterObj.exactCount === true || $scope.filterObj.exactCount === false) {
    $scope.lessThanCount = !$scope.filterObj.exactCount;
  }
  $scope.productCount = $scope.filterObj.productCount != null ? Number($scope.filterObj.productCount) : null;
  $scope.selectedStatus = $scope.filterObj.ticketStatus ? $scope.filterObj.ticketStatus._id : null
  $scope.statusDateRange = $scope.filterObj.statusDateRange ? $scope.filterObj.statusDateRange._id : null
  $scope.productsList = _.filter(p0.allProducts, function(product) {
    return product.isMainProduct === true && !nonCustomerProductCodes.includes(product.productCode);
  });
  if(p0.config.agentOnBoardingProduct){
    $scope.productsList = $scope.productsList.filter(p => p.productCode !== p0.config.agentOnBoardingProduct)
  }
  if($scope.filterObj.product){
    let product = _.find($scope.productsList, { '_id': $scope.filterObj.product._id});
    $scope.selectedProductId = product && product._id;
  }
  $scope.ageRangeOptions = [
    {_id:"0to25", from: 0, to: 25, name: "Below 25"},
    {_id:"26to35", from: 26, to: 35, name: "26 - 35"},
    {_id:"36to45", from: 36, to: 45, name: "36 - 45"},
    {_id:"46to55", from: 46, to: 55, name: "46 - 55"},
    {_id:"56to65", from: 56, to: 65, name: "56 - 65"},
    {_id:"66to75", from: 66, to: 75, name: "66 - 75"},
    {_id:"76to100", from: 76, to: 0, name: "76 & above"},
];

  $scope.dncOptions = [
      {_id:'true', name:'TRUE', value: true},
      {_id:'false', name:'FALSE', value: false},
  ];  
  
  $scope.ticketStatus = [
    {_id:'open', name:'Open'}
  ];

  $scope.dateRangeOptions = [
    {_id:"30", from: 0, to: 30, name: "Before 30 days"},
    {_id:"60", from: 0, to: 60, name: "60 days"},
    {_id:"120", from: 0, to: 120, name: "120 days"},
    {_id:"all", from: 0, to: 'all', name: "Above 120 days"}
];
  
  $scope.filterCustomersOnAge = function(key){
    if($scope.filterObj.ageRange && $scope.filterObj.ageRange._id == key){
      $scope.filterObj.ageRange = "";
      $scope.ageRange = null;
    } else {
        $scope.filterObj.ageRange = _.find($scope.ageRangeOptions, { '_id': key});
    }
  };

  $scope.filterCustomersOnDNC = function(key){
    if($scope.filterObj.state && $scope.ticketStateKey == key){
        $scope.filterObj.state = "";
        $scope.ticketStateKey = null;
    } else {
        $scope.filterObj.state = key;
        $scope.ticketStateKey = key;
    }
};


  $scope.dncTagChange = function(dnc){
    if(dnc){
      $scope.filterObj.dnc = _.find($scope.dncOptions, { 'name': 'TRUE'});
    }else{
      $scope.filterObj.dnc = _.find($scope.dncOptions, { 'name': 'FALSE'});
    }
  }

  $scope.filterCustomersOnProduct = function(key){
    if($scope.filterObj.product && $scope.filterObj.product._id == key){
      $scope.filterObj.product = "";
      $scope.selectedProductId = null;
    } else {
        $scope.filterObj.product = _.find($scope.productsList, { '_id': key});
    }
  };

  $scope.filterCustomersOnTicketStatus = function(key){
    if($scope.filterObj.ticketStatus && $scope.filterObj.ticketStatus._id == key){
      $scope.filterObj.ticketStatus = "";
      $scope.selectedStatus = null;
    } else {
        $scope.filterObj.ticketStatus = _.find($scope.ticketStatus, { '_id': key});
    }
  };

  $scope.filterCustomersOnTicketDate = function(key){
    if($scope.filterObj.statusDateRange && $scope.filterObj.statusDateRange._id == key){
      $scope.filterObj.statusDateRange = "";
      $scope.statusDateRange = null;
    } else {
        $scope.filterObj.statusDateRange = _.find($scope.dateRangeOptions, { '_id': key});
    }
  };

  $scope.setExactProductCount = function(){
    $scope.filterObj.exactCount = !$scope.lessThanCount;
  }

  $scope.setProductCount = function(productCount){
    $scope.invalidProductCount = _.isUndefined($scope.productCount) ? true : false;
    $scope.filterObj.productCount = productCount;
    $scope.productCount = productCount;
  }
    
  $scope.removeFilter = function(filter){
    switch(filter){
        case 'ageRange':
            $scope.ageRange = null;
            $scope.filterObj.ageRange = null;
            break;
        case 'dncTag':
            $scope.dncTag = false;
            $scope.filterObj.dnc = null;
            break;
        case 'product':
            $scope.selectedProductId = null;
            $scope.filterObj.product = null;
            break;
        case 'productCount':
            $scope.productCount = null;
            $scope.lessThanCount = null;
            $scope.filterObj.productCount = null;
            $scope.filterObj.exactCount = null;
            break;
        case 'ticketStatus':
            $scope.selectedStatus = null;
            $scope.filterObj.ticketStatus = null;
            break;
        case 'statusDateRange':
            $scope.statusDateRange = null;
            $scope.filterObj.statusDateRange = null;
            break;
        default:
            break;
    }
    // $scope.filterTickets();
  };

$scope.onClose = function(){
  $mdDialog.hide();
};

$scope.applyFilters = function(){
  callback($scope.filterObj);
  $mdDialog.hide();
}
  
});