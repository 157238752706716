const autoImportNgTemplateLoaderTemplate1 = require('../html/carouselSelect.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('carouselSelect', function () {
        return {
            name: 'carouselSelect',
            scope: {
                options:        '=',
                disable:       '=',
                model:          '=',
                onChange:       '=',
                field:          '@',
                type:           '@',
                maxDate:        '=',
                minDate:        '=',
                isDateFilter:   '=',
                search:         '=',
                label:          '@',
                color:          '@'
            },
            controller: 'carouselSelectCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
