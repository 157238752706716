const autoImportNgTemplateLoaderTemplate1 = require('../html/dailyDigest.html');

'use strict';
angular.module('bitnudgeApp')
    .component('dailyDigest', {
        templateUrl: autoImportNgTemplateLoaderTemplate1,
        bindings: {
        },
        controller: 'dailyDigestCtrl'
    });
