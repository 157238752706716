const autoImportNgTemplateLoaderTemplate1 = require('../html/cneTableCard.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('cneTableCard', function(){
        return {
            name: 'dashboardWidgetTable',
            scope: {
                headingLeft: '@',
                headingRight: '@',
                left: '=',
                right: '='
            },
            controller: 'cneTableCardCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
