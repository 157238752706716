'use strict';
angular.module('bitnudgeApp')
    .controller('moreDataValuesCtrl', function ($scope, p0) {

        var addedSeriesNames, selectedIndex;

        $scope.decrementCounter = function() {
            var newIndex = selectedIndex === 0 ? addedSeriesNames.length - 1 :  selectedIndex-1;

            if(newIndex !== selectedIndex){
                selectedIndex = newIndex;
                $scope.seriesName = addedSeriesNames[selectedIndex];
                calculateSeriesSummary();
                calculateSeriesData();
            }
        };

        $scope.incrementCounter = function() {
            var newIndex = selectedIndex === addedSeriesNames.length - 1 ? 0 :  selectedIndex+1;

            if(newIndex !== selectedIndex) {
                selectedIndex = newIndex;
                $scope.seriesName = addedSeriesNames[selectedIndex];
                calculateSeriesSummary();
                calculateSeriesData();
            }
        };

        $scope.$watch('summaryItems', function(newSeries){
            addedSeriesNames = _.map($scope.summaryItems[0].series, function(seriesObj){
                return seriesObj.name;
            });
            $scope.seriesName = addedSeriesNames[0];
            selectedIndex = 0;
            calculateSeriesSummary();
            calculateSeriesData();

        });

        function calculateSeriesData(){
            if(!$scope.isMoreDataCard){
                return;
            }
            var allCycles = $scope.selectedCycleType === 'monthly' ? p0.allMonthlyCycles : p0.allWeeklyCycles;

            if($scope.seriesName) {
                var seriesIndex = _.findIndex($scope.lineData.seriesLegends, {label: $scope.seriesName});
            }
            $scope.seriesData = _.map($scope.selectedCycles, function(cycleId, index){
                var mappedObj = {};
                mappedObj.name = _.find(allCycles, {_id : cycleId}).name;
                if(!$scope.hideUserValue){
                    mappedObj.userValue = $scope.lineData.series[0][index];
                    mappedObj.userValue = Number(mappedObj.userValue.toFixed(2));
                }
                if($scope.hideUserValue || (seriesIndex && seriesIndex >= 0)){
                    mappedObj.seriesValue = $scope.lineData.series[seriesIndex][index];
                    mappedObj.seriesValue = Number(mappedObj.seriesValue.toFixed(2));
                }
                return mappedObj;
            });
        }

        function calculateSeriesSummary(){
            $scope.summary = _.map($scope.summaryItems, function (item) {
                if(item.series && item.series.length > 0){
                    item.selectedSeriesValue = _.find(item.series, {name : $scope.seriesName}).value;
                }
                return item;
            });
        }
    });
