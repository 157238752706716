const autoImportNgTemplateLoaderTemplate1 = require('../html/admin/employee/editEmployee.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/admin/employee/listEmployee.html');
const autoImportNgTemplateLoaderTemplate3 = require('../html/admin/employee/addEmployee.html');
const autoImportNgTemplateLoaderTemplate4 = require('../html/admin/employee/addBulkEmployee.html');
const autoImportNgTemplateLoaderTemplate5 = require('../html/admin/employee/loginPrivileges.html');
const autoImportNgTemplateLoaderTemplate6 = require('../html/admin/employee/addBulkClonedEmployee.html');
const autoImportNgTemplateLoaderTemplate7 = require('../html/admin/employee/addBulkEmployeeJobRole.html');
const autoImportNgTemplateLoaderTemplate8 = require('../html/admin/employee/addParent.html');
const autoImportNgTemplateLoaderTemplate9 = require('../html/admin/employee/addReportee.html');

'use strict';

angular.module('bitnudgeApp')
    .config(function($stateProvider) {
        $stateProvider

            .state('lms.editEmployee', {
                url: '/editEmployee/:id',
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: function($stateParams, $scope, $http, $location,  toaster, Upload, Auth, p0) {
                    $http.post('/api/users/getUserById',{userId: $stateParams.id}).then(function(user) {
                        user=user.data;
                        $scope.user = user;
                    });
                    $scope.jobroles = p0.allJobRoles;
                    $scope.systemRole = ['manager','lmsAdmin','seniorManager','frontline'];
                    $scope.active = [true, false];
                    //regions
                    $http.post('/api/regions').then(function(regions) {
                        regions=regions.data;
                        $scope.regions = regions;
                    });
                    //teams
                    $http.post('/api/teams').then(function(teams) {
                        teams=teams.data;
                        $scope.allTeams = teams;
                        $scope.teams = teams;
                    });
                    //branches
                    $http.post('/api/branchs').then(function(branchs) {
                        branchs=branchs.data;
                        $scope.allBranchs = branchs;
                        $scope.branchs = branchs;
                    });
                    $scope.populateBranchAndTeam = function (region) {
                        $scope.branchs = _.filter($scope.allBranchs, function (branch) {
                            return (branch.regionId._id == region._id);
                        });
                        $scope.teams = _.filter($scope.allTeams, function (team) {
                            return (team.regionId == region._id);
                        });
                    }
                    $scope.updateUser = function() {
                        var data = [];
                        var name = $("#emp_name").val();
                        var address = $("#emp_addr").val();
                        var systemRole = $("#emp_role").val();
                        var joRole = $("#emp_jobrole").val();
                        var grade = $("#emp_designation").val();
                        var branch = $("#emp_branch").val();
                        var region = $("#emp_region").val();
                        var org_tagsData = {
                            region: region,
                            jobRole: joRole,
                            //                            grade: grade,
                            branch: branch
                        };

                        $http.post('/api/users/' + $stateParams.id, {
                            name: name,
                            address: address,
                            role: $scope.user.role,
                            organizationalTag: org_tagsData
                        }).then(function() {
                            $location.path('/employee/listEmployee');
                            toaster.pop('success', "Employee Update", "Employee Details Updated.");

                        }).catch(function() {});
                    };
                },
                authenticate: true
            })

            .state('lms.listEmployee', {
                url: '/listEmployee',
                templateUrl: autoImportNgTemplateLoaderTemplate2,
                controller:'listEmployeeCtrl'
            })
            .state('lms.addEmployee', {
                url: '/addEmployee',
                templateUrl: autoImportNgTemplateLoaderTemplate3,
                controller: 'addEmployeeCtrl'
            })
            .state('lms.addBulkEmployee', {
                url: '/addBulkEmployee',
                templateUrl: autoImportNgTemplateLoaderTemplate4,
                controller: 'addBulkEmployeeCtrl'
            })
            .state('lms.employeeLoginPrivileges', {
                url: '/loginPrivileges',
                templateUrl: autoImportNgTemplateLoaderTemplate5,
                controller: 'loginPrivilegesCtrl'
            })
            .state('lms.addBulkClonedEmployee', {
                url: '/addBulkClonedEmployee',
                templateUrl: autoImportNgTemplateLoaderTemplate6,
                controller: function($http, $scope, Auth, Upload, $location, toaster) {
                    $scope.fileType = "clonedUsers";
                    $scope.onFileSelect = function() {
                        //$files: an array of files selected, each file has name, size, and type.
                        var fileName = $scope.bulkEmployee[0].name.split(".");
                        var fileType = fileName[fileName.length - 1];
                        if (fileType == "xls" || fileType == "xlsx" || fileType == "xlsm" || fileType == "xlsb") {
                            Upload.upload({
                                url: '/api/upload',
                                data: {userId: Auth.getCurrentUser()._id, cronTag:'cloneUsr',invocationMethod:"manual"},
                                file: $scope.bulkEmployee[0],
                                progress: function (e) {
                                }
                            }).then(function (data, status, headers, config) {
                                // file is uploaded successfully
                                console.log(data);
                                toaster.pop('success', "Bulk Cloned Employee Creation.", "Bulk Cloned Employees Created.");
                            });
                        }else{
                            toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' Types Only.");
                        }
                    }
                }
            })
            .state('lms.addBulkJobRole', {
                url: '/addBulkEmployeeJobRole',
                templateUrl: autoImportNgTemplateLoaderTemplate7,
                controller: function($http, $scope, Auth, Upload, $location, toaster) {
                    $scope.onFileSelect = function($files) {
                        //$files: an array of files selected, each file has name, size, and type.
                        for (var i = 0; i < $files.length; i++) {
                            var $file = $files[i];
                            var fileName = $files[i].name.split(".");
                            var fileType = fileName[fileName.length - 1];
                            if (fileType == "xls" || fileType == "xlsx" || fileType == "xlsm" || fileType == "xlsb") {
                                Upload.upload({
                                    url: '/api/users/importJobRole/excel',
                                    file: $file,
                                    progress: function (e) {
                                    }
                                }).then(function (data, status, headers, config) {
                                    // file is uploaded successfully
                                    console.log(data);
                                    $location.path('/employee/listEmployee');
                                    toaster.pop('success', "Bulk Employee Creation.", "Bulk Employees Created.");
                                });
                            }else{
                                toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' Types Only.");
                            }
                        }
                    }
                }
            })
            .state('lms.addParent', {
                url: '/addParent',
                templateUrl: autoImportNgTemplateLoaderTemplate8,
                controller: function($scope, $http, Auth, $rootScope) {
                    $scope.strings = $rootScope.strings;
                    $scope.user = Auth.getCurrentUser();
                    $http.post('/api/users').then(function(users) {
                        users=users.data;
                        $scope.users = users;
                        //socket.syncUpdates('users', $scope.users);
                    });
                    $scope.addParent = function() {
                        console.log($scope.parents);
                        var data = [];
                        $scope.parents.forEach(function(parent, index) {
                            data.push({
                                parentId: parent._id
                            });
                        });
                        console.log(data);
                            data.id = $scope.selectedUser._id
                            $http.post('/api/users/addParent/user', data).then(function(res) {
                            res=res.data;
                            console.log(res);
                        });
                    }
                }
            })
            .state('lms.addReportees', {
                url: '/addReportees',
                templateUrl: autoImportNgTemplateLoaderTemplate9,
                controller: function($scope, $http, Auth, $rootScope) {
                    $scope.user = Auth.getCurrentUser();
                    $scope.strings = $rootScope.strings;
                    $http.post('/api/users').then(function(users) {
                        users=users.data;
                        $scope.users = users;
                        //socket.syncUpdates('users', $scope.users);
                    });
                    $scope.addReportees = function() {
                        console.log($scope.children);
                        var data = [];
                        $scope.children.forEach(function(child, index) {
                            data.push({
                                childId: child._id
                            });
                        });
                        console.log(data);
                        data.id = $scope.selectedUser._id;
                        $http.post('/api/users/addChildren/user', data).then(function(res) {
                            res=res.data;
                            console.log(res);
                        });
                    }
                }
            })
    })
    .directive('tooltip', function(){
        return {
            restrict: 'A',
            link: function(scope, element, attrs){
                $(element).hover(function(){
                    // on mouseenter
                    $(element).tooltip('show');
                }, function(){
                    // on mouseleave
                    $(element).tooltip('hide');
                });
            }
        };
    });;
