const autoImportNgTemplateLoaderTemplate1 = require('../html/chatPage/detailChat.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('detailChat', function(){
        return {
            name: 'detailChat',
            scope: {
                conversation: '=',
                refreshChat:  '=',
                textGroup:    '=',
                isAdmin:      '=',
                onGoBack:     '&',
                bottomPadding: '='
            },
            controller: 'detailChatController',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
