const autoImportNgTemplateLoaderTemplate1 = require('../html/scoreCard/scoreCard.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('scoreCard', function(){
        return {
            name: 'scoreCard',
            scope: {
                cardId:                 '=',
                benchmarkValue:         '=',
                benchmark:              '=',
                kpi:                    '=',
                forecastMultiplier:     '=',
                isActive:               '=',
                isSeniorManager:        '=',
                isActiveCycle:          '=',
                enlarge:                '=',
                compareValue:           '=',
                applyForecast:          '=',
                selectedUser:           '=',
                selectedCycle:          '=',
                selectedKpi:            '=',
                pipelineData:           '=',
                userportfolioData:      '=',
                toggleChild:           '=',
                onlyGauze: '='
            },
            controller: 'scoreCardCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
