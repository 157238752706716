angular.module('bitnudgeApp')
    .controller('lbJobroleConfigModalCtrl', function ($scope, Auth, $http, toaster, $mdDialog, jobRole, allJobRoles, preSelectedJobRoles, roleType, refreshFunction) {

        var calculateSomeSelected = function () {
            $scope.someSelected = Object.keys($scope.selectedJobroles).some(function (key) {
                return $scope.selectedJobroles[key];
            });
        };

        $scope.checkboxChanged = calculateSomeSelected;
        $scope.onClose = function () {
            $mdDialog.cancel();
        }

        $scope.submit = function () {
            var updatedJobRoleIds = []

            Object.keys($scope.selectedJobroles).forEach(function (key) {
                if ($scope.selectedJobroles[key]) {
                    updatedJobRoleIds.push(key);
                }
            });

            $http.post('/api/jobroles/setJobRolesConfig',{jobRoleId:jobRole._id, roleType, configJobRoleIds}).
                then(function () {
                    refreshFunction()
                    toaster.pop("success", 'Jobrole successfully updated');
                }).
                catch(function (err) {
                    console.log(err);
                    toaster.pop("Failure", 'Jobrole update failed');
                });
            $scope.onClose()
        }
        function init() {
            //remove current role from all roles
            _.remove(allJobRoles, function (role) {
                return role._id == jobRole._id;
            })
            $scope.selectedJobroles = {}
            if (preSelectedJobRoles) {
                preSelectedJobRoles.forEach(function (role) {
                    $scope.selectedJobroles[role._id] = true;
                })
            }
            $scope.allJobRoles = allJobRoles;
            calculateSomeSelected()
        }

        //init function
        init()
    });

