const autoImportNgTemplateLoaderTemplate1 = require('../html/kaiSwot.html');

'use strict';
angular.module('bitnudgeApp')
    .component('kaiSwot', {
        bindings: {
            cycleId: '<',
            parent: '<',
            type: '<',
            division: '<',
            playType: '<',
            kpi: '<',
            kai:'<',


        },
        templateUrl: autoImportNgTemplateLoaderTemplate1,
        controller: function ($scope, p0, SwotService) {
            $scope.strings = p0.strings;
            $scope.switch = {isSelf: false};
            $scope.swot = {
                s:[],
                o:[],
                w:[],
                t:[]
            };

            this.$onChanges = (changes) => {
                if (changes.parent) {
                    $scope.parent = changes.parent.currentValue;
                    $scope.switch.isSelf = $scope.parent.systemRole == 'frontline';
                }
                if (changes.type) {
                    $scope.type = changes.type.currentValue;
                }
                if (changes.cycleId) {
                    $scope.cycleId = changes.cycleId.currentValue;
                }
                if (changes.division) {
                    $scope.division = changes.division.currentValue;
                }
                if (changes.playType) {
                    $scope.playType = changes.playType.currentValue;
                }
                if (changes.kpi) {
                    $scope.kpi = changes.kpi.currentValue;
                }
                if (changes.kai) {
                    $scope.kai = changes.kai.currentValue;
                }
                init();
            };
            $scope.onSwitchChange = function(){
                init();
            };

            function init(){
                let userId = $scope.parent._id;
                let cycleId = $scope.cycleId;
                let kpiId = $scope.kpi._id;
                let kaiId = $scope.kai._id;
                let product = _.find(p0.allProducts,{mainKpi: kpiId});
                let productId = product ? product._id : null;
                let jobRoles = $scope.playType.frontline;

                return SwotService.getReporteeKaiSwot(userId, cycleId, kpiId, kaiId, productId, jobRoles, $scope.switch.isSelf).then(function(result){
                    $scope.swot = result;
                }).catch(() => {
                    $scope.swot = {
                        s:[],
                        o:[],
                        w:[],
                        t:[]
                    }
                });
            }
        }

    });
