'use strict';
angular.module('bitnudgeApp')
    .service('fileService', function ($http, $q) {
		this.markFileInactive = (fileId) => {
			const deferred = $q.defer();
			const url = '/api/uploadmanager/file';
			$http.post(url, {fileId})
				.then(result => {
					deferred.resolve(result.data);
				})
				.catch(err => {
					deferred.reject(err.errors[0]);
				});
				return deferred.promise;
		};
		this.getThumbnailData = function(){
            return $http.post('/api/uploadmanager/document', {isThumbnailRequired:true})
		};
		
    });
