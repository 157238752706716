const autoImportNgTemplateLoaderTemplate1 = require('../html/createPlayerGroupModal.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/chatPage/chatPlayerList.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('chatPageController', function ($stateParams, $state, $scope, $http, Auth, SocketService, $anchorScroll, $location, $timeout, $filter, $document, $mdDialog, $rootScope) {

        let self = this;

        this.init = function(){
            $scope.context='default';
            $scope.currentUser = Auth.getCurrentUser();
            $scope.isPlayers = false;
            $scope.searchOff = true;
            $scope.totalUnreadMessages = 0;
            self.stateChanged($state.current);
            $scope.getConversationHistory();
            $scope.searchPlaceHolder = 'Search contacts/conversations';
            $scope.isActive=[];
            $scope.isAdmin = false;
            return $http.post('/api/users').then(function(response){
               var users=response.data.data;
                users = _.filter(users, function(user){
                    return user._id.toString() != $scope.currentUser._id.toString()});
                $scope.filteredUsers = users;
                $scope.allUsers =  users;
            });
        }


        $rootScope.$on('$stateChangeStart',
            function(event, toState, toParams, fromState, fromParams){
                self.stateChanged(toState);
            });


        this.stateChanged = function(state){
            if(state.name == 'userFlow.profile' || state.name == 'userFlow.newsAndUpdates'){
                $scope.bottomPadding = true;
            }else{
                $scope.bottomPadding = false;
            }
        }

        this.scrollTo = function (index){
            $timeout(function() {
                var old = $location.hash();
                $location.hash(index);
                $anchorScroll();
                $location.hash(old);
            });
        };

        $scope.showPlayers = function(){
            $scope.isPlayers = true;
        };

        $scope.backToChatList = function(){
            $scope.isPlayers = false;
            $scope.searchContacts='';
        };

        $scope.getConversationHistory = function(isSelect){
            return $http.post('/api/chatGroups/conversationsWeb', {userId: $scope.currentUser._id}).then(function(conversations){
                conversations=conversations.data;
                $scope.groups=[];
                var validConversations=[];
                $scope.totalUnreadMessages = conversations.data ? conversations.data.totalUnreadMessages : 0;

                _.forEach(conversations, function(conversation, index){
                    if(conversation) {
                        //Unread Messages
                        if(conversation.owner.toString()  == $scope.currentUser._id.toString())
                            conversation.isOwner = true;
                        else
                            conversation.isOwner = false;
                        var loggedInUser = _.find(conversation.users, function (user) {
                            return (user.userId && user.userId._id.toString() == $scope.currentUser._id.toString())
                        });
                        if(!loggedInUser){
                            return;
                        }
                        if(conversation.messages.length){
                            conversation.time = conversation.messages[conversation.messages.length-1].time;
                            if(conversation.messages[conversation.messages.length-1].senderId.toString()==$scope.currentUser._id.toString())
                                conversation.unReadMessages=0;
                        }
                        //Pinned Groups
                        if(conversation.isGroup && !loggedInUser.isArchived){
                            if(self.validGroups(conversation.ownerType, $scope.currentUser.systemRole))
                                $scope.groups.push(conversation)
                        }
                        //Opponent Image and filter users
                        if(!conversation.isGroup){
                            var opponentUser =  _.find(conversation.users, function(user){
                                return user.userId._id.toString() != $scope.currentUser._id.toString()
                            });

                            conversation.name = opponentUser.userId.name;
                            conversation.image = opponentUser.userId.image;

							if(!conversation.image) conversation.image = require('../images/group.jpg');

                            $scope.filteredUsers = _.filter($scope.filteredUsers, function(el) {
                                return el && conversation && el.name.toString() != conversation.name.toString();
                            });
                        }
                        //Messages
                        var messages  = [];
                        if(conversation.isGroup){
                            _.forEach(conversation.messages, function(obj){
                                if(new Date(obj.time).getTime()>= new Date(loggedInUser.time).getTime() && obj.type === "message")
                                    messages.push(obj);
                            });
                            conversation.messages = messages;
                            if(!conversation.image) conversation.image = require('../images/group.jpg');
                        }
                        //Archivex
                        if(loggedInUser.isArchived)
                            conversation.isArchived = true;
                        else
                            conversation.isArchived = false;
                        validConversations.push(conversation);
                    }
                });
                $scope.conversations = validConversations.reverse();
                $scope.conversations = $scope.conversations.filter(function (conv) {
                    return conv.isArchived != true;
                });
                $scope.conversations = $filter('orderBy')($scope.conversations, '-time');
                if(isSelect){
                    $scope.isActive=[];
                    $scope.selectedConversation = $scope.conversations[0];
                    $scope.isActive[0] = 'active';
                }
             });
        };


        this.validGroups= function(role, currentRole){
            if(currentRole == role)
                return false;
            else{
                if(role == 'frontline' && currentRole == 'manager')
                    return false;
                else if(currentRole == 'frontline' && (role == 'manager'|| role == 'seniorManager'))
                    return true;
                else if(currentRole == 'manager' && role == 'seniorManager')
                    return true;
                else if(role == 'lmsAdmin')
                    return true;
                else
                    return false;
            }
        };

        $scope.selectConversation = function(conversation, index, fromSearch){
            _.forEach(conversation.users, function(user){
                if(user.userId && (user.userId._id.toString() == $scope.currentUser._id.toString())){
                    if(conversation.unReadMessages)
                        $scope.totalUnreadMessages -= conversation.unReadMessages;
                    conversation.unReadMessages = 0;
                    conversation.messageReadIndex = conversation.messages.length;
                }
            });
            if(conversation.owner && (conversation.owner.toString() == $scope.currentUser._id))
                $scope.isAdmin = true;
            else{
                $scope.isAdmin = false;
                $scope.isPlayers = false;
                $scope.isActive = [];
            }
            $scope.isActive=[];
            $scope.isActive[index] = 'selectedActive';
            $scope.selectedConversation= conversation;

            if(conversation.isArchived && fromSearch && fromSearch != ''){
                $scope.selectedConversation.isArchived = false;
                const postBody = {
                    conversationId: conversation._id ,
                    status: 'unarchive'
                }
                $http.post('/api/chatGroups/archiveConversation', postBody).then(function(){});
            }

            if($scope.isAdmin && conversation.isGroup)
                $scope.textGroup = 'Delete Group';
            else if(!$scope.isAdmin && conversation.isGroup)
                $scope.textGroup = 'Leave Group';

            var messagesLength = conversation.messages.length;

            self.scrollTo(conversation.messages.length-1);
            $scope.searchConversations= '';
            const postBody = {
                conversationId: conversation._id,
                messagesLength
            }
            return $http.post('/api/chatGroups/updateReadMessages', postBody).then(function(chat){});
            //$scope.getConversationHistory();
        };

        $scope.hoverChats = function(index){
            $scope.isActiveHover=[];
            $scope.isActiveHover[index] = 'active';
        }

        $scope.hoverOutChats = function(){
            $scope.isActiveHover=[];
        }

        $scope.selectPlayer = function(player){
            $scope.selectedConversation= player;
            var users=[$scope.currentUser._id,player._id];
            $scope.isPlayers = false;
            $scope.isActive = [];
            $scope.searchConversations= '';
            return $http.post('/api/chatGroups/privateChat',{users}).then(function(chat){
                chat=chat.data;
                if(chat.length>0){
                    $scope.selectedConversation = chat[0];
                    $scope.selectedConversation.name = player.name;
                }
            });
        };

        SocketService.getSocket().then(function(socket) {
            $scope.createOrEditGroupModal = function(selectedGroup){
                $mdDialog.show({
                    templateUrl: autoImportNgTemplateLoaderTemplate1,
                    controller: 'createPlayerGroupModalController',
                    hasBackdrop: false,
                    parent: document.getElementById('groupModal'),
                    resolve: {
                        refreshChat: function(){
                            return $scope.getConversationHistory
                        },
                        selectedGroup: function(){
                            return selectedGroup;
                        },
                        users: function(){
                            return $scope.allUsers;
                        },
                        bottomPadding: function () {
                            return $scope.bottomPadding;
                        },
                        onModalClose: function () {
                            return modalCloseCallback;
                        }
                    }
                });
            };
            var modalCloseCallback = function (groupImage) {
                // $scope.conversation.image = groupImage;
            };
            socket.on('message', function(msg){
                if (msg && msg.message.senderId.toString() !== $scope.currentUser._id.toString()) {
                    var title = "BitNudge Chat";
                    var option = {
                        body: msg.message.senderName + ': ' + msg.message.msg,
                        noscreen: true,
                        icon: msg.image
                    };
                    _.forEach($scope.conversations, function (conversation, index) {
                        if (msg._id.toString() == conversation._id.toString()) {
                            $scope.conversations[index].messages.push(msg.message);
                            var chatWebNotification = new Notification(title, option);
                            chatWebNotification.close.bind(chatWebNotification);
                        }
                    });
                    $scope.getConversationHistory(true);
                }
            });

            socket.on('group', function(users){
                _.forEach(users, function(obj){
                    if(obj.id.toString() == $scope.currentUser._id.toString())
                        $scope.getConversationHistory(true);
                });
            });

            socket.on('editedGroup', function(users){
                _.forEach(users, function(obj){
                    if(obj.id.toString() == $scope.currentUser._id.toString()){
                        $scope.getConversationHistory(true);
                    }
                });
            });
        });

        $scope.selectGroup = function(group){
            $scope.getConversationHistory();
            $scope.selectedConversation = group;
            $scope.isActive=[];
            var index = _.indexOf($scope.conversations, $scope.selectedConversation)
            $scope.isActive[index] = 'active';
            self.scrollTo(group.messages.length-1)
        };

        $scope.showPlayerList = function(conversation){
            $mdDialog.open({
                templateUrl: autoImportNgTemplateLoaderTemplate2,
                controller: 'chatPlayersListModalCtrl',
                size: 'md',
                resolve: {
                    playerList: function () {
                        return conversation;
                    }
                }
            });
        };

        $scope.showConversation = function(param1, param2){
            if(!param2 && !param1.isArchived)
                return true;
            else if(param2)
                return true;
        };
        $scope.clearSelects = function ($event){
            if ($event.keyCode == 27) {
                $scope.context = 'default';
            }
        };
        $scope.onGoBack= function(){
            var found = _.find($scope.conversations, {_id:$scope.selectedConversation._id});
            if(!found && $scope.selectedConversation.messages){
                $scope.conversations.unshift($scope.selectedConversation);
            }
            $scope.selectedConversation=null;
            $scope.context='default';
        };
        $scope.beginSearch = function () {
          $scope.searchConversations = $scope.tempQuery;
        };

        this.init();
    });
