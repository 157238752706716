"use strict";

angular.module("bitnudgeApp").service("CustomerService", function($http, $q, p0, Auth, UtilService) {
	var me;
	var appUrl = p0.config.domain;
	var subfolder = p0.config.subfolder;
	let self = this;
	if (subfolder) {
		if (appUrl[appUrl.length - 1] !== "/") {
			appUrl += "/";
		}
		if (subfolder[0] == "/") {
			subfolder = subfolder.slice(1);
		}
		appUrl += subfolder;
	}

	this.getPaginationObj = function() {
		var pagination = {
			limit: 50,
			page: 1,
			total: 0,
			offset: 0,
			disableNext: true,
			disablePrevious: true
		};
		return pagination;
	};
	this.init = function() {
		me = Auth.getCurrentUser();
	};

	this.getCustomers = function(userId, param, options, sortingData, customersView, externalParams) {
		return this.getCustomerData(userId, param, options, sortingData, customersView,externalParams);
	};

	this.getCustomerData = function(userId, param, options, sortingData,customersView, externalParams) {
		var offset = param.offset;
		var limit = param.limit;
		var sortKey = sortingData && sortingData.sortKey;
		var sortOrder = sortingData && sortingData.sortOrder;
		const params = {
			userId,
			offset,
			limit,
			sortKey,
			sortOrder,
			...options
		}

		if(customersView && customersView.unassigned){
			params.unassignedView = customersView.unassigned;
		}
		console.log('externalParams customerService: ', externalParams);
		if (!_.isEmpty(externalParams)) {
			params.externalParams = JSON.stringify(externalParams)
		}
		var deferred = $q.defer();
		$http.post("/api/customers/list", params).then(
			result => {
				deferred.resolve(result.data);
			},
			error => {
				console.log(error);
				deferred.reject(error);
			}
		);
		return deferred.promise;
	};

	this.getCustomerById = function(customerId, isDownload, sendValidationTimeline) {
		var deferred = $q.defer();
		var customer;
		if (isDownload) {
			if (!appUrl || appUrl == undefined) {
				appUrl = "";
			}
			var baseUrl = appUrl + "/api/downloaCustomerByCui";
			const params = {
				isDownload,
				cui: customerId,
				offset:0,
				limit:0
			}
			UtilService.downloadFile(baseUrl, params);
		} else {
			$http.post("/api/customers/show",{cui:customerId, sendValidationTimeline} ).then(
				function(result) {
					if(result.data.status){
						const {customer} = result.data;
						deferred.resolve(customer);
					}else{
						deferred.reject("no customer found in system");
					}
				},
				function(error) {
					deferred.reject(null);
				}
			);
			customer = deferred.promise;
			return $q.when(customer);
		}
	};
	this.updateCustomer = function(customer) {
		var source = {
			mode: "form",
			original: "bn",
			device: "web",
			actionBy: me._id
		};
		var data = {
			customer: customer,
			source: source
		};
		return $http
			.post("/api/customers/updateCustomer", data)
			.then(function(result) {
				if (result.data.status) return result.data.data;
				else return result.errors;
			})
			.catch(function(err) {
				return err;
			});
	};

	this.fetchTicketsOfCustomer = function(cui) {
		return $http
			.post("api/customers/tickets",{ cui})
			.then(function(result) {
				return result.data;
			})
			.catch(function(err) {
				console.log(err);
			});
	};
	//not in use, new end point built in document service
	// this.fetchDocuments = function(cui) {
	// 	return $http
	// 		.get("api/customers/" + cui + "/documents")
	// 		.then(function(result) {
	// 			return result.data;
	// 		})
	// 		.catch(function(err) {
	// 			console.log(err);
	// 		});
	// };
	this.archiveDocument = function(docId, reason) {
		return $http
			.post(`/api/documents/delete`, {documentId: docId, reason})
			.then(function(result) {
				return result.data;
			})
			.catch(function(err) {
				console.log(err);
			});
	};
	this.updateDocument = function(data) {
		return $http
			.post("/api/documents/update/", data)
			.then(function(result) {
				return result.data;
			})
			.catch(function(err) {
				console.log(err);
			});
	};
	this.getDocument = function(docId) {
		return $http.post("/api/documents/getDocumentContent",{documentId:docId}, {
				responseType: "arraybuffer"
			})
			.then(function(result) {
				return result.data;
			})
			.catch(function(err) {
				console.log(err);
			});
	};

	this.getCustomerOverview = function(cui) {
		return $http
			.post("/api/customers/customerOverview", { cui})
			.then(function(result) {
				return result.data;
			})
			.catch(function(err) {
				console.log(err);
			});
	};
	this.getExcelHeader = function(param) {
		return $http
			.post("/api/customers/getExcelHeader",{type: param})
			.then(function(result) {
				return result.data;
			})
			.catch(function(err) {
				console.log(err);
			});
	};
	this.getUserById = function(userId) {
		return $http.post("api/users/getUserById",{ userId}).then(function(user) {
			return user.data;
		});
	};

	this.getAllProductMetadata = function() {
		return $http.post("/api/products/subproducts").then(function(user) {
			return user.data;
		});
	};

	this.generateOTP = function(cui) {
		return $http.post("/api/customers/generateOTP",{cui}).then(function(result) {
			return result.data;
		});
	};

	this.validateOTP = function(cui, otp) {
		return $http.post("/api/customers/validateOTP",{cui, reqOtp: otp}).then(function(result) {
			return result.data;
		});
	};

	this.downloadCustomers = function(user, filterObj, pagination, sortingData, customerFilters) {
		if (!appUrl || appUrl == undefined) {
			appUrl = "";
		}

		var baseUrl = appUrl + "/api/customers/list?";
		var paramData = {
			userId: user._id,
			ageFrom: filterObj.ageRange ? filterObj.ageRange.from : null,
			ageTo: filterObj.ageRange ? filterObj.ageRange.to : null,
			minDeposit: filterObj.minDeposit ? filterObj.minDeposit : null,
			maxDeposit: filterObj.maxDeposit ? filterObj.maxDeposit : null,
			dnc: filterObj.dnc ? filterObj.dnc.value && filterObj.dnc._id : null, // only in dnc true case send the dnc param
			offset: pagination.offset,
			limit: pagination.limit ? pagination.limit : 50,
			download: true,
			sortKey: sortingData && sortingData.sortKey,
			sortOrder: sortingData && sortingData.sortOrder,
			unassignedView: customerFilters && customerFilters.unassigned
		};

		if(filterObj.name && filterObj.name.length){
			paramData.searchParam = filterObj.name;
		}
		if(filterObj.product){
			paramData.productCode = filterObj.product.productCode;
		}
		if(!_.isUndefined(filterObj.productCount) && !_.isNull(filterObj.productCount)){
			paramData.productCount = filterObj.productCount;
		}
		if(filterObj.exactCount === false || filterObj.exactCount === true){
			paramData.exactCount = filterObj.exactCount;
		} else {
			paramData.exactCount=true; //default value of exactCount is true
		}
		if(filterObj.ticketStatus){
			paramData.ticketStatus= filterObj.ticketStatus._id;
		}
		if(filterObj.statusDateRange){
			paramData.statusDateRange = filterObj.statusDateRange._id;
		}
		if (filterObj.all) {
			paramData.limit = 0;
		}

		UtilService.downloadFile(querystring, paramData);
	};

	this.init();
});
