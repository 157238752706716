'use strict';
angular.module('bitnudgeApp')
    .service('cneActivityIndicator', function ($http ,Auth,p0,$rootScope) {
        var importantActivities = ['call','meeting','submission','leadCreation'];
        var currentUser = Auth.getCurrentUser();
        var _this = this;
        _this.monthlyData = {};
        _this.weeklyData = {};
        _this.monthlyMetricData = {};
        _this.weeklyMetricData = {};
        this.getData = function(kpiId, callback){
            let postData = {
                userId: currentUser._id,
                cycleId: p0.currentMonthlyCycle._id,
                kpiId
            }
            return $http.post('/api/analytics/getKpiActivityIndicatorsData', postData).then(function(mdata){
                mdata=mdata.data;
                importantActivities.forEach(function(key){
                    mdata[key].rate = $rootScope.daysPassed? mdata[key].value.value / $rootScope.daysPassed: mdata[key].value.value;
                    mdata[key].pointRate = $rootScope.daysPassed? mdata[key].points.value / $rootScope.daysPassed: mdata[key].points.value;

                    mdata[key].rateDelta = mdata[key].value.delta / 20;
                    mdata[key].pointRateDelta = mdata[key].points.delta / 20;
                    mdata[key].heading = getActivityName(key);
                });
                _this.monthlyData[kpiId] = mdata;
                let postData = {
                    userId: currentUser._id,
                    cycleId: p0.currentWeeklyCycle._id,
                    kpiId
                }
                return $http.post('/api/analytics/getKpiActivityIndicatorsData', postData).then(function(wdata){
                    wdata=wdata.data;
                    importantActivities.forEach(function(key){
                        wdata[key].rate = $rootScope.weeklyDaysPassed? wdata[key].value.value / $rootScope.weeklyDaysPassed: wdata[key].value.value;
                        wdata[key].pointRate = $rootScope.weeklyDaysPassed? wdata[key].points.value / $rootScope.weeklyDaysPassed: wdata[key].points.value;

                        wdata[key].rateDelta = wdata[key].value.delta / 5;
                        wdata[key].pointRateDelta = wdata[key].points.delta / 5;
                    });
                    importantActivities.forEach(function(key){
                        wdata[key].timeToAct = mdata[key].timeToAct;
                        wdata[key].winRate = mdata[key].winRate;
                        wdata[key].heading = getActivityName(key);
                    });
                    _this.weeklyData[kpiId] = wdata;
                    return callback();
                });
            });
        };

        this.getMetricData = function(kpiId,metric,callback){
            // Selected User (Peer / Opponent)
            if(typeof metric == 'object'){
                let postData = {
                    userId: metric._id,
                    cycleId: p0.currentMonthlyCycle._id,
                    kpiId
                }
                return $http.post('/api/analytics/getKpiActivityIndicatorsData', postData).then(function(mdata){
                    mdata=mdata.data;
                    importantActivities.forEach(function(key){
                        mdata[key].rate = $rootScope.daysLeft? mdata[key].value.value / $rootScope.daysLeft: mdata[key].value.value;
                        mdata[key].pointRate = $rootScope.daysLeft? mdata[key].points.value / $rootScope.daysLeft: mdata[key].points.value;

                        mdata[key].rateDelta = mdata[key].value.delta / 20;
                        mdata[key].pointRateDelta = mdata[key].points.delta / 20;
                    });
                    _this.monthlyMetricData[kpiId] = mdata;
                    let postData = {
                        userId: metric._id,
                        cycleId: p0.currentWeeklyCycle._id,
                        kpiId
                    }
                    return $http.post('/api/analytics/getKpiActivityIndicatorsData', postData).then(function(wdata){
                        wdata=wdata.data;
                        importantActivities.forEach(function(key){
                            wdata[key].rate = $rootScope.weeklyDaysLeft? wdata[key].value.value / $rootScope.weeklyDaysLeft: wdata[key].value.value;
                            wdata[key].pointRate = $rootScope.weeklyDaysLeft? wdata[key].points.value / $rootScope.weeklyDaysLeft: wdata[key].points.value;

                            wdata[key].rateDelta = wdata[key].value.delta / 5;
                            wdata[key].pointRateDelta = wdata[key].points.delta / 5;
                        });
                        importantActivities.forEach(function(key){
                            wdata[key].timeToAct = mdata[key].timeToAct;
                            wdata[key].winRate = mdata[key].winRate;
                        });
                        _this.weeklyMetricData[kpiId] = wdata;
                        return callback();
                    });
                });
            }
            // Top Decile, Bank Average, Region Average, Branch Average
            else if(metric != 'Previous Period'){
                return $http.post('/api/analytics/getKpiActivityIndicatorsMetricData',{cycleId: p0.currentMonthlyCycle._id, kpiId, type:metric}).then(function(mdata){
                    mdata=mdata.data;
                    importantActivities.forEach(function(key){
                        mdata[key].rate = $rootScope.daysLeft? mdata[key].value.value / $rootScope.daysLeft: mdata[key].value.value;
                        mdata[key].pointRate = $rootScope.daysLeft? mdata[key].points.value / $rootScope.daysLeft: mdata[key].points.value;

                        mdata[key].rateDelta = mdata[key].value.delta / 20;
                        mdata[key].pointRateDelta = mdata[key].points.delta / 20;
                    });
                    _this.monthlyMetricData[kpiId] = mdata;

                    return $http.post('/api/analytics/getKpiActivityIndicatorsMetricData', {cycleId: p0.currentMonthlyCycle._id, kpiId, type:metric} ).then(function(wdata){ // Getting monthly Data until we get weekly data
                        wdata=wdata.data;
                        importantActivities.forEach(function(key){
                            wdata[key].rate = $rootScope.weeklyDaysLeft? wdata[key].value.value / $rootScope.weeklyDaysLeft: wdata[key].value.value;
                            wdata[key].pointRate = $rootScope.weeklyDaysLeft? wdata[key].points.value / $rootScope.weeklyDaysLeft: wdata[key].points.value;

                            wdata[key].rateDelta = wdata[key].value.delta / 5;
                            wdata[key].pointRateDelta = wdata[key].points.delta / 5;
                        });
                        importantActivities.forEach(function(key){
                            wdata[key].timeToAct = mdata[key].timeToAct;
                            wdata[key].winRate = mdata[key].winRate;
                        });
                        _this.weeklyMetricData[kpiId] = wdata;
                        return callback();
                    });
                });

            // Previous Period
            }else{
                let postData = {
                    userId: currentUser._id,
                    cycleId: p0.currentMonthlyCycle.previousCycleId,
                    kpiId
                }
                return $http.post('/api/analytics/getKpiActivityIndicatorsData', postData).then(function(mdata){
                    mdata=mdata.data;
                    importantActivities.forEach(function(key){
                        mdata[key].rate = $rootScope.daysLeft? mdata[key].value.value / $rootScope.daysLeft: mdata[key].value.value;
                        mdata[key].pointRate = $rootScope.daysLeft? mdata[key].points.value / $rootScope.daysLeft: mdata[key].points.value;
                    });
                    _this.monthlyMetricData[kpiId] = mdata;
                    let postData = {
                        userId: currentUser._id,
                        cycleId: p0.currentWeeklyCycle.previousCycleId,
                        kpiId
                    }
                    return $http.post('/api/analytics/getKpiActivityIndicatorsData', postData).then(function(wdata){
                        wdata=wdata.data;
                        importantActivities.forEach(function(key){
                            wdata[key].rate = $rootScope.weeklyDaysLeft? wdata[key].value.value / $rootScope.weeklyDaysLeft: wdata[key].value.value;
                            wdata[key].pointRate = $rootScope.weeklyDaysLeft? wdata[key].points.value / $rootScope.weeklyDaysLeft: wdata[key].points.value;
                        });
                        importantActivities.forEach(function(key){
                            wdata[key].timeToAct = mdata[key].timeToAct;
                            wdata[key].winRate = mdata[key].winRate;
                        });
                        _this.weeklyMetricData[kpiId] = wdata;
                        return callback();
                    });
                });
            }

        }
        function getActivityName(activity){
            switch(activity){
                case 'call':
                    return 'Calls';
                case 'meeting':
                    return 'Meetings';
                case 'submission':
                    return 'Submissions';
                case 'leadCreation':
                    return 'Leads';
            }
        }
    });
