const autoImportNgTemplateLoaderTemplate1 = require('../html/swotCard.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('swotCard', function () {
        return {
            name: 'swotCard',
            scope: {
                zone: '@',
                list: '=',
                type: '@',
				label: '@',
                onClick: '=',
                showBgColor:    '='
            },
            controller: 'swotCardCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
