const autoImportNgTemplateLoaderTemplate1 = require('../html/dateFilters.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('dateFilters', function () {
        return {
            name: 'dateFilters',
            scope: {
                selectedCycle:  '=',
                showWeeklyCycle:  '=',
                showDailyCycle: '=',
                onChange:       '=',
                showOneMonth: '=',
                color:      '@',
                selectedRole:  '=',
                selectedPlayType: '='
            },
            controller: 'dateFiltersCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
