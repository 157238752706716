'use strict';

angular.module('bitnudgeApp')
  .controller('userPortfolioPieCtrl', function ($scope, $mdDialog, products, p0, portfolioData) {

    $scope.pieData = []
    $scope.unit = p0.config.CURRENCY_UNIT || "IDR";
    var total = 0;
    Object.keys(portfolioData.portfolio).forEach( function(id){
        if(portfolioData.portfolio[id].value){
            var pro = _.find(products,{mainKpi:id})
            var pieObj = {
                value : portfolioData.portfolio[id].value.toFixed(2),
                key : pro.name
            }
            total += parseInt(portfolioData.portfolio[id].value.toFixed(2))
            $scope.pieData.push(pieObj)
        }
    })
    _.forEach($scope.pieData, function(pie){
        pie.percent = total ? Number((pie.value/total) * 100).toFixed(1) : 0
    })
    $scope.total = total;
    $scope.onClose = function(){
        $mdDialog.hide();
    };
  });
