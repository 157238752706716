'use strict';
angular.module('bitnudgeApp')
    .controller('kpiMixCtrl', function($http, $scope, Auth, Upload, $location, toaster) {

        $scope.toggleMin = function() {
            $scope.minDate = $scope.minDate ? null : new Date();
        };
        $scope.toggleMin();

        //opening a datepicker dialog box
        $scope.open = function($event) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened = true;
        };

        //Configration for Date picker.
        $scope.dateOptions = {
            formatYear: 'yy',
            modelDateFormat: "yyyy-MM-ddTHH:mm:ss",
            startingDay: 1
        };

        $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];

        //Function to submit lead excel
        $scope.onFileSelectLead = function() {
            $scope.asOfDate = $scope.asOfDate.getTime();
            if ($scope.file == undefined) {
                toaster.pop('error', "Submission.", "Select File to Upload.");

            } else {
                var fileName = $scope.file[0].name.split(".");
                var fileType = fileName[fileName.length - 1].toLowerCase();
                if (fileType == "xlsx") {
                    Upload.upload({
                        url: '/api/userAnalytics/productMix/import',
                        data: {
                            userId: Auth.getCurrentUser()._id,
                            asOfDate: $scope.asOfDate
                        },
                        file: $scope.file[0],
                        progress: function(e) {}
                    }).then(function(data, status, headers, config) {
                        // file is uploaded successfully
                        //$location.path('/lms/listLead');
                        toaster.pop('success', "Excel uploaded", "Lead creation successfully finished.");
                    });
                } else {
                    toaster.pop('error', "Submission.", "Upload '.xlsx' file only.");
                }

            }
        }

    })
