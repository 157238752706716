'use strict';

angular.module('bitnudgeApp')
    .controller('adminCampaignsCtrl', function ($rootScope, $stateParams, $scope, $http,  Auth, User, $location, DTOptionsBuilder, DTColumnDefBuilder, toaster, $timeout, p0) {
        $scope.strings = $rootScope.strings;

        $http.post('/api/campaigns').then(function (campaigns) {
            campaigns=campaigns.data;
            $scope.campaigns = campaigns;
            //socket.syncUpdates('campaigns', $scope.campaigns);

            $scope.dtOptionsCampaignsList = DTOptionsBuilder.newOptions().withPaginationType('full_numbers').withOption('order', [0, 'asc']);
            $scope.dtColumnDefsCampaignsList = [
                DTColumnDefBuilder.newColumnDef(0),
                DTColumnDefBuilder.newColumnDef(1),
                DTColumnDefBuilder.newColumnDef(2),
                DTColumnDefBuilder.newColumnDef(3),
                DTColumnDefBuilder.newColumnDef(4)
                //DTColumnDefBuilder.newColumnDef(5),
                //DTColumnDefBuilder.newColumnDef(6)
            ];
        });

        $scope.contests = [];
        $scope.mySelectedValues = [];

        $scope.addContest = function(){
            $scope.contests.push({});
            $scope.mySelectedValues.push($scope.kpis[0]);
            $timeout(applyDatepicker, 1);
        }

        $scope.addContestInEditCampaign = function(campaign){
            campaign.contests.push({});
            $scope.campaignKpi.push($scope.kpis[0]);
            $timeout(applyDatepicker, 1);
        }


        $scope.removeContest = function(index) {
            $scope.contests.splice(index, 1);
        };

        $scope.removeContestInEditCampaign = function(campaign, index) {
            campaign.contests.splice(index, 1);
        };

        $scope.kpis = p0.allKpis;

        $scope.addCampaign = function(campaign) {

            var kpis = $scope.mySelectedValues;

            var kpiIds = kpis.filter( function(item){
                return '_id' in item;
            }).map( function( item ){
                return { '_id' : item['_id'] }
            });

            _.forEach($scope.contests, function(contest, n) {
                contest.kpiId = kpiIds[n]._id;
            });

            campaign.contests = $scope.contests;

            $http.post('/api/campaigns/createCampaignAndCycle/', {
                name: campaign.name,
                desc: campaign.desc,
                startDate: new Date(campaign.startDate),
                endDate: new Date(campaign.endDate),
                active: campaign.active,
                contests: campaign.contests
            }).then(function (response) {
                var data = response.data;
                var status = response.status;
                var statusText = response.statusText;
                var headers = response.headers;
                var config = response.config;
                $location.path('/admin/listCampaigns');
                toaster.pop('success', "Campaign Creation", "New Campaign Added.");
            }).catch(function (response) {
                var data = response.data;
                var status = response.status;
                var statusText = response.statusText;
                var headers = response.headers;
                var config = response.config;

            });
        };

        $scope.updateCampaign = function(campaign) {
            var kpis = $scope.campaignKpi;

            var kpiIds = kpis.filter( function(item){
                return '_id' in item;
            }).map( function( item ){
                return { '_id' : item['_id'] }
            });

            _.forEach(campaign.contests, function(contest, n) {
                contest.kpiId = kpiIds[n]._id;
                contest.startDate = new Date(contest.formattedStartDate);
                contest.endDate = new Date(contest.formattedEndDate);
            });

            $http.post('/api/campaigns/'+campaign._id, {
                name: campaign.name,
                desc: campaign.desc,
                startDate: new Date(campaign.formattedStartDate),
                endDate: new Date(campaign.formattedEndDate),
                active: campaign.active,
                cycleId: campaign.cycleId,
                contests: campaign.contests
            }).then(function (response) {
                var data = response.data;
                var status = response.status;
                var statusText = response.statusText;
                var headers = response.headers;
                var config = response.config;
                $location.path('/admin/showCampaign/'+campaign._id);
                toaster.pop('success', "Campaign Updation", "Campaign Updated.");
            }).catch(function (response) {
                var data = response.data;
                var status = response.status;
                var statusText = response.statusText;
                var headers = response.headers;
                var config = response.config;
            });
        };

        $scope.deleteCampaign = function (campaign) {
                $http.post('/api/campaigns/archive',{id:campaign._id}).then(function (response) {
                var data = response.data;
                var status = response.status;
                var statusText = response.statusText;
                var headers = response.headers;
                var config = response.config;
                $location.path('/admin/listCampaigns');
                toaster.pop('success', "Campaign Deletion", "Campaign Deleted.");
            }).catch(function (data, status, headers, config) {

            });
        };

        $scope.formatDate = function (date, type) {
            var Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            var newDate = new Date(date);
            if (type == "date") {
                var reqDate = newDate.getDate() + "-" + Months[newDate.getMonth()] + "-" + newDate.getFullYear();
            } else {
                reqDate = newDate.toLocaleTimeString().split(" ")[0].slice(0, 5) + newDate.toLocaleTimeString().split(" ")[1];
            }
            return reqDate;
        };

        $scope.formatDateForDatePicker = function (date) {
            if(date)
                return date.split("T")[0];
            else
                return "";
        };

        $scope.toTitleCase = function(string) {
            return string.replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        };

        $scope.getYesNo = function(value) {
            if(value==true){
                return "Yes";
            }
            else{
                return "No"
            }
        };

        if(typeof $stateParams.campaignId != 'undefined') {
            $scope.campaignKpi = [];
            $scope.campaignId = $stateParams.campaignId;
            $http.post('/api/campaigns/show',{id:$stateParams.campaignId}).then(function (campaign) {
                campaign=campaign.data;
                campaign.formattedStartDate = $scope.formatDateForDatePicker(campaign.startDate);
                campaign.formattedEndDate = $scope.formatDateForDatePicker(campaign.endDate);
                $scope.campaign = campaign;

                _.forEach($scope.campaign.contests, function(contest, n) {
                    $scope.campaignKpi.push(_.find($scope.kpis, {_id: contest.kpiId}));
                    contest.formattedStartDate = $scope.formatDateForDatePicker(contest.startDate);
                    contest.formattedEndDate = $scope.formatDateForDatePicker(contest.endDate);
                });

                $timeout(applyDatepicker, 1);

                //socket.syncUpdates('campaign', $scope.campaign);
            });
        }

    });
