const autoImportNgTemplateLoaderTemplate1 = require('../html/benchmarkWidget.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('benchmarkWidget', function(){
        return {
            name: 'benchmarkWidget',
            scope: {
                goal : '=',
                selectedBenchmark: '=',
                cycle: '='
            },
            controller: "benchmarkWidgetCtrl",
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
