const autoImportNgTemplateLoaderTemplate1 = require('../html/compareMetricGroup.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('compareMetricGroup', function () {
        return {
            name: 'compareMetricGroup',
            scope: {
                metrics: '=',
                toggles: '=',
                absolute: '='
            },
            controller: 'compareMetricGroupCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
