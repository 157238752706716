const autoImportNgTemplateLoaderTemplate1 = require('./../html/component/documentComponent.html');

"use strict";
angular.module("bitnudgeApp").component("documentComponent", {
	templateUrl: autoImportNgTemplateLoaderTemplate1,
	bindings: {
		selectMode: "<",
		approveMode: "<",
		customerMode: "<",
		documentIds: "<",
		workflowId: "<",
		viewMode: "<",
		cui: "<",
		documentTypes: "<",
		context:"<",
		selectedDocumentId:"<",
		selectedDocumentType:"<"
	},
	controller: "documentComponentCtrl"
});
