'use strict';
angular.module('bitnudgeApp')
    .controller('bulkCommentModalCtrl', function ($scope,$mdDialog,onClose,dailyFeeds, badgeId,type,dailyFeed, dataFromDailyFeed, $window) {
        $scope.onClose = function() {
            $window.location.reload();
            onClose();
            $mdDialog.cancel();
        };
        switch (type){
            case 'badge':
                $scope.prependText = 'Hi "User", Congratulations on earning the "badge". Keep it Up.';
                break;
            case 'level':
                $scope.prependText = 'Hi "User", Congratulations on earning the "level" status. Keep it Up.';
                break;
            case 'rank':
                $scope.prependText = 'Hi "User", Congratulations on earning the "Rank". Keep it Up.';
                break;
            case 'delta':
                $scope.prependText = 'Hi "User", Congratulations on improving you Rank. Keep it Up.';
                break;
            case 'swot':
                $scope.prependText = 'Hi "User", Congratulations on entering the "SWOT" zone. Keep it Up.';
                break;
            case 'compliance':
                $scope.prependText = 'Hi "User", Congratulations on achieving your plan. Keep it Up.';
                break;
            case 'form':
                $scope.prependText = 'Hi "User", Congratulations on improving your form in the game. Keep it Up.';
                break;
            case 'redFlag':
                $scope.prependText = 'Hi "User", Try Harder.';
                break;
            case 'MTD':
                $scope.prependText = 'Hi "User", Good Work on your MTD. Keep it Up.';
                break;
            case 'call':
                $scope.prependText = 'Hi "User", Good Work on your calls. Keep it Up.';
                break;
            case 'submission':
                $scope.prependText = 'Hi "User", Good Work on your submissions. Keep it Up.';
                break;
        }
        $scope.postBulkComments = function(){
            var commentData = [];
            dailyFeeds.forEach(function(df){
                df.value.badges.forEach(function(badge){
                    if (badgeId && badge.badgeId != badgeId) return;
                    var message;
                    switch (type){
                        case 'badge':
                            message = 'Hi '+df.userId.name+', Congratulations on earning the '+badge.name+' badge. Keep it Up.';
                            break;
                    }
                    message = message+' ' + $scope.msg;
                    commentData.push({userId:df.userId._id,message:message});
                });
            });
            if (badgeId) {
                dailyFeed.socialByBadge('bulkComment',dailyFeeds, badgeId,type,commentData).then(function(updatedFeeds){
                    updatedFeeds.forEach(function (e, i, l) {
                        dataFromDailyFeed.forEach(function (d) {
                            if (d._id == e._id) {
                                d = e;
                            }
                        });
                    });
                    $scope.onClose();
                });
            }
            else {
                dailyFeed.social('bulkComment',dailyFeeds,type,commentData).then(function(){
                    $scope.onClose();
                });
            }
        };
    });
