angular.module('bitnudgeApp')
	.directive('splitMe', function () {
		return {
			restrict: 'EA',
			transclude: true,
			scope: {
				data: '=',
				roundoff: '=',
				roundoffcount: '=',
                jhaid:'@'
			},

			replace: true,
			link: function (scope, element) {
                scope.render = function(data) {
					const trauncateFractionAndFormat = (parts, digits) => {
						return parts.map(({ type, value }) => {
						  if (type !== 'fraction' || !value || value.length < digits) {
							return value;
						  }
						  
						  let retVal = "";
						  for (let idx = 0, counter = 0; idx < value.length && counter < digits; idx++) {
							if (value[idx] !== '0') {
							  counter++;
							}
							retVal += value[idx];
						  }
						  return retVal;
						}).reduce((string, part) => string + part);
					  };
					  const nf = new Intl.NumberFormat('en-US', {
						minimumFractionDigits: 0,
						maximumFractionDigits: 20
					  })
					let finalContent = data.map( (d, index ) =>  {
						if (scope.roundoff && !isNaN(d)) {
							return index !== data.length - 1 ? `<div>${trauncateFractionAndFormat(nf.formatToParts(Number(d)), scope.roundoffcount)}, </div>` : `<div>${trauncateFractionAndFormat(nf.formatToParts(Number(d)), scope.roundoffcount)}</div>`;
						} else {
							return index !== data.length - 1 ? `<div>${d}, </div>` : `<div>${d}</div>`;
						}
					})
                    element.html(finalContent);
                };

				scope.$watch('data', function (data) {
					 if (data != undefined){
                        let finalStrings = []
                            data = ''+data
                            finalStrings = data.split('|')
                            scope.render(finalStrings)                        
                     }
				});

			}
		};
	});