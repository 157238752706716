const autoImportNgTemplateLoaderTemplate1 = require('../html/reportModal.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/trendModal.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('customReportsFormCtrl', function ($scope, $rootScope, Auth, p0, $http, $mdDialog, ThresholdReport, Reportees,$state, toaster) {
        $scope.strings = $rootScope.strings;
        $scope.config = $rootScope.config;
        $scope.me = Auth.getCurrentUser();
        let kpis = _.cloneDeep(p0.myKpis)
        if(p0.config.agentOnBoardingProductKpi){
            kpis = kpis.filter(k => k.kpiCode !== p0.config.agentOnBoardingProductKpi)
        }
        $scope.kpis = kpis;
        $scope.thresholdKpis = _.cloneDeep(p0.myKpis);
        $scope.thresholdKpis.unshift({_id:'overall', name:'Overall'});
        $scope.trendKpi = $scope.kpis[0];
        $scope.params = {
            thresholdExpanded: false,
            trendExpanded : false,
            ocrExpanded: false,
            complianceExpanded: false,
            unit : null,
            isWeekly: false,
            isActualSales: false
        };
        var daily = {id:'daily', name:'Daily'}, monthly = {id:'monthly', name:'Monthly'}, weekly = {id:'weekly', name:'Weekly'};

        var setTrendTimeRange = function (monthType){
            var lastSync = new Date($rootScope.lastUpdatedAtDate);
            $scope.params.trendReportTimeRange = [];
            var dailyWeekly = [daily];
            var weeklyMonthly = [monthly];
            if($scope.config.weeklyCycle){
                dailyWeekly.push(weekly);
                weeklyMonthly.unshift(weekly);
            }
            var currentCycle = _.find(p0.allCycles,{cycleType:'monthly',active:true, subType:monthType});
            var trendReportTimeRange = [
                {name:'Last 7 Days', _id:'last7Days', startDate:new Date(), endDate:new Date(), cycleTypes:[daily]},
                {name:'This month', _id:'thisMonth', startDate:new Date(), endDate:new Date(), cycleTypes:dailyWeekly}
            ];
            if(currentCycle.previousCycleId){
                trendReportTimeRange.push({name:'Last month', _id:'lastMonth', startDate:new Date(), endDate:new Date(), cycleTypes:dailyWeekly});
                trendReportTimeRange.push({name:'Last 3 months', _id:'last3Months', startDate:new Date(), endDate:new Date(), cycleTypes:weeklyMonthly});
                trendReportTimeRange.push({name:'Last 6 months', _id:'last6Months', startDate:new Date(), endDate:new Date(), cycleTypes:weeklyMonthly});
            }

            var lastSevenStart = new Date(moment(lastSync).subtract(6,'d'));
            var lastSeven = _.find(trendReportTimeRange, {_id:'last7Days'});
            if(lastSeven){
                lastSeven.startDate = lastSevenStart.setHours(0, 0, 0);
                lastSeven.endDate = new Date($rootScope.lastUpdatedAtDate).setHours(23, 59, 59, 999);
            }
            var thisMonth = _.find(trendReportTimeRange, {_id:'thisMonth'});
            if(thisMonth){
                thisMonth.startDate = new Date(currentCycle.startDate).setHours(0, 0, 0);
                thisMonth.endDate = new Date(lastSync).setHours(23, 59, 59, 999);
                var timeDiff = Math.abs(new Date(thisMonth.endDate).getTime() - new Date(thisMonth.startDate).getTime());
                var days = Math.ceil(timeDiff / (1000 * 3600 * 24));
                var cycleTypes = [daily];
                if(days >= 7 && $scope.config.weeklyCycle){
                    cycleTypes.push(weekly);
                }
                thisMonth.cycleTypes = cycleTypes;
            }
            if(currentCycle.previousCycleId){
                var previousCycle = _.find(p0.allCycles,{_id:currentCycle.previousCycleId._id});
                var previous = _.find(trendReportTimeRange, {_id:'lastMonth'});
                if(previous && previousCycle){
                    previous.startDate = new Date(previousCycle.startDate).setHours(0, 0, 0);
                    previous.endDate = new Date(previousCycle.endDate).setHours(23, 59, 59, 999);
                }

                var y = lastSync.getFullYear(), m = lastSync.getMonth(), endDate, startDate;
                if(monthType == 'regular'){
                    endDate = new Date(y, m , 0);
                    startDate = new Date(y, m - 3, 1);
                }else{
                    endDate = new Date(y, m-1 , 14);
                    startDate = new Date(y, m - 4, 16);
                }
                endDate = endDate.setHours(23, 59, 59, 999);
                var last3M = _.find(trendReportTimeRange, {_id:'last3Months'});
                if(last3M){
                    last3M.startDate = new Date(startDate).setHours(0, 0, 0);
                    last3M.endDate = endDate;
                }
                if(monthType == 'regular'){
                    startDate = new Date(y, m - 6, 1);
                }else{
                    startDate = new Date(y, m - 7, 16);
                }
                var last6M = _.find(trendReportTimeRange, {_id:'last6Months'});
                if(last6M){
                    last6M.startDate = new Date(startDate).setHours(0, 0, 0);
                    last6M.endDate = endDate;
                }
            }

            $scope.params.trendReportTimeRange = trendReportTimeRange;
            $scope.selected.trendTimeRange = $scope.params.trendReportTimeRange[0];
            $scope.params.trends = $scope.selected.trendTimeRange.cycleTypes;
            $scope.selected.trend = $scope.params.trends[0];
        };

        $scope.onTimerangeChange = function () {
            $scope.params.trends = $scope.selected.trendTimeRange.cycleTypes;
            $scope.selected.trend = $scope.params.trends[0];
        }

        //Remove hardcoded activiries
        var trendReportActivities = ['call', 'meeting','newLead', 'submission', 'disbursal','volumes','volumes-a','throughput','aht',
            'accuracyp','accuracyp-r','accuracyp-i','accuracyp-a','throughput-a'];

        $scope.cycleTypes = [{name:'Monthly', id: 'monthly'}];
        if($scope.config.weeklyCycle) {
            $scope.cycleTypes.push({name: 'Weekly', id: 'weekly'});
        }
        $scope.cycleTypes.push({name:'Daily', id: 'daily'});
        $scope.params.cycleType = $scope.cycleTypes[0];
        $scope.params.kpi = $scope.thresholdKpis[0];
        $scope.dropdownCycles = getCycles();
        $scope.params.cycles = [$scope.dropdownCycles[0]];
        $scope.params.cycle = $scope.dropdownCycles[0];

        Reportees.fetchReportingTree($scope.me._id,p0.myPlayTypes).then(function(reportees){
            $scope.OCRReportees = _.filter(reportees,function (user) {
                return user.roleType == 'frontline' || user.roleType == 'manager';
            })
        });


        $scope.onReportOptionExpand = function (report) {
            if (report != 'trend') $scope.params.trendExpanded = false;
            if (report != 'threshold') $scope.params.thresholdExpanded = false;
            if (report != 'ocr') $scope.params.ocrExpanded = false;
            if (report != 'compliance') $scope.params.complianceExpanded = false;
            switch (report) {
                case 'trend':
                    $scope.params.trendExpanded = !$scope.params.trendExpanded;
                    break;
                case 'threshold':
                    $scope.params.thresholdExpanded = !$scope.params.thresholdExpanded;
                    break;
                case 'ocr':
                    $scope.params.ocrExpanded = !$scope.params.ocrExpanded;
                    break;
                case 'compliance':
                    $scope.params.complianceExpanded = !$scope.params.complianceExpanded;
                    break;
            }
        };

        $scope.onCustomReportDuration = function (duration) {
            if ($scope.customReportDuration != duration) {
                $scope.customReportDuration = duration;
                if (duration == 'week') {
                    $scope.selectedDuration = $scope.durationOptions[2];
                    $scope.selectedDurationType = $scope.durationType[2];
                } else {
                    $scope.selectedDuration = $scope.durationOptions[0];
                    $scope.selectedDurationType = $scope.durationType[0];
                }
            }
        };

        $scope.onComplianceTypeChange = function (type) {
            $scope.complianceType = type;
        };

        $scope.durationOptions = [
            {name: 'Last 3 Months', id: 1, use: 'month'},
            {name: 'This Month', id: 2, use: 'month'},
            {name: 'Last 3 Weeks', id: 3, use: 'week'},
            {name: 'This Week', id: 4, use: 'week'},
            {name: 'NA', id: 5, use: 'both'}
        ];

        $scope.durationType = [{name: 'Month on Month', id: 1, use: 'month'}, {name: 'End to End', id: 2, use: 'month'},
            {name: 'Week on Week', id: 3, use: 'week'}, {name: 'End to End', id: 4, use: 'week'},
            {name: 'Average', id: 5, use: 'both'}];
        $scope.activityOptions = [{name: 'Activity', id: 1}, {name: 'Output Performance', id: 2}, {name: 'KAIs', id: 3}];
        $scope.kpiOptions = [{name: 'Personal Loans', id: 1}, {name: 'Personal Loans 2', id: 2},
            {name: 'Personal Loans 3', id: 3}];
        //$scope.zoneOptions = [{name:$scope.strings.SWOT_S.value, id: 1}, {name:$scope.strings.SWOT_T.value , id: 2}, {name: $scope.strings.SWOT_W.value, id: 3}, {name: $scope.strings.SWOT_O.value, id: 4}];
        $scope.trendOptions = [{name: 'Increase', id: 1}, {name: 'Decrease', id: 2}];
        $scope.valueOptions = [{name: 'Value equals', id: 1}, {name: 'Value is less than', id: 2},
            {name: 'Value is more than', id: 3}];
        /*$scope.thresholdOptions = [{name: 'Value', id: 1, selected: true, label: 'Value is'},
         {name: 'Rank', id: 2, selected: false, label: 'Rank is'},
         {name: $scope.strings.SWOT_SWOT_PL.value, id: 3, selected: false, label: $scope.strings.SWOT_SWOT_PL.value+' is'},
         {name: 'Badges', id: 4, selected: false, label:'Badge count'}];*/
        /*$scope.unitOptions = [{name: '% of tgt.', id:1, use:'Value'}, {name: 'AED,m', id:2, use:'Value'},
         {name: $scope.strings.POINTS.value, id:3, use:'Value'}, {name: $scope.strings.SWOT_S.value, id:4, use: $scope.strings.SWOT_SWOT_PL.value}, {name: $scope.strings.SWOT_T.value, id:5, use: $scope.strings.SWOT_SWOT_PL.value},
         {name: $scope.strings.SWOT_W.value, id:6, use: $scope.strings.SWOT_SWOT_PL.value}, {name: $scope.strings.SWOT_O.value, id:7, use: $scope.strings.SWOT_SWOT_PL.value}];*/
        $scope.standaloneValueOptions = [{name: 'equal to', id: 1}, {name: 'less than', id: 2},
            {name: 'greater than', id: 3}];
        $scope.gameplanOptions = [{name: 'Achievement', id: 1}, {name: 'Not made', id: 2},
            {name: 'not completed', id: 3}];

        function initializePageOptions() {
            $scope.onCustomReportDuration('month');
            $scope.selectedKpi = $scope.kpiOptions[0];
            $scope.selectedActivity = $scope.activityOptions[0];
            //$scope.selectedZone = $scope.zoneOptions[0];
            $scope.selected.trend = $scope.trendOptions[0];
            $scope.selectedValue = $scope.valueOptions[0];
            //$scope.selectedThreshold = $scope.thresholdOptions[0];
            //$scope.selectedUnit = $scope.unitOptions[0];
            $scope.selectedStandaloneValue = $scope.standaloneValueOptions[0];
            $scope.selectedGameplan = $scope.gameplanOptions[0];
            $scope.selectedAchievement = $scope.standaloneValueOptions[0];
            $scope.complianceType = 'gameplan';
        }

        //{name: 'Login', id: 'Login', type: 'Login'},
        var metric2Options = [];
        function setMetricOptions() {
            $scope.metricOptions = [];
            var kpiId = $scope.params.kpi._id;
            if(kpiId == 'overall'){
                $scope.metricOptions.push({name: 'Usage Time', id: 'Usage', type:'Usage', unit:'mins.'});
            }else{
                $scope.metricOptions.push({name: 'Output Performance', id: 'mtd',type:'kpi'});
            }
            if($rootScope.config.lms){
                $scope.metricOptions.push({name: 'Leads Created', id: 'Lead add', type: 'Lead add',unit:'#'});
                $scope.metricOptions.push({name: 'Leads Updated', id: 'Lead update', type: 'Lead update', unit:'#'});
                $scope.metricOptions.push({name: 'Calls', id: 'call', type:'kai', unit:'#'});
                $scope.metricOptions.push({name: 'Meetings', id: 'meeting', type:'kai', unit:'#'});
                $scope.metricOptions.push({name: 'Submissions', id: 'submission',type:'kai', unit:'#'});
            }
            p0.allActivities.forEach(function(activity){
                if(activity.cron && !_.find($scope.metricOptions, {id:activity.fieldName}) && activity.fieldName != 'aht'
                && (kpiId =='overall' || (kpiId != 'overall' && activity.kpiSpecific && _.find(activity.kpiData,{kpiId:kpiId})))){
                    $scope.metricOptions.push({name:activity.name, id: activity.fieldName,unit:activity.unit, type:'kai'});
                }
            });
            metric2Options = [];
            $scope.metricOptions.forEach(function(option){
                metric2Options.push(option);
            });
            $scope.metric2Options = _.cloneDeep(metric2Options);
            if(!$scope.params.selectedMetric || ($scope.params.selectedMetric && !_.find($scope.metricOptions,{id:$scope.params.selectedMetric.id}))){
                $scope.params.selectedMetric = $scope.metricOptions[0];
            }
            _.remove($scope.metric2Options, {id:$scope.params.selectedMetric.id});
            if(!$scope.params.selectedMetric2 || ($scope.params.selectedMetric2 && !_.find($scope.metric2Options,{id:$scope.params.selectedMetric2.id}))){
                $scope.params.selectedMetric2 = $scope.metric2Options[0];
            }
        }
        setMetricOptions();

        $scope.joinOptions = [{name:'Do not add', id:0}, {name: 'And', id: 1}, {name: 'Or', id: 2}];
        $scope.params.selectedJoin = $scope.joinOptions[0];
        $scope.compareOptions = [{name: 'greater or equal to', id: 1}, {name: 'less than', id: 2}];
        $scope.params.selectedComparison = $scope.compareOptions[0];
        $scope.params.selectedComparison2 = $scope.compareOptions[0];
        $scope.trendReportsMetricOptions = [
            {name: 'Output Performance', id: 'percentage', metric:'kpi'}
        ];

        $scope.trendOptions = [{name: 'Monthly', id: 'monthly', disabled: false}];
        if($scope.config.weeklyCycle){
            $scope.trendOptions.push({name: 'Weekly', id: 'weekly', disabled: false});
        }
        $scope.trendOptions.push({name: 'Daily', id: 'daily', disabled: false});
        $scope.selected = {};
        $scope.selected.trendMetric = $scope.trendReportsMetricOptions[0];
        $scope.selected.trend = $scope.trendOptions[0];

        $scope.params.maxDateString = moment().format('DD-MM-YYYY');
        $scope.params.minDateString = moment().subtract(1, 'year').format('DD-MM-YYYY');

        $scope.generateCustomReport = function ($event) {
            if($scope.params.selectedJoin.id && !$scope.params.cycle) {
                toaster.pop('error', "Report", "Please select the cycle.");
                return;
            }
            if(!$scope.params.selectedJoin.id && !$scope.params.cycles.length){
                toaster.pop('error', "Report", "Please select atleast one cycle.");
                return;
            }
            if (!$scope.params.thresholdValue){
                toaster.pop('error', "Report", "Please set threshold value.");
                return;
            }if($scope.params.selectedJoin.id && !$scope.params.thresholdValue2){
                toaster.pop('error','Please select second metric threshold value');
                return;
            }

            if($scope.params.selectedMetric.id == 'mtd' || ($scope.params.selectedJoin.id && $scope.params.selectedMetric2.id == 'mtd')){
                if($scope.params.selectedJoin.id){
                    $scope.params.cycles = [$scope.params.cycle];
                }
                ThresholdReport.getSalesReport($scope.params.kpi._id,  $scope.params.cycles , $scope.params.isActualSales).then(function (outputData) {
                    if($scope.params.selectedJoin.id){
                        var cycleIds = _.map($scope.params.cycles, '_id');
                        var kai = $scope.params.selectedMetric.type == 'kpi'? $scope.params.selectedMetric2 : $scope.params.selectedMetric;
                        var inputData = {
                            userId: $scope.me._id,
                            actionType: kai.id,
                            cycleIds: cycleIds,
                            kpiId: $scope.params.kpi._id,
                            metricType: kai.type
                        };
                        $http.post('/api/customReports', inputData).then(function (outputData2) {
                            outputData2=outputData2.data;
                            outputData = outputData.concat(outputData2);
                           showThresholdModal(outputData,$event);
                        });
                    }else{
                        showThresholdModal(outputData, $event);
                    }
                });
            }else{
                if($scope.params.selectedJoin.id){
                    $scope.params.cycles = [$scope.params.cycle];
                }
                var cycleIds = _.map($scope.params.cycles, '_id');
                var inputData = {
                    userId: $scope.me._id,
                    actionType: $scope.params.selectedMetric.id,
                    cycleIds: cycleIds,
                    kpiId: $scope.params.kpi._id,
                    metricType: $scope.params.selectedMetric.type
                };
                var inputData2 ={};
                if($scope.params.selectedJoin.id){
                    inputData2 ={
                        userId: $scope.me._id,
                        actionType: $scope.params.selectedMetric2.id,
                        cycleIds: cycleIds,
                        kpiId: $scope.params.kpi._id,
                        metricType: $scope.params.selectedMetric2.type
                    };
                }
                $http.post('/api/customReports', inputData).then(function (outputData) {
                    outputData = outputData.data;
                    if($scope.params.selectedJoin.id){
                        $http.post('/api/customReports', inputData2).then(function (outputData2) {
                            outputData2 = outputData2.data;
                            outputData = outputData.concat(outputData2);
                            showThresholdModal(outputData,$event);
                        });
                    }else{
                        showThresholdModal(outputData, $event);
                    }
                });
            }
        };

        function showThresholdModal(outputData, $event) {
            var sortOrder = $scope.params.selectedComparison.id == 1 ? 'desc' : 'asc';
            outputData = _.orderBy(outputData, ['count'], [sortOrder]);
            var locals = {
                dataValues: outputData,
                selectedMetric: $scope.params.selectedMetric,
                thresholdValue: $scope.params.thresholdValue,
                unit: $scope.params.unit,
                sortOrder: sortOrder,
                extras:{
                    cycles: $scope.params.cycles,
                    kpi: $scope.params.kpi
                }
            };
            if($scope.params.selectedJoin.id){
                locals.extras['join'] = $scope.params.selectedJoin.name;
                locals.extras['selectedMetric2'] = $scope.params.selectedMetric2;
                locals.extras['thresholdValue2'] = $scope.params.thresholdValue2;
                locals.extras['sortOrder2'] = $scope.params.selectedComparison2.id == 1 ? 'desc' : 'asc';
                locals.extras['unit2'] = $scope.params.unit2;
            }
            $mdDialog.show({
                locals: locals,
                controller: 'reportModalCtrl',
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                parent: angular.element(document.body),
                targetEvent: $event,
                clickOutsideToClose: true,
                escapeToClose: true
            });
        }

        $scope.cycleTypeChange = function () {
            if($scope.params.cycleType.id == 'weekly'){
                $scope.dropdownCycles = p0.allWeeklyCycles;
            }else if($scope.params.cycleType.id == 'daily'){
                var dailyCycles = _.cloneDeep(p0.allDailyCycles);
                if(!$scope.config.lms && !$scope.params.selectedJoin.id && $scope.params.selectedMetric.id == 'Usage'){
                    var now = new Date();
                    dailyCycles = _.filter(p0.allAdminCycles,function(cycle){
                        return new Date(cycle.startDate) < now && cycle.cycleType == 'daily';
                    });
                }
                $scope.dropdownCycles = dailyCycles;
            }else{
                $scope.dropdownCycles = getCycles();
            }
            $scope.params.cycles = [$scope.dropdownCycles[0]];
            $scope.params.cycle = $scope.dropdownCycles[0];
        };
        $scope.onJoinChange = function(join){
            $scope.cycleTypeChange();
        }

        $scope.salesUnitChange = function () {
            setSalesUnit();
        };

        $scope.generateTrendReport = function ($event) {
            if (!$scope.selected.trendTimeRange) {
                toaster.pop('error', "Report", "Please select time range.");
                return;
            }

            var parameters = { userId: $scope.me._id, timeRange: $scope.selected.trendTimeRange,
                kpi: $scope.trendKpi, metric: $scope.selected.trendMetric, cycleType: $scope.selected.trend };
            $mdDialog.show({
                controller: 'trendModalCtrl',
                templateUrl: autoImportNgTemplateLoaderTemplate2,
                parent: angular.element(document.body),
                targetEvent: $event,
                clickOutsideToClose: true,
                escapeToClose: true,
                resolve:{
                    parameters: function(){
                        return parameters;
                    },
                    metricOptions: function () {
                        return $scope.trendReportsMetricOptions;
                    }
                }
            });
        };

        var selectedRange = {};
        $scope.onDateRangeSelected = function (dateRange) {
            if (dateRange.startDate && dateRange.endDate) {
                var startDate = dateRange.startDateAsMoment.toDate();
                startDate.setHours(0, 0, 0, 0);

                var endDate = dateRange.endDateAsMoment.toDate();
                endDate.setHours(23, 59, 59, 999);

                selectedRange.startDate = startDate.getTime();
                selectedRange.endDate = endDate.getTime();
            } else {
                selectedRange.startDate = 0;
                selectedRange.endDate = 0;
            }
        };

        $scope.trendReportTimeRange = {};
        /*$scope.onTrendReportTimeRangeSelected = function (dateRange) {
            var monthlyOption = _.find($scope.trendOptions, {id: 'monthly'});
            var weeklyOption = _.find($scope.trendOptions, {id: 'weekly'});
            var dailyOption = _.find($scope.trendOptions, {id: 'daily'});

            monthlyOption.disabled = false;
            weeklyOption.disabled = false;
            dailyOption.disabled = false;

            if (dateRange.startDate && dateRange.endDate) {
                var startDate = dateRange.startDateAsMoment.toDate();
                startDate.setHours(0, 0, 0, 0);

                var endDate = dateRange.endDateAsMoment.toDate();
                endDate.setHours(23, 59, 59, 999);

                $scope.trendReportTimeRange.startDate = startDate.getTime();
                $scope.trendReportTimeRange.endDate = endDate.getTime();

                var numDays = ($scope.trendReportTimeRange.endDate - $scope.trendReportTimeRange.startDate) / (86400 * 1000);
                numDays = Math.round(numDays);

                if (numDays < 60) {
                    monthlyOption.disabled = true;
                    $scope.selected.trend = weeklyOption;
                }

                if (numDays < 14) {
                    weeklyOption.disabled = true;
                    $scope.selected.trend = dailyOption;
                }
            } else {
                $scope.trendReportTimeRange.startDate = 0;
                $scope.trendReportTimeRange.endDate = 0;
            }
        };*/

        $scope.onThresholdOptionChange = function ($index) {
            if ($scope.selectedThreshold.id == $scope.thresholdOptions[$index].id) {
                $scope.selectedThreshold.selected = true;
                return;
            }

            $scope.selectedThreshold.selected = false;
            $scope.selectedThreshold = $scope.thresholdOptions[$index];

            if ($scope.selectedThreshold.name == 'Value') {
                $scope.selectedUnit = $scope.unitOptions[0];
            } else if ($scope.selectedThreshold.name == $scope.strings.SWOT_SWOT_PL.value) {
                $scope.selectedUnit = $scope.unitOptions[3];
            }
        };

        $scope.onMetricChange = function (metric) {
            if (metric.id == 'mtd') {
                selectedRange = {};
                setSalesUnit();
            }
            $scope.params.selectedJoin = $scope.joinOptions[0];
            $scope.metric2Options = _.cloneDeep(metric2Options);

            _.remove($scope.metric2Options, {id:metric.id});
            if(metric.id != 'mtd' && $scope.params.kpi._id == 'overall'){
                _.remove($scope.metric2Options, {type:'kpi'});
            }

            $scope.params.selectedMetric2 = $scope.metric2Options[0];
            $scope.onMetric2Change($scope.params.selectedMetric2);
            if(metric.id != 'mtd'){
                $scope.params.unit = metric.unit ? metric.unit : '#';
            }
            $scope.cycleTypeChange();
        };
        $scope.onMetric2Change = function(metric2){
          if(metric2.id == 'mtd'){
              setSalesUnit();
          }else{
              $scope.params.unit2 = metric2.unit ? metric2.unit : '#';
          }
        };
        $scope.onProductChange = function (product) {
            $scope.params.kpi = product;
            setMetricOptions();
            if($scope.params.selectedMetric.id == 'mtd'){
                setSalesUnit();
                if($scope.params.cycleType.id == 'monthly'){
                    $scope.dropdownCycles = getCycles();
                    $scope.params.cycles = [$scope.dropdownCycles[0]];
                }
            }
            $scope.cycleTypeChange();
        };

        $scope.onThresholdProductChange = function (product) {
            $scope.trendKpi = product;
            $scope.trendReportsMetricOptions = [{name: 'Output Performance', id: 'percentage', metric:'kpi'}];
            p0.allActivities.forEach(function (activity) {
                if(trendReportActivities.indexOf(activity.fieldName) > -1 && $scope.trendKpi.activities.indexOf(activity._id) > -1){
                    var activityName = _.find(activity.kpiData, {kpiId: product._id});
                    $scope.trendReportsMetricOptions.push({name: activityName.name, id: activity.fieldName, metric:'kai', unit:activity.unit, activityType: activity.activityType});
                }
            });
            $scope.selected.trendMetric = $scope.trendReportsMetricOptions[0];
            var playType = _.find(p0.allPlayTypes, function (playType) {return _.find(playType.kpis, {_id: product._id});});
            setTrendTimeRange(playType.cycleTypes[0]);
        };

        $scope.onThresholdProductChange($scope.trendKpi);

        $scope.generateOCRReport = function(user){
            $state.go('userFlow.oneClickReport',{selectedPlayerIdForOCR:user._id});
        };

        $scope.onMetricChange($scope.params.selectedMetric);
        //initializePageOptions();

        function setSalesUnit() {
            var unit;
            if($scope.params.isActualSales){
                unit = $scope.params.kpi.unit;
            }else{
                unit = '% of Tgt.';
            }
            if($scope.params.selectedMetric.id == 'mtd'){
                $scope.params.unit = unit;
            }else{
                $scope.params.unit2 = unit;
            }

        }
        function getCycles() {
            let kpiId = $scope.params.kpi._id, monthType = p0.currentMonthlyCycle.subType;
            if(kpiId != 'overall'){
                const division = _.find(p0.allDivisions, function (division) {
                    return division.kpis.find(k => k._id.toString() === kpiId.toString());
                });
                if(division){
                    monthType = division.monthType;
                }
            }
            return _.filter(p0.allMonthlyCycles, {subType: monthType});
        }
    });
