angular.module('bitnudgeApp')
    .controller('editConfigCtrl', function ($http, $scope,Upload, $mdDialog, toaster) {
        $scope.configs;
        $scope.types = ['Integer','String','Boolean','Array'];
        function init(){
            $scope.configs = []
            $http.post('/api/config/index').then(function(configs){
                configs=configs.data;
                $scope.newConfig ={};
                $scope.configs = _.sortBy(configs,'key');
                $scope.configs.unshift({_id:null, key:'Create new', value:null});
                $scope.oldConfig = {config:$scope.configs[0]};
            });
        };
        init();
        $scope.validateConfig = function(){
            if($scope.newConfig._id){
                $scope.updateConfig();
            }else
                $scope.addConfig();
        };
        $scope.addConfig = function(){
            if(_.find($scope.configs,{key:$scope.newConfig.key})){
                var confirm = $mdDialog.confirm()
                    .title('Duplicate key, please change the config or update the old config')
                    .targetEvent(event)
                    .ok('Ok');
                $mdDialog.show(confirm).then(function() {

                });
                // alert('Duplicate key, please change the config or update the old config');
                return;
            }
            $http.post('/api/config/create/',{type:$scope.newConfig.type, key:$scope.newConfig.key, value:$scope.newConfig.newValue}).then(function (result) {
                toaster.pop("success", $scope.newConfig.key+" config successfully created!");
                init();
            },function(error){
                toaster.pop("error", "Error in creating the new config!");
                notification('Error',error.data);
            });
        };
        $scope.reset = function(){
            $scope.oldConfig.config = $scope.configs[0];
            $scope.newConfig = {};
        };
        $scope.deleteConfig = function(config){
            var confirm = $mdDialog.confirm()
                .title('Are you sure? you want to delete "'+config.key+ '" config')
                .targetEvent(event)
                .ok('Yes')
                .cancel('No');
            $mdDialog.show(confirm).then(function() {
                    $http.post('/api/config/archive', {id:config._id}).then(function (result) {
                    result=result.data;
                    toaster.pop("Danger", config.key+" config deleted!");
                    init();
                })
            });
        };
        $scope.updateConfig = function(){
            $scope.newConfig.value = $scope.newConfig.newValue;
            var confirm = $mdDialog.confirm()
                .title('Are you sure? you want to update "'+$scope.newConfig.key+ '" config value as '+ $scope.newConfig.value)
                .targetEvent(event)
                .ok('Yes')
                .cancel('No');
            $mdDialog.show(confirm).then(function() {

                $http.post('/api/config/update',{config:$scope.newConfig}).then(function (result) {
                    toaster.pop("success", $scope.newConfig.key+" config successfully updated!");
                    init();
                },function(error){
                    notification('Error',error.data);
                    toaster.pop("error", "Error in updating the config value!");

                })
            });
        };
        function notification(type, error){
            var options = {
                body : error,
                noscreen: true
            };
            var errorNotification = new Notification(type, options);
            errorNotification.close.bind(errorNotification);
        }

        $scope.changeConfig = function(){
            if($scope.oldConfig.config._id){
                $scope.newConfig = $scope.oldConfig.config;
            }else{
                $scope.newConfig ={};
            }
        }
    });
