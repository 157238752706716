const autoImportNgTemplateLoaderTemplate1 = require('../html/lms/customerTimeline.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/customers/addCustomer.html');
const autoImportNgTemplateLoaderTemplate3 = require('../html/customers/interactionHistory.html');
const autoImportNgTemplateLoaderTemplate4 = require('../html/customers/exportCustomers.html');
const autoImportNgTemplateLoaderTemplate5 = require('../html/customers/filterCustomer.html');

const { USER_PERMISSIONS } = require("../types/permissions");

angular.module('bitnudgeApp')
    .controller('listCustomersCtrl', function ($scope, $rootScope, p0, Auth, CustomerService, $mdDialog,  $stateParams, $state, toaster) {
        // this variable will be used to store the current filters applied
        var filterOptions = {};
        $scope.query = {
            order: 'name',

        };

        let selectedUserStore = null
		$scope.me = Auth.getCurrentUser();
		$scope.viewTaggedCustomer = $rootScope.permissions.includes(USER_PERMISSIONS.viewTaggedCustomer);
		$scope.exportCustomers = $rootScope.permissions.includes(USER_PERMISSIONS.exportCustomers);
		$scope.retagCustomer = $rootScope.permissions.includes(USER_PERMISSIONS.retagCustomer);
		$scope.searchNonTaggedCustomer = $rootScope.permissions.includes(USER_PERMISSIONS.searchNonTaggedCustomer);
		if(!$scope.viewTaggedCustomer) {
			setTimeout(()=>{
				$scope.showUnassignedCustomers();
			}, 0);
		}

		const {strings} = p0;
        $scope.CUI_LABEL = strings && strings.CUI_LABEL && strings.CUI_LABEL.value || 'NRIC'
        if ($rootScope.cloned) {
            $scope.searchTypes = [
                {advancedSearch: true, name: "All Customers"}
			] 
        } else {
            $scope.searchTypes = [
                {advancedSearch: false, name: "My Customers"},
                {advancedSearch: true, name: "All Customers"}
			]
        }

        $scope.selectedSearchType = $scope.searchTypes[0]

        $scope.strings = p0.strings;
        $scope.pagination = CustomerService.getPaginationObj();
        $scope.selected = {};
        $scope.isFrontline = $rootScope.isFrontline;
        $scope.isSeniorManager = $rootScope.isSeniorManager
        $scope.dataLoaded = false;
        $scope.filterObj = {advancedSearch: false, exact_name: ""};
        $scope.sortKey = 'portfolioValue';
        $scope.sortOrder = 'desc';

        $scope.customerFilters = {
            showAll : true,
            unassigned: false,
            filtered : false
        }

        $scope.topLevelTabs = {
            SHOWALL : 'showAll',
            UNASSIGNED : 'unassigned'
        }

        $scope.selectedTab = $scope.topLevelTabs.SHOWALL


        function disableAllFilters(){
            Object.keys($scope.customerFilters).forEach(function(key){
                $scope.customerFilters[key] = false;
            })
        }

        function getCustomersData(userId, options){

            $scope.dataLoaded = false;
            var sortingData = {
                sortKey: $scope.sortKey,
                sortOrder: $scope.sortOrder
            }
            var externalParams = {};
            if ($stateParams.externalParams && !_.isEmpty($stateParams.externalParams)) {
                externalParams = $stateParams.externalParams;
            }
            CustomerService.getCustomers(userId,$scope.pagination, options, sortingData, $scope.customerFilters, externalParams)
                .then(function(data){
                    setPaginationData(data);
                    $scope.totalCustomers = data.total
                    $scope.customers = data.customers;
                    $scope.customersTemp = data.customers;
                    $scope.dataLoaded = true;

                    $scope.taggedCustomersCount = 0;
                    $scope.unassignedCustomerCount = 0;
                    $scope.customers.forEach(customer=>{
                        if(customer.activeHandler) {
                            $scope.taggedCustomersCount += 1;
                        }

                        else{
                            $scope.unassignedCustomerCount += 1;
                        }
                    })
                });
        };

        function init (user){
            if($scope.filterObj.exact_name){
                return;
            }
            $scope.customers;
          //  $scope.myKpis = p0.myKpis;
            $scope.myProducts = p0.myProducts;
            $scope.ticketsStatusList = p0.ticketsStatusList;
            getCustomersData(user._id, filterOptions);
            $scope.currentKpiIndex = 0;
            $scope.currentTicketStatusIndex = 0;
        }


        $scope.showLeadDrillDownButton = function(customer){
            customer.showLeadDrillDown = true;
        }

        $scope.hideLeadDrillDownButton = function(customer){
            customer.showLeadDrillDown = false;
        }

        $scope.showTicketDrillDownButton = function(customer){
            customer.showTicketDrillDown = true;
        }

        $scope.hideTicketDrillDownButton = function(customer){
            customer.showTicketDrillDown = false;
        }

        $scope.redirectToLeads = function(customer, productId){
            $state.go('lms.listLead', {customerIds: customer._id, userId: $scope.selected.user._id,productId: productId._id });
        }

        $scope.redirectToTickets = function(customer){
            $state.go('lms.tickets');
        }

        $scope.showTimeline = function(customer){
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'customerNotesTimelineController',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                fullscreen: true, // Only for -xs, -sm breakpoints.
                resolve:{
                    customer: function(){
                        return customer;
                    }
                }
            });
        }

        $scope.formatPhoneNumber = function(tel){
            if(tel){
                return tel.slice(0,4)+' '+tel.slice(4,6)+' '+tel.slice(6,9)+' '+tel.slice(9);
            }
        };

        $scope.addCustomer = function(event){
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate2,
                controller: 'addCustomerCtrl',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                targetEvent: event,
                fullscreen: true, // Only for -xs, -sm breakpoints.
            });
        }

        $scope.gotoDetails = function(customer, activeTab){
            $state.go('userFlow.customer',{customerId: customer._id, activeTab: activeTab});
        }

        $scope.showInteractionHistory = function(customer){
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate3,
                controller: 'interactionHistoryController',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                fullscreen: true, // Only for -xs, -sm breakpoints.
                resolve:{
                    customer: function(){
                        return customer;
                    }
                }
            });
        };

        $scope.nextPage = function () {
            $scope.pagination.offset += $scope.pagination.limit;
        	onApplyFilterCall($scope.filterObj, true);
        }

        $scope.previousPage = function () {
			$scope.pagination.offset -= $scope.pagination.limit;
			onApplyFilterCall($scope.filterObj, true);
        }

        function setPaginationData(data) {
            $scope.pagination.total = data.total;
            if (data.customers.length == 0) {
                $scope.filteredCount = 0;
            } else {
                if ($scope.pagination.offset + $scope.pagination.limit < $scope.pagination.total) {
                    $scope.filteredCount = ($scope.pagination.offset + 1) + '-' + ($scope.pagination.offset + $scope.pagination.limit);
                } else {
                    $scope.filteredCount = ($scope.pagination.offset + 1) + '-' + ($scope.pagination.total);
                }
            }
            if (($scope.pagination.offset + $scope.pagination.limit) >= $scope.pagination.total) {
                $scope.pagination.disableNext = true;
            } else {
                $scope.pagination.disableNext = false;
            }
            if ($scope.pagination.offset == 0) {
                $scope.pagination.disablePrevious = true;
            } else {
                $scope.pagination.disablePrevious = false;
            }
        }

        $scope.onReporteeChange = function (reportee) {
            if(!$scope.selected.user){
                $scope.selected.user =selectedUserStore
            }
        	if ($scope.selected.user && reportee._id == $scope.selected.user._id) {
        		return;
        	}
            $scope.selected.user = reportee;
            $scope.pagination.offset = 0;
        	init(reportee);
        }

        if ($rootScope.isFrontline) {
        	$scope.selected.user = $scope.me;
        	init($scope.selected.user);
        }else if ($stateParams.userId) {
            CustomerService.getUserById($stateParams.userId).then(user => {
                $scope.selectedUser = user;
            });
        }


        $scope.clearFilters = () => {
            $scope.customerFilters = {
                ...$scope.customerFilters,
                filtered : false
            }
            Object.keys($scope.filterObj).forEach(key => {
                if(!['name','exact_name','advancedSearch'].includes(key)){
                    delete $scope.filterObj[key]
                }
            })
            onApplyFilterCall($scope.filterObj, true);
        }


        $scope.downloadCustomerModal = function(ev){
            if ($rootScope.cloned && !$scope.filterObj.name) {
                toaster.pop('error', "Export Customer", "Please first search for a Customer to Export the Customer details");
                return;
            }
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate4,
                controller: 'exportCustomerCtrl',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose:true,
                fullscreen: true ,// Only for -xs, -sm breakpoints.
                resolve:{
                    parameters: function () {
                        return {
                            totalCustomers:$scope.pagination.total,
                            filterObj:$scope.filterObj,
                            currentUser:$scope.selected.user,
                            sortingObj: {
                                sortKey: $scope.sortKey,
                                sortOrder: $scope.sortOrder
                            },
                            customerFilters: $scope.customerFilters
                        };
                    }
                }
            })
        }


       const advancedSearchCallback = function(username) {
            if(username) {
                $scope.filterObj.advancedSearch = true;
                $scope.searchResult =true
                $scope.filterObj.exact_name=username;
                onApplyFilterCall($scope.filterObj, false)
            }
        }

        var onApplyFilterCall = function(updatedFilterObj, paginationRefreshRequired){
            //set the filters on to the state params

            if(!paginationRefreshRequired){
                $scope.pagination = CustomerService.getPaginationObj();
            }


            let filters = updatedFilterObj;
            let makeFilterApplied = true
            let options = {};
            if(!filters.advancedSearch && filters.name && filters.name.length){
                options.searchParam=filters.name;
                makeFilterApplied = false
            }else if(filters.advancedSearch && updatedFilterObj.exact_name && updatedFilterObj.exact_name.length) {
                options.searchParam=updatedFilterObj.exact_name;
                options.advancedSearch=filters.advancedSearch;
                makeFilterApplied = false
            }

            if(filters.ageRange){
                options.ageFrom=filters.ageRange.from;
                options.ageTo=filters.ageRange.to;
            }
            if(!isNaN(filters.minDeposit)){
                options.minDeposit=filters.minDeposit;
            }
            if(!isNaN(filters.maxDeposit)){
                options.maxDeposit=filters.maxDeposit;
            }
            // only in true case send the dnc
            if(filters.dnc && filters.dnc.value){
                options.dnc=filters.dnc._id;
            }
            if(filters.product){
                options.productCode=filters.product.productCode;
            }
            if(!_.isUndefined(filters.productCount) && !_.isNull(filters.productCount)){
                options +='&productCount='+filters.productCount;
                if(filters.exactCount === false || filters.exactCount === true){
                    options +='&exactCount='+filters.exactCount;
                } else {
                    options +='&exactCount=true'; //default value of exactCount is true
                }
            }
            if(filters.ticketStatus){
                options.ticketStatus=filters.ticketStatus._id;
            }
            if(filters.statusDateRange){
                options.statusDateRange=filters.statusDateRange._id;
            }
            $scope.filterObj.applied = (Object.keys(options).length !== 0) && (makeFilterApplied || $scope.filterObj.applied);
            $scope.customerFilters.filtered = $scope.filterObj.applied
            filterOptions = options;
            selectedUserStore = $scope.selected.user
            getCustomersData($scope.selected.user._id, options);
        }

        const onApplyFilterCallback = function(updatedFilterObj){
            $scope.customerFilters.filtered = true;
            updatedFilterObj.applied = true
            onApplyFilterCall(updatedFilterObj)
        }

        $scope.showFilters = function(){
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate5,
                controller: 'filterCustomerCtrl',
                clickOutsideToClose:true,
                fullscreen: false,
                resolve: {
                    filters:    function(){return   $scope.filterObj;},
                    callback:   function(){return   onApplyFilterCallback;}
                }
            });
        }

        $scope.showall = function(){
            $scope.customerFilters = {
                ...$scope.customerFilters,
                showAll : true,
                unassigned : false
            }

            $scope.selectedTab = $scope.topLevelTabs.SHOWALL
            onApplyFilterCall($scope.filterObj, false);
        }

        $scope.clearAppliedFilters = function(){
            $scope.filterObj.applied = false
            $scope.clearFilters()
        }

        $scope.showUnassignedCustomers = () => {
			if(!$scope.searchNonTaggedCustomer) {
				$scope.searchTypes = $scope.searchTypes.filter((item)=>{
					console.log(item);
					return item.advancedSearch !== true
				})
			}
            $scope.customerFilters = {
                ...$scope.customerFilters,
                showAll : false,
                unassigned : true
            }
            filterOptions = {};
            $scope.selectedTab = $scope.topLevelTabs.UNASSIGNED
            onApplyFilterCall($scope.filterObj, false);
        }

        $scope.showUnassigned = function(){
            $scope.showUnassignedCustomers()
        }

        $scope.search_enter = function(keyEvent, advancedSearch, name) {
            if (keyEvent.which === 13)
               {
                   if(advancedSearch && name)
                    advancedSearchCallback(name)
                   else if($scope.filterObj.name && $scope.filterObj.name.length)
                    $scope.searchCustomers()
               }
        }

        $scope.searchCustomers = function(){
        	if(!$scope.filterObj.name) return;
            $scope.searchResult = true;
            onApplyFilterCall($scope.filterObj, false)
        }
        $scope.resetSearchCustomers = function(){
            if((!$scope.filterObj.name || !$scope.filterObj.name.length) && (!$scope.filterObj.exact_name || !$scope.filterObj.exact_name.length)){
                return
            }
            $scope.filterObj.name = ""
            $scope.searchResult = false;
            $scope.filterObj.exact_name = "";
            $scope.pagination = CustomerService.getPaginationObj();
            onApplyFilterCall($scope.filterObj, false)
        }

        $scope.onHeaderClick = (sortKey) => {
            $scope.sortKey = sortKey;
            if ($scope.sortOrder === 'asc') {
                $scope.sortOrder = 'desc';
            } else {
                $scope.sortOrder = 'asc';
            }
            $scope.pagination = CustomerService.getPaginationObj();
            getCustomersData($scope.selected.user._id, filterOptions);
        }

        $scope.onSearchTypeChange = (selectedSearchType) => {
            if(selectedSearchType.advancedSearch){
                $scope.filterObj.advancedSearch = true
            }else{
                $scope.filterObj.advancedSearch = false
            }
            $scope.resetSearchCustomers()
        }

        // $scope.assignCustomer = function(customer, ev) {
        //         ev.stopPropagation();
        //         $mdDialog.show({
        //             templateUrl: '../html/customers/reassignCustomerModal.html',
        //             controller: 'reassignCustomerModalCtrl',
        //             parent: angular.element(document.body),
        //             targetEvent: ev,
        //             clickOutsideToClose:true,
        //             fullscreen: true, // Only for -xs, -sm breakpoints.
        //             resolve:{
        //                 customer: function(){
        //                     return customer;
        //                 },
        //                 getCustomersData: function(){
        //                     return getCustomersData;
        //                 },
        //                 secondaryHandler: function(){
        //                     return false
        //                 },
        //                 selectedUser: function(){
        //                     return $scope.selectedUser
        //                 },
        //                 frontLineList: function () {
        //                     return $scope.frontLineList;
        //                 }
        //             }
        //         })
        // }

        $scope.showFilterMenu = false;

    });
