'use strict';
angular.module('bitnudgeApp')
    .service('leagueAnalytic', function ($http, $q) {
        var _this = this, analytics = {}; 
        this._fetchLeagueAnalytic = function (leagueId, cycleId) {
            if (!analytics[cycleId][leagueId]) {
                var deferred = $q.defer();
                const postBody = {
                    leagueId, cycleId
                }
                $http.post('/api/leagueAnalytics/service', postBody)
                    .then(function(result) {
                        result=result.data;
                        analytics[cycleId][leagueId] = result;
                        deferred.resolve(analytics[cycleId][leagueId]);
                    });
                analytics[cycleId][leagueId] = deferred.promise;
            }
            return $q.when(analytics[cycleId][leagueId]);
        };
        this.fetchLeagueAnalytic = function (leagueId, cycleId) {
            if(!leagueId || !cycleId){return $q.resolve(null);}
            if(!analytics[cycleId]) analytics[cycleId] = {};

            return this._fetchLeagueAnalytic(leagueId, cycleId);
                // .then(function(analytic) {
                //     $q.resolve(analytic);
                // });
        };

        this.fetchLeagueAnalyticsOfCycle = function(leagueIds,cycleId){
            if(!analytics[cycleId]) analytics[cycleId] = {};
            var toFetch = _.filter(leagueIds,function(leagueId){
                return !analytics[cycleId][leagueId] || analytics[cycleId][leagueId].then;
            });
            if(toFetch.length) {
                return $http.post('/api/leagueAnalytics/getLeaguesOfCycle', {leagues: toFetch, cycleId}).then(function (leagueAnalytics) {
                    leagueAnalytics=leagueAnalytics.data;
                    leagueAnalytics.forEach(function (leagueAnalytic) {
                        analytics[cycleId][leagueAnalytic.leagueId] = leagueAnalytic;
                    });
                    var sendArray = [];
                    leagueIds.forEach(function (leagueId) {
                        sendArray.push(analytics[cycleId][leagueId]);
                    });
                    return sendArray;
                });
            }else{
                var sendArray = [];
                leagueIds.forEach(function (leagueId) {
                    sendArray.push(analytics[cycleId][leagueId]);
                });
                return $q.resolve(sendArray);
            }
        };
        this.destroy = function(){
            analytics = {};
        }
    });
