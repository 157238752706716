angular.module('bitnudgeApp').controller('docUploadModalCtrl', function ($scope, $http, lead, Upload, docImage ,$rootScope, toaster, UtilService) {
    $scope.lead = lead
    $scope.$watch('files',function(newValue,oldValue,scope) {
        if (newValue){
            $scope.onFileSelect(newValue);
        }
    });

    $scope.onFileSelect = function ($files) {
        for (var i = 0; i < $files.length; i++) {
            var $file = $files[i];
        var fileName = $files[i].name.split(".");
        var fileType = fileName[fileName.length - 1].toLowerCase();
        if (fileType == "jpg" ||fileType == "jpeg" || fileType == "png") {
            Upload.upload({
                url: '/api/leads/picture/addImage',
                data: {_id: lead.customerId._id},
                file: $file,
            }).then(function (data, status, headers, config) {
            }).catch(function (data, status, headers, config) {
            }).then(function (resp) {
                docImage(lead, resp.data.image);
                $scope.lead = lead;
            });
        } else {
            toaster.pop('error', "Submission.", "Upload '.png', '.jpg' Types Only.");
        }
        }
    };
    $scope.removeFile =function (filepath) {
        var data={
            leadId: lead.customerId._id,
            filePath: filepath
        };
        $http.post('/api/leads/picture/removeImage', data).then(function (response) {
            response=response.data;
            _.remove(lead.verificationDoc, function (doc) {
                return doc == filepath;
            });
            $scope.lead = lead;
        }).catch(function (response) {
        });
    };

    $scope.openImage = function (document) {
        var url = $rootScope.config.domain+'/'+document;
        UtilService.downloadFile(url);
    };

});
