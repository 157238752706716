'use strict';
angular.module('bitnudgeApp')
	.service('AdminDashboardService', function ($http, $q) {
		const self = this;
		this.fetchFilters = () => {
			const url = `/api/adminActions/filters`;
			const deferred = $q.defer();
			$http.post(url).then(
				result => {
					deferred.resolve(result);
				},
				error => {
					deferred.reject(error);
				}
			);
			return deferred.promise;
		}

		this.fetchAdminAction = (url, postBody) => {
			const deferred = $q.defer();
			$http.post(url, postBody).then(
				result => {
					deferred.resolve(result);
				},
				error => {
					deferred.reject(error);
				}
			);
			return deferred.promise;
		}
	})