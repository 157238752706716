const autoImportNgTemplateLoaderTemplate1 = require('../html/reporteeFilters.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('reporteeFilters', function () {
        return {
            name: 'reporteeFilters',
            scope: {
                onReporteeChange:               '=',
                selectedDivision:               '=',
                selectedPlayType:               '=',
                selectedRoleType:               '=',
                selectedReportee:               '=',
                onDivisionChangeListener:       '=',
                onPlayTypeChangeListener:       '=',
                showMyRole:                     '=',
                addOverallInReportees:          '=',
                addAllDivisions:                '=',
                addAllPlayTypes:                '=',
				selectedCycle:                  '=',
                color:                          '@',
                selectedKpi:                    '=',
                showReporteeList:               '=',
                reporteeList:                   '=',
                onRoleTypeChangeListener:       '=',
                user:                           '=',
                hideDivision:                   '=',
                hidePlaytype:                   '=',
                addOwnUserEntry:                '@',
            },
            controller: 'reporteeFiltersCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
