'use strict';

angular.module('bitnudgeApp').controller('adminFiltersController', function ($scope, $mdDialog, filters, appliedFilters, onApplyFilterCall) {
	$scope.filters = filters;
	$scope.selected = {
		dates: {}
	};
	const {
		dates,
		user,
        dataType,
        searchParam
	} = appliedFilters;
	if (dates && dates.start) {
		$scope.selected.dates.start = dates.start;
	}
	if (dates && dates.end) {
		$scope.selected.dates.end = dates.end;
	}
	if (user) {
		$scope.selected.user = filters.users.find(u => u._id.toString() === user._id.toString())
	}
	if (dataType) {
		$scope.selected.dataType = filters.dataTypes.find(d => d._id === dataType._id);
    }
    if(searchParam){
        $scope.selected.searchParam = searchParam;
    }
	$scope.now = new Date();

	$scope.applyFilter = function () {
		const {
			dates,
			user,
			dataType,
			searchParam
        } = $scope.selected;
		$scope.selected.applied = (dates && !!Object.keys(dates).length) || !!user || !!dataType || !!searchParam;
		onApplyFilterCall($scope.selected);
		$mdDialog.hide();
	}

	$scope.removeFilter = function (filter) {
		switch (filter) {
			case 'dates':
				$scope.selected.dates = null;
				break;
			case 'user':
				delete $scope.selected.user;
				break;
			case 'dataType':
				delete $scope.selected.dataType;
				break;
			case 'searchParam':
				delete $scope.selected.searchParam;
			default:
				break;
		}
	};

	$scope.onClose = () => {
		$mdDialog.hide();
	};
});