'use strict';

angular.module('bitnudgeApp')
    .service('Reportees', function ($http, $q, p0, Auth) {
        let self = this;
        self.reportingTree = {};
        var myLeaves = {};
        this.fetchReportingTree = function (userId, playTypes) {
            var me = Auth.getCurrentUser();
            if (!self.reportingTree[userId]) {
                var treeJobRoles = [];
                _.forEach(playTypes, function (playType) {
                    treeJobRoles = treeJobRoles.concat(playType.frontline);
                    treeJobRoles = treeJobRoles.concat(playType.managers);
                    treeJobRoles = treeJobRoles.concat(playType.seniorManagers)
                });
                var deferred = $q.defer();
                const postBody = {
                    userId,
                    jobRoles:JSON.stringify(treeJobRoles)
                }
                $http.post('/api/users/playersOfJobRoles', postBody)
                    .then(function (reportees) {
                        self.reportingTree[userId] = reportees.data;
                        if (userId == me._id) {
                            _.forEach(self.reportingTree[userId], function (reportee) {
                                myLeaves[reportee._id] = true;
                            });
                        }
                        deferred.resolve(self.reportingTree[userId]);
                    })
                    .catch(function (error) {
                        self.reportingTree[userId] = null;
                        deferred.reject(error);
                    });
                self.reportingTree[userId] = deferred.promise;
            }
            return $q.when(self.reportingTree[userId]);
        };

        this.isMyLeaf = function (reporteeId) {
            var me = Auth.getCurrentUser();
            return this.fetchReportingTree(me._id, p0.myPlayTypes)
                .then(function (reportees) {
                    var found = myLeaves[reporteeId];
                    return found;
                });
        };

        this.destroy = function () {
            self.reportingTree = {};
            myLeaves = {};
        }
    });
