const autoImportNgTemplateLoaderTemplate1 = require('../html/gpModal.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('gpPlanCtrl', function ($scope, $timeout, $http, $rootScope, Auth, toaster, $state, getGPService, p0, numberFilter, $mdDialog) {
    $scope.config = p0.config;
    $scope.strings = $rootScope.strings;
    var gpActivities = ['call', 'submission', 'newLead', 'throughput'];
    $scope.importantActivities = getGPService.getImportantActivities();
    $scope.me = Auth.getCurrentUser();
    $scope.isRate=  true;
    if($scope.config.targetPeriod == 'day'){
        $scope.isRate=  false;
    }
    $scope.goToGoalSummary = function(){
        $state.go('userFlow.gameplan', {selectedPlayerId: $scope.me._id, cycleId:$scope.selectedCycle._id});
    };
    if($state.params.selectedPlayerId)
        $scope.selectedPlayerId = $state.params.selectedPlayerId;
    else
        $scope.selectedPlayerId = $scope.me._id;
    if($state.params.cycleId){
        $scope.cycles = [p0.currentMonthlyCycle];
        $scope.selectedCycle=_.find(p0.allMonthlyCycles,{_id:$state.params.cycleId});
    }else
        $scope.selectedCycle = p0.currentMonthlyCycle;
    var players;
    $http.post('api/users/getUserById',{userId: $scope.selectedPlayerId}).then(function(user){
        user= user.data && user.data.data;
        $http.post('api/users/me/children', {userId: $scope.selectedPlayerId, select: ['name','systemRole']}).then(function(reportees){
            reportees=reportees.data;
            players = reportees;
            $scope.hideZones = _.find(reportees,{systemRole:'seniorManager'});
            players.unshift({name:'All Reportees',_id:'overall'});
            $scope.playersList = players;
            $scope.selectedPlayer = players[0];
        });
    });

    $http.post('api/users/getParentTrail',{playerId: $scope.selectedPlayerId}).then(function(result) {
        $scope.trailList = result.data;
    });

    $scope.getGP = function(userId){
        if(!userId) userId = $scope.me._id;
        getGPService.getGoal(userId,$scope.selectedCycle, true)
            .then(function(result){
                $scope.goals=result;
                $scope.selectedKPI = _.find($scope.goals,   function(goal){return (goal.kpiId == $state.params.selectedKPIId)});
                if(!$scope.selectedKPI)
                    $scope.selectedKPI = $scope.goals[0];
                if($scope.selectedKPI){
                    $scope.setSelectedKPI($scope.selectedKPI);
                    $scope.setRateIndicators();
                }
            });
    };

    $scope.setRateIndicators = function () {
        getGPService.getRateIndicator($scope.importantActivities[$scope.activityIndex], $scope.selectedKPI, p0.currentMonthlyCycle._id).then(function (args) {
            var rateColor = args[0], rateText = args[1];
            $scope.selectedKPI[$scope.importantActivities[$scope.activityIndex].fieldName].trafficColor = rateColor;
            $scope.selectedKPI[$scope.importantActivities[$scope.activityIndex].fieldName].trafficText = rateText;
        });
    };

    $scope.getGP($scope.selectedPlayerId);

    $scope.setSelectedKPI = function(goal) {
        if(!goal.newGpValue)
            goal.newGpValue = 0.00;
        if(!goal.gpRunRate)
            $scope.selectedKPI.gpRunRate = 0.00;
        if(goal!=null){
            if (goal.gameplanRunRate<0) {
                $scope.showSaveBtn = false;
                $scope.grrValidity = false;

                $scope.showSaveBtnInReportee=false;
                $scope.grrValidityInReportee = false;
            }else{
                $scope.showSaveBtn = true;
                $scope.grrValidity = true;

                $scope.showSaveBtnInReportee=true;
                $scope.grrValidityInReportee = true;
            }
            $scope.gameplanRunRate =$scope.getGamePlanRunRateOfGoal($scope.selectedKPI, $scope.selectedKPI.gameplanTarget.value);
            $scope.grrWeeklyPlan = $scope.gameplanRunRate;
        }

    };

    $scope.getReporteesData = function(goal, userId){
        if(!userId) userId = $scope.me._id;

        $http.post('/api/gamePlans/getGamePlan/cycles/children', {cycleId: $scope.selectedCycle._id, kpiId: goal.kpiId, userId}).then(function(reportees) {

            reportees=reportees.data;
            //initialise all gameplan data of each reportee
            $scope.reporteeKPILookUp = {};
            for (var j = 0, len1 = reportees.length; j < len1; j++) {
                reportees[j].kpiData = getGPService.initGoalsForReportee(userId, reportees[j].kpiData, $scope.selectedCycle._id);
            }

            for (var j = 0, len1 = reportees.length; j < len1; j++)
            {
                for(var i = 0, len2 = reportees[j].kpiData.length; i < len2; i++) {
                    if (reportees[j].kpiData[i].newGpValue == undefined) {
                        reportees[j].kpiData[i].newGpValue == 0;
                    }
                    var kpiId = reportees[j].kpiData[i].kpiId._id;
                    if (!$scope.reporteeKPILookUp[kpiId])
                        $scope.reporteeKPILookUp[kpiId] = [];
                    $scope.reporteeKPILookUp[kpiId].push(reportees[j].kpiData[i]);
                    $scope.reporteeKPILookUp[kpiId][$scope.reporteeKPILookUp[kpiId].length - 1]['userId'] = reportees[j].user
                }
            }
            if(goal)
                $scope.initReportees(goal);
        });
    };

    $scope.initReportees = function(goal){
        $scope.reporteesOfSelectedGoal = $scope.reporteeKPILookUp[goal.kpiId];
        _.forEach($scope.reporteesOfSelectedGoal, function (reportee) {
            reportee.isChanged = false;
        });
        $timeout(function() {
            $scope.updatePlan();
            $scope.setRateIndicators();
        });
    };

    $scope.onCycleChange=function(){
        $scope.getGP($scope.selectedPlayerId);
    };

    $scope.getReporteeDataforKPI = function(goal) {
        if(goal) {
            $scope.setSelectedKPI(goal);
            $scope.getReporteesData(goal, $scope.selectedPlayerId);
        }
    };
    $scope.onSWOTChange = function (swot) {
        $scope.selectedSWOTCategory = swot;
        var playerOfSWOT = [];
        if(swot.value != 'all'){
            _.forEach($scope.reporteesOfSelectedGoal,function(goal){
                if(goal.swot == swot.value)
                    playerOfSWOT.push(goal.userId._id);
            });
            $scope.playersList = _.filter(players, function (player) {
                return playerOfSWOT.indexOf(player._id) > -1;
            });
            $scope.playersList.unshift({name:'All Reportees',_id:'overall'});
            $scope.selectedPlayer = $scope.playersList[0];
        }else{
            $scope.playersList = players;
        }

    };
    $scope.onPlayersChange = function(player) {
        $scope.selectedPlayer = player;
        if(player._id != 'overall' && !$scope.hideZones){
            var swot = _.find($scope.reporteesOfSelectedGoal,function (goal) {
                return goal.userId._id == player._id;
            }).swot;
            $scope.selectedSWOTCategory = _.find($scope.swotCategories, {value : swot});
        }
    };

    $scope.updatePlan = function() {
        var mgrVal = parseFloat($scope.selectedKPI.newGpValue).toFixed(2);
        var reporteeSumVal = 0;
        if($scope.reporteesOfSelectedGoal){
            $scope.reporteesOfSelectedGoal.forEach(function(goal){
                if(!goal.newGpValue)
                    goal.newGpValue = 0.00;
                reporteeSumVal += parseFloat(goal.newGpValue);
                $scope.reporteeGamePlanGap = Math.abs(reporteeSumVal - mgrVal).toFixed(2)
            });

            var status = reporteeSumVal >= mgrVal ? 1 : 0;
            if (status==1) {
                $scope.simulationBorderStyle= 'validSimulation-border-style';
                $(".box-color").css({"background":"#E0EEC8"});
                $("#gp-signal-reportee").css({"background":"#E0EEC8"});
                $(".box-color").removeClass('swot-red');
                $(".box-color").addClass('swot-green');
            }else{
                $scope.simulationBorderStyle= 'invalidSimulation-border-style';
                $(".box-color").css({"background":"#F0D7CF"});
                $("#gp-signal-reportee").css({"background":"#F0D7CF"});
                $(".box-color").removeClass('swot-green');
                $(".box-color").addClass('swot-red');
            }
        }
        $scope.grrWeeklyPlan = $scope.selectedKPI.gpRunRate;
        if($rootScope.operations)
            $scope.updateWeeklyPlanTable();
        else
            $scope.updateReqForPlan();
        $scope.grrValidity = $scope.grrWeeklyPlan > 0;
    };

    $scope.changeFormatOfData = function(num){
        if(num != undefined){
            var stringNum = num.toString();

            var decimal = (stringNum.split(".")[1] == undefined ? "" : "."+stringNum.split(".")[1]);
            stringNum = stringNum.split(".")[0];
            var sign = "";
            if(parseFloat(num) < 0) {
                sign = "-";
                stringNum = stringNum.split("-")[1];
            }
            return (sign + stringNum.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + decimal);
        }
    };

    $scope.cancelGamePlanForGoal= function(goal){
        for(var i = 0; i < $scope.reporteesOfSelectedGoal.length; i++) {
            if(parseFloat($scope.reporteesOfSelectedGoal[i].gameplanTarget.value) == 0){
                $scope.reporteesOfSelectedGoal[i].newGpValue= 0
            }
            $scope.reporteesOfSelectedGoal[i].gameplanPercentage = parseFloat($scope.reporteesOfSelectedGoal[i].gameplanTarget.value)/$scope.reporteesOfSelectedGoal[i].target.value*100;
        }
        goal.gameplanPercentage = parseFloat(goal.gameplanTarget.value)/goal.target.value*100;
    }

    $scope.saveGamePlanForGoal = function(goal){
        var playersData=[];
        var mgrData = {};
        var mgrGpValue = goal.newGpValue*goal.denom;
        mgrData.gameplanTarget = mgrGpValue;
        playersData[0]= mgrData;

        for(var i = 0; i < $scope.reporteesOfSelectedGoal.length; i++) {
            var reporteeData={};
            if($scope.reporteesOfSelectedGoal[i].isChanged){
                reporteeData.userId = $scope.reporteesOfSelectedGoal[i].userId._id;
                reporteeData.managerValue = $scope.reporteesOfSelectedGoal[i].newGpValue*$scope.reporteesOfSelectedGoal[i].denom;
                $scope.reporteesOfSelectedGoal[i].managerValue =  $scope.reporteesOfSelectedGoal[i].newGpValue;
                playersData.push(reporteeData);
            }
        }
        goal.gameplanPercentage = goal.newGpValue/goal.target*100;
        $scope.selectedKPI.gameplanTarget.value = parseFloat(goal.newGpValue);
        let cycleId = $scope.selectedCycle._id;
        let kpiId = $scope.selectedKPI.kpiId;
        $http.post('/api/gamePlans/setManagerValue', {playersData, cycleId, kpiId}).then(function(goal) {
            goal=goal.data;
            toaster.pop('success', "Save", "Your GamePlan is successfully saved");
        });
        $scope.grrValidity = true;
        goal.gameplanTarget.value= goal.newGpValue;
        $scope.getReporteesData($scope.selectedKPI, $scope.selectedPlayerId);
    };

    $scope.swotCategories = [
        {name: "All "+$scope.strings.SWOT_SWOT_PL.value,           value: "all"},
        {name: $rootScope.strings.SWOT_S.value,      value: "s"},
        {name: $rootScope.strings.SWOT_W.value,      value: "w"},
        {name: $rootScope.strings.SWOT_O.value,   value: "o"},
        {name: $rootScope.strings.SWOT_T.value,        value: "t"}
    ];

    $scope.selectedSWOTCategory = $scope.swotCategories[0];


    $scope.getswotCategoryForReporteeGP=function(goal){
        if(!goal || !goal.swot)   return ;
        $scope.swotCategory = goal.swot;
        return _.find($scope.swotCategories, {value: goal.swot}).value;
    };

    $scope.showInfoModal = function(selectedPlayer, type){
        if(type == 'activity'){
           type = $scope.importantActivities[$scope.activityIndex].fieldName;
        }
        getGPService.showModals($scope.me,$scope.selectedKPI, type);
    };

    $scope.showLegendInfoModal = function(){
        getGPService.showLegendModal();
    };

    $scope.showReporteeModal = function(selectedKPI){
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate1,
            controller: 'gamePlanModalCtrl',
            size: 'lg',
            resolve: {
                selectedPlayer: function () {
                    return selectedKPI.userId;
                },
                selectedKPI: function () {
                    return selectedKPI;
                },
                type : function () {
                    return 'kpi';
                }
            }
        });
    };

    $scope.getGamePlanRunRateOfGoal = function(goal,gameplanValue){
        return ((((gameplanValue-goal.achievedValue)/$rootScope.daysLeft)*5).toFixed(2));
    };

    $scope.showValue= true;

    $scope.$watch('selectedKPI', function(newValue, oldValue) {
        if (newValue != oldValue) {
            $scope.getReporteeDataforKPI(newValue);
        }
    });

    $scope.getPlanOfSelectedPlayer = function (goal, userId) {
        var params = {selectedPlayerId: userId};
        if(goal)
            params = {selectedPlayerId: userId, selectedKPIId: goal.kpiId._id};
        $state.go('userFlow.gameplanPlan', params);
    };
    $scope.activityIndex = 0;
    $scope.toggleActivities = function(val){
        if(val){
            if($scope.activityIndex < $scope.importantActivities.length-1){
                $scope.activityIndex++;
                while($scope.activityIndex < $scope.importantActivities.length-1 && !$scope.selectedKPI[$scope.importantActivities[$scope.activityIndex].fieldName]){
                    $scope.activityIndex++;
                }

                if(!$scope.selectedKPI[$scope.importantActivities[$scope.activityIndex].fieldName]){
                    while($scope.activityIndex >0 && !$scope.selectedKPI[$scope.importantActivities[$scope.activityIndex].fieldName]){
                        $scope.activityIndex--;
                    }
                }
            }
        }else{
            if($scope.activityIndex > 0){
                $scope.activityIndex--;
                while($scope.activityIndex >0 && !$scope.selectedKPI[$scope.importantActivities[$scope.activityIndex].fieldName]){
                    $scope.activityIndex--;
                }
                if(!$scope.selectedKPI[$scope.importantActivities[$scope.activityIndex].fieldName]){
                    while($scope.activityIndex < $scope.importantActivities.length-1 && !$scope.selectedKPI[$scope.importantActivities[$scope.activityIndex].fieldName]){
                        $scope.activityIndex++;
                    }
                }
            }
        }
        $scope.setRateIndicators();
    };




    $scope.updateWeeklyPlanTable = function(){
        if($scope.grrWeeklyPlan >= 0) {
            if($scope.selectedKPI.kpiType == 'value') {
                $scope.impliedDocument = getGPService.getImpliedRunRateKpi($scope.selectedKPI);
            }
            else if($scope.selectedKPI.kpiType == 'percentage'){
                $scope.impliedAccuracy = getGPService.getImpliedRunRateKpi($scope.selectedKPI);
            }
        }
        else{
            $scope.impliedDocument = '-';
            $scope.impliedAccuracy = '-';
        }
        if($scope.impliedAccuracy <= 0) $scope.impliedAccuracy =  0;
    };

    $scope.updateReqForPlan = function(){
        $scope.importantActivities.forEach(function(activity){
            if($scope.selectedKPI[activity.fieldName]) {
                if($scope.config.targetPeriod == 'month'){
                    $scope.selectedKPI[activity.fieldName].reqPlan = getGPService.getTargetActivitiesForMonthType($scope.selectedKPI.kpiId,$scope.selectedKPI.newGpValue * $scope.selectedKPI.denom, $scope.selectedKPI.activityConversionRatios[activity.fieldName].value, $scope.selectedKPI.averageTicketSize.value);
                    $scope.selectedKPI[activity.fieldName].reqRatePlan = parseFloat($scope.selectedKPI[activity.fieldName].reqPlan / $rootScope.daysTotal).toFixed(2);
                }else{
                    $scope.selectedKPI[activity.fieldName].reqPlan = getGPService.getTargetThroughputRate('monthly', $scope.selectedKPI, false);
                    if($scope.selectedKPI['accuracyp'].value ==0 || $scope.selectedKPI['occupancy'].value == 0){
                        $scope.selectedKPI[activity.fieldName].reqPlanActual = 0;
                    }else{
                        $scope.selectedKPI[activity.fieldName].reqPlanActual = $scope.selectedKPI[activity.fieldName].reqPlan / $scope.selectedKPI['accuracyp'].value / $scope.selectedKPI['occupancy'].value;
                    }
                }
            }
        });
        var submissionIndex = $scope.importantActivities.indexOf('submission');
        if(submissionIndex != -1 && $scope.selectedKPI[$scope.importantActivities[submissionIndex].fieldName]) {
            $scope.selectedKPI[$scope.importantActivities[submissionIndex].fieldName].reqRatePlan = $scope.selectedKPI[$scope.importantActivities[submissionIndex].fieldName].reqRatePlan * 5;
        }
    }
});
