const autoImportNgTemplateLoaderTemplate1 = require('../html/correlationsCard.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('correlationsCard', function () {
        return {
            name: 'correlationsCard',
            scope: {
                isStepGraph: '@',
                reporteeList: '=',
                cycle: '=',
                kpi: '='
            },
            controller: 'correlationsCardCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
