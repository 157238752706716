const autoImportNgTemplateLoaderTemplate1 = require('../html/leadMgrDashboard.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('leadOverview', function(){
        return {
            name: 'leadOverview',
            scope: {
                leadOverviewData: '=',
                widgetName: '=',
                configData: '=',
                selectedReportee: '=',
                dynamicParams: '=',
                changeProduct: '&',
                changeLeadType: '&'
            },
            controller: 'leadOverviewCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
