'use strict';
angular.module('bitnudgeApp')
    .service('activityUpdates', function ($http ,Auth, $q) {
        var currentUser = Auth.getCurrentUser();
        this.getData = function(kpiId,callback){
            return $http.post('/api/leads/getLeadsByUser', {userId:currentUser._id, kpiId}).then(function(leads){
                leads=leads.data;
                var finalData = {};
                var now = new Date();
                var yesterday = new Date(now);
                yesterday.setDate(now.getDate() - 1);
                var week = new Date();
                week = week.setDate(now.getDate() - now.getDay());
                var w = 0, y= 0, hot = 0,active = 0,lost = 0,followUpMissed = 0,activeYetToContact = 0, hotYetToContact = 0,riskOfLoss = 0, followUpPending = 0 ;
                var minTime = 4 * 60*60*1000;
                leads.forEach(function(lead){
                    var leadCreationDate = new Date(lead.leadCreationDate);
                    var followUpDate = new Date(lead.followUpDate);
                    var expiryTime = new Date(lead.expiryTime);
                    if(lead.archived){
                        lost++;
                    }else{
                        active++;
                        if(leadCreationDate > week){
                            w++;
                            if(leadCreationDate > yesterday){
                                y++;
                            }
                        }
                        if(lead.followers.length > 0){
                            hot++;
                            if(lead.histories.length == 0){
                                hotYetToContact++;
                            }
                        }
                        if(followUpDate < now){
                            if(now.getFullYear() != followUpDate.getFullYear() || now.getMonth() != followUpDate.getMonth() || now.getDate() != followUpDate.getDate()) {
                                followUpMissed++;
                            }else{
                                followUpPending++;
                            }
                        }else{
                            followUpPending++;
                        }
                        if(lead.histories.length == 0){
                            activeYetToContact++;
                        }
                        if(expiryTime - now < minTime && !lead.isAssigned){
                            riskOfLoss++;
                        }
                    }
                });
                finalData.y = y;
                finalData.w = w;
                finalData.activeLeads = active;
                finalData.p = finalData.activeLeads - finalData.w;
                finalData.hot = hot;
                finalData.lost = lost;
                finalData.followUpMissed = followUpMissed;
                finalData.activeYetToContact = activeYetToContact;
                finalData.hotYetToContact = hotYetToContact;
                finalData.riskOfLoss = riskOfLoss;
                finalData.followUpPending = followUpPending;
                return callback(finalData);
            });
        };
    });
