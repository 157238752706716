"use strict";

angular.module("bitnudgeApp").service("DocumentService", function($http, $q, p0, Auth, UtilService) {
	this.status = {
		all : ["uploaded", "active", "expired", "approved", "archived", "rejected", "reupload"],
		positive : ["uploaded", "active", "approved"],
		negative : ["expired", "archived", "rejected", "reupload"],
	};
	this.save = data => {
		const deferred = $q.defer();
		const url = "/api/documents/addOrUpdate";
		$http
			.post(url, data)
			.then(result => {
				if (result.data.status) {
					deferred.resolve(result.data.data);
				} else {
					deferred.reject(result.data.errors);
				}
			})
			.catch(err => {
				deferred.reject(err);
			});
		return deferred.promise;
	};
	this.update = data => {
		const deferred = $q.defer();
		const url = "/api/documents/addOrUpdate";
		$http
			.post(url, data)
			.then(result => {
				if (result.data.status) {
					deferred.resolve(result.data.data);
				} else {
					deferred.reject(result.data.errors);
				}
			})
			.catch(err => {
				deferred.reject(err);
			});
		return deferred.promise;
	};
	this.updateReadingTime = ({docId,readingToken,fileId,readingTimeinMs}) => {
		const deferred = $q.defer();
		let url = "/api/documents/updateReadingTime";
		$http
			.post(url,{
					docId,
					readingToken,
					fileId,
					readingTimeinMs
			})
			.then(result => {
				if (result.status) deferred.resolve(result.data);
				else deferred.reject(result.errors);
			})
			.catch(err => {
				deferred.reject(err);
			});
		return deferred.promise;
	};
	this.markActive = (data) => {
		const deferred = $q.defer();
		const url = "/api/documents/markActive";
		$http.post(url, data)
			.then(result => {
				deferred.resolve(result.data);
			})
			.catch(err => {
				deferred.reject(err);
			});
		return deferred.promise;
	};
	this.allTypes = () => {
		const deferred = $q.defer();
		let url = "";
		$http
			.get(url)
			.then(result => {
				if (result.status) deferred.resolve(result.data);
				else deferred.reject(result.errors);
			})
			.catch(err => {
				deferred.reject(err);
			});
		return deferred.promise;
	};

	this.getDocumentTypes = context => {
		const deferred = $q.defer();
		let url = "/api/documents/typeNames";
		$http
			.post(url, {
					context: context
			})
			.then(result => {
				if (result.data.status) deferred.resolve(result.data.data);
				else deferred.reject(result.data.errors[0]);
			})
			.catch(err => {
				deferred.reject(err);
			});
		return deferred.promise;
	};
	this.configById = (documentTypeId) => {
		const deferred = $q.defer();
		let baseUrl = '/api/documents/configById';
		let postBody = {};
        if (documentTypeId) {
			postBody.documentTypeId = documentTypeId;
        }
		$http.post(baseUrl, postBody)
			.then(result => {
				if(result.status){
					deferred.resolve(result.data);
				}
				else {
					deferred.reject(result.errors[0]);
				}
			})
			.catch(err => {
				deferred.reject(err.errors[0]);
			});
		return deferred.promise;
	};
	this.byId = docId => {
		const deferred = $q.defer();
		let url = "/api/documents/byId";
		$http
			.post(url,{docId})
			.then(result => {
				if (result.data.status) {
					deferred.resolve(result.data.data);
				} else {
					deferred.reject(result.data.errors[0]);
				}
			})
			.catch(err => {
				deferred.reject(err);
			});
		return deferred.promise;
	};
	this.carouselThumbnails = ({cui,documentIds, documentTypes,context,workflowId,isWorkflowRequired, isApproveMode})=>{
		const deferred = $q.defer();
		let url = "/api/documents/forCarousel";
		$http
			.post(url, { cui, documentTypes, documentIds, context, workflowId, isWorkflowRequired, isApproveMode })
			.then(result => {
				if (result.data.status) {
					deferred.resolve(result.data.data);
				} else {
					deferred.reject(result.data.errors[0]);
				}
			})
			.catch(err => {
				deferred.reject(err);
			});
		return deferred.promise;
	}
	this.getDocuments = ({ cui, onlyActive, status, productId, context }) => {
		const deferred = $q.defer();
		const url = "api/documents/index";
		$http
			.post(url, {
				cui,
				onlyActive,
				status,
				productId,
				context
			})
			.then(result => {
				if (result.data.status) {
					deferred.resolve(result.data.data);
				} else {
					deferred.reject(result.errors[0]);
				}
			})
			.catch(err => {
				deferred.reject(err);
			});
		return deferred.promise;
	};
	this.isValid = () => {
		const deferred = $q.defer();
		let url = "";
		$http
			.get(url)
			.then(result => {
				if (result.status) deferred.resolve(result.data);
				else deferred.reject(result.errors);
			})
			.catch(err => {
				deferred.reject(err);
			});
		return deferred.promise;
	};
	this.isPresent = () => {
		const deferred = $q.defer();
		let url = "";
		$http
			.get(url)
			.then(result => {
				if (result.status) deferred.resolve(result.data);
				else deferred.reject(result.errors);
			})
			.catch(err => {
				deferred.reject(err);
			});
		return deferred.promise;
	};
	this.getEditableInputs = () => {
		const deferred = $q.defer();
		let url = "";
		$http
			.get(url)
			.then(result => {
				if (result.status) deferred.resolve(result.data);
				else deferred.reject(result.errors);
			})
			.catch(err => {
				deferred.reject(err);
			});
		return deferred.promise;
	};

	//function to get the documents previous versions
	this.previousVersions = ({ documentTypeId, cui, workflowId }) => {
		const deferred = $q.defer();
		let url = "/api/documents/versions";
		$http
			.post(url, { documentTypeId, cui, workflowId  })
			.then(result => {
				if (result.data.status) {
					deferred.resolve(result.data.data);
				} else {
					deferred.reject(result.errors[0]);
				}
			})
			.catch(err => {
				deferred.reject(err);
			});
		return deferred.promise;
	};
	//function to get only thumbnails of passed ids
	this.getThumbnails = ({ documentIds, workflowId, isWorkflowRequired }) => {
		const deferred = $q.defer();
		let url = "/api/documents/thumbnails";
		$http
			.post(url, { ids: documentIds, workflowId, isWorkflowRequired})
			.then(result => {
				if (result.data.status) {
					deferred.resolve(result.data.data);
				} else {
					deferred.reject(result.errors[0]);
				}
			})
			.catch(err => {
				deferred.reject(err);
			});
		return deferred.promise;
	};

	this.approve = (data) => {
		const deferred = $q.defer();
		let url = "/api/documents/approve";
		$http.post(url, data)
			.then(result => {
				if (result.status) deferred.resolve(result.data);
				else deferred.reject(result.errors);
			})
			.catch(err => {
				deferred.reject(err);
			});
		return deferred.promise;
	};

	this.reject = (data) => {
		const deferred = $q.defer();
		let url = "/api/documents/reject";
		$http.post(url, data)
			.then(result => {
				if (result.status) deferred.resolve(result.data);
				else deferred.reject(result.errors);
			})
			.catch(err => {
				deferred.reject(err);
			});
		return deferred.promise;
	};

	this.history = param => {
		const deferred = $q.defer();
		let url = "/api/documents/history";
		$http
			.post(url, {
				docId: param.docId,
				documentTypeId: param.documentTypeId,
				isGrouped: param.isGrouped,
				cui:param.cui,
				workflowId: param.workflowId
			})
			.then(result => {
				if (result.data.status) {
					deferred.resolve(result.data.data);
				} else {
					deferred.reject(result.data.errors[0]);
				}
			})
			.catch(err => {
				deferred.reject(err);
			});
		return deferred.promise;
	};

	this.getEditableViewableElems = ({ docId, documentTypeId, isNew }) => {
		const deferred = $q.defer();
		let url = "/api/documents/getEditableInputs";
		$http
			.post(url, {
					docId: docId,
					documentTypeId: documentTypeId,
					isNew: isNew
			})
			.then(result => {
				if (result.data.status) {
					deferred.resolve(result.data.data);
				} else {
					deferred.reject(result.data.errors[0]);
				}
			})
			.catch(err => {
				deferred.reject(err);
			});
		return deferred.promise;
	};

	this.getViewableElems = ({docId}) => {
		const deferred = $q.defer();
		let url = "/api/documents/getViewableInputs";
		$http
			.post(url, {
					docId: docId
			})
			.then(result => {
				if (result.data.status) {
					deferred.resolve(result.data.data);
				} else {
					deferred.reject(result.data.errors[0]);
				}
			})
			.catch(err => {
				deferred.reject(err);
			});
		return deferred.promise;
	}

	this.getDocumentsByType = (workflowId, docTypeId) => {
		const deferred = $q.defer();
		const postData = {typeId: docTypeId, workflowId}
		let url = `/api/documents/type`;
		$http.post(url, postData)
			.then(result => {
				deferred.resolve(result.data);
			})
			.catch(err => {
				deferred.reject(err);
			});
		return deferred.promise;
	};

	this.toBase64 = content => {
		return btoa(new Uint8Array(content).reduce(function (data, byte) {
			return data + String.fromCharCode(byte);
		}, ''))
	};

	this.updateDocumentPrerequisites = (prerequisites, data) => {
		const {selected, approved, rejected, docTypes, myApprovedDocIds} = data;
		prerequisites.find(item => {
			item.notSelected = true;
			item.selected = false;
			item.approved = false;
			item.rejected = false;
			item.rejectMessage = '';
			if(item.name && item.name.startsWith('upload')){
				const value = selected.find(selected =>  selected.uid === item.typeParam);
				if(value){
					item.notSelected = false;
					item.selected = true;
					item.value = value.docId;
					item.documentTypeId = value.docTypeId;
				}else{
					item.value = null;
				}
			}
			if(item.name && item.name.startsWith('approve')){
				const value = approved.find(selected =>  selected.uid === item.typeParam);
				if(value && value.docId && myApprovedDocIds && myApprovedDocIds.includes(value.docId.toString())){
					item.approved = true;
					item.notSelected = false;
					item.value = value.docId;
					item.selectedDocId = value.docId;
					item.documentTypeId = value.docTypeId;
				}else{
					item.value = null;
				}
				const stillSelected = selected.find(selected =>  selected.uid === item.typeParam);
				if(!stillSelected){
					const docType = docTypes.find(type => type.uid === item.typeParam);
					item.notSelected = false;
					item.rejectMessage = `${docType.name} is rejected.`;
					item.rejected = true;
					item.selectedDocId = null;
					if(rejected && rejected.length) {
						const reject = rejected.find(selected => selected.uid === item.typeParam);
						if(reject){
							item.rejectedDocId = reject.docId;
							item.documentTypeId = reject.docTypeId;
							const comment = reject.comment?reject.comment:'';
							item.rejectMessage = `${docType.name} is rejected. ${comment}`;
						}
					}
				}else if(!item.approved){
					item.selected = true;
					item.notSelected = false;
					item.selectedDocId = stillSelected.docId;
					item.documentTypeId = stillSelected.docTypeId;
				}
			}
		});
	}

	this.getDocumentPillsCount = function(cui){
		const deferred = $q.defer();
		let baseUrl = '/api/documents/getDocumentPillsCount';
		let postData = {}
        if (cui) {
			postData.cui = cui;
        }
		$http.post(baseUrl, postData)
			.then(result => {
				if(result.status){
					deferred.resolve(result.data);
				}
				else {
					deferred.reject(result.errors[0]);
				}
			})
			.catch(err => {
				deferred.reject(err.errors[0]);
			});
		return deferred.promise;
     };
	this.getAllDocumentTypes = () => {
		const deferred = $q.defer();
		let url = "/api/documents/allTypes";
		$http
			.post(url)
			.then(result => {
				if (result.data.status) deferred.resolve(result.data.data);
				else deferred.reject(result.data.errors[0]);
			})
			.catch(err => {
				deferred.reject(err);
			});
		return deferred.promise;
	}
});
