const autoImportNgTemplateLoaderTemplate1 = require('../html/workflowLayoutRenderer.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('workflowLayoutRenderer', function () {
        return {
            name: 'workflowLayoutRenderer',
            scope: {
                form: '=',
                items: '=',
                noOfColumn: '=',
				context: '<',
				addPrerequisites: '<',
            },
            controller: 'workflowLayoutRendererCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
