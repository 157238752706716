'use strict';
angular.module('bitnudgeApp')
    .service('pointsMultiplier', function ($rootScope) {
        this.pointsFunction = function(achievedValue, ptsMultiplier, kpiType, targetValue){
            var percentageAchieved = (achievedValue / targetValue)*100;
            var achievement;

            if($rootScope.config.mtdUploadType == $rootScope.strings.POINTS.value)
                achievement =  achievedValue;
            else
                achievement = percentageAchieved;

            if(kpiType == 'value'){
                var ptMultiplier = _.find(ptsMultiplier,function(ptMultiplier){
                    if(ptMultiplier.threshold <= percentageAchieved)
                        return true;
                });
                if(ptMultiplier) {
                    return ptMultiplier.value * achievement;
                }else{
                    return 0;
                }
            }
            else{
                if(percentageAchieved > targetValue)
                    return ptsMultiplier[0].value * percentageAchieved;
                else{
                    for(var i = 1; i < ptsMultiplier.length ; i++){
                        if(ptsMultiplier[i].threshold <= percentageAchieved){
                            return ptsMultiplier[i].value * percentageAchieved;
                        }
                    }
                }
            }
        };
    });
