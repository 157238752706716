'use strict';
angular.module('bitnudgeApp')
    .controller('leadQuickFilterModalController',function($http, $scope, toaster, Auth, $filter, $stateParams, p0, $state,leadsService, $mdDialog, allLeads, leads, filters, callback) {
        var filters = filters;
        var callback = callback;
        $scope.selectedProductIdx = 0;
        $scope.myProducts = _.cloneDeep(p0.myProducts);
        $scope.selectedProductIdx = _.findIndex($scope.myProducts, function(product) {
            return product.id === filters.productId
        })
        $scope.selectedProductIdx = $scope.selectedProductIdx >= 0 ?  $scope.selectedProductIdx : 0
        
        $scope.leads = leads;
        $scope.allLeads = allLeads;
        $scope.counts = {};
        var monthlyCycle = p0.currentMonthlyCycle;
        $scope.currentMonthlyCycle = {
            startDate: new Date(p0.currentMonthlyCycle.startDate).toDateString(),
            endDate: new Date(p0.currentMonthlyCycle.endDate).toDateString()
        };
        var dailyCycle = p0.currentDailyCycle;
        var startDate = new Date(monthlyCycle.startDate);
        var endDate = new Date(monthlyCycle.endDate);
        var me = Auth.getCurrentUser();
        var followUpRequiredStatusList = p0.followUpRequiredStatusList;

        var leadCounts = function(){
            var now = new Date();
            $scope.myProducts.forEach(function(product){
                $scope.counts[product.kpiCode] = {};
                var kpiLeads = _.filter(allLeads, function(lead){
                    return lead.kpiCode == product.kpiCode
                })
                $scope.counts[product.kpiCode].newLeadCount = _.filter(kpiLeads,{isNew:true}).length;
                $scope.counts[product.kpiCode].activeLeadCount = _.filter(kpiLeads,{archived:false}).length;
                $scope.counts[product.kpiCode].notYetContactedCount =  _.filter(kpiLeads, { leadStatus : 'Not yet contacted'}).length;
                $scope.counts[product.kpiCode].wonLeadCount = _.filter(kpiLeads, function(lead){
                    var date = new Date(lead.lastUpdateDate);
                    return (p0.leadExitStatusList.indexOf(lead.leadStatus) != -1 && p0.failedLeadStatusList.indexOf(lead.leadStatus) == -1) &&  startDate<= date && date <= endDate;
                }).length;
                $scope.counts[product.kpiCode].lostLeadCount = _.filter(kpiLeads, function(lead){
                    var date = new Date(lead.lastUpdateDate);
                    var result = (p0.failedLeadStatusList.indexOf(lead.leadStatus) != -1) &&  startDate<= date && date <= endDate;
                    return result;
                }).length;
                $scope.counts[product.kpiCode].submittedToAULeadCount = _.filter(kpiLeads, function(lead){
                    var date = new Date(lead.lastUpdateDate);
                    return lead.leadStatus && lead.leadStatus.toLowerCase() == 'submitted to au' &&  startDate<= date && date <= endDate;
                }).length;

                $scope.counts[product.kpiCode].submittedForApprovalLeadCount = _.filter(kpiLeads, function(lead){
                    var date = new Date(lead.lastUpdateDate);
                    return lead.leadStatus && lead.leadStatus.toLowerCase() == 'submitted for approval' &&  startDate<= date && date <= endDate;
                }).length;

                $scope.counts[product.kpiCode].submittedToECLeadCount = _.filter(kpiLeads, function(lead){
                    var date = new Date(lead.lastUpdateDate);
                    return lead.leadStatus && lead.leadStatus.toLowerCase() == 'submitted to ec' &&  startDate<= date && date <= endDate;
                }).length;
                $scope.counts[product.kpiCode].submittedToComplianceLeadCount = _.filter(kpiLeads, function(lead){
                    var date = new Date(lead.lastUpdateDate);
                    return lead.leadStatus && lead.leadStatus.toLowerCase() == 'submitted to compliance' &&  startDate<= date && date <= endDate;
                }).length;
                $scope.counts[product.kpiCode].submittedToCROPSLeadCount = _.filter(kpiLeads, function(lead){
                    var date = new Date(lead.lastUpdateDate);
                    return lead.leadStatus && lead.leadStatus.toLowerCase() == 'submitted to operations' &&  startDate<= date && date <= endDate;
                }).length;
                $scope.counts[product.kpiCode].hotLeadCount = _.filter(kpiLeads,function(lead){
                    return lead.archived == false && lead.followers.length;
                }).length;

                $scope.counts[product.kpiCode].interestedLeadCount = _.filter(kpiLeads, function (lead) {
                    var date = new Date(lead.lastUpdateDate);
                    return lead.leadStatus == 'Interested' && !lead.archived;
                }).length;

                var today = new Date();
                $scope.counts[product.kpiCode].followUpMissedCount = _.filter(kpiLeads, function(lead){
                    var followUp = new Date(lead.followUpDate);
                    if(!lead.archived){
                        return followUp < today && _.includes(followUpRequiredStatusList,lead.leadStatus);
                    }else
                        return false;
                }).length;
                $scope.counts[product.kpiCode].followUpTodayCount = _.filter(kpiLeads, function(lead){
                    var followUp = new Date(lead.followUpDate);
                    return followUp.getFullYear() == now.getFullYear()
                        && followUp.getMonth() == now.getMonth()
                        && followUp.getDate() == now.getDate()
                        && !lead.archived && _.includes(followUpRequiredStatusList,lead.leadStatus);
                }).length;

                today = new Date();
                $scope.counts[product.kpiCode].followUpPendingCount = _.filter(kpiLeads, function (lead) {
                    var followUpDate = new Date(lead.followUpDate);
                    return today < followUpDate && !lead.archived && _.includes(followUpRequiredStatusList,lead.leadStatus);
                }).length;

                //today = new Date();
                today = new Date(today.setHours(23,59,59,999));
                $scope.counts[product.kpiCode].hotAndNoUpdateCount = _.filter(kpiLeads, function (lead) {
                    var followUpDate = new Date(lead.followUpDate);
                    var hoursDiff = (today - followUpDate) / 3600000;
                    return hoursDiff <= 96 && hoursDiff >= 0
                        && !lead.archived && lead.followers.length;
                }).length;

                today = new Date();
                // today = new Date(today.setHours(23,59,59,999));
                $scope.counts[product.kpiCode].interestedCount = _.filter(kpiLeads, function (lead) {
                    var followUpDate = new Date(lead.followUpDate);
                    var hoursDiff = (today - followUpDate) / 3600000;
                    return hoursDiff <= 96 && hoursDiff >= 0
                        && !lead.archived && lead.leadStatus == 'Interested';
                }).length;

                today = new Date();
                // today = new Date(today.setHours(23,59,59,999));
                $scope.counts[product.kpiCode].undecidedCount = _.filter(kpiLeads, function (lead) {
                    var followUpDate = new Date(lead.followUpDate);
                    var hoursDiff = (today - followUpDate) / 3600000;
                    return hoursDiff <= 96 && hoursDiff >= 0
                        && !lead.archived && lead.leadStatus == 'Undecided';
                }).length;
            })
        };

        // leadCounts();
        var getLeadCounts = function(){
            $http.post('/api/leads/getLeadQuickFilterCounts').then(function(result){
                $scope.counts = result.data.data;
            })
        }
        getLeadCounts();

        var monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
            "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        var monthName = monthNames[new Date().getMonth()];

        // ************************* Quick Filters ***************************
        $scope.quickFilters = {};
        $scope.quickFilters['K05'] = [
            {key:'new',                 name: 'MTD Leads',              field: '',      count: $scope.newLeadCount, countKey: 'newLeadCount'},
            {key:'active',              name: 'Active',                 field: '',      count: $scope.activeLeadCount, countKey: 'activeLeadCount'},
            {key:'notYetContacted',     name: 'Not Yet Contacted',      field: '',      count: $scope.notYetContactedCount, countKey: 'notYetContactedCount'},
            {key:'hot',                 name: 'Hot',                    field: '',      count: $scope.hotLeadCount, countKey: 'hotLeadCount'},
            {key:'interested',          name: 'Interested',             field: '',      count: $scope.interestedLeadCount, countKey: 'interestedLeadCount'},
            {key:'submittedToAu',       name: 'Sub. to AU',             field: '',      count: $scope.submittedToAULeadCount, countKey: 'submittedToAULeadCount'},
            {key:'submittedToEc',       name: 'Sub. to EC',             field: '',      count: $scope.submittedToECLeadCount, countKey: 'submittedToECLeadCount'},
            {key:'submittedToOperations',   name: 'Sub to CROPS',       field: '',      count: $scope.submittedToCROPSLeadCount, countKey: 'submittedToCROPSLeadCount'},
            {key:'won',                 name: 'Won',                    field: '',      count: $scope.wonLeadCount, countKey: 'wonLeadCount'},
            {key:'lost',                name: 'Lost',                   field: '',      count: $scope.lostLeadCount, countKey: 'lostLeadCount'}
        ];
        $scope.quickFilters['K06'] = [
            {key:'new',                 name: 'MTD Leads',              field: '',      count: $scope.newLeadCount, countKey: 'newLeadCount'},
            {key:'active',              name: 'Active',                 field: '',      count: $scope.activeLeadCount, countKey: 'activeLeadCount'},
            {key:'notYetContacted',     name: 'Not Yet Contacted',      field: '',      count: $scope.notYetContactedCount, countKey: 'notYetContactedCount'},
            {key:'hot',                 name: 'Hot',                    field: '',      count: $scope.hotLeadCount, countKey: 'hotLeadCount'},
            {key:'interested',          name: 'Interested',             field: '',      count: $scope.interestedLeadCount, countKey: 'interestedLeadCount'},
            {key:'submittedToAu',       name: 'Sub. to AU',             field: '',      count: $scope.submittedToAULeadCount, countKey: 'submittedToAULeadCount'},
            {key:'submittedToEc',       name: 'Sub. to EC',             field: '',      count: $scope.submittedToECLeadCount, countKey: 'submittedToECLeadCount'},
            {key:'submittedToOperations',   name: 'Sub to CROPS',       field: '',      count: $scope.submittedToCROPSLeadCount, countKey: 'submittedToCROPSLeadCount'},
            {key:'won',                 name: 'Won',                    field: '',      count: $scope.wonLeadCount, countKey: 'wonLeadCount'},
            {key:'lost',                name: 'Lost',                   field: '',      count: $scope.lostLeadCount, countKey: 'lostLeadCount'}
        ];
        $scope.quickFilters['K07'] = [
            {key:'new',                 name: 'MTD Leads',              field: '',      count: $scope.newLeadCount, countKey: 'newLeadCount'},
            {key:'active',              name: 'Active',                 field: '',      count: $scope.activeLeadCount, countKey: 'activeLeadCount'},
            {key:'notYetContacted',     name: 'Not Yet Contacted',      field: '',      count: $scope.notYetContactedCount, countKey: 'notYetContactedCount'},
            {key:'hot',                 name: 'Hot',                    field: '',      count: $scope.hotLeadCount, countKey: 'hotLeadCount'},
            {key:'interested',          name: 'Interested',             field: '',      count: $scope.interestedLeadCount, countKey: 'interestedLeadCount'},
            {key:'submittedToAu',       name: 'Sub. to AU',             field: '',      count: $scope.submittedToAULeadCount, countKey: 'submittedToAULeadCount'},
            {key:'submittedToCompliance',   name: 'Compliance',         field: '',      count: $scope.submittedToComplianceLeadCount, countKey: 'submittedToComplianceLeadCount'},
            {key:'submittedToOperations',   name: 'Sub to CROPS',       field: '',      count: $scope.submittedToCROPSLeadCount, countKey: 'submittedToCROPSLeadCount'},
            {key:'won',                 name: 'Won',                    field: '',      count: $scope.wonLeadCount, countKey: 'wonLeadCount'},
            {key:'lost',                name: 'Lost',                   field: '',      count: $scope.lostLeadCount, countKey: 'lostLeadCount'}
        ];

        //************************ Follow up filters ***************************
        $scope.followUpFilters = [
            {key:'missed',              name: 'Missed',                field: '',      count: $scope.followUpMissedCount, countKey: 'followUpMissedCount'},
            {key:'today',               name: 'Today',                 field: '',      count: $scope.followUpTodayCount, countKey: 'followUpTodayCount'},
            {key:'upcoming',            name: 'Upcoming',              field: '',      count: $scope.followUpPendingCount, countKey: 'followUpPendingCount'}
        ];
        $scope.actNowFilters = [
            {key:'hot',              name: 'Hot & No Update (last 3days)',        field: '',      count: $scope.hotAndNoUpdateCount, countKey: 'hotAndNoUpdateCount'},
            {key:'undecided',        name: 'Undecided (last 3days)',              field: '',      count: $scope.undecidedCount, countKey: 'undecidedCount'},
            {key:'interested',       name: 'Interested & No Update (last 3days)', field: '',      count: $scope.interestedCount, countKey: 'interestedCount'}
        ];

        if(filters) {
            $scope.leadStatusKey     = filters.leadStatusKey;
            $scope.leadDateKey  = filters.leadDateKey;
            $scope.source     = filters.source;
            $scope.actNowFilterKey     = filters.actNowFilterKey;
            $scope.productId           = filters.productId;
            if(filters.startDate)
                $scope.startDate        = filters.startDate;
            if(filters.endDate)
                $scope.endDate          = filters.endDate;
            switch ($scope.leadStatusKey){
                case 'new':
                    $scope.quickFilterKey = 'new'; break;
                case 'active':
                    $scope.quickFilterKey = 'active';break;
                case 'hot':
                    $scope.quickFilterKey = 'hot'; break;
                case 'followUpMissed':
                    $scope.followUpFilterKey= 'missed'; break;
                case 'followUpPending':
                    $scope.followUpFilterKey= 'upcoming'; break;
                case 'notYetContacted':
                    $scope.quickFilterKey= 'notYetContacted'; break;
                case 'interested':
                    $scope.quickFilterKey = 'interested'; break;

            }
            switch($scope.leadDateKey){
                case 'disbursed':
                    $scope.quickFilterKey = 'won'; break;
                case 'failed':
                    $scope.quickFilterKey = 'lost'; break;
                case 'submittedToAu':
                case 'submittedToEc':
                case 'submittedToOperations':
                case 'SubmittedForApproval':
                    $scope.quickFilterKey = $scope.leadDateKey; break;
                case 'followUp':
                    var start = new Date($scope.startDate);
                    var end = new Date($scope.endDate);
                    if(start.getDate() == end.getDate() && start.getMonth() == end.getMonth() && start.getFullYear() == end.getFullYear())
                        $scope.followUpFilterKey = 'today';
                    break;
            }

        }

        $scope.onQuickFilter = function (product, quickFilterKey) {
            $scope.quickFilterKey = quickFilterKey;
            $scope.selectedProduct = product;
            $scope.quickFilter = _.find($scope.quickFilters[product.kpiCode],{key:$scope.quickFilterKey});
            $scope.leadStatusKey = null;
            $scope.leadDateKey = null;
            $scope.source = "";

            switch($scope.quickFilterKey){
                case 'new':
                    $scope.leadStatusKey = 'new';
                    $scope.leadDateKey = 'created';
                    $scope.startDate = startDate;
                    $scope.endDate = endDate;
                    break;
                case 'active':
                    $scope.leadStatusKey = 'active';
                    break;
                case 'notYetContacted':
                    $scope.leadStatusKey = 'notYetContacted';
                    break;
                case 'won':
                    $scope.leadDateKey = 'disbursed';
                    $scope.startDate = startDate;
                    $scope.endDate = endDate;
                    break;
                case 'interested':
                    $scope.leadStatusKey = 'interested';
                    break;
                case 'lost':
                    $scope.leadDateKey = 'failed';
                    $scope.startDate = startDate;
                    $scope.endDate = endDate;
                    break;
                case 'hot':
                    $scope.leadStatusKey = 'hot';
                    break;
                case 'submittedToAu':
                case 'submittedToEc':
                case 'submittedToOperations':
                case 'SubmittedForApproval':
                    $scope.leadDateKey = $scope.quickFilterKey;
                    $scope.startDate = startDate;
                    $scope.endDate = endDate;
                    break;
            }
            $mdDialog.cancel();
            refreshLeads();
        };
        $scope.onFollowUpFilter = function (product, followUpFilterKey) {
            $scope.followUpFilterKey = followUpFilterKey;
            $scope.selectedProduct = product;
            $scope.leadStatusKey = null;
            $scope.leadDateKey = null;
            $scope.source = "";
            switch ($scope.followUpFilterKey){
                case 'missed':
                    var yesterDay = new Date();
                    yesterDay.setDate(new Date().getDate() - 1);
                    $scope.leadStatusKey = 'followUpMissed';
                    $scope.startDate = new Date(monthlyCycle.startDate);
                    $scope.endDate = new Date(yesterDay.setHours(23,59,59,999));
                    break;
                case 'today':
                    $scope.leadDateKey = 'followUp';
                    $scope.startDate = new Date(new Date().setHours(0,0,0,0));
                    $scope.endDate = new Date(new Date().setHours(23,59,59,999));
                    break;
                case 'upcoming':
                    $scope.leadStatusKey = 'followUpPending';
                    var currentDate = new Date();
                    currentDate.setDate(currentDate.getDate());
                    currentDate = new Date(currentDate.setHours(0,0,0,0));
                    $scope.startDate = new Date(currentDate);
                    $scope.endDate = new Date(endDate);
                    break;
            }
            $mdDialog.cancel();
            refreshLeads();
        };
        $scope.onActNowFilter = function (product, actNowFilterKey) {
            $scope.actNowFilterKey = actNowFilterKey;
            $scope.selectedProduct = product;
            $scope.leadStatusKey = null;
            $scope.leadDateKey = null;
            $scope.source = "";
            switch ($scope.actNowFilterKey){
                case 'hot':
                case 'interested':
                case 'undecided':
                    var startDate = new Date();
                    startDate.setDate(startDate.getDate() - 3);
                    var endDate = new Date();
                    endDate.setDate(endDate.getDate() -1 );
                    $scope.startDate = new Date(new Date(startDate).setHours(0,0,0,0));
                    $scope.endDate = new Date(new Date(endDate).setHours(23,59,59,999));
                    break;
            }
            $mdDialog.cancel();
            refreshLeads();
        };

        var refreshLeads = function(){
            filters = {
                leadStatusKey:      $scope.leadStatusKey,
                leadDateKey:        $scope.leadDateKey,
                startDate:          $scope.startDate,
                endDate:            $scope.endDate,
                source:             $scope.source,
                quickFilter:        $scope.quickFilter,
                followUpFilter:     $scope.followUpFilter,
                actNowFilterKey:     $scope.actNowFilterKey,
                productId:          $scope.selectedProduct._id
            };
            if(callback) {
                callback(filters);
            }
        };

        $scope.onClose = function() {
            $mdDialog.cancel();
            // refreshLeads();

        };

    });
