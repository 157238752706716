const autoImportNgTemplateLoaderTemplate1 = require('./../html/component/infoComponent.html');

"use strict";
angular.module("bitnudgeApp").component("infoComponent", {
	templateUrl: autoImportNgTemplateLoaderTemplate1,
	bindings: {
		docId: "<"
	},
	controller: "infoComponentCtrl"
});
