const autoImportNgTemplateLoaderTemplate1 = require('../html/lbCarousel.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('lbCarousel', function(){
        return {
            name: 'lbCarousel',
            scope: {
                lbs: "=lbs",
                isSlickDataLoaded:  '=',
                isOwnLeaderBoard:   '@',
                onCompleteClick:    '=',
                selectedCycle:      '=',
                selectedPlayers:    '=',
                jobRole:            '=',
                hideCompare:        '@'
            },

            controller: 'LbCarouselCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
