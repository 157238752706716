'use strict';
angular.module('bitnudgeApp')
    .controller('playbookCarouselCtrl', function ($scope,$http,$rootScope) {
        $scope.hoverflag=0;
        $scope.strings = $rootScope.strings;
        $scope.slickConfig= {
            enabled: true,
            variableWidth:true,
            autoplay: false,
            draggable: false,
            slidesToScroll: 7,
            slidesToShow:7,
            infinite:false
        };

    });
