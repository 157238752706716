'use strict';
angular.module('bitnudgeApp')
    .controller('activityTrendModalCtrl', function ($scope, $http, $mdDialog, Auth, p0, parameters, TrendGraph, metricOptions) {
        $scope.me = Auth.getCurrentUser();
        $scope.metricOptions = metricOptions;
        $scope.selectedTrendMetric = parameters.metric;
        $scope.graphExpanded = false;
        $scope.isMonthlyTrend = false;
        $scope.showTrendSwitch = p0.allMonthlyCycles.length > 1;
        var activitiesYAxis= [{baseName:'Total', _id:'value', manager:false, type:'kai', fieldName:'value'},
            {baseName:'Per IFE', _id:'perIFE', manager:true, type:'kai', fieldName:'perIFE'},
            {baseName:'Per IFE per day', _id:'perIFEPerDay', manager:true, type:'kai', fieldName:'perIFEPerDay'},
            {baseName:'Per IFE per week', _id:'perIFEPerWeek', manager:true, type:'kai', fieldName:'perIFEPerWeek'}];
        var currentLineGraphsIds = [], allYAxisMetrics = ['value', 'perIFE', 'perIFEPerDay', 'perIFEPerWeek', 'actual', 'percentage'], otherKpis;

        $scope.comparisions = [];
        $scope.tableData = [];
        $scope.lineData = {
            labels: [],
            series: [],
            seriesLegends: []
        };
        $scope.disableAddSeries = true;
        $scope.selected = {};
        $scope.vsAnd = 'vs';

        $scope.selected.kpi = parameters.kpi;

        $scope.onReporteeChange = function (reportee) {
            $scope.selected.user = reportee;
            var userKpis = getUserKpis(reportee.divisions);
            otherKpis = _.filter(userKpis, function (kpi) {
                return kpi._id != parameters.kpi._id;
            });
            parameters.userId = reportee._id;
            setYAxis();
            $scope.onChangeYMetric($scope.yMetric);
            init();
        };
        function getMonthsRange(cycle){
            var allCycles = _.filter(p0.allMonthlyCycles,{subType:cycle.subType});
            var start = _.minBy(allCycles, function (cycle) {
               return new Date(cycle.startDate);
            });
            return {startDate:moment(start.startDate).valueOf(), endDate: moment(cycle.endDate).valueOf()};
        }
        $scope.onTrendChange = function(change){
            $scope.isMonthlyTrend = change;
            if($scope.isMonthlyTrend){
              parameters.cycleType = {id:'monthly', name:'Monthly'};
              parameters.timeRange = getMonthsRange(parameters.cycle);
            }else{
              parameters.cycleType = {id:'daily', name:'Daily'};
              parameters.timeRange ={startDate:moment(parameters.cycle.startDate).valueOf(), endDate: moment(parameters.cycle.endDate).valueOf()};
            }
            init();
        };

        $scope.onChangeYMetric = function (yMetric) {

            if($scope.trendModalData){
                //Remove current lineCharts
                initializeVariables();

                //Draw ymetric lineChart
                $scope.lineData.seriesLegends[0] = {label: yMetric.name, series: 'a', _id:yMetric._id, index:0};
                $scope.lineData.series[0] = _.map($scope.trendModalData, yMetric.fieldName);
                currentLineGraphsIds[0] = yMetric._id;
                updateTable();
            }
            if(yMetric.type == 'kai'){
                if(yMetric.unit == '#'){
                    $scope.yUnit = '(# Count)';
                }else{
                    $scope.yUnit = '('+yMetric.unit+')';
                }
                $scope.titleUnit = "Unit: "+yMetric.unit+" of "+yMetric.name;
            }
        };
        $scope.toggleGraph = function() {
            $scope.graphExpanded = !$scope.graphExpanded;
        };
        //Fetch trend graph
        function init() {
            initializeVariables();
            activitiesYAxis.forEach(function (perIFE) {
                perIFE.name = parameters.metric.name +' '+perIFE.baseName;
                perIFE.unit = parameters.metric.unit;
            });
            setYAxis();
            TrendGraph.fetchMetricTrend(parameters.userId, parameters.kpi._id, parameters).then(function (trend) {
                $scope.trendModalData = trend;
                $scope.showTrendChart = true;
                $scope.dialogFlex = 70;
                var type = (parameters.metric.activityType == 'value' ? 'perIFE' : 'value');
                initGraph(type);
            });
        }

        var updateTable = function () {
            $scope.table = [];
            $scope.table[0] = ['Cycle'];
            $scope.lineData.labels.forEach(function (cycleName, index) {
                $scope.table[index+1] = [cycleName];
            });
            $scope.lineData.seriesLegends.forEach(function (legend, lIndex) {
                $scope.table[0].push(legend.label);
                $scope.lineData.series[legend.index].forEach(function (value, vIndex) {
                    $scope.table[vIndex+1][lIndex+1] = parseFloat(value).toFixed(2);
                });
            });
        };

        var initializeVariables = function () {
            $scope.lineData.series = [];
            $scope.lineData.seriesLegends = [];
            currentLineGraphsIds = [];
            $scope.vsAnd ='vs';
            if($scope.selected.user) $scope.titleLeft = $scope.selected.user.name;
            for(var i=0; i<30; i++){ currentLineGraphsIds.push('');}
        };
        initializeVariables();

        var initGraph = function (valueField) {
            if ($scope.trendModalData && $scope.trendModalData.length > 0) {
                $scope.lineData.seriesLegends[0] = {label: $scope.yMetric.name, series: 'a', _id:valueField, index:0};
                $scope.lineData.labels = _.map($scope.trendModalData, 'name');
                $scope.lineData.series[0] = _.map($scope.trendModalData, valueField);
                currentLineGraphsIds[0] = valueField;
                updateTable();
            }

            $scope.lineOptions = {
                axisX: {
                    labelOffset: {
                        x: 0,
                        y: 0
                    },
                    showGrid: true,
                    labelInterpolationFnc: function (value) {
                        return getLabel(value);
                    }
                },
                axisY: {
                    labelOffset: {
                        x: 0,
                        y: 0
                    },
                    showGrid: true,
                    labelInterpolationFnc: function (value) {
                        return parseFloat(value).toFixed(2);
                    }
                },
                chartPadding: {
                    top: 20,
                    bottom: 5,
                    left: 20,
                    right: 40
                },
                plugins: [
                    Chartist.plugins.tooltip({
                        tooltipFnc: function(meta, value) {
                            return parseFloat(value).toFixed(2);
                        }
                    })
                ],
                fullWidth: true,
                showLine: true
            };

            if (parameters.cycleType.id === 'daily') {
                $scope.lineOptions.axisX.labelOffset.x = -8;
            } else if (parameters.cycleType.id === 'weekly') {
                $scope.lineOptions.axisX.labelOffset.x = -21;
            } else if (parameters.cycleType.id === 'monthly') {
                $scope.lineOptions.axisX.labelOffset.x = -17;
            }
        };

        var setYAxis = function () {
            if($scope.selected.user.systemRole == 'frontline'){
                $scope.yAxis = _.filter(activitiesYAxis, {manager:false});
                $scope.yMetric = $scope.yAxis[0];
            }else{
                if(parameters.metric.activityType == 'value'){
                    $scope.yAxis = _.filter(activitiesYAxis, {_id:'perIFE'});
                }else{
                    $scope.yAxis = _.filter(activitiesYAxis, {_id:'value'});
                }
                $scope.yMetric = $scope.yAxis[0];
            }
            $scope.onChangeYMetric($scope.yMetric);
        };

        var getLabel = function (value) {
            var index = $scope.lineData.labels.indexOf(value);
            if ($scope.trendModalData.length > 30) {
                if (index % 3 !== 0) return '';
            } else if ($scope.trendModalData.length > 15) {
                if (index % 2 === 0) return '';
            }

            var splits = value.split(' ');
            if (parameters.cycleType.id === 'daily') {
                return splits[1] + ' ' + splits[2];
            } else if (parameters.cycleType.id === 'weekly') {
                return splits[1];
            }

            return value;
        };

        var getUserKpis = function (divisionIds) {
            var kpis=[];
            _.forEach(divisionIds, function (divisionId) {
                var division = _.find(p0.allDivisions, {_id: divisionId});
                if(division) kpis = kpis.concat(division.kpis);
            });
            return kpis;
        };

        $scope.cancel = function () {
            TrendGraph.destroy();
            $mdDialog.cancel();
        };
        function onPageLoad(){
            var monthType = p0.currentMonthlyCycle.subType;
            if(parameters.kpi._id != 'overall'){
                var division = _.find(p0.allDivisions, function (division) {
                    var kpis = _.map(division.kpis, function(kpi){return kpi._id.toString();});
                    return _.includes(kpis, parameters.kpi._id.toString());
                });
                monthType = division.monthType
            }
            $scope.selectedCycle = _.find(p0.allMonthlyCycles,{active:true, subType:monthType});
            $scope.onReporteeChange($scope.me);
        }
        onPageLoad();
    });
