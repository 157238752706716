const autoImportNgTemplateLoaderTemplate1 = require('../html/pipelineInfo.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/recommendationCard/badgeRecommendationModal.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('recommendationCardCtrl', function ($scope, recommendation, p0, sidebarVariables, $state, $mdDialog, $rootScope) {
        $scope.strings=$rootScope.strings;
        $scope.sidebarVariables = sidebarVariables;
        $scope.kpi = sidebarVariables.kpi;
        $scope.$watch('sidebarVariables.kpi',function(){
            $scope.kpi = sidebarVariables.kpi;
            if($scope.kpi){
                getPerformanceSummary();
            }
        });
        $scope.dropDowns = [{name:'This Month', _id:'month'},{name:'This Week', _id:'week'}, {name:'Today', _id:'today'}, {name:'Yesterday',_id:'yesterday'}];
        $scope.selectedPeriod = $scope.dropDowns[0];
        if($state.is('lms.listLead')){
            // var startDate = new Date(p0.currentMonthlyCycle.startDate).getTime();
            // var endDate = new Date(p0.currentMonthlyCycle.endDate).getTime();
            // recommendation.getLeadsSummary(startDate, endDate, 'this month').then(function(recommendations){
            //     $scope.recommendations = recommendations;
            //     $scope.leadSummary = true;
            // });
        }else{
            getPerformanceSummary();
        }

        // $rootScope.$on('leadUpdation', function(event, args) {
        //     var startDate = new Date(p0.currentMonthlyCycle.startDate).getTime();
        //     var endDate = new Date(p0.currentMonthlyCycle.endDate).getTime();
        //     recommendation.getLeadsSummary(startDate, endDate, 'this month').then(function(recommendations){
        //         $scope.recommendations = recommendations;
        //         $scope.leadSummary = true;
        //     });
        // });


        function getPerformanceSummary() {
            if(!$state.is('lms.listLead')){
                recommendation.getNewData($scope.kpi).then(function(recommendations){
                    $scope.recommendations = recommendations;
                    $scope.leadSummary = false;
                });
            }
        }

        $scope.showPipelineInfo = function(){
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller : function($scope){
                    $scope.onClose = function(){
                        $mdDialog.hide();
                    }
                }
            });
        };

        $scope.changePeriod  = function (selectedPeriod) {
            var startDate, endDate, period;
            if(selectedPeriod._id == 'month'){
                startDate = new Date(p0.currentMonthlyCycle.startDate).getTime();
                endDate = new Date(p0.currentMonthlyCycle.endDate).getTime();
                period = 'this month';
            }else  if(selectedPeriod._id == 'week'){
                startDate = new Date(p0.currentWeeklyCycle.startDate).getTime();
                endDate = new Date(p0.currentWeeklyCycle.endDate).getTime();
                period = 'this week';
            }else if(selectedPeriod._id == 'today'){
                startDate = new Date(p0.currentDailyCycle.startDate).getTime();
                endDate = new Date(p0.currentDailyCycle.endDate).getTime();
                period = 'today';
            }else{
                startDate = new Date(p0.currentDailyCycle.previousCycleId.startDate).getTime();
                endDate = new Date(p0.currentDailyCycle.previousCycleId.endDate).getTime();
                period = 'yesterday';
            }
            recommendation.getLeadsSummary(startDate, endDate, period).then(function(recommendations){
                $scope.recommendations = recommendations;
                $scope.leadSummary = true;
            });
        };
        $scope.openBadgeRecommendations = function (selectedKpi) {
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate2,
                controller: 'badgeRecommendationModalCtrl',
                size: 'md',
                resolve:{
                    selectedKpi : function(){ return selectedKpi;}
                }
            });
        }
    });
