'use strict';
angular.module('bitnudgeApp')
    .controller('playerComparisonModalCtrl', function ($scope, modalType, onSelectPlayer, frontlineRoleId, $mdDialog, $http, Auth, fetchLeagues) {

        $scope.me = Auth.getCurrentUser();
        $scope.onSelectPlayer = onSelectPlayer;
        $scope.allLeagues =fetchLeagues.getAllLeagues($scope.me, modalType);
        $scope.selectedLeague = $scope.allLeagues[0];

        var subsetId;
        if (modalType)
            subsetId = $scope.me.organizationalTag.jobRole._id;
        else
            subsetId = frontlineRoleId;

        $http.post('api/users', {select: ["name", "teams", "organizationalTag"], "jobRoleId": subsetId}).then(function (users) {
            users=users.data;
            for (var i = 0; i < users.length; i++)
                users[i].seq = i;
            $scope.players = users.data;
            var removeUser = Auth.getCurrentUser().name;
            for (var i = $scope.players.length - 1; i >= 0; i--) {
                if ($scope.players[i].name == removeUser) {
                    $scope.players.splice(i, 1)
                };
            };
            $scope.getPlayersBySelectedLeague($scope.selectedLeague);
        });
        $scope.getPlayersBySelectedLeague = function (selectedLeague) {
            $scope.playerList = _.filter($scope.players, function (player) {
                var team = _.find(player.teams, {teamId:selectedLeague._id});
                return team;
            });
		};

        $scope.onClickPlayer = function(player) {
            $scope.onSelectPlayer('modal', player);
            $scope.onClose();
        };

        $scope.showModal = true;
        $scope.onClose = function () {
            $mdDialog.hide();
        };
    });
