'use strict';

angular.module('bitnudgeApp').controller('ticketFiltersController', function ($scope, $mdDialog, Auth, toaster, Tickets, p0, $timeout, CurrentCycle) {
    $scope.filterTicketsOnType = function(key){
        if($scope.filterObj.ticketType && $scope.filterObj.ticketType==key){
            $scope.filterObj.ticketType = "";
            $scope.ticketType = null;
        }else {
            $scope.filterObj.ticketType =key;
        }
        $scope.filterTickets();
    };

    $scope.allPlayTypes = p0.allPlayTypes;

    $scope.filterTicketsOnStatus = function(key){
        if($scope.filterObj.ticketStatus && $scope.filterObj.ticketStatus==key){
            $scope.filterObj.ticketStatus = "";
            $scope.ticketStatus = null;
        }else
            $scope.filterObj.ticketStatus =key;
        $scope.filterTickets();
    };
    $scope.filterTicketsOnSource = function(key){
        if($scope.filterObj.source && $scope.filterObj.source==key){
            $scope.filterObj.source = "";
            $scope.source = null;
        }else
            $scope.filterObj.source =key;
        $scope.filterTickets();
    };

    $scope.$watch('selected.cycle', function(newVal, oldVal) {
        if(newVal){
           setDates(newVal);
        }
    });
    function setDates(cycle){
        $scope.startDate = new Date(cycle.startDate);
        $scope.endDate = new Date(cycle.endDate);
    }

    $scope.cycleChange = {
        cycle : function(cycle){
            if($scope.selected.cycle == undefined || $scope.selected.cycle._id != cycle._id) {
                $scope.selected.cycle = cycle;
                $scope.filterTickets();
            }
            if(CurrentCycle)
                CurrentCycle.setCycle(cycle);
        },
        startDate : function(startDate){
            if(!$scope.endDate)
                $scope.endDate = $scope.selected.cycle.endDate;
            $scope.selected.cycle = {startDate:startDate, endDate:$scope.endDate};
            $scope.filterTickets();
        },
        endDate : function(endDate){
            if(!$scope.startDate)
                $scope.startDate = $scope.selected.cycle.startDate;
            $scope.selected.cycle = {startDate:$scope.startDate, endDate:endDate};
            $scope.filterTickets();
        }
    };

    $scope.removeFilter = function(filter){
        $scope.filterObj.actNowFilterKey = null;
        switch(filter){
            case 'ticketType':
                $scope.ticketType = "";
                $scope.filterObj.ticketType = "";
                break;
            case 'ticketStatus':
                $scope.ticketStatus = null;
                $scope.filterObj.ticketStatus = "";
                $scope.selected.cycle = CurrentCycle.cycle;
                break;
            case 'source':
                $scope.source = "";
                $scope.filterObj.source = "";
                break;
            default:
                break;
        }
        $scope.filterTickets();
    };
});