const autoImportNgTemplateLoaderTemplate1 = require('../html/hotKeys/hotKeyModal.html');

'use strict';
angular.module('bitnudgeApp')
	.controller('hotKeysCtrl', function ($scope, $mdDialog, HotKeyService, $window, toaster) {
		$scope.hotKeys = HotKeyService.hotKeys;;

		const onModalClose = () => {
			$mdDialog.hide();
		}
		const callback =(linkCode, link)=>{
			$scope.linkCode = linkCode;
			$scope.link = link;
		}

		$scope.openListingModel = function (hotKey) {
			$mdDialog.show({
				templateUrl: autoImportNgTemplateLoaderTemplate1,
				controller: 'hotKeyModalCtrl',
				clickOutsideToClose: false,
				fullscreen: true,
				resolve: {
					hotKey: () => hotKey,
					onClose: () => onModalClose,
					callback: ()=> callback
				}
			})
		}

		$scope.getTokenAndOpen = (linkCode, link) =>{
			HotKeyService.getReviewToken(linkCode).then(result => {
				if (result.status) {
					const token = result.data
					const url = `${link}?token=${token}`;
					console.log(url)
					$window.open(url, '_blank');
				}else{
					toaster.pop('error', result.message)
				}
			})
		}

	})