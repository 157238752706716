const autoImportNgTemplateLoaderTemplate1 = require('../html/reporteewiseTrendModal.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/reporteewiseTrendModal.html');
const autoImportNgTemplateLoaderTemplate3 = require('../html/reporteewiseTrendModal.html');

'use strict';
angular.module('bitnudgeApp').controller('srManagerFormCtrl', function ($scope, $http, Auth, SwotService, p0,$rootScope) {

    $scope.strings = $rootScope.strings;

    var today =  new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    var YearBack= new Date(new Date().getFullYear()-1, new Date().getMonth(), 1);
    var selectedPlayersForKAI =[];
    var selectedPlayersForKPI=[];
    var selectedPlayersForProductivity=[];

    $scope.allActivities = p0.allActivities;
    $scope.selectedActivityForALGraph = $scope.allActivities[0];

    $scope.jobRoles = p0.frontLineJobRoles;
    $scope.jobRolesForPerformance = $scope.jobRoles;

    $scope.selectedJobRole = $scope.jobRoles[0];
    $scope.selectedJobRoleForProductivity = $scope.jobRoles[0];
    $scope.selectedJobRoleForPerformance = $scope.jobRoles[0];
    const postBody = {
        userId: Auth.getCurrentUser()._id,
        jobRole: $scope.jobRoles[0]._id
    }
    $http.post('api/users/playerOfJobRole', postBody).then(function(children) {
        children=children.data;
        $scope.numberOfFrontlines = children.length;
        $scope.numberOfFrontlinesForProductivity = children.length;
    });
    $scope.allKPI = p0.allKpis;
    $scope.allKPIsForKAITrend = _.cloneDeep($scope.allKPI);
    $scope.allKPIsForProductivityTrend = _.cloneDeep($scope.allKPI);
    $scope.allKPIsForPerformance = _.cloneDeep($scope.allKPI);

    $scope.allKPIsForPerformance.unshift({_id:'overall',name:'Overall'});
    $scope.allKPIsForKAITrend.unshift({_id:'overall',name:'Overall'});
    $scope.selectedKPIForKAITrend = $scope.allKPIsForKAITrend[0];
    $scope.selectedKPIForPerformance = $scope.allKPIsForPerformance[0];

    $scope.getKpiSpecificActivities = function(kpi){
        $scope.selectedActivitiesForKAITrend =[];

        if(kpi.name != 'Overall'){
            $scope.selectedActivities = _.filter($scope.allActivities, function(obj, index){
                return obj.kpiSpecific == true
            })
        }
        else {
            $scope.selectedActivities = $scope.allActivities;
        }
        $scope.selectedKPIForKAITrend = kpi;
        if(selectedPlayersForKAI.length == 0)
            getKAITrendOfJobRole($scope.selectedJobRole);
        else
            getKAITrendOfReportees();
    };

    $scope.getKpiSpecificActivities($scope.selectedKPIForKAITrend);
    $http.post('/api/users/me').then(function(me){
        me=me.data;
        $scope.currentUser = me;
        $scope.initFormGraphs();
        if($scope.selectedKPIsForProductivityModel.length == 0){
            _.forEach($scope.allKPIsForProductivityTrend, function(obj){
                $scope.selectedKPIsForProductivityModel.push({id: obj._id});
            })
        }
        $scope.getProductivitySpecificActivities();
    });



    var getNumberOfReportees = function(jobRoleId, callback){
        const postBody = {
            userId: Auth.getCurrentUser()._id,
            jobRole: jobRoleId
        }
        $http.post('api/users/playerOfJobRole', postBody).then(function(children) {
            children=children.data;
            return callback(children.length);
        });
    };

    $scope.getProductivitySpecificActivities = function(){
        //$scope.selectedActivitiesModelForProductivity = [];

        if($scope.allKPIsForProductivityTrend.length != $scope.selectedKPIsForProductivityModel.length){
            $scope.selectedActivitiesForProductivity = _.filter($scope.allActivities, function(obj, index){
                return obj.kpiSpecific == true
            })
        }
        else {
            $scope.selectedActivitiesForProductivity = $scope.allActivities;
        }
        if($scope.selectedActivitiesModelForProductivity.length == 0)
            getKPITrendOfJobRole($scope.selectedJobRoleForProductivity, 'productivity');
        else
            getProductivityTrendOfJobRole($scope.selectedJobRoleForProductivity);
    };

    $scope.kaiTrendChart = "Value";

    $scope.initFormGraphs =function(){
        //$scope.toggleKaiTrendGraph();
        $scope.toggleKpiTrendGraph();
        $scope.getSWOTTrendGraphData();
    };

    $scope.toggleKaiTrendGraph = function(){
        if(selectedPlayersForKAI.length >0)
            getKAITrendOfReportees();
        else
            getKAITrendOfJobRole($scope.selectedJobRole);
    };

     function getKAITrendOfJobRole(jobRole) {
        selectedPlayersForKAI=[];
        var selectedKPI = [{id: $scope.selectedKPIForKAITrend._id}];
        const postBody = {
            today,
            yearBack:YearBack,
            kpiId:selectedKPI,
            jobRoleId: jobRole._id,
            activityIds:selectedActivitiesForKAITrend
        }
        $http.post('/api/analytics/getActivityTrendForPlayerType', postBody).then(function(activityGraphData){
            activityGraphData=activityGraphData.data;
            var activityLevelData = {};
            activityLevelData.xLabels =[];
            activityLevelData.data = [{name:jobRole.name,data:[]}];
            var label= $scope.strings.POINTS.value, valueSuffix= $scope.strings.POINTS.value;
            for(var i in activityGraphData){
                activityLevelData.xLabels.push(i.replace('_',' '));
                if($scope.kaiTrendChart==$scope.strings.POINTS.value)
                    activityLevelData.data[0].data.push(activityGraphData[i].points);
                else{
                    activityLevelData.data[0].data.push(activityGraphData[i].values);
                    label = "Absolute Activity Level";
                    valueSuffix= " Abs Activity Level";
                }
            }
           $scope.hightChartLine('#kaiTrend-chart', 'line', '',label ,valueSuffix, activityLevelData )
        })
    }

    var getProductivityTrendOfJobRole = function (jobRole) {
        $scope.productivityPerOrLevelText = 'Absolute Activity Level (per reportee)';
        $scope.productivityPointsText = $scope.strings.POINTS.value+' (per reportee)';
        selectedPlayersForKAI=[];
        $scope.selectedJobRoleForProductivity = jobRole;
        const postBody = {
            today,
            yearBack:YearBack,
            kpiId:selectedKPIsForProductivityModel,
            jobRoleId: jobRole._id,
            activityIds:selectedActivitiesModelForProductivity
        }
        $http.post('/api/analytics/getActivityTrendForPlayerType', postBody).then(function(activityGraphData){
            activityGraphData=activityGraphData.data;
            var activityLevelData = {};
            activityLevelData.xLabels =[];
            activityLevelData.data = [{name:jobRole.name,data:[]}];
            var label= $scope.strings.POINTS.value+" (per frontline)", valueSuffix= $scope.strings.POINTS.value+" (per frontline)";
            for(var i in activityGraphData){
                activityLevelData.xLabels.push(i.replace('_',' '));
                if($scope.productivityTrendChart==$scope.strings.POINTS.value)
                    activityLevelData.data[0].data.push(Math.round(activityGraphData[i].points/$scope.numberOfFrontlinesForProductivity));
                else{
                    activityLevelData.data[0].data.push(Math.round(activityGraphData[i].values/$scope.numberOfFrontlinesForProductivity*100)/100);
                    label = "Absolute Activity Level";
                    valueSuffix= " Abs Activity Level"
                }
            }
            $scope.hightChartLine('#ProductivityTrend-chart', 'line', '',label ,valueSuffix, activityLevelData )
        })
    };

    $scope.kpiTrendChart = $scope.strings.POINTS.value;
    $scope.toggleKpiTrendGraph = function(){
        if(selectedPlayersForKPI.length >0)
            getKPITrendOfReportees($scope.jobRoleForPlayerKPITrend, selectedPlayersForKPI);
        else
            getKPITrendOfJobRole($scope.selectedJobRole);
    };
    $scope.productivityTrendChart = "Value";
    $scope.toggleProductivityTrendGraph = function(){
        if(selectedPlayersForProductivity.length != 0){
            if($scope.selectedActivitiesModelForProductivity.length != 0)
                getProductivityTrendOfReportees();
            else
                getKPITrendOfReportees($scope.selectedJobRoleForProductivity, selectedPlayersForProductivity, 'productivity');
        }

        else{
            if($scope.selectedActivitiesModelForProductivity.length == 0)
                getKPITrendOfJobRole($scope.selectedJobRoleForProductivity, 'productivity');
            else
                getProductivityTrendOfJobRole($scope.selectedJobRoleForProductivity);
        }
    };

    var getKPITrendOfJobRole= function(jobRole, type){
        $scope.productivityPerOrLevelText = 'Percentage of target (per reportee)';
        $scope.productivityPointsText = $scope.strings.POINTS.value+' (per reportee)';
        selectedPlayersForKPI=[];
        getNumberOfReportees(jobRole._id, function(noOfFrontlines){
            if($scope.selectedKPIsModel.length == 0){
                _.forEach($scope.allKPI, function(obj){
                    $scope.selectedKPIsModel.push({id: obj._id});
                })
            }
            const postBody = {
                today,
                yearBack:YearBack,
                kpiId:$scope.selectedKPIsModel,
                jobRoleId: jobRole._id,
                selectedPlayers:selectedPlayerIds
            }
            $http.post('/api/analytics/getKPITrendForPlayerType', postBody).then(function(kpiGraphData){
                kpiGraphData=kpiGraphData.data;
                var label= "Percentage of target", valueSuffix= "  %";
                var kpiData = {};
                kpiData.xLabels =[];
                kpiData.data = [{name:jobRole.name, data: []}];
                for(var i in kpiGraphData){
                    kpiData.xLabels.push(i.replace('_',' '));
                    if(type){
                        if($scope.productivityTrendChart=="Value") {
                            kpiData.data[0].data.push(Math.round(kpiGraphData[i].percentage/noOfFrontlines*100)/100);
                        }
                        else{
                            kpiData.data[0].data.push(Math.round(kpiGraphData[i].points)/noOfFrontlines);
                            label = $scope.strings.POINTS.value;
                            valueSuffix= $scope.strings.POINTS.value;
                        }
                    }
                    else{
                        if($scope.kpiTrendChart==$scope.strings.POINTS.value)
                            kpiData.data[0].data.push(Math.round(kpiGraphData[i].percentage*100)/100);

                        else{
                            kpiData.data[0].data.push(Math.round(kpiGraphData[i].points));
                            label = $scope.strings.POINTS.value;
                            valueSuffix=$scope.strings.POINTS.value;
                        }
                    }
                }
                if(type)
                    $scope.hightChartLine('#ProductivityTrend-chart', 'line', '',label ,valueSuffix, kpiData )
                else
                    $scope.hightChartLine('#kpiTrend-chart', 'line', '',label , valueSuffix, kpiData);
            });
        })
    };

    $scope.hightChartLine = function(id, chartType, chartHeader, ylabel, valueSuffix, data){
        var reqLegend = false;
        if(id == '#scorecard-graph'){
            reqLegend = true;
        }

        $(id).highcharts({
            //chart:{
            //    type:'line'
            //},
            title: {
                text: chartHeader
//                        text: 'Monthly Average Temperature',
//                        x: -20 //center
            },
//                    subtitle: {
//                        text: 'Source: WorldClimate.com',
//                        x: -20
//                    },
            xAxis: {
                title: {
                    text: 'Months'
                },
                categories: data.xLabels,
                labels: {
                    rotation: -90
                }
            },
            yAxis: {
                title: {
                    text: ylabel
                },
                plotLines: [{
                    value: 0,
                    width: 1,
                    color: '#808080'
                }]
            },
            tooltip: {
                valueSuffix: valueSuffix,
                label:{

                }
            },
            legend: {

                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle',
                borderWidth: 0

                //enabled: reqLegend,
                //layout: 'horizontal',
                //align: 'center',
                //verticalAlign: 'bottom',
                //borderWidth: 1
            },
            series: data.data,

            plotOptions: {
                line: {
                    events: {
                        legendItemClick: function (e) {
                            var visibleSeries = function () {
                                var counter = 0;
                                $.each(data.data, function (i, v) {
                                        if (v.visible) {
                                        counter++;
                                    }
                                });
                                return counter;
                            }
                            if (visibleSeries() <= 1 && this.visible) {
                                return false;
                            } else {
                                return true;
                            }
                        }
                    },
                    showInLegend: true
                }
            }

        });
    };

    $scope.selectedKPIsModel = [];
    $scope.selectedActivitiesForKAITrend = [];
    $scope.kpiEmptyText = {buttonDefaultText: 'Select KPIS', checkAll: 'Select All KPIs', uncheckAll: 'Deselect All KPIs'};
    $scope.kpiProductivityEmptyText = {buttonDefaultText: 'Select KPIS', checkAll: 'Select All KPIs', uncheckAll: 'Deselect All KPIs'};

    $scope.selectedActivitiesModelForProductivity = [];
    $scope.activityEmptyText = {buttonDefaultText: 'Select Activities', checkAll: 'Select All Activities', uncheckAll: 'Deselect All Activities'};

    $scope.kpiMultiSelectSettings = {
        smartButtonMaxItems: 3,
        buttonClasses : 'srManagerButton',
        displayProp: 'name',
        idProp: '_id',
        enableSearch: true,
        showUncheckAll: false,
        scrollableHeight: '400px',
        scrollable: true

    };
    $scope.activityMultiSelectSettings = {
        smartButtonMaxItems: 2,
        buttonClasses : 'srManagerButton',
        displayProp: 'name',
        idProp: '_id',
        enableSearch: true,
        scrollableHeight: '350px',
        scrollable: true
    };

    function getKPITrendOfReportees(selectedJobRole, selectedPlayers, type) {
        $scope.productivityPerOrLevelText = 'Percentage of target';
        $scope.productivityPointsText = $scope.strings.POINTS.value;

        var selectedPlayerIds = _.map(selectedPlayers, function (player) {
            return player._id
        });
        if($scope.selectedKPIsModel.length == 0){
            _.forEach($scope.allKPI, function(obj){
                $scope.selectedKPIsModel.push({id: obj._id});
            })
        }
        const postBody = {
            today,
            yearBack:YearBack,
            kpiId:$scope.selectedKPIsModel,
            jobRoleId: selectedJobRole._id,
            selectedPlayers:selectedPlayerIds
        }
        $http.post('/api/analytics/getKPITrendGraphData', postBody).then(function (kpiGraphData) {
            kpiGraphData=kpiGraphData.data;
            var kpiData = {};
            kpiData.data = [];

            var label = "Percentage of target", valueSuffix = "  %";
            Object.keys(kpiGraphData).forEach(function (key, index) {

                kpiData.data.push({name: key, data: [], visible: true});

                kpiData.xLabels = [];
                var kpiDatas = kpiGraphData[key];
                _.forEach(kpiDatas, function (kpi, i) {
                    kpiData.xLabels.push(kpi.cycleName);
                    if(type){
                        if($scope.productivityTrendChart=="Value") {
                            kpiData.data[index].data.push(Math.round(kpi.percentageAchieved*100)/100);

                        }
                        else{
                            kpiData.data[index].data.push(Math.round(kpi.points));
                            label = $scope.strings.POINTS.value;
                            valueSuffix=$scope.strings.POINTS.value;
                        }
                    }
                    else{
                        if($scope.kpiTrendChart==$scope.strings.POINTS.value)
                            kpiData.data[index].data.push(Math.round(kpi.percentageAchieved*100)/100);

                        else{

                            kpiData.data[index].data.push(Math.round(kpi.points));
                            label = $scope.strings.POINTS.value;
                            valueSuffix=$scope.strings.POINTS.value;
                        }
                    }

                })
            });
            if(type)
                $scope.hightChartLine('#ProductivityTrend-chart', 'line', '', label, valueSuffix, kpiData);
            else
                $scope.hightChartLine('#kpiTrend-chart', 'line', '', label, valueSuffix, kpiData);
        });
    }

    function getKAITrendOfReportees(){
        var selectedPlayerIds = _.map(selectedPlayersForKAI, function (player) {
            return player._id
        });
        var selectedKPI = [{id: $scope.selectedKPIForKAITrend._id}];
        const postBody = {
            today,
            yearBack:YearBack,
            kpiId:selectedKPI,
            activityIds: $scope.selectedActivitiesForKAITrend,
            selectedPlayers:selectedPlayerIds
        }
        $http.post('/api/analytics/getKAITrendGraphData', postBody).then(function (kaiGraphData) {
            kaiGraphData=kaiGraphData.data;
            var activityData = {};
            activityData.data = [];

            var label, valueSuffix;
            Object.keys(kaiGraphData).forEach(function (key, index) {
                activityData.data.push({name: key, data: [], visible: true});

                activityData.xLabels = [];
                var kaiDatas = kaiGraphData[key];
                _.forEach(kaiDatas, function (kpi, i) {
                    activityData.xLabels.push(kpi.cycleName);
                    if ($scope.kaiTrendChart == 'Value'){
                        label = "Absolute Activity Level";
                        valueSuffix= " Abs Activity Level";
                        activityData.data[index].data.push(kpi.sumOfActivityValues);
                    }
                    else {
                        activityData.data[index].data.push(kpi.sumOfActivityPoints);
                        label= $scope.strings.POINTS.value;
                        valueSuffix= $scope.strings.POINTS.value;
                    }
                })
            });
            $scope.hightChartLine('#kaiTrend-chart', 'line', '', label, valueSuffix, activityData);
        });

    }

    function getProductivityTrendOfReportees(){
        $scope.productivityPerOrLevelText = 'Absolute Activity Level';
        $scope.productivityPointsText = $scope.strings.POINTS.value;

        var selectedPlayerIds = _.map(selectedPlayersForProductivity, function (player) {
            return player._id
        });
        const postBody = {
            today,
            yearBack:YearBack,
            kpiId:$scope.selectedKPIsForProductivityModel,
            activityIds: $scope.selectedActivitiesModelForProductivity,
            selectedPlayers:selectedPlayerIds
        }
        $http.post('/api/analytics/getKAITrendGraphData', postBody).then(function (kaiGraphData) {
            kaiGraphData=kaiGraphData.data;
            var activityData = {};
            activityData.data = [];

            var label, valueSuffix;
            Object.keys(kaiGraphData).forEach(function (key, index) {
                activityData.data.push({name: key, data: [], visible: true});

                activityData.xLabels = [];
                var kaiDatas = kaiGraphData[key];
                _.forEach(kaiDatas, function (kpi, i) {
                    activityData.xLabels.push(kpi.cycleName);
                    if ($scope.productivityTrendChart == 'Value'){
                        label = "Absolute Activity Level";
                        valueSuffix= " Abs Activity Level";
                        activityData.data[index].data.push(kpi.sumOfActivityValues);
                    }
                    else {
                        activityData.data[index].data.push(kpi.sumOfActivityPoints);
                        label= $scope.strings.POINTS.value;
                        valueSuffix= $scope.strings.POINTS.value;
                    }
                })
            });
            $scope.hightChartLine('#ProductivityTrend-chart', 'line', '', label, valueSuffix, activityData);
        });

    }

    $scope.showReporteeKPITrendModal = function(){
        $mdDialog.open({
            templateUrl: autoImportNgTemplateLoaderTemplate1,
            controller: 'reporteewiseTrendModalController',
            size: 'md',
            resolve: {
                addPlayers: function (){
                    return addPlayersForKPITrend;
                },
                addAllPlayers: function (){
                    return addAllPlayersForKPITrend;
                },
                selectedJobRole : function(){
                    return $scope.selectedJobRole;
                },
                allJobroles: function(){
                    return $scope.jobRoles;
                },
                selectedPlayers: function(){
                    return selectedPlayersForKPI;
                }
            }
        });
    };

    $scope.showReporteeKAITrendModal = function(){
        $mdDialog.open({
            templateUrl: autoImportNgTemplateLoaderTemplate2,
            controller: 'reporteewiseTrendModalController',
            size: 'md',
            resolve: {
                addPlayers: function (){
                    return addPlayersForKAITrend;
                },
                addAllPlayers: function (){
                    return addAllPlayersForKAITrend;
                },
                selectedJobRole : function(){
                    return $scope.selectedJobRole;
                },
                allJobroles: function(){
                    return $scope.jobRoles;
                },
                selectedPlayers: function(){
                    return selectedPlayersForKAI;
                }
            }
        });
    };

    $scope.showReporteeProductivityTrendModal = function(){
        $mdDialog.open({
            templateUrl: autoImportNgTemplateLoaderTemplate3,
            controller: 'reporteewiseTrendModalController',
            size: 'md',
            resolve: {
                addPlayers: function (){
                    return addPlayersForProductivityTrend;
                },
                addAllPlayers: function (){
                    return addAllPlayersForProductivityTrend;
                },
                selectedJobRole : function(){
                    return $scope.selectedJobRoleForProductivity;
                },
                allJobroles: function(){
                    return $scope.jobRoles;
                },
                selectedPlayers: function(){
                    return selectedPlayersForProductivity;
                }
            }
        });
    };

    var addPlayersForKAITrend = function(selectedPlayers){
        $scope.selectedActivitiesForKAITrend=[];
        selectedPlayersForKAI = selectedPlayers;
        $scope.toggleKaiTrendGraph();
    };

    var addAllPlayersForKAITrend = function(selectedJobRole, noOfPlayers){
        //$scope.numberOfFrontlines =noOfPlayers;
        $scope.selectedJobRole = selectedJobRole;
        $scope.selectedActivitiesForKAITrend=[];
        $scope.selectedKPIForKAITrend = $scope.allKPIsForKAITrend[0];
        selectedPlayersForKAI =[];
        getKAITrendOfJobRole(selectedJobRole);
    };

    var addPlayersForKPITrend = function(selectedPlayers, selectedJobRole){
        $scope.selectedKPIsModel=[];
        selectedPlayersForKPI = selectedPlayers;
        $scope.jobRoleForPlayerKPITrend = selectedJobRole;
        $scope.toggleKpiTrendGraph();
    };

    var addAllPlayersForKPITrend = function(selectedJobRole, noOfPlayers){
        $scope.numberOfFrontlines =noOfPlayers;
        $scope.selectedJobRole = selectedJobRole;
        $scope.selectedKPIsModel=[];
        selectedPlayersForKPI =[];
        getKPITrendOfJobRole(selectedJobRole);
    };

    var onKPIChangeEvent = function(){
        if(selectedPlayersForKPI.length> 0)
            getKPITrendOfReportees($scope.jobRoleForPlayerKPITrend, selectedPlayersForKPI);
        else{
            getKPITrendOfJobRole($scope.selectedJobRole);
        }
    };

    var onActivityChangeForKAIEvent = function(){
        if(selectedPlayersForKAI.length> 0)
            getKAITrendOfReportees();
        else
            getKAITrendOfJobRole($scope.selectedJobRole);
    };

    var addPlayersForProductivityTrend = function(selectedPlayers, selectedJobRole){
        $scope.selectedJobRoleForProductivity = selectedJobRole;
        $scope.selectedActivitiesModelForProductivity=[];
        selectedPlayersForProductivity = selectedPlayers;
        $scope.toggleProductivityTrendGraph();
    };

    var addAllPlayersForProductivityTrend = function(selectedJobRole, noOfPlayers){
        $scope.numberOfFrontlinesForProductivity =noOfPlayers;
        $scope.selectedActivitiesModelForProductivity=[];
        $scope.selectedKPIForProductivityTrend = $scope.allKPIsForKAITrend[0];
        $scope.selectedJobRoleForProductivity = selectedJobRole;
        if($scope.selectedActivitiesModelForProductivity.length == 0)
            getKPITrendOfJobRole(selectedJobRole, 'productivity');
        else
            getProductivityTrendOfJobRole(selectedJobRole);
    }

    var onProductivityChangeEvent = function(){
        $scope.getProductivitySpecificActivities();
        if(selectedPlayersForProductivity.length> 0){
            if($scope.selectedActivitiesModelForProductivity.length != 0)
                getProductivityTrendOfReportees();
            else
                getKPITrendOfReportees($scope.selectedJobRoleForProductivity, selectedPlayersForProductivity, 'productivity');
        }
        else{
            if($scope.selectedActivitiesModelForProductivity.length == 0)
                getKPITrendOfJobRole($scope.selectedJobRoleForProductivity, 'productivity');
            else
                getProductivityTrendOfJobRole($scope.selectedJobRoleForProductivity);
        }
    };

    $scope.kpiEvents = {
        onItemDeselect: function(item) {
            onKPIChangeEvent();
        },
        onSelectAll: function(){
            onKPIChangeEvent();
        },
        onItemSelect: function(item){
            onKPIChangeEvent();
        },
        onDeselectAll: function(){
            onKPIChangeEvent();
        }
    };

    $scope.productivityEvents = {
        onItemDeselect: function(item) {
            onProductivityChangeEvent();
        },
        onSelectAll: function(){
            onProductivityChangeEvent();
        },
        onItemSelect: function(item){
            onProductivityChangeEvent();
        },
        onDeselectAll: function(){
            onProductivityChangeEvent();
        }
    };

    $scope.activityEventsForKAI = {
        onItemDeselect: function(item) {
            onActivityChangeForKAIEvent();
        },
        onSelectAll: function(){
            onActivityChangeForKAIEvent();
        },
        onItemSelect: function(item){
            onActivityChangeForKAIEvent();
        },
        onDeselectAll: function(){
            onActivityChangeForKAIEvent();
        }
    };

    $scope.productivityEvents = {
        onItemDeselect: function(item) {
            onProductivityChangeEvent();
        },
        onSelectAll: function(){
            onProductivityChangeEvent();
        },
        onItemSelect: function(item){
            onProductivityChangeEvent();
        },
        onDeselectAll: function(){
            onProductivityChangeEvent();
        }
    };

    $scope.selectedKPIsForProductivityModel = [];

    $scope.thresholds=['Strength', 'Weakness', 'Opportunity', 'Threat', 'Custom']

    $scope.selectedThreshold =$scope.thresholds[0];

    $scope.thresholdValue = 50;

    $scope.getSWOTTrendGraphData = function(thresholdValue){
        var swotSymbol = 'Custom';
        var swotValue = 0;

        if(thresholdValue< 0) {thresholdValue = 0; $scope.thresholdValue = 0}
        else if(thresholdValue >100) {thresholdValue =100; $scope.thresholdValue = 100}


        if($scope.selectedThreshold != 'Custom')
            swotSymbol = SwotService.getSWOTSymbol($scope.selectedThreshold);
        else{
            if(thresholdValue)
                swotValue = thresholdValue;
            else
                swotValue = $scope.thresholdValue;
        }
        getNumberOfReportees($scope.selectedJobRoleForPerformance._id, function(noOfFrontlines) {
            const postData = {
                swotType: swotSymbol,
                swotValue,
                today,
                yearBack: YearBack,
                kpiId: $scope.selectedKPIForPerformance._id,
                jobRoleId: $scope.selectedJobRoleForPerformance._id
            }
            $http.post('/api/analytics/getSWOTTrendGraphData', postData).then(function (swotData) {
                swotData=swotData.data;
                var performanceData = {};
                performanceData.data = [{name: $scope.selectedJobRoleForPerformance.name, data: []}];
                performanceData.xLabels = [];
                var label, valueSuffix;
                Object.keys(swotData).forEach(function (key, index) {
                    performanceData.xLabels.push(key);
                    var percentageCompleted = Math.round((swotData[key]/noOfFrontlines)*100);
                    performanceData.data[0].data.push(percentageCompleted);
                    label = "Percentage of Players (Above Threshold)";
                    valueSuffix = "% of Players";
                });
                $scope.hightChartLine('#PerformanceTrend-chart', 'line', '', label, valueSuffix, performanceData);
            });
        })
    };
});
