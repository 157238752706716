'use strict';
angular.module('bitnudgeApp')
	.controller('auditLogsCtrl', function ($scope, $http, toaster, UtilService, Sidebar) {
		$scope.modulesForAudit = ['users'];
		$scope.today = new Date();
		$scope.selected = {};
		$scope.selected.module = $scope.modulesForAudit[0];

		function getDateString(date) {
			return moment(date).format('YYYY-MM-DD')
		}

		function validateInputs() {
			if (!$scope.selected.module) {
                toaster.pop('error', 'Audit Logs', 'Please select the Module');
                return false
			}
			const fromDate = $scope.selected.fromDate;
            const toDate = $scope.selected.toDate;
            
			if (!fromDate || !toDate) {
                toaster.pop('error', 'Audit Logs', 'Please select both FROM and TO dates');
                return false
			}
			let months = toDate.getMonth() - fromDate.getMonth() +
				(12 * (toDate.getFullYear() - fromDate.getFullYear()));

			if (months && toDate.getDate() > fromDate.getDate()) {
				months++;
            }
			if (months > 3) {
                toaster.pop('error', 'Audit Logs', 'Please note that Select date range period should be within Max 3 months');
                return false;
			}

			return true;
		}
		$scope.fetchLogs = function (download) {
            $scope.selected.dataFetched = false;
			if (validateInputs()) {
				const model = $scope.selected.module;
				const fromDate = getDateString($scope.selected.fromDate);
				const toDate = getDateString($scope.selected.toDate)
				const postData = {
					module: model,
					startDate:fromDate,
					endDate: toDate
				}
                let url = '/api/auditLogs';
				if (!download) {
					$http.post(url, postData).then(result => {
                        result = result.data;
						if (result.status) {
                            $scope.logs = result.data;
						} else {
                            _.forEach(result.errors, error => {
                                toaster.pop('error', 'Audit logs', error.message);
							})
						}
                        $scope.selected.dataFetched = true;
					}).catch(error => {
                        console.log(error)
						toaster.pop('error', 'Audit logs', error);
					})
				} else {
					let appUrl = Sidebar.getAppUrl();
					let url = `/api/auditLogs`;
					const params = {
						module: model,
						startDate: fromDate,
						endDate: toDate,
						download: true
					}
                    appUrl += url;
					UtilService.downloadFile(appUrl, params);
				}

			}
		}

	});
