angular.module('bitnudgeApp')
    .controller('documentTypesController', function ($scope,Auth, Upload, toaster) {
        $scope.fileType = "documentTypes";
        $scope.onFileSelect = function () {
            if ($scope.files == undefined) {
                toaster.pop('error', "Submission.", "Select File to Upload.");
            } else {
                let fileName = $scope.files[0].name.split(".");
                let fileType = fileName[fileName.length - 1];
                if (fileType == "xls" || fileType == "xlsx" || fileType == "csv") {
                    Upload.upload({
                        url: '/api/documents/types/import',
                        data: {userId: Auth.getCurrentUser()._id},
                        file: $scope.files[0],
                        progress: function (e) {
                        }
                    }).then(function (result) {
                        let message = result.data.data.message;
                        toaster.pop('success', "Excel uploaded", message);
                    });
                } else {
                    toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' or '.csv' Types Only.");
                }
            }
        }
    });