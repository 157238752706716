const autoImportNgTemplateLoaderTemplate1 = require('../html/pipelineModal.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('heatmapMirrorChartCtrl', function ($scope, $http, heatmapMirrorChart, $rootScope,Auth, $location, $state, p0,$anchorScroll, toaster, $mdDialog, ForecastMultiplier) {
        var heatmapActivities = ['call', 'meeting', 'volumes', 'availability', 'accuracyp'];
        var importantActivities = [];
        $scope.config = p0.config;
        p0.allActivities.forEach(function (activity) {
            if(heatmapActivities.indexOf(activity.fieldName) != -1){
                var multiplier = 1;
                if(activity.fieldName == 'availability' || activity.fieldName == 'accuracyp') {
                    multiplier = 100;
                }
                importantActivities.push({fieldName:activity.fieldName, name:activity.shortName, multiplier: multiplier, kpiSpecific:activity.kpiSpecific});
            }
        });
        $scope.currentUser = Auth.getCurrentUser();
        $scope.config = $rootScope.config;
        $scope.isFrontline = $rootScope.isFrontline;
        $scope.isSeniorManager = $rootScope.isSeniorManager;
        $scope.isMyPeer = false;
        $scope.mtd = 'MTD';
        if(!$scope.isCycleActive) $scope.mtd = 'ACTUAL';
        function init() {
            if($scope.kpi != 'overall'){
                $scope.importantActivities = _.filter(importantActivities,{kpiSpecific:true});
            }else{
                $scope.importantActivities = importantActivities;
            }
            $scope.checkedUsers= [];
            if($scope.kpi){
                $scope.selectedKpi = _.find(p0.allKpis,{_id:$scope.kpi});
            }
            if($scope.selectedKpi){
                var submission = _.find(p0.allActivities,{fieldName:'submission'});
                if(submission && !_.find($scope.selectedKpi.activities,function(tm){ return tm.toString() == submission.toString();})){
                    _.remove($scope.importantActivities,{fieldName:'submission'});
                }
            }
            if($scope.type == 'activities'){
                $scope.activities = [($scope.selectedKpi?$scope.selectedKpi.name:'')+' MTD'];
                if(p0.config.lms){
                    $scope.activities.push('Pipeline');
                }
                if($scope.kpi){
                    var selectedActivity = _.find(p0.allActivities,(act) => {
                        return act._id && $scope.activity && act._id.toString() === $scope.activity.toString();
                    });
                    if(selectedActivity){
                        switch (selectedActivity.name){
                            case 'Interactions':
                                $scope.conversionName = "Interaction conversion ratio";
                                break;
                            case 'Lead creation':
                                $scope.conversionName = "overall conversion ratio";
                                break;
                            case 'Application submission':
                                $scope.conversionName = "Submission conversion ratio";
                                break;
                            case 'Client calls':
                                $scope.conversionName = "Calls conversion ratio";
                                break;
                            case 'Meetings':
                                $scope.conversionName = "Meetings conversion ratio";
                                break;
                            case 'Emails':
                                $scope.conversionName = "Emails conversion ratio";
                                break;
                            case 'Other':
                                $scope.conversionName = "Other conversion ratio";
                                break;
                        }
                    }
                }
            }else if($scope.type == 'scorecard'){
                $scope.activities = _.map($scope.importantActivities,'name');
                if(p0.config.lms) {
                    $scope.activities.push('Pipeline');
                }
            }else{
                $scope.activities = [($scope.selectedKpi?$scope.selectedKpi.name:'')+' MTD'];
                var names = _.map($scope.importantActivities,'name');
                $scope.activities = $scope.activities.concat(names);
            }
            $scope.selectedActivity = $scope.activities[0];


            $scope.$watch('userId', function(newVal, oldVal) {
                if(oldVal){
                    _.forEach($scope.data, function(reportee){
                        if(reportee.checked)
                            reportee.checked = false;
                    });
                    var oldIndex = _.findIndex($scope.data, {userId: oldVal});
                    if(oldIndex > -1){
                        $scope.data[oldIndex].checked = false;
                    }
                }
                if($scope.data){
                    $scope.currentUserIndex = _.findIndex($scope.data, {userId: $scope.userId});
                    if($scope.currentUserIndex > -1){
                        $scope.data[$scope.currentUserIndex].checked = true;
                        $scope.checkedUsers =[$scope.userId];
                    }
                    $location.hash($scope.currentUserIndex+'');
                    $anchorScroll()
                }
            });

            heatmapMirrorChart.getMirrorChartData($scope.userId,$scope.kpi,$scope.activity,$scope.type,$scope.target,$scope.isWeekly, $scope.cycleId).then(function(args){
                if(args[0]) {
                    var data = args[0];
                    var leagues = args[1];
                    $scope.reporteeLeagues = [];
                    leagues.forEach(function (league) {
                        $scope.reporteeLeagues.push({_id:league.teamType, name:'All Players in '+league.teamTypeName});
                    });
                    $scope.selectedLeagueId = $scope.reporteeLeagues[0]._id;
                    if(!data.length) return;
                    $scope.showAchieved = heatmapMirrorChart.showAchieved;
                    $scope.data = data;
                    $scope.maxMTD = 100;
                    if ($scope.type == 'scorecard') {
                        $scope.maxActivities = {};
                        $scope.importantActivities.forEach(function (activity) {
                            $scope.maxActivities[activity.fieldName] = _.maxBy(data, function (user) {
                                return user[activity.fieldName].value
                            })[activity.fieldName].value;
                        });
                    } else if ($scope.type == 'activities') {
                        $scope.maxActivitiy = _.maxBy(data, 'activity').activity;
                    }else if ($scope.type == 'pipeline'){
                        $scope.data = _.sortBy(data, 'pipeline').reverse();
                    }
                    $scope.isPlayer = heatmapMirrorChart.getIsPlayer();
                    if ($scope.isPlayer && $scope.isReportee) {
                        $scope.currentUserIndex = _.findIndex($scope.data, {userId: $scope.userId});
                        $scope.data[$scope.currentUserIndex].checked = true;
                        $scope.checkedUsers = [$scope.userId];
                        $scope.selectedLeagueId = $scope.reporteeLeagues[0]._id;
                        $scope.filterData($scope.selectedLeagueId);
                    } else if (!$scope.isReportee) {
                        $scope.currentUserIndex = _.findIndex($scope.data, {userId: $scope.userId});
                        $scope.data[$scope.currentUserIndex].checked = true;
                        $scope.checkedUsers = [$scope.userId];
                    }
                    $location.hash($scope.currentUserIndex+'');
                    $anchorScroll();
                    $scope.isMyPeer = heatmapMirrorChart.getJobRole().roleType == $scope.currentUser.roleType;
                    $scope.showCompare =  heatmapMirrorChart.getJobRole().roleType != 'seniorManager';
                }
                else{
                    $scope.data= null;
                }
            });
        }
        $scope.forecastMultiplier = ForecastMultiplier.ofCycle($scope.cycleId);
        $scope.$watch('[kpi,activity,isReportee]',function(oldValue,newValue){
            init();
        });
        $scope.filterData = function(selectedLeagueId){
            if(selectedLeagueId == 'bank') {
                $scope.data = heatmapMirrorChart.getAllBankData();
            }else if(selectedLeagueId == 'region') {
                $scope.data = heatmapMirrorChart.getAllRegionData();
            }else {
                $scope.data = heatmapMirrorChart.getReporteeData();
            }
            $scope.maxMTD = 100;
            if($scope.type == 'scorecard' || $scope.type == 'pipeline') {
                $scope.maxActivities = {};
                $scope.importantActivities.forEach(function(activity){
                    $scope.maxActivities[activity.fieldName] = _.maxBy($scope.data,function(user){
                        return user[activity.fieldName].value
                    })[activity.fieldName].value;

                });
            } else if($scope.type == 'activities'){
                $scope.maxActivitiy = _.maxBy($scope.data,'activity').activity;
            }
        };

        $scope.userClicked = function(user){
            if(user.userId != $scope.userId) {
                if (user.checked) {
                    user.checked = false;
                    _.pull($scope.checkedUsers, user.userId);
                } else if ($scope.checkedUsers.length < 4) {
                    user.checked = true;
                    $scope.checkedUsers.push(user.userId);
                }else{
                    return toaster.pop('error', 'Comparison Limit', "You cannot select more than 4 players for Comparison");
                }
            }
        };

        $scope.compare = function(){
            var myComparison = false;
            if($rootScope.isSeniorManager)
                myComparison = false;
            else
                myComparison = $scope.isMyPeer;
            if($scope.checkedUsers.length < 2){
                if($rootScope.myComparison)
                    return toaster.pop('error', 'Comparison Selection', "Please select at least 1 player for Comparison");
                else
                    return toaster.pop('error', 'Comparison Selection', "Please select at least 2 players for Comparison");
            }
            if(myComparison){
                _.pull($scope.checkedUsers, $scope.userId);
            }
            $rootScope.playersFromLB = $scope.checkedUsers;
            $rootScope.jobRoleFromLB = heatmapMirrorChart.getJobRole();
            var roleType = '';  //manager, frontline
            if($rootScope.jobRoleFromLB.roleType == 'manager')
                roleType = 'manager';
            else
                roleType = 'frontline';
            $state.go('userFlow.analytics', {tabName: 'playerComparison', myComparison: myComparison, roleType: roleType});
        };

        $scope.comparePlayer = function(user){
            $rootScope.playersFromLB = [user.userId];
            $rootScope.jobRoleFromLB = heatmapMirrorChart.getJobRole();
            var roleType = '';  //manager, frontline
            if($rootScope.jobRoleFromLB.roleType == 'manager')
                roleType = 'manager';
            else
                roleType = 'frontline';
            $state.go('userFlow.analytics', {tabName: 'playerComparison', myComparison: true, roleType: roleType});
        };

        $scope.openPipelineModal = function(user){
            $mdDialog.show({
                templateUrl:autoImportNgTemplateLoaderTemplate1,
                controller:'heatMapPipelineModalCtrl',
                size:'lg',
                resolve:{
                    kpiId:function(){
                        return $scope.kpi
                    },
                    userId:function(){
                        return user.userId
                    },
                    userName:function (){
                        return user.userName
                    }
                }
            })
        };
        $scope.gotoOCR = function(user){
            $state.go('userFlow.oneClickReport',{selectedPlayerIdForOCR:user.userId});
        };
        $scope.gotoGP = function (user) {
            $state.go('userFlow.gameplan',{selectedPlayerId:user.userId});
        };
        $scope.gotoMe = function(){
            $anchorScroll();
        };
        $scope.gotoSimulateGP = function (user) {
            $state.go('userFlow.gameplanSimulation',{userId:user.userId});
        };

        $scope.openModal = function(item){
            angular.forEach($scope.data, function (currentItem) {
                currentItem.openModalClicked = currentItem === item && !currentItem.openModalClicked;
            });
        }
    });
