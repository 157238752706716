const autoImportNgTemplateLoaderTemplate1 = require('../html/moreDataCard.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('chartCardCtrl', function ($scope, $mdPanel, $element, $mdDialog, $stateParams, Auth, $http, $state, p0, analytics) {

        $scope.user = Auth.getCurrentUser();
        $scope.cycleType = 'monthly';
        $scope.badges = [{}, {}, {}, {}, {}];
        var maxLabelsInExpanded = 10;
        var maxLabelsInCollapsed = 6;

        var chartsData = [], chartLegends = [];

        $scope.addRemoveOptions = angular.copy($scope.addRemoveOptions);
        $scope.benchmarkOptions = angular.copy($scope.benchmarkComparisonOptions);

        $scope.graphEvents = {};
        $scope.cycleTypes = [
            {
                id: "monthly",
                name : 'Monthly'
            },{
                id: "weekly",
                name : 'Weekly'
            }
        ];
        $scope.hideUserValue = $scope.metric === 'combinedActivity';
        $scope.summaryItems = [
            {
                id : 'min',
                name : 'Minimum'
            },{
                id : 'max',
                name : 'Maximum'
            },{
                id : 'avg',
                name : 'Average'
            }
        ];
        $scope.peerAnalyticsData = {};
        var selectedCycles, chartLabels;

        var graphCreationProperties = {
            cycleTypeId : $scope.cycleTypes[0].id,
            unitId : $scope.units[0].id,
            indicatorId : $scope.indicator._id,
            addRemoveOptions : $scope.addRemoveOptions,
            benchmarkOptions : $scope.benchmarkOptions
        };

        $scope.lineOptions = {
            axisX: {
                showGrid: false,
            },
            chartPadding: {
                top: 30,
                bottom: 0,
                left: 0
            },
            plugins: [],
            width: '320px',
            height: '320px'
        };

        $scope.onIndicatorChange = function(indicator){
            graphCreationProperties.indicatorId = indicator._id;
            $scope.indicator = indicator;

            switch($scope.metric){
                case "kpi" :
                    $scope.onKpiChange(indicator);
                    generateGraphAndSummary();
                    break;
                case "combinedActivity" :
                    $scope.onCombinedActivityChange(indicator);

                    if($scope.indicator._id === 'conversionRatios'){
                        $scope.gameAnalyticsData = angular.copy($scope.activityConversionRatiosData);
                    }else{
                        $scope.gameAnalyticsData = angular.copy($scope.graphCreationData);
                    }

                    if($scope.gameAnalyticsData){
                        generateGraphForCombinedActivity();
                    }
                    break;
            }
        }

        $scope.$watch('graphCreationData', function(){
            if(!$scope.graphCreationData) return;
            if($scope.metric === 'combinedActivity'){
                if($scope.indicator._id === 'conversionRatios'){
                    $scope.gameAnalyticsData = angular.copy($scope.activityConversionRatiosData);
                }else{
                    $scope.gameAnalyticsData = angular.copy($scope.graphCreationData);
                }
                generateGraphForCombinedActivity();
            }else if($scope.metric === 'activity' && $scope.combinedActivity._id === 'conversionRatios'){
                $scope.gameAnalyticsData = angular.copy($scope.activityConversionRatiosData);
                generateGraphAndSummary();
            }else{
                $scope.gameAnalyticsData = angular.copy($scope.graphCreationData);
                generateGraphAndSummary();
            }
        })

        $scope.$watch('benchmarkComparisonOptions', function(benchmarkComparisonOptions){
            var benchmarkOptions = angular.copy($scope.benchmarkComparisonOptions);
            $scope.benchmarkOptions= benchmarkOptions;
        })

        $scope.$watch('activityPeerAnalyticsData', function(newValue){
            if( !$scope.activityPeerAnalyticsData || _.isEmpty($scope.activityPeerAnalyticsData)) return;
            angular.merge($scope.peerAnalyticsData, $scope.activityPeerAnalyticsData);
            generateGraphAndSummary();
        }, true);

        $scope.$watch('kpiPeerAnalyticsData', function(newValue){
            if( !$scope.kpiPeerAnalyticsData || _.isEmpty($scope.kpiPeerAnalyticsData)) return;

            angular.merge($scope.peerAnalyticsData, $scope.kpiPeerAnalyticsData);
            generateGraphAndSummary();
        }, true);

        $scope.onUnitChange = function(selectedUnit){
            graphCreationProperties.unitId = selectedUnit.id;
            generateGraphOnMetricBasis();
        }

        $scope.onCycleTypeChange = function(selectedCycleType){
            graphCreationProperties.cycleTypeId = selectedCycleType.id;
            selectedCycles = fetchSelectedCycles(graphCreationProperties);
            chartLabels = getChartLabels(selectedCycles, graphCreationProperties);
            generateGraphOnMetricBasis();
        }

        $scope.cardExpanded = false;
        $scope.expandLayout = function () {
            $scope.cardExpanded = !$scope.cardExpanded;
            $scope.lineOptions.width = $scope.cardExpanded ? '570px' : '320px';
            selectedCycles = fetchSelectedCycles(graphCreationProperties);
            chartLabels = getChartLabels(selectedCycles, graphCreationProperties);
            generateGraphOnMetricBasis();
        };

        $scope.onAddRemoveOptionsChange = function(addRemoveOptions){
            graphCreationProperties.addRemoveOptions = addRemoveOptions;
            generateGraphOnMetricBasis();
        }

        $scope.onBenchmarkOptionsChange = function(benchmarkOptions){
            graphCreationProperties.benchmarkOptions = benchmarkOptions;
            $scope.benchmarkOptions = benchmarkOptions;

            var peerIds = _.find(benchmarkOptions, {label : 'Custom'}).selectedOptions;
            var peerDataRequired = [];
            peerDataRequired = _.filter(peerIds, function(peerId){
                return !$scope.peerAnalyticsData[peerId];
            });
            if(peerDataRequired.length > 0){
                $scope.getPeerAnalyticsData(peerDataRequired, $scope.metric);
            }else{
                generateGraphAndSummary();
            }
        }

        function generateGraphOnMetricBasis(){
            switch($scope.metric){
                case "combinedActivity" :
                        generateGraphForCombinedActivity();
                    break;
                default :
                    generateGraphAndSummary();

            }
        }

        function generateGraphForCombinedActivity(){
            $scope.lineData  = {
                labels : chartLabels,
                series : [],
                seriesLegends : []
            }

            _.each($scope.indicator.activities, function(activity, index){

                graphCreationProperties.indicatorId = activity._id;

                var chartDataAndLegend = generateGraph(graphCreationProperties, graphCreationProperties.indicatorId, $scope.gameAnalyticsData, index);
                if(!chartDataAndLegend) return ;

                if(!selectedCycles || !chartLabels){
                    selectedCycles = fetchSelectedCycles(graphCreationProperties);
                    chartLabels = getChartLabels(selectedCycles, graphCreationProperties);
                    $scope.lineData.labels = chartLabels;
                }

                updateChartData(chartDataAndLegend);
            })

            $scope.summaryItems =  _.map($scope.summaryItems, function(summaryItem){

                summaryItem.series = [];
                summaryItem.series = _.map($scope.lineData.series, function(line, index){
                    var  summaryObj = {
                        name : $scope.lineData.seriesLegends[index].label
                    };
                    switch(summaryItem.id){
                        case "min":
                            summaryObj.value = line.reduce(function(minValue, value) {
                                return minValue < value ? minValue : value;
                            },  line[0]);
                            break;
                        case "max":
                            summaryObj.value = line.reduce(function(maxValue, value) {
                                return maxValue > value ? maxValue : value;
                            },  line[0]);
                            break;
                        case "avg":
                            summaryObj.value =  ( line.reduce(function(totalValue, value) {
                                return totalValue + value;
                            },  0))/line.length;
                            break;

                    }
                    summaryObj.value = Number(summaryObj.value.toFixed(2));
                    return summaryObj;
                });
                return summaryItem;
            });
        }

        function generateGraphAndSummary(){
            if($scope.gameAnalyticsData){
                generateGraphSeries(graphCreationProperties);
                calculateSummaryValues(graphCreationProperties);
            }
        }

        function generateGraphSeries(graphCreationProperties){
            var chartDataAndLegend = generateGraph(graphCreationProperties, graphCreationProperties.indicatorId, $scope.gameAnalyticsData);

            if(!chartDataAndLegend) return ;

            if(!selectedCycles || !chartLabels){
                selectedCycles = fetchSelectedCycles(graphCreationProperties);
                chartLabels = getChartLabels(selectedCycles, graphCreationProperties);
            }
            $scope.lineData = {
                labels : chartLabels,
                series : [chartDataAndLegend.series],
                seriesLegends : [chartDataAndLegend.legend],
            };
            _.each(graphCreationProperties.addRemoveOptions, function(addRemoveOption){
                if(addRemoveOption.allSelected || _.some( addRemoveOption.options, function(option){ return option.selected})){
                    generateIndicatorGraphs(graphCreationProperties, addRemoveOption);
                }
            });

            _.each(graphCreationProperties.benchmarkOptions, function(benchmarkOption){
                if(benchmarkOption.isCustom){
                    generatePeersGraph(graphCreationProperties, benchmarkOption, $scope.peerAnalyticsData);
                }else{
                    var benchmarkData;
                    if($scope.metric === 'activity' && $scope.combinedActivity._id === 'conversionRatios'){
                        benchmarkData = $scope.activityConversionRatiosBenchmarkData;
                    }else{
                        benchmarkData = $scope.benchmarkData;
                    }

                    if(benchmarkOption.left.selected){
                        generateBenchmarkGraph(graphCreationProperties, benchmarkOption.left, benchmarkData);
                    }

                    if(benchmarkOption.right.selected){
                        generateBenchmarkGraph(graphCreationProperties, benchmarkOption.right, benchmarkData);
                    }
                }
            });
        }

        function generatePeersGraph(graphCreationProperties, benchmarkOption, peersAnalyticsData){
            var cycleTypeId = graphCreationProperties.cycleTypeId;
            var unitId = graphCreationProperties.unitId;
            var indicatorId = graphCreationProperties.indicatorId;

            _.each(benchmarkOption.selectedOptions, function(peerId){
                if( !peersAnalyticsData || !peersAnalyticsData[peerId] || !peersAnalyticsData[peerId][cycleTypeId] || !peersAnalyticsData[peerId][cycleTypeId][indicatorId] ) return;

                var series = _.map( peersAnalyticsData[peerId][cycleTypeId][indicatorId], function(indicatorData){
                    return getValueForGraphBasedOnUnit(indicatorData, unitId, 'value');
                });

                var peerName = _.find(benchmarkOption.options, {_id : peerId}).name;

                var legend = {
                    label : peerName,
                    series : peerName
                };

                updateChartData({
                    legend : legend,
                    series  : series
                });

            })
        }

        function generateBenchmarkGraph(graphCreationProperties, benchmarkOption, benchmarkData) {
            var cycleTypeId = graphCreationProperties.cycleTypeId;
            var unitId = graphCreationProperties.unitId;
            var indicatorId = graphCreationProperties.indicatorId;

            if(! benchmarkData  || ! benchmarkData[cycleTypeId] || ! benchmarkData[cycleTypeId][indicatorId]) return;

            var series = _.map( benchmarkData[cycleTypeId][indicatorId], function(indicatorData){
                return getValueForGraphBasedOnUnit(indicatorData, unitId, benchmarkOption.id);
            });
            var legend = {
                label : benchmarkOption.name,
                series : benchmarkOption.name
            };

            updateChartData({
                legend : legend,
                series  : series
            });
        }

        function updateChartData(legendAndSeriesData){
            $scope.lineData.series.push(legendAndSeriesData.series);
            $scope.lineData.seriesLegends.push(legendAndSeriesData.legend);
        }

        function generateIndicatorGraphs(graphCreationProperties, addRemoveOption){

            if( addRemoveOption.allSelected){
                var filteredIndicators  =  _.filter( $scope.myIndicators , function(indicator){
                    return graphCreationProperties.indicatorId !== indicator._id
                });
                var selectedIndicators = _.map( filteredIndicators, '_id');

            }else{
                var selectedIndicators =  _.filter ( addRemoveOption.options, function(option) {
                    return option.selected;
                });
                selectedIndicators = _.map(selectedIndicators, '_id');
            }

            _.each(selectedIndicators, function(indicator, index){
                if($scope.metric === 'activity' && $scope.combinedActivity._id ==='conversionRatios') {
                    var chartDataAndLegend = generateGraph(graphCreationProperties, indicator, $scope.graphCreationData, index);
                }else{
                    var chartDataAndLegend = generateGraph(graphCreationProperties, indicator, $scope.gameAnalyticsData, index);
                }
                if(chartDataAndLegend){
                    updateChartData(chartDataAndLegend);
                }
            });
        }

        function generateGraph(graphCreationProperties, indicatorId, graphDataSeries, index){
            if(index === undefined) index = 0;
            var cycleTypeId = graphCreationProperties.cycleTypeId;
            var unitId = graphCreationProperties.unitId;

            if(!graphDataSeries || !graphDataSeries[cycleTypeId] || !graphDataSeries[cycleTypeId][indicatorId]) return;

            $scope.leafCount = graphDataSeries[cycleTypeId][indicatorId][0] ? graphDataSeries[cycleTypeId][indicatorId][0].leafCount || 1  : 1;

            var series = _.map(graphDataSeries[cycleTypeId][indicatorId], function(indicatorData){
                return getValueForGraphBasedOnUnit(indicatorData, unitId, "value");
            });

            if($scope.metric === 'combinedActivity'){
                var indicator = _.find($scope.indicator.activities, {_id : indicatorId});
            }else{
                var indicator = _.find($scope.myIndicators, {_id : indicatorId});
            }
            var legend = {
                label : indicator.name,
                series : String.fromCharCode(97 + index)
            };
            return {
                legend :  legend,
                series : series
            }
        }

        function getValueForGraphBasedOnUnit(indicatorData, unitId, attribute) {
            switch(unitId){
                case "percentageCompleted" :
                case "achievedValue":
                    return indicatorData[unitId][attribute]/$scope.indicator.denom || 0;
                case "value" : return indicatorData[unitId][attribute] || 0;
                case "perFTE" : return indicatorData[unitId][attribute] || 0;
                case "perDayPerFTE" : return indicatorData.perFTE[attribute]/30;
            }
        }

        function fetchSelectedCycles(graphCreationProperties){
            if(!$scope.gameAnalyticsData || !$scope.gameAnalyticsData[graphCreationProperties.cycleTypeId] || !$scope.gameAnalyticsData[graphCreationProperties.cycleTypeId][graphCreationProperties.indicatorId]) {
                return [];
            }
            return _.map($scope.gameAnalyticsData[graphCreationProperties.cycleTypeId][graphCreationProperties.indicatorId], 'cycleId');
        }

        function getChartLabels(selectedCycles, graphCreationProperties){

            if(graphCreationProperties.cycleTypeId === $scope.cycleTypes[0].id){
                var chartLabels = _.filter(p0.allMonthlyCycles, function(cycle){
                    return _.includes( selectedCycles, cycle._id);
                })

            }else{
                var chartLabels = _.filter(p0.allWeeklyCycles, function(cycle){
                    return _.includes( selectedCycles, cycle._id);
                })
            }

            chartLabels = _.sortBy(chartLabels, function(cycle){
                return cycle.startDate;
            });

            if($scope.cardExpanded){
                chartLabels = chartLabels.length > maxLabelsInExpanded ? filterLabels(chartLabels) : _.map(chartLabels ,'name');
            }else{
                chartLabels =  chartLabels.length > maxLabelsInCollapsed ? filterLabels(chartLabels) : _.map(chartLabels ,'name');
            }
            return chartLabels;
        }

        function filterLabels(chartLabels) {
            var quotient = $scope.cardExpanded ? chartLabels.length / maxLabelsInExpanded : chartLabels.length / maxLabelsInCollapsed;
            quotient  = Math.round(quotient);
            return _.map(chartLabels, function (label, index) {
                return index % quotient === 0 ? label.name : "";
            });
        }

        function calculateSummaryValues(graphCreationProperties){

            var cycleTypeId = graphCreationProperties.cycleTypeId;
            var unitId = graphCreationProperties.unitId;
            var indicatorId = graphCreationProperties.indicatorId;

            if(!$scope.gameAnalyticsData || !$scope.gameAnalyticsData[cycleTypeId] || !$scope.gameAnalyticsData[cycleTypeId][indicatorId]) return;

            var userDataSeries = $scope.gameAnalyticsData[cycleTypeId][indicatorId];

            $scope.summaryItems =  _.map($scope.summaryItems, function(summaryItem){
                summaryItem.userValue = fetchValue(userDataSeries, unitId, summaryItem.id);

                summaryItem.series = [];
                _.each(graphCreationProperties.addRemoveOptions, function(addRemoveOption){
                    var addedSeries = fetchSummaryValuesOfAddedSeries(addRemoveOption, cycleTypeId, unitId, indicatorId, summaryItem.id);
                    summaryItem.series = summaryItem.series.concat(addedSeries);
                });

                _.each(graphCreationProperties.benchmarkOptions, function(benchmarkOption){
                    if(benchmarkOption.isCustom){
                        if(benchmarkOption.selectedOptions.length > 0) {
                            _.each(benchmarkOption.selectedOptions, function (peerId) {
                                var peerSummaryObj = fetchPeerSummary(benchmarkOption, peerId, cycleTypeId, unitId, indicatorId, summaryItem.id);
                                summaryItem.series.push(peerSummaryObj);
                            });
                        }
                    }else{
                        var benchmarkSummaryObj = fetchSummaryValuesOfBenchmarks( benchmarkOption.left, cycleTypeId, unitId ,indicatorId, summaryItem.id, benchmarkOption.left.id);
                        if(benchmarkSummaryObj){
                            summaryItem.series.push(benchmarkSummaryObj);
                        }
                        var benchmarkSummaryObj = fetchSummaryValuesOfBenchmarks( benchmarkOption.right, cycleTypeId, unitId ,indicatorId, summaryItem.id, benchmarkOption.right.id);
                        if(benchmarkSummaryObj){
                            summaryItem.series.push(benchmarkSummaryObj);
                        }
                    }
                });

                return summaryItem;
            });
        }

        function fetchPeerSummary(peerBenchmarkOption, peerId, cycleTypeId, unitId, indicatorId, type){
            var peerName = _.find(peerBenchmarkOption.options, {_id : peerId}).name;
            var seriesObj = {};
            seriesObj.name = peerName;
            seriesObj.value = fetchValue ( $scope.peerAnalyticsData[peerId][cycleTypeId][indicatorId], unitId, type , 'value');
            return seriesObj;
        }

        function fetchSummaryValuesOfBenchmarks( benchmarkValue, cycleTypeId, unitId, indicatorId, type, attribute){
            if(benchmarkValue.selected){
                var seriesObj = {};
                seriesObj.name = benchmarkValue.name;
                seriesObj.value = fetchValue ( $scope.benchmarkData[cycleTypeId][indicatorId], unitId, type , attribute);
                // seriesObj.type = 'benchmark';
                // seriesObj.id = benchmarkValue.id;
                return seriesObj;
            }
        }

        function fetchSummaryValuesOfAddedSeries(addRemoveOption, cycleTypeId, unitId, indicatorId, type){
            var selectedOptions , series = [];
            if(addRemoveOption.allSelected){
                selectedOptions = _.map($scope.myIndicators, '_id');
                selectedOptions = _.without(selectedOptions, indicatorId);
            }else{
                selectedOptions = _.filter(addRemoveOption.options, function(option){
                    return option.selected;
                });
                selectedOptions = _.map( selectedOptions, '_id');
            }
            if(selectedOptions.length > 0){
                _.each(selectedOptions, function(optionsId){
                    var seriesObj = {};

                    switch(addRemoveOption.id){
                        case "indicator" :
                            seriesObj.name = _.find($scope.myIndicators, {_id : optionsId}).name;

                            if($scope.metric === 'activity' && $scope.combinedActivity._id ==='conversionRatios') {
                                seriesObj.value = fetchValue($scope.graphCreationData[cycleTypeId][optionsId], unitId, type);
                            }else{
                                seriesObj.value = fetchValue($scope.gameAnalyticsData[cycleTypeId][optionsId], unitId, type);
                            }
                            break;
                    }
                    series.push(seriesObj);
                });
            }
            return series;
        }

        function fetchValue(indicatorSeries, unitId, type, attribute){
            switch(type){
                case 'min' : return fetchMinimumValue(indicatorSeries, unitId, attribute);
                case 'max' : return fetchMaximumValue(indicatorSeries, unitId, attribute);
                case 'avg' : return fetchAverageValue(indicatorSeries, unitId, attribute);
            }
        }

        function fetchMinimumValue(indicatorSeries, unitId, attribute){
            if(!indicatorSeries) return 0;

            if(!attribute){
                attribute = 'value';
            }

            var initialValue = getValueForGraphBasedOnUnit(indicatorSeries[0], unitId, attribute);

            var minimumValue = indicatorSeries.reduce(function(minimumValue, indicatordData) {
                var value = getValueForGraphBasedOnUnit(indicatordData, unitId, attribute);
                return minimumValue < value ? minimumValue : value;
            },  initialValue);

            return Number(minimumValue.toFixed(2));
        }

        function fetchMaximumValue(indicatorSeries, unitId, attribute){
            if(!indicatorSeries) return 0;

            if(!attribute){
                attribute = 'value';
            }
            var initialValue = getValueForGraphBasedOnUnit(indicatorSeries[0], unitId, attribute);

            var maximumValue = indicatorSeries.reduce(function(maximumValue, indicatordData) {
                var value = getValueForGraphBasedOnUnit(indicatordData, unitId, attribute);
                return maximumValue > value ? maximumValue : value;
            },  initialValue);

            return Number(maximumValue.toFixed(2));
        }

        function fetchAverageValue(indicatorSeries, unitId, attribute){
            if(!indicatorSeries) return 0;
            if(!attribute){
                attribute = 'value';
            }
            var totalValue = indicatorSeries.reduce(function(totalValue, indicatorData) {
                return totalValue + getValueForGraphBasedOnUnit(indicatorData, unitId, attribute);
            },  0);

            var averageValue = totalValue / indicatorSeries.length;
            return Number(averageValue.toFixed(2));
        }

        var mdPanelRef;
        $scope.showBenchmarkLayout = function ($event) {

            if (!mdPanelRef) {
                var position = $mdPanel.newPanelPosition()
                    .absolute()
                    .bottom('36px'); //36px is the height of the benchmark row

                var panelAnimation = $mdPanel.newPanelAnimation()
                    .openFrom($event)
                    .withAnimation($mdPanel.animation.SCALE);

                var config = {
                    attachTo: $element.find('md-card'),
                    controller: 'benchmarkCtrl',
                    controllerAs: 'ctrl',
                    templateUrl: '/html/benchmarkWidget.html', //todo: verify correct widget?
                    panelClass: 'benchmark-layout',
                    position: position,
                    locals: {
                        'benchmarkOptions': angular.copy($scope.benchmarkComparisonOptions),
                        'onBenchmarkOptionsChange' : $scope.onBenchmarkOptionsChange
                    },
                    animation: panelAnimation,
                    focusOnOpen: false,
                    hasBackdrop: true,
                    clickOutsideToClose: true,
                    escapeToClose: false
                };

                $mdPanel.open(config).then(function (result) {
                    mdPanelRef = result;
                });
            } else {
                mdPanelRef.config.locals.benchmarkOptions[1].options  = angular.copy($scope.benchmarkOptions[1].options); //angular.copy($scope.benchmarkComparisonOptions[1].options);
                var selectedOptions = mdPanelRef.config.locals.benchmarkOptions[1].selectedOptions;
                mdPanelRef.config.locals.benchmarkOptions[1].options  = _.map( mdPanelRef.config.locals.benchmarkOptions[1].options, function(peerOption){
                    peerOption.selected = _.includes(selectedOptions, peerOption._id);
                    return peerOption;
                });
                mdPanelRef.open();
            }
        };

        var useDialogForMoreData = false;
        var mdPanelRef_1;
        $scope.showMoreDataCard = function ($event) {
            var position = $mdPanel.newPanelPosition()
                .absolute()
                .bottom('0%')
                .left('0%');

            var panelAnimation = $mdPanel.newPanelAnimation()
                .openFrom($event)
                .withAnimation($mdPanel.animation.SCALE);

            var data = {
                units : $scope.units,
                cycleTypes: $scope.cycleTypes,
                indicator : $scope.indicator,
                myIndicators : $scope.myIndicators,
                summaryItems : $scope.summaryItems,
                lineData : $scope.lineData,
                selectedCycles : selectedCycles,
                selectedCycleType : graphCreationProperties.cycleTypeId,
                isMoreDataCard : true,
                hideUserValue : $scope.hideUserValue
            };
            var local = { data : data };
            var config = {
                attachTo: $element.find('md-card'),
                controller: 'moreDataCardCtrl',
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                panelClass: 'more-data-card',
                position: position,
                locals: local,
                animation: panelAnimation,
                focusOnOpen: false,
                hasBackdrop: false,
                clickOutsideToClose: false,
                escapeToClose: false
            };

            $mdPanel.open(config).then(function (result) {
            });
        };


        $scope.disableAddSeries = false;

        $scope.onOptionSelect = function (selected) {

        };
    })
    .controller('benchmarkCtrl', function ($scope, mdPanelRef) {
        $scope.closeBenchmark = function () {
            mdPanelRef.close();
            $scope.ctrl.onBenchmarkOptionsChange($scope.ctrl.benchmarkOptions);
        }

        $scope.onBenchmarkSelect = function(benchmarkOption){
            benchmarkOption.selected = !benchmarkOption.selected;
        }

        $scope.customBenchmarkChange = function(selectedOptions, benchmarkOption){
            benchmarkOption.selectedOptions = selectedOptions;
        }

        $scope.isPeerSelected = function(peerId, selectedPeers){
            return _.includes(selectedPeers, peerId);
        }

    });

