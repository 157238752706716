'use strict';
angular.module('bitnudgeApp')
    .controller('pointsBoardCtrl', function ($scope, $timeout, $rootScope, $location, $http, Auth,  $redirectRouter, moment, $filter, $q, $stateParams, toaster, DTOptionsBuilder, DTColumnDefBuilder, GetDeltaImageService, p0, Gender) {

        //common stuff reqd. in multiple tabs starts
        $scope.strings = $rootScope.strings;
        $scope.me = Auth.getCurrentUser();
        $scope.isUserBranchManager = false;
        $scope.checkUserRole = function () {
            if ($scope.me && $scope.me.systemRole == 'manager')
                $scope.isUserBranchManager = true;
            else
                $scope.isUserBranchManager = false;
        };
        $scope.checkUserRole();
        $scope.getSimulationStatus = function () {
            $scope.hideSimulation = false;
        }
        $scope.getSimulationStatus();
        $scope.me = Auth.getCurrentUser();
        $scope.reporteePlayerTypes = p0.myFrontlineRoles;
        $scope.reporteePlayerType = $scope.reporteePlayerTypes[0];

        $scope.goToNewPageFromPlaybookAnalyze = function (val) {

            if (val == "rank") {
                $rootScope.fnLeaderboardBack = true;
                $location.path(getLeaderboardPage());
            }
            else if (val == "Level") {
                $rootScope.levelAnalytic = true;
                $rootScope.fnLeaderboardBack = true;
                $location.path(getAnalyticsPage());
            }
            else if (val == "Badges Won") {
                $("#badgeCornerTab").trigger('click');
            }
            else {
                $rootScope.pointsAnalytic = true;
                $location.path(getAnalyticsPage());
            }
        };



        var getLeaderboardPage = function () {
            if ($scope.me.systemRole == "frontline")                  return '/userFlow/leaderBoard-new';
            else if ($scope.me.systemRole == "manager")    return '/userFlow/branchManagerLeaderBoard';
            else if ($scope.me.systemRole == "seniorManager")       return '/userFlow/leaderboard-mgr';
        };

        var getAnalyticsPage = function () {
            return '/userFlow/analytics';
        };


        $scope.cycles = p0.allMonthlyCycles;

        $scope.monthlyAndQuarterlyCycles = _.filter($scope.cycles, function (cycle) {
            return ( (cycle.active == true) && (cycle.cycleType == "monthly" || cycle.cycleType == "quarterly") )
        })
        $scope.selectedMonthlyAndQuarterlyCycle = $scope.monthlyAndQuarterlyCycles[0];

        $scope.selectedCycle =  p0.currentMonthlyCycle;
        $scope.selectedCycleForImpact =  p0.currentMonthlyCycle;
        $scope.selectedCycleForActivity =  p0.currentMonthlyCycle;
        //$scope.getAllLeoData();
        //$scope.setCompetitiveFrame(Auth.getCurrentUser().organizationalTag.jobRole);
        //$scope.getPointsTree();
        $scope.activityTM = p0.allActivities;
        $scope.impactTM = p0.allKpis;

        //active: true, cycleType: monthly,
        var monthlyAndQuarterlyCycles = [];
        $.each($scope.cycles, function (index, cycle) {
            if ((cycle.active == true) && ( (cycle.cycleType == "monthly") || (cycle.cycleType == "quarterly") )) {
                monthlyAndQuarterlyCycles.push(cycle);
            }
            if (cycle.active == true && cycle.cycleType == "monthly") {
                $scope.currentMonthCycle = cycle;
            }
        });
        $scope.monthlyAndQuarterlyCycles = monthlyAndQuarterlyCycles;
        $scope.selectedMonthlyAndQuarterlyCycle = $scope.monthlyAndQuarterlyCycles[0];


        $http.post('/api/analytics/playersNearMe', {cycleId: $scope.currentMonthCycle._id} ).then(function (playersNearMe) {
            playersNearMe=playersNearMe.data;
            $scope.playersNearMe = playersNearMe;
            $scope.playersNearMe.forEach(function (obj) {
                if ((obj.image == undefined) || (obj.image == null)) {
                    obj.image = Gender.getAvatarImage();
                }
                obj.totalPoints = Math.floor(obj.totalPoints);
            })

            $scope.meFromPlayersNearMe = _.find(playersNearMe, {userId: Auth.getCurrentUser()._id});
        });


        $scope.goToNewPageFromPlaybookAnalyze = function (val) {

            if (val == "rank") {
                $rootScope.fnLeaderboardBack = true;
                $location.path(getLeaderboardPage());
            }
            else if (val == "Level") {
                $rootScope.levelAnalytic = true;
                $rootScope.fnLeaderboardBack = true;
                $location.path(getAnalyticsPage());
            }
            else if (val == "Badges Won") {
                $("#badgeCornerTab").trigger('click');
            }
            else {
                $rootScope.pointsAnalytic = true;
                $location.path(getAnalyticsPage());
            }
        };
    });
