'use strict';

angular.module('bitnudgeApp')
    .service('redemptionOptions', function ($http) {
        this.getData = function(){
            return $http.post('/api/rewards/redemptionOptions').then(function(data){
                data=data.data;
                data.yourOptions = _.remove(data.rewards,function(reward){
                    return reward.points <= data.balance.points || reward.gems <= data.balance.gems;
                });

                data.yourOptions=  _.sortBy(data.yourOptions,function(reward){
                    return reward.points;
                });
                data.rewards = _.sortBy(data.rewards,function(reward){
                    return reward.points;
                });
                return data;
            });
        }
    });

