const autoImportNgTemplateLoaderTemplate1 = require('../html/chatPage/chatList.html');

'use strict';
angular.module('bitnudgeApp')
    .component('chatList', {
        templateUrl: autoImportNgTemplateLoaderTemplate1,
        bindings: {
            filter: '<',
            view:'<',
            list: '<',
            onSelect: '&',
            idsAdded: '=',    //
            idsRemoved: '='
        },
        controller: function ($scope, $element, $attrs) {
            var ctrl = this;
            if (ctrl.view == 'chats') {

            }
            ctrl.onTap = function (obj) {
                if (ctrl.view =='addContacts') {
                    if (ctrl.idsAdded.indexOf(obj.chip.id) == -1) {
                        angular.element(obj.event.target).parent().find('.fa-circle-o').addClass('fa-circle').removeClass('fa-circle-o');
                        angular.element(obj.event.target).parents('.md-button').addClass('selected').find('.fa-plus').addClass('fa-inverse fa-check').removeClass('blue fa-plus');

                        ctrl.idsAdded.push(obj.chip.id);
                    }
                    else {
                        angular.element(obj.event.target).parent().find('.fa-circle').addClass('fa-circle-o').removeClass('fa-circle');
                        angular.element(obj.event.target).parents('.md-button').removeClass('selected').find('.fa-check').removeClass('fa-inverse fa-check').addClass('blue fa-plus');
                        _.pull(ctrl.idsAdded, obj.chip.id);
                    }
                }
                else if (ctrl.view== 'removeContacts') {
                    if (ctrl.idsRemoved.indexOf(obj.chip.id) == -1) {
                        angular.element(obj.event.target).parent().find('.fa-circle-o').addClass('fa-circle').removeClass('fa-circle-o');
                        angular.element(obj.event.target).parents('.md-button').addClass('selected').find('.fa-minus').addClass('fa-inverse fa-times').removeClass('blue fa-minus');
                        ctrl.idsRemoved.push(obj.chip.id);
                    }
                    else {
                        angular.element(obj.event.target).parent().find('.fa-circle').addClass('fa-circle-o').removeClass('fa-circle');
                        angular.element(obj.event.target).parents('.md-button').removeClass('selected').find('.fa-times').removeClass('fa-inverse fa-times').addClass('blue fa-minus');
                        _.pull(ctrl.idsRemoved, obj.chip.id);
                    }
                }
                else {
                    ctrl.onSelect ({chip: obj.chip});
                }
            };

        }
    });
