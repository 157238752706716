const autoImportNgTemplateLoaderTemplate1 = require('../../html/rejectDocModal.html');

"use strict";

angular.module("bitnudgeApp").controller("previewComponentCtrl", function($scope, Auth, DocumentService, toaster, $mdDialog, $interval, $rootScope) {
    const currentUser = Auth.getCurrentUser();
    $scope.zoom = 1;
    $scope.documentLoaded = false;
    let documentLoadedId = "";
    let readIntervalTimer;
    const cancelIntervalTimer = () => {
        if (angular.isDefined(readIntervalTimer)) {
            $interval.cancel(readIntervalTimer);
            readIntervalTimer = undefined;
        }
    }
    this.$onInit = () => {
        $scope.document = {};
        $scope.previousVersions = [];
        $scope.params = {
            showApprove: false,
            showReject: false,
            showSelect: false,
            approveText: 'approve',
            selectText: 'select',
            preApproved: false
        };
    };
    this.$onChanges = changes => {
        if (changes.document) {
            if (!changes.document.isFirstChange()) {
                $scope.document = changes.document.currentValue;
                if ($scope.document) {
                    if ($scope.document && (documentLoadedId !== $scope.document._id)) {
                        cancelIntervalTimer()
                    }
                    preparePreviewView($scope.document);
                    setIntervalTimer();
                }
                $scope.documentLoaded = true;
            } else {
                $scope.documentLoaded = false;
            }
        }
        if (changes.selectMode) {
            $scope.selectMode = changes.selectMode.currentValue;
        }
        if (changes.approveMode) {
            $scope.approveMode = changes.approveMode.currentValue;
        }
        if (changes.viewMode) {
            $scope.viewMode = changes.viewMode.currentValue;
        }
        if (changes.workflowId) {
            $scope.workflowId = changes.workflowId.currentValue;
        }

        if ($scope.document && $scope.selectMode) {
            setShowSelect($scope.document)
        }

        if ($scope.document && $scope.approveMode) {
            setApproveReject($scope.document);
        }
    };


    const setApproveReject = document => {
        const status = ['approved'];
        if (document && document.selectedFor && document.selectedFor.includes($scope.workflowId)) {
            if ((document.status === 'uploaded' || document.status === 'active') && !document.approvedByMe) {
                $scope.params.approveText = 'approve';
                $scope.params.preApproved = false;
            }
            if (status.includes(document.status) && document.approvedByMe) {
                $scope.params.approveText = 'approved';
                $scope.params.preApproved = true;
            }
            $scope.params.showApprove = true;
            $scope.params.showReject = true;
        } else {
            $scope.params.showApprove = false;
            $scope.params.showReject = false;
        }
    };

    const setShowSelect = document => {
        if (document && document.status && !DocumentService.status.negative.includes(document.status)) {
            $scope.params.showSelect = true;
        } else {
            $scope.params.showSelect = false;
        }

        if (document && document.selectedFor && document.selectedFor.includes($scope.workflowId)) {
            $scope.params.selectText = 'Currently selected';
        } else {
            $scope.params.selectText = 'select';
        }
    };


    let preparePreviewView = document => {
        if (document.uploadedFileIds) {
            $scope.documentLoaded = false;
            documentLoadedId = document._id;
            $scope.docthumbnails = document.uploadedFileIds.map(file => {
                if(!file){
                    return;
                }
                let url = file.dmsUrl;
                if(file.fileType === 'pdf' && !url.includes('token')){
                    url = `${url}?token=${$rootScope.dmsToken}`
                }
                return {
                    _id: file._id,
                    fileType: file.fileType,
                    data: url,
                    thumbnail: {
                        dmsUrl: file.isThumbnailAvailable && file.thumbnail ? file.thumbnail.dmsUrl : null
                    },
                    isThumbnailAvailable: file.isThumbnailAvailable,
                };
            });
            $scope.preview = $scope.docthumbnails[0];
            if ($scope.docTypeTemp !== $scope.document.documentTypeId) {
                $scope.versionactive = false;
                $scope.previousVersions = [];
            }
            $scope.documentLoaded = true;
        } else {
            $scope.preview = null
        }

    };


    const READING_INTERVAL_UNIT = 10000; // as per the requirement for every 10sec
    let setIntervalTimer = () => {
        if (angular.isDefined(readIntervalTimer)) {
            return
        }
        readIntervalTimer = $interval(function() {
            if ($rootScope.openDMS) {
                updateReadingTimeFunc();
            }
        }, READING_INTERVAL_UNIT);
    };

    const readingToken = Date.parse(new Date());
    const updateReadingTimeFunc = () => {
        if ($scope.document._id && ['active', 'uploaded', 'approved'].includes($scope.document.status)) {
            DocumentService.updateReadingTime({ docId: $scope.document._id, readingToken, fileId: $scope.preview._id, readingTimeinMs: READING_INTERVAL_UNIT }).then(() => {
                // console.log('reading time updated for the document')
            }, () => {
                console.error('error in reading the document')
            })
        }
    };

    const toBytes = content => {
        //conversion needed from binary to bytesArray
        let raw = window.atob(content);
        let rawLength = raw.length;
        let array = new Uint8Array(new ArrayBuffer(rawLength));

        for (let i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    };

    $scope.zoomOutContent = event => {
        if ($scope.zoom <= 3 && $scope.zoom > 0.5) {
            $scope.zoom = $scope.zoom - 0.5;
        }
    };
    $scope.zoomInContent = event => {
        if ($scope.zoom >= 0.5 && $scope.zoom < 3) {
            $scope.zoom = $scope.zoom + 0.5;
        }
    };
    $scope.fullscreenContent = () => {
        console.log("TODO");
    };

    $scope.selectThumbnail = fileId => {
        $scope.preview = $scope.docthumbnails.find(doc => {
            return doc._id === fileId;
        });
    };

    $scope.getPreviousVersions = () => {
        $scope.versionactive = !$scope.versionactive;
        if ($scope.versionactive) {
            $scope.versionsLoaded = false;
            DocumentService.previousVersions({
                    documentTypeId: $scope.document.documentTypeId,
                    cui: $scope.document.cui,
                    workflowId: $scope.workflowId
                })
                .then(docversions => {
                    docversions.forEach((version) => {
                        if (version.uploadedFileIds[0] && version.uploadedFileIds[0].thumbnail) {
                            version.uploadedFileIds[0].thumbnail.dmsUrl = version.uploadedFileIds[0].thumbnail.dmsUrl
                        }
                    });
                    $scope.previousVersions = docversions;
                    $scope.versionsLoaded = true;
                })
                .catch(err => {
                    $scope.versionsLoaded = true;
                    console.error(err.message);
                    toaster.pop("error", "Error in document previous versions", err.message);
                });
        } else {
            $scope.previousVersions = [];
            $scope.$emit("dms:version:hide");
        }
    };

    $scope.selectDoc = document => {
        const data = { docId: document._id, typeId: document.documentTypeId, workflowId: $scope.workflowId, cui: document.cui, };
        DocumentService.markActive(data)
            .then(result => {
                const { status, data, errors } = result;
                if (status) {
                    $scope.params.selectText = 'selected';
                    toaster.pop("success", data.message);
                    $rootScope.openDMS = false;
                    $scope.$emit("dms:trigger:close");
                } else {
                    toaster.pop("error", "Document not selected", errors[0].message);
                }
            })
            .catch(err => {
                console.error(err);
                toaster.pop("error", "Error in document selection", err.message);
            });
    };

    $scope.rejectDoc = document => {
        $mdDialog
            .show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: "RejectDocCtrl",
                clickOutsideToClose: true,
                preserveScope: true,
                autoWrap: true,
                resolve: {}
            })
            .then(result => {
                if (result) {
                    const { documentTypeId } = document;
                    const workflowId = $scope.workflowId;
                    let { comment, reason } = result;
                    if (!comment) {
                        comment = reason.title;
                    }
                    const data = { docId: document._id, documentTypeId, workflowId, notes: comment, label: reason.title };
                    DocumentService.reject(data).then(result => {
                            const { status, data, errors } = result;
                            if (status) {
                                $rootScope.openDMS = false;
                                $scope.$emit("dms:trigger:close");
                                toaster.pop("success", data.message);
                            } else {
                                toaster.pop("error", "Document not rejected", errors[0].message);
                            }
                        })
                        .catch(err => {
                            console.error(err);
                            toaster.pop("error", "Error in document rejected", err.message);
                        });
                }
            });
    };

    $scope.approveDoc = document => {
        const { documentTypeId } = document;
        const workflowId = $scope.workflowId;
        const data = { docId: document._id, documentTypeId, workflowId };
        DocumentService.approve(data)
            .then(result => {
                const { status, data, errors } = result;
                if (status) {
                    $scope.params.approveText = 'approved';
                    $scope.params.preApproved = true;
                    toaster.pop("success", data.message);
                    $rootScope.openDMS = false;
                    $scope.$emit("dms:trigger:close");
                } else {
                    toaster.pop("error", "Document not approved", errors[0].message);
                }
            })
            .catch(err => {
                console.error(err);
                toaster.pop("error", "Error in document approval", err.message);
            });
    };

    $scope.showVersionDetail = docId => {
        if (docId !== $scope.document._id) {
            $scope.docTypeTemp = $scope.document.documentTypeId;
            $scope.$emit("dms:version:show", { docId });
        }
    };

    this.$onDestroy = () => {
        cancelIntervalTimer();
    }

    $scope.$on('dms:noversion:hide', (evt, data) => {
        $scope.versionactive = false;
        $scope.previousVersions = [];
    })
});