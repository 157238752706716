const autoImportNgTemplateLoaderTemplate1 = require('../html/rhsAnalyticsL2Social.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('rhsAnalyticsL2Social', function(){
        return {
            name: 'rhsAnalyticsL2Social',
            scope: {
                changeView: '='
            },
            controller: 'rhsAnalyticsL2SocialCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
