'use strict';

angular.module('bitnudgeApp')
  .controller('OrganizationCtrl', function ($scope,$http,$location) {
    $scope.message = 'Hello';
    $http.post('/api/messages').then(function(messages) {
        messages=messages.data;
        $scope.messages = messages;
        //socket.syncUpdates('message', $scope.messages);
    });

    $scope.addMessage = function() {
        if($scope.message === '') {
            return;
        }
        $http.post('/api/messages', { name: $scope.newMessage, info: $scope.newInfo }).then(function() {
                // this callback will be called asynchronously
                // when the response is available
                $location.path('/message');
            }).catch(function() {
                // called asynchronously if an error occurs
                // or server returns response with an error status.
            });
        $scope.newMessage = '';
    };

    $scope.deleteMessage = function(message) {
        $http.post('/api/messages/archive',{id: message._id} );
    };

    $scope.$on('$destroy', function () {
        //socket.unsyncUpdates('message');
    });
  });
