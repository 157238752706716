'use strict';
angular.module('bitnudgeApp')
    .controller('redeemModalCtrl', function (reward,balance, $scope, p0,$mdDialog, $http, $rootScope,toaster) {
        $scope.strings = $rootScope.strings;
        $scope.reward = reward;
        $scope.balance = balance;
        $scope.onClose = function(){
            $mdDialog.cancel();
        };

        $scope.redeem = function (type) {
            $http.post('/api/redemptions/redeem',{rewardId: reward._id, type}).then(function(){
                balance[type] -= reward[type];
                toaster.pop("success", "Redemption request has been made!");
                $mdDialog.cancel();
            });
        };
    });
