const autoImportNgTemplateLoaderTemplate1 = require('../html/reporteesHierarchy.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('leadOverviewCtrl', function ($scope, p0, $rootScope, $state, $mdDialog, Auth, mgrDashboardMetricService) {
        var MAX_PRIMARY_METRICE_LENGTH = 7;
        $scope.selected = {};
        $scope.allLeadTypes = [];
        $scope.allProducts = [];
        var kpiNameMap = {
            'K05': 'RBL',
            'K06': 'TWC',
            'K07': 'Liabilities'
        };
        var currentUser = Auth.getCurrentUser();
        var myLeadKpis = [];
        p0.myPlayTypes.forEach(function(playType){
            myLeadKpis = myLeadKpis.concat(playType.leadKpis);
        });
        // myLeadKpis = _.uniqBy(myLeadKpis, function(object){
        //     return object._id.toString();
        // });
        // myLeadKpis.forEach(function(kpi){
        //     $scope.allProducts.push({
        //         _id: kpi._id,
        //         name: kpi.name
        //     });
        // });
        $scope.selected.product = $scope.allProducts[0];
        // $scope.allLeadTypes = _.cloneDeep(p0.leadTypes);
        // $scope.allLeadTypes = $scope.allLeadTypes.filter(function(leadType) { return !leadType.toLowerCase().includes('pos')});
        // if (_.indexOf($scope.allLeadTypes, 'All') === -1) {
        //     $scope.allLeadTypes.unshift('All');
        // }


        // var leadStageObj = {};
        // var kpiStageMap = {};
        // Object.keys(p0.stageToStatus).forEach(function (key) {
        //     var temp = [];
        //     if (!_.isEmpty(p0.stageToStatus[key])) {
        //         Object.keys(p0.stageToStatus[key]).forEach(function (stageName) {
        //             temp.push(`${kpiNameMap[key]}-${stageName}`);
        //             !leadStageObj[stageName] ? leadStageObj[stageName] = [kpiNameMap[key]] : leadStageObj[stageName].push(kpiNameMap[key]);
        //             !kpiStageMap[kpiNameMap[key]] ? kpiStageMap[kpiNameMap[key]] =
        //                 [stageName.toLowerCase()] : kpiStageMap[kpiNameMap[key]].push(stageName.toLowerCase());
        //         })
        //     }
        // });
        var stagesToShow = [];
        var watchVar = `leadOverviewData`;
        if ($scope.widgetName === 'Agent Lead'){
            watchVar = `leadOverviewData`;
        }
        $scope.$watch(`leadOverviewData`, function(newVal, oldVal){
            init();
        });

        $scope.moreInfo = function() {
            $rootScope.widgetSidePanelData = {};
            $rootScope.widgetSidePanelData = {
                widgetName: 'Lead Overview',
                //product: $scope.selected.product.name,
                //kpiId: $scope.selected.product._id,
                //leadType: $scope.selected.leadType
            };
            $rootScope.secondaryMetrices = [];
            $rootScope.secondaryMetrices = _.orderBy($scope.secondaryMetrices, ['secondarySeq']);
        }

        var init = async function() {
            // var stagesToShow = kpiStageMap[$scope.selected.product.name];
            // var allStageMetrices = _.uniq(_.flatten(Object.values(kpiStageMap)));
            if ($scope.selectedReportee && $scope.selectedReportee.email) {
                let activeAgents = await mgrDashboardMetricService.getActiveAgents(`${$scope.selectedReportee.email}(team(self+reportees))`);
                $scope.activeAgents = activeAgents.activeAgents;
            } else {
                $scope.activeAgents = 0;
            }

            var mgrConfig = $scope.configData.mgrConfig.data;
            var currentCycle = $scope.configData.currentCycle;
            var roleType = $scope.configData.roleType;
            var configuredMetrics = $scope.leadOverviewData['W_LEAD_PARAMS'];
            if ($scope.widgetName === 'Agent Lead'){
                configuredMetrics = $scope.leadOverviewData['W_LEAD_PARAMS_1'];
            }
            configuredMetrics = configuredMetrics && configuredMetrics.data;
            var configuredMetricIds = _.map(configuredMetrics, 'metric');
            var selectedConfigs = mgrConfig.filter(function(config){
                return configuredMetricIds.includes(config.metric);
            });
            $scope.primaryMetrices = [];
            $scope.verticalPrimaryMetrics = [];
            $scope.secondaryMetrices = [];
            selectedConfigs.forEach(function(config) {
                var rejectConfig = false;
                if (config.isVisible) {
                    // if (allStageMetrices.includes(config.label.toLowerCase())) {
                    //     if (!stagesToShow.includes(config.label.toLowerCase())) {
                    //         rejectConfig = true;
                    //     }
                    // }
                    rejectConfig = false;
                    if (!rejectConfig){
                        config.result = _.find(configuredMetrics, {'metric': config.metric}).data;
                        if (config.isPrimary) {
                            $scope.primaryMetrices.push({
                                label: config.label,
                                isVisible: config.isVisible,
                                color: config.color || '#3777AF',
                                value: config.result.total,
                                metric: config.metric,
                                result: config.result,
                                unit: config.unit,
                                positionSequence: config.positionSequence,
                                displayName: config.displayName
                            })
                        }
                        if(config.isSecondary) {
                            $scope.secondaryMetrices.push({
                                label: config.label,
                                isVisible: config.isVisible,
                                color: config.color || '#3777AF',
                                value: config.result.total,
                                metric: config.metric,
                                result: config.result,
                                unit: config.unit,
                                displayName: config.displayName,
                                secondarySeq: config.sequence
                            })
                        }
                    }
                }
            });
            $scope.primaryMetrices = _.orderBy($scope.primaryMetrices, ['positionSequence']);
            if ($scope.primaryMetrices.length > MAX_PRIMARY_METRICE_LENGTH) {
                $scope.verticalPrimaryMetrics = $scope.verticalPrimaryMetrics.concat($scope.primaryMetrices.slice(MAX_PRIMARY_METRICE_LENGTH, $scope.primaryMetrices.length))
            }
            var externalParams = _.cloneDeep($scope.dynamicParams);
            delete externalParams.metrics;
            externalParams.cycleId = $scope.configData.currentCycle._id;
            externalParams.roleType = $scope.configData.roleType;
            $rootScope.externalParams = externalParams;
            $scope.onProductChange = function() {
                $scope.changeProduct({
                        product: $scope.selected.product.name
                    }
                );
            }
            $scope.onLeadTypeChange = function() {
                $scope.changeLeadType({
                        leadType: $scope.selected.leadType
                    }
                );
            }
            $scope.moreInfo();
        }

        $scope.reporteesHierarchyDialog = function (metric) {
            $rootScope.externalParams.selectedMetric = metric.metric;
            $rootScope.externalParams.loadFullData = true;
            //$rootScope.externalParams.kpiId = $scope.selected.product._id;
            $rootScope.externalParams.kpiId = null;
            if (currentUser.systemRole.toLowerCase() === 'frontline') {
                if (metric.value === 0) {
                    return;
                }
                var params = {externalParams: $rootScope.externalParams};
                $state.go('lms.listLead', params);
            } else {
                $mdDialog.show({
                    templateUrl: autoImportNgTemplateLoaderTemplate1,
                    controller: 'reporteesHierarchyController',
                    resolve: {
                        leadsData: function () {
                            return metric.result;
                        },
                        widget: function() {
                            return 'Lead Overview'
                        },
                        label: function () {
                            return metric.displayName;
                        },
                        kpiId: function () {
                            return null;
                        },
                        unit: function () {
                            return metric.unit;
                        },
                        metricName: function() {
                            return metric.metric;;
                        }
                    }
                });
            }
        }
    });