const autoImportNgTemplateLoaderTemplate1 = require('../html/gpSimulation.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('gpSimulation', function () {
        return {
            name: 'gpSimulation',
            scope: {
            },
            controller: 'gpSimulationCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
