"use strict";

angular.module("bitnudgeApp").controller("archiveDocument", function($scope, $mdDialog) {
	$scope.onClose = () => {
		$mdDialog.cancel();
	};
	$scope.archive = () => {
		$mdDialog.hide($scope.comment);
	};
});
