const autoImportNgTemplateLoaderTemplate1 = require('../html/badgeCarousel.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('badgeCarousel', function(){
        return {
            name: 'badgeCarousel',
            scope: {
                selectedDivision:   '=',
                selectedUser:       '=',
                selectedKpi:        '=',
                cycleId:            '=',
                isSingleKpi:        '=',
                switchChange:       '=', //Reportee frontline switch change
                showOneBadge:       '=',
                selectedPlayType:   '='
            },
            controller: 'badgeCarouselCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
