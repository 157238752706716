'use strict';

angular.module('bitnudgeApp')
    .service('OCR', function ($http,p0, $q) {
        this.getActivityTrendGraph = function(user,activityId, activityType) {
            var endDate = new Date(p0.currentMonthlyCycle.endDate);
            var startDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
            const postData = {
                userId: user._id,
                startDate: startDate.getTime(),
                endDate: endDate.getTime(),
                kpiId: null,
                activityId,
                metric: 'kai',
                cycleType: 'monthly'
            }
            return $http.post('/api/customReports/trend', postData).then(function(graphData){
                graphData=graphData.data;
                return $http.post('/api/customReports/trendBenchmark', postData).then(function(result){
                    let benchMarkData=result.data.data;
                    var multiplier = (activityType == 'percentage' ? 100 : 1);
                    graphData.forEach(function(data){
                        var benchMark = _.find(benchMarkData,{cycleId:data.cycleId});
                        if(benchMark){
                            user.teams.forEach(function(team){
                                data[team.teamType] = benchMark[team.teamType]?(benchMark[team.teamType].avg * multiplier):null;
                            });
                        }
                    });
                    var max = _.maxBy(benchMarkData, function(data){return data.bank.max.value});
                    let temp;
                    if(max && max.bank && max.bank.max){
                        temp = max.bank.max.value * multiplier;
                    }
                    return $q.resolve(graphData, temp);
                });
            });
        };

        this.getRatioTrendGraph = function(user,kpiId,activityId,metricType){
            var endDate = new Date(p0.currentMonthlyCycle.endDate);
            var startDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
            const postData = {
                userId: user._id,
                startDate: startDate.getTime(),
                endDate: endDate.getTime(),
                kpiId: kpiId,
                activityId,
                metric: metricType,
                cycleType: 'monthly'
            }
            return $http.post('/api/customReports/trend', postData).then(function(graphData){
                graphData=graphData.data;
                const postData = {
                    userId: user._id,
                    startDate: startDate.getTime(),
                    endDate: endDate.getTime(),
                    kpiId: kpiId,
                    activityId,
                    metric: metricType,
                    cycleType: 'monthly'
                }
                return $http.post('/api/customReports/trendBenchmark',postData).then(function(benchMarkData){
                    benchMarkData=benchMarkData.data;
                    graphData.forEach(function(data){
                        var benchMark = _.find(benchMarkData,{cycleId:data.cycleId});
                        user.teams.forEach(function(team){
                            data[team.teamType] = benchMark[team.teamType]?benchMark[team.teamType].avg:null;
                        });
                    });
                    return $q.resolve(graphData);
                });
            });
        }

    });
