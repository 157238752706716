const autoImportNgTemplateLoaderTemplate1 = require('../html/swotModal.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/carImageChangeModal.html');
const autoImportNgTemplateLoaderTemplate3 = require('../html/leaveMgtModal.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('profileCtrl', function ($rootScope, $scope, $http, $location, Upload, Auth, User,  toaster, $stateParams, profile, p0, SwotService, userAnalyticsService, $state, Reportees, Gender, $mdDialog, sidebarVariables) {
        let self = this;
        
        this.init = function(){
            $scope.me=  Auth.getCurrentUser();
            $scope.userLevel = $scope.me.organizationalTag.jobRole.level;
            $scope.strings = $rootScope.strings;
            $scope.sidebarVariables = sidebarVariables;
            $scope.commentSectionOpened = false;
            $scope.showMotoInput = false;
            $scope.commentSectionStyle = {};
            $scope.userLookup = {};
            $scope.config = p0.config;
            $scope.isMyReportee = false;
            $scope.cycleId = $stateParams.cycleId;
            $scope.cycle = _.find(p0.allCycles, {_id: $scope.cycleId});

            $scope.kpis = p0.allKpis;
            $scope.replyClicked = [];
            $scope.showCompare = false;
            $scope.isOwn = $scope.me._id.toString() == $stateParams.selectedUserId;

            return self.getProfileData();
        }

        $scope.gotoOCR = function () {
            $mdDialog.hide();
            $state.go('userFlow.oneClickReport', {selectedPlayerIdForOCR: $scope.userId});
        };

        $scope.gotoGP = function () {
            $mdDialog.hide();
            $state.go('userFlow.gameplan', {selectedPlayerId: $scope.userId});
        };

        this.isMyReportee = function(){
            $scope.userId = $stateParams.selectedUserId;
            $scope.user.jobRole = $scope.playerData.roleType;
            if ($scope.userId != $scope.me._id) {
                return Reportees.isMyLeaf($scope.userId).
                then( function (isReportee) {
                    $scope.isMyReportee = isReportee;
                    if ($scope.isMyReportee && $scope.user.jobRole!= 'seniorManager') {

                        $scope.showOCR = true;

                    }
                });
            }
            else return Promise.resolve();
        };
        $scope.moreDetailClick = function (){
            var params = {
                userId: $stateParams.selectedUserId,
                leagueId: $scope.selectedLeague.leagueId,
                cycleId: $scope.selectedCycle._id,
                kpiId: $scope.selectedGoal._id,
                activityFilter: false
            };
            var swotType;
            if($scope.selectedGoal._id == 'overall'){
                swotType = 'kpi';
                params.title = "KPI Summary";
            }else{
                swotType = 'kai';
                params.title = "KAI Summary for ";
            }
            $mdDialog.show({
                locals:{params:params, swotType: swotType },
                controller: 'DialogController',
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                clickOutsideToClose:true,
                fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
            });
        };

        this.getProfileData = () => {
            return $http.post('/api/users/getProfileData',  {userId: $stateParams.selectedUserId})
            .then(function (playerData) {
                playerData=playerData.data;
                $scope.playerData = playerData;
                self.isMyReportee();
                $scope.selectedRole = playerData.organizationalTag.jobRole;

                $scope.jerseyImage = Gender.getJerseyImage(playerData);

                $scope.kpis = p0.getKpisOfPlayTypes($scope.playerData.playTypes);
                $scope.kpis.unshift({name:'Overall',_id:'overall'});
                $scope.selectedGoal = $scope.kpis[0];
                // console.log($scope.playerData);
                $scope.playerData.liked = _.find(playerData.likes, {likerId: $scope.me._id});
                $scope.playerData.followed = _.find(playerData.followers, {followerId: $scope.me._id});
                profile.getUserData($scope.playerData._id, $scope.playerData);
                $scope.me = Auth.getCurrentUser();
                $scope.allLeagues = $scope.playerData.leagues;
                $scope.selectedLeague = $scope.allLeagues[0];
                profile.getPlayerList().then(function (playerList) {
                    $scope.playerList = playerList;
                });

                $scope.setCommentSectionsStyles($scope.commentSectionOpened);
                $scope.playerData.comments = _.sortBy($scope.playerData.comments, 'date').reverse();
                // $scope.getBadges();


                if ($scope.playerData.systemRole != 'seniorManager')
                    $scope.showStats = true;
                else
                    $scope.showStats = false;

                $scope.showCompare = $rootScope.isPlayer && $stateParams.selectedUserId!= $scope.me._id
                    && $scope.playerData.organizationalTag.jobRole._id == $scope.me.organizationalTag.jobRole._id;

                $scope.$watch('files', function () {
                    $scope.onFileSelect("");
                });

                $scope.replyInput = true;
            });
        }

        $scope.onFileSelect = function ($files) {
            //$files: an array of files selected, each file has name, size, and type.
            for (var i = 0; i < $files.length; i++) {
                var $file = $files[i];
                var fileName = $files[i].name.split(".");
                var fileType = fileName[fileName.length - 1].toLowerCase();
                if (fileType == "jpg" ||fileType == "jpeg" || fileType == "png") {
                Upload.upload({
                    url: '/api/users/picture/addImage',
                    data: {userId: $scope.me._id},
                    file: $file,
                }).progress(function (evt) {
                    var progresspercentage = parseInt(100.0 * evt.loaded / evt.total);
                    // console.log('progress: ' + progresspercentage + '% ' + evt.config.file.name);
                }).then(function (response) {
                    var data = response.data;
                    if(data.status){
                        $scope.playerData.image = data.data.image;
                        $scope.me.image = data.data.image;
                    }
                }).catch(function (data, status, headers, config) {
                    toaster.pop('error', "Submission.", "Upload '.png', '.jpg' Types Only.");
                })
                }else{
                    toaster.pop('error', "Submission.", "Upload '.png', '.jpg' Types Only.");
                }

            }
        };


        $scope.postComment = function (comment, userTags, kpiTags) {
            if (comment != undefined) {
                return $http.post('/api/users/comments', {
                    userId: $scope.playerData._id,
                    comment: comment,
                    userTags: userTags,
                    kpiTags: kpiTags
                }).then(function (response) {
                    response=response.data;
                    toaster.pop('success', "Comment", "Comment Posted Successfully");
                    $scope.playerData.comments.unshift({
                        _id: response.comment._id,
                        commentor: $scope.me,
                        comment: comment,
                        date: new Date()
                    });
                    $scope.commentMessage = "";
                });
            }
            else{
                toaster.pop('Failure', "Please enter a valid Comment.");
                return Promise.resolve();
            }
        };


        $scope.showReply = function () {
            $scope.replyInput = false;
        };
        $scope.onCarImageChange = function (ev) {
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate2,
                controller: 'carImageUploadModalCtrl',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                fullscreen: true,
                resolve:{
                    carImage: function () {
                         return carImg;
                    },
                    me: function() {
                      return $scope.me;
                    }
                }
            });
        };
        var carImg = function (newImg) {
            $scope.playerData.profileInfo.carImage = newImg;
        }
        $scope.changeCycle = function(cycle){
            $scope.selectedCycle = cycle;
            $scope.initAnalytics();
        };
        $scope.changeLeague = function(league){
            $scope.selectedLeague = league;
            $scope.initAnalytics();
        };
        $scope.changeKpi = function(kpi){
            $scope.selectedGoal = kpi;
            $scope.initAnalytics();
        };
        $scope.initAnalytics = function(){
            return userAnalyticsService.fetchUserAnalytics($scope.playerData._id,$scope.selectedCycle._id)
            .then(function(userAnalytic){
                $scope.playerUserAnalytic = userAnalytic;
                if($scope.playerData.systemRole != 'seniorManager'){
                    $scope.getSWOTS();
                    $scope.getRank();
                }
            });

        };

        $scope.like = function(){
            if(!$scope.playerData.liked) {
                return $http.post('/api/users/likes', {userId: $stateParams.selectedUserId}).then(function () {
                    $scope.playerData.liked = true;
                });
            }else{
                return $http.post('/api/users/dislike', {userId: $stateParams.selectedUserId}).then(function () {
                    $scope.playerData.liked = false;
                });
            }
        };

        $scope.follow = function(){
            if(!$scope.playerData.followed) {
                return $http.post('/api/users/followUser', {userId: $stateParams.selectedUserId}).then(function () {
                    $scope.playerData.followed = true;
                });
            }else{
                return $http.post('/api/users/unfollowUser', {userId: $stateParams.selectedUserId}).then(function () {
                    $scope.playerData.followed = false;
                });
            }
        };

        $scope.getSWOTS = function(){
            if(!$scope.playerData){
                return;
            }
            if($scope.selectedGoal._id != 'overall'){
                return SwotService.getKaiSwot($scope.selectedCycle._id, $scope.playerData._id, $scope.selectedGoal._id)
                .then(function (kaiSwot) {
                    $scope.pointsHeader = $scope.strings.IMPACT.value;
                    $scope.swot = kaiSwot;
                });
            }else{
                return SwotService.getKpiSwot($scope.selectedCycle._id, $scope.playerData._id)
                .then(function (kpiSwot) {
                    $scope.pointsHeader = 'OVERALL';
                    $scope.swot = kpiSwot;
                });
            }

        };

        $scope.getRank = function(){
            var rank, points;
            if($scope.selectedGoal._id == 'overall'){
                rank = _.find($scope.playerUserAnalytic.points.ranks,{leagueId:$scope.selectedLeague.leagueId});
                points = $scope.playerUserAnalytic.points.value;
            }else{
                var kpiData = _.find($scope.playerUserAnalytic.kpiData,{kpiId:$scope.selectedGoal._id});
                rank = _.find(kpiData.points.ranks,{leagueId:$scope.selectedLeague.leagueId});
                points = kpiData.points.value;
            }
            if(rank)
                $scope.personRank = {
                    rank : rank.value,
                    points : points
                };
        };

        $scope.setCommentSectionsStyles = function(commentSectionOpened){
            var rhsSidebarWidth = $('.rhs-sidebar-container').width();
            var rhsSidebarHeight = $('.rhs-sidebar-container').height() - 50;
            if(commentSectionOpened){
                $scope.commentSectionStyle = {
                    width:rhsSidebarWidth,
                    height:rhsSidebarHeight
                };
                //,position:'absolute',right:'0px',bottom:'0px',background:'#fff','z-index':'100','overflow':'scroll'
            }else{
                $scope.commentSectionStyle = {
                    width:rhsSidebarWidth,
                    height:'85px',
                    'overflow-y':'hidden'
                };
            }
        };

        $scope.toggleCommentSection = function(){
            $scope.commentSectionOpened = !$scope.commentSectionOpened;
            $scope.setCommentSectionsStyles($scope.commentSectionOpened);
        };

        $scope.setAvatarName = function(){
            return $http.post('/api/users/setNickname',{nickname: $scope.playerData.profileInfo.nickname}).then(function () {
                $scope.editingAvatarName = false;
            });
        };

        $scope.setName = function(){
            return $http.post('/api/users/setName', {name: $scope.playerData.name})
            .then(function () {
                $scope.editingName = false;
            });
        };

        $scope.setMoto = function(){
            return $http.post('/api/users/setMoto',{moto: $scope.playerData.profileInfo.moto})
            .then(function () {
                $scope.showMotoInput = false;
            });
        };

        $scope.editNickname = function(){
            $scope.editingAvatarName = true;
            setTimeout(function () {
                $('#nickname-input').focus();
            },1);
        };

        $scope.editName = function(){
            $scope.editingName = true;
            setTimeout(function () {
                $('#name-input').focus();
            },1);
        };
        $scope.editMoto = function(){
            $scope.showMotoInput = true;
            setTimeout(function () {
                $('#moto-input').focus();
            },1);
        };

        $scope.setRacingAvatar = function(){
            return $http.post('/api/users/setJersey', {jersey: $scope.playerData.profileInfo.jersey})
            .then(function () {
                toaster.pop('success','Racing Avatar set Successfully');
                $scope.editingRacingAvatar = false;
            });
        };

        $scope.replyComment = function(index, reply){
            if($scope.playerData.comments[index].replies == undefined){
                $scope.playerData.comments[index].replies = [];
            }
            $scope.playerData.comments[index].replies.push(reply);
            $scope.replyClicked[index] = false;
        };

        $scope.isPlayerLiked=function(){
            if($scope.playerData && $scope.playerData.liked)
                return "blue";
            else
                return "black";
        };
        $scope.isPlayerFollowed=function(){
            if($scope.playerData && $scope.playerData.followed)
                return "blue";
            else
                return "black";
        };
        $scope.compare = function(){
            $rootScope.playersFromLB = [$stateParams.selectedUserId];
            $rootScope.jobRoleFromLB = $scope.me.organizationalTag.jobRole;
            var roleType = '';
            if($rootScope.isMiddleManager)
                roleType = 'manager';
            else
                roleType = 'frontline';
            $state.go('userFlow.analytics', {tabName:'playerComparison', myComparison: true, roleType: roleType});
        }

        $scope.$watch('sidebarVariables.tabIndex',function(newValue, oldValue){
            if($scope.commentSectionOpened){
                $scope.toggleCommentSection();
            }
        });
        $scope.openLeaveMgtModal = function(){
            $mdDialog.show({
                templateUrl:    autoImportNgTemplateLoaderTemplate3,
                controller:     'leaveMgtModalCtrl',
                resolve:        {}
            });
        };
        $scope.deleteComment = function (comment) {
            return $http.post('/api/users/deleteProfileComments', comment).then(function () {
                _.remove($scope.playerData.comments,function (currentComment) {
                    return currentComment._id == comment._id;
                });
            });
        };
        this.init();
    });
