angular.module('bitnudgeApp')
    .controller('addBulkRewardsCtrl', function ($scope,Auth, Upload, toaster) {
        $scope.fileType = "rewards";
        $scope.onFileSelectRewards = function () {
            if ($scope.bulkRewards == undefined) {
                toaster.pop('error', "Submission.", "Select File to Upload.");
            } else {
                var fileName = $scope.bulkRewards[0].name.split(".");
                var fileType = fileName[fileName.length - 1];
                if (fileType == "xls" || fileType == "xlsx" || fileType == "csv") {
                    Upload.upload({
                        url: '/api/rewards/import/excel',
                        data: {userId: Auth.getCurrentUser()._id},
                        file: $scope.bulkRewards[0],
                        progress: function (e) {
                        }
                    }).then(function (data, status, headers, config) {
                        toaster.pop('success', "Excel uploaded", "Division creation successfully finished.");
                    });
                } else {
                    toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' or '.csv' Types Only.");
                }
            }
        }
    });