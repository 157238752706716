const autoImportNgTemplateLoaderTemplate1 = require('../html/account/login.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/account/signup.html');
const autoImportNgTemplateLoaderTemplate3 = require('../html/account/settings.html');
const autoImportNgTemplateLoaderTemplate4 = require('../html/account/reset.html');

'use strict';

angular.module('bitnudgeApp')
    .config(function($stateProvider) {
        $stateProvider
            .state('login', {
                url: '/login',
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'LoginCtrl'
            })
            .state('signup', {
                url: '/signup',
                templateUrl: autoImportNgTemplateLoaderTemplate2,
                controller: 'SignupCtrl'
            })
            .state('settings', {
                url: '/settings',
                templateUrl: autoImportNgTemplateLoaderTemplate3,
                controller: 'SettingsCtrl',
                authenticate: true,
                resolve: {
                	user: function (Auth, $state) {
                		const user = Auth.getCurrentUser();
                		const gotoDashboard = () => {
                			if (user.systemRole === 'lmsAdmin') {
                				$state.go('lms.adminDashboard');
                			} else {
                				$state.go('userFlow.dashboard');
                			}
                		};

                		if (user && user.cloneUser && !user.cloneUser.useBitNudgeLoginFlow) {
                			gotoDashboard()
                		} else if (user && !user.useBitNudgeLoginFlow) {
                			gotoDashboard()
                		}
                	}
                }
            })
            .state('reset', {
                url: '/reset/:token',
                templateUrl: autoImportNgTemplateLoaderTemplate4,
                controller: function($scope, $http, $stateParams, $location, toaster) {
                    $scope.resetPassword = function(){
                        var pattern = new RegExp(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{7,19}$/);
                        var validatePassword= pattern.test($scope.password);
                        var confirmNewPassword = ($scope.password == $scope.passwordConfirm);
                        if (!validatePassword)
                            $scope.message = 'Please enter the correct format (a letter, a special character, a digit and password length greater than 8 characters)';
                        if(!confirmNewPassword)
                            $scope.message = 'Confirm Password is not the same as Password. Please enter again.';

                        if(validatePassword && confirmNewPassword) {
                            $http.post('/api/users/reset', {
                                password: $scope.password,
                                token: $stateParams.token
                            })
                                .then( function(userLoginStatus) {
                                	if(userLoginStatus.data && userLoginStatus.data.status){
										toaster.pop("success", "Password changed successfully!");
										$location.path('userFlow/main');
									}else if(userLoginStatus.data && !userLoginStatus.data.status){
                                		var result = userLoginStatus.data.errors;
										toaster.pop("Failure", result[0]?result[0].message:"Failed to reset the password");
									}else{
										toaster.pop("Failure", "Failed to reset the password");
									}
                                }).catch(function(err){
                                	console.error(err);
									toaster.pop("Failure", "Failed to reset the password");
							})
                        }
                        else {
                        	toaster.pop("Failure", "Please try again!");
						}
                    }
                },
                authenticate: false
            });
    });
