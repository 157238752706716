'use strict';

angular.module('bitnudgeApp')
  .controller('editCustomerCtrl', function ($scope, Auth, p0, $mdDialog, countryList, $state, $stateParams, CustomerService) {

    var customerId = $stateParams.customerId;

    function init(){
        CustomerService.getCustomerById(customerId).then(function(customer){
            console.log(customer);
            $scope.customer = customer;

            $scope.mobileNumbersArray = [];
            $scope.customer.country = {};
            $scope.customer.mobileNo = {};
            $scope.customer.mobReadOnly = {};
            customer.contactNumbers = customer.mobileNumbers;
            customer.contactNumbers.forEach(function(num, index){
                $scope.mobileNumbersArray.push(index);
                $scope.customer.country[index] = num.substring(0,4);
                $scope.customer.mobileNo[index] = num.substring(4);
                $scope.customer.mobReadOnly[index] = true;

            });
        })
    }

    $scope.countryList = p0.countryList;

    $scope.customer = {
        country: {
           0:$scope.countryList[0],
        },
        mobileNo: {},
        mobReadOnly: {}
    };

    $scope.nationList = countryList.countries;
    var uaeIndex = $scope.nationList.indexOf("United Arab Emirates ");
    $scope.customer.nationality = (uaeIndex != -1) ? $scope.nationList[uaeIndex] : $scope.nationList[0];
    $scope.mobileNumbersArray = [0];

    $scope.saveCustomer = function(isFormValid){
        if(isFormValid){
            $scope.customer.contactNumbers = [];
            $scope.mobileNumbersArray.forEach(function (i) {
                if ($scope.customer.mobileNo[i]) {
                    $scope.customer.contactNumbers.push($scope.customer.country[i] + $scope.customer.mobileNo[i]);
                }
            });

            var duplicates = findDuplicateNumbers(_.cloneDeep($scope.customer.contactNumbers));
            if(!duplicates.length){
                console.log($scope.customer);
                CustomerService.updateCustomer($scope.customer).then(function(result){

                });
            }
        }
    }


    $scope.removeSpaces = function(mobile){
        if(mobile){
            mobile = mobile.replace(/[^\d]/g,'');
            return mobile.substring(0,9);
        }
    };

    function findDuplicateNumbers(contactNumbers){
        var recipientsArray = contactNumbers.sort();
        var duplicates = [];
        for (var i = 0; i < recipientsArray.length - 1; i++) {
            if (recipientsArray[i + 1] == recipientsArray[i]) {
                duplicates.push(recipientsArray[i]);
            }
        }
        return duplicates;
    };

    $scope.findDuplicateNumbers = function(){
        var contactNumers = [];
        $scope.mobileNumbersArray.forEach(function (i) {
            if($scope.customer.mobileNo[i]) {
                contactNumers.push($scope.customer.country[i] + $scope.customer.mobileNo[i]);
            }
        });
        var duplicates = findDuplicateNumbers(contactNumers);

        $scope.duplicateNumbers = duplicates.length ? true : false;
    }

    $scope.addNewNumber = function(){
        $scope.focusLastMobile = false;
        var length = $scope.mobileNumbersArray.length;
        var last = $scope.mobileNumbersArray[length -1];
        var contactNumbers = []
        $scope.mobileNumbersArray.forEach(function (i) {
            if ($scope.customer.mobileNo[i]) {
                contactNumbers.push($scope.customer.country[i] + $scope.customer.mobileNo[i]);
            }
        });
        var duplicates = findDuplicateNumbers(_.cloneDeep(contactNumbers));
        if(duplicates.length){
            $scope.duplicateNumbers = true;
        }
        else if($scope.customer.mobileNo[last] && $scope.customer.country[last]){
            $scope.mobileNumbersArray.push(last+1);
            $scope.customer.country[last+1] = $scope.countryList[0];
        }else{
            $scope.focusLastMobile = true;
        }
    };

    $scope.removeNewMobileNumber = function(index){
        /**Delete the index from array by using the acutal index in the array**/
        var actualIndex = $scope.mobileNumbersArray.indexOf(index);
        $scope.mobileNumbersArray.splice(actualIndex,1);
        delete $scope.customer.country[index];
        delete $scope.customer.mobileNo[index];
        delete $scope.customer.mobReadOnly[index];
        $scope.findDuplicateNumbers();
    }

    $scope.cancel = function(){
      $state.go('userFlow.customer',{customerId: customerId});
    }

    init();
  
});