'use strict';
angular.module('bitnudgeApp')
    .controller('addRemoveSeriesCtrl', function ($scope, p0) {
        $scope.addRemoveExpanded = false;
        $scope.addRemoveClicked = function() {
            $scope.addRemoveExpanded = !$scope.addRemoveExpanded;
        };
        if(typeof $scope.addRemoveOptions === 'string'){
            $scope.addRemoveOptions = JSON.parse($scope.addRemoveOptions);
        }

        var indicatorDropdown = $scope.addRemoveOptions[0];
        $scope.$watch("indicator", function(indicator){
            indicatorDropdown.options = _.filter($scope.myIndicators, function(indicator){
                return indicator._id !== $scope.indicator._id;
            });
        });
        $scope.onAllOptionSelected = function(selectedOption){
            selectedOption.allSelected = !selectedOption.allSelected;
            const index = $scope.addRemoveOptions.findIndex(option=> (option.id === selectedOption.id));
            if (index > -1) {
                $scope.addRemoveOptions[index].allSelected = selectedOption.allSelected;
                if($scope.onAddRemoveOptionsChange){
                    $scope.onAddRemoveOptionsChange($scope.addRemoveOptions);
                }
            }
        }

        $scope.onSelectChange = function (selectedOptions, parent) {
            let addRemoveOptions = angular.copy($scope.addRemoveOptions);
            addRemoveOptions.forEach(function(arOption){
                arOption.options.forEach(function(option){
                    option.selected = _.includes(selectedOptions, option._id);
                });
            })
            if($scope.onAddRemoveOptionsChange){
                $scope.onAddRemoveOptionsChange(addRemoveOptions);
            }

        };
    });
