const autoImportNgTemplateLoaderTemplate1 = require('../html/playerComparison.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('playerComparison', function () {
        return {
            name: 'playerComparison',
            scope: {
                isOwn:'=',
                playerStatus: '=',
                managerEnabled: '=',
                cycleId:"="
            },
            controller: 'playerComparisonController',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
