'use strict';
angular.module('bitnudgeApp')
    .directive('cneKpiTab', function(){
        return {
            name: 'cneKpiTab',
            scope: {
                'user': '@',
                'type': '@',
                'kpi': '@',
                'kpiName': '@',
                'isWeekly': '=',
                'hideReportees': '=',
                'tabType': '@',
                'jobRole': '=',
                'cycle': '=',
                'metric': '=',
                'player': '=',
                'cycleType': '='
            },
            controller: 'cneKpiTabCtrl',
            restrict: 'AE',
            templateUrl: function(elem,attr){
                if(attr.tabType == 'huddleBoard'){
                    return '../html/cneKpiTab/cneKpiTab-huddleBoard.html';
                } else if(attr.tabType == 'leads'){
                    return '../html/cneKpiTab/cneKpiTab-leads.html';
                }else if(attr.tabType == 'feed'){
                    return '../html/cneKpiTab/cneKpiTab-daily.html';
                } else {
                    return '../html/cneKpiTab/cneKpiTab.html';
                }
            }
        };
    });
