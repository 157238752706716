'use strict';

angular.module('bitnudgeApp')
    .service('heatmapService', function($http,$q) {
            this.createHeatMapObject = function(product,userNames,userImages,dataFile,userIds){
                var deferred = $q.defer();
                var array = [];
                for(var i = 0;i<userNames.length;i++){
                    var obj = {};
                    obj.id=userIds[i];
                    obj.name=userNames[i];
                    obj.image=userImages[i];
                    if(dataFile && dataFile[i]){
                        obj.activeDivisions = dataFile[i].activeDivision;
                    }
                    array.push(obj);
                }
                _.each(array,function(val,index){
                    var productArr = [];
                    _.each(dataFile,function(dataVal){
                        var obj = {};
                        if(index+1==dataVal.userIndex){
                            obj.productIndex = dataVal.productIndex;    
                            obj.prodValue = !isNaN(dataVal.value)?parseInt(dataVal.value):'NA';
                            obj.clickable = dataVal.clickable;
                            if(dataVal.toolTip){
                                obj.toolTip = dataVal.toolTip;
                            }
                            productArr.push(obj);
                        }
                    });
                    val.products = productArr;
                });
                deferred.resolve(array);
                return deferred.promise;
            };

            this.createProductAbbrObj = function(columnNames){
                var array = [];
                _.each(columnNames,function(val){
                    if(val){
                        var obj = {};
                        var nameSplit = val.split(' ');
                        obj.name = val;
                        var abbr = "";
                        _.each(nameSplit,function(nameVal){
                            var firstLetter;
                            if(nameVal.charAt(0) == '('){
                                firstLetter = '%';
                            }else firstLetter = nameVal.charAt(0);
                            abbr = abbr+firstLetter;
                        });
                        obj.abbr = abbr;
                        array.push(obj);
                    }
                });
                return array;
            };

            return this;
    });


