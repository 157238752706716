'use strict';
angular.module('bitnudgeApp')
    .controller('rhsAnalyticsL1Ctrl', function ($scope, sidebarVariables,p0,rhsAnalyticsL1, $rootScope) {

        let self = this;

        this.init = () => {
            $scope.config = $rootScope.config;
            //sidebars variables
            $scope.sidebarVariables = sidebarVariables;
            $scope.showIncentives = true;
            $scope.kpi = sidebarVariables.kpi;
            $scope.user = sidebarVariables.user;
            $scope.lastSync = $rootScope.lastUpdatedAtDate;
            $scope.strings = $rootScope.strings;
            $scope.monthName = p0.currentMonthlyCycle.name.substring(0,3);
            if($scope.config.weeklyCycle){
                var weekName = p0.currentWeeklyCycle.name;
                $scope.weekName = weekName.split(',').length>1? weekName.split(',')[1]: weekName;
            }

            self.setLasySync(p0.currentMonthlyCycle);
            //cycle
            $scope.period = 'month';
            $scope.cycle = p0.currentMonthlyCycle;

            $scope.setData();

            $scope.card5Data = {
                heading1:'Incentives',
                heading2:'('+$scope.strings.INCENTIVE_UNIT.value+')',
                data:[
                    {
                        heading:'Forecast for the Month',
                        value:'Not Applicable'
                    }
                ]
            };
        }

        $scope.$watchGroup(['sidebarVariables.kpi','sidebarVariables.user','sidebarVariables.cycle'],function (newValue, oldValue) {
            if(newValue[0] && newValue[1] && newValue[2] ){
                $scope.kpi = sidebarVariables.kpi;
                $scope.user = sidebarVariables.user;
                $scope.isSeniorManager = $scope.user.roleType == 'seniorManager';
                $scope.monthName = sidebarVariables.cycle.name;
                $scope.cycle = sidebarVariables.cycle;
                $scope.period = 'month';
                $scope.inactive = !$scope.cycle.active || (sidebarVariables.kpi._id == 'overall' && $scope.config.showForecast);
                //else $scope.inactive = false;
                self.setLasySync($scope.cycle);
                $scope.setData();
            }
        });

        this.setLasySync = (cycle) => {
            if($rootScope.lastUpdatedAtDate.getTime() >= new Date(cycle.startDate).getTime()  && $rootScope.lastUpdatedAtDate.getTime()  < new Date(cycle.endDate).getTime() ){
                $scope.lastSync = $rootScope.lastUpdatedAtDate;
                $scope.mtdAvailable = true;
            }else if($rootScope.lastUpdatedAtDate.getTime()  > new Date(cycle.startDate).getTime() ){
                $scope.lastSync = new Date(cycle.endDate);
                $scope.mtdAvailable = true;
            } else {
                $scope.mtdAvailable = false;
            }
        };
        $scope.selectCycle = function (period) {
            if($scope.inactive) return;
            $scope.period = period;
            switch (period){
                case 'month':
                    $scope.showIncentives = true;
                    $scope.cycle = p0.currentMonthlyCycle;
                    break;
                case 'week':
                    $scope.showIncentives = false;
                    $scope.cycle = p0.currentWeeklyCycle;
                    break;
                case 'day':
                    $scope.showIncentives = false;
                    $scope.cycle = p0.trackDailyCycle;
                    break;
            }
            $scope.setData();
        };


        $scope.setData = function(){
            return rhsAnalyticsL1.getData($scope.user,$scope.kpi,$scope.cycle).then(function(data){
                $scope.data = data;
            });
        };

        $scope.drillDown = function(cardType){
            if($scope.user.leagues.length > 0) {
                switch (cardType) {
                    case 'mtd':         $scope.changeView('lvl2MTD'); break;
                    case 'call':        $scope.changeView('lvl2Call'); break;
                    case 'meeting':     $scope.changeView('lvl2Meeting'); break;
                    case 'newLead':     $scope.changeView('lvl2NewLead'); break;
                    case 'submission':  $scope.changeView('lvl2Submission');break;
                    case 'social':      $scope.changeView('lvl2Social');break;
                    case 'occupancy':   $scope.changeView('lvl2occupancy');break;
                    case 'accuracyp':   $scope.changeView('lvl2accuracyp');break;
                    case 'throughput':  $scope.changeView('lvl2throughput');break;
                }
            }
        }

        this.init();
    });
