angular.module('bitnudgeApp')
    .controller('addActivityCtrl', function ($http, $stateParams, $scope,  Auth, $location, toaster, p0,$rootScope) {
        $scope.activitySchema = {};
        $scope.editForm = false;
        $scope.activitySchema.pointsMultipliers = [];
        $scope.strings = $rootScope.strings;

        var index = 0;
        $scope.editForm = true;
        $scope.activities = p0.allActivities;
        if($stateParams.id) {
            $scope.activitySchema = p0.allActivities.filter(function (element, index, list) {
                return element._id.toString() == $stateParams.id;
            });
        }
        $scope.addPtMultiplier = function () {
            $scope.activitySchema.pointsMultipliers.push({});
        }
        $scope.removePtsMultiplier = function(index) {
            $scope.activitySchema.pointsMultipliers.splice(index, 1);
        };
        $http.post('/api/jobRoles/index').then(function (jobroles) {
            jobroles=jobroles.data;
            $scope.jobRoles = jobroles;
        });
        $scope.updateActivity = function () {
            if($scope.activitySchema.parent)
                $scope.activitySchema.parent = $scope.activitySchema.parent._id;
            if($scope.activitySchema.children)
                $scope.activitySchema.children.forEach(function (child, index) {
                    $scope.activitySchema.children[index] = child._id;
                });
            $scope.activitySchema.pointsMultipliers.forEach(function (ptsMultiplier, index) {
                $scope.activitySchema.pointsMultipliers[index].role = ptsMultiplier.role._id;
            });
            $http.post('/api/activities/activityForm', $scope.activitySchema).then(function(product) {
                product=product.data;
                $location.path('/listActivitySchema');
                toaster.pop('success', "Product Update", "Product Details Updated.");
            });
        }
        $scope.user = Auth.getCurrentUser();
        $scope.addActivity = function () {

        }
    });
