const autoImportNgTemplateLoaderTemplate1 = require('../html/progressBar.html');

/**********
  config = {
    mtdColor: color,
    forecastColor: color,
    label: boolean,
    threshold: threshold,
    percentageCompleted: value,
    percents: [0,150]
  }
**********/
'use strict';
angular.module('bitnudgeApp')
    .directive('progressBar', function () {
        return {
            name: 'progressBar',
            scope: {
                config: '@'
            },
            controller: 'progressBarCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
