'use strict';

import { filter } from "bluebird";

angular.module('bitnudgeApp')
    .controller('exportCustomerCtrl', function ($rootScope, $scope,p0, $mdDialog, parameters,CustomerService, UtilService) {
    $scope.pagination = CustomerService.getPaginationObj();
    $scope.pagination.limit =  parameters.totalCustomers
    $scope.isCloned = $rootScope.cloned;

    function diableAllFilters(){
        parameters.filterObj.name = null;
        if(parameters.filterObj.ageRange){
            parameters.filterObj.ageRange.from = parameters.filterObj.ageRange ? parameters.filterObj.ageRange.from : null;
            parameters.filterObj.ageRange.to = parameters.filterObj.ageRange ? parameters.filterObj.ageRange.to : null;;
        }
        parameters.filterObj.minDeposit = null;
        parameters.filterObj.maxDeposit =  null;
        parameters.filterObj.dnc = null;
        parameters.filterObj.all = true;
        $scope.pagination.offset = 0;
        $scope.pagination.limit = 0;

    }
    

    $scope.exportCustomers = function (isAllCustomers) {
        parameters.filterObj.all = false;
        if(isAllCustomers){
            diableAllFilters();
        }
        CustomerService.downloadCustomers(parameters.currentUser, parameters.filterObj, $scope.pagination, parameters.sortingObj, parameters.customerFilters)
        $scope.cancel();
    };
    $scope.cancel = function(){
        $mdDialog.cancel();
    }

});