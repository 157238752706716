const autoImportNgTemplateLoaderTemplate1 = require('../html/earnStatement.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('earnStatement', function(){
        return {
            name: 'earnStatement',
            scope: {
                cycle:  '='
            },
            controller: 'earnStatementCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
