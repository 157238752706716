'use strict';
angular.module('bitnudgeApp')
    .controller('customCorrelationsCardCtrl', function ($scope, $rootScope, Auth) {
        $scope.strings = $rootScope.strings;
        $scope.me = Auth.getCurrentUser();
        $scope.selectedRole = $scope.me.organizationalTag.jobRole;
        var currentMonth = "AUGUST";
        var dateLabels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17',
            '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
        var addedLines = 1;
        var chartData = [
            {
                legend: {label: 'Series A', series: 'a'},
                data: [0, 1, 2, 4, 7, 6, 9, 10, 8, 3, 0, 12, 14, 16, 2, 4, 6, 8, 10, 23, 0, 1, 2, 4, 7, 6, 9, 10, 8, 3, 23]
            },
            {
                legend: {label: 'Series B', series: 'b'},
                data: [0, 12, 14, 16, 2, 4, 6, 8, 10, 23, 0, 1, 2, 4, 7, 6, 9, 10, 8, 3, 0, 12, 14, 16, 2, 4, 6, 8, 10, 23, 5]
            },
            {
                legend: {label: 'Random Series C', series: 'c'},
                data: [0, 3, 6, 21, 24, 9, 12, 15, 18, 8, 0, 20, 24, 4, 8, 12, 16, 28, 32, 12, 0, 3, 6, 21, 24, 9, 12, 15, 18, 8, 15]
            },
            {
                legend: {label: 'Awesome Series D', series: 'd'},
                data: [0, 20, 24, 4, 8, 12, 16, 28, 32, 12, 0, 3, 6, 21, 24, 9, 12, 15, 18, 8, 0, 20, 24, 4, 8, 12, 16, 28, 32, 12, 9]
            }
        ];

        $scope.lineData = {
            labels: [],
            series: [],
            seriesLegends: []
        };

        var getLabel = function (value) {
            var index = $scope.lineData.labels.indexOf(value);
            if (index === 0) {
                return currentMonth.slice(0, 3) + ' ' + value;
            }

            if ($scope.cardExpanded) {
                return index % 2 === 0 ? value : '';
            }

            return (value === '15' || value === '30') ? value : '';
        };

        $scope.lineOptions = {
            axisX: {
                showGrid: false,
                labelInterpolationFnc: function (value) {
                    return getLabel(value);
                }
            },
            chartPadding: {
                top: 30,
                bottom: 0,
                left: 0
            },
            plugins: [],
            width: '320px',
            height: '320px'
        };

        $scope.cardExpanded = false;
        $scope.expandLayout = function () {
            $scope.cardExpanded = !$scope.cardExpanded;
            $scope.lineOptions.width = $scope.cardExpanded ? '570px' : '320px';
            changeLineData();
        };

        var changeLineData = function () {
            var i;
            if ($scope.cardExpanded) {
                $scope.lineData.labels = dateLabels;
                for (i = 0; i < addedLines; i++) {
                    $scope.lineData.series[i] = chartData[i].data;
                    $scope.lineData.seriesLegends[i] = chartData[i].legend;
                }
            } else {
                $scope.lineData.labels = _.filter(dateLabels, function (value, index) {
                    return (index + 1) % 3 == 0;
                });

                for (i = 0; i < addedLines; i++) {
                    $scope.lineData.series[i] = _.filter(chartData[i].data, function (value, index) {
                        return (index + 1) % 3 == 0;
                    });
                    $scope.lineData.seriesLegends[i] = chartData[i].legend;
                }
            }
        };

        changeLineData();
    });
