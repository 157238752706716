angular.module('bitnudgeApp')
	.directive('lineChart',[ '$window', '$rootScope' , function ($window, $rootScope) {
		return {
			restrict: 'EA',
			transclude: true,
			scope: {
				data: '=',
				bin: '=',
				export: '=',
				id: '@',
				title: '@',
				height: '@',
				flex: '=',
			},

			replace: true,
			link: function (scope, element, attrs, rootScope) {
				scope.render = function (data) {	
				var margin = {
						top: 50,
						right: 50,
						bottom: 65,
						left: 60
					};
				var topHeaderSize = 120;
				var height = (window.innerHeight - topHeaderSize)/3 - margin.top - margin.bottom;
				//navbar size
				var navBarWidth = 120;
				//calculation for the dynamic width
				var width = (((window.innerWidth - navBarWidth) * scope.flex) / 100) - 50 - margin.left - margin.right;

					data.forEach(function (d) {
						d.averageCount = +(d.averageCount);
						d.date = d3.time.format("%Y-%m-%d").parse(d.pipelineDate);

					});

					var x = d3.time.scale()
						.range([0, width]);
					var y = d3.scale.linear().range([height, 0]);

					var xAxis = d3.svg.axis().scale(x)
						.innerTickSize(-height)
						.outerTickSize(0)
						.orient("bottom").ticks(5); //tick 20 for 6 months   //20 for one,3 month  //25 for last 1 year

					var yAxis = d3.svg.axis().scale(y)
						.innerTickSize(-width)
						.outerTickSize(1)
						.orient("left").ticks(10);
					x.domain(
						d3.extent(data, function (d) {
							return d.date;
						})
					);

					/*if (newRange == 'max')
					 y.domain([0, d3.max(data, function (d) {
					 return d.countCus;
					 })]);
					 else
					 y.domain([0, newRange]);*/

					y.domain([0, d3.max(data, function (d) {
						return d.averageCount;
					})]);

					var area = d3.svg.area()
						.x(function (d) {
							return x(d.date);
						})
						.y0(height)
						.y1(function (d) {
							return y(d.averageCount);
						});

					var plotxy = d3.svg.line()
						.interpolate("monotone")
						.x(function (d) {
							// console.log(d.month);
							return x(d.date);
						})
						.y(function (d) { //console.log(d.SKUCount);
							return y(d.averageCount);
						});

					d3.select(element[0]).selectAll('svg').remove();

					var svg = d3.select(element[0])
						.append("svg")
						.attr("width", width + margin.left + margin.right)
						.attr("height", height + margin.top + margin.bottom)
						.append("g")
						.attr("transform", "translate(" + margin.left + "," + margin.top + ")");


					// var tip = d3.tip()
					// 	.attr('class', 'd3-tip')
					// 	.style('font-weight', 'normal')
					// 	.offset([-10, 0])
					// 	.html(function (d) {
					// 		return "<tr><td><b>" + (new Date(d.dateExpanded).toDateString()) + "</b></td></tr><br><br><span style='text-align:center;display:block'>" + (d.countCus) + "</span>";
					// 	});

					// svg.call(tip);

					height2 = height + 1; //for showing x-axis below points
					svg.append("g")
						.attr("class", "x axis")
						.attr("transform", "translate(0," + height2 + ")")
						.call(xAxis)
						.selectAll("text")
						.attr("y", 12)
						.attr("x", -2)
						.attr("dy", ".35em")
						.attr("transform", "rotate(20)")
						.style("text-anchor", "start");

					svg.append("text")
						.attr("class", "xlabel")
						.attr("text-anchor", "middle")
						.attr("x", width / 2)
						.attr("y", height + margin.bottom - 10)
						.style('color', 'steelblue')
						.style('font-size', 16)
						.text("Days");

					svg.append("g")
						.attr("class", "y axis")
						.call(yAxis);

					svg.append("text")
						.attr("class", "ylabel")
						.attr("y", 10 - margin.left)
						.attr("x", 0 - (height / 2))
						.attr("dy", "1em")
						.attr("transform", "rotate(-90)")
						.style("text-anchor", "middle")
						.text("Average Sales Cycle");

					//          var color = ['#74c476', 'steelblue', 'orange', '#6BAED6','#74c476', 'steelblue', 'orange', '#6BAED6'];

					var color = ['#74c476', 'steelblue', '#6BAED6', '#865a4b', '#ff9896', 'orange', 'red', '#9adae5'];

					// dataNest.forEach(function (d, i) {
					svg.append("path")
						.datum(data)
						.attr("class", "area")
						.style('fill', 'none')
						.attr('stroke', '#2683d5')
						.style('transition', 'all 0.2s linear')
						.attr("d", area);
					/*.on("mouseenter",function(){
					 drawPoints('mouseenter');
					 });*/


					// svg.append("path")
					// 	.attr("id", "dayCohort")
					// 	.attr("class", "line")
					// 	.attr("d", plotxy(data))
					// 	.style('fill', 'none')
					// 	.style('transition', 'all 0.2s linear');




					// });
					// function drawPoints(showhide){
					svg.selectAll(".dot")
						.data(data)
						.enter().append("circle")
						.attr('id', 'datapoint')

						.attr('cx', function (d) {
							return x(d.date);
						})
						.attr('cy', function (d) {
							return y(d.averageCount);
						})
						.attr('r', 4)
						.style('transition', 'all 0.1s ease-in-out')
						.attr('fill', '#ededed')
						.style('cursor', 'pointer')
						.attr('stroke', '#2683d5')
						.attr('stroke-width', '1')
						// .on('mouseover', tip.show)
						// .on('mouseout', tip.hide)
						.on('mouseenter', function (d, i) {
							this.setAttribute('r', 6);
						})
						.on('mouseleave', function (d, i) {
							this.setAttribute('r', 4);
						});

					//   }

					svg.append("text")
						.attr("class", "graphtitle")
						.attr("y", -30)
						.attr("x", width / 2)
						.style("fill", '#1f4c75')
						.style('font-size', '16pt')
						.style('font-weight', 'bold')
						.style('text-transform', 'uppercase')
						.style("text-anchor", "middle")
						.text(scope.title);

					var curtain = svg.append('rect')
						.attr('x', -1 * width)
						.attr('y', -1 * height)
						.attr('height', (height + 20))
						.attr('width', (width))
						.attr('class', 'curtain')
						.attr('transform', 'rotate(180)')
						.style('fill', '#ffffff')
						.attr('opacity', 0.9)

					/* Optionally add a guideline */
					var guideline = svg.append('line')
						.attr('stroke', '#333')
						.attr('stroke-width', 0.21)
						.attr('class', 'guide')
						.attr('x1', 1)
						.attr('y1', 1)
						.attr('x2', 1)
						.attr('y2', height)

					/* Create a shared transition for anything we're animating */
					var t = svg.transition()
						.delay(150)
						.duration(1500)
						.ease('linear')
						.each('end', function () {
							d3.select('line.guide')
								.transition()
								.style('opacity', 0)
								.remove()
						});

					t.select('rect.curtain')
						.attr('width', 0);
					t.select('line.guide')
						.attr('transform', 'translate(' + width + ', 0)');
				}
			
				scope.$watch('data', function (data) {
					if (data != undefined) {
						scope.render(data);
					}
				});

				angular.element($window).bind('resize', function(){
					scope.render(scope.data);
				});
			}
		}
	}])
