'use strict';
angular.module('bitnudgeApp')
    .controller('funTabCtrl', function($scope,userAnalyticsService, Auth,p0,$http) {
        var me = Auth.getCurrentUser();
        userAnalyticsService.fetchUserAnalytics(me._id, p0.currentDailyCycle._id).then(function(userAnalytic){
            $scope.secondsLeft = userAnalytic.gameTime != undefined?userAnalytic.gameTime: 3600;
            $scope.timerLoaded = true;
            if($scope.secondsLeft == 0){
                $scope.hideGame = true;
            }
        });

        $scope.finished = function(){
            $scope.hideGame = true;
            $http.post('/api/userAnalytics/gameOver', {cycleId:p0.currentDailyCycle._id}).then(function(){

            });
        };
    });
