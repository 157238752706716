const autoImportNgTemplateLoaderTemplate1 = require('../html/adminLogsModal.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('autoUploadLogsCtrl', function($http, $scope, Sidebar, $mdDialog,  moment, UtilService) {
        
        $http.post('/api/adminActions/getAutoUploadActions')
        .then(function(result){
            result=result.data;
            _.each(result.data, function(log){
                log.date = moment(new Date(log.timeStamp)).format('DD_MM_YYYY')
            })
            $scope.logs = result.data;
            
        }).catch(function(err){
            console.log('Error while fetching logs');
        })
        
        $scope.downloadExcel = function(logsObj){
            let appUrl = Sidebar.getAppUrl();
            return UtilService.downloadFile(appUrl + '/api/adminActions/fileData', { actionId:logsObj._id });
        }

        $scope.openLogsModal = function (logsObj) {
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'adminLogsModalCtrl',
                size: 'md',
                resolve: {
                    logs: function () {
                        return logsObj.logs;
                    },
                    header: function () {
                        var adminAction = logsObj;
                        var log = adminAction.dataType+' '+ adminAction.actionType + ' ';
                        if(adminAction.date)
                            log += '[' + adminAction.date + '] ';
                        if(adminAction.cycleId)
                            log += '[ ' + adminAction.cycleId.name + ' ] ';
                        return log;
                    },
                    isCronCompleted: function(){return false}
                }
            });
        };
        

    })
