'use strict';
angular.module('bitnudgeApp')
    .controller('lmsBranchReportController', function ($scope, $http, Auth, $q,DTOptionsBuilder, leadsSummaryFilter) {
        $scope.currentUser = Auth.getCurrentUser();

        $http.post('/api/users/getChildren', { id: $scope.currentUser._id}).then(function (children) {
            children=children.data;
            $scope.children = [{name: "All Reportees", _id: $scope.currentUser._id}];

            var reporteeIds = _.map(children, function (val, key) {
                return val.childId._id
            });

            $http.post('/api/lmsActivities/getReporteesActivity/' + reporteeIds).then(function (reporteeCompliance) {
                reporteeCompliance=reporteeCompliance.data;
                $scope.reporteeCompliance = reporteeCompliance;
            });
        });

    });
