'use strict';

angular.module('bitnudgeApp')
    .service('ProductService', function ($http, $q, p0, UtilService) {
		var appUrl = p0.config.domain;
        var subfolder = p0.config.subfolder;
        if(subfolder){
            if(appUrl[appUrl.length -1] !== '/'){
                appUrl += '/';
            }
            if(subfolder[0] == '/'){
                subfolder = subfolder.slice(1);
            }
            appUrl += subfolder;
        }
        this.getProductConfigs = function () {
            var deferred = $q.defer();
            let api = '/api/products/allproducts';
			var products;
			$http.post(api).then(function (result) {
				if(result.data.status){
					products = result.data.data;
					deferred.resolve(products);
				}else{
					deferred.reject(result.data.errors[0]);
				}
			}, function (error) {
				deferred.reject(error.data.errors[0]);
			});
			return deferred.promise;
        }

        this.getSubProductConfigsByParent = function (productId) {
            var deferred = $q.defer();
            // var productId = '5b4b66707772b363571a15de'
			let api = '/api/products/subproducts';
			let postBody = {}
            if(productId){
				postBody.parentProductId = productId	
            }
			var subProducts;
			$http.post(api, postBody).then(function (result) {
				subProducts = result.data.data;
				deferred.resolve(subProducts);
			}, function (error) {
				deferred.reject(null);
			});
			return deferred.promise;
        }

		this.updateSubProduct = function(subProduct){
			var deferred = $q.defer();
            let api = '/api/products/subproduct';
			$http.post(api,{ subProduct: subProduct}).then(function (result) {
				deferred.resolve(result.data);
			}, function (error) {
				deferred.reject(null);
			});
			return deferred.promise;
		}

		this.downloadSubProduct = function(subProduct){
			if (!appUrl || appUrl == undefined) {
				appUrl='';
			}
			let api = '/api/products/downloadSubProduct';
			const baseUrl = appUrl + api;
			UtilService.downloadFile(baseUrl, {id:subProduct._id})
		}
		
		
        
    });
