const autoImportNgTemplateLoaderTemplate1 = require('../html/badgeAchieversModal.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('liveBadgeUpdateController', function($scope, Auth, social, $rootScope, badgeCorner) {
        $scope.strings = $rootScope.strings;
        $scope.badgeCount = 0;
        $scope.badgeData.forEach(function(badge){
			badge.imageUrl = badgeCorner.getImageUrl(badge.imageEnum);
            $scope.badgeCount += badge.users.length > 0?badge.users.length:0;
            badge.badgeAchievers = badge.users;
            badge.users = undefined;
        });

        var userId = Auth.getCurrentUser()._id;
        $scope.achieversList = [];

        // switch($scope.badgeData[0].badgeType){
        //     case 'level':           $scope.stackImage = require("../images/badges/level.png"); break;
        //     case 'overall':         $scope.stackImage = require("../images/badges/trophy-gold.svg"); break;
        //     case 'productivity':    $scope.stackImage = require("../images/badges/percentage.png"); break;
        //     case 'swot':            $scope.stackImage = require("../images/badges/redFlag.png"); break;
        //     default:                $scope.stackImage = require("../images/badges/overall.png");
        // }

        $scope.badgeData.forEach(function (badge) {
            badge.badgeAchievers.forEach(function(achiever){
                achiever.user = achiever;
                achiever.badge = badge;
                $scope.achieversList.push(achiever);
            });
        });
        $scope.headingMap = {
            overallBusiness:"Impact "+$scope.strings.POINTS.value,
            overallActivity:"Activity "+$scope.strings.POINTS.value,
            total:"Overall "+$scope.strings.POINTS.value,
            productivity:"Overall productivity",
            level:"Level",
            overall:"Overall Consistency",
            swot:"Warning Badges"
        };
        $scope.labelMap = {
            overallBusiness:"Impact Badges have been won",
            overallActivity:"Activity Badges have been won",
            total:"Performance Badges have been won",
            productivity:"Productivity Badges have been won",
            level:"Level Badges have been won",
            overall:"Consistency Badges have been won",
            swot:$scope.strings.SWOT_T.value+" flags have been given"
        };
        $scope.hoverContent = getBadgePopoverHtml($scope.badgeData);

        function getBadgePopoverHtml(badges){
            var pluralLabel = '  Badges have been won';
            var singularLabel = '  Badge has been won';
            var badgeString = '';
            badges.forEach(function(badge,index){
                var label = badge.badgeAchievers.length > 1 ? pluralLabel : singularLabel;
                if(badge.badgeType == 'level' || badge.badgeType == 'overall' || badge.badgeType == 'swot')
                    badgeString+=' <div class="badgePopOverCard"><span class="badgePopOverContainer"><img class = "badgePopOverImage" src="'+badge.imageUrl+'"/>'+
                        '</span><span><span class="achieversLength">'+badge.badgeAchievers.length+ '</span>'+ label  +'</span></div>';
                else if(badge.badgeType != 'productivity')
                    badgeString+=' <div class="badgePopOverCard"><span class="badgePopOverContainer"><img class = "badgePopOverImage" src="'+badge.imageUrl+'"/>'+
                        '<p class="white badgePopOverLabel">'+badge.label+'</p></span><span><span class="achieversLength">'+badge.badgeAchievers.length+ '</span>'+  label +'</span></div>';
                else
                    badgeString+=' <div class="badgePopOverCard"><span class="badgePopOverContainer"><img class = "badgePopOverImage" src="'+badge.imageUrl+'" height="58px" width="58px" />'+
                        '<p class="black badgePopOverLabel" style="top:14px">'+badge.label+'</p></span><span class="achieversLength">'+badge.badgeAchievers.length+ '</span> label </span></div>';
                //if(index < badges.length -1){
                //    badgeString+='<hr>';
                //}
            });
            return badgeString
        }
        $scope.socialAction = social.bulkSocialAction;

        $scope.showModal = function() {
            $mdDialog.open({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'badgeAchieversModalController',
                size: 'lg',
                resolve: {
                    achieversList: function () {
                        return $scope.achieversList;
                    },
                    badgeName: function () {
                        return $scope.headingMap[$scope.badgeData[0].badgeType];
                    }
                }
            });
        }
    });
