const autoImportNgTemplateLoaderTemplate1 = require('../html/badgeAchieversModal.html');

'use strict';

angular.module('bitnudgeApp')
    .directive('badgeAchieversModal', function () {
        return {
            name: 'badgeAchieversModal',
            scope: {
                userList:     '=',
                badgeName:    '='
            },
            restrict: 'EA',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
