'use strict';
angular.module('bitnudgeApp')
    .controller('addBulkLeadController', function($http, $scope, Auth, Upload, ngXlsx, $location, toaster, $mdDialog, $filter) {
        let self=this;
        $scope.fileType = "leads";
        $scope.showNewLog = false;
        $scope.toggleMin = function() {
            $scope.minDate = $scope.minDate ? null : new Date();
        };
        $scope.toggleMin();

        $scope.maxDate = new Date();

        self.s2ab = function(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }

        self.getTimestamp = function(){
            var timestamp = new Date(); 
            timestamp = timestamp.toString().substring(0, 24)
            timestamp = timestamp.replace('-', ':');
            return timestamp;
        }

        //opening a datepicker dialog box
        $scope.open = function($event) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened = true;
        };

        //Configration for Date picker.
        $scope.dateOptions = {
            formatYear: 'yy',
            modelDateFormat: "yyyy-MM-ddTHH:mm:ss",
            startingDay: 1
        };

        $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];

        //Function to submit lead excel
        $scope.onFileSelectLead = function() {
            $scope.showNewLog = false;
            let asOfDate;
            if(Object.prototype.toString.call($scope.asOfDate) == '[object Date]'){
                asOfDate = $scope.asOfDate.getTime();
            }
            if ($scope.bulkLead == undefined) {
                toaster.pop('error', "Submission.", "Select File to Upload.");
            }else if(!asOfDate){
                toaster.pop('error', "Submission.", "Please select the date.");
            } else {
                var isFileNameValid = true;
                $scope.bulkLead.forEach(function (file) {
                    var fileName = $scope.bulkLead[0].name.split(".");
                    var fileType = fileName[fileName.length - 1].toLowerCase();
                    if (fileType != "xlsx" && fileType != "xlsm") {isFileNameValid = false;}
                });
                if (isFileNameValid) {
                    var uploadData = {
                        userId: Auth.getCurrentUser()._id,
                        asOfDate: asOfDate
                    };
                    $scope.bulkLead.forEach(function (file, index) {
                        uploadData['lead'+index] = file;
                    });

                    uploadData.cronTag = 'uploadLead';
                    uploadData.invocationMethod = 'manual'
                    
                    Upload.upload({
                        url: '/api/upload',
                        data: uploadData,
                        progress: function(e) {}
                    }).then(function(result){
                        $scope.showNewLog = true;
                        result=result.data;
                        toaster.pop('success', "Excel uploaded", successMessage);
                        // if(result.exceptions != undefined) {
                        //     if(result.operations.length == 0)
                        //     toaster.pop('warning', "Excel uploaded!!", 'There were '+result.exceptionsCount+' \"Exceptions\" and '+result.successCount+' \"Successful\" operations.!!');                    
                        //     else                        
                        //     toaster.pop('failure', "Excel uploaded!!", 'There were '+result.exceptionsCount+' \"Exceptions\" and '+result.successCount+' \"Successful\" operations.!!');                    
                        //     var file = ngXlsx.writeXlsx([result.exceptions]);
                        //     var date = new Date()
                        //     var timestamp = new Date(); 
                        //     timestamp = timestamp.toString().substring(0, 24)
                        //     saveAs(new Blob([self.s2ab(file)],{type:"application/octet-stream"}), self.getTimestamp()+'-Leads-Errors.xlsx');
                        // } else {
                        //     toaster.pop('success', "Excel uploaded!!", 'No exceptions in the operation!!');
                        // }
                    }).catch(function(res){
                        if(res.status === 310) {
                            toaster.pop('warning', "Excel could not be uploaded!!", 'Sorry!! Headers in the excel file are not appropriate!!');                    
                        }
                        $scope.showNewLog = true;
                    });
                } else {
                    toaster.pop('error', "Submission.", "Upload '.xlsx' file only.");
                }

            }
        };
        $scope.sendingMail = false;
        $scope.mailLeadsFile = function () {
            if(!$scope.asOfDate)return alert('Please select Date');
            $scope.sendingMail = true;
            var confirm = $mdDialog.confirm()
                .title('Leads updated in BitNudge from "'+ $filter('date')($scope.asOfDate, 'MMM dd, yyyy')+ '" will be exported')
                .targetEvent(event)
                .ok('Yes')
                .cancel('No');
            $mdDialog.show(confirm).then(function() {
                if(Object.prototype.toString.call($scope.asOfDate) == '[object Date]'){        
                    $scope.asOfDate = $scope.asOfDate.getTime();
                }
                $http.post('/api/leads/mailLeadsFile', {fromDate: $scope.asOfDate}).then(function(result){
                    result=result.data;
                    $scope.sendingMail = false;
                });
            }, function(err) {
                $scope.sendingMail = false;
            });

        }

    })
