'use strict';
angular.module('bitnudgeApp')
    .controller('showKPIActivitiesController', function ($scope, $rootScope, $mdDialog, $http, kpi, jobrole, userAnalyticsService, userId, cycleId) {
        var jobrole = jobrole, kpiId = kpi.kpiCode, submetric = "achievedValue";
        $scope.dataLoaded = false;
        $scope.kpiName = kpi.name;
        $scope.onClose = function () {
            $mdDialog.cancel();
        };
        const postBody = {
            jobroleId: jobrole, kpiId, submetric
        }
        $http.post('/api/userAnalytics/getFormulaActivitiesForKpi', postBody).then(function (activities) {
            activities=activities.data;

            userAnalyticsService.fetchUserAnalytics(userId, cycleId).then( function (userAnalytics) {
                var activitiesData = userAnalytics.activityData;
                activitiesData = activitiesData.filter(function (activityData) {
                    if (activityData.kpiCode && activityData.activityCode) {

                        return true;

                    }
                    return false;
                })
                activities.forEach(function (activity) {
                    activitiesData.forEach(function (activityData) {
                        if (activityData.kpiCode == activity.kpi && activityData.activityCode == activity.activity) {
                            if (activity.unit == "#") {
                                activity.value = Math.round(activityData.value.value);
                            }
                            else if (activity.unit == "%")
                            {
                                activity.value = Math.round(activityData.value.value);
                            }
                            else {
                                activity.value = Math.round(activityData.value.value * 100)/100;
                            }
                            activity.name = activityData.name;
                            activity.unit = activityData.unit;
                        }
                    })
                })
                $scope.activities = activities;
                $scope.dataLoaded = true;
            })
        });
    })

