'use strict';
angular.module('bitnudgeApp')
    .controller('addLostLeadsController', function($http, $scope, Auth, Upload, $location, toaster) {
        $scope.toggleMin = function() {
            $scope.minDate = $scope.minDate ? null : new Date();
        };
        $scope.toggleMin();

        //opening a datepicker dialog box
        $scope.open = function($event) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened = true;
        };

        //Configration for Date picker.
        $scope.dateOptions = {
            formatYear: 'yy',
            modelDateFormat: "yyyy-MM-ddTHH:mm:ss",
            startingDay: 1
        };

        $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];

        //Function to submit lead excel
        $scope.onFileSelectLead = function() {
            $scope.asOfDate = $scope.asOfDate.getTime();
            if ($scope.lostLeads == undefined) {
                toaster.pop('error', "Submission.", "Select File to Upload.");

            }else if(!$scope.asOfDate){
                toaster.pop('error', "Submission.", "Select as of date.");
            }
            else {
                var fileName = $scope.lostLeads[0].name.split(".");
                var fileType = fileName[fileName.length - 1].toLowerCase();
                if (fileType == "xlsx") {
                    Upload.upload({
                        url: '/api/leads/import/lostLeads/excel',
                        data: {
                            userId: Auth.getCurrentUser()._id,
                            asOfDate: $scope.asOfDate
                        },
                        file: $scope.lostLeads[0],
                        progress: function(e) {}
                    }).then(function(data, status, headers, config) {
                        toaster.pop('success', "Excel uploaded", "Lost Leads successfully finished.");
                    });
                } else {
                    toaster.pop('error', "Submission.", "Upload '.xlsx' file only.");
                }

            }
        }

    })



