angular.module('bitnudgeApp')
    .controller('adminConfigController', function ($http, $scope, toaster) {
        function init(){
            $http.post('/api/config/index').then(function(configs){
                configs = configs.data;
                var smtpIpObj = _.find(configs,{key:'smtpIp'});
                var smtpPortObj = _.find(configs,{key:'smtpPort'});
                var fcmServerKeyObj = _.find(configs,{key:'FCM_SERVER_KEY'});
                var cronAdminEmailObj = _.find(configs,{key:'cronAdmin'});
                var senderEmailObj = _.find(configs,{key:'senderEmail'});
                var senderEmailPasswordObj = _.find(configs,{key:'senderEmailPassword'});
                var proxyIPObj = _.find(configs,{key:'proxyIP'});
                var proxyPortObj = _.find(configs,{key:'proxyPort'});

                $scope.smtpIp = smtpIpObj ? smtpIpObj.value : '';
                $scope.smtpPort = smtpPortObj ? smtpPortObj.value : '';
                $scope.fcmServerKey = fcmServerKeyObj? fcmServerKeyObj.value : '';
                $scope.cronAdminEmail = cronAdminEmailObj ? cronAdminEmailObj.value: '';
                $scope.senderEmail = senderEmailObj ? senderEmailObj.value : '';
                $scope.senderEmailPassword = senderEmailPasswordObj ? senderEmailPasswordObj.value : '';
                $scope.proxyIP = proxyIPObj ? proxyIPObj.value : '';
                $scope.proxyPort = proxyPortObj ? proxyPortObj.value : '';
            });
        }
        init();
        $scope.validateConfig = function () {
            var adminEmails = $scope.cronAdminEmail;
            if(typeof $scope.cronAdminEmail == 'string'){
                adminEmails = $scope.cronAdminEmail.split(',');
            }
            adminEmails.forEach(function(email, i){
                adminEmails[i] = email.toString().trim();
            });
            var body = [
                {type:'String', key:'smtpIp', value:$scope.smtpIp},
                {type:'String', key:'smtpPort', value:$scope.smtpPort},
                {type:'String', key:'FCM_SERVER_KEY', value:$scope.fcmServerKey},
                {type:'Array', key:'cronAdmin', value:adminEmails},
                {type:'String', key:'senderEmail', value:$scope.senderEmail},
                {type:'String', key:'senderEmailPassword', value:$scope.senderEmailPassword},
                {type:'String', key:'proxyIP', value:$scope.proxyIP},
                {type:'String', key:'proxyPort', value:$scope.proxyPort}
            ];

            $http.post('/api/config/updateBulkConfigs',{configs:body})
                .then(function (result) {
                    toaster.pop("Success","Configs updated successfully!");
                })
                .catch(function(err){
                    toaster.pop('warning','Something went wrong!');
                });
        }
    });
