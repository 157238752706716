const autoImportNgTemplateLoaderTemplate1 = require('../html/metricSelectionModal.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/infoModal.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('cneCardCtrl', function ($scope, $rootScope, $location,$http, p0,Auth,cneActivityIndicator) {
        $scope.importantActivities = ['call','meeting','submission','leadCreation'];
        $scope.currentUser = Auth.getCurrentUser();
        $scope.isDummyIncentive = false;
        if($scope.heading == 'Incentives'){
            $scope.section1 = [];
            $scope.isDummyIncentive = true;
        }

        $scope.isWeekly = false;
        $scope.isPoints = false;
        $scope.user = false;
        var kpiId;
        if($scope.kpi){
            kpiId = $scope.kpi;
        }else{
            kpiId = 'overall';
        }
        $scope.comparePlayer = function (userId) {
            $rootScope.myComparison = true;
            $rootScope.playersFromLB = [userId];
            $rootScope.comparePlayersFromLB = true;
            $state.go('userFlow.analytics', {tabName: 'playerComparison', myComparison: $rootScope.myComparison});
        };
        function getData(){
            if($scope.isWeekly){
                $scope.userData = cneActivityIndicator.weeklyData[kpiId];
                $scope.metricData = cneActivityIndicator.weeklyMetricData[kpiId];
            }else{
                $scope.userData = cneActivityIndicator.monthlyData[kpiId];
                $scope.metricData = cneActivityIndicator.monthlyMetricData[kpiId];
            }
        }

        if($scope.itemType ==  'activityIndicators'){
            cneActivityIndicator.getData(kpiId,function(){
                getData();
                $scope.$watch('isWeekly',function(){
                    getData();
                });
            });
            if($scope.currentUser.roleType != 'seniorManager') {
                cneActivityIndicator.getMetricData(kpiId, $scope.metric, function () {
                    getData();
                    $scope.$watch('metric', function () {
                        setMetric($scope.metric);
                    });
                });
            }
        }

        var setMetric = function(metric){
            $scope.metric = metric;
            if(typeof metric == 'object'){
                $scope.user = true;
            }else{
                $scope.user = false;
            }
            switch($scope.metric){
                case 'Top Decile':
                    $scope.metricText = 'TD';
                    break;
                case 'Bank Average':
                    $scope.metricText = 'BA';
                    break;
                case 'Region Average':
                    $scope.metricText = 'RA';
                    break;
                case 'Branch Average':
                    $scope.metricText = 'BA';
                    break;
                case 'Previous Period':
                    $scope.metricText = 'PP';
                    break;
                case 'YTD Best':
                    $scope.metricText = 'PP';
                    break;
                default:
                   $http.post('/api/users/getUserImage', {userId:$scope.metric._id}).then(function(userImage){
                        userImage=userImage.data;
                       $scope.metricText = userImage;
                   });

            }
            cneActivityIndicator.getMetricData(kpiId,$scope.metric,function(){
                getData();
            });
        };


        $scope.openComparisionModal = function(){
            $mdDialog.open({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'metricSelectionModalCtrl',
                size: 'md',
                resolve:{
                    benchMark: function(){
                        return $scope.metric;
                    },
                    userId: function(){
                        return $scope.currentUser._id;
                    },
                    setMetric: function(){
                        return setMetric;
                    }
                }
            });
        };

        $scope.openInfoModal = function(){
            $scope.modalInstance = $uibModal.open({
                templateUrl: autoImportNgTemplateLoaderTemplate2,
                scope:$scope
            });
        };
        $scope.onClose = function() {
            $scope.modalInstance.close();
        };
    });

