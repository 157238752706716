const autoImportNgTemplateLoaderTemplate1 = require('../html/campaign/campaigns.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/main.html');

'use strict';

angular.module('bitnudgeApp')
    .config(function($stateProvider) {
        $stateProvider
            .state('userFlow.campaigns', {
                url: '/campaigns',
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'campaignController',
				authenticate: true
            })

            .state('userFlow.campaigns.main', {
                url: '/main',
                templateUrl: autoImportNgTemplateLoaderTemplate2,
                controller: 'MainCtrl'
            })
    });

