'use strict';

angular.module('bitnudgeApp')
    .filter ('returnFilteredNotifications', function () {
        return function (notifications, isChat) {
            return notifications.filter (function (e,i,l) {
                return isChat? e.extraParams && e.extraParams.isChatNotif && e.extraParams.isChatNotif === true :
                                !e.extraParams || !e.extraParams.isChatNotif || e.extraParams.isChatNotif === false ;
            });
        }
    })
  .controller('NavbarCtrl',["$templateCache","$scope", "$http","$location", "Auth", "$state","$redirectRouter" , "returnFilteredNotificationsFilter" ,'serviceManager',function ($templateCache, $scope, $http,$location, Auth, $state, $redirectRouter, returnFilteredNotificationsFilter,serviceManager) {
    $scope.menu = [{
      'title': 'Home',
      'link': '/'
    },{
      'title': 'Message',
      'link': '/message'
    }
    ];



    $scope.isCollapsed = true;
    $scope.isLoggedIn = Auth.isLoggedIn;
    $scope.isAdmin = Auth.isAdmin;
    $scope.getCurrentUser = Auth.getCurrentUser;

    $scope.logout = function() {
      serviceManager.logout();
      $templateCache.removeAll();
      Auth.logout();
      $location.path('/login');
    };

    $scope.onClickNotification=function(from, notificationObj) {
        if(!notificationObj.read){
            notificationObj.read = 'read';
            $http.post('/api/notifications/setNotificationRead',{id: notificationObj._id}, {read: notificationObj.read}).then(function(){
                if(from == 'app') $scope.unReadAppNotificationCount -= 1;
                else $scope.unReadChatNotificationCount -=1;
                $redirectRouter.redirectTo(notificationObj.linkTo, notificationObj.extraParams);
            });
        }
    };

    $scope.isActive = function(route) {
      return route === $location.path();
    };
    $http.post('/api/notifications/user/show',{id: Auth.getCurrentUser()._id}).then(function(notif){
        notif=notif.data;
        var sortedNotif  = _.sortBy(notif, 'createdBy');
        notif = sortedNotif.reverse();
        $scope.notifications=notif || [];
        $scope.chatNotifications = returnFilteredNotificationsFilter (notif || [], true);
        $scope.appNotifications = returnFilteredNotificationsFilter (notif || [], false);
        $scope.unReadChatNotificationCount = _.filter($scope.chatNotifications, {read: false}).length;
        $scope.unReadAppNotificationCount = _.filter($scope.appNotifications, {read: false}).length;
      //socket.syncUpdates('notif', $scope.notifications);
    })
  }]);

angular.module('bitnudgeApp').filter('nospace', function () {
    return function (value) {
        return (!value) ? '' : value.replace(/ /g, '');
    };
});
