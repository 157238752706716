'use strict';
angular.module('bitnudgeApp')
    .controller('leaveMgtModalCtrl', function ($scope, $mdDialog, LeaveMgt, $http, toaster) {
        var fetchData = function () {
            LeaveMgt.getReporteeData().then(function (args) {
                var reportees = args[0], futureLeaves = args[1];
                $scope.selectedReportee = null;
                $scope.addLeave = {};
                $scope.data = reportees;
                $scope.today = new Date();
                $scope.futureLeavesData = _.orderBy(futureLeaves, ['leave.from'], ['asc']);
            });
        };
        fetchData();

        $scope.addLeave = {};

        $scope.save = function (reportee) {
            if (reportee) {
                reportee.leave.from = new Date(reportee.leave.from).setHours(0, 0, 0, 0, 0);
                reportee.leave.to = new Date(reportee.leave.to).setHours(23, 59, 59, 59, 999);
                reportee.leave['userId'] = reportee._id;
                if (reportee.leave.to < reportee.leave.from) {
                    toaster.pop('Error', 'Start Date cannot be less than End date');
                }
                else {
                    $http.post('/api/users/editLeave', reportee.leave).then(function () {
                        toaster.pop('Success', 'Leave Edited');
                        fetchData();
                    });
                }
            } else {
                $scope.addLeave.from = new Date($scope.addLeave.from).setHours(0, 0, 0, 0, 0);
                $scope.addLeave.to = new Date($scope.addLeave.to).setHours(23, 59, 59, 59, 999);
                $scope.addLeave['userId'] = $scope.selectedReportee._id;
                $http.post('/api/users/addLeave', $scope.addLeave).then(function () {
                    toaster.pop('Success', 'Leave Added');
                    fetchData();
                });
            }
        };
        $scope.remove = function (reportee) {
            $http.post('/api/users/archive/cancelLeave', {leaveId:reportee.leave._id, userId:reportee._id}).then(function () {
                toaster.pop('Success', 'Leave Cancelled');
                fetchData();
            });
        };
        $scope.cancel = function () {
            $scope.selectedReportee = null;
            $scope.addLeave = {};
        };

        $scope.onClose = function () {
            $mdDialog.cancel();
        }
    });
