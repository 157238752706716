'use strict';
angular.module('bitnudgeApp')
    .service('recommendation', function ($http,p0, userAnalyticsService, Auth, $rootScope, $filter, leagueAnalytic, ForecastMultiplier, PipelineService, $q) {
        var _this = this;
        var dataFetched = false;
        let self = this;
        self.me = Auth.getCurrentUser();
        this.getData = function(){
            if(dataFetched){
                return $q.resolve(_this.recommendations);
            }
            return $http.post('/api/analytics/recommendations').then(function(recommendations){
                recommendations=recommendations.data;
                dataFetched = true;
                _this.recommendations = {};
                var activityWarnings = _.filter(recommendations.overall,{type:'activity'});
                _this.recommendations.overall = self.getRecommendationText(recommendations.overall,'overall');
                if(activityWarnings.length) {
                    _this.recommendations.overall.push({text: 'Stretch your performance to come out of threat '+$rootScope.strings.SWOT_SWOT_SING.value+' in ' + activityWarnings.length + ' activities.'});
                }
                var overallWarnings = [];
                p0.myKpis.forEach(function(kpi){
                    var warnings = _.filter(recommendations[kpi._id],function(recommendation){
                        return recommendation.type == 'swot' || recommendation.type == 'redFlag' || recommendation.type == 'form';
                    });
                    overallWarnings = overallWarnings.concat(warnings);
                    var warningSubTexts = [];
                    var swotwm =  _.filter(recommendations[kpi._id],{type:'swot',value:'w',cycleType:'monthly'});
                    if(swotwm.length > 0){
                        warningSubTexts.push( swotwm.length + ' Weakness in monthly cycle.');
                    }
                    var swotww =  _.filter(recommendations[kpi._id],{type:'swot',value:'w',cycleType:'weekly'});
                    if(swotww.length > 0){
                        warningSubTexts.push( swotww.length + ' Weakness in weekly cycle.');
                    }
                    var swottm =  _.filter(recommendations[kpi._id],{type:'swot',value:'t',cycleType:'monthly'});
                    if(swottm.length > 0){
                        warningSubTexts.push( swottm.length + ' threats in monthly cycle.');
                    }
                    var swottw =  _.filter(recommendations[kpi._id],{type:'swot',value:'t',cycleType:'weekly'});
                    if(swottw.length > 0){
                        warningSubTexts.push( swottw.length + ' threats in weekly cycle.');
                    }
                    var redFlag =  _.find(recommendations[kpi._id],{type:'redFlag'});
                    if(redFlag){
                        warningSubTexts.push(redFlag.value + ' red flags.');
                    }
                    var form =  _.find(recommendations[kpi._id],{type:'form'});
                    if(form){
                        warningSubTexts.push('Decreasing form in the game.');
                    }
                    var activityWarnings = _.filter(recommendations[kpi._id],{type:'activity'});
                    _this.recommendations[kpi._id] = self.getRecommendationText(recommendations[kpi._id],'kpi');
                    if(warnings.length) {
                        _this.recommendations[kpi._id].push({text: 'You have ' + warnings.length + ' warnings.',subText:warningSubTexts});
                    }
                    if(activityWarnings.length) {
                        _this.recommendations.overall.push({text: 'Stretch your performance to come out of threat '+$rootScope.strings.SWOT_SWOT_SING.value+ 'in ' + activityWarnings.length + ' activities.'});
                    }
                });
                var warningSubTexts = [];
                var swotwm =  _.filter(overallWarnings,{type:'swot',value:'w',cycleType:'monthly'});
                if(swotwm.length > 0){
                    warningSubTexts.push( swotwm.length + ' Weakness in monthly cycle.');
                }
                var swotww =  _.filter(overallWarnings,{type:'swot',value:'w',cycleType:'weekly'});
                if(swotww.length > 0){
                    warningSubTexts.push( swotww.length + ' Weakness in weekly cycle.');
                }
                var swottm =  _.filter(overallWarnings,{type:'swot',value:'t',cycleType:'monthly'});
                if(swottm.length > 0){
                    warningSubTexts.push( swottm.length + ' threats in monthly cycle.');
                }
                var swottw =  _.filter(overallWarnings,{type:'swot',value:'t',cycleType:'weekly'});
                if(swottw.length > 0){
                    warningSubTexts.push( swottw.length + ' threats in weekly cycle.');
                }
                var redFlag =  _.filter(overallWarnings,{type:'redFlag'});
                if(redFlag.length > 0){
                    warningSubTexts.push(_.sumBy(redFlag,'value') + ' red flags.');
                }
                var form =  _.filter(overallWarnings,{type:'form'});
                if(form.length > 0){
                    warningSubTexts.push('Decreasing form in the game in ' + form.length + ' kpis');
                }
                if(overallWarnings.length) {
                    _this.recommendations.overall.push({text: 'You have ' + overallWarnings.length + ' warnings.',subText:warningSubTexts});
                }
                return _this.recommendations;
            });
        };

        this.destroy = function(){
            dataFetched = false;
        };

        this.getRecommendationText = (recommendations,type) => {
            var finalObj = [];
            recommendations.forEach(function(recommendation){
                switch (recommendation.type){
                    case 'badge':
                        finalObj.push({text:'You need ' + recommendation.value.toFixed(0) + $rootScope.strings.POINTS.value+'to achieve ' + recommendation.desc + ' badge.'});
                        break;
                    case 'incentive':
                        finalObj.push({text:'You are not qualified for incentives in this cycle.'});
                        break;
                    case 'status':
                        if(recommendation.currentBadge && recommendation.desiredBadge.metricValue == recommendation.currentBadge.metricValue){
                            finalObj.push({text:'Earn ' + recommendation.value.toFixed(0) + $rootScope.strings.POINTS.value+' to avoid loss of ' + recommendation.currentBadge.name + ' status.'});
                        }else{
                            finalObj.push({text:'Earn ' + recommendation.value.toFixed(0) + $rootScope.strings.POINTS.value+' to achieve ' + recommendation.desiredBadge.name + ' status.'});
                        }
                        break;
                    case 'gameplan':
                        if(recommendation.subType == 'made'){
                            finalObj.push({text: recommendation.cycleType + ' gameplan not made'});
                        }else{
                            finalObj.push({text:'Trailing on ' + recommendation.cycleType + ' gameplan compliance.'});
                        }
                        break;
                    case 'lead':
                        switch (recommendation.subType){
                            case 'risk':
                                finalObj.push({text:recommendation.value + ' leads at a risk of loss.'});
                                break;
                            case 'missed':
                                finalObj.push({text:recommendation.value + ' follow up missed on leads.'});
                                break;
                            case 'today':
                                finalObj.push({text:recommendation.value + ' leads have a follow up today.'});
                                break;
                            case 'nearSuccess':
                                finalObj.push({text:recommendation.value + ' leads are near success.'});
                                break;
                            case 'hot':
                                finalObj.push({text:'Close ' + recommendation.value + ' that are followed by managers.'});
                                break;
                        }
                        break;
                }
            });
            return finalObj;
        }

        this.getNewData = function(selectedKpi){
            let {cycleIds, prevMonthCycle, prevWeekCycle} = self.getPreviousCycles();
            if(!self.me || !self.me.leagues || !self.me.leagues.length){
                return $q.resolve([]);
            }
            var leagueIds = [self.me.leagues[0].leagueId,self.me.leagues[self.me.leagues.length - 1].leagueId];
            return userAnalyticsService.fetchUserAnalyticsOfCycles(self.me._id,cycleIds).then(function(userAnalytics){
                return leagueAnalytic.fetchLeagueAnalyticsOfCycle(leagueIds,p0.trackMonthlyCycle._id).then(function(leagueAnalytics){
                    return self.setFinalData({leagueAnalytics, userAnalytics, selectedKpi, prevMonthCycle, prevWeekCycle})
                });
            });
        };

        this.setFinalData = ({leagueAnalytics, userAnalytics, selectedKpi, user, prevMonthCycle, prevWeekCycle}) => {
            var teamLeague = leagueAnalytics[0];
            var bankLeague = leagueAnalytics[1];
            var finalData = {};
            var monthlyUserAnalytics = _.find(userAnalytics,{cycleId:p0.trackMonthlyCycle._id});
            if(!monthlyUserAnalytics){
                return finalData;
            }
            if(p0.trackWeeklyCycle){
                var weeklyUserAnalytics = _.find(userAnalytics,{cycleId:p0.trackWeeklyCycle._id});
            }
            if(prevMonthCycle)
                var prevMonthlyUserAnalytics = _.find(userAnalytics, {cycleId: prevMonthCycle._id});
            if(prevWeekCycle)
                var prevWeeklyUserAnalytics = _.find(userAnalytics, {cycleId: prevWeekCycle._id});

            let performance = self.getPerformance({monthlyUserAnalytics, prevMonthCycle, prevWeekCycle, prevMonthlyUserAnalytics, prevWeeklyUserAnalytics});
            let comparison = self.getComparison(bankLeague, teamLeague, monthlyUserAnalytics);

            let {monthlyData, rankDeltaText, multiKpi, weeklyDelta, currentKpi} = self.getRankDeltaText(monthlyUserAnalytics, weeklyUserAnalytics, selectedKpi);
           
            let text = 'You have ' + rankDeltaText + ' in ranking (overall points) compared to last month';
            if(!isNaN(weeklyDelta)){
                text += 'and <span class="big-number">' + Math.abs(weeklyDelta) +  '</span> versus last week.'
            }
            comparison.push(text);

            let swot = self.getSwot(selectedKpi, multiKpi, monthlyUserAnalytics, monthlyData, currentKpi);
            
            if(performance){
                finalData.performance = performance;
            }
            finalData.comparison = comparison;
            finalData.swot = swot;

            /*if(selectedKpi._id !== 'overall') {
                var selectedKpiData =_.find(monthlyUserAnalytics.kpiData,{kpiId:currentKpi._id});
                _this.addBadgeRecommendations(finalData, selectedKpiData, monthlyUserAnalytics, weeklyUserAnalytics, self.me.leagues[self.me.leagues.length - 1].leagueId, bankLeague, selectedKpi, multiKpi).then( function () {
                    return finalData;
                });
            }else{
                return $q.resolve(finalData);
            }*/
            return finalData;
        }

        this.getRankDeltaText = (monthlyUserAnalytics, weeklyUserAnalytics, selectedKpi) => {
            var multiKpi = p0.myKpis.length >1;
            var monthlyData, weeklyData;
            var currentKpi = selectedKpi._id=='overall'?p0.myKpis[0]:selectedKpi;
            if(!multiKpi){
                monthlyData = _.find(monthlyUserAnalytics.kpiData,{kpiId:p0.myKpis[0]._id});
                if(weeklyUserAnalytics){
                    weeklyData = _.find(weeklyUserAnalytics.kpiData,{kpiId:p0.myKpis[0]._id});
                }
            }else{
                monthlyData = monthlyUserAnalytics;
                weeklyData = weeklyUserAnalytics;
            }
            var rankDeltaText;
            var mDelta = monthlyData.points.ranks[self.me.leagues.length - 1];
            if(mDelta){
                var monthlyDelta = mDelta.delta;
            }
            if(weeklyData && weeklyData.points && weeklyData.points.ranks && weeklyData.points.ranks.length){
                var weeklyDelta =  weeklyData.points.ranks[self.me.leagues.length - 1].delta;
                weeklyDelta = weeklyDelta ? weeklyDelta : 0;
            }
            if(weeklyDelta > 0){
                weeklyDelta = '+' + weeklyDelta;
            }
            if(monthlyDelta > 0){
                rankDeltaText = 'gained <span class="big-number">' + monthlyDelta + '</span> ' + $filter('inflect')('position',monthlyDelta);
            }else{
                rankDeltaText =  'slipped <span class="big-number">' + (-monthlyDelta) + '</span> ' + $filter('inflect')('position',monthlyDelta);
            } 
            return { monthlyData, multiKpi, rankDeltaText, weeklyDelta, currentKpi};           
        }

        this.getSwot = (selectedKpi, multiKpi, monthlyUserAnalytics, monthlyData, currentKpi) => {
            if(!monthlyUserAnalytics){
                return;
            }
            var swot = [];
            let swotText = self.getSwotText(multiKpi, selectedKpi, monthlyUserAnalytics, monthlyData, currentKpi);
            if(swotText) {
                swot.push(swotText);
            }
            let {strengthActivities, opportunityActivities, weaknessActivities, threatActivities} = self.getSwotActivities(multiKpi, selectedKpi, monthlyUserAnalytics, currentKpi);
            let {strengthString, oppurtunityString, warningString} = self.getSwotStrings(strengthActivities, opportunityActivities, weaknessActivities, threatActivities);
            
            if(strengthString) swot.push(strengthString);
            if(oppurtunityString) swot.push(oppurtunityString);
            if(warningString) swot.push(warningString);
            threatActivities.forEach(function(act){
                if(multiKpi && selectedKpi._id=='overall'){
                    swot.push('In ' + act.name +' ('+$filter('number')(act.percentageCompleted.value,0) + ' %), you run the risk of receiving a warning flag.');
                }else{
                    swot.push('At ' + $filter('number')(act.value.value,0) + ' on ' + act.name + ', you run the risk of receiving a warning flag.');
                }
            }); 
            return swot;           
        }

        this.getSwotStrings = (strengthActivities, opportunityActivities, weaknessActivities, threatActivities) => {
            let strengthString, oppurtunityString;
            var strengthNames = _.map(strengthActivities,'name');
            var opportunityNames = _.map(opportunityActivities,'name');
            var weaknessNames = _.map(weaknessActivities,'name');
            var threatNames = _.map(threatActivities,'name');
            if(strengthActivities.length) {
                strengthString = $filter('arrayToText')(strengthNames) + ' ' + self.inflect(strengthNames.length) + ' your <span class="big green">' + $filter('inflect')('STRENGTH', strengthNames.length) + '</span> where you fall in the <span class="big-number">Top 10%</span> of all players.';
                // swot.push()
            }
            if(opportunityActivities.length){
                oppurtunityString = $filter('arrayToText')(opportunityNames) + ' ' + self.inflect(opportunityActivities.length) + ' your <span class="big green">EMERGING '+$filter('inflect')('STRENGTH', opportunityNames.length) +'</span>';
                // swot.push();
            }
            if(weaknessNames.length || threatActivities.length){
                var warningText;
                if(weaknessNames.length){
                    warningText = 'You need to focus on ' + $filter('arrayToText')(weaknessNames) + ' that fall in your area of development';
                    if(threatNames.length){
                        warningText = warningText + ' and ' + $filter('arrayToText')(threatNames) + ' falls in concern '+$rootScope.strings.SWOT_SWOT_SING.value+'.'
                    }else{
                        warningText = warningText + '.';
                    }
                }else{
                    warningText = 'You need to focus on ' + $filter('arrayToText')(threatNames) + ' that fall in your concern '+$rootScope.strings.SWOT_SWOT_SING.value+'.';
                }
                // swot.push(warningText);
            }
            return {
                strengthString, oppurtunityString, warningText
            }            
        }

        this.getSwotText  = (multiKpi, selectedKpi, monthlyUserAnalytics, monthlyData, currentKpi) => {
            var swotText, swotString;
            if(multiKpi && selectedKpi._id=='overall'){
                    var concernKpis = _.filter(monthlyUserAnalytics.kpiData, {swot:'t'});
                    if(concernKpis.length>0){
                        concernKpis = _.map(concernKpis, 'name');
                        swotText = '<span class="big red">CONCERNs</span>';
                        swotString = 'You are demonstrating signs of ' + swotText + ' in '+$filter('arrayToText')(concernKpis)+'. Concentrate on your activities in order to inprove your performance';
                        // swot.push();
                    }
            }
            if(!multiKpi){
                var improveSuggestion = '. Improving performance will require you to focus sharply on your activities.';
                if(monthlyData.swot == 's'){
                    swotText = '<span class="big green">STRENGTH</span>';
                    improveSuggestion = '. Maintaining performance will require you to focus on your activities.';
                }else if(monthlyData.swot == 'o'){
                    // swotText = '<span class="big green">EMERGING STRENGTH</span>';
                    // improveSuggestion = '. Improving performance will require you to continue to focus on your activities.';
                }else if(monthlyData.swot == 'w') {
                    swotText = '<span class="big red">EARLY CONCERN</span>';
                }else{
                    swotText = '<span class="big red">CONCERN</span>';
                }
                if(swotText) {
                    swotString = 'In ' + currentKpi.name + ', you are demonstrating signs of ' + swotText + improveSuggestion;
                    // swot.push();
                }
            }
            return swotString;
        }

        this.getSwotActivities = (multiKpi, selectedKpi, monthlyUserAnalytics, currentKpi) => {
            if(!multiKpi || (multiKpi && selectedKpi._id!='overall')){
                var swotActivities = _.filter(monthlyUserAnalytics.activityData, {kpiId: currentKpi._id, swotFlag: true});
                var strengthActivities = _.filter(swotActivities,{swot:'s'});
                var opportunityActivities = _.filter(swotActivities,{swot:'o'});
                var weaknessActivities = _.filter(swotActivities,{swot:'w'});
                var threatActivities = _.filter(swotActivities,{swot:'t'});
            }else if(multiKpi && selectedKpi._id=='overall'){
                var strengthActivities = _.filter(monthlyUserAnalytics.kpiData,{swot:'s'});
                var opportunityActivities = _.filter(monthlyUserAnalytics.kpiData, {swot:'o'});
                var weaknessActivities = _.filter(monthlyUserAnalytics.kpiData, {swot:'w'});
                var threatActivities = _.filter(monthlyUserAnalytics.kpiData, {swot:'t'});
            }
            return {
                strengthActivities, opportunityActivities, weaknessActivities, threatActivities
            }            
        }

        this.getComparison = (bankLeague, teamLeague, monthlyUserAnalytics) => {
            var comparison = [];
            var bankAvg = bankLeague.percentageCompleted.avg;
            var teamAvg = teamLeague.percentageCompleted.avg;

            if(!monthlyUserAnalytics){
                return ['Sorry. No data available for this cycle.'];
            }
            if(bankAvg > monthlyUserAnalytics.percentageCompleted.value){
                if(p0.config.showForecast){
                    comparison.push('You are trending <span class="red big">BELOW</span> the average of all players.');
                }
                else{
                    comparison.push('Your performance is  <span class="red big">BELOW</span> the average performance of all players in the unit.');
                }
            }
            if(teamAvg > monthlyUserAnalytics.percentageCompleted.value){
                comparison.push('You currently fall <span class="red big">BELOW</span> the average of your '+ self.me.leagues[0].teamTypeName + '.');
            }
            return comparison;            
        }

        this.getPerformance = ({monthlyUserAnalytics, prevMonthCycle, prevWeekCycle, prevMonthlyUserAnalytics, prevWeeklyUserAnalytics}) => {
            if(p0.config.showForecast){
                if(!monthlyUserAnalytics){
                    let raceText = '<span class="black big">Sorry. No data available for this cycle.</span>';
                }else{
                    var performance = [];
                    var performanceForecast = $filter('number')(monthlyUserAnalytics.percentageCompleted.value * ForecastMultiplier.ofCycle(monthlyUserAnalytics.cycleId),0) + '%';
                    let raceText = self.getRaceText(monthlyUserAnalytics);
                    performance.push('At the current pace, you are racing '+ raceText + ' your monthly target. Your achievement forecast is <span class="big-number">'+performanceForecast+'</span>.' );
                    let deltaText = self.getDeltaText(monthlyUserAnalytics);
                    performance.push('You are likely to end up ' + deltaText + ' your last month\'s performance.');
                }
            }else if(prevMonthCycle || prevWeekCycle){
                var performance = [];
                let { progressText, accuracyText } = self.getProgressText(prevMonthCycle, prevWeekCycle, prevMonthlyUserAnalytics, prevWeeklyUserAnalytics);
                performance.push(progressText);
                if(accuracyText)performance.push(accuracyText);
            }
            return performance;
        }

        this.getProgressText = (prevMonthCycle, prevWeekCycle, prevMonthlyUserAnalytics, prevWeeklyUserAnalytics) => {
            var progressText = 'Your performance ';
            var accuracyText;
            if(prevMonthCycle){

                var accuracyAct = _.find(prevMonthlyUserAnalytics.activityData,function(activity){
                    return activity.fieldName == 'accuracy' && activity.activityType =='percentage' && !activity.kpiId;
                });
                progressText += 'in the last month was '+ $filter('number')(prevMonthlyUserAnalytics.percentageCompleted.value,0) +'% ';
                if(accuracyAct){
                    accuracyText = 'Your Accuracy in processing transactions is '+$filter('number')(accuracyAct.value.value,0)+'% (All transactions) in the last month.';
                }
            }
            if(prevMonthCycle && prevWeekCycle){
                progressText += ' and ';
            }
            if(prevWeekCycle){
                progressText += 'in the last week was '+$filter('number')(prevWeeklyUserAnalytics.percentageCompleted.value,0) +'%';
            }
            return { progressText, accuracyText };
        }

        this.getDeltaText = (monthlyUserAnalytics) => {
            var deltaText;
            if(monthlyUserAnalytics.percentageCompleted.delta > 0){
                deltaText = '<span class="big-number">'+ $filter('number')(monthlyUserAnalytics.percentageCompleted.delta,0) +  '%</span> <span class="green big">HIGHER</span> than';
            }else if(monthlyUserAnalytics.percentageCompleted.delta == 0){
                deltaText = '<span class="green big">EQUAL</span> to';
            }else{
                deltaText = '<span class="big-number">'+ $filter('number')(-monthlyUserAnalytics.percentageCompleted.delta,0) +  '%</span> <span class="red big">LOWER</span> than';
            }
            return deltaText;
        }

        this.getRaceText = (monthlyUserAnalytics) => {
            var raceText;
            if(monthlyUserAnalytics.percentageCompleted.value * ForecastMultiplier.ofCycle(monthlyUserAnalytics.cycleId) > 100){
                raceText = '<span class="green big">AHEAD</span> towards';
            }else if(monthlyUserAnalytics.percentageCompleted.value * ForecastMultiplier.ofCycle(monthlyUserAnalytics.cycleId) == 100){
                raceText = '<span class="green big">ON-TRACK</span> towards';
            }else{
                raceText = '<span class="red big">BEHIND</span>';
            }
            return raceText;
        }

        this.getPreviousCycles = () => {
            var cycleIds = [p0.trackMonthlyCycle._id];
            if(p0.config.weeklyCycle){
                cycleIds.push(p0.trackWeeklyCycle._id);
            }
            var prevMonthCycle, prevWeekCycle;
            if(p0.trackMonthlyCycle.previousCycleId){
                prevMonthCycle = p0.trackMonthlyCycle.previousCycleId;
                cycleIds.push(prevMonthCycle._id);
            }
            if(p0.trackWeeklyCycle && p0.trackWeeklyCycle.previousCycleId){
                prevWeekCycle = p0.trackWeeklyCycle.previousCycleId;
                cycleIds.push(prevWeekCycle._id);
            }
            return {
                cycleIds, prevMonthCycle, prevWeekCycle
            }
        }

        this.addBadgeRecommendations = function(finalData,monthlyKpiData,monthlyUserAnalytics, weeklyUserAnalytics,bankLeagueId, monthlyLeagueAnalytics, selectedKpi,multiKpi){
            return $http.post('/api/badges/index').then(function(allBadges){
                allBadges=allBadges.data;
                var today = new Date(p0.currentDailyCycle.startDate);
                var trackDate = new Date($rootScope.lastUpdatedAt);
                var monthEnd = new Date(p0.currentMonthlyCycle.endDate);
                var endWeek = moment(monthEnd).format('w');
                var currentWeek = moment(today).format('w');
                var day = today.getDay();
                var daysRemaining;
                daysRemaining = $rootScope.daysLeft;
                finalData.badge = [];
                if(multiKpi && selectedKpi._id == 'overall'){
                    monthlyUserAnalytics.kpiData.forEach(function(kpiData){
                        if(kpiData.target.value && kpiData.swot == 't'){
                            var leagueKpiData = _.find(monthlyLeagueAnalytics.kpiData,{kpiId:kpiData.kpiId});
                            var bottomDecilePC = leagueKpiData.percentageCompleted.bottomDecile;
                            var pcRate = bottomDecilePC/ $rootScope.daysPassed;
                            bottomDecilePC = pcRate * ($rootScope.daysPassed + daysRemaining);
                            var reqAchievement = kpiData.target.value  * bottomDecilePC / 100;
                            finalData.badge.push({text:'Achieve <span class="big-number">' +$filter('number')(reqAchievement,0) + '</span> '+kpiData.unit +' in the next <span class="big-number">' + daysRemaining + '</span> days to avoid a <span class="big red">flag</span> in ' + kpiData.name+'.'});
                        }
                    });
                }
                else if(monthlyKpiData && monthlyKpiData.swot == 't'){
                    var leagueKpiData = _.find(monthlyLeagueAnalytics.kpiData,{kpiId:monthlyKpiData.kpiId});
                    var bottomDecilePC = leagueKpiData.percentageCompleted.bottomDecile;
                    var pcRate = bottomDecilePC/ $rootScope.daysPassed;
                    bottomDecilePC = pcRate * ($rootScope.daysPassed + daysRemaining);
                    var reqAchievement = monthlyKpiData.target.value  * bottomDecilePC / 100;
                    finalData.badge.push({text:'Achieve <span class="big-number">' +$filter('number')(reqAchievement,0) + '</span> '+monthlyKpiData.unit +' in the next <span class="big-number">' + daysRemaining + '</span> days to avoid a <span class="big red">flag</span> in ' + monthlyKpiData.name+'.'});
                }
                if(multiKpi && selectedKpi._id == 'overall'){
                    return;
                }
                var myActivityRedFlags = _.filter(monthlyUserAnalytics.badges,{badgeCategory:'redFlag',badgeType:'activity'});
                myActivityRedFlags.forEach(function(badge){
                    if(selectedKpi._id != 'overall'){
                        if(badge.kpiName != selectedKpi.name)return;
                    }else{
                        return;
                    }
                    var activity = _.find(p0.allActivities,{_id:badge.activityId});
                    if(activity.operand1){
                        return;
                    }
                    var activityData = _.find(monthlyUserAnalytics.activityData,function(activityData){
                        return activityData.activityId == badge.activityId && !activityData.kpiId;
                    });
                    var leagueActivityData = _.find(monthlyLeagueAnalytics.activityData,function(activityData){
                        return activityData.activityId == badge.activityId && !activityData.kpiId;
                    });
                    var bottomDecileValue = leagueActivityData.value.bottomDecile;
                    var valueRate = bottomDecileValue / $rootScope.daysPassed;
                    bottomDecileValue = valueRate * ($rootScope.daysPassed + daysRemaining);
                    var reqValue = bottomDecileValue - activityData.value.value;

                    finalData.badge.push({text :'Make <span class="big-number">' +$filter('number')(reqValue,0) + '</span> ' + activityData.shortName+ ' in the next <span class="big-number">' + daysRemaining + '</span> days to avoid a <span class="big red">flag</span>.',
                        icon:   p0.config['activityIcons_'+activityData.fieldName]
                    });
                });
                if(!monthlyKpiData || (monthlyKpiData.swot == 't' || myActivityRedFlags.length != 0)){
                    return;
                }
                if(currentWeek != endWeek && p0.config.weeklyBadges) {
                    daysRemaining = Math.min(3,5 - day);
                    return leagueAnalytic.fetchLeagueAnalytic(bankLeagueId,p0.trackWeeklyCycle._id).then(function(weeklyLeagueAnalytics){
                        var weeklyDaysPassed = trackDate.getDay();
                        var weeklyBadges = weeklyUserAnalytics.badges;
                        var ipBadge = _.find(weeklyBadges,{badgeCategory:'overall', badgeType:'overallBusiness'});
                        if(!ipBadge || ipBadge.metric != 'top1'){
                            var nextBadge, metricValue;
                            var leagueKpiData = _.find(weeklyLeagueAnalytics.kpiData,{kpiId:monthlyKpiData.kpiId});
                            var userKpiData = _.find(weeklyUserAnalytics.kpiData,{kpiId:monthlyKpiData.kpiId});
                            if(ipBadge && ipBadge.metric == 'topQuartile'){
                                nextBadge = _.find(allBadges,{badgeCategory:'overall',badgeType:'overallBusiness',cycleType:'weekly',metric:'topDecile'});
                                metricValue = leagueKpiData.percentageCompleted.topDecile;
                            }else if(ipBadge && ipBadge.metric == 'topDecile'){
                                nextBadge = _.find(allBadges,{badgeCategory:'overall',badgeType:'overallBusiness',cycleType:'weekly',metric:'top1'});
                                metricValue = leagueKpiData.percentageCompleted.max.value;
                            }else{
                                nextBadge = _.find(allBadges,{badgeCategory:'overall',badgeType:'overallBusiness',cycleType:'weekly',metric:'topQuartile'});
                                metricValue = leagueKpiData.percentageCompleted.topQuartile;
                            }
                            var pcRate = weeklyDaysPassed?metricValue/ weeklyDaysPassed: metricValue;
                            metricValue = pcRate * ($rootScope.weeklyDaysPassed + daysRemaining);
                            var reqAchievement = monthlyKpiData.target.value  * metricValue / 100;
                            reqAchievement = reqAchievement - userKpiData.achievedValue.value;
                            finalData.badge.push({text:'Achieve <span class="big-number">' +$filter('number')(reqAchievement,0) + '</span> '+monthlyKpiData.unit +' in the next <span class="big-number">' + daysRemaining + '</span> days to earn the <span class="big green">'+nextBadge.name+'</span>.'});
                        }
                        var activityBadges = _.filter(allBadges,{badgeCategory:'activity',badgeType:'activity',cycleType:'weekly'});
                        activityBadges = _.uniqBy(activityBadges,'activityId');
                        activityBadges.forEach(function(aBadge){
                            var myBadge = _.find(weeklyBadges,{badgeCategory:'activity',badgeType:'activity',activityId:aBadge.activityId});
                            if(!myBadge || myBadge.metric != 'top1'){
                                var nextBadge, metricValue;
                                var leagueActivityData = _.find(weeklyLeagueAnalytics.activityData,function(activityData){
                                    return activityData.activityId == aBadge.activityId && !activityData.kpiId;
                                });
                                var userActivityData = _.find(weeklyUserAnalytics.activityData,function(activityData){
                                    return activityData.activityId == aBadge.activityId && !activityData.kpiId;
                                });
                                if(myBadge && myBadge.metric == 'topQuartile'){
                                    nextBadge = _.find(allBadges,{badgeCategory:'activity',badgeType:'activity',activityId:aBadge.activityId,cycleType:'weekly',metric:'topDecile'});
                                    metricValue = leagueActivityData.value.topDecile;
                                }else if(myBadge && myBadge.metric == 'topDecile'){
                                    nextBadge = _.find(allBadges,{badgeCategory:'activity',badgeType:'activity',activityId:aBadge.activityId,cycleType:'weekly',metric:'top1'});
                                    metricValue = leagueActivityData.value.max.value;
                                }else{
                                    nextBadge = _.find(allBadges,{badgeCategory:'activity',badgeType:'activity',activityId:aBadge.activityId,cycleType:'weekly',metric:'topQuartile'});
                                    metricValue = leagueActivityData.value.topQuartile;
                                }
                                var rate = weeklyDaysPassed?metricValue/ weeklyDaysPassed: metricValue;
                                var reqValue = rate * ($rootScope.weeklyDaysPassed + daysRemaining);
                                reqValue = reqValue - userActivityData.value.value;
                                finalData.badge.push({text:'Make <span class="big-number">' +$filter('number')(reqValue,0) + '</span> '+userActivityData.shortName +' in the next <span class="big-number">' + daysRemaining + '</span> days to earn the <span class="big green">'+nextBadge.name+'</span>.',
                                    icon:p0.config['activityIcons_'+userActivityData.fieldName]
                                });
                            }
                        });
                    });
                }else{
                    daysRemaining = $rootScope.daysLeft;
                    var opBadge = _.find(monthlyUserAnalytics.badges,{badgeCategory:'overall',badgeType:'total'});
                    if(!opBadge || opBadge.metric != 'top1'){
                        var nextBadge, metricValue;
                        if(opBadge && opBadge.metric == 'topQuartile'){
                            nextBadge = _.find(allBadges,{badgeCategory:'overall',badgeType:'total',cycleType:'monthly',metric:'topDecile'});
                            metricValue = monthlyLeagueAnalytics.points.topDecile;
                        }else if(opBadge && opBadge.metric == 'topDecile'){
                            nextBadge = _.find(allBadges,{badgeCategory:'overall',badgeType:'total',cycleType:'monthly',metric:'top1'});
                            metricValue = monthlyLeagueAnalytics.points.max.value;
                        }else{
                            nextBadge = _.find(allBadges,{badgeCategory:'overall',badgeType:'total',cycleType:'monthly',metric:'topQuartile'});
                            metricValue = monthlyLeagueAnalytics.points.topQuartile;
                        }
                        var pcRate = $rootScope.daysPassed?metricValue/ $rootScope.daysPassed: metricValue;
                        metricValue = pcRate * ($rootScope.daysPassed + daysRemaining);
                        var reqAchievement = metricValue - monthlyUserAnalytics.points.value;
                        finalData.badge.push({text:'Earn <span class="big-number">' +$filter('number')(reqAchievement,0) + '</span> '+$rootScope.strings.POINTS.value+' in the next <span class="big-number">' + daysRemaining + '</span> days to earn the <span class="big green">'+nextBadge.name+'</span>.'});
                    }

                    var apBadge = _.find(monthlyUserAnalytics.badges,{badgeCategory:'overall',badgeType:'overallActivity'});
                    if(!apBadge || apBadge.metric != 'top1'){
                        var nextBadge, metricValue;
                        if(apBadge && apBadge.metric == 'topQuartile'){
                            nextBadge = _.find(allBadges,{badgeCategory:'overall',badgeType:'overallActivity',cycleType:'monthly',metric:'topDecile'});
                            metricValue = monthlyLeagueAnalytics.activityPoints.topDecile;
                        }else if(apBadge && apBadge.metric == 'topDecile'){
                            nextBadge = _.find(allBadges,{badgeCategory:'overall',badgeType:'overallActivity',cycleType:'monthly',metric:'top1'});
                            metricValue = monthlyLeagueAnalytics.activityPoints.max.value;
                        }else{
                            nextBadge = _.find(allBadges,{badgeCategory:'overall',badgeType:'overallActivity',cycleType:'monthly',metric:'topQuartile'});
                            metricValue = monthlyLeagueAnalytics.activityPoints.topQuartile;
                        }
                        var pcRate = $rootScope.daysPassed?metricValue/ $rootScope.daysPassed: metricValue;
                        metricValue = pcRate * ($rootScope.daysPassed + daysRemaining);
                        var reqAchievement = metricValue - monthlyUserAnalytics.activityPoints.value;
                        finalData.badge.push({text:'Earn <span class="big-number">' +$filter('number')(reqAchievement,0) + '</span> activity '+$rootScope.strings.POINTS.value+' in the next <span class="big-number">' + daysRemaining + '</span> days to earn the <span class="big green">'+nextBadge.name+'</span>.'});
                    }

                    var levelBadge = _.find(monthlyUserAnalytics.badges,{badgeCategory:'overall',badgeType:'level'});
                    if(!apBadge || apBadge.metric != '10'){
                        var nextLvlBadge,nextMetric;
                        nextMetric = levelBadge?parseInt(levelBadge.metric) + 1:1;
                        nextMetric = Math.max(nextMetric,3);
                        nextLvlBadge = _.find(allBadges,{badgeCategory:'overall',badgeType:'level',cycleType:'monthly',metricValue:nextMetric});
                        return $http.post('/api/levels/index').then(function(levels){
                            levels=levels.data;
                            var level = _.find(levels,{levelNumber:nextMetric});
                            var reqPercentage = level.lowerLimit;
                            var reqAchievement = monthlyKpiData.target.value * reqPercentage /100;
                            reqAchievement = reqAchievement - monthlyKpiData.achievedValue.value;
                            finalData.badge.push({text:'Achieve <span class="big-number">' +$filter('number')(reqAchievement,0) + '</span> '+monthlyKpiData.unit +' in the next <span class="big-number">' + daysRemaining + '</span> days to earn the <span class="big green">'+nextLvlBadge.name+'</span>.'});
                        });
                    }


                    var kpiBadge = _.find(monthlyUserAnalytics.badges,{badgeCategory:'business',badgeType:'kpi',from:monthlyKpiData.kpiId});
                    if(!kpiBadge || kpiBadge.metric != 'top1'){
                        var nextBadge, metricValue;
                        var leagueKpiData = _.find(monthlyLeagueAnalytics.kpiData,{kpiId:monthlyKpiData.kpiId});
                        if(kpiBadge && kpiBadge.metric == 'topQuartile'){
                            nextBadge = _.find(allBadges,{badgeCategory:'business',badgeType:'kpi',cycleType:'monthly',from:monthlyKpiData.kpiId,metric:'topDecile'});
                            metricValue = leagueKpiData.percentageCompleted.topDecile;
                        }else if(kpiBadge && kpiBadge.metric == 'topDecile'){
                            nextBadge = _.find(allBadges,{badgeCategory:'business',badgeType:'kpi',cycleType:'monthly',from:monthlyKpiData.kpiId,metric:'top1'});
                            metricValue = leagueKpiData.percentageCompleted.max.value;
                        }else{
                            nextBadge = _.find(allBadges,{badgeCategory:'business',badgeType:'kpi',cycleType:'monthly',from:monthlyKpiData.kpiId,metric:'topQuartile'});
                            metricValue = leagueKpiData.percentageCompleted.topQuartile;
                        }
                        var pcRate = $rootScope.daysPassed?metricValue/ $rootScope.daysPassed: metricValue;
                        metricValue = pcRate * ($rootScope.daysPassed + daysRemaining);
                        var reqAchievement = monthlyKpiData.target.value * metricValue/100;
                        reqAchievement = reqAchievement - monthlyKpiData.achievedValue.value;
                        finalData.badge.push({text:'Achieve <span class="big-number">' +$filter('number')(reqAchievement,0) + '</span> '+monthlyKpiData.unit +' in the next <span class="big-number">' + daysRemaining + '</span> days to earn the <span class="big green">'+nextBadge.name+'</span>.'});
                    }

                    var activityBadges = _.filter(allBadges,{badgeCategory:'activity',badgeType:'kpi',from:monthlyKpiData.kpiId});
                    activityBadges = _.uniqBy(activityBadges,'activityId');
                    activityBadges.forEach(function(aBadge){
                        var myBadge = _.find(monthlyUserAnalytics.badges,{badgeCategory:'activity',badgeType:'kpi',activityId:aBadge.activityId});
                        if(!myBadge || myBadge.metric != 'top1'){
                            var nextBadge, metricValue;
                            var leagueActivityData = _.find(monthlyLeagueAnalytics.activityData,function(activityData){
                                return activityData.activityId == aBadge.activityId && activityData.kpiId == aBadge.from;
                            });
                            if(!weeklyUserAnalytics){
                                return;
                            }
                            var userActivityData = _.find(weeklyUserAnalytics.activityData,function(activityData){
                                return activityData.activityId == aBadge.activityId && activityData.kpiId == aBadge.from;
                            });
                            if(myBadge && myBadge.metric == 'topQuartile'){
                                nextBadge = _.find(allBadges,{badgeCategory:'activity',badgeType:'kpi',activityId:aBadge.activityId,cycleType:'monthly',metric:'topDecile'});
                                metricValue = leagueActivityData.value.topDecile;
                            }else if(myBadge && myBadge.metric == 'topDecile'){
                                nextBadge = _.find(allBadges,{badgeCategory:'activity',badgeType:'kpi',activityId:aBadge.activityId,cycleType:'monthly',metric:'top1'});
                                metricValue = leagueActivityData.value.max.value;
                            }else{
                                nextBadge = _.find(allBadges,{badgeCategory:'activity',badgeType:'kpi',activityId:aBadge.activityId,cycleType:'monthly',metric:'topQuartile'});
                                metricValue = leagueActivityData.value.topQuartile;
                            }
                            var rate = $rootScope.daysPassed?metricValue/ $rootScope.daysPassed: metricValue;
                            var reqValue = rate * ($rootScope.daysPassed + daysRemaining);
                            reqValue = reqValue - userActivityData.value.value;
                            finalData.badge.push({text:'Make <span class="big-number">' +$filter('number')(reqValue,0) + '</span> '+userActivityData.shortName +' in the next <span class="big-number">' + daysRemaining + '</span> days to earn the <span class="big green">'+nextBadge.name+'</span>.',
                                icon:p0.config['activityIcons_'+userActivityData.fieldName]
                            });
                        }
                    });
                }
                return;
            });
        };

        this.getLeadsSummary = function (startDate, endDate, period) {
            return PipelineService.getPipelineScorecardData (self.me._id, p0.currentMonthlyCycle._id)
                .then(function(pipelineScorecardData){
                    pipelineScorecardData = pipelineScorecardData.data;
                    // $scope.pipelineData = pipelineScorecardData;

                    return $http.post('/api/leads/getActionSummary', {fromDate:startDate, toDate:endDate}).then(function(summary){
                        summary=summary.data;
                        //var summaryHtml = [];
                        var you = 'you', teamLeader = 'Team leader', have ='have';
                        if(self.me.systemRole == 'manager'){
                            you = 'your team';
                            teamLeader = 'Me';
                            have = 'has';
                        }

                        var pipeLine = summary.leadsAddedByIFE / summary.activeLeads;
                        var swot, swotText;
                        if(pipeLine >= 1){
                            swot = 'swot-green';
                            swotText = 'STRONG';
                        }else if(pipeLine < 1 && pipeLine >= 0.8){
                            swot = 'swot-blue';
                            swotText = 'MODERATE';
                        }else if(pipeLine < 0.8 && pipeLine >= 0.5){
                            swot = 'swot-amber';
                            swotText = 'WEAK';
                        }else{
                            swot = 'swot-red';
                            swotText = 'VERY WEAK';
                        }

                        var summaryHtml2 = [];
                        var line1 = '<i class="fa fa-location-arrow fa-5" aria-hidden="true"></i>&nbsp;&nbsp;<span class="big">Action till date</span>';
                        var line11;
                        if((summary.calls + summary.meetings) > 0){
                            var was = 'was';
                            if((summary.calls + summary.meetings) > 1) was = 'were';
                            line11 = 'Total '+self.numberLabelHelper(summary.calls+summary.meetings, 'interaction')+' ('+summary.calls+' '+self.labelHelper(summary.calls, 'call')+', '+ summary.meetings+' '+ self.labelHelper(summary.meetings, 'meeting')+') '+ was + ' made '+period+' with '+  self.numberLabelHelper(summary.customers, 'customer')+'.';
                        }else{
                            line11 = 'Total '+'<span class="big-number">0</span>'+' interactions were made.';
                        }
                        var line12;
                        line12 = self.numberHelper(summary.submissions) +' <span class="big swot-green">'+self.labelHelper(summary.submissions, 'SUBMISSION')+'</span> '+period+'.';
                        if(summary.submissions){
                            line12 = line12 +'<ul class="lvl2-list"><li>'+ self.numberHelper(summary.approvedAftSub)+'<span class="big swot-green"> Approved.</span></li>';
                            line12 = line12 +'<li>'+self.numberHelper(summary.rejectedAftSub)+' <span class="big swot-amber"> Rejected.</span></li>';
                            line12 = line12 +'<li>'+self.numberHelper((summary.submissions - summary.approvedAftSub - summary.rejectedAftSub))+'<span class="big swot-blue"> Pending '+ self.labelHelper((summary.submissions - summary.approvedAftSub - summary.rejectedAftSub),'update') +'.</span></li></ul>';
                        }
                        var line13;
                        line13 = self.numberLabelHelper(summary.leadsAddedByIFE, 'lead')+' <span class="big swot-green">CREATED </span>'+period+'.';
                        line1 = line1 + '<ul class="lvl1-list"><li>'+line11+'</li>';
                        line1 = line1 + '<li>'+line12+'</li>';
                        line1 = line1 + '<li>'+line13+'</li></ul>';
                        summaryHtml2.push(line1);

                        var line2 = '<i class="fa fa-cart-arrow-down fa-5" aria-hidden="true"></i>&nbsp;&nbsp;Leads recieved</span> <ul class="lvl1-list"><li>';
                        if((summary.cciLeads + summary.managerLeads) > 0){
                            line2 = line2 + self.numberLabelHelper((summary.cciLeads + summary.managerLeads),'lead') + ' ' + self.wasHelper(summary.cciLeads + summary.managerLeads) + ' assigned ';
                            line2 = line2 + '('+ summary.cciLeads+' from CCI, '+ summary.managerLeads+' from '+ teamLeader +') '+period+'.';
                        }else{
                            line2 = self.numberHelper(0)+' leads were assigned.';
                        }
                        line2 = line2+'</li></ul>';
                        summaryHtml2.push(line2);


                        var line3 = '<i class="fa fa-flask fa-5" aria-hidden="true"></i>&nbsp;&nbsp;' +
                            '<span class="big">Pipeline &nbsp;' +
                            '<span ng-click="showPipelineInfo()">' +
                                '<i class="fa fa-info-circle fa-lg blue pipeline-info" aria-hidden="true"></i>'+
                            '</span>'
                        + '</span> <ul class="lvl1-list"><li>';
                        line3 = line3 + 'Current pipeline is '+ '<span class="big '+ swot +'">'+ swotText+ '</span>'+'.';
                        line3 = line3 +'</li></ul>';

                        var products = _.cloneDeep(p0.myProducts)

                        line3 += '<ul><li> <table style="border:0"><tbody>';
                        _.each(products, function(product){
                            var pipeline = pipelineScorecardData[product._id];
                            var productHtml = '<tr>' +
                                '<th><h3 class="no-margin">'+ pipeline.shortName + ' Overall' +'</h3></th>'+
                                '<th>' +
                                '<div layout="column">' +
                                    '<div><span>' +
                                        '<span class="pipeline-value no-margin big-number">' +
                                            pipeline.totalPipelineValue.toFixed(0) +
                                        "</span>&nbsp;" +
                                        '<span class="no-margin"  style="margin:0">('+ product.unit +')</span>' +
                                    "</span></div>" +
                                    '<div><span>' +
                                        '<span class="no-margin largerFont">('+ pipeline.totalConversionRatio.toFixed(1) +'</span><span class="smallerFont">%</span>,&nbsp;' +
                                        '<span class="no-margin largerFont">' + pipeline.totalSubmissionLeads +'<span class="smallerFont">#</span>)</span>' +
                                    '</span></div>'+
                                '</div>'+
                            '</th>'+
                            '</tr>';
                            _.each(pipeline.stages, function(stage){
                                productHtml += '<tr>' +
                                    '<th><h4 class="no-margin">'+ stage.stage.name +'</h4></th>'+
                                    '<th>' +
                                    '<div layout="column">' +
                                        '<div><span>' +
                                            '<span class="pipeline-value no-margin big-number">' +
                                                stage.pipelineValue.value.toFixed(0) +
                                            "</span>&nbsp;" +
                                            '<span class="no-margin"  style="margin:0">('+ product.unit +')</span>' +
                                        "</span></div>" +
                                        '<div><span>' +
                                            '<span class="no-margin largerFont">('+ stage.conversionRatioFinal.value +'<span class="smallerFont">%</span></span>,&nbsp;' +
                                            '<span class="no-margin largerFont">' + stage.leadCount.value +'<span class="smallerFont">#</span>)</span>' +
                                        '</span></div>'+
                                    '</div>'+
                                '</th>'+
                                '</tr>';
                            })
                            line3 += productHtml;
                        })
                        line3 += '</tbody></table></li></ul>';
                        summaryHtml2.push(line3);



                        /*var line1;
                        line1 = 'Currently '+ you + ' '+ have +' '+ self.numberHelper(summary.activeLeads) +' active '+ self.labelHelper(summary.activeLeads, 'lead');
                        line1 = line1 + ' of which '+ self.numberLabelHelper(summary.leadsAddedByIFE, 'lead')+' '+self.inflect(summary.leadsAddedByIFE) + ' added by '+ you+'.';
                        line1 = line1 + ' Current pipeline is '+ '<span class="big '+ swot +'">'+ swotText+ '</span>'+'.';
                        summaryHtml.push(line1);

                        var line2;
                        if((summary.cciLeads + summary.managerLeads) > 0){
                            line2 = self.numberLabelHelper((summary.cciLeads + summary.managerLeads),'lead') + ' ' + self.wasHelper(summary.cciLeads + summary.managerLeads) + ' assigned ';
                            line2 = line2 + '('+ summary.cciLeads+' from CCI, '+ summary.managerLeads+' from '+ teamLeader +') '+period+' to ' + you + ' of which ';
                            line2 = line2 + '<ul class="list"><li>'+ self.numberLabelHelper(summary.submissions, 'lead')+ ' reached '+'<span class="big swot-green">SUBMISSION</span>'+', of which ';
                            line2 = line2 + self.numberLabelHelper(summary.approvedAftSub, 'lead')+' '+ '<span class="big swot-green">APPROVAL</span>' +', '+ self.numberLabelHelper(summary.rejectedAftSub, 'lead')+' '+'<span class="big swot-amber">REJECTION</span>'+' and ';
                            line2 = line2 + self.numberHelper((summary.submissions - summary.approvedAftSub -  summary.rejectedAftSub)) + '<span class="big swot-blue"> PENDING '+  self.labelHelper((summary.submissions - summary.approvedAftSub -  summary.rejectedAftSub), 'UPDATE') + '</span></li>';
                            line2 = line2 +  '<li>'+self.numberLabelHelper(summary.notEligible + summary.notInterested, 'lead')+ ' '+ self.wasHelper(summary.notEligible + summary.notInterested)+ ' '+'<span class="big swot-red">FAILED</span>'+' due to customer\'s lack of interest/eligibility ';
                            line2 = line2 + ' ('+ Math.round((summary.notEligible + summary.notInterested)/(summary.cciLeads + summary.managerLeads) * 100) +'% fail rate).</li></ul>';
                        }else{
                            line2 = self.numberHelper(0)+' leads were assigned.';
                        }
                        summaryHtml.push(line2);

                        var line3;
                        if((summary.calls + summary.meetings) > 0){
                            var was = 'was';
                            if((summary.calls + summary.meetings) > 1) was = 'were';
                            line3 = 'Total '+self.numberLabelHelper(summary.calls+summary.meetings, 'interaction')+' ('+summary.calls+' '+self.labelHelper(summary.calls, 'call')+', '+ summary.meetings+' '+ self.labelHelper(summary.meetings, 'meeting')+') '+ was + ' made '+period+' with '+  self.numberLabelHelper(summary.customers, 'customer')+'.';
                        }else{
                            line3 = 'Total '+'<span class="big-number">0</span>'+' interactions were made.';
                        }
                        summaryHtml.push(line3);*/



                        /*var line4 = self.numberLabelHelper(summary.leadsAdded.byMe, 'lead') + ' '+self.wasHelper(summary.leadsAdded.byMe) +' added by me';
                        if(summary.leadsAdded.byIFE == 0 || summary.leadsAdded.byIFE > 0) {
                            line4 = line4 + ', and '+self.numberLabelHelper(summary.leadsAdded.byIFE, 'lead') + ' by frontline agents';
                        }
                        line4 = line4 +'.';
                        summaryHtml.push(line4);


                        var your = 'your';
                        if(self.me.systemRole == 'manager') your = 'the reportee\'s';
                        var line5 = '<span class="big-number">'+ summary.selfGenerated +  '%</span>'+ ' of '+ your+' active leads are self-generated';
                        summaryHtml.push(line5);

                        if(summary.submissions){
                            var line6 = 'Out of '+ self.numberLabelHelper(summary.submissions, 'submission') +', '+ self.numberHelper(summary.rejectedAftSub) +' rejected and '+ self.numberHelper(summary.submissions - summary.rejectedAftSub)+' pending '+self.labelHelper(summary.submissions - summary.rejectedAftSub, 'update')+'.';
                            summaryHtml.push(line6);
                        }*/
                        var finalDate = {};
                        finalDate.summary = summaryHtml2;
                        return finalDate;
                    });


                })

        };

        this.numberLabelHelper = function (count, word) {
            return '<span class="big-number">'+ count+  '</span>'+' '+$filter('inflect')(word,count);
        };
        this.numberHelper = function (count) {
            return '<span class="big-number">'+ count+  '</span>';
        };
        this.labelHelper = function (count, word) {
            return $filter('inflect')(word,count);
        };
        this.wasHelper = function (count) {
            var was = 'was';
            if(count != 1) was = 'were';
            return was;
        };
        this.inflect = function (count) {
            var is = 'is';
            if(count != 1) is = 'are';
            return is;
        }
    });
