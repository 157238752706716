'use strict';

angular.module('bitnudgeApp')
    .service('BadgeService', function ($http, $q) {
		
        this.getAllBadges = function () {
            var deferred = $q.defer();
            let api = '/api/badges/getAllBadges';
			var badges;
			$http.post(api).then(function (result) {
				if(result.data.status){
					badges = result.data.data;
					deferred.resolve(badges);
				}
			}, function (error) {
				deferred.reject(error);
			});
			return deferred.promise;
        }
    });
