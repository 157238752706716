const autoImportNgTemplateLoaderTemplate1 = require('../html/map/map.html');

angular.module('bitnudgeApp')
    .directive('mapsDir', function () {
        return {
            restrict: 'EA',
            scope: {},
            controller: 'mapsController',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
