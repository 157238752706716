'use strict';
angular.module('bitnudgeApp')
    .service('GetDeltaImageService', function () {
        this.getDeltaImage = function(number1, number2) {
            var deltaRes = (number1-number2);
            if (deltaRes < 0) {
                this.deltaImage = "../images/downarrow_dark.png";
            }else if(deltaRes > 0) {
                this.deltaImage = "../images/uparrow_dark.png";
            }else {
                this.deltaImage = "../images/equal_icon.png";
            }
            return this.deltaImage;
        };

        this.getDelta = function(number1, number2) {
            this.deltaResult = Math.round((number1 - number2),2);
            return Math.abs(this.deltaResult);
        };
    });
