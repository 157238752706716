const autoImportNgTemplateLoaderTemplate1 = require('../html/badgeCorner/badgeCorner.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('badgeCorner', function(){
        return {
            name: 'badgeCorner',
            scope: {
                selectedPlayer:     '=',
                numberOfShelf:      '=',
                selectedJobrole:    '=',
                selectedCycle:      '=',
                selectedKpi: '=',
                displayLockedBadges:'=',
                userKpis:    '=',
                isWeekly:           '='
            },
            controller: 'badgeCornerCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });

angular.module('bitnudgeApp').directive('showBlink',function($timeout){
    return{
        controller: function($scope, $element) {
            function showElement() {
                $element.fadeTo(600, 0.3, "swing")
                $timeout(hideElement, 1000);
            }

            function hideElement() {
                $element.fadeTo(600, 1, "swing")
                $timeout(showElement, 1000);
            }
            showElement();
        }
    }
});

