const autoImportNgTemplateLoaderTemplate1 = require('../html/admin/old/admin.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/admin/old/cycles.html');
const autoImportNgTemplateLoaderTemplate3 = require('../html/admin/old/listCycles.html');
const autoImportNgTemplateLoaderTemplate4 = require('../html/admin/old/addCycle.html');
const autoImportNgTemplateLoaderTemplate5 = require('../html/admin/old/editCycle.html');
const autoImportNgTemplateLoaderTemplate6 = require('../html/admin/old/showCycle.html');
const autoImportNgTemplateLoaderTemplate7 = require('../html/admin/old/campaigns.html');
const autoImportNgTemplateLoaderTemplate8 = require('../html/admin/old/listCampaigns.html');
const autoImportNgTemplateLoaderTemplate9 = require('../html/admin/old/addCampaign.html');
const autoImportNgTemplateLoaderTemplate10 = require('../html/admin/old/editCampaign.html');
const autoImportNgTemplateLoaderTemplate11 = require('../html/admin/old/showCampaign.html');
const autoImportNgTemplateLoaderTemplate12 = require('../html/swot.html');
const autoImportNgTemplateLoaderTemplate13 = require('../html/admin/old/listSwot.html');
const autoImportNgTemplateLoaderTemplate14 = require('../html/admin/old/addSwot.html');
const autoImportNgTemplateLoaderTemplate15 = require('../html/notifications.html');
const autoImportNgTemplateLoaderTemplate16 = require('../html/admin/old/listNotification.html');
const autoImportNgTemplateLoaderTemplate17 = require('../html/admin/old/addNotification.html');

'use strict';

angular.module('bitnudgeApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('admin', {
                url: '/admin',
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'AdminCtrl'
            })

            //cycles routes starts
            .state('admin.cycles', {
                url: '/cycles',
                templateUrl: autoImportNgTemplateLoaderTemplate2,
                controller: 'cyclesCtrl'
            })

            .state('admin.listCycles', {
                url: '/listCycles',
                templateUrl: autoImportNgTemplateLoaderTemplate3,
                controller: 'cyclesCtrl'
            })

            .state('admin.addCycle', {
                url: '/addCycle',
                templateUrl: autoImportNgTemplateLoaderTemplate4,
                controller: 'cyclesCtrl'
            })

            .state('admin.editCycle', {
                //url: '/editCycle?cycleId',
                url: '/editCycle/:cycleId',
                templateUrl: autoImportNgTemplateLoaderTemplate5,
                controller: 'cyclesCtrl'
            })

            .state('admin.showCycle', {
                //url: '/editCycle?cycleId',
                url: '/showCycle/:cycleId',
                templateUrl: autoImportNgTemplateLoaderTemplate6,
                controller: 'cyclesCtrl'
            })
            //cycles routes ends

            //campaigns routes starts
            .state('admin.campaigns', {
                url: '/campaigns',
                templateUrl: autoImportNgTemplateLoaderTemplate7,
                controller: 'adminCampaignsCtrl'
            })

            .state('admin.listCampaigns', {
                url: '/listCampaigns',
                templateUrl: autoImportNgTemplateLoaderTemplate8,
                controller: 'adminCampaignsCtrl'
            })

            .state('admin.addCampaign', {
                url: '/addCampaign',
                templateUrl: autoImportNgTemplateLoaderTemplate9,
                controller: 'adminCampaignsCtrl'
            })

            .state('admin.editCampaign', {
                //url: '/editCycle?cycleId',
                url: '/editCampaign/:campaignId',
                templateUrl: autoImportNgTemplateLoaderTemplate10,
                controller: 'adminCampaignsCtrl'
            })

            .state('admin.showCampaign', {
                //url: '/editCycle?cycleId',
                url: '/showCampaign/:campaignId',
                templateUrl: autoImportNgTemplateLoaderTemplate11,
                controller: 'adminCampaignsCtrl'
            })
            //campaigns routes ends

            //goals routes start todo: investigate
            // .state('admin.goals',{
            //     url:'/listGoals',
            //     templateUrl:'../html/admin/old/listGoals.html',
            //     controller:'adminGoalsCtrl'
            // })
            //goals routes end

            //swot routes starts
            .state('admin.swot', {
                    url: '/swot',
                templateUrl: autoImportNgTemplateLoaderTemplate12,
                controller: 'swotCtrl'
            })

            .state('admin.listSwot', {
                url: '/listSwot',
                templateUrl: autoImportNgTemplateLoaderTemplate13,
                controller: 'swotCtrl'
            })

            .state('admin.addSwot', {
                url: '/addSwot',
                templateUrl: autoImportNgTemplateLoaderTemplate14,
                controller: 'swotCtrl'
            })

            //.state('admin.editCampaign', {
            //    //url: '/editCycle?cycleId',
            //    url: '/editCampaign/:campaignId',
            //    templateUrl: '../html/admin/editCampaign.html',
            //    controller: 'adminCampaignsCtrl'
            //})
            //
            //.state('admin.showCampaign', {
            //    //url: '/editCycle?cycleId',
            //    url: '/showCampaign/:campaignId',
            //    templateUrl: '../html/admin/showCampaign.html',
            //    controller: 'adminCampaignsCtrl'
            //})
        //swot routes ends

            //notification routes starts
            //todo: definitely invalid path fix
            .state('admin.notification', {
                url: '/notification',
                templateUrl: autoImportNgTemplateLoaderTemplate15,
                controller: 'notificationCtrl'
            })

            .state('admin.listNotification', {
                url: '/listNotification',
                templateUrl: autoImportNgTemplateLoaderTemplate16,
                controller: 'notificationCtrl'
            })

            .state('admin.addNotification', {
                url: '/addNotification',
                templateUrl: autoImportNgTemplateLoaderTemplate17,
                controller: 'notificationCtrl'
            })

        //.state('admin.editCampaign', {
        //    //url: '/editCycle?cycleId',
        //    url: '/editCampaign/:campaignId',
        //    templateUrl: '../html/admin/editCampaign.html',
        //    controller: 'adminCampaignsCtrl'
        //})
        //
        //.state('admin.showCampaign', {
        //    //url: '/editCycle?cycleId',
        //    url: '/showCampaign/:campaignId',
        //    templateUrl: '../html/admin/showCampaign.html',
        //    controller: 'adminCampaignsCtrl'
        //})
        //notification routes ends

    });
