'use strict';

angular.module('bitnudgeApp')
    .controller('LbCompleteListCtrl', function($scope, $http, toaster, $rootScope, $location, Auth, DTOptionsBuilder, DTColumnDefBuilder, social, p1) {
        switch($scope.completeType){
            case 'league':{
                $scope.dtColumnDefsForLB = [
                    DTColumnDefBuilder.newColumnDef(0).notSortable(),
                    DTColumnDefBuilder.newColumnDef(1).notSortable(),
                    DTColumnDefBuilder.newColumnDef(2).notSortable(),
                    DTColumnDefBuilder.newColumnDef(3).notSortable(),
                    DTColumnDefBuilder.newColumnDef(4).notSortable().notVisible(),
                    DTColumnDefBuilder.newColumnDef(5).notSortable().notVisible(),
                    DTColumnDefBuilder.newColumnDef(6).notSortable().notVisible(),
                    DTColumnDefBuilder.newColumnDef(7).notSortable().notVisible(),
                    DTColumnDefBuilder.newColumnDef(8).notSortable(),
                    DTColumnDefBuilder.newColumnDef(9).notSortable(),
                    DTColumnDefBuilder.newColumnDef(10).notSortable(),
                    DTColumnDefBuilder.newColumnDef(11).notSortable(),
                    DTColumnDefBuilder.newColumnDef(12).notSortable()
                ];
                $scope.headerLength=13;
                $scope.label = $scope.strings.POINTS.value;
                $scope.fieldName = 'rank';
                break;
            }
            case 'ms':{
                $scope.dtColumnDefsForLB = [
                    DTColumnDefBuilder.newColumnDef(0).notSortable(),
                    DTColumnDefBuilder.newColumnDef(1).notSortable(),
                    DTColumnDefBuilder.newColumnDef(2).notSortable(),
                    DTColumnDefBuilder.newColumnDef(3),
                    DTColumnDefBuilder.newColumnDef(4).notSortable().notVisible(),
                    DTColumnDefBuilder.newColumnDef(5).notSortable().notVisible(),
                    DTColumnDefBuilder.newColumnDef(6).notSortable().notVisible(),
                    DTColumnDefBuilder.newColumnDef(7).notSortable().notVisible(),
                    DTColumnDefBuilder.newColumnDef(8).notSortable(),
                    DTColumnDefBuilder.newColumnDef(9).notSortable(),
                    DTColumnDefBuilder.newColumnDef(10).notSortable(),
                    DTColumnDefBuilder.newColumnDef(11).notSortable(),
                    DTColumnDefBuilder.newColumnDef(12).notSortable()
                ];
                $scope.headerLength=6;
                $scope.label = 'Change in Rank';
                $scope.fieldName = '-value';
                break;
            }
            case 'peer':{
                $scope.dtColumnDefsForLB = [
                    DTColumnDefBuilder.newColumnDef(0).notSortable(),
                    DTColumnDefBuilder.newColumnDef(1).notSortable(),
                    DTColumnDefBuilder.newColumnDef(2).notSortable(),
                    DTColumnDefBuilder.newColumnDef(3).notSortable(),
                    DTColumnDefBuilder.newColumnDef(4).notSortable(),
                    DTColumnDefBuilder.newColumnDef(5).notSortable().notVisible(),
                    DTColumnDefBuilder.newColumnDef(6).notSortable().notVisible(),
                    DTColumnDefBuilder.newColumnDef(7).notSortable().notVisible(),
                    DTColumnDefBuilder.newColumnDef(8).notSortable().notVisible(),
                    DTColumnDefBuilder.newColumnDef(9).notSortable(),
                    DTColumnDefBuilder.newColumnDef(10).notSortable()
                ];
                $scope.headerLength=10;
                break;
            }
        }
        $scope.$watch('selectedCard', function(newValue, oldValue, scope) {
            if(newValue!=oldValue)
                scope.changeDatatableOptions('kpi');
            // scope.refreshCompleteData();
        });

        $scope.isPlayerLiked=function(player){
            if(player&&player.userId&& p1.userData[player.userId._id].liked)
                return "light-blue";
        };
        $scope.isPlayerFollowed=function(player){
            if(player&& player.userId && p1.userData[player.userId._id].followed)
                return "light-blue";
        };

        $scope.changeDatatableOptions = function(view) {
            var leagueRank;
            if($scope.selectedLeague&&$scope.selectedLeague.value=='bank')
                leagueRank=DTColumnDefBuilder.newColumnDef(1).notSortable().notVisible();
            else leagueRank=DTColumnDefBuilder.newColumnDef(1).notSortable();
            var type=$scope.completeType;
            var selectedCardType=$scope.selectedCard;
            var dtOption;
            switch(type) {
                case 'league':{
                    dtOption={
                        leagueRankIndex:1,
                        overall:{
                            allNs:true,
                            notVisible:[4,6,7,8]
                        },
                        business:{
                            allNs:true,
                            notVisible:[4,5,7,8]
                        },
                        activity:{
                            allNs:true,
                            notVisible:[4,5,6,8]
                        },
                        badge:{
                            allNs:true,
                            notVisible:[5,6,7,8]
                        },
                        kpi:{
                            allNs:true,
                            notVisible:[4,5,6,7]
                        },
                    }
                    break;
                }
                case 'ms':{
                    return;
                }
                case 'peer':{
                    dtOption={
                        leagueRankIndex:1,
                        overall:{
                            allNs:true,
                            notVisible:[5,7,8,9]
                        },
                        business:{
                            allNs:true,
                            notVisible:[5,6,8,9]
                        },
                        activity:{
                            allNs:true,
                            notVisible:[5,6,7,9]
                        },
                        badge:{
                            allNs:true,
                            notVisible:[6,7,8,9]
                        },
                        kpi:{
                            allNs:true,
                            notVisible:[5,6,7,8]
                        },
                    }
                    break;
                }
            }
            if(dtOption&&dtOption[selectedCardType]){
                var optionsArray=[],currentColumn=null;
                for(var i=0;i<$scope.headerLength;i++){
                    if(i==dtOption.leagueRankIndex)
                        optionsArray.push(leagueRank);
                    else{
                        currentColumn=DTColumnDefBuilder.newColumnDef(i).notSortable();
                        // optionsArray.push(currentColumn);
                    }
                }
                if(dtOption[selectedCardType].allNs)
                    optionsArray.forEach(function(option){
                        option.notSortable();
                    });
                if(dtOption[selectedCardType].notVisible)
                    dtOption[selectedCardType].notVisible.forEach(function(visibleIndex){
                        // optionsArray[visibleIndex].notVisible();
                    });
            }
        };

        $scope.socialAction = social.socialAction;

        $scope.compareSinglePlayer=function(playerObj){
            if(playerObj._id==Auth.getCurrentUser()._id)
                return toaster.pop('error', "Select", "You cannot Select Yourself");
            $rootScope.playersFromLB = [playerObj._id];
            $rootScope.comparePlayersFromLB = true;
            $rootScope.myComparison = true;
            $state.go('userFlow.analytics', {tabName: 'playerComparison', myComparison: $rootScope.myComparison});
        };
        $scope.LBCheckAction=function(player){
            if(player.userId._id==Auth.getCurrentUser()._id)
                toaster.pop('error', "Select", "You cannot Select Yourself");
        };
        $scope.getFontFromDelta=function(value){
            if(value>0)
                return 'movers-font';
            else return 'shakers-font';
        };
        $scope.getArrowFromDelta=function(value){
            if(value>0)
                return 'fa-long-arrow-up';
            else return 'fa-long-arrow-down';
        };
        $scope.removePlayerFromPeerGroup=function(playerId){
            if(playerId==Auth.getCurrentUser()._id)
                return toaster.pop('error','Peer Group','You cannot remove yourself');
            $http.post('/api/users/archive/peerGroup',{peerUserId:playerId}).then(function (message){
                message=message.data;
                toaster.pop('success',"Peer Group","Player removed from Peers");
                $scope.onRefreshData();
            });
        };
        //options could be used to pass additional options to this function
        //this function will call the stuff that is passed when the directive is called
        //through the on-complete-click option in html
        // $scope.completeListClick=function(type,options){
        // 	$scope.selectedCardModel=type;
        // 	if($scope.onCompleteClick&&typeof $scope.onCompleteClick=='function')
        //  	$scope.onCompleteClick(type,options);
        // };
    });
