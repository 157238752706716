'use strict';
angular.module('bitnudgeApp')
    .controller('toggleSwitchCtrl', function ($scope) {
        $scope.label = $scope.label1;
        $scope.toggle = function() {
            if ($scope.value) {
                $scope.label = $scope.label1;
            }
            else {
                $scope.label = $scope.label2;
            }
        }
    });
