'use strict'
angular.module('bitnudgeApp')
    .controller('competitiveCtrl', function ($scope, $http) {
        $http.post('/api/adminActions/getCompetitiveInfo').then(function(result){
                $scope.data = result.data.data.data;
        });
        $scope.salaryRange = [
            {_id:0, name: 'All'},
            {_id:1, name:'<= 8000 AED'},
            {_id:2, name:'<= 15000 AED and > 8000 AED'},
            {_id:3, name:'> 15000 AED'}
        ];
        $scope.salaries = $scope.salaryRange[0];
        $scope.reducingRateRange = [
            {_id:0, name: 'All'},
            {_id:1, name:'<= 5%'},
            {_id:2, name:'<= 10% and > 5%'},
            {_id:3, name:'> 10%'}
        ];
        $scope.reducingRates = $scope.reducingRateRange[0];
        $scope.flatRateRange = [
            {_id:0, name: 'All'},
            {_id:1, name:'<= 5%'},
            {_id:2, name:'<= 10% and > 5%'},
            {_id:3, name:'> 10%'}
        ];
        $scope.flatRates = $scope.flatRateRange[0];
        $scope.changeFlatRates = function(flatRates){
            $scope.flatRates = flatRates;
            switch ($scope.flatRates._id){
                case 0:
                    $scope.flatMin = 0;$scope.flatMax = 0;
                    break;
                case 1:
                    $scope.flatMin = 0;$scope.flatMax = 5;
                    break;
                case 2:
                    $scope.flatMin = 5;$scope.flatMax = 10;
                    break;
                case 3:
                    $scope.flatMin = 10;$scope.flatMax = 100;
                    break;
                default:break;
            }
        };
        $scope.changeReducingRates = function(reducingRates){
            $scope.reducingRates = reducingRates;
            switch ($scope.reducingRates._id){
                case 0:
                    $scope.reduceMin = 0;$scope.reduceMax = 0;
                    break;
                case 1:
                    $scope.reduceMin = 0;$scope.reduceMax = 5;
                    break;
                case 2:
                    $scope.reduceMin = 5;$scope.reduceMax = 10;
                    break;
                case 3:
                    $scope.reduceMin = 10;$scope.reduceMax = 100;
                    break;
                default:break;
            }
        }
        $scope.changeSalaryRange = function(salaries){
            $scope.salaries = salaries;
            switch ($scope.salaries._id){
                case 0:
                    $scope.minSalary = 0;$scope.maxSalary = 0;
                    break;
                case 1:
                    $scope.minSalary = 0;$scope.maxSalary = 8000;
                    break;
                case 2:
                    $scope.minSalary = 8000;$scope.maxSalary = 15000;
                    break;
                case 3:
                    $scope.minSalary = 15000;$scope.maxSalary = 10000000000;
                    break;
                default:break;
            }
        }


    });
