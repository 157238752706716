'use strict';
angular.module('bitnudgeApp')
    .controller('dailyGameplanDetailCtrl', function ($scope, $http, $rootScope, $mdDialog, parameters) {
        $scope.importantActivities = parameters.importantActivities;
        $scope.today = parameters.dailyGp[0];
        $scope.unit = parameters.unit;
        $scope.denom = parameters.denom;
        $scope.dailyGp = parameters.dailyGp;
        $scope.saveDailyGP = parameters.saveDailyGP;
        $scope.undo = parameters.undo;
        $scope.dailyActivityRate = parameters.dailyActivityRate;
        $scope.deficit = parameters.deficit;
        $scope.calculateDailyActivityRate = parameters.calculateDailyActivityRate;
        $scope.dailyGp.forEach(function (daily) {
            $scope.importantActivities.forEach(function (activity) {
                var activityData = _.find(daily.activityData, {kpiId:daily.kpiData.kpiId , activityId: activity._id});
                var value = 0;
                if(activityData){
                    value = activityData.value.value;
                }
                daily[activity.fieldName] = {value: value};
            });
        });
        $scope.onClose = function(){
            $mdDialog.hide();
        };
    });
