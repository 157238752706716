const autoImportNgTemplateLoaderTemplate1 = require('../html/lms/exportLeads.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/conversionRatioInfo.html');
const autoImportNgTemplateLoaderTemplate3 = require('../html/pipelineInfoModal.html');

'use strict';
angular.module('bitnudgeApp')
	.controller('pipelineCtrl', function ($scope, p0, Auth, $q, $mdDialog, PipelineService, $stateParams, $state, $http) {

		let self = this;
        let dailyCycle = p0.currentDailyCycle;
        $scope.PIPELINE_LABEL = p0.strings.PIPELINE_LABEL ? p0.strings.PIPELINE_LABEL.value : "Pipeline"
        $scope.allCycles = [];
        $scope.params = {};
        let showInPipelineExists = false;
        this.init = function()
        {
            $scope.isFrontline = false;
            $scope.selected = {};
            // $scope.selectedIndex = 0;
            $scope.me = Auth.getCurrentUser();
            $scope.showReporteeTable = false;
            $scope.isLeadCountViewSelected = false;
            $scope.currentMonthlyCycleId = p0.currentMonthlyCycle._id;
            $scope.selectedCycle = p0.currentMonthlyCycle;
            $scope.isCumulativeGraph = false;
            $scope.selectedStateIndex = 0;
            $scope.selectedAttribute;
            if($stateParams.userId){
                $http.post('api/users/getUserById',{userId:$stateParams.userId}).then(function(user){
                    user=user.data;
                    $scope.selectedUser = user;
                })
            }
            if($scope.me.systemRole == 'frontline'){
                $scope.isFrontline = true;
                $scope.selected.user = $scope.me;
                $scope.selected.jobRole = $scope.me.organizationalTag.jobRole;
                $scope.selected.division = p0.myDivisions[0];
                $scope.selected.playType = p0.myPlayTypes[0];
                $scope.selected.products = p0.myProducts;
                self.setProductDropdown(p0.myProducts);
            }
            let allCycles = _.filter(p0.campaignCycles, (cycle) =>  {
                return cycle.startDate <= dailyCycle.startDate && cycle.endDate >= dailyCycle.endDate;
            });
            allCycles.unshift(p0.currentMonthlyCycle);
            $scope.allCycles = allCycles;

			PipelineService.getLastPipelineCronStatus().then(function (lastSync) {
				if(lastSync.status && lastSync.data.success && lastSync.data.success.lastUpdatedAt){
					$scope.lastSyncDate = new Date(lastSync.data.success.lastUpdatedAt);
				}
			});
        };

        $scope.onReporteeChange = function (reportee) {
            $scope.selected.user = reportee;
            $scope.selected.jobRole = $scope.selected.user.organizationalTag.jobRole;
            $scope.isSeniorManager = reportee.roleType == 'seniorManager';
            self.setProductDropdown(p0.myProducts)
        };

        $scope.onDivisionChange = function (division) {
            self.selectedDivision = division;
        };
		$scope.onRoleTypeChange = function(role){
			if(role._id === 'manager'){
				$scope.params.reporteeLabel = p0.strings.FRONTLINE.value;
			}else if(role._id === 'seniorManager'){
				$scope.params.reporteeLabel = p0.strings.MANAGER.value;
			}else{
				$scope.params.reporteeLabel = 'Reportee';
			}
		};

        $scope.onPlayTypeChange = function (playType) {
            self.selectedPlayType = playType;
        };

        $scope.incrementCounter = function(){
            var columnIndex = $scope.selectedIndex;
            var length = $scope.pipelineData.stages.length;
            columnIndex = (columnIndex+1)%length;
            $scope.onColumnClick(columnIndex);
        }
        $scope.decrementCounter = function(){
            var columnIndex = $scope.selectedIndex;
            var length = $scope.pipelineData.stages.length;
            if(columnIndex === 0)
                columnIndex = $scope.pipelineData.stages.length;
            columnIndex = (columnIndex-1)%length;
            $scope.onColumnClick(columnIndex);
        }

        $scope.onColumnClick = function(columnIndex){
            $scope.selectedIndex = columnIndex;
            if(columnIndex === -1)
                return;
            if($scope.stages){
                $scope.selectedStage = $scope.stages[columnIndex];
            }
		}
		$scope.onColumnClickRedirect = function(columnIndex){
            $scope.selectedIndex = columnIndex;
            if(columnIndex === -1)
                return;
            if($scope.stages){
                let params = {
                    productId : $scope.selectedProduct._id,
                    stageCode :{
                        name : $scope.stages[columnIndex].name,
                        stageUid : $scope.stages[columnIndex].uid
                    },
                    userId : $scope.selected.user._id,
                    // startDate : p0.currentMonthlyCycle.startDate,
                    // endDate :  p0.currentMonthlyCycle.endDate
                }
                let isCampaignCycle = $scope.selectedCycle.cycleType == 'campaign';
                if(isCampaignCycle){
                    let campaign = _.find($scope.campaigns, {cycleId:$scope.selectedCycle._id});
                    if(campaign){
                        params.campaignCode = campaign.campaignCode
                    }
                }
                $state.go('lms.listLead', params)
            }
        }

        /**
         * download the pipeline data on click
         */
        $scope.downloadPipelineData = function(ev) {
            const extraParams = {
                currentMonthlyCycle: {
                    startDate: p0.currentMonthlyCycle.startDate,
                    endDate: p0.currentMonthlyCycle.endDate
                }
            };
            extraParams.selectedCycle = {
                startDate: p0.currentMonthlyCycle.startDate,
                endDate: p0.currentMonthlyCycle.endDate
            };
            extraParams.productId = $scope.selectedProduct._id;
            const stageCode = {
                name : $scope.selectedStage.name,
                stageUid : $scope.selectedStage.uid
            }
    
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'leadExportCtrl',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                fullscreen: true,// Only for -xs, -sm breakpoints.
                resolve: {
                    parameters: function () {
                        const category = 'myLeads';
                        const userId = $scope.selected.user._id;
                        return {
                            filterObj: {
                                stageCode
                            },
                            extraParams: extraParams,
                            sortingObj: $scope.sortingObj,
                            view: 'leads',
                            userId: userId,
                            category,
                            pipelineContext: true
                        }
                    }
                }
            })
        }

        $scope.onProductChange = function(selectedProduct){
            $scope.selectedProduct = selectedProduct;
            self.getPipelineData($scope.selected.user._id, $scope.currentMonthlyCycleId, $scope.selectedProduct._id);
        }

        $scope.onCycleChange = function(cycle){
            $scope.currentMonthlyCycleId = cycle._id;
            $scope.selectedCycle = cycle;
            self.getPipelineData($scope.selected.user._id, $scope.currentMonthlyCycleId, $scope.selectedProduct._id);
        }

        $scope.showConversionRatioInfo = function(){
            const index = $scope.selectedIndex
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate2,
                controller: function ($scope, conversionRatio, timeSpent, firstStage, nextStage) {
                    $scope.conversionRatio = conversionRatio;
                    $scope.timeSpent = timeSpent;
                    $scope.firstStage = firstStage;
                    $scope.nextStage = nextStage;
                    $scope.onClose = function () {
                        $mdDialog.hide();
                    }
                },
                clickOutsideToClose:true,
                resolve: {
                    timeSpent:    function(){return   $scope.pipelineData.stages[index].timeToAct.value;},
                    conversionRatio:   function(){return   $scope.pipelineData.stages[index].conversionRatioNext.value;},
                    firstStage:   function(){return   $scope.pipelineData.stages[index].stage.name;},
                    nextStage:   function(){return   $scope.pipelineData.stages[index+1].stage.name;}
                }
            });

        }

        $scope.showPipelineInfo = function () {
            var startSubmissionStage = $scope.pipelineData.stages[0];
            var lastStage = $scope.pipelineData.stages[$scope.pipelineData.stages.length - 1];
            var netPipelineFormula = "";
            var statesLength = $scope.pipelineData.stages.length;
            $scope.pipelineData.stages.forEach(function(stage, index){
                netPipelineFormula  += "(Conversion Ratio of " + stage.name + " to " + lastStage.name + " stage * Its Pipeline Value)";
                if(index <  statesLength - 1 ){
                    netPipelineFormula += " + ";
                }
            });

            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate3,
                controller: function ($scope, firstStage, lastStage, netPipelineFormula) {
                    $scope.unit = p0.config.CURRENCY_UNIT || "IDR";
                    $scope.PIPELINE_LABEL = p0.strings.PIPELINE_LABEL ? p0.strings.PIPELINE_LABEL.value : "Pipeline"
                    $scope.firstStage = firstStage;
                    $scope.lastStage = lastStage;
                    $scope.netPipelineFormula = netPipelineFormula;
                    $scope.onClose = function () {
                        $mdDialog.hide();
                    }
                },
                clickOutsideToClose:true,
                resolve: {
                    firstStage:    function(){return   startSubmissionStage;},
                    lastStage:   function(){return   lastStage;},
                    netPipelineFormula:      function(){return   netPipelineFormula;},
                }
            });
        };

        this.setProductDropdown = function(products) {
            if(p0.config.agentOnBoardingProduct){
                products = products.filter(p => p.productCode !== p0.config.agentOnBoardingProduct)
            }
            $scope.products = _.cloneDeep(products);
            $scope.selectedProduct = $scope.products[0];
            $scope.showReporteeTable = false;
            self.getPipelineData($scope.selected.user._id, $scope.currentMonthlyCycleId, $scope.selectedProduct._id);
        }

        this.getPipelineData = function(userId, cycleId, productId){
            if(!(userId && cycleId && productId)){
                return;
            }
            $scope.selectedAttribute = $scope.isLeadCountViewSelected ? 'leadCount' : 'pipelineValue';
            let product = _.find(p0.allProducts, {_id:productId});
            return PipelineService.getPipelineData(userId, cycleId, productId)
                .then(function(pipelineData){
                    $scope.pipelineData = pipelineData.data.data[0][productId];
					self.mapStages($scope.pipelineData);
                    $scope.onColumnClick($scope.selectedIndex);
                    $scope.createPipelineGraph($scope.isLeadCountViewSelected);
                    if($scope.me.systemRole == 'frontline'){
                        $scope.showReporteeTable = false;
                        return;
                    }
                    return $http.post('/api/users/me/children', {userId})
                })
                .then(function(reportees){
                    if(!reportees)
                        return;
                    reportees=reportees.data;
                    if(reportees.length < 1){
                        $scope.showReporteeTable = false;
                        return;
					}
                    reportees = _.filter(reportees, function(reportee){
                        return _.includes(reportee.kpis, product.mainKpi);
                    });
                    if(reportees.length < 1){
                        $scope.showReporteeTable = false;
                        return;
                    }
                    $scope.showReporteeTable = true;
                    var reporteeIds = _.map(reportees, '_id');
                    var cycleId = $scope.currentMonthlyCycleId;

					$scope.reporteeData = {};
                    return PipelineService.getPipelineData(reporteeIds,cycleId, productId).then( function(result){
						if(result.data.data.length){
							result.data.data.forEach ( function(pipelineData){
								$scope.reporteeData[pipelineData.userId] = pipelineData
							})
							self.mapReporteeData(reportees, $scope.reporteeData);
						}
					},function(error){
						console.error("Error in fetching pipeline scorecard data for reportees of a user", error);
					})
                }).then(() => {
                    $http.post('/api/campaigns').then(function (campaigns) {
                        $scope.campaigns = campaigns.data;
                    });
                })
                .catch(function(err){
                    console.error(err,'--- I received error');
                })


        }

        this.mapStages = function(pipelineData){
            $scope.__pipelineData__ = Object.assign([],pipelineData)
            pipelineData.stages = pipelineData.stages.filter( state => {
                return state.stage.pipelineConfig && state.stage.pipelineConfig.calculatePipeline
            })
            $scope.stages = pipelineData.stages.map(function(state, index){
                return {
                    _id : index,
					name : state.stage.name,
                    uid : state.stage.uid,
                    showInPipeline:  state.stage.pipelineConfig ? state.stage.pipelineConfig.showInPipeline : false
                }
            });

            $scope.stages.forEach( (stage, index) => {
                if(stage.showInPipeline){
                    $scope.selectedStage = stage
                    $scope.selectedIndex = index
                    showInPipelineExists = true
                }
            });
            if(!$scope.selectedStage){
                $scope.selectedStage = $scope.stages[0]
            }
            if(!$scope.selectedIndex && $scope.selectedIndex !==0){
                $scope.selectedIndex = 0
            }
        }

        var createPipelineGraph = function( pipelineData ){
            if(!pipelineData || !pipelineData.stages){
                $scope.totalConversionRatio = 0;
                $scope.totalPipelineValue = 0;
            }
            $scope.pipelineColumns = self.calculatePipelineColumnHeights( pipelineData.stages );
            $scope.totalConversionRatio = $scope.pipelineData.totalConversionRatio.toFixed(1) || '0' ;
            $scope.totalPipelineValue =  self.calculateTotalPipelineValue( pipelineData.stages );
            $scope.conversionRatios = self.calculateFilterPercentage( pipelineData.stages );
            $scope.yAxisLabels = self.calculateYAxis(pipelineData.stages);
        }

        this.calculateTotalPipelineValue = function(pipelineItems){
            let pipelineValue = $scope.pipelineData.totalPipelineValue || 0 ;
            let stage = null
            if(showInPipelineExists){
               stage = pipelineItems.filter( item => {
                    return item.stage.pipelineConfig.showInPipeline
                })
            }
            if(stage && stage.length ===1 && stage[0].conversionRatioFinal){
                const value = parseFloat(stage[0].conversionRatioFinal.value)
                if(isNaN(value)){
                    value = 0
                }
                pipelineValue = (value * stage[0].pipelineValue.value) /100
            }
          return  pipelineValue

        }

        this.calculatePipelineColumnHeights = function( pipelineItems ){
            var pipelineItems = angular.copy(pipelineItems);
            var highestValue =  self.getMaxCountValue(pipelineItems);
            var attribute = $scope.selectedAttribute;

            return _.map(pipelineItems, function(item, index) {
                var height =  highestValue !== 0 ? self.getHeightValue(item[attribute].value, highestValue) : 0;
                var top = 100 - height;
                item.css = {
                    height : height + '%',
                    top: top + 40 + '%',
                    bottom: '-10%'
                }

                item.shortName = item.stage.name.indexOf( '(') !== -1 ? item.stage.name.substring( 0, item.stage.name.indexOf('(')).trim() : item.stage.name;
                if(item.shortName.split(' ').length > 1){
                    var words = item.shortName.split(' ');
                    item.names = [];
                    item.names = words.length % 2 === 0
                        ? [ words.slice(0, words.length/2).join(' '), words.slice((words.length/2), words.length).join(' ')]
                        : [ words.slice(0, words.length/2+1).join(' '), words.slice( (words.length/2)+1, words.length).join(' ')]
                }else{
                    item.names = [item.shortName];
                }
                return item;
            });
        }

        this.getMaxCountValue = function (pipelineItems){
            if(!pipelineItems || !pipelineItems.length) return 0;
            var attribute = $scope.selectedAttribute;
            if(!$scope.isCumulativeGraph){
                var value = pipelineItems.reduce(function(first, second){
                    if(!second[attribute].value){
                        return first;
                    }else{
                        return Math.max(first, second[attribute].value);
                    }
                }, pipelineItems[0][attribute].value || 0)
                return value;
            }
        }

        this.getHeightValue = function(value, highestValue){
            var height =  (parseFloat(value/highestValue))* 100;
            height = Number( height.toFixed(1));
            return height;
        }

        this.calculateFilterPercentage = function (pipelineItems){
            var result = pipelineItems.map(function(item, index){
                return {
                    percentage : item.conversionRatioNext.value,
                    timeToAct : item.timeToAct.value
                }
            });
            result.pop();
            return result;
        }

        this.calculateYAxis = function (pipelineData){
            var attribute = $scope.selectedAttribute;
            var highestValue = self.getMaxCountValue(pipelineData);
            var chartsMaxValue = highestValue / 0.8481012658227848;
            var yAxis = [6, 5, 4, 3, 2, 1, 0];
            var yAxisValues = _.map(yAxis, function (key) {
                var value = ( chartsMaxValue * 57.67529074667116 * key) / 395;
                value = value.toFixed().length > 2 ? value.toFixed() : value.toFixed(1);
                return $scope.isLeadCountViewSelected ? value : value;
            });
            // yAxisValues.pop();
            return yAxisValues;
        }

        this.mapUser = function ( user, userReporteeData, stages){
            var swotColor;
            if(userReporteeData.percentageCompleted < 50){
                swotColor = '#f7e5de';
            }else if ( userReporteeData.percentageCompleted >= 50 && userReporteeData.percentageCompleted < 100){
                swotColor = '#fff5d9';
            }else{
                swotColor = "#e1e8df";
            }
            return {
                reportee : user,
                achievedValue : userReporteeData.achievedValue,
                percentageCompleted : userReporteeData.percentageCompleted,
                totalPipelineValue : (userReporteeData && userReporteeData.totalPipelineValue) || 0,
                totalConversionRatio : (userReporteeData && userReporteeData.totalConversionRatio) || 0,
                pipelinePercentageCompleted : userReporteeData.pipelinePercentageCompleted,
                swotColor : swotColor,
                states : _.map(stages, function(state){
                    var stateInReportee = _.find(userReporteeData.stages, (stage) => {
						return stage && stage.stage.uid === state;
					});
                    if (stateInReportee && stateInReportee.pipelineValue) {
                        const denom = $scope.selectedProduct.denom || 1
                        stateInReportee.pipelineValue.value = stateInReportee.pipelineValue.value / denom
                    }
                    return stateInReportee ? stateInReportee : {
                        stage : state,
                        leadCount : { value : 0 },
                        conversionRatioFinal : { value : 0 },
                        pipelineValue : { value : 0 },
                        percentageCompleted : state.percentageCompleted
                    }
                })
            }
        }

        this.mapReporteeData = function (reportees, reporteeData){
            var productId = $scope.selectedProduct._id;
            $scope.reporteePipelineData = [];
            const repData = reporteeData[reportees[0]._id];
            var stages = repData[productId].stages.map(function(stage){
                return stage.stage.uid;
            })
            _.each(reportees, function(reportee){
                var userReporteeData = self.mapUser(reportee, $scope.reporteeData[reportee._id][productId], stages);
                $scope.reporteePipelineData.push(userReporteeData);
            });
        }


        $scope.createPipelineGraph = function(isLeadCountViewSelected){
            $scope.isLeadCountViewSelected = isLeadCountViewSelected
            $scope.selectedAttribute = isLeadCountViewSelected ? 'leadCount' : 'pipelineValue';
            createPipelineGraph($scope.pipelineData);
        }

        this.init();

    });
