'use strict';
angular.module('bitnudgeApp')
	.controller('setTriggerController', function (_, $scope, Auth, TriggerService, toaster, CustomerService, ProductService, BadgeService, p0, userAnalyticsService, ActivityService, WorkflowService, DocumentService, $filter) {
		const camelCase = $filter('camelCaseToHuman');
		$scope.getCurrentUser = Auth.getCurrentUser();
		//hash map created for the conditions to make it human readable
		var conditionsMap = new Map();
		conditionsMap.set('$eq', 'equal to');
		conditionsMap.set('$lt', 'less than');
		conditionsMap.set('$gt', 'greater than');
		conditionsMap.set('$gte', 'greater than or equals to');
		conditionsMap.set('$lte', 'less than or equals to');

		const ticketDateFields = {
			CREATED_AT: 'createdAt',
			UPDATED_AT: 'updatedAt',
			FOLLOWUP_DATE: 'followUpDate',
			REQUIRE_CLOSURE_DATE: 'requiredClosureDate',
			PROMISE_TO_PAY:'p2pDueDate',
			PAYMENT_DUE_ON:'paymentDueOn',
			NEXT_PAYMENT_DUE_DATE:'nextPaymentDueDate',
		}

		/**
		 * this trigger is going to have different set of options
		 * based on trigger field selected
		 */
		const exceptionDateTrigger = ['ticketMetadataDate'];
		// const
		const interactionCategories = [
			{name:'Leads', _id:'lead'},
			{name:'Tickets', _id: 'ticket'},
			{name:'Customers', _id: 'customer'}
		];

		$scope.interactionCategories = interactionCategories;
		$scope.selected = {
			kpis : p0.allKpis
		};

		ProductService.getProductConfigs().then(function (products) {
			$scope.products = products;
			$scope.product = $scope.products[0]
			BadgeService.getAllBadges().then(function (badges) {
				if (badges.length) {
					$scope.badges = badges;
					filterBadgesOnProduct();
				} else {
					toaster.pop('error', 'Badges', 'No Badges found')
				}
			}, function (error) {
				const message = error && error.message ? error.message : 'Error occured while fetching Badges.'
				toaster.pop('error', 'Badges', message)
				console.log(error)
			})
		}, function (error) {
			toaster.pop('error', 'Products', error.message)
			console.log(error)
		})

		DocumentService.getAllDocumentTypes().then( (documentTypes) => {
			$scope.documentTypes = documentTypes;
			$scope.changeDocumentType($scope.documentTypes[0])
		}).catch( (err) => {
			toaster.pop('error', 'Document Types', err.message)
			console.error(err)
		})


		var prepareOverallMetrics = function(userAnalytic){
			$scope.selected.overallMetrics = [];
			Object.keys(userAnalytic).forEach(key => {
				if (userAnalytic[key] instanceof Object && userAnalytic[key].value != undefined) {
					let obj = {
						key: key,
						name:  camelCase(key),
						value: userAnalytic[key].value,
						delta: userAnalytic[key].delta,
					};
					$scope.selected.overallMetrics.push(obj)
				}
			});
		};

		var prepareKPIMetrics = function(userAnalytic){
			let kpiData = userAnalytic.kpiData[0];
			$scope.selected.kpiMetrics = [];
			Object.keys(kpiData).forEach(key => {
				if (kpiData[key] instanceof Object && kpiData[key].value != undefined) {
					let obj = {
						key: key,
						name: camelCase(key),
						value: kpiData[key].value,
						delta: kpiData[key].delta,
					};
					$scope.selected.kpiMetrics.push(obj)
				}
			});
		}

		ActivityService.getAllActivities().then(function(result){
			$scope.activityObjs = result;
			$scope.activityObj = $scope.activityObjs[0]
			userAnalyticsService.getSingleActivityInUserAnalytic(p0.currentMonthlyCycle._id).then(function (result) {
				if (result) {
					$scope.userAnalytic = result;
					$scope.userAnalyticAcitivity = result.activityData[0];
					$scope.checkProductExist($scope.activityObj)
					prepareOverallMetrics(result);
					prepareKPIMetrics(result);
				} else
					toaster.pop('error', 'UserAnalytic', "No Useranalytic found")
			}, function (error) {
				toaster.pop('error', 'UserAnalytic', error.message)
			})
		},function(err){
			const message = err && err.message ? err.message : 'Error occured while fetching activities.'
			toaster.pop('error', 'Activity', message)
			console.log(err)
		})
		$scope.kpiSpecific = function (param) {
			if (param) {
				filterActivitesOnProducts()
			}
		}

		$scope.checkProductExist = function (selectedActivity) {
			if(selectedActivity.kpiSpecific){
				$scope.showProductOption = true;

				$scope.kpisProducts = []
				_.forEach(selectedActivity.kpiData, function (kpi) {
					var index = _.findIndex($scope.products, {
						mainKpi: kpi.kpiId
					});
					if(index >= 0)
						$scope.kpisProducts.push($scope.products[index])
				})
			}else{
				$scope.showProductOption = false;
			}

			$scope.metrics = []
			Object.keys($scope.userAnalyticAcitivity).forEach(function (key) {
				if ($scope.userAnalyticAcitivity[key] instanceof Object) {
					$scope.metrics.push({
						name: key,
						value: $scope.userAnalyticAcitivity[key].value,
						delta: $scope.userAnalyticAcitivity[key].delta,
					})
				}
			});

			$scope.metric = $scope.metrics[0]

		}

		function filterActivitesOnProducts() {

		}

		function filterBadgesOnProduct() {
			$scope.filteredBadges = _.filter($scope.badges, function (badge) {
				return badge.from == $scope.product.mainKpi
			})
		}

		$scope.productMetadataValue = 0;
		$scope.triggerNames = []
		var excelHeaders = []

		function getExcelHeaders() {
			CustomerService.getExcelHeader('customer').then(function (result) {
				if (result.status) {
					{
						excelHeaders = result.data.fields
						setTrigger($scope.triggerNameObj)
					}
				} else
					toaster.pop("error", "ExcelHeader Failed", "No Header present for the customer")
			}, function (err) {
				console.log(err)
			})
		}

		function getSubProductsMetadata() {

			CustomerService.getAllProductMetadata().then(function (result) {

				if (result.data) {
					{
						$scope.subProducts = _.filter(result.data, function (data) {
							if (data.metaData)
								return true
						});
						$scope.subProduct = $scope.subProducts[0]
						$scope.changeSubProduct($scope.subProduct)
					}
				} else
					toaster.pop("error", "Products Metadata Failed", "No metadata present for the products")
			}, function (err) {
					toaster.pop("error", "Products Metadata Failed", err)
				console.log(err)
			})
		}
		getSubProductsMetadata();

		$scope.changeSubProduct = function (product, name) {
			$scope.subProductMetadatas = product.metaData ? product.metaData : [];

			$scope.subProductMetadatas = _.filter($scope.subProductMetadatas, function (data) {
				if (name) {
					if (name === 'customerProductMetadataValue' && data.value === 'Number')
						return true
					else if (name !== 'customerProductMetadataValue' && data.value === 'Date')
						return true;
				} else {
					if (data.value === 'Date')
						return true;
				}
			})
			$scope.subProductMetadata = $scope.subProductMetadatas[0]
		}

		$scope.changeProduct = function (product, name) {
			if (name === 'badgeAlert')
				filterBadgesOnProduct();
			else if (name === 'metricAlert') {
				filterActivitesOnProducts();
			}
		}

		$scope.changeDocumentType = (type) => {
			$scope.documentPrerequisite = type.metaData.map( metadata => {
				if(metadata.prerequisite.type === 'date' && $scope.trigger && $scope.trigger.name === 'documentMetadataDate'){
					return{
						ipid: metadata.ipid,
						name: metadata.prerequisite.name,
						label: metadata.prerequisite.label,
						systemField: metadata.prerequisite.systemField,
						type: metadata.prerequisite.type
					}
				}else if(metadata.prerequisite.type === 'number' && $scope.trigger && $scope.trigger.name === 'documentMetadataNumber'){
					return{
						ipid: metadata.ipid,
						name: metadata.prerequisite.name,
						label: metadata.prerequisite.label,
						systemField: metadata.prerequisite.systemField,
						type: metadata.prerequisite.type
					}
				}
			})
			$scope.documentPrerequisite = $scope.documentPrerequisite.filter( e => e);
		}

		//fetching all the trigger configuration from the backend
		TriggerService.getAllTriggers().then(function (result) {
			$scope.triggerNames = _.map(result);
			$scope.triggerNameObj = $scope.triggerNames[0];
			if ($scope.triggerNameObj.name == "state_trigger") {
				getAllWorkflowDefinitions();
				setTrigger($scope.triggerNameObj)
			} else {
				getExcelHeaders();
			}


		}, function (error) {
			console.log(error)
		})


		//get all the Intervals possible for the triggers
		TriggerService.getTriggerIntervals().then(function (response) {
			$scope.intervals = response.data;
			$scope.interval = $scope.intervals[0];
		}, function (err) {
			console.log(err)
		})


		function setExcelTriggerFields() {

			if ($scope.trigger.name === 'customerMetadataDate') {
				if (excelHeaders.length) {
					$scope.trigger.triggerFieldLabels = [];
					excelHeaders.forEach(function (header) {
						if (header.isPrimary && header.typeOf === 'date') {
							$scope.trigger.triggerFieldLabels.push({
								'dbField': header.dbField,
								'displayName': header.displayName
							})
						}
					});
					$scope.triggerField = $scope.trigger.triggerFieldLabels[0]
				} else {
					toaster.pop('error', 'Alert Rule', 'Error in fetch the headers for the customer')
				}
			} else if ($scope.trigger.name === 'ticketMetadataDate') {
				$scope.trigger.triggerFieldLabels = [{
					displayName: "Creation Date",
					dbField: ticketDateFields.CREATED_AT,
					isFutureAvailable: false
				}, {
					displayName: "Updation Date",
					dbField: ticketDateFields.UPDATED_AT,
					isFutureAvailable: false
				},{
					displayName: "Due Date",
					dbField: ticketDateFields.REQUIRE_CLOSURE_DATE,
					isFutureAvailable: true
				},{
					displayName: "Promise to pay date",
					dbField: ticketDateFields.PROMISE_TO_PAY,
					isFutureAvailable: true,
					showPastAlso:true
				},{
					displayName: "Payment due on",
					dbField: ticketDateFields.PAYMENT_DUE_ON,
					isFutureAvailable: true
				},
				{
					displayName: "Next Payment Due Date",
					dbField: ticketDateFields.NEXT_PAYMENT_DUE_DATE,
					isFutureAvailable: true
				}
			
			];
				$scope.triggerField = $scope.trigger.triggerFieldLabels[0]
			}
		}

		function setTrigger(triggers) {

			$scope.triggers = triggers.config;
			$scope.triggerName = triggers.name;

			if ($scope.triggers.length) {
				$scope.trigger = $scope.triggers[0];
				$scope.CONST_TRIGGER = Object.assign({},$scope.trigger);
				//mapping the conditions to readable conditions

				$scope.triggers.forEach(trigger => {
					if (trigger.conditions) {
						trigger.readableConditions = []
						trigger.conditions.forEach(condition => {
							trigger.readableConditions.push(conditionsMap.get(condition))
						})
					}
				});
				setExcelTriggerFields();

			}
			setTriggerConf($scope.trigger)
		}

		//get the action based on the choosed trigger
		function getActionByTrigger(triggerName) {
			TriggerService.getActionByTrigger(triggerName).then(function (result) {
				$scope.actions = []
				$scope.actionsBackup = result;
				result.forEach(function (con) {
					con.config.forEach(function (c) {
						var conf = {}
						conf.actionType = c.actionType
						conf.label = c.label
						$scope.actions.push(conf)
					})
				});
				$scope.action = $scope.actions[0];
				setSelectedAction($scope.action, $scope.actionsBackup)
			}, function (err) {
				console.log(err)
			})
		}

		function getOutputsByTrigger(triggerName) {
			TriggerService.getOutputsByTrigger(triggerName).then(function (result) {
				$scope.outputs = []
				$scope.outputsBackup = result;
				result.forEach(function (con) {
					con.config.forEach(function (c) {
						var conf = {}
						conf.outputType = c.outputType
						conf.label = c.label
						$scope.outputs.push(conf)
					})
				});
				$scope.output = $scope.outputs[0];
				setSelectedOutput($scope.output, $scope.outputsBackup)
			}, function (err) {
				console.log(err)
			})
		}

		function setSelectedAction(action, actions) {
			actions.forEach(function (actn) {
				var data = _.find(actn.config, {
					actionType: action.actionType
				})
				if (data)
					$scope.actionSelected = data
			})
			if($scope.trigger.dummyTemplates){
				$scope.trigger.template = $scope.trigger.templates[$scope.actionSelected.actionName]
				$scope.trigger.dummyTemplate = $scope.trigger.dummyTemplates[$scope.actionSelected.actionName]
			}
		}

		function setSelectedOutput(output, outputs) {
			outputs.forEach(function (otp) {
				var data = _.find(otp.config, {
					outputType: output.outputType
				})
				if (data)
					$scope.outputSelected = data
			})
		}



		//change the trigger
		$scope.changeTrigger = function (trigger) {
			$scope.triggerNameObj = trigger;
			getAllWorkflowDefinitions();
			setTrigger(trigger);
		}

		//change the trigger config and set the other required values for this trigger
		$scope.changeTriggerConf = function (triggerConf) {
			if(!triggerConf){
				triggerConf = $scope.triggers[0]
			}
			$scope.trigger = triggerConf
			$scope.changeSubProduct($scope.subProduct, triggerConf.name)
			setTriggerConf(triggerConf)
		}

		$scope.changeTriggerOptions = ( triggerField ) => {
			const {isFutureAvailable, showPastAlso} = triggerField;
			$scope.readableConditions = []
			if(!isFutureAvailable){
				$scope.trigger.readableConditions = [conditionsMap.get('$lt')]
				$scope.trigger.options = $scope.CONST_TRIGGER.options.filter( option => {
					if(option !== 'tomorrow' && option !== 'today'){
						return true;
					}
				});
				$scope.readableConditions = $scope.trigger.readableConditions
			}else{
				$scope.trigger.conditions = $scope.CONST_TRIGGER.conditions;
				$scope.trigger.conditions.forEach(condition => {
					$scope.readableConditions.push(conditionsMap.get(condition))
				})
				$scope.trigger.options = $scope.CONST_TRIGGER.options.filter(option => {
					if (option !== 'yesterday') {
						return true;
					}else if(option === 'yesterday' && showPastAlso){
						return true
					}
				});
			}

			$scope.readableCondition = $scope.readableConditions[0];
			$scope.options = $scope.trigger.options
			$scope.option = $scope.options[0];
		}

		$scope.changeAction = function (action) {
			setSelectedAction(action, $scope.actionsBackup)
		}
		$scope.changeOutput = function (output) {
			setSelectedOutput(output, $scope.outputsBackupBackup)
		}

		function getAllWorkflowDefinitions() {
			WorkflowService.getAllWorkflowDefinition('all').then(function (result) {
				$scope.workflowDefinitions = result ? result : []
				$scope.workflowDefinition = $scope.workflowDefinitions[0];
				$scope.filterStatesOnWorkflowDefinition($scope.workflowDefinition);
			}, function (err) {
				console.log(err.message)
			})
		}

		// function formatWorkflowStates(data){
		//   var filteredWorkflow = _.groupBy(data, 'data.name')
		//   //$scope.workflowDefinitions = filteredWorkflow;

		//   console.log(filteredWorkflow,'---  filteredWorkflow')
		// }
		$scope.filterStatesOnWorkflowDefinition = function (param) {
			var filteredStates = _.filter($scope.workflowDefinitions, function (definition) {
				return definition.name == param.name
			});
			if (filteredStates.length) {
				$scope.workflowStates = filteredStates[0].states
			}
		}

		function setTriggerConf(triggerConf) {


			triggerConf = triggerConf ? triggerConf : $scope.trigger;

			$scope.trigger = triggerConf;
			setExcelTriggerFields()
			const filteredTrigger = _.find($scope.triggers, {
				label: triggerConf.label
			});

			$scope.readableConditions = filteredTrigger.readableConditions ? filteredTrigger.readableConditions : [];
			$scope.readableCondition = $scope.readableConditions[0];

			$scope.durationFors = triggerConf.durationFor ? triggerConf.durationFor : []
			$scope.durationFor = $scope.durationFors[0]

			$scope.durationNumbers = triggerConf.durationNumber ? triggerConf.durationNumber : []
			$scope.durationNumber = $scope.durationNumbers[0]

			$scope.recurringCycles = triggerConf.recurringCycles ? triggerConf.recurringCycles : []
			$scope.recurringCycle = $scope.recurringCycles[0]

			$scope.numberOfFlags = triggerConf.numberOfFlags ? triggerConf.numberOfFlags : []
			$scope.numberOfFlag = $scope.numberOfFlags[0]

			$scope.fromStates = $scope.states ? $scope.states : []
			$scope.fromState = $scope.fromStates[0]

			$scope.toStates = $scope.states ? $scope.states : []
			$scope.toState = $scope.toStates[0]

			$scope.options = filteredTrigger.options ? filteredTrigger.options : [];
			$scope.option = $scope.options[0];
			if(exceptionDateTrigger.includes(filteredTrigger.name)){
				$scope.changeTriggerOptions(triggerConf)
			}
			getActionByTrigger($scope.triggerName)
			getOutputsByTrigger($scope.triggerName)
		}


		function getCondition(searchValue) {
			for (var [key, value] of conditionsMap.entries()) {
				if (value === searchValue)
					return key;
			}
		}

		$scope.sendHandlerNotificationFunc = (param) => {
			if(param){
				$scope.actionsBackup.forEach(function (actn) {
					var data = _.find(actn.config, {
						actionType: "ACTION_TYPES.NOTIFICATION_FOR_FRONTLINE"
					})
					if (data)
						$scope.secondaryActionSelected = data
				})
			}else{
				$scope.secondaryActionSelected = {}
			}
		}
		//#endregion

		const deleteUnwantedKeys = (trigger, baseTrigger) => {
			const triggerKeys = Object.keys(baseTrigger);
			if(!triggerKeys.includes('triggerField')){
				delete trigger.triggerField;
				delete trigger.subProductMetadata
			}
		}

		$scope.saveTrigger = function () {
			var filteredTrigger = _.find($scope.triggers, {
				label: $scope.trigger.label
			});
			filteredTrigger.template = $scope.trigger.template;
			filteredTrigger.dummyTemplate = $scope.trigger.dummyTemplate;
			var trigger = {};
			trigger.label = filteredTrigger.label;
			trigger.name = filteredTrigger.name;


			if (trigger.name === 'customerProductMetadataDate') {
				$scope.triggerField = null
				trigger.workflowDefinition = null
			} else if (trigger.name === 'customerMetadataDate') {
				$scope.subProductMetadata = null
				trigger.workflowDefinition = null
			} else if (trigger.name === 'ticketMetadataDate') {
				$scope.subProductMetadata = null
				trigger.workflowDefinition = null
			} else if (trigger.name === 'durationInAState' || trigger.name === 'durationInAStateBulk'  || trigger.name === 'timeElapsedInSeconds') {
				$scope.triggerField = null
				$scope.subProductMetadata = null
				trigger.workflowDefinition = {}
				trigger.workflowDefinition.name = $scope.workflowDefinition.data.name
				trigger.workflowDefinition.uid = $scope.workflowDefinition.data.uid
				trigger.workflowDefinition.type = $scope.workflowDefinition.data.type
				trigger.workflowDefinition.active = $scope.workflowDefinition.data.active
				trigger.workflowDefinition._id = $scope.workflowDefinition.data._id
			} else if (trigger.name === 'badgeAlert') {
				$scope.subProductMetadata = null
				trigger.badgeCode = $scope.badge.badgeCode;
				trigger.badgeName = $scope.badge.name;
				trigger.badgeId = $scope.badge._id;
				trigger.numberOfFlag = $scope.numberOfFlag;
				trigger.product = $scope.product;
			} else if (trigger.name === 'metricAlert') {
				$scope.subProductMetadata = null
				trigger.product = $scope.isKpiSpecific ? $scope.product : null;
				trigger.activity = {
					name : $scope.activityObj.name,
					activityCode : $scope.activityObj.activityCode,
					alertSpecificName : $scope.activityObj.alertSpecificName,
					metric : $scope.metric,
					isKpiSpecific : $scope.isKpiSpecific
				}
				trigger.activity.metric.metricThresholdValue = $scope.metricThresholdValue;
				trigger.activity.metric.metricType = $scope.activityObj.activityType;
				// trigger.metricThresholdValue = $scope.metricThresholdValue;

				trigger.isRecurringSpecific  = $scope.isRecurringSpecific;
				trigger.recurringCycle = $scope.isRecurringSpecific ? $scope.recurringCycle : null;

			} else if (trigger.name === 'overallMetricAlert') {
				$scope.subProductMetadata = null
				trigger.metric = $scope.metric;
				trigger.metric.metricThresholdValue = $scope.metricThresholdValue;
				delete trigger.metric.value;
				delete trigger.metric.delta;
				trigger.isRecurringSpecific  = $scope.isRecurringSpecific;
				trigger.recurringCycle = $scope.isRecurringSpecific ? $scope.recurringCycle : null;

			} else if (trigger.name === 'kpiMetricAlert') {
				$scope.subProductMetadata = null;
				const selectedKpi = $scope.selected.kpi;
				trigger.kpi = {
					name : selectedKpi.name,
					_id: selectedKpi._id,
					kpiCode : selectedKpi.kpiCode,
					metric : $scope.metric,
				};
				delete trigger.kpi.metric.value;
				delete trigger.kpi.metric.delta;
				trigger.kpi.metric.metricThresholdValue = $scope.metricThresholdValue;

				trigger.isRecurringSpecific  = $scope.isRecurringSpecific;
				trigger.recurringCycle = $scope.isRecurringSpecific ? $scope.recurringCycle : null;
			} else if(trigger.name === 'documentMetadataDate' || trigger.name === 'documentMetadataNumber'){
				trigger.documentTypeId = $scope.documentType._id
				trigger.docPrerequisiteMetadata = $scope.docPrerequisiteMetadata
			}


			trigger.triggerType = filteredTrigger.triggerType ? filteredTrigger.triggerType : null,
			trigger.interval = $scope.interval;
			trigger.readableCondition = $scope.readableCondition;
			trigger.subProductMetadata = $scope.subProductMetadata;

			trigger.triggerField = $scope.triggerField;
			if ($scope.trigger.name === 'customerProductMetadataValue') {
				trigger.option = $scope.productMetadataValue
			} else{
				trigger.option = $scope.option;
			}
			trigger.actions = [$scope.actionSelected];
			if($scope.trigger.name === 'leadArchiveEvent'){
				trigger.sendHandlerNotification  = $scope.sendHandlerNotification;
				if($scope.sendHandlerNotification){
					trigger.actions.push($scope.secondaryActionSelected);
				}
			}
			trigger.output = $scope.outputSelected;
			trigger.output.value = filteredTrigger.template;
			trigger.condition = getCondition(trigger.readableCondition);
			trigger.triggerName = $scope.triggerName;
			trigger.durationNumber = $scope.durationNumber;
			trigger.durationFor = $scope.durationFor;
			trigger.fromState = $scope.fromState ? $scope.fromState.uid : null;
			trigger.fromStateLabel = $scope.fromState ? $scope.fromState.name : null;
			trigger.toState = $scope.toState ? $scope.toState.uid : null;
			trigger.interactionCategory = $scope.selected.interactionCategory ? $scope.selected.interactionCategory._id : null;

			//delete unwanted keys - triggerField and product info from object
			//its mutating the object so no return type required
			deleteUnwantedKeys(trigger, filteredTrigger);

			let tag = null;
			if($scope.selected.interactionCategory){
				const list = ['bulkUpcomingInteractions', 'noContactInteractions', 'noInteractionsComplete', 'followUpMissed'];
				if($scope.trigger && list.includes($scope.trigger.name)){
					tag = trigger.interactionCategory;
				}
			}else if(filteredTrigger.tag){
				tag = filteredTrigger.tag;
			}
			if(tag){
				trigger.tag = tag;
			}
			TriggerService.setTrigger(trigger).then(function (result) {
				toaster.pop('success', 'Alert Rule', 'New Alert set')
			}, function (error) {
				toaster.pop('error', 'Alert Rule', error.message)
				console.log(error)
			})
		};

		$scope.runAlarmCreation = function (interval) {
			//run alarm creation job dynamically based on time passed
			TriggerService.runAlarmCreationJob(interval).then(function (result) {
				toaster.pop('success', 'Alarm Creation', interval + ' alarm creation ran successfully')
			}, function (error) {
				toaster.pop('error', 'Alarm Creation', 'Error in creating alarm creation job')
				console.log(error)
			})
		}
	});
