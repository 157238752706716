const autoImportNgTemplateLoaderTemplate1 = require('../html/rhsCardTable.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('rhsCardTable', function(){
        return {
            name: 'rhsCardTable',
            scope: {
                data:   '=',
                type:   '@'
            },
            controller: 'rhsCardTableCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
