
'use strict';
angular.module('bitnudgeApp')
    .controller('otherKeyCtrl', function ($scope, $rootScope,$state, $location, $compile, $http, Auth, toaster, $timeout, $interval, DTOptionsBuilder, DTColumnDefBuilder, p0) {
        $scope.strings = $rootScope.strings;
        $scope.checkForRedFlag = function(league){
            var kpiData = [];
            var divisions = $scope.selectedPlayerType.division;
            var kpis = p0.getKpisOfDivisions(divisions);
            for(var i=0;i<kpis.length;i++){
                kpiData.push({_id:kpis[i]._id, name:kpis[i].name});
            }

            $scope.$watch('selectedCycle._id', function (newValue, oldValue) {
                if(newValue) {
                    $http.post('api/analytics/getRedFlag',{cycleId:$scope.selectedCycle._id, metric:league, playerType: $scope.selectedPlayerType._id, kpiData:JSON.stringify(kpiData)}).then(function (redFlag) {
                        redFlag=redFlag.data;
                        $scope.redFlag = redFlag;
                        $scope.redFlagSlides = [];
                        $scope.redFlagSlideTables = [0, 1];

                        for (var i = 0; i < Math.ceil($scope.redFlag.ref.length / 2); i++)
                            $scope.redFlagSlides.push(i);

                        $scope.redFlagSlide = 0;
                    });
                }
            });
        };

        $scope.changeRedFlagSlide = function(delta) {
            $scope.redFlagSlide += delta;
        }

        $rootScope.forLmsTracker = true;
        $scope.inLmsTracker = function(){
            $location.path('/lms/lmsTracker');
        }

        $scope.getLooserStreak = function(){
            var jobRoleId = Auth.getCurrentUser().organizationalTag.jobRole._id;
            if($scope.selectedPlayerType) {
                const postData = {
                    cycleType: 'monthly',
                    metric: 'bank',
                    kpiId: $scope.selectedKpi._id,
                    playerType: $scope.selectedPlayerType._id
                }
                $http.post('api/analytics/getLoserStreak', postData).then(function (loosers) {
                    loosers=loosers.data;
                    $scope.loosers = loosers;
                    $scope.loosersSlides = [];
                    $scope.loosersSlideTables = [0, 1];

                    for (var i = 0; i < Math.ceil($scope.loosers.ref.length / 2); i++)
                        $scope.loosersSlides.push(i);

                    $scope.looserSlide = 0;
                })
            }
        };
        $scope.changeLooserSlide = function(delta) {
            $scope.looserSlide += delta;
        }

        var cycleTypes =['monthly','quarterly','halfyearly','yearly'];

        $scope.kpis = p0.allKpis;
        $scope.selectedKpi = $scope.kpis[0];

        $scope.setFilterForPlayerList=function(playerList,playerListModalName,playerTypeModalName){

            if(!playerList || !playerListModalName || !playerTypeModalName)
                return;

            $scope[playerList]= _.filter($scope.allReportees,function(a){
                return a.organizationalTag.jobRole.toString()==playerTypeModalName._id.toString()
            });
            $scope[playerListModalName]=$scope[playerList][0];
        }

        $scope.allCycles = [p0.currentMonthlyCycle];
        $scope.allCycles.push(p0.previousMonthlyCycle);
        $scope.selectedCycle = p0.currentMonthlyCycle;
        triggerKeyPartialClick('cycle');

        $scope.playerType = p0.myFrontlineRoles;
        $scope.selectedPlayerType = $scope.playerType[0];
        triggerKeyPartialClick('playerType');
        $scope.selectedPlayerType = $scope.playerType[0];
        $scope.setFilterForPlayerList('playerListCorrelation', 'selectedPlayerCorrelation1', $scope.selectedPlayerType);
        $scope.setFilterForPlayerList('playerListCorrelation', 'selectedPlayerCorrelation2', $scope.selectedPlayerType);
        $scope.setFilterForPlayerList('playerList', 'selectedPlayerCorrelationCalls', $scope.selectedPlayerType)
        $scope.getCorrelationData = function() {
            const postData = {
                cycleId,playerType
            }
            $http.post('api/analytics/branchmanager/frontline/getCorrelationData', postData)
                .then(function (reporteeCorrelation) {
                    reporteeCorrelation=reporteeCorrelation.data;
                    $scope.reporteeCorrelation = reporteeCorrelation;
                    $scope.getCorrelationActivities();
                    $scope.getMoreCorrelation();
                });
        };

        $scope.getCorrelationData();

        $scope.$watch('selectedPlayerType',function(newValue,oldValue) {
            if (newValue) {
                $http.post('api/users/players/frontline/playerType', {playerTypeId: $scope.selectedPlayerType._id}).then(function (children) {
                    children=children.data;
                    $scope.children = children;

                });
            }
        });
        //function that isused when coming back from the oneClickReport. The click is triggerred only when both are set.
        //otherwise, it is not triggered.
        function triggerKeyPartialClick(type){
            if($rootScope.gotoAnalytics || $rootScope.backFromLMSComplaince){
                if(type=='playerType'&&$scope.selectedCycle){
                    $timeout(function(){
                        $("#showOki").trigger('click');
                        $rootScope.gotoAnalytics = false;
                    });
                }
                if(type=='cycle'&&$scope.selectedPlayerType){
                    $timeout(function(){
                        $("#showOki").trigger('click');
                        $rootScope.gotoAnalytics = false;
                    });
                }
            }
        };

        //frontline tab dropdowns ends
        $scope.dataTypes = [
            {name:"MTD"},
            {name:"forecast"}

        ]
        $scope.selectedDataTypeActivity = $scope.dataTypes[0];
        $scope.selectedDataTypeImpact = $scope.dataTypes[0];

        $scope.playerList = [];
        $scope.playerListCorrelation=[];
        $scope.allReportees=[];
        $scope.XAxisVar ='';
        $scope.YAxisVar = '';

        $http.post('api/users/me/children', {select: ["name", "organizationalTag", "teams"]}).then(function(children) {
            children=children.data;
            for(var i = 0; i < children.length; i++)
                children[i].seq = i;

            $scope.playerList = children;

            $scope.playerListCorrelation = children;
            $scope.allReportees= children;

            $scope.selectedPlayerCorrelation1 = $scope.playerListCorrelation[0];
            $scope.selectedPlayerCorrelation2 = $scope.playerListCorrelation[0];
            $scope.selectedPlayerMoreCorrelation = $scope.playerListCorrelation[0];
            $scope.selectedPlayerCorrelationCalls = $scope.playerList[0];
            $scope.playerSelectPlayerSpecific = $scope.playerList[0];

            $scope.setFilterForPlayerList('playerListCorrelation','selectedPlayerCorrelation1',$scope.selectedPlayerType);
            $scope.setFilterForPlayerList('playerListCorrelation','selectedPlayerCorrelation2',$scope.selectedPlayerType);
            $scope.setFilterForPlayerList('playerList','selectedPlayerCorrelationCalls',$scope.selectedPlayerType)
        });

        $scope.oneClickReportOfUser = function(selectedPlayerForOCR){
            $state.go('userFlow.oneClickReport', {selectedPlayerIdForOCR: selectedPlayerForOCR._id});
        }


        $scope.getCorrelationActivities = function() {
            $scope.graphVariables = [{_id:0, name:'Overall'+$scope.strings.value}, {_id:2,name:'Activity'+$scope.strings.POINTS.value}, {_id:1,name:'Impact'+$scope.strings.POINTS.value}];
            $.each(p0.allActivities, function(index,transaction) {
                $scope.graphVariables.push({"_id":transaction._id.toString(), "name":"Activity "+$scope.strings.POINTS.value+" (" +transaction.name + ")"});
            });
            $scope.YAxisVar = $scope.graphVariables[2];
            $scope.XAxisVar = $scope.graphVariables[1];
        };

        $scope.getMoreCorrelation = function() {
            $scope.YAxisVars = _.cloneDeep($scope.graphVariables);
            $scope.XAxisVars = _.cloneDeep($scope.graphVariables);
            _.pull($scope.YAxisVars,$scope.XAxisVar);
            _.pull($scope.XAxisVars,$scope.YAxisVar);
            var reporteeData=$scope.reporteeCorrelation.children;
            var otherData=$scope.reporteeCorrelation.others;
            var scatterData = {children:[],others:[]};
            reporteeData.forEach(function(repObj){
                var yActivity;
                var xActivity;
                if(typeof $scope.YAxisVar._id == 'string') {
                    yActivity = _.find(repObj.activityData, function(activity) {
                        return activity.activityId.toString() == $scope.YAxisVar._id.toString()
                    })
                }
                if(typeof $scope.XAxisVar._id == 'string') {
                    xActivity = _.find(repObj.activityData, function(activity) {
                        return activity.activityId.toString() == $scope.XAxisVar._id.toString()
                    })
                }
                scatterData.children.push({
                    name:repObj.name,
                    x: $scope.getMoreCorrelationAxisData(repObj, $scope.XAxisVar._id, xActivity),
                    y: $scope.getMoreCorrelationAxisData(repObj, $scope.YAxisVar._id, yActivity)
                });
            })
            otherData.forEach(function(repObj){
                var yActivity = "";
                var xActivity = "";
                if(typeof $scope.YAxisVar._id == 'string') {
                    yActivity = _.find(repObj.activityData, function(activity) {
                        return activity.activityId.toString() == $scope.YAxisVar._id.toString()
                    })
                }
                if(typeof $scope.XAxisVar._id == 'string') {
                    xActivity = _.find(repObj.activityData, function(activity) {
                        return activity.activityId.toString() == $scope.XAxisVar._id.toString()
                    })
                }
                scatterData.others.push({
                    name:repObj.name,
                    x: $scope.getMoreCorrelationAxisData(repObj, $scope.XAxisVar._id, xActivity),
                    y: $scope.getMoreCorrelationAxisData(repObj, $scope.YAxisVar._id, yActivity)
                });
            });
            $scope.plotScatterGraph('#more-correlation', scatterData, null, $scope.XAxisVar.name, $scope.YAxisVar.name);
        };

        $scope.getMoreCorrelationAxisData = function(repObj, id, activity) {
            switch(id) {
                case 0:
                    return repObj.points.value;
                    break;

                case 1:
                    return repObj.businessPoints.value;
                    break;

                case 2:
                    return repObj.activityPoints.value;
                    break;

                default:
                    return activity.points.value;
                    break;
            }
        }

        $scope.plotScatterGraph = function(element,scatterData,regressionCoordinates,xAxisLabel,yAxisLabel){
            $(element).highcharts({
                chart: {
                    type: 'scatter',
                    zoomType: 'xy'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    min:0,
                    title: {
                        enabled: true,
                        text: xAxisLabel
                    }
                },
                yAxis: {
                    min: 0,
                    title: {
                        enabled: true,
                        text: yAxisLabel
                    }
                },
                tooltip: {
                    crosshairs: false,
                    shared: false,
                    formatter: function() {
                        return "User: "+this.point.name + "<br />" +
                            yAxisLabel+": "+this.point.y + "<br />" +
                            xAxisLabel+": "+this.point.x + "<br />";
                    }
                },
                series: [{
                    name: 'Reportees',
                    color: 'rgba(223, 83, 83, .5)',
                    type:'scatter',
                    data:scatterData.children
                }, {
                    name: 'Others',
                    color: 'rgba(119, 152, 191, .5)',
                    data:scatterData.others
                }]
            });
        };

        $scope.plotReporteeAnalysisGraph = function(element, categories, series, model) {
            $scope.playerAnalysisChart = {
                playerId: model._id,
                playerName: model.name,

                title: {
                    text: 'Analysis of ' + model.name
                },
                xAxis: {
                    categories: categories
                },
                series: series
            };
            $(element).highcharts($scope.playerAnalysisChart);
        }

        $scope.loose = true;
        $scope.key = true;
        $scope.flagBank = true;
        $scope.showKeyAnalyses = function(){
            $scope.key = true;
            $scope.flagBank = true;
            $scope.loose = true;
        };
        $scope.callRedFlag = function(league){
            $scope.key = false;
            $scope.loose = true;
            $scope.flagBank = false;
            $scope.checkForRedFlag(league);
        };
        $scope.losingStreak = function(){
            $scope.key = false;
            $scope.loose = false;
            $scope.flagBank = true;
            $scope.getLooserStreak();
        };

        //using prototypal inheritance to access child controller function
        //in order to see if the tab can be opened.
        $scope.open.otherKeyTab=function(event){
            if(!$scope.selectedCycle||!$scope.selectedPlayerType){
                event.preventDefault();
                event.stopPropagation();
            }
            else
               $scope.getCorrelationData();
        };

    });
