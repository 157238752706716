'use strict';
angular.module('bitnudgeApp')
    .service('transactionService', function ($http, Sidebar, $q, UtilService) {
        let self = this;
        const getIncentiveTransactionURL = function (_params, downloadAll) {
            const { userId, filterObj, pagination, sortingObj } = _params;
            const offset = pagination.offset;
            const limit = downloadAll ? 0 : pagination.limit;
            const sortKey = sortingObj && sortingObj.sortKey;
            const sortOrder = sortingObj && sortingObj.sortOrder;
            const productGroupId = filterObj.productGroupKey
            const productNameId = filterObj.productFilterKey
            const amountRange = filterObj.amountFilterKey;
            const searchParam = filterObj.searchParam;
            const params = {
                userId,
                offset,
                limit,
                sortKey,
                sortOrder,
            };

            if (amountRange) {
                params.minAmount = filterObj.minAmount;
                params.maxAmount = filterObj.maxAmount;
            }
            if (productNameId) {
                params.productNameId = productNameId;
            }
            if (productGroupId) {
                params.productGroupId = productGroupId
            }
            if (searchParam && searchParam.length) {
                params.searchKey = searchParam
            }
            if (filterObj.startDate && filterObj.transactionDateKey) {
                params.startDate = new Date(filterObj.startDate).getTime();
            }
            if (filterObj.endDate && filterObj.transactionDateKey) {
                params.endDate = new Date(filterObj.endDate).getTime();
            }
            return { url: '/api/incentivetransactions', params };
        }

        this.getTransactionData = function (_params) {
            const { url, params } = getIncentiveTransactionURL(_params);
            $http.post(url, params).then(
                result => {
                    deferred.resolve(result.data);
                },
                error => {
                    console.log(error);
                    deferred.reject(error);
                }
            );
            return deferred.promise;
        };

        this.downloadTransactions = function (_params, downloadAll) {
            const appUrl = Sidebar.getAppUrl();
            let { url, params } = getIncentiveTransactionURL(_params, downloadAll);
            params.download = true;
            return UtilService.downloadFile(appUrl+url, params)
        }

        this.fetchAmountRanges = function (user) {
            let url = '/api/transactionAmountRanges';
            const deferred = $q.defer();
            $http.post(url, { userId: user._id }).then(
                result => {
                    deferred.resolve(result.data);
                },
                error => {
                    console.log(error);
                    deferred.reject(error);
                }
            );
            return deferred.promise;
        }

        this.getPaginationObj = function () {
            var pagination = {
                offset: 0,
                limit: 50,
                total: 0,
                disableNext: true,
                disablePrevious: true
            }
            return pagination;
        }

    });
