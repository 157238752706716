angular.module('bitnudgeApp')
	.directive('gauzeChart', function ($rootScope) {
		return {
			restrict: 'EA',
			transclude: true,
			scope: {
                data: '=',
                numerator: '=',
                denominator: '=',
				bin: '=',
				export: '=',
				id: '@',
				title: '@',
				height: '@',
                type: '@',
                legend: '@',
			},

			replace: true,
			link: function (scope, element, attrs, rootScope) {
                const gaugeFunction = function(configuration) {
                    var that = {};
                    var config = {
                        size						: 200,
                        clipWidth					: 200,
                        clipHeight					: 110,
                        ringInset					: 20,
                        ringWidth					: 20,
                        
                        pointerWidth				: 10,
                        pointerTailLength			: 5,
                        pointerHeadLengthPercent	: 0.9,
                        
                        minValue					: 0,
                        maxValue					: 10,
                        
                        minAngle					: -90,
                        maxAngle					: 90,
                        
                        transitionMs				: 750,
                        
                        majorTicks					: 5,
                        labelFormat					: d3.format(',g'),
                        labelInset					: 10,
                        
                        arcColorFn					: d3.interpolateHsl(d3.rgb('#d0e2f2'), d3.rgb('#4682b4'))
                    };
                    var range = undefined;
                    var r = undefined;
                    var pointerHeadLength = undefined;
                    var value = 0;
                    
                    var svg = undefined;
                    var arc = undefined;
                    var scale = undefined;
                    var ticks = undefined;
                    var tickData = undefined;
                    var pointer = undefined;
                
                    var donut = d3.layout.pie();
                    
                    function deg2rad(deg) {
                        return deg * Math.PI / 180;
                    }
                    
                    function newAngle(d) {
                        var ratio = scale(d);
                        var newAngle = config.minAngle + (ratio * range);
                        return newAngle;
                    }
                    
                    function configure(configuration) {
                        var prop = undefined;
                        for ( prop in configuration ) {
                            config[prop] = configuration[prop];
                        }
                        
                        range = config.maxAngle - config.minAngle;
                        r = config.size / 2;
                        pointerHeadLength = Math.round(r * config.pointerHeadLengthPercent);
                
                        // a linear scale that maps domain values to a percent from 0..1
                        scale = d3.scale.linear()
                            .range([0,1])
                            .domain([config.minValue, config.maxValue]);
                            
                        ticks = scale.ticks(config.majorTicks);
                        tickData = d3.range(config.majorTicks).map(function() {return 1/config.majorTicks;});
                        
                        arc = d3.svg.arc()
                            .innerRadius(r - config.ringWidth - config.ringInset)
                            .outerRadius(r - config.ringInset)
                            .startAngle(function(d, i) {
                                var ratio = d * i;
                                return deg2rad(config.minAngle + (ratio * range));
                            })
                            .endAngle(function(d, i) {
                                var ratio = d * (i+1);
                                return deg2rad(config.minAngle + (ratio * range));
                            });
                    }
                    that.configure = configure;
                    
                    function centerTranslation() {
                        return 'translate('+r +','+ r +')';
                    }
                    
                    function isRendered() {
                        return (svg !== undefined);
                    }
                    that.isRendered = isRendered;
                    
                    function render(newValue) {
                        svg = d3.select(element[0])
                            .append('svg:svg')
                                .attr('class', 'gauge')
                                .attr('width', config.clipWidth)
                                .attr('height', config.clipHeight);
                        
                        var centerTx = centerTranslation();
                        
                        var arcs = svg.append('g')
                                .attr('class', 'arc')
                                .attr('transform', centerTx);
                        
                        arcs.selectAll('path')
                                .data(tickData)
                            .enter().append('path')
                                .attr('fill', function(d, i) {
                                    return config.arcColorFn(d * i);
                                })
                                .attr('d', arc);
                        
                        var lg = svg.append('g')
                                .attr('class', 'label')
                                .attr('transform', centerTx);
                        lg.selectAll('text')
                                .data(ticks)
                            .enter().append('text')
                                .attr('transform', function(d) {
                                    var ratio = scale(d);
                                    var newAngle = config.minAngle + (ratio * range);
                                    return 'rotate(' +newAngle +') translate(0,' +(config.labelInset - r) +')';
                                })
                                .text(config.labelFormat);
                
                        var lineData = [ [config.pointerWidth / 2, 0], 
                                        [0, -pointerHeadLength],
                                        [-(config.pointerWidth / 2), 0],
                                        [0, config.pointerTailLength],
                                        [config.pointerWidth / 2, 0] ];
                        var pointerLine = d3.svg.line().interpolate('monotone');
                        var pg = svg.append('g').data([lineData])
                                .attr('class', 'pointer')
                                .attr('transform', centerTx);
                                
                        pointer = pg.append('path')
                            .attr('d', pointerLine/*function(d) { return pointerLine(d) +'Z';}*/ )
                            .attr('transform', 'rotate(' +config.minAngle +')');
                            
                        update(newValue === undefined ? 0 : newValue);
                    }
                    that.render = render;
                    
                    function update(newValue, newConfiguration) {
                        if ( newConfiguration  !== undefined) {
                            configure(newConfiguration);
                        }
                        var ratio = scale(newValue);
                        var newAngle = config.minAngle + (ratio * range);
                        pointer.transition()
                            .duration(config.transitionMs)
                            .ease('elastic')
                            .attr('transform', 'rotate(' +newAngle +')');
                    }
                    that.update = update;
                
                    configure(configuration);
                    
                    return that;
                };
                const powerGauge = gaugeFunction({
                    size: 350,
                    clipWidth: 350,
                    clipHeight: 200,
                    ringWidth: 60,
                    maxValue: 100,
                    transitionMs: 4000,
                });
                // powerGauge.render();
                // scope.render = function(data) {
                //     var legendRight = false;
                //     if(scope.legend == 'right' || scope.legend == 'Right'){
                //         legendRight = true;
                //     }

                //     var w = element.parent()[0].clientWidth || 450;
                //     h =  legendRight ? 380 : 620;
                //     var r = legendRight ? 180 : (w / 2) - 50;
                //     var color = d3.scale.category20c();
                //     var div = d3.select(element[0]).append("div")
                //         .attr("class", "tooltip")
                //         .style('background', 'black')
                //         .style('padding', '10px')
                //         .style('color', 'white')
                //         .style("opacity", 0)
                //         .style('height', 10)

                //     var vis = d3.select(element[0]).append("svg:svg").data([data]).attr("width", w).attr("height", h - 20).append("svg:g")
                //         .attr('viewBox', '0 0 ' + Math.min(w, h) + ' ' + Math.min(w, h))
                //         .attr('preserveAspectRatio', 'xMinYMin')
                //         .attr("transform", "translate(" + r + "," + r + ")");

                //     var pie = d3.layout.pie()
                //         .value(function(d) { 
                //         return d.value; });

                //     var arc = d3.svg.arc()
                //                 .outerRadius(r)
                //                 .innerRadius(0);

                //     var arcs = vis.selectAll("g.slice").data(pie)
                //                 .enter()
                //                 .append("svg:g")
                //                 .attr("class", "slice");
                //     arcs.append("svg:path")
                //         .attr('class', 'area')
                //         .attr("fill", function(d, i) {
                //             return color(i);
                //         })
                //         .attr("d", function(d) {
                //             return arc(d);
                //         })

                //         .on('mouseover', function(d) {
                //             div.transition()
                //                 .duration(200)
                //                 .style("opacity", .9);
                //             div.html(scope.type+' : ' + JSON.stringify(d.data.key) + '<br>Value : ' + d.value + "")
                //                 .style("left", (d3.mouse(this)[0] + 30) + "px")
                //                 .style("top", (d3.mouse(this)[1] + 150) + "px");
                //         })
                //         .on('mouseout', function(d) {
                //             div.transition()
                //                 .duration(300)
                //                 .style("opacity", 0);
                //         })
                //         .style('stroke', 'white')
                //         .style('stroke-width', 1)
                //         .transition()
                //         .duration(function(d, i) {
                //         return i * 1000;
                //         })
                //         .attrTween('d', function(d) {
                //             var i = d3.interpolate(d.startAngle+0.1, d.endAngle);
                //             return function(t) {
                //                 d.endAngle = i(t);
                //                 return arc(d);
                //             }
                //         })

                //     arcs.append("svg:text")   
                //         .attr("class", "text-in-arc")                                  //add a label to each slice
                //         .attr("transform", function(d) {                    //set the label's origin to the center of the arc
                //         //we have to make sure to set these before calling arc.centroid
                //         d.innerRadius = 0;
                //         d.outerRadius = r;
                //         return "translate(" + arc.centroid(d) + ")";        //this gives us a pair of coordinates like [50, 50]
                //     })
                //     .attr("text-anchor", "middle")  //center the text on it's origin
                //     .style("fill", "#fff")                      
                //     .text(function(d, i) {
                //         return d.data.percent ? d.data.percent + "%" : d.data.value; 
                //     });

                //     if(!legendRight){
                //         var legend = vis.append("g")
                //             .attr("class", "legend")
                //             .attr("width", w - 50)
                //             .attr("height", -100)
                //             .selectAll("g")
                //             .data(data)
                //             .enter().append("g")
                //             .attr("transform", function(d, i) {
                //                 return "translate(" + (-20) + "," + (i * 20 + r+20) + ")";
                //             });
                //     }else{
                //         var legend = vis.append("g")
                //         .attr("class", "legend")
                //         .attr("width", w - 50)
                //         .attr("height", -100)
                //         .selectAll("g")
                //         .data(data)
                //         .enter().append("g")
                //         .attr("transform", function(d, i) {
                //             return "translate(" + (r+20) + "," + (i * 20 - r+20) + ")";
                //         });
                //     }
                    

                    
                    

                //     legend.append("rect")
                //         .attr("width", 15)
                //         .attr("height", 15)
                //         .style("fill", function(d, i) {
                //             return color(i);
                //         });

                //     legend.append("text")
                //         .attr("x", 24)
                //         .attr("y", 9)
                //         .attr("dy", ".35em")
                //         .style('font-size', 10)
                //         .text(function(d) {
                //             return d.key;
                //         });

                // };

				scope.$watchGroup(['numerator','denominator'], function (newValue, oldValue) {
                    powerGauge.render();
                    const numerator = newValue[0];
                    const denominator = newValue[1];
					 if (newValue && denominator){
                        let result = numerator/denominator
                        powerGauge.update(result * 100);
                    }else{
                        powerGauge.update(0);
                     }
				});

			}
		};
	});