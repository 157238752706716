const autoImportNgTemplateLoaderTemplate1 = require('../html/customers/customerVerificationLogs.html');

'use strict';
angular.module('bitnudgeApp')
    .component('customerVerificationLogs',{
        bindings: {
            logs: '<',
            apiLoaded: '<'
        },
        templateUrl: autoImportNgTemplateLoaderTemplate1,
        controller: function($scope){
            this.$onChanges = (changes) => {
                if (changes && changes.logs && changes.logs.currentValue) {
                    $scope.logs = changes.logs.currentValue
                }
            };
        }
    });
