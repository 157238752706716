angular.module('bitnudgeApp')
    .controller('uploadFlowCronLogCtrl', function ($scope,Auth, toaster, uploadFlowCrons, parameters) {
        uploadFlowCrons.getCronLog(parameters.uploadTrackerId)
        .then(function(success) {
            var cronLogs
            if (success.length !== 0) {
                cronLogs = success;
            } else {
                cronLogs = ["No logs to display"]
            }
            $scope.cronLogs = cronLogs;
        },function(err){
            toaster.pop('warning','Upload Cron', 'Something went wrong!');
            console.error(err) 
        });
    });
