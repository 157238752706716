'use strict';
angular.module('bitnudgeApp')
    .directive ('trackingDirective', ['trackingService', function (trackingService ) {
        return {
            restrict : 'A',
            link: function (scope, element, attrs) {
                var trackingDirective = attrs.heading? attrs.heading: attrs.trackingDirective
                element.on('click', function(){
                    trackingService.update (trackingDirective, attrs.action);
                });
            }
        };
    }])
    .service ('trackingService', function ($rootScope, $state, $http, Auth, p0){
        var post = function (body, tab) {
            return $http.post ('/api/userActions/clientSideActionPerformed', body)
                .then (function (res) {
                    $rootScope.currentState.now= new Date();
                    if (tab) $rootScope.currentState.tab= tab;
                }, function (res) {
                    console.log (res);
                });
        };
        var returnUrlPath = function (url) {
            var a = document.createElement ("a");
            a.href = url;
            return a.pathname;

        };
        this.update = function (tab, action) {
            var me = Auth.getCurrentUser();
            if(!isTrackingEnabled() || me.systemRole =='lmsAdmin') return;

            var meta = {};
            var body =  {actionType: tab, user: Auth.getCurrentUser()};
            if (!action) {
                body.actionType = "State changed";
                meta.fromSate = returnUrlPath($state.current.url) + '/' + $rootScope.currentState.tab;
                meta.toState = returnUrlPath($state.current.url) + '/' + tab;
                meta.duration = new Date() - $rootScope.currentState.now;
                body.meta = meta;
            }
            post (body, tab);

        };
        this.stateChanged = function(oldUrl, newUrl) {
            var me = Auth.getCurrentUser();
            if(!isTrackingEnabled() || me.systemRole =='lmsAdmin') return;

            var meta = {};
            var body =  { user: Auth.getCurrentUser(), actionType : "State changed"};
            meta.fromSate = returnUrlPath(oldUrl);
            meta.toState = returnUrlPath(newUrl);
            meta.duration = new Date() - $rootScope.currentState.now;
            body.meta = meta;
            post(body);
        }

        function isTrackingEnabled() {
            return (p0.config && p0.config.trackStates) || false;
        }
    });
