const autoImportNgTemplateLoaderTemplate1 = require('../html/redirectConfirmation.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('reporteesHierarchyController', function ($scope, $state, $rootScope, Auth, $http, $mdDialog, leadsData, p0, label, widget, unit, metricName, mgrDashboardMetricService, $filter) {
    $scope.pattern = "";
    //$scope.treeFilter = $filter('uiTreeFilter');
    $scope.supportedFields = ['title'];
    $scope.label = label;
    $scope.widget = widget.split('(')[0];
    $scope.unit = unit;
    $scope.asc = true;
    $scope.loading = false;
    $scope.dataFilterType = 'team';
    console.log('leadsData: ', leadsData);
    console.log('$scope.leadsData: ', $scope.leadsData);
    var customerValueMap = {};
    var allUsersList = p0.allReportees;
    console.log('allUsersList: ', allUsersList);
    var isCustomer = $scope.widget.includes('Customer');
    var isPortfolio = $scope.widget.includes('Portfolio');
    $scope.isPortfolio = isPortfolio;

    var fetchMetricDataForCustomers = function() {
        console.log('$rootScope.externalParams.cycleId: ', $rootScope.externalParams.cycleId);
        console.log('$rootScope.externalParams.roleType: ', $rootScope.externalParams.roleType);
        console.log('metricName: ', metricName);
        console.log('$rootScope.externalParams: ', $rootScope.externalParams);
        mgrDashboardMetricService.getMetrics($rootScope.externalParams.cycleId, $rootScope.externalParams.roleType, metricName, $rootScope.externalParams, true).then(function(metrics) {
            console.log('metrics: ', metrics.data);
            metrics = metrics.data;
            leadsData = !_.isEmpty(metrics) && metrics[0] && metrics[0].data;
            console.log('leadsData here: ', leadsData);
            $scope.loading = false;
            init();
        });
    }

    $scope.cancel = function(){
        $mdDialog.hide();
    };
    $scope.collapseAll = function() {
    $scope.$broadcast('angular-ui-tree:collapse-all');
    };

    $scope.expandAll = function() {
    $scope.$broadcast('angular-ui-tree:expand-all');
    };

    $scope.sortTree = function() {
        $scope.asc = !$scope.asc;
        sortTreeData($scope.data[0], $scope.asc);
    }


    $scope.redirectToPage = function(node) {
        var externalParams = _.cloneDeep($rootScope.externalParams);
        externalParams.userEmail = `${node.email}(team(self+reportees))`;
        if (node.nodes && node.nodes.length > 0 && (node.dataCount > 0 || (isPortfolio && node.dataCount !== 0))) {
            $mdDialog.hide();
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'redirectConfirmationCtrl',
                resolve: {
                    ownData: function() {
                        return node.data;
                    },
                    teamData: function() {
                        return node.allBrns;
                    },
                    widget: function() {
                        return $scope.widget.toString().toLowerCase();
                    },
                    kpiId: function() {
                        return null;
                    },
                    externalParams: function() {
                        return externalParams;
                    },
                    source: function () {
                        return null;
                    }
                }
            });
        } else {
            $mdDialog.hide();
            var params = {externalParams: externalParams};
            if ($scope.widget.toString().toLowerCase().includes('lead') ||
                $scope.widget.toString().toLowerCase().includes('cash')) {
                // var params = {externalParams: externalParams};
                $state.go('lms.listLead', params);
            } else if ($scope.widget.toString().toLowerCase().includes('customer')) {
                // var params = {externalParams: externalParams};
                $state.go('lms.customers', params);
            }
        }
    }
    var recursiveReporteeMap = function (currentNode, currentUser, allUsers) {
        if(currentUser.children.length) {
            currentNode.nodes = [];
            currentUser.children.forEach(function (child) {
                var childObj = _.find(allUsers, {_id: child.childId});
                var errorCount = 0, message = '';
                var allValues = [];
                var allBrns = [];
                getAggregatedValue(childObj, allUsers, allValues, allBrns)
                currentNode.nodes.push({id:child.childId, email: childObj.email, title:childObj.name, reportees:childObj.children.length, leafCount:childObj.leafCount, errorCount:errorCount, message:message, playType: childObj.playTypeName, roleType: childObj.roleType, dataCount: childObj.dataCount, data: childObj.data, totalCount: getSum(allValues, allBrns), allBrns: allBrns});
                recursiveReporteeMap(currentNode.nodes[currentNode.nodes.length - 1], childObj, allUsers);
            });
        }
    };

    function getAggregatedValue(currentUser, allUsers, allValues, allBrns) {
      allValues.push(currentUser.dataCount);
      allBrns.push(currentUser.data);
      if(currentUser.children.length) {
          currentUser.children.forEach(function (child) {
              var childObj = _.find(allUsers, {_id: child.childId});
              getAggregatedValue(childObj, allUsers, allValues, allBrns);
          });
        }
    }

    function getSum(allValuesArray, allBrnsArray) {
        //this is to handle special case for customers
        //as a single customer can have multiple handlers
        if (isCustomer) {
            const flatRes = _.uniq(_.flatten(allBrnsArray));
            if (!unit) {
                return flatRes.length;
            } else {
                let sum = 0;
                flatRes.forEach(function(res) {
                    sum += !isNaN(customerValueMap[res]) ? customerValueMap[res] : 0;
                })
                return sum;
            }
        }
        return _.sum(allValuesArray);
    }

    function sortTreeData(data, asc) {
        var orderType = 'asc';
        if (!asc) {
            orderType = 'desc';
        }
        if (data.nodes && data.nodes.length) {
            data.nodes = _.orderBy(data.nodes, ['totalCount'], [orderType]);
            data.nodes.forEach(function(childNode) {
                sortTreeData(childNode, asc);
            })
        }
    }

    function init() {
        allUsersList.forEach(function(user) {
            var userId = user._id.toString();
            var playType = user.playTypes[0];
            user.playTypeName =  _.find(p0.allPlayTypes, ['_id', playType]).name;
            if (unit && isCustomer && leadsData[userId] && !_.isEmpty(leadsData[userId])) {
                let options = _.last(leadsData[userId]);
                user.dataCount = options.total;
                user.data = _.slice(leadsData[userId], 0, leadsData[userId].length - 1);
                customerValueMap = {...customerValueMap, ...options.custMap};
            } else if (unit && isPortfolio) {
                let options = _.first(leadsData[userId]);
                user.dataCount = options ? options.total : 0;
                user.data = [user];
            } 
            else {
                user.dataCount = leadsData[userId] ? leadsData[userId].length : 0;
                user.data = leadsData[userId] ? leadsData[userId] : [];
            }
        })
        $scope.users = allUsersList;
        var treeUsers = $scope.users;
        treeUsers = _.filter(treeUsers, function (user) {
            return user.roleType != 'lmsAdmin';
        });
        console.log('treeUsers: ', treeUsers);
        var tree = [];
        var orgHead = _.find(treeUsers, function (user) {
            return !user.parent;
        });
        var errorCount = false, message;
        if((!orgHead.children.length || !orgHead.leafCount)){
            errorCount = true;
            message = 'Zero reportees or frontline'
        }
        var allValues = [];
        var allBrns = [];
        getAggregatedValue(orgHead,treeUsers, allValues, allBrns)
        tree.push({id:orgHead._id, email: orgHead.email, title:orgHead.name, reportees:orgHead.children.length, leafCount:orgHead.leafCount, errorCount: errorCount, message:message, playType: orgHead.playTypeName, roleType: orgHead.roleType, dataCount: orgHead.dataCount, data: orgHead.data, totalCount: getSum(allValues, allBrns), allBrns: allBrns});
        recursiveReporteeMap(tree[0], orgHead, treeUsers);
        $scope.data = tree;
        console.log('$scope.data: ', $scope.data);
        sortTreeData($scope.data[0], $scope.asc);
        $scope.$broadcast('angular-ui-tree:collapse-all');
        $scope.collapseAll();
    }

    if (!widget.includes('Portfolio Parameters') && !widget.includes('Game Changer')) {
        $scope.loading = true;
        fetchMetricDataForCustomers();
    } else {
        init(); 
    }
});
