const autoImportNgTemplateLoaderTemplate1 = require('../html/landingPage/instructions.html');

'use strict';

angular.module('bitnudgeApp')
    .config(function($stateProvider) {
        $stateProvider

            .state('instructions', {
                url: '/instructions',
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'InstructionsCtrl'
            })

    });
