'use strict';
angular.module('bitnudgeApp')
    .controller('mySwotCtrl', function ($scope, $rootScope, $location, $http, p0) {

        $scope.getSwot= {};
        $scope.getChildSwot= {};

        $scope.swotRelativeTo = 'others';
        var monthlyCycle=p0.currentMonthlyCycle;
        $scope.period = p0.allMonthlyCycles;
        $scope.selectedCycle = monthlyCycle;

        $scope.getSwotPage = function (id) {
            if (id == 'deep-dive') {
                $scope.showDeepDive = true;
                $('#swotbox-part1').hide();
                $('#swotbox-part2').show();
                $('#swotback').show();
                $scope.showSwot = true;
            }
            else if (id == 'card-view') {
                $scope.showDeepDive = false;
            }
            else {
                $('#swotbox-part1').show();
                $('#swotbox-part2').hide();
                $('#swotback').hide();
            }
        };

        $scope.goBackDeepDive = function () {
            if ($scope.currentSwotView == 'deep-dive') {
                $scope.showDeepDive = false;
            }
            else $scope.currentSwotView = 'deep-dive';
        };

        $scope.backBtnPress = function () {
            $location.path($rootScope.previousPage);
        };

        $scope.swotOthersStrengthSlide = 0;
        $scope.swotSelfStrengthSlide = 0;
        $scope.swotOthersWeaknessSlide = 0;
        $scope.swotSelfWeaknessSlide = 0;
        $scope.swotOthersOpportunitySlide = 0;
        $scope.swotSelfOpportunitySlide = 0;
        $scope.swotOthersThreatSlide = 0;
        $scope.swotSelfThreatSlide = 0;
        $scope.mySwotClass = $rootScope.operations ? 'disabledSwotStyle' : 'enabledClickSwotStyle' ;


        $scope.navigateSWOTCarousel=function(block, swotSection, direction){

            if(direction=="left"){
                if(swotSection=="s"){
                    if(block=="others"){
                        if($scope.swotOthersStrengthSlide<=0){
                            $scope.swotOthersStrengthSlide = 0;
                        }
                        else {
                            $scope.swotOthersStrengthSlide = $scope.swotOthersStrengthSlide - 1;
                        }
                    }
                    else if(block=="self"){
                        if($scope.swotSelfStrengthSlide<=0){
                            $scope.swotSelfStrengthSlide = 0;
                        }
                        else {
                            $scope.swotSelfStrengthSlide = $scope.swotSelfStrengthSlide - 1;
                        }
                    }
                }
                else if(swotSection=="w"){
                    if(block=="others"){
                        if($scope.swotOthersWeaknessSlide<=0){
                            $scope.swotOthersWeaknessSlide = 0;
                        }
                        else {
                            $scope.swotOthersWeaknessSlide = $scope.swotOthersWeaknessSlide - 1;
                        }
                    }
                    else if(block=="self"){
                        if($scope.swotSelfWeaknessSlide<=0){
                            $scope.swotSelfWeaknessSlide = 0;
                        }
                        else {
                            $scope.swotSelfWeaknessSlide = $scope.swotSelfWeaknessSlide - 1;
                        }
                    }
                }
                else if(swotSection=="o"){
                    if(block=="others"){
                        if($scope.swotOthersOpportunitySlide<=0){
                            $scope.swotOthersOpportunitySlide = 0;
                        }
                        else {
                            $scope.swotOthersOpportunitySlide = $scope.swotOthersOpportunitySlide - 1;
                        }
                    }
                    else if(block=="self"){
                        if($scope.swotSelfOpportunitySlide<=0){
                            $scope.swotSelfOpportunitySlide = 0;
                        }
                        else {
                            $scope.swotSelfOpportunitySlide = $scope.swotSelfOpportunitySlide - 1;
                        }
                    }
                }
                else if(swotSection=="t"){
                    if(block=="others"){
                        if($scope.swotOthersThreatSlide<=0){
                            $scope.swotOthersThreatSlide = 0;
                        }
                        else {
                            $scope.swotOthersThreatSlide = $scope.swotOthersThreatSlide - 1;
                        }
                    }
                    else if(block=="self"){
                        if($scope.swotSelfThreatSlide<=0){
                            $scope.swotSelfThreatSlide = 0;
                        }
                        else {
                            $scope.swotSelfThreatSlide = $scope.swotSelfThreatSlide - 1;
                        }
                    }
                }
            }
            else{
                if(swotSection=="s"){
                    if(block=="others"){
                        if($scope.swotOthersStrengthSlide>=$scope.getSwot.swotData.Strength.length-1){
                            $scope.swotOthersStrengthSlide = $scope.getSwot.swotData.Strength.length-1;
                        }
                        else {
                            $scope.swotOthersStrengthSlide = $scope.swotOthersStrengthSlide + 1;
                        }
                    }
                    else if(block=="self"){
                        if($scope.swotSelfStrengthSlide>=$scope.getSwot.swotData.Strength.length-1){
                            $scope.swotSelfStrengthSlide = $scope.getSwot.swotData.Strength.length-1;
                        }
                        else {
                            $scope.swotSelfStrengthSlide = $scope.swotSelfStrengthSlide + 1;
                        }
                    }
                }
                else if(swotSection=="w"){
                    if(block=="others"){
                        if($scope.swotOthersWeaknessSlide>=$scope.getSwot.swotData.Weakness.length-1){
                            $scope.swotOthersWeaknessSlide = $scope.getSwot.swotData.Weakness.length-1;
                        }
                        else {
                            $scope.swotOthersWeaknessSlide = $scope.swotOthersWeaknessSlide + 1;
                        }
                    }
                    else if(block=="self"){
                        if($scope.swotSelfWeaknessSlide>=$scope.getSwot.swotData.Weakness.length-1){
                            $scope.swotSelfWeaknessSlide = $scope.getSwot.swotData.Weakness.length-1;
                        }
                        else {
                            $scope.swotSelfWeaknessSlide = $scope.swotSelfWeaknessSlide + 1;
                        }
                    }
                }
                else if(swotSection=="o"){
                    if(block=="others"){
                        if($scope.swotOthersOpportunitySlide>=$scope.getSwot.swotData.Opportunity.length-1){
                            $scope.swotOthersOpportunitySlide = $scope.getSwot.swotData.Opportunity.length-1;
                        }
                        else {
                            $scope.swotOthersOpportunitySlide = $scope.swotOthersOpportunitySlide + 1;
                        }
                    }
                    else if(block=="self"){
                        if($scope.swotSelfOpportunitySlide>=$scope.getSwot.swotData.Opportunity.length-1){
                            $scope.swotSelfOpportunitySlide = $scope.getSwot.swotData.Opportunity.length-1;
                        }
                        else {
                            $scope.swotSelfOpportunitySlide = $scope.swotSelfOpportunitySlide + 1;
                        }
                    }
                }
                else if(swotSection=="t"){
                    if(block=="others"){
                        if($scope.swotOthersThreatSlide>=$scope.getSwot.swotData.Threat.length-1){
                            $scope.swotOthersThreatSlide = $scope.getSwot.swotData.Threat.length-1;
                        }
                        else {
                            $scope.swotOthersThreatSlide = $scope.swotOthersThreatSlide + 1;
                        }
                    }
                    else if(block=="self"){
                        if($scope.swotSelfThreatSlide>=$scope.getSwot.swotData.Threat.length-1){
                            $scope.swotSelfThreatSlide = $scope.getSwot.swotData.Threat.length-1;
                        }
                        else {
                            $scope.swotSelfThreatSlide = $scope.swotSelfThreatSlide + 1;
                        }
                    }
                }
            }
        }

        $scope.analyzeKPI = function(kpi) {
            console.log("TODO: analyze kpi:", kpi);
        };

        $scope.kpiHeader="KPI " + $rootScope.strings.SWOT_FULL;
        $scope.getSwot.showActivitySwot=function(kpi){
            $scope.showActivity = true;
            if($scope.getChildSwot)
                $scope.getChildSwot.selectedKPI = kpi
        };
        $scope.hideActivitySwot=function(){
            $scope.showActivity= false;
        };

        $scope.$watch('getSwot.mySwot', function(obj){
            if($scope.getSwot.mySwot){
                $scope.getSwot.mySwot($scope.selectedCycle._id);
                $scope.getSwot.activitySwot($scope.selectedCycle._id);
            }
        })
    });
