'use strict';
angular.module('bitnudgeApp')
    .controller('gpSimulationCtrl', function ($scope, $timeout, $http, $rootScope, Auth, toaster, $state, getGPService, p0,pointsMultiplier, $stateParams, userAnalyticsService) {

    var STATUS_INVALID = 0;
    var STATUS_VALID = 1;

    $scope.me = Auth.getCurrentUser();
    $scope.kpis = p0.allKpis;
    var userAnalytics = {};
    var goals = [];
    $scope.showValue = false;
    if(p0.config.targetPeriod == 'month'){
        $scope.showValue = true;
    }
    $scope.getGP = function(){
        var userId = $scope.me._id;
        userAnalyticsService.fetchUserAnalytics(userId, p0.currentMonthlyCycle._id).then(function(userAnalytic){
            userAnalytics = userAnalytic;
            getGPService.getGoal(userId,p0.currentMonthlyCycle, p0.config.targetPeriod == 'month')
                .then(function(result){
                    goals = result;
                    $scope.productGroups = [];
                    var groupLength = 3;
                    for(var i=0;i<Math.floor(goals.length/groupLength);i++){
                        var group = [];
                        for(var j=0;j<groupLength;j++){
                            group.push(goals[(i*groupLength)+j]);
                        }
                        $scope.productGroups.push(group);
                    }
                    if(goals.length % groupLength != 0){
                        var group = [];
                        for(j=(i*groupLength);j<goals.length;j++){
                            group.push(goals[j]);
                        }
                        $scope.productGroups.push(group);
                    }
                    _.forEach(goals, function(goal){
                        goal.originalGameplanPercentage = goal.gameplanPercentage;
                    });
                    $scope.initSimulation(function(){
                        $scope.updateSimulation();
                    });
                });
        });
    };

    $scope.getGP();

    $scope.initSimulation = function(callback) {
        $http.post('/api/gameplans/getSimulationData', {userId: $scope.me._id}).then(function(simulation) {
            simulation=simulation.data;
            var objectives = [{name: 'Badge acquisition', value: 'badge_acquisition'},
                {name: 'Competition', value: 'competition'}];
            if(p0.config.incentive){
                objectives.push({name: 'Incentive', value: 'incentive'})
            }
            $scope.simulationEngine={
                objectives:objectives,
                getPointsOfKpi:function(kpiId,value,target){
                    var currentKpi  = p0.getKpi(kpiId);
                    var multiplier = _.filter(currentKpi.pointsMultipliers,{role:$scope.me.organizationalTag.jobRole._id});
                    //transformation based on the new format of pointsFunction. Now works based on percentageCompleted
                    if(target == 0){return 0;}
                    return pointsMultiplier.pointsFunction(value,multiplier,'value',target);
                },
                getCurrentPoints:function(objective, subObjective){
                    for(var weightedScore=0, sumWeights = 0, i=0; i<goals.length; i++){
                        var goal = goals[i];
                        var selectedKpiId=goals[i].kpiId,weightObj,weight;
                        var selectedKpi = _.find($scope.kpis, function(kpi){
                            return kpi._id == selectedKpiId
                        });
                        if(selectedKpi)
                            weightObj=_.find(selectedKpi.kpiWeightage,{role:$scope.me.organizationalTag.jobRole._id});
                        weight = weightObj ? weightObj.weightage : 1;
                        sumWeights += weight;
                        if(goal.newGpValue == null || goal.newGpValue == undefined || isNaN(goal.newGpValue)){
                            goal.newGpValue = 0;
                        }
                        var points;
                        if(p0.config.targetPeriod == 'day' || subObjective.badgeType == 'level'||subObjective.badgeCategory == 'level' ){
                            if(p0.config.targetPeriod == 'day' && goal.gameplanTarget.value == 0 && goal.target.value == 0){
                                continue;
                            }
                            points = goal.gameplanPercentage;
                        } else{
                            points = this.getPointsOfKpi(goal.kpiId, goal.newGpValue, goal.target.value);
                        }
                        weightedScore += points * weight;
                    }
                    if(p0.config.targetPeriod == 'day' && (objective.value == 'competition' || (subObjective.badgeType != 'level' && subObjective.badgeCategory != 'level'))){
                        var dailyPointsPlanned = (weightedScore / sumWeights) * p0.config.overallPtsMultiplier;
                        var currentPoints;
                        if(objective.value == 'competition'){
                            currentPoints = userAnalytics.businessPoints.value;
                        }else{
                            currentPoints = userAnalytics.points.value;
                        }
                        return currentPoints + (dailyPointsPlanned * $rootScope.daysLeft);
                    }else{
                        return weightedScore / sumWeights;
                    }
                },
                getCurrentIncentiveStatus:function(){
                    var overallPercentageCompleted = 0;
                    for(var i=0; i<goals.length; i++){
                        var goal = goals[i];
                        var selectedKpi = _.find($scope.kpis, function(kpi){
                            return kpi._id == goal.kpiId;
                        });
                        var weightObj
                        if(selectedKpi)
                            weightObj=_.find(selectedKpi.kpiWeightage,{role:$scope.me.organizationalTag.jobRole._id});
                        var weight = weightObj ? weightObj.weightage : 1;

                        var percentage=  goal.newGpValue/goal.target.value;
                        overallPercentageCompleted = overallPercentageCompleted + percentage * weight;
                    }
                    return Math.round((overallPercentageCompleted * 100) - p0.myIncentive.threshold);
                },
                getObjectivePoints:function(objective,subObjective){
                    return subObjective.points;
                },
                getObjectiveStatus:function(currentPoints,objectivePoints){
                    // returns 0 for invalid, 1 for close and 2 for valid
                    var res={status:0, gap:parseInt(objectivePoints-currentPoints)};
                    if(currentPoints>objectivePoints){
                        res.status=STATUS_VALID;
                        return res;
                    }
                    else {
                        res.status=STATUS_INVALID;
                        return res;
                    }
                }
            };
            $scope.objective=$scope.simulationEngine.objectives[0];
            _.each(simulation['Badge acquisition'],function (subObjective) {
                if($scope.simulationEngine.getObjectiveStatus($scope.simulationEngine.getCurrentPoints($scope.objective, subObjective),subObjective.points).status == STATUS_VALID){
                    simulation['Badge acquisition'] = _.reject(simulation['Badge acquisition'], function(item) {
                        return item === subObjective;
                    });
                }
            });
            $scope.subObjectiveCompetition = simulation['Competition'];
            $scope.subObjectiveIncentive= [{name: "Qualify for incentive", points: 1, _id: 1}];
            $scope.subObjectiveBadgeAcquisition=simulation['Badge acquisition'];

            if($rootScope.executeSimulationForGameplan){
                $rootScope.executeSimulationForGameplan = false;
                $scope.simulationForLeaderBoardGamePlan();
            }
            if(!$rootScope.subObjectiveId) {
                $scope.subObjectiveHolder = $scope.subObjectiveBadgeAcquisition;
                $scope.subObjective =  $scope.subObjectiveHolder[0];
            }
            if($rootScope.nearmetogp){
                $rootScope.nearmetogp = false;
                $scope.simulationForNearMeBadges();
            }
            if($stateParams.userId){
                $scope.objective = $scope.simulationEngine.objectives[1];
                $scope.subObjectiveHolder = $scope.subObjectiveCompetition;
                $scope.subObjective = _.find($scope.subObjectiveHolder,function (subObjective) {
                    return subObjective._id.toString() == $stateParams.userId;
                });
            }
            $scope.initSimulationValue();
            callback();
        });
    };
    $scope.isUserAction = false;
    $scope.initSimulationValue = function(subObjective){
        if(subObjective){
            $scope.subObjective = subObjective;
        }
        $scope.isUserAction = false;
        $scope.gameplanGapText = "";
        for(var i=0;i<goals.length;i++){
            goals[i].gameplanPercentage = (goals[i].gameplanTarget.value/goals[i].target.value*100).toFixed(2);
            if(!goals[i].gameplanPercentage || isNaN(goals[i].gameplanPercentage)){
                goals[i].gameplanPercentage = 0;
            }
        }
        if($scope.subObjective && $scope.subObjective.userId){
            $scope.updateSimulation();
        }
        else if($scope.subObjective && $scope.subObjective._id){
            $scope.updateSimulation();
        }
        setTimeout(function () {
            $scope.isUserAction = true;
        },100);
    };

    $scope.onCycleChange=function(){
        $scope.getGP();
    };



    $scope.onObjectiveChange = function (objective) {
        $scope.objective = objective;
        var obj = $scope.objective.name;
        if (obj == "Badge acquisition") {
            $scope.subObjectiveHolder = $scope.subObjectiveBadgeAcquisition;
            $scope.subObjective =  $scope.subObjectiveHolder[0];
        } else if (obj == "Competition") {
            $scope.subObjectiveHolder = $scope.subObjectiveCompetition;
            $scope.subObjective =  $scope.subObjectiveHolder[0];
        }
        else if(obj =="Incentive"){
            $scope.subObjectiveHolder = $scope.subObjectiveIncentive;
            $scope.subObjective= $scope.subObjectiveHolder[0];
        }
        $scope.initSimulationValue();
    };

    $scope.saveGamePlan = function(){
        for(var i = 0; i < goals.length; i++) {
            goals[i].originalGameplanPercentage = goals[i].gameplanPercentage;
            goals[i].gameplanRunRate = (((goals[i].gameplanTarget.value-goals[i].achievedValue)/$rootScope.daysLeft)*5).toFixed(2);
            var newValue = (goals[i].gameplanPercentage*goals[i].target.value/100)* goals[i].denom;
            $http.post('/api/gamePlans/setGP/cycle/'+p0.currentMonthlyCycle._id+'/kpi/'+goals[i].kpiId, {gameplanTarget:{value: newValue}}).then(function(goal) {
                goal=goal.data;
                var gpSimulation = {
                    set:true,
                    objectiveName: $scope.objective.name,
                    subObjective :{
                        name: $scope.subObjective.name,
                        extras:{}
                    }
                };
                if($scope.objective.name == 'Competition'){
                    gpSimulation.subObjective.extras.userId = $scope.subObjective._id;
                }else if($scope.objective.name =='Badge acquisition'){
                    gpSimulation.subObjective.extras.badgeCategory = $scope.subObjective.badgeCategory;
                    gpSimulation.subObjective.extras.badgeType = $scope.subObjective.badgeType;
                    gpSimulation.subObjective.extras.metric = $scope.subObjective.metric;
                    gpSimulation.subObjective.extras.metricValue = $scope.subObjective.metricValue;
                }
                $http.post('/api/gamePlans/setSimulation',{gpSimulation:gpSimulation}).then(function(){});
            });
            goals[i].gameplanTarget.value= goals[i].newGpValue;
        }
        toaster.pop('success', "Save", "Your GamePlan is successfully saved");
    };

    $scope.cancelGamePlan = function(){
        $scope.initSimulationValue();
    };

    $scope.enableSave = function () {
        for(var i=0;i< goals.length;i++){
            if((Math.abs(goals[i].originalGameplanPercentage - goals[i].gameplanPercentage))/goals[i].originalGameplanPercentage*100 >= 5 ){
                return true;
            }
        }
        return false;
    };

    $scope.updateSimulation = function() {
        var result={status: 0, gap: 0};
        if($scope.subObjective!= undefined && $scope.subObjective.name!= "Qualify for incentive") {
            result = $scope.simulationEngine.getObjectiveStatus($scope.simulationEngine.getCurrentPoints($scope.objective, $scope.subObjective), $scope.simulationEngine.getObjectivePoints($scope.objective, $scope.subObjective));
        }
        else if($scope.subObjective!= undefined && $scope.subObjective.name== "Qualify for incentive"){
            result = {status: $scope.simulationEngine.getCurrentIncentiveStatus() > 0, gap: -1* $scope.simulationEngine.getCurrentIncentiveStatus()};
        }
        $scope.gameplanGap = -1 * result.gap;

        if (result.status == STATUS_VALID) {
                $scope.simulationBorderStyle= 'validSimulation-border-style';
                $("#save_simulation").show();
                $("#reset_simulation").show();
                if($scope.enableSave()){
                    $scope.disableSave = '';
                }
                else {
                    $scope.disableSave = 'disable-save';
                }
                $scope.gameplanGapText = "Simulation is valid";
                $("#gp-signal").css({"background":"#E0EEC8"});
                $(".box-color").css({"background":"#E0EEC8"});
                $(".box-color").removeClass('swot-red');
                $(".box-color").addClass('swot-green');

            }
            else{
            	$scope.simulationBorderStyle= 'invalidSimulation-border-style';
            	$scope.gameplanGapText = "Simulation is invalid stretch gameplan further.";
                $scope.disableSave = 'disable-save';
                $("#gp-signal").css({"background":"#F0D7CF"});
                $(".box-color").css({"background":"#F0D7CF"});
                $(".box-color").removeClass('swot-green');
                $(".box-color").addClass('swot-red');

            }
    };

    $scope.goToGoalSummary = function(){
        $state.go('userFlow.gameplan', {selectedPlayerId: $scope.me._id});
    };

    $scope.showInfoModal = function(selectedGoal, type){
        $scope.selectedGoal=selectedGoal;
        getGPService.showModals($scope.me,$scope.selectedGoal, type);
    };

    $scope.showLegendInfoModal = function(){
        getGPService.showLegendModal();
    };

    $scope.goToAnalyze=function(goal){
        $state.go('userFlow.gameplanAnalyze',  {selectedKPIId: goal.kpiId});
    };

    $scope.goalSummarySwot=function(goal){
        if(!goal)   return ;
            $scope.swotCategory = goal.swot;
        if ($scope.swotCategory=="Threat") {
            return "custom-red";
        }else if ($scope.swotCategory=="Strength") {
            return "custom-green";
        }else if ($scope.swotCategory=="Weakness") {
            return "custom-yellow";
        }else if ($scope.swotCategory=="Opportunity") {
            return "custom-blue";
        }
    };

    $scope.simulationForLeaderBoardGamePlan = function(){
        $scope.objective = _.find($scope.simulationEngine.objectives, {name:$rootScope.objectiveName});
        $scope.onObjectiveChange($rootScope.subObjectiveId);
        $scope.initSimulationValue();
    };

    $scope.simulationForNearMeBadges = function(){
        $scope.objective = _.find($scope.simulationEngine.objectives, {name:$rootScope.objectiveName});
        $scope.onObjectiveChange($rootScope.subObjectiveId);
        $scope.initSimulationValue();
    }
});
