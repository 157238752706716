'use strict';

angular.module('bitnudgeApp')
    .controller('cyclesCtrl', function ($stateParams, $scope, $http,  Auth, User, $location, DTOptionsBuilder, DTColumnDefBuilder, toaster, p0) {

        $scope.cycles = p0.allCycles;
        //socket.syncUpdates('cycles', $scope.cycles);

        $scope.dtOptionsCyclesList = DTOptionsBuilder.newOptions().withPaginationType('full_numbers').withOption('order', [0, 'asc']);
        $scope.dtColumnDefsCyclesList = [
            DTColumnDefBuilder.newColumnDef(0),
            DTColumnDefBuilder.newColumnDef(1),
            DTColumnDefBuilder.newColumnDef(2),
            DTColumnDefBuilder.newColumnDef(3),
            DTColumnDefBuilder.newColumnDef(4),
            DTColumnDefBuilder.newColumnDef(5),
            DTColumnDefBuilder.newColumnDef(6)
        ];

        $('.date-picker').datepicker({
            autoclose: true,
            todayHighlight: true
        });

        // monthly, quarterly, halfyearly, yearly, campaign
        $scope.cycleTypes = [
            {"name": "Monthly", value: "monthly"},
            {"name": "Quarterly", value: "quarterly"},
            {"name": "Half Yearly", value: "halfyearly"},
            {"name": "Yearly", value: "yearly"},
            {"name": "Campaign", value: "campaign"}
        ];

        $scope.addCycle = function (cycle) {
            $http.post('/api/cycles', {
                name: cycle.name,
                info: cycle.info,
                startDate: new Date(cycle.startDate),
                endDate: new Date(cycle.endDate),
                cycleType: cycle.cycleType.value,
                active: cycle.active
            }).then(function (response) {
                var data = response.data;
                var status = response.status;
                var statusText = response.statusText;
                var headers = response.headers;
                var config = response.config;
                $location.path('/admin/listCycles');
                toaster.pop('success', "Cycle Creation", "New Cycle Added.");
            }).catch(function (response) {
                var data = response.data;
                var status = response.status;
                var statusText = response.statusText;
                var headers = response.headers;
                var config = response.config;
            });
        };

        $scope.updateCycle = function (cycle) {
            $http.post('/api/cycles',{id: cycle._id}, {
                name: cycle.name,
                info: cycle.info,
                startDate: new Date(cycle.startDate),
                endDate: new Date(cycle.endDate),
                cycleType: cycle.cycleType.value,
                active: cycle.active
            }).then(function (response) {
                var data = response.data;
                var status = response.status;
                var statusText = response.statusText;
                var headers = response.headers;
                var config = response.config;
                $location.path('/admin/listCycles');
                toaster.pop('success', "Cycle Update", "Cycle Updated.");
            }).catch(function (response) {
                var data = response.data;
                var status = response.status;
                var statusText = response.statusText;
                var headers = response.headers;
                var config = response.config;
            });
        };

        $scope.deleteCycle = function (cycle) {
            $http.post('/api/cycles/archive',{cycleId:cycle._id}).then(function (response) {
                var data = response.data;
                var status = response.status;
                var statusText = response.statusText;
                var headers = response.headers;
                var config = response.config;
                $location.path('/admin/listCycles');
                toaster.pop('success', "Cycle Deletion", "Cycle Deleted.");
            }).catch(function (response) {
                var data = response.data;
                var status = response.status;
                var statusText = response.statusText;
                var headers = response.headers;
                var config = response.config;
            });
        };

        $scope.formatDate = function (date, type) {
            var Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            var newDate = new Date(date);
            if (type == "date") {
                var reqDate = newDate.getDate() + "-" + Months[newDate.getMonth()] + "-" + newDate.getFullYear();
                //console.log(newDate.getFullYear() + "-" + newDate.getMonth() + "-" + newDate.getDate());
            } else {
                reqDate = newDate.toLocaleTimeString().split(" ")[0].slice(0, 5) + newDate.toLocaleTimeString().split(" ")[1];
            }
            return reqDate;
        };

        $scope.formatDateForDatePicker = function (date, type) {
            //var Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            var newDate = new Date(date);
            if (type == "date") {
                var reqDate = newDate.getFullYear() + "-" + newDate.getMonth() + "-" + newDate.getDate()
            } else {
                reqDate = newDate.toLocaleTimeString().split(" ")[0].slice(0, 5) + newDate.toLocaleTimeString().split(" ")[1];
            }
            return reqDate;
        };

        $scope.toTitleCase = function(string) {
            return string.replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        };

        $scope.getYesNo = function(value) {
            if(value==true){
                return "Yes";
            }
            else{
                return "No"
            }
        };

        /*$scope.edit = function(cycle) {
            window.location.href = "/admin/editCycle/"+cycle._id;
            $scope.isEdit = true;
            $scope.editCycle(cycle);
        };

        $scope.editCycle = function(cycle) {
            console.log("in editCycle");
            console.log("cycle :- " + cycle);
        }*/

        if(typeof $stateParams.cycleId !== 'undefined') {
            $scope.cycleId = $stateParams.cycleId;
            $scope.cycle = p0.getCycle($stateParams.cycleId);
            //socket.syncUpdates('cycle', $scope.cycle);
        }

    });
