'use strict';
angular.module('bitnudgeApp')
    .controller('rhsAnalyticsL2ActivityCtrl', function ($scope, sidebarVariables, p0, rhsAnalyticsL2Activity, Auth,$rootScope) {

        let self = this;
        this.init = function(){
            $scope.me = Auth.getCurrentUser();
            $scope.meetingsExist = _.find(p0.myActivities,{fieldName:'meeting'});
            $scope.isMeetings = false;
            $scope.perReportee = false;
            $scope.monthName = p0.currentMonthlyCycle.name.substring(0,3);
            $scope.weekName = p0.currentWeeklyCycle ? p0.currentWeeklyCycle.name : '';
            $scope.lastSync = $rootScope.lastUpdatedAtDate;
            $scope.config = p0.config;
			$scope.strings = p0.strings;

            //sidebars variables
            $scope.sidebarVariables = sidebarVariables;
            $scope.kpi = sidebarVariables.kpi;
            $scope.user = sidebarVariables.user;

            //cycle
            $scope.period = 'month';
            $scope.cycle = p0.currentMonthlyCycle;

        }
        $scope.changeActivity = function(){
            $scope.setData();
        };

        $scope.$watch('sidebarVariables.kpi',function(){
            $scope.kpi = sidebarVariables.kpi;
            $scope.setData();
        });
        $scope.$watch('sidebarVariables.user',function(){
            $scope.user = sidebarVariables.user;
            $scope.setData();
        });

        $scope.selectCycle = function (period) {
            $scope.period = period;
            switch (period){
                case 'month':
                    $scope.cycle = p0.currentMonthlyCycle;
                    break;
                case 'week':
                    $scope.cycle = p0.currentWeeklyCycle;
                    break;
                case 'day':
                    $scope.goBack();
                    break;
            }
            $scope.setData();
        };

        $scope.setData = function() {
            return rhsAnalyticsL2Activity.getData($scope.user, $scope.kpi, $scope.cycle,$scope.type,$scope.isMeetings,$scope.perReportee).then(function (data) {
                $scope.data = data;
            });
        };

        $scope.goBack = function(){
            $scope.changeView('lvl1');
        };

        this.init();
    });
