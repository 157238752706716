const autoImportNgTemplateLoaderTemplate1 = require('./../html/component/carouselComponent.html');

"use strict";
angular.module("bitnudgeApp").component("carouselComponent", {
	templateUrl: autoImportNgTemplateLoaderTemplate1,
	bindings: {
		thumbnails: "<",
		thumbnailselected: "<"
	},
	controller: "carouselComponentCtrl"
});
