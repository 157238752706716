'use strict';
angular.module('bitnudgeApp')
    .controller('addBulkLeadsSubmissionStatusController', function($http, $scope, Auth, Upload, $location, toaster) {
        //Function to submit lead excel
        $scope.onFileSelectLead = function() {
            if ($scope.bulkLeadSubStatus == undefined) {
                toaster.pop('error', "Submission.", "Select File to Upload.");

            } else {
                var fileName = $scope.bulkLeadSubStatus[0].name.split(".");
                var fileType = fileName[fileName.length - 1].toLowerCase();
                if (fileType == "xlsx") {
                    Upload.upload({
                        url: '/api/leads/import/leadsSubStatus/excel',
                        data: {
                            userId: Auth.getCurrentUser()._id
                        },
                        file: $scope.bulkLeadSubStatus[0],
                        progress: function(e) {}
                    }).then(function(data, status, headers, config) {
                        toaster.pop('success', "Excel uploaded", "Lead submission status creation successfully finished.");
                    });
                } else {
                    toaster.pop('error', "Submission.", "Upload '.xlsx' file only.");
                }

            }
        }

    })

