const autoImportNgTemplateLoaderTemplate1 = require('../html/pointsBoard.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('pointsBoard', function(){
        return {
            name: 'pointsBoard',
            scope: {
                playerData:  '=',
                showAnalyze: '@'
            },
            controller: 'pointsBoardCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
