const autoImportNgTemplateLoaderTemplate1 = require('../html/inputRenderer.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('inputRenderer', function () {
        return {
            name: 'inputRenderer',
            scope: {
                item: '=',
                form: "=",
				context: "<"
            },
            controller: 'inputRendererCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
