// 'use strict';
angular.module('bitnudgeApp')
    .controller('analyticsController', function ($scope, $rootScope, $stateParams, NgMap, $location, $compile, $http, Auth, toaster, $timeout, $interval, DTOptionsBuilder, DTColumnDefBuilder,$state, p0) {
        if($stateParams.cycleId && $stateParams.cycleId != ""){
            $scope.selectedParentCycleId = $stateParams.cycleId; 
        } 
        $scope.currentUser = Auth.getCurrentUser();
         
        // Enabling Game Analytics and Other analytics for development
        $scope.config = p0.config;
        $scope.reportsToShow = false;
        if($scope.config.showOneClickReport || $scope.config.showTrendReport || $scope.config.showThresholdReport){
            $scope.reportsToShow = true;
        }
        $scope.isManager = $rootScope.isManager;
        var tabNames = ['myZone', 'playerComparison','gameAnalytics', 'otherAnalytics', 'maps'];
        var tabIndex = tabNames.indexOf($stateParams.tabName);
        //Set active tab
        if(tabIndex === -1){
            $scope.selectedTabIndex = 3;
        }else $scope.selectedTabIndex = tabIndex;
        if($rootScope.isSeniorManager){
            $scope.myComparison = false;
            $scope.comparisonCards = [0, 0, 0, 0];
        }
        else if($stateParams.myComparison){
            if($stateParams.myComparison == 'true'){
                $scope.myComparison = true;
                $scope.comparisonCards = [0, 0, 0];
            }else{
                $scope.myComparison = false;
                $scope.comparisonCards = [0, 0, 0, 0];
            }
        }else{
            $scope.myComparison = true;
            $scope.comparisonCards = [0, 0, 0];
        }
        if($stateParams.roleType == 'manager')
            $scope.managerEnabled = true;
        else if($stateParams.roleType == 'frontline')
            $scope.managerEnabled = false;
        else{
            if($rootScope.isFrontline)
                $scope.managerEnabled = false;
            else
                $scope.managerEnabled = true;
        }

        var setStateParams = function () {
            const stateParams = {
                myComparison: $stateParams.myComparison,
                tabName:tabNames[$scope.selectedTabIndex]
            };
            $state.go('userFlow.analytics', stateParams, {notify: false});
        };

        $scope.onTabChange = function () {
            setStateParams();
        };

        $scope.strings =  $rootScope.strings;
        $scope.onBackPressed = function(){
            if($scope.fnLeaderboardBack){
                $scope.fnLeaderboardBack=false;
            }
            $location.path($rootScope.previousPage);
        };
        $scope.showSwotLegend = true;
        $scope.setShowSwotLegendStatus = function (status) {
            $scope.showSwotLegend = status;
        };

        $scope.showAnalyticsTab = function(selectedTab){
            var compileTab;
            if ($(selectedTab).is(':empty')) {
                switch (selectedTab) {
                    case "#my_SWOT":
                        compileTab = $compile("<div my-swot></div>")($scope);
                        break;
                    case "#my_comparison":
                        compileTab = $compile('<div player-comparison is-own="true" player-status="[0, 0, 0]"></div>')($scope);
                        break;
                    case "#myForm":
                        compileTab = $compile("<div my-form></div>")($scope);
                        break;
                    case "#myFormSrManager":
                        compileTab = $compile("<div sr-manager-form></div>")($scope);
                        break;
                    case "#player_comparison":
                        compileTab = $compile('<div player-comparison is-own="false" player-status="[0, 0, 0, 0]"></div>')($scope);
                        break;
                    case "#frontlineSWOT":
                        compileTab = $compile("<div frontline-swot></div>")($scope);
                        break;
                }
                $(selectedTab).append(compileTab);
            }
        };
        $scope.comparePlayersFromLB = $rootScope.comparePlayersFromLB;
        $scope.onBackPressed = function(){
            if($rootScope.navigateToCompleteList) {
                $scope.comparePlayersFromLB = false;
            }
            $location.path($rootScope.previousPage);
        }

        if($rootScope.playersFromLB) {
            if (!$rootScope.myComparison || $rootScope.myComparison == 'false') {
                $timeout(function () {
                    $('#playerComparison').trigger('click');
                })
            }
            else {
                $timeout(function () {
                    $('#myComparison').trigger('click');
                })
            }
        }

        $scope.open={};
        $rootScope.currentState = {tab: "My SWOT", now: new Date()};
        if($rootScope.hashLocation == 'otherKeyIndicator'){
            $timeout(function () {
                $('#showOki').trigger('click');
            }, 100);
        }
    });
