const autoImportNgTemplateLoaderTemplate1 = require('../../html/uploadDocumentModel.html');

"use strict";

angular.module("bitnudgeApp").controller("editableComponentCtrl", function($scope, $rootScope, Auth, DocumentService, $mdDialog, toaster) {
    $scope.getCurrentUser = Auth.getCurrentUser;
    $scope.documentForm = {};
    $scope.docMetadata = {};
    this.$onChanges = changes => {
        if (changes.docMetadata) {
            $scope.docMetadata = changes.docMetadata.currentValue;
            if (!$scope.docMetadata) {
                $scope.docMetadata = {};
            }
        }
        if (changes.isNew) {
            $scope.isNew = changes.isNew.currentValue;
            $scope.docMetadata.isNew = $scope.isNew;
        }
        if (changes.context) {
            $scope.context = changes.context.currentValue;
        }

        if (changes.cui) {
            $scope.cui = changes.cui.currentValue;
            $scope.docMetadata.cui = $scope.cui;
        }

        if (changes.workflowId) {
            $scope.workflowId = changes.workflowId.currentValue;
            $scope.docMetadata.workflowId = $scope.workflowId;
        }

        if (changes.docId) {
            $scope.docId = changes.docId.currentValue;
            $scope.docMetadata.docId = $scope.docId;
        }

        if (changes.documentTypeId) {
            $scope.documentTypeId = changes.documentTypeId.currentValue;
        }

        if (changes.fileIds) {
            $scope.docMetadata.files = changes.fileIds.currentValue;
        }
        if (changes.fileNames) {
            $scope.fileNames = changes.fileNames.currentValue;
        }
        getDocumentTypes();
    };

    const getDocumentTypes = () => {
        if ($scope.context) {
            DocumentService.getDocumentTypes($scope.context)
                .then(types => {
                    if ($scope.documentTypeId) {
                        const defaultSelectedType = types.find(type => {
                            return type._id === $scope.documentTypeId
                        })
                        if (defaultSelectedType) {
                            $scope.documentTypes = [defaultSelectedType]
                        } else {
                            $scope.documentTypes = types;
                        }
                    } else {
                        $scope.documentTypes = types;
                    }
                    $scope.selectedDocumentType = $scope.documentTypes[0];
                    $scope.uploadDisabled = false;
                })
                .catch(err => {
                    console.log(err.message);
                });
        }
    };

    $scope.changeDocumentType = selectedType => {
        if (selectedType) {
            $scope.uploadDisabled = false;
            $scope.docMetadata.documentType = selectedType;
            DocumentService.getEditableViewableElems({ docId: $scope.docId, documentTypeId: selectedType.uid, isNew: $scope.isNew })
                .then(response => {
                    $scope.docMetadata.prerequisites = response.metaData;
                    $scope.fileValidations = response.fileMetadata;
                })
                .catch(err => {
                    console.log(err.message);
                });
        } else {
            $scope.uploadDisabled = true;
        }
    };

    //---angular material -----
    $scope.searchList = (query, list, key, key2) => {
        var results = query ? list.filter(searchComponent(query, key, key2)) : list;
        return results;
    };


    $scope.callUMS = () => {
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate1,
            controller: "uploadDocumentCtrl",
            fullscreen: true,
            resolve: {
                parameters: function() {
                    return {
                        document: $scope.selectedDocumentType,
                    };
                }
            }
        }).then(({ fileIds, fileNames }) => {
            $scope.docMetadata.files = fileIds;
            $scope.fileNames = fileNames
        }, function() {});
    };

    $scope.editCancel = () => {
        $scope.$emit("dms:information:show", { edit: true });
    };
    $scope.addCancel = () => {
        $scope.$emit("dms:information:show", { add: true });
    };

    $scope.editDoc = () => {
        // $scope.$emit("editDocument", { data: $scope.docMetadata });
        $scope.docMetadata.context = $scope.context;
        DocumentService.update($scope.docMetadata)
            .then(success => {
                if (success) {
                    //TODO: please handle this event to get the recent document
                    $rootScope.$broadcast("dms:info:refresh");
                    $scope.$emit("dms:information:show", { edit: true });
                    toaster.pop("success", "Document Updated Successfully");
                }
            })
            .catch(errs => {
                errs.forEach(err => {
                    toaster.pop("error", "Error in document save", err.message);
                });
            });
    };
    $scope.saveDoc = () => {
        $scope.docMetadata.context = $scope.context;
        DocumentService.save($scope.docMetadata)
            .then(success => {
                if (success) {
                    if ($scope.context === "customer") {
                        //$rootscope broadcast because listener is in another controller which is not parent or child
                        $rootScope.$broadcast("documentAdded");
                    }
                    //TODO: please handle this event to get the recent document
                    $scope.$emit("dms:view:refresh", { context: $scope.docMetadata.context, documentType: $scope.docMetadata.documentType });
                    toaster.pop("success", "Document Added Successfully");

                    //Reset the form to untouched
                    if ($scope.docMetadata.prerequisites.length) {
                        $scope.docMetadata.prerequisites.forEach(item => item.value = null);
                    }
                    $scope.documentForm.$setUntouched();
                    $scope.docMetadata.files = [];
                }
            })
            .catch(errs => {
                errs.forEach(err => {
                    toaster.pop("error", "Error in document save", err.message);
                });
            });
    };

    $scope.$on('dms:select:documentType', (evt, data) => {
        if (data.documentType) {
            $scope.changeDocumentType(data.documentType)
        }
    })
});