const autoImportNgTemplateLoaderTemplate1 = require('../../html/archiveDocumentModal.html');
const autoImportNgTemplateLoaderTemplate2 = require('../../html/uploadDocumentModel.html');

"use strict";
const { USER_PERMISSIONS } = require("../../types/permissions");
angular
    .module("bitnudgeApp")
    .controller("customerCtrl", function(
        _,
        $scope,
        $state,
        $stateParams,
        Auth,
        p0,
        CustomerService,
        Upload,
        toaster,
        UserActivities,
        AlertsService,
        DocumentService,
        $mdDialog,
        $rootScope
    ) {
        var cui = $stateParams.customerId;
        $scope.isSeniorManager = $rootScope.isSeniorManager;
        $scope.isMiddleManager = $rootScope.isMiddleManager;
        $scope.customerContext = "customer";
        $scope.products = [];
        $scope.updateDoc = false;
        $scope.me = Auth.getCurrentUser();
        $scope.documentStatus = 'active';
        $scope.documentProductId = 'all';
        $scope.docs = [];
        $scope.customer360Loaded = false;
        $scope.selected = {};
        $scope.selected.isPrimaryFields = true;
        $scope.selected.customerRemark = null;
        $scope.selected.isReferredFromAgency = false;
        $scope.selected.isRemarkUpdated = false;
        $scope.selected.isFlagUpdated = false;
        $scope.selected.verificationTimeLine = [];


		$scope.searchNonTaggedCustomer = $rootScope.permissions.includes(USER_PERMISSIONS.searchNonTaggedCustomer);
		$scope.createCustomer = $rootScope.permissions.includes(USER_PERMISSIONS.createCustomer);
        $scope.changeCustomerSegment = $rootScope.permissions.includes(USER_PERMISSIONS.changeCustomerSegment);

		$scope.viewTaggedCustomer = $rootScope.permissions.includes(USER_PERMISSIONS.viewTaggedCustomer);
		$scope.exportCustomers = $rootScope.permissions.includes(USER_PERMISSIONS.exportCustomers);
		$scope.createServiceRequest = $rootScope.permissions.includes(USER_PERMISSIONS.createServiceRequest);
		$scope.createLead = $rootScope.permissions.includes(USER_PERMISSIONS.createLead);
		$scope.createReferralLead = $rootScope.permissions.includes(USER_PERMISSIONS.createReferralLead);


		$scope.alertsLogs = [];
        $scope.onlyEditableHeaders = ['customerSegment', 'premierMembershipNumber','premierMembershipStartDate'];

        //there is no configuration to be made as per the requirement due to time constraint and aligned with sprint owner
        $scope.customerSegments = [{
            name:"Premier",
            key:"Premier"
        },{
            name:"Regional Premier",
            key:"Regional Premier"
        },{
            name:"Personal",
            key:"Personal"
        }]
        $scope.documentStats = [{
                name: "Active",
                count: 0,
                id: "active"
            },
            {
                name: "Expired",
                count: 0,
                id: "expired",
                color: true
            }
        ];

        $scope.sortingObj = {
            sortKey: 'createdAt',
            sortOrder: 'asc'
        };

        $scope.switchDocStatus = function(status) {
            if (status.id !== $scope.documentStatus) {
                $scope.documentStatus = status.id;
                $scope.documentProductId = 'all';
                fetchDocuments(cui);
            }
        };

        const updateMemberShipView = ()=>{
            if($scope.selectedSegment && $scope.selectedSegment.name === "Personal"){
                $scope.showMembershipDetails = false
            }else{
                $scope.showMembershipDetails = true
            }
        }

        $scope.onSelectSegment = function(segment){
            $scope.selectedSegment = segment
            updateMemberShipView()
        }

        $scope.selectedProductDocs = function(product) {
            $scope.documentProductId = product._id;
            fetchDocuments(cui)
        };

        function prepareProductsForDocuments() {
            p0["myProducts"].forEach(function(product) {
                var productObj = {};
                productObj.name = product.name;
                productObj._id = product._id;
                $scope.products.push(productObj);
            });
            $scope.products.unshift({
                name: "All",
                _id: "all"
            });
        }
        prepareProductsForDocuments();

        $scope.editCustomer = function(customer) {
            $state.go("userFlow.editCustomer", {
                customerId: cui
            });
        };

        const fetchDocuments = function(cui) {
            // getDocuments
            DocumentService.getDocuments({ cui, onlyActive: false, status: $scope.documentStatus, productId: $scope.documentProductId, context: 'customer' })
                .then(documents => {
                    $scope.docs = documents;
                })
                .catch(err => {
                    toaster.pop("warning", "Document could not be fetched", err.message);
                });
        };

        const getDocumentPillsCount = () => {
            DocumentService.getDocumentPillsCount(cui)
                .then(result => {
                    $scope.documentStats = result.data;
                })
                .catch(err => {
                    toaster.pop("warning", "Document stats could not be fetched", err.message);
                });
        }

        const archiveDocument = (document, notes) => {
            CustomerService.archiveDocument(document._id, notes).then(function(result) {
                if (result.status) {
                    _.remove($scope.docs, function(doc) {
                        return doc._id === document._id;
                    });
                    toaster.pop("success", "Document archived successfully");
                    fetchDocuments(cui);
                } else {
                    toaster.pop("error", "Error in document archive", result.errors[0].message);
                }
            });
        };

        $scope.removeDocument = function(document) {
            if (!document || !document._id) {
                return;
            }
            $mdDialog
                .show({
                    templateUrl: autoImportNgTemplateLoaderTemplate1,
                    controller: "archiveDocument",
                    clickOutsideToClose: true,
                    preserveScope: true,
                    autoWrap: true,
                    resolve: {}
                })
                .then(reason => {
                    if (reason) {
                        archiveDocument(document, reason)
                    }
                });
        };

        $scope.document = {
            metadata: {}
        };

        $scope.uploadDocument = function(isFormValid) {
            if (isFormValid) {
                var data = {
                    cui: cui,
                    documentType: $scope.document.type,
                    productType: $scope.document.productId,
                    metaData: $scope.document.metadata
                };

                if ($scope.updateDoc) {
                    data.docId = $scope.document._id;
                    CustomerService.updateDocument(data).then(
                        function(result) {
                            if (result.status) {
                                toaster.pop("success", "Document updated", "Document updated successfully");
                                fetchDocuments(cui);
                                $scope.documentForm.$setPristine();
                                $scope.documentForm.$setUntouched();
                                $scope.document = {
                                    metadata: {}
                                };
                                $scope.updateDoc = false;
                            } else {
                                toaster.pop("error", "Document could not be updated", result.errors[0].message);
                            }
                        },
                        function(res) {
                            if (res.status == 310) {
                                toaster.pop("error", "Document could not be updated", "Something went wrong");
                            }
                        }
                    );
                } else {
                    Upload.upload({
                            url: "/api/documents/",
                            data: data,
                            file: $scope.document.file[0],
                            progress: function(e) {}
                        })
                        .then(function(data, status, headers, config) {
                            if (data.data.status) {
                                $scope.updateDoc = false;
                                toaster.pop("success", "Document uploaded", "Document uploaded successfully");
                                fetchDocuments(cui);
                                $scope.documentForm.$setPristine();
                                $scope.documentForm.$setUntouched();
                                $scope.document = {
                                    metadata: {}
                                };
                            } else {
                                toaster.pop("warning", "Document could not be uploaded", data.data.errors[0].message);
                            }
                        })
                        .catch(function(res) {
                            toaster.pop("warning", "Document could not be uploaded", "Something went wrong");
                        });
                }
            }
        };

        $scope.cancelUpdate = function() {
            $scope.updateDoc = false;
            $scope.documentForm.$setPristine();
            $scope.documentForm.$setUntouched();
            $scope.document = {
                metadata: {}
            };
        };

        $scope.ticketStats = [{
                name: "Active SRs",
                count: 10
            },
            {
                name: "Blocker",
                count: 2
            },
            {
                name: "Critical",
                count: 2
            },
            {
                name: "Major",
                count: 1
            },
            {
                name: "Open",
                count: 5
            },
            {
                name: "In Progress",
                count: 5
            },
            {
                name: "Others",
                count: 5
            }
		];

		$scope.shouldShowTab = function(action){
			if(action==="lead" && ($scope.isMiddleManager || ($rootScope.cloned && !$scope.createLead && !$scope.createReferralLead))) {
				return false;
			}
			else if($rootScope.cloned && action==="lead" && ($scope.createLead || $scope.createReferralLead)) {
				return true;
			}else if($rootScope.cloned && action==="ticket" && $scope.createServiceRequest){
				return true;
			}else if($rootScope.cloned && action==="ticket" && !$scope.createServiceRequest){
				return false;
            }else if(action==="ticket" && $scope.me.organizationalTag.jobRole.roleType === 'seniorManager') {
                return true;
            }
            else{
				// senior manager permissions
				return action && $scope.me.organizationalTag.jobRole.roleType !== 'seniorManager'
			}
		}

        $scope.addNew = function(action) {
            let selectedCustomersHandlerId = $scope.customer.activeHandler && $scope.customer.activeHandler._id;
            let LoggedInUserId = $scope.user._id;

            if (!action) {
                return;
            }
            switch (action) {
                case "lead":
                    var number = $scope.customer.primaryContactNumber.countryCode + ":" + $scope.customer.primaryContactNumber.number;
                    // check for unassigned customers
                    if(!selectedCustomersHandlerId) {
                        return $state.go(p0.config.leadUI_v1? 'userFlow.addLead_v1':'userFlow.addLead_v2', { unassignedView: true, number: number});
                    }
                    if(LoggedInUserId !== selectedCustomersHandlerId && !$scope.customer.primaryHandler) {
						if(!$scope.createReferralLead) return;
                        return $state.go(p0.config.leadUI_v1? 'userFlow.addLead_v1':'userFlow.addLead_v2', { unassignedView: false, isReferral: true, number: number, referredTo: selectedCustomersHandlerId});
                    }
                    if(LoggedInUserId !== selectedCustomersHandlerId) {
						if(!$scope.createReferralLead) return;
                        return $state.go(p0.config.leadUI_v1? 'userFlow.addLead_v1':'userFlow.addLead_v2', { unassignedView: false, isReferral: true, number: number, referredTo: $scope.customer.primaryHandler._id});
					}

					if(!$scope.createLead) return;
                    return $state.go(p0.config.leadUI_v1? 'userFlow.addLead_v1':'userFlow.addLead_v2', {
                        number: number
                    });
                case "ticket":
                    return $state.go("userFlow.addTicket", {
                        cui: $scope.customer.cui,
                        primaryHandler: $scope.customer.primaryHandler
                    });
                case "complaint":
                    return $state.go("");
            }
        };

        /**
         * interaction specific pagination
         */
        $scope.interactionPagination = {
            offset: 0,
            limit: 50,
            total: 0,
            disableNext: true,
            disablePrevious: true
        };
        $scope.nextInteractionPage = function() {
            $scope.interactionPagination.offset += $scope.interactionPagination.limit;
            interactionHistory();
        };

        $scope.previousInteractionPage = function() {
            $scope.interactionPagination.offset -= $scope.interactionPagination.limit;
            interactionHistory();
        };

        function setInteractionPaginationData(data) {
            $scope.interactionPagination.total = data.total;
            if (data.docs.length == 0) {
                $scope.filteredCount = 0;
            } else {
                if ($scope.interactionPagination.offset + $scope.interactionPagination.limit < $scope.interactionPagination.total) {
                    $scope.filteredCount =
                        $scope.interactionPagination.offset + 1 + "-" + ($scope.interactionPagination.offset + $scope.interactionPagination.limit);
                } else {
                    $scope.filteredCount = $scope.interactionPagination.offset + 1 + "-" + $scope.interactionPagination.total;
                }
            }
            if ($scope.interactionPagination.offset + $scope.interactionPagination.limit >= $scope.interactionPagination.total) {
                $scope.interactionPagination.disableNext = true;
            } else {
                $scope.interactionPagination.disableNext = false;
            }
            if ($scope.interactionPagination.offset == 0) {
                $scope.interactionPagination.disablePrevious = true;
            } else {
                $scope.interactionPagination.disablePrevious = false;
            }
        }
        $scope.activitiesApiLoaded = false;

        function interactionHistoryPills() {
            UserActivities.getAllInteractionHistoryCounts($scope.customer).then(
                function(result) {
                    $scope.activityTypes = result;
                },
                function(err) {
                    console.log(err);
                }
            );
        }

        function fetchInteractionHistory() {
            interactionHistoryPills();
            UserActivities.getInteractionTypes().then(function(activities) {
                interactionHistory();
                $scope.activitiesApiLoaded = true;
            });
        }

        function interactionHistory() {
            var cui = $scope.customer.cui;
            var user = $scope.customer.activeHandler;
            UserActivities.getInteractionHistory(user, cui, $scope.interactionPagination, $scope.interactionType).then(
                function(result) {
                    $scope.interactionIcons = UserActivities.getInteractionIcons();

                    if (result.docs && result.docs.length) {
                        setInteractionPaginationData(result);
                        $scope.activities = result.docs;
                    } else {
                        $scope.activities = [];
                    }
                },
                function(err) {
                    console.log(err);
                }
            );
        }
        $scope.interactionType = null;
        $scope.filterActivity = function(type) {
            $scope.interactionPagination.offset = 0;
            if (type != $scope.interactionType) {
                $scope.interactionType = type;
            } else {
                $scope.interactionType = null;
            }

            interactionHistory();
        };

        function prepareStatPills(data) {
            var overallStats = [{
                    name: "Sub Product",
                    key: "products",
                    count: 0
                },
                {
                    name: "Leads",
                    key: "leads",
                    count: 0,
                    addNew: "lead"
                },
                {
                    name: `Alerts/Recommendations`,
                    key: "ar",
                    count: 0
                }
            ];

            if ($rootScope.config.tickets) {
                overallStats.push({
                    name: "Service Request",
                    key: "tickets",
                    count: 0,
                    addNew: "ticket"
                })
            }
            overallStats.forEach(function(stat) {
                if (data[stat.key]) {
                    stat.count = data[stat.key];
                }
                if (stat.key == "products") {
                    stat.count = $scope.customer.formattedProducts.reduce(function(total, obj) {
                        return total + obj.productCount;
                    }, 0);
                    if (stat.count > 1) {
                        stat.name = "Sub Products";
                    } else {
                        stat.name = "Sub Product";
                    }
                }
            });
            $scope.overallStats = overallStats;
        }

        $scope.onInteractionCycleChange = function(cycle) {
            //console.log(cycle,'-----cycle')
        };
        $scope.customerInputElements = [];

        function preparePrimaryInputElements(headers, customer) {
            headers.forEach(header => {
                if (header.isPrimary) {
                    if (header.dbField == "primaryHandler" || header.dbField == "secondaryHandler") {
                        header.value = customer[header.dbField] ? customer[header.dbField].fullName : "";
                    } else if (header.dbField == "primaryContactNumber") {
                        header.value = customer[header.dbField].countryCode + customer[header.dbField].number;
                    } else if (header.dbField == "otherContactNumbers") {
                        header.value = customer[header.dbField][0] ? customer[header.dbField][0].countryCode + customer[header.dbField][0].number : null;
                    } else if(header.dbField == "customerSegment"){
                        $scope.selectedSegment = $scope.customerSegments.find(segment => segment.name === customer[header.dbField])
                        updateMemberShipView()
                    }else{
                        header.value = customer[header.dbField];
                    }
                }
            });

            headers = _.filter(headers, header => {
                return header.isPrimary === true;
            });

            $scope.customerInputElements = Object.assign([], headers);
            $scope.selectedCustomerForm = "primaryFields";
        }

        function prepareSecondaryInputElements(headers, customer) {
            //var secondaryFields = []
            var hashHeaders = _.groupBy(headers, "dbField");
            $scope.selectedCustomerForm = "secondaryFields";
            $scope.customerInputElements = [];
            Object.keys(customer).forEach(function(key, value) {
                var obj = {};
                obj.columnHeader = key;
                obj.displayName = hashHeaders[key] ? hashHeaders[key][0].displayName : key;
                obj.dbField = key;
                obj.isEditable = true;
                obj.value = !isNaN(customer[key]) && (obj.dbField === 'customerSalaryAnnual' || obj.dbField === 'customerSalaryMonthly') ? Number(customer[key]).toFixed(2) : customer[key];
                obj.typeOf = hashHeaders[key] ? hashHeaders[key][0].typeOf : 'string';
                $scope.customerInputElements.push(obj);
            });
        }

        $scope.updateProfile = function() {
            var formData = {};
            if ($scope.selectedCustomerForm == "secondaryFields") {
                formData.secondaryFields = {};
                $scope.customerInputElements.forEach(function(element) {
                    if (element.dbField == "otherContactNumbers") {
                        formData.secondaryFields[element.dbField] = [element.value];
                    } else {
                        formData.secondaryFields[element.dbField] = element.value;
                    }
                });
            } else {
                $scope.customerInputElements.forEach(function(element) {
                    if (element.dbField == "primaryHandler" || element.dbField == "secondaryHandler" || element.dbField == "active") {
                        formData[element.dbField] = $scope.customer[element.dbField]._id;
                    } else if (element.dbField == "primaryContactNumber") {
                        formData[element.dbField] = $scope.customer[element.dbField];
                    } else if (element.dbField == "otherContactNumbers") {
                        formData[element.dbField] = [element.value];
                    } else {
                        formData[element.dbField] = element.value;
                    }
                });
            }
            formData.cui = $scope.customer.cui;
            formData.contactNumber = $scope.customer.primaryContactNumber.countryCode + $scope.customer.primaryContactNumber.number;
            formData.contactNumbers = [formData.contactNumber];
            formData.name = $scope.customer.name;
            CustomerService.updateCustomer(formData).then(
                function(result) {
                    toaster.pop("success", "Customer Updated", "Customer Updated successfully");
                    $scope.customer = result;
                },
                function(err) {
                    console.log(err);
                }
            );
        };

        const updateCustomerFlow = function(formData) {
            CustomerService.updateCustomer(formData).then(
                function(result) {
                    toaster.pop("success", "Customer Updated", "Customer Updated successfully");
                    getCustomersData()
                    $scope.customer = result;
                },
                function(err) {
                    console.error(err)
                    toaster.pop("error","Customer update","Customer updation failed");
                }
            );
        }

        const showConfirm = function(formData) {
            var confirm = $mdDialog.confirm()
                .title('Values for Premier Membership Number and' + 
                    ' Premier Membership Start Date fields are deleted, Do you want to proceed?')
                .targetEvent()
                .ok('Yes')
                .cancel('No');
            $mdDialog.show(confirm).then(function() {
                formData.premierMembershipNumber = null;
                formData.premierMembershipStartDate = null;
                updateCustomerFlow(formData);
            });
        }

        $scope.$watch('selected.isReferredFromAgency',function(newValue){
            if ($scope.customer && _.isUndefined($scope.customer.isReferredFromAgency) && !newValue) {
                $scope.selected.isFlagUpdated = false;
                return;
            }
            else if ($scope.customer.isReferredFromAgency !== newValue) {
                $scope.selected.isFlagUpdated = true;
            } else {
                $scope.selected.isFlagUpdated = false;
            }
         });

        $scope.$watch('selected.customerRemark.remarkText',function(newValue){
            if ($scope.customer && ((!$scope.customer.remark || !$scope.customer.remark.remarkText) || ($scope.customer.remark && $scope.customer.remark.remarkText !== newValue))) {
                $scope.selected.isRemarkUpdated = true;
            }
            else {
                $scope.selected.isRemarkUpdated = false;
            }
         });

        $scope.updateRemarkOnly = () => {
            formData = {};
            formData.cui = $scope.customer.cui;
            if ($scope.selected.customerRemark && $scope.selected.customerRemark.remarkText || ($scope.customer &&  $scope.customer.remark && $scope.customer.remark.remarkText && (!$scope.selected.customerRemark || !$scope.selected.customerRemark.remarkText))) {
                formData.remark = {
                    remarkText : $scope.selected.customerRemark.remarkText,
                    userId: $scope.me._id,
                    userEmail: $scope.me.email,
                    userName: $scope.me.name
                };
            }
            formData.isReferredFromAgency = $scope.selected.isReferredFromAgency;
            CustomerService.updateCustomer(formData).then(
                function(result) {
                    $scope.selected.isRemarkUpdated = false;
                    $scope.selected.isFlagUpdated = false;
                    toaster.pop("success", "Customer Updated", "Customer Updated successfully");
                    getCustomersData()
                    $scope.customer = result;
                    $scope.selected.customerRemark = $scope.customer ? _.cloneDeep($scope.customer.remark) : null;
                    $scope.selected.isReferredFromAgency = $scope.customer && $scope.customer.isReferredFromAgency;
                },
                function(err) {
                    console.error(err)
                    toaster.pop("error","Customer update","Customer updation failed");
                }
            );
        }
        $scope.updateSegmentOnly = () => {
            const formData = {}
            formData.cui = $scope.customer.cui;
            formData.customerSegment =  $scope.selectedSegment ? $scope.selectedSegment.name : $scope.customerSegments[0].name
            const tempNumber = $scope.excelHeaders.find(header => header.dbField === "premierMembershipNumber");
            formData.premierMembershipNumber = tempNumber.value
            const tempDate = $scope.excelHeaders.find(header => header.dbField === "premierMembershipStartDate");
            formData.premierMembershipStartDate = tempDate.value;
            if(formData.customerSegment !== "Personal"){
                updateCustomerFlow(formData);
            } else if (formData.customerSegment === "Personal") {
                if (formData.premierMembershipNumber || formData.premierMembershipStartDate) {
                    showConfirm(formData)
                } else {
                    updateCustomerFlow(formData);
                }
            }
        }

        $scope.selectCustomerForm = function(type) {
            if (type == "primaryFields") {
                $scope.selected.isPrimaryFields = true;
                preparePrimaryInputElements($scope.excelHeaders, $scope.customer);
            } else {
                $scope.selected.isPrimaryFields = false;
                prepareSecondaryInputElements($scope.excelHeaders, $scope.customer.secondaryFields);
            }
        };

        $scope.excelHeaders = [];

        function fetchCustomerExcelHeader() {
            CustomerService.getExcelHeader("customer").then(
                function(result) {
                    //$scope.formCustomer = Object.assign({}, $scope.customer);

                    if (result.status) {
                        {
                            $scope.excelHeaders = result.data.fields;
                            preparePrimaryInputElements($scope.excelHeaders, $scope.customer);
                        }
                    } else toaster.pop("error", "ExcelHeader Failed", "No Header present for the customer");
                },
                function(err) {
                    console.log(err);
                }
            );
        }

        // ------------------------- customer alerts & recommendation

        $scope.moreFilters = [{
                key: "All",
                value: "all"
            },
            {
                key: "Read",
                value: "READ"
            },
            {
                key: "Unread",
                value: "UNREAD"
            },
            {
                key: "Resolved",
                value: "RESOLVED"
            }
        ];

        $scope.paginationAlerts = {
            offset: 0,
            limit: 50,
            total: 0,
            disableNext: false,
            disablePrevious: false,
            page: 1,
            isPagination: true
        };

        $scope.filterObjAlerts = {
            status: "all",
            tag: "alerts",
            searchParam: "",
            recent: 1,
            cui: cui
        };

        $scope.setReadFilter = function(value) {
            $scope.filterObjAlerts.status = value;
            $scope.paginationAlerts.offset = 0;
            $scope.paginationAlerts.page = 1;
            fetchAlertsData();
        };
        var messages = {
            read: "You have taken the action on this alert on "
        };

        $scope.selectedTab = 0;
        $scope.isSearchResult = false;

        // var setPaginationData = function(data){
        // 	$scope.alertsLogs = data.notifications.docs;
        // 	$scope.paginationAlerts.total = data.total;
        // }

        $scope.readAlert = function(alert) {
            if (alert.status === "UNREAD") {
                AlertsService.setAlertActionRead(alert).then(
                    function(result) {
                        toaster.pop("success", "Alert.", "Alert set read");
                        fetchData();
                    },
                    function(err) {
                        toaster.pop("error", "Alert.", "Alert unable to set read");
                    }
                );
            }
        };

        $scope.timelineAlert = function(alert) {
            var createDateString = new Date(alert.createdAt);
            createDateString = createDateString.toString().slice(4, 25);

            let timeline = ["Alert was created on " + createDateString];

            if (alert.read == true) {
                var updateDateString = new Date(alert.updatedAt);
                updateDateString = updateDateString.toString().slice(4, 25);
                timeline.push(messages.read + updateDateString);
            } else {
                var timeRemain = daysBetween(new Date(), new Date(alert.extraParams.dueDate));

                timeline.push(alert.content + ", You have " + timeRemain + " remain to take the action");
            }
            $scope.selectedTab = 1;
            $scope.timeline = timeline;
        };

        $scope.redirectAlert = function(alert) {
            $scope.readAlert(alert);
            if (alert.entityType === "lead" && alert.entityId) {
                const params = {leadId: alert.entityId}
                $state.go(p0.config.leadUI_v1 ? "userFlow.editLead_v1":"userFlow.editLead_v2", params);
            } else if (alert.entityType === "ticket" && alert.entityId) {
                $state.go("userFlow.editTicket", {
                    ticketId: alert.entityId
                });
            }
        };

        var daysBetween = function(date1, date2) {
            //Get 1 day in milliseconds
            var one_day = 1000 * 60 * 60 * 24;

            // Convert both dates to milliseconds
            var date1_ms = date1.getTime();
            var date2_ms = date2.getTime();

            // Calculate the difference in milliseconds
            var difference_ms = date2_ms - date1_ms;
            //take out milliseconds
            difference_ms = difference_ms / 1000;
            var seconds = Math.floor(difference_ms % 60);
            difference_ms = difference_ms / 60;
            var minutes = Math.floor(difference_ms % 60);
            difference_ms = difference_ms / 60;
            var hours = Math.floor(difference_ms % 24);
            var days = Math.floor(difference_ms / 24);
            var dateString = "";
            if (days) {
                dateString += days + " days ";
            }
            if (hours) {
                dateString += hours + " hours ";
            }
            if (minutes) {
                dateString += minutes + " minutes";
            }
            return dateString;
        };

        $scope.nextAlertsPage = function() {
            $scope.paginationAlerts.offset += $scope.paginationAlerts.limit;
            //What is this selected user check?
            fetchAlertsData();
        };
        $scope.previousAlertsPage = function() {
            $scope.paginationAlerts.offset -= $scope.paginationAlerts.limit;
            //What is this selected user check?
            fetchAlertsData();
        };

        function fetchAlertsData() {
            AlertsService.getUserAlerts($scope.filterObjAlerts, $scope.paginationAlerts, false, $scope.sortingObj).then(
                function(result) {
                    if (result.alerts.docs) {
                        $scope.alertsLogs = result.alerts.docs;
                    } else {
                        $scope.alertsLogs = [];
                    }
                    $scope.overallStats = $scope.overallStats ?
                        $scope.overallStats.map(function(stat) {
                            if (stat.key == "ar") {
                                stat.count = $scope.alertsLogs.length;
                            }
                            return stat;
                        }) : [];
                    setAlertsPaginationData(result);
                    $scope.selectedTab = 0;
                },
                function(error) {
                    console.log(error);
                }
            );
        }
        var setAlertsPaginationData = function(data) {
            $scope.paginationAlerts.total = data.total;
            if (data.alerts.docs.length === 0) {
                $scope.filteredCount = 0;
            } else {
                if ($scope.paginationAlerts.offset + $scope.paginationAlerts.limit < $scope.paginationAlerts.total) {
                    $scope.filteredCount = $scope.paginationAlerts.offset + 1 + "-" + ($scope.paginationAlerts.offset + $scope.paginationAlerts.limit);
                } else {
                    $scope.filteredCount = $scope.paginationAlerts.offset + 1 + "-" + $scope.paginationAlerts.total;
                }
            }

            if ($scope.paginationAlerts.offset + $scope.paginationAlerts.limit >= $scope.paginationAlerts.total) {
                $scope.paginationAlerts.disableNext = true;
            } else {
                $scope.paginationAlerts.disableNext = false;
            }
            if ($scope.paginationAlerts.offset === 0) {
                $scope.paginationAlerts.disablePrevious = true;
            } else {
                $scope.paginationAlerts.disablePrevious = false;
            }
        };

        $scope.clickAlert = function(alertObj) {
            AlertsService.setAlertActionRead(alertObj).then(
                function(response) {
                    if (response) {
                        var extraParams = {
                            customerId: alertObj.customerCui,
                            name: alertObj.name
                        };
                        $redirectRouter.redirectTo(alertObj.linkTo, extraParams);
                    }
                },
                function(err) {
                    console.log(err);
                }
            );
        };

        $scope.getFilteredAlerts = function() {
            fetchAlertsData();
        };

        $scope.getSearchFilteredAlerts = function() {
            fetchAlertsData();
            $scope.isSearchResult = true;
        };
        $scope.resetSearch = function() {
            $scope.filterObjAlerts.searchParam = "";
            $scope.isSearchResult = false;
            fetchAlertsData();
        };
        $scope.fetchAlerts = function() {
            $scope.paginationAlerts = {
                offset: 0,
                limit: 50,
                total: 0,
                disableNext: false,
                disablePrevious: false,
                page: 1,
                isPagination: true
            };
            fetchAlertsData();
        };

        $scope.alertsApiLoaded = true;

        $scope.alertsPillStats = [{
                name: "Leads",
                key: "leads",
                count: 0,
                addNew: "lead"
            },
            {
                name: "Service Request",
                key: "tickets",
                count: 0,
                addNew: "ticket"
            },
            {
                name: "Recommandations",
                key: "ar",
                count: 0
            }
        ];

        $scope.redirectToAlerts = function() {
            $scope.activeTab = "alerts";
        };
        $scope.defaultTab = function(param) {
            $scope.activeTab = param;
        };

        $scope.downloadCustomers = function() {
            CustomerService.getCustomerById($stateParams.customerId, true);
        };

        $scope.$on("documentAdded", (evt, data) => {
            $scope.documentStatus = 'active';
            $scope.documentTypeId = ""
            getDocumentPillsCount($stateParams.customerId);
            fetchDocuments($stateParams.customerId);
        });

        $scope.callUMS = (document) => {
            DocumentService.configById(document.documentTypeId._id).then(result => {
                $mdDialog.show({
                    templateUrl: autoImportNgTemplateLoaderTemplate2,
                    controller: "uploadDocumentCtrl",
                    fullscreen: true,
                    resolve: {
                        parameters: function() {
                            return {
                                document: result.data,
                            };
                        }
                    }
                }).then(({ fileIds, fileNames }) => {
                    $scope.documentTypeId = document.documentTypeId._id
                    $scope.fileIds = fileIds;
                    $scope.fileNames = fileNames;
                }, function() {});
            }).catch(err => {
                toaster.pop('error', 'UMS modal', 'Document type id is required')
            })

        };

        function init() {
            // fetchLeads(customerId)
            // fetchTickets(customerId);
            if ($stateParams.customerId) {
                var cui = $stateParams.customerId;
                if ($stateParams.activeTab) {
                    $scope.activeTab = $stateParams.activeTab;
                }
                if ($stateParams.documentStatus) {
                    $scope.documentStatus = $stateParams.documentStatus;
                }
                if ($stateParams.documentProductId) {
                    $scope.documentProductId = $stateParams.documentProductId;
                }
                getDocumentPillsCount(cui);
                CustomerService.getCustomerById(cui, false, true).then(
                    function(result) {
                        $scope.customer = result;
                        $scope.selected.customerRemark = $scope.customer ? _.cloneDeep($scope.customer.remark) : null;
                        $scope.selected.isReferredFromAgency = $scope.customer && $scope.customer.isReferredFromAgency;
                        $scope.selected.verificationTimeLine = $scope.customer && $scope.customer.verificationTimeLine;
                        setActiveOTP($scope.customer);
                        fetchInteractionHistory($scope.customer);
                        fetchCustomerExcelHeader();
                        CustomerService.getCustomerOverview(cui).then(function(overview) {
                            //$scope.overview = overview.overviewData;
                            prepareStatPills(overview.overviewData);
                        });
                        fetchAlertsData();
                        $scope.customer360Loaded = true;
                    },
                    function(err) {
                        console.log(err);
                    }
                );
                fetchDocuments(cui);
            }
        }

        /**
         * Redirect to profile on click on 'more'
         */
        $scope.redirecToProfile = function (tab){
            $scope.activeTab = tab;
        }


        $scope.onClickHandler = (header, $event) => {
            if(['primaryHandler','secondaryHandler'].includes(header.dbField)){
                if(!$scope.isSeniorManager){
                    return false;
                }
                let secondaryHandler = false
                if(header.dbField === 'secondaryHandler'){
                    secondaryHandler = true
                }
                $scope.assignCustomer($scope.customer, secondaryHandler, $event)
            }
        }

        const getCustomersData = () => {
            CustomerService.getCustomerById(cui, false).then(
                function(result) {
                    $scope.customer = result;
                    fetchInteractionHistory($scope.customer);
                    fetchCustomerExcelHeader();
                    CustomerService.getCustomerOverview(cui).then(function(overview) {
                        //$scope.overview = overview.overviewData;
                        prepareStatPills(overview.overviewData);
                    });
                    fetchAlertsData();
                    $scope.customer360Loaded = true;
                },
                function(err) {
                    console.error(err);
                }
            );
        }


        function searchComponent(query, key, key2) {
            var ismatched = false;
            var re = new RegExp(query, 'gi');
            return function filterFn(list) {
                if (key2)
                    ismatched = list[key].match(re) || list[key2].match(re);
                else
                    ismatched = list[key].match(re);

                if (ismatched)
                    return true;
                else
                    return false;
            };
        }

        //---angular material -----
        $scope.searchList = function(query, list, key, key2) {
            var results = query ? list.filter(searchComponent(query, key, key2)) : list;
            return results;
        }

        function maskOTP() {
            if (!$scope.generatedOtp) {
                return;
            }
            $scope.generatedOtp = $scope.generatedOtp.slice(0, -6)
            $scope.generatedOtp = $scope.generatedOtp + '******';
        }

        function setActiveOTP(customer) {
            if (customer.otpValidation && customer.otpValidation.length > 0) {
                const activeOtpObj = customer.otpValidation.filter(obj => obj.isActive);
                if (activeOtpObj && activeOtpObj.length > 0) {
                    $scope.generatedOtp = activeOtpObj[0].otp;
                    maskOTP();
                }
            }
        }

        $scope.generateOTP = async function() {
            try{
                const response = await CustomerService.generateOTP($scope.customer.cui);
                $scope.generatedOtp = response && response.data && response.data.otp;
                maskOTP();
                $scope.selected.verificationTimeLine = response && response.data && response.data.timeLine;
                toaster.pop("success", "OTP generated successfully!");
            } catch (err) {
                const msg = err && err.data && err.data.err;
                toaster.pop("error", `Error in generating OTP: ${msg}`);
            }
            $scope.otpEntered = null;
        }

        $scope.validateOTP = async function(otp) {
            if(!otp) {
                toaster.pop("error", "Please enter OTP");
                return;
            }
            try {
                const response = await CustomerService.validateOTP($scope.customer.cui, otp);
                if (response && response.data) {
                    if (response.data.isVerified) {
                        $scope.generatedOtp = null;
                        toaster.pop("success", "Customer verified successfully!");
                    } else {
                        toaster.pop("error", "Customer not verified: Incorrect OTP");
                        if (response.data.maxRetryLimitReached) {
                            $scope.generatedOtp = null;
                            toaster.pop("warning", "Max retry limit reached, please generate new OTP");
                        }
                    }
                    $scope.selected.verificationTimeLine = response && response.data && response.data.timeLine;
                }
            } catch (err) {
                const msg = err && err.data && err.data.err;
                toaster.pop("error", `Error in validating OTP: ${msg}`);
            }
            $scope.otpEntered = null;
        }

        init();
    });
