'use strict';
//
angular.module('bitnudgeApp')
    .service('PortfolioService', function ($http, $q, p0) {
        this.getPortfolio = function (cycleId, userId) {
            let url = './api/userAnalytics/portfolio';
            return $http.post(url, {cycleId, userId});
        }

        // this.getPortfolioScorecardData = function (userId, date) {
        //     return $http.get('./api/portfolio/getPortfolioForUser/userId/' + userId + '/date/' + date)
        // }

        // this.getReporteePortfolio = function (userId, date, jobRoleId) {
        //     return $http.get('api/portfolio/getReporteePortfolio/userId/' + userId + '/date/' + date + '/jobRoleId/' + jobRoleId)
        // }    
        // this.getChildrenReporteePortfolio = function (userId, date, jobRoleId) {
        //     return $http.get('./api/portfolio/getChildrenReporteePortfolio/userId/' + userId + '/date/' + date)
        // }

        this.getUserFromId = function (userId) {
            return $http.post('./api/users/getUserById',{id: userId})
        }

        this.getTrend = function(userId, range, cycleType, isReportees){
            let url = '/api/portfolio/trend';
            return $http.post(url, {userId, cycleType, startDate: range.startDate, endDate: range.endDate, isReportees})
        }

    });
