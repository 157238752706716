'use strict';
angular.module('bitnudgeApp')
    .controller('carouselSelectCtrl', function ($scope) {
        $scope.initDatepicker = function(){
            angular.element(".md-datepicker-button").each(function(){
                var el = this;
                var ip = angular.element(el).parent().find("input").bind('click', function(e){
                    angular.element(el).click();
                });
            });
        };
        $scope.type = $scope.type || 'default';
        
        var shouldDisable = function () {
            if($scope.isDateFilter && $scope.type == 'default'){
                $scope.isFirst= _.findIndex($scope.options, $scope.model) ==  $scope.options.length - 1;
                $scope.isLast=  _.findIndex($scope.options, $scope.model) == 0;
            }else{
                $scope.isFirst= $scope.type == 'default' && _.findIndex($scope.options, $scope.model) ==  0;
                $scope.isLast= $scope.type == 'default' && $scope.options && _.findIndex($scope.options, $scope.model) == $scope.options.length - 1;
            }
        };
        $scope.selectedDate = {date:$scope.model};
        $scope.selectItem = function(item){
            $scope.model = item;
            if ($scope.onChange){
                $scope.onChange($scope.model);
            }
            shouldDisable();
        };
        $scope.rotateRight = function(){
            if($scope.isDateFilter && $scope.type != 'date'){
                var modelIndex = _.findIndex($scope.options, $scope.model);
                $scope.model = $scope.options[modelIndex - 1];
                if ($scope.onChange){
                    $scope.onChange($scope.model);
                }
                shouldDisable();
            }
            else if($scope.type == 'default') {
                var modelIndex = _.findIndex($scope.options, $scope.model);
                $scope.model = $scope.options[modelIndex + 1];
                if ($scope.onChange){
                    $scope.onChange($scope.model);
                }
                shouldDisable();
            }else{
                if($scope.selectedDate.date){
                    $scope.model = new Date($scope.selectedDate.date);
                    $scope.model.setDate($scope.selectedDate.date.getDate() + 1);
                    if ($scope.onChange){
                        $scope.onChange($scope.model);
                    }
                }
            }
        };
        $scope.rotateLeft = function(){
            if($scope.isDateFilter && $scope.type != 'date'){
                var modelIndex = _.findIndex($scope.options, $scope.model);
                $scope.model = $scope.options[modelIndex + 1];
                if ($scope.onChange){
                    $scope.onChange($scope.model);
                }
                shouldDisable();
            }
            else if($scope.type == 'default') {
                var modelIndex = _.findIndex($scope.options, $scope.model);
                $scope.model = $scope.options[modelIndex - 1];
                if ($scope.onChange){
                    $scope.onChange($scope.model);
                }
                shouldDisable();
            }else{
                if($scope.selectedDate.date){
                    $scope.model = new Date($scope.selectedDate.date);
                    $scope.model.setDate($scope.selectedDate.date.getDate() - 1);
                    if ($scope.onChange){
                        $scope.onChange($scope.model);
                    }
                }
            }
        };
        if($scope.type=='date'){
            $scope.$watch('model',function(newVal,oldVal){
                if(newVal){
                    $scope.selectedDate = {date:$scope.model};
                }else{
                    $scope.selectedDate = {date:null};
                }
            });
        }
        $scope.onDateChange = function(){
            if ($scope.onChange){
                $scope.onChange($scope.selectedDate.date);
            }
        };
        $scope.$watch('options', function (n,o) {
            if (n) {
                shouldDisable();
            }
        });
        $scope.onChosenChange = function (user) {
            if(user){
                $scope.onChange(user);
            }
        };
    });
