const autoImportNgTemplateLoaderTemplate1 = require('../html/graphCard.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('graphCard', function(){
        return {
            name: 'graphCard',
            scope: {
                cardId:                 '=',
                benchmarkValue:         '=',
                benchmark:              '=',
                kpi:             '=',
                forecastMultiplier:     '=',
                isActive:               '=',
                isSeniorManager:        '=',
                isActiveCycle:          '=',
                enlarge:                '=',
                compareValue:           '=',
                applyForecast:          '=',
                selectedUser:           '='
            },
            controller: 'graphCardCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
