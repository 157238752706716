'use strict';
angular.module('bitnudgeApp')
    .controller('DialogController',function($scope, $rootScope, $mdDialog, params, SwotService, swotType, p0, $filter) {
        $scope.strings=$rootScope.strings;
        $scope.hide = function() {
            $mdDialog.hide();
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.swotType = swotType;

        if($scope.swotType == 'kpi'){
            $scope.title = params.title;
            SwotService.getKpiModalDetail(params.userId, params.leagueId, params.cycleId).then( function(args){
                var kpiData=args[0], kpiThresholds=args[1];
                $scope.dataArray = kpiData;
                $scope.threshold = kpiThresholds;
                setSwot();
            });
        }else if($scope.swotType == 'kai'){
            SwotService.getKaiModalDetail(params.userId, params.leagueId, params.cycleId, params.kpiId, params.activityFilter).then(function(args){
                var kaiData=args[0], kaiThresholds=args[1];
                $scope.title = params.title + kaiData[0].kpiName?kaiData[0].kpiName:' ';
                $scope.dataArray = _.cloneDeep(kaiData);
                $scope.dataArray.forEach(function(activity){
                    if(activity.activityType == 'percentage'){
                        activity.value.value *= 100;
                    }
                });
                $scope.threshold = kaiThresholds;
                removeUnwantedKAISwot();
                setSwot();
            });
        }else if($scope.swotType == 'activity'){
            SwotService.getReporteeModalDetailForActivities(params.cycleId, params.userId, params.kpiId, params.jobRoleId, params.leagueId, params.activityId).then(function(args){
                var userAnalytics = args[0], leagueAnalytics = args[1];
                var activity = _.find(p0.allActivities, {_id: params.activityId});
                var name = '';
                if(activity) name = ' for '+activity.name;
                $scope.title = params.title + name;
                $scope.dataArray = userAnalytics;
                $scope.activityType = "value";
                $scope.reporteeThreshold = leagueAnalytics;
                setSwot();
            });
        }else{
            SwotService.getReporteeModalDetail(params.cycleId, params.userId, params.kpiId, params.jobRoleId, params.leagueId, function(userAnalytics, leagueAnalytics){
                var kpi = _.find(p0.allKpis, {_id: params.kpiId});
                var name = '';
                if(kpi) name = ' for '+kpi.name;
                $scope.title = params.title + name;
                $scope.dataArray = userAnalytics;
                $scope.reporteeThreshold = leagueAnalytics;
                setSwot();
            });

        }

        $scope.progressBarConfig = function(swotType, percentageCompleted, threshold, swot, percents, activityType){
            return {
                mtdColor: "#b3defd",
                forecastColor: "#cdcdcd",
                label: true,
                threshold: threshold,
                percentageCompleted: percentageCompleted.value,
                percents: percents,
                swot:swot,
                swotType:swotType,
                activityType: activityType
            };
        };

        $scope.getSubTitle = function(data){
            if($scope.swotType == 'kpi'){
                return "(% of tgt.) Target - " +(data.target.value/data.denom).toFixed(2);
            }else if($scope.swotType == 'kai' && (data.fieldName == 'throughput-a' || data.fieldName == 'aht')){
                return "("+data.unit+") " + $filter('number')(data.value.value,1) + " "+ (data.ratePeriod?data.ratePeriod:'');
            }else if($scope.swotType == 'kai' && data.name != 'Application submission'){
                if(data.activityType == 'percentage'){
                    return "("+data.unit+") " + Math.round(data.value.value);
                }else{
                    return "("+data.unit+") " + Math.round(data.value.value) +", "+ Math.round(data.value.value/$rootScope.daysPassed)+" per day";
                }

            }else if($scope.swotType == 'kai' && data.name == 'Application submission'){
                return "("+data.unit+") " + Math.round(data.value.value) +", "+ Math.round(data.value.value/$rootScope.weeksPassed)+" per week";
            }else{
                return "";
            }
        };
        $scope.zoneCount = {
            red: 0,
            amber: 0,
            blue: 0,
            green: 0
        };
        var removeUnwantedKAISwot = function () {
            _.remove($scope.dataArray,function(data){
                var activity = _.find(p0.allActivities,{_id:data.activityId});
                return !activity.swot;
            });
        };

        var setSwot = function () {
            $scope.dataArray.forEach(function(data, key){
                if(data.swot == 't'){
                    $scope.zoneCount.red++;
                }else if(data.swot == 'w'){
                    $scope.zoneCount.amber++;
                }else if(data.swot == 'o'){
                    $scope.zoneCount.blue++;
                }else if(data.swot == 's'){
                    $scope.zoneCount.green++;
                }
            });
        };

    });
