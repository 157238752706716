'use strict';
angular.module('bitnudgeApp')
    .controller('scoreCardManagerModalController', function (p0, Auth, $scope, $http, moment, $rootScope,
															 $mdDialog, kpi, jobroleId, user, cycleId, selectedCycle,
															 performanceIndicatorService, ngXlsx, toaster, $filter,
															 isSelectedCycleActive, FileSaver, Sidebar, UtilService) {

        $scope.selectedCycle = selectedCycle;
        var userIdForScoreCard = user.id;
        var jobRoleIdForScoreCard = '';
        var fetchReporteeData = false;
        $scope.params = {
			reporteeLabel : p0.strings.FRONTLINE.value,
        };
        function setRoleBasedConfig(){
            switch (user.systemRole) {
                case 'frontline':
                    $scope.systemRole = p0.strings.FRONTLINE.value;
					$scope.userViewConfig = [{ "key": "me", "name": "ME" }];
					$scope.isMe = true;
					break;
                case 'manager':
                    $scope.systemRole = p0.strings.MANAGER.value;
					$scope.userViewConfig = [
                        { "key": "me", "name": "My Team" },
                        { "key": "me(own)", "name": "My Values" },
                        { "key": "reportees", "name": "My Reportees" }
                    ];
					$scope.isMe = true;
					break;
                case 'seniorManager':
                    $scope.systemRole = p0.strings.SENIORMANAGER.value;
					$scope.userViewConfig = [
                        { "key": "me", "name": "My Team" },
                        { "key": "me(own)", "name": "My Values" },
                        { "key": "reportees", "name": "My Reportees" }
                    ];
					$scope.isMe = true;
					break;
            }
        }

        setRoleBasedConfig();
        //config for filters
        $scope.cycleNames = [{ "cycleKey": "1M", "name": "MTD" }];
        //, { "cycleKey": "3M", "name": "3 Months" }, { "cycleKey": "6M", "name": "6 months" }
        $scope.forecastNames = [{ "forecastKey": "0M", "name": "Actual", forecastName: '' },
        // TODO: Add forecast dropdown later
        // { "forecastKey": "1M", "name": "This month", forecastName: ' , Forecast' },
        // { "forecastKey": "3M", "name": "3 Months", forecastName: ' , 3 Month Avg.' }
    	];

        $scope.unitMetrics = [{ "key": "achievementTarget", "name": "Achieved/Target" }, { "key": "percentage", "name": "%" }];


        //J18 is a Hack to fix PT05 wrong org hierarchy
        var loggedInUser = Auth.getCurrentUser();
        var userJobRoleId = loggedInUser.organizationalTag.jobRole._id;
        //remove 3 months and 6 months for non overall kpis
        if (kpi.kpiCode != "K00") {
            _.remove($scope.cycleNames, function (cycleObject) {
                if (cycleObject.cycleKey == "3M"||cycleObject.cycleKey == "6M") {
                    return true;
                }
                return false;
            });
        }
        if (!isSelectedCycleActive) {
            _.remove($scope.forecastNames, function (forecastObject) {
                if (forecastObject.forecastKey == "1M") {
                    return true;
                }
                return false;
            });
        }
        //selected config
        $scope.selectedUnitMetric = $scope.unitMetrics[0];
        $scope.selectedForecastName = $scope.forecastNames[0];
        $scope.selectedCycleName = $scope.cycleNames[0];
        $scope.selectedUserViewConfig = $scope.userViewConfig[0];

        $scope.isWindowMaximised = false;
        $scope.dataLoaded = false;

        $scope.isPercentage = $scope.selectedUnitMetric.key === 'percentage';
        $scope.modalHeading = "Scorecard";
        $scope.allowUserView = true;
        $scope.downloadScorecardMonths = false;
        $scope.lowestSeniorManager = false;
        $scope.showUnitChange = true;
        $scope.showForecastChange = true;
        $scope.sortOrder = "asc";
        $scope.sortKey = "userName";
		$scope.scorecardTemplate = {
			showMessageFrontline: true,
			showMessageManager: true
		};
		$scope.breadcrumbList = [];

        if (kpi.kpiCode != "K00") {
            $scope.modalHeading = kpi.name;
        }
        var allJobRoles = p0.allJobRoles;
        var loggedInUser = Auth.getCurrentUser();
        var userLevel = Auth.getCurrentUser().organizationalTag.jobRole.level;


        if (user.roleType == 'frontline') {
            //dont show reportee view
            $scope.allowUserView = false;
        }
        else {
            //don't allow user view  if the user level is more than the lowest senior manager
            $scope.allowUserView = true;
        }

        initializePlayTypeAndJobRole()
        if ($scope.isMe || $scope.isMeOwn) {
            setOverallScoreCardForCurrentUser()
        }
        else {
            setOverallScoreCardForUsers()
        }


        function checkLevelLowerThanLowestLevelSeniorManager(allJobroles, userLevel) {
            var seniorManagerJobRoles = allJobRoles.filter(function (jobRole) {
                if (jobRole.roleType == "seniorManager") {
                    return true;
                }
                else {
                    return false;
                }
            })
            seniorManagerJobRoles = _.orderBy(seniorManagerJobRoles, ['level'], ['asc']);
            var lowestLevel = seniorManagerJobRoles[0].level;
            if (userLevel == lowestLevel) {
                $scope.lowestSeniorManager = true;
            }
            if (userLevel < lowestLevel) {
                return true;
            }
            else {
                return false;
            }
        }

        function initializePlayTypeAndJobRole() {
			let playTypes = [];
			user.playTypes.forEach(function(playtype){
				playTypes.push(_.find(p0.allPlayTypes, {_id:playtype}))
			});
            if (kpi.kpiCode != 'K00') {
                playTypes = _.filter(playTypes, function (playType) {
                    var playTypeKpis = playType.kpis;
                    var playTypeKpiCodes = _.map(playTypeKpis, 'kpiCode')
                    if (playTypeKpiCodes.indexOf(kpi.kpiCode) != -1) {
                        return true;
                    }
                    else {
                        return false;
                    }
                })
            }
            $scope.playTypes = playTypes;
            var selectedPlayType = playTypes[0];
            $scope.selectedPlayType = playTypes[0];
            setJobRolesForSelectedPlayType()

        }

        function setJobRolesForSelectedPlayType() {
            var selectedPlayType = $scope.selectedPlayType;
            var jobRoleIdsForPlayType = [];
            /*var managerIds = selectedPlayType.managers;
            //dont add manager roletype filter if the user is manager himself
            if (loggedInUser.roleType=="seniorManager"||user.roleType != 'manager') {
                managerIds.forEach(function (managerId) {
                    jobRoleIdsForPlayType.push(managerId);
                })
            }*/
            var frontlineIds = selectedPlayType.frontline;
            frontlineIds.forEach(function (frontlineId) {
                jobRoleIdsForPlayType.push(frontlineId);
            });

            var allJobRoles = p0.allJobRoles;
            var jobRoleObjects = [];
            jobRoleIdsForPlayType.forEach(function (jobRoleIdForPlayType) {
                allJobRoles.forEach(function (jobRole) {
                    if (jobRoleIdForPlayType == jobRole._id) {
                        jobRoleObjects.push(jobRole)
                    }
                })
            });

            $scope.jobRoles = jobRoleObjects;
            $scope.selectedJobRole = jobRoleObjects[0];
            jobRoleIdForScoreCard = $scope.selectedJobRole._id;
        }

        function setOverallScoreCardForUsers(isDownloadExcel) {
            var userId = user._id
            var metric = kpi.kpiCode;
            $scope.kpiName = kpi.name;
            var subMetric = "achievedValue"
            $scope.kpi = kpi;

            $scope.selectedCycle = selectedCycle;
            const activityId = kpi.parentProductId ? kpi.activityId : null;
            if($scope.kpi.kpiCode === 'K00'){
                $scope.userActivityData = [];
                $scope.allScoreCard = [];
            	//For scorecard - Get the rendered template from the backend
				$scope.empty = false;
				$scope.dataLoaded = true;
				$scope.breadcrumbList = [{name:$scope.selectedJobRole.name, id:$scope.selectedJobRole._id, type:'role'}];
				$scope.scorecardTemplate.manager = getTemplateURL('manager', {jobRoleId:$scope.selectedJobRole._id, cycleId:cycleId});
			}else{
                if(!isDownloadExcel){
                    performanceIndicatorService.fetchOverallScoreCardForUsers(userId, cycleId, $scope.selectedJobRole._id, metric, subMetric, $scope.selectedPlayType._id, activityId).then(function (allScoreCard) {
                        if (allScoreCard.length==0||!allScoreCard[0].scorecard.kpiactivity) {
                            $scope.empty = true;
                            $scope.dataLoaded = true;
                        }
                        else {
                            $scope.empty = false;
                            for (var i = 0; i < allScoreCard.length; i++) {
                                var scoreCard = allScoreCard[i].scorecard;
                                //folding 3 level data into 2 level
                                _.forIn(scoreCard.kpiactivity, function (kpi, key) {
                                    var act = []
                                    _.forIn(kpi, function (actData, ikey) {
                                        actData.denom = actData.denom || 1;
                                        actData.denom1 = actData.denom1 || 1;
                                        act.push(actData);
                                    });
                                    scoreCard.kpiactivity[key] = act;
                                });
                                //add kpi weight to each activity for weighted values
                                _.forIn(scoreCard.kpi, function (kpi) {
                                    _.forIn(scoreCard.kpiactivity[kpi.kpiCode], function (kpiActivity) {
                                        kpiActivity.kpiWeight = kpi.weight;
                                    })
                                });
                                allScoreCard[i].scorecard = scoreCard;
                            }
                            $scope.allScoreCard = allScoreCard;
                            setActivityArray()
                            $scope.dataLoaded = true;
                        }
                    }).catch(function(err) {
                        $scope.empty = true;
                        $scope.dataLoaded = true;
                        console.error(err)
                    })
                }else{
                    let url = Sidebar.getAppUrl();
                    url += '/api/userAnalytics/fetchOverallScoreCardForUsers';
                    const params = {
                        cycleId,
                        metric,
                        subMetric,
                        jobroleId: $scope.selectedJobRole._id,
                        playType: $scope.selectedPlayType._id
                    }
                    if(activityId){
                        params.activityId = activityId;
                        params.subProductLevel = true;
                        params.download = true;
                    }else{
                        params.download = true;
                    }
                    return UtilService.downloadFile(url, params)
                }
			}
        }


        function setOverallScoreCardForCurrentUser(isDownloadExcel) {
            var userId = user._id
            var metric = kpi.kpiCode;
            const activityId = kpi.parentProductId ? kpi.activityId : null;
            $scope.kpiName = kpi.name;
            var subMetric = "achievedValue"
            $scope.kpi = kpi;
            $scope.selectedCycle = selectedCycle;
            $scope.showUnitChange = true;
			$scope.scorecardTemplate.frontline = getTemplateURL('frontline', {userId:userId, cycleId:cycleId});
			if($scope.kpi && $scope.kpi.kpiCode !== 'K00'){
				performanceIndicatorService.fetchOverallScoreCard(userId, cycleId, jobroleId, metric, subMetric, $scope.selectedPlayType._id, activityId, isDownloadExcel, $scope.isMeOwn, $scope.modalHeading).then(function (scorecard) {
					processOverallScorecardForCurrentUser(scorecard);
                })
            }

        }

        function sortSummaryActivity(kpiactivity) {
            return _.orderBy(kpiactivity, ['kpiCode'], ['asc'])
        }
        function processOverallScorecardForCurrentUser(scorecard) {
            wrapScoreCardForNonOverall(scorecard);
            $scope.scorecard = scorecard;
        }

        function wrapScoreCardForNonOverall(scoreCard) {
            var act = [];
            _.forIn(scoreCard.kpiactivity, function (kpi, key) {
                _.forIn(kpi, function (actData, ikey) {
                    actData.denom = actData.denom || 1;
                    actData.denom1 = actData.denom1 || 1;
                    act.push(actData);
                });
            });
            scoreCard.kpiactivity = act;
        }

        function setActivityArray() {

            var selectedKpiTab;
            if ($scope.kpi.kpiCode == "K00") {
                var metricHeaders = []
                var metrics = $scope.allScoreCard[0].scorecard.kpi;
                for (var key in metrics) {
                    if (metrics.hasOwnProperty(key)) {
                        metricHeaders.push(metrics[key])
                    }
                }
                $scope.metricHeaders = _.sortBy(metricHeaders, ['kpiCode']);
                if (!$scope.selectedMetric) {
                    $scope.selectedMetric = $scope.metricHeaders[0];
                }
                selectedKpiTab = $scope.selectedMetric.kpiCode;


                var userActivityData = []

                var activityHeaders = [];

                //set activity headers
                if ($scope.allScoreCard.length > 0) {
                    activityHeaders = _.cloneDeep($scope.allScoreCard[0].scorecard.kpiactivity[selectedKpiTab]);
                }
                // for overall scorecard add overall activity to activity headers if it is not present
                if ($scope.kpi.kpiCode == "K00") {
                    if (activityHeaders) {
                        var overallObjectIndex = _.findIndex(activityHeaders, { name: 'Overall' })

                        if (overallObjectIndex == -1 && !(selectedKpiTab == "K00" || selectedKpiTab == "kpiCampaign" || selectedKpiTab == "kpiFgip")) {
                            activityHeaders.unshift({ name: 'Overall', kpiCode:'K00' })
                        }
                    }
                }
                $scope.activityHeaders = activityHeaders;


                $scope.allScoreCard.forEach(function (scorecard) {
                    var userActivitiesForKPI = [];
                    var userName = scorecard.user.fullName;
                    var userId = scorecard.user._id;
                    var userActivitiesForKPI = scorecard.scorecard.kpiactivity[selectedKpiTab];
                    //overall column activity
                    if ($scope.kpi.kpiCode == "K00") {
                        var overallColumnIndex = _.findIndex(userActivitiesForKPI, { name: 'Overall' })
                        if (overallColumnIndex == -1 && !(selectedKpiTab == "K00" || selectedKpiTab == "kpiCampaign" || selectedKpiTab == "kpiFgip")) {
                            var scorecardKPI = scorecard.scorecard.kpi[selectedKpiTab];
                            userActivitiesForKPI.unshift({ name: 'Overall', value: { value: scorecardKPI.achievedValue.value }, valueForecast: { value: scorecardKPI.achievedValueForecast.value }, avgValue: { value: scorecardKPI.avgAchievedValueForecast.value }, denom: 1, unit: '%', weight: 1, kpiWeight: scorecardKPI.weight, valueMultiplier: 1, showOnlyPercentage: true })
                        }
                    }

                    // userActivitiesForKPI.unshift(scorecard.scorecard.kpi[selectedKpiTab]);
                    //set activities to empty if the activities are not present for the kpi
                    if (!userActivitiesForKPI) {
                        userActivitiesForKPI = []
                    }
                    userActivityData.push({
                        userName: userName,
                        userId: userId,
                        activities: userActivitiesForKPI
                    })
                })
                $scope.showUnitChange = true;
                $scope.showForecastChange = true;
            }
            else {
                var userActivityData = []
                var activityHeaders = [];

                //set activity headers
                if ($scope.allScoreCard.length > 0) {
                    var kpiActivities = _.cloneDeep($scope.allScoreCard[0].scorecard.kpiactivity);
                    for (key in kpiActivities) {
                        activityHeaders = activityHeaders.concat(kpiActivities[key])
                    }
                }
                $scope.activityHeaders = activityHeaders;

                $scope.allScoreCard.forEach(function (scorecard) {
                    var userActivitiesForKPI = [];
                    var userName = scorecard.user.fullName;
                    var userId = scorecard.user._id;
                    var userKpiActivities = scorecard.scorecard.kpiactivity;
                    for (key in userKpiActivities) {
                        userActivitiesForKPI = userActivitiesForKPI.concat(userKpiActivities[key])
                    }

                    //set activities to empty if the activities are not present for the kpi
                    if (!userActivitiesForKPI) {
                        userActivitiesForKPI = []
                    }
                    userActivityData.push({
                        userName: userName,
                        userId: userId,
                        activities: userActivitiesForKPI
                    })
                })
                $scope.showUnitChange = true;
                $scope.showForecastChange = true;
            }
            $scope.userActivityData = _.orderBy(userActivityData, [$scope.sortKey], [$scope.sortOrder]);
        }

        $scope.onPlayTypeChange = function (selectedPlayType) {
            $scope.selectedPlayType = selectedPlayType;
            $scope.selectedMetric = null;
            setJobRolesForSelectedPlayType();
            setOverallScoreCardForUsers();
        };

        $scope.onJobRoleChange = function (selectedJobRole) {
            $scope.selectedJobRole = selectedJobRole;
            jobRoleIdForScoreCard = $scope.selectedJobRole._id;
            if(selectedJobRole.roleType === 'frontline'){
				$scope.params.reporteeLabel = p0.strings.FRONTLINE.value;
			}else if(selectedJobRole.roleType === 'manager'){
				$scope.params.reporteeLabel = p0.strings.FRONTLINE.value;
			}else{
				$scope.params.reporteeLabel = p0.strings.SENIORMANAGER.value;
			}
            $scope.selectedMetric = null;
            setOverallScoreCardForUsers();
        };
        $scope.onMetricChange = function (selectedMetric) {
            $scope.selectedMetric = selectedMetric;
            setActivityArray();
        };
        $scope.onForcastChange = function (selectedForecastName) {
            $scope.selectedForecastName = selectedForecastName;
            if ($scope.isMe || $scope.isMeOwn) {
                setOverallScoreCardForCurrentUser()
            }
            else {
                setActivityArray()
            }
        };

        $scope.onCycleChange = function (selectedCycleName) {
            $scope.selectedCycleName = selectedCycleName;
            $scope.numMonths = 1;
            if ($scope.selectedCycleName.cycleKey == "1M") {
                $scope.downloadScorecardMonths = false;
                initializePlayTypeAndJobRole()

                setOverallScoreCardForUsers()

            }
            else {
                if ($scope.selectedCycleName.cycleKey == "3M") {
                    $scope.numMonths = 3;
                }
                else {
                    $scope.numMonths = 6;
                }
                $scope.downloadScorecardMonths = true;

            }
            // else {
            //     var numCycles = 3;
            //     if ($scope.selectedCycleName == "6 Months") {
            //         numCycles = 6;
            //     }
            //     setOverallScoreCardForMonths(numCycles)
            // }
        };

        $scope.onClose = function () {
            $mdDialog.cancel();
        };


        $scope.getValueForKpi = function (kpiCode, kpiData) {
            if (kpiData[kpiCode].achievedValue == undefined) {
                return '';
            }
            return Math.round(kpiData[kpiCode].achievedValue.value * 100) / 100 + "" + kpiData[kpiCode].unit;
        };
        $scope.getKpiName = function (kpiCode, kpiData) {
            return kpiData[kpiCode].name;
        };

        $scope.getWeightForKpi = function (kpiCode, kpiData) {
            if (kpiData[kpiCode].weight == undefined) {
                return '';
            }
            return kpiData[kpiCode].weight * 100 + "" + kpiData[kpiCode].unit;
        };

        $scope.getValueForActivity = function (activity) {
            // var forecastKey = getForecastValueKey()
            // var value = activity[forecastKey].value;
            let value = null;
            if ($scope.isMeOwn) {
                value = activity.target.ownValue ? activity.achievedValue.ownValue/activity.target.ownValue: 0;
            } else {
                value = activity.target.value ? activity.achievedValue.value/activity.target.value: 0;
            }
            //for the special case when overall kpi value is added to activity
            if (activity.valueMultiplier) {
                value = value * activity.valueMultiplier;
                return { value: value, colour: getBackgroundForValue(value) };
            }
            const targetValue = $scope.isMeOwn ? activity.target.ownValue : activity.target.value;
            if (targetValue != undefined) {
                return { value: value * 100, colour: getBackgroundForValue(value) };
            }
            return { value: value, colour: getBackgroundForValue(value) };
        };

        function getBackgroundForValue(value) {
            if (value <= 50) {
                return {"background-color":"#f7e5de"};
            }
            else if (value < 90) {
                return {"background-color":"#fff5d9"};
            }
            else {
                return {"background-color":"#e1e8df"};
            }
        }

        $scope.getAchievedValueForKPI = function (kpi) {
            if ($scope.selectedForecastName.forecastKey == "0M") {
                return kpi.achievedValue.value;
            } else if ($scope.selectedForecastName.forecastKey == "1M") {
                return kpi.achievedValueForecast.value;
            } else {
                return kpi.avgAchievedValueForecast.value;
            }
        };

        $scope.getAchievedValueForActivity = function (activity) {
            var achievedValue;
            try {
                if (!activity.showDetailedMetrics) {
                    if ($scope.selectedForecastName.forecastKey == "0M") {
                        achievedValue = $scope.isMeOwn ? activity.achievedValue.ownValue : activity.achievedValue.value;
                    } else if ($scope.selectedForecastName.forecastKey == "1M") {
                        achievedValue = $scope.isMeOwn ? activity.achievedValueForecast.ownValue : activity.achievedValueForecast.value;
                    } else {
                        achievedValue = $scope.isMeOwn ? activity.sumAchievedValue.ownValue : activity.sumAchievedValue.value;
                    }
                    return $filter('number')(achievedValue, 2);
                } else {
                    if ($scope.selectedForecastName.forecastKey == "0M") {
                        if ($scope.isMeOwn) {
                            achievedValue = $filter('number')(activity.operand1.achievedValue.ownValue, 0) + ' / ' + $filter('number')(activity.operand1.target.ownValue, 0);
                        } else {
                            achievedValue = $filter('number')(activity.operand1.achievedValue.value, 0) + ' / ' + $filter('number')(activity.operand1.target.value, 0);
                        }
                    } else if ($scope.selectedForecastName.forecastKey == "1M") {
                        if ($scope.isMeOwn) {
                            achievedValue = $filter('number')(activity.operand1.achievedValueForecast.ownValue, 0) + ' / ' + $filter('number')(activity.operand1.target.ownValue, 0);
                        } else {
                            achievedValue = $filter('number')(activity.operand1.achievedValueForecast.value, 0) + ' / ' + $filter('number')(activity.operand1.target.value, 0);
                        }
                    } else {
                        if ($scope.isMeOwn) {
                            achievedValue = $filter('number')(activity.operand1.sumAchievedValue.ownValue, 0) + ' / ' + $filter('number')(activity.operand1.target.ownValue, 0);
                        } else {
                            achievedValue = $filter('number')(activity.operand1.sumAchievedValue.value, 0) + ' / ' + $filter('number')(activity.operand1.target.value, 0);
                        }
                    }
                    return achievedValue;
                }
            }
            catch (err) {
                return "";
            }
        };

        $scope.getScore = function () {
            var forecastKey = getForecastScoreKey();
            var value = kpi[forecastKey].value;
            return value;
        };

        $scope.getUnitForAchievement = function (activity) {
            if (!activity.showDetailedMetrics) {
                return activity.unit1 != '#' ? activity.unit1 : '';
            } else {
                return activity.operand1.unit1 != '#' ? activity.operand1.unit1 : '';
            }
        };

        $scope.getUnitForTarget = function (activity) {
            if (!activity.showDetailedMetrics) {
                return activity.unit1 != '#' ? activity.unit1 : '';
            } else {
                return activity.operand2.unit != '#' ? activity.operand2.unit : '';
            }
        };

        $scope.getTargetForActivity = function (activity) {
            try {
                if (activity.showDetailedMetrics) {
                    return activity.operator + ' ' + $filter('number')(activity.operand2.value.value * 100, 2) + '' + activity.unit;
                } else if (activity.hasSlabActivities) {
                    return 'Nil';
                } else {
                    return $filter('number')(activity.target.value, 2);
                }
            }
            catch (err) {
                return "";
            }
        };

        $scope.unitMetricChange = function (unitMetric) {
            $scope.selectedUnitMetric = unitMetric;
            if ($scope.selectedUnitMetric.key == "percentage") {
                $scope.isPercentage = true;
            }
            else {
                $scope.isPercentage = false;
            }
        }

        $scope.userViewConfigChange = function (userViewConfig) {
            $scope.selectedUserViewConfig = userViewConfig;
            //set userViewType based on selected value
            if ($scope.selectedUserViewConfig.key == "me") {
                $scope.isMe = true;
                $scope.isMeOwn = false;
            }
            else if ($scope.selectedUserViewConfig.key == "me(own)") {
                $scope.isMe = false;
                $scope.isMeOwn = true;
            }
            else {
                $scope.isMe = false;
                $scope.isMeOwn = false;
            }
            //set user view based on selected value
            if ($scope.isMe || $scope.isMeOwn) {
                setOverallScoreCardForCurrentUser()
            }
            else {
                setOverallScoreCardForUsers()
            }
        };

        function sortReporteeScorecardData(key) {
            if ($scope.sortOrder == "asc") {
                $scope.sortOrder = "desc"
            }
            else {
                $scope.sortOrder = "asc"
            }
            if (key == "overallScore") {
                $scope.userActivityData = _.orderBy($scope.userActivityData, function (object) {
                    var forecastKey = getForecastValueKey()
                    var value = object.activities[0][forecastKey].value;
                    return value;
                }, [$scope.sortOrder]);
            }
            else if(key == 'userName'){
                $scope.userActivityData = _.orderBy($scope.userActivityData, [key], [$scope.sortOrder]);
            }else{
                let forecastKey;
                if($scope.isPercentage){
                    forecastKey = getForecastValueKey();
                }else{
                    forecastKey = getForecastAchievedValueKey();
                }
                $scope.userActivityData = _.orderBy($scope.userActivityData, function (object) {
                    let activity = _.find(object.activities, {name:key});
                    if(activity){
                        return activity[forecastKey].value;
                    }
                    return false;
                }, [$scope.sortOrder]);
            }
        }

        $scope.onHeaderClick = function (key) {
            $scope.sortKey = key
            sortReporteeScorecardData(key);
        }
        /*------------------Scorecard excel download function------------------------*/
        $scope.downloadScorecardExcel = function () {
            const appUrl = Sidebar.getAppUrl();
            let url;
            const params = {cycleId, cycleName: selectedCycle, download: true};
            if(user.systemRole === 'frontline' || fetchReporteeData){
                url = appUrl + '/api/scorecard/template/frontline';
                params.userId = userIdForScoreCard;
                params.cycleName = selectedCycle
			}else{
                url =  appUrl + '/api/scorecard/template/manager';
                params.jobRoleId = jobRoleIdForScoreCard
			}
            return UtilService.downloadFile(url, params)
        };

        /**
         * scorecard download function for user
         */
        $scope.downloadUserScorecardExcel = () => {
            if ($scope.isMe || $scope.isMeOwn) {
                setOverallScoreCardForCurrentUser(true)
            }
            else {
                setOverallScoreCardForUsers(true)
            }
        }
        $scope.maximiseWindow = function () {
            $scope.isWindowMaximised = !$scope.isWindowMaximised;
        };
        var s2ab = function (s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        };

        var getSheetName = function () {
            var selectedCycleName = $scope.selectedCycle;
            selectedCycleName = selectedCycleName.replace(/'/g, "");
            if ($scope.selectedForecastName.forecastKey == "0M") {
                return selectedCycleName;
            }
            else if ($scope.selectedForecastName.forecastKey == "1M") {
                return selectedCycleName + '-Forecast - 1 Month';
            }
            else {
                return selectedCycleName + '-Forecast - 3 Months';
            }
        };

        function roundToTwoPlaces(num) {
            return Math.round(num * 100) / 100
        }

        $scope.download = function () {
            var userId = user._id;
            var metric = kpi.kpiCode;
            var numMonths = $scope.numMonths;
            var subMetric = "achievedValue";
            var fileName = loggedInUser.name + '_Scorecard_' + getTimeStamp() + '.xlsx'

            var unitMetric;
            if ($scope.isPercentage) {
                unitMetric = "percentage";
            }
            else {
                unitMetric = "achievementTarget";
            }

            const postBody = {
                userId: user._id,
                cycleId,
                jobRoleId: $scope.selectedJobRole._id,
                metric,
                subMetric,
                playType: $scope.selectedPlayType._id,
                numMonths,
                unitMetric,
            }
            $http.post('/api/userAnalytics/getExcelOverallScoreCardForUsersMonths', postBody, { responseType: "arraybuffer" })
                .success(function (filedata) {
                    // TODO: move excel download to server side
                    // saveAs(new Blob([filedata], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "scorecard.xlsx");â€‚
                    FileSaver.saveAs(new Blob([filedata], { type: "application/octet-stream" }), fileName);
                });
        };

        function getTimeStamp() {
            var today = new Date();
            var dd = today.getDate();
            var currMonthName = moment().format('MMMM');

            var yyyy = today.getFullYear();
            if (dd < 10) {
                dd = '0' + dd;
            }
            return dd + '_' + currMonthName + '_' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
        }

        function getForecastValueKey() {
            if ($scope.selectedForecastName.forecastKey == "0M") {
                return 'value';
            }
            else if ($scope.selectedForecastName.forecastKey == "1M") {
                return 'valueForecast';
            }
            else {
                return 'avgValue';
            }
        }
        function getForecastAchievedValueKey() {
            if ($scope.selectedForecastName.forecastKey == "0M") {
                return 'achievedValue';
            }
            else if ($scope.selectedForecastName.forecastKey == "1M") {
                return 'achievedValueForecast';
            }
            else {
                return 'sumAchievedValue';
            }
        }

        function getForecastScoreKey() {
            if ($scope.selectedForecastName.forecastKey == "0M") {
                return 'percentageCompleted';
            }
            else if ($scope.selectedForecastName.forecastKey == "1M") {
                return 'multAchievedValueForecast';
            }
            else {
                return 'avgMultAchievedValue';
            }
        }

        $scope.showDetailedScorecard = function(name, id){
            userIdForScoreCard = id;
            fetchReporteeData = true;
			$scope.breadcrumbList.push({name:name, id:id, type:"user"});
			$scope.scorecardTemplate.manager = getTemplateURL('frontline', {userId:id, cycleId:cycleId});
		};

		$scope.onBreadCrumbClick = function(item,index){
			if(index !== $scope.breadcrumbList.length-1){
				$scope.breadcrumbList = $scope.breadcrumbList.slice(0,index+1);
				let top = $scope.breadcrumbList[index];
				if(top.type === 'user'){
                    userIdForScoreCard = top.id;
                    fetchReporteeData = true;
					$scope.scorecardTemplate.manager = getTemplateURL('frontline', {userId:top.id, cycleId:cycleId});
				}else{
                    jobRoleIdForScoreCard = top.id;
                    fetchReporteeData = false;
					$scope.scorecardTemplate.manager = getTemplateURL('manager', {jobRoleId:top.id, cycleId:cycleId});
				}
			}
		};

        function getTemplateURL(roleType, params){
			let random = Math.floor(Math.random() * (100000-1) + 1 );
			//Note: The random query parameter makes the template URL Unique every time. Otherwise angularJs caches the template
			//The scorecard template is dynamically calculated and sent from the backend. So ng-includes takes the input of the
			//scorecard template URL. We can't use string html in ng-include (angular bindings doesn't works). Angular bindings workl only if you pass template url
			if(roleType === 'frontline'){
				return '/api/scorecard/template/frontline/user/'+params.userId+'/cycle/'+params.cycleId+'?random='+random;
			}else{
				return '/api/scorecard/template/manager/jobRole/'+params.jobRoleId+'/cycle/'+params.cycleId+'?random='+random;
			}
		}

        function isFrontlineTemplate(url){
        	return url.indexOf('frontline') !== -1;
		}

		$rootScope.$on('$includeContentRequested', function (event, url) {
			let message = 'Loading...';
			if(isFrontlineTemplate(url)){
				$scope.scorecardTemplate.showMessageFrontline = true;
				$scope.scorecardTemplate.massageFrontline = message;
			}else{
				$scope.scorecardTemplate.showMessageManager = true;
				$scope.scorecardTemplate.massageManager = message;
			}
		});

		$rootScope.$on('$includeContentLoaded', function (event, url) {
			if(isFrontlineTemplate(url)){
				$scope.scorecardTemplate.showMessageFrontline = false;
			}else{
				$scope.scorecardTemplate.showMessageManager = false;
			}
		});

		$rootScope.$on('$includeContentError', function (event, url,arg3, arg4) {
			console.log(event, url,arg3, arg4);
			let message = 'Unable to fetch the scorecard...';
			if(isFrontlineTemplate(url)){
				$scope.scorecardTemplate.showMessageFrontline = true;
				$scope.scorecardTemplate.massageFrontline = message;
			}else{
				$scope.scorecardTemplate.showMessageManager = true;
				$scope.scorecardTemplate.massageManager = message;
			}
		});


    });
