const autoImportNgTemplateLoaderTemplate1 = require('../html/kpiRadialGraph.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('kpiRadialGraph', function () {
        return {
            name: 'kpiRadialGraph',
            scope: {
                goal: '=',
                cycleIsActive: '=',
                i: '@',
                cycle: '=',
                selectedBenchmark: '=',
                isForecast: '='
            },
            controller: 'keyRadialGraphCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1

        };
    });

