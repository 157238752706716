const autoImportNgTemplateLoaderTemplate1 = require('../html/map/userDialog.html');

angular.module('bitnudgeApp')
    .controller('mapsController', function ($scope, NgMap, $http, $mdDialog, p0, $rootScope, toaster, Auth, $filter) {
        $scope.divisions = [];
        $scope.mapZoomLevel = p0.config.mapZoomLevel || 10;
        $scope.allDivisionUsers = [];
        $scope.liveFilter = false;
        $scope.liveUsers = [];
        $scope.nonLiveUsers = [];
        $scope.locationFilter = true;
        $scope.officeFilter = false;
        $scope.officeLocations = [];
        $scope.strings = $rootScope.strings;
        $scope.me = Auth.getCurrentUser();
        $scope.isSeniorManager = $rootScope.isSeniorManager;
        $scope.isFrontline = $rootScope.isFrontline;
        $scope.selected = {};

        const roleTypeNames = {
            'seniorManager':{name:$rootScope.strings.SENIORMANAGER.value, level:2},
            'manager':{name:$rootScope.strings.MANAGER.value, level:1},
            'frontline':{name: $rootScope.strings.FRONTLINE.value, level:0}
        };

        $scope.onRoleChange = function(role){
            $scope.selected.role = role;
            $scope.allUsers = $scope.selected.usersByRole[$scope.selected.role._id]

            if($scope.allUsers && $scope.allUsers.length){
                $scope.selected.selectedUsers = [$scope.allUsers[0].email]
            }
            $scope.usersData();
        }
       
        var getDivisionData = function () {
            let jobRoleIds  = [];
            p0.myDivisions.forEach(d =>{
                jobRoleIds = jobRoleIds.concat([...d.seniorManagers, ...d.managers, ...d.frontline])
            })
            jobRoleIds = [...new Set(jobRoleIds)]
            $http.post('/api/users/playersOfJobRoles',{userId:$scope.me._id, jobRoles: jobRoleIds, scope: 'basic'}).then(function (response) {
                response=response.data;
                if($rootScope.isSeniorManager){
                    $scope.selected.usersByRole = _.groupBy(response, 'systemRole')
                    const roles = Object.keys($scope.selected.usersByRole);
                    $scope.selected.roles = [];
                    roles.forEach(r => {
                        $scope.selected.roles.push({_id:r ,name:roleTypeNames[r].name, level:roleTypeNames[r].level});
                    })
                    $scope.selected.roles = $scope.selected.roles.sort((a,b)=> b.level - a.level)
                    $scope.onRoleChange($scope.selected.roles[0])
                }else{
                    $scope.allUsers = response;
                    if(response && response.length){
                        $scope.selected.selectedUsers = [$scope.allUsers[0].email]
                    }
                }
            });

        };

        $scope.usersData = function () {
            const params = {}
            if($scope.selectedCycle){
                params.startDate = $scope.selectedCycle.startDate,
                params.endDate = $scope.selectedCycle.endDate;
            }
            if($scope.selected.selectedUsers && $scope.selected.selectedUsers.length){
                params.reportees = $scope.selected.selectedUsers;
            }

            if(!$rootScope.isFrontline && (!$scope.selected.selectedUsers || !$scope.selected.selectedUsers.length)){
                $scope.events = [];
                return;
            }
            $http.post('api/Location/events', params).then(function (result) {
                result = result.data;
                if (result.status) {
                    $scope.events = result.data.results;
                } else {
                    toaster.pop('error', result.message)
                }
            });
        };

        $scope.cycleChanged = function(cycle){
            $scope.selectedCycle = cycle;
            $scope.usersData();
        }


        $scope.getLiveUserLocationData = function () {
            $http.post('api/Location/getLiveUserData', {userIds: $scope.selected.selectedUsers}).then(function (liveUsers) {
                
                liveUsers=liveUsers.data;
                $scope.liveUsers = liveUsers;
                _.remove($scope.liveUsers,function (user) {
                    return !user.locationInfo[0].latitude && !user.locationInfo[0].longitude;
                })
            });
        };

        $scope.getNonLiveUserLocationData = function (cycle) {
            const postBody = {
                startDate:new Date(cycle.startDate).getTime(),
                endDate: new Date(cycle.endDate).getTime(),
                userIds: $scope.selected.selectedUsers
            }
            $http.post('api/Location/getNonLiveUserData', postBody).then(function (response) {
                response=response.data;
                $scope.nonLiveUsers = response.notLiveUsers;
            })
        };

        $scope.path = $scope.nonLiveUsers.forEach(function (user) {
            user.locationInfo.forEach(function (location) {
                return [location.latitude, location.longitude];
            })
        });

        $scope.locationFilterHandler = function () {
            //if Field Only is required leave
            if(!$scope.locationFilter)
            {
                var temp = $scope.liveUsers;
                $scope.liveUsers = [];
                temp.forEach(function (user) {
                    if(user.locationInfo.locationType !="Office"){
                        $scope.liveUsers.push(user);
                    }
                });
                $scope.nonLiveUsers.forEach(function (user) {
                    var temp = [];
                    user.locationInfo.forEach(function (location) {
                        if(location.locationType !="Office"){
                            temp.push(location);
                        }
                    });
                    user.locationInfo = [];
                    user.locationInfo = temp;
                });
            }
            else{
                // $scope.getLiveUserLocationData();
                // $scope.getNonLiveUserLocationData();
            }
        };

        $scope.liveFilterHandler = function () {
            if($scope.liveFilter){
                // $scope.getLiveUserLocationData();
            }
        };

        $scope.officeFilterHandler = function() {
            if($scope.officeFilter)
            {
                var location= [];
                $scope.allDivisionUsers.forEach(function (user) {
                    location.push(user.officeLocation);
                });
                $scope.officeLocations = _.uniqBy(location, 'latitude');
            }
            else $scope.officeLocations = [];
        };
        $scope.getReporteesPercentWithGPSon= function(percentage){
            var employees = [];
            percentage = percentage /100;
            var workingHoursPerDay = ($rootScope.config.officeEndTime - $rootScope.config.officeStartTime)/100;
            var numberOfWorkingDays = $rootScope.calcBusinessDays($scope.selectedCycle.startDate, $scope.selectedCycle.endDate);
            var totalEnteriesInSelectedWorkingDays = (workingHoursPerDay * numberOfWorkingDays)/$rootScope.config.LOC_FREQ_MINS;
            $scope.nonLiveUsers.forEach(function (user) {
                if((user.locationInfo.length/Math.round(totalEnteriesInSelectedWorkingDays)) < percentage)
                    employees.push(user);
            });
            $scope.employeesWithGPSOnLessThanPercentage =  employees;
        };


        $scope.showMarkerInfo = function(event,marker){
            $scope.selectedMarker = marker;
            const allEventsAtSamePlace = $scope.events.filter(point => point.lat === marker.lat && point.long === marker.long)
            // $scope.map.showInfoWindow('mappopup', marker.linkCode.toString());
            let infowindow = new google.maps.InfoWindow();
            let center = new google.maps.LatLng(marker.lat,marker.long);
            let content = '';
            if(allEventsAtSamePlace && allEventsAtSamePlace.length > 1){
                content = `<h5 class="marker-body">`;
                allEventsAtSamePlace.forEach((event, index)=>{
                    content += `<b class="marker-header">User:</b> ${event.userName}<br>
                    <b class="marker-header">Event:</b> ${$filter('camelCaseToCapitalize')(event.eventType)} (<a href="${p0.config.saleskeyDomainExternal}/c/${event.linkCode}" target="blank">${event.linkCode}</a>) at ${$filter('date')(event.createdAt,'d-MMM-y hh:mm a')}<br>
                    ${(index === allEventsAtSamePlace.length -1) ? (`<br><b class="marker-header">Address:</b> ${event.address} <br> `):(`<br>`)}`
                })
                content += `</h5>`
            }else{
                content = `<h5><b class="marker-header">User:</b> ${marker.userName}<br>
                <b class="marker-header">Event:</b> ${$filter('camelCaseToCapitalize')(marker.eventType)} (<a href="${p0.config.saleskeyDomainExternal}/c/${marker.linkCode}" target="blank">${marker.linkCode}</a>) at ${$filter('date')(marker.createdAt,'d-MMM-y hh:mm a')}<br>
                <b class="marker-header">Address:</b> ${marker.address}</h5>`
            }
            infowindow.setContent(content);
            infowindow.setPosition(center);
            infowindow.open($scope.map);
        };

        $scope.showUserListDialog = function () {
            var liveUserIds = _.map($scope.liveUsers,'userId._id');
            var nonLiveUsers = _.filter($scope.allDivisionUsers,function(user){
                return !_.includes(liveUserIds,user._id);
            });
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                parent: angular.element(document.body),
                controllerAs: 'ctrl',
                bindToController: true,
                locals: {
                    thisScope: nonLiveUsers
                },
                controller: function ($scope, thisScope, $mdDialog) {
                    $scope.close = function (){
                        $mdDialog.hide();
                    }
                },
                clickOutsideToClose: true,
                fullscreen: true // Only for -xs, -sm breakpoints.
            });
        };
        //init map
        $scope.initMap = function() {
            return NgMap.getMap("map")
                .then(function (map) {
                    $scope.map = map;
                });
        }
        getDivisionData();
        $scope.getBackground = function(num,total){
            return 'rgba(0,0,0,'+(num/total-1)+')';
        }

        if($rootScope.isFrontline){
            $scope.usersData();
        }
    });
