'use strict';
angular.module('bitnudgeApp')
    .controller('playerComparisonMirrorChartCtrl', function ($scope, $http, $rootScope,Auth, $location, $state, analytics) {

        $scope.strings = $rootScope.strings;
        $scope.selectedRow =[];
        $scope.defaultSelection = [];
        if($scope.isMenu){
            $scope.defaultSelection[$scope.selectedIndex]='defaultSelection';
            $scope.sideMenuClass= 'user';
        }
        else
            $scope.sideMenuClass= 'detail';

        $scope.highlightRow = function(index){
            $scope.selectedRow = [];
            if($scope.isMenu)
                $scope.selectedRow[index]='selectedRow';
        };
        $scope.forecastMultiplier = $rootScope.forecastMultiplier;
    });
