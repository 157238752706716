'use strict';
angular.module('bitnudgeApp')
    .service('ForecastMultiplier', function (p0, $rootScope) {
        _.this = this;

        this.ofCycle = function (cycleId) {
            var cycle = _.find(p0.allCycles, {_id: cycleId});
            if(!cycle) return 0;
            if(cycle.cycleType == 'daily') return 1;
            var lastDate = new Date(p0.lastUpdatedAt);
            if(cycle.active){
                if(lastDate < new Date(cycle.startDate)){
                    lastDate = new Date(cycle.startDate);
                }
                return calculateForecastMultiplier(new Date(cycle.startDate), lastDate, new Date(cycle.endDate));
            }else{ //If last sync date is in last month
                if(lastDate < new Date(cycle.endDate)){
                    return calculateForecastMultiplier(new Date(cycle.startDate), lastDate, new Date(cycle.endDate));
                }else return 1;
            }
        };

        var calculateForecastMultiplier = function (startDate, currentDate, endDate) {
            var daysLeft = $rootScope.calcBusinessDays(currentDate, new Date(endDate)) - 1;
            if (daysLeft < 0) {
                daysLeft = 0;
            }
            var daysTotal = $rootScope.calcBusinessDays((new Date(startDate)), (new Date(endDate)));
            var daysPassed = daysTotal - daysLeft;
            return daysPassed == 0 ? NaN : daysTotal / daysPassed;
        };

    });
