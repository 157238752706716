const autoImportNgTemplateLoaderTemplate1 = require('../html/playbookCarousel.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('playbookCarousel', function(){
        return {
            name: 'playbookCarousel',
            scope: {
                data: '=',
                heading: '=',
                redeemable: '=',
                redeem:     '='
            },
            controller: 'playbookCarouselCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
