'use strict';
angular.module('bitnudgeApp')
    .controller('addBulkIndividualIncentiveController', function ($http, $scope, Auth, Upload, $location, toaster, p0) {

        $scope.allCycles = p0.allCycles;
        $scope.selectedCycle=p0.currentMonthlyCycle;
        $scope.onFileSelectIndi = function ($files) {
            //$files: an array of files selected, each file has name, size, and type.
            if(!$scope.selectedCycle)
                return;
            for (var i = 0; i < $files.length; i++) {
                var $file = $files[i];
                var fileName = $files[i].name.split(".");
                var fileType = fileName[fileName.length - 1];
                if (fileType == "xls" || fileType == "xlsx" || fileType == "xlsm" || fileType == "xlsb") {
                    Upload.upload({
                        url: '/api/goals/incentives/import/excel/' + $scope.selectedCycle._id,
                        file: $file,
                        progress: function (e) {
                        }
                    }).then(function (data, status, headers, config) {
                        // file is uploaded successfully
                        console.log(data);
                        // $location.path('/lms/listIndividualTarget');
                        toaster.pop('success', "Bulk Individual target Creation.", "Bulk Individual target Creation.");
                    });
                }else{
                    toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' Types Only.");
                }
            }
        }
    })
