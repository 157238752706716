'use strict';
angular.module('bitnudgeApp')
    .controller('gamePlanModalCtrl', function ($scope, $rootScope, $mdDialog, selectedPlayer, selectedKPI, type,leagueAnalytic, p0,userAnalyticsService, ForecastMultiplier) {
        $scope.selectedPlayer = selectedPlayer;
        $scope.selectedKPI = selectedKPI;
        $scope.config = p0.config;
        $scope.previousCycle = p0.previousMonthlyCycle;
        var leagueOrder = ['bank','region','team'];
        var bms = [];
        var bm;
        _.forEach(leagueOrder,function (league) {
            bm = _.find($scope.selectedKPI.benchmarks,{teamType:league});
            if(bm)bms.push(bm);
        });
        $scope.selectedKPI.benchmarks = bms;
        $scope.type = type;
        $scope.isPlayer = $rootScope.isPlayer;
        $scope.forecastMultiplier = ForecastMultiplier.ofCycle(p0.currentMonthlyCycle._id);
        var previousLeagueAnalytics= [];
        var leagues = _.map($scope.selectedPlayer.leagues,'leagueId');
        if(p0.previousMonthlyCycle) {
            leagueAnalytic.fetchLeagueAnalyticsOfCycle(leagues, p0.previousMonthlyCycle._id).then(function (leagueAnalytics) {
                previousLeagueAnalytics = leagueAnalytics;
                setData();
            })
        }else{
            setData();
        }
        function setData() {
            if ($scope.type == 'kpi') {
                $scope.selectedKPI.benchmarks.forEach(function(benchmark){
                    var selectedLeagueAnalytics  =_.find(previousLeagueAnalytics,{teamType:benchmark.teamType});
                    if(selectedLeagueAnalytics) {
                        benchmark.previousPeriod = selectedLeagueAnalytics.percentageCompleted.avg || 0;
                    }
                });
                $scope.planTitle = 'Gameplan for the Product';
                if(!p0.config.disableReporteeGamePlan) $scope.targetTitle = 'Gameplan proposed by Manager';
                $scope.planValue = $scope.selectedKPI.gameplanPercentage;
                $scope.targetValue = $scope.selectedKPI.managerPercentage;
                $scope.subtitle = '(% of target)';
                $scope.title = 'Key stats and benchmarks';
            }
            else{
                if(p0.previousMonthlyCycle){
                    userAnalyticsService.fetchUserAnalytics(selectedPlayer._id, p0.previousMonthlyCycle._id).then( function (prevUserAnalytics) {
                        if (prevUserAnalytics) {
                            var activity = _.find(prevUserAnalytics.activityData, {
                                fieldName: $scope.type,
                                kpiId: $scope.selectedKPI.kpiId
                            });
                            $scope.prevActivity = activity.value.value;
                        }
                    });
                }
                leagueAnalytic.fetchLeagueAnalyticsOfCycle(leagues, p0.currentMonthlyCycle._id).then(function (leagueAnalytics) {
                    $scope.selectedKPI.benchmarks.forEach(function(benchmark){
                        var selectedLeagueAnalytics  =_.find(leagueAnalytics,{teamType:benchmark.teamType});
                        var activity = _.find(selectedLeagueAnalytics.activityData,{fieldName:$scope.type,kpiId:$scope.selectedKPI.kpiId});
                        benchmark.activityAvg = activity.value.avg;

                        var prevLeagueAnalytics = _.find(previousLeagueAnalytics,{teamType:benchmark.teamType});
                        if(prevLeagueAnalytics) {
                            var prevActivity = _.find(prevLeagueAnalytics.activityData, {fieldName:$scope.type, kpiId: $scope.selectedKPI.kpiId});
                            benchmark.prevActivity = prevActivity.value.avg;
                        }
                    });
                });
                $scope.planValue = $scope.selectedKPI[$scope.type].reqPlan;
                $scope.targetValue = $scope.selectedKPI[$scope.type].req;
                $scope.subtitle = '(key stats and benchmark)';

                switch($scope.type){
                    case 'call':
                        $scope.planTitle = 'Calls required for plan';
                        $scope.targetTitle = 'Calls required for target';
                        $scope.title = 'Number of calls';
                        break;
                    case 'submission':
                        $scope.planTitle = 'Submissions required for plan';
                        $scope.targetTitle = 'Submissions required for target';
                        $scope.title = 'Number of submissions';
                        break;
                    case 'newLead':
                        $scope.planTitle = 'Leads required for plan';
                        $scope.targetTitle = 'Leads required for target';
                        $scope.title = 'Number of leads';
                        break;
                    case 'throughput':
                        $scope.planTitle = 'Throughput required for plan';
                        $scope.targetTitle = 'Throughput required for target';
                        $scope.title = 'Throughput';
                        break;
                }

            }
        }
        $scope.onClose = function() {
            $mdDialog.hide();
        };
    });
