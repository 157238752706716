'use strict';
angular.module('bitnudgeApp')
    .service('profile', function ($http) {
        this.getUserData = function(userId,playerData){
            return $http.post('/api/userAnalytics/getProfileDashboard', {userId}).then(function(userAnalytic){
                userAnalytic=userAnalytic.data;
                playerData.points = userAnalytic.points.value;
                if(playerData.systemRole != 'seniorManager'){
                    var bankRank = _.find(userAnalytic.points.ranks,{teamType:'bank'});
                    playerData.rank = bankRank.value;
                    playerData.level = userAnalytic.level;
                    return;
                }
                return;
            });
        }

        this.getPlayerList = function(){
            return $http.post('/api/users/usersList').then(function(playerList){
                playerList=playerList.data;
                return playerList;
            });
        }
    });
