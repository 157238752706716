'use strict';
angular.module('bitnudgeApp')
    .controller('rhsAnalyticsTabCtrl', function ($scope, Auth,$state, $rootScope) {

        let self = this;

        this.init = function(){
            $scope.expandReco = true;
            $scope.view = 'lvl1';
            $scope.me = Auth.getCurrentUser();
            $scope.isPlayer = $rootScope.isPlayer;
            self.stateChanged($state.current);
        }
        $rootScope.$on('$stateChangeStart',
            function(event, toState, toParams, fromState, fromParams){
                self.stateChanged(toState);
        });

        this.stateChanged = function(state){
            console.log('state.name: ', state.name);
            if (state.name === 'userFlow.mgrDashboard') {
                $scope.hideRecommendation = true;
                $scope.showWidgetSidePanel = true;
            } else if(state.name == 'userFlow.profile' || state.name == 'userFlow.newsAndUpdates'){
                $scope.hideRecommendation = true;
                $scope.showWidgetSidePanel = false;

            }else{
                $scope.hideRecommendation = false;
                $scope.showWidgetSidePanel = false;
                if(state.name == 'userFlow.dashboard' || state.name == 'lms.listLead' || state.name == 'userFlow.oneClickReport'){
                    $scope.expandReco = true;
                }else{
                    $scope.expandReco = false;
                }
            }
        }

        $scope.toggleRecommendations = function(){
            if($scope.expandReco){
                $scope.expandReco = false;
            }else{
                $scope.expandReco = true;
            }

        };

        $scope.changeView = function(view){
            $scope.view = view;
        }
        this.init();
    });
