const autoImportNgTemplateLoaderTemplate1 = require('../html/admin/uploadFlowCronLog.html');

angular.module('bitnudgeApp')
    .controller('uploadFlowCronCtrl', function ($scope, Auth, toaster, uploadFlowCrons, $mdDialog, SocketService) {

        var runningStatuses = {
            "0": "CREATED",
            "1": "ACTIVE",
            "-1": "HALTED",
            "2": "TERMINATED",
            "3": "SUCCESSFULLY_EXECUTED",
            "-3": "CRASHED"
        }
        
        $scope.query = {
            order: 'date',
            limit: 10,
            page: 1,
            total:0,
            pages:2,
            disableNext:false,
            disablePrevious:false
        };
        $scope.limitOptions = [5, 10, 15];

        $scope.options = {
            rowSelection: false,
            multiSelect: false,
            autoSelect: false,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true
        };

        
        getuploadFlowCronsFunc();
        
        $scope.killCron = function(cron, uploadTrackerId,ev){

            var confirm = $mdDialog.confirm(ev)
                .title('Upload Flow Cron')
                .textContent('Are you sure you want to kill the cron ? ')
                .ariaLabel('Kill Cron')
                .targetEvent(ev)
                .ok('Yes')
                .cancel('No');

            $mdDialog.show(confirm).then(function() {
                if (!cron.kill) {
                    uploadFlowCrons.killUploadFlowCron(uploadTrackerId).then(function(response){
                        toaster.pop("Success",'Upload Cron', response.data.message);
                    },function(error){
                        cron.kill = false;
                        toaster.pop('Error', 'Upload Cron', error.data.message);
                    })
                }else{
                    toaster.pop('Warning', 'Upload Cron','Already in killed!');
                }
                $mdDialog.hide();
            }, function() {
                $scope.status = 'You decided to keep your debt.';
                $mdDialog.hide();
            });
        }

        SocketService.getSocket(function(socket) {
            $scope.adminLogMessage = "";
            socket.removeAllListeners('logMessage'); //Remove all listeners on the same before listen
            socket.on('logMessage', function (data) {
                $scope.socketLog =  data;
            });
        });

        $scope.showLogs = function(uploadTrackerId){
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'uploadFlowCronLogCtrl',
                parent: angular.element(document.body),
                clickOutsideToClose:true,
                fullscreen: true ,// Only for -xs, -sm breakpoints.
                resolve:{
                    parameters: function () {
                        return {
                            uploadTrackerId: uploadTrackerId,
                        };
                    }
                }
            })
        };

        $scope.nextPage = function(){
            $scope.query.page += 1;
            
            getuploadFlowCronsFunc()

        }
    
        $scope.previousPage = function(){
            $scope.query.page -= 1;

            getuploadFlowCronsFunc()

        }

        $scope.changeLimit = function(limit){
            $scope.query.limit = limit;
            $scope.query.page = 1,
            getuploadFlowCronsFunc()
        }

        function setPaginatedButtons(){
            if(($scope.query.limit * $scope.query.page) >= $scope.query.pages*$scope.query.limit){
                $scope.query.disableNext = true;
            } else {
                $scope.query.disableNext = false;            
            }
            if($scope.query.page === 1){
                $scope.query.disablePrevious = true;
            } else {
                $scope.query.disablePrevious = false;            
            }
        }

        function getuploadFlowCronsFunc(){
            var uploadFlowCronsPromise = uploadFlowCrons.getUploadFlowCrons($scope.query.page,$scope.query.limit);
            uploadFlowCronsPromise.then(function(success){
                $scope.crons = success.data.docs; 
                $scope.crons.forEach(function(cron) {
                    cron.name = cron.moduleTag ? cron.moduleTag : cron.token.split('-')[0]
                    cron.status = runningStatuses[String(cron.runningStatus)]
                    cron.date = cron.triggerTs ? moment(cron.triggerTs).format('MM/DD/YYYY , hh:mm:ss a'): "";
                    if(["TERMINATED", "SUCCESSFULLY_EXECUTED", "CRASHED"].indexOf(cron.status) !== -1){
                        cron.kill = true;
                    }else{
                        cron.kill = false;
                    }
                })
                $scope.query.total = success.data.total;
                $scope.query.pages = success.data.pages;
                setPaginatedButtons()
              //  toaster.pop("Success","Upload Cron","Crons fetched successfully!");
            },function(err){
                toaster.pop('Warning','Upload Cron','Something went wrong!');
            });
        }

    });
