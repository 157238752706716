const autoImportNgTemplateLoaderTemplate1 = require('../html/admin/level/listLevel.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/admin/level/addLevel.html');
const autoImportNgTemplateLoaderTemplate3 = require('../html/admin/level/addBulkLevel.html');

'use strict';

angular.module('bitnudgeApp')
  .config(function ($stateProvider) {
    $stateProvider
        .state('lms.listLevel',{
            url: '/listLevel',
            templateUrl: autoImportNgTemplateLoaderTemplate1,
            controller: 'listLevelCtrl'
        })

        .state('lms.addLevel',{
            url: '/addLevel/:id',
            templateUrl: autoImportNgTemplateLoaderTemplate2,
            controller: 'addLevelCtrl'
        })
        .state('lms.addBulkLevel',{
            url: '/addBulkLevel',
            templateUrl: autoImportNgTemplateLoaderTemplate3,
            controller: 'bulkLevelCtrl'
        })
  });
