angular.module('bitnudgeApp').controller('gpGraphCtrl', function($scope, $compile, $timeout, $http, $rootScope, getGPService, Auth, p0, $filter) {

    $scope.me = Auth.getCurrentUser();
    $scope.selectKpiType = 'value';
    $scope.config = p0.config;
    $scope.previousPeriod = p0.previousMonthlyCycle;

    if ($scope.goal.dateRange) {
        $scope.daysTotal = Math.max($rootScope.weeklyDaysLeft, 1);
    } else {
        $scope.daysTotal = Math.max($rootScope.daysLeft, 1);
    }
    $scope.goal.roundedPercentage = Math.round($scope.goal.gameplanPercentage);

    if ($scope.goal.kpiType == 'value') {
        $scope.goal.newGpValue = Math.round($scope.goal.gameplanPercentage * $scope.goal.target.value) / 100;
        $scope.goal.newGpPoints = $scope.goal.newGpValue * $scope.goal.denom;
    }
    $scope.$watch('goal.gameplanPercentage', function(newVal, oldVal) {
        if (newVal) {
            if (newVal !== oldVal) {
                if (newVal > 0) {
                    $scope.goal.isChanged = true;
                }
            }
            $scope.goal.gameplanPercentage = newVal;
            $scope.goal.roundedPercentage = Math.round($scope.goal.gameplanPercentage);

            if ($scope.goal.kpiType === 'value') {
                if ($scope.goal.unit == '#') {
                    $scope.goal.newGpValue = Math.ceil(newVal * $scope.goal.target.value / 100);
                } else {
                    $scope.goal.newGpValue = (newVal * $scope.goal.target.value / 100).toFixed(2);
                }
                $scope.goal.gpRunRate = (($scope.goal.newGpValue - $scope.goal.achievedValue.value) / $rootScope.daysLeft * 5).toFixed(2);
                $scope.goal.newGpPoints = ($scope.goal.newGpValue * $scope.goal.denom).toFixed(0);
                $scope.goal.profitMargin = $filter('currency')(($scope.goal.newGpValue * $scope.goal.margin.value) / 100, '')
            } else {
                $scope.goal.newGpValue = Math.ceil(newVal * $scope.goal.target.value / 100);
                $scope.goal.gpRunRate = getGPService.getGamePlanRunRateOfKPI($scope.goal);
                $scope.goal.profitMargin = $filter('currency')(($scope.goal.newGpValue * $scope.goal.margin.value) / 100, '')
            }
            if ($scope.onSlide != undefined)
                $scope.onSlide($scope.goal);
        }
    });

    $scope.setPercent = function() {
        //$scope.goal.newGpValue = $scope.goal.newGpPoints / $scope.goal.denom;
        $scope.goal.gameplanPercentage = ($scope.goal.newGpValue / $scope.goal.target.value) * 100;
        $scope.goal.roundedPercentage = Math.round($scope.goal.gameplanPercentage);
    };

    $scope.setValue = function() {
        $scope.goal.gameplanPercentage = $scope.goal.roundedPercentage;
        $scope.goal.newGpValue = ($scope.goal.gameplanPercentage * $scope.goal.target.value) / 100 ;
    };

    $scope.getProjectedValue = function(goal) {
        if (!goal)
            return 0;
        return parseFloat(((goal.percentageCompleted.value + goal.projectedTotalLeft) * goal.target.value) / 100);
    };

    $scope.getGetMarkers = function() {
        if ($scope.getProjectedValue($scope.goal) / $scope.goal.target.value > 1.5) {
            return '0,50';
        } else {
            return '0,50,150'
        }
    };

    $scope.getBarColor = function(goal) {
        if (!goal)
            return;
        if ($scope.getProjectedValue(goal) > goal.target.value) {
            return "progress-bar-success"
        } else return "progress-bar-danger";
    };

    $scope.getGpClass = function(goal) {
        var neededClass = '';
        if ((goal.gameplanPercentage * (100 / 150)) >= 66.66667) {
            neededClass = '';
        } else {
            neededClass = 'custom-gp-image'
        }
        return neededClass;
    };

    $scope.getMarkerPosition = function(value) {
        var percent = value * (100 / 150);
        return Math.min(percent, 100);
    };
    $scope.getForecastBarLength = function(value) {
        if (!$scope.goal)
            return;
        var remainingSpace = 100 - $scope.getMarkerPosition($scope.goal.percentageCompleted.value);
        var length = Math.min(remainingSpace, value * (100 / 150));
        return length;
    };

    $scope.getSalesRR = function() {
        return Math.max($scope.goal.newGpValue - $scope.goal.achievedValue.value, 0) / $scope.daysTotal;
    };


});