const autoImportNgTemplateLoaderTemplate1 = require('../html/taggingInput.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('taggingInput', function(){
        return {
            name: 'taggingInput',
            scope: {
                playerList:     '=',
                addNudge:       '=',
                message:        '=',
                addReply:       '=',
                type:           '@',
                kpis:           '='

            },
            controller: 'taggingInputCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });


