'use strict';
angular.module('bitnudgeApp')
    .controller('playerCommentModalController', function ($scope, $mdDialog, player,type,feed, params, social, dailyFeed) {

        $scope.sendComment = function() {
            $mdDialog.hide();
            if(type == 'dailyFeed'){
                dailyFeed.postComment(feed,$scope.comment);
            }else {
                social.postComment(player, $scope.comment, params.cycleId);
            }
        };
        $scope.onClose = function() {
            $mdDialog.hide();
        };
    });
