'use strict';
angular.module('bitnudgeApp')
    .controller('userActivityCtrl', function ($scope, $rootScope, Auth, UserActivities) {
        $scope.strings = $rootScope.strings;
        $scope.me = Auth.getCurrentUser();
        $scope.selected = {};

        $scope.pagination = {
            offset: 0,
            limit: 50,
            total: 0,
            disableNext: false,
            disablePrevious: false
        };
        var setPaginationData = function(data){
            console.log(data);
            $scope.pagination.total = data.data.total;

            if(data.data.interactions.length == 0){
                $scope.filteredCount = 0;
            } else {
                if($scope.pagination.offset+$scope.pagination.limit < $scope.pagination.total){
                    $scope.filteredCount = ($scope.pagination.offset+1)+'-'+($scope.pagination.offset+$scope.pagination.limit);
                } else {
                    $scope.filteredCount = ($scope.pagination.offset+1)+'-'+($scope.pagination.total);
                }
            }
            if(($scope.pagination.offset+$scope.pagination.limit) >= $scope.pagination.total){
                $scope.pagination.disableNext = true;
            } else {
                $scope.pagination.disableNext = false;
            }
            if($scope.pagination.offset == 0){
                $scope.pagination.disablePrevious = true;
            } else {
                $scope.pagination.disablePrevious = false;
            }
        }

        $scope.nextPage = function () {
            $scope.pagination.offset += $scope.pagination.limit;
            fetchData($scope.selected.user ? $scope.selected.user : $scope.me)
        }

        $scope.previousPage = function () {
            $scope.pagination.offset -= $scope.pagination.limit;
            fetchData($scope.selected.user ? $scope.selected.user : $scope.me)
        }

        $scope.onReporteeChange = function (user) {
            $scope.selected.user = user;
            fetchCounts($scope.selected.user);
            fetchData($scope.selected.user);
        }

        var fetchCounts = function (user) {
            UserActivities.getInteractionHistoryCounts(user).then(function (result) {
                let counts = result.data;
                counts.forEach(function(data){
                    let activity = _.find(activityTypesBackup, {internalId: data._id});
                    if(activity){
                        activity.count = data.count;
                    }
                });
                $scope.selectTab('all');
            })
        }

        var fetchData = function (user) {
            $scope.apiLoaded = false;
            let status = 'completed';
            UserActivities.getInteractionHistory(user, $scope.pagination, status).then(function (data) {
                setPaginationData(data);
                $scope.activityHistory = data.data.interactions;
                _.forEach($scope.activityHistory, (history)=> {
                    history.result = (history.status == 'failed' ? 'warning' : 'success');
                })
                $scope.apiLoaded = true;
            });
        }

        

        $scope.apiLoaded = false;

        $scope.activitiesOld = [
            {
                type: 'call', completedAt: new Date('2018-06-15T06:59:59'), text1: 'Called by R.M. Raunak', result: 'success', icon: 'fa fa-phone',
                head1: 'Service Request', text2: 'Follow-up Call', text3: 'New Service request Created', text4: 'SR no. 1234567'
            },
            {
                type: 'call', completedAt: new Date('2018-06-15T05:59:59'), text1: 'Called by Client', result: 'warning', icon: 'fa fa-phone',
                head1: 'Service Request', text2: 'Follow-up Call', text3: 'New Service request Created', result: 'SR no. 1234567'
            },
            {
                type: 'email', completedAt: new Date('2018-06-15T12:59:59'), text1: 'Email by RM', result: 'success', icon: 'fa fa-envelope',
                head1: 'Service Request', text2: 'Email to notify', text3: 'New Service request Created', text4: 'SR no. 1234567'
            },
            {
                type: 'message', completedAt: new Date('2018-06-15T15:59:59'), text1: 'Message by RM', result: 'success', icon: 'fa fa-comment',
                head1: 'Service Request', text2: 'Message to notify', text3: 'New Service request Created', text4: 'SR no. 1234567'
            },
            {
                type: 'meeting', completedAt: new Date('2018-06-15T18:59:59'), text1: 'Client came for meeting', result: 'warning', icon: 'fa fa-phone',
                head1: 'Service Request', text2: 'Meeting', text3: 'New Service request Created', text4: 'SR no. 1234567'
            },
            {
                type: 'notification', completedAt: new Date('2018-06-15T18:59:59'), text1: 'Notification', result: 'success', icon: 'fa fa-bell',
                head1: 'Service Request', text2: 'Notification', text3: 'New Service request Created', text4: 'SR no. 1234567'
            },
            {
                type: 'notification', completedAt: new Date('2018-06-15T18:59:59'), text1: 'Notification', result: 'success', icon: 'fa fa-bell',
                head1: 'Service Request', text2: 'Notification', text3: 'New Service request Created', text4: 'SR no. 1234567'
            },
            {
                type: 'notification', completedAt: new Date('2018-06-15T18:59:59'), text1: 'Notification', result: 'success', icon: 'fa fa-bell',
                head1: 'Service Request', text2: 'Notification', text3: 'New Service request Created', text4: 'SR no. 1234567'
            },
            {
                type: 'notification', completedAt: new Date('2018-06-15T18:59:59'), text1: 'Notification', result: 'success', icon: 'fa fa-bell',
                head1: 'Service Request', text2: 'Notification', text3: 'New Service request Created', text4: 'SR no. 1234567'
            },
            {
                type: 'notification', completedAt: new Date('2018-06-15T18:59:59'), text1: 'Notification', type: 'success', icon: 'fa fa-bell',
                head1: 'Service Request', text2: 'Notification', text3: 'New Service request Created', text4: 'SR no. 1234567'
            },
            {
                type: 'notification', completedAt: new Date('2018-06-15T18:59:59'), text1: 'Notification', type: 'success', icon: 'fa fa-bell',
                head1: 'Service Request', text2: 'Notification', text3: 'New Service request Created', text4: 'SR no. 1234567'
            },
            {
                type: 'notification', completedAt: new Date('2018-06-15T18:59:59'), text1: 'Notification', type: 'success', icon: 'fa fa-bell',
                head1: 'Service Request', text2: 'Notification', text3: 'New Service request Created', text4: 'SR no. 1234567'
            },
            {
                type: 'notification', completedAt: new Date('2018-06-15T18:59:59'), text1: 'Notification', type: 'success', icon: 'fa fa-bell',
                head1: 'Service Request', text2: 'Notification', text3: 'New Service request Created', text4: 'SR no. 1234567'
            },
        ];

        let activityTypesBackup;
        function initializeActivityCounts(activities){
            _.forEach(activities, function(act){
                act.count = 0;
            })
            activityTypesBackup = activities;
            $scope.activityTypes = _.cloneDeep(activities);

        }
        $scope.selectTab = function (tab) {
            $scope.selectedTab = tab;
            
            if (tab == 'all') {
                $scope.activityTypes = _.cloneDeep(activityTypesBackup);
            }
            else if(tab == 'ctob'){
                let activities = _.filter(activityTypesBackup, (act)=>{
                   return act.subText == 'Cust. to Bank';
                })
                $scope.activityTypes = _.cloneDeep(activities);
            }
            else{
                let activities = _.filter(activityTypesBackup, (act)=>{
                    return act.subText !== 'Cust. to Bank';
                 })
                 $scope.activityTypes = _.cloneDeep(activities);
            }

        }
        function init() {
            UserActivities.getInteractionTypes().then(function (activities) {
                $scope.interactionIcons = UserActivities.getInteractionIcons();
                initializeActivityCounts(activities);
                if ($scope.me.roleType == 'frontline') {
                    fetchCounts($scope.me);
                    fetchData($scope.me);
                }
            });

        }
        init();

    });
