const autoImportNgTemplateLoaderTemplate1 = require('../html/heatmapMirrorChart.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('heatmapMirrorChart', function(){
        return {
            name: 'heatmapMirrorChart',
            scope: {
                userId: '=',
                kpi: '=',
                activity: '=',
                activityName: '=',
                isReportee: '=',
                type: '=',
                target: '=',
                isWeekly:'=',
                cycleId: '=',
                isCycleActive: '='
            },
            controller: 'heatmapMirrorChartCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
