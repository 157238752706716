'use strict';
angular.module('bitnudgeApp')
    .controller('barGraphCtrl', function ($scope) {
        if($scope.max == 0 || isNaN($scope.max)){
            $scope.max = 1;
        }
        $scope.length = Math.min($scope.value/$scope.max * 100,100);
        if($scope.value2){
            $scope.length2 = Math.min($scope.value2/$scope.max2 * 100,100);
        }
        if(!$scope.color)
            $scope.color = 'blue';
    });
