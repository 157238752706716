angular.module('bitnudgeApp')
    .controller('addDeepDiveConfig', function ($http, $scope, Auth, Upload, $location, toaster) {
        $scope.fileType = "deepDiveConfig";
        $scope.onFileSelectDeepDiveConfig = function () {
            if ($scope.bulkProduct == undefined) {
                toaster.pop('error', "Submission.", "Select File to Upload.");
            } else {
                var fileName = $scope.bulkProduct[0].name.split(".");
                var fileType = fileName[fileName.length - 1];
                if (fileType == "xls" || fileType == "xlsx" || fileType == "csv") {
                    Upload.upload({
                        url: '/api/upload',
                        data: {
                            userId: Auth.getCurrentUser()._id,
                            cronTag: 'uploadDeepDiveConfig',
                            dataType: $scope.fileType,
                            invocationMethod: 'manual'},
                        file: $scope.bulkProduct[0],
                    }).then(function (data, status, headers, config) {
                        // file is uploaded successfully
                        toaster.pop('success', "Excel uploaded", "Product colourConfig creation successfully finished.");
                    });
                } else {
                    toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' or '.csv' Types Only.");
                }
            }
        }
    });