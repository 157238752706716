'use strict';
angular.module('bitnudgeApp')
    .controller('rhsCardTableCtrl', function (sidebarVariables,$scope,$state,Auth) {
        var user = sidebarVariables.user;
        $scope.me = Auth.getCurrentUser();
        $scope.goToBadgeCorner = function () {
            user = sidebarVariables.user;
            if(sidebarVariables.user._id == $scope.me._id) user._id = null;
            $state.go('userFlow.badgeCorner',{userId:user._id});
        };
    });

