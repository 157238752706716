const autoImportNgTemplateLoaderTemplate1 = require('../html/basicPerformanceBarometer.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('basicPerformanceBarometer', function () {
        return {
            name: 'basicPerformanceBarometer',
            scope: {
                reportee:           '=',
                selectedCycle:  '=',
                selectedKpi:    '='
            },
            controller: 'basicPerformanceBarometerCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
