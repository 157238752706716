const autoImportNgTemplateLoaderTemplate1 = require('../html/swotChart.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('swotChart', function () {
        return {
            name: 'swotChart',
            scope: {
                isMySwot:               '=',
                getSwot:                '=',
                headerText:             '=',
                isActivity:             '=',
                swotTypeCss:            '=',
                getChildSwot:           '=',
                cycleId:                '=',
                isKpiFilter:            '=',
                isPlayerFilter:         '=',
                rootScope:              '@'
            },
            controller: 'swotChartCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1

        };
    });
