const autoImportNgTemplateLoaderTemplate1 = require('../html/playerComparisonModal.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/mySwot.html');

'use strict';

angular.module('bitnudgeApp')
    .controller('playerComparisonCardCtrl', function ($scope, $rootScope, Auth, GetDeltaImageService, $state, $http, analytics, SwotService, p0, $mdDialog) {
        $scope.strings = $rootScope.strings;
        $scope.playerLoaded = false;
        $scope.me = Auth.getCurrentUser();
        $scope.config = p0.config;
        $scope.showLevel = true;
        if(!$scope.config.liveLevel && !$scope.cycle.previousCycleId){
            $scope.showLevel = false;
        }
        $scope.getDelta = function (number1, number2) {
            return GetDeltaImageService.getDelta(number1, number2);
        };
        $scope.getDeltaImage = function (number1, number2) {
            return GetDeltaImageService.getDeltaImage(number1, number2);
        };
        $scope.getValue = function(number1, number2){
          return number1 - number2;
        }
        $scope.$watch('player', function(newVal, oldVal, scope){
            if(newVal && newVal != oldVal) {
                getPlayerData();
            }
        });
        $scope.$watch('cycle', function(newVal, oldVal, scope){
            if(newVal && newVal != oldVal) {
                getPlayerData();
            }
        });

        $scope.deepDiveToggled = false;
        $scope.onClickDeepDive = function() {
            $scope.deepDiveToggled = !$scope.deepDiveToggled;
            var player = $scope.deepDiveToggled ? $scope.player : null;
            $scope.onDeepDive(player, $scope.cycle);
        };

        $scope.simulateGameplan = function (playerId) {
            $state.go('userFlow.gameplanSimulation',{userId:playerId});
        };

        $scope.oneClickReportOfUser = function(){
            $state.go('userFlow.oneClickReport', {selectedPlayerIdForOCR: $scope.playerData._id});
        };

        $scope.onClickUserName = function(){
            $state.go('userFlow.profile', {selectedUserId: $scope.playerData._id});
        };

        $scope.onSelectPlayer = function(from, player) {
            // TODO: Show error message if player already added
            // toaster.pop('error', 'Player Comparison', 'Selected player is already being compared!');
            $scope.player = player;
            getPlayerData();
        };

        $scope.onRemovePlayer = function() {
            $scope.player = {};
        };

        $scope.hideDeepDive = false;
        if(!$rootScope.typeOfLb)
            $rootScope.typeOfLb = 'total';
        if(!$rootScope.kpiIdOfLb)
            $rootScope.kpiIdOfLb = 'overall';
        if(!$rootScope.activityIdOfLb)
            $rootScope.activityIdOfLb = 'overall';
        if(!$rootScope.leagueOfLb)
            $rootScope.leagueOfLb = 'bank';

        if($rootScope.typeOfLb == 'badge')
            $scope.hideDeepDive = true;

        var getPlayerData = function(){
            var cycle = $scope.cycle, player = $scope.player;
            if(!player || !player._id || !cycle) return;
            return analytics.getPlayerComparisonData(player, cycle._id, $rootScope.typeOfLb, $rootScope.kpiIdOfLb, $rootScope.activityIdOfLb, $rootScope.leagueOfLb).then(function(playerData){
                $scope.playerData = playerData;
                $scope.playerLoaded = true;
            });
        };

        getPlayerData();

        $scope.showPlayerSelectModal = function() {
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'playerComparisonModalCtrl',
                size: 'md',
                resolve: {
                    modalType: function(){
                        return $scope.isMyComparison;
                    },
                    onSelectPlayer: function (){
                        return $scope.onSelectPlayer;
                    },
                    frontlineRoleId: function(){
                        if(Auth.getCurrentUser().systemRole=="frontline")
                            return;
                        else
                            return $scope.playerType._id;
                    }
                }
            });
        };

        $scope.showSwotModal = function(showActivityModal) {
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate2,
                controller: 'swotModalCtrl',
                size: 'lg',
                resolve: {
                    selectedPlayer: function () {
                        $scope.mySWOTs = [];
                        $scope.swotData = $scope.playerData.kpiSwot;

                        for(var swot in $scope.swotData) {
                            for(var i = 0; i < $scope.swotData[swot].length; i++) {
                                $scope.swotData[swot][i].swot = swot;
                                $scope.mySWOTs.push($scope.swotData[swot][i])
                            }
                        }
                        return $scope.mySWOTs;
                    },
                    kpiName: function(){return ""},
                    activityData: function(){
                        $scope.myActivity = [];
                        $scope.selectedActivity = $scope.playerData.activitySwot;

                        for(var swot in $scope.selectedActivity) {
                            for(var i = 0; i < $scope.selectedActivity[swot].length; i++) {
                                $scope.selectedActivity[swot][i].swot = swot;
                                $scope.myActivity.push($scope.selectedActivity[swot][i])
                            }
                        }
                        return $scope.myActivity;
                    },
                    isActivity: function(){
                        return "";
                    },
                    showActivityModal: function(){
                        return showActivityModal;
                    }
                }
            });
        };

        $scope.getKPISWOT=function(swot){
            swot= SwotService.getSWOTString(swot);
            if (swot=="Threat") {
                return "t-swot";
            }else if (swot=="Strength") {
                return "s-swot";
            }else if (swot=="Weakness") {
                return "w-swot";
            }else if (swot=="Opportunity") {
                return "o-swot";
            }
        };

    });
