'use strict';
angular.module('bitnudgeApp')
    .controller('deleteOrLeaveGroupModalCtrl', function ($scope,$rootScope, DTOptionsBuilder, leaveOrDeleteGroup, conversation, textGroup, $mdDialog) {
        $scope.leaveOrDeleteGroup = leaveOrDeleteGroup;
        $scope.conversation = conversation;
        $scope.textGroup = textGroup;

        $scope.onClose = function() {
            $mdDialog.cancel();
        };
    });
