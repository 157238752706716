'use strict';

angular.module('bitnudgeApp')
    .service('leadsSummaryFilter', function () {
        var reportee, kpi;
        return{
            getReportee: function(){
                return reportee;
            },
            setReportee: function(val){
                reportee = val;
            },
            getKpi: function(){
                return kpi;
            },
            setKpi: function(val){
                kpi = val;
            }
        }
    });

