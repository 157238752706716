const autoImportNgTemplateLoaderTemplate1 = require('../html/reporteesHierarchy.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('custOverviewCtrl', function ($scope, p0, $rootScope, $state, $mdDialog, Auth) {
        var MAX_PRIMARY_METRICE_LENGTH = 4;
        $scope.selected = {};
        $scope.allSources = ['All', 'Customer file upload', 'Other', 'Active', 'Inactive'];
        $scope.dataTypes = ['Count', 'Value'];
        $scope.selected.dataType = $scope.dataTypes[0];
        $scope.selected.customerSource = $scope.allSources[0];
        var currentUser = Auth.getCurrentUser();
        var isFrontline = currentUser.systemRole.toLowerCase() === 'frontline';
        var primarySecMetriceMap = {};
        $scope.$watch('customerOverviewData', function(newVal, oldVal){
            console.log('Customers Updated');
            init();
        });

        $scope.moreInfo = function() {
            $rootScope.widgetSidePanelData = {};
            $rootScope.widgetSidePanelData = {
                widgetName: 'Customer Overview',
                kpiId: null,
                customerSource: $scope.selected.customerSource || null,
                dataType: $scope.selected.dataType || null
            };
            $rootScope.secondaryMetrices = [];
            $rootScope.secondaryMetrices = $scope.secondaryMetrices;
        }

        $scope.showChildrenMetrices = function(metric) {
            $rootScope.secondaryMetrices = [];
            $rootScope.widgetSidePanelData = {
                widgetName: 'Customer Overview (' + metric.displayName + ')',
                kpiId: null,
                customerSource: $scope.selected.customerSource || null,
                dataType: $scope.selected.dataType || null
            };
            $rootScope.secondaryMetrices = _.orderBy(primarySecMetriceMap[metric.metric], ['secondarySeq']);
        }

        var init = function() {
            var mgrConfig = $scope.configData.mgrConfig.data;
            var currentCycle = $scope.configData.currentCycle;
            var roleType = $scope.configData.roleType;
            var configuredMetrics = $scope.customerOverviewData['W_CUSTOMER_PARAMS'];
            configuredMetrics = configuredMetrics && configuredMetrics.data;
            var configuredMetricIds = _.map(configuredMetrics, 'metric');
            var selectedConfigs = mgrConfig.filter(function(config){
                return configuredMetricIds.includes(config.metric);
            });
            $scope.primaryMetrices = [];
            $scope.secondRowPrimaryMetrics = [];
            $scope.verticalRowPrimaryMetrics = [];
            $scope.secondaryMetrices = [];
            primarySecMetriceMap = {};
            selectedConfigs.forEach(function(config) {
                var rejectConfig = false;
                var showValue = false;
                if (config.isVisible) {
                    if (config.unit && !_.isUndefined(config.unit) && !_.isNull(config.unit)) {
                        showValue = true;
                        if ($scope.selected.dataType.toLowerCase() === 'count') {
                            rejectConfig = true;
                        }
                    } else {
                        showValue = false;
                        if ($scope.selected.dataType.toLowerCase() === 'value') {
                            rejectConfig = true;
                        }
                    }
                    if (!rejectConfig) {
                        config.result = _.find(configuredMetrics, {'metric': config.metric}).data;
                        if (config.isPrimary) {
                            $scope.primaryMetrices.push({
                                label: config.label,
                                isVisible: config.isVisible,
                                color: config.color || '#3777AF',
                                value: config.result.total,
                                result: config.result,
                                positionSequence: config.positionSequence,
                                metric: config.metric,
                                unit: config.unit,
                                displayName: config.displayName
                            })
                        }
                        if(config.isSecondary) {
                            var metricObj = {
                                label: config.label,
                                isVisible: config.isVisible,
                                color: config.color || '#3777AF',
                                value: config.result.total,
                                result: config.result,
                                metric: config.metric,
                                unit: config.unit,
                                secondarySeq: config.sequence,
                                displayName: config.displayName
                            };
                            if (config.parent != null && config.parent != "") {
                                !primarySecMetriceMap[config.parent] ? primarySecMetriceMap[config.parent] = [metricObj] : primarySecMetriceMap[config.parent].push(metricObj);
                            } else {
                                $scope.secondaryMetrices.push(metricObj) ;
                            }
                        }
                    }
                }
            });
            var parentKeys = Object.keys(primarySecMetriceMap);
            $scope.primaryMetrices.forEach(function(config) {
                if (parentKeys.includes(config.metric)) {
                    config.hasChildren = true;
                }
            })
            $scope.primaryMetrices = _.orderBy($scope.primaryMetrices, ['positionSequence']);
            if ($scope.primaryMetrices.length > MAX_PRIMARY_METRICE_LENGTH) {
                $scope.secondRowPrimaryMetrics = $scope.secondRowPrimaryMetrics.concat($scope.primaryMetrices.slice(MAX_PRIMARY_METRICE_LENGTH, MAX_PRIMARY_METRICE_LENGTH + 4))
            }
            $scope.verticalRowPrimaryMetrics = [$scope.primaryMetrices[8]];
            var externalParams = _.cloneDeep($scope.dynamicParams);
            delete externalParams.metrics;
            externalParams.cycleId = $scope.configData.currentCycle._id;
            externalParams.roleType = $scope.configData.roleType;
            $rootScope.externalParams = externalParams;
            $scope.onCustomerSourceChange = function() {
                if ($scope.selected.customerSource.toLowerCase().includes('active')) {
                    $scope.changeCustomerType({
                        customerType: $scope.selected.customerSource
                    });
                } else {
                    $scope.changeCustomerSource({
                        customerSource: $scope.selected.customerSource
                    });
                }
            }
            $scope.onDataTypeChange = function(dataType) {
                $scope.selected.dataType = dataType;
                $scope.changeCustomerDataType({
                    dataType: $scope.selected.dataType
                })
            }
            $scope.moreInfo();
        }

        $scope.reporteesHierarchyDialog = function (metric) {
            $rootScope.externalParams.selectedMetric = metric.metric;
            $rootScope.externalParams.loadFullData = true;
            if (currentUser.systemRole.toLowerCase() === 'frontline') {
                if (metric.value === 0) {
                    return;
                }
                var params = {externalParams: $rootScope.externalParams};
                $state.go('lms.customers', params);
            } else {
                $mdDialog.show({
                    templateUrl: autoImportNgTemplateLoaderTemplate1,
                    controller: 'reporteesHierarchyController',
                    resolve: {
                        leadsData: function () {
                            return metric.result;
                        },
                        widget: function() {
                            return 'Customer Overview'
                        },
                        label: function () {
                            return metric.displayName;
                        },
                        kpiId: function () {
                            return null;
                        },
                        unit: function () {
                            return metric.unit;
                        },
                        source: function () {
                            return metric.source;
                        },
                        metricName: function() {
                            return metric.metric;
                        }
                    }
                });
            }
        }
    });