'use strict';

angular.module('bitnudgeApp')
    .service('SwotService', function ($http, $q, p0, userAnalyticsService, leagueAnalytic, toaster) {
        var _reporteeSwot={};
        var _kpiSpecificActivitySwot = {};
        var _reporteeKAISwot = {};
        let self=this;

        self._getKpiSwot = function (swot) {
            var kpiSwot = {
                's' : [],
                'w' : [],
                'o' : [],
                't' : [],
                'u' : [],
            };
            _.forEach(swot.kpiData, function (kpi) {
                if(kpi.swot && kpi.target.value)
                    kpiSwot[kpi.swot].push({_id : kpi.kpiId, name: kpi.name});
            });
            return kpiSwot;
        };

        self._getKaiSwot = function (swot, kpiId) {
            var kaiSwot = {
                's' : [],
                'w' : [],
                'o' : [],
                't' : [],
                'u' : [],
            };
            if(kpiId == 'overall'){
                var nonKpiSpecificActivities = _.filter(p0.allActivities,{kpiSpecific:false,swot:true});
                nonKpiSpecificActivities = _.map(nonKpiSpecificActivities,'_id');
                var activities = _.filter(swot.activityData,function (actData) {
                    return _.includes(nonKpiSpecificActivities,actData.activityId);
                });
            }else {
                var activities = _.filter(swot.activityData, {kpiId : kpiId});
            }
            _.forEach(activities, function (activity) {
                if(activity.swot && activity.swotFlag) kaiSwot[activity.swot].push({_id : activity.activityId, name: activity.name});
            });
            return kaiSwot;
        };

        self._filterReporteeModalForKpi = function (userAnalytics, leagueAnalytic, kpiId) {
            var dataArray = []
            if(kpiId == 'overall'){
                _.forEach(userAnalytics, function (userAnalytic) {
                    dataArray.push({name:userAnalytic.name, percentageCompleted:userAnalytic.percentageCompleted, swot:userAnalytic.swot});
                });
                dataArray = _.sortBy(dataArray,function(data){return -data.percentageCompleted.value});
                return $q.resolve([dataArray, leagueAnalytic.percentageCompleted]);
            }else{
                _.forEach(userAnalytics, function (userAnalytic) {
                    var kpiData = _.find(userAnalytic.kpiData, {kpiId: kpiId});
                    dataArray.push({name:userAnalytic.name, percentageCompleted:kpiData.percentageCompleted, swot:userAnalytic.swot});
                });
                var kpiData = _.find(leagueAnalytic.kpiData, {kpiId: kpiId});
                dataArray = _.sortBy(dataArray,function(data){return -data.percentageCompleted.value});
                return $q.resolve([dataArray, kpiData.percentageCompleted]);
            }
        };

        self._filterReporteeModalForActivities = function (userAnalytics, leagueAnalytic, kpiId, activityId) {
            var dataArray = [];
            _.forEach(userAnalytics, function (userAnalytic) {
                var activityData = _.find(userAnalytic.activityData, {activityId: activityId, kpiId : kpiId, swotFlag: true});
                dataArray.push({name:userAnalytic.name, value: activityData.value, swot:userAnalytic.swot});
            });
            var activityData = _.find(leagueAnalytic.activityData, {activityId: activityId, kpiId: kpiId});
            dataArray = _.sortBy(dataArray,function(data){return - data.value.value});
            return $q.resolve([dataArray, activityData.value]);
        };

        self._filterKaiModalForKpi = function (activityData, kpiId, activityFilter) {
            var kpiActivity =  _.filter(activityData, function(activityData){
                if(kpiId){
                    return activityData.kpiId && activityData.kpiId.toString() === kpiId.toString();
                }else{
                    return !activityData.kpiId;
                }
            });
            if(activityFilter) kpiActivity =  _.filter(kpiActivity, {activityId: activityFilter});
            return kpiActivity;
        };

        self._filterKpiData = function (kpis, list) {
            return _.filter(list, function (item) {
                return kpis.indexOf(item.kpiId)>=0;
            })
        };

        self._getReporteeSwot = function (cycleId, userId, jobRoleId) {
            if(!_reporteeSwot[cycleId][userId][jobRoleId]){
                var deferred = $q.defer();
                const postBody = {
                    userId,
                    cycleId,
                    jobRole: jobRoleId,
                    isActivity: false
                }
                $http.post('/api/swot/frontlineSWOT', postBody)
                    .then(function(result) {
                        _reporteeSwot[cycleId][userId][jobRoleId] = result.data;
                        deferred.resolve(_reporteeSwot[cycleId][userId][jobRoleId]);
                    }, function(error) {
                        _reporteeSwot[cycleId][userId][jobRoleId] = null;
                        deferred.reject(error);
                    });
                _reporteeSwot[cycleId][userId][jobRoleId] = deferred.promise;
            }
            return $q.when(_reporteeSwot[cycleId][userId][jobRoleId]);
        };

        self._kpiSpecificActivitySwot = function (cycleId, userId, jobRoleId) {
            if(!_kpiSpecificActivitySwot[cycleId][userId][jobRoleId]){
                var deferred = $q.defer();
                const postBody = {
                    userId,
                    cycleId,
                    jobRole: jobRoleId,
                    isActivity: true
                }
                $http.post('/api/swot/frontlineSWOT', postBody)
                    .then(function(result) {
                        _kpiSpecificActivitySwot[cycleId][userId][jobRoleId] = result.data;
                        deferred.resolve(_kpiSpecificActivitySwot[cycleId][userId][jobRoleId]);
                    }, function(error) {
                        _kpiSpecificActivitySwot[cycleId][userId][jobRoleId] = null;
                        deferred.reject(error);
                    });
                _kpiSpecificActivitySwot[cycleId][userId][jobRoleId] = deferred.promise;
            }
            return $q.when(_kpiSpecificActivitySwot[cycleId][userId][jobRoleId]);
        };

        self.getReporteeSwot = function (cycleId, userId, kpiId, jobRoleId) {
            if(!_reporteeSwot[cycleId]) _reporteeSwot[cycleId] = {};
            if(!_reporteeSwot[cycleId][userId]) _reporteeSwot[cycleId][userId] = {};
            return self._getReporteeSwot(cycleId, userId, jobRoleId)
                .then(function(reporteeSwot) {
                    return _reporteeSwot[cycleId][userId][jobRoleId]['reporteeSwot'][kpiId];
                });
        };
        self.getReporteeModalDetail = function (cycleId, userId, kpiId, jobRoleId, leagueId) {
            if(!_reporteeSwot[cycleId]) _reporteeSwot[cycleId] = {};
            if(!_reporteeSwot[cycleId][userId]) _reporteeSwot[cycleId][userId] = {};
            return self._getReporteeSwot(cycleId, userId, jobRoleId)
                .then(function(reporteeSwot) {
                    return leagueAnalytic.fetchLeagueAnalytic(leagueId, cycleId).then( function (leagueAnalytic) {
                        return self._filterReporteeModalForKpi([_reporteeSwot[cycleId][userId][jobRoleId]['userAnalytics'], leagueAnalytic, kpiId]);
                    });
                });
        };

        self.getReporteeModalDetailForActivities = function (cycleId, userId, kpiId, jobRoleId, leagueId, activityId) {
            if(!_kpiSpecificActivitySwot[cycleId][userId][jobRoleId]['userAnalytics'][cycleId]) _kpiSpecificActivitySwot[cycleId] = {};
            if(!_kpiSpecificActivitySwot[cycleId][userId]) _kpiSpecificActivitySwot[cycleId][userId] = {};
            return self._kpiSpecificActivitySwot(cycleId, userId, jobRoleId)
                .then(function(reporteeSwot) {
                    leagueAnalytic.fetchLeagueAnalytic(leagueId, cycleId).then( function (leagueAnalytic) {
                        return self._filterReporteeModalForActivities([_kpiSpecificActivitySwot[cycleId][userId][jobRoleId]['userAnalytics'], leagueAnalytic, kpiId, activityId]);
                    });
                });
        };



        self.getKpiSpecificActivitySwot = function(cycleId, userId, kpiId, jobRoleId){
            if(!_kpiSpecificActivitySwot[cycleId]) _kpiSpecificActivitySwot[cycleId] = {};
            if(!_kpiSpecificActivitySwot[cycleId][userId]) _kpiSpecificActivitySwot[cycleId][userId] = {};
            return self._kpiSpecificActivitySwot(cycleId, userId, jobRoleId)
                .then(function(reporteeSwot) {
                    return _kpiSpecificActivitySwot[cycleId][userId][jobRoleId]['reporteeSwot'][kpiId];
                });
        };

        self.getKpiSwot = function (cycleId, userId) {
            return userAnalyticsService.fetchUserAnalytics(userId, cycleId).then( function (userAnalytic) {
                return self._getKpiSwot(userAnalytic);
            });
        };

        self.getKpiModalDetail = function (userId, leagueId, cycleId) {
            return userAnalyticsService.fetchUserAnalytics(userId, cycleId).then( function (userAnalytic) {
                return leagueAnalytic.fetchLeagueAnalytic(leagueId, cycleId).then(function (leagueAnalytic) {
                    var userRelevantKpis = _.map(_.filter(userAnalytic.kpiData, function (kpiData) { return kpiData.target.value > 0}), 'kpiId');
                    var kpiData = self._filterKpiData(userRelevantKpis ,userAnalytic.kpiData);
                    var kpiDetails = self._filterKpiData(userRelevantKpis ,leagueAnalytic.kpiData);
                    return [kpiData, kpiDetails];
                });
            });
        };

        self.getKaiModalDetail = function (userId, leagueId, cycleId, kpiId, activityFilter) {
            return userAnalyticsService.fetchUserAnalytics(userId, cycleId).then( function (userAnalytic) {
                return leagueAnalytic.fetchLeagueAnalytic(leagueId, cycleId).then(function (leagueAnalytic) {
                    return [self._filterKaiModalForKpi(userAnalytic.activityData, kpiId, activityFilter), self._filterKaiModalForKpi(leagueAnalytic.activityData, kpiId, activityFilter)];
                });
            });
        };

        self.getKaiSwot = function (cycleId, userId, kpiId) {
            return userAnalyticsService.fetchUserAnalytics(userId, cycleId).then( function (userAnalytic) {
                return self._getKaiSwot(userAnalytic, kpiId);
            });
        };

        self.getSWOTString = function(swot){
            var swotText;
            switch (swot) {
                case "s":
                    swotText = "Strength";
                    break;
                case "w":
                    swotText = "Weakness";
                    break;
                case "o":
                    swotText = "Opportunity";
                    break;
                case "t":
                    swotText =  "Threat";
                    break;
                case "u":
                    swotText= "Unknown";
                    break;
            }
            return swotText;
        };
        self.getSWOTSymbol = function(swot){
            var swotText;
            switch (swot) {
                case "Strength":
                    swotText = "s";
                    break;
                case "Weakness":
                    swotText = "w";
                    break;
                case "Opportunity":
                    swotText = "o";
                    break;
                case "Threat":
                    swotText =  "t";
                    break;
                case "Unknown":
                    swotText= "u";
                    break;
            }
            return swotText;

        };

        self.getReporteeKaiSwot = function (userId, cycleId, kpiId, kaiId, productId, jobRoles, selfSwot) {
                const postBody = {
                    userId,
                    cycleId,
                    kpiId,
                    productId,
                    activityId: kaiId,
                    jobRoles,
                    selfSwot
                }
                return $http.post('/api/swot/reporteeKAI', postBody)
        			.then(function (result) {
                        result = result.data;
                        if(result.status){
                            return $q.resolve(result.data);
                        }else{
                            result.errors.forEach(function(error){
                                console.log(error.message);
                                toaster.pop('error', error.message);
                            })
                            return $q.reject(null);
                        }
        			}, function (error) {
        				return $q.reject(null);
        			});
               
        };

        self.destroy = function(){

        }
    });

