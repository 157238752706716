'use strict';

'use strict';
angular.module('bitnudgeApp')
    .service('mgrDashboardMetricService', ['$http', '$q', function ($http, $q) {
        var self = this;

        this.getMetrics = function (cycleId, roleType, metrics, dynamicParams, loadFullData, userId) {
            var deferred = $q.defer();
            var names = Array.isArray(metrics) ? metrics.join(',') : metrics;
            dynamicParams.metrics = names;
            dynamicParams.loadFullData = loadFullData;
            if (userId) {
                dynamicParams.userId = userId;
            }
            var baseUrl = '/api/managerDashboard/get/metric';
            // $http.get(url).then(function (result) {
            //     console.log('result: ', result);
            //     deferred.resolve(result);
            // }, function (error) {
            //     deferred.reject(error);
            // });

            $http.get(baseUrl, {cycleId, roleType, dynamicQueries: dynamicParams })
                .then(function (leads) {
                    deferred.resolve(leads);
                }, function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        };

        //special case for demo
        this.getActiveAgents = function (user) {
            var deferred = $q.defer();
            var baseUrl = '/api/managerDashboard/metric/activeAgents';
            $http.post(baseUrl,{user})
                .then(function (activeAgents) {
                    console.log('activeAgents: ', activeAgents);
                    deferred.resolve(activeAgents && activeAgents.data);
                }, function (error) {
                    deferred.reject(activeAgents);
                });

            return deferred.promise;
        };


    }]);

