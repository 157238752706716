'use strict';
angular.module('bitnudgeApp')
    .controller('frontlineSwotCtrl', function ($rootScope, $scope, $http, Auth, p0){
        $scope.selectedCycle = p0.currentMonthlyCycle;
        $scope.me=Auth.getCurrentUser();
        $scope.getSwot={};
        $scope.getChildSwot={};
        $scope.goBackDeepDive=function(){
            if($scope.currentSwotView=='deep-dive'){
                $scope.showFrontlineDeepDive=false;
            }
            else $scope.currentSwotView='deep-dive';
        };

        $scope.playerType = p0.myReporteeRoles;
        $scope.selectedPlayerType = $scope.playerType[0];
        $scope.allCycles = p0.allMonthlyCycles;
        $scope.selectedCycle = p0.currentMonthlyCycle;

        $scope.frontlineSwot = function(){
            $scope.isPlayerKPISwot = false;
            $scope.getSwot.frontlineSwot($scope.selectedPlayerType, $scope.selectedCycle, $scope.selectedKPIFrontline);
        };

        $scope.getRelevantKpis = function(){
            var relevantDivisions = $scope.selectedPlayerType.division;
            $scope.allKpi = [];
            relevantDivisions.forEach(function(division){
                var selectedDivision = _.find(p0.allDivisions,{_id:division});
                $scope.allKpi = $scope.allKpi.concat(selectedDivision.kpis);
            });
            $scope.allKpi.unshift({name:"Overall", _id:'overall', type:'overall', value:'overall'});
            $scope.selectedKPIFrontline = $scope.allKpi[0];
            setTimeout($scope.frontlineSwot,100);

        };

        $scope.getRelevantKpis();

        $scope.getSwotPage = function (id) {

            if (id == 'deep-dive') {
                $scope.showFrontlineDeepDive=true;
                $('#swotbox-part1').hide();
                $('#swotbox-part2').show();
                $('#swotback').show();
                $scope.showSwot = true;
            }
            else if(id=='card-view'){
                $scope.showFrontlineDeepDive=false;
            }
            else {
                $('#swotbox-part1').show();
                $('#swotbox-part2').hide();
                $('#swotback').hide();
            }
        };

        $scope.flSwotHeader="Frontline " + $rootScope.strings.SWOT_FULL;
        $scope.getSwot.showPlayerKPISwot=function(user){
            $scope.playerHeader=user.name + "'s"+" "+$rootScope.strings.SWOT_FULL;
            if($scope.getChildSwot){
                user.userId=user._id;
                $scope.getChildSwot.selectedPlayer = user
            }
            $scope.isPlayerKPISwot = true;
        }
        $scope.getChildSwot.showPlayerKPISwot=function(user){
            $scope.playerHeader=user.name + "'s"+" " + $rootScope.strings.SWOT_FULL;
            $scope.isPlayerKPISwot = true;
        }
    });
