angular.module('bitnudgeApp')
    .controller('triggerCronsCtrl', function ($scope,Auth, $http, toaster) {
        $scope.toggleMin = function() {
            $scope.minDate = $scope.minDate ? null : new Date();
        };
        $scope.toggleMin();
        $scope.actionTriggered = false;
        //opening a datepicker dialog box
        $scope.open = function($event) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened = true;
        };

        //Configration for Date picker.
        $scope.dateOptions = {
            formatYear: 'yy',
            modelDateFormat: "yyyy-MM-ddTHH:mm:ss",
            startingDay: 1
        };

        $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];


        $scope.aggregateMTD = function(){
            let asOfDate = $scope.asOfDate.getTime();
            $scope.actionTriggered = true;
            $http.post('/api/cron/startMTDAggregation',{trackDate:asOfDate}).then(function(result){
                result=result.data;
                $scope.actionTriggered = false;
            });
        };
        $scope.aggregateActivity = function(){
            let asOfDate = $scope.asOfDate.getTime();
            $scope.actionTriggered = true;
            $http.post('/api/cron/startActivityAggregation',{trackDate:asOfDate}).then(function(result){
                result=result.data;
                $scope.actionTriggered = false;
            });
        };

        $scope.aggregatePortfolio = function(){
            $scope.actionTriggered = true;
            $http.post('/api/upload', { cronTag: 'portfolio-aggregate', invocationMethod: "manual" }).then(function (result) {
                $scope.actionTriggered = false;
            })
        }
        $scope.startFormulaCron = function(){
            let asOfDate = $scope.asOfDate.getTime();
            $http.post("/api/cron/startFormulaCron", {
                asOfDate: asOfDate
            }).then(function(rs) {
                rs=rs.data;
                $scope.actionTriggered = true;
                toaster.pop('success','Formula cron' ,'Cron started successfully!');
            }).catch(function (response) {
                toaster.pop('error', 'Formula cron' ,"Can't start the Formula cron!");
            });
        };
});
