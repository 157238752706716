angular.module('bitnudgeApp')
    .controller('addBulkContestCtrl', function ($http, $scope, Auth, Upload, $location, toaster) {
        $scope.fileType = "contests";
        $scope.onFileSelectContests = function () {
            if ($scope.bulkContests == undefined) {
                toaster.pop('error', "Submission.", "Select File to Upload.");
            } else {
                var fileName = $scope.bulkContests[0].name.split(".");
                var fileType = fileName[fileName.length - 1];
                if (fileType == "xls" || fileType == "xlsx" || fileType == "csv") {
                    Upload.upload({
                        url: '/api/upload',
                        data: {userId: Auth.getCurrentUser()._id,
                        cronTag:'uploadContests',
                        invocationMethod:"manual",
                        dataType: $scope.fileType},
                        file: $scope.bulkContests[0],
                    }).then(function (data, status, headers, config) {
                        // file is uploaded successfully
                        toaster.pop('success', "Excel uploaded", "Contest creation successfully finished.");
                    });
                } else {
                    toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' or '.csv' Types Only.");
                }
            }
        }
    });
