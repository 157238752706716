'use strict';
angular.module('bitnudgeApp')
    .controller('managePeersModalController', function ($scope, DTOptionsBuilder, Auth, $http, toaster,
                                                        $mdDialog, refreshDataCB, fetchLeagues) {

        $scope.refreshDataCB = refreshDataCB;
        $scope.allLeagues =fetchLeagues.getAllLeagues(Auth.getCurrentUser(), 'false');
        $scope.selectedLeague = _.find($scope.allLeagues, {value:'peer'});

        $http.post('api/users/me').then(function(me) {
            me=me.data;
            $scope.me = me;

            $http.post('api/users', {select: ["name", "organizationalTag"], "jobRoleId": $scope.me.organizationalTag.jobRole._id}).then(function(users) {
                users=users.data;
                $scope.allUsersOfRole = users;
                $scope.filterPlayersByLeague();
            });
        });

        $scope.filterPlayersByLeague=function(){
            switch($scope.selectedLeague.value) {
                case "branch":
                    $scope.playerList = _.filter($scope.allUsersOfRole, function(u){
                        return u.organizationalTag.branch == $scope.selectedLeague._id;
                    }); break;
                case "region":
                    $scope.playerList = _.filter($scope.allUsersOfRole, function(u){
                        return u.organizationalTag.region == $scope.selectedLeague._id;
                    }); break;
                case "bank":
                    $scope.playerList = $scope.allUsersOfRole; break;
                case "peer":
                    var peerIds = _.map($scope.me.peerGroup, 'userId');
                    $scope.playerList = _.filter($scope.allUsersOfRole, function(u){
                        return _.includes(peerIds, u._id);
                    }); break;
            }
        };

        $scope.addPeer = function($event, obj){
            angular.element("#add_"+obj._id).hide()
            angular.element("#remove_"+obj._id).show()
            $http.post('/api/users/peerGroup',{userId:obj._id}).then(function(result){
                result=result.data;
                toaster.pop('success',"Peer Group", "Player added to Peers");

                $scope.me.peerGroup.push({userId:obj._id});

                if($scope.refreshDataCB) {
                    $scope.refreshDataCB();
                }
            });

        };

        $scope.removePeer = function($event, obj){
            angular.element("#add_"+obj._id).show();
            angular.element("#remove_"+obj._id).hide();

            if(obj._id==Auth.getCurrentUser()._id)
                return toaster.pop('error','Peer Group','You cannot remove yourself');
            $http.post('/api/users/archive/peerGroup',{peerUserId:obj._id}).then(function (message){
                message=message.data;
                toaster.pop('success',"Peer Group","Player removed from Peers");

                $scope.me.peerGroup = _.reject($scope.me.peerGroup, function(el) {return el.userId==obj._id;});

                if($scope.refreshDataCB) {
                    $scope.refreshDataCB();
                }
            });
        };

        $scope.isPeer = function(playersId){
            return _.find($scope.me.peerGroup, {userId: playersId});
        };




        $scope.onClose = function() {
            $mdDialog.hide();

            if($scope.refreshDataCB) {
                $scope.refreshDataCB();
            }
        };


        $scope.dtOptionsForMySWOT = DTOptionsBuilder.newOptions()
            .withOption('paging', false)
            .withOption('searching', false)
            .withOption('ordering', false)
            .withOption('info', false);

    });
