const autoImportNgTemplateLoaderTemplate1 = require('../html/timelineHistory.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('timelineHistory', function () {
        return {
            name: 'timelineHistory',
            scope: {
                histories: '=',
                loaded: '=',
                source: '=',
                hideTitle: '<'
            },
            controller:'timelineHistoryCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
