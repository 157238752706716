const autoImportNgTemplateLoaderTemplate1 = require('../html/deltaIcon.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('deltaIcon', function () {
        return {
            name: 'deltaIcon',
            scope: {
                value:       '='
            },
            controller: 'deltaIconCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
