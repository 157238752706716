'use strict';
angular.module('bitnudgeApp')
    .controller('myFormCtrl', function ($scope, $http, p0,$rootScope) {

        $scope.strings = $rootScope.strings;
        var monthlyCycle= p0.currentMonthlyCycle;
        $scope.kpis = p0.myKpis;
        $scope.allKPI = p0.myKpis;
        var temp = {name:"Overall",type:'overall',_id:'0'};
        $scope.allKPIFrontline = p0.myKpis;
        if($scope.allKPIFrontline.length > 1) {
            $scope.allKPIFrontline.unshift(temp);
            $scope.selectedKPIForImpactPt = $scope.allKPI[1];
        }else{
            $scope.selectedKPIForImpactPt = $scope.allKPI[0];
        }
        $scope.selectedKPI = $scope.allKPI[0];


        $scope.allActivities = p0.allActivities;
        $scope.selectedActivity = $scope.allActivities[0];

        $scope.initGraphs = function () {
            var today = new Date($rootScope.lastUpdatedAtDate.getFullYear(), $rootScope.lastUpdatedAtDate.getMonth(), 1);
            var YearBack= new Date(new Date().getFullYear()-1, new Date().getMonth(), 1);
            const postData = {
                today,
                yearBack: YearBack
            }
            $http.post('api/analytics/getChartDataForYear', postData).then(function(data){
                data=data.data;
                $scope.chartData = data.chartData;
                $scope.leafCount = data.leafCount;
                $scope.generateOverallPointsGraph();
                $scope.generateImpactPointsGraph();
                $scope.generateActivityPointsGraph();
                $scope.generateRankGraph();
                $scope.generateLevelGraph();
                $scope.toggleRankLevelChart('rank');
                if($scope.chartData){
                    $scope.rankLevelToggle = 'rank';
                }
            })
        };
        $scope.initGraphs();

        $scope.generateLevelGraph = function () {
            $scope.levelChartData = [{
                values:[]
            }];
            $scope.levelChartAnalytics = [];


            for(var i = 0; i < $scope.chartData.length; i++) {
                var temp1 = {};
                var temp2 = {};
                temp1.name = $scope.chartData[i].cycleName;
                temp2.name = $scope.chartData[i].cycleName;
                if(i==0) {
                    temp1.value = $scope.chartData[i].user.level;
                    temp2.value = $scope.chartData[i].user.level;
                } else {
                    temp1.value = $scope.chartData[i].user.level;
                    temp2.value = $scope.chartData[i].user.level;
                }
                $scope.levelChartData[0].values.push(temp1);
                $scope.levelChartAnalytics.push(temp2);
            }

            // var cumulative = 0;
            // for (var i = 0; i < $scope.levelChartData.length; i++) {
            //     $scope.levelChartData[i].start = cumulative;
            //     cumulative += $scope.levelChartData[i].value;
            //     $scope.levelChartData[i].end = cumulative;
            //
            //     var trendString,chart=$scope.levelChartData;
            //     if(!chart[i-2])
            //         trendString='neutralBar';
            //     else if(chart[i-2].value>chart[i].value)
            //         trendString='positiveBar';
            //     else if(chart[i-2].value<chart[i].value)
            //         trendString='negativeBar';
            //     $scope.levelChartData[i].class = ( $scope.rankChartData[i].value >= 0 ) ? 'positive' : 'negative'
            //     // $scope.levelChartData[i].
            //     $scope.levelChartData[i].class += " "+trendString;
            // }

            // $scope.levelChartData.push({
            //     name: 'Total',
            //     end: cumulative,
            //     start: 0,
            //     class: 'total'
            // });

            $scope.lineChart('#level-chart', $scope.levelChartData);
        }

        $scope.generateRankGraph = function () {
            $scope.rankChartData = [
                {
//                    "label": "Points",
                    "values": []
                }
            ];
            $scope.rankChartAnalytics = [{
//                    "label": "Points",
                "values": []
            }];

            for(var i = 0, len = $scope.chartData.length; i < len; i++){
                var chart = {};
                var analytic = {};
                var current = {};
                var previous = {};

                chart.name = $scope.chartData[i].cycleName;
                analytic.name = $scope.chartData[i].cycleName;

                if (i==0) {
                    if ($scope.selectedKPI._id == 0)
                        current = $scope.chartData[i].user
                    else
                        current = _.find($scope.chartData[i].user.kpiData, {kpiId : $scope.selectedKPI._id});

                    if(!current.points.ranks[0]){
                        chart.value = 0;
                        analytic.value = 0;
                    }
                    else{
                        chart.value = current.points.ranks[0].value;
                        analytic.value = current.points.ranks[0].value;
                    }

                }else {

                    if ($scope.selectedKPI._id == 0) {
                        current = $scope.chartData[i].user;
                        previous = $scope.chartData[i - 1].user;
                    }else{
                        current = _.find($scope.chartData[i].user.kpiData, {kpiId : $scope.selectedKPI._id});
                        previous = _.find($scope.chartData[i - 1].user.kpiData, {kpiId : $scope.selectedKPI._id});
                    }

                    if(current.points.ranks[current.points.ranks.length - 1] != undefined){
                        chart.value = current.points.ranks[current.points.ranks.length - 1].delta;
                        analytic.value = current.points.ranks[current.points.ranks.length - 1].value;
                    }
                }
                $scope.rankChartData[0].values.push(analytic);
                $scope.rankChartAnalytics[0].values.push(analytic);
            }
            // var cumulative = 0;
            // for (var i = 0; i < $scope.rankChartData.length; i++) {
            //
            //     $scope.rankChartData[i].start = cumulative;
            //     if(!isNaN($scope.rankChartData[i].value)){
            //         cumulative += $scope.rankChartData[i].value;
            //     }
            //     $scope.rankChartData[i].end = cumulative;
            //
            //     var trendString,chart=$scope.rankChartData;
            //     if(!chart[i-2])
            //         trendString='neutralBar';
            //     else if(chart[i-2].value>chart[i].value)
            //         trendString='positiveBar';
            //     else if(chart[i-2].value<chart[i].value)
            //         trendString='negativeBar';
            //     $scope.rankChartData[i].class = ( $scope.rankChartData[i].value >= 0 ) ? 'positive' : 'negative';
            //     $scope.rankChartData[i].class += " " +trendString;
            // }
            //
            // $scope.rankChartData.push({
            //     name: 'Total',
            //     end: cumulative,
            //     start: 0,
            //     class: 'total'
            // });

            $scope.lineChart('#rank-chart', $scope.rankChartData);
        }

        $scope.generateOverallPointsGraph = function () {
            $scope.overallChartData = [
                {
//                    "label": "Points",
                    "values": []
                }
            ]

            for(var i = 0; i < $scope.chartData.length; i++) {
                var temp = {};
                temp.name = $scope.chartData[i].cycleName;
                temp.value = $scope.chartData[i].user.points.value;
                $scope.overallChartData[0].values.push(temp);
            }
            $scope.lineChart('#overall-chart', $scope.overallChartData);
        }

        $scope.generateImpactPointsGraph = function () {
            $scope.impactChartData = [
                {
//                    "label": "Points",
                    "values": []
                }
            ]
            for(var i = 0; i < $scope.chartData.length; i++) {
                var temp = {};
                temp.name = $scope.chartData[i].cycleName;

                temp.value= _.find($scope.chartData[i].user.kpiData, {kpiId : $scope.selectedKPIForImpactPt._id});

            if(temp.value == undefined)
                    temp.value = 0;
                else
                    temp.value = temp.value.points.value;
                $scope.impactChartData[0].values.push(temp);
            }

            $scope.lineChart('#impact-chart', $scope.impactChartData);
        }

        $scope.generateActivityPointsGraph = function () {
            $scope.activityChartData = [
                {
//                    "label": "Points",
                    "values": []
                }
            ]

            for(var i = 0; i < $scope.chartData.length; i++) {
                var temp = {};
                temp.name = $scope.chartData[i].cycleName;
                temp.value= _.find($scope.chartData[i].user.activityData, {activityId : $scope.selectedActivity._id});
                if(temp.value == undefined)
                    temp.value = 0;
                else
                    temp.value = temp.value.points.value;
                $scope.activityChartData[0].values.push(temp);
            }
            $scope.lineChart('#activity-chart', $scope.activityChartData);
        }

        $scope.lineChart = function (id, chartData) {
            $(id).empty();
            var data = chartData;

            var margin = {
                    top: 20,
                    right: 50,
                    bottom: 70,
                    left: 40
                },
                width = $(id).outerWidth() - margin.left - margin.right - 20,
                height = $(id).outerHeight() - margin.top - margin.bottom;
            var labelHeight = (height/2)+margin.top;

            var x = d3.scale.ordinal();

            var y = d3.scale.linear()
                .range([height, 0]);

            var color = d3.scale.category20();

            var xAxis = d3.svg.axis()
                .scale(x)
                .orient("bottom");

            var yAxis = d3.svg.axis()
                .scale(y)
                .orient("left");

            var line = d3.svg.line()
                .x(function (d) {
                    return x(d.name);
                })
                .y(function (d) {
                    return y(d.value);
                })
                .interpolate("linear");

            // Define 'div' for tooltips
            var div = d3.select(id).append("div") // declare the properties for the div used for the tooltips
                .attr("class", "tooltip")       // apply the 'tooltip' class
                .style("opacity", 0);         //

            var svg = d3.select(id).append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

            //x label
            svg.append("text")
                .attr("class", "x label")
                .attr("text-anchor", "end")
                .attr("x", width)
                .attr("y", height + margin.bottom-10)
                .text("Months");

            //y label
            svg.append("text")
                .attr("class", "y label")
                .attr("id", "y-label")
                .attr("text-anchor", "end")
                .attr("y", -20)
                .attr("x", -5)
                .attr("dy", ".75em");
                // .text("Points");

            color.domain(data.map(function (d) {
                return d.label;
            }));

            data.forEach(function (kv) {
                var labelName = kv.label;
                kv.values.forEach(function (d) {
                    d.value = +d.value;
                    d.label = labelName;
                });
            });

            function make_y_axis() {    // function for the y grid lines
                return d3.svg.axis()
                    .scale(y)
                    .orient("left")
                    .ticks(5)

            }

            function make_x_axis() {    // function for the x grid lines
                return d3.svg.axis()
                    .scale(x)
                    .orient("top")
                    .ticks(5)

            }


            var minY = d3.min(data, function (kv) {
                return d3.min(kv.values, function (d) {
                    return d.value;
                })
            });
            var maxY = d3.max(data, function (kv) {
                return d3.max(kv.values, function (d) {
                    return d.value;
                })
            });

            var padding = width / (data[0].values.length + 1) / 2;
            x.domain(data[0].values.map(function (d) {
                return d.name;
            }))
                .rangePoints([padding, width - padding]);
            y.domain([0, 1.3 * (maxY)]);

            svg.append("svg:rect")  // Grid lines Bakcground
                .attr("x", 0)
                .attr("y", 0)
                .attr("height", height)
                .attr("width", width)
                .attr("fill", "#fff")
                .style("opacity", "0.3")
                .style("opacity", "0.3");

            svg.append("g")
                .attr("class", "x axis")
                .attr("transform", "translate(0," + height + ")")
                .call(xAxis)
                .style("text-anchor", "end");

            svg.append("g")
                .attr("class", "y axis")
                .call(yAxis)
                .append("text")
                .attr("transform", "rotate(-90)")
                .attr("y", 0)
                .attr("dy", ".71em")
                .style("text-anchor", "end");

            svg.append("g")     // Draw the y Grid lines
                .attr("class", "grid")
                .style("stroke-dasharray", "5,5")
//                .style("opacity", "0.5")
                .call(make_y_axis()
                    .tickSize(-width, 0, 0)
                    .tickFormat("")
            );

            var city = svg.selectAll(".branch")
                .data(data)
                .enter().append("g")
                .attr("class", "branch");

            city.append("path")
                .attr("class", "line")
                .attr("d", function (d) {
                    return line(d.values);
                })
                .style("stroke", function (d) {
                    return color(d.label);
                })
                .style("fill", "none")
                .style("stroke-width", 1);

            city.append("text")
                .attr("transform", function (d) {
                    var nameExt = d.values[d.values.length - 1].name;
                    var valueExt = d.values[d.values.length - 1].value;
                    return "translate(" + x(nameExt) + "," + y(valueExt) + ")";
                })
                .attr("x", 3)
                .attr("dy", ".35em")
                .text(function (d) {
                    return d.label
                });


            svg.selectAll("g.dot")
                .data(data)
                .enter().append("g")
                .attr("class", "dot")
                .selectAll("circle")
                .data(function (d) {
                    return d.values;
                })
                .enter().append("circle")
                .attr("r", 3)
                .attr("cx", function (d, i) {
                    return x(d.name);
                })
                .attr("cy", function (d, i) {
                    return y(d.value);
                })

                // Tooltip stuff after this
                .on("mouseover", function (d) {              // when the mouse goes over a circle, do the following
                    div.transition()                  // declare the transition properties to bring fade-in div
                        .duration(200)                  // it shall take 200ms
                        .style("opacity", .9)              // and go all the way to an opacity of .9
//                    div.html("<span style='color: #ec971f;font-weight: bold '>" + d.label + "</span> <br/>" + d.name + "<br/>" + d.value)  // add the text of the tooltip as html
//                    div.html("<span style='color: #ec971f;font-weight: bold '>" + Points + "</span> <br/>" + d.name + "<br/>" + d.value)  // add the text of the tooltip as html
                    div.html("<div style='color: #ec971f;font-weight: bold'>{{strings.POINTS.value}}</div>"+ d.name + "<br/>" + d.value)  // add the text of the tooltip as html
                        .style("left", (d3.event.pageX) + "px")     // move it in the x direction
                        .style("top", (d3.event.pageY - 28) + "px");  // move it in the y direction
                })                          //
                .on("mouseout", function (d) {             // when the mouse leaves a circle, do the following
                    div.transition()                  // declare the transition properties to fade-out the div
                        .duration(500)                  // it shall take 500ms
                        .style("opacity", 0);             // and go all the way to an opacity of nil
                });
//            $(id+" .grid .tick").eq(0).css({stroke: "#000","stroke-width":1,opacity:"1"})

        }

        $scope.waterFallChart = function (element, data, colorCode) {
            $(element).empty();
            var margin = {top: 20, right: 30, bottom: 60, left: 40},
                width = $(element).outerWidth() - margin.left - margin.right,
                height = $(element).outerHeight() - margin.top - margin.bottom,
                padding = 0.3;

            var x = d3.scale.ordinal()
                .rangeRoundBands([0, width], padding);

            var y = d3.scale.linear()
                .range([height, 0]);

            var xAxis = d3.svg.axis()
                .scale(x)
                .orient("bottom");

            var yAxis = d3.svg.axis()
                .scale(y)
                .orient("left")
//                .tickFormat(function(d) { return dollarFormatter(d); });

            var chart = d3.select(element)
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

            chart.append("text")
                .attr("class", "x label")
                .attr("text-anchor", "end")
                .attr("x", width)
                .attr("y", height + margin.bottom-10)
                .text("Months");

            chart.append("text")
                .attr("class", "y label")
                .attr("id", "y-label")
                .attr("text-anchor", "end")
                .attr("y", -20)
                .attr("x", -5)
                .attr("dy", ".75em")
                .text("Points");

            x.domain(data.map(function (d) {
                return d.name;
            }));
            y.domain([0, d3.max(data, function (d) {
                return d.end;
            })]);

            function make_y_axis() {    // function for the y grid lines
                return d3.svg.axis()
                    .scale(y)
                    .orient("left")
                    .ticks(1)

            }

            chart.append("g")
                .attr("class", "x axis")
                .attr("transform", "translate(0," + height + ")")
                .call(xAxis);



            chart.append("g")
                .attr("class", "y axis")
                .call(yAxis);

            var bar = chart.selectAll(".bar")
                .data(data)
                .enter().append("g")
                .attr("class", function (d) {
                    return "bar " + d.class
                })
                .attr("transform", function (d) {
                    return "translate(" + x(d.name) + ",0)";
                });

            bar.append("rect")
                .attr("y", function (d) {
                    return y(Math.max(d.start, d.end));
                })
                .attr("height", function (d) {
                    return Math.abs(y(d.start) - y(d.end));
                })
                .attr("width", "15px")
//                .attr("width", x.rangeBand());
                .attr("transform", "translate(6,0)");

            bar.append("text")
                .attr("x", x.rangeBand() / 2)
                .attr("y", function (d) {
                    return y(d.end) + 5;
                })
                .attr("dy", function (d) {
                    // return ((d.class=='negative') ? '-' : '') + ".75em"
                    return ((d.class.match('negative ')) ? '-' : '') + ".75em"
                })
                .text(function (d) {
                    return (d.end - d.start);
                });
//                    .text(function(d) { return dollarFormatter(d.end - d.start);});

            bar.filter(function (d) {
                return d.class != "total"
            }).append("line")
                .attr("class", "connector")
                .attr("x1", x.rangeBand() + 5)
                .attr("y1", function (d) {
                    return y(d.end)
                })
                .attr("x2", x.rangeBand() / ( 1 - padding) - 5)
                .attr("y2", function (d) {
                    return y(d.end)
                })

            $(element + " .bar.positive text").attr('transform', "translate(0,-20)");
            $(element + " .bar.negative text").attr('transform', "translate(0,20)");
            $(element + " .bar.total text").attr('transform', "translate(0,-20)");
            $(element + " .x path").attr("d", "M0,6V0H890V6");


            var green='#5cb85c',red='#d15b47',blue='steelblue';
            switch (colorCode) {
                case "normal":
                    $(element + " .bar.positiveBar rect").attr('fill',green);
                    $(element + " .bar.negativeBar rect").attr('fill',red);
                    $(element + " .bar.neutralBar rect").attr('fill',blue);
                    break;

                case "inverse":
                    $(element + " .bar.positiveBar rect").attr('fill',red);
                    $(element + " .bar.negativeBar rect").attr('fill',green);
                    $(element + " .bar.neutralBar rect").attr('fill',blue);
                    break;
            }

            function type(d) {
                d.value = +d.value;
                return d;
            }

            function dollarFormatter(n) {
//                n = Math.round(n);
//                var result = n;
//                if (Math.abs(n) > 1000) {
//                    result = Math.round(n/1000) + 'K';
//                }
//                return '$' + result;
                return n;
            }
        }

        $scope.toggleRankLevelChart = function(state) {
            $scope.rankLevelChart = state;
            if($scope.rankLevelChart=="rank"){
                $scope.rankLevelToggle = 'rank';
            }
            else if($scope.rankLevelChart=="level"){
                $scope.rankLevelToggle = 'level';
            }
        }

        $scope.changeImpactGraph = function () {
            $scope.generateImpactPointsGraph();
        }
    });
