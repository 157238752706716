'use strict';
angular.module('bitnudgeApp')
    .controller('addBulkTicketController', function($scope, Auth, Upload,p0, ngXlsx, toaster, UtilService) {
        let self=this;
        $scope.strings = p0.strings;
        $scope.fileType = "tickets";
        $scope.toggleMin = function() {
            $scope.minDate = $scope.minDate ? null : new Date();
        };
        $scope.toggleMin();

        self.s2ab = function(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }
        $scope.yesterday = new Date()
        $scope.yesterday.setDate($scope.yesterday.getDate() - 1)
        $scope.maxDate = new Date();
        $scope.downloadOptions = [{name:'All',_id:'all'},{ 'name': "Assigned SRs", _id: 'assigned' }, { 'name': "UnAssigned SRs", _id: 'unassigned' }]
        $scope.selectedDownloadOption = $scope.downloadOptions[0];

        self.getTimestamp = function(){
            var timestamp = new Date();
            timestamp = timestamp.toString().substring(0, 24)
            timestamp = timestamp.replace('-', ':');
            return timestamp;
        }

        //opening a datepicker dialog box
        $scope.open = function() {
            //$event.preventDefault();
            //$event.stopPropagation();

            $scope.opened = true;
        };

        //Configration for Date picker.
        $scope.dateOptions = {
            formatYear: 'yy',
            modelDateFormat: "yyyy-MM-ddTHH:mm:ss",
            startingDay: 1
        };

        //TODO place this in a config file.
        //$scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = 'dd-MMMM-yyyy';

        //Function to submit Ticket excel
        $scope.onFileSelectTicket = function() {
            if ($scope.bulkTicket == undefined) {
                toaster.pop('error', "Submission.", "Select File to Upload.");
                
            } else {
                var asOfDate = $scope.asOfDate.getTime();
                var fileName = $scope.bulkTicket[0].name.split(".");
                var fileType = fileName[fileName.length - 1].toLowerCase();
                if (fileType == "xlsx" || fileType == "xlsm") {
                    Upload.upload({
                        url: '/api/upload',
                        data: {
                            cronTag: 'tckt',
                            invocationMethod: 'manual',
                            userId: Auth.getCurrentUser()._id,
                            asOfDate: asOfDate,
                            dataType : $scope.fileType
                        },
                        file: $scope.bulkTicket[0],
                        progress: function(e) {}
                    }).then(function(data, status, headers, config) {
                        if(result.exceptions != undefined) {
                            if(result.operations.length == 0)
                                toaster.pop('warning', "Excel uploaded!!", 'There were '+result.exceptionsCount+' \"Exceptions\" and '+result.successCount+' \"Successful\" operations.!!');
                            else
                                toaster.pop('failure', "Excel uploaded!!", 'There were '+result.exceptionsCount+' \"Exceptions\" and '+result.successCount+' \"Successful\" operations.!!');
                            var file = ngXlsx.writeXlsx([result.exceptions]);
                            var date = new Date()
                            var timestamp = new Date();
                            timestamp = timestamp.toString().substring(0, 24)
                            saveAs(new Blob([self.s2ab(file)],{type:"application/octet-stream"}), self.getTimestamp()+'-Tickets-Errors.xlsx');
                        } else {
                            toaster.pop('success', "Excel uploaded!!", 'No exceptions in the operation!!');
                        }
                    }).catch(function(res){
                        if(res.status == 310) {
                            toaster.pop('warning', "Excel could not be uploaded!!", 'Sorry!! Headers in the excel file are not appropriate!!');
                        }
                    });
                } else {
                    toaster.pop('error', "Submission.", "Upload '.xlsx' file only.");
                }

            }
        };

        var appUrl = p0.config.domain;
        var subfolder = p0.config.subfolder;
        if(subfolder){
            if(appUrl[appUrl.length -1] !== '/'){
                appUrl += '/';
            }
            if(subfolder[0] == '/'){
                subfolder = subfolder.slice(1);
            }
            appUrl += subfolder;
        }

        $scope.downloadTickets = function(){
            if(Object.prototype.toString.call($scope.fromDate) == "[object Date]"){
                $scope.fromDateTime = (new Date($scope.fromDate.setHours(0,0,0,0))).getTime();
            }
            if(Object.prototype.toString.call($scope.endDate) == "[object Date]"){
                $scope.endDateTime = (new Date($scope.endDate.setHours(23,59,59,59))).getTime();
            }
            if(!$scope.fromDateTime){
                toaster.pop('error', "Date", "Please select from Date");
                return;
            }
            if(!$scope.endDateTime){
                toaster.pop('error', "Date", "Please select end Date");
                return;
            }
            
            var downloadOptTickets = function(){
                
                if (!appUrl || appUrl == undefined) {
                    appUrl='';
                }
                const baseUrl = appUrl + '/api/tickets/downloadTickets';
                const paramData = {
                    offset : 0,
                    limit : 0,
                    download : true,
                    startDate : new Date($scope.fromDateTime).getTime(),
                    endDate : new Date($scope.endDateTime).getTime(),
                    view: $scope.selectedDownloadOption._id
                };
                
                UtilService.downloadFile(baseUrl, paramData)
            }

            downloadOptTickets();


        }
    })