const autoImportNgTemplateLoaderTemplate1 = require('../../html/rejectDocModal.html');

'use strict';

angular.module('bitnudgeApp').controller('updateLeadStatusCtrl', function($rootScope, $scope, Auth, p0, $state, $stateParams,
    leadsService, $timeout, toaster, $window, $filter, WorkflowService, DocumentService, $mdDialog, moment) {

    let me = Auth.getCurrentUser();
    $scope.loggedInUser = me;
    let onCloseListener = null;
    let selectedRoleUsers = [];
    $scope.data = {};
    $scope.interactionService = { show: true, isRequired: false };
    $scope.params = {};
    $scope.mindate = new Date();
    let roleTypes = ["Loan Advisors"];
   
    let allRoleTypes = []
    const cuiLabel = p0.strings.CUI_LABEL && p0.strings.CUI_LABEL.value ? p0.strings.CUI_LABEL.value : 'CUI';

    const registerForEvents = function() {
        $scope.$on('workflow:lead:addPrerequisite', function(event, opt) {
            const { existing, data } = opt;
            const {
                contact,
                workflowId,
                _id,
                userId,
                productId,
                subProduct,
                leadStatusId,
                leadStage
            } = $scope.data.lead;
			const { state } = $scope.data;
            const payload = {
                customer: { name: contact.name, contactNumber: contact.contactNumber.countryCode+''+contact.contactNumber.number },
                existing,
                docTypeId: (data && data._id) ? data._id : '',
                newPreqParams: data, // New document type params are saved here...
                userId: userId._id,
                workflowId,
                context: 'lead',
                productId: productId._id,
                subProduct,
                workflowConsumerId: _id,
                stateUid: leadStatusId,
                stageUid: leadStage,
				toStateUid: state.state,
            };
            WorkflowService.addPrerequisite(payload).then(function(response) {
                if (response.data.status) {
                    toaster.pop('success', "Lead", "Request for additional information is successful.");
                } else {
                    toaster.pop('error', "Lead", "Request for additional information is failed. Please ask administrator to recheck the workflow permissions.");
                }
            }, function(err) {
                const message = 'Add prerequisite failed.';
                console.error(`${message}`, err);
                toaster.pop('error', `${message} Please try after sometime.`);
            })
        });

        $scope.$on('workflow:lead:removePrerequisite', function(event, opt) {
            const { item } = opt;
            const { workflowId } = $scope.data.lead;
            WorkflowService.removePrerequisite(workflowId, item._id, item.reference.to).then(function(response) {
                if (response.data.status) {
                    const prereq = $scope.data.state.prerequisites;
                    prereq.splice(prereq.findIndex(p => p._id === item._id), 1);
                    toaster.pop('success', "Lead", "Additional prerequisite is removed.");
                } else {
                    toaster.pop('error', "Lead", "Failed to remove additional prerequisite.");
                }
            }, function(err) {
                const message = 'Remove prerequisite failed.';
                console.error(`${message}`, err);
                toaster.pop('error', `${message} Please try after sometime.`);
            });
        });

        $scope.$on('workflow:lead:document', function(event, opt) {
            const { item } = opt;
            const { workflowId, contact, cui } = $scope.data.lead;
            const isApproved = item.name.startsWith("approve");
            const docPrerequisites = $scope.data.state.prerequisites.filter(p => p.type === 'document');
            const otherPrerequisites = docPrerequisites.filter(type => type !== item.typeParam);

            let documentTypes = otherPrerequisites.map(p => p.typeParam);
            documentTypes.unshift(item.typeParam);

            let documentIds = otherPrerequisites.filter(p => (p.selectedDocId || p.rejectedDocId)).map(p => p.selectedDocId || p.rejectedDocId);
            const refId = item.selectedDocId || item.rejectedDocId;
            if (refId) {
                documentIds.unshift(refId);
            }
            const dmsParameters = {
                documentTypes, //In case of select mode
                documents: documentIds, //In case of approve mode send document Ids
                isCustomerMode: false, //for customer mode, open DMS with edit option without selection or approve feature
                isApproveMode: isApproved, //for approve mode, open DMS with approve and reject option
                isSelectMode: !isApproved, //for selection mode, open DMS with lead or ticket context i.e workflow
                customer: {},
                workflowId,
                //cui: cui || contact.cui,
                context: 'lead'
            };
            $rootScope.$broadcast('dms:openModal', dmsParameters);

            if (onCloseListener) onCloseListener();
            onCloseListener = $scope.$on("dms:onCloseModal", () => {
                // getLeadHistoryFunc();
                refreshDocumentPrereqStatus();
            });

        });

        $scope.$on('workflow:lead:approveDirect', function(event, opt) {
            const { item } = opt;
            const { documentTypeId, selectedDocId, approved, rejected } = item;
            const { workflowId } = $scope.data.lead;
            if (rejected) {
                toaster.pop('info', "Rejected document can't be approved.");
                return;
            }
            if (approved) {
                toaster.pop('info', "Document is already approved.");
                return;
            }
            const data = { docId: selectedDocId, documentTypeId, workflowId };
            DocumentService.approve(data)
                .then(result => {
                    const { status, data, errors } = result;
                    if (status) {
                        toaster.pop("success", data.message);
                        // getLeadHistoryFunc();
                        refreshDocumentPrereqStatus();
                    } else {
                        toaster.pop("error", "Document not approved", errors[0].message);
                    }
                })
                .catch(err => {
                    console.error(err);
                    toaster.pop("error", "Error in document approval", err.message);
                });
        });

        $scope.$on('workflow:lead:rejectDirect', function(event, opt) {
            $mdDialog
                .show({
                    templateUrl: autoImportNgTemplateLoaderTemplate1,
                    controller: "RejectDocCtrl",
                    clickOutsideToClose: true,
                    preserveScope: true,
                    autoWrap: true,
                    resolve: {}
                })
                .then(result => {
                    if (result) {
                        const { item } = opt;
                        const { documentTypeId, selectedDocId, approved, rejected } = item;
                        const { workflowId } = $scope.data.lead;
                        let { comment, reason } = result;
                        if (!comment) {
                            comment = reason.title;
                        }
                        const data = {
                            docId: selectedDocId,
                            documentTypeId,
                            workflowId,
                            notes: comment,
                            label: reason.title
                        };
                        DocumentService.reject(data).then(result => {
                                const { status, data, errors } = result;
                                if (status) {
                                    toaster.pop("success", data.message);
                                    // getLeadHistoryFunc();
                                    refreshDocumentPrereqStatus();
                                } else {
                                    toaster.pop("error", "Document not rejected", errors[0].message);
                                }
                            })
                            .catch(err => {
                                console.error(err);
                                toaster.pop("error", "Error in document rejected", err.message);
                            });
                    }
                });
        });
    };

    const refreshDocumentPrereqStatus = () => {
        const { workflowId } = $scope.data.lead;
        leadsService.getDocumentsCache('lead', workflowId).then(function(response) {
            response = response.data;
            const { status, data, errors } = response;
            if (status) {
                DocumentService.updateDocumentPrerequisites($scope.data.state.prerequisites, data);
            }
        }).catch((err) => {
            console.error('Get documents cache failed', err);
        });
    };

    const init = function() {
        registerForEvents();
        $scope.loadingInfo = true;
		$scope.attendanceLabel = leadsService.getAttendanceLabel($rootScope.isFrontline);
        if(me && me.organizationalTag && me.organizationalTag.jobRole){
        	const myRole = p0.allJobRoles.find(role => role._id === me.organizationalTag.jobRole._id);
			if(myRole){
				$scope.showAdviserAttendance = myRole.loanAdviserAttendanceTrack;
			}else{
				$scope.showAdviserAttendance = false;
			}
        }
        
        $scope.loadingInfo = true;
        leadsService.getLead($stateParams.leadId).then(function(data) {
            $scope.data.lead = data.data.data;
            $scope.interactionService.isRequired = data.data.data.leadStatusId.toLowerCase() === 'created';
          
            leadsService.getNextStates($stateParams.leadId, me._id).then(function(response) {
                $scope.data.nextStates = response.data.data;
                $scope.workflowNoUpdateMessage = response.data.message
                $scope.data.state = $scope.data.nextStates.find(state => state.isNextPositiveState === true) || $scope.data.nextStates[0];
                $scope.data.stateSelected = true;
                $scope.loadingInfo = false;
                refreshDocumentPrereqStatus();
            }).catch(function(err) {
                console.error('Lead get next states API failed', err);
                toaster.pop('error', "Can't update lead now");
            });
            if($scope.data.lead && $scope.data.lead.notes){
                $scope.data.lead.notes = $scope.data.lead.notes.filter(note => note.notes && note.notes.length)
            }
        }).catch(function(err) {
            console.error('Get lead API failed', err);
            toaster.pop('error', "Can't get the lead");
        });
    };

    const updateWorkflow = function(prerequisites) {
        const params = {
            _id: $scope.data.lead._id,
            userId: me._id,
            workflowId: $scope.data.lead.workflowId,
            nextStateId: $scope.data.state.state,
            prerequisites: prerequisites
        };
        $scope.params.errors = [];
        return leadsService.gotoState(params);
    };

    const goBack = function() {
        if(p0.config.leadUI_v1){
            $state.go('userFlow.editLead_v1', {leadId: $stateParams.leadId,productId: $stateParams.productId});
        }else{
            $state.go('userFlow.editLead_v2', {leadId: $stateParams.leadId,productId: $stateParams.productId});
        }
    };
    $scope.onCancel = function() {
        goBack();
    };

    $scope.onStateChange = function() {
        $scope.data.stateSelected = false;
        // checkInteractionRequired();
        $scope.params.errors = [];
        $timeout(function() {
            $scope.data.stateSelected = true;
        });
    };

    function searchComponent(query, key, key2) {
        //var lowercaseQuery = query.toLowerCase();
        let ismatched = false;
        const re = new RegExp(query, 'gi');
        return function filterFn(list) {
            if (key2)
                ismatched = list[key].match(re) || list[key2].match(re);
            else
                ismatched = list[key].match(re);

            if (ismatched)
                return true;
            else
                return false;
        };
    }

    //---angular material -----
    $scope.searchList = function(query, list, key, key2) {
        var results = query ? list.filter(searchComponent(query, key, key2)) : list;
        return results;
    };


    $scope.onSubmit = function () {
    	updateWorkflow($scope.data.state.prerequisites).then(function (response) {
    		if (response.data.status) {
    			$scope.params.errors = [];
    			toaster.pop('success', "Lead", "Update is successful.");
    			goBack();
    		} else {
    			toaster.pop('error', "Lead", "There were some errors in updating the lead. Please fix and try again.");
    			$scope.params.errors = response.data.errors;
    		}
    	}).catch(function (err) {
    		console.error('Lead goto state API failed', err);
    		$scope.params.errors = err.data.errors;
    		toaster.pop('error', "Lead", "Update is failed.");
    	});
    };

    //#endregion
    $scope.additionalItem = {
        id:new Date().getTime(),
        isAdd: true,
        roleType: "",
        roleUser: "",
        roleUsers: [],
        roleTypes: []
    }
    $scope.additionalList = [];
    $scope.additionalList.push(angular.copy($scope.additionalItem));

    /**
     * additionalList : this list contains the dynamically added loan advisors from the ui
     */

    $scope.addAdditionalField = () => {
        $scope.additionalList.forEach( list => {
            list.isAdd = false;
            selectedRoleUsers.push(list.roleUser._id);
        })
		$scope.additionalItem.id = new Date().getTime();
        $scope.additionalItem.isAdd = true;
        
        const result = checkForItems()
        $scope.additionalItem.roleTypes = result.roleTypes;
        $scope.additionalItem.showMore = result.showMore;


        $scope.additionalList.push(angular.copy($scope.additionalItem));
    };

    
    const prepareRolesAndUsersData = () => {
        const {allPlayTypes, config} =  p0;
        const loanAdvisorPlayType = allPlayTypes.find( type => {
            if(type.playTypeCode === config.loanAdvisorPlaytype){
                return type
            }
        });
        //get users based on playtype
        const getPlayTypeUsersPromise = leadsService.getPlayTypeUsers(loanAdvisorPlayType._id);
        getPlayTypeUsersPromise.then( function(playTypeUsers){
            $scope.loanAdvisors = playTypeUsers;
            let result = {}
            if(me.systemRole === 'frontline'){
                leadsService.getUserById(me.parent).then( function(user){
                    $scope.managerUsers = [user]
                    roleTypes.push('Manager')
                    allRoleTypes = angular.copy(roleTypes)
                    result = checkForItems()
                    $scope.additionalList[0].roleUsers = $scope.loanAdvisors;
                    $scope.additionalList[0].roleTypes = result.roleTypes;
                    $scope.additionalList[0].showMore = result.showMore
                })        
            }else{
                allRoleTypes = angular.copy(roleTypes)
                result = checkForItems()
                $scope.additionalList[0].roleUsers = $scope.loanAdvisors;
                $scope.additionalList[0].roleTypes = result.roleTypes;
                $scope.additionalList[0].showMore = result.showMore
            }
            
            
        }).catch( function(error){
            const message = error && error.message ? error.message : 'Error while fetching Playtype users.'
            toaster.pop('error', "Playtype Users", message);
        })
    }
    //#endregion
    $scope.changeRoleUserlist = (role, id) => {
        let filteredField = $scope.additionalList.find( field => {
            if(field.id === id){
                return field
            }
        })
        if(role === 'Manager'){
            filteredField.roleUsers = $scope.managerUsers.filter( manager => {
                if(selectedRoleUsers.includes(manager._id.toString())){
                    return false;
                }else{
                    return true;
                }
            });
            
        }else{
            filteredField.roleUsers = $scope.loanAdvisors.filter( manager => {
                if(selectedRoleUsers.includes(manager._id.toString())){
                    return false;
                }else{
                    return true;
                }
            });
        }
    }

    prepareRolesAndUsersData();

    $scope.removeAdditionalField = (item) => {
        selectedRoleUsers = selectedRoleUsers.filter( id => {
            if(item.roleUser && item.roleUser._id === id){
                return false
            }else{
                return true
            }
        })
        $scope.additionalList = $scope.additionalList.filter(ele => ele.id !== item.id);

        let removedManager= null;
        let removedAdvisor = null;
        if($scope.managerUsers){
            removedManager = $scope.managerUsers.find( manager => {
                if(item.roleUser && manager._id === item.roleUser._id){
                    return true
                }
            })
        }
        if($scope.loanAdvisors){
            removedAdvisor = $scope.loanAdvisors.find( advisor => {
                if(item.roleUser && advisor._id === item.roleUser._id){
                    return true
                }
            })
        }
        $scope.additionalList.forEach( list => {
            if(list.isAdd){
                if(list.roleType === 'Manager' && removedManager){ 
                    list.roleUsers.push(removedManager)
                }else if(removedAdvisor && list.roleType !== 'Manager'){
                    list.roleUsers.push(removedAdvisor)
                }
                if(!list.roleTypes.includes(item.roleType)){
                    list.roleTypes.push(item.roleType)
                    list.roleUser = ""
                    list.roleType = ""
                }
                list.showMore = true
            }
        })
    };
    
    const checkForItems = () => {
        let tempTypes = angular.copy(roleTypes);
        let users = []
        let managerLength = 0;
        let laLength = 0
        const result ={
            roleTypes : "",
            showMore : false
        }
        allRoleTypes.forEach( role => {
            if(role === 'Manager'){
                users = $scope.managerUsers.filter( manager => {
                    return !selectedRoleUsers.includes(manager._id.toString())
                });
                managerLength = users.length
                if(!users.length){
                    const index = tempTypes.indexOf(role)
                    if(index >= 0)
                    tempTypes.splice(index,1)
                }
            }else{
                users = $scope.loanAdvisors.filter( advisor => {
                    return !selectedRoleUsers.includes(advisor._id.toString())
                });
                laLength = users.length
                if(!users.length){
                    const index = tempTypes.indexOf(role)
                    if(index >= 0)
                    tempTypes.splice(index,1)
                }
            }
            
        })

        if((!managerLength && laLength === 1) || (managerLength ===1 && !laLength)){
            result.showMore = false
        }else{
            result.showMore = true
        }
        result.roleTypes = tempTypes
        return result
    }

    init();
});
