'use strict';
angular.module('bitnudgeApp')
    .controller('bulkConfigCtrl', function($http, $scope, Auth, Upload, $location, toaster) {
        $scope.fileType = "configs";
        $scope.onFileSelect = function() {
            //$files: an array of files selected, each file has name, size, and type.
            for (var i = 0; i < $scope.files.length; i++) {
                var $file = $scope.files[i];
                var fileName = $scope.files[i].name.split(".");
                var fileType = fileName[fileName.length - 1];
                if (fileType == "xls" || fileType == "xlsx" || fileType == "xlsm" || fileType == "xlsb") {
                    Upload.upload({
                        url: '/api/upload',
                        data: {cronTag:'uploadConfig',
                        invocationMethod:"manual"},
                        file: $file,
                        progress: function (e) {
                        }
                    }).then(function (data, status, headers, config) {
                        toaster.pop('success', "Bulk configs upload.", "Bulk Configs upload.");
                    });
                } else {
                    toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' Types Only.");
                }
            }
        }
    })
