const autoImportNgTemplateLoaderTemplate1 = require('../html/hallOfFame.html');

'use strict';
angular.module('bitnudgeApp')
    .component('hallOfFame', {
        templateUrl: autoImportNgTemplateLoaderTemplate1,
        bindings: {
            badgeAchievers:     '<',
            starPlayers:        '<',
            filterSelects:            '<',
            onFiltersChanged:   '&'
        },
        controller: 'hallOfFameCtrl'
    });
