'use strict';
angular.module('bitnudgeApp')
    .controller('swotWithValueCtrl', function ($scope) {
        if ($scope.zone == 'green') {
            $scope.class = "fa-heart";
        } else if ($scope.zone == 'blue') {
            $scope.class = "fa-lightbulb-o";
        } else if ($scope.zone == 'amber') {
            $scope.class = "fa-thumbs-down";
        } else {
            $scope.class = "fa-flag";
        }
    });
