'use strict';
angular.module('bitnudgeApp')
    .controller('metricSelectionModalCtrl', function ($scope, p0, benchMark,$http,$uibModalInstance,setMetric) {
        $scope.benchMark = benchMark;
        $scope.strings = p0.strings;
        $http.post('/api/users/getAllPeers',{teamType: 'bank'}).then(function(users){
            users=users.data;
            $scope.users = users
        });
        $scope.closeModal = function() {
            $uibModalInstance.close();
        };

        $scope.setMetric = function(metric) {
            setMetric(metric);
            $scope.closeModal();
        };
    });
