'use strict';
angular.module('bitnudgeApp')
    .service('TriggerService', function ($http, $q, _) {
        var _this = this;

        _this.getAllTriggers = function () {
            var deferred = $q.defer();
            $http.post('/api/alertsrec/getAllTriggers').then(function(success){
                deferred.resolve(success.data);
            },function(error){
                deferred.reject(error);
            });
            return deferred.promise;
        }

        _this.getTriggerIntervals  = function(){
            var deferred = $q.defer();
            $http.post('/api/alertsrec/getTriggerIntervals').then(function(response){
                deferred.resolve(response)
            },function(error){
                deferred.reject(error)
            })
            return deferred.promise;
        }

        _this.getActionByTrigger = function (triggername) {
            var deferred = $q.defer();
            $http.post('/api/alertsrec/getActionByTrigger', { triggerName:triggername}).then(function(success){      
                deferred.resolve(success.data);
            },function(error){
                deferred.reject(error)
            });
            return deferred.promise;
        }
        _this.getOutputsByTrigger = function (triggername) {
            var deferred = $q.defer();
            $http.post('/api/alertsrec/getOutputsByTrigger', { triggerName:triggername}).then(function(success){
                deferred.resolve(success.data);
            },function(error){
                deferred.reject(error)
            });
            return deferred.promise;
        }

        _this.setTrigger = function (payLoad) {
            var deferred = $q.defer();
            $http.post('/api/alertsrec/setTrigger',{payload:payLoad}).then(function(result){
                if(result.data.status)
                    deferred.resolve(result.data.status)
                else
                    deferred.reject(result.data.errors[0])
            },function(error){
                deferred.reject(error)
            });

            return deferred.promise;
        }
        //#endregion

        _this.updateTrigger = function (payLoad) {
            var deferred = $q.defer();
            $http.post('/api/alertsrec/setTrigger',{payload:payLoad, isUpdate: true}).then(function(result){
                if(result.data.status)
                    deferred.resolve(result.data.status)
                else
                    deferred.reject(result.data.errors[0])
            },function(error){
                deferred.reject(error)
            });

            return deferred.promise;
        }


        _this.getAllAlarms = function (params) {
            var deferred = $q.defer();
            let url = '/api/alertsrec/getAllAlarms';
            const {allAlarms} = params;
            let postBody = {};
            if(allAlarms){
                postBody.allAlarms = true;
            }
            $http.post(url, postBody).then(function(response){
                deferred.resolve(response.data)
            },function(error){
                deferred.reject(error)
            })
            return deferred.promise;
        }

        _this.getAlarmLogsForUser = function(userId,interval){
            var deferred = $q.defer();
            $http.post('/api/alertsrec/getAlarmLogsForUser', {userId, interval}).then(function(response){
                deferred.resolve(response.data);
            },function(error){
                deferred.reject(error);
            })
            return deferred.promise;
        }

        _this.getAlarmLogs = function(){
            var deferred = $q.defer();
            $http.post('/api/alertsrec/getAlarmLogs').then(function(response){
                deferred.resolve(response.data);
            },function(error){
                deferred.reject(error);
            })
            return deferred.promise;
        }

        _this.getAlarmDetail = (id) => {
            var deferred = $q.defer();
            $http.post('/api/alertsrec/getAlarmDetail', {id}).then(function(response){
                deferred.resolve(response);
            },function(error){
                deferred.reject(error)
            })
            return deferred.promise;
        }

        _this.runAlarmCreationJob = function(interval){
            var interval = interval ? interval : "hourly"
            var deferred = $q.defer();
            $http.post('/api/alertsrec/runAlarmCreationJob',{interval}).then(function(response){
                deferred.resolve(response)
            },function(error){
                deferred.reject(error)
            })
            return deferred.promise;
        }
        

    });
