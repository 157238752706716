'use strict';
angular.module('bitnudgeApp')
    .controller('transactionController', function ($scope,user,savedFilter, transactionService, UtilService, $mdDialog,$filter, p0, toaster) {
        
        const currentUser = user;
        let canFetchData = false;
        let allSubProducts = []
        $scope.filterTypeMap = {
            transactionDate: 'transactionDate',
            productGroup: 'productGroup',
            productFilter : 'productFilter',
            amountFilter: 'amountFilter'
        }
        const init = () => {
            $scope.sortingObj = {
                sortKey: 'transactionDate',
                sortOrder: 'desc'
            };
            $scope.filterObj = {}
            $scope.selected = {};
            $scope.filteredChilds = []
            $scope.filterSelection = undefined;
            $scope.pagination = transactionService.getPaginationObj();
            prepareProductsGroup();
            // prepareProductsFilter();
            prepareAmountsFilter();

        }

        /**
         * Restore filter params if available
         */

        const checkAndRestoreStateParams = () => {
            const filterParams = savedFilter;
                if(filterParams){
                    
                    if(filterParams.startDate){
                        $scope.filterObj.startDate = filterParams.startDate
                    }
                    
                    if(filterParams.endDate){
                        $scope.filterObj.endDate = filterParams.endDate 
                    }

                    if(filterParams.productGroupKey){
                        const product =  $scope.productGroups.find( product => {
                            return product._id === filterParams.productGroupKey
                        })
                        $scope.selectProductGroup(product, $scope.filterTypeMap.productGroup);
                    }

                    if(filterParams.productFilterKey){
                        const product =  allSubProducts.find( product => {
                            return product._id === filterParams.productFilterKey
                        })
                        $scope.selectProductFilter(product, $scope.filterTypeMap.productFilter);
                    }

                    if(filterParams.amountFilterKey){
                        const amount =  $scope.amountRanges.find( amount => {
                            return amount.id === filterParams.amountFilterKey
                        })
                        $scope.selectAmountFilter(amount, $scope.filterTypeMap.amountFilter);
                    }

                    if(filterParams.searchParam && filterParams.searchParam.length){
                        $scope.searchResult = true;
                        $scope.filterObj.searchParam = filterParams.searchParam
                    }

                    $scope.filterObj.transactionDateKey = filterParams.transactionDateKey;
                }else{
                    $scope.filterObj.startDate = p0.currentMonthlyCycle.startDate;
                    $scope.filterObj.endDate = p0.currentMonthlyCycle.endDate;
                }
                
            setTimeRange('regular');
            canFetchData = true;
            // $scope.fetchTransactions();
        }

        //convert date to readable string
        const convertDateToString = (date) => {
            return $filter('date')(date, "mediumDate")
        }
        

         /**
         * Products group preparation
         * TODO: change product group as actual product group when
         * product group functionality is available in our system.
         */
        const prepareProductsGroup = () => {
            const {allProducts} = p0;
            $scope.productGroups = allProducts;
            $scope.productGroups.forEach( product => {
                allSubProducts.push(...product.subProducts)
            })
            if(!$scope.filterObj.productGroupKey){
                $scope.productFilters = allSubProducts
            }
        }
        
        /**
         * Products filter preparation
         */
        // const prepareProductsFilter = () => {
        //     // const {allProducts} = p0;
        //     // $scope.productFilters = allProducts
        // }

        /**
         * Prepare amounts filter
         */
        const prepareAmountsFilter = function(){
            const fetchAmountRangesPromise = transactionService.fetchAmountRanges(currentUser);
            fetchAmountRangesPromise.then( function(data) {
                $scope.amountRanges = data.ranges;
                checkAndRestoreStateParams();            
            }).catch(function(error){
                console.error(error)
                toaster.pop('error', 'Transaction', 'Error in fetching amount ranges')
            })
        }
        /**
         * Set Pagination data function
         */
        const setPaginationData = function(data){
            $scope.pagination.total = data.total;
            if(data.transactions.length === 0){
                $scope.filteredCount = 0;
            } else {
                if($scope.pagination.offset+$scope.pagination.limit < $scope.pagination.total){
                    $scope.filteredCount = ($scope.pagination.offset+1)+'-'+($scope.pagination.offset+$scope.pagination.limit);
                } else {
                    $scope.filteredCount = ($scope.pagination.offset+1)+'-'+($scope.pagination.total);                
                }
            }
    
            if(($scope.pagination.offset+$scope.pagination.limit) >= $scope.pagination.total){
                $scope.pagination.disableNext = true;
            } else {
                $scope.pagination.disableNext = false;            
            }
            if($scope.pagination.offset === 0){
                $scope.pagination.disablePrevious = true;
            } else {
                $scope.pagination.disablePrevious = false;            
            }
        }

        /**
         * cycle change $watch
         */
        $scope.$watch('selected.cycle', function(newVal, oldVal) {
            if (newVal) {
                setDates(newVal);
            }
        });
    
        function setDates(cycle) {
            $scope.startDate = new Date(cycle.startDate);
            $scope.endDate = new Date(cycle.endDate);
            $scope.filterObj.startDate = $scope.startDate;
            $scope.filterObj.endDate = $scope.endDate;
            updateDateChild($scope.filterTypeMap.transactionDate);
        }
    
        /**
         * cycle change
         */
        
        $scope.cycleChange = {
            cycle: function(cycle) {
                if ($scope.selected.cycle && cycle._id != $scope.selected.cycle._id) {
                    $scope.selected.cycle = cycle;
                };
                $scope.filterObj.startDate = $scope.selected.cycle.startDate;
                $scope.filterObj.endDate = $scope.selected.cycle.endDate;
                // updateDateChild();
            },
            startDate: function(startDate) {
                $scope.startDate = new Date(startDate);
                if ($scope.selected.cycle) {
                    $scope.selected.cycle.startDate = $scope.startDate;
                }
                $scope.filterObj.startDate = $scope.startDate;
                updateDateChild($scope.filterTypeMap.transactionDate);
            },
            endDate: function(endDate) {
                $scope.endDate = new Date(endDate);
                if ($scope.selected.cycle) {
                    $scope.selected.cycle.endDate = $scope.endDate;
                }
                $scope.filterObj.endDate = $scope.endDate;
                updateDateChild($scope.filterTypeMap.transactionDate);
                
            }
            
        };
        
        const updateDateChild = (filterSelection) => {
            const child = {
                filterSelection: filterSelection ? filterSelection : $scope.filterSelection,
                value : convertDateToString($scope.filterObj.startDate) +' - '+ convertDateToString($scope.filterObj.endDate),
                name: 'Transaction Date :'
            }
            $scope.filterObj.transactionDateKey = true;
            updateFilteredChild(child)
        }

        $scope.onClose = function(){
            $mdDialog.hide($scope.filterObj);
        };

        //maximize window function
        $scope.maximiseWindow = function () {
			$scope.isWindowMaximised = !$scope.isWindowMaximised;
        };
        
        /**
         * fetch transaction logic
         */
        $scope.fetchTransactions = (user, pagination) => {
            $scope.apiLoaded = false;
            if (!user) {
                user = currentUser;
            }
            if (pagination) {
                $scope.pagination = pagination;
            }
            if(canFetchData){
                $scope.transactionData = [];
                const txnDataPromise = transactionService.getTransactionData({userId: user._id, filterObj: $scope.filterObj, pagination: $scope.pagination, sortingObj: $scope.sortingObj});
                txnDataPromise.then( data => {
                    setPaginationData(data)
                    $scope.transactionData = data.transactions;
                    $scope.apiLoaded = true;
                }).catch(error => {
                    console.error(error,'---error from transaction api service')
                    toaster.pop('error', 'Transaction', 'Error in fetching transaction data')
                })
            }
        }

        /**
         * Transaction search functionality
         */
        $scope.searchTransactions = function(){
            $scope.searchResult = true;
            $scope.pagination = transactionService.getPaginationObj();
            $scope.fetchTransactions(currentUser);
        }

        $scope.resetSearchTransactions = function(){
            $scope.filterObj.searchParam=""
            $scope.searchResult = false;
            $scope.pagination = transactionService.getPaginationObj();
            $scope.fetchTransactions(currentUser);
        }

        $scope.search_enter = function(keyEvent) {
            if (keyEvent.which === 13 && $scope.filterObj.searchParam && $scope.filterObj.searchParam.length) 
               { 
                    $scope.searchTransactions()
               }
        }
        
        /** transaction next page functionality */
        $scope.nextPage = function(){
            $scope.pagination.offset += $scope.pagination.limit;
            //What is this selected user check?
            $scope.fetchTransactions(currentUser);
        }
        
        /** transaction previous page functionality */
        $scope.previousPage = function(){
            $scope.pagination.offset -= $scope.pagination.limit;
            //What is this selected user check?
            $scope.fetchTransactions(currentUser);
        }
        
        /**
         * set time range cycles
         */
        const setTimeRange = (monthType) => {
            $scope.timeRange = UtilService.getTimeRange(monthType, 'lead');
            if ($scope.filterObj.startDate) {
                $scope.selected.cycle = _.find($scope.timeRange, function(range) {
                    return moment(range.startDate).valueOf() == moment($scope.filterObj.startDate).valueOf() &&
                        moment(range.endDate).valueOf() == moment($scope.filterObj.endDate).valueOf();
                });
                if (!$scope.selected.cycle) {
                    $scope.selected.cycle = _.find($scope.timeRange, {
                        _id: 'custom'
                    });
                    $scope.selected.cycle.startDate = new Date($scope.filterObj.startDate);
                    $scope.selected.cycle.endDate = new Date($scope.filterObj.endDate);
                }
            }
            if (!$scope.selected.cycle) {
                $scope.selected.cycle = $scope.timeRange[0];
            }
        };
        
        /**
         * show filter functionality based on key
         */
        $scope.showFilter = (filterName) => {
            if($scope.filterSelection === filterName){
                $scope.filterSelection = undefined;
            }else{
                $scope.filterSelection = filterName
            }
        }

        /**
         * Amount filter handle
         */
        $scope.selectAmountFilter = (amountFilter, staticFilterSelection) => {
            $scope.filterObj.amountFilterKey = amountFilter.id
            $scope.filterObj.minAmount = amountFilter.minAmount
            $scope.filterObj.maxAmount = amountFilter.maxAmount
            const child = {
                filterSelection: staticFilterSelection || $scope.filterSelection,
                value: amountFilter.name,
                name: 'Amount :'
            }
            updateFilteredChild(child)
        }

        /**
         * handle all the product selections
         */
        $scope.selectProductFilter = (product, staticFilterSelection) => {
            $scope.filterObj.productFilterKey = product._id
            const child = {
                filterSelection: staticFilterSelection || $scope.filterSelection,
                value: product.name,
                name: 'Product Name :'
            }
            updateFilteredChild(child)
        }

        $scope.selectProductGroup = (productGroup, staticFilterSelection) => {
            $scope.filterObj.productGroupKey = productGroup._id
            if(!$scope.filterObj.productGroupKey){
                $scope.productFilters = allSubProducts;
            }else{
                $scope.productFilters = productGroup.subProducts;
            }

            const child = {
                filterSelection: staticFilterSelection || $scope.filterSelection,
                value: productGroup.name,
                name: 'Product Group :'
            }
            updateFilteredChild(child)
        }

        /**
         * 
         * handle all the remove filter functionality
         */
        $scope.removeFilter = (filterType) => {
            removeFilteredChild(filterType)
        }
        /**
         * $scope.filteredChilds: top row after filter gets selected
         * update filteredChild list
         */
        const updateFilteredChild = (data) => {
            let filteredChildIndex = -1;
            if($scope.filteredChilds.length === 0){
                filteredChildIndex = 0;
            }else{
                $scope.filteredChilds.forEach( (child, index) => {
                    if(child.filterSelection === data.filterSelection){
                        filteredChildIndex = index
                    }
                })
            }
            
            if(filteredChildIndex < 0){
                $scope.filteredChilds.push(data);
            }else{
                $scope.filteredChilds[filteredChildIndex] = data;
            }
            $scope.pagination = transactionService.getPaginationObj();
            $scope.fetchTransactions();
        }

        /**
         * $scope.filteredChilds : top row after filter gets selected
         * remove filteredChild 
         */
        const removeFilteredChild = (filterType) => {
            let filteredChildIndex = -1;
            if($scope.filteredChilds.length === 0){
                filteredChildIndex = 0;
            }else{
                $scope.filteredChilds.forEach( (child, index) => {
                    if(child.filterSelection === filterType){
                        filteredChildIndex = index
                    }
                })
            }
            if(filteredChildIndex >= 0){
                $scope.filteredChilds.splice(filteredChildIndex, 1); 
            }
            switch(filterType){
                case $scope.filterTypeMap.transactionDate:
                     $scope.filterObj.transactionDateKey = false
                     $scope.selected.cycle = ""
                     break;

                case $scope.filterTypeMap.productFilter: 
                     $scope.filterObj.productFilterKey = null
                     $scope.selectedProductFilter = ""
                     break;
                
                case $scope.filterTypeMap.amountFilter:
                     $scope.filterObj.amountFilterKey = 0
                     $scope.selectedRange = {}
                     break;
                
                case $scope.filterTypeMap.productGroup:
                     $scope.filterObj.productGroupKey = null
                     $scope.selectedProductGroup = {}
                     break;
                default:
                     break;
                    
            }
            $scope.fetchTransactions();
        }
        
        $scope.exportTransactions = (isAllTransactions) => {
            const jobRole = currentUser.organizationalTag.jobRole
            if(!jobRole.incentiveTransactionDownload){
                toaster.pop('error', 'Transaction', 'Not Authorized to download')
            }else{
                transactionService.downloadTransactions({userId: user._id, filterObj: $scope.filterObj, pagination: $scope.pagination, sortingObj: $scope.sortingObj}, isAllTransactions);
            }
        }

        init();
    });