'use strict';

angular.module('bitnudgeApp')
    .service('badgeCorner', function ($http, $q) {
		const self = this;

        this.getStarBadges = function(cycle){
            return $http.post('/api/userAnalytics/getStarBadges',{cycleId:cycle._id} ).then(function(starCycles){
                starCycles=starCycles.data;

				starCycles.forEach(function(cycle){
					cycle.badges = _.map(cycle.badges, function(badge) {
						badge.imageUrl = self.getImageUrl(badge.imageEnum);
						return badge;
					});
				});

                return $q.resolve(starCycles);
            });
        };

        this.getWeekBadges = function(monthlyCycle,kpi,selectedPlayer,jobRole){
			const postBody = {
				cycleId: monthlyCycle._id,
				userId: selectedPlayer._id,
				jobroleId: jobRole?jobRole:'null'
			}
            return $http.post('/api/userAnalytics/getWeeklyBadgesOfMonth', postBody).then(function(weeklyBadges){
                weeklyBadges=weeklyBadges.data;

                weeklyBadges.forEach(function(week){
                    week.badges = _.filter(week.badges, function (badge) {
                        if(kpi._id === 'overall'){
                            return !badge.from;
                        } else{
                            return badge.from && badge.from.toString() === kpi._id.toString();
                        }
                    });

					week.badges = _.map(week.badges, function(badge) {
						badge.imageUrl = self.getImageUrl(badge.imageEnum);
						return badge;
					});
                });
                return $q.resolve(weeklyBadges)
            });
        };

        this.getImageUrl = function(imageEnum) {
        	//console.log('Badge requested: ' + imageEnum);

			switch(imageEnum) {
				case 'Aggregate-Impact-First':
					return require('../images/badges/Aggregate-Impact-2.png');
				case 'Aggregate-Impact-Second':
					return require('../images/badges/Aggregate-Impact-2.png');
				case 'Aggregate-Impact-Third':
					return require('../images/badges/Aggregate-Impact-3.png');
				case 'Aggregate-Activity-Badge-First':
					return require('../images/badges/Aggregate-Activity-Badge-1.png');
				case 'Aggregate-Activity-Badge-Second':
					return require('../images/badges/Aggregate-Activity-Badge-2.png');
				case 'Aggregate-Activity-Badge-Third':
					return require('../images/badges/Aggregate-Activity-Badge-3.png');
				case 'lvl10':
					return require('../images/badges/lvl10.png');
				case 'lvl9':
					return require('../images/badges/lvl9.png');
				case 'lvl8':
					return require('../images/badges/lvl8.png');
				case 'lvl7':
					return require('../images/badges/lvl7.png');
				case 'lvl6':
					return require('../images/badges/lvl6.png');
				case 'lvl5':
					return require('../images/badges/lvl5.png');
				case 'lvl4':
					return require('../images/badges/lvl4.png');
				case 'lvl3':
					return require('../images/badges/lvl3.png');
				case 'red_flag_first':
					return require('../images/badges/red_flag_1.png');
				case 'red_flag_second':
					return require('../images/badges/red_flag_2.png');
				case 'red_flag_third':
					return require('../images/badges/red_flag_3.png');
				case 'ytd_best':
					return require('../images/badges/ytd_best.png');
				case 'Status-Badges-Platinum':
					return require('../images/badges/Status-Badges-Platinum.png');
				case 'Status-Badges-Gold':
					return require('../images/badges/Status-Badges-Gold.png');
				case 'Status-Badges-Silver':
					return require('../images/badges/Status-Badges-Silver.png');
				case 'KPI-Wise-Badge-First':
					return require('../images/badges/KPI-Wise-Badge-1.png');
				case 'KPI-Wise-Badge-Second':
					return require('../images/badges/KPI-Wise-Badge-2.png');
				case 'KPI-Wise-Badge-Third':
					return require('../images/badges/KPI-Wise-Badge-3.png');
				case 'decile4':
					return require('../images/badges/decile4.png');
				case 'decile3':
					return require('../images/badges/decile3.png');
				case 'decile2':
					return require('../images/badges/decile2.png');
				case 'productivity150':
					return require('../images/badges/productivity150.png');
				case 'productivity100':
					return require('../images/badges/productivity100.png');
				case 'productivity50':
					return require('../images/badges/productivity50.png');
				case 'activity1':
					return require('../images/badges/activity1.png');
				case 'activity10':
					return require('../images/badges/activity10.png');
				case 'activity25':
					return require('../images/badges/activity25.png');
				default:
					console.error('Badge not found', imageEnum);
					return '';
			}
		}
    });

