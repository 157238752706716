'use strict';
angular.module('bitnudgeApp')
    .controller('swotCardCtrl', function ($rootScope, $scope, $timeout){
        $scope.onItemClick = $scope.onClick;
        $scope.strings=$rootScope.strings;
        if($scope.showBgColor){
            $scope.swotCardBackground = 'swot-background-'+$scope.zone;
        }else $scope.swotCardBackground = '';
        $scope.$watch('list',function (newValue, oldValue) {
            if(newValue){
                $scope.listLoaded = false;
                $timeout(function(){ $scope.listLoaded = true;}, 0);
            }
        });
    });
