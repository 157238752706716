const autoImportNgTemplateLoaderTemplate1 = require('../html/customers/leadtableContainer.html');

'use strict';
angular.module('bitnudgeApp')
    .component('leadtableContainer', {
        templateUrl: autoImportNgTemplateLoaderTemplate1,
        bindings:{
            customer : "<"
        },
        controller: 'leadtableContainerController' 
    });
