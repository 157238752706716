"use strict";

angular.module("bitnudgeApp").controller("thumbnailComponentCtrl", function($scope, Auth) {
	$scope.getCurrentUser = Auth.getCurrentUser;
	$scope.noInfoPresent = true
	this.$onChanges = changes => {
		if (changes.thumbnail) {
			$scope.thumbnail = changes.thumbnail.currentValue;
        }
        if(changes.fileType){
			$scope.fileType = changes.fileType.currentValue
        }
	};
});
