'use strict';
angular.module('bitnudgeApp')
    .controller('heatmapCtrl', function($scope, $http, Auth, $location, $rootScope, $stateParams, $state, p0,heatmapService, CurrentCycle, ForecastMultiplier, Kpi, Accuracy, $filter) {
        $scope.config = p0.config;
        $scope.showUserWiseReport = false;
        $scope.showBranchWiseReport = false;
        var validActions = ['scorecard', 'pipeline', 'activities'];
        var accuracyActivities = ['accuracyp', 'accuracyp-r', 'accuracyp-i'];
        if($stateParams.selectedAction && validActions.indexOf($stateParams.selectedAction)!=-1){
            $scope.selectedAction = $stateParams.selectedAction;
        }else $scope.selectedAction = "scorecard";
        $scope.showOneMonth = false;
        $scope.targets  = ['Target','Plan'];
        $scope.currentPortfolio = [];
        var columnNamesList = [];
        var kpis =[];
        $scope.currentMonth = {active:true};
        $scope.me = Auth.getCurrentUser();
        $scope.selectedJobRole = $scope.me.organizationalTag.jobRole;
        $scope.targetToggle = false;
        function getUser(){
            let userId = $stateParams.userId;
            if(!userId || userId == 'undefined'){
                return;
            }
            $http.post('api/users/getUserById',{id:$stateParams.userId}).then(function (user) {
                user = user && user.data && user.data.data;
                $scope.currUser = user;
                $scope.selectedJobRole = user.organizationalTag.jobRole;
                setKpis(function(){
                    $scope.onCycleChange($scope.selectedCycle);
                });
            });
        }
        $scope.mainFilterTabWidth = 25;
        if(!$scope.config.lms && !$scope.config.portfolio){
            $scope.mainFilterTabWidth = 50;
        }else if(($scope.config.lms && !$scope.config.portfolio) || (!$scope.config.lms && $scope.config.portfolio)){
            $scope.mainFilterTabWidth = 33;
        }else if($scope.config.lms && $scope.config.portfolio){
            $scope.mainFilterTabWidth = 25;
        }

        var setKpis = function(callback){
            var myPlayTypes = _.cloneDeep(p0.myPlayTypes);
            var KpiList = [];
            myPlayTypes.forEach(function(playType){
                playType.kpis.forEach(function(kpi){
                    KpiList.push(kpi._id);
                })
            });
            if (p0.config.agentOnBoardingProductKpi) {
                $scope.kpis = _.cloneDeep(_.filter(p0.myKpis,function(goal){
                    return _.includes(KpiList,goal._id) && goal.kpiCode !== p0.config.agentOnBoardingProductKpi;
                }));
            } else {
                $scope.kpis = _.cloneDeep(_.filter(p0.myKpis,function(goal){
                    return _.includes(KpiList,goal._id);
                }));
            }
            if($scope.kpis.length > 1){
                $scope.kpis.unshift({_id:'overall', name:'Overall'});
            }
            kpis = _.cloneDeep($scope.kpis);
            $scope.selectedKpi =  $scope.kpis[0];
            return callback();
        };

        $scope.$watch('selectedThreshold',function(){
            if($scope.selectedThreshold) {
                if ($scope.users) {
                    $scope.calculateColors();
                }
            }
        });
        $scope.changeKpi = function(kpiId){
            $scope.selectedKpi = _.find($scope.kpis,{_id:kpiId});
            if($scope.selectedKpi){
                $scope.init();
            }
        };
        $scope.calculateColors = function(){
            _.each($scope.users,function(val){
                _.each(val.products,function(productVal,index){
                    var prodValue = productVal.prodValue;
                    if($scope.selectedAction == 'activities') {
                        var currActivity = $scope.activities[index];
                        var temp = _.cloneDeep($scope.currentAnalytic);
                        var arr = _.map(temp, function (user) {
                            var selectedActivity;
                            if ($scope.selectedKpi._id == 'overall') {
                                selectedActivity = _.find(user.activityData, function (activityDatum) {
                                    return activityDatum.activityId == currActivity._id && activityDatum.kpi == undefined;
                                });
                            } else {
                                selectedActivity = _.find(user.activityData, function (activityDatum) {
                                    return activityDatum.activityId == currActivity._id && activityDatum.kpi == $scope.selectedKpi._id.toString();
                                });
                            }
                            var value = selectedActivity.value.value;
                            if(selectedActivity.activityType == 'percentage'){
                                value = value * 100;
                            }
                            return value;
                        });
                        arr.sort();
                        if ($scope.thresholdDropDownValue == 'Average') {
                            $scope.selectedThreshold  = arr[Math.floor(arr.length / 2)];
                        } else if ($scope.thresholdDropDownValue == 'Top Decile') {
                            $scope.selectedThreshold  = arr[Math.floor(9 * arr.length / 10)];
                        } else if ($scope.thresholdDropDownValue == 'Bottom Decile') {
                            $scope.selectedThreshold  = arr[Math.floor(9 * arr.length / 10)];
                        }
                    }

                    var percentage = prodValue/ $scope.selectedThreshold * 100;
                    if(!productVal.clickable){
                        productVal.color = 'grey';
                    }else if(prodValue == 'NA'){
                        productVal.color = 'red';
                    } else if(percentage < 80){
                        productVal.color = 'red';
                    }else if(percentage < 100){
                        productVal.color = 'amber';
                    }else{
                        productVal.color = 'green';
                    }
                })
            });
        };
        function initAction() {
            //if ($scope.selectedAction == 'scorecard' || $scope.selectedAction == 'pipeline' || $scope.selectedAction == 'portfolio') {
            if ($scope.selectedAction == 'scorecard' || $scope.selectedAction == 'pipeline') {
                $scope.thresholds = ['Custom',20, 30, 40, 50, 60, 70, 80, 90, 100];
                $scope.selectedThreshold = 100;
                if($scope.selectedAction == 'scorecard'){
                    $scope.selectedTarget = $scope.targets[0];
                    $scope.isForecast = false;
                    $scope.isWeekly = false;
                }
            } else {
                $scope.thresholds = ['Bottom Decile', 'Average', 'Top Decile'];
                $scope.thresholdDropDownValue  = $scope.thresholds[1];
            }
            $scope.init();
        }
        $scope.init = function(){
            $scope.showRHS = false;
            $scope.showRHSGraphs = false;
            $scope.activityId = '';
            $scope.activities = _.filter(p0.allActivities,{swot:true});
            if($scope.selectedKpi._id != 'overall'){
                $scope.activities = _.filter($scope.activities,{'kpiSpecific':true});
            }
            $http.post('/api/userAnalytics/getUserAndChildAnalytics', {userId:$scope.currUser._id, cycleId: $scope.selectedCycle._id}).then(function (analytics) {
                analytics=analytics.data;
                $scope.currentAnalytics = analytics;
                if(p0.config.weeklyCycle){
                    $http.post('/api/userAnalytics/getUserAndChildAnalytics', {userId:$scope.currUser._id, cycleId: p0.currentWeeklyCycle._id}).then(function(weekAnalytics){
                        weekAnalytics=weekAnalytics.data;
                        $scope.currentWeekAnalytics = weekAnalytics;
                        generateUserAnalyticsData($scope.currUser);
                    });
                }else{
                    generateUserAnalyticsData($scope.currUser);
                }

            });
            $http.post('api/users/getParentTrail', {playerId: $scope.currUser._id}).then(function (user) {
                user=user.data;
                $scope.trailList = user;
            });
            if (!p0.config.leadUI_v1) {
                _.remove($scope.activities,{name:'Referrals'});
            }
        };

        //This function generate heatmap and all the click event on heatmap is written inside it.
        //Parameter: 1. times is list of product name, 2. days : list of all users, 3. gridsize: size of heatmap blocks, 4. userIdList  is list of user id, userId is current user id.
        $scope.generateHeatMap = function(columnNames, userNames,userImages, dataFile, gridsize, legendPos, userIds) {
            columnNamesList = angular.copy(columnNames);
            heatmapService.createHeatMapObject(columnNames, userNames,userImages, dataFile,userIds).then(function(data){
                $scope.users = data;
                _.each($scope.users,function(val){
                    _.each(val.products,function(productVal){
                        productVal.percentageCompleted = $scope.percentageInRange(productVal.prodValue);
                    })
                });
                $scope.calculateColors();
                $scope.sortFirstColumn();
                $scope.clickedOnUser($scope.users[0],$scope.users[0].products[0]);
            });
            $scope.productNames = heatmapService.createProductAbbrObj(columnNames);

        };

        $scope.sortFirstColumn = function(){
            var firstRow = $scope.users.shift();
            $scope.users = _.sortBy($scope.users,function(user){
                return -user.products[0].prodValue;
            });
            $scope.users.unshift(firstRow);
        };

        // Function to generate data for Heat map resetting heatmap and regenerating it based on calculated data.
        var generateUserAnalyticsData = function(myUser) {
            var currentUserAna = _.remove($scope.currentAnalytics,{userId:myUser._id});
            $scope.currentAnalytics.unshift(currentUserAna[0]);
            if ($scope.currentAnalytics.length > 0) {
                $("#chart").empty();
                var data = [];
                var userNames = _.map($scope.currentAnalytics,'name');
                var userImages = _.map($scope.currentAnalytics,'image');
                var userIds = _.map($scope.currentAnalytics,'userId');
                var activities = [];
                var kpiNames = [];
                if($scope.selectedAction == 'scorecard') {
                    if($scope.selectedTarget == 'Target') {
                        for (var i = 0; i < kpis.length; i++) {
                            if (i < kpis.length) {
                                kpiNames.push(kpis[i].name);
                            }
                            for (var j = 0; j < $scope.currentAnalytics.length; j++) {
                                if($scope.currentAnalytics[j]){
                                    var val = NaN;
                                    var clickable;
                                    if (kpis[i]._id != 'overall'){
                                        val = getKPIDataForUser(kpis[i].name, $scope.currentAnalytics[j]);
                                        var currentKPIData = _.find($scope.currentAnalytics[j].kpiData,function(kpiData){return kpiData.kpiId == kpis[i]._id});
                                        clickable = currentKPIData.target.value > 0 ? true:false;
                                    }else{
                                        val = Math.round($scope.currentAnalytics[j].percentageCompleted.value * ($scope.isForecast? ForecastMultiplier.ofCycle($scope.currentAnalytics[j].cycleId): 1));
                                        clickable = true;
                                    }
                                    var myDivisions = $scope.currentAnalytics[j].divisions;
                                    var isActiveDivision = _.find(p0.allDivisions,function(div){
                                        return _.includes(myDivisions,div._id.toString());
                                    });
                                    isActiveDivision = isActiveDivision?true:false;
                                    data.push({
                                        "userIndex": j + 1,   //user
                                        "productIndex": i + 1,  //product
                                        "value": val,
                                        "clickable":clickable,
                                        "activeDivision":isActiveDivision
                                    })
                                }
                            }
                        }
                    }else{
                        var forecastMultiplier;
                        if($scope.isWeekly){
                            forecastMultiplier = $rootScope.weeklyForecastMultiplier;
                        }else{
                            forecastMultiplier = ForecastMultiplier.ofCycle($scope.selectedCycle._id);;
                        }

                        for (var i = 0; i < $scope.kpis.length; i++) {
                            if (i < $scope.kpis.length) {
                                kpiNames.push($scope.kpis[i].name);
                            }
                            for (var j = 0; j < $scope.currentAnalytics.length; j++) {
                                if($scope.currentAnalytics[j]){
                                    var val = NaN;
                                    var clickable;
                                    if ($scope.kpis[i]._id != 'overall') {
                                        var selectedKpi = _.find($scope.currentAnalytics[j].kpiData,function(kpi){return kpi.kpiId.toString() == $scope.kpis[i]._id.toString()});
                                        val = selectedKpi.gameplanTarget.value? selectedKpi.gameplanPercent.value * ($scope.isForecast?forecastMultiplier:1): NaN;
                                        clickable = selectedKpi.target.value > 0 ? true:false;
                                    }else{
                                        val = $scope.currentAnalytics[j].gameplanPercent? ($scope.currentAnalytics[j].gameplanPercent.value * ($scope.isForecast?forecastMultiplier:1)):0;
                                        clickable = true;
                                    }
                                    var myDivisions = $scope.currentAnalytics[j].divisions;
                                    var isActiveDivision = _.find(p0.allDivisions,function(div){
                                        return _.includes(myDivisions,div._id.toString());
                                    });
                                    isActiveDivision = isActiveDivision?true:false;
                                    data.push({
                                        "userIndex": j + 1,   //user
                                        "productIndex": i + 1,  //product
                                        "value": val,
                                        "clickable":clickable,
                                        "activeDivision":isActiveDivision
                                    })
                                }
                            }
                        }
                    }
                    $scope.generateHeatMap(kpiNames, userNames,userImages, data, 32, 1200, userIds);
                }else if($scope.selectedAction == 'activities'){
                    $scope.activities.forEach(function(activity,activityIndex){
                        $scope.currentAnalytics.forEach(function (user,userIndex) {
                            var selectedActivity;
                            if(user){
                                if($scope.selectedKpi._id == 'overall') {
                                    selectedActivity = _.find(user.activityData, function (activityDatum) {
                                        return activityDatum.activityId == activity._id && activityDatum.kpi == undefined && activityDatum.swotFlag;
                                    });
                                }else{
                                    selectedActivity = _.find(user.activityData, function (activityDatum) {
                                        return activityDatum.activityId.toString() == activity._id.toString() && activityDatum.kpiId && activityDatum.kpiId.toString() == $scope.selectedKpi._id.toString() && activityDatum.swotFlag;
                                    });
                                }
                            }
                            if(selectedActivity){
                                if(activities.indexOf(activity.name) == -1) activities.push(activity.name);
                                var myDivisions = user.divisions;
                                var isActiveDivision = _.find(p0.allDivisions,function(div){
                                    return _.includes(myDivisions,div._id.toString());
                                });
                                var value = selectedActivity.value.value;
                                if(selectedActivity.activityType == 'percentage'){
                                    value = value * 100;
                                }
                                isActiveDivision = isActiveDivision?true:false;
                                var activityObject = {
                                    "userIndex": userIndex + 1,             //user
                                    "productIndex": activities.indexOf(activity.name) + 1,      //product
                                    "value": value,
                                    'clickable':true,
                                    "activeDivision":isActiveDivision,
                                };
                                if(accuracyActivities.indexOf(activity.fieldName) > -1){
                                    var kpiId = $scope.selectedKpi._id;
                                    if(kpiId == 'overall') kpiId = null;
                                    var errCount = Accuracy.getErrorCount(activity.fieldName, user, kpiId);
                                    activityObject.toolTip = "Error count " + $filter('number')(errCount, 0);;
                                }
                                data.push(activityObject);
                            }
                        });
                    });
                    $scope.generateHeatMap(activities, userNames,userImages, data, 32, 1200, userIds);
                }else if($scope.selectedAction == 'pipeline'){
                    for (var i = 0; i < $scope.kpis.length; i++) {
                        if (i < $scope.kpis.length) {
                            kpiNames.push($scope.kpis[i].name);
                        }
                        for (var j = 0; j < $scope.currentAnalytics.length; j++) {
                            if($scope.currentAnalytics[j]){
                                var val;
                                var clickable;
                                if($scope.kpis[i]._id == 'overall'){
                                    val = $scope.currentAnalytics[j].pipelinePercentage.value;
                                    clickable = true;
                                }else{
                                    var selectedKpi = _.find($scope.currentAnalytics[j].kpiData,function(kpiData){return kpiData.kpiId.toString() == $scope.kpis[i]._id.toString()});
                                    val = selectedKpi.pipelinePercentage && selectedKpi.pipelinePercentage.value;
                                    clickable = selectedKpi.target.value > 0 ? true:false;;
                                }
                                var myDivisions = $scope.currentAnalytics[j].divisions;
                                var isActiveDivision = _.find(p0.allDivisions,function(div){
                                    return _.includes(myDivisions,div._id.toString());
                                });
                                isActiveDivision = isActiveDivision?true:false;
                                data.push({
                                    "userIndex": j + 1,   //user
                                    "productIndex": i + 1,  //product
                                    "value": val,
                                    'clickable':clickable,
                                    "activeDivision":isActiveDivision
                                });
                            }
                        }
                    }
                    $scope.generateHeatMap(kpiNames, userNames,userImages, data, 32, 1200, userIds);
                }else if($scope.selectedAction == 'portfolio'){
                    for (var i = 0; i < $scope.kpis.length; i++) {
                        kpis.push($scope.kpis[i].name);
                        data.push({
                            'userIndex':1,
                            'productIndex':i+1,
                            'value':getRandomValue()
                        });
                    }
                    kpis = ['Volume','Yield','RoRWA','Cross sell','# of Customers','Quality'];
                    data = data.slice(0, data.length - 2);
                    $scope.generateHeatMap(kpis, userNames, userImages,data, 32, 1200, userIds);
                }
            } else {
                $("#chart").empty();
                $("#chart").append('<h4 class="blue center smaller">No user in this branch</h4>')
            }
        };

        // Function to change action
        $scope.changeAction = function(action, $event) {
            $scope.selectedAction = action;
            initAction();
        };

        // mixin for unserscore js
        _.mixin({
            sum: function(arr) {
                return _.reduce(arr, function(s, x) {
                    return s + x;
                }, 0);
            },
            avg: function(arr) {
                return _.sum(arr) / arr.length;
            }
        });

        //Function to return value to Heatmap
        var getKPIDataForUser = function(productId, currentUserAnalytic) {
            var result = getKPIWiseData(currentUserAnalytic);
            var val = _.find(result, function(p) {
                return p.name === productId;
            });
            if (typeof val === 'undefined') {
                return 0;
            } else {
                return parseInt(100 * val.forecast / val.target.value);
            }
        };

        function getKPIWiseData(currentUserAnalytic) {
            if(!currentUserAnalytic){
                return []
            }
            var KPIData = currentUserAnalytic.kpiData;
            for (var i = 0; i < KPIData.length; i++) {
                KPIData[i].forecast= KPIData[i].achievedValue.value * ($scope.isForecast?ForecastMultiplier.ofCycle(currentUserAnalytic.cycleId):1);
            }
            return KPIData;
        }

        $scope.$watch('[isForecast,isWeekly]',function(){
            if($scope.currUser && $scope.currentAnalytics) {
                generateUserAnalyticsData($scope.currUser);
            }
        });

        $scope.drillDownClicked = function(userId){
            $state.go('lms.heatmap',{userId:userId, selectedAction: $scope.selectedAction, cycleId:$scope.selectedCycle._id});
        };

        $scope.percentageInRange = function(values){
            var value = -1;
            if(!isNaN(values)){
                value = values/$scope.selectedThreshold*100;
            }
            return value;
        };

        $scope.clickedOnUser = function(user,metric){
            if(!metric){
                return;
            }
            var userId = user.id;
            $scope.selectedUserId = userId;
            if($scope.selectedUserId == $scope.currUser._id)
                $scope.isReportee = false;
            else
                $scope.isReportee = true;

            if($scope.selectedAction == 'scorecard' || $scope.selectedAction == 'pipeline') {
                var kpisList = $scope.kpis;
                if($scope.selectedAction == 'scorecard') kpisList = kpis;
                $scope.selectedKpi = _.find(kpisList, function (p,index) {
                    return index+1 === metric.productIndex;
                });
                $scope.kpiId = $scope.selectedKpi._id;
                $scope.showRHS = true;
            }else if($scope.selectedAction == 'activities'){
                var product = columnNamesList[metric.productIndex-1];
                $scope.activityId = _.find($scope.activities,{name:product})._id;
                $scope.activityName = product;
                $scope.kpiId = $scope.selectedKpi._id;
                $scope.showRHS = true;
            }else{
                if(!$scope.isReportee){
                    var portfolioMetric = columnNamesList[metric.productIndex-1];
                    $scope.currentPortfolio = portfolioMetric;
                    $scope.selectedKpi = {name:'portfolio',_id:'portfolio'};
                    $scope.kpiId = 'portfolio';
                    $scope.showRHSGraphs = false;
                    setTimeout(function(){
                        $scope.showRHSGraphs = true;
                        $scope.$apply();
                    }, 100);
                }else{
                    var product = columnNamesList[metric.productIndex-1];
                    $scope.activityId = _.find($scope.activities,{name:product})._id;
                    $scope.activityName = product;
                    $scope.kpiId = $scope.selectedKpi._id;
                    $scope.showRHS = true;
                }

            }
            setActive(metric);
            getUserObj(userId);
        };

        function getUserObj(userId){
            if(!userId || userId == 'undefined'){
                return;
            }
            $http.post('/api/users/getUserById',{id:userId}).then(function(user) {
                user = user && user.data && user.data.data;
                if (user.systemRole == 'frontline' && !$rootScope.isFrontline && $scope.config.showFLAchievedInHMap)
                    $scope.showAchieved = true;
                else
                    $scope.showAchieved = false;
            });
        }

        function setActive(product) {
            _.each($scope.users,function(val){
                _.each(val.products,function(productVal){
                    productVal.active = false;
                })
            });
            if(product) product.active = true;
        }

        $scope.changeTarget = function(){
            if($scope.targetToggle){
                $scope.selectedTarget = "Plan";
            }else{
                $scope.selectedTarget = "Target";
            }
            $scope.init();
        };

        $scope.gotoHeatmap = function(playerId){
            $state.go('lms.heatmap',{userId:playerId, selectedAction: $scope.selectedAction, cycleId:$scope.selectedCycle._id});
        };

        $scope.onCycleChange = function (cycle) {
            if(!$scope.currUser)return;
            $scope.selectedCycle = cycle;
            $scope.currentMonth.active = !isPastCycle(cycle);
            setKpis(function(){
                initAction();
                CurrentCycle.setCycle(cycle);
            });
        };

        function isPastCycle(cycle){
            if($rootScope.lastUpdatedAtDate.getTime() >= new Date(cycle.startDate).getTime()  && $rootScope.lastUpdatedAtDate.getTime()  < new Date(cycle.endDate).getTime() ){
                return false;
            }else if($rootScope.lastUpdatedAtDate.getTime()  > new Date(cycle.startDate).getTime() ){
                return true;
            }else return false;
        }

        function getRandomValue(){
            return Math.floor((Math.random() * 100) + 1);
        }
        getUser();
    });


