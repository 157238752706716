'use strict';

angular.module('bitnudgeApp').controller('alertAdvancedFiltersController', function($scope, $mdDialog, p0, advancedFilters, selectedFilter, callback) {

	$scope.timeRanges = [];
	$scope.selected = {};
	$scope.params = {};
	if(advancedFilters && advancedFilters.length){
		$scope.timeRanges = advancedFilters;
		if(selectedFilter){
			$scope.selected.timeRange = $scope.timeRanges.find(r => r._id === selectedFilter._id);
		}else{
			$scope.selected.timeRange = $scope.timeRanges[0];
		}
	}
	if(selectedFilter){
		$scope.params.startDate = new Date(selectedFilter.startDate);
		$scope.params.endDate = new Date(selectedFilter.endDate);

	} else if(p0.currentMonthlyCycle){
		$scope.params.startDate = new Date(p0.currentMonthlyCycle.startDate);
		$scope.params.endDate = new Date(p0.currentMonthlyCycle.endDate);
	}else{
		// By default last 7 days
		const start = new Date();
		$scope.params.startDate = new Date(start.setDate(start.getDate() - 7));
		$scope.params.endDate = new Date();
	}
	$scope.removeFilter = function(filter) {
		$scope.selected.timeRange = $scope.timeRanges.find(range => range._id  === 'all');
	};

	$scope.timeRangeChange = function(newTimeRange){};

	$scope.applyFilter = function(){
		if(callback){
			if($scope.selected.timeRange._id === 'custom'){
				$scope.selected.timeRange.startDate = new Date($scope.params.startDate).getTime();
				$scope.selected.timeRange.endDate = new Date($scope.params.endDate).getTime();
			}
			callback($scope.selected.timeRange);
		}
		$mdDialog.hide();
	};

	$scope.startDateChange = function(d){
		$scope.params.startDate = d;
	};

	$scope.endDateChange = function(d){
		$scope.params.endDate = d;
	};

	$scope.onClose = function() {
		callback($scope.selected.timeRange);
		$mdDialog.hide();
	};

});
