'use strict';

angular.module('bitnudgeApp')
  .controller('NotificationCtrl', function ($scope, $http,toaster) {
    $scope.currentPage = 1;
    $scope.selectPage = function(pageNumber){
        $scope.currentPage = pageNumber;
        fetchNotificationConfig();
    }
    
    $scope.noPrevious = function() {
        return $scope.currentPage === 1;
    };
    $scope.noNext = function() {
        return $scope.currentPage === $scope.numPages;
    };
    $scope.selectPrevious = function() {
        if ( !$scope.noPrevious() ) {
            $scope.currentPage -= 1;
            fetchNotificationConfig();
        }
    };
    $scope.selectNext = function() {
        if ( !$scope.noNext() ) {
            $scope.currentPage += 1;
            fetchNotificationConfig();
        }
    };
    $scope.isActive = function(page) {
        return $scope.currentPage === page;
      };
    $scope.saveChanges = function(notificationId,notificationtype,value){
        $http.post('/api/adminActions/updateNotificationConfig?notificationId='+notificationId + "&type=" + notificationtype + "&value=" + value).then(function(data){
            if(data.data.status){
               toaster.pop('success','Success','Successfully updated');
            }else{
               toaster.pop('warning','Error',data.data.errors[0].message);
            }
        })  
    }
    function fetchNotificationConfig() {
        $http.post('/api/adminActions/getNotificationConfig', {limit: 15, skip: $scope.currentPage}).then(function (data) {
                if(data.data.status){
                    $scope.list = [];
                    if($scope.list.length == 0){
                        $scope.list = data.data.data.notificationConfig;
                        $scope.numPages = data.data.data.pages;
                        $scope.pageCount = [];
                        for(var i = 1;i <= $scope.numPages; i++ ){
                            $scope.pageCount.push(i);
                        }
                    }
                }else{
                    toaster.pop('warning','Error in getting config'.data.data.errors[0].message);
                }
            });
        };
    fetchNotificationConfig();
  });
