'use strict';
angular.module('bitnudgeApp')
    .service('CurrentCycle', function (Auth, p0, $rootScope) {
        var _this = this;
        this.cycle = p0.currentMonthlyCycle;
        this.setCycle = function (cycle) {
            _this.cycle = cycle;
            if(cycle.cycleType =='monthly' && $rootScope.isSeniorManager){
                setForecastMultiplier(cycle);
            }
        };
        var setForecastMultiplier = function(cycle){
            var lastDate = new Date(p0.lastUpdatedAt);
            if(lastDate < new Date(cycle.startDate))lastDate = new Date(cycle.startDate);
            if(lastDate > new Date(cycle.endDate))lastDate = new Date(cycle.endDate);
            $rootScope.daysTotal = $rootScope.totalBD = $rootScope.calcBusinessDays((new Date(cycle.startDate)), (new Date(cycle.endDate)));
            var daysLeft = $rootScope.calcBusinessDays(lastDate, new Date(cycle.endDate)) - 1;
            if (daysLeft < 0 || !daysLeft) {
                if(!cycle.active){
                    daysLeft = 0;
                }else{
                    daysLeft = 1;
                }
            }
            $rootScope.daysLeft = daysLeft;
            $rootScope.daysPassed = $rootScope.daysTotal - $rootScope.daysLeft;
            $rootScope.daysLeftPercentage = $rootScope.perDayLeft = 100 - parseInt(($rootScope.daysLeft / $rootScope.daysTotal) * 100);
            //$rootScope.forecastMultiplier = ($rootScope.daysPassed == 0 ? NaN : $rootScope.daysTotal / $rootScope.daysPassed);
        };
    });

