angular.module('bitnudgeApp')
    .controller('addL3ProductsCtrl', function ($http, $scope, Auth, Upload, $location, toaster, Sidebar, UtilService) {
        $scope.fileType = "l3ProductsFile"
        $scope.onFileSelectL3Products = function () {
            if ($scope.l3Products == undefined) {
                toaster.pop('error', "Submission.", "Select File to Upload.");
            } else {
                const fileName = $scope.l3Products[0].name.split(".");
                const fileType = fileName[fileName.length - 1];
                if (fileType == "xls" || fileType == "xlsx") {
                    Upload.upload({
                        url: '/api/upload',
                        data: {userId: Auth.getCurrentUser()._id,cronTag:'l3ProductsUpload',invocationMethod:"manual"},
                        file: $scope.l3Products[0],
                        progress: function (e) {
                        }
                    }).then(function (data, status, headers, config) {
                        // file is uploaded successfully
                        toaster.pop('success', "Excel uploaded", "l3ProductsFile upload successfully finished.");
                    });
                } else {
                    toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' Types Only.");
                }
            }
        }
    });
