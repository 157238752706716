'use strict';
angular.module('bitnudgeApp')
    .service('WorkflowService', function ($http, $q) {

        var flattenSelectInputs = function (prerequisites) {
            var prerequisitesFormatted = [];
            prerequisites.forEach(function (prerequisite) {
                var formattedPrereq = {};
                formattedPrereq.value = prerequisite.value;
                formattedPrereq.name = prerequisite.name;
                formattedPrereq.type = prerequisite.type;
                formattedPrereq.typeParam = prerequisite.typeParam;
                if(prerequisite.type === 'date'){
                    formattedPrereq.value = new Date(prerequisite.value).getTime();
                }
                prerequisitesFormatted.push(formattedPrereq);
            });
            return prerequisitesFormatted;
        };

        this.createWorkflowObject = function (url, workflow) {
            workflow.prerequisites = flattenSelectInputs(workflow.prerequisites);
            return $http.post(url,workflow);
        };

        this.getNextStates = function (url, postBody) {
            return $http.post(url, postBody);
        };

        this.gotoState = function (url, workflow) {
            workflow.prerequisites = flattenSelectInputs(workflow.prerequisites);
            return $http.post(url,workflow);
        };

        this.getHistory = function(url){
            return $http.post(url);
        };

        this.getAllWorkflowDefinition = function(type){
            var deferred = $q.defer();
            $http.post('/api/workflows/getAllWorkflowDefinitions',{type}).then(function(response){
                if(response.data.status)
                    deferred.resolve(response.data.data);
                else
                    deferred.reject(response.data.errors[0])
            });
            return deferred.promise;
        };

        this.addPrerequisite = function (payload) {
			return $http.post('/api/workflows/addPrerequisite', payload);
		};

		this.removePrerequisite = function (workflowObjectId, id, referenceTicketId) {
			const postPayload = { workflowObjectId, id , referenceTicketId};
			return $http.post(`/api/workflows/removePrerequisite`, postPayload);
		};

		this.initDocumentService = function({ leadId, ticketId, cui, docType }){
			var deferred = $q.defer();
			//TODO: DOCUMENT SERVICE INTEGRATION
			setTimeout(() => {
				return deferred.resolve({value:'<DOCUMENTID>'});
			}, 3000);
			return deferred.promise;
		}
    });
