const autoImportNgTemplateLoaderTemplate1 = require('../html/swotWithValue.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('swotWithValue', function () {
        return {
            name: 'swotWithValue',
            scope: {
                zone: '@',
                value: '@'
            },
            controller: 'swotWithValueCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
