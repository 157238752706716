const autoImportNgTemplateLoaderTemplate1 = require('../html/rhsCard.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('rhsCard', function(){
        return {
            name: 'rhsCard',
            scope: {
                data:       '=',
                type:       '@',
                drillDown:  '='
            },
            controller: 'rhsCardCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
