'use strict';
angular.module('bitnudgeApp')
    .controller('contactsCtrl',function($http,$scope,Auth,DTOptionsBuilder, DTColumnDefBuilder,$rootScope,$state,p0) {
        $scope.currrentUser = Auth.getCurrentUser();
        var allCustomers;
        $scope.strings = p0.strings;
        $http.post('/api/customers/getCustomersByUser', {userId: $scope.currentUser._id}).then(function(customers){
            customers=customers.data;
            $scope.customers = customers;
            $scope.customers.forEach(function(customer){
                var length = customer.histories.length;
                if(length == 0){
                    customer.interactionHistory = "";
                }else if(length == 1){
                    customer.interactionHistory = customer.histories[0].interactionType ? customer.histories[0].interactionType : "";
                }else{
                    customer.interactionHistory = (customer.histories[length - 1].interactionType ? customer.histories[length - 1].interactionType: "") + (customer.histories[length - 2].interactionType ? (' - ' + customer.histories[length - 2].interactionType) : "");
                }
                if(length != 0){
                    customer.firstInteraction = customer.histories[0].updateDate;
                    customer.lastInteraction = customer.histories[length - 1].updateDate;
                }
                customer.handlers = _.map(customer.frontline,'name');
                customer.handlerJobroles  = _.map(customer.frontline, 'organizationalTag.jobRole');
                customer.handlers = customer.handlers.toString();
            });
            allCustomers = $scope.customers;
        });

        $scope.frontLineJobRoles = p0.myFrontlineRoles;
        $scope.frontLineJobRoles.unshift({name:'All',_id:''});
        $scope.selectedJobRole = {jobRole:$scope.frontLineJobRoles[0]};

        $scope.dataTableOptions = DTOptionsBuilder.newOptions().withPaginationType('full_numbers').withOption('order', [0, 'asc'])               // sort DESC by lead Creation date
            .withDisplayLength(25)
            .withDOM('fitp');

        $scope.dataTableColumns = [
            DTColumnDefBuilder.newColumnDef(1).notSortable(),
            DTColumnDefBuilder.newColumnDef(2).notSortable(),
            DTColumnDefBuilder.newColumnDef(3).notSortable(),
            DTColumnDefBuilder.newColumnDef(4).notSortable(),
            DTColumnDefBuilder.newColumnDef(5).notSortable(),
            DTColumnDefBuilder.newColumnDef(6).notSortable(),
            DTColumnDefBuilder.newColumnDef(7).notSortable(),
            DTColumnDefBuilder.newColumnDef(8).notSortable(),
            DTColumnDefBuilder.newColumnDef(9).notSortable()
        ];

        $scope.editLead = function(customer){
            const params = {customerId:customer._id, userId:$scope.currentUser._id};
            if(!$rootScope.isFrontline){
                params.userId = customer.frontline[0]._id
            }
            $state.go(p0.config.leadUI_v1 ? 'userFlow.editLead_v1' : 'userFlow.editLead_v2', params);
        }
        $scope.changeFrontlineRole = function(){
            if($scope.selectedJobRole.jobRole._id == ''){
                $scope.customers = allCustomers;
            }
            else {
                $scope.customers = _.filter(allCustomers, function (customer) {
                    var jobrole = _.find(customer.handlerJobroles, function (jobrole) {
                        return jobrole.toString() == $scope.selectedJobRole.jobRole._id.toString();
                    });
                    if (jobrole) {
                        return true;
                    } else {
                        return false;
                    }
                });
            }
        }
    })
