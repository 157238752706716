"use strict";

angular.module("bitnudgeApp").controller("RejectDocCtrl", function($scope, $mdDialog) {
	$scope.cancel = () => {
		$mdDialog.cancel();
	};
	$scope.showTextarea = false;
	$scope.reasons = [
		{ title: "Document quality is bad", selected: false, id: "requireDocument" },
		{ title: "Some other reason", selected: false, id: "otherReason" }
	];
	$scope.comment = "";
	$scope.saveReason = () => {
		let rejectionReason = {};
		if ($scope.comment.length) {
			rejectionReason.comment = $scope.comment;
		}
		rejectionReason.reason = $scope.reasons.find(reason => reason.selected);
		$mdDialog.hide(rejectionReason);
	};

	$scope.checkBoxChange = (i) => {
		$scope.reasons.forEach( reason => reason.selected = false);
		$scope.reasons[i].selected = true;
		$scope.reasonModalSelected = true
		if($scope.reasons[i].id ==='otherReason'){
			$scope.showTextarea = true
			$scope.reasonModalSelected = $scope.comment.length ? true : false
		}else{
			$scope.showTextarea = false
			$scope.reasonModalSelected = true
		}
	}
	$scope.textChange = (text) => {
		if(text.length){
			$scope.reasonModalSelected = true
		}else{
			$scope.reasonModalSelected = false
		}
	}
});
