'use strict';
angular.module('bitnudgeApp')
    .service('analytics', function ($http, $q, $rootScope, SwotService, Auth, ForecastMultiplier) {
        var _kpiGraphAnalyticsData = {};
        var _benchmarkData = {};
        var _activityGameAnalyticsData = {};
        var _activityBenchmarkData = {};

        var generateGameAnalyticsKpiGraphs = function (userId, startDate, endDate) {
            var deferred = $q.defer();
            if (!_kpiGraphAnalyticsData[userId][startDate][endDate]) {

                $http.post('api/customReports/kpiAnalytics', {userId, startDate, endDate})
                    .then(function (result) {

                        _kpiGraphAnalyticsData[userId][startDate][endDate] = result.data;
                        deferred.resolve(_kpiGraphAnalyticsData[userId][startDate][endDate]);

                    }, function (error) {

                        _kpiGraphAnalyticsData[userId][startDate][endDate] = null;
                        deferred.reject(error);
                    });
                _kpiGraphAnalyticsData[userId][startDate][endDate] = deferred.promise;
            }
            return $q.when(_kpiGraphAnalyticsData[userId][startDate][endDate]);

        }

        var getBenchmarkData = function (userId, startDate, endDate) {
            if (!_benchmarkData[userId][startDate][endDate]) {
                var deferred = $q.defer();
                $http.post('api/customReports/kpiBenchmarks', {userId, startDate, endDate})
                    .then(function (result) {
                        _benchmarkData[userId][startDate][endDate] = result.data;
                        deferred.resolve(_benchmarkData[userId][startDate][endDate]);
                    }, function (error) {
                        _benchmarkData[userId][startDate][endDate] = null;
                        deferred.reject(error);
                    });
                _benchmarkData[userId][startDate][endDate] = deferred.promise;
            }
            return $q.when(_benchmarkData[userId][startDate][endDate]);
        }

        this.getUsersWithIds = function (userIds, playerType, callback) {
            var subsetId = playerType._id.toString();
            
            return $http.post('api/users', {select: ["name", "teams", "organizationalTag"], "jobRoleId": subsetId}).then(function (users) {
                users = users.data.data;
                users = _.filter(users, function (user) { 
                    return _.includes(userIds, user._id.toString()) 
                });
                return $q.resolve(users);
            });
        };

        this.getPlayerComparisonData = function (player, cycleId, type, kpiId, activityId, league) {
            const postData = {
                cycleId,
                players: [player._id]
            }
            return $http.post('api/analytics/comparison/users', postData).then(function (playersData) {
                playersData = playersData.data;
                if (!playersData || !playersData.length) {
                    return;
                }
                var playerData = playersData[0];
                const postData = {
                    cycleId,
                    league: 'Bank',
                    metric: 'avg',
                    userId: player._id
                }
                return $http.post('api/analytics/user/mySWOT',  postData).then(function (swotData) {
                    swotData = swotData.data;

                    return getPlayerActivitySwot(cycleId, player._id).then(function (orgs) {

                        let userId = orgs[0];
                        var kpiIndepActivities = orgs[1];
                        var allActivities = orgs[2];
                        playerData.activitySwot = kpiIndepActivities;

                        if (swotData && swotData.users && swotData.users.kpiData) {
                            var oppSwotAnalysis = {
                                Strength: [], Weakness: [], Opportunity: [], Threat: [], Unknown: []
                            };
                            swotData.users.kpiData.forEach(function (kpi) {
                                var forecastMultiplier = kpi.kpiId.kpiType == 'percentage' ? 1 : ForecastMultiplier.ofCycle(cycleId);
                                var leagueKPIDataPercentageCompleted = _.find(swotData.league.kpiData, { kpiId: kpi.kpiId._id }).percentageCompleted;
                                kpi.percentageCompleted.value = forecastMultiplier * kpi.percentageCompleted.value;
                                kpi.topDecilePercentageCompleted = leagueKPIDataPercentageCompleted.topDecile;
                                kpi.bottomDecilePercentageCompleted = leagueKPIDataPercentageCompleted.bottomDecile;
                                kpi.swotThreshold = leagueKPIDataPercentageCompleted.avg;
                                kpi.forecastedAchievement = forecastMultiplier * kpi.percentageCompleted.value;
                                const swtstring = SwotService.getSWOTString(kpi.swot);
                                if(swtstring)oppSwotAnalysis[swtstring].push(kpi);
                            });
                            playerData.kpiSwot = oppSwotAnalysis;
                            playerData.showActivityPoints = true;
                            playerData.showImpactPoints = true;
                            playerData.pointsText = 'Overall';
                            playerData.showActivityModal = false;
                            playerData.league = league;
                            if (playerData.league)
                                playerData.leagueName = _.find(player.teams, { teamType: league }).teamTypeName;
                            switch (type) {
                                case "total":
                                    playerData.swot = playerData.kpiSwot;
                                    playerData.badgeCount = swotData.users.badges.length;
                                    $http.post("/api/rollingBadges/users", {userId: player._id, cycleId }).then(function (result) {
                                        playerData.badgeCount += result.data.length;
                                    }, function (error) {
                                        console.log(error);
                                    });
                                    const globalrank = _.find(playerData.overallPoints.ranks, function (obj) { return (obj.teamType == playerData.league) })
                                    playerData.globalRank = globalrank? globalrank.value : null;
                                    break;
                                case "business":
                                    playerData.swot = playerData.kpiSwot;
                                    playerData.badgeCount = _.filter(swotData.users.badges, function (badge) {
                                        return badge.badgeType == 'overallBusiness';
                                    }).length;
                                    playerData.globalRank = _.find(playerData.businessPoints.ranks, function (obj) { return (obj.teamType == playerData.league) }).value;
                                    //playerData.businessPoints = playerData.businessPoints.value;
                                    break;
                                case "activity":
                                    if (kpiId == 'overall' && activityId == 'overall') {
                                        playerData.swot = playerData.activitySwot;
                                        playerData.showActivityModal = true;
                                        playerData.badgeCount = _.filter(swotData.users.badges, function (badge) {
                                            return badge.badgeType == 'overallActivity';
                                        }).length;
                                        playerData.globalRank = _.find(playerData.activityPoints.ranks, function (obj) { return (obj.teamType == playerData.league); }).value;
                                        //playerData.activityPoints = playerData.activityPoints.value;
                                    }
                                    else if (activityId != 'overall' && kpiId == 'overall') {
                                        var validActivity = _.find(allActivities.users.activityData, function (obj) { if (!obj.kpiId) return obj.activityId._id.toString() == activityId.toString() })
                                        playerData.totalPoints = validActivity.points.value;
                                        playerData.globalRank = _.find(validActivity.points.ranks, function (obj) { return (obj.teamType == playerData.league); }).value;
                                        playerData.pointsText = 'Activity';
                                        playerData.swotType = SwotService.getSWOTSymbol(validActivity.swot);
                                        playerData.swot = playerData.activitySwot;
                                        playerData.showActivityModal = true;
                                        playerData.badgeCount = _.filter(swotData.users.badges, function (badge) {
                                            return badge.badgeType == 'activity' && badge.from.toString() == activityId.toString();
                                        }).length;
                                    }
                                    else if (activityId != 'overall' && kpiId != 'overall') {
                                        var validActivity = _.find(allActivities.users.activityData, function (obj) {
                                            if (!obj.kpiId) { return false; }
                                            else { return obj.kpiId.toString() == kpiId.toString() && obj.activityId._id.toString() == activityId.toString() }
                                        });
                                        playerData.totalPoints = validActivity.points.value;
                                        playerData.globalRank = _.find(validActivity.points.ranks, function (obj) { return (obj.teamType == playerData.league); }).value;
                                        playerData.pointsText = 'Activity';
                                        playerData.swotType = SwotService.getSWOTSymbol(validActivity.swot);
                                        playerData.swot = playerData.activitySwot;
                                        playerData.showActivityModal = true;
                                        playerData.badgeCount = _.filter(swotData.users.badges, function (badge) {
                                            return badge.badgeType == 'activity' && badge.from.toString() == activityId.toString();
                                        }).length;
                                    }
                                    break;
                                case "badge":
                                    playerData.swot = playerData.kpiSwot; break;
                                case "kpi":
                                    var validKPI = _.find(swotData.users.kpiData, function (obj) { return obj.kpiId._id.toString() == kpiId.toString() });
                                    playerData.totalPoints = validKPI.points.value + playerData.activityPoints.value;
                                    playerData.globalRank = _.find(validKPI.points.ranks, function (obj) { return (obj.teamType == playerData.league); }).value;
                                    playerData.pointsText = 'Overall';
                                    playerData.swotType = validKPI.swot;
                                    playerData.activitySwot = getActivitiesOfKPI(allActivities, kpiId, cycleId);
                                    playerData.swot = playerData.activitySwot;
                                    playerData.badges = _.filter(swotData.users.badges, function (badge) {
                                        return ((badge.badgeType == 'kpi' && (badge.badgeCategory == 'business' || badge.badgeCategory == 'conversion'))
                                            || (badge.badgeType == 'productivity' && badge.badgeCategory == 'productivity')
                                            || (badge.badgeType == 'redFlag' && badge.badgeCategory == 'redFlag'))
                                            && badge.from.toString() == kpiId.toString();
                                    });
                                    playerData.badgeCount = _.uniqBy(playerData.badges, function (badge) { return badge._id; }).length;
                                    break;
                                default:
                            }
                        }
                        return playerData;
                    });
                });
            });
        };

        this.generateGameAnalyticsKpiGraphs = function (userId, startDate, endDate) {

            if (!_kpiGraphAnalyticsData[userId]) _kpiGraphAnalyticsData[userId] = {};
            if (!_kpiGraphAnalyticsData[userId][startDate]) _kpiGraphAnalyticsData[userId][startDate] = {};

            return generateGameAnalyticsKpiGraphs(userId, startDate, endDate)
        };

        var activityBenchmarkData = function (userId, kpiId, cycleIds, leagueIds) {
            if (!_activityBenchmarkData[userId][kpiId]) {
                var deferred = $q.defer();
                $http.post('api/customReports/activityBenchmarks', {
                    cycleIds: cycleIds,
                    leagueIds: leagueIds,
                    userId, kpiId
                })
                    .then(function (result) {
                        _activityBenchmarkData[userId][kpiId] = result.data;
                        deferred.resolve(_activityBenchmarkData[userId][kpiId]);
                    }, function (error) {
                        _activityBenchmarkData[userId][kpiId] = null;
                        deferred.reject(error);
                    });
                _activityBenchmarkData[userId][kpiId] = deferred.promise;
            }
            return $q.when(_activityBenchmarkData[userId][kpiId]);
        }

        this.activityBenchmarkData = function (userId, kpiId, cycleIds, leagueIds) {

            if (!_activityBenchmarkData[userId]) _activityBenchmarkData[userId] = {};

            return activityBenchmarkData(userId, kpiId, cycleIds, leagueIds);
        };

        this.getBenchmarkData = function (userId, startDate, endDate) {

            if (!_benchmarkData[userId]) _benchmarkData[userId] = {};
            if (!_benchmarkData[userId][startDate]) _benchmarkData[userId][startDate] = {};

            return getBenchmarkData(userId, startDate, endDate);
        };


        function activityGameAnalyticsData(userId, kpiId, cycleIds) {
            var deferred = $q.defer();
            if (!_activityGameAnalyticsData[userId[kpiId]]) {

                $http.post('api/customReports/activityGameAnalytics', {
                    cycleIds: cycleIds,
                    kpiId, userId
                })
                    .then(function (result) {

                        _activityGameAnalyticsData[userId][kpiId] = result.data;
                        deferred.resolve(_activityGameAnalyticsData[userId][kpiId]);

                    }, function (error) {

                        _activityGameAnalyticsData[userId][kpiId] = null;
                        deferred.reject(error);
                    });
                _activityGameAnalyticsData[userId][kpiId] = deferred.promise;
            }
            return $q.when(_activityGameAnalyticsData[userId][kpiId]);
        }

        this.getKpiSpecificActivitiesAnalyticsData = function (userId, kpiId, cycleIds) {
            if (!_activityGameAnalyticsData[userId]) _activityGameAnalyticsData[userId] = {};
            return activityGameAnalyticsData(userId, kpiId, cycleIds);
        };

        var getPlayerActivitySwot = function (cycleId, userId) {
            const postData = {
                league: 'Bank',
                cycleId,
                userId
            }
            return $http.post('/api/analytics/user/myActivitySWOT',  postData).then(function (myActivity) {
                myActivity = myActivity.data;
                var kpiIndependentActivities = { Strength: [], Weakness: [], Opportunity: [], Threat: [], Unknown: [] };
                if (myActivity.users) {
                    const activityData = _.filter(myActivity.users.activityData, function (obj) { return (obj.kpiId == undefined) })
                    _.forEach(activityData, function (activity, index) {
                        if (myActivity.league) {
                            var leagueActivityData = _.filter(myActivity.league.activityData, function (obj) { return (obj.kpiId == undefined && obj.activityId == activity.activityId._id) });
                            activity.topDecilePercentageCompleted = leagueActivityData[0].topDecile;
                            activity.bottomDecilePercentageCompleted = leagueActivityData[0].bottomDecile;
                            activity.swot = SwotService.getSWOTString(activity.swot);
                            activity.threshold = leagueActivityData[0].avg;
                            activity.unit = "#";
                            if (activity.swot)
                                kpiIndependentActivities[activity.swot].push(activity);
                        }
                    });
                }

                return $q.resolve([userId, kpiIndependentActivities, myActivity]);
            });
        };

        var getActivitiesOfKPI = function (myActivity, kpiId, cycleId) {
            var kpiSpecificActivities = {
                Strength: [], Weakness: [], Opportunity: [], Threat: [], Unknown: []
            };
            var relevantActivities = [];
            _.forEach(myActivity.users.activityData, function (activity) {
                if (activity.kpiId && activity.kpiId.toString() == kpiId.toString())
                    relevantActivities.push(activity)
            });
            _.forEach(relevantActivities, function (activity) {
                var leagueActivityData = _.filter(myActivity.league.activityData, function (obj) { return (obj.kpiId == kpiId.toString() && obj.activityId.toString() == activity.activityId._id.toString()) })
                activity.topDecilePercentageCompleted = leagueActivityData[0].topDecile;
                activity.bottomDecilePercentageCompleted = leagueActivityData[0].bottomDecile;
                activity.forecastValue = ForecastMultiplier.ofCycle(cycleId) * activity.value;
                activity.weaknessThreshold = leagueActivityData[0].avg;
                activity.unit = "#";
                if (activity.swot)
                    kpiSpecificActivities[SwotService.getSWOTString(activity.swot)].push(activity);
            });
            return kpiSpecificActivities;
        }


        this.getDeepDiveData = function (playerId, cycleId) {
            return $http.post('/api/userAnalytics/deepDiveData', {userId:playerId, cycleId}).then(function (userAnalytic) {
                userAnalytic = userAnalytic.data;
                return $q.resolve(userAnalytic);
            });
        }
    });
