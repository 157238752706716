angular.module('bitnudgeApp')
	.directive('funnelChart', function ($rootScope) {
		return {
			restrict: 'EA',
			transclude: true,
			scope: {
				data: '=',
				bin: '=',
				export: '=',
				id: '@',
				title: '@',
				height: '@',
				type: '@'
			},

			replace: true,
			link: function (scope, element, attrs, rootScope) {
                var width = 700,
                height = 450,
                radius = Math.min(width, height) / 2;

            scope.render = function (data) {
                
            var color = d3.scale.ordinal()
                .range(["#255aee","#3a6fff","#4f84ff","rgb(101,154,302)","rgb(122,175,323)", "rgb(144,197,345)", "rgb(165,218,366)"]);

            var svg = d3.select(element[0]).append("svg")
                .attr("width", width)
                .attr("height", height)
                .append("g")

                data.forEach(function(error, data) {
                    //TODO move th funnel logic from pipelinecontroller to here
                });
            }

            scope.$watch('data', function (data) {
                if (data != undefined){
                    scope.render(data);
                }
            });

        }
		}
	})