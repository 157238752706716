'use strict';
angular.module('bitnudgeApp')
	.controller('incentivesModalController', function (p0, Auth, user, $scope, $http, $mdDialog, Sidebar, UtilService) {
		var currentUser = user;
		let allMonthlyCycles = _.cloneDeep(p0.allMonthlyCycles);
		allMonthlyCycles = _.sortBy(allMonthlyCycles, function(cycle){ return new Date(cycle.startDate)});
		let __allMonthlyCycles = Object.assign([],allMonthlyCycles);
			__allMonthlyCycles = __allMonthlyCycles.reverse();
		const errorMessage = 'Unable to fetch the incentives...';
		const loadingMessage = 'Loading...';
		$scope.labels = {reportee: p0.strings.FRONTLINE.value};
		function getQuarter(d) {
			d = d || new Date();
			return Math.floor(d.getMonth()/3)+1;
		}

		function setQuarters(){
			let quarters = [];
			const start = new Date(allMonthlyCycles[0].startDate);
			const end = new Date(allMonthlyCycles[allMonthlyCycles.length -1].endDate);
			const startFullYear = start.getFullYear();
			const endFullYear = end.getFullYear();
			for(let year = startFullYear; year <= endFullYear;year++){
				let startQ = 1, endQ = 4;
				if(year === startFullYear) startQ = getQuarter(start);
				if(year === endFullYear) endQ = getQuarter(end);
				for(let q = startQ; q<=endQ;q++){
					quarters.push({name:"Q"+q+" - "+year, qId:q, yId: year})
				}
			}
			quarters = quarters.reverse();
			$scope.cycles = quarters;
			$scope.selectedCycle = $scope.cycles[0];
		}

		function setYears(){
			let currentDate = new Date();
			let currentYear = currentDate.getFullYear()
			let startingDate = new Date(allMonthlyCycles[0].startDate);
			let startingYear = startingDate.getFullYear();
			let years = [];
			for( let i = currentYear; i >= startingYear; i--){
				const year = i;
				years.push({name:"Year - "+year, yId: year, onlyYear: true})
			}

			$scope.cycles = years;
			$scope.selectedCycle = $scope.cycles[0];
		}

		function setCycles(){
			$scope.cycles = __allMonthlyCycles;
			$scope.selectedCycle = $scope.cycles[0];
		}

		function initializePlayTypeAndJobRole() {
			let playTypes = [];
			currentUser.playTypes.forEach(function(playtype){
				playTypes.push(_.find(p0.allPlayTypes, {_id:playtype}))
			});
			$scope.playTypes = playTypes;
			$scope.selectedPlayType = $scope.playTypes[0];
			setJobRolesForSelectedPlayType();
		}

		function setJobRolesForSelectedPlayType() {
			var selectedPlayType = $scope.selectedPlayType;
			var jobRoleIdsForPlayType = [];
			var frontlineIds = selectedPlayType.frontline;
			frontlineIds.forEach(function (frontlineId) {
				jobRoleIdsForPlayType.push(frontlineId);
			});

			var allJobRoles = p0.allJobRoles;
			var jobRoleObjects = [];
			jobRoleIdsForPlayType.forEach(function (jobRoleIdForPlayType) {
				allJobRoles.forEach(function (jobRole) {
					if (jobRoleIdForPlayType === jobRole._id) {jobRoleObjects.push(jobRole)}
				})
			});

			$scope.jobRoles = jobRoleObjects;
			$scope.selectedJobRole = jobRoleObjects[0];
		}

		function getURL(){
			const metrics = ['RHB-M30', 'RHB-M40', 'RHB-M41'];
			let queryFilters = {};

			if($scope.selectedCycle.qId){
				const qId = $scope.selectedCycle.qId, yId = $scope.selectedCycle.yId;
				queryFilters['quarter'] = qId;
				queryFilters['year'] = yId;
				queryFilters['metrics'] = JSON.stringify(metrics);
			}else if($scope.selectedCycle.onlyYear){
				const yId = $scope.selectedCycle.yId;
				queryFilters['onlyYear'] = true;
				queryFilters['year'] = yId;
				queryFilters['metrics'] = JSON.stringify(metrics);
			}else{
				queryFilters['cycleId'] = $scope.selectedCycle._id;
			}
			if($scope.params.isFrontline){
				queryFilters['userId'] = currentUser._id;
				return {url: '/api/scorecard/frontline', data: queryFilters};
			}else{
				queryFilters['jobRoleId'] = $scope.selectedJobRole._id;
				return {url: '/api/scorecard/manager', data: queryFilters};
			}
		}

		function processFrontlineData(data) {
			let cyclesData = [];
			let quarterTotal = 0, quarterIncentiveTotal = 0, unit;
			data.cycles.forEach(function(cycle){
				let cycleData = {name:cycle.name};
				cycle.metrics.forEach(function (metric) {
					let metricCode = metric.fieldName?metric.fieldName.split('-'):"";
					if(metricCode && metricCode.length){
						metricCode = metricCode.pop();
						cycleData[metricCode.toLowerCase()] = metric;
					}
				});
				quarterIncentiveTotal += cycleData.m30.value
				quarterTotal += cycleData.m40.value;
				unit = cycleData.m40.unit;
				cyclesData.push(cycleData);
			});
			return {cycleData:cyclesData, result:{value:quarterTotal, unit:unit, quarterIncentive: quarterIncentiveTotal}};

		}

		function processNonFrontlineData(res){
			let reporteeData = [];
			const metricKey = $scope.selectedCommission.id === 'quarterly'?'m40':'m30';
			res.data.forEach(function(reportee){
				let userData = processFrontlineData(reportee);
				let userRow = {name: reportee.name, result: userData.result};
				userRow.cyclesData = userData.cycleData.map(function(m){return m[metricKey];});
				reporteeData.push(userRow);
			});
			let cycleNames = res.data[0].cycles.map(function(c){return c.name});
			return {cycleNames:cycleNames, reportees:reporteeData, unit:reporteeData[0].result.unit}
		}

		function getData() {
			$scope.params.dataLoaded = false;
			$scope.params.message = loadingMessage;
			const urlDetails = getURL();
			$http.post(urlDetails.url, urlDetails.data).then(function (res) {
				if(res.data.data && !res.data.data.length){
					$scope.params.message = 'You have no data!';
					return;
				}
				try{
					if($scope.params.isFrontline){
						let incentiveData = processFrontlineData(res.data.data[0]);
						$scope.data = {cyclesData: incentiveData.cycleData, resultMetric: incentiveData.result};
					}else{
						let reporteeIncentiveData = processNonFrontlineData(res.data);
						$scope.data = {
							cycleNames: reporteeIncentiveData.cycleNames,
							reportees: reporteeIncentiveData.reportees,
							unit: reporteeIncentiveData.unit
						};
					}
					$scope.params.dataLoaded = true;
				}catch(err){
					console.error(err);
					if(!err.data.status && err.data.errors[0] ){
						$scope.params.message = err.data.errors[0].message;
					}else{
						$scope.params.message = errorMessage;
					}
				}
			}).catch(function (err) {
				console.error(err);
				if(!err.data.status && err.data.errors[0] ){
					$scope.params.message = err.data.errors[0].message;
				}else{
					$scope.params.message = errorMessage;
				}
			})
		}
		$scope.downloadExcel = function(){
			const appUrl = Sidebar.getAppUrl();
			//need to fix
			let url = appUrl + getURL();
			const commissionId = $scope.selectedCommission.id;
			const params = {
				commissionId,
				download: true,
				cycleName: $scope.selectedCycle.name
			}
			return UtilService.downloadFile(url, params)
		};

		function init(){
			$scope.params = {dataLoaded:false, message:loadingMessage};
			$scope.params.isFrontline = currentUser.systemRole === 'frontline';

			$scope.isWindowMaximised = false;
			$scope.modalHeading = "Incentives";

			$scope.commissionDropdown = [{name: "Yearly", id:"yearly"},{name:"Quarterly", id:"quarterly"}, {name:"Monthly", id:"monthly"}];
			$scope.selectedCommission = $scope.commissionDropdown[0];


			initializePlayTypeAndJobRole();
			$scope.commissionDropdownChange($scope.selectedCommission);
		}

		$scope.commissionDropdownChange = function(value){
			$scope.selectedCommission = value;
			if($scope.selectedCommission.id === 'quarterly'){
				setQuarters();
			}else if($scope.selectedCommission.id === 'yearly'){
				setYears();
			}else{
				setCycles();
			}
			$scope.cycleChange($scope.cycles[0]);
		};

		$scope.cycleChange = function(cycle){
			$scope.selectedCycle = cycle;
			getData();
		};

		$scope.onPlayTypeChange = function(playtype){
			$scope.selectedPlayType = playtype;
			setJobRolesForSelectedPlayType();
			getData();
		};

		$scope.onJobRoleChange = function(role){
			$scope.selectedJobRole = role;
			getData();
		};

		$scope.maximiseWindow = function () {
			$scope.isWindowMaximised = !$scope.isWindowMaximised;
		};

		$scope.onClose = function () {
			$mdDialog.cancel();
		};

		init();
	});
