'use strict';
angular.module('bitnudgeApp')
    .controller('taggingInputCtrl', function ($scope, $element, toaster) {
        $scope.placeholdershow = true;
        $scope.inputText = "";
        var inputText;
        $scope.userTagIds=[];
        $scope.kpiIds = [];
        var previousInput = "";
        var cursorPosition;
        $scope.showPlayersForMessage = false;
        var taggingInput = angular.element($element).children('.taggingInputContainer').children('.taggingInput');
        $scope.clearSelects = function ($event){
            if ($event.keyCode == 27) {
                $scope.showPlayersForMessage=false;
                $scope.showKpisForMessage=false;
                taggingInput.focus();
                placeCaretAtEnd(taggingInput[0]);
            }
        };
        if($scope.playerList && $scope.kpis){
            $scope.placeholderText = 'Enter @player';
        }else{
            $scope.placeholderText = 'Enter comment';
            $scope.tagPlayers = false;
        }
        function placeCaretAtEnd(el) {
            el.focus();
            if (typeof window.getSelection != "undefined"
                && typeof document.createRange != "undefined") {
                var range = document.createRange();
                range.selectNodeContents(el);
                range.collapse(false);
                var sel = window.getSelection();
                sel.removeAllRanges();
                sel.addRange(range);
            } else if (typeof document.body.createTextRange != "undefined") {
                var textRange = document.body.createTextRange();
                textRange.moveToElementText(el);
                textRange.collapse(false);
                textRange.select();
            }
        }

        $scope.checkCharacter = function () {
            $scope.showPlayersForMessage = false;
            $scope.showKpisForMessage = false;
            var i = 0;
            inputText = $scope.inputText.replace(/&nbsp;/g, " ");
            if(inputText.length == 0){
                $scope.placeholdershow = true;
            }
            else{
                $scope.placeholdershow = false;
            }
            if(inputText.length > previousInput.length) {
                while (previousInput[i] === inputText[i]) {
                    i++;
                }

                if(inputText[i] == '@' && $scope.playerList){
                    $scope.showPlayersForMessage = true;
                    cursorPosition = i;
                    setTimeout(function () {
                        $("#playerForTaggingMessage").trigger('chosen:open');},100);
                }else if(inputText[i] == '#'){
                    $scope.showKpisForMessage = true;
                    cursorPosition = i;
                    setTimeout(function () {
                        $("#kpiForTaggingMessage").trigger('chosen:open');},100);
                }
            }
            else if(inputText.length < previousInput.length){
                while (previousInput[i] === inputText[i]) {
                    i++;
                }
                if(previousInput[i] == '\u200C'){
                    cursorPosition = i;
                    for(i = cursorPosition - 1; i>=0;i--){
                        if(previousInput[i] == '\u200C'){
                            previousInput = previousInput.substring(0,i-1) + previousInput.substring(cursorPosition, previousInput.length);
                            $scope.inputText  = previousInput;
                            break;
                        }
                    }
                }
            }
            previousInput = inputText;
        }

        $scope.selectedPlayerForTagging =  function(playerChanged){
            var counter = 0;
            for(var i = 0; i<= cursorPosition; i++){
                if($scope.inputText[i] == '\u200C'){
                    counter++;
                }
            }
            inputText = $scope.inputText.replace('&nbsp;' , " ");
            $scope.userTagIds.splice(counter/2,0,playerChanged.id);
            $scope.showPlayersForMessage= false;
            $scope.inputText = inputText.substring(0,i - 1) + '\u200C <a href = "userFlow/profile/'+ playerChanged.id  +'">' + playerChanged.name + '</a>\u200C&nbsp;' + inputText.substring(cursorPosition+1, inputText.length);
            previousInput = $scope.inputText;
            //taggingInput.focus();
            setTimeout(function(){
                placeCaretAtEnd(taggingInput[0]);
            },100);

            //placeCaretAtEnd(taggingInput[0]);
        };

        $scope.selectedKpiForTagging = function(kpi){
            var counter = 0;
            for(var i = 0; i<= cursorPosition; i++){
                if($scope.inputText[i] == '\u200C'){
                    counter++;
                }
            }
            inputText = $scope.inputText.replace('&nbsp;' , " ");
            $scope.kpiIds.splice(counter/2,0,kpi._id);
            $scope.showKpisForMessage = false;
            $scope.inputText = inputText.substring(0,i - 1) + '\u200C <a>' + kpi.name + '</a>\u200C&nbsp;' + inputText.substring(cursorPosition+1, inputText.length);
            previousInput = $scope.inputText;
            setTimeout(function(){
                placeCaretAtEnd(taggingInput[0]);
            },100);
        };

        $scope.submitMessage = function(){
            var findSpace = '&nbsp;';
            var replaceSpace = new RegExp(findSpace, 'g');
            if($scope.type == 'message'){
                $scope.inputText = $scope.inputText.replace(replaceSpace, '');
                if($scope.inputText != undefined && $scope.inputText.trim()!= "") {
                    $scope.addNudge($scope.inputText, $scope.userTagIds,$scope.kpiIds);
                    $scope.inputText = "";
                }
                else
                    toaster.pop('Failure', "Please enter a valid message.");
            }
            else{
                $scope.inputText = $scope.inputText.replace(replaceSpace, '');

                if($scope.inputText != undefined && $scope.inputText.trim()!= "") {
                    $scope.addReply($scope.inputText, $scope.message);
                    $scope.inputText = "";
                }
                else
                    toaster.pop('Failure', "Please enter a valid reply.");
            }
        };
        $scope.focusTaggingInput = function(){
            placeCaretAtEnd(taggingInput[0]);
            $scope.showPlayersForMessage = false;
        }


    });

