'use strict';
angular.module('bitnudgeApp')
    .controller('healthMetricsCtrl', function ($scope, user, playType, cycle, $http, $mdDialog, p0) {

        var selectedCycle = cycle;
        var kpis = _.filter(playType.kpis, function(kpi){
            return _.find(p0.allKpis, {_id: kpi._id});
        });
        $scope.kpis = _.cloneDeep(kpis);
        $scope.kpi = $scope.kpis[0];
        var trackCycle = p0.trackDailyCycle;
        $scope.options = [{_id:trackCycle._id, name:trackCycle.name},{_id:cycle._id, name:'MTD'}];
        $scope.selectedOption = $scope.options[1];

        $scope.header = 'Health Metrics'+' ['+playType.name+']';
        $scope.changePF = function(option){
            $scope.selectedOption = option;
          init();
        };
        function init() {
            const postBody = {
                playTypeId: playType._id,
                userId: user._id,
                cycleId: $scope.selectedOption._id
            }
            $http.post('/api/userAnalytics/healthMetrics', postBody).then(function(result){
                result=result.data;
                $scope.managerFeed = result[user._id];
                $scope.reporteeFeed = [];
                _.forOwn(result , function(value,userId){
                    if(userId == user._id) return;
                    $scope.reporteeFeed.push(result[userId]);
                });
            });
        }
        $scope.onClose = function() {
            $mdDialog.hide();
        };
        init();
    });
