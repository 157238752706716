"use strict";

angular
	.module("bitnudgeApp")
	.controller("historyCardComponentCtrl", function(
		$scope,
		Auth,
		DocumentService
	) {
		$scope.getCurrentUser = Auth.getCurrentUser;
		// console.log(this.$scope.data, "--data from parent controller");
		this.$onChanges = changes => {
			if (changes.history) {
				$scope.history = changes.history.currentValue;
			}
		};

		$scope.showHistoryDetails = function() {
			$scope.showDetail = true;
		};
		$scope.hideHistoryDetails = function() {
			$scope.showDetail = false;
		};
	});
