const autoImportNgTemplateLoaderTemplate1 = require('../html/toggleSwitch.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('toggleSwitch', function () {
        return {
            name: 'toggleSwitch',
            scope: {
                value : '=',
                label1 : '@',
                label2: '@',
                alternateId: '@'
            },
            controller: 'toggleSwitchCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1

        };
    });
