const autoImportNgTemplateLoaderTemplate1 = require('../html/badge.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('badge', function () {
        return {
            name: 'badge',
            scope: {
                data:           '=',
                goal:           '=',
                activity:       '=',
                owner:          '=',
                isEarned:       '=',
                hideName:       '=',
                hideCount:      '=',
                showImage:      '@',
                count:          '@',
                hideModel:      '@'
            },
            controller: 'badgeCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
