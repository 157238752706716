'use strict';
angular.module('bitnudgeApp')
    .controller('addBulkIndividualTargetController', function($http, $scope, Auth, Upload, $location, toaster, p0) {

        $scope.selectedCycle=p0.currentMonthlyCycle;
        $scope.allCycles=p0.allAdminCycles;
        $scope.uploadPeriod = p0.config.uploadPeriod;

        $scope.toggleMin = function() {
            $scope.minDate = $scope.minDate ? null : new Date();
        };
        $scope.toggleMin();

        //Function to open date dialog box
        $scope.open = function($event) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened = true;
        };

        //Configuration of datepicker
        $scope.dateOptions = {
            formatYear: 'yy',
            modelDateFormat: "yyyy-MM-ddTHH:mm:ss",
            startingDay: 1
        };

        $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];

        //On submit function
        $scope.onFileSelectIndi = function() {
            if($scope.asOfDate) {
                $scope.asOfDate = $scope.asOfDate.getTime();
            }
            var $file = $scope.files[0];
            var fileName = $scope.files[0].name.split(".");
            var fileType = fileName[fileName.length - 1];
            if (fileType == "xls" || fileType == "xlsx" || fileType == "xlsm" || fileType == "xlsb") {
                Upload.upload({
                    url: '/api/individualTargets/import/excel',
                    data: {
                        asOfDate: $scope.asOfDate,
                        cycle: $scope.selectedCycle,
                        dataType: 'targets'
                    },
                    file: $file,
                    progress: function (e) {
                    }
                }).then(function (data, status, headers, config) {
                    // file is uploaded successfully
                    // $location.path('/lms/listIndividualTarget');
                    toaster.pop('success', "Bulk Individual target Creation.", "Bulk Individual target Creation.");
                });
            }else{
                toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' Types Only.");
            }
        }
    });
