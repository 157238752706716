const autoImportNgTemplateLoaderTemplate1 = require('../html/rhsSidebar.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('rhsSidebar', function () {
        return {
            name: 'rhsSidebar',
            scope: {
            },
            controller: 'rhsSidebarCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
