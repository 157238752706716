/******************
 *
 * Deprecated
 *
 */
'use strict';
angular.module('bitnudgeApp')
    .service('ewi', function ($http ,Auth,gpSimulation, $q) {
        var dataFetched = false;
        var _this = this;
        this.getData = function(cycleId){
            if(!dataFetched) {
                var currentUser = Auth.getCurrentUser();
                return $http.post('/api/userAnalytics/getEWIData', {userId: currentUser._id, cycleId}).then(function(ewiData){
                    ewiData=ewiData.data;
                    _this.data = ewiData;
                    return gpSimulation.checkValidity().then(function(validity){
                        _this.data.gpValidity = validity;
                        dataFetched = true;
                        return $q.resolve(true);
                    });
                });
            }else{
                return $q.resolve(true);
            }
        };
        this.destroy = function(){
            dataFetched = false;
            delete(_this.data);
        }
    });
