const autoImportNgTemplateLoaderTemplate1 = require('../html/cneKpiTab/dailyFeedModals/dailyFeedModal-badge.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/cneKpiTab/dailyFeedModals/bulkCommentModal.html');
const autoImportNgTemplateLoaderTemplate3 = require('../html/playerListModal.html');
const autoImportNgTemplateLoaderTemplate4 = require('../html/cneKpiTab/dailyFeedModals/labModal.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('cneKpiTabCtrl', function ($scope, $http,Auth, p0,$rootScope, dailyFeed, activityUpdates) {
        var currentCycle;
        var data;
        var dailyFeeds;
        $scope.strings = $rootScope.strings;
        $scope.me = Auth.getCurrentUser();
        $scope.prevDailyCycle = p0.previousDailyCycle;
        if($scope.kpi){
            $scope.selectedKpi = _.find(p0.allKpis, {_id:$scope.kpi});
        }
        $scope.setCycleDescName = function () {
            if($scope.cycleType == 'weekly'){
                $scope.cycleDesc = 'week';
            }else if($scope.cycleType == 'monthly'){
                $scope.cycleDesc = 'month';
            }else $scope.cycleDesc = 'day';
        };
        $scope.setCycleDescName();
        $scope.lab = {isLive:true};
        function frontEndCalls(){
            if(!$scope.isWeekly){
                $scope.cycleType = 'month';
                currentCycle = p0.currentMonthlyCycle;
            }
            else{
                $scope.cycleType = 'week';
                currentCycle = p0.currentWeeklyCycle;
            }

            var url;
            url = 'api/cne/myUpdates/'+$scope.user+'/';
            if ($scope.type == 'overall' && $scope.user != 'seniorManager') {
                url = url +$scope.cycleType +'/'+ currentCycle._id;
            }
            else if($scope.type == 'kpi' && $scope.user != 'seniorManager'){
                url = url +$scope.cycleType +'/'+ currentCycle._id + '/kpiId/' + $scope.kpi;
            }
            else if($scope.type == 'overall'){
                url = url +$scope.cycleType +'/'+ currentCycle._id + '/reporteeType/' + $scope.jobRole._id;
            }
            else{
                url = url +$scope.cycleType +'/'+ currentCycle._id + '/kpiId/' + $scope.kpi + '/reporteeType/' + $scope.jobRole._id;
            }
            //this route does not exists
            $http.post(url).then(function(innerData){
                innerData=innerData.data;
                data = innerData;
                $scope.hoverData = data.hoverData;
                if($scope.tabType == 'huddleBoard'){
                    huddleBoardInit();
                }
                else {
                    init();
                }
            });
        }
        function init(){
            $scope.tabs = [];
            if($scope.type == 'kpi'){
                var title = $scope.kpiName + " Performance";
            }
            else{
                var title = "Overall Performance";
            }

            var submissions = _.find(data.activityData, function(activity){return activity.name === 'Application submission' && activity.kpiId == $scope.kpi});
            var interactions = _.find(data.activityData, function(activity){return activity.name === 'Interactions' && activity.kpiId == $scope.kpi});
            var leads = _.find(data.activityData, function(activity){return activity.name === 'Lead creation' && activity.kpiId == $scope.kpi});
            var i = 0;
            $scope.tabs[i] = {
                title: title,
                section1: [
                    {
                        label: "Weighted avg Target achievement",
                        val: Math.round(data.percentageCompleted.value) + '%',
                        valLink: "/userFlow/leaderboard",
                        delta: data.percentageCompleted.delta ,
                        deltaLink: "/userFlow/leaderboard"
                    }
                ],
                section2: [
                    {
                        label: "Top",
                        val: Math.round(data.percentageCompleted.max.value) + '%',
                        placement:'right',
                        userId: data.percentageCompleted.max.userId,//getPopoverHTML(data.percentageCompleted.max.userId,data.hoverData)
                    },
                    {
                        label: "Average",
                        val: Math.round(data.percentageCompleted.avg) + '%'
                    },
                    {
                        label: "Bottom",
                        val: Math.round(data.percentageCompleted.min.value) + '%',
                        placement:'left',
                        userId: data.percentageCompleted.min.userId,//getPopoverHTML(data.percentageCompleted.min.userId,data.hoverData)
                    }
                ]
            };
            if($scope.type == 'kpi' && $scope.isWeekly){
                $scope.tabs[i].section1[0].label = "Target achievement";
            }
            else if($scope.type == 'kpi') {
                $scope.tabs[i].section1[0].label = "Projected Achievement";
            }
            else{
                $scope.tabs[i].section1[0].label = "Projected Achievement (wtd. avg.)";
            }
            if(!$scope.hideReportees && $scope.user != 'frontline'){
                $scope.tabs[i].section2.push(
                    {
                        label: 'Reportees above 50%',
                        val: data.children.precentageCompleted.aboveFiftyPercent + '/' + data.children.total
                    },
                    {},
                    {
                        label: 'Reportees below 50%',
                        val: data.children.precentageCompleted.belowFiftyPercent + '/' + data.children.total
                    }
                )
            }
            else if($scope.user == 'seniorManager' && !$scope.isWeekly){
                $scope.tabs[i].section2.push(
                    {
                        label: 'Reportees who will meet their Target',
                        val: data.children.percentageCompleted.aboveThreshold + '/' + data.children.total
                    },
                    {},
                    {
                        label: 'Reportees who will miss their Target',
                        val: data.children.percentageCompleted.belowThreshold + '/' + data.children.total
                    }
                )
            }
            if($scope.user != 'seniorManager'){
                $scope.tabs[i].section1.push({
                    label: "Overall "+$scope.strings.POINTS.value,
                    val: Math.round(data.points.value),
                    valLink: "/userFlow/leaderboard",
                    delta: data.points.delta,
                    deltaLink: "/userFlow/leaderboard"
                });
            }
            if($scope.user != 'seniorManager') {
                $scope.tabs[++i] = {
                    title: "Rank",
                    section1: [
                        {
                            label: "Bank-wide Rank",
                            val: data.points.ranks.value,
                            valLink: "/userFlow/leaderboard",
                            delta: data.points.ranks.delta,
                            deltaLink: "/userFlow/leaderboard"

                        }
                    ],
                    section2: [
                        {
                            label: "Top Rank in team",
                            val: data.points.max.rank,
                            placement: 'right',
                            userId: data.points.max.userId,//getPopoverHTML(data.points.max.userId, data.hoverData)
                        },
                        {},
                        {
                            label: "Bottom Rank in team",
                            val: data.points.min.rank,
                            placement: 'left',
                            userId: data.points.min.userId,//getPopoverHTML(data.points.min.userId, data.hoverData)
                        }
                    ]
                };
            }
            if($scope.user == 'frontline' || !$scope.hideReportees) {
                $scope.tabs[++i] = {
                    title: "Incentives",
                    section1: [
                        {
                            label: "Incentives earned this " + $scope.cycleType,
                            val: data.incentive.value,
                            valLink: "/userFlow/leaderboard",
                            delta: data.incentive.delta,
                            deltaLink: "/userFlow/leaderboard"
                        }
                    ],
                    section2: [
                        {
                            label: "Maximum incentive",
                            val: data.incentive.max.value,
                            //TODO: Uncomment when incentives are available
                            //placement:'right',
                            //content:
                            //"<div>" +
                            //"<div class='row'>" +
                            //"<img src='"+ data.hoverData[data.incentive.max.userId].image +"'>" +
                            //"<p class='text'>"+ data.hoverData[data.incentive.max.userId].name +"</p>" +
                            //"<p class='text'>"+ data.hoverData[data.incentive.max.userId].branch +"</p>" +
                            //
                            //"<span class='bubbleContainer'><span>Points:</span><span class='bubble'>" + Math.round(data.hoverData[data.incentive.max.userId].points) + "</span></span>" +
                            //"<span class='bubbleContainer'><span>Rank:</span><span class='bubble'>" + Math.round(data.hoverData[data.incentive.max.userId].rank) + "</span></span>" +
                            //"<span class='bubbleContainer'><span>Badges:</span><span class='bubble'>" + Math.round(data.hoverData[data.incentive.max.userId].badgeCount) + "</span></span>" +
                            // "<hr>" + "</div>" +
                            //"<a href='/userFlow/profile/"+data.incentive.max.userId+"' class='button'>Profile</a>" +
                            //"<a href='/userFlow/gameplan/"+data.incentive.max.userId+"' class='button'>Gameplan</a>" +
                            //"<a onclick='comparePlayer(&quot;"+data.incentive.max.userId+"&quot;)' class='button'>Compare</a>" +
                            //"</div>"
                        },
                        {
                            label: "Average Incentive",
                            val: data.incentive.avg
                        },
                        {
                            label: "Minimum Incentive",
                            val: data.incentive.min.value,
                            //TODO: Uncomment when incentives are available
                            //placement:'left',
                            //content:
                            //"<div>" +
                            //"<div class='row'>" +
                            //"<img src='"+ data.hoverData[data.incentive.min.userId].image +"'>" +
                            //"<p class='text'>"+ data.hoverData[data.incentive.min.userId].name +"</p>" +
                            //"<p class='text'>"+ data.hoverData[data.incentive.min.userId].branch +"</p>" +
                            //
                            //"<span class='bubbleContainer'><span>Points:</span><span class='bubble'>" + Math.round(data.hoverData[data.incentive.min.userId].points) + "</span></span>" +
                            //"<span class='bubbleContainer'><span>Rank:</span><span class='bubble'>" + Math.round(data.hoverData[data.incentive.min.userId].rank) + "</span></span>" +
                            //"<span class='bubbleContainer'><span>Badges:</span><span class='bubble'>" + Math.round(data.hoverData[data.incentive.min.userId].badgeCount) + "</span></span>" +
                            // "<hr>" + "</div>" +
                            //"<a href='/userFlow/profile/"+data.incentive.min.userId+"' class='button'>Profile</a>" +
                            //"<a href='/userFlow/gameplan/"+data.incentive.min.userId+"' class='button'>Gameplan</a>" +
                            //"<a onclick='comparePlayer(&quot;"+data.incentive.min.userId+"&quot;)' class='button'>Compare</a>" +
                            //"</div>"
                        }
                    ]
                };
                if($scope.user != 'frontline') {
                    $scope.tabs[i].title = 'Incentives of my Reportees';
                }
            }
            if($scope.user == 'seniorManager'){
                $scope.tabs[++i] = {
                    title: 'Incentives',
                    section1:[
                        {
                            label:"Reportees Qualifying for Incentives",
                            val: data.children.incentive.qualifying + '/' + data.children.total
                        }
                    ],
                    section2: [
                        {
                            label: "Maximum incentive",
                            val: data.incentive.max.value,
                            //TODO: Uncomment when incentives are available
                            //placement:'right',
                            //content:
                            //"<div>" +
                            //"<div class='row'>" +
                            //"<img src='"+ data.hoverData[data.incentive.max.userId].image +"'>" +
                            //"<p class='text'>"+ data.hoverData[data.incentive.max.userId].name +"</p>" +
                            //"<p class='text'>"+ data.hoverData[data.incentive.max.userId].branch +"</p>" +
                            //
                            //"<span class='bubbleContainer'><span>Points:</span><span class='bubble'>" + Math.round(data.hoverData[data.incentive.max.userId].points) + "</span></span>" +
                            //"<span class='bubbleContainer'><span>Rank:</span><span class='bubble'>" + Math.round(data.hoverData[data.incentive.max.userId].rank) + "</span></span>" +
                            //"<span class='bubbleContainer'><span>Badges:</span><span class='bubble'>" + Math.round(data.hoverData[data.incentive.max.userId].badgeCount) + "</span></span>" +
                            // "<hr>" + "</div>" +
                            //"<a href='/userFlow/profile/"+data.incentive.max.userId+"' class='button'>Profile</a>" +
                            //"<a href='/userFlow/gameplan/"+data.incentive.max.userId+"' class='button'>Gameplan</a>" +
                            //"<a onclick='comparePlayer(&quot;"+data.incentive.max.userId+"&quot;)' class='button'>Compare</a>" +
                            //"</div>"
                        },
                        {
                            label: "Average Incentive",
                            val: data.incentive.avg
                        },
                        {
                            label: "Minimum Incentive",
                            val: data.incentive.min.value,
                            //TODO: Uncomment when incentives are available
                            //placement:'left',
                            //content:
                            //"<div>" +
                            //"<div class='row'>" +
                            //"<img src='"+ data.hoverData[data.incentive.min.userId].image +"'>" +
                            //"<p class='text'>"+ data.hoverData[data.incentive.min.userId].name +"</p>" +
                            //"<p class='text'>"+ data.hoverData[data.incentive.min.userId].branch +"</p>" +
                            //
                            //"<span class='bubbleContainer'><span>Points:</span><span class='bubble'>" + Math.round(data.hoverData[data.incentive.min.userId].points) + "</span></span>" +
                            //"<span class='bubbleContainer'><span>Rank:</span><span class='bubble'>" + Math.round(data.hoverData[data.incentive.min.userId].rank) + "</span></span>" +
                            //"<span class='bubbleContainer'><span>Badges:</span><span class='bubble'>" + Math.round(data.hoverData[data.incentive.min.userId].badgeCount) + "</span></span>" +
                            // "<hr>" + "</div>" +
                            //"<a href='/userFlow/profile/"+data.incentive.min.userId+"' class='button'>Profile</a>" +
                            //"<a href='/userFlow/gameplan/"+data.incentive.min.userId+"' class='button'>Gameplan</a>" +
                            //"<a onclick='comparePlayer(&quot;"+data.incentive.min.userId+"&quot;)' class='button'>Compare</a>" +
                            //"</div>"
                        }
                    ]

                };
            }
            //if($scope.user == 'frontline' || !$scope.hideReportees) {
            //    $scope.tabs[++i] = {
            //        title: "Activity Indicators",
            //        section1: [
            //            {
            //                label: "File submissions",
            //                val: submissions.value.value,
            //                delta: submissions.value.delta,
            //                deltaLink: "/userFlow/leaderboard",
            //                valLink: "/userFlow/leaderboard"
            //            },
            //            {
            //                label: "Interactions",
            //                val: interactions.value.value,
            //                delta: interactions.value.delta,
            //                deltaLink: "/userFlow/leaderboard",
            //                valLink: "/userFlow/leaderboard"
            //            },
            //            {
            //                label:"Activity Points",
            //                val: data.activityPoints.value,
            //                delta: data.activityPoints.delta,
            //                deltaLink: "/userFlow/leaderboard",
            //                valLink: "/userFlow/leaderboard"
            //            }
            //        ],
            //        section2: [
            //            {
            //                label: "Maximum submissions",
            //                valLink: '/lms/lmsBranchReport',
            //                val: submissions.value.max.value,
            //                //TODO: Remove when activityData is available
            //                //placement:'right',
            //                //content:
            //                //"<div>" +
            //                //"<div class='row'>" +
            //                //"<img src='"+ data.hoverData[data.submissions.value.max.userId].image +"'>" +
            //                //"<p class='text'>"+ data.hoverData[data.submissions.value.max.userId].name +"</p>" +
            //                //"<p class='text'>"+ data.hoverData[data.submissions.value.max.userId].branch +"</p>" +
            //                //
            //                //"<span class='bubbleContainer'><span>Points:</span><span class='bubble'>" + Math.round(data.hoverData[data.submissions.value.max.userId].points) + "</span></span>" +
            //                //"<span class='bubbleContainer'><span>Rank:</span><span class='bubble'>" + Math.round(data.hoverData[data.submissions.value.max.userId].rank) + "</span></span>" +
            //                //"<span class='bubbleContainer'><span>Badges:</span><span class='bubble'>" + Math.round(data.hoverData[data.submissions.value.max.userId].badgeCount) + "</span></span>" +
            //                // "<hr>" + "</div>" +
            //                //"<a href='/userFlow/profile/"+data.submissions.value.max.userId+"' class='button'>Profile</a>" +
            //                //"<a href='/userFlow/gameplan/"+data.submissions.value.max.userId+"' class='button'>Gameplan</a>" +
            //                //"<a onclick='comparePlayer(&quot;"+data.submissions.value.max.userId+"&quot;)' class='button'>Compare</a>" +
            //                //"</div>"
            //            },
            //            {
            //                label: "Average submissions",
            //                val: submissions.value.avg,
            //                valLink: '/lms/lmsBranchReport',
            //            },
            //            {
            //                label: "Minimum submissions",
            //                val: submissions.value.min.value,
            //                valLink: '/lms/lmsBranchReport',
            //                //TODO: Remove when activityData is available
            //                //placement:'left',
            //                //content:
            //                //"<div>" +
            //                //"<div class='row'>" +
            //                //"<img src='"+ data.hoverData[data.submissions.value.min.userId].image +"'>" +
            //                //"<p class='text'>"+ data.hoverData[data.submissions.value.min.userId].name +"</p>" +
            //                //"<p class='text'>"+ data.hoverData[data.submissions.value.min.userId].branch +"</p>" +
            //                //
            //                //"<span class='bubbleContainer'><span>Points:</span><span class='bubble'>" + Math.round(data.hoverData[data.submissions.value.min.userId].points) + "</span></span>" +
            //                //"<span class='bubbleContainer'><span>Rank:</span><span class='bubble'>" + Math.round(data.hoverData[data.submissions.value.min.userId].rank) + "</span></span>" +
            //                //"<span class='bubbleContainer'><span>Badges:</span><span class='bubble'>" + Math.round(data.hoverData[data.submissions.value.min.userId].badgeCount) + "</span></span>" +
            //                // "<hr>" + "</div>" +
            //                //"<a href='/userFlow/profile/"+data.submissions.value.min.userId+"' class='button'>Profile</a>" +
            //                //"<a href='/userFlow/gameplan/"+data.submissions.value.min.userId+"' class='button'>Gameplan</a>" +
            //                //"<a onclick='comparePlayer(&quot;"+data.submissions.value.min.userId+"&quot;)' class='button'>Compare</a>" +
            //                //"</div>"
            //            },
            //            {
            //                label: "Maximum calls",
            //                val: calls.value.max.value,
            //                valLink: '/lms/lmsBranchReport',
            //                //TODO: Remove when activityData is available
            //                //placement:'right',
            //                //content:
            //                //"<div>" +
            //                //"<div class='row'>" +
            //                //"<img src='"+ data.hoverData[data.calls.value.max.userId].image +"'>" +
            //                //"<p class='text'>"+ data.hoverData[data.calls.value.max.userId].name +"</p>" +
            //                //"<p class='text'>"+ data.hoverData[data.calls.value.max.userId].branch +"</p>" +
            //                //
            //                //"<span class='bubbleContainer'><span>Points:</span><span class='bubble'>" + Math.round(data.hoverData[data.calls.value.max.userId].points) + "</span></span>" +
            //                //"<span class='bubbleContainer'><span>Rank:</span><span class='bubble'>" + Math.round(data.hoverData[data.calls.value.max.userId].rank) + "</span></span>" +
            //                //"<span class='bubbleContainer'><span>Badges:</span><span class='bubble'>" + Math.round(data.hoverData[data.calls.value.max.userId].badgeCount) + "</span></span>" +
            //                // "<hr>" + "</div>" +
            //                //"<a href='/userFlow/profile/"+data.calls.value.max.userId+"' class='button'>Profile</a>" +
            //                //"<a href='/userFlow/gameplan/"+data.calls.value.max.userId+"' class='button'>Gameplan</a>" +
            //                //"<a onclick='comparePlayer(&quot;"+data.calls.value.max.userId+"&quot;)' class='button'>Compare</a>" +
            //                //"</div>"
            //            },
            //            {
            //                label: "Average calls",
            //                valLink: '/lms/lmsBranchReport',
            //                val: calls.value.avg
            //            },
            //            {
            //                label: "Minimum calls",
            //                valLink: '/lms/lmsBranchReport',
            //                val: calls.value.min.value,
            //                //TODO: Remove when activityData is available
            //                //placement:'left',
            //                //content:
            //                //"<div>" +
            //                //"<div class='row'>" +
            //                //"<img src='"+ data.hoverData[data.calls.value.min.userId].image +"'>" +
            //                //"<p class='text'>"+ data.hoverData[data.calls.value.min.userId].name +"</p>" +
            //                //"<p class='text'>"+ data.hoverData[data.calls.value.min.userId].branch +"</p>" +
            //                //
            //                //"<span class='bubbleContainer'><span>Points:</span><span class='bubble'>" + Math.round(data.hoverData[data.calls.value.min.userId].points) + "</span></span>" +
            //                //"<span class='bubbleContainer'><span>Rank:</span><span class='bubble'>" + Math.round(data.hoverData[data.calls.value.min.userId].rank) + "</span></span>" +
            //                //"<span class='bubbleContainer'><span>Badges:</span><span class='bubble'>" + Math.round(data.hoverData[data.calls.value.min.userId].badgeCount) + "</span></span>" +
            //                // "<hr>" + "</div>" +
            //                //"<a href='/userFlow/profile/"+data.calls.value.min.userId+"' class='button'>Profile</a>" +
            //                //"<a href='/userFlow/gameplan/"+data.calls.value.min.userId+"' class='button'>Gameplan</a>" +
            //                //"<a onclick='comparePlayer(&quot;"+data.calls.value.min.userId+"&quot;)' class='button'>Compare</a>" +
            //                //"</div>"
            //            }
            //        ]
            //    };
            //    if($scope.user != 'frontline') {
            //        $scope.tabs[i].title = 'Reportee Activity';
            //    }
            //    if($scope.kpiId){
            //        $scope.tabs[i].section1[2].val = calls.points.value + submissions.points.value + leads.points.value;
            //        $scope.tabs[i].section1[2].delta = calls.points.delta + submissions.points.delta + leads.points.delta;
            //    }
            //}
            //else if($scope.user == 'seniorManager'){
            //    $scope.tabs[++i] = {
            //        title: "Activity Indicators",
            //        section1: [
            //            {
            //                label: "File submissions",
            //                val: (submissions.value.value / data.daysPassed) + " / day",
            //                delta: submissions.value.delta / data.daysPassed,
            //                deltaLink: "/userFlow/leaderboard",
            //                valLink: "/userFlow/leaderboard"
            //            },
            //            {
            //                label: "Calls / Meetings",
            //                val: (calls.value.value / data.daysPassed) + " / day",
            //                delta: calls.value.delta / data.daysPassed,
            //                deltaLink: "/userFlow/leaderboard",
            //                valLink: "/userFlow/leaderboard"
            //            },
            //            {
            //                label:"Activity Points",
            //                val: (data.activityPoints.val / data.daysPassed) + " / day",
            //                delta: data.activityPoints.val / data.daysPassed,
            //                deltaLink: "/userFlow/leaderboard",
            //                valLink: "/userFlow/leaderboard"
            //            }
            //        ],
            //        section2: [
            //            {
            //                label: "Maximum submissions",
            //                valLink: '/lms/lmsBranchReport',
            //                val: (submissions.value.max.value / data.daysPassed) + " / day",
            //                //TODO: Remove when activityData is available
            //                //placement:'right',
            //                //content:
            //                //"<div>" +
            //                //"<div class='row'>" +
            //                //"<img src='"+ data.hoverData[data.submissions.value.max.userId].image +"'>" +
            //                //"<p class='text'>"+ data.hoverData[data.submissions.value.max.userId].name +"</p>" +
            //                //"<p class='text'>"+ data.hoverData[data.submissions.value.max.userId].branch +"</p>" +
            //                //
            //                //"<span class='bubbleContainer'><span>Points:</span><span class='bubble'>" + Math.round(data.hoverData[data.submissions.value.max.userId].points) + "</span></span>" +
            //                //"<span class='bubbleContainer'><span>Rank:</span><span class='bubble'>" + Math.round(data.hoverData[data.submissions.value.max.userId].rank) + "</span></span>" +
            //                //"<span class='bubbleContainer'><span>Badges:</span><span class='bubble'>" + Math.round(data.hoverData[data.submissions.value.max.userId].badgeCount) + "</span></span>" +
            //                // "<hr>" + "</div>" +
            //                //"<a href='/userFlow/profile/"+data.submissions.value.max.userId+"' class='button'>Profile</a>" +
            //                //"<a href='/userFlow/gameplan/"+data.submissions.value.max.userId+"' class='button'>Gameplan</a>" +
            //                //"<a onclick='comparePlayer(&quot;"+data.submissions.value.max.userId+"&quot;)' class='button'>Compare</a>" +
            //                //"</div>"
            //            },
            //            {
            //                label: "Average submissions",
            //                val: (submissions.value.avg/ data.daysPassed) + " / day",
            //                valLink: '/lms/lmsBranchReport',
            //            },
            //            {
            //                label: "Minimum submissions",
            //                val: (submissions.value.min.value/ data.daysPassed) + " / day",
            //                valLink: '/lms/lmsBranchReport',
            //                //TODO: Remove when activityData is available
            //                //placement:'left',
            //                //content:
            //                //"<div>" +
            //                //"<div class='row'>" +
            //                //"<img src='"+ data.hoverData[data.submissions.value.min.userId].image +"'>" +
            //                //"<p class='text'>"+ data.hoverData[data.submissions.value.min.userId].name +"</p>" +
            //                //"<p class='text'>"+ data.hoverData[data.submissions.value.min.userId].branch +"</p>" +
            //                //
            //                //"<span class='bubbleContainer'><span>Points:</span><span class='bubble'>" + Math.round(data.hoverData[data.submissions.value.min.userId].points) + "</span></span>" +
            //                //"<span class='bubbleContainer'><span>Rank:</span><span class='bubble'>" + Math.round(data.hoverData[data.submissions.value.min.userId].rank) + "</span></span>" +
            //                //"<span class='bubbleContainer'><span>Badges:</span><span class='bubble'>" + Math.round(data.hoverData[data.submissions.value.min.userId].badgeCount) + "</span></span>" +
            //                // "<hr>" + "</div>" +
            //                //"<a href='/userFlow/profile/"+data.submissions.value.min.userId+"' class='button'>Profile</a>" +
            //                //"<a href='/userFlow/gameplan/"+data.submissions.value.min.userId+"' class='button'>Gameplan</a>" +
            //                //"<a onclick='comparePlayer(&quot;"+data.submissions.value.min.userId+"&quot;)' class='button'>Compare</a>" +
            //                //"</div>"
            //            },
            //            {
            //                label: "Maximum calls",
            //                val: (calls.value.max.value/ data.daysPassed) + " / day",
            //                valLink: '/lms/lmsBranchReport',
            //                //TODO: Remove when activityData is available
            //                //placement:'right',
            //                //content:
            //                //"<div>" +
            //                //"<div class='row'>" +
            //                //"<img src='"+ data.hoverData[data.calls.value.max.userId].image +"'>" +
            //                //"<p class='text'>"+ data.hoverData[data.calls.value.max.userId].name +"</p>" +
            //                //"<p class='text'>"+ data.hoverData[data.calls.value.max.userId].branch +"</p>" +
            //                //
            //                //"<span class='bubbleContainer'><span>Points:</span><span class='bubble'>" + Math.round(data.hoverData[data.calls.value.max.userId].points) + "</span></span>" +
            //                //"<span class='bubbleContainer'><span>Rank:</span><span class='bubble'>" + Math.round(data.hoverData[data.calls.value.max.userId].rank) + "</span></span>" +
            //                //"<span class='bubbleContainer'><span>Badges:</span><span class='bubble'>" + Math.round(data.hoverData[data.calls.value.max.userId].badgeCount) + "</span></span>" +
            //                // "<hr>" + "</div>" +
            //                //"<a href='/userFlow/profile/"+data.calls.value.max.userId+"' class='button'>Profile</a>" +
            //                //"<a href='/userFlow/gameplan/"+data.calls.value.max.userId+"' class='button'>Gameplan</a>" +
            //                //"<a onclick='comparePlayer(&quot;"+data.calls.value.max.userId+"&quot;)' class='button'>Compare</a>" +
            //                //"</div>"
            //            },
            //            {
            //                label: "Average calls",
            //                valLink: '/lms/lmsBranchReport',
            //                val: (calls.value.avg/ data.daysPassed) + " / day",
            //            },
            //            {
            //                label: "Minimum calls",
            //                valLink: '/lms/lmsBranchReport',
            //                val: (calls.value.min.value/ data.daysPassed) + " / day",
            //                //TODO: Remove when activityData is available
            //                //placement:'left',
            //                //content:
            //                //"<div>" +
            //                //"<div class='row'>" +
            //                //"<img src='"+ data.hoverData[data.calls.value.min.userId].image +"'>" +
            //                //"<p class='text'>"+ data.hoverData[data.calls.value.min.userId].name +"</p>" +
            //                //"<p class='text'>"+ data.hoverData[data.calls.value.min.userId].branch +"</p>" +
            //                //
            //                //"<span class='bubbleContainer'><span>Points:</span><span class='bubble'>" + Math.round(data.hoverData[data.calls.value.min.userId].points) + "</span></span>" +
            //                //"<span class='bubbleContainer'><span>Rank:</span><span class='bubble'>" + Math.round(data.hoverData[data.calls.value.min.userId].rank) + "</span></span>" +
            //                //"<span class='bubbleContainer'><span>Badges:</span><span class='bubble'>" + Math.round(data.hoverData[data.calls.value.min.userId].badgeCount) + "</span></span>" +
            //                // "<hr>" + "</div>" +
            //                //"<a href='/userFlow/profile/"+data.calls.value.min.userId+"' class='button'>Profile</a>" +
            //                //"<a href='/userFlow/gameplan/"+data.calls.value.min.userId+"' class='button'>Gameplan</a>" +
            //                //"<a onclick='comparePlayer(&quot;"+data.calls.value.min.userId+"&quot;)' class='button'>Compare</a>" +
            //                //"</div>"
            //            }
            //        ]
            //    };
            //    if($scope.kpiId){
            //        $scope.tabs[i].section1[2].val = ((calls.points.value + submissions.points.value + leads.points.value)/ data.daysPassed) + "/ day";
            //        $scope.tabs[i].section1[2].delta = ((calls.points.delta + submissions.points.delta + leads.points.delta) / data.daysPassed) + "/ day";
            //    }
            //}
            if($scope.user == 'frontline') {
                $scope.tabs[++i] = {
                    title: "My team's summary",
                    section1: [
                        {
                            label: "My manager's rank",
                            val: data.manager.points.ranks.value,
                            delta: data.manager.points.ranks.delta
                        },
                        {
                            label: "My manager's decile",
                            val: data.manager.points.ranks.decile.value,
                            delta: data.manager.points.ranks.decile.delta
                        }
                    ],
                    badges:data.manager.badges
                };
            }
            if($scope.type == 'kpi' && $scope.user != 'seniorManager') {
                $scope.tabs[++i] = {
                    title: "Social graph",
                    section2: [
                        {
                            label: data.mentions + " mentions this " + $scope.cycleType
                        }
                    ]
                };
                if($scope.user == 'frontline') {
                    $scope.tabs[i].section1 = [
                        {
                            label:data.manager.mentions + " mentions this " + $scope.cycleType,
                        }
                    ];
                }
            }
            else if($scope.user != 'seniorManager'){
                $scope.tabs[++i] = {
                    title: "Social graph",
                    section2: [
                        {
                            label: data.likes + " likes this " + $scope.cycleType
                        },
                        {
                            label: data.mentions + " mentions this " + $scope.cycleType
                        },
                        {
                            label: data.followers + " players followed you this " + $scope.cycleType
                        }
                    ]
                };
                if($scope.user == 'frontline') {
                    $scope.tabs[i].section1 = [
                        {
                            label: data.manager.likes + " likes this " + $scope.cycleType
                        },
                        {
                            label: data.manager.mentions + " mentions this " + $scope.cycleType
                        },
                        {
                            label: data.manager.followers + " players followed you this " + $scope.cycleType
                        }
                    ];
                }
            }
            $scope.table = {};
            $scope.table.headingLeft = "Areas to Cheer";
            $scope.table.headingRight = "Areas of Concern";
            $scope.table.left = [];
            $scope.table.right = [];
            var deltaValues;
            if($scope.user == 'seniorManager')
                deltaValues =  {ranks: data.points.ranks[0].delta,points: data.points.delta,decile: data.points.ranks[0].decile.delta,percentageCompleted: data.percentageCompleted.delta};
            else
                deltaValues = {ranks: data.points.ranks.delta,points: data.points.delta,decile: data.points.ranks.decile.delta,percentageCompleted: data.percentageCompleted.delta};
            var links = {ranks:"/userFlow/leaderboard",points:"/userFlow/leaderboard",decile:"/userFlow/badgeCorner",percentageCompleted:"/userFlow/leaderboard"}
            var cheerLabels = {ranks:' ranks gained', points:' increase in '+strings.POINTS.value+' earned', decile:' deciles improvement in performance', percentageCompleted:'% increase in target completion'};
            var concernLabels = {ranks: ' ranks lost', points: ' decrease in '+strings.POINTS.value+' earned', decile: ' deciles fall in performance',percentageCompleted: '% decrease in percentage completion'};

            for(var field in deltaValues){
                if(deltaValues[field] > 0) {
                    $scope.table.left.push({value:Math.round(deltaValues[field]),postfixLabel:cheerLabels[field]+' this '+$scope.cycleType,valLink:links[field]});
                }
                else if(deltaValues[field] < 0){
                    $scope.table.right.push({value:Math.abs(Math.round(deltaValues[field])),postfixLabel:concernLabels[field]+' this '+$scope.cycleType,valLink:links[field]});
                }
            }
            if(!data.name) data.name = 'overall';
            if(data.swot == 's'){
                $scope.table.left.push({value:'Strength Zone' ,postfixLabel:' performance is likely to be achieved for '+data.name});
            }
            else if(data.swot == 't'){
                $scope.table.right.push({value:'Threat Zone' ,postfixLabel:' performance is likely to be achieved for '+data.name});
            }
            if(data.nearMeBadges.length == 0 && !data.achievedAllBadges)
                $scope.table.right.push({prefixLabel:'No badges in contention'});
            else if(!data.achievedAllBadges){
                $scope.table.left.push({value: data.nearMeBadges.length, content: getBadgePopoverHtml(data.nearMeBadges), postfixLabel:'Badges likely to be won in this cycle'});
            }
            if(data.achievedAllBadges){
                $scope.table.left.push({prefixLabel:'You have achieved all the badges in this cycle'});
            }
            if(data.target > data.percentageCompleted.value){
                var missValue = Math.round(data.target - data.percentageCompleted.value);
                $scope.table.right.push({value:missValue ,postfixLabel:'miss forecasted achievement from target'});
            }
            if($scope.type == 'overall'){
                var badges = ['Silver','Gold','Platinum'];
                $http.post("/api/rollingBadges/users", {userId: Auth.getCurrentUser()._id}).then(function(rollingBadges) {
                    rollingBadges=rollingBadges.data;
                    _(rollingBadges).forEach(function (rollingBadge) {
                        if ((rollingBadge.badgeCategory === "level") && (rollingBadge.badgeType === "overall")) {
                            var value;
                            switch(rollingBadge.shortName){
                                case 'platinum': value = 'Platinum';break;
                                case 'gold': value = 'Gold';break;
                                case 'silver': value = 'Silver';break;
                            }
                            if(value){
                                $scope.table.left.push({value:value ,postfixLabel:' status is likely to be maintained'});
                            }
                        }
                    });
                });
            }

        }

        function huddleBoardInit(){
            $scope.tabs = [];
            var i = 0;
            $scope.tabs[i] = {
                title: 'Rank',
                section1:[
                    {
                        label: 'Bank-wide Rank',
                        val: data.points.ranks.value,
                        delta: data.points.ranks.delta,
                        delta2: data.points.ranks.delta + data.prevCycle.points.ranks.delta,
                        deltaLink: "/userFlow/leaderboard",
                        valLink: "/userFlow/leaderboard"
                    }
                ],
                section2: [
                    {
                        label: "Top Rank",
                        val:data.points.max.rank,
                        placement:'right',
                        userId: data.points.max.userId,//getPopoverHTML(data.points.max.userId,data.hoverData)
                    },
                    {},
                    {
                        label: "Bottom Rank",
                        val:data.points.min.rank,
                        placement:'left',
                        userId: data.points.min.userId,//getPopoverHTML(data.points.min.userId,data.hoverData)
                    }
                ]

            };
            $scope.tabs[++i] = {
                title: 'Badges',
                section1:[
                    {
                        label: 'Badges',
                        val: data.badgeCount.value,
                        delta: data.badgeCount.delta,
                        delta2: data.badgeCount.delta + data.prevCycle.badgeCount.delta,
                        deltaLink: "/userFlow/leaderboard",
                        valLink: "/userFlow/leaderboard"
                    }
                ],
                section2: [
                    {
                        label: "Top Rank",
                        val:data.badgeCount.max.value,
                        placement:'right',
                        userId: data.badgeCount.max.userId,//getPopoverHTML(data.badgeCount.max.userId,data.hoverData)
                    },
                    {},
                    {
                        label: "Bottom Rank",
                        val:data.badgeCount.min.value,
                        placement:'left',
                        userId: data.badgeCount.min.userId,//getPopoverHTML(data.badgeCount.min.userId,data.hoverData)
                    }
                ]
            };
            $scope.tabs[++i] = {
                title: "Decile Performance",
                section1: [
                    {
                        label: "Current Decile",
                        val: data.points.ranks.decile.value,
                        delta: data.points.ranks.decile.delta,
                        delta2: data.points.ranks.decile.delta + data.prevCycle.points.ranks.decile.delta,
                        deltaLink: "/userFlow/leaderboard",
                        valLink: "/userFlow/leaderboard"
                    },
                ],
                section2: [
                    {
                        label: "Top decile in team",
                        val: data.points.max.decile,
                        placement:'right',
                        userId: data.points.max.userId,// getPopoverHTML(data.points.max.userId,data.hoverData)
                    },
                    {},
                    {
                        label: "Bottom decile in team" ,
                        val: data.points.min.decile,
                        placement:'left',
                        userId: data.points.min.userId,//getPopoverHTML(data.points.min.userId,data.hoverData)
                    }
                ]
            };
            if(data.points.ranks.decile.delta > 0 && data.prevCycle.points.ranks.decile.delta > 0){
                var form = "increasing";
            }
            else if(data.points.ranks.decile.delta > 0 || data.prevCycle.points.ranks.decile.delta > 0){
                var form = "steady";
            }
            else{
                var form = 'decreasing';
            }
            $scope.tabs[++i] = {
                "title": 'My Form in the game',
                'section1':[
                    {
                        label:'Your form in the game is ' + form,
                        delta:data.prevCycle.points.ranks.decile.delta
                    }
                ]
            };
            $scope.table = {};
            $scope.table.headingLeft = "Strength";
            $scope.table.headingRight = "Weakness";
            //TODO: remove comment when swot is available
            /*
             $scope.table.left = _.chain(data.kpiData).filter(function(kpi){ return kpi.swot == 's'}).map(function(kpi){return kpi.name});
             $scope.table.right = _.chain(data.kpiData).filter(function(kpi){ return kpi.swot == 'w'}).map(function(kpi){return kpi.name});
             */

        }

        function getBadgePopoverHtml(badges){
            var badgeString = '<div>';
            badges.forEach(function(badge){
                if(badge.badgeCategory == 'overall')
                    badgeString+=' <span class="badgeContainer"><img style = "display: inline-block; height:58px; width:58px;" src="'+badge.imageUrl+'" height="58px" width="58px" />'+
                        '<p class="white badgeLabel">'+badge.label+'</p><p class="badgeName">'+badge.name.substring(badge.name.indexOf(" "))+'</p>'+
                        '<p class="badgePoints"> Req'+$scope.strings.POINTS.value+': +'+Math.round(badge.pointsGap)+'</p>'+'</span>'
                else
                    badgeString+=' <span class="badgeContainer"><img style = "display: inline-block; height:58px; width:58px;" src="'+badge.imageUrl+'" height="58px" width="58px" />'+
                        '<p class="black badgeLabel" style="top:10px">'+badge.label+'</p><p class="badgeName">'+badge.name.substring(badge.name.indexOf(" "))+'</p>'+
                        '<p class="badgePoints"> Req '+$scope.strings.POINTS.value+': +'+Math.round(badge.pointsGap)+'</p>'+'</span>'
            });
            badgeString += "</div>";
            return badgeString
        }

        function initLeads(){
            $scope.tabs = [];
            var kpiId;
            if($scope.type == 'kpi'){
                kpiId = $scope.kpi;
            }else{
                kpiId = 'overall';
            }
            activityUpdates.getData(kpiId,function(data){
                $scope.tabs[0] = {};
                $scope.tabs[0].title = "Leads Summary";
                $scope.tabs[0].section1 = [];
                $scope.tabs[0].section1.push({
                    title:'Total, #',
                    val:data.activeLeads,
                    bubble1:data.p,
                    bubble2:data.w,
                    bubble3:data.y,
                    valLink:'lms.listLead({filter:"active"})',
                    bubble1Link:'lms.listLead({filter:"active",dateFilter:"past",dateFilterField:"update"})',
                    bubble2Link:'lms.listLead({filter:"active",dateFilter:"week",dateFilterField:"update"})',
                    bubble3Link:'lms.listLead({filter:"active",dateFilter:"yesterday",dateFilterField:"update"})'
                });
                $scope.tabs[0].section1.push({
                    title:'Total, #',
                    val:data.hot,
                    valLink:'lms.listLead({filter:"hot"})',
                    bubble1link:'lms.listLead({filter:"hot",dateFilter:"past",dateFilterField:"update"})',
                    bubble2Link:'lms.listLead({filter:"hot",dateFilter:"week",dateFilterField:"update"})',
                    bubble3Link:'lms.listLead({filter:"hot",dateFilter:"yesterday",dateFilterField:"update"})'
                }); // hot leads
                $scope.tabs[0].section1.push({
                    title:'Lost Leads, #',
                    val:data.lost,
                    valLink:'lms.listLead({filter:"archived"})'
                });
                $scope.tabs[0].section1.push({
                    title:'Follow up Missed, #',
                    val:data.followUpMissed,
                    valLink:'lms.listLead({filter:"followUpMissed"})'
                });
                $scope.tabs[0].section1.push({
                    title:'Yet to Contact, #',
                    val:data.activeYetToContact,
                    valLink:'lms.listLead({filter:"yetToContact"})'
                });
                $scope.tabs[0].section1.push({
                    title:'Yet to Contact, #',
                    val:data.hotYetToContact,
                    valLink:'lms.listLead({filter:"hotYetToContact"})'
                });
                $scope.tabs[0].section1.push({
                    title:'Risk of Loss, #',
                    val:data.riskOfLoss,
                    valLink:'lms.listLead({filter:"riskOfLoss"})'
                });
                $scope.tabs[0].section1.push({
                    title:'Follow up Pending, #',
                    val:data.followUpPending,
                    valLink:'lms.listLead({filter:"followUp"})'
                });
            });
        }
        var callDailyFeeds = function (){
            $scope.period = $scope.cycleType;
            if($scope.period == 'daily'){
                $scope.periodText = 'yesterday';
            }else if($scope.period == 'weekly'){
                $scope.periodText = 'this week';
            }else{
                $scope.periodText = 'this month';
            }
            if($scope.hideReportees != undefined && $scope.player != undefined) {
                dailyFeed.getFeed($scope.player, ($scope.jobRole ? $scope.jobRole._id : null), $scope.kpi, $scope.hideReportees, $scope.period, $scope.lab.isLive).then(function (result) {
                    $scope.dailyFeed = result.feed;
                    $scope.myLabFeed = result.lab;
                    $scope.labData = result.labData;

                });
            }
        };

        $scope.$watch('isWeekly',function(newValue,oldValue){
            if (newValue != oldValue){
                if ($scope.tabType == 'leads') {
                    initLeads();
                } else if ($scope.tabType == 'feed') {
                    callDailyFeeds();
                }
                else {
                    frontEndCalls();
                }
            }
        });
        $scope.$watch('lab.isLive', function(newValue, oldValue){
            if (newValue!=oldValue) {
                callDailyFeeds();
            }
        });
        $scope.$watch('player', function(newValue, oldValue){
            if (newValue && newValue!=oldValue) {
                callDailyFeeds();
            }
        });
        $scope.$watch('cycleType', function(newValue, oldValue){
            if (newValue && newValue!=oldValue) {
                callDailyFeeds();
                $scope.setCycleDescName();
            }
        });

        $scope.$watch('jobRole',function(newValue,oldValue){
            if (newValue!=oldValue && ($scope.tabType == 'feed')) {
                callDailyFeeds();
            }
        });
        $scope.$watch('hideReportees',function(newValue,oldValue){
            if (newValue && newValue!=oldValue){
                if ($scope.tabType == 'feed') {
                    callDailyFeeds();
                }
                else {
                    init();
                }
            }
        });

        function getDailyFeedsLeadData(feeds){
            var leads = _.filter(feeds,{type:'lead'});
            if(leads.length > 0) {
                var calls = _.chain(leads).filter({subType: 'Call'}).map('entityId').value();
                var meetings = _.chain(leads).filter({subType: 'Meeting'}).map('entityId').value();
                var emails = _.chain(leads).filter({subType: 'E-mail'}).map('entityId').value();
                var received = _.chain(leads).filter({subType: 'Documents & Application received'}).map('entityId').uniq().value();
                var submitted = _.chain(leads).filter({subType: 'Application submitted'}).map('entityId').uniq().value();
                var sold = _.chain(leads).filter({subType: 'Disbursed/sold'}).map('entityId').uniq().value();
                return {calls:calls,meetings:meetings,emails:emails,received:received,submitted:submitted,sold:sold};
            }else{
                return false;
            };
        }

        function getDailyFeedsBadgeData(feeds){
            var allBadges = _.filter(feeds,{type:'badge'});
            if(allBadges.length > 0){
                var activityBadges = _.filter(allBadges,function(feed){
                    return feed.value.badgeType == 'activity';
                });
                var activityBadgeReportees = _.uniqBy(activityBadges,function(badgeFeed){
                    return badgeFeed.userId._id;
                });
                var performanceBadges = _.filter(allBadges,function(feed){
                    return feed.value.badgeCategory == 'business' && feed.value.badgeType == 'kpi';
                });
                var performanceBadgeReportees = _.uniqBy(performanceBadges,function(badgeFeed){
                    return badgeFeed.userId._id;
                });
                var excellenceBadges = _.filter(allBadges,function(feed){
                    return feed.value.badgeCategory == 'overall' &&  feed.value.badgeType == 'total';
                });
                var excellenceBadgeReportees = _.uniqBy(excellenceBadges,function(badgeFeed){
                    return badgeFeed.userId._id;
                });
                var impactPointBadges  = _.filter(allBadges, function(feed){
                    return feed.value.badgeCategory == 'overall' && feed.value.badgeType == 'overallBusiness';
                });
                var impactPointBadgeReportees = _.uniqBy(impactPointBadges,function(badgeFeed){
                    return badgeFeed.userId._id;
                });

                var activityPointBadges  = _.filter(allBadges, function(feed){
                    return feed.value.badgeCategory == 'overall' && feed.value.badgeType == 'overallActivity';
                });
                var activityPointBadgeReportees = _.uniqBy(activityPointBadges,function(badgeFeed){
                    return badgeFeed.userId._id;
                });
                var levelBadges = _.filter(allBadges,function(feed){
                    return feed.value.badgeType == 'level';
                });
                var levelBadgeReportees = _.uniqBy(levelBadges,function(badgeFeed){
                    return badgeFeed.userId._id;
                });
                var conversionBadges = _.filter(allBadges,function(feed){
                    return feed.value.badgeCategory == 'conversion';
                });
                var conversionBadgeReportees = _.uniqBy(conversionBadges,function(badgeFeed){
                    return badgeFeed.userId._id;
                });
                var productivityBadges = _.filter(allBadges,function(feed){
                    return feed.value.badgeType == 'productivity';
                });
                var productivityBadgeReportees =  _.uniqBy(productivityBadges,function(badgeFeed){
                    return badgeFeed.userId._id;
                });
                return {
                    activityBadges:activityBadges,
                    activityBadgeReportees:activityBadgeReportees,
                    performanceBadges:performanceBadges,
                    performanceBadgeReportees:performanceBadgeReportees,
                    excellenceBadges:excellenceBadges,
                    excellenceBadgeReportees:excellenceBadgeReportees,
                    impactPointBadges:impactPointBadges,
                    impactPointBadgeReportees:impactPointBadgeReportees,
                    activityPointBadges:activityPointBadges,
                    activityPointBadgeReportees:activityPointBadgeReportees,
                    levelBadges:levelBadges,
                    levelBadgeReportees:levelBadgeReportees,
                    productivityBadges:productivityBadges,
                    productivityBadgeReportees:productivityBadgeReportees,
                    conversionBadges:conversionBadges,
                    conversionBadgeReportees:conversionBadgeReportees,
                    allBadges:allBadges
                };
            }
        }

        function getDailyFeedsLevelData(feeds){
            var allLevels = _.filter(feeds,{type:'level'});
            if(allLevels.length > 0){
                var silver = _.filter(allLevels,function(levelFeed){
                    return levelFeed.value.name == 'Silver';
                });
                var gold = _.filter(allLevels,function(levelFeed){
                    return levelFeed.value.name == 'Gold';
                });
                var platinum = _.filter(allLevels,function(levelFeed){
                    return levelFeed.value.name == 'Platinum';
                });
                return {
                    silver:silver,
                    gold:gold,
                    platinum:platinum,
                    allLevels:allLevels
                };
            }
        }

        function getDailyFeedsRankData(feeds){
            var allRanks = _.filter(feeds,function(feed){return feed.type == 'rank' || feed.type == 'delta'});
            if(allRanks.length > 0){
                var top = _.filter(allRanks,{type:'rank'});
                var improve = _.filter(allRanks,{type:'delta'});
                var daily = _.filter(improve,{subType:'daily'});
                var weekly = _.filter(improve,{subType:'weekly'});
                var monthly = _.filter(improve,{subType:'monthly'});
                return {
                    top:top,
                    daily:daily,
                    weekly:weekly,
                    monthly:monthly,
                    allRanks:allRanks
                };
            }
        }

        function getDailyFeedsSwotData(feeds) {
            var allSwot = _.filter(feeds,{type:'swot'});
            if(allSwot.length > 0){
                var s = _.filter(allSwot,{value:'s'});
                var o = _.filter(allSwot,{value:'o'});
                return {
                    s:s,
                    o:o,
                    allSwot:allSwot
                }
            }
        }

        function getDailyFeedsSocialData(feeds){
            var allSocialData = _.filter(feeds,function(feed){return feed.type == 'like' || feed.type == 'comment'});
            if(allSocialData.length > 0){
                var like = _.filter(allSocialData,{type:'like'});
                var likedPeople = _.uniqBy(like, function(likeFeed){
                    return likeFeed.userId._id;
                });
                var comment = _.filter(allSocialData,{type:'comment'});
                var commentedPeople = _.uniqBy(comment, function(likeFeed){
                    return likeFeed.userId._id;
                });
                return {
                    like:like,
                    likedPeople:likedPeople,
                    comment:comment,
                    commentedPeople:commentedPeople
                };
            }
        }

        function getDailyFeedGameplanData(feeds) {
            var allGameplan = _.filter(feeds,function(feed){ return feed.type == 'gameplan' || feed.type == 'compliance'});
            if(allGameplan.length > 0){
                var weekly = _.filter(allGameplan,{subType:'weekly'});
                var monthly = _.filter(allGameplan,{subType:'monthly'});
                var nonCompliant = _.filter(allGameplan,{subType:'false'});
                return{
                    weekly:weekly,
                    monthly:monthly,
                    nonCompliant:nonCompliant,
                    allGameplan:allGameplan
                }
            }
        }

        function getDailyFeedRedFlagData(feeds){
            var allRedFlag = _.filter(feeds,{type:'redFlag'});
            if(allRedFlag.length > 0){
                 var one = _.filter(allRedFlag,function(badgeFeed){
                    return badgeFeed.value.metric == 'topQuartile'
                 });
                var two = _.filter(allRedFlag, function(badgeFeed){
                    return badgeFeed.value.metric == 'topDecile'
                });
                var three = _.filter(allRedFlag, function(badgeFeed){
                    return badgeFeed.value.metric == 'top1'
                })
                return {
                    one:one,
                    two:two,
                    three:three,
                    allRedFlag:allRedFlag
                };
            }
        }

        function getDailyFeedFormData(feeds){
            var allFormData = _.filter(feeds,{type:'form'});
            if(allFormData.length > 0){
                return {
                    allFormData: allFormData
                }
            }
        }

        function getDailyFeedMTDData(feeds){
            var allMTDData = _.filter(feeds,{type:'MTD'});
            if(allMTDData.length > 0){
                var MTDData = [];
                allMTDData = _.groupBy(allMTDData,'kpiId');
                _.forOwn(allMTDData, function(value, key) {
                    var selectedKpi = p0.getKpi(key);
                    var sum = _.sumBy(value,'value');
                    var percentageSum = _.sumBy(value,function(feed){return Number(feed.subType)});
                    MTDData.push({
                        kpi:selectedKpi.name,
                        kpiId:selectedKpi._id,
                        MTD:sum/value.length,
                        percentage:percentageSum/value.length,
                        allKpiData:value
                    });
                });
                return MTDData;
            }
        }

        function getDailyFeedCallData(feeds){
            var allCallData = _.filter(feeds,{type:'call'});
            if(allCallData.length > 0){
                var callData = [];
                allCallData = _.groupBy(allCallData,'kpiId');
                _.forOwn(allCallData, function(value, key) {
                    var selectedKpi = p0.getKpi(key);
                    var sum = _.sumBy(value,'value');
                    callData.push({
                        kpi:selectedKpi.name,
                        kpiId:selectedKpi._id,
                        calls:sum / value.length,
                        allCallData:value
                    });
                });
                return callData;
            }
        }

        function getDailyFeedSubmissionData(feeds){
            var allSubmissionData = _.filter(feeds,{type:'submission'});
            if(allSubmissionData.length > 0){
                var submissionData = [];
                allSubmissionData = _.groupBy(allSubmissionData,'kpiId');
                _.forOwn(allSubmissionData, function(value, key) {
                    var selectedKpi = p0.getKpi(key);
                    var sum = _.sumBy(value,'value');
                    submissionData.push({
                        kpi:selectedKpi.name,
                        kpiId:selectedKpi._id,
                        submissions:sum/ value.length,
                        allSubmissionData:value
                    });
                });
                return submissionData;
            }
        }

        $scope.social = function(action,dailyFeedData,type){
            dailyFeed.social(action,dailyFeedData,type,null).then(function(){
                checkAllSocial();
            });
        };

        function checkAllSocial(){
            $scope.feedData.forEach(function(data){
                if(data.badgeData){
                    data.badgeData.likedAll = _.every(data.badgeData.allBadges,'liked');
                    data.badgeData.commentedAll = _.every(data.badgeData.allBadges,'commented');
                }
                if(data.levelData){
                    data.levelData.likedAll = _.every(data.levelData.allLevels,'liked');
                    data.levelData.commentedAll = _.every(data.levelData.allLevels,'commented');
                }
                if(data.rankData){
                    data.rankData.likedAll = _.every(data.rankData.allRanks,'liked');
                    data.rankData.commentedAll = _.every(data.rankData.allRanks,'commented');
                }
                if(data.swotData){
                    data.swotData.likedAll = _.every(data.swotData.allSwot,'liked');
                    data.swotData.commentedAll = _.every(data.swotData.allSwot,'commented');
                }
                if(data.complianceData){
                    data.complianceData.likedAll = _.every(data.complianceData.allCompliance,'liked');
                    data.complianceData.commentedAll = _.every(data.complianceData.allCompliance,'commented');
                }
                if(data.redFlagData){
                    data.redFlagData.likedAll = _.every(data.redFlagData.allRedFlag,'liked');
                    data.redFlagData.commentedAll = _.every(data.redFlagData.allRedFlag,'commented');
                }
                if(data.formData){
                    data.formData.likedAll = _.every(data.formData.allFormData, 'liked');
                    data.formData.commentedAll = _.every(data.formData.allFormData, 'commented');
                }
                if(data.DailyData){
                    data.DailyData.forEach(function(daily){
                        if(daily.MTDData) {
                            daily.MTDData.likedAll = _.every(daily.MTDData.allKpiData, 'liked');
                            daily.MTDData.commentedAll = _.every(daily.MTDData.allKpiData, 'commented');
                        }
                        if(daily.callData) {
                            daily.callData.likedAll = _.every(daily.callData.allCallData, 'liked');
                            daily.callData.commentedAll = _.every(daily.callData.allCallData, 'commented');
                        }
                        if(daily.submissionData) {
                            daily.submissionData.likedAll = _.every(daily.submissionData.allSubmissionData, 'liked');
                            daily.submissionData.commentedAll = _.every(daily.submissionData.allSubmissionData, 'commented');
                        }
                    });
                }
            })

        }

        $scope.openDailyFeedModal = function(data,type){
            $mdDialog.open({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'dailyFeedController',
                size:'lg',
                resolve:{
                    data:function(){return data;},
                    onClose: function(){return checkAllSocial;},
                    type:function(){return type;}
                }
            });
        };

        $scope.openBulkCommentModal = function(dailyFeed,type){
            $mdDialog.open({
                templateUrl: autoImportNgTemplateLoaderTemplate2,
                controller: 'bulkCommentModalCtrl',
                size:'lg',
                resolve:{
                    dailyFeeds:function(){return dailyFeed;},
                    onClose: function(){return checkAllSocial;},
                    type:function(){return type;}
                }
            });
        };

        $scope.openDailyFeedModalPopup = function(feeds, type, subType, swotType){
            if($scope.hideReportees) return;
            var users = [];
            feeds.forEach(function(feed){
                if(type == 'delta'){
                    feed.userId.delta = feed.value;
                    users.push(feed.userId);
                } else if((type == 'swot' || type == 'swotDelta') && feed.value[swotType]){
                    feed.userId.swot = feed.value;
                    users.push(feed.userId);
                } else if(type == 'gameplan'){
                    users.push(feed.userId);
                }
            });
            $mdDialog.open({
                templateUrl: autoImportNgTemplateLoaderTemplate3,
                controller: 'playerListModalCtrl',
                size: 'md',
                resolve: {
                    playerList: function () {
                        return users;
                    },
                    valueType : function () {
                        return type;
                    },
                    title : function () {
                        if(type == 'delta')
                            return 'Players delta rank';
                        else if(type == 'gameplan')
                            return 'Players';
                        else if(type == 'swot')
                            return subType+' '+ $scope.strings.SWOT_FULL;
                        else if(type == 'swotDelta')
                            return subType+' '+ $scope.strings.SWOT_FULL+ ' Delta';
                    },
                }
            });
        };

        if ($scope.tabType == 'leads') {
            initLeads();
        } else if ($scope.tabType == 'feed') {
            callDailyFeeds();
        }else{
            frontEndCalls();
        }


        $scope.openLAB = function(){
            if($scope.me.roleType != 'frontline'){
                $mdDialog.open({
                    templateUrl: autoImportNgTemplateLoaderTemplate4,
                    controller: 'labModalController',
                    size:'xl',
                    resolve:{
                        myReporteeFeed: function () {
                            return $scope.labData;
                        },
                        cycleType : function () {
                            return $scope.period;
                        },
                        selectedKpi: function () {
                            return $scope.kpi;
                        },
                        isLive : function(){
                            return $scope.lab.live;
                        }
                    }
                });
            }
        }
    });

