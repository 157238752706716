'use strict';

angular.module('bitnudgeApp').controller('redirectConfirmationCtrl',function($scope, $mdDialog, ownData, teamData, widget, kpiId, externalParams, source, $state){
    if (widget.includes('lead')) {
        $scope.dataType = 'leads';
    } else if (widget.includes('ticket')) {
        $scope.dataType = 'tickets';
    } else if (widget.includes('customer')) {
        $scope.dataType = 'customers';
    }

    // var portfolioDialog = function (portfolioUser, tabKey, userViewConfigType) {
    //     var start = new Date();
    //     start.setHours(0, 0, 0, 0);
    //     var milliseconds = start.getTime();
    //     $mdDialog.show({
    //         templateUrl: 'components/scoreCard/portfolio/portfolioModal.html',
    //         controller: 'portfolioModalController',
    //         fullscreen: true, // Only for -xs, -sm breakpoints.
    //         resolve: {
    //             date: function () {
    //                 return milliseconds;
    //             },
    //             user: function () {
    //                 return portfolioUser;
    //             },
    //             tabKey: function () {
    //                 return tabKey;
    //             },
    //             userViewKey: function () {
    //                 return userViewConfigType;
    //             },
    //             unitChange: function () {
    //                 return false;
    //             },
    //             isAggregateView: function () {
    //                 return false;
    //             }
    //         },
    //         multiple: true
    //     });
    // }

    $scope.filterData = function(type) {
        $mdDialog.hide();
        var paramData = [];
        if (type === 'own') {
            paramData = ownData;
            var email = externalParams.userEmail.split('(')[0];
            externalParams.userEmail = `${email}(self)`;
        } else {
            paramData = teamData;
        }
        if (widget.includes('lead') || widget.includes('cash') || source === 'lead') {
            var params = {externalParams: externalParams};
            $state.go('lms.listLead', params);
        } else if (widget.includes('customer')) {
            var params = {externalParams: externalParams};
            $state.go('lms.customers', params);
        }
    }
    $scope.cancel = function() {
        $mdDialog.cancel();
    };
});

