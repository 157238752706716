const autoImportNgTemplateLoaderTemplate1 = require('../html/cneTab.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('cneTab', function(){
        return {
            name: 'cneTab',
            scope: {
                type: '@'
            },
            controller: 'cneTabCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
