'use strict'
angular.module('bitnudgeApp')
      .controller('challengeCtrl', function ($scope, $location, Auth, $stateParams, $http,  User,toaster) {
        $scope.thisCampaignId = $stateParams.campaignId;
        $http.post('/api/campaigns/show', {id:$scope.thisCampaignId}).then(function (campaign) {
          campaign=campaign.data;
            $scope.thisCampaign = campaign;
        });
        $scope.thrownusername = [];
        $scope.thrownPastusername = [];
        $scope.userDetails = [];
        $scope.message = 'Hello';
        $scope.isLoggedIn = Auth.isLoggedIn;
        $scope.isAdmin = Auth.isAdmin;
        $scope.getCurrentUser = Auth.getCurrentUser;
        $scope.user = Auth.getCurrentUser();

        $http.post('/api/challengeMasters/user/live', {userId:$scope.user._id}).then(function(challenges){
          challenges=challenges.data;
          $scope.liveChallenges=challenges;
          console.log($scope.liveChallenges);
          //socket.syncUpdates('liveChallenge',$scope.liveChallenges);
        });

        $scope.getLiveAcceptedChallenges=function(){
          //TODO: server implementation not done
          $http.post('/api/users/challenges/accepted/live', {userId: $scope.user._id}).then(function(challenges){
            challenges=challenges.data;
            $scope.liveAcceptedChallenges=challenges;
            //socket.syncUpdates('liveAcceptedChallenge',$scope.liveAcceptedChallenges);
          });
        };
        $scope.getLiveThrownChallenges=function(){
          $http.post('/api/challengeMasters/user/thrown/live',{userId: $scope.user._id}).then(function(challenges){
            challenges=challenges.data;
            $scope.liveThrownChallenges=challenges;
            //socket.syncUpdates('liveThrownChallenge',$scope.liveAcceptedChallenges);
          });
        };
        $scope.getLiveAcceptedChallenges();
        $scope.getLiveThrownChallenges();

        $scope.onCycleChanged=function(){
          $scope.getPastChallengesOfCycle($scope.selectedPrevCycle._id);
          $scope.getPastAcceptedChallengesOfCycle($scope.selectedPrevCycle._id);
          $scope.getPastThrownChallengesOfCycle($scope.selectedPrevCycle._id);
        };

        $http.post('/api/cycles/previouscycles').then(function(cycles){
          cycles=cycles.data;
          $scope.prevCycles=cycles;
          $scope.selectedPrevCycle=cycles[0];
          $scope.onCycleChanged();
          //socket.syncUpdates('prevCycle',$scope.prevCycles);
        });

        $scope.getPastChallengesOfCycle=function(cycleId){
          $http.post('/api/challengeMasters',{userId: $scope.user._id, cycleId}).then(function(cycles){
            cycles=cycles.data;
            console.log("past chall");
            console.log(cycles);
            $scope.pastChallenges=cycles;
            //socket.syncUpdates('pastChallenge',$scope.pastChallenge);
          });
        };

        $scope.getPastAcceptedChallengesOfCycle=function(cycleId){
          $http.post('/api/users/challenges/accepted', {userId: $scope.user._id, cycleId}).then(function(cycles){
            cycles=cycles.data;
            console.log("past accepted chall");
            console.log(cycles);
            $scope.pastAcceptedChallenges=cycles;
            //socket.syncUpdates('pastAcceptedChallenge',$scope.pastAcceptedChallenge);
          });
        };

        //this route does not exists
        $scope.getPastThrownChallengesOfCycle=function(cycleId){
          //TODO: server implementation not done
          $http.post('/api/challengeMasters/user/thrown/cycle/',{userId: $scope.user._id, cycleId}).then(function(cycles){
            cycles=cycles.data;
            console.log("past thrown chall");
            console.log(cycles);
            $scope.pastThrownChallenges=cycles;
            //socket.syncUpdates('pastThrownChallenge',$scope.pastThrownChallenge);
          });
        };



        $scope.getThrower=function(creator){
          if(!creator)
            return "Admin"
          else return creator;
        };
    });
