"use strict";

angular.module("bitnudgeApp").controller("addPrerequisite", function($scope, $mdDialog, DocumentService, context) {
	let activeTabId = 'misc';
	$scope.documentTypes = [];
	const inputTypes = [{id: 'text', name: "Text"}, {id: 'number', name: "Number"}, {id: 'date', name: "Date"}];
	$scope.inputTypes1 = [...inputTypes, {id: 'document', name: "Document"}];
	$scope.inputTypes2 = inputTypes;
	$scope.params = {
		pdf: true,
		image: true,
		additionalList : [],
		additional : {}
	};
	const init = () =>{
		DocumentService.getDocumentTypes(context)
			.then(list => {
				$scope.documentTypes = list;
				$scope.params.selectedDocType = list[0];
			})
			.catch(err => {
				console.log(err.message);
			});
	};

	$scope.tabChange = (tabId) => {
		activeTabId = tabId;
	};

	$scope.onSelectChange = () => {
		$scope.params.pdf = true;
		$scope.params.image = true;
		$scope.params.additional = {};
		$scope.params.additionalList = [];
	};

	$scope.addAdditionalField = () => {
		$scope.params.additional.id = new Date().getTime();
		$scope.params.additionalList.push(angular.copy($scope.params.additional));
		$scope.params.additional = {};
	};

	$scope.removeAdditionalField = (item) => {
		$scope.params.additionalList = $scope.params.additionalList.filter(ele => ele.id !== item.id)
	};

	$scope.onClose = () => {
		$mdDialog.cancel();
	};

	$scope.onSubmit = () => {
		let returnData;
		if (activeTabId === 'misc') {
			returnData = { existing: false, data: $scope.params}
		} else {
			returnData = { existing: true, data: $scope.params.selectedDocType}
		}
		$mdDialog.hide(returnData);
	};
	init();
});
