const autoImportNgTemplateLoaderTemplate1 = require('../html/playerComparisonMirrorChart.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('playerComparisonMirrorChart', function(){
        return {
            name: 'playerComparisonMirrorChart',
            scope: {
                data:                   '=',
                selectrow:              '=',
                isMenu:                 '=',
                selectedIndex:           '=',
                label:                   '=',
                content:                 '='
            },
            controller: 'playerComparisonMirrorChartCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
