const autoImportNgTemplateLoaderTemplate1 = require('../html/transaction/transactionTable.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('transactionTable', function () {
        return {
            name: 'transactionTable',
            scope: {
                transactions: '=',
                pagination: '=',
                apiLoaded: '=',
                previousPage: '=',
                nextPage: '=',
                fetchTransactions: '=',
                selected: '=',
                sortingObj: '='
            },
            controller: 'transactionTableCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1

        };
    });
