'use strict';
angular.module('bitnudgeApp')
    .service('rhsAnalyticsL2Activity', function (userAnalyticsService,$rootScope,leagueAnalytic,p0,$filter, $q) {
        let self = this;
        
        this.getData = function(user, kpi, cycle, type, isMeetings, perReportee){
            var returnData = {};
            return userAnalyticsService.fetchUserAnalytics(user._id,cycle._id).then(function(userAnalytic){
                var userLeagues = _.map(user.leagues,'leagueId');
                var leagueCycle = cycle.cycleType == 'daily'? p0.currentMonthlyCycle:cycle;
                return leagueAnalytic.fetchLeagueAnalyticsOfCycle(userLeagues,leagueCycle._id).then(function(leagueAnalytics) {
                    var bankLeagueAnalytic = leagueAnalytics[leagueAnalytics.length - 1];
                    var localLeague = leagueAnalytics[0];
                    var fieldName, card1Heading,card2Heading2;
                    fieldName = type;
                    var {percentage, rateMultiplier} = self.getPercentageAndMultiplierForType(type);
                    var currentActivity = _.find(p0.allActivities,{fieldName:fieldName});
                    card2Heading2 = currentActivity.ratePeriod?'('+currentActivity.ratePeriod+')':'';
                    card1Heading = currentActivity.name;

                    var rateDivider = self.getRateDivider(type, cycle);
                    //card1
                    // var activity, topDecile, bestLocal, selection = [];
                    var localLabel = 'Best in '+ localLeague.teamTypeName;
                    let params = {
                        userAnalytic, bankLeagueAnalytic, fieldName, localLeague, leagueAnalytics, kpi
                    }
                    if(kpi._id == 'overall'){
                        var { activity, topDecile, bestLocal, selection, max1 } = self.getAchievementInformtionForOverall(params);
                    }else{
                        var { activity, topDecile, bestLocal, selection, max1 } = self.getAchievementInformtionForKpi(params);
                        //card3
                        if(type != 'newLead'){
                            returnData.card3 = self.setCard3Data(userAnalytic, bankLeagueAnalytic, kpi);
                        }
                    }
                    returnData.card1 = self.setCard1Data({ card1Heading, currentActivity, localLabel, percentage, max1, activity, topDecile, bestLocal, selection});
                    var rateSelection = _.cloneDeep(selection);
                    rateSelection.forEach(function(option){
                        option.number =  (option.number/rateDivider)*rateMultiplier;
                    });
                    returnData.name = currentActivity.name;
                    if(currentActivity.activityType != "percentage" && type != 'throughput' && type != 'occupancy'){
                        returnData.card2 = self.setCard2Data({card2Heading2, rateDivider, rateMultiplier, activity, topDecile, max1, bestLocal, rateSelection, localLabel});
                    }
                    return $q.resolve(returnData);
                });
            });
        };

        this.getAchievementInformtionForKpi = ({userAnalytic, bankLeagueAnalytic, kpi, fieldName, localLeague, leagueAnalytics}) => {
            let activity, topDecile, bestLocal, selection = [];
            let max1 = 0;
            activity = _.find(userAnalytic.activityData,{fieldName:fieldName,kpiId:kpi._id}).value.value;
            if(activity > max1){max1 = activity;}
            topDecile = _.find(bankLeagueAnalytic.activityData,{fieldName:fieldName,kpiId:kpi._id}).value.topDecile;
            if(topDecile > max1){max1 = topDecile;}
            bestLocal = _.find(localLeague.activityData,{fieldName:fieldName,kpiId:kpi._id}).value.max.value;
            if(bestLocal > max1){max1 = bestLocal;}
            leagueAnalytics.forEach(function(leagueAnalytic){
                var leagueAvg = _.find(leagueAnalytic.activityData,{fieldName:fieldName,kpiId:kpi._id}).value.avg;
                if(leagueAvg > max1){max1 = leagueAvg;}
                selection.push({
                    label: leagueAnalytic.teamTypeName + ' Avg.',
                    number:$filter('number')(leagueAvg,0)
                });
            });
            return {
                activity, 
                topDecile, 
                bestLocal, 
                selection,
                max1
            }
        }

        this.setCard2Data = ({card2Heading2, rateDivider, rateMultiplier, max1, activity, topDecile, bestLocal, rateSelection, localLabel}) => {
            let card2 = {
                heading1:'Rate',
                heading2:card2Heading2,
                max:((max1 || 1) / rateDivider)*rateMultiplier,
                data:[
                    {
                        label:'Me',
                        number:$filter('number')((activity/rateDivider)*rateMultiplier,0),
                        labelColor:'blue-1',
                        graphColor:'blue-1'
                    },
                    {
                        label:'Bank Top 10%',
                        number:$filter('number')((topDecile/rateDivider)*rateMultiplier,0)
                    },
                    {
                        label:localLabel,
                        number:$filter('number')((bestLocal/rateDivider)*rateMultiplier,0)
                    },
                    {
                        selection:rateSelection
                    }
                ]
            };            
            return card2;
        }

        this.setCard3Data = (userAnalytic, bankLeagueAnalytic, kpi) => {
            var kpiData = _.find(userAnalytic.kpiData,{kpiId:kpi._id});
            var bankKpiData = _.find(bankLeagueAnalytic.kpiData,{kpiId:kpi._id});
            var winRate = kpiData.stagesData[0].conversionRatioFinal.value * 100;
            var callToMeeting = kpiData.activityConversionRatios.call.value / kpiData.activityConversionRatios.meeting.value * 100;
            var meetingToSubmission = kpiData.activityConversionRatios.meeting.value / kpiData.activityConversionRatios.submission.value * 100;
            var submissionWinRate = kpiData.activityConversionRatios.submission.value * 100;

            var bankCallToMeeting = bankKpiData.activityConversionRatios.call.topDecile / bankKpiData.activityConversionRatios.meeting.topDecile * 100;
            var bankMeetingToSubmission = bankKpiData.activityConversionRatios.meeting.topDecile / bankKpiData.activityConversionRatios.submission.topDecile * 100;
            var bankSubmissionWinRate = bankKpiData.activityConversionRatios.submission.topDecile * 100;
            let card3 = {
                heading1:'Conversion Rate',
                heading2:'(%)',
                percentage:'Win Rate '+ $filter('number')(winRate,2) + '%',
                data:[
                    [
                        {},
                        {
                            text:'Me',
                            color:'blue-1'
                        },
                        {
                            text:'Bank Top 10%'
                        }
                    ],
                    [
                        {
                            text:'Calls to Meetings'
                        },
                        {
                            circle: $filter('number')(callToMeeting,0)+'%',
                            color:'blue-1'
                        },
                        {
                            text:$filter('number')(bankCallToMeeting,2) +'%'
                        }
                    ],
                    [
                        {
                            text:'Meetings to Submissions'
                        },
                        {
                            circle: $filter('number')(meetingToSubmission,0)+'%',
                            color:'blue-1'
                        },
                        {
                            text:$filter('number')(bankMeetingToSubmission,2) +'%'
                        }
                    ],
                    [
                        {
                            text:'Submissions to Disbursal'
                        },
                        {
                            circle: $filter('number')(submissionWinRate,0) +'%',
                            color:'blue-1'
                        },
                        {
                            text:$filter('number')(bankSubmissionWinRate,2) +'%'
                        }
                    ]
                ]
            };
            return card3;
        }

        this.setCard1Data = ({card1Heading, currentActivity, percentage, max1, localLabel, activity, topDecile, bestLocal, selection}) => {
            let card1 = {
                heading1:card1Heading,
                heading2:'('+currentActivity.unit+')',
                percentage:percentage,
                max:max1 || 1,
                data:[
                    {
                        label:'Me',
                        number:$filter('number')(activity,0),
                        labelColor:'blue-1',
                        graphColor:'blue-1'
                    },
                    {
                        label:'Bank Top 10%',
                        number:$filter('number')(topDecile,0)
                    },
                    {
                        label:localLabel,
                        number:$filter('number')(bestLocal,0)
                    },
                    {
                        selection:selection
                    }
                ]
            };
            return card1;
        }

        this.getAchievementInformtionForOverall = ({userAnalytic, bankLeagueAnalytic, fieldName, localLeague, leagueAnalytics, }) => {
            let activity, topDecile, bestLocal, selection = [];
            let max1 = 0;
            activity = _.find(userAnalytic.activityData,function(activityData){
                    return activityData.fieldName == fieldName && !activityData.kpiId;
                }).value.value;
            if(activity > max1){max1 = activity;}
            topDecile = _.find(bankLeagueAnalytic.activityData,function(activityData){
                    return activityData.fieldName == fieldName && !activityData.kpiId;
                }).value.topDecile;
            if(topDecile > max1){max1 = topDecile;}
            bestLocal = _.find(localLeague.activityData,function(activityData){
                    return activityData.fieldName == fieldName && !activityData.kpiId;
                }).value.max.value;
            if(bestLocal > max1){max1 = bestLocal;}
            leagueAnalytics.forEach(function(leagueAnalytic){
                var leagueAvg = _.find(leagueAnalytic.activityData,function(activityData){
                        return activityData.fieldName == fieldName && !activityData.kpiId;
                    }).value.avg;
                if(leagueAvg > max1){max1 = leagueAvg;}
                selection.push({
                    label: leagueAnalytic.teamTypeName + ' Avg.',
                    number:$filter('number')(leagueAvg,0)
                });
            });
            return {
                activity, 
                topDecile, 
                bestLocal, 
                selection,
                max1
            }
        }

        this.getPercentageAndMultiplierForType = (type) => {
            let percentage, rateMultiplier;
            if (type == 'call') {
                percentage = 'Last: Call 3d ago ';
                rateMultiplier = 1;
            }else if(type == 'meeting'){
                percentage = 'Last: Meeting 3d ago ';
                rateMultiplier = 1;
            }else if(type == 'newLead'){
                percentage = 'Last: Meeting 3d ago ';
                rateMultiplier = 1;
            }else if(type == 'submission'){
                percentage = 'Last: Submission 3d ago ';
                rateMultiplier = 5;
            }else if(type == 'accuracyp'){
                percentage = '';
                rateMultiplier = 1;
            }else if(type == 'throughput'){
                percentage = '';
                rateMultiplier = 1;
            }else if(type == 'occupancy'){
                percentage = '';
                rateMultiplier = 1;
            }            
            return {
                percentage,
                rateMultiplier
            }
        }

        this.getRateDivider = (type, cycle) => {
            let rateDivider;
            if(type == 'accuracyp' || type == 'throughput'){
                rateDivider = 1;
            }
            else if(cycle.cycleType == 'monthly'){
                rateDivider = $rootScope.daysPassed;
            }else if(cycle.cycleType == 'weekly'){
                rateDivider = $rootScope.weeklyDaysPassed;
            }else{
                rateDivider = 1;
            }
            return rateDivider;
        }
    });

