'use strict';
angular.module('bitnudgeApp')
    .service('serviceManager', function (p0, SocketService, performanceBarometerService, p1, recommendation, dailyFeed, SwotService, userAnalyticsService, Usage, Reportees, TrendGraph, PipelineService) {
        this.logout = function(){
            Usage.pushUsage();
            p0.destroy();
            SocketService.destroy();
            performanceBarometerService.destroy();
            p1.destroy();
            recommendation.destroy();
            dailyFeed.destroy();
            SwotService.destroy();
            userAnalyticsService.destroy();
            Usage.stopInterval();
            Reportees.destroy();
            TrendGraph.destroy();
            PipelineService.destroy();
        }
    });
