const autoImportNgTemplateLoaderTemplate1 = require('../html/activityExtraDataModal.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('huddleBoardController',function($scope, $http, Auth, leadsSummaryFilter, $state, $stateParams, DTOptionsBuilder, $rootScope, p0, moment, $filter, huddleBoard, CurrentCycle, Kpi, $mdDialog) {
        $scope.activities = {};
        $scope.config = p0.config;
		$scope.strings = $rootScope.strings;

        function populateActivities() {
            var activities = _.cloneDeep(p0.allActivities);

            activities.forEach(function(activity){
                if(activity.fieldName){
                    $scope.activities[activity.fieldName] = {name:activity.name, icon: activity.icon, isValue: (activity.activityType !== 'percentage')};
                }
            });
        }

        populateActivities();
        $scope.me = Auth.getCurrentUser();
        $scope.selectedRole = $scope.me.organizationalTag.jobRole;
        $scope.config = $rootScope.config;
        $scope.hasHealthMetrics = false;

        if($scope.me.roleType == 'seniorManager'){
            $scope.roleTypes = [
                {
                    _id: 'manager',
                    name: $scope.strings.MANAGER.value
                },
                {
                    _id: 'frontline',
                    name: $scope.strings.FRONTLINE.value
                }
            ];

            $scope.selectedRoleType = $scope.roleTypes[0];
            $scope.playTypes = p0.allPlayTypes;
            $scope.selectedPlayType = $scope.playTypes[0];
        } else if ($scope.me.roleType == 'manager') {
            $scope.selectedRoleType = {
                _id: 'frontline',
                name: $scope.strings.FRONTLINE.value
            };

            $scope.playTypes = p0.allPlayTypes;
            $scope.selectedPlayType = $scope.playTypes[0];
        }


        p0.myPlayTypes.forEach(function(playType){
            if(playType.healthMetrics && playType.healthMetrics.length > 0){
                $scope.hasHealthMetrics = true;
            }
        });

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('info', false)
            .withOption('paging', false)
            .withOption('searching', false)
            .withOption("bSort", false);
        $scope.teamSummaryData = {};
        $scope.mtdText = 'MTD';

        function setKpiDropdown(callback){
            let kpis = _.cloneDeep(p0.myKpis);
            if(p0.config.agentOnBoardingProductKpi){
                kpis = p0.myKpis.filter(k => k.kpiCode !== p0.config.agentOnBoardingProductKpi)
            }
            $scope.kpis = kpis;

            $scope.selectedKpi = $scope.kpis[0];
            if(p0.config.showOverallHuddleboard){
                $scope.kpis.unshift({name:'Overall',_id:'overall'});
            }
            return callback(true);
        }

        function loadInit(){
            $scope.dataLoaded = false;

            setKpiDropdown(function(result){
                if(!$stateParams.kpiId || $stateParams.kpiId == 'overall'){
                    $scope.isOverAll = true;
                    $scope.selectedKpi = $scope.kpis[0];
                }else{
                    $scope.isOverAll = false;
                    $scope.selectedKpi = _.find($scope.kpis, {_id: $stateParams.kpiId});
                }

                if(!$scope.selectedKpi){
                    $scope.selectedKpi = $scope.kpis[0];
                }

                $scope.lastSyncDate = $rootScope.lastUpdatedAtDate;
                $scope.showLastSync = true;
                $scope.isRate = {rate:false};

                if($scope.me.roleType == 'manager'){
                    $scope.bankTeamName = _.find($scope.me.leagues, {teamType:'bank'}).teamTypeName;
                } else if($scope.me.roleType == 'seniorManager'){
                    let teams;
                    if($scope.me.teams.length){
                        teams = $scope.me.teams
                    }else if($scope.me.leagues.length){
                        teams = $scope.me.leagues;
                    }else{
                        teams = $scope.me.teamsUnder;
                    }
                    $scope.bankTeamName = _.find(teams, {teamType:'bank'}).teamTypeName;
                }

                if($scope.selectedKpi._id != 'overall'){
                    init();
                }
            })
        }

        $scope.lastSyncDate = $rootScope.lastUpdatedAtDate;
        $scope.showLastSync = true;
        $scope.isRate = {rate:false};

        if($scope.me.roleType == 'manager'){
            $scope.bankTeamName = _.find($scope.me.leagues, {teamType:'bank'}).teamTypeName;
        } else if($scope.me.roleType == 'seniorManager'){
            let teams;
            if($scope.me.teams.length){
                teams = $scope.me.teams
            }else if($scope.me.leagues.length){
                teams = $scope.me.leagues;
            }else{
                teams = $scope.me.teamsUnder;
            }

            $scope.bankTeamName = _.find(teams, {teamType:'bank'}).teamTypeName;
        }

        function init() {
            if(!$scope.selectedCycle || !$scope.selectedKpi){
                return;
            }
            const selectedProduct = p0.myProducts.find(product => product.mainKpi === $scope.selectedKpi._id)

            huddleBoard.getData($scope.selectedCycle, $scope.selectedKpi, $scope.healthMetrics, $scope.selectedPlayType, $scope.selectedRoleType, selectedProduct, $scope.me).then(function(data){
                $scope.importantActivities = huddleBoard.getHuddleboardActivities();
                if($scope.config.showBasicScorecard){
                    $scope.data = $filter('orderBy')(data, 'opBankRank');
                }else{
                    var managerData = data.shift();
                    $scope.data = $filter('orderBy')(data, 'opBankRank');
                    $scope.data.unshift(managerData);
                }
                $scope.dataLoaded = true;
                $scope.columnIndex = 0;
            });
        }

        $scope.metricChange = function (changed) {
            $scope.healthMetrics = changed;
            init();
        };

        $scope.roleTypeChanged = function(roleType){
            $scope.selectedRoleType = roleType;
            setKpiDropdown(function(){
                init();
            });
        };

        $scope.playTypeChanged = function(playType){
            $scope.selectedPlayType = playType;
            init();
        };

        $scope.kpiChanged = function(kpi){
            $scope.selectedKpi = kpi;
            $scope.isOverAll = $scope.selectedKpi._id == 'overall';
            if($scope.selectedKpi._id != 'overall'){
                init();
            }
        };

        $scope.cycleChanged = function(changed){
            $scope.selectedCycle = changed;
            CurrentCycle.setCycle(changed);
            if(changed.cycleType == 'monthly'){
                if(changed._id == p0.currentMonthlyCycle._id)
                    $scope.showLastSync = true;
                else
                    $scope.showLastSync = false;
            }
            if($scope.selectedKpi._id != 'overall'){
                $scope.cycleType = $scope.selectedCycle.cycleType;
                if($scope.config.uploadPeriod == 'month'){
                    $scope.mtdText = 'Achievement';
                } else {
                    if($scope.cycleType == 'monthly'){
                        $scope.mtdText = 'MTD';
                    }else if($scope.cycleType == 'weekly'){
                        $scope.mtdText = 'WTD';
                    }else{
                        $scope.mtdText = 'Actual'
                    }
                }

                init();
            }
        };

        function  showExtraData (user,extraData) {
            if(!extraData)return;

            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                escapeToClose: true,
                controller: 'activityExtraDataCtrl',
                resolve:{
                    user: function(){return user},
                    data: function() { return extraData;},
                    kpi: function() {return $scope.selectedKpi}
                }
            });
        }

        $scope.redirectToLeads = function (activity, value, user) {
            if(user[activity].extras){
                return showExtraData(user, user[activity].extras);
            }

            if(!value || !$scope.config.lms) return;
            if(activity == 'lostLeads') return;

            var status = '';
            var dateField = '';
            var stageCode = null
            if(activity == 'newLead'){
                dateField = 'createdIn';
                status= 'new';
            }
            else if(activity == 'call'){
                status = '';
                dateField = 'called';
            }else if(activity == 'meeting'){
                status ='';
                dateField = 'met';
            }else if(activity == 'submission') {
                status = '';
                stageCode= {name:'submission', stageUid: 'SUBMISSION'}
            }
            const productId = $scope.selectedKpi.products && $scope.selectedKpi.products[0];
            const params = {status:status, dateField:dateField, stageCode, source:'', kpiId:$scope.selectedKpi._id, productId: productId, userId:user.userId, startDate: moment($scope.selectedCycle.startDate).valueOf(), endDate:moment($scope.selectedCycle.endDate).valueOf(), fromHB:true};
            $state.go('lms.listLead', params);
        };

        $scope.columnIndex = 0;
        $scope.kaiLeft = function(){
            if($scope.columnIndex>0)
                $scope.columnIndex=$scope.columnIndex-1; //#1 - Jump 1 fields everytime
        };

        $scope.kaiRight = function(){
            let showCol = $scope.config.showForecast ? 3 : 4;
            if($scope.columnIndex< $scope.importantActivities.length-showCol) // #3 - Number of fields displaying
                $scope.columnIndex=$scope.columnIndex+1;
        };

       loadInit();
    });
