
'use strict';
angular.module('bitnudgeApp')
    .controller('campaignsController', function ($scope, $timeout, $rootScope, $http, Auth,  moment, $filter,$q, $stateParams, DTOptionsBuilder, DTColumnDefBuilder, p0) {
        $scope.currentUser=Auth.getCurrentUser();
        $scope.strings = $rootScope.strings;
        var getCampaigns=  function(roleId){
            $http.post('api/campaigns').then(function(campaigns) {
                campaigns=campaigns.data;
                if(!campaigns.length){
                    $scope.campaignRunning = false;
                    return;
                }
                $scope.campaignRunning = true;
                $scope.campaigns = campaigns;
                campaigns[0].rewards = _.find(campaigns[0].rewards, {role: roleId}).rewards;
                $scope.selectedCampaignForOverview = campaigns[0];
                $scope.getContests(roleId);
            });
        };
        if($rootScope.isPlayer && !$rootScope.isManager){
            getCampaigns($scope.currentUser.organizationalTag.jobRole._id);
        }
        else{
            var myRole = $scope.currentUser.organizationalTag.jobRole;
            $scope.roles = p0.frontLineJobRoles;
            if($rootScope.isPlayer && $rootScope.isManager)
                $scope.roles.unshift(myRole);
            $scope.selectedRole = $scope.roles[0];
            getCampaigns($scope.selectedRole._id)
        }
        $scope.getContests = function(roleId) {
            //TODO: get call for campaign contests -> (get contests of selected campaign)
            //TODO: trigger my perf and my team perf change
            jQuery.grep($scope.campaigns, function(campaign) {
                if(campaign._id === $scope.selectedCampaignForOverview._id) {
                    $scope.selectedCampaignForOverview = campaign;
                    $scope.selectedCampaignCycleIdForOverview = campaign.cycleId;
                    var isOverall;
                    var contests = campaign.contests;
                    contests.forEach(function(contest){
                        var weightage = _.find(contest.weightage, {role: roleId});
                        if(weightage)
                            contest.weightagePoints= weightage.weightage;
                        var qualification = _.find(contest.qualification, {role: roleId});
                        if(qualification)
                            contest.qualificationPoints =qualification.qualification;
                        if(contest.name == "Overall Campaign")
                            isOverall= true;
                    });
                    if(!isOverall)
                        campaign.contests.unshift({name:"Overall Campaign", kpiId:"total"});
                    $scope.selectedCampaignContests = campaign.contests;
                    $scope.selectedCampaignForLeaderboard = campaign;
                    $scope.selectedCampaignForQualStatus = campaign;
                    $scope.getPlayerQualificationStatus();
                    $scope.selectedCampaignForPerfStatus = campaign;
                    //$scope.getPerformanceStatus();    //**********************************
                    $scope.campaignTop3PlayersNavCount = 0;
                    $scope.getContestPlayerLB(null,$scope.selectedCampaignContests[0], roleId);
                    $scope.campaignContestsWinnersNavCount = 0;

                }
            });
        }

        $scope.getContestDataForPlayerPerf = function(currentContest) {
            //$scope.playerPerf = null;
            var subtype = currentContest.kpiId;
            var type;
            if(subtype == 'total'){
                type = 'total';
                subtype = 'overall';
            }
            else{
                type='kpi';
            }

            const postData = {
                cycleId: $scope.selectedCampaignCycleIdForOverview,
                kpiId: subtype,
                activityId: 'overall',
                type,
                playerType: $scope.currentUser.organizationalTag.jobRole._id,
                teamType: 'bank',
                teamId: 0
            }
            $http.post('api/analytics/leaderboards/cycles', postData).then(function(leaderboards) {
                    leaderboards=leaderboards.data;
                $.each(leaderboards.leaderboard, function( key, value ) {
                    if(value.userId._id==$scope.currentUser._id) {
                        $scope.playerPerf = {
                            "rank": value.rank,
                            "score": value.value,
                            "qualified": value.incentiveAchieved //TODO: fix this
                        }
                    }
                });
            });
        };

        $scope.getContestPlayerLB = function(type, contest, roleId) {
            var subtype = contest.kpiId;
            var type;
            if(subtype == 'total'){
                type = 'total';
                subtype = 'overall';
            }
            else{
                type='kpi';
            }
            $scope.topPlayersArray = [];
            const postData = {
                cycleId: $scope.selectedCampaignCycleIdForOverview,
                kpiId: subtype,
                activityId: 'overall',
                type,
                playerType: roleId,
                teamType: 'bank',
                teamId: 0,
                count:3
            }
            if(roleId){
                $http.post('api/analytics/leaderboards/cycles', postData).then(function(leaderboards) {
                    //required fields :- rank, name, team, points
                    leaderboards=leaderboards.data;
                    var uTeam = "";
                    $scope.topPlayersArray=[];
                    $.each(leaderboards.leaderboard, function( key, value ) {
                        if(value.userId.teams[0]) {
                            uTeam = value.userId.teams[0].name;
                        } else {
                            uTeam = "";
                        }
                        $scope.topPlayersArray.push({"rank":value.rank, name:value.userId.name, "team":uTeam, "score":value.value});
                    });
                    $scope.campaignTop3PlayersContest = $scope.topPlayersArray;
                });
            }
        }

        $scope.getContestQualificationStatClass = function(qualificationStatus) {
            if (qualificationStatus == true) {
                $scope.contestQualificationStatClass = "green-light";
            }
            else {
                $scope.contestQualificationStatClass = "red-light";
            }
            return $scope.contestQualificationStatClass;
        }

        $scope.getContestQualificationStatText = function(qualificationStatus) {
            if (qualificationStatus == true) {
                $scope.contestQualificationStatText = "Qualified";
            }
            else {
                $scope.contestQualificationStatText = "Not Qualified";
            }
            return $scope.contestQualificationStatText;
        }

        $scope.navigationData = {};

        $scope.makeDynamicObj = function(block, component){
            // console.log("in $scope.makeDynamicObj()");
            var navigationData= {};
            navigationData[block] = {};
            navigationData[block][component] = {};
            navigationData[block][component]['navCount'] = 0;
            return navigationData;
        }

        $scope.navigationData.top3 = {
            players: {
                navCount: 0
            },
            teams: {
                navCount: 0
            }
        }

        $scope.manageNavCount = function(navigationObj, block, component, delta){
            // console.log("in $scope.manageNavCount()");
            navigationObj[block][component]['navCount'] = navigationObj[block][component]['navCount'] + delta;
            if(navigationObj[block][component]['navCount'] <= 0){
                navigationObj[block][component]['navCount'] = 0;
            }
            else if(navigationObj[block][component]['navCount'] >= $scope.selectedCampaignContests.length-1) {
                navigationObj[block][component]['navCount'] = $scope.selectedCampaignContests.length-1;
            }
            $scope.getContestPlayerLB(navigationObj[block][component]['navCount']);
        }

        $scope.navigator = function(block, component, delta){
            /*if( typeof $scope.navigationData[block] === 'undefined' ) {
             $scope.navigationData = $scope.makeDynamicObj(block, component);
             }*/
            $scope.manageNavCount($scope.navigationData, block, component, delta);
        }

        $scope.campaignPlayerQualContestNavCount = 0;
        $scope.campaignTeamQualContestNavCount = 0;

        // campaign overview section ends

        // campaign leaderboard section starts
        $scope.getDaysLeft=function(){
            var currCampaign=$scope.selectedCampaignForLeaderboard;
            if(currCampaign)
                return Math.floor((new Date(currCampaign.endDate)-new Date())/(1000*60*60*24));
            else return 0;
        };
        // campaign leaderboard section ends

        // qualification status starts
        $scope.getPlayerQualificationStatus=function(){
            var selectedCampaign=$scope.selectedCampaignForQualStatus;
            var kpiDetailBlock=[],campaignQualData=[];
            const postBody = {
                employeeId: $scope.currentUser._id,
                cycleId: $scope.selectedCampaignForQualStatus.cycleId
            }
            $http.post('api/goals/getAllUserAnalyticsByEmployeeIdAndCycleId', postBody).then(function(userAnalytics){
                userAnalytics=userAnalytics.data;
                campaignQualData=[];
                if(userAnalytics){
                    userAnalytics[0].kpiData.forEach(function(kpisOfUser){
                        var boolVal=false;
                        if(kpisOfUser.incentiveValue)
                            boolVal=kpisOfUser.achievedValue>kpisOfUser.incentiveValue;
                        kpiDetailBlock.push({name:kpisOfUser.kpiId.name,achievedStatus:kpisOfUser.percentageCompleted+"%",conditionMet:boolVal});
                    });
                }
                selectedCampaign.contests.forEach(function(contest){
                    campaignQualData.push({name:contest.name,qualCriteria:contest.qualificationCriteria,timePeriod:$scope.getTimePeriod(contest.startDate,contest.endDate)
                        ,kpiDetails:kpiDetailBlock});
                });
                $scope.playerQualStatContests=campaignQualData;
            });
        };
        $scope.returnClassForProgressBar = function(number) {
            var realNumber = parseInt(number.replace("%",""));
            if (realNumber > 70) {
                return "progress-bar-success";
            } else if (realNumber > 30) {
                return "progress-bar-info";
            } else {
                return "progress-bar-warning";
            }
        }
        $scope.getContestQualConditionMetClass = function(conditionMetStatus) {
            if (conditionMetStatus == true) {
                $scope.contestQualConditionMetClass = "check green";
            }
            else {
                $scope.contestQualConditionMetClass = "times red";
            }
            return $scope.contestQualConditionMetClass;
        }
        // qualification status ends

        $scope.getPointsRunRate=function(points,startDate){
            if(startDate&&points){
                var daysPassed = Math.abs(Math.floor(($rootScope.lastUpdatedAtDate - new Date(startDate))/(1000*60*60*24)));
                return points/daysPassed;
            }
            else return 0;
        };
        $scope.allGaps=[
            {name:'Top 1',value:'top1'},
            {name:'Top Decile',value:'topDecile'},
            {name:'Top Quartile',value:'topQuartile'}
        ];
        var monthText=['Jan','Feb',"Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        $scope.getTimePeriod=function(startDate,endDate){
            var start=new Date(startDate);
            var end=new Date(endDate);
            var startFormatted=start.getDate()+' '+monthText[start.getMonth()];
            var endFormatted=end.getDate()+' '+monthText[end.getMonth()];
            return startFormatted+" - "+endFormatted;
        };
        $scope.dayDiff=function(first, second){
            var result = Math.floor((new Date(second)-new Date())/(1000*60*60*24));
            if(result<0){
                result = "Over";
            }
            return result;
        };
        $scope.dayDiffRemaining=function(endDate){
            var result = Math.floor((new Date(endDate)-new Date())/(1000*60*60*24));
            if(result<0){
                result = "Over";
            }
            return result;
        };
        // performance status ends

        $scope.$watch('selectedCampaignContests', function(newValue, oldValue){
            if(!oldValue && newValue) {
                $scope.selectedContestForPlayerPerf = newValue[0];
                $scope.getContestDataForPlayerPerf($scope.selectedContestForPlayerPerf);
            }
        })
    });
