const autoImportNgTemplateLoaderTemplate1 = require('../html/redemptionOptions/redemptionOptions.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('redemptionOptions', function(){
        return {
            name: 'redemptionOptions',
            scope: {
                expand: '='
            },
            controller: 'redemptionOptionsCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
