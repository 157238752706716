'use strict';
angular.module('bitnudgeApp')
    .service('deepDiveService', function ($http, p0, userAnalyticsService, leagueAnalytic, CurrentCycle, $q) {
        var _this = this;
        this.getPlayersData = function (playerIds, cycleId) {
            return userAnalyticsService.fetchUserAnalytics(playerIds[0], cycleId).then(function (userAnalytic1) {
                return userAnalyticsService.fetchUserAnalytics(playerIds[1], cycleId).then(function (userAnalytic2) {
                    return $q.resolve([userAnalytic1, userAnalytic2]);
                });
            });
        };

        this.getStatusBadge = function (userId) {
            return $http.post("/api/rollingBadges/users", {userId, cycleId: CurrentCycle.cycle._id}).then(function(rollingBadgesEarned) {
                rollingBadgesEarned=rollingBadgesEarned.data;
                if(rollingBadgesEarned[0]){
                    return $q.resolve(rollingBadgesEarned[0]);
                }else{
                    return $q.resolve(false);
                }
            });
        };
    });
