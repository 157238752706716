angular.module('bitnudgeApp')
    .controller('commandLineCtrl', function ($http, $scope,Upload, $mdDialog) {

        $scope.options = [
            {_id:'restartApp', value: 'Restart App'},
            // {_id:'startApp', value: 'Start App'},
            // {_id:'stopApp', value: 'Stop App'},
            // {_id:'pm2Logs', value: 'PM2 Logs'},
            {_id:'gruntSass',value:'Grunt sass'},
            {_id:'gitStatus',value:'Git status'},
            {_id:'gitLog', value: 'Git Logs'},
            {_id:'gitDiff', value: 'Git Diff'},
            {_id:'gitDiffSave', value: 'Git Diff Save on Server'},
            {_id:'gitStash', value: 'Git Stash'},
            {_id:'gitStashPop', value: 'Git Stash Pop'},
            {_id:'applyPatch', value: 'Apply Patch'},
            {_id:'uploadFile', value: 'Upload File'},
            {_id:'readFile', value: 'Read/Update File'},
            {_id:'dbQuery', value: 'DB Query'},
            {_id:'custom', value: 'Custom command'}
        ];
        $scope.extras = {};

        $scope.execute = function(){
            if($scope.option == 'applyPatch' && !$scope.patchFile)return alert('please select the patch file');
            if($scope.option == 'custom' && !$scope.extras.command)return alert('please enter the command');
            if($scope.option == 'readFile') return $scope.readFile();
            var selectedCommand = _.find($scope.options,{_id:$scope.option});
            if(selectedCommand._id =='custom')
                commandName = $scope.extras.command;
            else commandName = selectedCommand.value;
            var confirm = $mdDialog.confirm()
                .title('You have selected "'+commandName+ '" command')
                .targetEvent(event)
                .ok('Yes')
                .cancel('No');
            $mdDialog.show(confirm).then(function() {
                if($scope.option != 'applyPatch'){
                    $http.post('/api/commandLine/execute',{appName:$scope.appName, option: $scope.option, command: $scope.extras.command}).then(function(result) {
                        result=result.data;
                        $scope.result = result;
                    }).catch(function(err) {
                        $scope.result = err;
                    });
                }else{
                    Upload.upload({
                        url: '/api/commandLine/execute',
                        data: {
                            appName:$scope.appName,
                            option: $scope.option,
                            command: $scope.extras.command
                        },
                        file: $scope.patchFile[0],
                        progress: function(e) {}
                    }).then(function(data, status, headers, config) {
                        $scope.result = data.data;
                    });
                }
            }, function(err) {
            });

        };
        $scope.upload = function(){
            if($scope.option == 'uploadFile' && !$scope.patchFile)return alert('please select the file');
            Upload.upload({
                url: '/api/commandLine/upload/file',
                data: {
                    appName:$scope.appName,
                    option: $scope.option,
                    path: $scope.extras.command
                },
                file: $scope.patchFile[0],
                progress: function(e) {}
            }).then(function(data, status, headers, config) {
                $scope.result = data.data;
            },function(error){
                $scope.result = JSON.stringify(error.data);
            });
        };
        $scope.readFile = function(){
            if($scope.option == 'readFile' && !$scope.extras.command)return alert('please select the file');
            //this route is not used any more
            $http.post('/api/commandLine/readFile', {path: $scope.extras.command.toString()}).then(function(result) {
                $scope.showWriteOptions = true;
                if(typeof result.data == 'object'){
                    var str = JSON.stringify(result.data, undefined, 4);
                    document.getElementById('result').innerHTML = str;
                }else $scope.result = result.data;
            }, function(error) {
               $scope.result = JSON.stringify(error);
            });
        };
        $scope.updateFile = function(){
            var confirm = $mdDialog.confirm()
                .title('Do you really want to update the "'+$scope.extras.command+ '" file')
                .targetEvent(event)
                .ok('Yes')
                .cancel('No');
            $mdDialog.show(confirm).then(function() {
                    $http.post('/api/commandLine/writeToFile',{path:$scope.extras.command, data: $scope.result}).then(function(result) {
                        result=result.data;
                        $scope.showWriteOptions = false;
                        $scope.result = result;
                    }).catch(function(err) {
                        $scope.result = err;
                    });
            }, function(err) {
            });
        };

        $scope.executeQuery = function(){
            $http.post('/api/commandLine/query',{query:$scope.extras.query}).then(function(result) {
                if(typeof result.data == 'object'){
                    var str = JSON.stringify(result.data, undefined, 4);
                    $scope.result = str;
                    document.getElementById('result').innerHTML = str;
                }else
                    $scope.result = result.data;
            }, function(error) {
                $scope.result = JSON.stringify(error);
            });
        };

        $scope.cancel = function(){
            $scope.showWriteOptions = false;
            $scope.option = null;
            $scope.extras.command = null;
            $scope.result = '';
        };
        function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }
        $scope.saveDiffasFile = function(){
            // TODO: move excel download to server side
            saveAs(new Blob([s2ab($scope.result)],{type:"application/octet-stream"}), "diff.patch");
        };

    });
