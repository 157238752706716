'use strict';

'use strict';
angular.module('bitnudgeApp')
    .service('queryFields', function () {
        this.queryFieldsList = {
        lead: [
            {_id: 'userEmail',label: 'User Email',type: 'freetext', showCriteria: true, canBeDynamic: true},
            {_id: 'preApproved',label: 'Is Preapproved',type: 'dropdown', showCriteria: false},
            // {_id: 'followUpMissedSince',label: 'Follow Up Missed Since (Days)',type: 'number', showCriteria: false},
            // {_id: 'upcomingFollowUp',label: 'Upcoming Follow Up',type: 'dropdown', showCriteria: false},
            // {_id: 'upcomingFollowUpIn',label: 'Upcoming Follow Up In (Days)',type: 'number', showCriteria: false},
            // {_id: 'leadsNotContacted',label: 'Leads Not Contacted',type: 'dropdown', showCriteria: false},
            {_id: 'product',label: 'Products',type: 'dropdown', showCriteria: true, canBeDynamic: true},
            // {_id: 'subProduct',label: 'Sub Products',type: 'dropdown', showCriteria: true},
            // {_id: 'lastUpdateDate',label: 'Leads Not Updated Since (Days)',type: 'number', showCriteria: false},
            // {_id: 'leadType',label: 'Lead Type',type: 'dropdown', showCriteria: true, canBeDynamic: true},
            // {_id: 'leadSubType',label: 'Lead Sub Type',type: 'dropdown', showCriteria: true},
            {_id: 'leadStatus',label: 'Lead Status',type: 'dropdown', showCriteria: true},
            {_id: 'leadStage',label: 'Lead Stage',type: 'dropdown', showCriteria: true},
            {_id: 'activeLeads',label: 'Active Agents',type: 'dropdown', showCriteria: false},
            {_id: 'active',label: 'Active/In Active',type: 'dropdown', showCriteria: false}
        ],
        customer: [
            {_id: 'userEmail',label: 'User Email',type: 'freetext', showCriteria: true, canBeDynamic: true},
            {_id: 'customerSource',label: 'Source',type: 'dropdown', showCriteria: true, canBeDynamic: true},
            {_id: 'customerProduct',label: 'Product',type: 'dropdown', showCriteria: true, canBeDynamic: true},
            {_id: 'deleted',label: 'Active/In Active',type: 'dropdown', showCriteria: false, canBeDynamic: true},
            {_id: 'limitExtendedExpiryDate',label: 'Expired Limits',type: 'dropdown', showCriteria: false},
            {_id: 'updatedAt',label: 'Updated In',type: 'number', showCriteria: false},
            {_id: 'createdAt',label: 'Created In',type: 'number', showCriteria: false},
            {_id: 'utilisationPercentage',label: 'Customer Age',type: 'number', showCriteria: false},
            {_id: 'source',label: 'Customer Source',type: 'dropdown', showCriteria: false}
        ]
    }
});
