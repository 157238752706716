'use strict';
angular.module('bitnudgeApp')
    .controller('exportLeadsController', function($http, $scope, Auth, Upload, $location, p0, toaster, $mdDialog, UtilService, $filter) {
        // $scope.exportFormat = p0.config.defaultLeadExportFormat || 'bnFormat';
        $scope.exportFormat = 'bnFormat';
        var s2ab = function(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }

        $scope.now = new Date();
        /** initialize the values**/
        $scope.fromDate = $scope.endDate = $scope.now;

        $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];

        var appUrl = p0.config.domain;
        var subfolder = p0.config.subfolder;
        if(subfolder){
            if(appUrl[appUrl.length -1] !== '/'){
                appUrl += '/';
            }
            if(subfolder[0] == '/'){
                subfolder = subfolder.slice(1);
            }
            appUrl += subfolder;
        }

        //Function to submit lead excel
        $scope.onFileSelectLead = function() {
            $scope.fromDate = $scope.fromDate.getTime();
            $scope.endDate = $scope.endDate.getTime();
            if ($scope.bulkLead == undefined) {
                toaster.pop('error', "Submission.", "Select File to Upload.");

            } else {
                var fileName = $scope.bulkLead[0].name.split(".");
                var fileType = fileName[fileName.length - 1].toLowerCase();
                if (fileType == "xlsx" || fileType == "xlsm") {
                    Upload.upload({
                        url: '/api/leads/import/excel',
                        data: {
                            userId: Auth.getCurrentUser()._id,
                            asOfDate: $scope.fromDate
                        },
                        file: $scope.bulkLead[0],
                        progress: function(e) {}
                    }).then(function(data, status, headers, config) {
                        // file is uploaded successfully
                        //$location.path('/lms/listLead');
                        toaster.pop('success', "Excel uploaded", "Lead creation successfully finished.");
                    });
                } else {
                    toaster.pop('error', "Submission.", "Upload '.xlsx' file only.");
                }

            }
        };

        $scope.downloadLeads = function(){
            if(Object.prototype.toString.call($scope.fromDate) == "[object Date]"){
                $scope.fromDateTime = (new Date($scope.fromDate.setHours(0,0,0,0))).getTime();
            }
            if(Object.prototype.toString.call($scope.endDate) == "[object Date]"){
                $scope.endDateTime = (new Date($scope.endDate.setHours(23,59,59,59))).getTime();

            }
            //POSLeads obslete functionality
            // if($scope.posLeads)
            //     $scope.downloadPosLeads();
            // else
            $scope.downloadNormalLeads();
        }

        var getTimestamp = function(){
            var timestamp = new Date();
            timestamp = timestamp.toString().substring(0, 24)
            timestamp = timestamp.replace('-', ':');
            return timestamp;
        };
        //this is deprecated and not used
        // $scope.downloadPosLeads = function(){
        //     if(!$scope.fromDateTime)return alert('Please select from Date');
        //     if(!$scope.endDateTime)return alert('Please select end Date');
        //     // $http.get('/api/leads/getPosLeads/'+$scope.fromDateTime+'/'+$scope.endDateTime,
        //     //     {responseType: "arraybuffer"}).then(function(filedata){
        //     //     filedata=filedata.data;
        //     //     // TODO: move excel download to server side
        //     //     saveAs(new Blob([filedata],{type:"application/octet-stream"}), getTimestamp()+'-POSLeads.xlsx');
        //     // });
        // };

        $scope.downloadNormalLeads = function(){
            if(!$scope.fromDateTime)return alert('Please select from Date');
            if(!$scope.endDateTime)return alert('Please select end Date');
			if(!$scope.exportFormat){
				toaster.pop('error', "Leads download", "Please select the download file format.");
				return;
			}
            const params = {
                fromDate: (new Date($scope.fromDateTime)).getTime(),
                endDate: (new Date($scope.endDateTime)).getTime(),
                format: $scope.exportFormat
            }
           return UtilService.downloadFile(appUrl+ '/api/leads/exportLeads', params);
        };

        $scope.sendingMail = false;
        $scope.mailLeadsFile = function () {
            if(!$scope.fromDate)return alert('Please select Date');
            $scope.sendingMail = true;
            var confirm = $mdDialog.confirm()
                .title('POS Leads created in BitNudge on or after "'+ $filter('date')($scope.fromDate, 'MMM dd, yyyy')+ '" will be exported')
                .targetEvent(event)
                .ok('Yes')
                .cancel('No');
            $mdDialog.show(confirm).then(function() {
                $scope.fromDateTime = $scope.fromDate.getTime();
                $http.post('/api/leads/mailPosLeadsFile', {fromDate: $scope.fromDateTime}).then(function(result){
                    result=result.data;
                    $scope.sendingMail = false;
                });
            }, function(err) {
                $scope.sendingMail = false;
            });

        }

    })
