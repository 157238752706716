const autoImportNgTemplateLoaderTemplate1 = require('../html/swot.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('swot', function () {
        return {
            name: 'swot',
            scope: {
                cycleId:                '=',
                swotUser:               '=',
                kpi:                    '=', //overall or kpi._id
                type:                   '=', //kpi, reportee, kai
                selectedDivision:       "=",
                //Gets used in case of Analytics swot
                isQuickView:            '=',       //true -> dashboard quick view swot, false -> analytics swot
                onClick:                '=',
                onParentSwitchChange:     '=', //Analytics swot - notify child when switch changes
                meReporteeSwitch:           '=', //Quickview swot- Reportee frontline switch change from outside
                showTwoByTwo:       '=',
                selectedPlayType: '='
            },
            controller: 'swotCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
