'use strict';
angular.module('bitnudgeApp')
    .controller('reassignLeadModalCtrl', function ($scope, $mdDialog, lead, Auth, frontLineList, fetchLeads, $http, $state, p0, toaster) {
        $scope.lead = lead;
        $scope.me = Auth.getCurrentUser();
        //for list of frontlines or managers
        $scope.isManagerSelected = $scope.me.roleType == 'manager' ? true : false;
        //for showing hide/show on switch
        $scope.dataLoaded = false;
        $scope.roles = ["Manager", "Frontline"];
        if($scope.me.roleType === 'seniorManager'){
            $scope.roles.unshift("Senior Manager")
        }
        $scope.role = $scope.roles[0];
        function fetchUsersList() {
            $scope.dataLoaded = false;
            if ($scope.me.roleType == 'manager') {
                //If the logged in user is manager then only show frontlines and hide user toggle switch
                fetchUsersListForManager()
            }
        }

        function fetchUsersListForManager() {
            //get all the children of the logged in manager
            $http.post('api/users/me/children', {userId: $scope.me._id}).then(function (result) {
                result=result.data;
                //this is intentional for demo purpose
                if(true || p0.config.leadReassignToSelf){
                    let me = _.cloneDeep($scope.me);
                    me.name = me.name+' (Me)';
                    result.unshift(me);
                }
                //remove the current assigned user from the list
                _.remove(result, function (user) {
                    return lead.userId && user._id.toString() == lead.userId._id.toString()
                });
                //usersList is shown on modal List
                $scope.dataLoaded = true;
                $scope.usersList = result;
            });
        }
        function fetchUsersListForSeniorManager(roleType) {
            if (roleType.toLowerCase() === 'manager') {
                //get all the managers of the loggedin seniormanager
                var myPlayTypes = p0.myPlayTypes;
                var managerIds = []
                myPlayTypes.forEach(function (playType) {
                    var playTypeProductIds = playType.products;
                    if (_.includes(playTypeProductIds, lead.productId._id)) {
                        managerIds = _.concat(managerIds, playType.managers)
                    }
                });
                managerIds  = _.map(managerIds, function(id){
                    return id.toString();
                });

                /*Show only managers who has fronline under him. do not show dummy  managers in the list*/
                var managerRoles = _.filter(p0.allJobRoles, function(role){
                    return role.hasFrontline && _.includes(managerIds, role._id.toString());
                });
                managerIds = _.map(managerRoles, function(role){
                    return role._id.toString();
                });

                $http.post('/api/users/getAllLeafJobRoles', {
                    reporteeJobRoles: managerIds,
                    userId: $scope.me._id
                }).then(function (result) {
                    result=result.data.data;
                    $scope.dataLoaded = true;
                     //remove the current assigned user from the list
                     _.remove(result, function (user) {
                        return user._id.toString() == lead.userId._id.toString()
                    });
                    $scope.usersList = result;
                })
            } else if (roleType.toLowerCase() === 'senior manager') {
                $http.post('api/users/getAllSeniorManagers/',{userId:$scope.me._id}).then(function(result){
                    result = result.data;
                    if(true || p0.config.leadReassignToSelf){
                        let me = _.cloneDeep($scope.me);
                        me.name = me.name+' (Me)';
                        result.unshift(me);
                    }
                     //remove the current assigned user from the list
                     _.remove(result, function (user) {
                        return lead.userId && user._id.toString() == lead.userId._id.toString()
                    });
                    $scope.usersList = result;
                    $scope.dataLoaded = true;
                })
            }
            else {
                //get all the frontlines of the loggedin seniormanager
                $http.post('/api/users/getAllLeaf', {userId: $scope.me._id}).then(function (result) {
                    result = result.data.data;
                    
                     //get all the frontline roles of the loggedin seniormanager
                    var myPlayTypes = p0.myPlayTypes;
                    var frontlineRoles = []
                    myPlayTypes.forEach(function (playType) {
                        var playTypeProductIds = playType.products;
                        if (_.includes(playTypeProductIds, lead.productId._id)) {
                            frontlineRoles = _.concat(frontlineRoles, playType.frontline)
                        }
                    });
                    frontlineRoles  = _.map(frontlineRoles, function(id){
                        return id.toString();
                    });
                    
                    result = result.filter(function (user) {
                        return _.includes(frontlineRoles, user.organizationalTag.jobRole._id.toString());
                    })
                    //remove the current assigned user from the list
                    _.remove(result, function (user) {
                        return lead.userId && user._id.toString() == lead.userId._id.toString()
                    });
                    $scope.dataLoaded = true;
                    $scope.usersList = result;
                })
            }

        }

        //on user toggle change update the userlist
        $scope.changeUsers = function (isManagerSelected) {
            $scope.isManagerSelected = isManagerSelected;
            fetchUsersList()
        }
        $scope.roleChanged = function (roleType) {
            fetchUsersListForSeniorManager(roleType);
        }
        //reassign lead to the clicked user
        $scope.onClickPlayer = function (player, event) {
            showConfirm($scope.lead, player, event);
        };

        //modal for confirmation popup
        var showConfirm = function (lead, player, event) {
            if (!lead.userId || lead.userId.toString() == $scope.me._id.toString()) {
                //In the case of Assign lead show this message
                var confirm = $mdDialog.confirm()
                    .title('Are you sure you want reassign this lead to ' + player.name + '?')
                    .targetEvent(event)
                    .ok('Yes')
                    .cancel('No');
            } else {
                //In the case of Reassign lead show this message
                var confirm = $mdDialog.confirm()
                    .title('Are you sure you want reassign this lead from ' + lead.userId.name + ' to ' + player.name + '?')
                    .targetEvent(event)
                    .ok('Yes')
                    .cancel('No');
            }
            $mdDialog.show(confirm).then(function () {
                const postBody = {
                    leadId: lead._id,
                    userId: player._id,
                    userEmail: player.email
                }
                $http.post('/api/leads/changeUser', postBody).then(function (lead) {
                    if(lead.data.status){
                        fetchLeads();
                        if (lead.data.message) {
                            if (lead.data.message.includes('reassigned')) {
                                toaster.success("Lead reassigned successfully");
                            } else {
                                toaster.success("Lead assigned successfully");
                            }
                        }
                    }else{
                        lead.data.errors.forEach( error => toaster.error(error))
                    }
                    $mdDialog.hide();
                });
            }, function () {
            });
        };

        $scope.cancel = function () {
            $mdDialog.hide();
        }
        if ($scope.me.roleType === 'seniorManager') {
            $scope.roleChanged('senior manager');
        } else {
            fetchUsersList()
        }
    });
