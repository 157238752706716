'use strict';
angular.module('bitnudgeApp').controller('leadNoteCtrl', function ($scope, $mdDialog, leadsService, lead, allowedAddNotes, toaster) {
    $scope.lead = lead;
    $scope.selected = {currentNote: ""}
    $scope.allowedAddNotes = allowedAddNotes;
    $scope.cancel = function () {
        $mdDialog.hide();
    }
    
    leadsService.getLead(lead._id).then( (data) => {
        $scope.lead = data.data.data;
        $scope.histories = $scope.lead.notes;
    }).catch(err => {
        toaster.pop('error','Lead', err)
    })
    
    $scope.addNote = () => {
        leadsService.addNote(lead._id, $scope.selected.currentNote).then((data)=>{
            if(data.status){
                $scope.histories.unshift({notes: $scope.selected.currentNote, updatedAt: new Date()})
                $scope.selected.currentNote = "";
            }else{
                toaster.pop('error','Lead Notes', 'Error occured while adding notes')
            }
        }).catch(err => {
            const message = err && err.message ? err.message : 'Error occured while adding notes.'
            toaster.pop('error','Lead Notes', message)
        })
    }
    

});
