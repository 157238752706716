'use strict';
angular.module('bitnudgeApp')
    .controller('gradesCardCtrl', function ($rootScope, $scope, $timeout){
        $scope.onItemClick = $scope.onClick;
		$scope.strings=$rootScope.strings;
		if($scope.showBgColor){
            $scope.gradeCardBackground = 'grade-background-'+$scope.zone;
        }else $scope.gradeCardBackground = '';
        $scope.$watch('list',function (newValue, oldValue) {
			if(newValue){
                $scope.listLoaded = false;
                $timeout(function(){ $scope.listLoaded = true;}, 0);
            }
        });
    });
