'use strict';
angular.module('bitnudgeApp')
    .controller('badgePageController', function ($scope, Auth, $http, p0, CurrentCycle, $timeout, $stateParams, firework, Kpi, $rootScope) {
        var init = (function(){
            $scope.me=Auth.getCurrentUser();
            $scope.selected = {};
            $scope.animatePage = p0.config.anim_badgePage; // make this flag false if want to disable animation
			$scope.params = {};
			if($stateParams.userId){
                $scope.reportee=$stateParams.userId;
                $http.post('/api/users/getUserById', {id:$scope.reportee}).then(function(response) {
                    response=response.data;
                    $scope.selected.user= response;
                });
            }
            if($scope.me.systemRole == 'frontline' || $scope.me.systemRole == 'manager'){
                $scope.selectedJobRole = $scope.me.organizationalTag.jobRole;
            }
            if($scope.me.systemRole == 'frontline'){
                $scope.onPlayTypeChange(p0.myPlayTypes[0]);
            }
        }).bind(this);

        var setKpiDropdown = function (kpis) {
            if(!kpis && $scope.selected.playType){
                if($scope.selected.playType._id == 'all'){
                    kpis = _.cloneDeep(p0.allKpis);
                }else{
                    kpis = $scope.selected.playType.kpis;
                }
            }else if(!kpis && !$scope.selected.playType) return;
            $scope.kpis = _.cloneDeep(kpis);
            $scope.kpis = _.sortBy($scope.kpis, ['sequence']);
            //Overall case - only when there are more than one kpi
            $scope.kpis.unshift({_id:'overall', name:'Overall'});
            $scope.selectedKpi = $scope.kpis[0];
        };

        $scope.onReporteeChange = function (reportee) {
            $scope.selected.user = reportee;
            if(reportee._id == 'all'){
                /*var roleType = $scope.selected.systemRole;
                if(roleType._id == 'manager'){
                    $scope.selectedJobRoleId = $scope.selected.playType.managers[0];
                }else{
                    $scope.selectedJobRoleId = $scope.selected.playType.frontline[0];
                }*/
                $scope.selectedJobRole = $scope.me.organizationalTag.jobRole;

            }else{
                $scope.selectedJobRole = reportee.organizationalTag.jobRole;
                //$scope.selectedJobRoleId = $scope.selectedJobRole._id;
            }
        };

        $scope.onDivisionChange = function (division) {
            $scope.selected.division = division;
        };

        $scope.onPlayTypeChange = function (playType) {
            $scope.selected.playType = playType;
            setKpiDropdown(playType.kpis);
        };

        $scope.onRoleTypeChange=function(roleType) {
            $scope.selected.systemRole = roleType;
            $scope.selectedJobRole = $scope.me.organizationalTag.jobRole ;
            if(roleType._id === 'manager'){
				$scope.params.reporteeLabel = `${p0.strings.MANAGER.value}`;
                $scope.selectedJobRoleId = $scope.selected.playType.managers[0];
            }else{
				$scope.params.reporteeLabel = `${p0.strings.FRONTLINE.value}`;
                $scope.selectedJobRoleId = $scope.selected.playType.frontline[0];
            }
        };

        $scope.changeKpi = function(kpi){
            $scope.isWeekly = false;
            $scope.selectedKpi = kpi;
        };

        $scope.cycleChange = function (cycle) {
            $scope.selectedCycle = cycle;
            CurrentCycle.setCycle(cycle);
        };

        firework.triggerFireWorks();
        $timeout(function() {
            $scope.animatePage=false;
        }, 10000);

        init();
        // var emitter;
        // if ($scope.animatePage) {
        //     $timeout(function() {
        //         letItSnow();
        //     }, 50);
        //
        //     $element.on('$destroy', function () {
        //         if (angular.isDefined(emitter)) {
        //             emitter.stop();
        //         }
        //     });
        // }
        //
        // function letItSnow() {
        //     var pageContainer = $element.find('.badge-page')[0];
        //     var canvas = $element.find('canvas')[0];
        //
        //     canvas.width = pageContainer.offsetWidth;
        //     canvas.height = pageContainer.offsetHeight;
        //     // Now the emitter
        //     emitter = Object.create(rectangleEmitter);
        //     emitter.setCanvas(canvas);
        //     emitter.setBlastZone(0, -10, canvas.width, 1);
        //     emitter.particle = snow;
        //     emitter.runAhead(0);
        //     emitter.start(30);
        // }
    });
