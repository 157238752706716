'use strict';
angular.module('bitnudgeApp')
    .controller('gpCommentBoxCtrl', function ($scope) {
        $scope.formatDate = function(date,type){
            var Months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
            var newDate = new Date(date);
            if(type=="comment")
                var reqDate = newDate.getDate()+"-"+Months[newDate.getMonth()]+"-"+newDate.getFullYear();
            else
                var reqDate = newDate.getDate()+"-"+Months[newDate.getMonth()];
            return reqDate;
        };
        $scope.setBackgroundColor = function(myId, commentorId){
            return myId === commentorId ?'fill-style': 'outline-style' ;
        };
    });
