'use strict';
angular.module('bitnudgeApp')
    .service('exitStatus', function () {
        this.getExitStatus = function(status){
            switch(status){
                case 'Not yet contacted':
                    return ['No Response (Multiple attempts)','Customer not eligible', 'Not Interested'];
                case 'Undecided':
                    return ['Not Interested','Customer not eligible'];
                case 'Interested':
                    return ['Not Interested','Customer not eligible'];
                case 'Documents collected':
                    return ['Not Interested','Customer not eligible'];
                case 'Application submitted':
                    return ['Rejected'];
                case 'Application approved':
                    return ['Not Disbursed'];
            }
        };
    });
