'use strict';
angular.module('bitnudgeApp')
    .service('Kpi', function (p0, userAnalyticsService, $q) {
        this.getUserKpis = function (kpis, userId, cycleId,jobRole) {
            return userAnalyticsService.fetchUserAnalytics(userId, cycleId).then(function(userAna){
                var userKpis = _.cloneDeep(_.filter(kpis, function(goal){
                    return _.find(userAna.kpiData, function(gm){
                        return gm.kpiId == goal._id && (gm.target.value || goal.uiLevel == 2);
                    });
                }));
                return $q.resolve(userKpis);
            })
        };
    });
