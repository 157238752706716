angular.module('bitnudgeApp')
    .controller('addACLCtrl', function ($http, $scope, Auth, Upload, $location, toaster) {
        $scope.fileType = "ACLFile"
        $scope.onFileSelectACL = function () {
            if ($scope.acl == undefined) {
                toaster.pop('error', "Submission.", "Select File to Upload.");
            } else {
                var fileName = $scope.acl[0].name.split(".");
                var fileType = fileName[fileName.length - 1];
                if (fileType == "xls" || fileType == "xlsx" || fileType == "csv") {
                    Upload.upload({
                        url: '/api/upload',
                        data: {userId: Auth.getCurrentUser()._id,cronTag:'ACLFileUpload',invocationMethod:"manual"},
                        file: $scope.acl[0],
                        progress: function (e) {
                        }
                    }).then(function (data, status, headers, config) {
                        // file is uploaded successfully
                        toaster.pop('success', "Excel uploaded", "ACL upload successfully finished.");
                    });
                } else {
                    toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' or '.csv' Types Only.");
                }
            }
        }
    });
