'use strict';
angular.module('bitnudgeApp')
    .controller('userProfilePopoverCtrl', function ($scope, $rootScope, Auth, $http, $timeout, social, $location, p1, p0, CurrentCycle, $state, Reportees, $mdDialog) {
        let self = this;
        $scope.dataLoaded = false;
        $scope.strings = $rootScope.strings;
        $scope.config = $rootScope.config;
        $scope.cloned = $rootScope.cloned;
        $scope.me = Auth.getCurrentUser();
        $scope.isMyReportee = false;
        $scope.cycle = CurrentCycle.cycle;
        $scope.user = {};
        $scope.user.showImage = false;
        $scope.user.name = $scope.popoverUserName;
        $scope.user.linkName = $scope.popoverLinkName;
        $scope.user.navigate = $scope.onClickNavigate;
        $scope.placement = $scope.popoverPlacement;
        $scope.userId = $scope.popoverUserId;
        $scope.showProfile = true;
        $scope.showCompare = false;
        $scope.showActionButtons = false;
        $scope.popover = {
            'templateUrl': 'popover.html',
            isOpen: false
        };

        self.init = function () {
            if ($scope.popoverShowImage) {
                $scope.user.showImage = $scope.popoverShowImage;
            }
            if ($scope.popoverImageUrl) {
                $scope.user.image = $scope.popoverImageUrl;
            }
            if ($scope.popoverLinkWhite) {
                $scope.showWhiteLink = $scope.popoverLinkWhite;
            }
        }

        $scope.setUserProfile = function (changeLinkName) {
            $scope.showProfile = true;
            $scope.showCompare = false;
            $scope.user.jobRole = p1.userData[$scope.userId][CurrentCycle.cycle._id].jobRole;
            $scope.user.points = p1.userData[$scope.userId][CurrentCycle.cycle._id].points;
            $scope.user.badgeCount = p1.userData[$scope.userId][CurrentCycle.cycle._id].badgeCount;
            $scope.user.fullName = p1.userData[$scope.userId][CurrentCycle.cycle._id].fullName;
            $scope.user.rank = p1.userData[$scope.userId][CurrentCycle.cycle._id].rank;
            $scope.user.branch = p1.userData[$scope.userId][CurrentCycle.cycle._id].branch;
            $scope.user.nickname = p1.userData[$scope.userId][CurrentCycle.cycle._id].nickname;
            $scope.user.teamNickname = p1.userData[$scope.userId][CurrentCycle.cycle._id].teamNickname;
            if (!$scope.user.image) {
                $scope.user.image = p1.userData[$scope.userId][CurrentCycle.cycle._id].image;
            }
            $scope.user.popoverImage = p1.userData[$scope.userId][CurrentCycle.cycle._id].image;
            if (CurrentCycle.cycle._id == p0.currentMonthlyCycle._id) {
                $scope.socialData = p1.userData[$scope.userId];
            } else {
                $scope.socialData = p1.userData[$scope.userId][CurrentCycle.cycle._id];
            }
            $scope.social = p1.userData[$scope.userId];
            if ($scope.user.jobRole.roleType == 'seniorManager' || self.isLBPFCCDivision($scope.user.jobRole.division)) {
                $scope.showProfile = false;
            }
            if (!$scope.hideName && !$scope.popoverShowImage && changeLinkName) {
                $scope.user.linkName = p1.userData[$scope.userId][CurrentCycle.cycle._id].name;
            }
            if ($scope.me.organizationalTag.jobRole._id == $scope.user.jobRole._id) {
                $scope.showCompare = true;
                $scope.dataLoaded = true;
            } else {
                return Reportees.isMyLeaf($scope.userId).then(function (isReportee) {
                    $scope.isMyReportee = isReportee;
                    if ($scope.isMyReportee && $scope.user.jobRole.roleType != 'seniorManager') {
                        $scope.showOCR = true;
                    }
                    $scope.dataLoaded = true;
                    return;
                });
            }
        };

        self.isLBPFCCDivision = function (divisions) {
            var isLBPFCC = false;
            divisions.forEach(function (division) {
                var divObject = _.find(p0.allDivisions, { _id: division });
                if (!divObject) isLBPFCC = true;
            });
            return isLBPFCC;
        }

        $scope.$watch('showWhiteLink', function () {
            if ($scope.showWhiteLink) {
                $scope.linkColor = 'white white-hover-color';
            }
        });

        $scope.isPlayerLiked = function (player) {
            return player && player._id && p1.userData[player._id].liked;
        };
        $scope.isPlayerFollowed = function (player) {
            return player && player._id && p1.userData[player._id].followed;
        };

        $scope.socialAction = function (action, user) {
            if ($scope.showActionButtons) {
                social.socialAction(action, user);
            }
        };
        $scope.gotoProfile = function () {
            $mdDialog.hide();
            $state.go('userFlow.profile', { selectedUserId: $scope.userId, cycleId: CurrentCycle.cycle._id });
        };
        $scope.comparePlayer = function () {
            $rootScope.jobRoleFromLB = $scope.me.organizationalTag.jobRole;
            $rootScope.playersFromLB = [$scope.userId];
            $mdDialog.hide();
            $state.go('userFlow.analytics', { tabName: 'playerComparison', myComparison: true, cycleId: CurrentCycle.cycle._id });
        };
        $scope.gotoGP = function () {
            $mdDialog.hide();
            $state.go('userFlow.gameplan', { selectedPlayerId: $scope.userId });
        };
        $scope.gotoSimulate = function () {
            $mdDialog.hide();
            $state.go('userFlow.gameplanSimulation', { userId: $scope.userId });
        };
        $scope.onClick = function () {
            if ($scope.user.navigate && $scope.user.navigate.state) {
                $state.go($scope.user.navigate.state, $scope.user.navigate.params);
            }
        };
        $scope.gotoOCR = function () {
            //temp disable this feature
            return;
            $mdDialog.hide();
            $state.go('userFlow.oneClickReport', { selectedPlayerIdForOCR: $scope.userId });
        };
        self.fetchData = function (changeLinkName) {
            if ($scope.userId && CurrentCycle.cycle._id) {
                $scope.user._id = $scope.userId;
                if ($scope.userId == $scope.me._id.toString()) {
                    $scope.showActionButtons = false;
                } else {
                    $scope.showActionButtons = true;
                }
                if (p1.userData && p1.userData[$scope.userId]) {
                    if (!p1.userData[$scope.userId][CurrentCycle.cycle._id] || !p1.userData[$scope.userId][CurrentCycle.cycle._id].jobRole) {
                        p1.userData[$scope.userId][CurrentCycle.cycle._id] = {};
                        return $http.post('api/cne/shortProfile', {userId: $scope.userId, cycleId: CurrentCycle.cycle._id}).then(function (userProfile) {
                            userProfile = userProfile.data;
                            p1.userData[$scope.userId][CurrentCycle.cycle._id] = userProfile[$scope.userId];
                            $scope.setUserProfile(changeLinkName);
                        });
                    } else {
                        $scope.setUserProfile(changeLinkName);
                    }
                }
            }
        }

        $scope.$watch('popoverUserId', function (newVal, oldValue) {
            if (newVal != oldValue) {
                $scope.userId = newVal;
                $scope.dataLoaded = false;
                self.fetchData(true);
            }
        });

        // ***************************** Show / Hide logic using $timeout **************************************

        var showTimer, hideTimer;

        $scope.triggerMouseEnter = function () {
            if (!$scope.dataLoaded) {
                self.fetchData(false);
            }
            self.triggerShow();
        };
        $scope.triggerMouseLeave = function () {
            self.triggerHide();
        };

        $scope.popoverMouseEnter = function (a) {
            $timeout.cancel(hideTimer);
            self.show();
        };
        $scope.popoverMouseLeave = function () {
            self.hide();
        };

        self.show = function () {
            $timeout.cancel(hideTimer);
            $scope.popover.isOpen = true;
        }
        self.hide = function () {
            $timeout.cancel(showTimer);
            $scope.popover.isOpen = false;
        }

        self.triggerShow = function () {
            $timeout.cancel(hideTimer);
            showTimer = $timeout(function () {
                self.show();
            }, 200);
        }
        self.triggerHide = function () {
            $timeout.cancel(showTimer);
            hideTimer = $timeout(function () {
                self.hide();
            }, 200);
        }

        $scope.$on("$destroy", function (event) {
            $timeout.cancel(showTimer);
            $timeout.cancel(hideTimer);
        });

        self.init();

    });

