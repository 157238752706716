'use strict';
angular.module('bitnudgeApp')
    .controller('adminLogsCtrl', function ($scope,SocketService, $mdDialog, $rootScope, $http) {
        function openAlertBox(id, data){
            $mdDialog.hide();
            var alert = $mdDialog.alert({
                title: id,
                textContent: data,
                multiple:false,
                ok: 'Close'
            });
            $mdDialog.show( alert ).then(function() {});
        }
        SocketService.getSocket().then(function(socket){
            $scope.adminLogMessage = "";
            socket.removeAllListeners('logMessage'); //Remove all listeners on the same before listen
            socket.on('logMessage', function (data) {
                $scope.adminLogMessage +=  data + '<br/>' ;
                document.getElementById('adminContainer').innerHTML = $scope.adminLogMessage;
            });
            //Only for browser notifications.
            socket.removeAllListeners('logNotif');
            socket.on('logNotif', function(dataObj) {
                openAlertBox(dataObj.type, dataObj.body);
                var options = {
                    body : dataObj.body,
                    noscreen: true
                };
                var notification = new Notification(dataObj.type, options);
                notification.close.bind(notification);
            });
            socket.removeAllListeners('cronMessage');
            socket.on('cronMessage', function(data) {
                if(data._id !='cron'){
                    if(data._id == 'TARGET_FILE_UPLOAD' || data._id == 'SCORECARD_FILE_UPLOAD'){
                        if(data.status == 'started'){
                            changeCronStatus(true);
                            var id = data._id== 'TARGET_FILE_UPLOAD' ? 'Daily targets' : 'Daily scorecard metrics';
                            openAlertBox(id,'Upload in progress!');
                        }else if(data.status == 'completed'){
                            changeCronStatus(false);
                        }
                    }else if(data.status == 'started'){
                        changeCronStatus(true);
                        openAlertBox(data._id+' cron','cron is running!');
                    }else if(data.status == 'completed'){
                        changeCronStatus(false);
                        openAlertBox(data._id+' cron','cron is completed!');
                    }
                }
            });
            function changeCronStatus(status){
                $rootScope.$apply(function(){
                    $rootScope.isCronRunning = status;
                });
            }
            $http.post('/api/cron/status').then(function (response) {
                response=response.data;
                response.forEach(function (status) {
                    if(status._id == 'TARGET_FILE_UPLOAD' || status._id == 'SCORECARD_FILE_UPLOAD'){
                        if(status.status == 'running'){
                            $rootScope.isCronRunning = true;
                            var id = status._id== 'TARGET_FILE_UPLOAD' ? 'Daily targets' : 'Daily scorecard metrics';
                            openAlertBox(id,'Upload in progress!');
                        }
                    }else if(status.status == 'running'){
                        $rootScope.isCronRunning = true;
                        openAlertBox(status._id+' cron','cron is running!');
                    }
                });
            });
        });
    });
