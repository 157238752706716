const autoImportNgTemplateLoaderTemplate1 = require('../html/cneKpiTab/dailyFeedModals/bulkCommentModal.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/cneKpiTab/dailyFeedModals/dailyFeedModal-badge.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('dailyDigestCtrl', function($rootScope, $scope, $element, $attrs, $http,Auth, p0,dailyFeed, $timeout, $mdDialog, newsAndUpdatesService, CurrentCycle){
        var _this = this;
        $scope.kpis = p0.allKpis;
        $scope.strings = $rootScope.strings;
        $scope.config = p0.config;

        $scope.me = Auth.getCurrentUser();
        $scope.badgeAchieversLoaded = false;
        $scope.lastSyncDate = $rootScope.lastUpdatedAtDate;
        $scope.allCycles = p0.allMonthlyCycles;
        $scope.gainerSelected = false;
        $scope.allPlayTypes = _.cloneDeep(p0.allPlayTypes);
        $scope.isPlayer = $rootScope.isPlayer;
        $scope.isManager = !$rootScope.isFrontline;
        $scope.showLastSync = true;
        $scope.showPlayTypes = p0.allPlayTypes.length>1;
        $scope.monthlyCyclesAsOfDate = _.filter(p0.allCycles, function(cycle){
            return  cycle.cycleType == 'monthly' && new Date(cycle.startDate).getTime() <= new Date($rootScope.lastUpdatedAtDate).getTime();
        });
        $scope.selectedCycle = $scope.monthlyCyclesAsOfDate[0];
        function setAsOfDate(cycle) {
            if($rootScope.lastUpdatedAtDate.getTime() >= new Date(cycle.startDate).getTime()  && $rootScope.lastUpdatedAtDate.getTime()  < new Date(cycle.endDate).getTime() ){
                $scope.asOfDate = $rootScope.lastUpdatedAtDate;
            }else if($rootScope.lastUpdatedAtDate.getTime()  > new Date(cycle.startDate).getTime() ) {
                $scope.asOfDate = new Date(cycle.endDate);
            }
        }
        _this.$onDestroy = function () {
            $rootScope.$emit('hideFromMain', {hide: 'nudges'});
        };
        var getTopPerformers = function () {
            newsAndUpdatesService.fetchTopPerformers($scope.isManager?'manager':'frontline', $scope.selectedCycle._id).then(function(topPerformers){
                $scope.OPPerformers = [];
                $scope.APPerformers = [];
                $scope.IPPerformers = [];
                $scope.movers = {};
                $scope.movers.op = [];
                $scope.movers.ap = [];
                $scope.movers.ip = [];
                var maxUsersPerLeagues = $rootScope.config.overallLeague?3:1;
                topPerformers.forEach(function(leagueAnalytic){
                    for(var i = 0; i< maxUsersPerLeagues; i++){
                        if(leagueAnalytic.points.leaders[i]){
                            $scope.OPPerformers.push(leagueAnalytic.points.leaders[i]);
                        }
                        if(leagueAnalytic.points.gainers[i] && leagueAnalytic.points.gainers[i].deltaRank > 0){
                            $scope.movers.op.push(leagueAnalytic.points.gainers[i]);
                        }
                    }
                    for(var i = 0; i< maxUsersPerLeagues; i++){
                        if(leagueAnalytic.activityPoints.leaders[i]){
                            $scope.APPerformers.push(leagueAnalytic.activityPoints.leaders[i]);
                        }
                        if(leagueAnalytic.activityPoints.gainers[i] && leagueAnalytic.activityPoints.gainers[i].deltaRank > 0){
                            $scope.movers.ap.push(leagueAnalytic.activityPoints.gainers[i]);
                        }
                    }
                    for(var i = 0; i< maxUsersPerLeagues; i++){
                        if(leagueAnalytic.businessPoints.leaders[i]){
                            $scope.IPPerformers.push(leagueAnalytic.businessPoints.leaders[i]);
                        }
                        if(leagueAnalytic.businessPoints.gainers[i] && leagueAnalytic.businessPoints.gainers[i].deltaRank > 0){
                            $scope.movers.ip.push(leagueAnalytic.businessPoints.gainers[i]);
                        }
                    }
                });
                $scope.OPPerformers = _.sortBy($scope.OPPerformers, function(user){
                    return -user.value;
                });
                $scope.APPerformers = _.sortBy($scope.APPerformers, function(user){
                    return -user.value;
                });
                $scope.IPPerformers = _.sortBy($scope.IPPerformers, function(user){
                    return -user.value;
                });
                _.forOwn($scope.movers,function(value, key){
                    $scope.movers[key] = _.sortBy(value, function(user){
                        return -user.value;
                    });
                });
                if($rootScope.config.overallLeague){
                    $scope.OPPerformers = $scope.OPPerformers.slice(0,3);
                    $scope.APPerformers = $scope.APPerformers.slice(0,3);
                    $scope.IPPerformers = $scope.IPPerformers.slice(0,3);
                    _.forOwn($scope.movers,function(value, key){
                        $scope.movers[key] = $scope.movers[key].slice(0,3);
                    });
                }
                if(!$scope.isManager) {
                    $scope.frontLineRoles.forEach(function(role,i){
						if(!$scope.playTypes[i+1]) {
							console.error('Trying to look for playtype that does not exist');
							return;
						}
                        $scope.playTypes[i+1].performer = _.find(topPerformers,{role:role});
                    });
                }else{
                    $scope.managerRoles.forEach(function(role,i){
						if(!$scope.playTypes[i+1]) {
							console.error('Trying to look for playtype that does not exist');
							return;
						}
                        $scope.playTypes[i+1].performer = _.find(topPerformers,{role:role});
                    });
                }
                $scope.performersLoaded = true;
            });
        };
        $scope.getJobRoleName = function(role){
            var jobRole = _.find(p0.allJobRoles,{_id:role})
            if(jobRole){
                return jobRole.name;
            } 
        };
        var getBadgeFeeds = function () {
            newsAndUpdatesService.fetchBadgeFeeds($scope.isManager?'manager':'frontline',$scope.selectedCycle._id).then(function(_badgeFeed){
                $scope.feed = _badgeFeed;
                if($scope.selectedCycle.previousCycleId){
                    newsAndUpdatesService.fetchBadgeFeeds($scope.isManager?'manager':'frontline',$scope.selectedCycle.previousCycleId._id).then(function(oldFeed){
                        $scope.feed = $scope.feed.concat(oldFeed);
                        populateFeed();
                    });
                }else{
                    populateFeed();
                }
                function populateFeed(){
                    var feed = _.groupBy($scope.feed, function(feed){return feed.cycleId;});
                    if(!$scope.isManager) {
                        $scope.frontLineRoles.forEach(function(role,i){
							if(!$scope.playTypes[i+1]) {
								console.error('Trying to look for playtype that does not exist');
								return false;
							}

                            $scope.playTypes[i+1].feed = _.filter($scope.feed,function(feed) {
                                return feed.userId.organizationalTag.jobRole == role;
                            });
                            $scope.playTypes[i+1].feeds = _.groupBy($scope.playTypes[i+1].feed,function (feed) {
                                return feed.cycleId;
                            });
                        });
                    }else{
                        $scope.managerRoles.forEach(function(role,i){
							if(!$scope.playTypes[i+1]) {
								console.error('Trying to look for playtype that does not exist');
								return false;
							}

                            $scope.playTypes[i+1].feed = _.filter($scope.feed,function (feed) {
                                return feed.userId.organizationalTag.jobRole == role;
                            });
                            $scope.playTypes[i+1].feeds = _.groupBy($scope.playTypes[i+1].feed,function (feed) {
                                return feed.cycleId;
                            });
                        });
                    }
                    $scope.newsFeed = filterBadgeData($scope.feed);
                    $scope.newsFeeds = [];
                    _.forOwn(feed, function(value,cycleId){
                        var feedCycle = _.find(p0.allCycles, function(cycle){
                            return cycle._id.toString() === cycleId;
                        });
                        if(feedCycle){
                            var cycleName = feedCycle.name;
                            $scope.newsFeeds.push({cycleId: cycleName, startDate: new Date(feedCycle.startDate), feed:filterBadgeData(value)});
                        }
                    });
                    $scope.newsFeeds = _.sortBy($scope.newsFeeds, function (feed){return -feed.startDate});
                    $scope.playTypes.forEach(function(tab){
                        delete tab.feed;
                        if(tab.feeds){
                            tab.fd = [];
                            _.forOwn(tab.feeds, function(feed,cycleId){
                                var feedCycle = _.find(p0.allCycles, function(cycle){
                                    return cycle._id.toString() === cycleId;
                                });
                                if(feedCycle){
                                    var cycleName = feedCycle.name;
                                    tab.fd.push({cycleId: cycleName, startDate: new Date(feedCycle.startDate), feed:filterBadgeData(feed)});
                                }
                            });
                            tab.fd = _.sortBy(tab.fd, function (feed){return -feed.startDate});
                        }
                    });
                    checkAllSocial();
                }
            });

        };
        $scope.badgeTypes = {
            overall: [
                {name: 'Excellence'}, {name: 'Status'}
            ],
            kpiSpecific: [
                {name: 'Excellence'}, {name: 'Status'}, {name:'Action'},{name: 'Personal Best'}, {name:'All Badges'}
            ]
        };
        $scope.selectedBadgeType = $scope.badgeTypes.overall[0];
        $scope.getBadgeTypes = function () {
            return $scope.selectedPlayType._id == 'overall' ? $scope.badgeTypes['overall'] : $scope.badgeTypes['kpiSpecific'];
        };


        $scope.refreshCarousel = function (selectedBadgeType) {
            $scope.badgeAchieversLoaded = false;
            var badgeAchievers = _.cloneDeep($scope.badgeAchievers);
            if($scope.selectedPlayType._id != 'overall'){
                var selectedKpis = $scope.selectedPlayType.kpis;
                selectedKpis = _.map(selectedKpis, function(kpi){return kpi._id.toString()});
            }
            switch (selectedBadgeType.name) {
                case 'All Badges' :
                    $scope.carousel = badgeAchievers.filter(function (badge, i, l) {
                        if(selectedKpis){
                            var kpiSpecific = badge.from ?_.includes(selectedKpis,badge.from.toString()) : false;
                        }else{
                            var kpiSpecific = true;
                        }
                        return ((badge.badgeCategory == 'overall') && (badge.badgeType == 'total' || badge.badgeType == 'overallBusiness' || badge.badgeType == 'overallActivity')) ||
                            badge.badgeCategory == 'level' || badge.badgeCategory == 'ytdBest' ||
                            (kpiSpecific && (badge.badgeCategory == 'productivity' || badge.badgeCategory == 'conversion' || badge.badgeCategory == 'activity'));
                    });
                    break;
                case 'Excellence' :
                    $scope.carousel = badgeAchievers.filter(function (badge, i, l) {
                        return (badge.badgeCategory == 'overall') && (badge.badgeType == 'total' || badge.badgeType == 'overallBusiness' || badge.badgeType == 'overallActivity');
                    });
                    break;
                case 'Status' :
                    $scope.carousel = badgeAchievers.filter(function (badge, i, l) {
                        return badge.badgeCategory == 'level';
                    });
                    break;
                case 'Personal Best' :
                    $scope.carousel = badgeAchievers.filter(function (badge, i, l) {
                        return badge.badgeCategory == 'ytdBest';
                    });
                    break;
                case 'Action' :
                    $scope.carousel = badgeAchievers.filter(function (badge, i, l) {
                        var kpiSpecific = badge.from ?  _.includes(selectedKpis, badge.from.toString()) : false;
                        return kpiSpecific && (badge.badgeCategory == 'productivity' || badge.badgeCategory == 'conversion' || badge.badgeCategory == 'activity');
                    });
                    break;
            }
            if($scope.selectedPlayType._id != 'overall') {
                $scope.carousel.forEach(function (badge,index) {
                    var temp = _.cloneDeep(badge);
                    temp.users = _.filter(temp.users, function (user) {
                        return user.playTypes[0] == $scope.selectedPlayType._id;
                    });
                    $scope.carousel[index] = temp;
                });
            }
            $scope.carousel = $scope.carousel.filter(function (badge, i, l) {
                return badge.users.length > 0;
            });
            $timeout(function(){
                $scope.badgeAchieversLoaded = true;
            },1);
        };
        var setWeeklyCycles = function (monthCycle) {
            $scope.weeklyCycles = _.filter(_.cloneDeep(p0.allWeeklyCycles), function (weekCycle, index) {
                if(weekCycle.startDate && weekCycle.startDate >= monthCycle.startDate &&  weekCycle.startDate <= monthCycle.endDate){
                    return true;
                }else if(weekCycle.startDate && weekCycle.startDate <= monthCycle.startDate &&  weekCycle.endDate >= monthCycle.startDate){
                    return true;
                }
                else return false;
            });
        };

        $scope.init = function(selectedCycle){
            CurrentCycle.setCycle(selectedCycle);
            setAsOfDate(selectedCycle);
            $scope.showLastSync = selectedCycle.active;
            $scope.selectedBadgeType = $scope.selectedPlayType._id == 'overall' ? $scope.badgeTypes['overall'][0] : $scope.badgeTypes['kpiSpecific'][0];
            getStars(selectedCycle);
            setWeeklyCycles(selectedCycle);
            getBadgeAchievers(selectedCycle, null);
            getBadgeFeeds();
            getTopPerformers();
            $scope.isWeekly = false;
        };
        $scope.changePlayType = function(playType){
            $scope.selectedPlayType = playType;
            $scope.init($scope.selectedCycle);
        };
        var setPlayTypes = function(){
            $scope.frontLineRoles = [];
            $scope.managerRoles = [];
            $scope.playTypes = $scope.allPlayTypes;
            $scope.playTypes.forEach(function(playType){
                $scope.frontLineRoles = $scope.frontLineRoles.concat(playType.frontline);
                $scope.managerRoles = $scope.managerRoles.concat(playType.managers);
            });
            $scope.playTypes.unshift({name:'All ' + $rootScope.strings.PLAYTYPE.value + 's',_id:'overall'});
            if(p0.allPlayTypes.length == 1){
                $scope.selectedPlayType = $scope.playTypes[1]; // if there is only one playType select that playType directly
            }else{
                $scope.selectedPlayType = $scope.playTypes[0];
            }
        };
        $scope.onCycleChange = function(selectedCycle){
            CurrentCycle.setCycle(selectedCycle);
            setAsOfDate(selectedCycle);
            $scope.selectedCycle = selectedCycle;
            $scope.init($scope.selectedCycle);
        };
        setPlayTypes();
        $scope.onCycleChange($scope.selectedCycle);
        $http.post('/api/users').then(function(children){
            children=children.data;
            $scope.players = children;
            $rootScope.$emit('showFromMain', {show: 'nudges', data: {players: $scope.players, kpis: $scope.kpis}});
        });

        function getStars(selectedCycle){
            newsAndUpdatesService.getStarsOfTheDay($scope.isManager?'manager':'frontline',selectedCycle._id).then(function(stars){
                if($rootScope.config.overallLeague){
                    $scope.playTypes[0].star = _.maxBy(stars,'totalPoints');
                }else {
                    $scope.playTypes[0].stars = stars;
                }
                stars.forEach(function(star){
                    var playTypeIndex = _.findIndex($scope.playTypes,(pt) => {
                        return pt._id && pt._id.toString() == star.playTypes[0].toString();
                    });
                    if(playTypeIndex > -1){
                        $scope.playTypes[playTypeIndex].star = star;
                    }
                });
                $scope.starsLoaded = false;
                $timeout(function(){
                    $scope.starsLoaded = true;
                },1);
            });
        }

        function getBadgeAchievers(selectedCycle, weeklyCycleId){
            newsAndUpdatesService.fetchBadgeAchievers($scope.isManager?'manager':'frontline',selectedCycle._id, weeklyCycleId).then(function (badgeAchievers) {
                $scope.badgeAchievers = badgeAchievers;
                $scope.refreshCarousel($scope.selectedBadgeType);
            });

        }
        $scope.changeBadgeAchievers = function (isWeekly) {
            if(isWeekly){
                $scope.weeklyCycle = $scope.weeklyCycles[0];
                $scope.selectedBadgeType = {name:'All Badges'};
                getBadgeAchievers($scope.selectedCycle, p0.currentWeeklyCycle ? p0.currentWeeklyCycle._id : null);
            }else getBadgeAchievers($scope.selectedCycle, null);
        };

        $scope.changeWeekly = function (weeklycycle) {
            getBadgeAchievers($scope.selectedCycle, weeklycycle._id);
        };

        function filterBadgeData(feed){
            var activityBadgeReportees = _.filter(feed,function(feed){
                return feed.subType == 'activity' && feed.feedType == 'monthly';
            });
            var activityBadges = _.sumBy(activityBadgeReportees,function(badgeFeed){
                return badgeFeed.value.number;
            });
            var kpiPerformanceBadgeReportees = _.filter(feed,function(feed){
                return feed.subType == 'performance' && feed.kpiId != null && feed.feedType == 'monthly';
            });
            var kpiPerformanceBadges = _.sumBy(kpiPerformanceBadgeReportees,function(badgeFeed){
                return badgeFeed.value.number;
            });
            kpiPerformanceBadgeReportees = _.uniqBy(kpiPerformanceBadgeReportees,function(feed){
                return feed.userId._id;
            });
            var excellenceBadgeReportees = _.filter(feed,function(feed){
                return feed.subType == 'performance' &&  feed.kpiId == null && feed.feedType == 'monthly';
            });
            var excellenceBadges = _.sumBy(excellenceBadgeReportees,function(badgeFeed){
                return badgeFeed.value.number;
            });


            var wtdActivityBadgeReportees = _.filter(feed,function(feed){
                return feed.subType == 'activity' && feed.feedType == 'weekly';
            });
            var wtdActivityBadges = _.sumBy(wtdActivityBadgeReportees,function(badgeFeed){
                return badgeFeed.value.number;
            });
            var wtdKpiPerformanceBadgeReportees = _.filter(feed,function(feed){
                return feed.subType == 'performance' && feed.kpiId != null && feed.feedType == 'weekly';
            });
            var wtdKpiPerformanceBadges = _.sumBy(wtdKpiPerformanceBadgeReportees,function(badgeFeed){
                return badgeFeed.value.number;
            });
            wtdKpiPerformanceBadgeReportees = _.uniqBy(wtdKpiPerformanceBadgeReportees,function(feed){
                return feed.userId._id;
            });
            var wtdExcellenceBadgeReportees = _.filter(feed,function(feed){
                return feed.subType == 'performance' &&  feed.kpiId == null && feed.feedType == 'weekly';
            });
            var wtdExcellenceBadges = _.sumBy(wtdExcellenceBadgeReportees,function(badgeFeed){
                return badgeFeed.value.number;
            });
            var levelBadgeReportees = _.filter(feed,function(feed){
                return feed.subType == 'level';
            });
            var levelBadges = _.sumBy(levelBadgeReportees,function(badgeFeed){
                return badgeFeed.value.number;
            });
            var statusBadgeReportees = _.filter(feed,function(feed){
                return feed.subType == 'status';
            });
            var statusBadges = _.sumBy(statusBadgeReportees,function(badgeFeed){
                return badgeFeed.value.number;
            });
            var conversionBadgeReportees = _.filter(feed,function(feed){
                return feed.subType == 'conversion';
            });
            var conversionBadges = _.sumBy(conversionBadgeReportees,function(badgeFeed){
                return badgeFeed.value.number;
            });
            var decileJumpReportees = _.filter(feed,function(feed){
                return feed.subType == 'decileJump';
            });
            var decileJumpBadges = _.sumBy(decileJumpReportees,function(badgeFeed){
                return badgeFeed.value.number;
            });
            var YTDReportees = _.filter(feed,function(feed){
                return feed.subType == 'ytdBest';
            });
            var YTDBadges = _.sumBy(YTDReportees,function(badgeFeed){
                return badgeFeed.value.number;
            });

            var productivityBadgeReportees = _.filter(feed,function(feed){
                return feed.subType == 'productivity' && feed.kpiId == null;
            });
            var productivityBadges =  _.sumBy(productivityBadgeReportees,function(badgeFeed){
                return badgeFeed.value.number;
            });
            var kpiProductivityBadgeReportees = _.filter(feed,function(feed){
                return feed.subType == 'productivity' && feed.kpiId != null;
            });
            var kpiProductivityBadges = _.sumBy(kpiProductivityBadgeReportees,function(badgeFeed){
                return badgeFeed.value.number;
            });
            kpiProductivityBadgeReportees =  _.uniqBy(kpiProductivityBadgeReportees,function(feed){
                return feed.userId._id;
            });
            return {
                activityBadges:activityBadges,
                activityBadgeReportees:activityBadgeReportees,
                kpiPerformanceBadges:kpiPerformanceBadges,
                kpiPerformanceBadgeReportees:kpiPerformanceBadgeReportees,
                excellenceBadges:excellenceBadges,
                excellenceBadgeReportees:excellenceBadgeReportees,
                wtdActivityBadges:wtdActivityBadges,
                wtdActivityBadgeReportees:wtdActivityBadgeReportees,
                wtdKpiPerformanceBadges:wtdKpiPerformanceBadges,
                wtdKpiPerformanceBadgeReportees:wtdKpiPerformanceBadgeReportees,
                wtdExcellenceBadges:wtdExcellenceBadges,
                wtdExcellenceBadgeReportees:wtdExcellenceBadgeReportees,
                levelBadges:levelBadges,
                levelBadgeReportees:levelBadgeReportees,
                statusBadges:statusBadges,
                statusBadgeReportees:statusBadgeReportees,
                conversionBadges:conversionBadges,
                conversionBadgeReportees:conversionBadgeReportees,
                decileJumpBadges:decileJumpBadges,
                decileJumpReportees:decileJumpReportees,
                YTDBadges:YTDBadges,
                YTDReportees:YTDReportees,
                productivityBadges:productivityBadges,
                productivityBadgeReportees:productivityBadgeReportees,
                kpiProductivityBadges:kpiProductivityBadges,
                kpiProductivityBadgeReportees:kpiProductivityBadgeReportees
            }
        }

        function checkAllSocial(){
            if($scope.newsFeed.activityBadgeReportees.length > 0) {
                $scope.newsFeed.activityBadgesLikedAll = _.every($scope.newsFeed.activityBadgeReportees, function(feed){
                    if(feed.userId._id == $scope.me._id){
                        return true;
                    }
                    return _.every(feed.value.badges,'liked');
                });
                $scope.newsFeed.activityBadgesCommentedAll = _.every($scope.newsFeed.activityBadgeReportees, function(feed){
                    if(feed.userId._id == $scope.me._id){
                        return true;
                    }
                    return _.every(feed.value.badges,'commented');
                });
            }
            if($scope.newsFeed.wtdActivityBadgeReportees.length > 0) {
                $scope.newsFeed.wtdActivityBadgesLikedAll = _.every($scope.newsFeed.wtdActivityBadgeReportees, function(feed){
                    if(feed.userId._id == $scope.me._id){
                        return true;
                    }
                    return _.every(feed.value.badges,'liked');
                });
                $scope.newsFeed.wtdActivityBadgesCommentedAll = _.every($scope.newsFeed.wtdActivityBadgeReportees, function(feed){
                    if(feed.userId._id == $scope.me._id){
                        return true;
                    }
                    return _.every(feed.value.badges,'commented');
                });
            }
            if($scope.newsFeed.kpiPerformanceBadgeReportees.length > 0) {
                $scope.newsFeed.kpiPerformanceBadgesLikedAll = _.every($scope.newsFeed.kpiPerformanceBadgeReportees, function(feed){
                    if(feed.userId._id == $scope.me._id){
                        return true;
                    }
                    return _.every(feed.value.badges,'liked');
                });
                $scope.newsFeed.kpiPerformanceBadgesCommentedAll = _.every($scope.newsFeed.kpiPerformanceBadgeReportees, function(feed){
                    if(feed.userId._id == $scope.me._id){
                        return true;
                    }
                    return _.every(feed.value.badges,'commented');
                });
            }
            if($scope.newsFeed.wtdKpiPerformanceBadgeReportees.length > 0) {
                $scope.newsFeed.wtdKpiPerformanceBadgesLikedAll = _.every($scope.newsFeed.wtdKpiPerformanceBadgeReportees, function(feed){
                    if(feed.userId._id == $scope.me._id){
                        return true;
                    }
                    return _.every(feed.value.badges,'liked');
                });
                $scope.newsFeed.wtdKpiPerformanceBadgesCommentedAll = _.every($scope.newsFeed.wtdKpiPerformanceBadgeReportees, function(feed){
                    if(feed.userId._id == $scope.me._id){
                        return true;
                    }
                    return _.every(feed.value.badges,'commented');
                });
            }
            if($scope.newsFeed.excellenceBadgeReportees.length > 0) {
                $scope.newsFeed.excellenceBadgesLikedAll = _.every($scope.newsFeed.excellenceBadgeReportees, function(feed){
                    if(feed.userId._id == $scope.me._id){
                        return true;
                    }
                    return _.every(feed.value.badges,'liked');
                });
                $scope.newsFeed.excellenceBadgesCommentedAll = _.every($scope.newsFeed.excellenceBadgeReportees, function(feed){
                    if(feed.userId._id == $scope.me._id){
                        return true;
                    }
                    return _.every(feed.value.badges,'commented');
                });
            }
            if($scope.newsFeed.wtdExcellenceBadgeReportees.length > 0) {
                $scope.newsFeed.wtdExcellenceBadgesLikedAll = _.every($scope.newsFeed.wtdExcellenceBadgeReportees, function(feed){
                    if(feed.userId._id == $scope.me._id){
                        return true;
                    }
                    return _.every(feed.value.badges,'liked');
                });
                $scope.newsFeed.wtdExcellenceBadgesCommentedAll = _.every($scope.newsFeed.wtdExcellenceBadgeReportees, function(feed){
                    if(feed.userId._id == $scope.me._id){
                        return true;
                    }
                    return _.every(feed.value.badges,'commented');
                });
            }
            if($scope.newsFeed.levelBadgeReportees.length > 0) {
                $scope.newsFeed.levelBadgesLikedAll = _.every($scope.newsFeed.levelBadgeReportees, function(feed){
                    if(feed.userId._id == $scope.me._id){
                        return true;
                    }
                    return _.every(feed.value.badges,'liked');
                });
                $scope.newsFeed.levelBadgesCommentedAll = _.every($scope.newsFeed.levelBadgeReportees, function(feed){
                    if(feed.userId._id == $scope.me._id){
                        return true;
                    }
                    return _.every(feed.value.badges,'commented');
                });
            }
            if($scope.newsFeed.statusBadgeReportees.length > 0) {
                $scope.newsFeed.statusBadgesLikedAll = _.every($scope.newsFeed.statusBadgeReportees, function(feed){
                    if(feed.userId._id == $scope.me._id){
                        return true;
                    }
                    return _.every(feed.value.badges,'liked');
                });
                $scope.newsFeed.statusBadgesCommentedAll = _.every($scope.newsFeed.statusBadgeReportees, function(feed){
                    if(feed.userId._id == $scope.me._id){
                        return true;
                    }
                    return _.every(feed.value.badges,'commented');
                });
            }
            if($scope.newsFeed.conversionBadgeReportees.length > 0) {
                $scope.newsFeed.conversionBadgesLikedAll = _.every($scope.newsFeed.conversionBadgeReportees, function(feed){
                    if(feed.userId._id == $scope.me._id){
                        return true;
                    }
                    return _.every(feed.value.badges,'liked');
                });
                $scope.newsFeed.conversionBadgesCommentedAll = _.every($scope.newsFeed.conversionBadgeReportees, function(feed){
                    if(feed.userId._id == $scope.me._id){
                        return true;
                    }
                    return _.every(feed.value.badges,'commented');
                });
            }
            if($scope.newsFeed.decileJumpReportees.length > 0) {
                $scope.newsFeed.decileJumpBadgesLikedAll = _.every($scope.newsFeed.decileJumpReportees, function(feed){
                    if(feed.userId._id == $scope.me._id){
                        return true;
                    }
                    return _.every(feed.value.badges,'liked');
                });
                $scope.newsFeed.decileJumpBadgesCommentedAll = _.every($scope.newsFeed.decileJumpReportees, function(feed){
                    if(feed.userId._id == $scope.me._id){
                        return true;
                    }
                    return _.every(feed.value.badges,'commented');
                });
            }
            if($scope.newsFeed.YTDReportees.length > 0) {
                $scope.newsFeed.YTDBadgesLikedAll = _.every($scope.newsFeed.YTDReportees, function(feed){
                    if(feed.userId._id == $scope.me._id){
                        return true;
                    }
                    return _.every(feed.value.badges,'liked');
                });
                $scope.newsFeed.YTDBadgesCommentedAll = _.every($scope.newsFeed.YTDReportees, function(feed){
                    if(feed.userId._id == $scope.me._id){
                        return true;
                    }
                    return _.every(feed.value.badges,'commented');
                });
            }
            if($scope.newsFeed.productivityBadgeReportees.length > 0) {
                $scope.newsFeed.productivityBadgesLikedAll = _.every($scope.newsFeed.productivityBadgeReportees, function(feed){
                    if(feed.userId._id == $scope.me._id){
                        return true;
                    }
                    return _.every(feed.value.badges,'liked');
                });
                $scope.newsFeed.productivityBadgesCommentedAll = _.every($scope.newsFeed.productivityBadgeReportees, function(feed){
                    if(feed.userId._id == $scope.me._id){
                        return true;
                    }
                    return _.every(feed.value.badges,'commented');
                });
            }
            if($scope.newsFeed.kpiProductivityBadgeReportees.length > 0) {
                $scope.newsFeed.kpiProductivityBadgesLikedAll = _.every($scope.newsFeed.kpiProductivityBadgeReportees, function(feed){
                    if(feed.userId._id == $scope.me._id){
                        return true;
                    }
                    return _.every(feed.value.badges,'liked');
                });
                $scope.newsFeed.kpiProductivityBadgesCommentedAll = _.every($scope.newsFeed.kpiProductivityBadgeReportees, function(feed){
                    if(feed.userId._id == $scope.me._id){
                        return true;
                    }
                    return _.every(feed.value.badges,'commented');
                });
            }
        }

        $scope.social = function(event, action,dailyFeedData,type){
            event.stopPropagation();
            dailyFeed.social(action,dailyFeedData,type,null).then(function(){
                checkAllSocial();
            });
        };
        $scope.openBulkCommentModal = function(event, dailyFeed){
            event.stopPropagation();
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'bulkCommentModalCtrl',
                resolve:{
                    dailyFeeds:function(){return dailyFeed;},
                    onClose: function(){return checkAllSocial;},
                    type:function(){return 'badge';}
                },
                locals: {
                    badgeId: null,
                    dataFromDailyFeed: null
                }
            });
        };
        $scope.slickConfig = {
            method: {},
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 11,
            slidesToScroll: 11,
            slidesPerRow:11,
            arrows:true,
            draggable:true,
            autoplay: true,
            autoplaySpeed: 2000,
            pauseOnHover:true
        };


        $scope.openDailyFeedModal = function(data){
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate2,
                controller: 'dailyFeedController',
                resolve: {
                    data: function () {
                        return data;
                    },
                    type : function () {
                        return 'badge';
                    },
                    onClose : function () {
                        return checkAllSocial;
                    }
                }
            });
        };
        $scope.overallOrSpecific = function (tab) {
            if(!$scope.gainerSelected) {
                if ($scope.selectedPlayType._id == 'overall') {
                    switch (tab) {
                        case 'op':
                            return $scope.OPPerformers;
                        case 'ip':
                            return $scope.IPPerformers;
                        case 'ap':
                            return $scope.APPerformers;
                    }
                }
                else {
                    if ($scope.selectedPlayType && $scope.selectedPlayType.performer) {
                        let performer = $scope.selectedPlayType.performer;
                        switch (tab) {
                            case 'op':
                                return performer.points ? performer.points.leaders : '';
                            case 'ip':
                                return performer.businessPoints ? performer.businessPoints.leaders : '';
                            case 'ap':
                                return performer.activityPoints ? performer.activityPoints.leaders : '';
                        }
                    }
                }
            }else{
                if ($scope.selectedPlayType._id == 'overall') {
                    switch (tab) {
                        case 'op':
                            return $scope.movers.op;
                        case 'ip':
                            return $scope.movers.ip;
                        case 'ap':
                            return $scope.movers.ap;
                    }
                }else{
                    if ($scope.selectedPlayType && $scope.selectedPlayType.performer) {
                        let performer = $scope.selectedPlayType.performer;
                        switch (tab) {
                            case 'op':
                                if (performer.points) return _.filter(performer.points.gainers, function (player) { return player.deltaRank > 0 });
                                return;
                            case 'ip':
                                if (performer.businessPoints) return _.filter(performer.businessPoints.gainers, function (player) { return player.deltaRank > 0 });
                                return;
                            case 'ap':
                                if (performer.activityPoints) return _.filter(performer.activityPoints.gainers, function (player) { return player.deltaRank > 0 });
                                return;
                        }
                    }
                }
            }
        };


        $scope.showFullList = function (badgeRow, format) {
            $rootScope.$emit ('showFromMain', {show: format, data: badgeRow});
        };
    });
