'use strict';

'use strict';
angular.module('bitnudgeApp')
    .service('queryUtilityService', ['$http','$q', function ($http, $q) {
        var self = this;
        this.getResults = function(collectionId, query) {
            if (collectionId === 'lead') {
                return self.getLeads(query);
            } else if (collectionId === 'ticket') {
                return self.getTickets(query);
            }  else if (collectionId === 'customer') {
                return self.getCustomers(query);
            }  else if (collectionId === 'portfolio') {
                return self.getPortfolio(query);
            }  else if (collectionId === 'target') {
                return self.getTarget(query);
            }  else if (collectionId === 'customerRanking') {
                return self.getCustomerRanks(query);
            }  else if (collectionId === 'leaderBoard') {
                return self.getLeaderBoardData(query);
            }
        }
        this.getLeads = function(query){
            var deferred = $q.defer();
            var baseUrl = '/api/queryUtility/leads';
			$http.get(baseUrl, {params: query}).then(function (result) {
                deferred.resolve(result);
			}, function (error) {
				deferred.reject(error.data.errors[0]);
			});
            return deferred.promise;
        };

        this.getTickets = function(query){
            var deferred = $q.defer();
            var baseUrl = '/api/queryUtility/tickets';
            $http.get(baseUrl, {params: query})
            .success(function(tickets){
                deferred.resolve(tickets);
            })
            .error(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        };

        this.getCustomers = function(query){
            var deferred = $q.defer();
            var baseUrl = '/api/queryUtility/customers';
            // $http.get(baseUrl, {params: query})
            // .success(function(customers){
            //     deferred.resolve(customers);
            // })
            // .error(function(error) {
            //     deferred.reject(error);
            // });

            $http.get(baseUrl, {params: query}).then(function (result) {
                deferred.resolve(result);
			}, function (error) {
				deferred.reject(error.data.errors[0]);
			});

            return deferred.promise;
        };

        this.getPortfolio = function(query){
            var deferred = $q.defer();
            var baseUrl = '/api/queryUtility/portfolio';
            $http.get(baseUrl, {params: query})
            .success(function(portfolio){
                deferred.resolve(portfolio);
            })
            .error(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        };

        this.getTarget = function(query){
            var deferred = $q.defer();
            var baseUrl = '/api/queryUtility/target';
            $http.get(baseUrl, {params: query})
            .success(function(target){
                deferred.resolve(target);
            })
            .error(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        };

        this.getCustomerRanks = function(query){
            var deferred = $q.defer();
            var baseUrl = '/api/queryUtility/customerRanks';
            $http.get(baseUrl, {params: query})
            .success(function(customerRanks){
                deferred.resolve(customerRanks);
            })
            .error(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        };

        this.getUserByEmail = function(userEmail){
            var deferred = $q.defer();
            var baseUrl = '/api/users/getUser/userEmail/';
            $http.get(baseUrl + userEmail, {params:{populateJobRole:true}})
            .success(function(customers){
                deferred.resolve(customers);
            })
            .error(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        };

        this.getCyclesByEmail = function(userEmail){
            var deferred = $q.defer();
            var baseUrl = '/api/cycles/getAllCyclesByUserEmail/';
            $http.get(baseUrl + userEmail)
            .success(function(customers){
                deferred.resolve(customers);
            })
            .error(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        };

        this.getLeaderBoardData = function(query) {
            var deferred = $q.defer();
            var baseUrl = '/api/queryUtility/leaderBoard';
            $http.post(baseUrl, query)
            .success(function(customerRanks){
                deferred.resolve(customerRanks);
            })
            .error(function(error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }

    }]);
