'use strict';

angular.module('bitnudgeApp')
.filter('convertToDays', function () {
    return function (value) {
        if(value!=undefined) {
            var currentDateInMillis = new Date().getTime();
            var dateInMillis = new Date(value).getTime();
            return Math.round((currentDateInMillis - dateInMillis)/(1000*24*60*60));
        }else{
            return "";
        }
    };
})
.filter('timeInHHMMSS', function () {
    return function (current, value) {
        if(value!=undefined) {
            var currentDateInMillis = current? new Date(current).getTime() :new Date().getTime();
            var refDate = new Date(value).getTime();
            if(!isNaN(refDate)){
                var duration, since = '';
                if(refDate> currentDateInMillis){
                    duration = refDate - currentDateInMillis;
                }else{
                    duration = currentDateInMillis - refDate;
                    since = 'Since '
                }
                var milliseconds = parseInt((duration%1000)/100),
                    seconds = parseInt((duration/1000)%60),
                    minutes = parseInt((duration/(1000*60))%60),
                    hours = parseInt((duration/(1000*60*60))%24);
                if(hours) {
                    hours = (hours < 10) ? "0" + hours : hours;
                    hours = hours + ' h: '
                }
                else hours = '';
                if(minutes){
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    minutes = minutes + ' m: '
                }else minutes = '';
                if(seconds){
                    seconds = (seconds < 10) ? "0" + seconds : seconds;
                    seconds = seconds + ' s'
                }else seconds = '';
                return since + hours + minutes + seconds;
            }else{
                return "";
            }
        }else{
            return "";
        }
    };
})
.filter('secondsToDateTime', function () {
    return function(seconds) {
        return new Date(1970, 0, 1).setSeconds(seconds);
    };
})
.filter('abs', function () {
    return function(val) {
        return Math.abs(val);
    }
})
.filter('arrayToText', function () {
    return function(val) {
        if(val.length < 2){
            return val[0];
        }
        var last = val[val.length - 1];
        var valCopy = _.cloneDeep(val);
        valCopy.pop();
        var result = valCopy.join(', ');
        result = result + ' and '+ last;
        return result;
    }
})
.filter('capitalize', function() {
    return function(input, all) {
        var reg = (all) ? /([^\W_]+[^\s-]*) */g : /([^\W_]+[^\s-]*)/;
        return (!!input) ? input.replace(reg, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}) : '';
    }
})
.filter('truncate', function(){
    return function(input, truncateAfter) {
        if(input == undefined)
            return input
        if(input.length > truncateAfter)
            return input.substring(0, truncateAfter)+'...'
        else
            return input
    }
})
.filter('phoneNumber', function(){
    return function(tel) {
        if(!tel) return '';
        return tel.slice(0,4)+' '+tel.slice(4,6)+' '+tel.slice(6,9)+' '+tel.slice(9);
    }
})
.filter('truncateStatus', function(){
    return function(input, truncateAfter){
        if(input == undefined)
            return input
        if(input.length > truncateAfter) {
            if(input.substring('Submitted') != -1)
                return input.replace('Submitted', 'Sub.');
            else
                return input;
        } else
            return input;
    }
})
.filter('getProductHolding', function(){
    return function(input){
        var result = '';
        if(typeof input == 'object'){
            Object.keys(input).forEach(function(key){
                if(input[key] != undefined && input[key] != 0 && key != 'total')
                    result += key+';';
            })
        }
        return result;
    }
})
.filter('formatDate', function(){
    return function(input){
        if(input instanceof Date){
            return input.toString().substring(0,24)
        } else {
            var temp = new Date(input)
            return temp.toString().substring(0,24)
        }
    }
})
.filter('camelCaseToCapitalize', function() {
    return function(input, all) {
        if (input) {
            const text = input.replace( /([A-Z])/g, " $1" );
            const processedText = text.charAt(0).toUpperCase() + text.slice(1);
            return processedText;
        }
    }
})



