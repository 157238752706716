angular.module('bitnudgeApp')
	.directive('pieChart', function ($rootScope) {
		return {
			restrict: 'EA',
			transclude: true,
			scope: {
                data: '=',
                warning: '=',
				bin: '=',
				export: '=',
				id: '@',
				title: '@',
				height: '@',
                type: '@',
                legend: '@',
			},

			replace: true,
			link: function (scope, element, attrs, rootScope) {
                

                scope.render = function(data) {
                    function numberWithCommas(x) {
                        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                    var legendRight = false;
                    if(scope.legend == 'right' || scope.legend == 'Right'){
                        legendRight = true;
                    }
                    scope.ycord = 25 * data.length
                    var w = element.parent()[0].clientWidth || 380;
                    h =  legendRight ? 380 : 570;
                    var r = legendRight ? 180 : (w / 2) - 50;
                    var color = d3.scale.category20c();
                    var div = d3.select(element[0]).append("div")
                        .attr("class", "tooltip")
                        .style('background', 'black')
                        .style('padding', '10px')
                        .style('color', 'white')
                        .style("opacity", 0)
                        .style('height', 10)

                    var vis = d3.select(element[0]).append("svg:svg").data([data]).attr("width", w).attr("height", h - 20).append("svg:g")
                        .attr('viewBox', '0 0 ' + Math.min(w, h) + ' ' + Math.min(w, h))
                        .attr('preserveAspectRatio', 'xMinYMin')
                        .attr("transform", "translate(" + r + "," + r + ")");

                    var pie = d3.layout.pie()
                        .value(function(d) { 
                        return d.value; });

                    var arc = d3.svg.arc()
                                .outerRadius(r)
                                .innerRadius(0);

                    var arcs = vis.selectAll("g.slice").data(pie)
                                .enter()
                                .append("svg:g")
                                .attr("class", "slice");
                    arcs.append("svg:path")
                        .attr('class', 'area')
                        .attr("fill", function(d, i) {
                            return color(i);
                        })
                        .attr("d", function(d) {
                            return arc(d);
                        })
//
                        .on('mouseover', function(d) {
                            div.transition()
                                .duration(200)
                                .style("opacity", .9);
                            if (d.data.percent) {
                                div.html(scope.type+' : ' + JSON.stringify(d.data.key) + '<br>Value : ' + numberWithCommas(d.value.toFixed(2)) + '<br>Percentage: ' + d.data.percent + "%" + "")
                                .style("left", (d3.mouse(this)[0] + 30) + "px")
                                .style("top", (d3.mouse(this)[1] + 150) + "px");   
                            } else {
                                div.html(scope.type+' : ' + JSON.stringify(d.data.key) + '<br>Value : ' + numberWithCommas(d.value.toFixed(2)) + "")
                                .style("left", (d3.mouse(this)[0] + 30) + "px")
                                .style("top", (d3.mouse(this)[1] + 150) + "px");
                            }
                        })
                        .on('mouseout', function(d) {
                            div.transition()
                                .duration(300)
                                .style("opacity", 0);
                        })
                        .style('stroke', 'white')
                        .style('stroke-width', 1)
                        .transition()
                        .duration(function(d, i) {
                        return i * 1000;
                        })
                        .attrTween('d', function(d) {
                            var i = d3.interpolate(d.startAngle+0.1, d.endAngle);
                            return function(t) {
                                d.endAngle = i(t);
                                return arc(d);
                            }
                        })

                    arcs.append("svg:text")   
                        .attr("class", "text-in-arc")                                  //add a label to each slice
                        .attr("transform", function(d) {                    //set the label's origin to the center of the arc
                        //we have to make sure to set these before calling arc.centroid
                        d.innerRadius = 0;
                        d.outerRadius = r;
                        return "translate(" + arc.centroid(d) + ")";        //this gives us a pair of coordinates like [50, 50]
                    })
                    .attr("text-anchor", "middle")  //center the text on it's origin
                    .style("fill", "#fff")                      
                    .text(function(d, i) {
                        return d.data.percent ? d.data.percent + "%" : d.data.value; 
                    });

                    if(!legendRight){
                        var legend = vis.append("g")
                            .attr("class", "legend")
                            .attr("width", w - 50)
                            .attr("height", -100)
                            .selectAll("g")
                            .data(data)
                            .enter().append("g")
                            .attr("transform", function(d, i) {
                                return "translate(" + (-20) + "," + (i * 20 + r+20) + ")";
                            });
                        var warn = vis.append("g")
                            .attr("class", "legend")
                            .attr("width", w - 50)
                            .attr("height", -100)
                            .selectAll("g")
                            .data([data[0]])
                            .enter().append("g")
                            .attr("transform", function(d, i) {
                                return "translate(" + (-20) + "," + (i * 20 + r+20) + ")";
                            });
                    }else{
                        var legend = vis.append("g")
                        .attr("class", "legend")
                        .attr("width", w - 50)
                        .attr("height", -100)
                        .selectAll("g")
                        .data(data)
                        .enter().append("g")
                        .attr("transform", function(d, i) {
                            return "translate(" + (r+20) + "," + (i * 20 - r+20) + ")";
                        });
                    }
                    

                    
                    

                    legend.append("rect")
                        .attr("width", 15)
                        .attr("height", 15)
                        .style("fill", function(d, i) {
                            return color(i);
                        });

                    legend.append("text")
                        .attr("x", 24)
                        .attr("y", 9)
                        .attr("dy", ".35em")
                        .style('font-size', 10)
                        .text(function(d) {
                            return d.key;
                        });
                    
                    if(scope.warning) {
                        warn.append("text")
                            .attr("x", -55)
                            .attr("y", scope.ycord)
                            .attr("dy", ".35em")
                            .style('font-size', 10)
                            .text(function() {
                                return '*value excludes DCI and Insurance';
                            });
                    }

                };

				scope.$watch('data', function (data) {
					 if (data != undefined){
                        scope.render(data);
                     }
				});

			}
		};
	});