'use strict';
angular.module('bitnudgeApp')
    .service('rhsAnalyticsL1', function (userAnalyticsService,$rootScope,leagueAnalytic,p0,$filter, ForecastMultiplier, getGPService, Accuracy, $q) {
        let self = this;
        self.config = p0.config;
        this.getOverallPercentage = function (userAnalytic, user) {
            return (userAnalytic && userAnalytic.percentageCompleted && userAnalytic.percentageCompleted.value) || 0;
        };
        this.getData = function(user,kpi,cycle){
            var bankLeagueAnalytic, yesterdayAnalytics;
            return userAnalyticsService.fetchUserAnalytics(user._id,cycle._id)
            .then(function(userAnalytic){
                var bankLeague = _.find(userAnalytic.leagues,{teamType:'bank'});
                var leagueCycle,yesterdayCycle;
                let params = {
                    kpi : kpi,
                    user : user,
                    userAnalytic: userAnalytic,
                    bankLeagueAnalytic: bankLeagueAnalytic,
                    yesterdayAnalytics: yesterdayAnalytics,
                    cycle :cycle
                };
                if(cycle.cycleType == 'daily'){
                    leagueCycle = p0.currentMonthlyCycle;
                    yesterdayCycle = p0.previousDailyCycle;
                    
                    if(yesterdayCycle){
                        return userAnalyticsService.fetchUserAnalytics(user._id,yesterdayCycle._id).then(function(analytics){
                            yesterdayAnalytics = analytics;
                            return self.fetchLeagueAnalytics(bankLeague, leagueCycle, params);
                        });
                    }else return self.fetchLeagueAnalytics(bankLeague, leagueCycle, params);
                }else{
                    leagueCycle = cycle;
                    return self.fetchLeagueAnalytics(bankLeague, leagueCycle, params);
                }
            });
        };

        this.fetchLeagueAnalytics = (bankLeague, leagueCycle, params) => {
            if(bankLeague) {
                return leagueAnalytic.fetchLeagueAnalytic(bankLeague.leagueId, leagueCycle._id)
                .then(function (leagueAnalytic) {
                    params.bankLeagueAnalytic = leagueAnalytic;
                    return self.setData(params);
                });
            }else{
                return self.setData(params);
            }
        }

        this.setData = (params) => {
            let {kpi, user, cycle, yesterdayAnalytics, bankLeagueAnalytic, userAnalytic} = params;            
            //MTD
            var activities = p0.config.rhsActivities;
            var cardsData = [];

            //Social
            var likes = userAnalytic.likes;
            var follows = userAnalytic.followers;
            var comments = userAnalytic.mentions;

            //volume processed
            if (kpi._id != 'overall') {
                var {percentageCompleted, achieved, forecast, actual, pipeline, incentive,
                incentiveForecast, VIPTarget, VIPVolume, rank, badges } = self.getRhsKpiWiseData({userAnalytic, kpi, cycle, activities, user, params, cardsData});
            }
            else {
                var {percentageCompleted, achieved, forecast, pipeline, incentive,
                incentiveForecast, VIPTarget, VIPVolume, rank, badges } = self.getRhsOverallData({userAnalytic, cycle, activities, user, params, cardsData});
            }

            var pastCycle = self.isPastCycle(cycle);
            //MTD
            
            var {card1Heading, dataHeading} = self.getCard1AndDataHeading(cycle);
            let tempDataHeading = dataHeading;
            var {card1Value, card1Forecast, dotStatus, subValue, dataHeading} = self.getCard1Information(percentageCompleted, pastCycle, achieved, forecast);
            if(!dataHeading)
                 dataHeading = tempDataHeading;
            let pipelineValue = pastCycle ? 'NA' : ( pipeline ? $filter('number')(pipeline, 0) + '%': pipeline + '%');

            let returnData = self.setReturnData({
                card1Heading, dataHeading, card1Value, card1Forecast, kpi, cycle,
                dotStatus, subValue, percentageCompleted, pastCycle, bankLeagueAnalytic,
                achieved, forecast, VIPVolume, VIPTarget, incentive, activities,
                likes, follows, badges, comments, rank, pipelineValue, cardsData, user
            });
            
            return $q.resolve(returnData);
        }

        this.getRhsKpiWiseData = ({userAnalytic, kpi, cycle, activities, user, params, cardsData}) => {
            var kpiData = _.find(userAnalytic.kpiData, {kpiId: kpi._id});
            var currentActivities = _.find(p0.allKpis,{_id:kpi._id}).activities;
            currentActivities = _.map(currentActivities,function(id){
                return _.find(p0.allActivities,{_id:id});
            });
            //MTD
            let {percentageCompleted, achieved, forecast} = self.getMTDData(cycle, kpiData, kpi);
            let actual = kpiData.achievedValue.value;
            let pipeline = kpiData.pipelinePercentage.value;
            activities.forEach(function (fieldName) {
                var kpiAct = _.find(p0.myActivities,{fieldName:fieldName});
                if(kpiAct && kpiAct.kpiSpecific && _.find(kpiAct.kpiData,{kpiId:kpi._id})) {
                    cardsData.push(self.setKpiActivityDataHelper(fieldName, kpiData, params));
                }
            });

            //Social
            if(user.roleType != 'seniorManager') {
                var {rank, badges} = self.getSocialData(kpiData, userAnalytic, kpi);
            }

            //Incentive
            if(p0.myIncentive) {
                var incentiveForecast = (kpiData.achievedValue.value * ForecastMultiplier.ofCycle(cycle._id) - (kpiData.target.value*p0.myIncentive.threshold/100)) * p0.myIncentive.payout;
                var incentive = incentiveForecast>0?incentiveForecast:0;
            }
            let VIPTarget = 0, VIPVolume = 0;
            return {
                percentageCompleted, achieved, forecast, actual, pipeline, incentive,
                incentiveForecast, VIPTarget, VIPVolume, rank, badges
            }
        }

        this.getRhsOverallData = ({userAnalytic, cycle, activities, user, params, cardsData}) => {
            let percentageCompleted, achieved, forecast, pipeline, incentive,
            incentiveForecast, VIPTarget, VIPVolume;
            //MTD
            if(cycle.cycleType == 'weekly' && self.config.showGameplan){
                percentageCompleted = self.getOverallPercentage(userAnalytic, user);
                forecast = percentageCompleted * $rootScope.weeklyForecastMultiplier;
            }else {
                percentageCompleted = self.getOverallPercentage(userAnalytic, user);
                forecast = cycle.cycleType == 'daily'?percentageCompleted: percentageCompleted * ForecastMultiplier.ofCycle(cycle._id);
            }
            pipeline = userAnalytic.pipelinePercentage.value;
            
            activities.forEach(function (fieldName) {
                if(_.find(p0.myActivities,{fieldName:fieldName})) {
                    cardsData.push(self.setOverallActivityDataHelper(fieldName, params));
                }
            });

            //Social
            if(user.roleType != 'seniorManager'){
                var rank = userAnalytic.points.ranks.length ? userAnalytic.points.ranks[userAnalytic.points.ranks.length - 1].value : '';
                var badges = userAnalytic.badgeCount.value;
            }

            //Incentive
            if(p0.myIncentive) {
                var kpiData = _.find(userAnalytic.kpiData,{kpiId:p0.myKpis[0]._id});
                incentiveForecast = (kpiData.achievedValue.value * ForecastMultiplier.ofCycle(cycle._id) - (kpiData.target.value*p0.myIncentive.threshold/100)) * p0.myIncentive.payout;
                incentive = incentiveForecast>0?incentiveForecast:0;
            }
            VIPTarget = 0, VIPVolume = 0;
            return {
                percentageCompleted, achieved, forecast, pipeline, incentive,
                incentiveForecast, VIPTarget, VIPVolume, rank, badges
            }
        }

        this.getSocialData = (kpiData, userAnalytic, kpi) => {
            let rank, badges;
            rank = kpiData.points.ranks[kpiData.points.ranks.length - 1];
            if(rank) rank = rank.value;
            if(p0.myKpis.length == 1){
                badges = userAnalytic.badges.length;
            }else{
                badges = _.filter(userAnalytic.badges, {from: kpi._id}).length;
            }
            return {
                rank, badges
            }
        }
        this.getMTDData = (cycle, kpiData, kpi) => {
            let percentageCompleted, achieved, forecast;
            if (cycle.cycleType == 'weekly' && self.config.showGameplan) {
                percentageCompleted = kpiData.gameplanPercent.value;
                achieved = kpiData.achievedValue.value * $rootScope.weeklyForecastMultiplier / kpi.denom;
                forecast = percentageCompleted * $rootScope.weeklyForecastMultiplier;
            }else{
                percentageCompleted = kpiData.percentageCompleted.value;
                achieved = kpiData.achievedValue.value * ForecastMultiplier.ofCycle(cycle._id) / kpi.denom;
                forecast = cycle.cycleType == 'daily' ? percentageCompleted:percentageCompleted * ForecastMultiplier.ofCycle(cycle._id);
            }       
            return {
                percentageCompleted, achieved, forecast
            }     
        }

        this.setReturnData = (params) => {
            let returnData = {};
            returnData.mtd = self.setReturnDataMTD(params);
            var activityHeading;
            if (params.cycle.cycleType == 'monthly') {
                activityHeading = 'Till Date'
            } else if(params.cycle.cycleType == 'weekly'){
                activityHeading = 'Week Till Date'
            }
            params.activityHeading = activityHeading;
            //Cards
            returnData.cards = self.setReturnDataCard(params);
            //Social
            returnData.social = self.setReturnDataSocial(params);
            //Incentive
            if(params.incentive!=undefined){
                returnData.incentive = self.setReturnDataIncentive(params.incentive);
            }
            return returnData;
        }

        
        this.setReturnDataMTD = ({card1Heading, kpi, cycle, dataHeading, card1Value, subValue, card1Forecast, dotStatus, pastCycle, pipelineValue, VIPTarget, VIPVolume}) => {
            let mtd =  {
                heading1: card1Heading,
                heading2: '('+(kpi.unit?kpi.unit+', ':'')+'% of '+(cycle.cycleType =='weekly' && self.config.showGameplan?'plan.)':' tgt.)'),
                cardType: 'mtd',
                data: [
                    {
                        heading: dataHeading,
                        value: card1Value,
                        subValue : subValue,
                        forecast:  card1Forecast,
                        dot: dotStatus,
                        smallText: cycle.cycleType == 'weekly' ?  kpi.unit :null
                    }
                ]
            };
            if(!pastCycle && self.config.lms){
                mtd.data.push( {
                    heading: 'Pipeline',
                    value: pipelineValue
                });
            }
            return mtd;
        }

        this.setReturnDataIncentive = (incentive) => {
            let incentives = {
                heading1: 'Incentives',
                heading2:'('+p0.strings.INCENTIVE_UNIT.value+')',
                data:[
                    {
                        heading:'Forecast for the Month',
                        value: $filter('number')(Math.min(incentive,p0.config.incentiveCap), 0)

                    }
                ]
            };
            return incentives
        }

        this.setReturnDataSocial = ({kpi, rank, badges, likes, follows, comments, user}) => {
            let social = {
                heading1: kpi._id == 'overall'?'Rank, Badges and Social':'Rank and Badges',
                cardType:'social',
                data: []
            };
            if(user.roleType != 'seniorManager') {
                social.data.push(
                    {
                        heading: 'fa-bar-chart',
                        value: rank
                    },
                    {
                        heading: 'fa-shield',
                        value: badges
                    }
                );
            } 
            if((p0.myKpis.length > 1 && kpi._id == 'overall') || p0.myKpis.length == 1){
                social.data.push(
                    {
                        heading: 'fa-thumbs-o-up',
                        value: likes
                    },
                    {
                        heading: 'fa-users',
                        value: follows
                    },
                    {
                        heading: 'fa-comment-o',
                        value: comments
                    }
                );
            }            
            return social;         
        }
        
        this.setReturnDataCard = ({cardsData, activityHeading, cycle, bankLeagueAnalytic, pastCycle}) => {
            let cards  = [];
            cardsData.forEach(function (cardData) {
                if(cycle.cycleType == 'daily'){
                    activityHeading = cardData.name;
                }
                var isPercentage = cardData.activityType == 'percentage';
                var heading, heading2, smallText;
                if(cardData.fieldName == 'submission'){
                    heading = 'Weekly Rate';
                    heading2 = '(#, per week)';
                    smallText = 'per week'
                }else{
                    heading = 'Daily Rate';
                    heading2 = cycle.cycleType == 'daily' ? '('+cardData.unit+')' : '('+cardData.unit+''+((cardData.ratePeriod && cardData.unit != cardData.ratePeriod)?(', '+cardData.ratePeriod):'')+')';
                    smallText = cardData.ratePeriod ? cardData.ratePeriod : 'per day';
                }

                var card = {
                    heading1: cardData.name,
                    heading2: heading2,
                    cardType: cardData.fieldName,
                    data: [
                        {
                            heading: activityHeading,
                            value: $filter('number')(cardData.activityValue, 0),
                            errCount : $filter('number')(cardData.activityErrCount, 0)
                        }
                    ]
                };
                var bankActivityBenchmark;
                if(cardData.activityAvgRate) bankActivityBenchmark= $filter('number')(cardData.activityAvgRate, 0);
                else bankActivityBenchmark = 'NA';
                if(cycle.cycleType != 'daily' && !isPercentage && self.config.showForecast) {
                    card.data.push({
                        heading: heading,
                        value: $filter('number')(cardData.activityRate, 0),
                        dot: bankLeagueAnalytic?cardData.activityDot:null,
                        popover: '<p>Required for Target : ' + $filter('number')(cardData.activityReqRateTgt, 0) + '</p>' +
                        '<p>Required for Plan : ' + $filter('number')(cardData.activityReqRatePlan, 0) + '</p>' +
                        '<p>Bank Average : ' + bankActivityBenchmark + '</p>'
                    });
                }
                if(!isPercentage && (!cardData.subActivities.length || cardData.showTarget)){
                    card.data.push({
                        heading: 'Req. for Target',
                        value: pastCycle? 'NA' : $filter('number')(cardData.activityReqRateTgt, 0),
                        smallText: smallText
                    });
                }
                if(cardData.subActivities.length){
                    cardData.subActivities.forEach(function(subActivity){
                        var value = $filter('number')(subActivity.activityValue, 0);
                        var leavesCount;
                        if(subActivity.fieldName == 'attendance'){
                            var totalDays = $rootScope.calcBusinessDays(cycle.startDate, $rootScope.lastUpdatedAtDate);
                            var daysPresent = totalDays * value/100;
                            value = $filter('number')(daysPresent, 0)+' / '+ totalDays;
                            leavesCount = $filter('number')((totalDays - daysPresent), 0);
                        }
                        card.data.push({
                            heading: subActivity.name,
                            value: value,
                            errCount : $filter('number')(subActivity.activityErrCount, 0),
                            leavesCount: leavesCount
                        });
                    })
                }
                cards.push(card);
            });
            return cards;
        }

        this.getCard1AndDataHeading = (cycle) => {
            let card1Heading, dataHeading;
            if (cycle.cycleType == 'monthly') {
                card1Heading = 'MTD - '+ p0.strings.ACHIEVEMENT.value;
                dataHeading = self.config.showForecast ? 'Forecast for the Month': 'Till Date';
            } else if (cycle.cycleType == 'weekly') {
                card1Heading = 'WTD - '+ p0.strings.ACHIEVEMENT.value;
                dataHeading = self.config.showForecast ? 'Forecast for the Week' : 'Week Till Date';
            } else {
                card1Heading = "Day's - " + p0.strings.ACHIEVEMENT.value;
                dataHeading = 'Achievement';
            }
            return {
                card1Heading,
                dataHeading
            }      
        }

        this.getCard1Information = (percentageCompleted, pastCycle, achieved, forecast) => {
            let card1Value, card1Forecast, dotStatus, subValue, dataHeading;
            if(!self.config.showForecast){
                card1Value = $filter('number')(percentageCompleted, 0) + '%';
                subValue = {};
                subValue.value = $filter('number')(achieved, 0);
                subValue.info = $filter('number')(achieved / $rootScope.daysPassed, 0) + ' per day';
                card1Forecast = null;
                dotStatus = null;
            }
            else if(pastCycle){
                dataHeading = 'Achieved';
                card1Value = $filter('number')(achieved, 0);
                card1Forecast = null;
                dotStatus = null;
            }else if(achieved){
                card1Value = $filter('number')(achieved, 0);
                card1Forecast = $filter('number')(forecast, 0) + '%';
                dotStatus = (forecast >= 100 ? 'green' : 'red')
            }else{
                card1Value = $filter('number')(forecast, 0) + '%';
                card1Forecast = null;
                dotStatus = (forecast >= 100 ? 'green' : 'red')
            }
            return {
                card1Value, card1Forecast, dotStatus, subValue, dataHeading
            }
        }

        this.setOverallActivityDataHelper = (fieldName, params) => {
            var errCount, activityAvg, showTarget = false;
            let {kpi, user, cycle, yesterdayAnalytics, bankLeagueAnalytic, userAnalytic} = params;
            
            let activityValue = self.setOverallActivityDataAndValue(params, fieldName);
            
            if(bankLeagueAnalytic) {
                activityAvg = _.find(bankLeagueAnalytic.activityData, function (activityData) {
                    return activityData.fieldName == fieldName && !activityData.kpiId;
                }).value;
                if(activityAvg) activityAvg = activityAvg.avg;
            }
            var {subActivities, activityRate, activityAvgRate} = self.getOverallActivityAverageRate(fieldName, params, cycle, activityValue, activityAvg);
            
            if(fieldName == 'accuracyp' || fieldName == 'accuracyp-a'){
                errCount = Accuracy.getErrorCount(fieldName, userAnalytic, null);
            }
            var {activityReqRateTgt, activityReqRatePlan} = self.getOverallActivityReqRateTgtAndPlan(userAnalytic, fieldName, cycle);
            var {activityReqRateTgt, activityReqRatePlan} = self.reduceActivityReqRate(activityReqRateTgt, activityReqRatePlan, fieldName, cycle);
            
            if(fieldName == 'throughput' && cycle.cycleType != 'daily'){
                activityReqRateTgt = self.getActivityReqTgtForThroughput(userAnalytic, cycle);
                showTarget = true;
            }
            let activityDot = self.getActivityDot(activityAvgRate, activityRate, activityReqRateTgt, activityReqRatePlan);
            var activity = _.find(userAnalytic.activityData, function (activityData) {return activityData.fieldName == fieldName && !activityData.kpiId;});
            if(activity)
                var { activityType, unit, ratePeriod, name} = activity;

            return {
                activityValue : activityValue,
                activityErrCount: errCount,
                activityRate : activityRate,
                activityReqRateTgt: activityReqRateTgt,
                activityReqRatePlan: activityReqRatePlan,
                activityAvg: activityAvg,
                activityAvgRate: activityAvgRate,
                activityDot:activityDot,
                name: name,
                fieldName: fieldName,
                activityType:activityType,
                unit: unit,
                ratePeriod : ratePeriod,
                subActivities: subActivities,
                showTarget: showTarget
            }
        }

        this.getOverallActivityReqRateTgtAndPlan = (userAnalytic, fieldName, cycle) => {
            let activityReqRateTgt = 0, activityReqRatePlan = 0; 
            userAnalytic.kpiData.forEach(function (kpiData) {
                var conversionRatio = kpiData.activityConversionRatios[fieldName];
                if(conversionRatio) {
                    activityReqRateTgt += self.calculateOverallActivityReqRateTgt(kpiData, conversionRatio, cycle);
                    activityReqRatePlan += self.calculateOverallActivityReqRatePlan(kpiData, conversionRatio, cycle);
                }
            });
            return {
                activityReqRateTgt,
                activityReqRatePlan
            }
        }

        this.calculateOverallActivityReqRatePlan = (kpiData, conversionRatio, cycle) => {
            var target = kpiData.gameplanTarget.value;
            if(!self.config.showGameplan){
                target = kpiData.target.value;
            }
            return getGPService.getTargetActivitiesForMonthType(kpiData.kpiId, target - kpiData.achievedValue.value, conversionRatio.value, kpiData.averageTicketSize.value);
        }

        this.calculateOverallActivityReqRateTgt = (kpiData, conversionRatio, cycle) => {
            let target;
            if(cycle.cycleType === 'weekly'){
                target = (kpiData.target.value / 4  - kpiData.achievedValue.value)
            }else{
                target = kpiData.target.value - kpiData.achievedValue.value;
            }
            return getGPService.getTargetActivitiesForMonthType(kpiData.kpiId,target, conversionRatio.value, kpiData.averageTicketSize.value);
        } 
        
        this.getOverallActivityAverageRate = (fieldName, params, cycle, activityValue, activityAvg) => {
            let subActivities=[], activityRate=0, activityAvgRate=0;
            if(fieldName == 'throughput'){
                subActivities.push(self.setOverallActivityDataHelper('throughput-a', params));
            }
            else if(fieldName == 'accuracyp'){
                subActivities.push(self.setOverallActivityDataHelper('accuracyp-a', params));
            }
            else if(fieldName == 'occupancy'){
                subActivities.push(self.setOverallActivityDataHelper('attendance', params));
                subActivities.push(self.setOverallActivityDataHelper('availability', params));
            }
            else if (cycle.cycleType == 'monthly') {
                activityRate = activityValue / $rootScope.daysPassed;
                if(activityAvg) activityAvgRate = activityAvg / $rootScope.daysPassed;
            } 
            else if (cycle.cycleType == 'weekly') {
                activityRate = activityValue / $rootScope.weeklyDaysPassed;
                if(activityAvg) activityAvgRate = activityAvg / $rootScope.weeklyDaysPassed;
            }
            return {
                subActivities, 
                activityRate,
                activityAvgRate
            }            
        }

        this.getOverallActivityDataAndValue = (analytics, fieldName) => {
            let activityData = _.find(analytics.activityData, function (activityData) {
                return activityData.fieldName == fieldName && !activityData.kpiId;
            });
            let value = activityData.activityType == 'percentage' ? activityData.value.value * 100 : activityData.value.value;
            return value;
        }
        
        this.setOverallActivityDataAndValue = (params, fieldName) => {
            if (params.cycle.cycleType === 'daily') {
                if(params.yesterdayAnalytics){
                    return self.getOverallActivityDataAndValue(params.yesterdayAnalytics, fieldName);
                }
                else 
                    return 0;
            } else {
                return self.getOverallActivityDataAndValue(params.userAnalytic, fieldName);
            }
        }

        this.getActivityReqTgtForThroughput = (userAnalytic, cycle) => {
            var kpis = _.filter(userAnalytic.kpiData, function(data){
                return data.target.value > 0;
            });
            var sumOfWeights = 0, weight = 0;
            let activityReqRateTgt = 0;
            kpis.forEach(function(kpi){
                weight = 0;
                var currKPI = _.find(p0.myKpis,{_id:kpi.kpiId});
                if(currKPI){
                    weight = _.find(currKPI.kpiWeightage,{role:userAnalytic.role}).weightage;
                    sumOfWeights += weight;
                    activityReqRateTgt += (getGPService.getTargetThroughputRate(cycle.cycleType,kpi, true) * weight);
                }
            });
            activityReqRateTgt /= sumOfWeights;
            return activityReqRateTgt;
        }

        this.setKpiActivityDataHelper = (fieldName, kpiData, params) => {
            var errCount, activityAvg, showTarget = false;

            let {kpi, user, cycle, yesterdayAnalytics, bankLeagueAnalytic, userAnalytic} = params;

            let activityValue = self.setActivityDataAndValue(params, fieldName);
            if(bankLeagueAnalytic) {
                activityAvg = _.find(bankLeagueAnalytic.activityData, { fieldName: fieldName, kpiId: kpi._id }).value;
                if(activityAvg) 
                    activityAvg = activityAvg.avg;
            }

            var {subActivities, activityRate, activityAvgRate} = self.getActivityAverageRate(fieldName, kpiData, params, cycle, activityValue, activityAvg);

            if(fieldName == 'accuracyp' || fieldName == 'accuracyp-a'){
                errCount = Accuracy.getErrorCount(fieldName, userAnalytic, kpi._id);
            }

            activityReqRateTgt = self.getActivityReqRateTgt(kpiData, fieldName, cycle) || 0;
            activityReqRatePlan = self.getActivityReqRatePlan(kpiData, fieldName) || 0;
            var {activityReqRateTgt, activityReqRatePlan} = self.reduceActivityReqRate(activityReqRateTgt, activityReqRatePlan, fieldName, cycle);
            
            if(fieldName == 'throughput' && cycle.cycleType != 'daily'){
                var activityReqRateTgt = getGPService.getTargetThroughputRate(cycle.cycleType,kpiData, true);
                showTarget = true;
            }

            var activity  = _.find(userAnalytic.activityData, {fieldName: fieldName, kpiId: kpi._id});
            if(activity)
                var { activityType, unit, ratePeriod, name} = activity;
            let activityDot = self.getActivityDot(activityAvgRate, activityRate, activityReqRateTgt, activityReqRatePlan);
            
            return {
                activityValue : activityValue,
                activityErrCount: errCount,
                activityRate : activityRate,
                activityReqRateTgt: activityReqRateTgt,
                activityReqRatePlan: activityReqRatePlan,
                activityAvg: activityAvg,
                activityAvgRate: activityAvgRate,
                activityDot:activityDot,
                name: name,
                fieldName: fieldName,
                activityType: activityType,
                unit:unit,
                ratePeriod:ratePeriod,
                subActivities: subActivities,
                showTarget:showTarget
            }
        }


        this.getActivityDataAndValue = (analytics, kpi, fieldName) => {
            let activityData = _.find(analytics.activityData, {fieldName: fieldName, kpiId: kpi._id});
            let value = activityData.activityData == 'percentage' ? activityData.value.value * 100 : activityData.value.value;
            return value;
        }
        
        this.setActivityDataAndValue = (params, fieldName) => {
            if (params.cycle.cycleType === 'daily') {
                if(params.yesterdayAnalytics){
                    return self.getActivityDataAndValue(params.yesterdayAnalytics, params.kpi, fieldName);
                }
                else 
                    return 0;
            } else {
                return self.getActivityDataAndValue(params.userAnalytic, params.kpi, fieldName);
            }
        }

        this.getActivityReqRatePlan = (kpiData, fieldName) => {
            let activityRatio = kpiData.activityConversionRatios[fieldName];
            if(activityRatio){
                var target = kpiData.gameplanTarget.value;
                if(!self.config.showGameplan){
                    target = kpiData.target.value;
                }
                return getGPService.getTargetActivitiesForMonthType(kpiData.kpiId, target - kpiData.achievedValue.value, activityRatio.value, kpiData.averageTicketSize.value);
            }
        }

        this.getActivityReqRateTgt = (kpiData, fieldName, cycle) => {
            let activityRatio = kpiData.activityConversionRatios[fieldName];
            if(activityRatio){
                var target;
                if(cycle.cycleType === 'weekly'){
                    target = (kpiData.target.value / 4  - kpiData.achievedValue.value)
                }else{
                    target = kpiData.target.value - kpiData.achievedValue.value;
                }
                return getGPService.getTargetActivitiesForMonthType(kpiData.kpiId,target, activityRatio.value, kpiData.averageTicketSize.value);
            }
        }

        this.reduceActivityReqRate = (activityReqRateTgt, activityReqRatePlan, fieldName, cycle) => {
            if(fieldName != 'submission'){
                if (cycle.cycleType == 'weekly') {
                    activityReqRateTgt = activityReqRateTgt / 5;
                    activityReqRatePlan = activityReqRatePlan / 5;
                } else {
                    activityReqRateTgt = activityReqRateTgt / 20;
                    activityReqRatePlan = activityReqRatePlan / 20;
                }
            }else{
                if (cycle.cycleType == 'monthly' || cycle.cycleType == 'daily') {
                    activityReqRateTgt = activityReqRateTgt / 4;
                    activityReqRatePlan = activityReqRatePlan / 4;
                }
            }
            return {
                activityReqRateTgt,
                activityReqRatePlan
            }        
        }

        this.getActivityDot = (activityAvgRate, activityRate, activityReqRateTgt, activityReqRatePlan) => {
            if (activityAvgRate && activityAvgRate <= activityRate && activityRate >= activityReqRateTgt && activityRate >= activityReqRatePlan) {
                return 'green';
            }else if (activityRate >= activityReqRateTgt && activityRate >= activityReqRatePlan) {
                return 'green';
            } else {
                return 'red';
            }
        } 

        this.getActivityAverageRate = (fieldName, kpiData, params, cycle, activityValue, activityAvg) => {
            let subActivities=[], activityRate=0, activityAvgRate=0;
            if(fieldName == 'throughput'){
                subActivities.push(self.setKpiActivityDataHelper('throughput-a', kpiData, params));
            }
            else if(fieldName == 'accuracyp'){
                subActivities.push(self.setKpiActivityDataHelper('accuracyp-a', kpiData, params));
            }
            else if (cycle.cycleType == 'monthly') {
                activityRate = activityValue / $rootScope.daysPassed;
                if(activityAvg) activityAvgRate = activityAvg / $rootScope.daysPassed;
            }else if (cycle.cycleType == 'weekly') {
                activityRate = activityValue / $rootScope.weeklyDaysPassed;
                if(activityAvg) activityAvgRate = activityAvg / $rootScope.weeklyDaysPassed;
            }
            return {
                subActivities, 
                activityRate,
                activityAvgRate
            }            
        }

        this.isPastCycle = (cycle) => {
            if($rootScope.lastUpdatedAtDate.getTime() >= new Date(cycle.startDate).getTime()  && $rootScope.lastUpdatedAtDate.getTime()  < new Date(cycle.endDate).getTime() ){
                return false;
            }else if($rootScope.lastUpdatedAtDate.getTime()  > new Date(cycle.startDate).getTime() ){
                return true;
            }else return false;
        }

    });

