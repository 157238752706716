const autoImportNgTemplateLoaderTemplate1 = require('../html/leagueTable.html');

'use strict';

angular.module('bitnudgeApp')
    .directive('leagueTable', function () {
        return {
            name: 'leagueTable',
            scope: {
                playerType:     '=',        // 'managers' or 'frontline'
                parentCtrlObj:  '='
            },
            controller: 'leagueTableController',
            restrict: 'EA',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
