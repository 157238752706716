const autoImportNgTemplateLoaderTemplate1 = require('../html/gradesIcon.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('gradesIcon', function () {
        return {
            name: 'gradeIcon',
            scope: {
                zone: '@',
                isBigger: '@'
            },
            controller: 'gradesIconCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
