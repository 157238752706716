'use strict';
angular.module('bitnudgeApp')
    .controller('createPlayerGroupModalController', function ($scope, Auth, $http, toaster,  refreshChat, selectedGroup, onModalClose, SocketService, Upload, users, $mdDialog, bottomPadding, $rootScope) {
        let self = this;
        this.socket;
        
        this.init = function(){
            $scope.idsAdded = [];
            $scope.idsRemoved = [];
            $scope.strings = $rootScope.strings;
            $scope.bottomPadding = bottomPadding;
            $scope.groupName = selectedGroup.name;
            var selectedPlayers = [];
            $scope.selectedUsers = [];
            $scope.playerEmptyText = {buttonDefaultText: 'Select Players'};
            $scope.playerMultiSelectSettings = {
                smartButtonMaxItems: 10,
                buttonClasses: 'srManagerButton',
                displayProp: 'name',
                idProp: '_id',
                enableSearch: true,
                scrollableHeight: '500px',
                scrollable: true
            };
            self.currentUser = Auth.getCurrentUser();
            if (selectedGroup == '')
                $scope.isGroup = false;
            else
                $scope.isGroup = true;

            if (selectedGroup.users) {
                _.forEach(selectedGroup.users, function (obj) {
                    if(obj.userId)
                        $scope.selectedUsers.push({id: obj.userId._id});
                });
            }
            $scope.roleDropdown = [
                {
                    _id:'seniorManager',
                    name:'Senior '+ $rootScope.strings.MANAGER.value
                },{
                    _id:'manager', 
                    name: $rootScope.strings.MANAGER.value
                },{
                    _id:'frontline', 
                    name: $rootScope.strings.FRONTLINE.value
                }
            ];
            $scope.selectedRoleType = 'frontline';
            $scope.roleChanged();

            var cloned = _.cloneDeep($scope.selectedUsers);
            var otherUsers = _.pullAllBy(cloned, [{'id': self.currentUser._id}], 'id');
            $scope.members = otherUsers.map(function (obj) {
                return users.find(function (e) {
                    return e.id == obj.id;
                });
            });
            $scope.nonMembers= self.diffOfArrays($scope.users, $scope.selectedUsers);
            if($scope.isGroup) $scope.groupImage = selectedGroup._image;
            else $scope.groupImage = require('../images/group.jpg');

            return SocketService.getSocket().then(function(socket){
                self.socket = socket;
            });
        }
        
        this.diffOfArrays = function (result1, result2) {
            var uniqueResult = result1.filter(function (obj) {
                return !result2.some(function (obj2) {
                    return obj.id == obj2.id;
                });
            });
            return uniqueResult;
        };
        
        $scope.roleChanged = function () {
            $scope.users = _.filter(users, {systemRole:$scope.selectedRoleType});
            $scope.nonMembers= self.diffOfArrays($scope.users, $scope.selectedUsers);
        }
        
        this.addGroup = function () {
            var selectedUsersArray = [];
            _.forEach($scope.idsAdded, function (id) {
                selectedUsersArray.push({userId: id})
            });

            var groupObj = {
                name: $scope.groupName, 
                owner: self.currentUser._id, 
                ownerType: self.currentUser.systemRole, 
                users: selectedUsersArray, 
                time: new Date(), 
                isGroup: true 
            };
            if ($scope.groupName) {
                return $http.post('/api/chatGroups', groupObj)
                .then(function () {
                    toaster.pop('success', "Group Creation", "New Group Added.");
                    $mdDialog.hide();
                    refreshChat(true);
                    self.socket.emit('created:group',$scope.selectedUsers);
                }).catch(function () {
                    console.log("Error")
                })
            }
            else{
                return Promise.resolve().then(() => {
                    toaster.pop('error', "Group Creation", "Please provide a group name.");
                });
            }
                
        };

        this.editGroup = function () {
            var usersRemoved = $scope.idsRemoved.map(function (e) {
                return {id: e};
            });
            var usersAdded = $scope.idsAdded.map(function (e) {
                return {id: e};
            });
            var selectedUsersArray = [];

            let updateObj = {
                groupName:$scope.groupName,
                groupId:selectedGroup._id,
                usersAdded: usersAdded,
                usersRemoved:usersRemoved,
            }
            _.forEach(_.union (self.diffOfArrays($scope.selectedUsers, usersRemoved), $scope.idsAdded), function (obj) {
                selectedUsersArray.push({userId: obj.id});
            });
            if ($scope.groupName != selectedGroup.name)
                updateObj.name = $scope.groupName;

            if (usersRemoved.length > 0 || usersAdded.length > 0)
                updateObj.users = selectedUsersArray;

            if (updateObj.name || updateObj.users) {
                return $http.post('/api/chatGroups/updateGroup/', updateObj)
                .then(function () {
                    refreshChat(true);
                    var updatedUsers = usersAdded.concat(usersRemoved);
                    self.socket.emit('edited:group',updatedUsers);
                    $mdDialog.hide();
                });
            }
            else
                return Promise.resolve().then(() => {
                    toaster.pop('error', "Group Updation", "Please update any field.");
                });
        };

        $scope.uploadGroupImage = function ($files) {
            //$files: an array of files selected, each file has name, size, and type.
            for (var i = 0; i < $files.length; i++) {
                var $file = $files[i];
                var fileName = $files[i].name.split(".");
                var fileType = fileName[fileName.length - 1].toLowerCase();
                if(fileType == 'jpg' || fileType == 'jpeg' || fileType == 'png') {

                    Upload.upload({
                        url: '/api/chatGroups/picture/addImage',
                        data: {groupId: selectedGroup._id},
                        file: $file,
                    }).progress(function (evt) {
                        var progresspercentage = parseInt(100.0 * evt.loaded / evt.total);
                        // console.log('progress: ' + progresspercentage + '% ' + evt.config.file.name);
                    }).then(function (response) {
                        // console.log('file ' + config.file.name + ' uploaded. response: ' + data);
                        var data = response.data;
                        var status = response.status;
                        var statusText = response.statusText;
                        var headers = response.headers;
                        var config = response.config;
                        $scope.groupImage = data.image;
                    }).catch(function (data, status, headers, config) {
                        // console.log('error status: ' + status);
                    })
                } else {
                    toaster.pop('error', "Submission.", "Upload '.png', '.jpg' Types Only.");
                }

            }
        };

        $scope.submitGroup = function () {
            if (!$scope.isGroup)
                self.addGroup();
            else
                self.editGroup();
        };
        $scope.onClose = function() {
            onModalClose($scope.groupImage);
            $mdDialog.cancel();
        };
        this.init();
    });
