'use strict';
angular.module('bitnudgeApp')
    .controller('pipelineModalCtrl', function (p0, $scope, $rootScope, $state, $mdDialog, $q, $http, data, PipelineService) {

        let self = this;
        $scope.unit = p0.config.CURRENCY_UNIT || "IDR";
        this.init = function(){
            angular.extend($scope, data);
            $scope.reporteeData = {};
            $scope.reporteeData[$scope.user._id] = $scope.pipelineData;
            $scope.selectedUser = $scope.user;
            var jobRole = _.find(p0.allJobRoles, { _id : $scope.selectedUser.organizationalTag.jobRole._id });
            var curKpi = _.find(p0.allKpis, {_id : $scope.productId});
            $scope.submissionStates = curKpi ? curKpi.submissionStates : [];
            $scope.product = _.find(p0.allProducts, {_id:$scope.productId});
            $scope.userChildrenArray = {};
            $scope.trailList = [];

            $scope.onReporteeDrillDownClick($scope.selectedUser._id);
        }
        
        $scope.onClose = function () {
            $mdDialog.cancel();
        }

        $scope.onReporteeDrillDownClick = function(userId){
            if($scope.userChildrenArray[userId]){
                self.setSelectedUser(userId);
                self.mapReporteeData($scope.reporteeData);
                return;
            }
            var response = $http.post('/api/users/me/children', {userId});
            return response.then(function(reportees){
                if(!reportees) return;
                reportees=reportees.data;
                if(reportees.length < 1){
                    self.mapReporteeData($scope.reporteeData);
                    return;
                }
                reportees = _.filter(reportees, function(reportee){
                    return _.includes(reportee.kpis, $scope.product.mainKpi);
                });
                $scope.userChildrenArray[userId] = reportees;

                if(reportees.length < 1){
                    return;
                }
                self.setSelectedUser(userId);
                var reporteeIds = _.map(reportees, '_id');
                var reporteePipelineData = [];
                var cycleId = p0.currentMonthlyCycle._id;
                _.each(reporteeIds, function(reporteeId){
                    reporteePipelineData.push( PipelineService.getPipelineScorecardData(reporteeId,cycleId));
                });
                return $q.all(reporteePipelineData)
                .then(function(pipelineData){
                    _.each(reporteeIds, function(reporteeId, index){
                        $scope.reporteeData[reporteeId] = pipelineData[index];
                    });
                    self.mapReporteeData($scope.reporteeData);
                })
            }).catch(function(error){
                self.handleError(error);
            })
        }

        this.handleError = (error) => {
            console.log("Error receiving reportee of user", error);
        }

        $scope.exportExcel = function(){
            var pipelineData = [];
            var headers = ["User", "Overall Pipeline Value"].concat($scope.submissionStates);
            pipelineData.push(headers);
            _.each($scope.reporteePipelineData, function(reporteeData){
                var user = [];
                user.push(reporteeData.reportee.name);
                user.push(reporteeData.totalPipelineValue.toFixed(1) + " " + $scope.unit);
                _.each(reporteeData.states, function(state){
                    var string = state.pipelineValue.value.toFixed(1) + " " + $scope.unit + " (" +  state.leadCount.value + "#, " + state.conversionRatioFinal.value + "%)";
                    user.push(string);
                });
                pipelineData.push(user);
            });
            var fileName = "Pipeline_" + $scope.reporteePipelineData[0].reportee.name  + "_" + moment(new Date().getTime()).format('YYYY_MM_DD') + ".xlsx";
            PipelineService.exportToExcel(pipelineData, fileName);
        }

        this.mapUser = function ( user, userReporteeData, submissionStates){
            var jobRoleId = user.organizationalTag.jobRole._id ? user.organizationalTag.jobRole._id : user.organizationalTag.jobRole;
            var jobRole = _.find(p0.allJobRoles, { _id : jobRoleId });
            return {
                reportee : user,
                showDrillDownView : (user.roleType && user.roleType !== 'frontline') || (user.systemRole && user.systemRole !== 'frontline'),
                totalPipelineValue : (userReporteeData && userReporteeData.totalPipelineValue) || 0,
                letRedirectionToLeads : jobRole.level < 3,
                
                states : _.map(submissionStates, function(state){
                    var stateInReportee = _.find(userReporteeData.stages, {stage : state});
                    return stateInReportee ? stateInReportee : {
                        stage : state,
                        leadCount : { value : 0 },
                        conversionRatioFinal : { value : 0 },
                        pipelineValue : { value : 0 }
                    }
                })
            }
        }

        this.mapReporteeData = function(reporteeData){
            if(!reporteeData || !reporteeData[$scope.selectedUser._id])
                return;
            var productId = $scope.productId;
            $scope.reporteePipelineData = [];
            var userReporteeData = self.mapUser( $scope.selectedUser, reporteeData[$scope.selectedUser._id][productId], $scope.submissionStates);
            $scope.unit = reporteeData[$scope.selectedUser._id][productId].unit ? reporteeData[$scope.selectedUser._id][productId].unit : $scope.unit;
            $scope.reporteePipelineData.push(userReporteeData);
            _.each($scope.userChildrenArray[$scope.selectedUser._id], function(reportee){
                userReporteeData = self.mapUser(reportee, reporteeData[reportee._id][productId], $scope.submissionStates );
                $scope.reporteePipelineData.push(userReporteeData);
            });
        }


        $scope.redirectToPipelineView = function(userId){
            $scope.onClose();
            $state.go('userFlow.pipeline', {
                userId : userId,
                productId : $scope.productId,
            });
        }

        $scope.redirectToLeads = function(userId){
            $scope.onClose();
            $state.go('lms.listLead', {
                userId : userId,
                productId : $scope.productId
            });
        }

        $scope.onBreadCrumbClick = function(user){
            var index = _.findIndex ( $scope.trailList, {_id : user._id});
            $scope.trailList.length = index + 1;
            $scope.selectedUser = user;
            self.mapReporteeData($scope.reporteeData);
        }

        this.setSelectedUser = function( userId){
            $scope.selectedUser = userId === $scope.user._id ? $scope.user : _.find( $scope.userChildrenArray[$scope.selectedUser._id], {_id : userId});
            var index = _.findIndex ( $scope.trailList, {_id : userId});
            if(index === -1){
                $scope.trailList.push( $scope.selectedUser);
            }
        }

        this.init();
    });