const autoImportNgTemplateLoaderTemplate1 = require('../html/addPrerequisite.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('workflowLayoutRendererCtrl', function ($scope, $mdDialog){

    	const initLoop = function(){
    		const addPrereq = $scope.items.filter(i => i.type === 'addPrerequisite');
			$scope.items = [ ...$scope.items.filter(i => i.type !== 'addPrerequisite'), ...addPrereq ];
			$scope.loop = Array.from({length: Math.ceil($scope.items.length)}, function(v, k) {return k});
		};

        var init = function () {
            if(!$scope.params){
                $scope.params = {};
            }

            if($scope.noOfColumn){
                $scope.noOfColumn = parseInt($scope.noOfColumn);
            }else{
                $scope.noOfColumn = 2;
            }
			initLoop();
        };

		/*const registerForEvents = function(){
			$scope.$on('workflow:prerequisiteUpdate', function(event, opt) {
				initLoop();
			});
		};*/

		$scope.requestAdditional = () => {
			$mdDialog.show({
				templateUrl: autoImportNgTemplateLoaderTemplate1,
				controller: "addPrerequisite",
				clickOutsideToClose: true,
				preserveScope: true,
				autoWrap: true,
				resolve: {
					context: function () { return $scope.context; },
				}
			})
			.then(item => {
				$scope.$emit(`workflow:${$scope.context}:addPrerequisite`, item)
			});
		};

        /*$scope.onFileUpload = function (file, item) {
            var upload = Upload.upload({
                url: item.url,
                data: {file: file},
            });
            upload.then(function (response) {
                $timeout(function () {
                    file.result = response.data;
                });
            }, function (response) {
                if (response.status > 0) {
                    item.errorMsg = response.status + ': ' + response.data;
                }
            }, function (evt) {
                file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
            });
        }*/
        init();
    });
