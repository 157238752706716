const autoImportNgTemplateLoaderTemplate1 = require('../html/chatPage/chatPage.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('chatPage', function(){
        return {
            name: 'chatPage',
            scope: {
                totalUnreadMessages: '='
            },
            controller: 'chatPageController',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
