'use strict';

angular.module('bitnudgeApp')
    .controller('addLevelCtrl', function ($stateParams, $scope,$location, Auth, $http, toaster, User) {
        $scope.getCurrentUser = Auth.getCurrentUser;
        var url = '/api/levels/show';
        $scope.level = {};
        $scope.editForm = false;
        if($stateParams.id){
            let postBody = {
                id: $stateParams.id
            }
            $scope.editForm = true;
            $http.post(url, postBody).then(function(levels) {
                levels=levels.data;
                $scope.level = levels;
            });
        }
        $scope.user = Auth.getCurrentUser();
        $scope.addLevel = function(){
            $scope.level.createdAt = new Date();
            $http.post("/api/levels/levelForm",$scope.level)
                .then(function(){
                    $location.path('/level/listLevel');
                    toaster.pop('success' , "Level Creation", "New Level Added")

                })
                .catch(function(data,status,header,config){})
        }
    });
