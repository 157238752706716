'use strict';

angular.module('bitnudgeApp')
.controller('InstructionsCtrl', function ($scope, Auth, $location, $state,$http) {

$scope.step_continue = function(current,me){
    var cont = $(".track-loc");
    cont.find(current).prevAll().addClass("completed");
    cont.find(current).addClass("active");
    $(".track-info").find(".step-tab").hide();
    $(me.target).closest(".step-tab").next().fadeIn();
};

$scope.step_finish = function(current,me){
    $http.post('/api/users/setInstructionsRead').then(function(){
        var currentUser = Auth.getCurrentUser();
        if(currentUser.cloneUser){
            currentUser.cloneUser.instructionsRead = true;
        }else{
            currentUser.instructionsRead = true;
        }

        $state.go('user.step1');
    }).catch(function(err) {
        toaster.pop('error', "Next", "Can't proceed now. Please try after sometime.");
    });
    // var cont = $(".track-loc");
    // cont.find(current).addClass("completed");
};
});

