'use strict';
angular.module('bitnudgeApp')
    .controller('gpNotMadeModalCtrl', function ($scope,  $mdDialog, KPIsForWeek, KPIsForMonth, weekName, simulation, p0) {
        $scope.KPIsForWeek = KPIsForWeek;
        $scope.KPIsForMonth = KPIsForMonth;
        $scope.weekName = weekName;
        $scope.monthName = p0.currentMonthlyCycle.name;
        $scope.hideWeeklyGamePlan = p0.config.hideWeeklyGamePlan
        if(!simulation.status){
            $scope.gpSimulation = simulation.gpSimulation;
        }
        $scope.cancel = function() {
            $mdDialog.hide();
        };
    });
