const autoImportNgTemplateLoaderTemplate1 = require('../html/rhsAnalyticsTab.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('rhsAnalyticsTab', function(){
        return {
            name: 'rhsAnalyticsTab',
            scope: {
            },
            controller: 'rhsAnalyticsTabCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });

