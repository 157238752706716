const autoImportNgTemplateLoaderTemplate1 = require('../html/nudgeWall.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('nudgeWall', function () {
        return {
            name: 'nudgeWall',
            scope: {
                playerRole:     '=',
                playerList:     '=',
                kpis:           '='
            },
            controller: 'nudgeWallCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
