'use strict';
angular.module('bitnudgeApp')
    .service('TrendGraph', function ($http ,Auth, p0, moment, ForecastMultiplier, $rootScope, $q) {
        var _this = this;
        this.benchMarks = {};
        var getActivityId = function (parameters) {
            var activityId;
            if(parameters.metric.metric == 'kai'){
                activityId = _.find(p0.allActivities, {fieldName: parameters.metric.id})._id;
            }else{
                activityId = 'overall';
            }
            return activityId;
        };
        this.fetchMetricTrend = function (userId, kpiId, parameters) {
            const postData = {
                userId,
                startDate: parameters.timeRange.startDate,
                endDate: parameters.timeRange.endDate,
                activityId: getActivityId(parameters),
                kpiId,
                metric: parameters.metric.metric,
                cycleType: parameters.cycleType.id
            }
            return $http.post('/api/customReports/trend', postData).then(function (outputData) {
                outputData=outputData.data;
                //Don't include active cycles in trend report
                if(parameters.cycleType.id == 'weekly'){
                    outputData = _.filter(outputData, {active:false});
                }
                outputData.forEach(function (cycleData) {
                    if(cycleData.active){
                        cycleData.perIFE = cycleData.perIFE * ForecastMultiplier.ofCycle(cycleData.cycleId);
                        cycleData.value = cycleData.value * ForecastMultiplier.ofCycle(cycleData.cycleId);
                    }
                    if('perIFE' in cycleData){
                        var days = $rootScope.calcBusinessDays(cycleData.startDate, cycleData.endDate);
                        cycleData.perIFEPerDay = (cycleData.perIFE / days).toFixed(1);
                        var startWeekIndex = moment(new Date(cycleData.startDate)).format('w');
                        var endWeekIndex = moment(new Date(cycleData.endDate)).format('w');
                        if(endWeekIndex - startWeekIndex) cycleData.perIFEPerWeek = (cycleData.perIFE / (endWeekIndex - startWeekIndex)).toFixed(1);
                    }
                });
                return outputData;
            });
        };
        var formatSeriesBenchmarks = function (parameters, benchmarkData, benchMarkMetric) {
            //Don't include active cycles in trend report
            if(parameters.cycleType.id == 'weekly'){
                benchmarkData = _.filter(benchmarkData, {active:false});
            }
            benchmarkData.forEach(function (cycleData){
                var benchmarkValue = cycleData[benchMarkMetric.teamType] && cycleData[benchMarkMetric.teamType][benchMarkMetric.metric];
                if(!benchmarkValue) benchmarkValue = 0;
                if(cycleData.active){
                    benchmarkValue = benchmarkValue * ForecastMultiplier.ofCycle(cycleData.cycleId);
                }
                cycleData[benchMarkMetric.metric+'value'] = benchmarkValue;
                cycleData[benchMarkMetric.metric+'perIFE'] = benchmarkValue.toFixed(1);
                var days = $rootScope.calcBusinessDays(cycleData.startDate, cycleData.endDate);
                var startWeekIndex = moment(new Date(cycleData.startDate)).format('w');
                var endWeekIndex = moment(new Date(cycleData.endDate)).format('w');
                if(days) cycleData[benchMarkMetric.metric+'perIFEPerDay'] = (benchmarkValue / days).toFixed(1);
                if(endWeekIndex - startWeekIndex) cycleData[benchMarkMetric.metric+'perIFEPerWeek']  =  (benchmarkValue / (endWeekIndex - startWeekIndex)).toFixed(1);
            });
            return benchmarkData;
        };

        this.fetchSeriesBenchmarks = function (parameters, seriesBenchmark, metric) {
            if(seriesBenchmark.id == 'benchmark'){
                var activityId = getActivityId(parameters);
                if(_this.benchMarks[parameters.userId+activityId]){
                    return $q.resolve(formatSeriesBenchmarks(parameters, _this.benchMarks[parameters.userId+activityId], metric));
                }else{
                        const postData = {
                            userId: parameters.userId,
                            startDate: parameters.timeRange.startDate,
                            endDate: parameters.timeRange.endDate,
                            kpiId: parameters.kpi._id,
                            activityId,
                            metric: parameters.metric.metric,
                            cycleType: parameters.cycleType.id
                        }
                        return $http.post('/api/customReports/trendBenchmark', postData).then(function (outputData) {
                        outputData=outputData.data;
                        _this.benchMarks[parameters.userId+activityId] = outputData;
                        return formatSeriesBenchmarks(parameters, _this.benchMarks[parameters.userId+activityId], metric);
                    });
                }
            }else if(seriesBenchmark.id == 'peer'){
                var userId = metric._id;
                return this.fetchMetricTrend(userId, parameters.kpi._id, parameters, function (outputData) {
                    return outputData;
                });
            }else{
                var kpiId = metric._id;
                return this.fetchMetricTrend(parameters.userId, kpiId, parameters, function (outputData) {
                    return outputData;
                });
            }
        };
        this.destroy = function(){
            _this.benchMarks = {};
        };
    });
