'use strict';
angular.module('bitnudgeApp')
    .directive('cneKpiNetworkTab', function(){
        return {
            name: 'cneKpiNetworkTab',
            scope: {
                'user': '@',
                'type': '@',
                'kpi': '@',
                'kpiName': '@',
                'isMonthly': '=',
                'teamType': '=',
                'hideReportees': '=',
                'tabType': '@',
                'jobRole': '='
            },
            controller: 'cneKpiNetworkTabCtrl',
            restrict: 'AE',
            templateUrl: function(elem, attr) {
                if (attr.tabType == 'huddleBoard') {
                    return '../html/cneKpiNetworkTab/cneKpiNetworkTab-huddleBoard.html';
                }
                else {
                    return '../html/cneKpiNetworkTab/cneKpiNetworkTab.html';
                }
            }
        };
    });
