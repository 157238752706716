'use strict';

'use strict';
angular.module('bitnudgeApp')
    .service('mgrDashboardConfigService', ['$http', '$q', function ($http, $q) {
        var self = this;

        this.getConfig = function (cycleId, roleType, userId) {
            var deferred = $q.defer();
            let url = `/api/managerDashboard/config`;
            let params = {};
            params.cycleId = cycleId;
            params.roleType = roleType;
            if(userId) {
                params.userId = userId;
            }
            $http.post(url,params).then(function (result) {
                console.log('result: ', result);
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        this.getConfiguredCycles = function (roleType) {
            var deferred = $q.defer();
            $http.post(`/api/managerDashboard/config/cycles`,{roleType})
                .success(function (leads) {
                    deferred.resolve(leads);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

    }]);

