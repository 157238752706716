'use strict';
angular.module('bitnudgeApp')
    .controller('transactionTableCtrl', function ($scope, transactionService) {
        $scope.onHeaderClick = (sortKey) => {
            $scope.sortingObj.sortKey = sortKey;
            if ($scope.sortingObj.sortOrder === 'asc') {
                $scope.sortingObj.sortOrder = 'desc';
            } else {
                $scope.sortingObj.sortOrder = 'asc';
            }
            $scope.pagination = transactionService.getPaginationObj();
            $scope.fetchTransactions(null, $scope.pagination);
        }

    });