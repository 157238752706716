const autoImportNgTemplateLoaderTemplate1 = require('../html/customers/documentsView.html');

"use strict";
angular.module("bitnudgeApp").component("documentsView", {
	bindings: {
		documents: "<",
		removeDocument: "&",
		editDocument: "&",
		callUms: "&",
		customer: "<",
	},
	templateUrl: autoImportNgTemplateLoaderTemplate1,
	controller: function($scope, CustomerService, FileSaver, Blob, $mdDialog, $rootScope, DocumentService, toaster) {
		this.$onChanges = changes => {
			if (changes.documents) {
				$scope.documents = changes.documents.currentValue;
				if($scope.documents){
					$scope.slickReload = false
					arrayBufferConversion();
				}
			}
		};

		arrayBufferConversion =()=>{
			$scope.documents.forEach( doc => {
				if(doc.uploadedFileIds.length){
					doc.uploadedFileIds.forEach( file => {
						if(file.thumbnail && file.thumbnail.content.data){
							file.thumbnail.content = btoa(String.fromCharCode(...new Uint8Array(file.thumbnail.content.data)))
						}
					})
				}
				$scope.slickReload = true
			})
		}
		
		$scope.slickConfig = {
			enabled: true,
			infinite: false,
			autoplay: false,
			draggable: false,
			slidesToShow: 1,
			centerMode: false,
			slidesToScroll: 1
		};

		$scope.openDocument = function(docId, type, name) {
			CustomerService.getDocument(docId).then(function(content) {
				// TODO: move excel download to server side
				FileSaver.saveAs(new Blob([content], { type: "application/octet-stream" }), name);
			});
		};

		$scope.openDMS = function(document) {
			if(document.doc._id){
				const dmsParameters = {
					documents:[document.doc._id],
					selectedDocumentId: document.doc._id,
					isCustomerMode: true, //for customer mode, open DMS with edit option without selection or approve feature
					isApproveMode: false, //for approve mode, open DMS with approve and reject option
					isSelectMode: false, //for selection mode, open DMS with lead or ticket context i.e workflow
					cui: document.doc.cui,
					context: 'customer'
				};

				//for notification mode where user only have option to read it.
				// const dmsParameters = {
				// 	documents:[document.doc._id],
				// 	selectedDocumentId: document.doc._id,
				// 	isCustomerMode: false, //for customer mode, open DMS with edit option without selection or approve feature
				// 	isViewMode: true,
				// 	isApproveMode: false, //for approve mode, open DMS with approve and reject option
				// 	isSelectMode: false, //for selection mode, open DMS with lead or ticket context i.e workflow
				// 	cui: document.doc.cui,
				// 	context: 'viewOnly'
				// };

				//for notification mode where documentTypes(uid) is required with workflowId in viewOnly context
				// const dmsParameters = {
				// 	documents:[document.doc._id],
				// 	selectedDocumentId: document.doc._id,
				// 	documentTypes:["document_d01"],
				// 	workflowId:"5cac9b1836d6da3a4a704e4e",
				// 	isCustomerMode: false, //for customer mode, open DMS with edit option without selection or approve feature
				// 	isViewMode: true,
				// 	isApproveMode: false, //for approve mode, open DMS with approve and reject option
				// 	isSelectMode: false, //for selection mode, open DMS with lead or ticket context i.e workflow
				// 	cui: document.doc.cui,
				// 	context: 'viewOnly'
				// };

				$rootScope.$broadcast('dms:openModal', dmsParameters);
			}else{
				toaster.pop('error','DMS modal','Selected Document Id is required')
			}
			
		};
	}
});
