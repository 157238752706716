const autoImportNgTemplateLoaderTemplate1 = require('../html/moversAndShakers.html');

'use strict';

angular.module('bitnudgeApp')
    .directive('moversAndShakers', function () {
        return {
            name: 'moversAndShakers',
            scope: {
                playerType:     '=',        // 'managers' or 'frontline'
                parentCtrlObj:  '='
            },
            controller: 'moversAndShakersController',
            restrict: 'EA',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
