const autoImportNgTemplateLoaderTemplate1 = require('../html/customerMgrDashboard.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('customerOverview', function(){
        return {
            name: 'customerOverview',
            scope: {
                customerOverviewData: '=',
                configData: '=',
                dynamicParams: '=',
                changeProductCustomer: '&',
                changeCustomerSource: '&',
                changeCustomerType: '&',
                changeCustomerDataType: '&'
            },
            controller: 'custOverviewCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
