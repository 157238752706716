angular.module('bitnudgeApp')
    .controller('addBulkMTDActivityController', function($http, $scope, Auth, Upload, $location, toaster) {
        $scope.fileType = "activities";
        $scope.toggleMin = function() {
            $scope.minDate = $scope.minDate ? null : new Date();
        };
        $scope.toggleMin();

        //opening a datepicker dialog box
        $scope.open = function($event) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened = true;
        };

        //Configration for Date picker.
        $scope.dateOptions = {
            formatYear: 'yy',
            modelDateFormat: "yyyy-MM-ddTHH:mm:ss",
            startingDay: 1
        };

        $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];

        // On submit function submit the excel and date to server's controller
        $scope.onFileSelectIndi = function() {
            // alert();

            $scope.asOfDate = $scope.asOfDate.getTime();
            //$files: an array of files selected, each file has name, size, and type.
            for (var i = 0; i < $scope.files.length; i++) {
                var $file = $scope.files[i];
                var fileName = $scope.files[i].name.split(".");
                var fileType = fileName[fileName.length - 1];
                if (fileType == "xls" || fileType == "xlsx" || fileType == "xlsm" || fileType == "xlsb") {
                    Upload.upload({
                        url: '/api/activities/importMTD/excel',
                        data: {
                            asOfDate: $scope.asOfDate
                        },
                        file: $file,
                        progress: function(e) {}
                    }).then(function(data, status, headers, config) {
                        // file is uploaded successfully
                        console.log(data);
                        //$location.path('/lms/listIndividualTarget');
                        toaster.pop('success', "Bulk Activity MTD Creation.", "Bulk Individual MTD Creation.");
                    });
                } else {
                    toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' Types Only.");
                }
            }
        }
    });
