const autoImportNgTemplateLoaderTemplate1 = require('../html/customers/customerProducts.html');

'use strict';
angular.module('bitnudgeApp')
	.component('customerProducts', {
		name: 'customerProducts',
		bindings: {
			products: '<',
			customer: '<'
		},
		templateUrl: autoImportNgTemplateLoaderTemplate1,
		controller: function ($scope, $rootScope, $filter, $http, $state, p0) {
			
			/**
			 * TODO: As per the sprint owner's interest
			 * Making this configuration and getting it from product is a longer task and due to deliverables withing 2 hr. This is hardcoded on the frontend for the client.
			 * Later in cron related task all the calculation from here will be moved to backend
			 */
			let viewMetadata = {
				keyName: '',
				appendCurrency: true,
				dataType: "string",
				displayName: '',
				isPortfolio: false,
				keyName: '',
				level1: true,
				level2: false,
				level3: false,
				portfolioLabel: "Balance",
				portfolioUnit: "%",
				showOnUI: true,
				value : '',
			}
			const productFormula= {
				productCode:'P01-01',
				operator:'/',
				field1: 'Fund Level P&L_SGD',
				field2: 'Current Amount_SGD',
				resultField:'Portfolio P&L'
			}
			//specific requirement from client
			const specialProductView = {
				productCodes:['P02-01','P02-02','P02-03','P02-04'],
				resultField:'Total Policies'
			}

			/**
			 * ========END===========
			 */
			
			
			this.$onChanges = (changes) => {
				if (changes.products) {
					$scope.products = changes.products.currentValue;
					let pieDataArr  = []
					let total = 0;
					const groupBy = (items, key) => items.reduce(
						(result, item) => ({
						  ...result,
						  [item[key]]: [
							...(result[item[key]] || []),
							item,
						  ],
						}),
						{},
					);
                    _.forEach($scope.products, function(product){
						if(product.isPortfolio){
							var pieDataObj ={
								key: product.productName,
								value: Number(product.portfolioValue).toFixed(2)
							}
							total += product.portfolioValue
							pieDataArr.push(pieDataObj);
						}

						product.aggregatedSubProducts = []
						const tempSubproducts = _.cloneDeep(product.subproducts);
						/**
						 * Grouping the sub product based on productCode
						 */
						let groupedProduct = groupBy(tempSubproducts,'productCode')
						const groupedProductKeys = Object.keys(groupedProduct);

						const {config} =  p0;
						$scope.productAggregatedView = config.productAggregatedView
						if(groupedProductKeys.length && $scope.productAggregatedView){
							groupedProductKeys.forEach(gp => {
								/**
								 * gp is key, which is a productCode
								 * aggregating the product metadata which is configured for level 1
								 * after aggregation append the updated metadata to the new array
								 * new array now contains the aggregated data, which can be used on the frontend
								*/
								const spExists = product.aggregatedSubProducts.find( sp => sp.productCode === gp)
								const firstSubProduct = groupedProduct[gp][0]
								
								groupedProduct[gp].forEach((subProduct, index) => {
									const aggregationMetadatas = subProduct.metaData.filter( metadata => metadata.level1)
									firstSubProduct.metaData.forEach( metadata => {
										const filteredKey = aggregationMetadatas.find( md => md.keyName === metadata.keyName)
										if(metadata.level1 && filteredKey && index){
											metadata.value = metadata.value+filteredKey.value
										}
									})
								})
								if(specialProductView.productCodes.includes(firstSubProduct.productCode)){
									viewMetadata = {
										...viewMetadata,
										keyName: specialProductView.resultField,
										displayName: specialProductView.resultField,
										keyName: specialProductView.resultField,
										appendCurrency: false,
										portfolioUnit: "",
										value : groupedProduct[gp].length,
									} 
									firstSubProduct.metaData.push(viewMetadata)
								}
								if(!spExists){
									// console.log(firstSubProduct.productCode, productFormula.productCode,'firstSubProduct.productCode === productFormula.productCode')
									if(firstSubProduct.productCode === productFormula.productCode){
										// console.log(firstSubProduct.metaData)
										const field1 = firstSubProduct.metaData.find(md => md.keyName === productFormula.field1)
										const field2 = firstSubProduct.metaData.find(md => md.keyName === productFormula.field2)
										if(field1 && field2 && field1.value && field2.value){
											viewMetadata = {
												...viewMetadata,
												keyName: productFormula['resultField'],
												displayName: productFormula['resultField'],
												keyName: productFormula['resultField'],
												portfolioUnit: "%",
												appendCurrency: true,
												value : (field1.value/field2.value)*100,
											} 
											firstSubProduct.metaData.push(viewMetadata)
										}

									}
									product.aggregatedSubProducts.push(firstSubProduct)
								}
							})
						}
						product.subproducts.forEach(subproduct => {
							subproduct.metaData.forEach ( metadata => {
								const value = new Date(metadata.value)
								/**
								 * condition to check for valid date, if valid date then format it as per the requirement
								 */
								if(metadata.dataType && metadata.dataType.toLocaleLowerCase().trim() === 'date') {
									metadata.value = $filter('date')(value, "mediumDate")
								}
							})

						})
						//if there is no configuration to show aggregatedView then aggregatedSubProducts is same as individual subproducts
						if(!$scope.productAggregatedView) {
							product.aggregatedSubProducts = product.subproducts
						}
					})

					_.forEach(pieDataArr, function(pie){
							pie.percent = total ? Number((pie.value/total) * 100).toFixed(2) : 0;

                    })

					$scope.totalPortfolio = total

                    //uncomment this to show the real data
                    if(pieDataArr.length)
                    $scope.pieData = pieDataArr
				}
				if (changes.customer) {
					$scope.customer = changes.customer.currentValue;
				}
			}

			$scope.accounts = [{
					type: 'current',
					name: 'Current Account',
					number: '12345678901234',
					balance: 16677800
				},
				{
					type: 'savings',
					name: 'Savings Account',
					number: '12345678904321',
					balance: 10677800
				},
				{
					type: 'savings',
					name: 'Savings Account',
					number: '12345678904321',
					balance: 10677800
				},
				{
					type: 'savings',
					name: 'Savings Account',
					number: '12345678904321',
					balance: 10677800
				}
			];
			$scope.creditCards = [{
					type: 'current',
					name: 'American Express',
					number: '12345678901234',
					balance: 0
				},
				{
					type: 'savings',
					name: 'Mastercard Platinum',
					number: '12345678904321',
					balance: 0
				}
			];

			$scope.showDetails = function (event) {
				var target = angular.element(event.target);
				if (target.parent().find('.dynamic-details').hasClass('toggle-details'))
					target.parent().find('.dynamic-details').removeClass('toggle-details')
				else
					target.parent().find('.dynamic-details').addClass('toggle-details')
			}
			$scope.showAngleDetails = function(event){
				var target = angular.element(event.target);
				if (target.parent().parent().find('.dynamic-details').hasClass('toggle-details'))
					target.parent().parent().find('.dynamic-details').removeClass('toggle-details')
				else
					target.parent().parent().find('.dynamic-details').addClass('toggle-details')
			}

            // $scope.pieData = [{"key":"one", "value":20},
            // {"key":"two", "value":50},
            // {"key":"three", "value":30},{"key":"one", "value":20},
            // {"key":"two", "value":50},
            // {"key":"three", "value":30}];
            /**
			 * open sidebar and show product details
			 */
			$scope.showSideProductDetail = (product, subProduct, index) => {
				const sideProduct = Object.assign({},product);
				$scope.activeProduct = subProduct;
				$scope.activeProductIndex = index;
				sideProduct.subproducts = sideProduct.subproducts.filter( sp => sp.productCode === subProduct.productCode)
				$rootScope.$emit('showSideProductView', {product: sideProduct, viewStatus: true});
				if(!$rootScope.isRightSideBarExpand)
				$rootScope.isRightSideBarExpand = true
				//KEEP IT: for future purpose
				// $http.get(`/api/customers/getAccountMapping?cui=${$state.params.customerId}`).then(function(result) {
				// 	mappedCustomers = result.data.mapping;
                // }).catch(e=>{
                //     console.log(e.message)
                // });
			}
			$rootScope.$on('hideSideProductView', function(event, obj){
				$scope.activeProduct = {};
				$scope.activeProductIndex = -1;
			})
		}

	});
