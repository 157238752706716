'use strict';

angular.module('bitnudgeApp')
    .controller('StepOneController', function ($scope, Auth, $http, $state, Gender, p0) {
        let self = this;
        self.init = function () {
            $scope.jerseyImage = Gender.getJerseyImage();
            $scope.currentUser = Auth.getCurrentUser();
            $scope.nickname = $scope.currentUser.profileInfo.nickname;
            if (!$scope.nickname) {
                $scope.nickname = '';
            }
            $scope.deg = $scope.currentUser.profileInfo.jersey;
        }
        $scope.step_continue = function (current, me) {
            var cont = $(".track-loc");
            cont.find(current).prevAll().addClass("completed");
            cont.find(current).addClass("active");
            $(".track-info").find(".step-tab").hide();
            $(me.target).closest(".step-tab").next().fadeIn();
        };

        $scope.step_finish = function (current, me) {
            var cont = $(".track-loc");
            cont.find(current).addClass("completed");
        };

        $scope.continue = function () {
            if ($scope.nickname) {
                return $http.post('/api/users/setStep1Info',{nickname: $scope.nickname, jersey: $scope.deg}).then(function () {
                    $scope.currentUser.profileInfo.nickname = $scope.nickname;
                    $scope.currentUser.profileInfo.jersey = $scope.deg;
                    var currentUser = Auth.getCurrentUser();
                    if(currentUser.cloneUser){
                        currentUser.cloneUser.avatarSet = true;
                    }else{
                        currentUser.avatarSet = true;
                    }
                    Auth.setSecurityQuestions().then(function (set) {
                        if(set) {
                            $state.go('user.securityQuestions',{set:true});
                        }else{
                            if (p0.config.showStep2) {
                                $state.go('user.step2');
                            } else {
                                $state.go('userFlow.dashboard');
                            }
                        }
                    });

                });
            }
        };
        self.init()
    });

