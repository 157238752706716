const autoImportNgTemplateLoaderTemplate1 = require('../html/moreDataValues.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('moreDataValues', function () {
        return {
            name: 'moreDataValues',
            scope: {
                dataValues: '=',
                summaryItems : '=',
                summary : '=',
                seriesName : '=',
                lineData :'=',
                selectedCycles :'=',
                selectedCycleType :'=',
                isMoreDataCard :'=',
                hideUserValue :'='

            },
            controller: 'moreDataValuesCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
