const autoImportNgTemplateLoaderTemplate1 = require('../html/landingPage/user.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/landingPage/step1.html');
const autoImportNgTemplateLoaderTemplate3 = require('../html/landingPage/step2.html');
const autoImportNgTemplateLoaderTemplate4 = require('../html/landingPage/securityQuestions.html');

'use strict';

angular.module('bitnudgeApp')
    .config(function($stateProvider) {
        $stateProvider
            .state('user',{
                abstract : true,
                url : '/user',
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller : function(){}
            })
            .state('user.step1',{
                url : '/step1',
                templateUrl: autoImportNgTemplateLoaderTemplate2,
                controller : 'StepOneController'
            })
            .state('user.step2',{
                url : '/step2',
                templateUrl: autoImportNgTemplateLoaderTemplate3,
                controller : 'StepTwoController'
            })
            .state('user.securityQuestions',{
                  url: '/securityQuestions/:set/:email',
                  templateUrl:autoImportNgTemplateLoaderTemplate4,
                  controller: 'securityQuestionsController'
            })
    });
