angular.module('bitnudgeApp')
    .controller('addIPCtrl', function ($http, $scope, Auth, Upload, $location, toaster,$state) {
        $scope.modules = [];
        $scope.addIP = function (form) {
            var isValid = true;
            if(!$scope.data){
                $scope.message = "Please provide all the fields";
                isValid = false;
            }
            else if(!$scope.data.name){
                $scope.message = "Please provide name field";
                isValid = false;
            }
            else if(!$scope.data.IP){
                $scope.message = "Please provide IP";
                isValid = false;
            }else if(!$scope.data.modules || !$scope.data.modules.length){
                $scope.message = "Please provide atleast one module";
                isValid = false;
            }else if($scope.data.IP){
                var pattern = new RegExp(/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/);
                var validateIP = pattern.test($scope.data.IP);
                if(!validateIP){
                    $scope.message = "Please provide valid IP"
                    isValid = false;
                }
            }
            if(isValid){
                $http.post('/api/adminActions/addIP',$scope.data).then(function(result){
                    if(result.data.status){
                        toaster.pop("success","IP added","Successfully IP added");
                        $scope.data.name = "";
                        $scope.data.IP = "";
                        $scope.data.modules = "";
                    }else{
                        toaster.pop("error","Error",result.data.errors[0].message);
                    }
                }); 
            }
        
        }

        function getModulesList() {
            $http.post('/api/adminActions/getModuleList').then(function (modulesList) {
                    if(modulesList.data.status){
                        $scope.modules = modulesList.data.data; 
                    }else{
                        toaster.pop('error', "Error", modulesList.data.errors[0].message);
                    }
                });
            };
        getModulesList();    
});