const autoImportNgTemplateLoaderTemplate1 = require('../html/moversAndShakersCards.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('moversAndShakersCards', function(){
        return {
            name: 'moversAndShakersCards',
            scope: {
                mss:                    "=",
                isOwnCards:             "@",
                onCompleteClick:        '=',
                selectedCycleForMS:     '=selectedCycleModel',
                selectedLeagueForMS:    '=selectedLeagueModel',
                selectedPlayerTypeForMS:'=selectedPlayerTypeModel',
                jobRole:                '='
            },

            controller: 'moversAndShakersCardsCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });

