'use strict'
angular.module('bitnudgeApp')
    .controller('inboxCtrl',function($scope, $location, $http, Auth, toaster, moment) {
        $scope.user = Auth.getCurrentUser();

        $scope.receiverList = [];
        $http.post('api/users',{select: ["name"]}).then(function(users) {
            users=users.data;
            $scope.userList = users;
            $scope.receiverList = $scope.userList[-1];

            $scope.getMyInbox();
            $scope.getMySent()

        });

        $scope.inboxTab = "active";
        $scope.sentTab = "";
        $scope.writeTab = "";
        $scope.mailHeader = ": Inbox";

        $scope.setTab = function(tab, header) {
            $scope.inboxTab = "";
            $scope.sentTab = "";
            $scope.writeTab = "";
            $scope[tab] = "active";
            $scope.mailHeader = ": "+ header;
        }

        $scope.inboxStatus = 'mailbox';
        $scope.sentStatus = 'mailbox';

        $scope.getMyInbox = function() {
            $http.post('/api/nudgeMailboxes/user/inbox', {id: $scope.user._id}).then(function (inbox) {
                inbox=inbox.data;
                for(var i = 0; i < inbox.length; i++)
                    if(inbox[0].sender == undefined)
                        inbox[0].senderObj = {name: "Admin"}

                for(var i = 0; i < inbox.length; i++) {
                    if(inbox[i].sender != undefined)
                        inbox[i].senderObj = $.grep($scope.userList, function(user) {return user._id.toString() == inbox[i].sender.toString()})[0];

                    inbox[i].receiverObjs = [];
                    for(var j = 0; j < inbox[i].receivers.length; j++) {
                        var newObj = $.grep($scope.userList, function(user) {return user._id.toString() == inbox[i].receivers[j].toString()})[0];
                        inbox[i].receiverObjs.push(newObj);
                    }
                }

                console.log(inbox);
                $scope.inbox = inbox;

                $scope.getUnreadCount();

                $scope.inboxPages = $scope.mailPagination($scope.inbox, 10);

                $scope.currentInboxPage = 0;
                $scope.selectedInboxPage = $scope.inboxPages[0];
                $scope.initInboxMailsCheckList();
                $scope.inboxPg1 = "disabled";
                $scope.inboxPgLeft = "disabled";
                $scope.inboxPgRight = (($scope.inboxPages.length == 0) || ($scope.inboxPages.length == 1)) ? "disabled" : "";
                $scope.inboxPgLast = (($scope.inboxPages.length == 0) || ($scope.inboxPages.length == 1)) ? "disabled" : "";

            });
        }

        $scope.getMySent = function() {
            $http.post('/api/nudgeMailboxes/user/sent',{id:$scope.user._id}).then(function (sent) {
                sent=sent.data;
                for(var i = 0; i < sent.length; i++) {
                    if(sent[i].sender != undefined)
                        sent[i].senderObj = $.grep($scope.userList, function(user) {return user._id.toString() == sent[i].sender.toString()})[0];

                    sent[i].receiverObjs = [];
                    for(var j = 0; j < sent[i].receivers.length; j++) {
                        var newObj = $.grep($scope.userList, function(user) {return user._id.toString() == sent[i].receivers[j].toString()})[0];
                        sent[i].receiverObjs.push(newObj);
                    }
                }

                console.log(sent);
                $scope.sent = sent;

                $scope.sentPages = $scope.mailPagination($scope.sent, 10);

                $scope.currentSentPage = 0;
                $scope.selectedSentPage = $scope.sentPages[0];
                $scope.sentPg1 = "disabled";
                $scope.sentPgLeft = "disabled";
                $scope.sentPgRight = (($scope.sentPages.length == 0) || ($scope.sentPages.length == 1)) ? "disabled" : "";
                $scope.sentPgLast = (($scope.sentPages.length == 0) || ($scope.sentPages.length == 1)) ? "disabled" : "";

            });
        }

        $scope.getUnreadCount = function() {
            $scope.unread = _.filter($scope.inbox, function(mail) { return mail.read == 'unread' });
            $scope.unreadCount = $scope.unread.length;
        }

        $scope.mailPagination = function(array, count) {
            var i = 0;
            var pages = []
            while(i < array.length) {
                var upperLimit = i+count;
                if(upperLimit <= array.length) {
                    var newPage = array.slice(i, upperLimit);
                    pages.push(newPage);
                    i += count;
                } else {
                    var newPage = array.slice(i);
                    pages.push(newPage);
                    i += count;
                }
            }

            return pages;
        }

        $scope.changeInboxPage = function(loc) {
            $scope.inboxPg1 = "";
            $scope.inboxPgLeft = "";
            $scope.inboxPgRight = "";
            $scope.inboxPgLast = "";
            switch(loc) {
                case 'first':
                    $scope.currentInboxPage = 0;
                    $scope.selectedInboxPage = $scope.inboxPages[0];
                    $scope.inboxPg1 = "disabled";
                    $scope.inboxPgLeft = "disabled";
                    break;

                case 'left':
                    $scope.currentInboxPage--;
                    $scope.selectedInboxPage = $scope.inboxPages[$scope.currentInboxPage];
                    if($scope.currentInboxPage == 0) {
                        $scope.inboxPg1 = "disabled";
                        $scope.inboxPgLeft = "disabled";
                    }
                    break;

                case 'right':
                    $scope.currentInboxPage++;
                    $scope.selectedInboxPage = $scope.inboxPages[$scope.currentInboxPage];
                    if($scope.currentInboxPage == $scope.inboxPages.length-1) {
                        $scope.inboxPgRight = "disabled";
                        $scope.inboxPgLast = "disabled";
                    }
                    break;

                case 'last':
                    $scope.currentInboxPage = $scope.inboxPages.length-1;
                    $scope.selectedInboxPage = $scope.inboxPages[$scope.currentInboxPage];
                    $scope.inboxPgRight = "disabled";
                    $scope.inboxPgLast = "disabled";
                    break;
            }
            $scope.initInboxMailsCheckList();
        }

        $scope.changeSentPage = function(loc) {
            $scope.sentPg1 = "";
            $scope.sentPgLeft = "";
            $scope.sentPgRight = "";
            $scope.sentPgLast = "";
            switch(loc) {
                case 'first':
                    $scope.currentSentPage = 0;
                    $scope.selectedSentPage = $scope.sentPages[0];
                    $scope.sentPg1 = "disabled";
                    $scope.sentPgLeft = "disabled";
                    break;

                case 'left':
                    $scope.currentSentPage--;
                    $scope.selectedSentPage = $scope.sentPages[$scope.currentSentPage];
                    if($scope.currentSentPage == 0) {
                        $scope.sentPg1 = "disabled";
                        $scope.sentPgLeft = "disabled";
                    }
                    break;

                case 'right':
                    $scope.currentSentPage++;
                    $scope.selectedSentPage = $scope.sentPages[$scope.currentSentPage];
                    if($scope.currentSentPage == $scope.sentPages.length-1) {
                        $scope.sentPgRight = "disabled";
                        $scope.sentPgLast = "disabled";
                    }
                    break;

                case 'last':
                    $scope.currentSentPage = $scope.sentPages.length-1;
                    $scope.selectedSentPage = $scope.sentPages[$scope.currentSentPage];
                    $scope.sentPgRight = "disabled";
                    $scope.sentPgLast = "disabled";
                    break;
            }
            $scope.initSentMailsCheckList
        }

        $scope.getMailReadStatus = function(mail) {
            if(mail.read == 'unread')
                return "message-unread";
            else
                return "";
        }

        $scope.setSelectedInboxMail = function(index) {
            $scope.inboxMessageIndex = index;
            $scope.selectedInboxMail = $scope.inbox[index];

            $scope.inboxStatus = 'mail';

            $scope.markMailAsRead('read');
        }

        $scope.setSelectedSentMail = function(index) {
            $scope.sentMessageIndex = index;
            $scope.selectedSentMail = $scope.sent[index];

            $scope.sentStatus = 'mail';
        }

        $scope.markMailAsRead = function(readStatus) {
            $scope.selectedInboxMail.read = readStatus;
            $http.post('/api/nudgeMails/setMailRead/'+$scope.selectedInboxMail._id, {read: readStatus})
            $scope.getUnreadCount()
        }

        $scope.backToInbox = function() {
            $scope.inboxStatus = 'mailbox';
        }

        $scope.backToSent = function() {
            $scope.sentStatus = 'mailbox';
        }

        $scope.emptyNewMail = function() {
            $scope.receiverList = $scope.userList[-1];
            $scope.subject = "";
            $scope.content = "";
        }

        $scope.sendMail = function() {
            if($scope.receiverList == undefined)
                toaster.pop('error', 'New Mail', 'Enter Mail Receipts.');
            else if($scope.subject == undefined)
                toaster.pop('error', 'New Mail', 'Enter Mail Subject.');
            else if($scope.content == undefined)
                toaster.pop('error', 'New Mail', 'Enter Mail Content.');
            else {
                var receivers = [];
                for(var i = 0; i < $scope.receiverList.length; i++) {
                    receivers.push($scope.receiverList[i]._id)
                }

                var mailBody = {
                    sender: $scope.user._id,
                    receivers: receivers,
                    subject: $scope.subject,
                    content: $scope.content,
                    read: 'unread'
                }

                $http.post('/api/nudgeMailboxes/user/sendMail', mailBody).then(function(e) {
                    e=e.data;
                    toaster.pop('success', 'New Mail', 'Mail sent.');
                    $scope.getMyInbox();
                    $scope.getMySent();
                    $scope.emptyNewMail();
                    $scope.setTab('sentTab', "Sent");
                });
            }
        }

        $scope.replyMail = function(mail) {
            console.log("in replyMail");
            console.log(mail);
        }

        $scope.forwardMail = function(mail) {
            console.log("in forwardMail");
            console.log(mail);
        }

        $scope.deleteMail = function(deleteMailList) {
            console.log("in deleteMail");
            console.log(deleteMailList);
            for(var i = 0; i < deleteMailList.length; i++) {
                $http.post('/api/nudgeMailboxes/deleteMail/'+deleteMailList[i]).then(function() {})
            }

            $scope.getMyInbox();
            $scope.getMySent();
        }

        $scope.initInboxMailsCheckList = function() {
            $scope.inboxMails = [];
            $scope.allInboxMailsForPage = false;
            $scope.deleteInboxMailStatus = undefined;
            for(var i = 0; i < $scope.selectedInboxPage.length; i++)
                $scope.inboxMails.push(0);
        }

        $scope.initSentMailsCheckList = function() {
            $scope.sentMails = [];
            $scope.allSentMailsForPage = false;
            $scope.deleteSentMailStatus = undefined;
            for(var i = 0; i < $scope.selectedSentPage.length; i++)
                $scope.sentMails.push(0);
        }

        $scope.selectInboxMails = function(status) {
            switch(status) {
                case 'all':
                    $scope.readInbox = 'Check';
                    $scope.unreadInbox = 'Check';
                    $scope.allInboxMailsForPage = true;
                    for(var i = 0; i < $scope.selectedInboxPage.length; i++)
                        $scope.setInboxMailAsChecked(i, $scope.selectedInboxPage[i]._id, 'bulk');

                    break;

                case 'none':
                    $scope.readInbox = undefined;
                    $scope.unreadInbox = undefined;
                    $scope.allInboxMailsForPage = false;
                    for(var i = 0; i < $scope.selectedInboxPage.length; i++)
                        $scope.setInboxMailAsChecked(i, 0, 'bulk');
                    break;

                case 'read':
                    $scope.readInbox = 'Check';
                    $scope.unreadInbox = undefined;
                    $scope.allInboxMailsForPage = false;
                    for(var i = 0; i < $scope.selectedInboxPage.length; i++)
                        if($scope.selectedInboxPage[i].read == 'read')
                            $scope.setInboxMailAsChecked(i, $scope.selectedInboxPage[i]._id, 'bulk');
                        else
                            $scope.setInboxMailAsChecked(i, 0, 'bulk');

                    break;

                case 'unread':
                    $scope.readInbox = undefined;
                    $scope.unreadInbox = 'Check';
                    $scope.allInboxMailsForPage = false;
                    for(var i = 0; i < $scope.selectedInboxPage.length; i++)
                        if($scope.selectedInboxPage[i].read == 'unread')
                            $scope.setInboxMailAsChecked(i, $scope.selectedInboxPage[i]._id, 'bulk');
                        else
                            $scope.setInboxMailAsChecked(i, 0, 'bulk');

                    break;

            }

            console.log($scope.inboxMails);
        }

        $scope.selectInboxMails = function(status) {
            switch(status) {
                case 'all':
                    $scope.readSent = 'Check';
                    $scope.unreadSent = 'Check';
                    $scope.allSentMailsForPage = true;
                    for(var i = 0; i < $scope.selectedSentPage.length; i++)
                        $scope.setInboxMailAsChecked(i, $scope.selectedSentPage[i]._id, 'bulk');

                    break;

                case 'none':
                    $scope.readSent = undefined;
                    $scope.unreadSent = undefined;
                    $scope.allSentMailsForPage = false;
                    for(var i = 0; i < $scope.selectedSentPage.length; i++)
                        $scope.setInboxMailAsChecked(i, 0, 'bulk');
                    break;

                case 'read':
                    $scope.readSent = 'Check';
                    $scope.unreadSent = undefined;
                    $scope.allSentMailsForPage = false;
                    for(var i = 0; i < $scope.selectedSentPage.length; i++)
                        if($scope.selectedSentPage[i].read == 'read')
                            $scope.setInboxMailAsChecked(i, $scope.selectedSentPage[i]._id, 'bulk');
                        else
                            $scope.setInboxMailAsChecked(i, 0, 'bulk');

                    break;

                case 'unread':
                    $scope.readSent = undefined;
                    $scope.unreadSent = 'Check';
                    $scope.allSentMailsForPage = false;
                    for(var i = 0; i < $scope.selectedSentPage.length; i++)
                        if($scope.selectedSentPage[i].read == 'unread')
                            $scope.setInboxMailAsChecked(i, $scope.selectedSentPage[i]._id, 'bulk');
                        else
                            $scope.setInboxMailAsChecked(i, 0, 'bulk');

                    break;

            }

        }

        $scope.setInboxMailAsChecked = function(index, mailId, type) {
            switch(type) {
                case 'single':
                    $scope.inboxMails[index] = $scope.inboxMails[index] == 0 ? mailId : 0;
                    $scope.getInboxCheckStatus();
                    break;

                case 'bulk':
                    $scope.inboxMails[index] = mailId;
                    $scope.getInboxCheckStatus();
                    break;
            }
        }

        $scope.setSentMailAsChecked = function(index, mailId, type) {
            switch(type) {
                case 'single':
                    $scope.sentMails[index] = $scope.sentMails[index] == 0 ? mailId : 0;
                    $scope.getSentCheckStatus();
                    break;

                case 'bulk':
                    $scope.sentMails[index] = mailId;
                    $scope.getSentCheckStatus();
                    break;
            }
        }

        $scope.getInboxCheckStatus = function() {
            $scope.deleteInboxMailStatus = undefined;
            for(var i = 0; i < $scope.inboxMails.length; i++) {
                if($scope.inboxMails[i] != 0) {
                    $scope.deleteInboxMailStatus = 'Check';
                    break;
                }
            }
        }

        $scope.getSentCheckStatus = function() {
            $scope.deleteSentMailStatus = undefined;
            for(var i = 0; i < $scope.sentMails.length; i++) {
                if($scope.sentMails[i] != 0) {
                    $scope.deleteSentMailStatus = 'Check';
                    break;
                }
            }
        }

        $scope.setAllInboxMailCheckStatus= function() {
//            alert($scope.allInboxMailsForPage)
            if($scope.selectedInboxPage)
            {
                for(var i = 0; i < $scope.selectedInboxPage.length; i++) {
                    if($scope.allInboxMailsForPage == true){
                        $scope.setInboxMailAsChecked(i, $scope.selectedInboxPage[i]._id, 'bulk');
                        $scope.readInbox = 'Check';
                        $scope.unreadInbox = 'Check';
                    } else {
                        $scope.setInboxMailAsChecked(i, 0, 'bulk');
                        $scope.readInbox = undefined;
                        $scope.unreadInbox = undefined;
                    }
                }
            }
        }

        $scope.setAllSentMailCheckStatus= function() {
            for(var i = 0; i < $scope.selectedSentPage.length; i++) {
                if($scope.allSentMailsForPage == true){
                    $scope.setInboxMailAsChecked(i, $scope.selectedSentPage[i]._id, 'bulk');
                    $scope.readSent = 'Check';
                    $scope.unreadSent = 'Check';
                } else {
                    $scope.setInboxMailAsChecked(i, 0, 'bulk');
                    $scope.readSent = undefined;
                    $scope.unreadSent = undefined;
                }
            }
        }

        $scope.deleteSelectedInboxMails = function() {
            var deleteList = [];
            for(var i = 0; i < $scope.inboxMails.length; i++)
                if($scope.inboxMails[i] != 0)
                    deleteList.push($scope.inboxMails[i]);

            $scope.deleteMail(deleteList);
            $scope.initInboxMailsCheckList();

        }

        $scope.deleteSelectedSentMails = function() {
            var deleteList = [];
            for(var i = 0; i < $scope.sentMails.length; i++)
                if($scope.sentMails[i] != 0)
                    deleteList.push($scope.sentMails[i]);

            $scope.deleteMail(deleteList);
            $scope.initSentMailsCheckList();

        }

        $scope.formatDate = function(date, type){

            switch(type) {
                case 'mailbox':
                    return(moment(date).fromNow());

                case 'mail':
                    return $scope.getMailDate(date);
            }

        }

        $scope.getMailDate = function(date) {
            var today = parseInt(moment().get('year') + "" + moment().get('month') + "" + moment().get('date'));
            var mailDate = parseInt(moment(date).get('year') + "" + moment(date).get('month') + "" + moment(date).get('date'));

            switch(today-mailDate) {
                case 0:
                    return ("Today, " + moment(date).get('hour') + ":" + moment(date).get('minute') + ":" + moment(date).get('seconds'));

                case 1:
                    return ("Yesterday, " + moment(date).get('hour') + ":" + moment(date).get('minute') + ":" + moment(date).get('seconds'));

                default:
                    return (
                        moment(date).format("ddd") + ", " +
                        moment(date).get('date') + "/" + moment(date).get('month') + "/" + moment(date).get('year') + ", " +
                        moment(date).get('hour') + ":" + moment(date).get('minute') + ":" + moment(date).get('seconds')
                    )
            }
        }


    });
