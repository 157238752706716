'use strict';
angular.module('bitnudgeApp')
    .controller('confirmWithCheckModalCtrl', function ($scope, $mdDialog, onConfirm, subHeader) {
        $scope.subHeader = subHeader;
        $scope.confirm = function (status) {
            onConfirm($scope.checked && status);
            $scope.cancel();
        };
        $scope.cancel = function() {
            $mdDialog.cancel();
        };
    });