const autoImportNgTemplateLoaderTemplate1 = require('../html/incentives/incentivesModal.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/scoreCard/scoreCardPipelineModal.html');
const autoImportNgTemplateLoaderTemplate3 = require('../html/portfolioModal.html');
const autoImportNgTemplateLoaderTemplate4 = require('../html/scoreCard/scoreCardManagerModal.html');
const autoImportNgTemplateLoaderTemplate5 = require('../html/pipelineInfo.html');
const autoImportNgTemplateLoaderTemplate6 = require('../html/portfolio/userPortfolioPie.html');
const autoImportNgTemplateLoaderTemplate7 = require('../html/transaction/transaction.html');

'use strict';
angular.module('bitnudgeApp')

    .controller('scoreCardCtrl', function ($scope, p0, Auth, $rootScope, $mdDialog, PipelineService, PortfolioService, $state) {

        let self = this;
        this.init = function () {
            $scope.user = Auth.getCurrentUser();

            $scope.daysPassed = $rootScope.daysPassed;
            $scope.myProducts = _.sortBy(_.cloneDeep(p0.myProducts),'productCode');
			      $scope.params = {reporteeLabel: p0.strings.FRONTLINE.value};
            if (!$scope.kpi.isPipelineCard && !$scope.kpi.isPortfolio) {

                $scope.unit = _.find(p0.allUIKpis, { _id: $scope.kpi.kpiId }).unit;

                $scope.denom = _.find(p0.allUIKpis, { _id: $scope.kpi.kpiId }).denom;
            }
            $scope.unit = p0.config.CURRENCY_UNIT || "IDR";
            $scope.mtd = 'MTD';
            if ($scope.kpi.kpiCode) {
                $scope.kpi.kpiPerDayAchieved = $scope.kpi.achievedValue.value / ($scope.kpi.denom * $scope.daysPassed);
            }
            var userLevel = Auth.getCurrentUser().organizationalTag.jobRole.level;
            //don't allow reportee view  if the user level is more than the lowest senior manager
            $scope.showScorecard = self.checkLowestSeniorManager(userLevel)

            // if ($scope.kpi.isPortfolio) {

            // }
            // let cycleId = p0.currentMonthlyCycle._id;
            // PortfolioService.getPortfolio(cycleId).then(function (result) {
            //     result = result.data;
            //     if(result.status){
            //         if (result.data.length > 0) {
            //             $scope.userPortfolioData = result.data[0];

            //         }
            //     }
            // })

        }

        self.checkLowestSeniorManager = function (userLevel) {
            var allJobRoles = p0.allJobRoles;
            var lowestLeverSenioMangerOrBelow = false;
            var seniorManagerJobRoles = allJobRoles.filter(function (jobRole) {
                if (jobRole.roleType == "seniorManager") {
                    return true;
                }
                else {
                    return false;
                }
            })
            seniorManagerJobRoles = _.orderBy(seniorManagerJobRoles, ['level'], ['asc']);
            var lowestLevel = seniorManagerJobRoles[0].level;
            if (userLevel <= lowestLevel) {
                lowestLeverSenioMangerOrBelow = true;
            }
            else {
                lowestLeverSenioMangerOrBelow = false;
            }
            if (!lowestLeverSenioMangerOrBelow && $scope.kpi.kpiCode == "K00") {
                return false;
            }
            else {
                return true;
            }
        }

        $scope.dialog = function (kpi) {
            self.managerDialog(kpi);
        };

        $scope.incentivesDialog = function(user){
			$mdDialog.show({
				templateUrl: autoImportNgTemplateLoaderTemplate1,
				controller: 'incentivesModalController',
				fullscreen: true, // Only for -xs, -sm breakpoints.
				resolve: {
					user: function () {
						return user;
					}
				}
			});
		};

        $scope.pipelineDialog = function (productId) {
            if (!$scope.pipelineData[productId]) {
                return;
            }
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate2,
                controller: 'pipelineModalCtrl',
                fullscreen: true, // Only for -xs, -sm breakpoints.
                resolve: {
                    data: function () {
                        return {
                            productId: productId,
                            jobroleId: $scope.selectedUser.organizationalTag.jobRole._id,
                            user: $scope.selectedUser,
                            pipelineData: $scope.pipelineData
                        }
                    }
                }
            });
        }

        $scope.portfolioDialog = function () {
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate3,
                controller: 'portfolioModalController',
                fullscreen: true, // Only for -xs, -sm breakpoints.
                resolve: {
                    user: function () {
                        return $scope.selectedUser;
                    }
                }
            });
        }


        self.managerDialog = function (kpi) {
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate4,
                controller: 'scoreCardManagerModalController',
                fullscreen: true, // Only for -xs, -sm breakpoints.
                resolve: {
                    kpi: function () {
                        return kpi;
                    },
                    jobroleId: function () {
                        return $scope.selectedUser.organizationalTag.jobRole._id;
                    }
                    ,
                    user: function () {
                        return $scope.selectedUser;
                    }
                    ,
                    cycleId: function () {
                        return $scope.selectedCycle._id;
                    }
                    ,
                    selectedCycle: function () {
                        return $scope.selectedCycle.name;
                    },
                    isSelectedCycleActive: function () {
                        return $scope.selectedCycle.active;
                    }
                }
            });
        };

        // $scope.$watch('selectedUser', function (selectedUser) {
        //     if (selectedUser && selectedUser._id) {
        //         var cycleId = p0.currentMonthlyCycle._id;
        //         var userId = selectedUser._id;
        //         self.getPipelineScorecardData(userId, cycleId);
        //     }
        // });

        // self.getPipelineScorecardData = function (userId, cycleId) {
        //     PipelineService.getPipelineScorecardData(userId, cycleId)
        //         .then(function (pipelineScorecardData) {
        //             pipelineScorecardData = pipelineScorecardData.data
        //             pipelineScorecardData = self.mapProductNames(pipelineScorecardData);
        //             $scope.pipelineData = pipelineScorecardData;
        //         })
        // }

        self.mapProductNames = function (pipelineScorecardData) {
            let data = {};
            /* remove name, email, _id from the pipeline object keep only product id keys*/
            p0.myProducts.forEach((prd) => {
                data[prd._id] = pipelineScorecardData[prd._id]
                data[prd._id].shortName = prd.shortName
            });
            return data;
        }

        $scope.showPipelineInfo = function () {
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate5,
                controller: function ($scope) {
                    $scope.onClose = function () {
                        $mdDialog.hide();
                    }
                }
            });
        };

        $scope.openUserPortfolio = function(){
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate6,
                controller: 'userPortfolioPieCtrl',
                clickOutsideToClose:true,
                fullscreen: false,
                resolve : {
                    products : function(){ return $scope.myProducts},
                    portfolioData :function(){ return $scope.userportfolioData }
                }
            });
        }
        $scope.toggleMyChild = (kpi, showChildren, isScoreCardEvent) => {
            $scope.toggleChild(kpi, showChildren, isScoreCardEvent);
        }
        $scope.openUserTransaction = function(){
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate7,
                controller: 'transactionController',
                clickOutsideToClose:false,
                fullscreen: true,
                resolve : {
                    user: function () {
                        return $scope.selectedUser;
                    },
                    savedFilter: function () {
                        return $scope.incentiveTransactionFilter;
                    }

                }
            }).then((data)=>{
                $scope.incentiveTransactionFilter = data
            })
        }
        $scope.openUserPortfolioTrend = function(){
            $state.go('userFlow.portfolioTrend',{});
        }
        self.init()
    });
