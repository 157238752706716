angular.module('bitnudgeApp')
    .controller('configureCronCtrl', function ($scope, Auth, uploadFlowCrons, $mdDialog, $window) {
        const currentUser = Auth.getCurrentUser();

        const availableCrons = () => {
            return uploadFlowCrons.availableCrons()
            .then(cronList => {
                if (cronList.length > 0) {
                    $scope.cronNameList = cronList.filter(cron => cron.noInputRequired);
                    return $scope.cronNameList;
                } else {
                    cronLogs = ["No crons to display"]
                }
            })
        }

        const groupCronByStatus = () => {
            return uploadFlowCrons.getCronStatus()
            .then(allCrons => {
                if (allCrons.length > 0) {
                    $scope.allCrons = allCrons.map(cron => {
                        const runningCrons = cron.status.filter(status => status.runningStatus === 1 )
                        cron.runningCronCount = runningCrons.length;
                        if (runningCrons.length === 0 && cron.status.length > 0 && cron.status[0]._id) {
                            cron.inactiveCronId = cron.status[0]._id;
                        }
                        return cron;
                    })
                    return $scope.allCrons;
                } else {
                    cronLogs = ["No cron list to display"]
                }
            })
        }

        $scope.runCron = cron => {
            const confirm = $mdDialog.confirm()
                .title('Are you sure want to run this cron??')
                .ok('Yes')
                .cancel('No');
            $mdDialog.show(confirm).then(function() {
                uploadFlowCrons.runCron(cron._id)
                    .then(success => {
                        groupCronByStatus();
                        $window.location.reload();
                    })
                });
        }

        const getAllSchedulers = () => {
            uploadFlowCrons.getAllSchedulers()
            .then(schedulerList => {
                if (schedulerList && schedulerList.length > 0) {
                    $scope.schedulerList = schedulerList;
                } else {
                    $scope.schedulerList = [];
                    cronLogs = ["No Schedulers"]
                }
            })
        }

        $scope.addScheduler = (scheduler, cronSchedule) => {
            scheduler.createdBy = currentUser._id;
            scheduler.isActive = true;
            $scope.errorMsg = "";
            let schedule = '';
            Object.entries(cronSchedule).map((cron, id) => {
                schedule = schedule + (id === 0 ? '' : ' ') + cron[1]
            });
            scheduler.schedule = schedule;
            uploadFlowCrons.createScheduler(scheduler)
            .then(success => {
                getAllSchedulers();
                $scope.scheduler = {};
            })
            .catch(err => {
                $scope.errorMsg = err.data.message;
            })
        }

        $scope.disableScheduler = scheduler => {
            uploadFlowCrons.disableScheduler(scheduler)
            .then(success => {
                getAllSchedulers();
            })
        }

    const init = () => {
        availableCrons().then(filteredCrons => {
            groupCronByStatus().then(cronList => {
                filteredCrons.map(filteredCron => {
                    cronList.map(cron => {
                        if (filteredCron.cron === cron._id) {
                            cron.noInputRequired = filteredCron.noInputRequired;
                        }
                    })
                })
                getAllSchedulers();
            })
        })
    }

    init();
})