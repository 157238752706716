'use strict';
angular.module('bitnudgeApp')
    .controller('reporteewiseTrendModalController', function ($scope, DTOptionsBuilder,addPlayers, addAllPlayers, selectedJobRole, selectedPlayers, allJobroles, DTColumnDefBuilder, Auth, $http, $uibModalInstance, $state) {

        $scope.jobRoles = allJobroles;
        $scope.selectedJobRole = selectedJobRole;
        $scope.selectedPlayers=[];

        $scope.getPlayerList = function(jobRoleId){
            const postBody = {
                userId: Auth.getCurrentUser()._id,
                jobRole: jobRoleId
            }
            $http.post('api/users/playerOfJobRole', postBody).then(function(children) {
                children=children.data;
                $scope.playerList = children;
                $scope.noOfChildren = children.length;
                _.forEach($scope.playerList, function(obj){
                    obj.isChecked = false;
                    _.forEach(selectedPlayers, function(player){
                        if(player._id.toString() == obj._id.toString())
                            obj.isChecked = true;
                    })
                });
                $scope.isAllChecked = false;
            });
        };

        $scope.dtOptions = DTOptionsBuilder.newOptions()

        $scope.dtColumnDefs = [
            DTColumnDefBuilder.newColumnDef(2).notSortable()
        ];

        $scope.onClose = function () {
            $uibModalInstance.close();
        };

        $scope.addPlayers =addPlayers;
        $scope.addAllPlayers =addAllPlayers;

        $scope.getPlayerList($scope.selectedJobRole._id);

        $scope.setSelected = function(index){
            var player = $scope.playerList[index];
            var ind =  $scope.selectedPlayers.indexOf(player);
            if(ind == -1)
                $scope.selectedPlayers.push(player);
            else
                $scope.selectedPlayers.splice(ind, 1);
            player.isChecked = !player.isChecked;
        }

        $scope.goToOCR= function(selectedPlayer){
            $uibModalInstance.close();
            $state.go('userFlow.oneClickReport', {selectedPlayerIdForOCR: selectedPlayer._id});
        }
    });
