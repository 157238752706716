'use strict';
angular.module('bitnudgeApp')
    .controller('analyticsSwotCtrl', function ($rootScope, $scope, $http, Auth, p0, CurrentCycle, Kpi){
        $scope.me = Auth.getCurrentUser();
        $scope.kpis = _.cloneDeep(p0.myKpis);
        $scope.selectedKpi = $scope.kpis[0];
        $scope.isFrontline = false;
        $scope.selected = {};
        if($scope.me.systemRole == 'frontline'){
            $scope.isFrontline = true;
            $scope.selected.user = $scope.me;
            $scope.selected.role = $scope.me.organizationalTag.jobRole;
			$scope.selected.playType = p0.myPlayTypes[0];
        }
        $scope.parentSwotType = 'kpi';
        $scope.showChildSwot = false;

        $scope.swotTypes = [
            {_id: 'kpi', name:'KPI'},
            {_id: 'kai', name: 'KAI'}
        ];
        $scope.selected.swotType = $scope.swotTypes[0];
        $scope.onSwotTypeChange = function(type){
            $scope.selected.swotType = type;
        }

        $scope.history = [];

        var setKpiDropdown = function (kpis) {
            var user = $scope.selected.user;
            if(!user){
                user = $scope.me;
            }
            if(p0.config.agentOnBoardingProductKpi){
                kpis = kpis.filter(k => k.kpiCode !== p0.config.agentOnBoardingProductKpi)
            }
            // Kpi.getUserKpis(kpis, user._id, $scope.selectedCycle._id,user.organizationalTag.jobRole._id, function(kpis){
                $scope.kpis = _.cloneDeep(kpis);
                $scope.kpis = _.sortBy($scope.kpis, ['sequence']);
                $scope.selectedKpi = $scope.kpis[0];
            // });
        };

        // $scope.onDivisionChange = function (division) {
        //     $scope.selected.division = division;
        //     if(!$scope.onReporteeChange)
        //         setKpiDropdown(division.kpis);
        // };
        $scope.onPlayTypeChange = function(playType){
            $scope.selected.playType = playType;
        }

        $scope.cycleChange = function (cycle) {
            $scope.selectedCycle = cycle;
            CurrentCycle.setCycle(cycle);
            if($rootScope.isFrontline){
                setKpiDropdown(p0.myKpis);
            }
        };


        $scope.parentSwotOnClick = function (kpiId, user) {
            $scope.childSwotType = 'kai';
            $scope.childKpi = _.find($scope.kpis, {_id:kpiId});
            $scope.childUser = user;
            $scope.showChildSwot = true;
        };

        $scope.onParentSwitchChange = function(status){
            $scope.showChildSwot = false;
            $scope.selectedKpi = $scope.kpis[0];
        };

        //When selected user changes hide child swot
        $scope.$watch('selected.user', function (newValue) {
            if(newValue){
                if($scope.selected.user.systemRole == 'frontline'){
                    $scope.parentSwotType = 'kpi';
                }else if($scope.selected.user.systemRole == 'manager'){
                    $scope.parentSwotType = 'kpi';
                }else{
                    $scope.parentSwotType = 'reportee';
                }
                $scope.showChildSwot = false;
            }
        });
        $scope.$watch('selectedKpi', function (newValue) {
            if(newValue){
                if($scope.selectedKpi._id == 'overall'){
                    $scope.showChildSwot = false;
                }
                setKaiDropDown();
            }
        });
        $scope.onReporteeChange = function (user) {
            $scope.selected.user = user;
            $scope.selected.role = user.organizationalTag.jobRole;
            setKpiDropdown($scope.selected.playType.kpis);
        };

        $scope.kais = [];
        var setKaiDropDown = function(){
            let kais = _.filter(p0.allActivities, activity => {
                return activity.kpiSpecific && activity.swot && _.find(activity.kpiData,{kpiId:$scope.selectedKpi._id})
            })
            $scope.kais = _.cloneDeep(kais);
            let pipeline = {
                _id:'pipeline',
                name:'Pipeline'
            }
            $scope.kais.push(pipeline);
            $scope.selected.kai = $scope.kais[0]
        }
        $scope.onKaiChange = function(kai){
            $scope.selected.kai = kai
        }
    });
