'use strict';

angular.module('bitnudgeApp')
    .controller('playerComparisonController', function ($scope, $rootScope, $state, $location, $http, Auth, toaster, analytics, p0, CurrentCycle,$stateParams) {
        $scope.currUser = Auth.getCurrentUser();
        $scope.strings = p0.strings;
        $scope.showSwitch = $rootScope.isManager;
        $scope.isMiddleManager = $rootScope.isMiddleManager;
        $scope.isSeniorManager = $rootScope.isSeniorManager;
        $scope.kpis = _.cloneDeep(p0.myKpis);
        $scope.kpis.unshift({_id:'overall', name:'Overall'});
        $scope.selectedKpi = $scope.kpis[0];
        $scope.opponents = [{}, {}, {},{}];

        if($rootScope.comparePlayersFromLB){
            $scope.lbName = $rootScope.nameOfLb + ' comparison';
        }

        $scope.cycleChange = function (cycle) {
            CurrentCycle.setCycle(cycle);
        };

        var playerTypes;
        if($rootScope.isSeniorManager)
            $scope.isOwn = false;
        if($scope.isOwn) {
            playerTypes = [$scope.currUser.organizationalTag.jobRole];
        }else {
            if($rootScope.isMiddleManager)
                playerTypes = p0.myFrontlineRoles;
            else if($rootScope.isSeniorManager){
                if($scope.managerEnabled)
                    playerTypes = p0.myManagerRoles;
                else
                    playerTypes = p0.myFrontlineRoles;
            }
        }
        $scope.playerTypes = playerTypes;
        if($rootScope.jobRoleFromLB && _.find(playerTypes, {_id:$rootScope.jobRoleFromLB._id}))
            $scope.selectedPlayerType = $rootScope.jobRoleFromLB;
        else if(playerTypes)
                $scope.selectedPlayerType = playerTypes[0];
        var monthlyCycle;
        if($state.params.cycleId)
            monthlyCycle = _.find(p0.allMonthlyCycles,{_id:$state.params.cycleId});
        else
            monthlyCycle= p0.currentMonthlyCycle;
        var allCyclles = _.cloneDeep(p0.allMonthlyCycles);
        $scope.allCycles = allCyclles;
        $scope.selectedCycle = monthlyCycle;
        if(!$rootScope.typeOfLb)
            $rootScope.typeOfLb = 'total';
        if(!$rootScope.kpiIdOfLb)
            $rootScope.kpiIdOfLb = 'overall';
        if(!$rootScope.activityIdOfLb)
            $rootScope.activityIdOfLb = 'overall';
        if ($rootScope.comparePlayersFromLB) {
            $scope.nameOfLb = $rootScope.nameOfLb;
            $scope.comparePlayersFromLB = true;
            delete $rootScope.comparePlayersFromLB;
        }
        $scope.filterPlayerList=function(){
            $scope.opponents = [{}, {}, {}, {}];
        };

        $scope.onBackPressed = function () {
            $rootScope.backFromAnalytics = true;
            $state.go ('userFlow.leaderboard');
        };
        $scope.deepDivePlayer1 = null;
        $scope.onDeepDive = function(player, cycle) {
            var player1;
            if($scope.isOwn) {
                player1 = Auth.getCurrentUser();
            }else {
                if(player) {
                    if($scope.deepDivePlayer1) {
                        player1 = $scope.deepDivePlayer1;
                    }else {
                        $scope.deepDivePlayer1 = player;
                        toaster.pop('info', 'Deep dive', 'Please select another player for comparison');
                        return;
                    }
                }else {
                    $scope.deepDivePlayer1 = null;
                    return;
                }
            }
            var deepDiveType;
            if($rootScope.typeOfLb =='total'){
                deepDiveType = 'overall';
            }else if($rootScope.typeOfLb =='business'){
                deepDiveType = 'impact';
            }else if($rootScope.typeOfLb =='activity'){
                deepDiveType = 'activity';
            } else if($rootScope.typeOfLb =='kpi'){
                deepDiveType = $rootScope.kpiIdOfLb;
            }
            $state.go('userFlow.deepDive', {userId1: player1._id, userId2: player._id, typeId:deepDiveType, cycleId:cycle._id});
        };

        var loadMyAnalytics = function (player1) {
            if(!$rootScope.leagueOfLb)
                $rootScope.leagueOfLb = 'bank';
            if($scope.isOwn)
                return analytics.getPlayerComparisonData($scope.currUser, $scope.selectedCycle._id, $rootScope.typeOfLb, $rootScope.kpiIdOfLb, $rootScope.activityIdOfLb, $rootScope.leagueOfLb).then(function (playerData) {
                    $scope.myAnalytics = playerData;
                });
            // else
            //     analytics.getPlayerComparisonData($scope.opponents[0], $scope.selectedCycle._id, $rootScope.typeOfLb, $rootScope.kpiIdOfLb, $rootScope.activityIdOfLb, $rootScope.leagueOfLb).then(function (playerData) {
            //         $scope.myAnalytics = playerData;
            //     });
        };

         // if coming from LB: Compare
         if($rootScope.playersFromLB && $rootScope.jobRoleFromLB._id.toString() == $scope.selectedPlayerType._id.toString()) {
            analytics.getUsersWithIds($rootScope.playersFromLB, $scope.selectedPlayerType).then(function(users){
                $scope.opponents = users;
                $rootScope.playersFromLB = null;
                loadMyAnalytics($scope.opponents[0]);
            });
        }
        if($scope.isOwn)
            loadMyAnalytics($scope.selectedPlayerType);

        $scope.switchChanged = function(changed){
            $scope.managerEnabled = changed;
            if($scope.managerEnabled){
                if($rootScope.isSeniorManager)
                    $state.go('userFlow.analytics', {tabName:'playerComparison', myComparison: false, roleType:'manager'});
                else
                    $state.go('userFlow.analytics', {tabName:'playerComparison', myComparison: true, roleType: 'manager'});
            }else{
                $state.go('userFlow.analytics', {tabName:'playerComparison', myComparison: false, roleType: 'frontline'});
            }
        };
        $scope.onPlayerTypeChange = function (selectedPlayerType) {
            $scope.opponents = [];
        };
    });
