const autoImportNgTemplateLoaderTemplate1 = require('../html/gameplanTicks.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('gameplanTicks', function () {
        return {
            name: 'gameplanTicks',
            scope: {
                cap:    '@',
                percents: '@',
                gpPercent: '@'
            },
            controller: 'gameplanTicksCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
