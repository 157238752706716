'use strict';
angular.module('bitnudgeApp')
    .controller('uploadDocumentCtrl', function($scope, Upload, toaster, $mdDialog, _, fileService, parameters) {
        const init = () => {
            $scope.items = [];
            $scope.progressVisible = {}
            $scope.progress = {}
            $scope.selectedFiles = 0;
            $scope.showModal = true;
            const { fileMetadata } = parameters.document;
            if (fileMetadata) {
                let fileTypes = fileMetadata.map(meta => meta.fileType);
                fileTypes = _.flatten(fileTypes);
                fileTypes = fileTypes.map(type => type[0] !== '.' ? `.${type}` : type);
                $scope.pattern = fileTypes.join(',');
            }
        };
        const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const imageTypes = ['png', 'jpeg', 'jpg', 'gif']

        const readableSize = function(bytes) {
            let l = 0,
                n = parseInt(bytes, 10) || 0;
            while (n >= 1024 && ++l)
                n = n / 1024;
            return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
        }

        const getUploadedImagesUserWise = () => {
            $scope.loadedItems = false;
            fileService.getThumbnailData().then((result) => {
                $scope.image = [];
                if (result.data.status) {
                    $scope.imagesData = result.data.data;
                    let data = [];
                    $scope.imagesData.forEach((imageObj) => {
                        data.push({
                            _id: imageObj._id,
                            isActive: imageObj.isActive,
                            isSelected: false,
                            fileType: imageObj.file.fileType,
                            content: (imageObj.thumbnail && imageObj.thumbnail.content && imageObj.thumbnail.content.data) ? btoa(String.fromCharCode(...new Uint8Array(imageObj.thumbnail.content.data))) : undefined,
                            name: imageObj.file.name,
                            size: readableSize(imageObj.file.size),
                            dmsUrl: imageObj.thumbnail && imageObj.thumbnail.dmsUrl,
                            isImage: imageTypes.includes(imageObj.file.fileType)
                        });
                    });
                    $scope.realImages = data;
                    $scope.items = data;
                    $scope.loadedItems = true;
                } else {
                    $scope.loadedItems = true;
                    toaster.pop('error', "Error", result.data.errors[0].message);
                }
            });
        }
        getUploadedImagesUserWise();
        $scope.$watch('files', function() {
            $scope.uploadFiles($scope.files);
        });

        const setMongooseId = (file, tempKey) => {
            $scope.items.forEach(item => {
                if (item.tempKey === tempKey) {
                    item._id = file._id
                }
            })
        }

        const removeFromSelected = (tempKey) => {
            _.remove($scope.items, function(item) {
                return item.tempKey === tempKey || item._id === tempKey
            });
        }



        $scope.$watch('file', function() {
            if ($scope.file != null) {
                $scope.files = [$scope.file];
            }
        });

        $scope.uploadFiles = function(files) {
            if (files && files.length) {
                for (let i = 0; i < files.length; i++) {
                    const reader = new FileReader();
                    const file = files[i]
                    const fileType = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
                    const tempKey = Date.parse(new Date()) + '' + Math.ceil(Math.random() * 100)
                    $scope.progressVisible[tempKey] = true
                    $scope.progress[tempKey] = 0
                    reader.readAsDataURL(file);
                    reader.addEventListener("load", function() {
                        const content = reader.result ? reader.result.split(',')[1] : null
                        $scope.items.unshift({ content, fileType, isSelected: true, isActive: true, tempKey, name: file.name, size: readableSize(file.size), isImage: imageTypes.includes(fileType) });
                    }, false);
                    $scope.upload(file, tempKey);

                }
            }
        };

        $scope.upload = function(file, tempKey) {
            if (file) {
                if (!file.$error) {
                    Upload.upload({
                        url: '/api/uploadmanager/document/upload',
                        data: {
                            docTypeId: parameters.document.uid
                        },
                        file: file,
                    }).then(function(result) {
                        if (result.data.status) {
                            toaster.pop('success', "File Uploaded", "you have successfully uploaded the file");
                            $scope.progressVisible[tempKey] = false
                            $scope.selectedFiles += 1
                            setMongooseId(result.data.data, tempKey);
                        } else {
                            removeFromSelected(tempKey)
                            toaster.pop('error', "Error", result.data.errors[0].message);
                        }
                    }, function(err) {

                    }, function(evt) {
                        $scope.progress[tempKey] = parseInt(100.0 * evt.loaded / evt.total);
                    });
                }
            }
        };



        $scope.selectFile = function(id) {
            const obj = _.find($scope.items, { _id: id });
            obj["isSelected"] = !obj["isSelected"];
            if (obj["isSelected"] === true) {
                $scope.selectedFiles += 1
            } else {
                $scope.selectedFiles -= 1
            }
        };

        $scope.onClose = function() {
            $mdDialog.hide();
        };

        $scope.onSubmit = () => {
            let fileIds = []
            let fileNames = []
            $scope.items.filter(file => file.isSelected).forEach(file => {
                fileIds.push(file._id)
                fileNames.push(file.name)
            });
            $mdDialog.hide({ fileIds, fileNames });
        };

        $scope.markInactive = (id, tempKey) => {
            let fileId = null;
            if (!id) {
                const file = $scope.items.filter((item) => {
                    return item.tempKey === tempKey
                })
                fileId = file[0]._id
            } else {
                fileId = id
            }
            fileService.markFileInactive(fileId).then((result) => {
                if (result.status) {
                    toaster.pop('success', "File Archived", "You have successfully archived the file");
                    removeFromSelected(fileId)
                } else {
                    toaster.pop('error', "Error", result.data.errors[0].message);
                }
            }).catch((err) => {
                toaster.pop('error', "Error", err.message);
            })
        }

        init();

    });