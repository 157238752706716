'use strict';
angular.module('bitnudgeApp')
    .directive('gpSlider', function ($timeout) {
        return {
            name: 'gpSlider',
            scope: {
                model:      "=",
                enable:     '=',

                onSlide:    '='
            },
            link: function(scope, elem, attrs) {
                scope.$watch('model', function(newValue, oldValue) {
                    $(elem).slider('value', scope.model)
                });
                scope.$watch('enable', function(newValue, oldValue) {
                    $(elem).slider({disabled: scope.enable});
                });


                $(elem).slider({
                    disabled: scope.enable,
                    value: scope.model,
                    range: false,
                    min: 0,
                    max: 150,
                    step: 0.01,
                    slide: function(event, ui) {
                        scope.$apply(function() {
                            scope.model = ui.value;
                        });

                        if(scope.onSlide) {
                            scope.onSlide();
                        }
                    }
                });
            },
            restrict: 'AE'
        };
    });

angular.module('bitnudgeApp')
    .directive('decimalPlaces',function(){
    return {
        link:function(scope,ele,attrs){
            ele.bind('keypress',function(e){
                var newVal=$(this).val()+(e.charCode!==0?String.fromCharCode(e.charCode):'');
                if($(this).val().search(/(.*)\.[0-9][0-9]/)===0 && newVal.length>$(this).val().length){
                    e.preventDefault();
                }
            });
        }
    };
});
