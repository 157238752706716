const autoImportNgTemplateLoaderTemplate1 = require('../html/scorecardKPIActivityInfo.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('activityIndicatorCardCtrl', function ($scope, p0, $state, $rootScope,performanceIndicatorService,$mdDialog) {
        $scope.unit = _.find(p0.allUIKpis, {_id:$scope.achievedData.kpiId}).unit;
        $scope.popover = {};
        $scope.isSeniorManager = $rootScope.isSeniorManager;

        var userId=$scope.user._id;
        var cycleId=$scope.selectedCycle._id;
        var jobRoleId=$scope.user.organizationalTag.jobRole._id;
        
        if ($scope.achievedData.kpiCode == "K00") {
            var metric="K00";
            var subMetric="achievedValue"
            performanceIndicatorService.fetchOverallKPIForFormula(userId,cycleId,jobRoleId,metric,subMetric).then(function (kpiData) {
                var jobRoleId=$scope.user.organizationalTag.jobRole._id;
                var metric="K00";
                var submeteric="achievedValue"
                $scope.kpiData = kpiData;
                })
        }

        $scope.showKPIActivities = function(kpi){
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'showKPIActivitiesController',
                clickOutsideToClose:true,
                fullscreen: true, // Only for -xs, -sm breakpoints.
                resolve: {
                    kpi: function(){
                        return kpi;
                    },
                    jobrole:function()
                    {
                        return $scope.user.organizationalTag.jobRole._id;
                    }
                    ,
                    userId:function()
                    {
                        return userId;
                    }
                    ,
                    cycleId:function()
                    {
                        return cycleId;
                    }
                }
            });
        };

        function compareWithBenchMarks() {
            if($scope.achievedData.call.value >= $scope.benchmarkValue.call)
                $scope.callStatus = 'green';
            else
                $scope.callStatus = 'red';
            $scope.popover.calls = 'Vs '+$scope.benchmark.name +' calls: '+$scope.benchmarkValue.call;
            if($scope.achievedData.submission.value >= $scope.benchmarkValue.submission)
                $scope.submissionStatus = 'green';
            else
                $scope.submissionStatus = 'red';
            $scope.popover.submission = 'Vs '+$scope.benchmark.name +' submissions: '+$scope.benchmarkValue.submission;
            if($scope.achievedData.meeting.value >= $scope.benchmarkValue.meeting)
                $scope.meetingStatus = 'green';
            else
                $scope.meetingStatus = 'red';
            $scope.popover.meeting = 'Vs '+$scope.benchmark.name +' meetings: '+$scope.benchmarkValue.meeting;
        }
        function setZone() {
            if($scope.achievedData.swot == 's'){
                $scope.zone = 'green';
            }else if($scope.achievedData.swot == 'o'){
                $scope.zone = 'blue';
            }else if($scope.achievedData.swot == 'w'){
                $scope.zone = 'amber';
            }else $scope.zone = 'red';
        }
        setZone();

        $scope.$watch('benchmarkValue',function(newValue){
           if(newValue){
               if($scope.lms) {
                   compareWithBenchMarks();
               }
           }
        });

        $scope.compareBenchMark = function(value,benchmark){
            if(benchmark == undefined || benchmark == null){return 'green';}
            if(value > benchmark){
                return 'green';
            }else{
                return 'red';
            }
        };
        $scope.navigateToLeads = function (activity, value) {
            if(!value) return;
            var params ={};
            var userId = '';
            if($scope.user.roleType != 'manager')
                userId = $scope.user._id;
            if(activity == 'new')
                params = {status : 'new', dateField: 'createdIn', kpiId:$scope.achievedData.kpiId, userId: userId, startDate:moment($scope.selectedCycle.startDate).valueOf(), endDate:moment($scope.selectedCycle.endDate).valueOf()};
            else if(activity == 'active')
                params = {status: 'active', dateField: 'createdIn', kpiId:$scope.achievedData.kpiId, userId: userId, startDate:moment($scope.selectedCycle.startDate).valueOf(), endDate:moment($scope.selectedCycle.endDate).valueOf()};
            else if(activity == 'followUpMissed'){
                var yday = new Date();
                yday = new Date(yday.setHours(23,59,59,999));
                yday = new Date(yday.setDate(yday.getDate() - 1));
                params = {status: 'followUpMissed', userId: userId,startDate:moment($scope.selectedCycle.startDate).valueOf(), endDate:moment(yday).valueOf()};
            }
            else if(activity == 'failed')
                params = {dateField: 'failed', kpiId:$scope.achievedData.kpiId};
            $state.go('lms.listLead', params);
        };
        $scope.goToLeadsWithDateFitler = function (count) {
            if(!count) return;
            var userId = '';
            if($scope.user.roleType != 'manager')
                userId = $scope.user._id;
            var startDate  = moment(new Date().setHours(0,0,0,0)).valueOf();
            var endDate  = moment(new Date().setHours(23,59,59,999)).valueOf();
            var params = {status:'followUpComing', kpiId:$scope.achievedData.kpiId, userId:userId, startDate: startDate, endDate:endDate};
            $state.go('lms.listLead', params);
        }
        
        $scope.toggleMyChild = (kpi, showChildren, isScoreCardEvent) => {
            $scope.toggleChild(kpi, showChildren, isScoreCardEvent);
        }
    });

