const autoImportNgTemplateLoaderTemplate1 = require('../libraries/game2048/game.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('game2048', function () {
        return {
            name: 'game2048',
            controller: 'game2048Ctrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
