const autoImportNgTemplateLoaderTemplate1 = require('../html/gpGraph.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('gpGraph', function () {
        return {
            name: 'gpGraph',
            scope: {
                goal:           '=',
                showValue:      '=',
                enableSlide:    '=',
                onSlide:        '=',
                save:           '=',
                cancel:         '='
            },
            controller: 'gpGraphCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
