const autoImportNgTemplateLoaderTemplate1 = require('../html/burnStatement.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('burnStatement', function(){
        return {
            name: 'burnStatement',
            scope: {
            },
            controller: 'burnStatementCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
