'use strict';
angular.module('bitnudgeApp')
    .controller('lmsComplianceReportCtrl', function ($scope, $http, Auth,  moment,$location, $rootScope, $filter, p0) {

        $scope.currUser=Auth.getCurrentUser();
        $scope.cycles = p0.allMonthlyCycles;
        $scope.selectedCycle=p0.currentMonthlyCycle;
        //kept for testing with lms data. The frontlineJobrole route doesn't work with LMS data
        $scope.jobroles=p0.frontLineJobRoles;
        $scope.selectedJobroleAbove=[];
        $scope.selectedJobroleBelow=[];
        $scope.setCalculatedDays=function(startDate,endDate){
            var totalDays=$rootScope.calcBusinessDays(startDate,endDate);
            $scope.daysLeft=$rootScope.calcBusinessDays($rootScope.lastUpdatedAtDate,endDate) + 1;
            $scope.daysPassed=totalDays-$scope.daysLeft;
            if($scope.daysPassed===0)
                $scope.daysPassed=1;
        };
        $scope.filterComplianceAbove=function(){
            var filters=$scope.selectedJobroleAbove;
            var concattedArray=[];
            if(filters.length==0){
                return $scope.complianceAbove.data=$scope.userComplianceAbove;
            }
            filters.forEach(function(filter){
                var filtered=_.filter($scope.userComplianceAbove,function(obj){
                    return obj.user.organizationalTag.jobRole&&obj.user.organizationalTag.jobRole._id.toString()==filter._id.toString();
                });
                concattedArray=concattedArray.concat(filtered);
            });
            $scope.complianceAbove.data=concattedArray;
        };
        $scope.filterComplianceBelow=function(){
            var filters=$scope.selectedJobroleBelow;
            var concattedArray=[];
            if(filters.length==0)
                return $scope.complianceBelow.data=$scope.userComplianceBelow;
            filters.forEach(function(filter){
                var filtered=_.filter($scope.userComplianceBelow,function(obj){
                    return obj.user.organizationalTag.jobRole&&obj.user.organizationalTag.jobRole._id.toString()==filter._id.toString();
                });
                concattedArray=concattedArray.concat(filtered);
            });
            $scope.complianceBelow.data=concattedArray;
        };
        //config data for the ui-grids
        $scope.complianceAbove = {
            //data: 'nonComplan.data',
            columnDefs: [{
                field: 'Agent',
                width: "13%",
                enableFiltering: false
            }, {
                field: 'Name'
            }, {
                field: 'Last Updated',
                width: "22%",
                enableFiltering: false
            }, {
                field: 'Compliance Percentage',
                enableFiltering: false
            }],
            enableGridMenu: true,
            enableSelectAll: true,
            enableFiltering: true,
            exporterCsvFilename: 'myFile.csv',
            exporterPdfDefaultStyle: {
                fontSize: 9
            },
            exporterPdfTableStyle: {
                margin: [30, 30, 30, 30]
            },
            exporterPdfTableHeaderStyle: {
                fontSize: 10,
                bold: true,
                italics: true,
                color: 'red'
            },
            exporterPdfHeader: {
                text: "My Header",
                style: 'headerStyle'
            },
            exporterPdfFooter: function(currentPage, pageCount) {
                return {
                    text: currentPage.toString() + ' of ' + pageCount.toString(),
                    style: 'footerStyle'
                };
            },
            exporterPdfCustomFormatter: function(docDefinition) {
                docDefinition.styles.headerStyle = {
                    fontSize: 22,
                    bold: true
                };
                docDefinition.styles.footerStyle = {
                    fontSize: 10,
                    bold: true
                };
                return docDefinition;
            },
            exporterPdfOrientation: 'portrait',
            exporterPdfPageSize: 'LETTER',
            exporterPdfMaxGridWidth: 500,
            exporterCsvLinkElement: angular.element(document.querySelectorAll(".custom-csv-link-location")),
            onRegisterApi: function(gridApi) {
                $scope.gridApi = gridApi;
            }
        };
        $scope.complianceBelow = {
            columnDefs: [{
                field: 'Agent',
                width: "13%",
                enableFiltering: false
            }, {
                field: 'Name'
            }, {
                field: 'Last Updated',
                width: "22%",
                enableFiltering: false
            }, {
                field: 'Compliance Percentage',
                enableFiltering: false
            }],
            enableGridMenu: true,
            enableSelectAll: true,
            enableFiltering: true,
            exporterCsvFilename: 'myFile.csv',
            exporterPdfDefaultStyle: {
                fontSize: 9
            },
            exporterPdfTableStyle: {
                margin: [30, 30, 30, 30]
            },
            exporterPdfTableHeaderStyle: {
                fontSize: 10,
                bold: true,
                italics: true,
                color: 'red'
            },
            exporterPdfHeader: {
                text: "My Header",
                style: 'headerStyle'
            },
            exporterPdfFooter: function(currentPage, pageCount) {
                return {
                    text: currentPage.toString() + ' of ' + pageCount.toString(),
                    style: 'footerStyle'
                };
            },
            exporterPdfCustomFormatter: function(docDefinition) {
                docDefinition.styles.headerStyle = {
                    fontSize: 22,
                    bold: true
                };
                docDefinition.styles.footerStyle = {
                    fontSize: 10,
                    bold: true
                };
                return docDefinition;
            },
            exporterPdfOrientation: 'portrait',
            exporterPdfPageSize: 'LETTER',
            exporterPdfMaxGridWidth: 500,
            exporterCsvLinkElement: angular.element(document.querySelectorAll(".custom-csv-link-location")),
            onRegisterApi: function(gridApi) {
                $scope.gridApi = gridApi;
            }
        };

        $scope.reports =['LMS Report'];
        $scope.selectedReport='LMS Report';

        $scope.$watch('selectedReport', function(oldValue, newValue){

            if(newValue){
                if($scope.selectedReport == 'LMS Report')
                    $scope.lms =true;
                else
                    $scope.lms =false;
            }

        })



        $scope.backUrl = function(){
            $rootScope.backUrl = '/userFlow/analytics';
            $rootScope.backFromLMSComplaince = true;
            $location.path($rootScope.backUrl);
        }
        $scope.getComplianceDataOfCycle($scope.selectedCycle);
    });
