/**
 * Deprecated Component
 */

'use strict';
angular.module('bitnudgeApp')
    .controller('cneKpiNetworkTabCtrl', function ($scope, $http, p0) {
        var currentCycle;
        var url;
        function init() {
            $scope.isMonthly = true;
            if($scope.isMonthly){
                $scope.cycleType = 'month';
                currentCycle = p0.currentMonthlyCycle;
            }
            else{
                $scope.cycleType = 'week';
                currentCycle = p0.currentWeeklyCycle;
            }
            if($scope.user == 'frontline') {
                if ($scope.type == 'overall') {
                    url = 'api/cne/networkUpdates/' + $scope.user + '/' + currentCycle._id + '/teamType/' + $scope.teamType;
                }
                else if ($scope.type == 'kpi') {
                    url = 'api/cne/networkUpdates/' + $scope.user + '/' + currentCycle._id + '/kpiId/' + $scope.kpi + '/teamType/' + $scope.teamType;
                }
            }
            else if($scope.user == 'manager'){
                if($scope.hideReportees){
                    if ($scope.type == 'overall') {
                        url = 'api/cne/networkUpdates/' + $scope.user + '/' + currentCycle._id + '/teamType/' + $scope.teamType;
                    }
                    else if ($scope.type == 'kpi') {
                        url = 'api/cne/networkUpdates/' + $scope.user + '/' + currentCycle._id + '/kpiId/' + $scope.kpi + '/teamType/' + $scope.teamType;
                    }
                }
                else{
                    if ($scope.type == 'overall') {
                        url = 'api/cne/networkUpdates/' + $scope.user + '/' + currentCycle._id + '/teamType/' + $scope.teamType + '/reporteeData/true';
                    }
                    else if ($scope.type == 'kpi') {
                        url = 'api/cne/networkUpdates/' + $scope.user + '/' + currentCycle._id + '/kpiId/' + $scope.kpi + '/teamType/' + $scope.teamType + '/reporteeData/true';
                    }
                }
            }
            else{
                if ($scope.type == 'overall') {
                    url = 'api/cne/networkUpdates/' + $scope.user + '/' + currentCycle._id + '/teamType/' + $scope.teamType + '/reporteeType/' + $scope.jobRole._id;
                }
                else if ($scope.type == 'kpi') {
                    url = 'api/cne/networkUpdates/' + $scope.user + '/' + currentCycle._id + '/kpiId/' + $scope.kpi + '/teamType/' + $scope.teamType  +  '/reporteeType/' + $scope.jobRole._id;
                }
            }
            //this route does not exists
            $http.post(url).then(function (data) {
                data=data.data;
                $scope.tabs = [];
                data.topRankers.forEach(function(user){
                    user.popoverData = data.hoverData[user.userId];
                    user.popoverData._id = user.userId;
                    user.placement = 'bottom';
                    user.link = "/userFlow/profile/"+user.userId;

                });
                data.topBadge.forEach(function(user){
                    user.popoverData = data.hoverData[user.userId];
                    user.popoverData._id = user.userId;
                    user.placement = 'top';
                    user.link = "/userFlow/profile/"+user.userId;

                });
                data.topDecile.forEach(function(user){
                    user.popoverData = data.hoverData[user.userId];
                    user.popoverData._id = user.userId;
                    user.placement = 'top';
                    user.link = "/userFlow/profile/"+user.userId;

                });
                data.improvingForm.forEach(function(user){
                    user.popoverData = data.hoverData[user.userId];
                    user.popoverData._id = user.userId;
                    user.placement = 'top';
                    user.link = "/userFlow/profile/"+user.userId;

                });
                data.topIncentives.forEach(function(user){
                    user.popoverData = data.hoverData[user.userId];
                    user.popoverData._id = user.userId;
                    user.placement = 'top';
                    user.link = "/userFlow/profile/"+user.userId;
                });

                //TODO: uncommnet when activityData is available
                //data.topCalls.forEach(function(user){
                //    user.content = getPopoverHTML(user.userId, data.hoverData);
                //    user.placement = 'top';
                //});
                //data.topSubmissions.forEach(function(user){
                //    user.content = getPopoverHTML(user.userId, data.hoverData);
                //    user.placement = 'top';
                //});

                //TODO: uncommnet when swot is available
                //data.strength.forEach(function(user){
                //    user.content = getPopoverHTML(user.userId, data.hoverData);
                //    user.placement = 'top';
                //
                //});
                //data.weakness.forEach(function(user){
                //    user.content = getPopoverHTML(user.userId, data.hoverData);
                //    user.placement = 'top';
                //
                //});

                //TODO:uncomment when social is available
                //if($scope.type == 'overall'){
                //    data.topLikes.forEach(function(user){
                //        user.content = getPopoverHTML(user.userId, data.hoverData);
                //        user.placement = 'top';
                //
                //    });
                //    data.topFollows.forEach(function(user){
                //        user.content = getPopoverHTML(user.userId, data.hoverData);
                //        user.placement = 'top';
                //
                //    });
                //    data.topTagged.forEach(function(user){
                //        user.content = getPopoverHTML(user.userId, data.hoverData);
                //        user.placement = 'top';
                //
                //    });
                //}
                $scope.tabs.push(
                    {
                        title:'Top Rankers',
                        users: data.topRankers,
                        showLink: '/userFlow/leaderboard'
                    },
                    {
                        title:"Top Badge achievers",
                        users:data.topBadge,
                        showLink: '/userFlow/leaderboard'
                    },
                    {
                        title:'Top Decile',
                        users:data.topDecile,
                        showLink: '/userFlow/leaderboard'
                    },
                    {
                        title:'Players with improving Form',
                        users:data.improvingForm
                    },
                    {
                        title: 'Top Incentive Achievers',
                        users: data.topIncentives,
                        showLink: '/userFlow/leaderboard'
                    }
                    //{
                    //    title: 'Most Calls',
                    //    users: data.topCalls,
                    //    showLink: '/userFlow/leaderboard'
                    //},
                    //{
                    //    title: 'Most Submissions',
                    //    users: data.topSubmissions,
                    //    showLink: '/userFlow/leaderboard'
                    //}
                );
                //if($scope.type == 'overall'){
                //    $scope.tabs.push(
                //        {
                //            title:"Top Likes",
                //            users: data.topLikes,
                //            showLink: '/userFlow/leaderboard'
                //
                //        },
                //        {
                //            title:"Top Follows",
                //            users: data.topFollows,
                //            showLink: '/userFlow/leaderboard'
                //        },
                //        {
                //            title:"Top Tags",
                //            users: data.topTagged,
                //            showLink: '/userFlow/leaderboard'
                //        }
                //    )
                //}
                $scope.table = {};
                $scope.table.headingLeft = "Strength";
                $scope.table.headingRight = "Weakness";
                //TODO: remove comment when swot is available
                /*
                 $scope.table.left = _.chain(data.kpiData).filter(function(kpi){ return kpi.swot == 's'}).map(function(kpi){return kpi.name});
                 $scope.table.right = _.chain(data.kpiData).filter(function(kpi){ return kpi.swot == 'w'}).map(function(kpi){return kpi.name});
                 */


            });
        }
        init();
        $scope.$watch('[teamType,isMonthly, hideReportees]',function(newValue,oldValue){
            if (newValue!=oldValue){
                init();
            }
        });
        $scope.$watch('jobRole',function(newValue,oldValue){
            if (newValue!=oldValue) {
                init();
            }
        });
    });

