const autoImportNgTemplateLoaderTemplate1 = require('../html/chartLegend.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('chartLegend', function () {
        return {
            name: 'chartLegend',
            scope: {
                series: '@',
                label: '@'
            },
            controller: 'chartLegendCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
