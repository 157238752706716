'use strict';
angular.module('bitnudgeApp')
    .service('LeaveMgt', function ($http ,p0, $q) {
        var futureLeaves = [];
        this.getReporteeData = function(){
              return $http.post('/api/users/getReporteeLeaveData').then(function(leaves){
                  leaves=leaves.data;
                  futureLeaves = [];
                  setOnLeaveToday(leaves);
                  return $q.resolve([leaves,futureLeaves]);
              });
        };

        function setOnLeaveToday(users) {
            var today = new Date(p0.currentDailyCycle.startDate);
            users.forEach(function(user){
                user.currentLeave = {};
                user.leaves.forEach(function(leave){
                    var startDate = new Date(leave.from);
                    var endDate = new Date(leave.to);
                    if(startDate <= today && endDate >=today){
                        user.onLeaveToday = true;
                    }
                    if(today <= endDate){
                        futureLeaves.push({
                            _id:user._id,
                            name:user.name,
                            leave:{
                                from:startDate,
                                to:endDate,
                                _id:leave._id
                            }
                        });
                    }
                });
            });
        }
    });
