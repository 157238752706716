angular.module('bitnudgeApp')
    .controller('addBulkActivityPointMultipliersCtrl', function ($http, $scope, Auth, Upload, $location, toaster) {
        $scope.fileType = "kaiPointsMultipliers";
        $scope.onFileSelectPointMultiplier = function () {
            if ($scope.bulkPoints == undefined) {
                toaster.pop('error', "Submission.", "Select File to Upload.");
            } else {
                var fileName = $scope.bulkPoints[0].name.split(".");
                var fileType = fileName[fileName.length - 1];
                if (fileType == "xls" || fileType == "xlsx" || fileType == "csv") {
                    Upload.upload({
                        url: '/api/upload',
                        data: {userId: Auth.getCurrentUser()._id,cronTag:'uploadActivityPointMultipliers',invocationMethod:"manual"},
                        file: $scope.bulkPoints[0],
                    }).then(function (data, status, headers, config) {
                        // file is uploaded successfully
                        toaster.pop('success', "Excel uploaded", "Weightage creation successfully finished.");
                    });
                } else {
                    toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' or '.csv' Types Only.");
                }
            }
        }
    });
