'use strict';
angular.module('bitnudgeApp')
    .directive('ngToggle', function () {
        return function (scope, element, attrs) {
            element.bind("click", function (event) {
                    scope.$apply(function (){
                        if(scope[attrs.ngToggle]){
                            scope[attrs.ngToggle] = false;
                        }
                        else {
                            scope[attrs.ngToggle] = true;
                        }
                    });
                    event.preventDefault();
            });
        };
    });
