const autoImportNgTemplateLoaderTemplate1 = require('../html/playerCommentModal.html');

'use strict';
angular.module('bitnudgeApp')
    .service('dailyFeed', function ($http ,Auth,p0,$rootScope,$q) {
        var _fetched = {mine: false, reportees: false, role: {}};
        var importantActivities = ['call','meeting','submission','leadCreation'];
        var achievementActivities = ['call','submission'];
        var that = this;
        that.fetchedLab = false;
        that['dailyFeeds'] = {jobRole: {}};

        var promises = []; // empty promises
        this.getFeed = function(user,jobrole,kpi,hideReportees, feedPeriod,isLive){
            var currentUser  = Auth.getCurrentUser();
            var defer = $q.defer();
            var feedObj;
            if((currentUser.roleType == 'frontline' || hideReportees) && currentUser.roleType != 'seniorManager') {
                feedObj = _.cloneDeep(that.dailyFeeds[currentUser._id]);
            }else if(user._id == 'all'){
                feedObj = _.cloneDeep(that.dailyFeeds.jobRole[jobrole]);
            }else{
                feedObj = _.cloneDeep(that.dailyFeeds[user._id]);
            }
            if(feedObj) {
                if (p0.myKpis.length == 1) {
                    var returnFeeds = feedObj.dailyFeed.filter(function (e, i, l) {
                        return e.feedPeriod == feedPeriod && e.kpiId == kpi;
                    });
                    var overallFeeds = feedObj.dailyFeed.filter(function(e,i,l){
                        return e.feedPeriod == feedPeriod && !e.kpiId && (e.type == 'badge' || e.type == 'badgeDelta' || e.type == 'teamLeaderBadge' || e.type == 'teamBadge'
                        || e.type == 'rank' || e.type == 'teamLeaderRank' || e.type == 'teamRank' || e.type == 'swot' || e.type == 'reporteeSWOT')
                    });
                    returnFeeds = returnFeeds.concat(overallFeeds);
                } else{
                    var returnFeeds = feedObj.dailyFeed.filter(function (e, i, l) {
                        return e.feedPeriod == feedPeriod && (kpi != undefined ? e.kpiId == kpi : true);
                    });
                    if (kpi == undefined) {
                        _.remove(returnFeeds, function (dailyFeed) {
                            if (dailyFeed.kpiId) {
                                return dailyFeed.subType != 'achievement';
                            } else {
                                return false;
                            }
                        });
                    }
                }
                if ((currentUser.roleType == 'frontline' || hideReportees) && currentUser.roleType != 'seniorManager') {
                    defer.resolve({feed:sortDailyFeed(returnFeeds),lab:that.getMyLabFeeds(feedObj.lab,currentUser._id,kpi,feedPeriod,isLive),labData:feedObj.lab});
                } else if(user._id == 'all'){
                    defer.resolve({feed:sortDailyFeedReportees(returnFeeds),lab:that.getMyLabFeeds(feedObj.lab,currentUser._id,kpi,feedPeriod,isLive),labData:feedObj.lab});
                }else{
                    defer.resolve({feed:sortDailyFeed(returnFeeds),lab:that.getMyLabFeeds(feedObj.lab,user._id,kpi,feedPeriod,isLive),labData:feedObj.lab});
                }
            }else{
                var url;
                let postData = {};
                if((currentUser.roleType == 'frontline' || hideReportees) && currentUser.roleType != 'seniorManager') {
                    url = '/api/dailyFeeds/batchFeed/user';
                    postData['userId'] = currentUser._id;
                }else if(user._id == 'all'){
                    url = '/api/dailyFeeds/batchFeed/jobroles';
                    postData['jobrole'] = jobrole;
                }else{
                    url = '/api/dailyFeeds/batchFeed/user';
                    postData['userId'] = user._id;
                }
                promises.push({defer:defer, kpi:kpi,feedPeriod:feedPeriod});
                if(promises.length <2) {
                    return $http.post(url, postData).then(function (result) {
                        result=result.data;
                        if ((currentUser.roleType == 'frontline' || hideReportees) && currentUser.roleType != 'seniorManager') {
                            that.dailyFeeds[currentUser._id] = result;
                        } else if(user._id == 'all'){
                            that.dailyFeeds.jobRole[jobrole] = result;
                        }else{
                            that.dailyFeeds[user._id] = result;
                        }
                        while(promises.length > 0){
                            var pData = promises.pop();
                            if (p0.myKpis.length == 1) {
                                var filteredFeed = result.dailyFeed.filter(function (e, i, l) {
                                    return e.feedPeriod == feedPeriod && e.kpiId == pData.kpi;
                                });
                                var overallFeeds = result.dailyFeed.filter(function(e,i,l){
                                    return e.feedPeriod == feedPeriod && !e.kpiId && (e.type == 'badge' || e.type == 'badgeDelta' || e.type == 'teamLeaderBadge' || e.type == 'teamBadge'
                                        || e.type == 'rank' || e.type == 'teamLeaderRank' || e.type == 'teamRank' || e.type == 'swot' || e.type == 'reporteeSWOT')
                                });
                                filteredFeed = filteredFeed.concat(overallFeeds);
                            } else{
                                var filteredFeed = result.dailyFeed.filter(function (e, i, l) {
                                    return e.feedPeriod == feedPeriod && (pData.kpi != undefined ? e.kpiId == pData.kpi : true);
                                });
                                if (pData.kpi == undefined) {
                                    _.remove(filteredFeed, function (dailyFeed) {
                                        if (dailyFeed.kpiId) {
                                            return dailyFeed.subType != 'achievement';
                                        } else {
                                            return false;
                                        }
                                    });
                                }
                            }
                            if ((currentUser.roleType == 'frontline' || hideReportees) && currentUser.roleType != 'seniorManager') {
                                pData.defer.resolve({feed:sortDailyFeed(filteredFeed),lab:that.getMyLabFeeds(result.lab,currentUser._id,pData.kpi,feedPeriod,isLive),labData:result.lab});
                            } else if(user._id == 'all'){
                                pData.defer.resolve({feed:sortDailyFeedReportees(filteredFeed),lab:that.getMyLabFeeds(result.lab,currentUser._id,pData.kpi,feedPeriod,isLive),labData:result.lab});
                            }else{
                                pData.defer.resolve({feed:sortDailyFeed(filteredFeed),lab:that.getMyLabFeeds(result.lab,user._id,pData.kpi,feedPeriod,isLive),labData:result.lab});
                            }
                        }
                    })
                }
            }
            return defer.promise;
        };

        this.getMyLabFeeds = function (labData,userId, kpi, feedPeriod,isLive) {
            var cycleId,salesCycleId;
            if(feedPeriod == 'daily'){
                if(isLive) {
                    cycleId = p0.currentDailyCycle._id;
                    salesCycleId = cycleId;
                }else{
                    cycleId = p0.previousDailyCycle._id;
                    salesCycleId = p0.currentDailyCycle._id;
                }
            }else if(feedPeriod == 'weekly'){
                cycleId = p0.currentWeeklyCycle._id;
                salesCycleId = cycleId;
            }else{
                cycleId = p0.currentMonthlyCycle._id;
                salesCycleId = cycleId;
            }
            if(labData[userId]){
                var myLabFeed = {};
                if(kpi)
                    var myLab = labData[userId][cycleId][kpi];
                else var myLab = labData[userId][cycleId]['overall'];
                myLabFeed.LAB = [
                    {
                        heading:'Calls, #',
                        val:myLab.calls,
                        last:getMinutesAgo()
                    },
                    {
                        heading:'Meetings, #',
                        val:myLab.meetings,
                        last:getMinutesAgo()
                    },
                    {
                        heading:'Submissions, #',
                        val:myLab.submissions,
                        last:getMinutesAgo()
                    },
                    {
                        heading:'Distance Travelled, Km',
                        val:myLab.distance,
                        last:getMinutesAgo()
                    },
                    {
                        heading:'Leads Assigned, #',
                        val:myLab.leadsAssigned,
                        last:getMinutesAgo()
                    },
                    {
                        heading:'Leads Created, #',
                        val:myLab.newLead,
                        last:getMinutesAgo()
                    },
                    {
                        heading:'Leads Lost, #',
                        val:myLab.leadsLost,
                        last:getMinutesAgo()
                    }
                ];

                var achievement = [];
                var selectedKpis = p0.myKpis;
                if(kpi){selectedKpis = _.filter(selectedKpis,{_id:kpi});}

                selectedKpis.forEach(function(kpi){
                    var kpi = [];
                    var sales = labData[userId][salesCycleId][kpi._id].sales.value;
                    sales = sales/ kpi.denom;
                    sales = sales.toFixed(2);
                    var delta = labData[userId][salesCycleId][kpi._id].sales.delta;
                    delta = delta/ kpi.denom;
                    delta = delta.toFixed(2);
                    kpi.push({value:sales,delta:delta,name:'SALES, '+ kpi.unit});
                    achievement.push({kpi:kpi,name:kpi.name});
                });
                myLabFeed.achievement = achievement;
                return myLabFeed;
            }
            else{
                return null;
            }
        };
        function sortDailyFeed(dailyFeed){
            var finalObj = {};
            var made = _.find(dailyFeed,{type:'gameplan',subType:'made'});
            var simulation = _.find(dailyFeed,{type:'gameplan',subType:'simulation'});
            var compliance = _.find(dailyFeed,{type:'compliance'});
            if(made || simulation || compliance) {
                finalObj.gameplan = {
                    made: made,
                    simulation: simulation,
                    compliance: compliance
                };
            }
            if(p0.myKpis.length == 1){
                var swots = _.filter(dailyFeed, {type: 'swot', subType: 'snapshot'});
                var swotDeltas = _.filter(dailyFeed,{type:'swot',subType:'delta'});
                var kpiSwot, kpiSwotDelta, activitySwot, activitySwotDelta;
                swots.forEach(function(swot){
                    if(swot.kpiId){
                        activitySwot = swot;
                    }else{
                        kpiSwot = swot;
                    }
                });
                swotDeltas.forEach(function(swot){
                    if(swot.kpiId){
                        activitySwotDelta = swot;
                    }else{
                        kpiSwotDelta = swot;
                    }
                });
                finalObj.kpiSwot = {
                    value: kpiSwot.value,
                    delta: kpiSwotDelta.value
                };
                finalObj.activitySwot = {
                    value: activitySwot.value,
                    delta: activitySwotDelta.value
                };
            }else {
                var swot = _.find(dailyFeed, {type: 'swot', subType: 'snapshot'}).value;
                var swotDelta = _.find(dailyFeed,{type:'swot',subType:'delta'}).value;
                if(swot.s || swot.o || swot.t || swot.w || swotDelta.s || swotDelta.o || swotDelta.t || swotDelta.w) {
                    finalObj.swot = {
                        value: swot,
                        delta: swotDelta
                    };
                }
            }



            finalObj.badge = _.filter(dailyFeed,{type:'badge'});
            finalObj.badgeDelta = _.filter(dailyFeed,{type:'badgeDelta'});
            finalObj.badgeTL = _.filter(dailyFeed,{type:'teamLeaderBadge'});
            finalObj.badgeT = _.filter(dailyFeed,{type:'teamBadge'});
            if(p0.myKpis.length == 1) {
                finalObj.overallRank = _.find(dailyFeed, {type: 'rank', subType: 'snapshot',kpiId:null});
                finalObj.overallRankTL = _.find(dailyFeed, {type: 'teamLeaderRank', subType: 'delta',kpiId:null});
                finalObj.overallRankT = _.find(dailyFeed, {type: 'teamRank', subType: 'delta',kpiId:null});
                finalObj.kpiRank = _.find(dailyFeed, {type: 'rank', subType: 'snapshot',kpiId:p0.myKpis[0]._id});
                finalObj.kpiRankTL = _.find(dailyFeed, {type: 'teamLeaderRank', subType: 'delta',kpiId:p0.myKpis[0]._id});
                finalObj.kpiRankT = _.find(dailyFeed, {type: 'teamRank', subType: 'delta',kpiId:p0.myKpis[0]._id});
            }else{
                finalObj.rank = _.find(dailyFeed, {type: 'rank', subType: 'snapshot'});
                finalObj.rankDelta = _.find(dailyFeed, {type: 'rank', subType: 'delta'});
                finalObj.rankTL = _.find(dailyFeed, {type: 'teamLeaderRank', subType: 'delta'});
                finalObj.rankT = _.find(dailyFeed, {type: 'teamRank', subType: 'delta'});
            }

            return finalObj;
        }

        function setUsersToBadgeHelper(filteredBadged) {
            var users = [];
            filteredBadged.forEach(function (perform) {
                perform.value.badges.forEach(function(badge){
                    users.push({_id:perform.userId._id ,name:perform.userId.name, badge:_.cloneDeep(badge), teams: perform.userId.teams, badgeName: badge.name});
                });
            });
            return users;
        }

        function sortDailyFeedReportees(dailyFeed){
            var finalObj = {};
            var made = _.filter(dailyFeed,{type:'gameplan',subType:'made'});
            var simulation = _.filter(dailyFeed,{type:'gameplan',subType:'simulation'});
            var compliance = _.filter(dailyFeed,{type:'compliance'});
            finalObj.gameplan = {
                made:made,
                simulation:simulation,
                compliance:compliance
            };
            var swot = _.filter(dailyFeed,{type:'reporteeSWOT',subType:'snapshot'});
            var swotDelta = _.filter(dailyFeed,{type:'reporteeSWOT',subType:'delta'});
            var s = 0, w = 0, o = 0, t = 0;
            var ds = 0, dt = 0;
            swot.forEach(function(swotFeed){
                s += swotFeed.value.s;
                w += swotFeed.value.w;
                o += swotFeed.value.o;
                t += swotFeed.value.t;
            });
            swotDelta.forEach(function(swotFeed){
                ds += swotFeed.value.s;
                dt += swotFeed.value.t;
            });
            finalObj.swot = {
                value:{s:s,w:w,o:o,t:t},
                delta:{s:ds,t:dt}
            };
            finalObj.swotUsers = swot;
            finalObj.swotDeltaUsers = swotDelta;
            var badges  = [];
            var badgeT = _.filter(dailyFeed,{type:'badge'});
            var performance = _.filter(badgeT,{subType:'performance'});
            var performanceValue = _.sumBy(performance,function(badge){
                return badge.value.number;
            });
            if(performance[0]) {
                performance[0].value.number = performanceValue;
                var users = setUsersToBadgeHelper(performance);
                performance[0].value.badges[0].users = performance;
                badges.push(performance[0]);
            }
            var level = _.filter(badgeT,{subType:'level'});
            var levelValue = _.sumBy(level,function(badge){
                return badge.value.number;
            });
            if(level[0]) {
                level[0].value.number = levelValue;
                var users = setUsersToBadgeHelper(level);
                level[0].value.badges[0].users = level;
                badges.push(level[0]);
            }
            var status = _.filter(badgeT,{subType:'status'});
            var statusValue = _.sumBy(status,function(badge){
                return badge.value.number;
            });
            if(status[0]) {
                status[0].value.number = statusValue;
                var users = setUsersToBadgeHelper(status);
                status[0].value.badges[0].users = status;
                badges.push(status[0]);
            }
            var productivity = _.filter(badgeT,{subType:'productivity'});
            var productivityValue = _.sumBy(productivity,function(badge){
                return badge.value.number;
            });
            if(productivity[0]) {
                productivity[0].value.number = productivityValue;
                var users = setUsersToBadgeHelper(productivity);
                productivity[0].value.badges[0].users = productivity;
                badges.push(productivity[0]);
            }
            var decileJump = _.filter(badgeT,{subType:'decileJump'});
            var decileJumpValue = _.sumBy(decileJump,function(badge){
                return badge.value.number;
            });
            if(decileJump[0]) {
                decileJump[0].value.number = decileJumpValue;
                var users = setUsersToBadgeHelper(decileJump);
                decileJump[0].value.badges[0].users = decileJump;
                badges.push(decileJump[0]);
            }
            var ytdBest = _.filter(badgeT,{subType:'ytdBest'});
            var ytdBestValue = _.sumBy(ytdBest,function(badge){
                return badge.value.number;
            });
            if(ytdBest[0]) {
                ytdBest[0].value.number = ytdBestValue;
                var users = setUsersToBadgeHelper(ytdBest);
                ytdBest[0].value.badges[0].users = ytdBest;
                badges.push(ytdBest[0]);
            }
            var conversion = _.filter(badgeT,{subType:'conversion'});
            var conversionValue = _.sumBy(conversion,function(badge){
                return badge.value.number;
            });
            if(conversion[0]) {
                conversion[0].value.number = conversionValue;
                var users = setUsersToBadgeHelper(conversion);
                conversion[0].value.badges[0].users = conversion;
                badges.push(conversion[0]);
            }
            var redFlag = _.filter(badgeT,{subType:'redFlag'});
            var redFlagValue = _.sumBy(redFlag,function(badge){
                return badge.value.number;
            });
            if(redFlag[0]) {
                redFlag[0].value.number = redFlagValue;
                var users = setUsersToBadgeHelper(redFlag);
                redFlag[0].value.badges[0].users = redFlag;
                badges.push(redFlag[0]);
            }
            finalObj.badgeT = badges;

            var rankDelta = _.filter(dailyFeed,{type:'rank',subType:'delta'});
            finalObj.rankDelta = {};
            finalObj.rankDelta.pos = _.filter(rankDelta,function(feed){return feed.value > 0});
            finalObj.rankDelta.neg = _.filter(rankDelta,function(feed){return feed.value <= 0});
            return finalObj;
        }

        this.social = function(action,dailyFeedData,type,messages){
            var currentUser = Auth.getCurrentUser();
            switch(action){
                case 'like':
                    if(dailyFeedData.userId._id == currentUser._id){
                        return;
                    }
                    if(dailyFeedData.value.badge.liked){
                        let postData = {
                            feedId: dailyFeedData._id,
                            userId: dailyFeedData.userId._id,
                            badgeId: dailyFeedData.value.badge.badgeId
                        }
                        return $http.post('/api/dailyFeeds/dislike', postData).then(function(){
                            dailyFeedData.value.badge.liked = false;
                        });
                    }else{
                        let postData = {
                            feedId: dailyFeedData._id,
                            userId: dailyFeedData.userId._id,
                            badgeId: dailyFeedData.value.badge.badgeId
                        }
                        return $http.post('/api/dailyFeeds/like', postData).then(function(){
                            dailyFeedData.value.badge.liked = true;
                        });
                    }
                    break;
                case 'comment':
                    if(dailyFeedData.userId._id == currentUser._id){
                        return;
                    }
                    if(!dailyFeedData.value.badge.commented){
                        $mdDialog.open({
                            templateUrl: autoImportNgTemplateLoaderTemplate1,
                            controller: 'playerCommentModalController',
                            size: 'md',
                            resolve: {
                                type:function(){
                                    return 'dailyFeed';
                                },
                                player: function () {
                                    return null;
                                },
                                feed:function(){
                                    return dailyFeedData;
                                },
								params:function(){
									return {}
								}
                            }
                        });
                    }
                    break;
                case 'bulkLike':
                return $http.post('/api/dailyFeeds/bulkLike',{data:dailyFeedData}).then(function(){
                        dailyFeedData.forEach(function(dailyFeed){
                            if(dailyFeed.userId._id == currentUser._id){
                                return;
                            }
                            dailyFeed.value.badges.forEach(function(badge){
                                badge.liked = true;
                            });
                        });
                        return $q.resolve(dailyFeedData);
                    });
                    break;
                case 'bulkComment':
                return $http.post('/api/users/addBulkComments',{data:messages}).then(function(){
                        var uniqueUsers = _.uniqBy(dailyFeedData,function(dailyFeed){
                            return dailyFeed.userId._id;
                        });
                        var totalBadges = _.sumBy(dailyFeedData,function(badgeFeed){
                            return badgeFeed.value.number;
                        });
                        var text;
                        if(type == 'badge'){
                            text = 'commented on ' + uniqueUsers.length + ' people for earning ' + totalBadges + ' badges.';
                        }else if(type == 'level'){
                            text = 'commented on ' + uniqueUsers.length + ' people for achieving new status.';
                        }else if(type == 'rank') {
                            text = 'commented on ' + uniqueUsers.length + ' people for their performance in leaderboard.';
                        }else if(type == 'delta'){
                            text = 'commented on ' + uniqueUsers.length + ' people for improving their rank.';
                        }else if(type == 'swot'){
                            text = 'commented on ' + uniqueUsers.length + ' people for their ' + $rootScope.strings.SWOT_FULL + ' status.';
                        }else if(type == 'compliance'){
                            text = 'commented on ' + uniqueUsers.length + ' people for their gameplan compliance.';
                        }else if(type == 'deltaDecile'){
                            text = 'commented on ' + uniqueUsers.length + ' people for their decile performance.';
                        }else if(type == 'form'){
                            text = 'commented on ' + uniqueUsers.length + ' people for their improving form in the game.';
                        }else if(type == 'redFlag'){
                            return;
                        }else if(type == 'MTD'){
                            text = 'commented on ' + uniqueUsers.length + ' people for their MTD.';
                        }
                        return $http.post('/api/statusMessages/false', {content:text,userId:currentUser._id, createdAt:new Date(),userTags:[]}).then(function() {
                            var feeds = [];
                            dailyFeedData.forEach(function(dailyFeed){
                                if(dailyFeed.userId._id == currentUser._id){
                                    return;
                                }
                                feeds.push({_id:dailyFeed._id,userId:dailyFeed.userId._id});
                            });
                            return $http.post('/api/dailyFeeds/bulkComment',{data:feeds}).then(function(){
                                dailyFeedData.forEach(function(dailyFeed){
                                    if(dailyFeed.userId._id == currentUser._id){
                                        return;
                                    }
                                    dailyFeed.value.badges.forEach(function(badge){
                                        badge.commented = true;
                                    });
                                });
                                return $q.resolve(true);
                            });
                        });
                    });
                    break;
            }
        };
        this.socialByBadge = function (action,dailyFeedData, badgeId, type,messages) {
            var currentUser = Auth.getCurrentUser();
            switch(action){
                case 'bulkLike':
                return $http.post('/api/dailyFeeds/bulkLikeByBadge',{feedIds:dailyFeedData, badgeId: badgeId}).then(function(){
                        dailyFeedData.forEach(function(dailyFeed){
                            if(dailyFeed.userId._id == currentUser._id){
                                return;
                            }
                            dailyFeed.value.badges.forEach(function(badge){
                                if (badge.badgeId == badgeId) {
                                    badge.liked=true;
                                    badge.likes.push(Auth.getCurrentUser()._id);
                                }
                            });
                        });
                        return $q.resolve(dailyFeedData);
                    });
                    break;
                case 'bulkComment':
                    return $http.post('/api/users/addBulkComments',{data:messages}).then(function(){
                        var uniqueUsers = _.uniqBy(dailyFeedData,function(dailyFeed){
                            return dailyFeed.userId._id;
                        });
                        var totalBadges = _.sumBy(dailyFeedData,function(badgeFeed){
                            return badgeFeed.value.number;
                        });
                        var text;
                        if(type == 'badge'){
                            text = 'commented on ' + uniqueUsers.length + ' people for earning ' + totalBadges + ' badges.';
                        }else if(type == 'level'){
                            text = 'commented on ' + uniqueUsers.length + ' people for achieving new status.';
                        }else if(type == 'rank') {
                            text = 'commented on ' + uniqueUsers.length + ' people for their performance in leaderboard.';
                        }else if(type == 'delta'){
                            text = 'commented on ' + uniqueUsers.length + ' people for improving their rank.';
                        }else if(type == 'swot'){
                            text = 'commented on ' + uniqueUsers.length + ' people for their ' + $rootScope.strings.SWOT_FULL + ' status.';
                        }else if(type == 'compliance'){
                            text = 'commented on ' + uniqueUsers.length + ' people for their gameplan compliance.';
                        }else if(type == 'deltaDecile'){
                            text = 'commented on ' + uniqueUsers.length + ' people for their decile performance.';
                        }else if(type == 'form'){
                            text = 'commented on ' + uniqueUsers.length + ' people for their improving form in the game.';
                        }else if(type == 'redFlag'){
                            return;
                        }else if(type == 'MTD'){
                            text = 'commented on ' + uniqueUsers.length + ' people for their MTD.';
                        }
                        return $http.post('/api/statusMessages/false', {content:text,userId:currentUser._id, createdAt:new Date(),userTags:[]}).then(function() {
                            var feeds = [];
                            dailyFeedData.forEach(function(dailyFeed){
                                if(dailyFeed.userId._id == currentUser._id){
                                    return;
                                }
                                feeds.push({_id:dailyFeed._id,userId:dailyFeed.userId._id});
                            });
                            return $http.post('/api/dailyFeeds/bulkCommentByBadge',{feedIds:dailyFeedData, badgeId: badgeId}).then(function(){
                                dailyFeedData.forEach(function(dailyFeed){
                                    if(dailyFeed.userId._id == currentUser._id){
                                        return;
                                    }
                                    dailyFeed.value.badges.forEach(function(badge){
                                        if (badge.badgeId == badgeId) {
                                            badge.commented = true;
                                            if (badge.comments) {
                                                badge.comments.push(Auth.getCurrentUser()._id);
                                            }
                                            else {
                                                badge.comments = [Auth.getCurrentUser()._id];
                                            }
                                        }
                                    });
                                });
                                return $q.resolve(dailyFeedData);
                            });
                        });
                    });
                    break;
            }
        };
        this.postComment = function(feed,comment){
            var reqBody,text;
            if(feed.type == 'badge'){
                reqBody = {comment: comment + ' (From: Badge Updates: '+feed.value.badge.name +' badge)'};
                text = 'commented on \u200C<a href = "userFlow/profile/'+feed.userId._id+'">'+feed.userId.name+'</a>\u200C for earning ' + feed.value.desc + ' badge: ' + comment;
            }else if(feed.type == 'level'){
                reqBody = {comment: comment + ' (From: Level Updates: '+feed.value.name +' status)'};
                text = 'commented on \u200C<a href = "userFlow/profile/'+feed.userId._id+'">'+feed.userId.name+'</a>\u200C for achieving ' + feed.value.name + ' badge: ' + comment;
            }else if(feed.type == 'rank'){
                reqBody = {comment: comment + ' (From: Rank Updates: '+feed.value +' rank)'};
                text = 'commented on \u200C<a href = "userFlow/profile/'+feed.userId._id+'">'+feed.userId.name+'</a>\u200C for achieving ' + feed.value + ' rank: ' + comment;
            }else if(feed.type == 'delta'){
                reqBody = {comment: comment + ' (From: Rank Improvements: '+feed.value +' ranks jumped)'};
                text = 'commented on \u200C<a href = "userFlow/profile/'+feed.userId._id+'">'+feed.userId.name+'</a>\u200C for improving their rank: ' + comment;
            }else if(feed.type == 'swot'){
                reqBody = {comment: comment + ' (From: ' + $rootScope.strings.SWOT_FULL + ' Updates)'};
                text = 'commented on \u200C<a href = "userFlow/profile/'+feed.userId._id+'">'+feed.userId.name+'</a>\u200C for improving their ' + $rootScope.strings.SWOT_FULL + ' status :' + comment;
            }else if(feed.type == 'compliance'){
                reqBody = {comment: comment + ' (From: Compliance Updates)'};
                text = 'commented on \u200C<a href = "userFlow/profile/'+feed.userId._id+'">'+feed.userId.name+'</a>\u200C for improving their gameplan compliance :' + comment;
            }else if(feed.type == 'deltaDecile'){
                reqBody = {comment: comment + ' (From: Decile Updates)'};
                text = 'commented on \u200C<a href = "userFlow/profile/'+feed.userId._id+'">'+feed.userId.name+'</a>\u200C for improving their decile :' + comment;
            } else if(feed.type == 'redFlag'){
                reqBody = {comment: comment + ' (From: '+$rootScope.strings.SWOT_T.value+' Flag Updates)'};
                text = 'commented on \u200C<a href = "userFlow/profile/'+feed.userId._id+'">'+feed.userId.name+'</a>\u200C for getting a '+$rootScope.strings.SWOT_T.value+'flag :' + comment;
            } else if(feed.type == 'form'){
                reqBody = {comment: comment + ' (From: Form Updates)'};
                text = 'commented on \u200C<a href = "userFlow/profile/'+feed.userId._id+'">'+feed.userId.name+'</a>\u200C for their improving form in the game :' + comment;
            }
            reqBody['userId'] = feed.userId._id;
            return $http.post('/api/users/comments', reqBody).then(function(){
                var message = {content:text, userId:currentUser._id, createdAt:new Date(),userTags:[feed.userId._id]};
                return $http.post('/api/statusMessages/false', message).then(function() {
                    return $http.post('/api/dailyFeeds/comment', {feedId: feed._id, badgeId: feed.value.badge._id}).then(function(){
                        feed.commented = true;
                    })
                });
            });
        };

        this.initOwnFeed = function(feeds,cycle){
            var filteredFeeds;
            if(cycle.name == 'This Month'){
                filteredFeeds = _.filter(feeds, function(feed){
                    return feed.date > p0.currentMonthlyCycle.startDate &&  feed.date < p0.currentMonthlyCycle.endDate;
                });
            }else if(cycle.name == 'Yesterday'){
                var yesterday = new Date($rootScope.lastUpdatedAtDate);
                yesterday.setDate(yesterday.getDate() - 1);
                filteredFeeds = _.filter(feeds, function(feed){
                    var feedDate = new Date(feed.date);
                    return feedDate > yesterday;
                });
            }else if(cycle.name == 'This Week'){
                filteredFeeds = _.filter(feeds, function(feed){
                    return feed.date > p0.currentWeeklyCycle.startDate &&  feed.date < p0.currentWeeklyCycle.endDate;
                });
            }else if(cycle.name == 'Last Month'){
                filteredFeeds = _.filter(feeds, function(feed){
                    return feed.date > p0.previousMonthlyCycle.startDate &&  feed.date < p0.previousMonthlyCycle.endDate;
                });
            }
            filteredFeeds =  _.groupBy(filteredFeeds,function(feed){
                var date = new Date(feed.date);
                date.setHours(0,0,0,0);
                return date;
            });
            var feedData = [];
            _.forOwn(filteredFeeds, function(value, key) {
                if(currentUser.systemRole == 'frontline') {
                    var ownFeed = _.filter(value, function (feed) {
                        return feed.userId._id == currentUser._id
                    });
                    var managerFeed = _.filter(value, function (feed) {
                        return feed.userId._id == currentUser.parent;
                    });
                    _.remove(value, function (feed) {
                        return feed.userId._id == currentUser._id || feed.userId._id == currentUser.parent;
                    });
                    var leadData = getDailyFeedsLeadData(ownFeed);
                    var incentiveData = getDailyFeedsIncentiveData(ownFeed);
                    var swotData = getDailyFeedSwotData(ownFeed);
                    var gameplanData = getDailyFeedGameplanData(ownFeed);
                    var gameData = getDailyFeedGameData(ownFeed);
                    var managerData = getDailyFeedGameData(managerFeed);
                    var teamData = getDailyFeedGameDataForTeam(value);
                }else{
                    var MTDData = getDailyFeedMTDData(value);
                    var callData = getDailyFeedCallData(value);
                    var submissionData = getDailyFeedSubmissionData(value);
                    var gameplanData = getDailyFeedGameplanData(value);
                    var badgeData = getDailyFeedBadgeData(value);
                    var socialData = getDailySocialData(value);
                    var DailyData = [];
                    p0.allKpis.forEach(function(kpi){
                        var selectedMTD = _.find(MTDData,{kpiId:kpi._id});
                        var selectedCall = _.find(callData,{kpiId:kpi._id});
                        var selectedSubmission = _.find(submissionData,{kpiId:kpi._id});
                        if(selectedMTD || selectedCall){
                            DailyData.push({kpi:kpi.name, MTDData:selectedMTD, callData: selectedCall, submissionData:selectedSubmission});
                        }
                    });
                }
                var date = new Date(key);
                feedData.push({
                    leadData:leadData,
                    incentiveData:incentiveData,
                    swotData:swotData,
                    gameplanData:gameplanData,
                    gameData:gameData,
                    managerData:managerData,
                    teamData:teamData,
                    DailyData:DailyData,
                    socialData:socialData,
                    badgeData:badgeData,
                    date:date
                });
            });
            feedData = _.sortBy(feedData,function(feed){return -feed.date;});
            return feedData;
        };

        function getDailyFeedsLeadData(feeds) {
            var assign = 0, followUp = 0;
            var feeds = _.filter(feeds,{type:'lead'});
            feeds = _.groupBy(feeds,'subType');
            if(feeds['follow']){
                feeds['follow'] = _.uniqBy(feeds['follow'],'entityId');
            }
            if(feeds['assign'] || feeds['followUp'] || feeds['lost'] || feeds['follow']) {
                return {
                    assign: feeds['assign']?feeds['assign'].length:feeds['assign'],
                    followUp:feeds['followUp']?feeds['followUp'].length:feeds['followUp'],
                    lost:feeds['lost']? feeds['lost'].length:feeds['lost'],
                    follow:feeds['follow']? feeds['follow'].length:feeds['follow']
                };
            }
        }

        function getDailyFeedsIncentiveData(feeds){
            var incentive = _.find(feeds,{type:'incentive'});
            return incentive;
        }

        function getDailyFeedSwotData(feeds){
            var swot = _.find(feeds,function(feed){
                return feed.type == 'swot';
            });
            return swot;
        }
        function getDailyFeedGameplanData(feeds){
            var gameplan = _.find(feeds,function(feed){
                return (feed.type == 'gameplan' && feed.subType == 'weekly');
            });
            var compliance = _.find(feeds, function(feed){
                return feed.type == 'compliance';
            });
            if(gameplan || compliance) {
                return {
                    gameplan: gameplan,
                    compliance: compliance
                };
            }
        }

        function getDailyFeedGameData(feeds){
            var delta = _.filter(feeds,function(feed){
                return feed.type == 'delta';
            });
            var daily = _.find(delta,{subType:'daily'});
            var weekly = _.find(delta,{subType:'weekly'});
            var monthly = _.find(delta,{subType:'monthly'});

            var apDelta = _.filter(feeds,function(feed){
                return feed.type == 'apDelta';
            });

            var apDaily = _.find(apDelta,{subType:'daily'});
            var apWeekly = _.find(apDelta,{subType:'weekly'});
            var apMonthly = _.find(apDelta,{subType:'monthly'});

            var bpDelta = _.filter(feeds,function(feed){
                return feed.type == 'bpDelta';
            });

            var bpDaily = _.find(bpDelta,{subType:'daily'});
            var bpWeekly = _.find(bpDelta,{subType:'weekly'});
            var bpMonthly = _.find(bpDelta,{subType:'monthly'});

            var allBadges = _.filter(feeds,{type:'badge'});
            var activityBadges = _.filter(allBadges,function(feed){
                return feed.value.badgeType == 'activity';
            });
            var performanceBadges = _.filter(allBadges,function(feed){
                return feed.value.badgeCategory == 'business' && feed.value.badgeType == 'kpi';
            });
            var excellenceBadges = _.filter(allBadges,function(feed){
                return feed.value.badgeCategory == 'overall' &&  feed.value.badgeType == 'total';
            });
            var impactPointBadges  = _.filter(allBadges, function(feed){
                return feed.value.badgeCategory == 'overall' && feed.value.badgeType == 'overallBusiness';
            });
            var activityPointBadges  = _.filter(allBadges, function(feed){
                return feed.value.badgeCategory == 'overall' && feed.value.badgeType == 'overallActivity';
            });
            var levelBadges = _.filter(allBadges,function(feed){
                return feed.value.badgeType == 'level';
            });
            var conversionBadges = _.filter(allBadges,function(feed){
                return feed.value.badgeCategory == 'conversion';
            });
            var productivityBadges = _.filter(allBadges,function(feed){
                return feed.value.badgeType == 'productivity';
            });

            var comments = _.filter(feeds,{type:'comment'});
            var likes = _.filter(feeds,{type:'like'});
            var levelExpire = _.find(feeds,{type:'levelExpire'});
            if(daily || weekly || monthly || apDaily || apMonthly || apWeekly || bpDaily || bpWeekly || bpMonthly || allBadges.length || comments.length || likes.length || levelExpire) {
                return {
                    daily: daily,
                    weekly: weekly,
                    monthly: monthly,
                    apDaily: apDaily,
                    apWeekly: apWeekly,
                    apMonthly: apMonthly,
                    bpDaily: bpDaily,
                    bpWeekly: bpWeekly,
                    bpMonthly: bpMonthly,
                    activityBadges: activityBadges,
                    performanceBadges: performanceBadges,
                    excellenceBadges: excellenceBadges,
                    impactPointBadges: impactPointBadges,
                    activityPointBadges: activityPointBadges,
                    levelBadges:levelBadges,
                    conversionBadges:conversionBadges,
                    productivityBadges:productivityBadges,
                    comments:comments,
                    likes:likes,
                    levelExpire:levelExpire
                };
            }
        }

        function getDailyFeedGameDataForTeam(feeds){
            var delta = _.filter(feeds,function(feed){
                return feed.type == 'delta';
            });
            var daily = _.filter(delta,{subType:'daily'});
            var weekly = _.filter(delta,{subType:'weekly'});
            var monthly = _.filter(delta,{subType:'monthly'});

            var apDelta = _.filter(feeds,function(feed){
                return feed.type == 'apDelta';
            });

            var apDaily = _.filter(apDelta,{subType:'daily'});
            var apWeekly = _.filter(apDelta,{subType:'weekly'});
            var apMonthly = _.filter(apDelta,{subType:'monthly'});

            var bpDelta = _.filter(feeds,function(feed){
                return feed.type == 'bpDelta';
            });

            var bpDaily = _.filter(bpDelta,{subType:'daily'});
            var bpWeekly = _.filter(bpDelta,{subType:'weekly'});
            var bpMonthly = _.filter(bpDelta,{subType:'monthly'});

            var allBadges = _.filter(feeds,{type:'badge'});
            var activityBadges = _.filter(allBadges,function(feed){
                return feed.value.badgeType == 'activity';
            });
            var performanceBadges = _.filter(allBadges,function(feed){
                return feed.value.badgeCategory == 'business' && feed.value.badgeType == 'kpi';
            });
            var excellenceBadges = _.filter(allBadges,function(feed){
                return feed.value.badgeCategory == 'overall' &&  feed.value.badgeType == 'total';
            });
            var impactPointBadges  = _.filter(allBadges, function(feed){
                return feed.value.badgeCategory == 'overall' && feed.value.badgeType == 'overallBusiness';
            });
            var activityPointBadges  = _.filter(allBadges, function(feed){
                return feed.value.badgeCategory == 'overall' && feed.value.badgeType == 'overallActivity';
            });
            var levelBadges = _.filter(allBadges,function(feed){
                return feed.value.badgeType == 'level';
            });
            var conversionBadges = _.filter(allBadges,function(feed){
                return feed.value.badgeCategory == 'conversion';
            });
            var productivityBadges = _.filter(allBadges,function(feed){
                return feed.value.badgeType == 'productivity';
            });


            var comments = _.filter(feeds,{type:'comment'});
            var likes = _.filter(feeds,{type:'like'});

            if(daily.length || weekly.length || monthly.length || apDaily.length || apMonthly.length || apWeekly.length || bpDaily.length || bpWeekly.length || bpMonthly.length || allBadges.length || comments.length || likes.length ) {
                return {
                    daily: daily,
                    weekly: weekly,
                    monthly: monthly,
                    apDaily: apDaily,
                    apWeekly: apWeekly,
                    apMonthly: apMonthly,
                    bpDaily: bpDaily,
                    bpWeekly: bpWeekly,
                    bpMonthly: bpMonthly,
                    activityBadges: activityBadges,
                    performanceBadges: performanceBadges,
                    excellenceBadges: excellenceBadges,
                    impactPointBadges: impactPointBadges,
                    activityPointBadges: activityPointBadges,
                    levelBadges:levelBadges,
                    conversionBadges:conversionBadges,
                    productivityBadges:productivityBadges,
                    comments:comments,
                    likes:likes
                };
            }
        }

        function getDailyFeedMTDData(feeds){
            var allMTDData = _.filter(feeds,{type:'MTD'});
            if(allMTDData.length > 0){
                var MTDData = [];
                allMTDData = _.groupBy(allMTDData,'kpiId');
                _.forOwn(allMTDData, function(value, key) {
                    var selectedKpi = p0.getKpi(key);
                    MTDData.push({
                        kpi:selectedKpi.name,
                        kpiId:selectedKpi._id,
                        MTD:value[0].value,
                        percentage:Number(value[0].subType)
                    });
                });
                return MTDData;
            }
        }

        function getDailySocialData(feeds){
            var comments = _.filter(feeds,{type:'comment'});
            var likes = _.filter(feeds,{type:'like'});
            if(comments.length || likes.length){
                return {
                    comments:comments,
                    likes:likes
                }
            }
        }

        function getDailyFeedBadgeData(feeds){
            var allBadges = _.filter(feeds,{type:'badge'});
            var activityBadges = _.filter(allBadges,function(feed){
                return feed.value.badgeType == 'activity';
            });
            var performanceBadges = _.filter(allBadges,function(feed){
                return feed.value.badgeCategory == 'business' && feed.value.badgeType == 'kpi';
            });
            var excellenceBadges = _.filter(allBadges,function(feed){
                return feed.value.badgeCategory == 'overall' &&  feed.value.badgeType == 'total';
            });
            var impactPointBadges  = _.filter(allBadges, function(feed){
                return feed.value.badgeCategory == 'overall' && feed.value.badgeType == 'overallBusiness';
            });
            var activityPointBadges  = _.filter(allBadges, function(feed){
                return feed.value.badgeCategory == 'overall' && feed.value.badgeType == 'overallActivity';
            });
            var levelBadges = _.filter(allBadges,function(feed){
                return feed.value.badgeType == 'level';
            });
            var conversionBadges = _.filter(allBadges,function(feed){
                return feed.value.badgeCategory == 'conversion';
            });
            var productivityBadges = _.filter(allBadges,function(feed){
                return feed.value.badgeType == 'productivity';
            });
            if(allBadges.length){
                return {
                    activityBadges: activityBadges,
                    performanceBadges: performanceBadges,
                    excellenceBadges: excellenceBadges,
                    impactPointBadges: impactPointBadges,
                    activityPointBadges: activityPointBadges,
                    levelBadges:levelBadges,
                    conversionBadges:conversionBadges,
                    productivityBadges:productivityBadges
                }
            }
        }

        function getDailyFeedCallData(feeds){
            var allCallData = _.filter(feeds,{type:'call'});
            if(allCallData.length > 0){
                var callData = [];
                allCallData = _.groupBy(allCallData,'kpiId');
                _.forOwn(allCallData, function(value, key) {
                    var selectedKpi = p0.getKpi(key);
                    callData.push({
                        kpi:selectedKpi.name,
                        kpiId:selectedKpi._id,
                        calls:value.value * currentUser.children.length,
                    });
                });
                return callData;
            }
        }

        function getDailyFeedSubmissionData(feeds){
            var allSubmissionData = _.filter(feeds,{type:'submission'});
            if(allSubmissionData.length > 0){
                var submissionData = [];
                allSubmissionData = _.groupBy(allSubmissionData,'kpiId');
                _.forOwn(allSubmissionData, function(value, key) {
                    var selectedKpi = p0.getKpi(key);
                    submissionData.push({
                        kpi:selectedKpi.name,
                        kpiId:selectedKpi._id,
                        submissions:value.value * currentUser.children.length,
                    });
                });
                return submissionData;
            }
        }
        function getRandomValue(){
            return Math.floor((Math.random() * 100) + 1)/ 10;
        }
        function getMinutesAgo() {
            return Math.floor((Math.random() * 50) + 1);
        }

        this.destroy = function () {
            that.fetchedLab = false;
            that['dailyFeeds'] = {jobRole: {}};
            _fetched = {mine: false, reportees: false, role: {}}
        };
    });


