const autoImportNgTemplateLoaderTemplate1 = require('../html/playerCommentModal.html');

'use strict';
angular.module('bitnudgeApp')
    .service('social', function (toaster, Auth, $http, p1, $mdDialog) {
        var currentUser = Auth.getCurrentUser();

        var like = function(player, bulkFlag) {
            if(player._id == currentUser._id) {
                toaster.pop('error', "Like", "You cannot Like yourself");
                return;
            }
            if(p1.userData[player._id].liked){
                return $http.post('/api/users/dislike', {userId:player._id}).then(function(){
                    player.likes--;
                    player.liked=false;
                    p1.userData[player._id].liked = false;
                    p1.userData[player._id].likes--;
                    //toaster.pop('success', "Like", "You cancelled your Like for "+player.userId.name);
                });
            }else {
                return $http.post('/api/users/likes',{userId: player._id, bulk:bulkFlag}).then(function(){
                    player.likes++;
                    player.liked=true;
                    p1.userData[player._id].liked = true;
                    p1.userData[player._id].likes++;
                });
            }
        };

        var follow = function(player) {
            if(player._id == currentUser._id) {
                toaster.pop('error', "Follow", "You cannot Follow yourself");
                return;
            }
            if(p1.userData[player._id].followed){
                return $http.post('/api/users/unfollowUser/'+player._id).then(function(){
                    player.followers--;
                    player.followed=false;
                    p1.userData[player._id].followed = false;
                    p1.userData[player._id].followers--;
                    //toaster.pop('success', "Follow", "You are now 'Un-following' "+player.name);
                });
            }else {
                return $http.post('/api/users/followUser/'+player._id).then(function(){
                    player.followers++;
                    player.followed=true;
                    p1.userData[player._id].followed = true;
                    p1.userData[player._id].followers++;
                    //toaster.pop('success', "Follow", "You are now 'Following' "+player.name);
                });
            }
        };

        var comment = function(player, cycleId) {
            if(player._id == currentUser._id) {
                toaster.pop('error', 'Comment' , "You cannot Comment on yourself");
                return;
            }
            showPlayerCommentModal(player, cycleId);
        };

        var showPlayerCommentModal = function(player, cycleId){
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'playerCommentModalController',
                size: 'md',
                resolve: {
                    type:function(){
                      return 'normal';
                    },
                    player: function () {
                        return player;
                    },
                    feed:function(){
                        return null;
                    },
					params:function(){
                    	return {cycleId:cycleId}
					}
                }
            });
        };

        this.postComment = function(player, comment, cycleId) {
            var reqBody = {comment: comment, userId: player._id};
            if(cycleId){
            	reqBody.cycleId = cycleId;
            }
            let url = '/api/users/comments'
            return $http.post(url, reqBody).then(function(response){
            	if(response.data && response.data.status){
					player.comments++;
					toaster.pop('success', "Comment", "Comment added successfully.");
				}else{
            		var message = "Failed to add the comment.";
            		if(response.data &&  response.data.errors && response.data.errors.length){
            			message = response.data.errors[0].message;
					}
					toaster.pop('info', "Comment", message);
				}
            });
        };

        var postBulkComment = function(player, comment) {
            player.forEach(function (playerData) {
                var reqBody = {};
                reqBody['comment'] = "Hey "+ playerData.user.name +" congrats on achieving "+ playerData.badge.name +" badge";
                reqBody['tag'] = playerData.badge.name;
                reqBody['userId'] = playerData.user._id;
                $http.post('/api/users/comments', reqBody);
            });
            toaster.pop('success', "Comment", "Your 'Comment' was posted successfully");
        };

        this.bulkSocialAction = function(action, playerList){
            var likesCount = 0;
            switch(action) {
                case "Like": playerList.forEach(function (player) {
                    if(!p1.userData[player.user._id].liked && player.user._id != currentUser._id) {
                        likesCount++;
                        var data = ' for your '+ player.badge.name+' badge';
                        like(player.user,data);
                    }
                }); toaster.pop('success', "Like", "You 'Liked' "+(likesCount>0?likesCount+" players":'all players already'));
                    break;
                case "Comment": postBulkComment(playerList);
                    break;
            }
        };

        this.socialAction = function(action, player, cycleId) {
            switch(action) {
                case "Like":    like(player,null); break;
                case "Follow":  follow(player); break;
                case "Comment": comment(player, cycleId); break;
            }
        };

    });
