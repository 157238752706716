'use strict';
angular.module('bitnudgeApp')
    .service('performanceBarometerService', function ($http ,$rootScope, Auth, gpSimulation, userAnalyticsService, p0, ForecastMultiplier, $q) {
        var dataFetched = false;
        var currentCycleId;
        var _this = this;

        _this.performanceBarometerFormatDataHelper = function (userId, userAnalytics, kpiId) {
            var performanceBarometer = {
                'level':[],
                'redFlags':[],
                'goFunnel': 'Last: 3 weeks ago', //TODO: Fetch this data
                'ranks' : [],
                'prevRanks' : [],
                'points': [],
                'prevPoints': [],
                'percentageCompleted':[],
                'formInGame' : 100,
                'gpCompliance':{},
                'gameplanPercent':[],
                'grade':[],
                'percentile':[]

            };
            _.forEach(userAnalytics, function (userAnalytic, index) {
                if(index == 0 && userAnalytic){ //Current cycle
                    var redFlags = _.filter(userAnalytic.badges, {badgeCategory:'redFlag'});
                    redFlags = _.filter(redFlags,{earned:true});
                    performanceBarometer['redFlags']=redFlags;
                    if(kpiId == 'overall' || p0.myKpis.length <2){
                        performanceBarometer['ranks'].push(userAnalytic.points.ranks);
                        performanceBarometer['ranks'].push(userAnalytic.businessPoints.ranks);
                        performanceBarometer['ranks'].push(userAnalytic.activityPoints.ranks);
                        if(userAnalytics[1]) performanceBarometer['prevRanks'].push(userAnalytics[1].points.ranks);
                        performanceBarometer['points'].push(userAnalytic.points.value);
                        performanceBarometer['points'].push(userAnalytic.businessPoints.value);
                        performanceBarometer['points'].push(userAnalytic.activityPoints.value);
                        if(userAnalytics[1])  {
                            performanceBarometer['prevPoints'].push(userAnalytics[1].points.value);
                            performanceBarometer['prevPoints'].push(userAnalytics[1].businessPoints.value);
                            performanceBarometer['prevPoints'].push(userAnalytics[1].activityPoints.value);
                        }
                        performanceBarometer['formInGame'] = userAnalytic.formInTheGame;
                        //performanceBarometer['gameplanPercent'].push({name:'Overall', _id:'overall', value:userAnalytic.gameplanPercent.value});
                        var gpNotSetCount = 0, status = true, laggingCount=0;
                        _.forEach(userAnalytic.kpiData, function (kpi) {
                            if(kpi.target.value == 0) return;
                            status = status && kpi.gameplanCompliant;
                            if(kpi.gameplanTarget.value == 0){ gpNotSetCount++;}
                            if(kpi.achievedValue.value * ForecastMultiplier.ofCycle(userAnalytic.cycleId) < kpi.target.value ){
                                laggingCount++;
                            }
                            //performanceBarometer['gameplanPercent'].push({_id:kpi.kpiId, name:kpi.name,value:kpi.gameplanPercent.value});
                        });
                        performanceBarometer['gpCompliance'] = {status:status, gpNotSetCount:gpNotSetCount, laggingCount:laggingCount};
                        performanceBarometer['incentive'] = userAnalytic.incentive.value;
                    }else{
                        var currentCycleKpi = _.find(userAnalytic.kpiData,{kpiId:kpiId});
                        if(userAnalytics[1]){ var prevCycleKpi = _.find(userAnalytics[1].kpiData,{kpiId:kpiId});}
                        var currentCycleActivity = _.find(userAnalytic.activityData,{kpiId:kpiId});
                        performanceBarometer['ranks'].push(currentCycleKpi.points.ranks);
                        performanceBarometer['ranks'].push(currentCycleActivity.points.ranks);
                        if(prevCycleKpi) performanceBarometer['prevRanks'].push(prevCycleKpi.points.ranks);
                        performanceBarometer['points'].push(currentCycleKpi.points.value);
                        performanceBarometer['points'].push(currentCycleActivity.points.value);
                        if(prevCycleKpi){
                            performanceBarometer['prevPoints'].push(prevCycleKpi.points.value);
                            performanceBarometer['prevPoints'].push(prevCycleKpi.points.value);
                        }
                        if(prevCycleKpi) performanceBarometer['prevPoints'].push(prevCycleKpi.points.value);
                        performanceBarometer['formInGame'] = currentCycleKpi.formInTheGame;
                        performanceBarometer['gpCompliance'] = currentCycleKpi.gameplanCompliant;
                        //performanceBarometer['gameplanPercent'].push({_id:currentCycleKpi.kpiId, name:currentCycleKpi.name, value:currentCycleKpi.gameplanPercent.value});
                        performanceBarometer['incentive'] = currentCycleKpi.incentive.value;
                    }
                    if (p0.config.showBasicScorecard) {
                        performanceBarometer.percentile = _.find(userAnalytic.activityData, function (activityData) {
                            return activityData.fieldName == "percentileRank" && !activityData.kpiId;
                        })
                        performanceBarometer.grade = _.find(userAnalytic.activityData, function (activityData) {
                            return activityData.fieldName == "grade" && !activityData.kpiId;
                        })
                    }
                }
                
                if(userAnalytic){
                    //inserting grade and overall percentile
                    if(kpiId == 'overall'){
                        performanceBarometer['percentageCompleted'].push(userAnalytic.percentageCompleted);
                    }else{
                        var currentCycleKpi = _.find(userAnalytic.kpiData,{kpiId:kpiId});
                        performanceBarometer['percentageCompleted'].push(currentCycleKpi.percentageCompleted);
                    }
                    performanceBarometer['level'].push(userAnalytic.level);
                }
            });

            if (!p0.config.showBasicScorecard) {
                //Get rolling/status badges
                return $http.post("/api/rollingBadges/users", {userId, cycleId: currentCycleId }).then(function (rollingBadgesEarned) {
                    rollingBadgesEarned = rollingBadgesEarned.data;
                    if (rollingBadgesEarned[0]) {
                        performanceBarometer['statusBadge'] = rollingBadgesEarned[0];
                    } else {
                        performanceBarometer['statusBadge'] = false;
                    }
                    var playerAheadKPI = kpiId;
                    if (p0.myKpis.length < 2) playerAheadKPI = 'overall';
                    if(userAnalytics[0]){
                        const postData = {
                            userId,
                            cycleId: userAnalytics[0].cycleId,
                            kpiId: playerAheadKPI
                        }
                        return $http.post('/api/analytics/getPlayerAhead', postData).then(function (playerAhead) {
                            playerAhead = playerAhead.data;
                            performanceBarometer['playerAhead'] = playerAhead;
                            return performanceBarometer;
                        });
                    }else{
                        return performanceBarometer;
                    }
                });
            }
            else {
                return performanceBarometer;
            }
        };

        _this.getPerformanceBarometer = function(userId, cycleIds, kpiId){
            var userAnalytics = [];
            currentCycleId = cycleIds[0];
            return userAnalyticsService.fetchUserAnalytics(userId, cycleIds[0]).then( function (userAnalytic) {
                if(!userAnalytic){
                    return _this.performanceBarometerFormatDataHelper(userId, userAnalytics, kpiId);
                }
                userAnalytics.push(userAnalytic);
                if(cycleIds[1]){
                    return userAnalyticsService.fetchUserAnalytics(userId, cycleIds[1]).then( function (preUserAnalytic) {
                        if(!preUserAnalytic){
                            return _this.performanceBarometerFormatDataHelper(userId, userAnalytics, kpiId);
                        }
                        userAnalytics.push(preUserAnalytic);
                        if(cycleIds[2]){
                            return userAnalyticsService.fetchUserAnalytics(userId, cycleIds[2]).then( function (prePreUserAnalytic) {
                                if(!prePreUserAnalytic){
                                    return _this.performanceBarometerFormatDataHelper(userId, userAnalytics, kpiId);
                                }
                                userAnalytics.push(prePreUserAnalytic);
                                return _this.performanceBarometerFormatDataHelper(userId, userAnalytics, kpiId);
                            });
                        }else {return _this.performanceBarometerFormatDataHelper(userId, userAnalytics, kpiId);}
                    });
                }else {return _this.performanceBarometerFormatDataHelper(userId, userAnalytics, kpiId);}
            });
        };

        this.destroy = function(){

        }
    });
