'use strict';
angular.module('bitnudgeApp')
    .controller('addBulkErrorWorkItemsCtrl', function($http, $scope, Auth, Upload, $location, toaster, $mdDialog, $rootScope) {

        $scope.toggleMin = function() {
            $scope.minDate = $scope.minDate ? null : new Date();
        };
        $scope.toggleMin();

        //opening a datepicker dialog box
        $scope.open = function($event) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened = true;
        };

        //Configration for Date picker.
        $scope.dateOptions = {
            formatYear: 'yy',
            modelDateFormat: "yyyy-MM-ddTHH:mm:ss",
            startingDay: 1
        };
        function getFileNameFormats(date, fileNameFormats, extension){
            var fileNames = [];
            var dateFormats = $rootScope.config.fileformat_date;
            var connectors =  $rootScope.config.fileformat_connector;
            var nameFormats = fileNameFormats;
            dateFormats.forEach(function(dateFormat){
                var dateFormat = moment(date).format(dateFormat);
                connectors.forEach(function(connector){
                    nameFormats.forEach(function(nameFormat){
                        var fileName = dateFormat + connector + nameFormat +'.'+extension;
                        fileNames.push(fileName);
                    })
                })
            });
            return fileNames;

        }

        $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];

        //Function to submit lead excel
        $scope.onFileSelectLead = function() {
            if($scope.asOfDate > new Date($rootScope.lastUpdatedAtDate)){
                var alert = $mdDialog.alert({
                    title: 'Attention',
                    textContent: 'The date you have selected is greater than Data uploaded date, please upload the KPI data first!',
                    ok: 'Close'
                });
                $mdDialog.show(alert).then(function() {alert = undefined;});
                return;
            }
            var fileFormats = getFileNameFormats($scope.asOfDate, ['BN-Mashreq-Error_workItems'], 'xlsx');
            if(!_.includes(fileFormats, $scope.excel[0].name)){
                var alert = $mdDialog.alert({
                    title: 'Attention',
                    textContent: 'You have selected wrong file or the file name is wrong, please check!',
                    ok: 'Close'
                });
                $mdDialog.show(alert).then(function() { alert = undefined;});
                return;
            }

            var asOfDate = $scope.asOfDate.getTime();
            if ($scope.excel == undefined) {
                toaster.pop('error', "Submission.", "Select File to Upload.");

            } else {
                var fileName = $scope.excel[0].name.split(".");
                var fileType = fileName[fileName.length - 1].toLowerCase();
                if (fileType == "xlsx" || fileType == "xlsm") {
                    Upload.upload({
                        url: '/api/individualTargets/importErrorWorkItems/excel',
                        data: {
                            userId: Auth.getCurrentUser()._id,
                            asOfDate: asOfDate
                        },
                        file: $scope.excel[0],
                        progress: function(e) {}
                    }).then(function(data, status, headers, config) {
                        // file is uploaded successfully
                        //$location.path('/lms/listLead');
                    });
                } else {
                    toaster.pop('error', "Submission.", "Upload '.xlsx' file only.");
                }

            }
        };
    })
