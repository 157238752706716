const autoImportNgTemplateLoaderTemplate1 = require('../html/addRemoveSeries.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('addRemoveSeries', function () {
        return {
            name: 'addRemoveSeries',
            scope: {
                indicator : '<',
                myIndicators :'<',
                addRemoveOptions:'<',
                disableAddSeries:'=',
                onAddRemoveOptionsChange : '='
            },
            controller: 'addRemoveSeriesCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
