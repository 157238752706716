"use strict";

angular.module("bitnudgeApp").controller("infoComponentCtrl", function($scope, Auth, DocumentService,toaster,$rootScope) {
	$scope.getCurrentUser = Auth.getCurrentUser;
	$scope.noInfoPresent = true
	this.$onChanges = changes => {
		if (changes.docId) {
			$scope.docId = changes.docId.currentValue;
			if($scope.docId){
				$scope.noInfoPresent = true
				getDocumentInfo($scope.docId)
			}else{
				$scope.noInfoPresent = true
			}
		}
	};
	const getDocumentInfo = (docId) => {
		if(docId){
			$scope.noInfoPresent = false
			DocumentService.getViewableElems({ docId })
					.then(response => {
						$scope.docMetadata = response;
					})
					.catch(err => {
						console.error(err.message);
						toaster.pop("error","Documention Information","Error in fetching document info")
					});
		}else{
			$scope.noInfoPresent = true
		}	
	}
	$rootScope.$on('dms:info:refresh',(evt, data)=>{
		getDocumentInfo($scope.docId)
	})
});
