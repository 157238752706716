'use strict';
angular.module('bitnudgeApp')
    .controller('autoUploadSchedulerCtrl', function($http, $scope, Auth, Upload, $location, toaster, $mdDialog, $rootScope, moment) {

    $scope.cronTime;
    $scope.scorecardDirectory;
    $scope.usersDirectory;
    $scope.leadsDirectory;
    $scope.params = {
        cronTime1:null,
        cronTime2:null,
    };

    $http.post('/api/config/fetchAutoUploadConfigs').then(function(result){
        result=result.data;
        $scope.autoUploadConfigs = _.cloneDeep(result.defaultConfigs);

        _.forEach(result.savedConfigs, (val, key) => {
            $scope.autoUploadConfigs[key] = val;
        });
    }).catch(function(err){
        console.log('Error while fetching configurations');
    })

    $scope.saveScheduler = function(){

        let url = '/api/config/updateAutoUploadConfigs';
        const method = 'post';
        $http[method]( url, {
            autoUploadConfigs: $scope.autoUploadConfigs
        }).then(function(result){            
            toaster.pop('success', "Updated Successfully!");
        }).catch(function(err){
            toaster.pop('error', "Error while updating");
        })
    }

})
