const autoImportNgTemplateLoaderTemplate1 = require('./../html/component/historyComponent.html');

"use strict";
angular.module("bitnudgeApp").component("historyComponent", {
	templateUrl: autoImportNgTemplateLoaderTemplate1,
	bindings: {
		document: "<",
		workflowId: "<"
	},
	controller: "historyComponentCtrl"
});
