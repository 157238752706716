'use strict';
angular.module('bitnudgeApp')
    .controller('scoreCardModalCtrl', function ($scope, $rootScope, user, kpi, $mdDialog, p0, userAnalyticsService, Auth, CurrentCycle) {
        $scope.strings = $rootScope.strings;
        $scope.lastSyncDate = $rootScope.lastUpdatedAtDate;
        var me = Auth.getCurrentUser();
        var kpiCode = _.find(p0.allKpis,{_id:kpi.kpiId}).kpiCode;
        var cardDetails = {};
        cardDetails['K01'] = [{id:'call',name:'Calls',type:'kai'}, {id:'newLead',name:'Bookings',type:'kai'},
            {id:'submission',name:'Submissions',type:'kai'},{id:'disbursal',name:'Disbursals',type:'kai'},{id:'supplementary',name:'Credit Cards (Supplementary)',type:'kai'}];
        cardDetails['K02'] = [{id:'newLead',name:'Leads Generated',type:'kai'}, {id:'submission',name:'Submissions',type:'kai'},
            {id:'disbursal',name:'Approvals',type:'kai'},{id:'incentives',name:'Incentives (MTD)',type:'incentive'}];
        cardDetails['K03'] = [{id:'call',name:'Calls',type:'kai'},{id:'newLead',name:'Leads Generated',type:'kai'},{id:'disbursal',name:'Sales',type:'kai'},
            {id:'utilization',name:'Dialer Utilization',type:'kai'}];
        cardDetails['K04'] = [{id:'call',name:'Calls',type:'kai'},{id:'newLead',name:'Leads Generated',type:'kai'},{id:'disbursal',name:'Sales',type:'kai'},
            {id:'utilization',name:'Dialer Utilization',type:'kai'}];
        cardDetails['K05'] = [{id:'newLead',name:'Leads Generated',type:'kai'}, {id:'disbursal',name:'Debits',type:'kai'},
            {id:'value',name:'Exceed Points',type:'kpi'},{id:'incentives',name:'Incentives (MTD)',type:'incentive'}];
        cardDetails['K06'] = [{id:'newLead',name:'Leads Generated',type:'kai'}, {id:'disbursal',name:'Debits',type:'kai'},
            {id:'value',name:'Exceed Points',type:'kpi'},{id:'incentives',name:'Incentives (MTD)',type:'incentive'}];
        $scope.cards = [];
        function init(){
            var cycle;
            if(CurrentCycle.cycle.active){
                cycle = CurrentCycle.cycle;
            }else{
                cycle = _.find(p0.allMonthlyCycles,{active:true, subType:CurrentCycle.cycle.subType});
            }
            userAnalyticsService.fetchUserAnalytics(user._id,cycle._id).then( function(userAnalytic){
                cardDetails[kpiCode].forEach(function(card){
                    var value, unit;
                    if(card.type == 'kai'){
                        value = getActivityValue(card.id, userAnalytic.activityData, kpi);
                        var act = _.find(userAnalytic.activityData,{fieldName:card.id});
                        if(!act.unit){
                            unit = act.activityType == 'value' ? '#' : '%';
                        }else{
                            unit = act.unit;
                        }
                    }else if(card.type == 'incentive'){
                        value = getIncentives(userAnalytic.kpiData, kpi);
                        unit = $scope.strings.INCENTIVE_UNIT.value;
                    }else if(card.type == 'kpi'){
                        value  = getKpiData(userAnalytic.kpiData, kpi, card.id);
                        var kpi = _.find(userAnalytic.kpiData,{kpiId : kpi.kpiId});
                        unit = (card.id == 'value' ? kpi.unit : '%');
                    }
                    $scope.cards.push({name:card.name, value: value, unit:unit});
                });
            });
        }
        function getActivityValue(fieldName, activityData, kpi){
            var value =0, act;
            act = _.find(activityData, function(act){
                if(act.kpiId){
                    return act.fieldName == fieldName && act.kpiId.toString() == kpi.kpiId.toString();
                }
            });
            if(act){
                value = act.value.value * (act.activityType == 'percentage' ? 100 : 1);
            }
            return value;
        }
        function getIncentives(kpiData, kpi) {
            var value = 0, kpi;
            kpi = _.find(kpiData, function(kpi){
                return kpi.kpiId.toString() == kpi.kpiId.toString();
            });
            if(kpi){
                value = kpi.incentive.value;
            }
            return value;
        }
        function getKpiData(kpiData, kpi, type){
            var value = 0, kpi;
            var kpi = _.find(kpiData, function(kpi){
                return kpi.kpiId.toString() == kpi.kpiId.toString();
            });
            if(type == 'value'){
                value = kpi.achievedValue.value/kpi.denom;
            }else{
                value = kpi.percentageCompleted.value;
            }
            return value;
        }
        $scope.onClose = function(){
            $mdDialog.hide();
        };
        var name = user.name;
        if(user._id == me._id){
            name = me.name;
        }
        $scope.header = name + ': '+ kpi.name + ' Scorecard';
        init();
    });
