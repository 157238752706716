'use strict';
angular.module('bitnudgeApp').controller('interactionHistoryController', function ($scope, $rootScope, _, $mdDialog, customer, p0, UserActivities, UtilService) {
    $scope.strings = p0.strings;
    let backupHistories = [];
    $scope.filter = {
        historyFor: 'all'
    }
    UserActivities.getInteractionHistory(customer.activeHandler, customer.cui).then(function(result){
        if(result.docs && result.docs.length)
        {
            $scope.histories = result.docs;
        }
        else{
            $scope.histories = []
        }
        if(customer.handlerHistory){
            customer.handlerHistory.forEach(h => {
                $scope.histories.push({
                    notes: h.notes,
                    createdAt:h.actionOn,
                    category: 'customer'
                })
            });
        }
        $scope.histories = _.orderBy($scope.histories, 'createdAt','desc')
        backupHistories = Object.assign([],$scope.histories);
    },function(error){
        console.log(error)
    })

 
    
    $scope.refilter = function(){
        if($scope.filter.historyFor === 'all'){
            $scope.histories = backupHistories;
        } else {
            $scope.histories = _.filter(backupHistories, function(history){
                return history.category == $scope.filter.historyFor
            })
        }
    };
//not used anywhere
    // $scope.download = function (path) {
    //     var appUrl = p0.config.domain;
    //     var subfolder = p0.config.subfolder;
    //     if(subfolder){
    //         if(appUrl[appUrl.length -1] !== '/'){
    //             appUrl += '/';
    //         }
    //         if(subfolder[0] == '/'){
    //             subfolder = subfolder.slice(1);
    //         }
    //         appUrl += subfolder;
    //     }
    //     UtilService.downloadFile(appUrl+path)
    // };

    $scope.cancel = function () {
        $mdDialog.hide();
    }
});