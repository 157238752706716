const autoImportNgTemplateLoaderTemplate1 = require('../html/swotModal.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('swotCtrl', function ($rootScope, $scope, $http, Auth, p0, SwotService, $mdDialog){
        let self=this;
        $scope.allKpis = p0.allKpis;
        $scope.me = Auth.getCurrentUser();
        //In case of quick view swot - history is saved inside swot directive
        //In Analytics swot case - history is saved in parent of swot directive
        $scope.history = [];
        $scope.switch = {isReportee:false,isKpiOverall:false};
        $scope.config = p0.config;
        $scope.params = {};
        let preUserId;

        if($scope.me.roleType === 'frontline'){
            $scope.hideActivityDropdown = true;
        }
        const allActivityModel = {
            '_id' : 'All',
            'name' : 'All'
        };

        self.setKpiSpecificActivities = function(){
            var activities = _.filter(p0.allActivities, function (activity){
                return activity.swot && _.includes( $scope.kpi.activities, activity._id);
            });
            activities.unshift(allActivityModel);
            return activities;
        };

        $scope.kpiSpecificActivities = self.setKpiSpecificActivities();
        $scope.selectedValue = {};

        $scope.selectedValue.activity = $scope.kpiSpecificActivities[0];

        $scope.swotCard = {
            onClick : function (item, $event) {
                if($scope.isQuickView){
                    //Quick view swot
                    if($scope.swotType == 'kpi'){
                        $scope.history.push({type:$scope.swotType, cycleId: $scope.cycleId, kpiId:$scope.kpiId, userId:$scope.userId, userName:$scope.userName, leagueId:$scope.leagueId, jobRoleId: $scope.jobRoleId});
                        $scope.kpiId = item._id;
                        $scope.swotType = 'kai';
                        $scope.fetchSwot();
                    }else if($scope.swotType == 'kai'){
                        $scope.moreDetailClick($event, item._id); //Filter only this activity in KAI modal
                    }else if($scope.swotType == 'reportee'){
                        $scope.history.push({type:$scope.swotType, cycleId: $scope.cycleId, kpiId:$scope.kpiId, userId:$scope.userId, userName:$scope.userName, leagueId:$scope.leagueId, jobRoleId: $scope.jobRoleId});
                        $scope.swotType = 'kpi';
                        $scope.userId = item._id;
                        $scope.userName = item.name;
                        $scope.leagueId = item.leagueId;
                        $scope.fetchSwot();
                    }
                }else{
                    //Analytics swot case - calling parents onClick Handler
                    if($scope.swotType == 'kpi'){
                        if($scope.onClick) $scope.onClick(item._id, {_id:$scope.userId ,name: $scope.userName, leagues:$scope.swotUser.leagues, systemRole:'frontline'});
                    }else if($scope.swotType == 'kai'){
                        $scope.moreDetailClick($event, item._id); //Filter only this activity in KAI modal
                    }else if($scope.swotType == 'reportee'){
                        $scope.history.push({type:$scope.swotType, cycleId: $scope.cycleId, kpiId:$scope.kpiId, userId:$scope.userId, userName:$scope.userName, leagueId:$scope.leagueId, jobRoleId: $scope.jobRoleId});
                        $scope.swotType = 'kpi';
                        $scope.userId = item._id;
                        $scope.userName = item.name;
                        $scope.leagueId = item.leagueId;
                        $scope.fetchSwot();
                    }
                }
            }
        };

        $scope.fetchSwot = function () {
            if(!($scope.cycleId && $scope.userId && $scope.swotType)) return;
            if($scope.swotType == 'kpi'){
                SwotService.getKpiSwot($scope.cycleId, $scope.userId).then(function (kpiSwot) {
                    $scope.swot = kpiSwot;
                });
            }else if($scope.swotType == 'kai'){
                SwotService.getKaiSwot($scope.cycleId, $scope.userId, $scope.switch.isKpiOverall?'overall':$scope.kpiId).then(function (kaiSwot) {
                    $scope.swot = self.filterSwotActivities(kaiSwot);
                });
            }else if($scope.swotType == 'reportee'){
                if(!$scope.jobRoleId) return;
                SwotService.getReporteeSwot($scope.cycleId, $scope.userId, $scope.kpi._id, $scope.jobRoleId).then(function(reporteeSwot){
                    $scope.swot = reporteeSwot;
                    $scope.reporteeSwot = reporteeSwot;
                });
                SwotService.getKpiSpecificActivitySwot($scope.cycleId, $scope.userId, $scope.kpi._id, $scope.jobRoleId).then(function(kpiSpecificActivitySwot){
                    $scope.activitySwot = kpiSpecificActivitySwot;
                })
                // SwotService.getKpiSpecificManagerActivitySwot
            }
        };
        self.filterSwotActivities = function (kaiSwot) {
            var allActivities = p0.allActivities;
            _.forEach(['s','w','o','t','u'], function (swotKey) {
                kaiSwot[swotKey] = _.filter(kaiSwot[swotKey], function (kai) {
                    return _.find(allActivities,{_id: kai._id}).swot;
                });
            });
            return kaiSwot;
        };

        $scope.swotBackClick = function () {
            var prevState = $scope.history.pop();
            if(prevState){
                $scope.swotType = prevState.type;
                $scope.cycleId = prevState.cycleId;
                $scope.kpiId = prevState.kpiId;
                $scope.userId = prevState.userId;
                $scope.userName = prevState.userName;
                $scope.leagueId = prevState.leagueId;
                $scope.jobRoleId = prevState.jobRoleId;
                $scope.fetchSwot();
                if($scope.onParentSwitchChange) $scope.onParentSwitchChange($scope.switch.isReportee);
            }
        };

        $scope.onActivityChange = function(activity){
            $scope.activity = activity;
            if(activity._id !== 'All'){
                $scope.selectedValue.activity = activity;

                if( !$scope.activitySwot || !$scope.activitySwot[activity._id]) return;

                $scope.swot = $scope.activitySwot[activity._id];
                $scope.swotType = 'activity';
            }else{
                $scope.swot = $scope.reporteeSwot;
                $scope.swotType = 'reportee';
            }
        }

        $scope.moreDetailClick = function (ev, activityFilter) {
            var params = {
                userId: $scope.userId,
                leagueId: $scope.leagueId,
                cycleId: $scope.cycleId,
                kpiId: $scope.switch.isKpiOverall?null:$scope.kpiId,
                jobRoleId: $scope.jobRoleId,
                activityFilter: activityFilter
            };
            if($scope.swotType == 'kpi'){
                params.title = "KPI Summary";
            }else if($scope.swotType == 'kai'){
                if($scope.switch.isKpiOverall) {
                    params.title = "KAI Summary";
                }else{
                    params.title = "KAI Summary for ";
                }
            }else if( $scope.swotType === 'activity' ){
                params.activityId = $scope.selectedValue.activity._id;
                params.title = 'Reportee Summary';
            }else{
                params.title = "Reportee Summary";
            }
            $mdDialog.show({
                locals:{params:params, swotType: $scope.swotType },
                controller: 'DialogController',
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose:true,
                fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
            });
        };
        self.getJobRolesFromPlayType = function (roleType) {
            if($scope.selectedPlayType._id !='all'){
                var jobRoleId = $scope.selectedPlayType[roleType][0];
                self.setLeagueId(jobRoleId);
                return jobRoleId;
            }else{
                //All divisions case
                $scope.leagueId = null;
                return roleType;
            }
        };

        self.setLeagueId = function (jobRoleId) {
            var leagues = _.find(p0.allJobRoles, {_id:jobRoleId}).leagues;
            if(leagues) {
                $scope.leagueId = _.find(leagues, {teamType:'bank'}).leagueId;
            }
        };

        $scope.$watch('kpi', function(newValue, oldValue){
            if(newValue){
                $scope.kpiSpecificActivities = self.setKpiSpecificActivities();
                $scope.selectedValue.activity = $scope.kpiSpecificActivities[0];
            }
        });

        $scope.$watchGroup(['swotUser','cycleId','type','kpi', 'selectedPlayType'],function (newValue, oldValue) {
            if(newValue[0] && newValue[1] && newValue[2]){
                $scope.userId = $scope.swotUser._id;
                if(preUserId &&  preUserId !== $scope.userId && !$scope.isQuickView){
					$scope.switch.isReportee = false;
					self.setReporteeLabel($scope.switch.isReportee, newValue[2]);
					preUserId = $scope.userId;
				}else{
					preUserId = $scope.userId;
				}
                $scope.isFrontline = false;
                if($scope.kpi){
                    $scope.kpiId = $scope.kpi._id;
                }
                if($scope.me._id != $scope.userId){
                    $scope.userName = $scope.swotUser.name;
                }else{
                    $scope.userName ='My';
                }
                if($scope.swotUser.systemRole=='frontline'){
                    $scope.isFrontline = true;
                    $scope.leagueId = _.find($scope.swotUser.leagues, {teamType:'bank'}).leagueId;
                }else if($scope.swotUser.systemRole=='manager'){
                    $scope.label1 = 'Me';
                    if($scope.me._id != $scope.userId) $scope.label1 = $scope.swotUser.name;
                    $scope.label2 = $rootScope.strings.FRONTLINE.value;
                    if($scope.me._id != $scope.userId) $scope.label2 = $scope.swotUser.name+'\'s '+$rootScope.strings.FRONTLINE.value+'s';
                    $scope.leagueId = _.find($scope.swotUser.leagues, {teamType:'bank'}).leagueId;
                }else{
                    $scope.label1 =$rootScope.strings.FRONTLINE.value;
                    $scope.label2 = $rootScope.strings.MANAGER.value;
                    $scope.jobRoleId =  $scope.switch.isReportee ?  self.getJobRolesFromPlayType('managers') : self.getJobRolesFromPlayType('frontline') ;
                }
                //In case of quick view swot - single swot component changes to different swot types based of the selected swot user
                //In Analytics swot case - swot type is determined by its parent container.
                if($scope.isQuickView){
                    self.setSwotTypeBasedOnRole();
                }else{
                    if($scope.switch.isReportee && $scope.swotUser.systemRole=='manager'){
                        $scope.swotType = 'reportee';
                    }else $scope.swotType = $scope.type;
                }

                $scope.history = [];
                $scope.fetchSwot();
            }
        });
        $scope.$watch('meReporteeSwitch',function (newValue, oldValue) {
            if(newValue != undefined && newValue != null && $scope.swotUser && $scope.isQuickView){
                self.setVarsOnSwitch(newValue);
            }
        });
        self.setSwotTypeBasedOnRole = function(){
            if($scope.swotUser.systemRole=='frontline'){
                if($scope.kpi._id !='overall'){
                    $scope.swotType = 'kai';
                }else $scope.swotType = 'kpi';
            }else if($scope.swotUser.systemRole=='manager'){
                if($scope.kpi._id !='overall'){
                    $scope.swotType = 'kai';
                }else $scope.swotType = 'kpi';
            }else{
                $scope.swotType = 'reportee';
            }
			self.setReporteeLabel($scope.switch.isReportee);
        };

        self.setReporteeLabel = function(isReportee, type){
        	type = type || $scope.swotType;
			$scope.params.reporteeLabel = type;
			if (type === 'reportee' && $rootScope.isManager){
				$scope.params.reporteeLabel = p0.strings.FRONTLINE.value;
				if($scope.swotUser.systemRole ==='manager' && isReportee){
					$scope.params.reporteeLabel = p0.strings.FRONTLINE.value;
				}else if($scope.swotUser.systemRole === 'seniorManager'){
					$scope.params.reporteeLabel = isReportee ? p0.strings.MANAGER.value : p0.strings.FRONTLINE.value;
				}else{
					$scope.params.reporteeLabel = type;
				}
			}else{
				$scope.params.reporteeLabel = type;
			}
		};

        self.setVarsOnSwitch = function (isReportee) {
            $scope.switch.isReportee = isReportee;
            if($scope.swotUser.systemRole=='manager'){
                if(isReportee || ( $scope.activity && $scope.activity._id !== 'All')){
                    $scope.swotType = 'reportee';
                    $scope.hideActivityDropdown = false;
                    $scope.jobRoleId = self.getJobRolesFromPlayType('frontline')
                }else{
                    $scope.hideActivityDropdown = true;
                    $scope.swotType = 'kpi';
                }
            }else if($scope.swotUser.systemRole=='seniorManager'){
                $scope.swotType = 'reportee';
                if(isReportee){
                    $scope.jobRoleId = self.getJobRolesFromPlayType('managers');
                }else{
                    $scope.jobRoleId = self.getJobRolesFromPlayType('frontline')
                }
            }
            else{
                $scope.hideActivityDropdown = true;
            }
            $scope.fetchSwot();
            if($scope.onParentSwitchChange){
				$scope.onParentSwitchChange(isReportee);
			}
			self.setReporteeLabel(isReportee)
        };

        $scope.onSwitchChange = function () {
            $scope.hideActivityDropdown = !$scope.switch.isReportee;
            self.setVarsOnSwitch($scope.switch.isReportee);
        };
    });
